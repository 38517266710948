import React from 'react';
import { MenuTextContainer } from './menu-text-styles';

interface MenuTextProps {
  children: any;
  className?: string;
  color?: string;
}

const MenuText = ({ children = '', className = '', color = '' }: MenuTextProps) => {
  return (
    <MenuTextContainer color={color} className={className}>
      {children}
    </MenuTextContainer>
  );
};
export default MenuText;
