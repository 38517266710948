// tslint:disable-next-line:file-name-casing

export const normalizePhoneNumber = (value: any, prevCompanyPhone: any) => {
    // return nothing if no value
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!prevCompanyPhone || value.length > prevCompanyPhone.length) {
        if (cvLength < 3) return currentValue;
        if (cvLength < 6) return `+${currentValue.slice(0, 2)} ${currentValue.slice(2)}`;
        return `+${currentValue.slice(0, 2)} ${currentValue.slice(2, 11)}`;
    }
};

export const formatPhoneToSave = (phoneNumber: string) => {
    // +34 632964428
    if (phoneNumber) {
        const prefix = phoneNumber.slice(1,3)
        const number = `${phoneNumber.slice(4,15)}`;
        return {prefix, number}
    }
    return null
}
