import * as React from 'react';
import { MarineReserveGalleryStyle } from './marine-reserve-gallery-style';
import HeadDestacadoText from '../../../components/text/head-destacado-text/head-destacado-text';
import CardPhotoCenter from '../../../components/card-photo-center/card-photo-center';
import { RouteComponentProps, withRouter } from 'react-router';
import { uploadMarineReservePictures, deleteMarineReservePictures } from '../../../services/marine-reserve-pictures';
import { getOneMarineReserve } from '../../../services/marine-reserve';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import ModalTitlePhoto from '../../../components/modals/modal-title-photo/modal-title-photo';
import ModalSlider from '../../../components/modals/modal-slider/modal-slider';
import services from '../../../services/services';

interface RouteParams {
  id: string;
}
export interface IMarineReserveGalleryProps extends RouteComponentProps<RouteParams> {
  setSelectedPictures?: Function;
  clearValue?: boolean;
}

export interface IMarineReserveGalleryState {
  featuredPictures: any[];
  pictures: any[];
  selectedPictures: string[];
  modal: string;
  pictureSelected: number;
  modalPictures: any[];
  idPicture: string;
  pictureEdit: any;
}

class MarineReserveGallery extends React.Component<IMarineReserveGalleryProps, IMarineReserveGalleryState> {
  constructor(props: IMarineReserveGalleryProps) {
    super(props);

    this.state = {
      featuredPictures: [],
      pictures: [],
      selectedPictures: [],
      modalPictures: [],
      modal: '',
      pictureSelected: -1,
      idPicture: '',
      pictureEdit: {},
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.getMarineReserve(match.params.id);
    }

    window.addEventListener('reload-marine-reserve', this.reloadPicture);
  }

  componentWillUnmount() {
    window.removeEventListener('reload-marine-reserve', this.reloadPicture);
  }

  componentDidUpdate(prevProps: IMarineReserveGalleryProps) {
    const { clearValue } = this.props;
    const { selectedPictures } = this.state;
    if (clearValue && prevProps.clearValue !== clearValue && selectedPictures.length > 0) {
      this.setState({ selectedPictures: [] });
    }
  }

  reloadPicture = () => {
    const { match } = this.props;
    if (match.params.id) {
      this.getMarineReserve(match.params.id);
    }
  };

  removePictures = () => {
    const { selectedPictures, pictures, featuredPictures } = this.state;

    const filterPictures = pictures.filter(element => !selectedPictures.includes(element.id));
    const filterFeaturedPictures = featuredPictures.filter(element => !selectedPictures.includes(element.id));

    this.setState({
      pictures: filterPictures,
      featuredPictures: filterFeaturedPictures,
      selectedPictures: [],
    });
  };

  getMarineReserve = async (id: string, showSlider?: boolean) => {
    try {
      const { modalPictures } = this.state;

      const params = {
        filter: {
          relations: ['pictures', 'pictures.likes'],
        },
      };

      const response: any = await getOneMarineReserve(id, params);
      const featuredPictures: any[] = response.data.pictures.filter((p: any) => p.featured);
      const pictures: any[] = response.data.pictures.filter((p: any) => !p.featured);
      let modal = '';
      if (showSlider && modalPictures.length > 0) {
        modal = 'modal-slider';
      }
      this.setState({ featuredPictures, pictures, modal, pictureSelected: 0 });
    } catch (err) {}
  };

  handleSelected = (checked: boolean, id: string, picture: any) => {
    const { selectedPictures } = this.state;
    const { setSelectedPictures } = this.props;

    if (!checked) {
      const filter = selectedPictures.filter((p: any) => p.id !== id);
      this.setState({ selectedPictures: filter });
      if (setSelectedPictures) {
        setSelectedPictures(filter);
      }
    } else {
      const newSelectedPictures = selectedPictures.concat([{ ...picture }]);
      this.setState({ selectedPictures: newSelectedPictures });
      if (setSelectedPictures) {
        setSelectedPictures(newSelectedPictures);
      }
    }
  };

  handleFav = async (fav: boolean, id: string) => {
    const data = new FormData();
    data.set('featured', fav.toString());
    const { featuredPictures, pictures } = this.state;

    try {
      await uploadMarineReservePictures(id, data);
      if (fav) {
        const index = pictures.findIndex(p => p.id === id);
        featuredPictures.push(pictures[index]);
        pictures.splice(index, 1);
      } else {
        const index = featuredPictures.findIndex(p => p.id === id);

        pictures.push(featuredPictures[index]);
        featuredPictures.splice(index, 1);
      }
      this.setState({ featuredPictures, pictures });
    } catch (err) {}
  };

  deleteMarineReserveSite = async () => {
    const { idPicture } = this.state;

    try {
      await deleteMarineReservePictures(idPicture);
      services.pushNotification({
        title: 'Fotografía eliminada',
        text: 'Se ha eliminado la fotografía con éxito.',
        type: 'red',
      });
      const { modalPictures } = this.state;
      const index = modalPictures.findIndex(p => p.id.toString() === idPicture.toString());
      if (index !== -1) {
        modalPictures.splice(index, 1);
      }
      this.setState({ modalPictures }, () => {
        const { match } = this.props;
        if (match.params.id) {
          this.getMarineReserve(match.params.id, true);
        }
      });
    } catch (err) {}
  };

  editMarineReserveSite = async (data: any) => {
    const { idPicture } = this.state;
    try {
      const formData = new FormData();
      // formData.set('main', data.main);
      formData.set('description', JSON.stringify(data.description));
      await uploadMarineReservePictures(idPicture, formData);
      services.pushNotification({
        title: 'Fotografía editada',
        text: 'Se ha editado la fotografía con éxito.',
        type: 'green',
      });
      const { modalPictures } = this.state;
      const index = modalPictures.findIndex(p => p.id.toString() === idPicture.toString());
      if (index !== -1) {
        modalPictures[index].description = Object.assign({}, data.description);
      }
      this.setState({ modalPictures }, () => {
        const { match } = this.props;
        if (match.params.id) {
          this.getMarineReserve(match.params.id, true);
        }
      });
    } catch (err) {}
  };

  render() {
    const {
      featuredPictures,
      pictures,
      modal,
      pictureSelected,
      modalPictures,
      idPicture,
      pictureEdit,
      selectedPictures,
    } = this.state;
    return (
      <MarineReserveGalleryStyle>
        {featuredPictures.length > 0 && (
          <div className="dive-site-gallery-section">
            <div className="dive-site-gallery-section__title">
              <div className="dive-site-gallery-section__title__text">
                <HeadDestacadoText>Destacadas</HeadDestacadoText>
              </div>
              {/* TODO: SUPERADMIN NO ESTÄ
             <div className="dive-site-gallery-section__title__buttons">
              <SingleButton className="secondary" icon={images.alertSvg} onClick={() => {}} />
            </div> */}
            </div>
            <div className="dive-site-gallery-section__grid">
              {featuredPictures.map((fp: any, index: number) => (
                <CardPhotoCenter
                  key={`featured-${fp.id}`}
                  isFav={true}
                  picture={fp}
                  canAddFav={true}
                  selected={!!selectedPictures.find((sp: any) => sp.id === fp.id)}
                  handleFav={(fav: boolean, id: string) => this.handleFav(fav, id)}
                  handleSelected={(checked: boolean, id: string) => this.handleSelected(checked, id, fp)}
                  onClick={() =>
                    this.setState({
                      modal: 'modal-slider',
                      pictureSelected: index,
                      modalPictures: featuredPictures,
                    })
                  }
                />
              ))}
            </div>
          </div>
        )}
        <div className="dive-site-gallery-section">
          <div className="dive-site-gallery-section__title">
            <div className="dive-site-gallery-section__title__text">
              <HeadDestacadoText>Galería</HeadDestacadoText>
            </div>
            {/* TODO: NO ENTIENDO ESTE SELECT
            <div className="dive-site-gallery-section__title__buttons">
              <SelectBox
                labelText="país"
                placeholder="Seleccione certificadora"
                required={true}
                className="rounded-filter"
                // errorCode={"error"}
                optionsText={[]}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={''}
                onChange={() => {}}
                icon={images.arrowDown2BlueSvg}
                // onChange={(value) => {
                //     handleDataChange("insured", "doc_type", value);
                // }}
              />
            </div>
            */}
          </div>
          <div className="dive-site-gallery-section__grid">
            {pictures.map((fp: any, index: number) => (
              <CardPhotoCenter
                key={`picture-${fp.id}`}
                isFav={false}
                picture={fp}
                canAddFav={true}
                selected={!!selectedPictures.find((sp: any) => sp.id === fp.id)}
                handleFav={(fav: boolean, id: string) => this.handleFav(fav, id)}
                handleSelected={(checked: boolean, id: string) => this.handleSelected(checked, id, fp)}
                onClick={() =>
                  this.setState({
                    modal: 'modal-slider',
                    pictureSelected: index,
                    modalPictures: pictures,
                  })
                }
              />
            ))}
          </div>
        </div>
        {modal === 'modal-slider' && (
          <ModalSlider
            onClose={() => this.setState({ modal: '' })}
            showModal={modal === 'modal-slider'}
            pictures={modalPictures}
            pictureSelected={pictureSelected}
            handleFav={(fav: boolean, id: string) => this.handleFav(fav, id)}
            handleDelete={(id: string) => this.setState({ idPicture: id, modal: 'modal-delete-picture' })}
            handleEdit={(id: string, picture: any) =>
              this.setState({ idPicture: id, modal: 'modal-edit-picture', pictureEdit: picture })
            }
          />
        )}

        {modal === 'modal-delete-picture' && (
          <ModalDelete
            title={'Vas a eliminar la imagen'}
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete-picture'}
            onRemove={() => {
              this.deleteMarineReserveSite();
            }}
            onCancel={() => this.setState({ modal: 'modal-slider' })}
          />
        )}
        {modal === 'modal-edit-picture' && (
          <ModalTitlePhoto
            idPicture={idPicture}
            picture={pictureEdit}
            showModal={modal === 'modal-edit-picture'}
            onAccept={(data: any) => {
              this.editMarineReserveSite(data);
            }}
            onCancel={() => this.setState({ modal: 'modal-slider' })}
          />
        )}
      </MarineReserveGalleryStyle>
    );
  }
}

export default withRouter(MarineReserveGallery);
