import styled from 'styled-components';

export const ContentRightStyle = styled.div`
  .title {
    font-size: 11px;
    color: #61656d;
    text-transform: uppercase;
    display: block;
    margin-bottom: 7px;
  }
  .data {
    color: #000019;
    font-size: 13px;
  }
  .content-right {
    width: 100%;
    &__observation {
      // height: 4rem;
      background-color: rgba(1, 66, 81, 1);
      color: white !important;
      padding: 1em;
      border-radius: 4px;
      .title {
        color: white !important;
        display: flex;
        justify-content: space-between;
        img {
          width: 15px;
          cursor: pointer;
        }
      }
      p {
        font-size: 13px;
        margin: 1em 0 1em 0;
      }
    }
    &__date {
      background-color: #f5f5f5;
      padding: 1em;
      border-radius: 4px;
      margin-top: 10px;
      // height: 6em;
    }
    &__price {
      background-color: #f5f5f5;
      padding: 1em;
      border-radius: 4px;
      margin-top: 10px;
      // height: 6em;
    }
    &__contact {
      padding: 1em;
      border-radius: 4px;
      margin-top: 10px;
      // height: 4em;
      border: 1px solid rgba(1, 66, 81, 1);
      .title {
        color: #323438;
      }
      .data {
        display: flex;
        margin-top: 15px;
         .image {
          margin-right: 10px;
          margin-top: 5px;
        }
        .contact-info {
          color: #323438;
          font-size: 1.1em;
          &__email {
            display: block;
            margin-top: 7px;
            color:#8cbfc6;
          }
        }
      }
    }
  }
  .modal-footer {
    margin-top: 2em;
  }
  table {
    width: 100%;
    thead {
      td {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.2;
        vertical-align: top;
        &:nth-child(odd) {
          width: 60%;
        }

        &:nth-child(even) {
          text-align: right;
        }

        &.small {
          font-size: 12px;
          padding-bottom: 5px;
        }
      }
    }
    tbody {
      tr {
        &.total {
          border-top: 1px solid #e9eaeb;
          td {
            font-size: 20px;
          }
          .total_price {
            margin: 0;
            font-size: 20px;
          }

          .total_price_person {
            font-size: 11px;
            margin: 0;
            opacity: 0.6;
          }
        }
        td {
          font-size: 13px;
          font-weight: 400;
          padding: 15px 0px 15px;
          line-height: 1.2;
          vertical-align: top;

          &:nth-child(odd) {
            width: 60%;
          }
          &:nth-child(even) {
            text-align: right;
          }
        }
      }
    }
  }
`;
