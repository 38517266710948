import React from 'react';
import {images} from "../../../../../assets/images";
import BodyDestacadoText from "../../../../../components/text/body-destacado-text/body-destacado-text";
import MainButton from "../../../../../components/main-button";
import ModalContainer from "../../../../../components/modals/modal-container";
import {ModalRecoverChatStyle} from "./modal-recover-chat-style";


interface ModalRecoverChatProps {
  centreId?: string;
  showModal: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const ModalRecoverChat = ({ onCancel, onSuccess, showModal }: ModalRecoverChatProps) => {
  return (
    <ModalContainer modalClose={onCancel} active={showModal} title=''>
      <ModalRecoverChatStyle>
        <div className="modal-icon-container">
          <img src={images.alertBlueSvg} alt="alerta" className="modal-icon"/>
        </div>
        <BodyDestacadoText className="modal-text">
          Vas a recuperar una conversación ¿estas seguro?
        </BodyDestacadoText>

        <MainButton
          type="primary"
          text="Recuperar"
          onClick={onSuccess}
        />
      </ModalRecoverChatStyle>
    </ModalContainer>
  );
};

export default ModalRecoverChat;
