import { TableColumn } from '../../../types/table';

export interface Company {
  id: string;
  name: string;
  kind: string;
  location: string;
  cetifiers: any;
  centreId: string;
  createdAt: string;
  validated: boolean;
  email: string;
  checked?: boolean;
}

export const tableColumns = (t: any): TableColumn[] => [
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: t('tables:name'),
    filter: false,
    ascendent: true,
  },
  {
    text: t('buttons:type'),
    filter: false,
    ascendent: true,
  },
  {
    text: t('buttons:place'),
    filter: false,
    ascendent: false,
  },
  {
    text: t('buttons:certs'),
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: 'ID',
    filter: false,
    ascendent: true,
  },
  {
    text: t('buttons:reg'),
    filter: false,
    ascendent: true,
  },
  {
    text: t('buttons:status'),
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
];

export const rowKeys: string[] = [
  'check',
  'name',
  'kind',
  'location',
  'certifier',
  'count',
  'centreId',
  'register-date',
  'validated',
  'options',
];
