import styled, { css } from 'styled-components';

export const Label02TextContainer = styled.p`
  font-family: 'plain';
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 3px;

  ${props => css`
    color: ${props.color};
  `}
`;
