import * as React from 'react';
import { CardPhotoCenterStyle } from './card-photo-center-style';
import { images } from '../../assets/images';
import DicotomicCheckbox from '../dicotomic-checkbox';
import MenuText from '../text/menu-text/menu';
import InfoText from '../text/info-text/info-text';

export interface ICardPhotoCenterProps {
  isFav?: boolean;
  canAddFav?: boolean;
  picture: any;
  handleFav?: Function;
  handleSelected?: Function;
  onClick?: Function;
  selected?: boolean;
}

export interface ICardPhotoCenterState {
  fav: boolean;
}

export default class CardPhotoCenter extends React.Component<ICardPhotoCenterProps, ICardPhotoCenterState> {
  constructor(props: ICardPhotoCenterProps) {
    super(props);

    this.state = {
      fav: this.props.isFav || false,
    };
  }

  render() {
    const { fav } = this.state;
    const { picture, handleFav, handleSelected, onClick, canAddFav, selected } = this.props;
    let filename = '';
    // let name = '';
    // let file = '';
    if (picture.src) {
      const url = decodeURIComponent(picture.src);
      filename = url.substring(url.lastIndexOf('/') + 1);
      const s = filename.split('.');
      // file = s[s.length - 1];
      // name = s.slice(0, -1).join(".");
    }
    return (
      <CardPhotoCenterStyle>
        <div className="card-photo-center-img">
          <div className="card-photo-center-img__img">
            <img src={picture.src} alt="" />
          </div>
          {handleSelected && (
            <div className="card-photo-center-img__check">
              <DicotomicCheckbox
                checked={selected}
                className="table"
                onCheck={value => handleSelected(value, picture.id)}
              />
            </div>
          )}

          {canAddFav && (
            <div
              className="card-photo-center-img__fav"
              onClick={() => this.setState({ fav: !fav }, () => handleFav && handleFav(this.state.fav, picture.id))}
            >
              <img src={!fav ? images.starWhiteSvg : images.starFullRedSvg} alt="" />
            </div>
          )}
          {picture.likes.length > 0 && (
            <div className="card-photo-center-img__rate">
              <div className="card-photo-center-img__rate__icon">
                <img src={images.thumbsUp} alt="" />
              </div>
              <div className="card-photo-center-img__rate__number">
                <p>{picture.likes.length}</p>
              </div>
            </div>
          )}
        </div>
        <div className="card-photo-center-info" onClick={() => onClick && onClick()}>
          <div className="card-photo-center-info__title">
            <MenuText>{picture.description && picture.description.es ? picture.description.es : '-'}</MenuText>
          </div>
          <div className="card-photo-center-info__file">
            <InfoText>{filename}</InfoText>
          </div>
        </div>
      </CardPhotoCenterStyle>
    );
  }
}
