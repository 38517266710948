import services from './services';

export const getBills = (params?: any, loader: boolean = true) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      loader,
      endpoint: `billing`,
      then: resolve,
      catch: reject,
    };
    services.get(args);
  });
};

export const getCentreBills = (centreId: string, params?: any, loader: boolean = true) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      loader,
      endpoint: `billing/${centreId}`,
      then: resolve,
      catch: reject,
    };
    services.get(args);
  });
};

export const postBill = (centreId: string | number, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `billing/${centreId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const getBill = (billId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      loader: true,
      endpoint: `billing/invoice/${billId}`,
      then: resolve,
      catch: reject,
    };
    services.get(args);
  });
};
