import styled from "styled-components";
import { colors } from "../../../assets/colors";

export const ModalDeleteContainer = styled.div`
  width: 416px;
  padding: 62px 32px 32px;
  .modal-delete-pop  {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    width: 100%;
  }

  .modal-delete-img  {
    padding: 20px;
    border-radius: 100%;
    width: 68px;
    height: 68px;
    background: ${colors["CO-003/15"]};
    margin-bottom: 20px;
  }
  .modal-delete-title {
    text-align: center;
    margin-bottom: 40px;
  }
  .modal-delete-buttons  {
    width: 280px;
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;
