import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const PhoneBoxContainer = styled.div`
  position: relative;

  &.show-list {
    z-index: 12;
    .input-box-main-field-select {
      color: #49abb8;
      border-bottom: 1px solid #49abb8;
      p {
        color: #49abb8;
      }
    }
    .input-box-main-list {
      display: block;
    }
  }

  &.required {
    .input-box-topbar-label {
      position: relative;
      padding-left: 10px;
      &::before  {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 9px;
        background: ${colors['PR-001/100']};
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .input-box-main-field-input {
    &.error {
      input {
        border-bottom: 1px solid ${colors['CO-003/100']};
      }
      .input-box-error {
        color: ${colors['CO-003/100']};
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: ${colors['TX-001-30']};
    }

    .input-box-main-field {
      background: ${colors['NE-004-30']};
      border-bottom: 1px solid ${colors['TX-001-30']};
      color: ${colors['TX-001-30']};

      &:before {
        background: ${colors['TX-001-30']};
      }
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 6px;
  }

  .input-box-topbar-label {
    color: ${colors['NE-002/100']};
    text-transform: uppercase;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    right: 0;
  }

  .input-box-error {
    color: ${colors['CO-003/100']};
    min-height: 18px;
  }

  .input-box-main-field {
    position: relative;
    width: 100%;
    padding: 13px 18px 9px;
    padding-right: 30px;
    transition: all 0.2s ease-in-out;
    margin-bottom: 4px;
    color: ${colors['NE-001/100']};
    border-bottom: 1px solid ${colors['NE-001/100']};
    cursor: pointer;
    height: 48px;

    p {
      cursor: inherit;
    }

    &:before {
      content: '';
      z-index: 9;
      position: absolute;
      top: 23px;
      left: 4px;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      background: ${colors['NE-001/100']};
    }

    &:hover {
      background: ${colors['SC-001-10']};
    }

    &:focus {
      background: ${colors['NE-004-0']};
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-list {
    position: absolute;
    top: calc(100% + 10px);
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    background: ${colors['NE-004-0']};
    width: 100%;
    color: ${colors['NE-001/100']};
    max-height: 250px;
    overflow-y: scroll;
    background: white;

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      span {
        margin-left: 8px;
        font-size: 14px;
        color: ${colors['TX-001-50']};
      }

      &:hover {
        background: ${colors['SC-001-10']};
      }

      &.no-data {
        font-size: 14px;
        line-height: 20px;
        color: ${colors['TX-001-50']};
        padding: 15px;
      }
    }
  }

  .input-box-main {
    display: flex;
  }

  .input-box-main-field-select {
    position: relative;
    width: 100%;
    cursor: pointer;
    margin-right: 20px;
    /* height: 52px; */
    max-width: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;

    &:before {
      content: '';
      z-index: 9;
      position: absolute;
      top: 22px;
      left: 4px;
      border-radius: 50%;
      width: 6px;
      height: 6px;
    }

    &:hover {
      background: ${colors['SC-001-10']};
    }
    input {
      &:focus {
        background: ${colors['NE-004-0']};
        border-bottom: none;
        color: ${colors['NE-001/100']};
        border-bottom: 1px solid ${colors['NE-001/100']};
      }
    }

    &.error {
      .input-box-main-field-select-inputs  {
        input {
          border-bottom: 1px solid ${colors['CO-003/100']};
        }
        .input-box-error {
          color: ${colors['CO-003/100']};
        }
      }
    }

    &.complete {
      input {
        color: ${colors['TX-001-100']};
        &:focus {
          background: ${colors['NE-004-0']};
          border-bottom: none;
          color: ${colors['NE-001/100']};
          border-bottom: 1px solid ${colors['NE-001/100']};
        }
      }
    }
  }

  input {
    font-family: 'plain';
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    font-weight: 300;
    /* color: ${colors['NE-002/100']}; */
    padding: 13px 18px 9px;
    transition: all 0.2s ease-in-out;
    padding-right: 30px;
    margin-bottom: 4px;
    height: 48px;
    border-bottom: 1px solid ${colors['TX-001-30']};
  }

  .input-box-main-field-input {
    position: relative;
    width: 100%;

    input {
      border-bottom: 1px solid ${colors['TX-001-30']};

      &:focus {
        background: ${colors['NE-004-0']};
        border-bottom: none;
        color: ${colors['NE-001/100']};
        border-bottom: 1px solid ${colors['NE-001/100']};
      }
      &::placeholder  {
        color: ${colors['NE-002/100']};
      }
    }

    &:before {
      content: '';
      z-index: 9;
      position: absolute;
      top: 23px;
      left: 4px;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      background: ${colors['PR-001-100']};
    }

    &:hover {
      background: ${colors['SC-001-10']};
    }

    &:focus {
      background: ${colors['NE-004-0']};
      border-bottom: none;
      color: ${colors['NE-001/100']};
      border-bottom: 1px solid ${colors['NE-001/100']};
    }

    &.error {
      input {
        border-bottom: 1px solid ${colors['CO-003/100']};
      }
    }

    &.complete {
      input {
        color: ${colors['TX-001-100']};
        &:focus {
          color: ${colors['NE-001/100']};
        }
      }
    }
  }

  &.rounded {
    .input-box-main-field-input .input-box-topbar {
      display: none;
    }

    .input-box-main .input-box-main-field-select.error,
    .input-box-main .input-box-main-field-input.error {
      input {
        border: 1px solid ${colors['CO-003/100']};
      }
    }
    .input-box-error {
      color: ${colors['CO-003/100']};
    }
    .input-box-topbar {
      display: none;
    }
    .input-box-main {
      .input-box-main-field-select-inputs,
      .input-box-main-field-input {
        &.complete,
        &:focus {
          input {
            border: 1px solid ${colors['NE-002/100']};
          }
        }

        input {
          border: 1px solid ${colors['NE-002/100']};
          border-radius: 5px;
          font-family: 'plain';
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
  &.rounded-title {
    .input-box-main .input-box-main-field-select.error,
    .input-box-main .input-box-main-field-input.error {
      input {
        border: 1px solid ${colors['CO-003/100']};
      }
    }
    .input-box-error {
      color: ${colors['CO-003/100']};
    }
    .input-box-main {
      .input-box-main-field-select-inputs,
      .input-box-main-field-input {
        &.complete,
        &:focus {
          input {
            border: 1px solid ${colors['NE-002/100']};
          }
        }

        input {
          border: 1px solid ${colors['NE-002/100']};
          border-radius: 5px;
          font-family: 'plain';
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
`;
