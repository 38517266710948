import styled from 'styled-components';

export const UsersSuperAdminLayoutStyle = styled.div`
  .dive-center-head {
    display: flex;
    justify-content: flex-end;
    &__filter {
      max-width: 120px;
      margin-bottom: 40px;
    }
  }

  .dive-center-filters {
    display: none;
    &.active {
      display: flex;
    }
    margin-bottom: 40px;
    &__item  {
      width: 213px;
      margin-right: 20px;
    }
  }
`;
