import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const PlanningEndActivityStyle = styled.div`
  padding: 48px 32px 32px;
  width: 416px;
  .planning-end-activity-title-top {
    font-family: Plain;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${colors['SE-002/100']};
    margin-bottom: 2px;
  }

  .planning-end-activity-title-buttom {
    font-family: Plain;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: ${colors['NE-001/100']};
    margin-bottom: 12px;
    max-width: 320px;
  }

  .planning-end-activity-subtitle {
    font-family: Plain;
    font-size: 14px;
    font-weight: 100;
    line-height: 22px;
    color: ${colors['NE-002/100']};
    margin-bottom: 28px;
    max-width: 300px;
  }

  .planning-end-activity-checkbox-title {
    font-family: Plain;
    font-size: 14px;
    line-height: 22px;
    color: ${colors['SE-002/100']};
    margin-bottom: 12px;
  }

  .planning-end-activity-list {
    margin-bottom: 14px;
    .planning-end-activity-image {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 12px;
      & img {
        object-fit: cover;
        height: 100%;
      }
    }

    &:last-child {
      margin-bottom: 35px;
    }
  }
  .planning-end-activity-checkbox {
    margin-bottom: 50px;
  }
`;
