import * as React from 'react';
import { PayDataStyle } from './pay-data-style'
import ItemSectionDetail from '../../../../../components/item-sections-detail/item-sections-detail';
import { images } from '../../../../../assets/images';
import TextDestacadoText from '../../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../../components/text/text-text/text-text';
import IStoreState from '../../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import ModalContainer from '../../../../../components/modals/modal-container';
import { ModalCreateActivitiesStyle } from '../../../../dive-offers/activities/modal-create-activities/modal-create-activities-style';
import BookingFormPayData from '../../booking-form/booking-form-pay-data/booking-form-pay-data';
import { getOneBookings } from '../../../../../services/bookings';
import { mergeObject } from '../../../../../utils/other';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

const PayData = (props: any): JSX.Element => {
  const [bookingPayment, setBookingPayment] = useState<any>({
    paymentRequest: null,
    paymentResponse: null,
    paymentCorrect: false,
    rejected: false,
    paymentType: {}
  });
  const [renderModal, setRenderModal] = useState('')
  const [bookingCancelled, setBookingCancelled] = useState(false)
  const [bookingPaymentData, setbookingPaymentData] = useState(0)
  const [refundsBooking, setRefundsBooking] = useState(0)

  useEffect(() => {
    getBookingPayment()
  }, []);

  useEffect(() => {

    if (props.bookingPaymentData) {
      let paymentTypeName = ''
      if (props.bookingPaymentData.paymentType === 1) {
        paymentTypeName = 'Stripe'
      } else {
        paymentTypeName = 'Centre'
      }
      setBookingPayment({
        paymentRequest: null,
        paymentResponse: null,
        paymentCorrect: false,
        rejected: false,
        paymentType: { name: paymentTypeName }
      })
    }
  }, [props.bookingPaymentData])

  const getBookingPayment = async () => {
    if (!props.create) {
      const { bookingId } = props;
      const params = {
        filter: {
          relations: ['activity', 'divers', 'divers.user'],
        },
      };
      const params2 = {
        filter: {
          relations: ['charge', 'charge.refunds', 'payment', 'payment.paymentType'],
        },
      };
      const res = (await getOneBookings(bookingId, params)).data;
      const res2 = (await getOneBookings(bookingId, params2)).data;
      const booking = mergeObject(res, res2);


      if (booking.cancelled !== 'noCancelled') {
        setBookingCancelled(true);
      }

      //setBooking(booking)
      setBookingPayment(booking.payment);


      if (JSON.parse(booking.payment.paymentResponse)) {
        //if(booking.payment.paymentResponse.)
        const bookingResp = JSON.parse(booking.payment.paymentResponse)
        if (bookingResp.confirm) {
          const paymentResponse = JSON.parse(booking.payment.paymentResponse)
          setbookingPaymentData(paymentResponse.confirm.confirm
            && paymentResponse.confirm.confirm.amount_received ? paymentResponse.confirm.confirm.amount_received : null)
        }else{
          if(bookingResp.amount_received > 0){
            setbookingPaymentData(bookingResp.amount_received)
          }
        }
      }

      if (booking.charge && booking.charge.refunds && booking.charge.refunds.length !== 0) {
        let amount = 0;
        for (const r of booking.charge.refunds) {
          amount += parseInt(r.amount);
        }
        setRefundsBooking(amount);
      }

    }
  }

  const { t } = useTranslation(['layouts'])

  return (


    <PayDataStyle>
      <div className="pay-detail-general-container">
        <div className="pay-detail-general-left">

          <div className="pay-detail-general-left__section">
            <ItemSectionDetail
              title={t('layouts:bills.payinfo')}
              icon={images.serviceServices}
              onEdit={!props.bookingId ? () => setRenderModal('edit-pay-data') : undefined}
            >
              <div className="pay-detail-general-double">
                <div className="pay-detail-general-double__title">
                  <TextDestacadoText>{t('layouts:bookings.booking.bookingType')}:</TextDestacadoText>
                </div>
                <div className="pay-detail-general-double__text">
                  <TextText>Online</TextText>
                </div>
              </div>
              {bookingPayment.paymentType && bookingPayment.paymentType.name === 'Stripe' ?
                <div className="pay-detail-general-double">
                  <div className="pay-detail-general-double__title">
                    <TextDestacadoText>{t('layouts:bookings.paymethods')}:</TextDestacadoText>
                  </div>
                  <div className="pay-detail-general-double__text">
                    <TextText>{t('layouts:bookings.card')}</TextText>
                  </div>
                </div> : ''
              }
              {bookingPayment.paymentType && bookingPayment.paymentType.name === 'Stripe' ?
                <div className="pay-detail-general-double">
                  <div className="pay-detail-general-double__title">
                    <TextDestacadoText>{t('layouts:bills-detail.imprec')}:</TextDestacadoText>
                  </div>
                  <div className="pay-detail-general-double__text">
                    {bookingPayment.paymentCorrect ?
                      <TextText>{bookingPaymentData && bookingPaymentData / 100} €</TextText>
                      : '0 €'
                    }
                  </div>
                </div> : ''
              }
              {bookingCancelled && bookingPayment.paymentType && bookingPayment.paymentType.name === 'Stripe' ?
                <div className="pay-detail-general-double">
                  <div className="pay-detail-general-double__title">
                    <TextDestacadoText>{t('layouts:bills-detail.impret')}:</TextDestacadoText>
                  </div>
                  <div className="pay-detail-general-double__text">
                    {bookingPayment.paymentCorrect ?
                      <TextText>{refundsBooking / 100} €</TextText>
                      : '0 €'
                    }
                  </div>
                </div> : ''
              }

              <div className="pay-detail-general-double">
                <div className="pay-detail-general-double__title">
                  <TextDestacadoText>{t('layouts:bookings.booking.paymentType')}:</TextDestacadoText>
                </div>
                <div className="pay-detail-general-double__text">
                  {bookingPayment.paymentType ?
                    <TextText>{bookingPayment.paymentType.name}</TextText>
                    : ''
                  }
                </div>
              </div>
            </ItemSectionDetail>
          </div>
          {renderModal === 'edit-pay-data' && (
            <ModalContainer
              title={t('layouts:bookings.booking.editpay')}
              modalClose={() => setRenderModal('')}
              active={renderModal === 'edit-pay-data'}
            >
              <ModalCreateActivitiesStyle>
                <BookingFormPayData
                  create={props.create}
                  onNext={() => { getBookingPayment(); setRenderModal('') }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )}
        </div>
      </div>
    </PayDataStyle>
  )
}

function mapStateToProps(state: IStoreState) {
  return {
    bookingPaymentData: state.bookingPaymentData.value
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(PayData));
