import * as React from 'react';
import { BonusesStyle } from './bonuses-style';
import { images } from '../../../assets/images';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import MainTable from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { withRouter, RouteComponentProps, match } from 'react-router';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import ModalBonuses from './modal-bonuses/modal-bonuses';
import services from '../../../services/services';
import { getCentreTickets, deleteCentreTicket } from '../../../services/centre';
import { copyOf } from '../../../utils/other';
import { TICKET_DURATION_UNITS_KEY_VALUE_2 } from '../../../constants/duration';
import { CENTRE_TICKETS_PER_PAGE } from '../../../constants/data';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { ContentBlock } from '../../../components/content-block/content-block';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';

export interface IBonusesProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
  }>;
  staffPermissions?: any;
}

export interface IBonusesState {
  renderModal: string;
  tickets: any[];
  selectedTicket?: any;
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
}

class Bonuses extends React.Component<IBonusesProps, IBonusesState> {
  constructor(props: IBonusesProps) {
    super(props);

    this.state = {
      renderModal: '',
      tickets: [],
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
    };
  }

  componentDidMount() {
    this.getCentreTickets();
  }

  async getCentreTickets() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { skipPage } = this.state;
    const params = {
      filter: {
        skip: skipPage,
        limit: CENTRE_TICKETS_PER_PAGE,
        relations: ['templates'],
      },
    };
    const resp = await getCentreTickets(id, params);
    const tickets = resp.data.data;
    const totalPages: number = Math.ceil(resp.data.count / CENTRE_TICKETS_PER_PAGE);
    this.setState({ tickets, totalPages, total: resp.data.count });
  }

  async deleteTicket() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { selectedTicket } = this.state;
    if (selectedTicket) {
      await deleteCentreTicket(id, selectedTicket.id.toString());
      services.pushNotification({
        title: '¡Eliminado!',
        text: 'El bono se ha eliminado con éxito',
        type: 'red',
      });
      this.setState({ renderModal: '', selectedTicket: undefined }, () => {
        this.getCentreTickets();
      });
    }
  }

  getTableRows() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { tickets } = this.state;
    const tableRows: any[] = [];
    tickets.forEach((ticket: any) => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.name.value = ticket.name;
      tableRow.activities.value = ticket.templates.length > 0 ? ticket.templates[0].name : '';
      if (ticket.templates.length > 1) {
        tableRow.activitiesCount.value = {
          title: 'Actividades',
          items: ticket.templates.map((template: any) => template.name),
        };
      }
      tableRow.uses.value = ticket.uses;
      tableRow.duration.value = ticket.duration ? TICKET_DURATION_UNITS_KEY_VALUE_2[ticket.duration] : '';
      tableRow.shared.value = ticket.sharedUsage ? 'Sí' : 'No';
      tableRow.price.value = `${ticket.price / 100} €`;
      tableRow.view.link = `/centres/${id}/offers/bonuses/${ticket.id}`;
      tableRow.actions.actions[0].onClick = () => {
        this.setState({ renderModal: 'modal-create-bonus', selectedTicket: ticket });
      };
      tableRow.actions.actions[1].onClick = () => {
        this.setState({ renderModal: 'modal-delete-bonus', selectedTicket: ticket });
      };
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CENTRE_TICKETS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getCentreTickets();
      });
    }
  }

  closeModal() {
    this.setState({ renderModal: '', selectedTicket: undefined });
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t,
      staffPermissions,
    } = this.props;
    const { renderModal, totalPages, selectedTicket, page } = this.state;
    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Bonos'}
            mainButton={
              !staffPermissions || staffPermissions.deals
                ? {
                    text: 'Crear',
                    img: images.createWhiteSvg,
                    onClick: () => {
                      this.setState({ renderModal: 'modal-create-bonus' });
                    },
                  }
                : undefined
            }
          />
          {renderModal === 'modal-create-bonus' && (
            <ModalBonuses
              centreId={id}
              ticket={selectedTicket}
              showModal={renderModal === 'modal-create-bonus'}
              onSuccess={(isEdit: boolean) => {
                this.closeModal();
                if (isEdit) services.showModalSuccess('¡Editado!', 'El bono se ha editado correctamente');
                else services.showModalSuccess('¡Creado!', 'El bono se ha creado correctamente');
                this.getCentreTickets();
              }}
              onCancel={() => this.closeModal()}
            />
          )}
          {renderModal === 'modal-delete-bonus' && (
            <ModalDelete
              title=""
              subtitle="¿Estas seguro de querer eliminar el bono?"
              showModal={renderModal === 'modal-delete-bonus'}
              onRemove={() => this.deleteTicket()}
              onCancel={() => this.closeModal()}
            />
          )}
          <MainLayoutSection>
            <BonusesStyle>
              <div className="bonus-title">
                {/* <div className="bonus-title__filters">
                <SelectBox
                  className="rounded-filter"
                  labelText="Certificadora"
                  placeholder="Seleccione"
                  required={true}
                  initialValue={''}
                  optionsText={[]}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={''}
                  icon={images.arrowDown2BlueSvg}
                  onChange={() => {}}
                />
              </div> */}
              </div>
              <div className="bonus-table">
                <MainLayoutTableContainer>
                  <div className="diving-center-table">
                    <MainTable
                      columns={this.tableColumns}
                      rows={this.getTableRows()}
                      rowKeys={this.rowKeys}
                      onOrder={() => {}}
                    />
                  </div>
                  {totalPages > 1 && (
                    <div className="diving-center-pagination">
                      <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                    </div>
                  )}
                </MainLayoutTableContainer>
              </div>
            </BonusesStyle>
          </MainLayoutSection>
        </ContentBlock>
      </>
    );
  }

  tableColumns = [
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'nombre',
      filter: false,
      ascendent: false,
    },
    {
      text: 'actividades',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'nº de usos',
      filter: false,
      ascendent: true,
    },
    {
      text: 'compartir',
      filter: false,
      ascendent: true,
    },
    {
      text: 'validez',
      filter: false,
      ascendent: true,
    },
    {
      text: 'precio',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    select: {
      key: 'highlighted-body',
      value: '',
    },
    name: {
      key: '',
      value: '1234567043',
    },
    activities: {
      key: '',
      value: 'Diciembre',
    },
    activitiesCount: {
      key: 'count',
      value: '',
    },
    uses: {
      key: '',
      value: '15.00 €',
    },
    duration: {
      key: '',
      value: '15.00 €',
    },
    shared: {
      key: '',
      value: '',
    },
    price: {
      key: '',
      value: '15.00 €',
    },
    view: {
      key: 'detail',
      value: '',
    },
    actions: {
      key: 'options',
      actions: [
        {
          label: 'Editar',
          icon: images.editSvg,
          onClick: () => {},
        },
        {
          label: 'Eliminar',
          icon: images.deleteSvg,
          onClick: () => {},
        },
      ],
    },
  };

  rowKeys = [
    'select',
    'name',
    'activities',
    'activitiesCount',
    'uses',
    'shared',
    'duration',
    'price',
    'view',
    'actions',
  ];
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(withTranslation(['intro', 'buttons'])(withRouter(Bonuses)));
