import { Rules } from './rbac-types';

const rules: Rules = {
  diver: {
    static: [
      // 'centres:management',
      'profile:management',
      // "posts:list",
      // "home-page:visit"
    ],
  },
  divingCenter_owner: {
    static: [
      'centres:management',
      'roles:management',
      'profile:management',
      'configuration:management',
      'billings:management',
      // "posts:list",
      // "posts:create",
      // "users:getSelf",
      // "home-page:visit",
      // "dashboard-page:visit"
    ],
    dynamic: {
      "posts:edit": ({userId, postOwnerId}) => {
         if (!userId || !postOwnerId) return false;
         return userId === postOwnerId;
      }
    },
  },
  divingCenter_admin: {
    static: [
      'centres:management',
      'roles:management',
      'profile:management',
      //'configuration:management',
      //'billings:management',
      // "posts:list",
      // "posts:create",
      // "users:getSelf",
      // "home-page:visit",
      // "dashboard-page:visit"
    ],
    dynamic: {
      "posts:edit": ({userId, postOwnerId}) => {
         if (!userId || !postOwnerId) return false;
         return userId === postOwnerId;
      }
    },
  },
  divingCenter_staff: {
    static: [
      'centres:management',
      'roles:management',
      'profile:management',
      /*"posts:list",
      "posts:create",
      "home-page:visit",
      "dashboard-page:visit",
      'companies:getSelf',
      'users:management',
      'collaborators:getSelf',
      'incidences:getSelf',
      'notifications:getSelf',
      'profile:getSelf', */
    ],
    dynamic: {
      // "posts:edit": ({userId, postOwnerId}) => {
      //   if (!userId || !postOwnerId) return false;
      //   return userId === postOwnerId;
      // }
    },
  },
  organization_owner: {
    static: [
      'organization:management',
      'billings:management',
    ],
  },
  organization_staff: {
    static: ['organization:management'],
  },
  superAdmin: {
    static: [
      'roles:management',
      'companies:management',
      'users:management',
      'collaborators:management',
      'dive-sites:management',
      'incidences:management',
      'notifications:management',
      'configuration:management',
      'billings:management',
      'bills:superadmin',
      'ads:management',
      'stats:management',
      'profile:management',
      'ads:management',
      'centres:management',
      // "posts:list",
      // "posts:create",
      // "posts:edit",
      // "posts:delete",
      // "users:get",
      // "users:getSelf",
      // "home-page:visit",
      // "dashboard-page:visit"
    ],
  },
};

export default rules;
