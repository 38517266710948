import * as React from 'react';
import ModalContainer from '../modal-container';
import { ModalInfoStyle } from './modal-info-style';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import MainButton from '../../main-button';
import InputBox from '../../input-box';
import InputArea from '../../input-area/input-area';
import BodyText from '../../text/body-text/body-text';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

// export interface IModalButtons {
//   text: string;
//   type: string;
//   onClick: Function;
// }

export interface IModalInfoProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onClose: Function;
  withInput?: boolean;
  withArea?: boolean;
  type: string; // red || blue
  icon: string;
  text?: string[]; // {["" ,"", "" ]}
  buttons?: any; // {[{text: "", type: "", onClick:() => {}}, ____  , ____ ]}
  title: any[]; // {['This is ', <span key="">will</span>, ' work.']}
  onChange?: Function;
  requiredInput?: boolean;
  className?: string;
  t: any
}

export interface IModalInfoState {
  value: string;
}

class ModalInfo extends React.Component<IModalInfoProps, IModalInfoState> {
  constructor(props: IModalInfoProps) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onChange(value: string) {
    const { onChange } = this.props;
    onChange && onChange(value);
    this.setState({ value });
  }

  onClose() {
    const { onClose } = this.props;
    onClose();
    this.setState({ value: '' });
  }

  render() {
    const { showModal, icon, buttons,t, title, withInput, withArea, type, text, requiredInput, className } = this.props;
    const { value } = this.state;

    return (
      <ModalContainer className={className || ''} active={showModal} modalClose={() => this.onClose()}>
        <ModalInfoStyle className={type}>
          <div className="modal-info-pop">
            <div className="modal-info-img">
              <img src={icon} alt="" />
            </div>
            <div className="modal-info-title">
              <SubheadDestacadoText>{title}</SubheadDestacadoText>
            </div>
            {text && (
              <div className="modal-info-text">
                {text.map((p, index) => (
                  <div key={index} className="modal-info-text__item">
                    <BodyText>{p}</BodyText>
                  </div>
                ))}
              </div>
            )}
            {withInput && (
              <div className="modal-info-input">
                <InputBox
                  placeholder={t('components:general.message-body')}
                  className="rounded"
                  type="email"
                  value={value}
                  filledValue={''}
                  labelText=""
                  onChange={val => this.onChange(val)}
                />
              </div>
            )}
            {withArea && (
              <div className="modal-info-input">
                <InputArea
                  required={false}
                  name=""
                  placeholder={t('components:general.message-body')}
                  className="rounded"
                  value={value}
                  labelText=""
                  onChange={(ev: any) => this.onChange(ev.target.value)}
                />
              </div>
            )}
            {buttons && (
              <div className="modal-info-buttons">
                {buttons.map((item: any, index: any) => (
                  <div key={index} className="modal-info-buttons__item">
                    <MainButton
                      text={item.text}
                      type={item.type}
                      onClick={item.onClick}
                      disabled={requiredInput && value === ''}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </ModalInfoStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalInfo));
