import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ChatMessageContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  .chat-message-avatar {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 12px;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .chat-message-text  {
    max-width: 50%;
    &__item {
      border-radius: 5px;
      background: ${colors['NE-002/10']};
      color: ${colors['NE-001/100']};
      padding: 16px 32px 20px 16px;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &.right {
    flex-direction: row-reverse;
    .chat-message-avatar {
      margin-left: 12px;
    }
    .chat-message-text  {
      text-align: right;
      &__item {
        background: ${colors['SE-002/100']};
        color: white;
        padding: 16px 16px 20px 32px;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
`;
