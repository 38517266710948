import * as React from 'react';
import { CardDestinationStyle } from './card-destination-style';
import { images } from '../../assets/images';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';
import SingleButton from '../single-button/single-button';

export interface ICardDestinationProps {
  onEdit: Function;
  onDelete: Function;
  title: string;
  img: string;
  isActive: boolean;
}

export interface ICardDestinationState { }

export default class CardDestination extends React.Component<ICardDestinationProps, ICardDestinationState> {
  constructor(props: ICardDestinationProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { onEdit, onDelete, title, img, isActive } = this.props;
    return (
      <CardDestinationStyle>
        <div className="card-destination-img">
          <img src={img} alt="" />
        </div>
        {isActive && (
          <div className="card-destination-check">
            <img src={images.stateSuccess} alt="" />
          </div>
        )}
        <div className="card-destination-actions">
          <div className="card-destination-actions__title">
            <SubheadDestacadoText>{title}</SubheadDestacadoText>
          </div>
          <div className="card-destination-actions__edit">
            <SingleButton className="secondary small" icon={images.editSvg} onClick={() => onEdit()} />
          </div>

          <div className="card-destination-actions__delete">
            <SingleButton className="secondary small" icon={images.delete2RedSvg} onClick={() => onDelete()} />
          </div>
        </div>
      </CardDestinationStyle>
    );
  }
}
