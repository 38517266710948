import styled from 'styled-components';
import { colors } from '../../assets/colors';
export const BuyResumeStyle = styled.div`
  padding: 48px 32px;
  background: ${colors['NE-002/10']};

  .buy-resume-title {
    margin-bottom: 6px;
  }
  .buy-resume-detail {
    margin-bottom: 32px;
    color: ${colors['PR-001/100']};
  }
  .buy-resume-activity {
    margin-bottom: 40px;
    display: flex;
    &__img {
      min-width: 72px;
      width: 72px;
      min-height: 72px;
      height: 72px;
      margin-right: 20px;
      overflow: hidden;
      border-radius: 5px;
      img {
        width: 100%;
        min-height: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
  .buy-resume-item {
    display: flex;
    margin-bottom: 20px;
    .buy-resume-item-product {
      flex: auto;
      &__title {
         margin-bottom: 4px;
      }
    }
    .buy-resume-item-amount {
      margin-right: 40px;
    }
    .buy-resume-item-price {
    }
  }

  .buy-resume-bonus {
    border-bottom: 0.5px solid #bcc5d7;
    padding: 0 0 20px 0;
    color: ${colors['NE-002/100']};
    margin-bottom: 20px;
  }
  .buy-resume-resume {
    border-bottom: 0.5px solid #bcc5d7;
    padding: 0 0 20px 0;
    margin-bottom: 20px;
    &__item {
      display: flex;
      justify-content: space-between;
    }
  }

  }
  .buy-resume-total {
    display : flex;
    justify-content: space-between;
  }
`;
