import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CardDiverActivityStyle = styled.div`
  background: ${colors['NE-002/10']};
  border-radius: 5px;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;

  .card-diver-activity-left {
    &__state {
      color: ${colors['NE-002/100']};
    }
    &__title {
      color: ${colors['NE-001/100']};
      margin-bottom: 2px;
    }
    &__location {
      color: ${colors['NE-001/50']};
    }
  }

  .card-diver-activity-right {
    display: flex;
    text-transform: uppercase;
    &__item {
      text-align: center;
      margin-right: 24px;
      &__title  {
        color: ${colors['NE-001/100']};
        margin-bottom: 14px;
      }
      &__subtitle  {
        color: ${colors['SE-002/100']};
      }
    }
  }

  &.done {
    .card-diver-activity-left {
      &__state {
        color: ${colors['CO-001/100']};
      }
    }
  }
  &.registered {
    .card-diver-activity-left {
      &__state {
        color: ${colors['CO-001/100']};
      }
    }
  }
  &.pending {
    background: ${colors['NE-002/10']};
    .card-diver-activity-left {
      &__state {
        color: ${colors['CO-004/100']};
      }
    }
  }
  &.unrealized  {
    background: ${colors['NE-003/20']};
    .card-diver-activity-left {
      &__state {
        color: ${colors['CO-003/100']};
      }
      &__title {
        color: ${colors['NE-003/100']};
        margin-bottom: 2px;
      }
      &__location {
        color: ${colors['NE-003/100']};
      }
    }
  }
`;
