import * as React from 'react';
import { CreateActivitiesIncludedEquipmentStyle } from './create-activities-included-equipment-style';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import InfoText from '../../../../../components/text/info-text/info-text';
import { images } from '../../../../../assets/images';
import { IDropdown, IId } from '../../../../../models/generic';
import MainButton from '../../../../../components/main-button';
import SingleButton from '../../../../../components/single-button/single-button';
import { getStock } from '../../../../../services/centre';

export interface ICreateActivitiesIncludedEquipmentProps {
  stock?: IId[];
  onPrev?: () => void;
  onNext: (equipment: IId[]) => void;
  centreId: string;
}

export interface ICreateActivitiesIncludedEquipmentState {
  equipmentList: IDropdown[];
  equipments: number[];
}

export default class CreateActivitiesIncludedEquipment extends React.Component<
  ICreateActivitiesIncludedEquipmentProps,
  ICreateActivitiesIncludedEquipmentState
  > {
  constructor(props: ICreateActivitiesIncludedEquipmentProps) {
    super(props);

    this.state = {
      equipmentList: [],
      equipments: [],
    };
  }

  componentDidMount() {
    this.getCentreEquipment();
  }

  componentDidUpdate(prevProps: ICreateActivitiesIncludedEquipmentProps) {
    const { stock } = this.props;
    if (prevProps.stock !== stock) {
      this.setStock();
    }
  }

  setStock() {
    const { stock } = this.props;
    if (stock) {
      this.setState({ equipments: stock.map(st => st.id) });
    }
  }

  async getCentreEquipment() {
    const { centreId } = this.props;
    const resp = await getStock(centreId);
    const equipmentList = resp.data.data.map((service: any) => {
      return { value: service.id.toString(), label: service.name };
    });
    this.setState({ equipmentList }, () => this.setStock());
  }

  handleCheck(checked: boolean, serviceId: string) {
    const { equipments } = Object.assign(this.state);
    const service = parseInt(serviceId, undefined);
    if (checked && !equipments.includes(service)) {
      equipments.push(service);
      this.setState({ equipments });
    } else if (!checked && equipments.includes(service)) {
      this.setState({ equipments: equipments.filter((serv: number) => serv !== service) });
    }
  }

  handleNext() {
    const { onNext } = this.props;
    const { equipments } = this.state;
    onNext(
      equipments.map(equipment => {
        return { id: equipment };
      })
    );
  }

  render() {
    const { onPrev } = this.props;
    const { equipmentList, equipments } = this.state;
    return (
      <CreateActivitiesIncludedEquipmentStyle>
        <div className="create-activities-wrapper">
          {equipmentList.map((equipment: IDropdown, index: number) => {
            return (
              <DicotomicCheckbox
                key={index}
                checked={equipments.includes(parseInt(equipment.value, undefined))}
                onCheck={checked => this.handleCheck(checked, equipment.value)}
              >
                <div className="create-activities-dicotomic-container">
                  <div className="create-activities-dicotomic-container__icon">
                    <img src={images.seaSvg} alt="" />
                  </div>
                  <div className="create-activities-dicotomic-container__text">
                    <InfoText>{equipment.label}</InfoText>
                  </div>
                </div>
              </DicotomicCheckbox>
            );
          })}
        </div>

        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}

            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={onPrev ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesIncludedEquipmentStyle>
    );
  }
}
