import React from 'react';
import { SubtitleTextContainer } from './subtitle-text-styles';

interface SubtitleTextProps {
  children: any;
  className?: string;
  color?: string;
}

const SubtitleText = ({ children = '', className = '', color = '' }: SubtitleTextProps) => {
  return (
    <SubtitleTextContainer color={color} className={className}>
      {children}
    </SubtitleTextContainer>
  );
};
export default SubtitleText;
