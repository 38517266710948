import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const StatsLayoutStyle = styled.div`
  .stats-lines {
    display: flex;
    margin-bottom: 160px;
    .stats-lines-cards {
      width: 290px;
      margin-right: 100px;
      &__item {
        margin-bottom: 24px;
      }
      &__title {
        margin-bottom: 24px;
      }
      &__gap {
        margin-bottom: 60px;
      }
    }
    .stats-lines-graph {
      flex: auto;
    }

    .stats-lines-graph-filters {
      display: flex;
      justify-content: space-between;
      &__left {
        width: 180px;
        min-height: 60px;
      }
      &__right {
        display: flex;
        align-items: flex-start;
        padding-top: 14px;
        &__item {
          margin-left: 28px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &.active {
            border-bottom: 1px solid ${colors['PR-001/100']};
            color: ${colors['PR-001/100']};
          }
        }
      }
    }
  }
  .stats-bar-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 82px;
    margin-bottom: 80px;
  }
  .stats-bar {
    border: 0.5px solid #bcc5d7;
    border-radius: 5px;
    padding: 54px 40px 40px;

    .stats-bar-cards {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      &__filter {
        width: 180px;
      }
      &__info {
        &__number  {
          color: ${colors['SE-002/100']};
        }
        &__title {
          margin-bottom: 2px;
        }
      }
    }
    .stats-bar-graph {
      flex: auto;
    }
  }

  .stats-lines-graph-draw {
  }
  .stats-lines-graph-draw-filters {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 6px;
    &__item {
      margin-left: 26px;
      &__text {
        padding-left: 14px;
        position: relative;
        &.blue {
          &::before {
            background: ${colors['SE-005/100']};
          }
        }
        &.red {
          &::before {
            background: ${colors['CO-004/100']};
          }
        }
        &.light-blue {
          &::before {
            background: ${colors['SE-002/100']};
          }
        }
        &::before {
          content: '';
          border-radius: 100%;
          width: 8px;
          height: 8px;
          position: absolute;
          left: 0;
          top: 6px;
        }
      }
    }
  }
  .stats-bar-resume  {
    margin-bottom: 60px;
    &__title {
      margin-bottom: 24px;
    }
  }
`;
