interface Column {
  Header: any;
  columns?: Column[];
  getHeaderProps: () => {
    style: object;
  };
  id: string | number;
  parent?: Column;
  sticky?: 'left' | 'right';
  totalLeft: number;
}

const getStickyValue = (column: Column): null | 'left' | 'right' => {
  if (column.sticky === 'left' || column.sticky === 'right') {
    return column.sticky;
  }

  if (column.parent) {
    return getStickyValue(column.parent);
  }

  return null;
};

// const getMarginRight = (columnId: Column["id"], columns: any) => {
//   const currentIndex = columns.findIndex(({ id }: any) => id === columnId);
//   let rightMargin = 0;
//   for (let i = currentIndex + 1; i < columns.length; i += 1) {
//     if (columns[i].isVisible !== false) {
//       rightMargin += columns[i].width;
//     }
//   }

//   return rightMargin;
// };
// @ts-ignore
const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
const cellStylesSticky = {
  position: isEdge ? 'flex' : 'sticky',
  top: 0,
  zIndex: 1,
};

// function findHeadersSameLevel(header: any, headers: any) {
//   if (!header.parent) {
//     return headers;
//   }

//   const children = headers
//     .map((_: any) => _.headers)
//     .reduce((a: any, b: any) => [...a, ...b], []);

//   return children;
// }

const getStickyProps = (header: any, instance: any) => {
  let style = {};

  const sticky = getStickyValue(header);

  if (sticky) {
    style = {
      ...cellStylesSticky,
    };

    // const headers = findHeadersSameLevel(header, instance.headers);

    const margin = sticky === 'left' ? header.totalLeft : 0;

    style = {
      ...style,
      [sticky]: `${margin}px`,
    };
  }

  return {
    style,
  };
};

export const useSticky = (hooks: any) => {
  hooks.getHeaderProps.push((props: any, { instance, column }: any) => {
    const nextProps = getStickyProps(column, instance);
    return [props, nextProps];
  });

  hooks.getCellProps.push((props: any, { instance, cell }: any) => {
    const nextProps = getStickyProps(cell.column, instance);
    return [props, nextProps];
  });
};
