import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ItemFeatureCenterStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px;
  justify-content: space-between;
  .item-feautres-center-container {
    align-items: center;
    display: flex;
    &__icon {
      margin-right: 18px;
      width: 24px;
      height: 24px;
    }
    &__text {
      margin-right: 18px;
      color: ${colors['SE-002/100']};
    }
  }

  .item-feautres-center-container-delete {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
