import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const OrganizationMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(1, 66, 81, 1);
  width: 224px;
  /* width: 204px; */
  height: 100vh;
  max-height: 100vh;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  .menu-top {
    display: flex;
    flex-direction: column;
    background-color: rgba(1, 66, 81, 1);
  }
  .menu-superadmin {
  }
  .menu-back {
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    /* border-bottom: 1px solid white; */
    margin: 0 24px;
    padding-bottom: 35px;
    margin-bottom: 32px;
    .menu-back-button {
      display: flex;
      opacity: 0.6;
      transition: all 0.3s ease-in-out;
      color: #b3e3e4;

      &:hover {
        opacity: 1;
      }
      &__arrow {
        margin-right: 8px;
        min-width: 16px;
        min-height: 16px;
        margin-top: 3px;
      }
    }
  }

  .menu-logo {
    width: 120px;
    margin: 37px 60px 20px 27px;
  }
  .menu-items {
    width: 100%;
    padding: 0 10px 0 13px;
    background: rgba(1, 66, 81, 1);
    &__button {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .menu-bottom {
    margin: 0 24px 36px;
    border-top: 1px solid white;
    padding-top: 20px;
    background-color: rgba(1, 66, 81, 0.9);
    &__profile {
      margin-bottom: 10px;
    }
    &__logout {
    }
  }

  .menu-back-superadmin {
    color: white;
    padding: 12px;
    background: rgb(0, 161, 165, 0.3);
    border-radius: 5px;
    text-transform: initial;
    margin-top: 18px;
    cursor: pointer;
    &__text {
    }
    &__back {
      color: ${colors['SE-001/100']};
    }
  }

  .track-vertical {
    min-width: 2px;
    width: 2px;
    padding-left: 3px;
    height: 100%;
    right: 4px;
  }

  .thumb-vertical {
    background: #bcc5d7 !important;
    border-radius: 0px;
  }
`;
