import React, { Component } from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import MainTable from '../../../components/main-table/main-table';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import { images } from '../../../assets/images';
import Pagination from '../../../components/pagination';
import { DivingCenterLayoutContainer } from './../diving-center-style';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import ModalSuccess from '../../../components/modals/modal-success/modal-success';
import { tableColumns, rowKeys, Company } from './types';
import { TableRow, TableCell } from '../../../types/table';
import Can from '../../../roles/can-component';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import ModalCreateCentre from '../create-edit/modal-centre-form';
import moment from 'moment';
import 'moment/locale/es.js';
import { NavigationTabContainer } from '../../../components/navigation-tabs/navigation-tabs-style';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import MainButton from '../../../components/main-button';
import SelectBox from '../../../components/select-box';
import ModalInfo from '../../../components/modals/modal-info/modal-info';
import ModalInvite from '../../../components/modals/modal-invite/modal-invite';
import CalendarBox from '../../../components/calendar-box';
import services from '../../../services/services';
import { requestInfo, declineCentre, validateCentre, resendConfirmationEmail } from '../../../services/centre';
import { withTranslation, WithTranslation, useTranslation } from 'react-i18next';
import { CENTRE_TYPES } from '../../../constants/centres';
import { STATE_DROPDOWN } from '../../../constants/states';
import { IFilter } from '../../../models/filter';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { sendEmail } from '../../../services/register';
interface CentresLayoutProps extends WithTranslation, RouteComponentProps {
  agent?: any;
  centres: Company[];
  totalPages: number;
  page: number;
  checked: boolean;
  totalCentres: number;
  validateCentres: (validateRequestBody: string) => void;
  rejectCentres: (validateRequestBody: string) => void;
  setPage: (page: number) => void;
  refreshCentres: () => void;
  deleteCentre: (id: string) => void;
  onChangeFilter: (filters: { [key: string]: IFilter }) => void;
  onCheck: (rows: any[], all: boolean, allValue?: boolean | undefined) => void;
}
interface CentresLayoutState {
  renderModal: string;
  editID?: string;
  section: string;
  activeFilter: boolean;
  infoRequestBody: string;
  declineRequestBody: string;
  validateRequestBody: string;
  filters: { [key: string]: IFilter };
}

class CentresSuperAdminLayout extends Component<CentresLayoutProps, CentresLayoutState> {
  state: CentresLayoutState = {
    renderModal: '',
    section: 'integrated',
    activeFilter: false,
    infoRequestBody: '',
    declineRequestBody: '',
    validateRequestBody: '',
    filters: {
      confirmed: { value: true },
    },
  };

  resendEmail = async (centreId: string) => {
    const { t } = this.props
    try {
      const res = await resendConfirmationEmail(centreId);
      services.pushNotification({
        type: 'green',
        title: t('modalMessages:success-title'),
        text: t('modalMessages:resend'),
      });
    } catch (err) {
      services.pushNotification({
        type: 'red',
        title: t('modalMessages:error'),
        text: t('modalMessages:somerror'),
      });
    }
  };

  formatDataToTableRows(data: { [key: string]: any }[]) {

    const { section } = this.state;
    const { t } = this.props;
    const filteredData = data;
    return filteredData.map(item => {
      const row: TableRow = {};

      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };
        switch (key) {
          case 'name':
            cell.value = item[key];
            cell.key = 'highlighted-body';
            break;
          case 'centreId':
            cell.value = item[key];
            cell.key = 'centreId';
            break;
          case 'check':
            cell.value = item[key];
            cell.key = 'check';
            break;
          case 'count':
            cell.value = item[key];
            cell.key = 'count';
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });
      if (!item.validated && !item.rejectedBy && section === 'integrated') {
        row.check = {
          value: item.check,
          key: 'check',
          type: '',
        }
      }
      if (item.confirmed && item.validated) {
        row.options = {
          value: item.id,
          type: '',
          key: 'options',
          actions: [
            {
              label: t('modalMessages:access'),
              icon: images.loginSvg,
              onClick: () => {
                this.props.history.push(`/centres/${item.id}/planning/calendar`);
                localStorage.setItem('centreId', item.id);
              },
            },
            {
              label: t('modalMessages:edit'),
              icon: images.editSvg,
              onClick: () => {
                this.setState({ renderModal: 'modal-center-form', editID: item.id });
              },
            },
            {
              label: t('buttons:delete'),
              icon: images.deleteSvg,
              onClick: () => {
                this.setState({ renderModal: 'modal-delete', editID: item.id });
              },
            },
          ],
        };
      } else if (item.confirmed && !item.rejectedBy) {
        row.options = {
          value: item.id,
          type: '',
          key: 'options',
          actions: [
            {
              label: t('modalMessages:access'),
              icon: images.loginSvg,
              onClick: () => {
                this.props.history.push(`/centres/${item.id}/business/documentation`);
              },
            },
            {
              label: t('modalMessages:edit'),
              icon: images.editSvg,
              onClick: () => {
                this.setState({ renderModal: 'modal-center-form', editID: item.id });
              },
            },
            {
              label: t('buttons:validate'),
              icon: images.checkCircleSvg,
              onClick: () => {
                this.setState({ renderModal: 'modal-validate', editID: item.id });
              },
            },
            {
              label: t('buttons:solinfo'),
              icon: images.alertSvg,
              onClick: () => {
                this.setState({ renderModal: 'modal-info', editID: item.id });
              },
            },
            {
              label: t('buttons:reject'),
              icon: images.closeCircleSvg,
              onClick: () => {
                this.setState({ renderModal: 'modal-decline', editID: item.id });
              },
            },
            {
              label: t('buttons:delete'),
              icon: images.deleteSvg,
              onClick: () => {
                this.setState({ renderModal: 'modal-delete', editID: item.id });
              },
            },
          ],
        };
      } else {
        row.options = {
          value: item.id,
          type: '',
          key: 'options',
          actions: [
            {
              label: t('buttons:edit'),
              icon: images.editSvg,
              onClick: () => {
                this.setState({ renderModal: 'modal-center-form', editID: item.id });
              },
            },
            {
              label:  t('buttons:reinv'),
              icon: images.mailSvg,
              onClick: () => {
                this.resendEmail(item.id);
              },
            },

            {
              label:  t('buttons:delete'),
              icon: images.deleteSvg,
              onClick: () => {
                this.setState({ renderModal: 'modal-delete', editID: item.id });
              },
            },
          ],
        };
      }

      const validated = !!item.rejectedBy
        ? 'reject'
        : section === 'no-integrated'
          ? 'reject'
          : item.validated
            ? 'success'
            : 'pending';
      row.validated = {
        value: validated,
        type: '',
        key: validated,
      };

      const cert = item.certifiers[0] ? item.certifiers[0] : '';
      row['certifier'] = {
        value: cert.certifierName || '-',
        type: '',
        key: '',
      };
      if (item.certifiers.length > 1) {
        row['count'] = {
          value: {
            title: `(${cert.certifierName})`,
            items: item.certifiers.map((cert: any) => {
              return `${cert.certifierName} ${cert.ownId ? `(${cert.ownId})` : ''}`;
            }),
          },
          type: '',
          key: 'count',
        };
      }

      /*row['centreId'] = {
        value: cert.ownId || '-',
        type: '',
        key: 'centreId',
      };*/

      row['register-date'] = {
        value: moment(item.createdAt)
          .utc()
          .format('DD/MM/YYYY'),
        type: '',
        key: '',
      };
      return row;
    });
  }

  async handleClickInfoRequest() {
    const { infoRequestBody, editID } = this.state;
    const { t } = this.props
    if (infoRequestBody && editID && infoRequestBody !== '') {
      await requestInfo(editID, infoRequestBody);
      services.pushNotification({
        type: 'green',
        title:  t('modalMessages:success-title'),
        text: t('modalMessages:confirmemail'),
      });
      this.setState({ renderModal: '', editID: '', infoRequestBody: '' });
    }
  }

  async handleClickDeclineRequest(declineRequestBody: string) {
    const { editID } = this.state;
    const { refreshCentres, t } = this.props;
    if (editID) {
      await declineCentre(editID, declineRequestBody);
      services.pushNotification({
        type: 'red',
        title: t('modalMessages:rej'),
        text: t('modalMessages:rejcentre'),
      });
      this.setState({ renderModal: '', editID: '', declineRequestBody: '', validateRequestBody: '' }, () => {
        refreshCentres();
      });
    }
  }

  async handleClickValidateRequest() {
    const { validateRequestBody, editID } = this.state;
    const { refreshCentres, t } = this.props;
    if (editID) {
      await validateCentre(editID, validateRequestBody);
      services.pushNotification({
        type: 'green',
        title: t('modalMessages:success-title'),
        text: t('modalMessages:valid'),
      });
      this.setState({ renderModal: '', editID: '', validateRequestBody: '' }, () => {
        refreshCentres();
      });
    }
  }

  handleSetFilter(key: string, value: any, method?: string) {
    const { onChangeFilter } = this.props;
    const { filters } = this.state;
    if (!filters[key]) filters[key] = { value: '' };
    filters[key].value = value;
    if (method) filters[key].method = method;
    onChangeFilter(filters);
    this.setState({ filters });
  }

  handleRemoveFilter(key: string) {
    const { onChangeFilter } = this.props;
    const { filters } = this.state;
    delete filters[key];
    onChangeFilter(filters);
    this.setState({ filters });
  }

  handleChangeSection(section: string) {
    const { filters } = this.state;
    const { onChangeFilter } = this.props;

    if (!filters['confirmed']) filters['confirmed'] = { value: section === 'integrated' };
    else filters['confirmed'].value = section === 'integrated';
    onChangeFilter(filters);
    this.setState({ section });
  }

  render() {
    const { renderModal, editID, section, activeFilter, declineRequestBody, validateRequestBody, filters } = this.state;
    const { agent, centres, totalPages, setPage, refreshCentres, deleteCentre, page, onCheck, checked, validateCentres, rejectCentres, totalCentres, t } = this.props
    return (
      <>
        {/* Header */}
        <HeaderMain
          placeholderSearch={t('layouts:config.config.center-search-placeholder')}
          title={'Gestión de empresas'}
          withSearch={true}
          resultCount={totalCentres}
          handleSearch={(value: string) => {
            if (value && value !== '') this.handleSetFilter('name', value);
            else this.handleRemoveFilter('name');
          }}
          mainButton={{
            img: !checked || section !== 'integrated' ? images.createWhiteSvg : undefined,
            text: section === 'integrated' ? checked ? 'Validar' : 'Crear' : 'Invitar', // "validar" cuando se marcan los check de la tabla
            onClick: () =>
              checked ? this.setState({ renderModal: 'modal-validate' }) :
                this.setState({ renderModal: section === 'integrated' ? 'modal-center-form' : 'modal-invite-center' }),
          }}
          secondaryButtonRed={checked && section === 'integrated' ? {
            text: 'Rechazar',
            onClick: () => this.setState({ renderModal: 'modal-decline' })
          } : undefined}
        />
        {/* Content */}
        <MainLayoutSection>
          <DivingCenterLayoutContainer>
            <div className="dive-center-head">
              <div className="dive-center-head__tabs">
                <NavigationTabContainer>
                  <div
                    className={`navigation-item ${section === 'integrated' && 'active'}`}
                    onClick={() => this.handleChangeSection('integrated')}
                  >
                    <BodyDestacadoText>{t('modalMessages:integrated')}</BodyDestacadoText>
                  </div>
                  <div
                    className={`navigation-item ${section === 'no-integrated' && 'active'}`}
                    onClick={() => this.handleChangeSection('no-integrated')}
                  >
                    <BodyDestacadoText>{t('modalMessages:nintegrated')}</BodyDestacadoText>
                  </div>
                </NavigationTabContainer>
              </div>
              <div className="dive-center-head__filter">
                <MainButton
                  onClick={() => this.setState({ activeFilter: !activeFilter })}
                  type={activeFilter ? 'secondary-active' : 'secondary'}
                  text={t("buttons:filter")}
                  iconImg={activeFilter ? images.filtersLightBlueSvg : images.filtersSvg}
                />
              </div>
            </div>

            <div className={`dive-center-filters${activeFilter ? ' active' : ''}`}>
              {/* <div className="dive-center-filters__item">
                <SelectBox
                  labelText=""
                  placeholder="Tipo de empresa"
                  className="rounded-filter"
                  optionsText={[{ value: '*', label: 'Todos' }].concat(CENTRE_TYPES)}
                  optionKey={'value'}
                  optionValue={'label'}
                  icon={images.arrowDown2BlueSvg}
                  onChange={(value, label) => {
                    if (value === '*') this.handleRemoveFilter('type');
                    else if (label) this.handleSetFilter('type', value);
                  }}
                />
              </div> */}
              {section === 'integrated' && (
                <div className="dive-center-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder={t("buttons:status")}
                    className="rounded-filter"
                    optionsText={[{ value: '*', label: 'Todos' }].concat(STATE_DROPDOWN.slice(0, 2))}
                    optionKey={'value'}
                    optionValue={'label'}
                    icon={images.arrowDown2BlueSvg}
                    onChange={(value, label) => {
                      if (value === '*') this.handleRemoveFilter('validated');
                      else {
                        switch (value) {
                          case 'pending':
                            this.handleSetFilter('validated', null, 'is');
                            break;
                          case 'success':
                            this.handleSetFilter('validated', null, 'is not');
                            break;
                        }
                      }
                    }}
                  />
                </div>
              )}
              <div className="dive-center-filters__item">
                <CalendarBox
                  placeholder={t("modalMessages:regdate")}
                  className="filter"
                  labelText=""
                  initialValue={''}
                  onChange={(date, error, secondvalue) => {
                    if (date && date !== '') {
                      this.handleSetFilter('createdAt', moment(date, 'DD/MM/YYYY'));
                    } else this.handleRemoveFilter('createdAt');
                  }}
                />
              </div>
            </div>

            <MainLayoutTableContainer>
              <Can roles={agent.roles} perform="companies:management">
                <div className="diving-center-table">
                  <MainTable
                    columns={section === 'integrated' ? tableColumns(t) : tableColumns(t).slice(1)}
                    rows={this.formatDataToTableRows(centres)}
                    rowKeys={section === 'integrated' ? rowKeys : rowKeys.slice(1)}
                    onOrder={() => { }}
                    onSelect={(rows: any[], all: boolean, allValue?: boolean | undefined) =>
                      onCheck(rows, all, allValue)}
                  />
                </div>
                {totalPages > 1 && (
                  <div className="diving-center-pagination">
                    <Pagination page={page} limit={totalPages} callback={page => setPage(page)} />
                  </div>
                )}
              </Can>
            </MainLayoutTableContainer>
          </DivingCenterLayoutContainer>
        </MainLayoutSection>

        {/* Company Form */}

        {renderModal === 'modal-center-form' && (
          <ModalCreateCentre
            showModal={renderModal === 'modal-center-form'}
            onAccept={() => {
              this.setState({ renderModal: '', editID: undefined }, () => {
                refreshCentres();
              });
            }}
            onCancel={() => this.setState({ renderModal: '', editID: undefined })}
            centreID={editID}
            editMode={!!editID}
          />
        )}

        {/* Modal Delete centre  */}

        {renderModal === 'modal-delete' && (
          <ModalDelete
            title={t("modalMessages:roles-delete.delete")}
            subtitle={t("modalMessages:roles-delete.confirm")}
            showModal={renderModal === 'modal-delete'}
            onRemove={async () => {
              if (!!editID) {
                await deleteCentre(editID);
                services.pushNotification({
                  text: t("modalMessages:roles-delete.centredel"),
                  title: t("modalMessages:roles-delete.delconfirm"),
                  type: 'red',
                });
                this.setState({ renderModal: '', editID: undefined }, () => {
                  refreshCentres();
                });
              }
            }}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}

        {/* Modal Success */}
        {renderModal === 'modal-success' && (
          <ModalSuccess
            title={t("modalMessages:success-title")}
            text={t("modalMessages:pendingcentre")}
            showModal={renderModal === 'modal-success'}
            onClose={() => this.setState({ renderModal: '' })}
          />
        )}

        {/* Modal Info Request */}
        {renderModal === 'modal-info' && (
          <ModalInfo
            showModal={renderModal === 'modal-info'}
            onClose={() => this.setState({ renderModal: '', infoRequestBody: '' })}
            icon={images.alertBlueSvg}
            title={[
              t('modalMessages:solinfo'),
              <br key="modal-info-request" />,
              <span key="modal-info-request">{t('modalMessages:acentre')}</span>,
              ', '+t('modalMessages:need'),
            ]}
            type={'blue'}
            withArea={true}
            requiredInput={true}
            buttons={[{ text: t('modalMessages:solicit'), type: 'primary', onClick: () => this.handleClickInfoRequest() }]}
            onChange={(val: any) => this.setState({ infoRequestBody: val })}
          />
        )}
        {/* Modal Reject Center */}
        {renderModal === 'modal-decline' && (
          <ModalInfo
            showModal={renderModal === 'modal-decline'}
            onClose={() => this.setState({ renderModal: '' })}
            icon={images.closeCircleRedSvg}
            title={checked ? [t('modalMessages:torej'),
              <span key="modal-reject-center">
                {t('modalMessages:divecen')}
            </span>,
              ', '+t('modalMessages:reason'),] : [
              t('modalMessages:torej'),
              <span key="modal-reject-center">
                {t('modalMessages:divec')}
              </span>,
              ', '+t('modalMessages:reason'),
            ]}
            type={'red'}
            requiredInput={true}
            withArea={true}
            buttons={[
              {
                text: t('buttons:reject'), type: 'error', onClick: () => checked ?
                  this.setState({ renderModal: '' }, () => rejectCentres(declineRequestBody)) :
                  this.handleClickDeclineRequest(declineRequestBody)
              },
            ]}
            onChange={(val: any) => this.setState({ declineRequestBody: val })}
          />
        )}
        {/* Modal Validate */}
        {renderModal === 'modal-validate' && (
          <ModalInfo
            showModal={renderModal === 'modal-validate'}
            onClose={() => this.setState({ renderModal: '' })}
            icon={images.checkCircleBlueSvg}
            title={[<span key="modal-validate-center">{checked ? t('modalMessages:valcent') : t('modalMessages:valcenters')}</span>]}
            type={'blue'}
            text={checked ?
              [t('modalMessages:confirmvalids')]
              : [
                t('modalMessages:confirmvalid'),
              ]}
            buttons={[
              {
                text: t('buttons:validate'), type: 'primary', onClick: () => checked ?
                  this.setState({ renderModal: '' }, () => validateCentres(validateRequestBody)) :
                  this.handleClickValidateRequest()
              },
              {
                text: t('buttons:reject'),
                type: 'secondary-red',
                onClick: () => checked ? this.setState({ renderModal: '' }, () => rejectCentres('*')) :
                  this.handleClickDeclineRequest(validateRequestBody),
              },
            ]}
            onChange={(val: any) => this.setState({ validateRequestBody: val })}
          />
        )}
        {/* Modal Invite / Resend  */}
        {renderModal === 'modal-invite-center' && (
          <ModalInvite
            onSuccess={() => {
              this.setState({ renderModal: '' });
              services.showModalSuccess(t('modalMessages:sent'), t('modalMessages:invite'));
            }}
            onClose={() => this.setState({ renderModal: '' })}
            showModal={renderModal === 'modal-invite-center'}
            title={t('modalMessages:invdiveapp')}
            text={[
              t('modalMessages:confinv'),
              t('modalMessages:invok'),
            ]}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default withTranslation(['intro', 'buttons', 'tables', 'modalMessages'])(
  connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(CentresSuperAdminLayout))
);
