import styled from 'styled-components';

const MiniModalCheckboxStyle = styled.div`
  .checkbox-list {
    min-width: 228px;
    display: block;
    padding: 12px 4px;

    &__options-bar {
      border-bottom: 1px solid #ebeef3;
      padding-bottom: 10px;
      & div {
        display: inline-block;
      }

      & + div {
        margin-top: 10px;
      }
    }
    &__count {
      display: inline-block;
      margin-right: 40px;
    }

    &__delete-btn {
      font-weight: 700;
      text-align: end;
      cursor: pointer;
    }

    &__item {
      display: flex;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      display: flex;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &-icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-text {
        margin-left: 8px;
      }
    }
  }

  &.active {
  }
`;

export default MiniModalCheckboxStyle;
