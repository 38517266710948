import styled from 'styled-components';
import { MainButtonContainer } from '../main-button/main-button-style';
import { colors } from '../../assets/colors';

export const DicotomicButtonContainer = styled.div`
  &.disabled {
    ${MainButtonContainer} {
      cursor: default;
      background-color: ${colors['NE-004-30']};
      color: ${colors['TX-001-30']};
    }
    .dicotomic-item {
      cursor: default;
      &.active {
        ${MainButtonContainer} {
          background-color: ${colors['TX-001-30']} !important;
          color: ${colors['NE-004-30']} !important;
        }
      }
    }
  }

  .dicotomic-button-label {
    color: green;
    margin-bottom: 10px;
  }

  .dicotomic-container {
    display: flex;
  }
  .dicotomic-item {
    width: 50%;
  }

  .dicotomic-item {
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &.active {
      ${MainButtonContainer} {
        background: ${colors['PR-001/100']};
        color: white;
      }
    }
  }

  ${MainButtonContainer} {
    background: ${colors['NE-002/10']};
    color: ${colors['NE-001/100']};
    padding: 0 16px;
    min-width: 100px;
    font-size: 12px;
  }

  &.dark-blue  {
    .dicotomic-item {
      padding: 0 12px;
      &.active {
        ${MainButtonContainer} {
          background: ${colors['SE-002/100']};
          color: white;
        }
      }
    }
  }

  .input-box-error {
    background: ${colors['CO-003/100']};
    padding-top: 3px;
  }

  &.modal {
    background: ${colors['NE-002/10']};
    .dicotomic-item {
      width: 100%;
      padding: 0;
    }
    ${MainButtonContainer} {
      min-width: 80px;
      width: 80px;
      height: 48px;
    }
  }
`;
