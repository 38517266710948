import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const MyCenterProfileFacilitiesStyle = styled.div`
  .my-center-profile-facilities-selected {
    font-size: 14px;
    line-height: 22px;
    color: ${colors['NE-001/100']};
    font-weight: 300;
    margin-bottom: 40px;
    span {
      color: ${colors['CO-003/100']};
      font-weight: 500;
    }
  }

  .my-center-profile-facilities-grid {
    display: flex;
    flex-wrap: wrap;

    &__item {
      width: 120px;
      height: 120px;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }
`;
