import { withRouter } from 'react-router';
import React, { useState } from 'react';
import ItemSectionDetail from '../../../../components/item-sections-detail/item-sections-detail';
import { images } from '../../../../assets/images';
import TextDestacadoText from '../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../components/text/text-text/text-text';
import { DiveDisciplinesKeyValues, DiveKindsKeyValues } from '../../../../constants/dive-dropdowns';
import { capitalize } from '../../../../utils/texts';
import { calculateActivityDuration, getUserLanguageComment } from '../../../../utils/other';
import { ActivityDetailGeneralStyle } from '../general/activity-detail-general-style';
import ModalContainer from '../../../../components/modals/modal-container';
import { ModalCreateActivitiesStyle } from '../../../dive-offers/activities/modal-create-activities/modal-create-activities-style';
import CreateActivitiesIncludedServices
  from '../../../dive-offers/activities/modal-create-activities/create-activities-included-services/create-activities-included-services';
import { IId } from '../../../../models/generic';
import { patchActivity, patchAddStaffActivity } from '../../../../services/activities';
import services from '../../../../services/services';
import CreateActivitiesBoats
  from '../../../dive-offers/activities/modal-create-activities/create-activities-boats/create-activities-boats';
import { IBoatsData } from '../../../../models/activity-template';
import PlanningStaff, { PlanningStaffState } from '../../modal-planning-activity/planning-staff/planning-staff';
import PlanningDiveSite from '../../modal-planning-activity/planning-dive-site/planning-dive-site';
import { CENTRE_SERVICES_KEY_VALUE } from '../../../../constants/centre-services';
import parser from 'html-react-parser';
import { getCourse } from '../../../../services/courses'

const ActivityDetailBooking = (props: any): JSX.Element => {
  const { activity, centreId, levelName } = props;
  const [renderModal, setRenderModal] = useState('');

  let duration: any;

  if (activity) {
    duration = calculateActivityDuration(activity.timeBlocks);
  }

  const onEditActivity = async (data: any) => {
    const { activity, onReload } = props;
    if (activity) {
      await patchActivity(activity.id.toString(), data);
      services.pushNotification({
        title: '¡Editado!',
        text: 'La actividad se ha editado con éxito',
        type: 'green',
      });
      setRenderModal('');
      onReload();
    }
  }

  const handleAdd = async (staffs: any) => {
    const { activity, onReload } = props;
    const stafs = [];
    if (activity && activity.staff_activities) {
      for (const s of staffs.staff) {
        const staffFound = activity.staff_activities.find((stf: any) => parseInt(stf.staff.id) === parseInt(s.staff));
        if (staffFound === undefined) {
          stafs.push(s);
        }
      }
    }

    if (activity) {

      await patchAddStaffActivity(activity.id.toString(), stafs);
      services.pushNotification({
        title: '¡Editado!',
        text: 'Los staffs se han editado con éxito',
        type: 'green',
      });
    }
    setRenderModal('')
    onReload();
  }

  const renderSites = (item: any, index: number) => {
    return (
      <div key={index}>
        <div style={{ marginBottom: '2em' }}>
          <p>{index + 1} - {item.name}</p>
        </div>
        <div className="activity-detail-general-double">
          <div className="activity-detail-general-double__title">
            <TextDestacadoText>Ubicación:</TextDestacadoText>
          </div>
          <div className="activity-detail-general-double__text">
            <TextText>{item.latitude} {item.longitude}</TextText>
          </div>
        </div>
        <div className="activity-detail-general-double">
          <div className="activity-detail-general-double__title">
            <TextDestacadoText>Punto de inmersión:</TextDestacadoText>
          </div>
          <div className="activity-detail-general-double__text">
            <TextText>{item.name}</TextText>
          </div>
        </div>
        <div className="activity-detail-general-double">
          <div className="activity-detail-general-double__title">
            <TextDestacadoText>Lugar:</TextDestacadoText>
          </div>
          <div className="activity-detail-general-double__text">
            <TextText>{item.state}</TextText>
          </div>
        </div>
        <div className="activity-detail-general-double">
          <div className="activity-detail-general-double__title">
            <TextDestacadoText>Profundidad mín - máx:</TextDestacadoText>
          </div>
          <div className="activity-detail-general-double__text">
            <TextText>{item.minDepth} - {item.maxDepth} m</TextText>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <ActivityDetailGeneralStyle>
        <div style={{ width: '90%' }}>
          {
            props.create ? ''
              : <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="Datos generales"
                  icon={images.dataGeneral}
                >
                  {/*
                    <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Nº mínimo buceadores:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity && activity.minDivers && activity.minDivers}</TextText>
                    </div>
                  </div>
                  

                  {activity && activity.maxDivers && (
                    <div className="activity-detail-general-double">
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>Nº máximo buceadores:</TextDestacadoText>
                      </div>
                      <div className="activity-detail-general-double__text">
                        <TextText>{activity.maxDivers}</TextText>
                      </div>
                    </div>
                  )}
                  */}
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Actividad:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      {activity && !activity.cancelled &&
                        <TextText><a href={activity && '../../planning/calendar/' + activity.id}><u>{activity && activity.name}</u></a></TextText>
                      }
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Duración:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{
                        duration &&
                        `${Math.floor(duration.hours)} horas y ${-Math.floor(
                          duration.minutes
                        )} minutos`}</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Disciplina:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity && DiveDisciplinesKeyValues[activity.discipline]}</TextText>
                    </div>
                  </div>
                  {activity && activity.kind && activity.kind.length > 0 && (
                    <div className="activity-detail-general-double">
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>Tipo de inmersión:</TextDestacadoText>
                      </div>
                      <div className="activity-detail-general-double__text">
                        <TextText>
                          {capitalize(
                            activity.kind
                              .map((kind: string) => {
                                return DiveKindsKeyValues[kind];
                              })
                              .toString()
                              .replace(/,/g, ', ')
                              .toLowerCase()
                          )}
                        </TextText>
                      </div>
                    </div>
                  )}
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Nº de inmersiones:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity && activity.diveCount}</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
              </div>
          }

          <div className="activity-detail-general-left__section">
            <ItemSectionDetail
              title="Requisitos mínimos"
              icon={images.minimunRequirementsSvg}
              onEdit={activity && activity.bookings.length == 0 ? () => setRenderModal('edit-services') : undefined}
            >

              <div className="activity-detail-general-double">
                <div className="activity-detail-general-double__title">
                  <TextDestacadoText>Edad mínima:</TextDestacadoText>
                </div>
                <div className="activity-detail-general-double__text">
                  <TextText>{activity && activity.minimumRequisites ? activity.minimumRequisites.age : ''} años</TextText>
                </div>
              </div>
              <div className="activity-detail-general-double">
                <div className="activity-detail-general-double__title">
                  <TextDestacadoText>Titulación requerida:</TextDestacadoText>
                </div>
                <div className="activity-detail-general-double__text">
                  <TextText>{levelName}</TextText>
                </div>
              </div>

              {activity && activity.minimumRequisites.requisites &&
                activity.minimumRequisites.requisites.findIndex((r: any) => r.requisite === 'medicalCertificate') >
                -1 && (
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Certificado médico:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>
                        {
                          activity.minimumRequisites.requisites.find((r: any) => r.requisite === 'medicalCertificate')
                            .data
                        }
                      </TextText>
                    </div>
                  </div>
                )}
              {activity && activity.minimumRequisites.requisites &&
                activity.minimumRequisites.requisites.findIndex((r: any) => r.requisite === 'diveCount') > -1 && (
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Nº mínimo inmersiones:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>
                        {activity.minimumRequisites.requisites.find((r: any) => r.requisite === 'diveCount').data}
                      </TextText>
                    </div>
                  </div>
                )}
              {activity && activity.minimumRequisites.requisites &&
                activity.minimumRequisites.requisites.findIndex((r: any) => r.requisite === 'speciality') > -1 && (
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Especialidad:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>
                        {activity.minimumRequisites.requisites.find((r: any) => r.requisite === 'speciality').data}
                      </TextText>
                    </div>
                  </div>
                )}
              {activity && activity.minimumRequisites.requisites &&
                activity.minimumRequisites.requisites.findIndex((r: any) => r.requisite === 'other') > -1 && (
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Requisito:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>
                        {activity.minimumRequisites.requisites.find((r: any) => r.requisite === 'other').data}
                      </TextText>
                    </div>
                  </div>
                )}
            </ItemSectionDetail>
          </div>








          <div className="activity-detail-general-left__section">
            <ItemSectionDetail
              title="Servicios"
              icon={images.servicesSvg}
              onEdit={activity && activity.bookings.length == 0 ? () => setRenderModal('edit-services') : undefined}
            >
              <div className="activity-detail-general-services">
                {activity && activity.services && activity.services.length > 0 &&
                  activity.services.map((serviceItem: any, index: number) => (
                    <div className="activity-detail-general-services__item" key={index}>
                      <div className="activity-detail-general-services__item__icon">
                        <img src={images.flagSvg} alt="" />
                      </div>
                      <div className="activity-detail-general-services__item__text">
                        <TextText>{CENTRE_SERVICES_KEY_VALUE[serviceItem.name]}</TextText>
                      </div>
                    </div>
                  ))}
              </div>
            </ItemSectionDetail>
          </div>
          <div className="activity-detail-general-left__section">
            <ItemSectionDetail
              title="Embarcaciones"
              icon={images.boatsSvg}
              onEdit={activity && activity.bookings.length == 0 ? () => setRenderModal('edit-boats') : undefined}
            >
              <div className="activity-detail-general-double">
                <div className="activity-detail-general-double__title">
                  <TextDestacadoText>Se necesita embarcación:</TextDestacadoText>
                </div>
                <div className="activity-detail-general-double__text">
                  <TextText>{activity && activity.needBoat ? 'Si' : 'No'}</TextText>
                </div>
              </div>
            </ItemSectionDetail>
          </div>
          <div className="activity-detail-general-left__section">
            <ItemSectionDetail
              title="Puntos de inmersión"
              icon={images.boatsSvg}
              onEdit={activity && activity.bookings.length == 0 ? () => setRenderModal('edit-sites') : undefined}
            >
              {
                activity && activity.sites.map((item: any, index: number) => {
                  return renderSites(item, index)
                })
              }
            </ItemSectionDetail>
          </div>

          <div className="activity-detail-general-left__section">
            <ItemSectionDetail
              title="Staff"
              icon={images.boatsSvg}
              onEdit={activity && activity.bookings.length == 0 ? () => setRenderModal('edit-staff') : undefined}
            >
              {
                activity && activity.staff_activities.map((item: any, index: number) => {
                  return (
                    <div className="activity-detail-general-double" key={index}>
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>Nombre:</TextDestacadoText>
                      </div>
                      <div className="activity-detail-general-double__text">
                        <TextText>{item.staff.user.name} {item.staff.user.surname}</TextText>
                      </div>
                    </div>
                  )
                })
              }
            </ItemSectionDetail>
          </div>

          <div className="activity-detail-general-left__section">
            <ItemSectionDetail
              title="Comentarios sobre la actividad"
              icon={images.message}
            >
              <p>{activity && activity.comments ? parser(getUserLanguageComment(activity.comments)) : ''}</p>
            </ItemSectionDetail>
          </div>

        </div>
        {activity && renderModal === 'edit-services' && (
          <ModalContainer
            title={'Editar servicios'}
            modalClose={() => setRenderModal('')}
            active={renderModal === 'edit-services'}
          >
            <ModalCreateActivitiesStyle>
              <CreateActivitiesIncludedServices
                services={activity.services.map((service: any) => ({ id: service.id }))}
                onNext={(services: IId[]) => {
                  onEditActivity({
                    services,
                  });
                }}
                centreId={centreId}
              />
            </ModalCreateActivitiesStyle>
          </ModalContainer>
        )}

        {activity && renderModal === 'edit-boats' && (
          <ModalContainer
            title={'Editar embarcaciones'}
            modalClose={() => setRenderModal('')}
            active={renderModal === 'edit-boats'}
          >
            <ModalCreateActivitiesStyle>
              <CreateActivitiesBoats
                boats={{
                  needBoat: activity.needBoat
                }}
                onNext={(boats: IBoatsData) => {
                  onEditActivity(boats);
                }}
              />
            </ModalCreateActivitiesStyle>
          </ModalContainer>
        )}

        {activity && renderModal === 'edit-sites' && (
          <ModalContainer
            title={'Editar inmersiones'}
            modalClose={() => setRenderModal('')}
            active={renderModal === 'edit-sites'}
          >
            <ModalCreateActivitiesStyle>
              <PlanningDiveSite
                diveSites={activity.sites.map((site: any) => site.id)}
                centreId={centreId}
                edit={true}
                onNext={(state: any, sites: IId[]) => {
                  onEditActivity({ sites });
                }}
              />
            </ModalCreateActivitiesStyle>
          </ModalContainer>
        )}

        {activity && renderModal === 'edit-staff' && (
          <ModalContainer
            title={'Editar staff'}
            modalClose={() => setRenderModal('')}
            active={renderModal === 'edit-staff'}
          >
            <PlanningStaff
              selectedStaffs={
                activity.staff_activities
                  ? activity.staff_activities.map((staffActivity: any) => staffActivity.staff.id.toString())
                  : undefined
              }
              centreId={centreId}
              edit={true}
              onNext={(state: PlanningStaffState, staffs: IId[], inmediateConfirmation: boolean) => {
                const staff = staffs.map(staff => ({ staff: +staff.id, confirmed: inmediateConfirmation }));
                handleAdd({ staff });
              }}
            />
          </ModalContainer>
        )}
      </ActivityDetailGeneralStyle>
    </>
  )
}

export default withRouter(ActivityDetailBooking);