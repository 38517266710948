import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const NotificationsDetailStyled = styled.div`
  .notifications-detail-header {
    border-bottom: 1px solid ${colors['NE-002/100']};
    margin-bottom: 48px;
  }
  .notifications-detail-header-top  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .notifications-detail-header-bottom {
    margin-bottom: 40px;
    color: ${colors['NE-002/100']};
  }
  .notifications-detail-header-left {
    display: flex;
    align-items: center;
    &__icon {
      img {
        width: 36px;
        height: 36px;
      }
      margin-right: 20px;
    }
    &__title  {
      margin-right: 32px;
    }
    &__type {
      background: #fff2ef;
      white-space: nowrap;
      align-self: start;
      border-radius: 3px;
      padding: 10px 16px;
      color: ${colors['CO-004/100']};
    }
  }
  .notifications-detail-header-right {
    display: flex;
    &__item {
      width: 40px;
      height: 40px;
      margin-left: 12px;
    }
  }

  .notifications-detail-text {
    margin-bottom: 60px;
    padding-left: 60px;
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .notifications-detail-cards {
    margin-bottom: 60px;
    padding-left: 60px;
    width: 780px;
    &__row {
      margin-bottom: 60px;
      display: flex;
      &__item {
        flex: auto;
        margin-right: 20px;
        &:last-child  {
          margin-right: 0px;
        }
      }
    }
  }
`;
