import React from 'react';
import { OopsContentStyle } from './oops-content-style';

interface IOopsContentProps {
  title?: string;
  body: string;
}

const OopsContent: React.FC<IOopsContentProps> = (props: IOopsContentProps) => {
  const { title, body } = props;
  return (
    <OopsContentStyle>
      <div className="oops-content-title">{title ? title : '¡Oops!'}</div>
      <div className="oops-content-body">
        <p>{body}</p>
      </div>
    </OopsContentStyle>
  );
};

export default OopsContent;
