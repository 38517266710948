import * as React from 'react';
import { MarineReserveDetailStyle } from './marine-reserve-detail-style';
import HeadDestacadoText from '../../../components/text/head-destacado-text/head-destacado-text';
import DiveSiteDetailMap from '../../../components/dive-site-detail-map/dive-site-detail-map';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import CardDiveDetail from '../../../components/card-dive-detail/card-dive-detail';
import { RouteComponentProps, withRouter } from 'react-router';
import { getOneMarineReserve } from '../../../services/marine-reserve';
import BodyText from '../../../components/text/body-text/body-text';
import { getDateQuery } from '../../../utils/time';
import { getActivities } from '../../../services/activities';

interface RouteParams {
  id: string;
}

export interface IMarineReserveDetailProps extends RouteComponentProps<RouteParams> {
  marineReserve?: any;
  agent?: any;
}

export interface IMarineReserveDetailState {
  marineReserve?: any;
  activities: [];
}

class MarineReserveDetail extends React.Component<IMarineReserveDetailProps, IMarineReserveDetailState> {
  state: IMarineReserveDetailState;

  constructor(props: IMarineReserveDetailProps) {
    super(props);
    this.state = {
      // marineReserve: {
      //   description: {},
      // },

      activities: [],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      if (this.props.marineReserve) {
        this.getMarineReserve(match.params.id);
      }
      else {
        this.getActivities(match.params.id);
      }

    }
  }

  getMarineReserve = async (id: string) => {
    try {
      const params = {};
      const response: any = await getOneMarineReserve(id, params);
      this.setState({ marineReserve: response.data });
    } catch (err) { }
  };

  getActivities = async (id: string) => {
    const params = {
      filter: {
        where: {
          date: { method: '>=', value: getDateQuery(new Date()) },
          id: { value: id, collection: 'reserve' },
        },
        limit: 15,
        relations: ['sites', 'template', 'sites.reserve'],
      },
    };

    const response: any = await getActivities(params);
    this.setState({ activities: response.data.data });
  };



  render() {
    const { activities } = this.state;
    const { marineReserve, agent } = this.props;
    return (
      <MarineReserveDetailStyle agent={agent}>
        <div className="dive-site-detail-hero">
          <DiveSiteDetailMap item={marineReserve} agent={agent} />
        </div>
        {/*  <div className="dive-site-detail-next">
          <div className="dive-site-detail-next__title">
            <HeadDestacadoText>Próximas actividades</HeadDestacadoText>
          </div>

          <div className="dive-site-detail-next-container">
            {activities.length > 0 ? (
              activities.map((activity: any) => <CardDiveDetail key={`activity-${activity.id}`} activity={activity} />)
            ) : (
                <BodyText>No hay próximas actividades</BodyText>
              )}
          </div>
        </div>
        <PopReserve /> */}
      </MarineReserveDetailStyle>
    );
  }
}

export default withRouter(MarineReserveDetail);
