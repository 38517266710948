import * as React from 'react';
import { DiverBonusesStyle } from './diver-bonuses-style';
import MainTableLayout from '../../../../components/main-table/main-table';
import { MainLayoutTableContainer } from '../../../main-layout-style';
import { getDiver } from '../../../../services/diver';
import { RouteComponentProps, match } from 'react-router';
import { copyOf } from '../../../../utils/other';
import { TICKET_DURATION_UNITS_KEY_VALUE_2 } from '../../../../constants/duration';
import { getCentreDiver, getCentreOneStaff } from '../../../../services/centre';

export interface IDiverBonusesLayoutProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
    staff: string;
  }>;
  isCentre: boolean;
  isOrganization: boolean;
}

export interface IDiverBonusesLayoutState {
  tickets: any[];
}

export default class DiverBonusesLayout extends React.Component<IDiverBonusesLayoutProps, IDiverBonusesLayoutState> {
  constructor(props: IDiverBonusesLayoutProps) {
    super(props);

    this.state = {
      tickets: [],
    };
  }

  componentDidMount() {
    this.getTickets();
  }

  async getTickets() {
    const {
      match: {
        params: { id, diver, staff },
      },
    } = this.props;
    const params = { filter: { relations: ['tickets'] } };
    let tickets: any;
    if (staff) tickets = (await getCentreOneStaff(id, staff, params)).data.tickets;
    else if (diver) tickets = (await getCentreDiver(id, diver, params)).data.tickets;
    else tickets = (await getDiver(id, params)).data.tickets;
    this.setState({ tickets });
  }

  getTableRows() {
    const { tickets } = this.state;
    const tableRows: any[] = [];
    tickets.forEach(ticket => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.name.value = ticket.ticket ? ticket.ticket.name : '-';
      tableRow.typology.value = '';
      tableRow.number.value = 0;
      tableRow.usage.value = ticket.uses;
      tableRow.share.value = ticket.ticket ? (ticket.ticket.sharedUsage ? 'Sí' : 'No') : '-';
      tableRow.expiration =
        ticket.ticket && ticket.ticket.duration ? TICKET_DURATION_UNITS_KEY_VALUE_2[ticket.ticket.duration] : '';
      tableRow.price = `${ticket.ticket.price}€`;
    });
    return tableRows;
  }

  render() {
    return (
      <DiverBonusesStyle>
        <MainLayoutTableContainer>
          <div className="diving-center-table">
            <MainTableLayout
              columns={this.tableColumns}
              rows={this.getTableRows()}
              rowKeys={this.rowKeys}
              onOrder={() => {}}
            />
          </div>
          {/* <div className="diving-center-pagination">
            <Pagination page={1} callback={() => {}} limit={22} />
          </div> */}
        </MainLayoutTableContainer>
      </DiverBonusesStyle>
    );
  }

  tableRowTemplate = {
    name: {
      key: 'highlighted-body',
      value: 'Bono especial iniciación ',
    },
    typology: {
      key: '',
      value: 'Buceo recreativo',
    },
    number: {
      key: 'count',
      value: 'Pasillo 3, estante 7',
    },
    usage: {
      key: '',
      value: '12 ',
    },
    share: {
      key: '',
      value: 'SI',
    },
    expiration: {
      key: '',
      value: '24/09/2022',
    },
    price: {
      key: '',
      value: '356,67€',
    },
    detail: {
      key: 'detail',
      value: '/centres/8/divers/10/bonus-detail',
    },
  };

  tableColumns = [
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Tipologia',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Usos restantes',
      filter: false,
      ascendent: false,
    },
    {
      text: 'Compartir',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Validez',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Precio',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  rowKeys = ['name', 'typology', 'number', 'usage', 'share', 'expiration', 'price', 'detail'];
}
