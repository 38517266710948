import * as React from 'react';
import ModalContainer from '../modal-container';
import { ModalInviteStyle } from './modal-invite-style';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../text/body-text/body-text';
import InputBox from '../../input-box';
import MainButton from '../../main-button';
import { inviteCentre } from '../../../services/centre';
import { IEmailInvitation } from '../../../models/generic';
import SelectBox from '../../select-box';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

export interface IModalInviteProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onClose: Function;
  onSuccess: Function;
  title: string;
  text: string[];
  t: any
}

export interface IModalInviteState {
  invitation: IEmailInvitation;
  errors: {
    email: string;
    name: string;
  };
}

class ModalInvite extends React.Component<IModalInviteProps, IModalInviteState> {
  constructor(props: IModalInviteProps) {
    super(props);

    this.state = {
      invitation: {
        email: '',
        language: 'es',
        name: '',
      },
      errors: {
        email: '',
        name: '',
      },
    };
  }

  async handleSubmit() {
    const { onSuccess } = this.props;
    const { invitation, errors } = this.state;
    errors['email'] = this.validate('email', invitation.email, true);
    this.setState({ errors });
    if (errors['email'] === '') {
      await inviteCentre(invitation);
      onSuccess();
    }
  }

  validate(field: string, value: string, required: boolean) {
    if (required && (!value || value === '')) {
      return 'required';
    }
    return '';
  }

  handleChange(field: string, value: string, required: boolean) {
    const { invitation, errors } = Object.assign(this.state);
    errors[field] = this.validate(field, value, required);
    invitation[field] = value;
    this.setState({ errors, invitation });
  }

  render() {
    const { showModal, onClose, title, text, t } = this.props;
    const { invitation, errors } = this.state;
    return (
      <ModalContainer active={showModal} modalClose={() => onClose()}>
        <ModalInviteStyle>
          <div className="modal-invite-pop">
            <div className="modal-invite-title">
              <SubheadDestacadoText>{title}</SubheadDestacadoText>
            </div>
            <div className="modal-invite-text">
              {text.map((item, index) => (
                <div className="modal-invite-text__item" key={index}>
                  <BodyText>{item}</BodyText>
                </div>
              ))}
            </div>
            <div className="modal-invite-form">
              <div className="modal-invite-form__item">
                <InputBox
                  placeholder={t('components:general.centre-name')}
                  className="rounded"
                  type="text"
                  value={invitation.name ? invitation.name : ''}
                  filledValue={''}
                  labelText=""
                  onChange={value => this.handleChange('name', value, false)}
                />
              </div>
              {/* <div className="modal-invite-form__item">
                <SelectBox
                  optionsText={[]}
                  optionKey={'value'}
                  optionValue={'label'}
                  labelText=""
                  onChange={() => {}}
                />
              </div> */}
              <div className="modal-invite-form__item">
                <InputBox
                  placeholder={t('components:general.email')}
                  className="rounded"
                  type="email"
                  value={invitation.email}
                  errorCode={errors.email}
                  filledValue={''}
                  labelText=""
                  onChange={value => this.handleChange('email', value, true)}
                />
              </div>
            </div>
            <div className="modal-invite-button">
              <MainButton text={t('components:actions.send')} onClick={() => this.handleSubmit()} type="primary" />
            </div>
          </div>
        </ModalInviteStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalInvite));
