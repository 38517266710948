export interface IGoogleAdministrativeLevel {
  longName: string;
  shortName: string;
}

export const getAdministrativeLevel1 = (result: any) => {
  return getInfo(result, 'administrative_area_level_1');
};

export const getTown = (result: any) => {
  let town = getInfo(result, 'locality');
  if (town.longName === '') {
    town = getInfo(result, 'administrative_area_level_2');
  }
  return town;
};

export const getCountry = (result: any) => {
  return getInfo(result, 'country');
};

export const getPostalCode = (result: any) => {
  return getInfo(result, 'postal_code');
};

const getInfo = (result: any, level: string) => {
  const returnObject: IGoogleAdministrativeLevel = {
    longName: '',
    shortName: '',
  };
  result.address_components.forEach((addressComponent: any) => {
    if (addressComponent.types.includes(level)) {
      returnObject.longName = addressComponent.long_name;
      returnObject.shortName = addressComponent.short_name;
    }
  });
  return returnObject;
};
