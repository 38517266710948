import * as React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import crypto from 'crypto';
import { match, RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { STRIPE_CLIENT_ID } from '../../../env';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import { BillingInformationStyle } from './billing-information-style';
import MainButton from '../../../components/main-button';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, FormGrid, FormGridRow, FormGridItem } from '../../main-layout-style';
import { images } from '../../../assets/images';
import ModalSuccess from '../../../components/modals/modal-success/modal-success';
import IStoreState from '../../../redux/store/IStoreState';
import { setCentreStripeUserId } from '../../../redux/actions';
import services from '../../../services/services';
import { getCentreVat, postCentreVat, deleteCentreVat } from '../../../services/centre';
import { getOrganization } from '../../../services/collaborator';
import ModalInfo from '../../../components/modals/modal-info/modal-info';
import InputBox from '../../../components/input-box';
import { getCentre, editCentre } from '../../../services/centre';
import SelectBox from '../../../components/select-box';
import { getAdministrativeLevel1, getTown, getCountry } from '../../../utils/maps';
import { getDataFromAddress } from '../../../services/google-maps';
import { IDropdown } from '../../../models/generic';
import _ from 'lodash';

interface IBillingInformationProps extends RouteComponentProps, WithTranslation {
  centreId: string | null;
  roles?: string;
  centreStripeUserId?: string;
}

interface IBillingInformationState {
  renderModal: string;
  stripeLoading: boolean;
  csrfToken: string;
  vat: any;
  isOrganization: boolean;
  organizationHasStripe: boolean;
  centre: any;
  places: IDropdown[];
  errors: {
    [key: string]: string;
  };
}

class BillingInformation extends React.Component<IBillingInformationProps, IBillingInformationState> {
  constructor(props: IBillingInformationProps) {
    super(props);

    this.state = {
      renderModal: '',
      stripeLoading: true,
      csrfToken: '',
      vat: null,
      isOrganization: this.props.location.pathname.includes('organization') || !!localStorage.getItem('organizationId'),
      organizationHasStripe: false,
      centre: '',
      places: [],
      errors: {}
    };
  }

  completeStripeConnection = async (id: string, code: any) => {
    const { isOrganization } = this.state;
    const args = {
      endpoint: `${isOrganization ? 'organizations' : 'centres'}/${id}/stripe-register`,
      data: { code },
      then: (responseData: any) => {
        if (!isOrganization) {
          // aqui se deberia enviar la notificación al admin
          services.setValue(setCentreStripeUserId(responseData.data.stripeAccountId));
          this.setState({ stripeLoading: false });
        } else {
          this.setState({ stripeLoading: false, organizationHasStripe: !!responseData.data.stripeAccountId });
        }
        this.props.history.replace(location.pathname);
      },
      catch: (e: any) => {
        this.props.history.replace(this.props.location.pathname);
        this.setState({ stripeLoading: false });
      },
    };
    await services.post(args);
  };

  onDeleteVat = async () => {
    const { centreId, t } = this.props;
    let isSuperAdmin = false
    if (this.props.roles) isSuperAdmin = this.props.roles.includes('superAdmin');
    if (!centreId || !isSuperAdmin) {
      this.setState({ renderModal: '' });
      return;
    }
    try {
      await deleteCentreVat(centreId);
      services.pushNotification({
        type: 'red',
        title: t('push:reject-vat-certificate.title'),
        text: t('push:reject-vat-certificate.text'),
      });
      this.setState({ vat: null, renderModal: '' });
    } catch (e) {
      console.log(e);
    }
  };

  onSubmitCreateVat = async (data: any) => {
    const { centreId, location } = this.props;
    if (!centreId || !data) return;
    try {
      const { data: vat } = await postCentreVat(centreId, data);
      this.setState({ vat, renderModal: 'success' });
    } catch (e) { }
  };

  initStripe = async () => {
    const { location, centreId, centreStripeUserId } = this.props;
    const { isOrganization, organizationHasStripe } = this.state;
    const organizationId = localStorage.getItem('organizationId');

    if (!isOrganization && !centreId) {
      return;
    }
    if (isOrganization && !organizationId) {
      return;
    }

    if ((!isOrganization && centreStripeUserId) || (isOrganization && organizationHasStripe)) {
      this.setState({ stripeLoading: false });
      return;
    }

    const { code, error, error_description, state } = queryString.parse(location.search);

    const crsf = localStorage.getItem('stripeToken');

    if (error && error_description && state === crsf && crsf !== '') {
      // this.setState({ stripeLoading: false });
      this.props.history.replace(location.pathname);
      services.pushNotification({
        title: 'Error',
        text: error_description.toString(),
        type: 'red-warning',
      });
      this.setState({ stripeLoading: false });
      return;
    }

    if (state === crsf && crsf !== '' && code) {
      localStorage.removeItem('stripeToken');
      if (!isOrganization && centreId) this.completeStripeConnection(centreId, code);
      else if (isOrganization && organizationId) this.completeStripeConnection(organizationId, code);
      return;
    }

    if (state !== crsf && crsf !== '' && state) {
      this.props.history.replace(location.pathname);
      this.setState({ stripeLoading: false });
      services.pushNotification({
        title: this.props.t('push:error-connecting-stripe.title'),
        text: this.props.t('push:error-connecting-stripe.text'),
        type: 'red-warning',
      });
    }
    this.setState({ stripeLoading: false });
  };

  fetchVat = async () => {
    const { centreId } = this.props;
    if (!centreId) {
      return;
    }
    const { data } = await getCentreVat(centreId);
    if (data.hasVat !== undefined) {
      this.setState({ vat: data });
    }
  };

  fetchOrganizationStripe = async () => {
    const organizationId = localStorage.getItem('organizationId');
    if (!organizationId) return;
    const { data } = await getOrganization(organizationId);
    this.setState({ organizationHasStripe: !!data.stripeAccountId });
  };

  componentDidMount() {
    this.initStripe();
    if (!this.state.isOrganization) this.fetchVat();
    else {
      this.fetchOrganizationStripe();
    }
    this.getMyCenterProfile();
  }

  async getMyCenterProfile() {
    const { centreId } = this.props;
    if (centreId) {
      const centre = (await getCentre(centreId)).data;
      this.setState({ centre });
    }
  }

  async saveInformation() {
    const { centreId, t } = this.props;
    const { centre } = this.state;

    if (centreId) {
      const formattedCertifiers = centre.certifiers.map((cer: any) => ({
        ...cer,
        certifier: cer.certifier ? cer.certifier.id : -1,
      }));
      await editCentre(centreId, { ...centre, certifiers: formattedCertifiers });
      services.pushNotification({
        title: t('layouts:billing-information.saved'),
        text: t('layouts:billing-information.savedok'),
        type: 'green',
      });
    }
  }

  onConnectStripeHandler = () => {
    const { isOrganization, organizationHasStripe } = this.state;
    if (
      this.state.stripeLoading ||
      (this.props.centreStripeUserId && !isOrganization) ||
      (isOrganization && organizationHasStripe)
    ) {
      return;
    }
    const newCsrf = crypto.randomBytes(64).toString('hex');
    localStorage.setItem('stripeToken', newCsrf);
    location.assign(
      `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&state=${newCsrf}&redirect_uri=${window.location.href}`
    );
  };

  validateField(field: string, value: string, required: boolean) {
    const { errors } = this.state;
    errors[field] = '';
    if (required && (!value || value === '')) {
      errors[field] = 'required';
      this.setState({ errors });
      return false;
    }
    if (!required && (!value || value === '')) {
      this.setState({ errors });
      return true;
    }
    this.setState({ errors });
    return errors[field] === '';
  }


  handleChange(field: string, value: string, required: boolean = false) {
    const { centre } = Object.assign(this.state);
    if (field.indexOf('.') > -1) {
      _.update(centre, field, () => value);
    } else {
      centre[field] = value;
    }
    this.validateField(field, value, required);
    return new Promise((res, rej) => {
      this.setState({ centre }, () => res(''));
    });
  }

  searchTimeout: any;
  handleSearchAddress(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (value && value !== '') this.searchAddress(value);
    }, 300);
  }

  async searchAddress(value: string) {
    const resp: any = await getDataFromAddress(value);
    const results = resp.data.results;
    const places: any[] = results.map((result: any) => {
      const level1 = getAdministrativeLevel1(result);
      const town = getTown(result);
      const country = getCountry(result);
      return {
        level1,
        town,
        country,
        value: result.formatted_address,
        label: result.formatted_address,
      };
    });
    this.setState({ places });
  }


  render() {
    const { t, centreStripeUserId, roles } = this.props;
    const { renderModal, stripeLoading, vat, isOrganization, organizationHasStripe, centre, errors, places } = this.state;
    let isSuperAdmin = false;
    if (this.props.roles) isSuperAdmin = this.props.roles.includes('superAdmin');

    let addVatBtn;
    let vatInformation;
    if (!isOrganization) {
      if (!vat) {
        addVatBtn = (
          <MainButton
            className="billing-information-btn"
            type="primary"
            iconImg={images.createWhiteSvg}
            onClick={() => {
              this.setState({ renderModal: 'add-billing-data' });
            }}
            text={t('buttons:add')}
          />
        );
      }

      if (vat) {
        if (vat.hasVat) {
          vatInformation = (
            <div className="billing-information-vat">
              <div className="billing-information-vat__title">{t('layouts:billing-information.vat.title')}</div>
              <div className="billing-information-vat__details">
                <div className="billing-information-vat__detail">{t('layouts:billing-information.vat.number')}:</div>
                <div className="billing-information-vat__detail-value">{vat.numberId}</div>
                {vat.file && (
                  <>
                    <div className="billing-information-vat__detail">
                      {t('layouts:billing-information.vat.certificate')}:
                    </div>
                    <div className="billing-information-vat__detail-value">{vat.fileName}</div>
                  </>
                )}
              </div>
              {vat.file && (
                <>
                  <div className="billing-information-vat__download-certificate">
                    <a href={vat.file} target="blank">
                      {t('layouts:billing-information.vat.download-certificate')}
                    </a>
                  </div>
                  {isSuperAdmin && (
                    <div
                      onClick={() => this.setState({ renderModal: 'modal-reject-certificate' })}
                      className="billing-information-vat__reject-certificate"
                    >
                      <p>{t('layouts:billing-information.vat.reject-certificate')}</p>
                    </div>
                  )}
                </>
              )}
            </div>
          );
        } else {
          vatInformation = (
            <div className="billing-information-vat">
              <div className="billing-information-vat__title">{t('layouts:billing-information.vat.title')}</div>
              <div className="billing-information-vat__details">
                <div className="billing-information-vat__detail">{t('layouts:billing-information.vat.number')}:</div>
                <div className="billing-information-vat__detail-value">
                  {t('layouts:billing-information.vat.doesnt-have')}
                </div>
                <div>
                  {isSuperAdmin && (
                    <div
                      onClick={() => this.setState({ renderModal: 'modal-reject-certificate' })}
                      className="billing-information-vat__reject-certificate"
                    >
                      <p>{t('layouts:billing-information.vat.reject-certificate')}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }
      }
    }

    let stripeConnected;
    if ((!isOrganization && !!centreStripeUserId) || (isOrganization && organizationHasStripe)) {
      stripeConnected = (
        <div className="billing-information-connected">{t('layouts:billing-information.stripe-connected')}</div>
      );
    }

    return (
      <BillingInformationStyle>
        <MainLayoutSection>
          <div className="billing-information">
            <div className="billing-information-header">
              <HeaderMain title={t('layouts:billing-information.title')} />
            </div>
            <div className="billing-information-btn--save-btn">
              {/* <MainButton
                disabled={true}
                type="primary"
                iconImg={images.checkCircleGreySvg}
                onClick={() => {}}
                text={t('buttons:save-changes')}
                className="billing-information-btn"
              /> */}
            </div>
            {roles && (
              <div className="billing-information-section billing-information-section--payment-gateway">
                <div className="billing-information-title">
                  <p>{t('layouts:billing-information.payment-gateway')}</p>
                  {stripeConnected}
                </div>
                {roles && (
                  <div className="billing-information-btn--title-btn">
                    <MainButton
                      onClick={this.onConnectStripeHandler}
                      type="primary"
                      iconImg={images.stripeSvg}
                      loading={stripeLoading}
                      disabled={
                        (!isOrganization && !!centreStripeUserId) ||
                        (!isOrganization && !roles.includes('divingCenter_owner')) ||
                        (isOrganization && organizationHasStripe) ||
                        (isOrganization && !roles.includes('organization_owner'))
                      }
                      text={stripeLoading ? t('buttons:connection-in-progress') : t('buttons:connect-stripe')}
                      className={classNames('billing-information-btn', { 'events-disabled': stripeLoading })}
                    />
                  </div>
                )}
                <div className="billing-information-text">
                  <SubtitleText className="billing-information-text__paragraph">
                    {t('layouts:billing-information.stripetext')}
                  </SubtitleText>
                </div>
              </div>
            )}
            <div>
              <div className="billing-information-title">
                <p> {t('layouts:billing-information.fisc')}</p>
              </div>
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      required={true}
                      placeholder={t('layouts:billing-information.cifnif')}
                      className="rounded-title"
                      type="text"
                      //errorCode={errors.cif}
                      value={centre.cif ? centre.cif : ''}
                      labelText={t('layouts:billing-information.cifnif')}
                      filledValue={''}
                      onChange={value => {
                        centre.cif = value;
                        this.setState({ centre })
                      }
                      }
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      required={true}
                      placeholder="IVA"
                      className="rounded-title"
                      type="number"
                      //errorCode={errors.vat}
                      value={centre.iva ? centre.iva : ''}
                      labelText="IVA"
                      filledValue={''}
                      onChange={value => {
                        centre.iva = parseInt(value, undefined);
                        this.setState({ centre })
                      }
                      }
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      required={true}
                      placeholder="European VAT Number"
                      className="rounded-title"
                      type="text"
                      //errorCode={errors.vat}
                      value={centre.vat ? centre.vat : ''}
                      labelText="European VAT Number"
                      filledValue={''}
                      onChange={value => {
                        centre.vat = value;
                        this.setState({ centre })
                      }
                      }
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      required={true}
                      placeholder={t('layouts:billing-information.razon')}
                      className="rounded-title"
                      type="text"
                      //errorCode={errors.bussinessName}
                      value={centre.bussinessName ? centre.bussinessName : ''}
                      labelText={t('layouts:billing-information.razon')}
                      filledValue={''}
                      onChange={value => {
                        centre.bussinessName = value;
                        this.setState({ centre })
                      }
                      }
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-2">
                      <SelectBox
                        required={true}
                        placeholder={t('layouts:billing-information.fiscaddress')}
                        className="rounded-title"
                        optionsText={places}
                        errorCode={errors['address']}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={centre ? centre.fiscalAddress : ''}
                        initialValue={centre ? centre.fiscalAddress : ''}
                        initialValueFilterText={centre ? centre.fiscalAddress : ''}
                        defaultValueFilterText={centre ? centre.fiscalAddress : ''}
                        labelText={t('layouts:billing-information.fiscaddress')}
                        onSearch={value => {
                          this.handleSearchAddress(value);
                        }}
                        onChange={(value, label) => {
                          const place: any = places.filter(place => place.value === value)[0];
                          this.handleChange('fiscalAddress', label || '', true).then(() => {
                            this.handleChange('fiscalState', place.level1.longName, true).then(() => {
                              this.handleChange('fiscalTown', place.town.longName).then(() => {
                                this.handleChange('fiscalCountry', place.country.shortName);
                              });
                            });
                          });
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGridRow>
              </FormGrid>
              <div className="save-button">
                <MainButton iconImg={images.checkCircleWhiteSvg} text={t('layouts:billing-information.save')} type="primary" onClick={() => { this.saveInformation() }} />
              </div>
            </div>
            {/************************ Eliminado hasta que se salga de españa *******************************/}
            {/************************ Eliminado hasta que se salga de españa *******************************/}
            {/************************ Eliminado hasta que se salga de españa *******************************/}
          </div>
          {/* }
              {!isOrganization && (
                <div className="billing-information-section billing-information-section--bill-data">
                  <div className="billing-information-title">
                    <p>{t('layouts:billing-information.billing-documentation')}</p>
                  </div>
                  <div className="billing-information-btn--title-btn">{addVatBtn}</div>
                  <div className="billing-information-text">
                    <SubtitleText className="billing-information-text__paragraph">
                      {t('layouts:billing-information.vatinfo')}
                    </SubtitleText>
                  </div>
                  {vatInformation}
                </div>
              )}
            {renderModal === 'add-billing-data' && !isOrganization && (
              <ModalAddBillingData
                showModal={renderModal === 'add-billing-data'}
                onSubmit={(data: any) => {
                  this.onSubmitCreateVat(data);
                }}
                onClose={() => {
                  this.setState({ renderModal: '' });
                }}
              />
            )}
            */}
          {/************************ Eliminado hasta que se salga de españa *******************************/}
          {/************************ Eliminado hasta que se salga de españa *******************************/}
          {/************************ Eliminado hasta que se salga de españa *******************************/}
          {roles === 'superAdmin' && !isOrganization && (
            <ModalInfo
              type="red"
              title={[t('modalMessages:modal-reject-vat-certificate.title')]}
              onClose={() => this.setState({ renderModal: '' })}
              icon={images.delete2RedSvg}
              showModal={renderModal === 'modal-reject-certificate'}
              buttons={[
                {
                  text: t('buttons:confirm'),
                  type: 'error',
                  onClick: this.onDeleteVat,
                },
              ]}
            />
          )}

          {!isOrganization && (
            <ModalSuccess
              title={t('modalMessages:success-title')}
              text={t('modalMessages:billing-data-added')}
              showModal={renderModal === 'success'}
              onClose={() => this.setState({ renderModal: '' })}
            />
          )}
        </MainLayoutSection>
      </BillingInformationStyle>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    centreStripeUserId: state.centreStripeUserId.value,
    roles: state.agent.value.roles,
  };
};

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(
  withTranslation(['layouts', 'buttons', 'modalMessages'])(withRouter(BillingInformation))
);
