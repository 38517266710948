import styled from 'styled-components';

export const CreateActivitiesIncludedServicesStyle = styled.div`
  .modal-create-activities-icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 50px;
    .create-activities-dicotomic-container {
       display: flex;
      &__text {
        width: 100%;
      }
      &__icon {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
    }
  }
`;
