import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { AdsLayoutStyle } from './ads-style';
import { MainLayoutSection, MainLayoutTableContainer } from '../main-layout-style';
import { images } from '../../assets/images';
import MainTableLayout from '../../components/main-table/main-table';
import HeaderMain from '../../components/header-main/header-main';
import Pagination from '../../components/pagination';
import ModalCreateAdsMembership from '../../components/modals/modal-create-ads-membership/modal-create-ads-membership';
import ModalDelete from '../../components/modals/modal-delete/modal-delete';
import Can from '../../roles/can-component';
import ToastNotification from '../../components/toast-notification/toast-notification';
import { ADS_PER_PAGE } from '../../constants/data';
import { getAds, deleteAds } from '../../services/ads';
import { TableRow, TableCell } from '../../types/table';
import ModalSuccess from '../../components/modals/modal-success/modal-success';
import services from '../../services/services';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
export interface IAdsLayoutProps extends RouteComponentProps, WithTranslation {
  agent?: any;
}

export interface IAdsLayoutState {
  modal: string;
  ads: any[];
  total: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  page: number;
  editId?: string;
}

class AdsLayout extends React.Component<IAdsLayoutProps, IAdsLayoutState> {
  readonly state: IAdsLayoutState;

  private tableColumns: any[];

  private rowKeys: string[];

  constructor(props: IAdsLayoutProps) {
    super(props);

    this.state = {
      modal: '',
      ads: [],
      total: 0,
      totalPages: 0,
      searchFilter: '',
      skipPage: 0,
      page: 1,
      orderAscendent: '',
    };

    this.tableColumns = [
      {
        text: this.props.t('tables:name'),
        filter: false,
        ascendent: true,
      },
      {
        text: this.props.t('tables:company'),
        filter: false,
        ascendent: true,
      },
      {
        text: this.props.t('tables:location'),
        filter: false,
        ascendent: true,
      },
      {
        text: this.props.t('tables:website'),
        filter: false,
        ascendent: true,
      },
      {
        text: this.props.t('tables:clicks'),
        filter: false,
        ascendent: false,
      },
      {
        text: this.props.t('tables:conv'),
        filter: false,
        ascendent: true,
      },
      {
        text: this.props.t('tables:fee'),
        filter: false,
        ascendent: true,
      },
      {
        text: '€', // TO DO
        filter: false,
        ascendent: true,
      },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
    ];
    this.rowKeys = ['name', 'company', 'location', 'website', 'clicks', 'conv', 'fee', 'coin', 'options'];
  }

  componentDidMount() {
    this.getAds();
  }

  getAds = async () => {
    const { searchFilter, skipPage, orderAscendent } = this.state;
    try {
      const params = {
        filter: {
          skip: skipPage,
          limit: ADS_PER_PAGE,
          orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        },
      };
      const resp: any = await getAds(params);
      const totalPages: number = Math.ceil(resp.data.count / ADS_PER_PAGE);
      this.setState({ totalPages, ads: resp.data.data, total: resp.data.count });
    } catch (err) {
      this.setState({ ads: [] });
    }
  };

  deleteAds = async (id: string) => {
    const { t } = this.props;
    try {
      await deleteAds(id);
      services.pushNotification({
        title: t('push:delete-code.title'),
        text: t('push:delete-code.text'),
        type: 'red',
      });
      this.setState({ modal: '', editId: undefined }, () => this.getAds());
    } catch (err) { }
  };

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = ADS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getAds();
      });
    }
  }

  formatDataToTableRows(data: any[]) {
    const { t } = this.props;
    return data.map(item => {
      const row: TableRow = {};

      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };
        switch (key) {
          case 'name':
            cell.value = item[key];
            cell.key = 'highlighted-body';
            break;
          case 'id':
            cell.value = item[key];
            cell.key = 'detail';
            break;
          case 'fee':
            cell.value = `${(item[key] || '0').toString()} %`;
            cell.key = 'body';
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });
      row.location = { key: '', type: '', value: 'TODO:BACKEND' };
      row.options = {
        value: item.id,
        type: '',
        key: 'options',
        actions: [
          {
            label: t('buttons:edit'),
            icon: images.edit3Svg,
            onClick: () => {
              this.setState({ modal: 'modal-create-add', editId: item.id });
            },
          },
          {
            label: t('buttons:delete'),
            icon: images.delete2Svg,
            onClick: () => {
              this.setState({ modal: 'modal-delete', editId: item.id });
            },
          },
          {
            label: 'Ver detalle',
            icon: images.eyeSvg,
            onClick: () => this.props.history.push(`/ads/detail/${item.id}`),
          },
        ],
      };

      return row;
    });
  }

  render() {
    const { modal, totalPages, ads, editId, page } = this.state;
    const { agent, t } = this.props;
    return (
      <>
        <HeaderMain
          title={t('layouts:ads.header')}
          // withSearch={true}
          mainButton={{
            img: images.createWhiteSvg,
            text: t('buttons:create'),
            onClick: () => this.setState({ modal: 'modal-create-add' }),
          }}
        />
        <MainLayoutSection>
          <AdsLayoutStyle>
            <MainLayoutTableContainer>
              <Can roles={agent.roles} perform="ads:management">
                <div className="diving-center-table">
                  <MainTableLayout
                    columns={this.tableColumns}
                    rows={this.formatDataToTableRows(ads)}
                    rowKeys={this.rowKeys}
                    onOrder={() => { }}
                  />
                </div>
                {totalPages > 1 && (
                  <div className="diving-center-pagination">
                    <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
                  </div>
                )}
              </Can>
            </MainLayoutTableContainer>
          </AdsLayoutStyle>
        </MainLayoutSection>
        {modal === 'modal-create-add' && (
          <ModalCreateAdsMembership
            onCancel={() => this.setState({ modal: '', editId: undefined })}
            showModal={modal === 'modal-create-add'}
            onAccept={() => {
              this.setState({ modal: 'modal-success', editId: undefined }, () => {
                this.getAds();
              });
            }}
            adsId={editId}
            editMode={!!editId}
          />
        )}

        {/* Modal Delete centre  */}
        <ModalDelete
          title={t('modalMessages:delete-member.title')}
          subtitle={t('modalMessages:delete-member.title')}
          showModal={modal === 'modal-delete'}
          onRemove={() => {
            if (!!editId) {
              this.deleteAds(editId);
            }
          }}
          onCancel={() => this.setState({ modal: '' })}
        />

        {/* Modal Success */}

        <ModalSuccess
          title={t('modalMessages:success-title')}
          text={t('modalMessages:success-ads')}
          showModal={modal === 'modal-success'}
          onClose={() => this.setState({ modal: '' })}
        />
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default withTranslation(['intro', 'buttons', 'layouts', 'modalMessages'])(
  connect<{}, {}, {}, IStoreState>(mapStateToProps)(AdsLayout)
);
