import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const FileItemModalContainer = styled.div`
  position: relative;
  cursor: pointer;
  .file-item-container-delete {
    position: absolute;
    top: 5px;
    right: 5px;
    width: ;
  }
  .file-item-container-title {
    color: ${colors['NE-001/100']};
    text-align: center;
  }
  .file-item-container-subtitle {
    color: ${colors['NE-002/100']};
    font-size: 10px;
    line-height: 16px;
    font-weight: 300;
    text-align: center;
  }
  .file-item-container-img {
    width: 100%;
    height: 110px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 12px;
    background: ${colors['PR-001/100']};
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .file-item-container-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .file-item-container-delete-icon {
    background: ${colors['NE-001/75']};
    border-radius: 100%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgba(13, 30, 63, 0.4);
    transition: all 0.3s ease-in-out;
    &:hover {
      background: rgba(13, 30, 63, 1);
    }
  }

  &.fail {
    .file-item-container-img {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: 1px solid ${colors['CO-003/100']};

      img  {
        width: 20px;
        height: 20px;
        min-height: unset;
      }
    }
  }

  .file-item-container-checkbox {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &.full {
    height: 90%;
    .file-item-container-img {
      height: 300px;
    }
    width: 100%;
  }
`;
