import React, { Component } from 'react';
import { TooltipBoxContainer } from './tooltip-box-style';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import InfoText from '../text/info-text/info-text';

interface TooltipBoxProps {
  active: boolean;
  tooltipTitle?: string;
  tooltipText: string;
  dark?: boolean;
  setActive?: any;
}

class TooltipBox extends Component<TooltipBoxProps> {
  public static defaultProps = {
    active: false,
    tooltipTitle: 'Titulo string',
    tooltipText: 'body string',
  };
  private toggleContainer: any;

  constructor(props: TooltipBoxProps) {
    super(props);
    this.toggleContainer = React.createRef();
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  componentDidUpdate(prevProps: TooltipBoxProps) {
    if (prevProps.active !== this.props.active && this.props.active) {
      window.addEventListener('click', this.onClickOutsideHandler);
    } else if (prevProps.active !== this.props.active && !this.props.active) {
      window.removeEventListener('click', this.onClickOutsideHandler);
    }
  }

  onClickOutsideHandler(event: Event) {
    if (this.props.setActive && this.props.active && !this.toggleContainer.current.contains(event.target)) {
      this.props.setActive(false);
    }
  }

  render() {
    const { active, tooltipTitle, tooltipText, dark } = this.props;

    return (
      <TooltipBoxContainer ref={this.toggleContainer} className={active ? 'active' : ''}>
        <div className={`tooltip-modal ${dark ? 'tooltip-modal__dark' : ''}`}>
          {tooltipTitle && (
            <div className="tooltip-title">
              <TextDestacadoText>{tooltipTitle}</TextDestacadoText>
            </div>
          )}
          <div className="tooltip-main">
            <InfoText>{tooltipText}</InfoText>
          </div>
        </div>
      </TooltipBoxContainer>
    );
  }
}

export default TooltipBox;
