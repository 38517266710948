import styled, { css } from 'styled-components';

const ButtonDropdownStyle = styled.div`
  position: relative;
  width: auto;
  min-width: 60px;
  display: inline-block;

  .dropdown {
    visibility: hidden;
    opacity: 0;
    width: auto;
    position: absolute;
    background: #fff;
    top: calc(100% + 12px);
    box-shadow: 0px 0px 6px rgba(0, 0, 25, 0.08);
    padding: 12px;
    border-radius: 5px;
    transition: visibility 0.3s linear, opacity 0.3s linear;

    right: ${props => (props.orientationModal === 'right' ? '0' : 'auto')};
    left: ${props => (props.orientationModal === 'left' ? '0' : 'auto')};

    li {
      display: block;
      font-family: 'plain';
      color: #405364;
      font-family: Plain;
      font-weight: 300;
      font-size: 13px;
      line-height: 22px;
      border-bottom: 0.5px solid #bcc5d7;
      padding: 14px 0;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
    }

    ${props =>
      props.isOpen &&
      css`
        visibility: visible;
        opacity: 1;
        z-index: 100;
      `}
  }
`;

export default ButtonDropdownStyle;
