import styled from 'styled-components';

export const ModalCreateMarineReserveContainer = styled.div`
  padding: 58px 32px 32px 32px;
  width: 416px;

  .modal-create-marine-reserve-title {
    margin-bottom: 40px;
  }
  .modal-create-marine-reserve-form {
    margin-bottom: 40px;
  }
  .modal-create-marine-reserve-buttons {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .modal-create-marine-reserve-languages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;
  }
`;
