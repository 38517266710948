import { images } from '../assets/images';

export const CENTRE_SERVICES = [
  { value: 'segdiario', label: 'Seguro diario', icon: images.seguro },
  { value: 'segsem', label: 'Seguro semanal', icon: images.seguro },
  { value: 'segmen', label: 'Seguro mensual', icon: images.seguro },
  { value: 'seganual', label: 'Seguro anual', icon: images.seguro },
  { value: 'marineReserveMuseum', label: 'Acceso a museo subacuático', icon: images.serviceMarineReserve },
  { value: 'marineReserve', label: 'Acceso a reserva marina', icon: images.serviceMarineReserve },
  { value: 'medicalCertificate', label: 'Expedición de certificado médico', icon: images.serviceMedicalCertificate },
  { value: 'equipmentFull', label: 'Alquiler de equipo completo SCUBA', icon: images.equipmentFull },
  { value: 'snorkel', label: 'Alquiler Equipo completo Snorkel', icon: images.serviceSnorkel },
  { value: 'equipmentComputer', label: 'Alquiler de ordenador de buceo', icon: images.computer },
  { value: 'equipmentWetSuit', label: 'Alquiler de traje seco', icon: images.wetsuit },
  { value: 'equipmentSemiWet', label: 'Alquiler de traje semi-seco', icon: images.wetsemi },
  { value: 'trajehumd', label: 'Alquiler Traje húmedo', icon: images.trajeHumedo },
  { value: 'equipmentmask', label: 'Alquiler Máscara', icon: images.mascara },
  { value: 'alqaletas', label: 'Alquiler aletas', icon: images.aletas },
  { value: 'alqlastre', label: 'Alquiler lastre 2 kg', icon: images.lastre },
  { value: 'alqesc', label: 'Alquiler escarpines', icon: images.escarpines },
  { value: 'alqchjack', label: 'Alquiler Chaleco-Jacket', icon: images.chaleco },
  { value: 'alqchalas', label: 'Alquiler chaleco alas', icon: images.alas },
  { value: 'alqsidemount', label: 'Alquiler equipo sidemount', icon: images.alqsidemount },
  { value: 'boyadeco', label: 'Alquiler Boya deco', icon: images.boyadeco },
  { value: 'alqelcorte', label: 'Alquiler Corta cabos', icon: images.cuchillo },
  { value: 'equipmentLight', label: 'Alquiler de linterna', icon: images.equipmentLight },
  { value: 'equipmentLightfocus', label: 'Alquiler de foco', icon: images.equipmentLight },
  { value: 'equipmentCamera', label: 'Alquiler Cámara fotos', icon: images.equipmentCamera },
  { value: 'equipmentScooter', label: 'Alquiler de scooter submarino', icon: images.equipmentScooter },
  { value: 'reg', label: 'Alquiler Regulador completo', icon: images.regulador },
  { value: 'bibo', label: 'Alquiler Bibotella', icon: images.bibotella },
  { value: 'alqEAN3212', label: 'Carga botella EAN 32 12 L', icon: images.recargaNitrox },
  { value: 'alqEAN3215', label: 'Carga botella EAN 32 15 L', icon: images.recargaNitrox },
  { value: 'alqEAN3612', label: 'Carga botella EAN 36 12 L', icon: images.recargaNitrox },
  { value: 'alqEAN3615', label: 'Carga botella EAN 36 15 L', icon: images.recargaNitrox },
  { value: 'bottleSurcharge', label: 'Carga de botella aire', icon: images.serviceBottleSurcharge },
  { value: 'alq12l', label: 'Alquiler Botella Aire 12L', icon: images.alq12l },
  { value: 'alq15l', label: 'Alquiler Botella Aire 15L', icon: images.alq15l },
  { value: 'alq18l', label: 'Alquiler Botella Aire 18L', icon: images.alq18l },
  { value: 'nitrox', label: 'Suplemento Nitrox', icon: images.recargaNitrox },
  { value: 'supnoc', label: 'Suplemento inmersión nocturna', icon: images.supnoc },
  { value: 'supguia', label: 'Suplemento guía', icon: images.guia },
  { value: 'serviceEquipment', label: 'Montaje de equipo', icon: images.serviceEquipment },
  { value: 'serviceReportage', label: 'Reportaje subacuático', icon: images.serviceReportage },
  { value: 'paddleSurf', label: 'Alquiler de Paddle surf', icon: images.servicePaddleSurf },
  { value: 'kayak', label: 'Alquiler de Kayak', icon: images.serviceKayak },
  { value: 'emb', label: 'Alquiler embarcación con patrón', icon: images.embarcacion },
  { value: 'equipmentment', label: 'Pupilaje de equipos', icon: images.alas },
  { value: 'equipmentRev', label: 'Revisión y puesta a punto de tu equipo', icon: images.equipmentRev },
  { value: 'airport', label: 'Recogida del aeropuerto', icon: images.serviceAirport },
  { value: 'hotel', label: 'Recogida del hotel', icon: images.serviceHotel },
  { value: 'port', label: 'Recogida del puerto', icon: images.servicePort },
  { value: 'parking', label: 'Reserva de plaza párking', icon: images.alas },
  { value: 'yoga', label: 'Sesión de Yoga', icon: images.serviceYoga },
  { value: 'petCare', label: 'Cuidado de mascotas', icon: images.servicePetCare },
  { value: 'kindergarten', label: 'Guardería - Ludoteca', icon: images.serviceKindergarten },
  { value: 'others', label: 'Otros', icon: images.others },
];

export const CENTRE_SERVICES_KEY_VALUE: any = {
  segdiario: 'Seguro diario',
  segsem: 'Seguro semanal',
  segmen: 'Seguro mensual',
  seganual: 'Seguro anual',
  marineReserveMuseum: 'Acceso a museo subacuático',
  marineReserve: 'Acceso a reserva marina',
  medicalCertificate: 'Expedición de certificado médico',
  equipmentFull: 'Alquiler de equipo completo SCUBA',
  snorkel: 'Alquiler Equipo completo Snorkel',
  equipmentComputer: 'Alquiler de ordenador de buceo',
  equipmentWetSuit: 'Alquiler de traje seco',
  equipmentSemiWet: 'Alquiler de traje semi-seco',
  trajehumd: 'Alquiler Traje húmedo',
  equipmentmask: 'Alquiler Máscara',
  alqaletas: 'Alquiler aletas',
  alqlastre: 'Alquiler lastre 2 kg',
  alqesc: 'Alquiler escarpines',
  alqchjack: 'Alquiler Chaleco-Jacket',
  alqchalas: 'Alquiler chaleco alas',
  alqsidemount: 'Alquiler equipo sidemount',
  boyadeco: 'Alquiler Boya deco',
  alqelcorte: 'Alquiler Corta cabos ',
  equipmentLight: 'Alquiler de linterna',
  equipmentLightfocus: 'Alquiler de foco',
  equipmentCamera: 'Alquiler Cámara fotos',
  equipmentScooter: 'Alquiler de scooter submarino',
  reg: 'Alquiler Regulador completo ',
  bibo: 'Alquiler Bibotella',
  alqEAN3212 : 'Carga botella EAN 32 12 L',
  alqEAN3215 : 'Carga botella EAN 32 15 L',
  alqEAN3612 : 'Carga botella EAN 36 12 L',
  alqEAN3615 : 'Carga botella EAN 36 15 L',
  bottleSurcharge: 'Carga de botella aire',
  alq12l: 'Alquiler Botella Aire 12L',
  alq15l: 'Alquiler Botella Aire 15L',
  alq18l: 'Alquiler Botella Aire 18L',
  nitrox: 'Suplemento Nitrox',
  supnoc: 'Suplemento inmersión nocturna',
  supguia: 'Suplemento guía',
  serviceEquipment: 'Montaje de equipo',
  serviceReportage: 'Reportaje subacuático',
  paddleSurf: 'Alquiler de Paddle surf',
  kayak: 'Alquiler de Kayak',
  emb: 'Alquiler embarcación con patrón',
  equipmentment: 'Pupilaje de equipos',
  equipmentRev: 'Revisión y puesta a punto de tu equipo',
  airport: 'Recogida del aeropuerto',
  hotel: 'Recogida del hotel',
  port: 'Recogida del puerto',
  parking: 'Reserva de plaza párking',
  yoga: 'Sesión de Yoga',
  petCare: 'Cuidado de mascotas',
  kindergarten: 'Guardería - Ludoteca',
  others: 'Otros'
}

export const CENTRE_SERVICES_KEY_ICON: any = {
   segdiario: images.seguro,
   segsem: images.seguro ,
   segmen: images.seguro ,
   seganual: images.seguro ,
   marineReserveMuseum: images.serviceMarineReserve ,
   marineReserve: images.serviceMarineReserve ,
   medicalCertificate: images.serviceMedicalCertificate ,
   equipmentFull: images.equipmentFull ,
   snorkel: images.serviceSnorkel ,
   equipmentComputer: images.computer ,
   equipmentWetSuit: images.wetsuit ,
   equipmentSemiWet: images.wetsemi ,
   trajehumd: images.trajeHumedo ,
   equipmentmask: images.mascara ,
   alqaletas: images.aletas ,
   alqlastre: images.lastre ,
   alqesc: images.escarpines ,
   alqchjack: images.chaleco ,
   alqchalas: images.alas ,
   alqsidemount: images.alqsidemount ,
   boyadeco: images.boyadeco ,
   alqelcorte: images.cuchillo ,
   equipmentLight: images.equipmentLight ,
   equipmentLightfocus: images.equipmentLight ,
   equipmentCamera: images.equipmentCamera ,
   equipmentScooter: images.equipmentScooter ,
   reg: images.regulador ,
   bibo: images.bibotella ,
   alqEAN3212: images.recargaNitrox ,
   alqEAN3215: images.recargaNitrox ,
   alqEAN3612: images.recargaNitrox ,
   alqEAN3615: images.recargaNitrox ,
   bottleSurcharge: images.serviceBottleSurcharge ,
   alq12l: images.alq12l ,
   alq15l: images.alq15l ,
   alq18l: images.alq18l ,
   nitrox: images.recargaNitrox ,
   supnoc: images.supnoc ,
   supguia: images.guia ,
   serviceEquipment: images.serviceEquipment ,
   serviceReportage: images.serviceReportage ,
   paddleSurf: images.servicePaddleSurf ,
   kayak: images.serviceKayak ,
   emb: images.embarcacion ,
   equipmentment: images.alas ,
   equipmentRev: images.equipmentRev ,
   airport: images.serviceAirport ,
   hotel: images.serviceHotel ,
   port: images.servicePort ,
   parking: images.alas ,
   yoga: images.serviceYoga ,
   petCare: images.servicePetCare ,
   kindergarten: images.serviceKindergarten ,
   others: images.others ,
};
