import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalDocumentContainer = styled.div`
  padding: 32px;
  display: flex;
  height: 80vh;
  .modal-document-preview {
    background: ${colors['PR-001/10']};
    width: 500px;
    margin-right: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__empty {
      width: 120px;
      height: 120px;
    }
    &__img {
      width: 330px;
    }
    &__doc {
      width: 100%;
      height: 100%;
    }
  }
  .modal-document-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 80px;
  }

  .modal-document-info-top {
    margin-right: 40px;
    &__title {
      color: ${colors['NE-001/100']};
      margin-bottom: 20px;
      width: 280px;
    }
    &__name {
      color: ${colors['PR-001/100']};
      margin-bottom: 16px;
    }
    &__subtitle {
      margin-bottom: 4px;
      text-transform: uppercase;
      color: ${colors['NE-002/100']};
    }
    &__info {
      margin-bottom: 16px;
      color: ${colors['NE-001/100']};
    }
    &__buttons {
      display: flex;
      &__item {
        margin-right: 16px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .modal-document-info-bottom {
    display: flex;
    &__item {
      margin-right: 16px;
      width: 100%;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;
