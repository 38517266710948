import styled from 'styled-components';

export const CertifierFaqLayoutStyle = styled.div`
  padding-right: 40px;

  .faq__section {
    margin-bottom: 40px;

    &__title {
      font-family: Plain;
      font-size: 22px;
      line-height: 28px;
      color: #000019;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &__paragraph {
      font-family: Plain;
      font-size: 16px;
      line-height: 26px;
      color: #000019;
      font-weight: 300;
      text-align: justify;
    }
  }
`;
