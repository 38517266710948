import * as React from 'react';
import { CreateActivitiesIncludedServicesStyle } from './create-activities-included-services-style';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import InfoText from '../../../../../components/text/info-text/info-text';
import { images } from '../../../../../assets/images';
import { IDropdown, IId } from '../../../../../models/generic';
import { getServices } from '../../../../../services/centre';
import MainButton from '../../../../../components/main-button';
import SingleButton from '../../../../../components/single-button/single-button';
import { CENTRE_SERVICES_KEY_VALUE, CENTRE_SERVICES_KEY_ICON } from '../../../../../constants/centre-services';


export interface ICreateActivitiesIncludedServicesProps {
  services?: IId[];
  onPrev?: () => void;
  onNext: (services: IId[]) => void;
  centreId: string;
}

export interface ICreateActivitiesIncludedServicesState {
  servicesList: IDropdown[];
  services: number[];
}

export default class CreateActivitiesIncludedServices extends React.Component<
  ICreateActivitiesIncludedServicesProps,
  ICreateActivitiesIncludedServicesState
  > {
  constructor(props: ICreateActivitiesIncludedServicesProps) {
    super(props);

    this.state = {
      servicesList: [],
      services: [],
    };
  }

  componentDidMount() {
    this.getCentreServices();
  }

  componentDidUpdate(prevProps: ICreateActivitiesIncludedServicesProps) {
    const { services } = this.props;
    if (prevProps.services !== services) {
    }
  }

  setServices() {
    const { services } = this.props;
    if (services) {
      this.setState({ services: services.map(service => service.id) });
    }
  }

  async getCentreServices() {
    const { centreId } = this.props;
    const resp = await getServices(centreId);
    
    const servicesList = resp.data.data.map((service: any) => {
      return { 
          value: service.id.toString(), 
          label: CENTRE_SERVICES_KEY_VALUE[service.name], 
          image: CENTRE_SERVICES_KEY_ICON[service.name] };
    });
    this.setState({ servicesList }, () => this.setServices());
  }

  handleCheck(checked: boolean, serviceId: string) {
    const { services } = Object.assign(this.state);
    const service = parseInt(serviceId, undefined);
    if (checked && !services.includes(service)) {
      services.push(service);
      this.setState({ services });
    } else if (!checked && services.includes(service)) {
      this.setState({ services: services.filter((serv: number) => serv !== service) });
    }
  }

  handleNext() {
    const { onNext } = this.props;
    const { services } = this.state;
    onNext(
      services.map(service => {
        return { id: service };
      })
    );
  }

  render() {
    const { onPrev } = this.props;
    const { servicesList, services } = this.state;
    return (
      <CreateActivitiesIncludedServicesStyle>
        <div className="modal-create-activities-icons">
          {servicesList.map((service: IDropdown, index: number) => {
            return (
              <DicotomicCheckbox
                checked={services.includes(parseInt(service.value, undefined))}
                key={index}
                onCheck={checked => this.handleCheck(checked, service.value)}
              >
                <div className="create-activities-dicotomic-container">
                  <div className="create-activities-dicotomic-container__icon">
                    <img src={service.image} alt="" />
                  </div>
                  <div className="create-activities-dicotomic-container__text">
                    <InfoText>{service.label}</InfoText>
                  </div>
                </div>
              </DicotomicCheckbox>
            );
          })}
        </div>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={onPrev ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesIncludedServicesStyle>
    );
  }
}
