import React, { Component } from 'react';
import { PopNotificationContainer, PopNotificationLayout } from './pop-notification-style';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import { images } from '../../assets/images';
import InfoText from '../text/info-text/info-text';
import { INotification } from '../../models/notifications';
import { NOTIFICATION_DURATION } from '../../constants/data';
import { copyOf } from '../../utils/other';

interface IToastNotificationProps {
  notifications: INotification[];
}

interface IToastNotificationState {
  notifications: INotification[];
}

export default class ToastNotification extends Component<IToastNotificationProps, IToastNotificationState> {
  state = {
    notifications: [],
  };

  componentDidUpdate(prevProps: IToastNotificationProps) {
    const { notifications } = Object.assign(this.props);
    if (prevProps.notifications.length !== notifications.length) {
      notifications.forEach((notification: INotification) => {
        notification.active = '';
      });
      this.setState({ notifications }, () => {
        setTimeout(() => {
          notifications.forEach((notification: INotification) => {
            notification.active = 'active';
          });
          this.setState({ notifications });
        }, 10);

        setTimeout(() => {
          notifications.forEach((notification: INotification) => {
            notification.active = '';
          });
          this.setState({ notifications });
        }, NOTIFICATION_DURATION * 1000 - 500);
      });
    }
  }

  removeNotification(index: number) {
    const { notifications } = this.state;
    const newNotifications = copyOf(notifications);
    newNotifications.splice(index, 1);
    this.setState({ notifications: newNotifications });
  }

  render() {
    const { notifications } = this.state;
    return (
      <PopNotificationLayout>
        {notifications.map((notification: any, index: number) => {
          const { className, text, title, type, onClose, active } = notification;
          return (
            <PopNotificationContainer key={`notification-${index}`} className={`${className} ${active} ${type}`}>
              <div className="pop-notification-head">
                <div className="pop-notification-head__icon">
                  <img
                    src={
                      type === 'yellow'
                        ? images.alertSvg
                        : type === 'red'
                        ? images.delete2RedSvg
                        : type === 'red-warning'
                        ? images.warningRedSvg
                        : images.checkCircleGreenSvg
                    }
                    alt="toast icon"
                  />
                </div>
                <div className="pop-notification-head__title">
                  <TextDestacadoText>{title}</TextDestacadoText>
                </div>
                <div className="pop-notification-head__close" onClick={() => this.removeNotification(index)}>
                  <img
                    // src={images.closeSvg}
                    src={
                      type === 'yellow'
                        ? images.closeSvg
                        : type === 'red'
                        ? images.closeRedSvg
                        : type === 'red-warning'
                        ? images.closeRedSvg
                        : images.closeGreenSvg
                    }
                    alt="close icon"
                  />
                </div>
              </div>
              <div className="pop-notification-text">
                <InfoText>{text}</InfoText>
              </div>
            </PopNotificationContainer>
          );
        })}
      </PopNotificationLayout>
    );
  }
}
