import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ModalCancelationPolicyStyle = styled.div`
  padding: 58px 32px 32px 32px;
  width: 416px;

  .modal-cancelation-policy-type-select {
    margin-bottom: 26px;
  }

  .modal-cancelation-policy-title {
    margin-bottom: 12px;
    padding-right: 20px;
  }
  .modal-cancelation-policy-text  {
    margin-bottom: 28px;
    color: ${colors['NE-002/100']};
  }
  .manage-dive-levels-line {
    width: 100%;
    height: 1px;
    background: ${colors['NE-002/100']};
    margin-bottom: 40px;
  }

  .modal-cancelation-policy-top-row  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding-bottom: 44px;
    &__input {
      margin-right: 20px;
    }

    &__dicotomic {
      margin-top: 11px;
      align-self: center;
    }
  }

  .modal-cancelation-policy-top-select {
    min-width: 150px;
  }

  .modal-cancelation-policy-tableform {
    margin-bottom: 20px;
  }

  .modal-cancelation-policy-tableform-title {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
    color: ${colors['SE-002/100']};
    text-transform: uppercase;
    &__penalty {
      width: 110px;
      margin-left: 20px;
    }
    &__term  {
      flex: auto;
    }
  }

  .modal-cancelation-policy-tableform-row {
    display: flex;
    /* margin-bottom: 20px; */
    &__text {
      padding-top: 12px;
      flex: auto;
    }
    &__input {
      width: 110px;
      margin-left: 40px;
    }
  }

  .modal-cancelation-policy-languages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;
  }
`;
