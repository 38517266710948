import * as React from 'react';
import { DiverBonusesDetailStyle } from './diver-bonuses-detail-style';
import { MainLayoutSection, MainLayoutTableContainer } from '../../../../main-layout-style';
import HeaderMain from '../../../../../components/header-main/header-main';
import { images } from '../../../../../assets/images';
import Pagination from '../../../../../components/pagination';
import MainTableLayout from '../../../../../components/main-table/main-table';
import TitleText from '../../../../../components/text/title-text/title-text';
import CardBonus from '../../../../../components/card-bonus/card-bonus';
import DropDown from '../../../../../components/drop-down/drop-down';
import LabelText from '../../../../../components/text/label-text/label';
import SubheadDestacadoText from '../../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import InfoText from '../../../../../components/text/info-text/info-text';
import { RouteComponentProps, match } from 'react-router';
import { getTicket } from '../../../../../services/tickets';
import moment from 'moment';
import { TICKET_DURATION_UNITS_KEY_VALUE_2 } from '../../../../../constants/duration';

export interface IDiverBonusesDetailProps extends RouteComponentProps {
  match: match<{
    id: string;
    bonusId: string;
  }>;
}

export interface IDiverBonusesDetailState {
  bonus?: any;
}

export default class DiverBonusesDetail extends React.Component<IDiverBonusesDetailProps, IDiverBonusesDetailState> {
  constructor(props: IDiverBonusesDetailProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getBonus();
  }

  async getBonus() {
    const {
      match: {
        params: { bonusId },
      },
    } = this.props;
    const params = {
      filter: {
        relations: ['instances', 'templates'],
      },
    };
    const resp: any = await getTicket(bonusId, params);
    this.setState({ bonus: resp.data });
  }

  render() {
    const { bonus } = this.state;
    if (!bonus) return '';
    const diveActivities = bonus.templates
      .filter((template: any) => !template.courseInfo)
      .map((template: any) => template.name);
    const trainingActivities = bonus.templates
      .filter((template: any) => template.courseInfo)
      .map((template: any) => template.name);
    return (
      <>
        <HeaderMain title={'Detalle del bono'} goBack={true} />
        <MainLayoutSection>
          <DiverBonusesDetailStyle>
            <div className="diver-bonuses-detail-content">
              <div className="diver-bonuses-detail-content-left">
                <div className="diver-bonuses-detail-content-left__title">
                  <div className="diver-bonuses-detail-content-left__title__icon">
                    <img src={images.percentBlueSvg} alt="" />
                  </div>
                  <div className="diver-bonuses-detail-content-left__title__text">
                    <TitleText>{bonus.name}</TitleText>
                  </div>
                </div>
                <div className="diver-bonuses-detail-content-bonus">
                  {bonus.createdBy && (
                    <>
                      <div className="diver-bonuses-detail-content-bonus__by">
                        <LabelText>Creado por</LabelText>
                      </div>
                      <div className="diver-bonuses-detail-content-bonus__name">
                        <SubheadDestacadoText>{`${bonus.createdBy.name} ${bonus.createdBy.surname}`}</SubheadDestacadoText>
                      </div>
                    </>
                  )}
                  <div className="diver-bonuses-detail-content-bonus__date">
                    <InfoText>{moment(bonus.createdAt).format('DD/MM/YYYY')}</InfoText>
                  </div>
                </div>
              </div>
              <div className="diver-bonuses-detail-content__right">
                <CardBonus title={`${bonus.instances.length}/${bonus.uses}`} subtitle="nº USOS" />
                <CardBonus title={bonus.sharedUsage ? 'Sí' : 'No'} subtitle="compartir" />
                <CardBonus
                  title={bonus.duration ? TICKET_DURATION_UNITS_KEY_VALUE_2[bonus.duration] : 'Indefinida'}
                  subtitle="validez"
                />
                <CardBonus className={'blue'} title={(bonus.price / 100).toString()} subtitle="EUR" />
              </div>
            </div>

            <div className="diver-bonuses-detail-content-included">
              <div className="diver-bonuses-detail-content-included__title">
                <TitleText>¿Qué incluye?</TitleText>
              </div>
              <div className="diver-bonuses-detail-content-included__drop">
                {diveActivities.length > 0 && (
                  <div className="diver-bonuses-detail-content-included__drop__item">
                    <DropDown title="Actividades de inmersión" items={diveActivities} />
                  </div>
                )}
                {trainingActivities.length > 0 && (
                  <div className="diver-bonuses-detail-content-included__drop__item">
                    <DropDown title="Actividades de formación" items={trainingActivities} />
                  </div>
                )}
              </div>
            </div>
            {/* <div className="diver-bonuses-detail-table-head">
              <div className="diver-bonuses-detail-table-head__text">
                <TitleText>Historial de la actividad</TitleText>
              </div>
              <div className="diver-bonuses-detail-table-head__buttons">
                <div className="diver-bonuses-detail-table-head__buttons__item">
                  <SingleButton className="secondary modal" icon={images.searchSvg} onClick={() => {}} />
                </div>
                <div className="diver-bonuses-detail-table-head__buttons__item">
                  <MainButton iconImg={activeFilter ? images.filtersLightBlueSvg : images.filtersSvg} text="Filtrar" type={activeFilter ? "primary" : "secondary"} />
                </div>
              </div>
            </div> */}

            {/* <MainLayoutTableContainer> //!TODO AÑADIR TicketInstances
              <div className="diving-center-table">
                <MainTableLayout
                  filter={() => {}}
                  titleTable="Historial de la actividad"
                  columns={this.tableColumns}
                  rows={this.tableRows}
                  rowKeys={this.rowKeys}
                  onOrder={() => {}}
                />
              </div>
              <div className="diving-center-pagination">
                <Pagination page={1} callback={() => {}} limit={22} />
              </div>
            </MainLayoutTableContainer> */}
          </DiverBonusesDetailStyle>
        </MainLayoutSection>
      </>
    );
  }

  tableColumns = [
    {
      text: 'Actividades',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Fecha de compra',
      filter: false,
      ascendent: true,
    },
    {
      text: 'compartido',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRows = [
    {
      activities: {
        key: 'highlighted-body',
        value: 'Bautismo de buceo en aguas abiertas para mayores de 16 años ',
      },
      'buy-date': {
        key: '',
        value: '09/09/2019',
      },
      shared: {
        key: '',
        value: 'Si, entre 4',
      },
      detail: {
        key: 'detail',
        value: '',
      },
    },
    {
      activities: {
        key: 'highlighted-body',
        value: 'Bautismo de buceo en aguas abiertas para mayores de 16 años ',
      },
      'buy-date': {
        key: '',
        value: '09/09/2019',
      },
      shared: {
        key: '',
        value: 'Si, entre 4',
      },
      detail: {
        key: 'detail',
        value: '',
      },
    },
    {
      activities: {
        key: 'highlighted-body',
        value: 'Bautismo de buceo en aguas abiertas para mayores de 16 años ',
      },
      'buy-date': {
        key: '',
        value: '09/09/2019',
      },
      shared: {
        key: '',
        value: 'Si, entre 4',
      },
      detail: {
        key: 'detail',
        value: '',
      },
    },
  ];

  rowKeys = ['activities', 'buy-date', 'shared', 'detail'];
}
