import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const TooltipBoxContainer = styled.div`
  display: none;
  &.active {
    display: block;
    .tooltip-modal {
      visibility: visible;
      opacity: 1;
    }
  }

  .tooltip-modal {
    visibility: hidden;
    opacity: 0;
    color: ${colors['NE-004-0']};
    background: ${colors['PR-001-100']};
    padding: 16px 14px;
    position: relative;
    max-width: 260px;
    border-radius: 3px;
    background: ${colors['SE-002/100']};
    z-index: 60;
    transition: all 0.3s ease-in-out;

    &:before {
      content: '';
      position: absolute;
      right: 3px;
      transform: rotate(45deg);
      top: -4px;
      width: 13px;
      height: 16px;
      border-radius: 4px;
      background: ${colors['SE-002/100']};
    }

    &__dark {
      background: ${colors['SE-004/100']};

      &:before {
        background: ${colors['SE-004/100']};
      }
    }
  }

  .tooltip-title {
    margin-bottom: 4px;
    color: white;
  }

  .tooltip-main {
    color: ${colors['NE-002/100']};
  }
`;
