import { differenceInYears, isFuture } from 'date-fns';
import moment from 'moment';
import 'moment/locale/es.js';

// EMPTY
export const isEmpty = (value: any) => value === null || value === undefined || value === '';

// EMAIL
export const isValidEmail = (mail: string) => {
  return /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail);
};

// COLLEGE NUMBER
export const isValidCollegeNumber = (collegeNumber: string) => /^\d{3,10}$/.test(collegeNumber);

// DATE (format DD MM YYYY)
export const isValidDate = (date: string, separator: '-' | '/') => {
  switch (separator) {
    case '-':
      return /^([0-2][0-9]|(3)[0-1])-(((0)[0-9])|((1)[0-2]))-\d{4}$/.test(date);
    case '/':
      return /^([0-2][0-9]|(3)[0-1])\/(((0)[0-9])|((1)[0-2]))\/\d{4}$/.test(date);
    default:
      break;
  }
};

// Documento de Identidad (DNI/NIF, NIE, Passport)
export const isValidDocumentID = (text: string, elemID: string) => {
  if (!text) return false;
  if (['NIF', 'NIE', 'DNI'].includes(elemID)) {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const nieRexp = /^[XYZ012][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const str = text.toString().toUpperCase();

    if (elemID === 'NIF' && !nifRexp.test(str)) return false;
    if (elemID === 'NIE' && !nieRexp.test(str)) return false;

    const nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

    if (validChars.charAt(charIndex) === letter) return true;

    return false;
  }
  if (elemID === 'PASP') {
    const passEsp = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
    const passWorld = /^[A-Za-z0-9]{9}$/i;
    return passEsp.test(text) || passWorld.test(text);
  }
  return false;
};

// PHONE (ES)
export const isValidPhoneESP = (phone: string) => /^\d{9}$/.test(phone);

// PHONE INTERNATIONAL
export const isValidPhone = (number: string) => {
  if (!number) {
    return true;
  }
  const regExp = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/g;
  return regExp.test(number);
  // /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail)
};

// CARD
export const isValidCard = (text: string) => {
  if (!text || text.length < 18) return false;

  const number = text.split(' ').join('');

  if (number.length < 15 || number.length > 16) return false;

  if (number.length === 15 && number.substr(0, 2) !== '34' && number.substr(0, 2) !== '37') {
    return false;
  }

  if (number.length === 16) {
    const firstDigits = parseInt(number.substr(0, 2), 10);

    if (number.substr(0, 1) !== '4' && (firstDigits < 51 || firstDigits > 55)) {
      return false;
    }
  }

  return number.match(/[0-9]/);
};

// CIF
export const isValidCif = (text: string) => {
  // if (!text || text.length !== 9) return false;

  // const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'N', 'P', 'Q', 'R', 'S', 'U', 'V', 'W'];
  // const letter = text.substr(0, 1);
  // const province = text.substr(1, 2);
  // const control = text.substr(text.length - 1);

  // if (letters.indexOf(letter) === -1) return false;

  // if ((['P', 'Q', 'R', 'S', 'W'].indexOf(letter) !== -1 || province === '00') && !control.match(/[A-Z]/)) {
  //   return false;
  // }

  // if (['A', 'B', 'E', 'H'].indexOf(letter) !== -1 && !control.match(/[0-9]/)) {
  //   return false;
  // }

  // const central = text.substr(1, 7);

  // if (Number.isNaN(parseInt(central, 10))) return false;

  // let sum = 0;

  // central.split('').forEach((value, index) => {
  //   if ((index + 1) % 2 === 0) {
  //     sum += parseInt(value, 10);
  //   } else {
  //     let doubleValue = parseInt(value, 10) * 2;
  //     if (doubleValue > 9) doubleValue -= 9;
  //     sum += doubleValue;
  //   }
  // });

  // const units = sum % 10;
  // const digit = units === 0 ? 0 : 10 - units;
  // const relation = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];

  // if (control !== digit.toString() && control !== relation[digit]) return false;

  // return true;
  const cif: string = text.toUpperCase();

  const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
  const match = cif.match(CIF_REGEX);
  if (match === null) {
    return false;
  }
  const letter = match[1];
  const number = match[2];
  const control = match[3];

  let evenSum: any = 0;
  let oddSum: any = 0;
  let n;

  // tslint:disable-next-line
  for (let i = 0; i < number.length; i++) {
    n = parseInt(number[i], 10);
    if (i % 2 === 0) {
      n *= 2;
      oddSum += n < 10 ? n : n - 9;
    } else {
      evenSum += n;
    }
  }

  const controlDigit: any = (10 - (evenSum + oddSum).toString().substr(-1)).toString().substr(-1);
  const controlLetter: any = 'JABCDEFGHI'.substr(controlDigit, 1);

  if (letter.match(/[ABEH]/)) {
    return +control === +controlDigit;
  }
  if (letter.match(/[KPQS]/)) {
    return control === controlLetter;
  }
  // tslint:disable-next-line
  return control === controlDigit || control === controlLetter;
};

// PASSWORD
export const isValidPassword = (text: string) => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[=@$!%*#?&_.;-])[A-Za-z\d=@$!%*#?&_.;-]{6,}$/;

  return text.length > 5; // regex.test(text)
};

export const hasNumber = (text: string) => {
  return /\d/.test(text);
};

export const isNumeric = (text: string) => {
  return /^\d+$/.test(text);
};

// IS UNDER-AGE
export const isUnderAge = (maxAge: number, birthdate: string) => {
  const birthDate = moment(birthdate, 'DD/MM/YYYY').toDate();

  const age = differenceInYears(new Date(), birthDate);
  return age < maxAge;
};

// POSTAL CODE
export const isValidPostalCode = (postalCode: string) => {
  if (!postalCode) {
    return true;
  }
  if (postalCode.length >= 3 && postalCode.length <= 10) return true;
  return false;
  /// ^\d{5}$/.test(postalCode)
};

export const isValidBirthdate = (date: string, type: 'insured' | 'taker') => {
  if (!isValidDate(date, '/')) return false;

  const dateAsDate = moment(date, 'DD/MM/YYYY').toDate();
  if (isFuture(dateAsDate)) return false;
  if (differenceInYears(new Date(), dateAsDate) > 80) return false;

  switch (type) {
    case 'insured':
      return !isUnderAge(12, date);
    case 'taker':
      return !isUnderAge(18, date);
    default:
      break;
  }
};

// URL
// tslint:disable-next-line: max-line-length
export const isValidURL = (url: string) =>
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(url);

export const isValidForm = (errors: {}): boolean => {
  let validForm = true;
  Object.values(errors).forEach(error => (validForm = validForm && (error === '' || !error)));
  return validForm;
};

export const isValidIBAN = (iban: string): boolean =>
  /([A-Z]{2})\s*\t*(\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d\d\d)\s*\t*(\d\d)\s*\t*(\d\d\d\d\d\d\d\d\d\d)/.test(iban);
