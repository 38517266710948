import styled from 'styled-components';

export const NotificationsStyle = styled.div`

  .notification-filters {
    display: flex;
    justify-content: flex-end;
    &__item {
      margin-left: 12px;
      width: 213px;
    }
  }

  .notifications-section {
    margin-bottom: 60px;
    &__title {
      margin-bottom: 24px;
    }
    &__row { 

    }
  }
`;
