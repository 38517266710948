import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const PlanningPreferencesStyle = styled.div`
  .planning-preferences-subtitle {
    margin-bottom: 4px;
  }

  .planning-preferences-text  {
    color: ${colors['NE-002/100']};
    margin-bottom: 14px;
  }
  /* .planning-preferences-period {
    margin-bottom: 40px;
    .input-box-main-field {
      border: 0px;
    }
    .complete {
      .input-box-main-field {
        padding: 0px;
      }
    }
  } */

  .planning-preferences-ckeck {
    &__item {
      margin-bottom: 16px;
    }
  }
`;
