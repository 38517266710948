import { TableColumn } from '../../../../types/table';

export interface DiverDive {
  id: string;
  date: string;
  activity?: string;
  kind: string;
  site: string;
  centre?: string;
  validated: boolean;
  discipline: string;
  type: string;
}

export interface DiverDiveStats {
  diveCount: number;
  diveNotValidatedCount: number;
  lastDiveDate: string;
}

export const tableColumns: TableColumn[] = [
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Fecha',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Actividad',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Tipo',
    filter: false,
    ascendent: false,
  },
  {
    text: 'Punto Inmersión',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Centro',
    filter: false,
    ascendent: true,
  },
  // {
  //   text: 'Validado por',
  //   filter: false,
  //   ascendent: true,
  // },
  // {
  //   text: 'Estado',
  //   filter: false,
  //   ascendent: true,
  // },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
];

export const rowKeys: string[] = ['check', 'date', 'type', 'kind', 'site', 'centre', 'detail'];
// export const rowKeys: string[] = ['check', 'date', 'type', 'kind', 'site', 'centre', 'validate', 'status', 'detail'];
