import React from 'react';
import { ModalManageDiveLevelsStyle } from './modal-manage-dive-levels-style';
import ModalContainer from '../../../../components/modals/modal-container';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../../../components/text/body-text/body-text';
import { FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import InputBox from '../../../../components/input-box';
import MainButton from '../../../../components/main-button';
import { images } from '../../../../assets/images';
import SelectBox from '../../../../components/select-box';
import SingleButton from '../../../../components/single-button/single-button';
import { deleteLevels, uploadLevels, createLevels } from '../../../../services/levels';

interface ModalManageDiveLevelsProps {
  showModal: boolean;
  onCancel: () => void;
  onAccept: Function;
  levels: any[];
}
interface ModalManageDiveLevelsState {
  levels: any[];
  level: any;
  levelsDeleted: string[];
}

const INIT_LEVEL = {
  name: {
    value: '',
    error: false,
    errorCode: '',
    required: true,
  },
  level: {
    value: '',
    error: false,
    errorCode: '',
    required: true,
  },
};

export default class ModalManageDiveLevels extends React.Component<
  ModalManageDiveLevelsProps,
  ModalManageDiveLevelsState
> {
  readonly state: ModalManageDiveLevelsState;
  constructor(props: ModalManageDiveLevelsProps) {
    super(props);
    this.state = {
      level: JSON.parse(JSON.stringify(INIT_LEVEL)),
      levels: props.levels.map((item: any) => ({
        name: {
          value: item.name,
          error: false,
          errorCode: '',
          required: true,
        },
        level: {
          value: item.level.toString(),
          error: false,
          errorCode: '',
          required: true,
        },
        id: item.id,
        default: item.default,
      })),
      levelsDeleted: [],
    };
  }

  changeValue = (value: string, key: string, subKey?: number) => {
    const { level, levels } = this.state;
    if (subKey === null || subKey === undefined) {
      level[key].value = value;
      this.setState({ level });
    } else {
      levels[subKey][key].value = value;
      this.setState({ levels });
    }
  };

  handleAdd = () => {
    const { level, levels } = this.state;
    let isOk = true;
    Object.keys(level).forEach((k: string) => {
      if (level[k].required && level[k].value === '') {
        level[k].error = true;
        level[k].errorCode = 'required';
        isOk = false;
      } else {
        level[k].error = false;
        level[k].errorCode = '';
      }
    });
    if (isOk) {
      const levelPush = JSON.parse(JSON.stringify(level));
      levels.push(levelPush);
      this.setState({ levels, level: JSON.parse(JSON.stringify(INIT_LEVEL)) });
    } else {
      this.setState({ level });
    }
  };

  handleDelete = (index: number) => {
    const { levels, levelsDeleted } = this.state;
    if (levels[index].id) {
      levelsDeleted.push(levels[index].id);
    }
    levels.splice(index, 1);

    this.setState({ levelsDeleted, levels });
  };

  handleAccept = async () => {
    const { levels, levelsDeleted } = this.state;
    const { levels: propsLevels, onAccept } = this.props;
    const levelsOld = propsLevels.filter((i: any) => levelsDeleted.findIndex(d => d === i.id) === -1);
    // GUARDAR NUEVOS / ACTUALZIAR CAMBIOS / BORRAR
    try {
      for (const id of levelsDeleted) {
        await deleteLevels(id);
      }
      for (const item of levels) {
        const search = levelsOld.find((o: any) => o.id === item.id) || {};

        const data = {
          name: item.name.value || search.name,
          level: parseInt(item.level.value, 10),
        };
        if (item.id && (search.name !== data.name || search.level !== data.level)) {
          await uploadLevels(item.id, data);
        } else if (!item.id) {
          await createLevels(data);
        }
      }
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { showModal, onCancel, onAccept } = this.props;
    const { levels, level } = this.state;
    const sortedLevels = levels.sort((a: any, b: any) => {
      return +a.level.value - +b.level.value;
    });

    return (
      <ModalContainer title={``} active={showModal} modalClose={() => onCancel()}>
        <ModalManageDiveLevelsStyle>
          <div className="modal-change-pass-title">
            <SubheadDestacadoText>Administrar niveles de buceo</SubheadDestacadoText>
          </div>
          <div className="modal-change-pass-text">
            <BodyText>
              Gestiona y crea los niveles de buceo que cree convenientes para que los usuarios conozcan la equivalencia
              de su nivel de buceo en la plataforma.
            </BodyText>
          </div>
          <div className="modal-change-pass-form">
            <div className="modal-change-pass-form-level">
              {sortedLevels.map((item: any, index: number) => (
                <div key={`level-${index}`} className="modal-change-pass-form-level-row">
                  <div className="modal-change-pass-form-level-row__input">
                    <InputBox
                      placeholder="Nombre"
                      className="rounded"
                      labelText="País"
                      required={item.name.required}
                      disabled={item.default}
                      errorCode={item.name.errorCode}
                      value={item.name.value}
                      onChange={(value: string) => this.changeValue(value, 'name', index)}
                    />
                  </div>
                  <div className="modal-change-pass-form-level-row__select">
                    <SelectBox
                      labelText=""
                      disabled={item.default}
                      placeholder="Nivel"
                      className="rounded"
                      optionsText={[
                        { label: '01', value: '1' },
                        { label: '02', value: '2' },
                        { label: '03', value: '3' },
                        { label: '04', value: '4' },
                        { label: '05', value: '5' },
                        { label: '06', value: '6' },
                        // { label: '7', value: '7' },
                        // { label: '8', value: '8' },
                        // { label: '9', value: '9' },
                        // { label: '10', value: '10' },
                      ]}
                      optionKey={'value'}
                      optionValue={'label'}
                      required={item.level.required}
                      errorCode={item.level.errorCode}
                      initialValue={item.level.value}
                      onChange={(value: string) => this.changeValue(value, 'level', index)}
                    />
                  </div>
                  {!item.default && (
                    <div className="modal-change-pass-form-level-row__delete">
                      <SingleButton
                        icon={images.deleteSvg}
                        onClick={() => this.handleDelete(index)}
                        className={' modal'}
                      />
                    </div>
                  )}
                </div>
              ))}

              <div className="manage-dive-levels-line" />

              <div className="modal-change-pass-form-level-row">
                <div className="modal-change-pass-form-level-row__full">
                  <InputBox
                    placeholder="Nombre"
                    className="rounded-title"
                    labelText="Nombre del Nivel"
                    required={level.name.required}
                    errorCode={level.name.errorCode}
                    value={level.name.value}
                    onChange={(value: string) => this.changeValue(value, 'name')}
                  />
                </div>
              </div>

              <div className="modal-change-pass-form-level-row">
                <div className="modal-change-pass-form-level-row__scale">
                  <SelectBox
                    labelText="Escala"
                    placeholder="Nivel"
                    className="rounded-title"
                    optionsText={[
                      { label: '01', value: '1' },
                      { label: '02', value: '2' },
                      { label: '03', value: '3' },
                      { label: '04', value: '4' },
                      { label: '05', value: '5' },
                      { label: '06', value: '6' },
                      // { label: '7', value: '7' },
                      // { label: '8', value: '8' },
                      // { label: '9', value: '9' },
                      // { label: '10', value: '10' },
                    ]}
                    optionKey={'value'}
                    optionValue={'label'}
                    required={level.level.required}
                    errorCode={level.level.errorCode}
                    initialValue={level.level.value}
                    onChange={(value: string) => this.changeValue(value, 'level')}
                  />
                </div>

                <div className="modal-change-pass-form-level-row__add">
                  <MainButton
                    iconImg={images.addSvg}
                    onClick={() => this.handleAdd()}
                    text="Añadir"
                    type="secondary full-height"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal-change-pass-button">
            <MainButton onClick={() => onAccept(() => this.handleAccept())} text="Guardar" type="primary" />
          </div>
        </ModalManageDiveLevelsStyle>
      </ModalContainer>
    );
  }
}
