import React, { Component } from 'react';
import { MainLayout, MainLayoutContent } from '../containers/main-layout-style';
import HomeMenu from '../components/simple-menu/home-menu';
import { Switch, Route } from 'react-router';
import CentresFuncional from '../containers/centres/centres-functional';
import RolesFunctional from '../containers/roles/centres/roles-functional';
import CentresSuperadminFunctional from '../containers/centres/super-admin/centres-superadmin-functional';
import { isAllowed } from '../roles/can-component';
import IStoreState from '../redux/store/IStoreState';
import { connect } from 'react-redux';
import ConfigurationContact from '../containers/configuration/contact/configuration-contact';
import AdsLayout from '../containers/ads/ads';
import ConfigurationTemplate from '../containers/configuration/mail-template/configuration-template';
import ConfigurationFee from '../containers/configuration/fee/configuration-fee';
import Collaborators from '../containers/collaborators/collaborators-functional';
import UsersSuperAdminFuncional from '../containers/divers/super-admin/users-superadmin-functional';
import ConfigurationTemplateDetail from '../containers/configuration/mail-template/mail-template-detail/configuration-template-detail';
import AdsDetailLayout from '../containers/ads/detail/ads-detail';
import Incidences from '../containers/incidences/incidences';
import DiveLevels from '../containers/configuration/dive-levels/dive-levels';
import Taxes from '../containers/configuration/taxes/taxes';
import MeasurementUnits from '../containers/configuration/measurement-units/measurement-units';
import Bonuses from '../containers/billing/bonuses/bonuses-layout';
import BillsSuperAdmin from '../containers/billing/bills/bills-superadmin/bills-superadmin-layout';
import Notifications from '../containers/notifications/notifications';
import NotificationsDetail from '../containers/notifications/detail/notifications-detail';
import MyProfile from '../containers/my-profile/my-profile';
import Bookings from '../containers/billing/bookings/bookings';
import Destination from '../containers/configuration/destination/destination';
import CancellationPolicy from '../containers/configuration/cancellation-policy/cancellation-policy';
import StatsLayout from '../containers/stats/stats-layout';
import StatsCertifierLayout from '../containers/stats/stats-certifier-layout';
import DiveSite from '../containers/dive-site/dive-site-layout/dive-site';
import DiverDetail from '../containers/divers/detail/diver-detail';
import DiveSiteDetailSwitch from '../containers/dive-site/dive-site-detail-switch/dive-site-detail-switch';
import MarineReserve from '../containers/dive-site/marine-reserve-layout/marine-reserve';
import DiverDiveDetail from '../containers/divers/detail/dives/detail/diver-dive-detail';
import MarineReserveDetailSwitch from '../containers/dive-site/marine-reserve-detail-switch/marine-reserve-detail-switch';
import Roles from '../containers/roles/roles';
import DiveActivitiesDetail from '../containers/divers/detail/activities/detail/dive-activities-detail';
import CertifierProfileLayout from '../containers/certifier/certifier-profile/certifier-profile';
import CertifierCatalog from '../containers/certifier/certifier-catalog/certifier-catalog-layout';
import CertifierProject from '../containers/certifier/certifier-project/certifier-project-layout';
import CertifierCenterDetail from '../containers/certifier/certifier-center/certifier-center-detail/certifier-center-detail-layout';
import CertifierCenter from '../containers/certifier/certifier-center/certifier-center-layout';
import CertifierFaq from '../containers/certifier/certifier-faq/certifier-faq-layout';
import CertifierDivers from '../containers/certifier/certifier-divers/certifier-divers-layout';
import OrganizationDonations from '../containers/certifier/organization-donations/organization-donations';
import CertifierRoles from '../containers/certifier/certifier-roles/certifier-roles';
import RenderModals from '../utils/render-modals/render-modals';
import NotFoundContainer from '../components/not-found/not-found';
import { getOneUsers } from '../services/users';
import CertifierCatalogDetail from '../containers/certifier/certifier-catalog/certifier-catalgo-detail/certifier-catalog-detail';
import Chat from '../containers/messages/chat/chat';
import CertifierProjectDetail from '../containers/certifier/certifier-project/certifier-project-detail/certifier-project-detail';
import StatsOrganizationLayout from '../containers/stats/stats-organization-layout';
import BlankPage from '../components/blank-page/blank-page';
import BillsSuperAdminDetail from '../containers/billing/bills/bills-superadmin/bills-superadmin-detail/bills-superadmin-detail';
import BillingInformation from '../containers/billing/billing-information/billing-information';


interface HomeProps {
  agent?: any;
  onLogout: () => void;
  profilePicture?: string;
}

interface HomeState {
  isCertifier: boolean;
  isStaff: boolean;
  loading: boolean;
}

class Home extends Component<HomeProps, HomeState> {
  static basePath = '';

  constructor(props: HomeProps) {
    super(props);
    this.state = {
      isCertifier: true,
      loading: true,
      isStaff: false,
    };
  }

  componentDidMount() {
    const { agent } = this.props;
    if (agent && agent.roles && (agent.roles.includes('organization_owner') || agent.roles.includes('organization_staff'))) {
      this.setOrganizationId();
    } else if (agent.roles && agent.roles.includes('diver')) {
      this.getUser();
    } else if (!!agent.roles) {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps: HomeProps) {
    const { loading } = this.state;
    const { agent } = this.props;

    
    if (
      agent &&
      agent.roles &&
      prevProps.agent !== agent &&
      (agent.roles.includes('organization_owner') || agent.roles.includes('organization_staff'))
    ) {
      this.setOrganizationId();
    } else if (agent && agent.roles && prevProps.agent !== agent && agent.roles.includes('diver')) {
      this.getUser();
    } else if (loading && !!agent.roles && prevProps.agent !== agent) {
      this.setState({ loading: false });
    }
  }

  getUser = async () => {
    const { agent } = this.props;
    const params = {
      filter: {
        relations: ['diver'],
      },
    };
    const userId: string | null = localStorage.getItem('userId');

    if (userId) {
      const resp = await getOneUsers(userId, params);
      if (resp.data.diver) {
        localStorage.setItem('diverId', resp.data.diver.id);
        this.setState({ isStaff: resp.data.diver.isStaff, loading: false });
      } else {
        this.setState({ isStaff: false, loading: false });
      }
    }
  };

  async setOrganizationId() {
    const { agent } = this.props;
    let params = {
      filter: {
        relations: ['organizations', 'organizations.courses'],
      },
    };
    if (agent.roles.includes('organization_staff')) {
      params = {
        filter: {
          relations: ['diver', 'diver.staff_organizations', 'staff_organizations.organization'],
        },
      };
    }
    const userId: string | null = localStorage.getItem('userId');
    let isCertifier = false;
    if (userId) {
      const resp = await getOneUsers(userId, params);
      const organization =
        agent.roles.includes('organization_staff')
          ? resp.data.diver.staff_organizations[0].organization
          : resp.data.organizations[0];
      if (organization) {
        const organizationId = organization ? organization.id.toString() : null;
        isCertifier = organization.type !== 'marineConservation';
        localStorage.setItem('organizationId', organizationId);
        if (isCertifier) {
          localStorage.setItem('isCertifier', isCertifier.toString());
        }
      } else {
        this.props.onLogout();
      }
      this.setState({ isCertifier, loading: false });
    }
  }

  getStartPage() {
    const { agent } = this.props;

    const { isCertifier, loading, isStaff } = this.state;



    if (loading) return BlankPage;
    if (agent.roles.includes('superAdmin')) {
      return CentresSuperadminFunctional;
    } else if (agent.roles.includes('divingCenter_admin') || agent.roles.includes('divingCenter_owner')) {
      return CentresFuncional;
    } else if (agent.roles.includes('organization_staff') || agent.roles.includes('organization_owner')) {
      return isCertifier ? CertifierCatalog : CertifierProject;
    } else if (agent.roles.includes('divingCenter_staff')) {
      return CentresFuncional;
    } else if (agent.roles.includes('diver')) {
      return BlankPage;
    } else {
      return BlankPage;
    }
  }

  render() {
    const { isCertifier, loading } = this.state;
    const { agent, profilePicture } = this.props;
    const basePath = Home.basePath;

    if (loading) return <BlankPage />;
    return (
      <MainLayout>
        {/* Menu */}
        {!loading && (
          <HomeMenu onLogout={this.props.onLogout} isCertifier={isCertifier} profilePicture={profilePicture} />
        )}
        {/* MainLayout */}
        <MainLayoutContent>
          <Switch>
            <Route exact={true} path={'/'} component={this.getStartPage()} />

            {/* Certificadoras */}
            {/* Certificadoras */}
            {isAllowed(agent.roles, 'organization:management') && (
              <Route path={`${basePath}/profile`} component={CertifierProfileLayout} />
            )}
            {isAllowed(agent.roles, 'organization:management') && !isCertifier && (
              <Route path={`${basePath}/projects/:id`} component={CertifierProjectDetail} />
            )}
            {isAllowed(agent.roles, 'organization:management') && !isCertifier && (
              <Route path={`${basePath}/projects`} component={CertifierProject} />
            )}
            {isAllowed(agent.roles, 'organization:management') && isCertifier && (
              <Route path={`${basePath}/catalog/:id`} component={CertifierCatalogDetail} />
            )}
            {isAllowed(agent.roles, 'organization:management') && isCertifier && (
              <Route path={`${basePath}/divers/:diver/dives/:diveId`} component={DiverDiveDetail} />
            )}
            {isAllowed(agent.roles, 'organization:management') && (
              <Route path={`${basePath}/divers/:id`} component={DiverDetail} />
            )}
            {isAllowed(agent.roles, 'organization:management') && (
              <Route path={`${basePath}/organization/billings`} component={BillingInformation} />
            )}
            {isAllowed(agent.roles, 'organization:management') && isCertifier && (
              <Route path={`${basePath}/catalog`} component={CertifierCatalog} />
            )}
            {isAllowed(agent.roles, 'organization:management') && isCertifier && (
              <Route path={`${basePath}/centres/:id`} component={CertifierCenterDetail} />
            )}
            {isAllowed(agent.roles, 'organization:management') && isCertifier && (
              <Route path={`${basePath}/centres`} component={CertifierCenter} />
            )}
            {isAllowed(agent.roles, 'organization:management') && isCertifier && (
              <Route path={`${basePath}/divers`} component={CertifierDivers} />
            )}
            {isAllowed(agent.roles, 'organization:management') && !isCertifier && (
              <Route path={`${basePath}/divers`} component={OrganizationDonations} />
            )}
            {isAllowed(agent.roles, 'organization:management') && (
              <Route path={`${basePath}/configuration`} component={CertifierFaq} />
            )}
            {isAllowed(agent.roles, 'organization:management') && (
              <Route path={`${basePath}/stats`} component={StatsCertifierLayout} />
            )}
            {isAllowed(agent.roles, 'organization:management') && (
              <Route path={`${basePath}/organization-stats`} component={StatsOrganizationLayout} />
            )}
            {(isAllowed(agent.roles, 'organization:management') ||
              isAllowed(agent.roles, 'notifications:management')) && (
                <Route path={`${basePath}/messages`} component={Chat} />
              )}

            <Route path={`${basePath}/notifications/detail/:id`} component={NotificationsDetail} />
            <Route path={`${basePath}/notifications`} component={Notifications} />

            

            {isAllowed(agent.roles, 'organization:management') && !agent.roles.includes('organization_staff') && isCertifier && (
              <Route path={`${basePath}/roles`} component={CertifierRoles} />
            )}

            {/* Certificadoras */}
            {/* Certificadoras */}
            {isAllowed(agent.roles, 'centres:management') && (
              <Route path={`${basePath}/centres`} component={CentresFuncional} />
            )}

            {isAllowed(agent.roles, 'centres:management') && (
              <Route path={`${basePath}/roles`} component={RolesFunctional} />
            )}

            {isAllowed(agent.roles, 'roles:management') && <Route path={`${basePath}/roles`} component={Roles} />}

            {/* {isAllowed(agent.roles, 'roles:management') && (
              <Route path={`${basePath}/roles/:id`} component={CentreRolesDetail} />
            )} */}

            {isAllowed(agent.roles, 'companies:management') && (
              <Route path={`${basePath}/companies`} component={CentresSuperadminFunctional} />
            )}

            {isAllowed(agent.roles, 'users:management') && (
              <Route path={`${basePath}/dives/:diveId`} component={DiverDiveDetail} />
            )}

            {isAllowed(agent.roles, 'users:management') && (
              <Route path={`${basePath}/users/detail/:id/dives/:diveId`} component={DiverDiveDetail} />
            )}

            {isAllowed(agent.roles, 'users:management') && (
              <Route path={`${basePath}/users/detail/:id`} component={DiverDetail} />
            )}

            {isAllowed(agent.roles, 'users:management') && (
              <Route path={`${basePath}/users`} component={UsersSuperAdminFuncional} />
            )}

            {isAllowed(agent.roles, 'users:management') && (
              <Route path={`${basePath}/activity/:id`} component={DiveActivitiesDetail} />
            )}

            {isAllowed(agent.roles, 'collaborators:management') && (
              <Route path={`${basePath}/collaborators`} component={Collaborators} />
            )}

            {isAllowed(agent.roles, 'dive-sites:management') && (
              <Route path={`${basePath}/dive-sites/dive-site/detail/:id`} component={DiveSiteDetailSwitch} />
            )}

            {isAllowed(agent.roles, 'dive-sites:management') && (
              <Route path={`${basePath}/dive-sites/dive-site`} component={DiveSite} />
            )}

            {isAllowed(agent.roles, 'dive-sites:management') && (
              <Route path={`${basePath}/dive-sites/marine-reserve/detail/:id`} component={MarineReserveDetailSwitch} />
            )}
            {isAllowed(agent.roles, 'dive-sites:management') && (
              <Route path={`${basePath}/dive-sites/marine-reserve`} component={MarineReserve} />
            )}

            {isAllowed(agent.roles, 'incidences:management') && (
              <Route path={`${basePath}/incidences`} component={Incidences} />
            )}

            {/* Stats */}
            {isAllowed(agent.roles, 'stats:management') && <Route path={`${basePath}/stats`} component={StatsLayout} />}

            {/* Ads */}
            {isAllowed(agent.roles, 'ads:management') && (
              <Route path={`${basePath}/ads/detail/:id`} component={AdsDetailLayout} />
            )}
            {isAllowed(agent.roles, 'ads:management') && <Route path={`${basePath}/ads`} component={AdsLayout} />}

            {/* Billings */}

            {isAllowed(agent.roles, 'billings:management') && (
              <Route exact={true} path={`${basePath}/billings/bills`} component={BillsSuperAdmin} />
            )}

            {isAllowed(agent.roles, 'billings:management') && (
              <Route path={`${basePath}/billings/bills/:billId`} component={BillsSuperAdminDetail} />
            )}

            {isAllowed(agent.roles, 'billings:management') && (
              <Route path={`${basePath}/billings/bookings`} component={Bookings} />
            )}

            {/* El layout es casi el mismo que el de BILLs */}
            {isAllowed(agent.roles, 'billings:management') && (
              <Route path={`${basePath}/billings/bonuses`} component={Bonuses} />
            )}

            {/* Configuration */}


            {isAllowed(agent.roles, 'configuration:management') && (
              <Route path={`${basePath}/configuration/contact`} component={ConfigurationContact} />
            )}

            {isAllowed(agent.roles, 'configuration:management') && (
              <Route
                path={`${basePath}/configuration/mail-template/detail/:templateName`}
                component={ConfigurationTemplateDetail}
              />
            )}
            {isAllowed(agent.roles, 'configuration:management') && (
              <Route path={`${basePath}/configuration/mail-template`} component={ConfigurationTemplate} />
            )}
            {isAllowed(agent.roles, 'configuration:management') && (
              <Route path={`${basePath}/configuration/fee`} component={ConfigurationFee} />
            )}
            {isAllowed(agent.roles, 'configuration:management') && (
              <Route path={`${basePath}/configuration/dive-levels`} component={DiveLevels} />
            )}
            {isAllowed(agent.roles, 'configuration:management') && (
              <Route path={`${basePath}/configuration/taxes`} component={Taxes} />
            )}
            {isAllowed(agent.roles, 'configuration:management') && (
              <Route path={`${basePath}/configuration/destination`} component={Destination} />
            )}
            {isAllowed(agent.roles, 'configuration:management') && (
              <Route path={`${basePath}/configuration/cancellation-policy`} component={CancellationPolicy} />
            )}
            {isAllowed(agent.roles, 'configuration:management') && (
              <Route path={`${basePath}/configuration/measurement-units`} component={MeasurementUnits} />
            )}

            {/* Profile */}
            <Route path={`/render-modals`} component={RenderModals} />
            {/* {isAllowed(agent.roles, 'profile:management') && ( */}
            <Route path={`${basePath}/my-profile`} component={MyProfile} />
            {/* )} */}

            {/* 404 */}
            {!loading && <Route path="/" component={NotFoundContainer} />}
          </Switch>
        </MainLayoutContent>
      </MainLayout>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(Home);