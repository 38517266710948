import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const MainStateContainer = styled.table`
  .state-center {
    background-color: ${colors['CO-004/15']};
    color: ${colors['CO-004/100']};
    text-transform: uppercase;
    border-radius: 3px;
    font-size: 10px;
    line-height: 14px;
    padding: 8px 10px 10px 10px;
  }

  .state-certificate {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 14px;
    padding: 8px 10px 10px 10px;
    background: ${colors['NE-002/10']};
    color: ${colors['SE-003/100']};
    border-radius: 3px;
  }
`;

export default MainStateContainer;
