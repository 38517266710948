import * as React from 'react';
import { DiveOffersActivitiesStyle } from './dive-offers-activities-style';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import { images } from '../../../assets/images';
import MainButton from '../../../components/main-button';
import MainTable from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import ModalCreateActivities from './modal-create-activities/modal-create-activities';
import { RouteComponentProps, match, withRouter } from 'react-router';
import { getActivityTemplates } from '../../../services/centre';
import { IActivityTemplate } from '../../../models/activity-template';
import { copyOf, createFilterRequest, mergeObject } from '../../../utils/other';
import {
  DiveDisciplinesKeyValues,
  DiveDisciplines,
  DiveTypesKeyValues,
  DiveTypes,
} from '../../../constants/dive-dropdowns';
import { CENTRE_DIVE_OFFERS_PER_PAGE } from '../../../constants/data';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import ModalUnplaning from '../../../components/modals/modal-unplaning/modal-unplaning';
import { deleteActivityTemplate, unplaningActivityTemplate } from '../../../services/activity-templates';
import services from '../../../services/services';
import ModalPlanningActivity from '../../planning/modal-planning-activity/modal-planning-activity';
import SelectBox from '../../../components/select-box';
import { ContentBlock } from '../../../components/content-block/content-block';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { havePermissionsActivities } from '../../../services/staff-centre';
import notifications from '../../notifications/notifications';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';

export interface DiveOffersActivitiesProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
    diver: string;
  }>;
  t: any, 
  staffPermissions?: any;
}

export interface DiveOffersActivitiesState {
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
  activeFilter: boolean;
  renderModal: string;
  selectedId: string;
  activityTemplates?: IActivityTemplate[];
  selectedTemplate?: IActivityTemplate;
  filterDiscipline: string;
  filterName: any;
  filterType: string;
  filterSites: any;
  filterIds: string;
  searchValue: string | null;
  canDeleteCurrentEmplate: boolean;
  activitiesIds: any;
  activitiesNames: any;
}

class DiveOffersActivities extends React.Component<DiveOffersActivitiesProps, DiveOffersActivitiesState> {
  constructor(props: DiveOffersActivitiesProps) {
    super(props);

    this.state = {
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
      selectedId: '',
      activeFilter: false,
      renderModal: '',
      filterDiscipline: '',
      filterType: '',
      filterName: '',
      filterIds: '',
      filterSites: [],
      searchValue: null,
      canDeleteCurrentEmplate: false,
      activitiesIds: [],
      activitiesNames: []
    };
  }

  componentDidMount() {
    this.getActivityTemplates();
  }

  async getActivityTemplates() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const { filterDiscipline, filterType, searchValue, filterSites, filterIds, filterName } = this.state;
    const filters: any = {};
    if (filterDiscipline !== '' && filterDiscipline !== '*') {
      filters.discipline = { value: filterDiscipline };
    }

    if (filterType !== '' && filterType !== '*') {
      filters.courseInfo = { value: null, method: filterType === 'dive' ? 'is' : 'is not' };
    }

    if (filterName !== '' && filterName !== '*' && filterName) {
      filters.name = { value: filterName };
    }

    if (filterIds !== '' && filterIds !== '*') {
      filters.id = { value: Number(filterIds) };
    }

    if (searchValue) {
      filters.name = { value: searchValue };
    }
    const { skipPage } = this.state;
    const params = {
      filter: {
        where: createFilterRequest(filters),
        //relations: ['course', 'course.certifier', 'services', 'stock', 'kind'],
        //relations: ['course', 'course.certifier', 'services', 'stock', 'kind'],
        skip: skipPage,
        limit: CENTRE_DIVE_OFFERS_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
      },
    };
    const params2 = {
      filter: {
        relations: ['category'],
        //relations: ['category', 'exclusiveStock', 'exclusiveServices', 'cancellationPolicy'],
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
      },
    };
    const params3 = {
      filter: {
        where: { deleted: false },
        //relations: ['activities', 'sites'],
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
      },
    };

    //>= new Date()
    const resp = await getActivityTemplates(id, params);
    const resp2 = await getActivityTemplates(id, params2);
    //const resp3 = await getActivityTemplates(id, params3);
    //const activityTemplates: IActivityTemplate[] = mergeObject(mergeObject(resp.data.data, resp2.data.data), resp3.data.data);

    const d = resp.data.data;
    const d2 = resp2.data.data;
    //const d3 = resp3.data.data;
    for (let x = 0; x < d.length; x++) {
      d[x].category = d2[x].category;
      //d[x].exclusiveStock = d2[x].exclusiveStock;
      //d[x].exclusiveServices = d2[x].exclusiveServices;
      //d[x].cancellationPolicy = d2[x].cancellationPolicy;
      //d[x].activities = d3[x].activities;
      //d[x].sites = d3[x].sites;
    }
    const totalPages: number = Math.ceil(resp.data.count / CENTRE_DIVE_OFFERS_PER_PAGE);
    const activityTemplates: IActivityTemplate[] = d;

    activityTemplates.map((at: any) => at.category ? at.category = at.category.id : '');

    this.setState({ activityTemplates, totalPages, total: resp.data.count });
  }

  getTableRows() {
    const { staffPermissions } = this.props;
    const { activityTemplates, activitiesIds, activitiesNames } = this.state;

    const tableRows: any[] = [];
    //let allSites: any = [];

    if (!activityTemplates) return [];
    activityTemplates.forEach((activityTemplate: IActivityTemplate) => {
      const tableRow = copyOf(this.tableRowTemplate);

      if (this.state.filterIds === '' && this.state.filterDiscipline === '' && this.state.filterName === '' && this.state.filterType === '') {
        this.state.activitiesIds.push({ "label": activityTemplate.id.toString(), "value": activityTemplate.id.toString() })
        this.state.activitiesNames.push({ "label": activityTemplate.name, "value": activityTemplate.name })
      }

      //tableRow.id.value = activityTemplate.id;
      tableRow.name.value = activityTemplate.name;
      tableRow.model.value = activityTemplate.discipline ? DiveDisciplinesKeyValues[activityTemplate.discipline] : '-';
      tableRow.type.value = DiveTypesKeyValues[activityTemplate.courseInfo ? 'training' : 'dive'];


      /*let sites: string[] = [];
      if(activityTemplate.sites && activityTemplate.sites.length > 0){
          for(let i = 0; i < activityTemplate.sites.length; i++){
            sites.push(activityTemplate.sites[i].name)
            allSites.push({value: activityTemplate.sites[i].id, label: activityTemplate.sites[i].name})
          }

        }*/
      /*tableRow.sites.value = {
        title: 'Puntos de inmersión',
        items: sites,
      };*/
      //tableRow.site.value = activityTemplate.discipline.;
      // if (activityTemplate.kind && activityTemplate.kind.length > 1 && activityTemplate.kind.map) {
      //   tableRow.count.value = {
      //     title: 'DISCIPLINAS',
      //     items: activityTemplate.discipline.map(discipline => DiveDisciplines[discipline]),
      //   };
      // }
      if ((!staffPermissions || staffPermissions.activities)) {
        tableRow.planning.onClick = () => {
          this.setState({ renderModal: 'modal-plan-activity', selectedId: activityTemplate.id.toString() });
        };
      } else {
        tableRow.planning = {
          key: '',
          value: ' ',
        };
      }

      if (!havePermissionsActivities()) {
        tableRow.planning = {
          key: '',
          value: ' ',
        };
      }

      tableRow.actions.actions[0].onClick = () => {
        this.setState({ selectedTemplate: activityTemplate, renderModal: 'modal-create-activity' });
      };
      tableRow.actions.actions[1].onClick = () => {

        if (activityTemplate.activities && activityTemplate.activities.length !== 0) {
          services.pushNotification({
            title: '¡La actividad no se puede eliminar!',
            text: 'La actividad contiene planificaciones, debe eliminarlas primero',
            type: 'red',
          });
        } else {
          this.setState({ selectedTemplate: activityTemplate, renderModal: 'modal-delete-activity' });
        }
      };


      tableRow.actions.actions[2].onClick = () => {

        if (activityTemplate.activities && activityTemplate.activities.length !== 0) {
          services.pushNotification({
            title: '¡La actividad no se puede desplanificar!',
            text: 'La actividad contiene planificaciones, debe eliminarlas primero',
            type: 'red',
          });
        } else {
          this.setState({ selectedTemplate: activityTemplate, renderModal: 'modal-unplaning-activity' });
        }
      };

      if (!havePermissionsActivities()) {
        tableRow.actions = {
          key: '',
          value: '',
        };
      }

      tableRow.view.link = `/centres/${this.props.match.params.id}/offers/activities/${activityTemplate.id}`;
      tableRows.push(tableRow);
    });

    //let sites: string[] = [];
    /*
    for(var i = allSites.length -1; i >=0; i--){
      if(allSites.indexOf(allSites[i]) !== i) allSites.splice(i,1);
    }
  
    //this.setState({filterSites: allSites})
    if(this.state.filterSites.length == 0){
      this.setState({filterSites: allSites})
    }*/
    return tableRows;
  }

  async handleRemove() {
    const { selectedTemplate } = this.state;
    if (selectedTemplate) {
      await deleteActivityTemplate(selectedTemplate.id.toString());
      services.pushNotification({
        title: '¡Eliminado!',
        text: 'Actividad eliminada con éxito',
        type: 'red',
      });
      await this.closeModal();
      this.getActivityTemplates();
    }
  }


  async handleUnplaning() {
    const { selectedTemplate } = this.state;
    if (selectedTemplate) {
      await unplaningActivityTemplate(selectedTemplate.id.toString());
      services.pushNotification({
        title: 'Desplanificada!',
        text: 'Actividad desplanificada con éxito',
        type: 'red',
      });
      await this.closeModal();
      this.getActivityTemplates();
    }
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CENTRE_DIVE_OFFERS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ skipPage: skip, page: page }, () => {
        this.getActivityTemplates();
      });
    }
  }

  closeModal() {
    return new Promise((res, rej) => {
      this.setState({ renderModal: '', selectedTemplate: undefined }, () => {
        res('');
      });
    });
  }

  searchTimeout: any;
  handleSearch(value: string | null) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState({ searchValue: value, page: 1, skipPage: 0 }, () => {
        this.getActivityTemplates();
      });
    }, 500);
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t, 
      staffPermissions,
    } = this.props;

    const { activeFilter, renderModal, totalPages, selectedTemplate, selectedId, page, total, filterSites, activitiesIds, filterIds, activitiesNames, filterName } = this.state;
    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={t('layouts:stats.activities')}
            withSearch={true}
            resultCount={total}
            handleSearch={(value: string | null) => this.handleSearch(value)}
            mainButton={
              !staffPermissions || staffPermissions.activities
                ? {
                  disabled: !havePermissionsActivities(),
                  img: images.createWhiteSvg,
                  text: 'Crear', // "validar" cuando se marcan los check de la tabla
                  onClick: () => this.setState({ renderModal: 'modal-create-activity' }),
                }
                : undefined
            }
          />
          <MainLayoutSection>
            <DiveOffersActivitiesStyle>
              <div className="dive-offers-activities-head">
                <div className="dive-offers-activities-head__filter">
                  <MainButton
                    onClick={() => this.setState({ activeFilter: !activeFilter })}
                    type={activeFilter ? 'secondary-active' : 'secondary'}
                    text="Filtrar"
                    iconImg={activeFilter ? images.filtersLightBlueSvg : images.filtersSvg}
                  />
                </div>
              </div>
              {activeFilter && (
                <div className={`dive-offers-activities-filters${activeFilter ? ' active' : ''}`}>

                  <div className="dive-offers-activities-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Id Actividad"
                      optionsText={[{ label: 'Todos', value: '*' }].concat(activitiesIds)}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        this.setState({ filterIds: value, page: 1, skipPage: 0 }, () => {
                          this.getActivityTemplates();
                        });
                      }}
                    />
                  </div>
                  <div className="dive-offers-activities-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Nombre Actividad"
                      optionsText={[{ label: 'Todos', value: '*' }].concat(activitiesNames)}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        this.setState({ filterName: value, page: 1, skipPage: 0 }, () => {
                          this.getActivityTemplates();
                        });
                      }}
                    />
                  </div>

                  <div className="dive-offers-activities-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Disciplina"
                      optionsText={[{ label: 'Todos', value: '*' }].concat(DiveDisciplines)}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        this.setState({ filterDiscipline: value, page: 1, skipPage: 0 }, () => {
                          this.getActivityTemplates();
                        });
                      }}
                    />
                  </div>
                  <div className="dive-offers-activities-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Tipo de actividad"
                      optionsText={DiveTypes}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        this.setState({ filterType: value, page: 1, skipPage: 0 }, () => {
                          this.getActivityTemplates();
                        });
                      }}
                    />
                  </div>
                  {/*<div className="dive-offers-activities-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Puntos de inmersión"
                      optionsText={filterSites}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        this.setState({ filterSites: value, page: 1, skipPage: 0 }, () => {
                          this.getActivityTemplates();
                        });
                      }}
                    />
                  </div>*/}
                </div>
              )}
              <div className="dive-offers-activities-table">
                <MainLayoutTableContainer>
                  <div className="diving-center-table">
                    <MainTable
                      columns={this.tableColumns}
                      rows={this.getTableRows()}
                      rowKeys={this.rowKeys}
                      onOrder={() => { }}
                    />
                  </div>
                  {totalPages > 1 && (
                    <div className="diving-center-pagination">
                      <Pagination
                        page={page}
                        callback={page => this.setPage(page)}
                        limit={totalPages} />

                    </div>
                  )}
                </MainLayoutTableContainer>
              </div>
            </DiveOffersActivitiesStyle>
          </MainLayoutSection>
          {renderModal === 'modal-delete-activity' && (
            <ModalDelete
              title={''}
              subtitle={'¿Estas seguro de querer eliminar la actividad?'}
              showModal={renderModal === 'modal-delete-activity'}
              onRemove={() => this.handleRemove()}
              onCancel={() => this.closeModal()}
            />
          )}
          {renderModal === 'modal-unplaning-activity' && (
            <ModalUnplaning
              title={''}
              subtitle={'¿Estas seguro de querer eliminar las planificaciones de la actividad?'}
              showModal={renderModal === 'modal-unplaning-activity'}
              onRemove={() => this.handleUnplaning()}
              onCancel={() => this.closeModal()}
            />
          )}
          {renderModal === 'modal-plan-activity' && (
            <ModalPlanningActivity
              onCreateActivity={(activitiesType: string) => { }}
              onSuccess={(activityId?: number) => {
                this.closeModal();
                if (activityId) {
                  this.props.history.push(`/centres/${id}/planning/calendar/${activityId}`);

                } else {
                  this.getActivityTemplates();
                }

              }}
              centreId={id}
              onCancel={() => this.closeModal()}
              showModal={renderModal === 'modal-plan-activity'}
              activityId={selectedId}
            />
          )}
          {renderModal === 'modal-create-activity' && (
            <ModalCreateActivities
              centreId={id}
              onSuccess={() => {
                this.closeModal();
                this.getActivityTemplates();
              }}
              activityEdit={selectedTemplate}
              onCancel={() => this.closeModal()}
              showModal={renderModal === 'modal-create-activity'}
            />
          )}
        </ContentBlock>
      </>
    );
  }

  tableColumns = [
    /*{
      text: 'Id',
      filter: false,
      ascendent: true,
    },*/
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Disciplina',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Tipo',
      filter: false,
      ascendent: false,
    },/*
    {
      text: 'P. Inmersión',
      filter: false,
      ascendent: false,
    },*/
    {
      text: 'Planificar',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Ver actividad',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    /*id: {
      key: 'highlighted-body',
      value: '0',
    },*/
    name: {
      key: 'highlighted-body',
      value: 'Inmersión en Cabo de Gata',
    },
    model: {
      key: '',
      value: 'Bautismo de buceo',
    },
    type: {
      key: '',
      value: 'Buceo recreativo',
    },/*
    sites: {
      key: 'count',
      value: {
        title: 'Puntos de inmersión',
        items: [],
      },
    },*/
    planning: {
      key: 'text-button',
      value: 'Planificar',
    },
    view: {
      key: 'detail',
      value: images.eyeSvg,
    },
    actions: {
      key: 'options',
      actions: [
        {
          label: 'Editar',
          icon: images.edit3Svg,
          onClick: () => { },
        },
        {
          label: 'Eliminar',
          icon: images.deleteSvg,
          onClick: () => { },
        },
        {
          label: 'Desplanificar',
          icon: images.deleteSvg,
          onClick: () => { },
        },
      ],
    },
  };

  rowKeys = ['name', 'model', 'type', 'planning', 'view', 'actions'];
  // rowKeys = ['name', 'model', 'type', 'sites', 'planning', 'view', 'actions'];
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

//export default connect(mapStateToProps)(DiveOffersActivities);
export default connect(mapStateToProps)(withTranslation(['layouts','modalMessages','components'])(withRouter(DiveOffersActivities)));

