import { colors } from '../../assets/colors';

export const optionsLine = {
  scales: {
    xAxes: [
      {
        distribution: 'linear',
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'll',
        },
        ticks: {
          fontColor: '#1948A0',
          fontSize: 12,
        },
      },
    ],
    yAxes: [
      {
        display: true,

        ticks: {
          fontColor: '#1948A0',
          fontSize: 12,
          suggestedMin: 0,
          precision: 0,
        },
      },
    ],
  },
  layout: {
    fontColor: 'red',
  },
  legend: {
    display: false,
    labels: {
      // This more specific font property overrides the global property
      fontColor: '#1948A0',
    },
  },
};

export const optionsPie = {
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: '#1948A0',
          fontSize: 12,
        },
      },
    ],
    yAxes: [
      {
        display: true,

        ticks: {
          fontColor: '#1948A0',
          fontSize: 12,
        },
      },
    ],
  },
  layout: {
    fontColor: 'red',
  },
  legend: {
    display: false,
    labels: {
      // This more specific font property overrides the global property
      fontColor: '#1948A0',
    },
  },
};

export const lineDatasetTemplate = {
  fill: false,
  lineTension: 0.1,
  backgroundColor: colors['SE-002/100'],
  borderColor: colors['SE-002/100'],
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: colors['SE-002/100'],
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: colors['SE-002/100'],
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

export const diversBarDatasetTemplate = [
  {
    fill: false,
    lineTension: 0.1,
    backgroundColor: [colors['CO-004/50'], colors['CO-004/100']],
    borderColor: [colors['CO-004/50'], colors['CO-004/100']],
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: [colors['CO-004/50'], colors['CO-004/100']],
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: [colors['CO-004/50'], colors['CO-004/100']],
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  },
];

export const centresBarDatasetTemplate = [
  {
    fill: false,
    lineTension: 0.1,
    backgroundColor: [colors['SE-002/75'], colors['SE-005/100']],
    borderColor: [colors['SE-002/75'], colors['SE-005/100']],
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: [colors['SE-002/75'], colors['SE-005/100']],
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: [colors['SE-002/75'], colors['SE-005/100']],
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  },
];

export const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      fill: false,
      lineTension: 0.1,

      backgroundColor: colors['SE-002/100'],
      borderColor: colors['SE-002/100'],
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: colors['SE-002/100'],
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: colors['SE-002/100'],
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40],
    },
    {
      fill: false,
      lineTension: 0.1,
      backgroundColor: colors['SE-001/100'],
      borderColor: colors['SE-001/100'],
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: colors['SE-001/100'],
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: colors['SE-001/100'],
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [5, 20, 40, 21, 56, 25, 2],
    },
    {
      label: 'My second dataset',
      fill: false,
      lineTension: 0.1,
      backgroundColor: colors['CO-004/100'],
      borderColor: colors['CO-004/100'],
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: colors['CO-004/100'],
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: colors['CO-004/100'],
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [15, 2, 40, 10, 26, 15, 12],
    },
  ],
};
