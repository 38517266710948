import * as React from 'react';
import { PlanningChooseTemplateStyle } from './planning-choose-template-style';
import { MainLayoutTableContainer } from '../../../main-layout-style';
import Pagination from '../../../../components/pagination';
import MainTableLayout from '../../../../components/main-table/main-table';
import { getActivityTemplates } from '../../../../services/centre';
import { IActivityTemplate } from '../../../../models/activity-template';
import { copyOf } from '../../../../utils/other';

const ACTIVITIES_PER_PAGE = 5;
interface PlanningChooseTemplateProps {
  onNext: (selectedTemplate: IActivityTemplate) => void;
  centreId: string;
  isDive: boolean;
}

interface PlanningChooseTemplateState {
  total: number;
  totalPages: number;
  skipPage: number;
  activityTemplates?: IActivityTemplate[];
  page: number;
}

class PlanningChooseTemplate extends React.Component<PlanningChooseTemplateProps, PlanningChooseTemplateState> {
  constructor(props: PlanningChooseTemplateProps) {
    super(props);
    this.state = {
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
    };
  }

  componentDidMount() {
    this.getActivityTemplates();
  }

  async getActivityTemplates() {
    const { centreId, isDive } = this.props;
    const { skipPage } = this.state;
    const params = {
      filter: {
        where: {
          id: { method: isDive ? 'is' : 'is not', value: 'null', collection: 'course' },
        },
        relations: ['course', 'course.certifier', 'services', 'sites', 'kind'],
        skip: skipPage,
        limit: ACTIVITIES_PER_PAGE,
      },
    };
    const params2 = {
      filter: {
        relations: ['category', 'exclusiveStock', 'exclusiveServices', 'cancellationPolicy'],
      },
    };
    const resp = await getActivityTemplates(centreId, params);
    const resp2 = await getActivityTemplates(centreId, params2);
    const d = resp.data.data;
    const d2 = resp2.data.data;
    for (let x = 0; x < d.length; x++) {
      d[x].category = d2[x].category;
      d[x].exclusiveStock = d2[x].exclusiveStock;
      d[x].exclusiveServices = d2[x].exclusiveServices;
      d[x].cancellationPolicy = d2[x].cancellationPolicy;
    }
    const totalPages: number = Math.ceil(resp.data.count / ACTIVITIES_PER_PAGE);
    const activityTemplates: IActivityTemplate[] = d;


    activityTemplates.map((at: any) => at.category ? at.category = at.category.id : '');

    this.setState({ activityTemplates, totalPages, total: resp.data.count });




  }

  getTableRows() {
    const { onNext } = this.props;
    const { activityTemplates } = this.state;
    const tableRows: any[] = [];
    if (!activityTemplates) return [];
    activityTemplates.forEach(activity => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.name.value = activity.name;
      tableRow.detail.onClick = () => {
        onNext(activity);
      };
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = ACTIVITIES_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getActivityTemplates();
      });
    }
  }

  render() {
    const { totalPages, page } = this.state;
    return (
      <PlanningChooseTemplateStyle>
        <MainLayoutTableContainer>
          <div className="diving-center-table">
            <MainTableLayout
              columns={this.tableColumns}
              rows={this.getTableRows()}
              rowKeys={this.rowKeys}
              onOrder={() => { }}
            />
          </div>
          {totalPages > 1 && (
            <div className="diving-center-pagination">
              <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
            </div>
          )}
        </MainLayoutTableContainer>
      </PlanningChooseTemplateStyle>
    );
  }

  tableColumns = [
    {
      text: 'Nombre',
      filter: false,
      ascendent: false,
    },
    // {
    //   text: 'Duración',
    //   filter: false,
    //   ascendent: true,
    // },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    name: {
      key: 'highlighted-body',
      value: 'Titulación',
    },
    // duration: {
    //   key: '',
    //   value: 'Lorem ipsum dolor sit amet consec',
    // },
    detail: {
      key: 'text-button',
      type: 'light-blue',
      value: 'Seleccionar',
    },
  };

  rowKeys = ['name', 'duration', 'detail'];
}

export default PlanningChooseTemplate;
