import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const AddItemFormCertifierStyle = styled.div`
  background: ${colors['NE-002/10']};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  .add-item-form-certifier-img {
    width: 51px;
    height: 51px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 12px;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .add-item-form-certifier-text {
    color: ${colors['SE-002/100']};
    flex: auto;
    &__title {
      margin-bottom: 4px;
    }

    &__certifier {
      font-size: 10px;
      line-height: 16px;
      font-weight: 300;
    }
    &__id  {
      font-size: 10px;
      line-height: 16px;
      font-weight: 300;
    }
  }
  .add-item-form-certifier-delete {
    cursor: pointer;
    width: 32px;
    height: 32px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
`;
