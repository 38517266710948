import styled from 'styled-components';
export const MapMarkerStyle = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  .map-marker-container {
    top: 32px;
    width: 32px;
    height: 32px;
    position: absolute;
    top: -32px;
    right: 16px;
  }
`;
