import services from './services';

export const getLevels = async (params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'levels',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getOneLevels = (levelId: string, params?: any) => {
  const endpoint = `levels/${levelId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadLevelsFormData = (levelId: string, data: FormData) => {
  const endpoint = `levels/${levelId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const uploadLevels = (levelId: string, data: any) => {
  const endpoint = `levels/${levelId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteLevels = (levelId: string) => {
  const endpoint = `levels/${levelId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createLevels = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'levels',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
export const createLevelsFormData = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'levels',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};
