export const GENDER_DROPDOWN = [
  { value: 'male', label: 'Hombre' },
  { value: 'female', label: 'Mujer' },
  { value: '-', label: 'No binario' },
];

export const GENDER_KEY_VALUES: any = {
  male: 'Hombre',
  female: 'Mujer',
  '-': 'No binario',
};
