import * as React from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { images } from '../../../assets/images';
import { DiveDisciplines } from '../../../constants/dive-dropdowns';
import { CERTIFIER_COURSES_PER_PAGE } from '../../../constants/data';
import { DIVERS_PER_PAGE } from '../../../constants/data';
import SelectBox from '../../../components/select-box';
import MainButton from '../../../components/main-button';
import { getCertifierDivers, getCollaboratorCourses } from '../../../services/collaborator';
import { copyOf, mergeObject, createFilterRequest } from '../../../utils/other';
import moment, { Moment } from 'moment';
import { IFilter } from '../../../models/filter';
import { IDropdown } from '../../../models/generic';
import ModalInviteDivers from './modal-invite-divers/modal-invite-divers';

interface RouteParams {
  id: string;
}

interface ICertifierDiversProps extends RouteComponentProps<RouteParams>, WithTranslation { }

interface ICertifierDiversState {
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
  activeFilters: boolean;
  modal: string;
  searchValue: string;
  selectedCourse: number;
  divers: any[];
  certifierCourses: IDropdown[];
}

class CertifierDivers extends React.Component<ICertifierDiversProps, ICertifierDiversState> {
  constructor(props: ICertifierDiversProps) {
    super(props);
    this.state = {
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
      activeFilters: false,
      modal: '',
      searchValue: '',
      divers: [],
      selectedCourse: NaN,
      certifierCourses: [],
    };
  }

  componentDidMount() {
    this.getCertifierDivers();
  }

  async getCertifierDivers() {
    const { searchValue, skipPage, selectedCourse } = this.state;


    const certifierId = localStorage.getItem('organizationId') || '';
    if (searchValue) {

      /*const params = {
        filter: {
          skip: skipPage,
          search: searchValue,
          limit: DIVERS_PER_PAGE,
          orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
          relations: ['user', 'bookings', 'bookings.activity', 'documents'],
        },
      };*/

      const paramsFilter = {
        filter: {
          skip: skipPage,
          search: searchValue,
          limit: DIVERS_PER_PAGE,
          orderBy: [{ key: 'createdAt', direction: 'DESC' }],
          relations: ['user', 'bookings', 'bookings.activity'],
        },
      };

      /*const filters: { [key: string]: IFilter } = {
        search: searchValue,
      };
      const paramsFilter = {
        filter: {
          where: createFilterRequest(filters),
          relations: ['user', 'bookings', 'bookings.activity'],
          orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        },
      };*/
      const resp = await getCertifierDivers(certifierId, paramsFilter);
      const params2 = copyOf(paramsFilter);
      params2.filter.relations = ['user', 'dives', 'documents', 'documents.course'];
      const resp2 = await getCertifierDivers(certifierId, params2);
      const divers = mergeObject(resp, resp2).data.data;
      this.setState({ divers, total: resp.data.count ? resp.data.count : 0 }, () => this.getCertifierCourses());
    }
    else {
      let params = {
        filter: {
          relations: ['user', 'bookings', 'bookings.activity'],
          orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        },
      };
      const resp = await getCertifierDivers(certifierId, params);
      const params2 = copyOf(params);
      params2.filter.relations = ['user', 'dives', 'documents', 'documents.course'];
      const resp2 = await getCertifierDivers(certifierId, params2);
      const divers = mergeObject(resp, resp2).data.data;
      this.setState({ divers, total: resp.data.count ? resp.data.count : 0 }, () => this.getCertifierCourses());
    }


  }

  async getCertifierCourses() {
    const certifierId: string | null = localStorage.getItem('organizationId');
    if (certifierId) {
      const resp = await getCollaboratorCourses(certifierId);
      const certifierCourses: any[] = resp.data.data.map((course: any) => ({
        value: course.id.toString(),
        label: course.name,
      }));
      this.setState({ certifierCourses });
    }
  }

  getTableRows() {
    const { divers } = this.state;
    const { t } = this.props;

    const tableRows: any[] = [];
    divers.forEach(diver => {
      const diverTitulations = diver.documents
        .filter((doc: any) => doc.kind === 'diving license')
        .map((doc: any) => doc.name);
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.id.value = diver.diverId;
      tableRow.name.value = `${diver.user.name} ${diver.user.surname}`;
      if (diverTitulations.length > 0) {
        tableRow.titulation.value = diverTitulations[0];
      }
      if (diverTitulations.length > 1) {
        tableRow.titulationCount.value = {
          title: t('modalMessages:tit'),
          items: diverTitulations,
        };
      }
      /*tableRow.numCourses.value = diver.bookings.filter((booking: any) => !!booking.activity.courseInfo).length;
      tableRow.numDives.value = diver.dives.length;
      if (diver.dives.length > 0) {
        tableRow.lastDive.value = diver.dives
          .reduce((maxDate: Moment, dive: any) => {
            const date = moment(dive.date);
            if (date.isAfter(maxDate)) return date;
            return maxDate;
          }, moment(0))
          .format('DD/MM/YYYY');
      }*/
      tableRow.detail.link = `divers/${diver.id}`;
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CERTIFIER_COURSES_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ skipPage: skip, page }, () => {
        this.getCertifierDivers();
      });
    }
  }

  timeoutSearch: any;
  handleSearch(value: string | null) {
    const searchValue = value || '';
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      this.setState({ searchValue, page: 1, skipPage: 0 }, () => this.getCertifierDivers());
    }, 1000);
  }

  render() {
    const { t } = this.props;
    const { activeFilters, totalPages, modal, certifierCourses, page, total } = this.state;
    return (
      <>
        <HeaderMain
          title={t('modalMessages:mydivers')}
          withSearch={true}
          resultCount={total}
          handleSearch={(value: string | null) => this.handleSearch(value)}
        // mainButton={{
        //   text: 'Invitar',
        //   onClick: () => this.setState({ modal: 'invite-divers' }),
        // }}
        />
        <MainLayoutSection>
          {/* <div className="catalog-head">
            <div className="catalog-head__filter">
              <MainButton
                onClick={() => this.setState({ activeFilters: !activeFilters })}
                type={activeFilters ? 'secondary-active' : 'secondary'}
                text="Filtrar"
                iconImg={activeFilters ? images.filtersLightBlueSvg : images.filtersSvg}
              />
            </div>
          </div>
          {activeFilters && (
            <div className="catalog-filters">
              <div className="catalog-filters__item">
                <SelectBox
                  labelText=""
                  placeholder="Curso"
                  className="rounded-filter"
                  optionsText={certifierCourses.concat([{ value: '*', label: 'Todos' }]).reverse()}
                  optionKey={'value'}
                  optionValue={'label'}
                  icon={images.arrowDown2BlueSvg}
                  onChange={(value, label) => {
                    if (value === '*') this.setState({ selectedCourse: NaN }, () => this.getCertifierDivers());
                    else this.setState({ selectedCourse: parseInt(value, undefined) }, () => this.getCertifierDivers());
                  }}
                />
              </div>
            </div>
          )} */}
          <div className="ads-detail-table__content">
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout
                  columns={this.tableColumns}
                  rows={this.getTableRows()}
                  rowKeys={this.rowKeys}
                  onOrder={() => { }}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                </div>
              )}
            </MainLayoutTableContainer>
          </div>
        </MainLayoutSection>
        {modal === 'invite-divers' && (
          <ModalInviteDivers
            showModal={modal === 'invite-divers'}
            onCancel={() => this.setState({ modal: '' })}
            onSuccess={() => this.setState({ modal: '' })}
            title={t('mocalMessages:invdivers')}
          />
        )}
      </>
    );
  }

  tableColumns = [
    {
      text: 'ID',
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:name'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:tit'),
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },/*
    {
      text: 'nº cursos (instructor)',
      filter: false,
      ascendent: false,
    },
    {
      text: 'nº inmersiones',
      filter: false,
      ascendent: true,
    },
    {
      text: 'última inmersión',
      filter: false,
      ascendent: true,
    },*/
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    id: {
      key: '',
      value: 'C23',
    },
    name: {
      key: '',
      value: 'Centro Estrellita de los mares',
    },
    titulation: {
      key: '',
      value: '09/09/2019',
    },
    titulationCount: {
      key: 'count',
      value: {
        title: this.props.t('modalMessages:tit'),
        items: [],
      },
    },/*
    numCourses: {
      key: '',
      value: 'Rafael Gómez Zuluaga',
    },
    numDives: {
      key: '',
      value: 'Rafael Gómez Zuluaga',
    },
    lastDive: {
      key: '',
      value: 'Rafael Gómez Zuluaga',
    },*/
    detail: {
      key: 'detail',
      link: '',
    },
  };

  rowKeys = ['id', 'name', 'titulation', 'titulationCount', 'detail'];
}
export default withTranslation(['intro', 'buttons', 'modalMessages'])(withRouter(CertifierDivers));
