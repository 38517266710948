import styled from 'styled-components';

export const RolesLayoutStyle = styled.div`
  .roles-table {
    margin-bottom: 65px;

    .roles-table-head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 38px;
    }

    .roles-table-more {
      cursor: pointer;
    }
  }
`;
