import React from 'react';
import { DiveCardContainer } from './dive-card-style';
import TitleText from '../text/title-text/title-text';
import LabelBox from '../label-box/label-box';
import LabelText from '../text/label-text/label';

interface DiveCardProps {
  title: string;
  text: string;
  subtext: string;
  type: string;
}

export default class DiveCard extends React.Component<DiveCardProps, {}> {
  render() {
    const { title, text, subtext, type } = this.props;
    return (
      <DiveCardContainer className={type}>
        <div className="dive-card-container">
          <div className="dive-card-container__title">
            <TitleText>{title}</TitleText>
          </div>
          <div className="dive-card-container__text">
            <LabelText>{text}</LabelText>
          </div>
          <div className="dive-card-container__subtext">
            <LabelText>{subtext}</LabelText>
          </div>
        </div>
      </DiveCardContainer>
    );
  }
}
