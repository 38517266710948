import React from 'react';
import { SubheadTextContainer } from './subhead-text-styles';

interface SubheadTextProps {
  children: any;
  className?: string;
  color?: string;
}

const SubheadText = ({ children = '', className = '', color = '' }: SubheadTextProps) => {
  return (
    <SubheadTextContainer color={color} className={className}>
      {children}
    </SubheadTextContainer>
  );
};
export default SubheadText;
