import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const PaginationContainer = styled.div`
  .pagination-container {
    display: flex;
    align-items: center;
    color: ${colors['TX-001-50']};
  }

  .pagination-left {
    margin-right: 23px;
    min-width: 20px;
    img {
      max-width: 16px;
    }
  }

  .pagination-main {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 240px; */
    span {
      width: 40px;
      height: 40px;
      margin: 0 5px;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: white;
      transition: all 0.3s ease-in-out;
      &.actual {
        color: ${colors['PR-001/100']};
        position: relative;
        background: ${colors['NE-002/10']};
      }
    }
  }

  .pagination-right {
    margin-left: 23px;
    min-width: 20px;
    img {
      max-width: 16px;
    }
  }

  span {
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    opacity: 1;
    pointer-events: all;

    &.limit {
      opacity: 0.5;
      pointer-events: none;
      transform: translateX;
    }
  }

  .dots-container {
    color: ${colors['NE-002/10']};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 16px;
  }

  .dots-item {
    display: flex;
    color: #000019;
    span {
      margin: 0;
      cursor: default;
      width: 4px;
      height: 4px;
      background: #000019;
      border-radius: 50%;
      display: block;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
