import React from 'react';
import { SubheadDestacadoTextContainer } from './subhead-destacado-text-styles';

interface SubheadDestacadoTextProps {
  children: any;
  className?: string;
  color?: string;
}

const SubheadDestacadoText = ({ children = '', className = '', color = '' }: SubheadDestacadoTextProps) => {
  return (
    <SubheadDestacadoTextContainer color={color} className={className}>
      {children}
    </SubheadDestacadoTextContainer>
  );
};
export default SubheadDestacadoText;
