import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalCreateCenterContainer = styled.div`
  padding: 32px;
  width: 416px;

  @media (min-width: 768px) {
    width: 900px;
  }

  @media (min-width: 1024px) {
    width: 1000px;
  }

  .modal-create-title {
    margin-bottom: 40px;
    &__step {
      color: ${colors['SE-002/100']};
    }
  }
  .modal-create-form {
    &__title  {
      color: ${colors['SE-002/100']};
      margin-bottom: 14px;
      text-transform: uppercase;
    }
    /*     &__left {
      width: calc(50% - 20px);
    }
    &__right {
      width: calc(50% - 20px);
    } */
    &__content {
    }
  }

  .modal-create-buttons {
    display: flex;

    &__item {
      width: 100%;
    }
    &__back {
      width: 60px;
      margin-right: 12px;
    }
  }

  .MT-26 {
    margin-top: 26px;
  }
`;
