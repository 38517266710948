import React from 'react';
import { BodyTextContainer } from './body-text-styles';

interface BodyTextProps {
  children: any;
  className?: string;
  color?: string;
  title?: string;
}

const BodyText = ({ children = '', className = '', color = '', title }: BodyTextProps) => {
  return (
    <BodyTextContainer color={color} className={className} title={title}>
      {children}
    </BodyTextContainer>
  );
};
export default BodyText;
