import styled, { css } from "styled-components";
import { mediaqueries } from "../../../constants/mediaqueries";

export const HeadDestacadoTextContainer = styled.p`
  font-family: "plain";
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;

  ${props => css`
    color: ${props.color};
  `}
  ${mediaqueries["max-width768"]} {
    font-size: 30px;
    line-height: 38px;
  }
`;
