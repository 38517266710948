// tslint:disable
import React, { Component } from 'react';
import * as dateFns from 'date-fns';
import es from 'date-fns/locale/es';
import { images } from '../../assets/images';
import { CalendarItemContainer } from './calendar-item-style';
import { isValidDate } from '../../utils/validation';
import { capitalizeFirstLetter, copyOf } from '../../utils/other';
import Label02Text from '../text/label-02-text/label-02';

interface CalendarItemProps {
  onSelect: (value: string, closeCalendar: boolean, secondValue?: string) => void;
  initialValue?: string;
  forbidFutureDates?: boolean;
  forbidPastDates?: boolean;
  rangeSelect?: boolean;
  minAge?: number;
  maxAge?: number;
  maxYear?: number;
  minYear?: number;
  className?: string;
  allowedDates?: string[];
  multiSelect?: boolean;
  onChangeMulti?: (values: Date[]) => void;
  isOutsideRange?: (date: string) => boolean;
  clearValue?: boolean;
}

class CalendarItem extends Component<CalendarItemProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showYears: false,
      showMonths: false,
      rangeSelectStep: 0,
      currentDate: new Date(),
      secondDate: props.rangeSelect ? new Date() : null,
      auxDate: new Date(),
      multiValues: [],
    };
  }

  componentDidMount() {
    const { currentDate } = this.state;
    const { minAge } = this.props;

    if (minAge) {
      const startDate = dateFns.subYears(currentDate, minAge);
      this.setState({ currentDate: startDate });
    }
  }

  componentDidUpdate(prevProps: CalendarItemProps) {
    const { currentDate } = this.state;
    const { initialValue, rangeSelect, multiSelect, clearValue } = this.props;

    if (!rangeSelect && initialValue && isValidDate(initialValue, '/')) {
      const initialDate = dateFns.parse(initialValue, 'dd/MM/yyyy', new Date());
      if (initialDate.getTime() !== currentDate.getTime()) {
        if (rangeSelect) {
          this.setState({ currentDate: initialDate, secondDate: initialDate });
        } else {
          this.setState({ currentDate: initialDate });
        }
      }
    }
    if (multiSelect && clearValue && prevProps.clearValue !== clearValue) {
      this.setState({ multiValues: [] });
    }
  }

  isAllowSelect(date: Date) {
    const { forbidFutureDates, minAge, forbidPastDates } = this.props;
    if (minAge && dateFns.differenceInYears(new Date(), date) < minAge) {
      return false;
    }
    if (forbidFutureDates && dateFns.isFuture(date)) {
      return false;
    }
    if (forbidPastDates && dateFns.isPast(date) && !dateFns.isToday(date)) {
      return false;
    }
    return true;
  }

  renderHeader() {
    const dateFormatDay = 'D';
    const dateFormatMonth = 'MMMM';
    const dateFormatYear = 'yyyy';

    return (
      <div className="calendar-header">
        {/* Current Month */}
        <div className="calendar-header__date">
          <div className="current-month">
            <p onClick={() => this.setState({ showMonths: true })}>
              {capitalizeFirstLetter(dateFns.format(this.state.auxDate, dateFormatMonth, { locale: es }))},
            </p>
            {/* <div className="pick-arrow top">
            <img src={images.arrowUpSvg} alt="Elegir mes" onClick={() => this.prevMonth()} />
            </div>
            <div className="pick-arrow bottom">
            <img src={images.arrowDownSvg} alt="Elegir mes" onClick={() => this.nextMonth()} />
          </div> */}
          </div>
          {/* Current Year */}
          <div className="current-year">
            <p onClick={() => this.setState({ showYears: true })}>
              {capitalizeFirstLetter(dateFns.format(this.state.auxDate, dateFormatYear, { locale: es }))}
            </p>
            {/* <div className="pick-arrow top" onClick={() => this.nextYear()}> */}
            {/* al hacer click, meter clase active a .year-select */}
            {/* <img src={images.arrowUpSvg} alt="Elegir año" />
          </div>
        <div className="pick-arrow bottom" onClick={() => this.prevYear()}> */}
            {/* al hacer click, meter clase active a .year-select */}
            {/* <img src={images.arrowDownSvg} alt="Elegir año" />
          </div> */}
          </div>
        </div>
        {/* Swipe Month */}
        <div className="calendar-arrow-container">
          {/* Arrow Left */}
          <div className="arrows arrow-left" onClick={() => this.prevMonth()}>
            <img src={images.arrowLeft2Svg} alt="" />
          </div>
          {/* Arrow Right */}
          <div className="arrows arrow-right" onClick={() => this.nextMonth()}>
            <img src={images.arrowRight2Svg} alt="" />
          </div>
        </div>
      </div>
    );
  }

  renderDaysHeader() {
    const dateFormat = 'EEE';
    const days: JSX.Element[] = [];
    // "L","M","X","J","V","S","D"
    const startDate = dateFns.startOfWeek(this.state.auxDate, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar-days-day" key={i}>
          <div>
            <p>{dateFns.format(dateFns.addDays(startDate, i), dateFormat, { locale: es })}</p>
          </div>
        </div>
      );
    }
    return <div className="calendar-days">{days}</div>;
  }

  renderCells() {
    const { rangeSelect, multiSelect, allowedDates, isOutsideRange } = this.props;
    const { currentDate, secondDate, rangeSelectStep, auxDate, multiValues } = this.state;
    const monthStart = dateFns.startOfMonth(auxDate);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = dateFns.endOfWeek(monthEnd, { weekStartsOn: 1 });
    const dateFormat = 'dd';
    const rows: JSX.Element[] = [];
    let days: JSX.Element[] = [];
    let day = startDate;
    let formattedDate = '';
    let multiSelected = false;
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        if (multiSelect) {
          multiSelected = false;
          multiValues.forEach((multiValue: string) => {
            if (!multiSelected) {
              multiSelected = dateFns.isSameDay(day, new Date(multiValue));
            }
          });
        }

        days.push(
          <div
            className={`col cell 
              ${
                !dateFns.isSameMonth(day, monthStart) ||
                (allowedDates &&
                  allowedDates.filter(date => dateFns.isSameDay(dateFns.parse(date, 'dd/MM/yyyy', new Date()), day))
                    .length === 0)
                  ? ' disabled'
                  : isOutsideRange && isOutsideRange(dateFns.format(day, 'dd/MM/yyyy'))
                  ? ' disabled'
                  : !this.isAllowSelect(day)
                  ? ' no-allow'
                  : multiSelected
                  ? ' selected-multi'
                  : currentDate &&
                    !multiSelect &&
                    ((rangeSelect && rangeSelectStep !== 0) || !rangeSelect) &&
                    (dateFns.isSameDay(day, currentDate) || (secondDate && dateFns.isSameDay(day, secondDate)))
                  ? ' selected'
                  : (rangeSelect &&
                      currentDate &&
                      secondDate &&
                      dateFns.isAfter(currentDate, day) &&
                      dateFns.isBefore(secondDate, day)) ||
                    (dateFns.isBefore(currentDate, day) && dateFns.isAfter(secondDate, day) && !multiSelect)
                  ? ' in-selected'
                  : ' '
              }
            `}
            key={dateFns.format(day, 'dd-MM-yyyy')}
            onClick={() => this.onDateClick(cloneDay)}
          >
            {/* Days */}
            <div>
              <Label02Text>{formattedDate}</Label02Text>
            </div>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={dateFns.format(day, 'dd-MM-yyyy')}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  renderSelectYear() {
    const { auxDate } = this.state;
    const currentYear = new Date().getFullYear();
    const maxYear = this.props.maxYear || currentYear + 30;
    const minYear = this.props.minYear || currentYear - 110;
    const years: JSX.Element[] = [];
    for (let i = maxYear; i >= minYear; i--) {
      const child = [<p key={i}>{i}</p>];
      const yearDate = dateFns.setYear(auxDate, i);
      years.push(
        <li
          className={`${!this.isAllowSelect(auxDate) ? 'no-allow' : ''}`}
          key={i}
          onClick={() => {
            const selected = dateFns.setYear(auxDate, i);
            this.onDateClick(selected, true);
          }}
        >
          {child}
        </li>
      );
    }

    const { showYears } = this.state;

    return (
      <div className={'year-select ' + (showYears ? 'active' : '')}>
        <div className="year-select-close" onClick={() => this.setState({ showYears: false })}>
          <img src={images.otherSvg} alt="cerrar seleccion de año" />
        </div>
        <ul>{years}</ul>
      </div>
    );
  }

  renderSelectMonth() {
    const { showMonths, auxDate } = this.state;
    let tempDate = dateFns.setMonth(new Date(), 0);
    const rows: JSX.Element[] = [];
    let months: JSX.Element[] = [];
    for (let i = 0; i < 12; i++) {
      const monthDate = dateFns.setMonth(auxDate, i);
      months.push(
        <div
          className={`select-month-container-item ${!this.isAllowSelect(monthDate) ? 'no-allow' : ''}`}
          key={i}
          onClick={() => {
            const selected = dateFns.setMonth(auxDate, i);
            this.onDateClick(selected, true);
          }}
        >
          <p>{dateFns.format(tempDate, 'MMMM', { locale: es })}</p>
        </div>
      );
      tempDate = dateFns.addMonths(tempDate, 1);

      if ((i + 1) % 3 === 0) {
        rows.push(
          <div className="select-month-container-row" key={i}>
            {months}
          </div>
        );
        months = [];
      }
    }

    return <div className={'select-month-container ' + (showMonths ? 'active' : '')}>{rows}</div>;
  }

  handleMultipleOnSelect(closeCalendar: boolean) {
    const { currentDate, secondDate } = this.state;
    const { onSelect } = this.props;
    if (dateFns.isBefore(currentDate, secondDate)) {
      onSelect(dateFns.format(currentDate, 'dd/MM/yyyy'), closeCalendar, dateFns.format(secondDate, 'dd/MM/yyyy'));
    } else {
      onSelect(dateFns.format(secondDate, 'dd/MM/yyyy'), closeCalendar, dateFns.format(currentDate, 'dd/MM/yyyy'));
    }
  }

  onDateClick(date: Date, auxDate?: boolean) {
    const { onSelect, rangeSelect, multiSelect, onChangeMulti } = this.props;
    const { rangeSelectStep, multiValues } = this.state;
    if (auxDate) {
      this.setState({
        auxDate: date,
        showMonths: false,
        showYears: false,
      });
    } else if (rangeSelect) {
      if (rangeSelectStep === 0) {
        this.setState(
          {
            rangeSelectStep: 1,
            currentDate: date,
            secondDate: date,
            showMonths: false,
            showYears: false,
          },
          () => {
            this.handleMultipleOnSelect(false);
          }
        );
      } else if (rangeSelectStep === 1) {
        this.setState(
          {
            rangeSelectStep: 2,
            secondDate: date,
            showMonths: false,
            showYears: false,
          },
          () => {
            this.handleMultipleOnSelect(true);
          }
        );
      } else {
        this.setState({
          rangeSelectStep: 0,
          currentDate: new Date(),
          secondDate: new Date(),
          showMonths: false,
          showYears: false,
        });
      }
    } else if (multiSelect && onChangeMulti) {
      let newMultiValues: any[] = [...multiValues];
      const newDate = date;
      if (!!newMultiValues.find((val: Date) => val.toISOString() === date.toISOString())) {
        newMultiValues = newMultiValues.filter((val: Date) => val.toISOString() !== date.toISOString());
      } else newMultiValues.push(newDate);
      this.setState({ multiValues: newMultiValues, showMonths: false, showYears: false }, () => {
        onChangeMulti(newMultiValues);
      });
    } else if (this.isAllowSelect(date)) {
      this.setState(
        {
          selectedCurrentDate: true,
          currentDate: date,
          showMonths: false,
          showYears: false,
        },
        () => {
          onSelect(dateFns.format(this.state.currentDate, 'dd/MM/yyyy'), true);
        }
      );
    }
  }

  nextMonth() {
    const { onSelect } = this.props;
    const nextMonth = dateFns.addMonths(this.state.auxDate, 1);

    if (this.isAllowSelect(nextMonth)) {
      this.setState(
        {
          auxDate: nextMonth,
        },
        () => {
          //onSelect(dateFns.format(this.state.currentDate, 'dd/MM/yyyy'), false);
        }
      );
    }
  }

  prevMonth() {
    const { onSelect } = this.props;
    const prevMonth = dateFns.subMonths(this.state.auxDate, 1);

    if (this.isAllowSelect(prevMonth)) {
      this.setState(
        {
          auxDate: prevMonth,
        },
        () => {
          //onSelect(dateFns.format(this.state.currentDate, 'dd/MM/yyyy'), false);
        }
      );
    }
  }

  nextYear = () => {
    const { onSelect } = this.props;
    const nextYear = dateFns.addYears(this.state.auxDate, 1);

    if (this.isAllowSelect(nextYear)) {
      this.setState(
        {
          auxDate: nextYear,
        },
        () => {
          onSelect(dateFns.format(this.state.auxDate, 'dd/MM/yyyy'), false);
        }
      );
    }
  };

  prevYear = () => {
    const { onSelect } = this.props;
    const prevYear = dateFns.subYears(this.state.auxDate, 1);

    if (this.isAllowSelect(prevYear)) {
      this.setState(
        {
          auxDate: prevYear,
        },
        () => {
          onSelect(dateFns.format(this.state.auxDate, 'dd/MM/yyyy'), false);
        }
      );
    }
  };

  render() {
    const { rangeSelect, className } = this.props;
    return (
      <CalendarItemContainer className={`${rangeSelect ? 'range' : ''} ${className}`}>
        <div className="calendar">
          {this.renderHeader()}
          {this.renderDaysHeader()}
          {this.renderCells()}
          {this.renderSelectYear()}
          {this.renderSelectMonth()}
        </div>
      </CalendarItemContainer>
    );
  }
}

export default CalendarItem;
