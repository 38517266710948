import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const AddItemFormSimpleStyle = styled.div`
  background: ${colors['NE-002/10']};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  min-height: 50px;
  border-radius: 5px;

  .add-item-form-simple-text {
    color: ${colors['SE-002/100']};
  }
  .add-item-form-simple-delete {
    cursor: pointer;
    width: 32px;
    height: 32px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
`;
