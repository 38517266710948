import styled from 'styled-components';
import { colors } from '../../../../../../assets/colors';

export const ModalSpecializationStyle = styled.div`
  padding: 58px 32px 32px 32px;
  width: 416px;
  .create-user-title {
    margin-bottom: 12px;
  }
  .create-user-text {
    margin-bottom: 28px;
    color: ${colors['NE-002/100']};
  }
  .create-user-form {
    margin-bottom: 40px;
    &__title  {
      color: ${colors['SE-002/100']};
      margin-bottom: 14px;
    }
  }
  .create-user-buttons {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .create-user-role-container  {
    margin-bottom: 48px;
  }
`;
