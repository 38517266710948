import React from 'react';
import { Label02TextContainer } from './label-02-text-styles';

interface Label02TextProps {
  children: any;
  className?: string;
  color?: string;
}

const Label02Text = ({ children = '', className = '', color = '' }: Label02TextProps) => {
  return (
    <Label02TextContainer color={color} className={className}>
      {children}
    </Label02TextContainer>
  );
};
export default Label02Text;
