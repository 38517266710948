import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ChatContainer = styled.div`
  display: flex;
  .chat-navigation {
    display: flex;
    margin-bottom: 40px;
    margin-right: 60px;

    .navigation-item {
      display: flex;
      cursor: pointer;
      &.active {
        p  {
          border-bottom: 2px solid ${colors['PR-001/100']};
          color: ${colors['PR-001/100']};
        }
      }
      p {
        padding: 20px 19px 8px;
        border-bottom: 2px solid white;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .chat-users {
    width: 100%;
    margin-right: 60px;
    max-width: 450px;
  }

  .chat-conversation  {
    width: 100%;
  }

  .chat-users-container  {
    width: 100%;
    /* overflow-y: scroll; */
    height: calc(100vh - 301px);
    &__item {
      width: 100%;
    }
  }

  .chat-conversation-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
    &__name {
    }

    &__buttons  {
      display: flex;
      &__item  {
        width: 40px;
        margin-left: 12px;
      }
    }
  }

  .chat-conversation-container {
    /* overflow-y: scroll; */

    height: calc(100vh - 334px);
    &__date {
      text-align: center;
      color: ${colors['NE-001/50']};
      padding: 8px 20px 40px;
      width: 100%;
    }
  }
  .chat-conversation-input {
  }
`;
