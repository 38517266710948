import * as React from 'react';
import { connect } from 'react-redux';
import { ModalDiveSiteStyle } from './modal-dive-site-style';
import SingleButton from '../../single-button/single-button';
import { images } from '../../../assets/images';
import ModalContainer from '../modal-container';
import CardMap from '../../card-map/card-map';
import { mapStyle } from '../../../constants/map-style';
import GoogleMap from 'google-map-react';
import LabelText from '../../text/label-text/label';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../text/body-text/body-text';
import { FormGrid, FormGridRow, FormGridItem } from '../../../containers/main-layout-style';
import InputBox from '../../input-box';
import SelectBox from '../../select-box';
import DicotomicCheckbox from '../../dicotomic-checkbox';
import InfoText from '../../text/info-text/info-text';
import MainButton from '../../main-button';
import InputUploadFiles from '../../input-upload-file/input-upload-file';
import { DIVE_SITES_DROPDOWN } from '../../../constants/dive-sites';
import { createDiveSites, uploadDiveSites } from '../../../services/dive-sites';
import { COUNTRIES, COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import { createDiveSitesPictures } from '../../../services/dive-sites-pictures';
import { deleteDiveSitesPictures } from '../../../services/dive-sites-pictures';
import { GMAPS_KEY } from '../../../env';
import { getMarineReserve } from '../../../services/marine-reserve';
import { MapMarker } from '../../map-marker/map-marker';
import { postCentreDiveSites } from '../../../services/centre';
import { copyOf } from '../../../utils/other';
import { transformToSI } from '../../../utils/measurement-units';
import InputCoordBox from '../../input-coord-box/input-coord-box';
import { getDataFromAddress } from '../../../services/google-maps';
import { getAdministrativeLevel1, getCountry } from '../../../utils/maps';
import IStoreState from '../../../redux/store/IStoreState';
import { deletePicture } from '../../../services/users';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

export interface IModalDiveSiteProps extends RouteComponentProps, WithTranslation {
  centreId?: string;
  showModal: boolean;
  onClose: Function;
  diveSiteId: string;
  divesSites: any[];
  cards: any[];
  reserve?: any;
  agent?: any;
  t: any;
}

export interface IModalDiveSiteState {
  step: number;
  check: boolean;
  divesSite: any;
  pictures: any[];
  deletedPictures: any[];
  picture: any;
  languages: any[];
  reserves: any[];
  selectedLanguage: string;
  coordinate: any;
  states: any[];
}

class ModalDiveSite extends React.Component<IModalDiveSiteProps, IModalDiveSiteState> {
  searchWaiter: any = null;
  constructor(props: IModalDiveSiteProps) {
    super(props);
    const { divesSite, reserves } = this.getDiveSite(props.divesSites, props.diveSiteId, props.reserve);

    this.state = {
      divesSite,
      reserves,
      step: 1,
      check: !!reserves.length,
      coordinate: {
        lat: parseFloat(divesSite.latitude.value || 0),
        lng: parseFloat(divesSite.longitude.value || 0),
      },
      pictures: [],
      picture: {},
      deletedPictures: [],
      states: [],
      languages: [
        { value: 'es', label: 'ES' },
        { value: 'pt', label: 'PT' },
        { value: 'en', label: 'EN' },
        { value: 'fr', label: 'FR' },
        { value: 'it', label: 'IT' },
        { value: 'de', label: 'DE' },
      ],
      selectedLanguage: 'es',
    };
  }

  componentDidMount() {
    this.getReserves();
  }

  getDiveSite = (divesSites: any[], id: string, reserve?: any) => {
    const d = divesSites.find(d => d.id.toString() === id.toString()) || {};
    const divesSite = {
      name: {
        value: d.name || '',
        error: false,
        errorCode: '',
        required: true,
      },
      kind: {
        value: d.kind || '',
        error: false,
        errorCode: '',
        required: true,
      },
      maxDepth: {
        value: d.maxDepth || '',
        error: false,
        errorCode: '',
        required: true,
        type: 'number',
      },
      minDepth: {
        value: d.minDepth || '',
        error: false,
        errorCode: '',
        required: true,
        type: 'number',
      },
      location: {
        value: d.location || '',
        error: false,
        errorCode: '',
        required: true,
      },
      country: {
        value: d.country || '',
        error: false,
        errorCode: '',
        required: true,
      },
      state: {
        value: d.state || '',
        error: false,
        errorCode: '',
        required: true,
      },
      latitude: {
        value: d.latitude || '',
        error: false,
        errorCode: '',
        required: true,
        type: 'number',
      },
      longitude: {
        value: d.longitude || '',
        error: false,
        errorCode: '',
        required: true,
        type: 'number',
      },
      description: {
        es: {
          value: d.description ? d.description.es : '',
          error: false,
          errorCode: '',
          required: true,
        },
        pt: {
          value: d.description ? d.description.pt : '',
          error: false,
          errorCode: '',
          required: true,
        },
        en: {
          value: d.description ? d.description.en : '',
          error: false,
          errorCode: '',
          required: true,
        },
        fr: {
          value: d.description ? d.description.fr : '',
          error: false,
          errorCode: '',
          required: true,
        },
        it: {
          value: d.description ? d.description.it : '',
          error: false,
          errorCode: '',
          required: true,
        },
        de: {
          value: d.description ? d.description.de : '',
          error: false,
          errorCode: '',
          required: true,
        },
      },
      pictures: d.pictures || [],
      reserve: {
        value: reserve ? reserve.id : d.reserve ? d.reserve.id : '',
        error: false,
        errorCode: '',
        required: false,
        type: 'number',
      },
    };
    const reserves = reserve
      ? [{ label: reserve.name, value: reserve.id }]
      : d.reserve
        ? [{ label: d.reserve.name, value: d.reserve.id }]
        : [];
    return { divesSite, reserves };
  };

  getReserves(val?: string) {
    const params: any = {
      filter: {},
    };

    if (val) {
      params.filter = {
        where: {
          name: { method: 'ilike', value: `%${val.toLowerCase()}%` },
        },
      };
    }
    if (this.searchWaiter) {
      clearInterval(this.searchWaiter);
    }
    this.searchWaiter = setTimeout(async () => {
      const resp: any = await getMarineReserve(params, false);
      const reserves = resp.data.data.map((reserve: any) => {
        return {
          value: reserve.id.toString(),
          label: reserve.name,
          country: reserve.country,
        };
      });
      this.setState({ reserves });
    }, 750);
  }

  getSpot = (type?: string) => {
    switch (type) {
      case 'site':
        return 'dive-spot';
      case 'marine-reserve':
        return 'marine-reserve';
      default:
        return 'invalid-spot';
    }
  };

  changeValue = (value: string, key: string, subKey?: string) => {
    const { divesSite } = this.state;
    if (!subKey) {
      divesSite[key].value = value;
    } else {
      divesSite[key][subKey].value = value;
    }

    this.setState({ divesSite });
  };

  handleChangePicture = (value: any, key: string) => {
    if (key !== 'mainPicture') {
      this.setState({ pictures: value });
    } else {
      let { picture } = this.state;
      if (value[0]) {
        picture = value[0];
        picture.value = value[0].name;
      } else picture = {};
      this.setState({ picture });
    }
  };

  handleChangeDeletePictures = (values: any) => {
    this.setState({ deletedPictures: values });
  };

  validateForm = () => {
    const { divesSite } = this.state;

    let isOk = true;
    Object.keys(divesSite).forEach((k: string) => {

      if (divesSite[k].required && divesSite[k].value === '') {
        divesSite[k].error = true;
        divesSite[k].errorCode = 'required';
        isOk = false;
      } else if (k === 'longitude' && (parseFloat(divesSite[k].value) > 180 || parseFloat(divesSite[k].value) < -180)) {
        divesSite[k].error = true;
        divesSite[k].errorCode = 'invalid-range';
        isOk = false;
      } else if (k === 'latitude' && (parseFloat(divesSite[k].value) > 85 || parseFloat(divesSite[k].value) < -85)) {
        divesSite[k].error = true;
        divesSite[k].errorCode = 'invalid-range';
        isOk = false;
      } else if (
        (k === 'maxDepth' || k === 'minDepth') &&
        parseFloat(divesSite.maxDepth.value) <= parseFloat(divesSite.minDepth.value)
      ) {
        divesSite.minDepth.error = true;
        divesSite.maxDepth.error = true;
        divesSite.minDepth.errorCode = 'invalid-max-min';
        divesSite.maxDepth.errorCode = 'invalid-max-min';
        isOk = false;
      } else if ((k === 'maxDepth' || k === 'minDepth') && parseFloat(divesSite[k].value) < 0) {
        divesSite[k].error = true;
        divesSite[k].errorCode = 'invalid-negative';
        isOk = false;
      } else {
        divesSite[k].error = false;
        divesSite[k].errorCode = '';
      }
    });

    this.setState({ divesSite });
    if (isOk) {
      this.setState({ step: 2 });
    }
  };

  handleSave = () => {
    const { divesSite, check } = this.state;
    const { diveSiteId } = this.props;
    const description: any = {};
    Object.keys(divesSite.description).forEach((k: string) => {
      description[k] = divesSite.description[k].value;
    });
    const data: any = {};
    Object.keys(divesSite).forEach((k: string) => {
      if (divesSite[k].type === 'number') {
        data[k] = parseFloat(divesSite[k].value);
      } else if (k === 'description') {
        data[k] = description;
      } else {
        data[k] = divesSite[k].value;
      }
    });
    if (!check) data.reserve = null;
    if (!!diveSiteId) {
      this.editDiveSite(data);
    } else {
      this.createDiveSite(data);
    }
  };

  editDiveSite = async (data: any) => {
    const { pictures, picture, deletedPictures } = this.state;
    const { diveSiteId, onClose } = this.props;

    try {
      const response: any = await uploadDiveSites(diveSiteId, data);
      await this.uploadPictures(pictures, picture, response.data.id);
      if (Object.keys(picture).length > 0) {
        for (const pic of this.state.divesSite.pictures) {
          if (pic.main) {
            deletedPictures.push(pic);
          }
        }
      }

      await this.removePictures(deletedPictures);
      onClose(true);
    } catch (err) { }
  };

  createDiveSite = async (data: any) => {
    const { pictures, picture } = this.state;
    const { onClose, centreId } = this.props;
    try {
      let response: any;
      const formatData = copyOf(data);
      if (formatData.minDepth) formatData.minDepth = Math.round(transformToSI('depth', formatData.minDepth));
      if (formatData.maxDepth) formatData.maxDepth = Math.round(transformToSI('depth', formatData.maxDepth));
      if (centreId) response = await postCentreDiveSites(centreId, formatData);
      else response = await createDiveSites(formatData);
      await this.uploadPictures(pictures, picture, response.data.id);
      onClose(true);
    } catch (err) { }
  };

  uploadPictures = async (pictures: any[], picture: any, id: number) => {
    const picturesData: any[] = pictures.map(p => ({
      file: p.data,
      site: id.toString(),
    }));

    if (Object.keys(picture).length > 0) {
      picturesData.push({
        file: picture.data,
        site: id.toString(),
        main: true,
      });
    }
    for (const element of picturesData) {
      try {
        const formData = new FormData();
        formData.set('file', element.file);
        formData.set('site', element.site);
        formData.set('main', element.main);
        const pic = await createDiveSitesPictures(formData);
      } catch (err) {

        console.log(err);
      }
    }
  };

  removePictures = async (deletePictures: any[]) => {
    for (const pic of deletePictures) {
      try {
        await deleteDiveSitesPictures(pic.id);
      } catch (err) { }
    }
  };

  searchTimeout: any;
  handleSearchState(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (value && value !== '') this.searchAddress(value);
    }, 300);
  }

  async searchAddress(value: string) {
    const { divesSite } = this.state;
    const resp: any = await getDataFromAddress(value, divesSite.country.value);
    const results = resp.data.results;
    let states: any[] = results.map((result: any) => {
      const level1 = getAdministrativeLevel1(result);
      const country = getCountry(result);
      return {
        level1: level1.longName,
        country: country.shortName,
      };
    });
    states = Array.from(new Set(states)).map(state => ({
      country: state.country,
      value: state.level1,
      label: state.level1,
    }));
    this.setState({ states });
  }

  handleLink(){
    console.log("handleLink")
  }


  diveSiteManagement() {
    const { step, check, divesSite, coordinate, reserves, states } = this.state;
    const { cards, diveSiteId, agent, centreId, t } = this.props;
    reserves.find(r => r.value.toString() === divesSite.reserve.value.toString());
    return (
      <>
        <div className="modal-dive-site-map">
          <GoogleMap
            options={{
              styles: mapStyle,
              mapTypeId: 'hybrid',
              fullscreenControl: false,
            }}
            bootstrapURLKeys={{ key: GMAPS_KEY }}
            defaultCenter={coordinate}
            defaultZoom={2}
            resetBoundsOnResize={true}
            onClick={({ x, y, lat, lng, event }) => {
              this.setState({
                divesSite: {
                  ...divesSite,
                  longitude: { ...divesSite.longitude, value: lng },
                  latitude: { ...divesSite.latitude, value: lat },
                },
              });
            }
          }
          >
            {divesSite.longitude !== '' && divesSite.latitude !== '' && (
              <MapMarker
                key={`card-map-selected`}
                text={'my-marker'}
                lng={divesSite.longitude.value}
                lat={divesSite.latitude.value}
              />
            )}
            {cards.map((c: any, index) => (
              <CardMap
                key={`card-map-${index}`}
                spot={c.spot}
                lng={c.lng}
                lat={c.lat}
                title={c.title}
                type={c.type}
                locationDive={c.location}
                depth={c.depth}
                rate={c.rate}
                next={c.next}
                imagesSite={c.images}
              />
            ))}
          </GoogleMap>
        </div>

        <div className="modal-dive-site-info">
          <div className="modal-dive-site-info-top">
            <div className="modal-dive-site-info-top__step">
              <LabelText>{t('components:general.step')} {step}</LabelText>
            </div>
            <div className="modal-dive-site-info-top__title">
              <SubheadDestacadoText>{diveSiteId ? t('components:actions.edit') : t('components:actions.create')} {t('components:general.immersion-point')}</SubheadDestacadoText>
            </div>
            <div className="modal-dive-site-info-top__text">
              <BodyText>
                {diveSiteId
                  ? t('modalMessages:modal-dive-site.make-changes')
                  : t('modalMessages:modal-dive-site.create-points-for-activities')}
              </BodyText>
            </div>
            <div className="modal-dive-site-info-top__form">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      labelText={t('modalMessages:modal-dive-site.immersion-point-name')}
                      placeholder={t('modalMessages:modal-dive-site.immersion-point-name')}
                      type="text"
                      className="rounded"
                      required={divesSite.name.required}
                      errorCode={divesSite.name.errorCode}
                      value={divesSite.name.value}
                      onChange={(value: string) => this.changeValue(value, 'name')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      searchable={true}
                      className="rounded"
                      labelText={t('components:general.country')}
                      placeholder={t('components:general.country')}
                      required={true}
                      optionsText={COUNTRIES}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValueFilterText={COUNTRIES_KEY_LABEL[divesSite.country.value]}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => this.changeValue(value, 'country')}
                      errorCode={divesSite.country.errorCode}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText={t('components:general.state')}
                      placeholder={t('components:general.state')}
                      className="rounded"
                      optionsText={states}
                      optionKey={'value'}
                      optionValue={'label'}
                      onSearch={value => this.handleSearchState(value)}
                      required={divesSite.state.required}
                      errorCode={divesSite.state.errorCode}
                      initialValue={divesSite.state.value}
                      defaultValue={divesSite.state.value}
                      initialValueFilterText={divesSite.state.value}
                      defaultValueFilterText={divesSite.state.value}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.changeValue(value, 'state')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      labelText={t('components:general.location')}
                      placeholder={t('components:general.location')}
                      className="rounded"
                      type="text"
                      required={divesSite.location.required}
                      errorCode={divesSite.location.errorCode}
                      value={divesSite.location.value}
                      onChange={(value: string) => this.changeValue(value, 'location')}
                    />
                  </FormGridItem>
                </FormGridRow>

                {agent.units.coordinates === 'degree' ? (
                  <FormGridRow>
                    <FormGridItem type="col-2">
                      <InputBox
                        className="rounded"
                        placeholder={t('components:general.latitude')}
                        labelText={t('components:general.latitude')}
                        type={divesSite.latitude.type}
                        required={divesSite.latitude.required}
                        errorCode={divesSite.latitude.errorCode}
                        value={divesSite.latitude.value}
                        onChange={(value: string) => this.changeValue(value, 'latitude')}
                      />
                    </FormGridItem>
                    <FormGridItem type="col-2">
                      <InputBox
                        className="rounded"
                        placeholder={t('components:general.longitude')}
                        labelText={t('components:general.longitude')}
                        type={divesSite.longitude.type}
                        required={divesSite.longitude.required}
                        errorCode={divesSite.longitude.errorCode}
                        value={divesSite.longitude.value}
                        onChange={(value: string) => this.changeValue(value, 'longitude')}
                      />
                    </FormGridItem>
                  </FormGridRow>
                ) : (
                    <FormGridRow>
                      <FormGridItem type="col-2">
                        <InputCoordBox
                          className="rounded"
                          placeholder={t('components:general.latitude')}
                          labelText={t('components:general.latitude')}
                          isLat={true}
                          type={agent.units.coordinates}
                          required={divesSite.latitude.required}
                          errorCode={divesSite.latitude.errorCode}
                          value={divesSite.latitude.value}
                          onChange={(value: string) => this.changeValue(value, 'latitude')}
                        />
                      </FormGridItem>
                      <FormGridItem type="col-2">
                        <InputCoordBox
                          className="rounded"
                          placeholder={t('components:general.longitude')}
                          labelText={t('components:general.longitude')}
                          isLat={false}
                          type={agent.units.coordinates}
                          required={divesSite.longitude.required}
                          errorCode={divesSite.longitude.errorCode}
                          value={divesSite.longitude.value}
                          onChange={(value: string) => this.changeValue(value, 'longitude')}
                        />
                      </FormGridItem>
                    </FormGridRow>
                  )}
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      className="rounded"
                      placeholder={t('components:general.min-deep')}
                      labelText={t('components:general.min-deep')}
                      type={divesSite.minDepth.type}
                      required={divesSite.minDepth.required}
                      errorCode={divesSite.minDepth.errorCode}
                      value={divesSite.minDepth.value}
                      onChange={(value: string) => this.changeValue(value, 'minDepth')}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-2">
                    <InputBox
                      className="rounded"
                      labelText={t('components:general.max-deep')}
                      placeholder={t('components:general.max-deep')}
                      type={divesSite.maxDepth.type}
                      required={divesSite.maxDepth.required}
                      errorCode={divesSite.maxDepth.errorCode}
                      value={divesSite.maxDepth.value}
                      onChange={(value: string) => this.changeValue(value, 'maxDepth')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText={t('components:general.point-type')}
                      placeholder={t('components:general.point-type')}
                      className="rounded"
                      optionsText={DIVE_SITES_DROPDOWN}
                      optionKey={'value'}
                      optionValue={'label'}
                      icon={images.arrowDown2BlueSvg}
                      required={divesSite.kind.required}
                      errorCode={divesSite.kind.errorCode}
                      initialValue={divesSite.kind.value}
                      onChange={(value: string) => this.changeValue(value, 'kind')}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <div className="modal-dive-site-form-check">
                      <DicotomicCheckbox
                        name="name"
                        errorText="Error"
                        className=""
                        onCheck={() => this.setState({ check: !check })}
                        checked={check}
                      >
                        <InfoText>{t('modalMessages:modal-dive-site.check-reserve-marine')}</InfoText>
                      </DicotomicCheckbox>
                    </div>
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    {/*TODO: select con las reservas marinas que haya, no está la tabla aún */}
                    <SelectBox
                      disabled={!check}
                      placeholder={t('modalMessages:modal-dive-site.reserve-name')}
                      className="rounded"
                      initialValue={divesSite.reserve.value}
                      optionsText={reserves.filter(
                        (reserve: any) => divesSite.country.value === '' || reserve.country === divesSite.country.value
                        )
                      }
                      onSearch={val => this.getReserves(val)}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={divesSite.reserve.value}
                      labelText={t('components:dive-site-detail-map.reserve')}
                      errorCode={divesSite.reserve.errorCode}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: string, label: string | undefined) => this.changeValue(value, 'reserve')}
                    />
                  </FormGridItem>
                </FormGridRow>
                {/* <FormGridRow>
                  <FormGridItem type="col-0">
                <InputBox
                  required={true}
                  disabled={true}
                  type="text"
                  className="rounded"
                  value=""
                  placeholder="Municipio"
                  labelText="id certificadora"
                  onChange={() => { }}
                  withTooltip={false}
                  tooltipTitle={'Hola'}
                  tooltipText={'TooltipText'}
                />
                  </FormGridItem>
                </FormGridRow> */}
              </FormGrid>
            </div>
          </div>

          <div className="modal-dive-site-info-bottom">
            <div className="modal-dive-site-info-bottom__item">
              <MainButton className="primary" text={t('components:actions.next')} onClick={() => this.validateForm()} />
            </div>
          </div>
        </div>
      </>
    );
  }

  getFromTypePicture(pics: any[], isMain: boolean): any {
    if (isMain) {
      const f = pics.find((p) => p.main === isMain ? true : false);
      return f ? f.src : "";
    }
    else {
      const fs = pics.filter((p) => p.main === isMain ? true : false);
      let result: any[] = [];

      const { deletedPictures } = this.state;

      fs.forEach(function (item) {
        if (deletedPictures.includes(item) === false) {
          result.push(item);
        }
      });

      return result;
    }

  }
  addPhotos(pics: any[]) {
    const { step } = this.state;
    const { diveSiteId, t } = this.props;
    return (
      <div className="modal-dive-site-info-top">
        <div className="modal-dive-site-info-top__step">
          <LabelText>PASO {step}</LabelText>
        </div>
        <div className="modal-dive-site-info-top__title">
          <SubheadDestacadoText>{diveSiteId ? t('components:actions.edit') : t('components:actions.create')} {t('components:general.immersion-point')}</SubheadDestacadoText>
        </div>
        <div className="modal-dive-site-info-top__text" />
        <div className="modal-dive-site-info-top__file">
          <InputUploadFiles
            className={'full'}
            multiple={false}
            required={true}
            label="Foto principal"
            value={pics ? this.getFromTypePicture(pics, true) : ""}
            onChange={ev => this.handleChangePicture(ev, 'mainPicture')}
          />
        </div>

        <div className="modal-dive-site-info-top__files">
          <InputUploadFiles
            multiple={true}
            required={true}
            label={t('components:general.photos')}
            values={pics ? this.getFromTypePicture(pics, false) : ""}
            deletedPictures={[]}
            onChange={ev => this.handleChangePicture(ev, 'picture')}
            onDeleteChange={ev => this.handleChangeDeletePictures(ev)}
          />
        </div>

        <div className="modal-dive-site-pic-bottom">
          <div className="modal-dive-site-pic-bottom__left">
            <div className="modal-dive-site-pic-bottom__left__item">
              <SingleButton
                className="secondary"
                icon={images.arrowLeftSvg}
                onClick={() => this.setState({ step: 1 })}
              />
            </div>
            <div className="modal-dive-site-pic-bottom__left__save">
              <MainButton className="primary" text={t('components:actions.photos')} onClick={() => this.handleSave()} />
            </div>
          </div>
          {/* <div className="modal-dive-site-pic-bottom__right">
            <MainButton className="secondary" text={'Saltar'} onClick={() => this.handleSave()} />
          </div> */}
        </div>
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { showModal, onClose } = this.props;
    return (
      <ModalContainer modalClose={() => onClose()} active={showModal}>
        <ModalDiveSiteStyle>{step === 1 ? this.diveSiteManagement() : this.addPhotos(this.state.divesSite.pictures)}</ModalDiveSiteStyle>
      </ModalContainer>
    );
  }
}



function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(withTranslation(['modalMessages','components'])(withRouter(ModalDiveSite)));
