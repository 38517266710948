import styled from 'styled-components';

export const CreateActivitiesGeneralDataStyle = styled.div`
  .create-activities-other {
    display: flex;
    margin-bottom: 24px;
    &__text {
      margin-right: 28px;
    }
    &__check  {
      margin-right: 20px;
    }
  }
`;
