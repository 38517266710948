import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import MyCenterProfileDescription from './my-center-proofile-description/my-center-proofile-description';
import MyCenterProfileServices from './my-center-proofile-services/my-center-proofile-services';
import MyCenterProfileFacilities from './my-center-proofile-facilities/my-center-proofile-facilities';
import MyCenterProfileData from './my-center-profile-data/my-center-profile-data';

export interface IMyCenterProfileProps {}

export interface IMyCenterProfileState {}

export default class MyCenterProfile extends React.Component<IMyCenterProfileProps, IMyCenterProfileState> {
  render() {
    return (
      <Switch>
        <Route path="/centres/:id/business/profile/data" component={MyCenterProfileData} />
        <Route path="/centres/:id/business/profile/description" component={MyCenterProfileDescription} />
        <Route path="/centres/:id/business/profile/services" component={MyCenterProfileServices} />
        <Route path="/centres/:id/business/profile/facilites" component={MyCenterProfileFacilities} />
        <Route path="/centres/:id/business/profile" component={MyCenterProfileData} />
      </Switch>
    );
  }
}
