import React, { Component } from 'react';
import { InputButtonFilesContainer } from './input-button-files-style';
import { images } from '../../assets/images';
import FileItemModal from '../file-item-modal/file-item-modal';
import moment from 'moment';
import 'moment/locale/es.js';
import BodyText from '../text/body-text/body-text';
import InfoText from '../text/info-text/info-text';
import LabelText from '../text/label-text/label';
import { ERROR_MESSAGES_ES } from '../../constants/error-messages';
import { AttachedFile } from '../../types/file';
import FileItemModalUrl from '../file-item-modal-url/file-item-modal-url';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

interface InputButtonFilesProps extends RouteComponentProps, WithTranslation {
  className?: string;
  label?: string;
  required?: boolean;
  errorCode?: string;
  value?: string;
  multiple: boolean;
  accept?: string[];
  onChange?: (files: AttachedFile[]) => void;
  onConfirm?: (files: AttachedFile[]) => void;
}

interface InputButtonFilesState {
  selectedFiles: AttachedFile[];
}

class InputButtonFiles extends Component<InputButtonFilesProps, InputButtonFilesState> {
  state: InputButtonFilesState = {
    selectedFiles: [],
  };
  fileInput: any;

  componentDidMount() {
    document.body.classList.add('no-scroll');
  }
  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
  }

  countCorrectFiles() {
    const { selectedFiles } = this.state;
    return selectedFiles.reduce((sum, item) => {
      if (!item.error) {
        return sum + 1;
      }
      return sum;
    }, 0);
  }

  uploadFile(files: any) {
    const { selectedFiles } = this.state;
    const inputFiles: AttachedFile[] = selectedFiles.slice();

    for (const file of files) {
      let error = false;
      if (file.size > 1024 * 1024 * 2) {
        // max size 2MB
        error = true;
      }
      inputFiles.push({
        error,
        data: file,
        name: file.name,
        size: file.size,
        creation_date: moment(file.lastModified).format('DD MMM YYYY'),
      });
    }
    this.setState({ selectedFiles: inputFiles }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(inputFiles);
    });
  }

  removeFile(item: any, index: number) {
    const { selectedFiles } = this.state;

    selectedFiles.splice(index, 1);
    Array.from(this.fileInput.value).splice(index, 1);

    this.setState({ selectedFiles }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(selectedFiles);
    });
  }

  // DEPRECATED: not being used
  confirmFiles() {
    const { selectedFiles } = this.state;
    const { onConfirm } = this.props;

    const tempFiles = selectedFiles.slice();

    this.setState({ selectedFiles: [] }, () => {
      if (onConfirm) onConfirm(tempFiles);
    });
  }

  render() {
    const { selectedFiles } = this.state;
    const { className, label, required, multiple, errorCode, value, accept, t } = this.props;
    const errorText = errorCode ? ERROR_MESSAGES_ES[errorCode] : '';
    return (
      <InputButtonFilesContainer
        className={`${className} ${required && 'required'} ${errorCode ? ' error' : ''} ${multiple && 'multiple'} `}
      >
        {label && (
          <div className="item-modal-message-label">
            <LabelText>{label}</LabelText>
          </div>
        )}
        <div
          className="
        "
        >
          <>
            <div className="item-modal-message-drag">
              <form className="uploader" encType="multipart/form-data">
                <input
                  multiple={multiple}
                  type="file"
                  name="file"
                  id="file"
                  onChange={e => this.uploadFile(e.target.files)}
                  accept={accept ? accept.toString() : 'image/*'}
                />
              </form>
            </div>
            {errorCode ? (
              <div className="input-box-error">{errorCode && <InfoText>{`${errorText}`}</InfoText>}</div>
            ) : (
                <div className="item-modal-message-disclaimer">
                  <InfoText>{t('indicences:filelongerthan')} 2MB*</InfoText>
                </div>
              )}
          </>
        </div>
      </InputButtonFilesContainer>
    );
  }
}

// eslint-disable-next-line no-undef
export default withTranslation(['incidences'])(withRouter(InputButtonFiles));