import services from './services';
import { ICollaboratorForm } from '../models/collaborator-forms';
import { IOrganization } from '../models/organization';

export const createCollaborator = (data: ICollaboratorForm) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: 'organizations',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const uploadCollaboratorLogo = (organizationId: string, data: FormData) => {
  const endpoint = `organizations/${organizationId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const getOrganizations = () => {
  return new Promise<IOrganization>((resolve, reject) => {
    const args = {
      endpoint: 'organizations',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCertifiers = () => {
  return new Promise<IOrganization>((resolve, reject) => {
    const args = {
      params: {
        filter: {
          where: {
            type: { value: 'certifier' },
          },
        },
      },
      endpoint: 'organizations',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCollaborators = () => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      endpoint: 'organizations',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCollaboratorCourses = (certifierId: string | number, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `organizations/${certifierId}/courses`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const deleteCollaboratorCourse = (certifierId: string, courseId: string) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      endpoint: `organizations/${certifierId}/courses/${courseId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const patchOrganization = (organizationId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `organizations/${organizationId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const postCertifierCourse = (organizationId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `organizations/${organizationId}/courses`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchCertifierCourse = (organizationId: string, courseId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `organizations/${organizationId}/courses/${courseId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const postCertifierCourseLicense = (organizationId: string, courseId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `organizations/${organizationId}/courses/${courseId}/upload-e-license`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};

export const getOrganization = (organizationId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `organizations/${organizationId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const validateDiverDocument = (organizationId: string, diverId: string, docId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `organizations/${organizationId}/divers/${diverId}/documents/${docId}/validate`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const getOrganizationCentres = (organizationId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `organizations/${organizationId}/centres`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCertifierDivers = (organizationId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `organizations/${organizationId}/divers`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postInviteDiver = (organizationId: string, data: any, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `organizations/${organizationId}/invite-diver`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const postInviteCentre = (organizationId: string, data: any, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `organizations/${organizationId}/invite-centre`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const getOrganizationStaff = (organizationId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `organizations/${organizationId}/staff`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postOrganizationStaff = (organizationId: string, data: any, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `organizations/${organizationId}/staff`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchOrganizationStaff = (organizationId: string, staffId: string, data: any, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `organizations/${organizationId}/staff/${staffId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteOrganizationStaff = (organizationId: string, staffId: string) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      endpoint: `organizations/${organizationId}/staff/${staffId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};
