import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// const languages = ['es', 'en'];
// const namespaces = ['intro', 'buttons', 'modalMessages'];

// const getAllResources = () => {
//   return languages.reduce(async (all: {[key: string]: any}, lang) => {
//     all[lang] = await namespaces.reduce(async (sum: {[key: string]: any}, ns) => {
//         try {
//           const moduleLang = await import(`./${lang}/${ns}`);
//           return sum[ns] = moduleLang;
//         } catch(err) {
//           return sum[ns] = {};
//         }
//       }, {});
//     }, {});
// }

import pushFr from './fr/push.json';
import introFr from './fr/intro.json';
import buttonsFr from './fr/buttons.json';
import modalMessagesFr from './fr/modalMessages.json';
import incidencesFr from './fr/incidences.json';
import tablesFr from './fr/tables.json';
import layoutsFr from './fr/layouts.json';
import componentsFr from './fr/components.json';
import inputsFr from './fr/inputs.json';

import pushEn from './en/push.json';
import introEn from './en/intro.json';
import buttonsEn from './en/buttons.json';
import modalMessagesEn from './en/modalMessages.json';
import incidencesEn from './en/incidences.json';
import tablesEn from './en/tables.json';
import layoutsEn from './en/layouts.json';
import componentsEn from './en/components.json';
import inputsEn from './en/inputs.json';

import pushIt from './it/push.json';
import introIt from './it/intro.json';
import buttonsIt from './it/buttons.json';
import modalMessagesIt from './it/modalMessages.json';
import incidencesIt from './it/incidences.json';
import tablesIt from './it/tables.json';
import layoutsIt from './it/layouts.json';
import componentsIt from './it/components.json';
import inputsIt from './it/inputs.json';

import pushDe from './de/push.json';
import introDe from './de/intro.json';
import buttonsDe from './de/buttons.json';
import modalMessagesDe from './de/modalMessages.json';
import incidencesDe from './de/incidences.json';
import tablesDe from './de/tables.json';
import layoutsDe from './de/layouts.json';
import componentsDe from './de/components.json';
import inputsDe from './de/inputs.json';

import pushPt from './pt/push.json';
import introPt from './pt/intro.json';
import buttonsPt from './pt/buttons.json';
import modalMessagesPt from './pt/modalMessages.json';
import incidencesPt from './pt/incidences.json';
import tablesPt from './pt/tables.json';
import layoutsPt from './pt/layouts.json';
import componentsPt from './pt/components.json';
import inputsPt from './pt/inputs.json';

import pushEs from './es/push.json';
import introEs from './es/intro.json';
import buttonsEs from './es/buttons.json';
import modalMessagesEs from './es/modalMessages.json';
import incidencesEs from './es/incidences.json';
import tablesEs from './es/tables.json';
import layoutsEs from './es/layouts.json';
import componentsEs from './es/components.json';
import inputsES from './es/inputs.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: 'en',
    fallbackLng: 'es',
    debug: false,
    returnEmptyString: true,

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */

    resources: {
      es: {
        intro: introEs,
        buttons: buttonsEs,
        modalMessages: modalMessagesEs,
        incidences: incidencesEs,
        push: pushEs,
        tables: tablesEs,
        layouts: layoutsEs,
        components: componentsEs,
        inputs: inputsES,
      },
      en: {
        intro: pushEn,
        buttons: introEn,
        modalMessages: buttonsEn,
        incidences: modalMessagesEn,
        push: incidencesEn,
        tables: tablesEn,
        layouts: layoutsEn,
        components: componentsEn,
        inputs: inputsEn,
      },
      it: {
        intro: pushIt,
        buttons: introIt,
        modalMessages: buttonsIt,
        incidences: modalMessagesIt,
        push: incidencesIt,
        tables: tablesIt,
        layouts: layoutsIt,
        components: componentsIt,
        inputs: inputsIt,
      },
      pt: {
        intro: pushIt,
        buttons: introIt,
        modalMessages: buttonsIt,
        incidences: modalMessagesIt,
        push: incidencesIt,
        tables: tablesIt,
        layouts: layoutsIt,
        components: componentsIt,
        inputs: inputsIt,
      },
      de: {
        intro: pushDe,
        buttons: introDe,
        modalMessages: buttonsDe,
        incidences: modalMessagesDe,
        push: incidencesDe,
        tables: tablesDe,
        layouts: layoutsDe,
        components: componentsDe,
        inputs: inputsDe,
      },
      fr: {
        intro: pushFr,
        buttons: introFr,
        modalMessages: buttonsFr,
        incidences: modalMessagesFr,
        push: incidencesFr,
        tables: tablesFr,
        layouts: layoutsFr,
        components: componentsFr,
        inputs: inputsFr,
      },
    },
    // have a common namespace used around the full app
    ns: ['intro', 'buttons', 'modalMessages', 'tables', 'layouts', 'components', 'inputs'],
    defaultNS: 'intro',
  });

export default i18n;
