import React from 'react';
import { ModalDestinationStyle } from './modal-destination-style';
import ModalContainer from '../../../../components/modals/modal-container';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../../../components/text/body-text/body-text';
import { FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import InputBox from '../../../../components/input-box';
import MainButton from '../../../../components/main-button';
import { images } from '../../../../assets/images';
import SelectBox from '../../../../components/select-box';

import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import DicotomicButton from '../../../../components/dicotomic-button';
import InputArea from '../../../../components/input-area/input-area';
import InfoText from '../../../../components/text/info-text/info-text';
import InputUploadFiles from '../../../../components/input-upload-file/input-upload-file';
import { createDestinationsFormData, uploadDestinationsFormData } from '../../../../services/destinations';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { COUNTRIES, COUNTRIES_KEY_LABEL } from '../../../../constants/countries';

interface ModalDestinationProps extends WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  onAccept: () => void;
  editItem: any;
}
interface ModalDestinationState {
  destination: any;
}

class ModalDestination extends React.Component<ModalDestinationProps, ModalDestinationState> {
  readonly state: ModalDestinationState;
  constructor(props: ModalDestinationProps) {
    super(props);

    this.state = {
      destination: {
        cotinent: {
          value: props.editItem.cotinent || '',
          error: false,
          errorCode: '',
          required: true,
        },
        country: {
          value: props.editItem.country || '',
          error: false,
          errorCode: '',
          required: true,
        },
        file: {
          value: props.editItem.picture || '',
          error: false,
          errorCode: '',
          required: true,
        },
        active: {
          value: props.editItem.active ? props.editItem.active.toString() : 'false',
          error: false,
          errorCode: '',
          required: true,
        },
      },
    };
  }

  createDestination = async (data: any) => {
    const { onAccept } = this.props;
    try {
      const formData = new FormData();
      formData.set('cotinent', data.cotinent);
      formData.set('country', data.country);
      formData.set('file', data.file);
      formData.set('active', data.active);
      const response: any = await createDestinationsFormData(formData);
      onAccept();
    } catch (err) {
      console.log(err);
    }
  };

  editDestination = async (data: any) => {
    const { onAccept, editItem } = this.props;
    try {
      const formData = new FormData();
      formData.set('cotinent', data.cotinent);
      formData.set('country', data.country);
      formData.set('file', data.file);
      formData.set('active', data.active);
      const response: any = await uploadDestinationsFormData(editItem.id, formData);
      onAccept();
    } catch (err) {
      console.log(err);
    }
  };

  changeValue = (value: string, key: string, subKey?: string) => {
    const { destination } = this.state;
    if (!subKey) {
      destination[key].value = value;
    } else {
      destination[key][subKey].value = value;
    }
    this.setState({ destination });
  };

  handleChangePicture = (value: any, key: string) => {
    const { destination } = this.state;
    destination[key].value = value[0] || '';
  };

  handleAccept = () => {
    const { destination } = this.state;
    const { editItem } = this.props;
    let isOk = true;
    Object.keys(destination).forEach((k: string) => {
      if (destination[k].required && destination[k].value === '') {
        destination[k].error = true;
        destination[k].errorCode = 'required';
        isOk = false;
      } else {
        destination[k].error = false;
        destination[k].errorCode = '';
      }
    });
    this.setState({ destination });
    if (isOk) {
      const data: any = {};
      Object.keys(destination).forEach((k: string) => {
        if (k === 'file') {
          data[k] = destination[k].value.data;
        } else {
          data[k] = destination[k].value;
        }
      });
      if (editItem) {
        this.editDestination(data);
      } else {
        this.createDestination(data);
      }
    }
  };

  render() {
    const { showModal, onCancel, t } = this.props;
    const { destination } = this.state;

    return (
      <ModalContainer title={``} active={showModal} modalClose={() => onCancel()}>
        <ModalDestinationStyle>
          <div className="modal-destination-title">
            <SubheadDestacadoText>{t('modalMessages:modal-destination.title')}</SubheadDestacadoText>
          </div>
          <div className="modal-destination-text">
            <BodyText>{t('modalMessages:modal-destination.text')}</BodyText>
          </div>
          <div className="modal-destination-file">
            <InputUploadFiles
              className={'full'}
              multiple={false}
              label="Logo"
              required={destination.file.required}
              errorCode={destination.file.errorCode}
              onChange={ev => this.handleChangePicture(ev, 'file')}
              value={destination.file.value}
            />
          </div>
          <div className="modal-destination-top">
            <div className="modal-destination-top-select">
              <SelectBox
                labelText="Cotinente"
                placeholder="Cotinente"
                className="rounded-title"
                optionsText={[
                  { label: t('layouts:config.destination.europe'), value: 'europe' },
                  { label: t('layouts:config.destination.northAmerica'), value: 'northAmerica' },
                  { label: t('layouts:config.destination.southAmerica'), value: 'southAmerica' },
                  { label: t('layouts:config.destination.africa'), value: 'africa' },
                  { label: t('layouts:config.destination.asia'), value: 'asia' },
                  { label: t('layouts:config.destination.australia'), value: 'australia' },
                  { label: t('layouts:config.destination.antartica'), value: 'antartica' },
                ]}
                optionKey={'value'}
                optionValue={'label'}
                required={destination.cotinent.required}
                errorCode={destination.cotinent.errorCode}
                initialValue={destination.cotinent.value}
                onChange={(value: string) => this.changeValue(value, 'cotinent')}
              />
            </div>
            <div className="modal-destination-top-row">
              <div className="modal-destination-top-row__input">
                <SelectBox
                  labelText="País"
                  placeholder="País"
                  required={true}
                  className="rounded-title"
                  disabled={destination.country.disabled}
                  optionsText={COUNTRIES}
                  defaultValueFilterText={COUNTRIES_KEY_LABEL[destination.country.value]}
                  optionKey={'value'}
                  optionValue={'label'}
                  searchable={true}
                  initialValue={destination.country.value}
                  errorCode={destination.country.errorCode}
                  icon={images.arrowDown2BlueSvg}
                  onChange={value => {
                    this.changeValue(value, 'country');
                  }}
                />
              </div>
              <div className="modal-destination-top-row__dicotomic">
                <DicotomicButton
                  className={'modal dark-blue'}
                  initialValue={destination.active.value === 'true' ? 'on' : 'off'}
                  onChange={(value: string) => this.changeValue((value === 'on').toString(), 'active')}
                  options={[
                    { code: 'on', label: t('buttons:active') },
                    { code: 'off', label: t('buttons:disable') },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="modal-destination-button">
            <MainButton onClick={() => this.handleAccept()} text={t('buttons:save')} type="primary" />
          </div>
        </ModalDestinationStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(ModalDestination);
