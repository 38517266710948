import styled from 'styled-components';

export const ConfigurationFeeStyle = styled.div`
  .configuration-fee-text {
    margin-bottom: 80px;
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .configuration-fee-input {
    margin-bottom: 60px;
    width: 346px;
  }

  .config-contact-buttons {
    display: flex;
    &__item {
      margin-right: 12px;
      width: 144px;
    }
  }
`;
