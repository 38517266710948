import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const MyCenterProfileDataStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .my-center-profile-data-left {
    width: calc((100% - 80px) / 2);
  }
  .my-center-profile-data-right {
    width: calc((100% - 80px) / 2);
  }

  .my-center-profile-data-section {
    margin-bottom: 80px;
  }

  .my-center-profile-data-title {
    display: flex;
    align-items: center;
    padding-bottom: 13px;
    border-bottom: 1px solid ${colors['NE-002/100']};
    margin-bottom: 38px;
    &__img {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }
    &__text {
      color: ${colors['NE-001/100']};
    }
  }
`;
