import React, { Component } from 'react';
import { HomeMenuContainer } from './home-menu-style';
import { images } from '../../assets/images/index';
import MainButton from '../main-button';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import ModalLogOut from '../modals/modal-logout/modal-logout';
import MainButtonDrop from '../main-button-drop';
import MainButtonDropProfile from '../main-button-drop-profile';
import Can from '../../roles/can-component';
import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';
import Home from '../../navigation/home';
import Scrollbars from 'react-custom-scrollbars';
import { withTranslation, WithTranslation } from 'react-i18next';
import { setValidCentre, setStaffPermissions } from '../../redux/actions';
import services from '../../services/services';
import SelectBox from '../../components/select-box';
import { getDivers } from '../../services/diver';
import { countUnreadNotifications } from '../../services/notifications';
import { APP_WEB_URL } from '../../env';


interface HomeMenuProps extends RouteComponentProps, WithTranslation {
  agent?: any;
  onLogout: () => void;
  isCertifier: boolean;
  profilePicture?: string;
}

interface HomeMenuState {
  modalLogOut: boolean;
  sectionOpen: string;
  activeSection: string;
  centres: any[];
  unReadNotifications: number,
}

class HomeMenu extends Component<HomeMenuProps, HomeMenuState> {
  static sections: { [key: string]: string } = {
    centres: 'centres',
    roles: 'roles',
    companies: 'companies',
    users: 'users',
    collaborators: 'collaborators',
    diveSites: 'dive-sites',
    incidences: 'incidences',
    messages: 'messages',
    notifications: 'notifications',
    stats: 'stats',
    ads: 'ads',
    billings: 'billings',
    config: 'configuration',
    organizationProfile: 'profile',
    certifierCatalog: 'catalog',
    certifierCentres: 'centres',
    certifierDivers: 'divers',
    certifierProject: 'projects',
    organizationStats: 'organization-stats',
  };

  state: HomeMenuState = {
    modalLogOut: false,
    sectionOpen: '',
    activeSection: this.props.history.location.pathname,
    centres: [],
    unReadNotifications: 0,
  };

  static getDerivedStateFromProps(nextProps: any, prevState: HomeMenuState) {
    if (nextProps.history.location.pathname !== prevState.activeSection) {
      return { activeSection: nextProps.history.location.pathname };
    }
    return null;
  }

  componentDidMount() {
    const { agent } = this.props;

    if (agent.roles.includes('superAdmin')) {
      localStorage.removeItem('organizationId');
      localStorage.removeItem('isCertifier');
    } else {
      const centreId = localStorage.getItem('centreId');
      if (centreId && Number(centreId) > 0) {
        window.location.href = '/centres/' + centreId + '/planning/calendar';
      }

    }
    services.setValue(setValidCentre(true));
    services.setValue(setStaffPermissions(null));
    this.countUnreadNotifications();
    this.getCentres();
  }

  componentDidUpdate(prevProps: any, prevState: HomeMenuState) {

    if (prevProps.history.location.pathname !== this.props.history.location.pathname) {
      this.setState({ activeSection: this.props.history.location.pathname });
    }
  }

  setSectionOpen = (section: string) => {
    this.setState({ sectionOpen: section });
  };

  getCentres() {
    const endpoint = 'centres';
    const params = {
      filter: {
        where: { user: localStorage.userId },
        orderBy: [{ key: 'createdAt', direction: 'ASC' }],
        relations: ['user'],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        const centres: any[] = res.data.map((item: any) => {
          const { id, name, rating, address, town, user } = item;
          return {
            id,
            name,
            rating,
            location: `${address}, ${town}`,
            managers: user.name ? `${user.name} ${user.surname}` : '',
          };
        }).map((centre: any) => { return { "value": centre.id.toString(), "label": centre.name } })

        centres.push(...await this.getCentresStaff(centres));
        this.setState({ centres });
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }
  async countUnreadNotifications() {
    const user = localStorage.getItem("userId");
    if (user) {
      const res: any = await countUnreadNotifications(parseInt(user));
      if (res.data.length !== 0 && parseInt(res.data[0].count) !== 0) {
        this.state.unReadNotifications = parseInt(res.data[0].count);
        this.setState({ ...this.state });
      }
    }

  }

  async getCentresStaff(centres: any[]) {

    const params = {
      filter: {
        where: { user: localStorage.userId },
        relations: ['staff_centres', 'staff_centres.centre', 'centre.user'],
      },
    };

    let staffCentres = [];
    const centresId = centres.map(centre => centre.value);
    const diver = (await getDivers(params)).data.data[0];
    if (diver && diver.staff_centres && diver.staff_centres.length > 0) {
      for (const staffCentre of diver.staff_centres) {
        if (staffCentre.centre && staffCentre.centre.id && !centresId.includes(staffCentre.centre.id.toString())) staffCentres.push(staffCentre.centre)
      }
      return staffCentres.map((centre: any) => { return { "value": centre.id.toString(), "label": centre.name } });
    }
    return [];

  }

  render() {
    const { basePath } = Home;
    const { sections } = HomeMenu;
    const { agent, onLogout, t, isCertifier, profilePicture } = this.props;
    const { modalLogOut, activeSection, sectionOpen, centres } = this.state;

    return (
      <>
        <HomeMenuContainer>
          <div className="menu-top">
            <div className="menu-logo">
              <NavLink to="/">
                <img src={images.diveasappLogoWhiteSvg} alt="" />
              </NavLink>
            </div>
            <div className="menu-back">
              {!agent.roles.includes('superAdmin') ?
                <div className="menu-centers-button">
                  <SelectBox
                    className="rounded-title"
                    optionsText={centres}
                    required={true}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={''}
                    initialValue={''}
                    onChange={(value: any) => {
                      this.props.history.push(`/centres/${value}/planning/calendar`);
                      window.location.reload();
                    }}
                  />
                </div>
                : ''}
            </div>
            <Scrollbars
              renderTrackVertical={props => <div {...props} className="track-vertical" />}
              renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
              style={{ width: '100%', height: '100%' }}
              autoHide={false}
              autoHideTimeout={200}
              autoHideDuration={200}
            >
              <div className="menu-items">
                { /*!agent.roles.includes('superAdmin') && (
                  <Can roles={agent.roles} perform="centres:management">
                    <div className="menu-items__button">
                      <NavLink to={`${basePath}/${sections.centres}`}>
                        <MainButton
                          text={t('layouts:home-menu.dive-center')}
                          iconImg={images.divingCompaniestMenu}
                          type="simple-menu"
                          active={activeSection.includes(sections.centres)}
                          onClick={() => {
                            const section = sectionOpen !== sections.centres ? sections.centres : '';
                            this.setState({ sectionOpen: section });
                          }}
                        />
                      </NavLink>
                    </div>
                  </Can>
                )*/
                }

                { /*agent.roles.includes('divingCenter_admin') && (
                  <Can roles={agent.roles} perform="roles:management">
                    <div className="menu-items__button">
                      <NavLink to={`${basePath}/${sections.roles}`}>
                        <MainButton
                          text={t('layouts:home-menu.role')}
                          iconImg={images.rolesManagementMenu}
                          type="simple-menu"
                          active={activeSection.includes(sections.roles)}
                          onClick={() => {
                            const section = sectionOpen !== sections.roles ? sections.roles : '';
                            this.setState({ sectionOpen: section });
                          }}
                        />
                      </NavLink>
                    </div>
                  </Can>
                )*/
                }

                {/* certificadora */}
                <Can roles={agent.roles} perform="organization:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/${sections.organizationProfile}`}>
                      <MainButton
                        text={t(
                          isCertifier ? 'layouts:home-menu.certifier-profile' : 'layouts:home-menu.organization-profile'
                        )}
                        iconImg={images.profileCertifierMenu}
                        type="simple-menu"
                        active={activeSection === `/${sections.organizationProfile}`}
                        onClick={() => {
                          const section = sectionOpen !== sections.roles ? sections.roles : '';
                          this.setState({ sectionOpen: section });
                          this.countUnreadNotifications();
                        }}
                      />
                    </NavLink>
                  </div>
                </Can>

                {!isCertifier && (
                  <Can roles={agent.roles} perform={'organization:management'}>
                    <div className="menu-items__button">
                      <NavLink to={`${basePath}/${sections.certifierProject}`}>
                        <MainButton
                          text={t('layouts:home-menu.certifier-projects')}
                          iconImg={images.projectsSvg}
                          type="simple-menu"
                          active={activeSection.includes(sections.certifierProject)}
                          onClick={() => {
                            const section = sectionOpen !== sections.roles ? sections.roles : '';
                            this.setState({ sectionOpen: section });
                            this.countUnreadNotifications();
                          }}
                        />
                      </NavLink>
                    </div>
                  </Can>
                )}

                {isCertifier && (
                  <Can roles={agent.roles} perform="organization:management">
                    <div className="menu-items__button">
                      <NavLink to={`${basePath}/${sections.certifierCatalog}`}>
                        <MainButton
                          text={t('layouts:home-menu.certifier-catalog')}
                          iconImg={images.certifierCatalogWhite}
                          type="simple-menu"
                          active={activeSection === '/' || activeSection.includes(sections.certifierCatalog)}
                          onClick={() => {
                            const section = sectionOpen !== sections.roles ? sections.roles : '';
                            this.setState({ sectionOpen: section });
                            this.countUnreadNotifications();
                          }}
                        />
                      </NavLink>
                    </div>
                  </Can>
                )}

                {isCertifier && (
                  <Can roles={agent.roles} perform="organization:management">
                    <div className="menu-items__button">
                      <NavLink to={`${basePath}/${sections.certifierCentres}`}>
                        <MainButton
                          text={t('layouts:home-menu.certifier-dive-center')}
                          iconImg={images.divingCompaniestMenu}
                          type="simple-menu"
                          active={activeSection.includes(sections.certifierCentres)}
                          onClick={() => {
                            const section = sectionOpen !== sections.certifierCentres ? sections.certifierCentres : '';
                            this.setState({ sectionOpen: section });
                            this.countUnreadNotifications();
                          }}
                        />
                      </NavLink>
                    </div>
                  </Can>
                )}

                <Can roles={agent.roles} perform="organization:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/${sections.certifierDivers}`}>
                      <MainButton
                        text={t('layouts:home-menu.certifier-my-divers')}
                        iconImg={images.myDivesMenu}
                        type="simple-menu"
                        active={activeSection.includes(sections.certifierDivers)}
                        onClick={() => {
                          const section = sectionOpen !== sections.certifierDivers ? sections.certifierDivers : '';
                          this.setState({ sectionOpen: section });
                          this.countUnreadNotifications();
                        }}
                      />
                    </NavLink>
                  </div>
                </Can>



                {<Can roles={agent.roles} perform="organization:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/organization/${sections.billings}`}>
                      <MainButton
                        text={t('layouts:home-menu.billing')}
                        iconImg={images.fileWhiteSvg}
                        type="simple-menu"
                        active={activeSection.includes(sections.billings)}
                        onClick={() => {
                          const section = sectionOpen !== sections.billings ? sections.billings : '';
                          this.setState({ sectionOpen: section });
                          this.countUnreadNotifications();
                        }}
                      />
                    </NavLink>
                  </div>
                </Can>
                }
                {
                  <Can roles={agent.roles} perform="organization:management">
                    <div className="menu-items__button">
                      <NavLink to={`${basePath}/${sections.certifierCatalog}`}>
                        <MainButton
                          text={t('layouts:home-menu.certifier-record')}
                          iconImg={images.actCertifierMenu}
                          type="simple-menu"
                          active={activeSection.includes(sections.roles)}
                          onClick={() => {
                            const section = sectionOpen !== sections.roles ? sections.roles : '';
                            this.setState({ sectionOpen: section });
                            this.countUnreadNotifications();
                          }}
                        />
                      </NavLink>
                    </div>
                  </Can>
                }

                {isCertifier && (
                  <Can roles={agent.roles} perform="organization:management">
                    <div className="menu-items__button">
                      <NavLink to={`${basePath}/${sections.stats}`}>
                        <MainButton
                          text={t('layouts:home-menu.certifier-stats')}
                          iconImg={images.chartWhiteSvg}
                          type="simple-menu"
                          active={activeSection.includes(sections.stats)}
                          onClick={() => {
                            const section = sectionOpen !== sections.stats ? sections.stats : '';
                            this.setState({ sectionOpen: section });
                            this.countUnreadNotifications();
                          }}
                        />
                      </NavLink>
                    </div>
                  </Can>
                )}

                {!isCertifier && (
                  <Can roles={agent.roles} perform="organization:management">
                    <div className="menu-items__button">
                      <NavLink to={`${basePath}/${sections.organizationStats}`}>
                        <MainButton
                          text={t('layouts:home-menu.certifier-stats')}
                          iconImg={images.chartWhiteSvg}
                          type="simple-menu"
                          active={activeSection.includes(sections.organizationStats)}
                          onClick={() => {
                            const section =
                              sectionOpen !== sections.organizationStats ? sections.organizationStats : '';
                            this.setState({ sectionOpen: section });
                            this.countUnreadNotifications();
                          }}
                        />
                      </NavLink>
                    </div>
                  </Can>
                )}
                {!agent.roles.includes('organization_staff') && isCertifier && (
                  <Can roles={agent.roles} perform="organization:management">
                    <div className="menu-items__button">
                      <NavLink to={`${basePath}/${sections.roles}`}>
                        <MainButton
                          text={t('layouts:home-menu.certifier-roles')}
                          iconImg={images.rolesManagementMenu}
                          type="simple-menu"
                          active={activeSection.includes(sections.roles)}
                          onClick={() => {
                            const section = sectionOpen !== sections.roles ? sections.roles : '';
                            this.setState({ sectionOpen: section });
                            this.countUnreadNotifications();
                          }}
                        />
                      </NavLink>
                    </div>
                  </Can>
                )}
                <Can roles={agent.roles} perform="organization:management">
                  <div className="menu-items__button">
                    {/* <MessagesDropdown
                      activeSection={activeSection}
                      sectionOpen={sectionOpen}
                      sections={sections}
                      to={[
                        `${basePath}/${sections.messages}`,
                        `${basePath}/${sections.notifications}`
                      ]}
                      setSectionOpen={this.setSectionOpen}
                    /> */}
                    <MainButtonDrop
                      text={t('layouts:home-menu.certifier-notifications')}
                      iconImg={images.messageCircleWhiteSvg}
                      type="simple-menu"
                      propOpen={sectionOpen === sections.collaborators}
                      active={sectionOpen.includes(sections.collaborators)}
                      onClick={() => {
                        const section = sectionOpen !== sections.collaborators ? sections.collaborators : '';
                        this.setState({ sectionOpen: section });
                        this.countUnreadNotifications();
                      }}
                    >
                      <div className="main-button-drop-content__item">
                        <NavLink to={`${basePath}/${sections.messages}`}>
                          <p>{t('layouts:home-menu.certifier-notifications')}</p>
                        </NavLink>
                      </div>
                      <div className="main-button-drop-content__item">
                        <NavLink to={`${basePath}/${sections.notifications}`}>
                          <p>{t('layouts:home-menu.notification')}</p>
                        </NavLink>
                      </div>
                    </MainButtonDrop>
                  </div>
                </Can>
                <Can roles={agent.roles} perform="organization:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/${sections.config}`}>
                      <MainButton
                        text={t('layouts:home-menu.certifier-config')}
                        iconImg={images.settingsWhiteSvg}
                        type="simple-menu"
                        active={activeSection.includes(sections.config)}
                        onClick={() => {
                          const section = sectionOpen !== sections.config ? sections.config : '';
                          this.setState({ sectionOpen: section });
                          this.countUnreadNotifications();
                        }}
                      />
                    </NavLink>
                  </div>
                </Can>

                {/* Certificadora */}

                <Can roles={agent.roles} perform="companies:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/${sections.companies}`}>
                      <MainButton
                        text={t('layouts:home-menu.dive-center')}
                        iconImg={images.divingCompaniestMenu}
                        type="simple-menu"
                        active={activeSection === '/' || activeSection.includes(sections.companies)}
                        onClick={() => {
                          const section = sectionOpen !== sections.companies ? sections.companies : '';
                          this.setState({ sectionOpen: section });
                          this.countUnreadNotifications();
                        }}
                      />
                    </NavLink>
                  </div>
                </Can>

                <Can roles={agent.roles} perform="users:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/${sections.users}`}>
                      <MainButton
                        text={t('layouts:home-menu.users')}
                        iconImg={images.myDivesMenu}
                        type="simple-menu"
                        active={activeSection.includes(sections.users)}
                        onClick={() => {
                          const section = sectionOpen !== sections.users ? sections.users : '';
                          this.setState({ sectionOpen: section });
                          this.countUnreadNotifications();
                        }}
                      />
                    </NavLink>
                  </div>
                </Can>

                <Can roles={agent.roles} perform="collaborators:management">
                  <div className="menu-items__button">
                    <MainButtonDrop
                      text={t('layouts:home-menu.collaborators')}
                      iconImg={images.menuCollaborators}
                      type="simple-menu"
                      propOpen={sectionOpen === sections.collaborators}
                      active={sectionOpen.includes(sections.collaborators)}
                      onClick={() => {
                        const section = sectionOpen !== sections.collaborators ? sections.collaborators : '';
                        this.setState({ sectionOpen: section });
                        this.countUnreadNotifications();
                      }}
                    >
                      <div className="main-button-drop-content__item">
                        <NavLink to={`${basePath}/${sections.collaborators}/certifiers`}>
                          <p>{t('layouts:home-menu.certifiers')}</p>
                        </NavLink>
                      </div>
                      <div className="main-button-drop-content__item">
                        <NavLink to={`${basePath}/${sections.collaborators}/marine-conservation`}>
                          <p>{t('layouts:home-menu.marine-conservation')}</p>
                        </NavLink>
                      </div>
                    </MainButtonDrop>
                  </div>
                </Can>
                {/*
              <Can roles={agent.roles} perform="dive-sites:management">
                <div className="menu-items__button">
                  <NavLink to={`${basePath}/${sections.diveSites}`}>
                    <MainButton
                      text="Puntos inmersión"
                      iconImg={images.layersWhiteSvg}
                      type="simple-menu"
                      active={activeSection.includes(sections.diveSites)}
                      onClick={() => {
                        const section = sectionOpen !== sections.diveSites ? sections.diveSites : '';
                        this.setState({ sectionOpen: section });
                      }}
                    />
                  </NavLink>
                </div>
              </Can>
 */}

                <Can roles={agent.roles} perform="collaborators:management">
                  <div className="menu-items__button">
                    <MainButtonDrop
                      text={t('layouts:home-menu.dive-sites')}
                      iconImg={images.layersWhiteSvg}
                      type="simple-menu"
                      propOpen={sectionOpen === sections.diveSites}
                      active={sectionOpen.includes(sections.diveSites)}
                      onClick={() => {
                        const section = sectionOpen !== sections.diveSites ? sections.diveSites : '';
                        this.setState({ sectionOpen: section });
                        this.countUnreadNotifications();
                      }}
                    >
                      <div className="main-button-drop-content__item">
                        <NavLink to={`${basePath}/${sections.diveSites}/dive-site`}>
                          <p>{t('layouts:home-menu.dive-sites')}</p>
                        </NavLink>
                      </div>
                      <div className="main-button-drop-content__item">
                        <NavLink to={`${basePath}/${sections.diveSites}/marine-reserve`}>
                          <p>{t('layouts:home-menu.reserve')}</p>
                        </NavLink>
                      </div>
                    </MainButtonDrop>
                  </div>
                </Can>
                {/*
                <Can roles={agent.roles} perform="incidences:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/${sections.incidences}`}>
                      <MainButton
                        text={t('layouts:home-menu.incidences')}
                        iconImg={images.alertWhiteSvg}
                        type="simple-menu"
                        active={activeSection.includes(sections.incidences)}
                        onClick={() => {
                          const section = sectionOpen !== sections.incidences ? sections.incidences : '';
                          this.setState({ sectionOpen: section });
                        }}
                      />
                    </NavLink>
                  </div>
                </Can>
                      */}

                <Can roles={agent.roles} perform="notifications:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/${sections.notifications}`}>
                      <MainButton
                        text={(this.state.unReadNotifications !== 0 ? `(${this.state.unReadNotifications}) ` : '') + t('layouts:home-menu.notification')}
                        iconImg={this.state.unReadNotifications !== 0 ? images.alertCircleRedSvg : images.alertWhiteSvg}
                        /*text={t('layouts:home-menu.notification')}
                        iconImg={images.bellWhiteSvg}*/
                        type="simple-menu"
                        active={activeSection.includes(sections.notifications)}
                        onClick={() => {
                          const section = sectionOpen !== sections.notifications ? sections.notifications : '';
                          this.setState({ sectionOpen: section });
                        }}

                      />
                    </NavLink>
                  </div>
                </Can>

                <Can roles={agent.roles} perform="ads:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/${sections.ads}`}>
                      <MainButton
                        text={t('layouts:home-menu.ads')}
                        iconImg={images.adsMenu}
                        type="simple-menu"
                        active={activeSection.includes(sections.ads)}
                        onClick={() => {
                          const section = sectionOpen !== sections.ads ? sections.ads : '';
                          this.setState({ sectionOpen: section });
                        }}
                      />
                    </NavLink>
                  </div>
                </Can>

                {<Can roles={agent.roles} perform="billings:superadmin">
                  <div className="menu-items__button">
                    <MainButtonDrop
                      text={t('layouts:home-menu.billing')}
                      iconImg={images.fileWhiteSvg}
                      type="simple-menu"
                      propOpen={sectionOpen === sections.billings}
                      active={sectionOpen.includes(sections.billings)}
                      onClick={() => {
                        const section = sectionOpen !== sections.billings ? sections.billings : '';
                        this.setState({ sectionOpen: section });
                      }}
                    >
                      <div className="main-button-drop-content__item">
                        <NavLink to={`${basePath}/${sections.billings}/bills`}>
                          <p>{t('layouts:home-menu.bills')}</p>
                        </NavLink>
                      </div>
                      <div className="main-button-drop-content__item">
                        <NavLink to={`${basePath}/${sections.billings}/bookings`}>
                          <p>{t('layouts:home-menu.bookings')}</p>
                        </NavLink>
                      </div>
                    </MainButtonDrop>
                  </div>
                </Can>
                }

                <Can roles={agent.roles} perform="stats:management">
                  <div className="menu-items__button">
                    <NavLink to={`${basePath}/${sections.stats}`}>
                      <MainButton
                        text={t('layouts:home-menu.stats')}
                        iconImg={images.chartWhiteSvg}
                        type="simple-menu"
                        active={activeSection.includes(sections.stats)}
                        onClick={() => {
                          const section = sectionOpen !== sections.stats ? sections.stats : '';
                          this.setState({ sectionOpen: section });
                        }}
                      />
                    </NavLink>
                  </div>
                </Can>


              </div>
            </Scrollbars>
          </div>
          <div className="menu-bottom">
            {agent.roles.includes('superAdmin') ?

              <div className="menu-bottom__profile">
                <NavLink to="/my-profile">
                  <MainButton
                    type="simple-menu avatar"
                    iconImg={profilePicture ? profilePicture : images.avatar}
                    text={t('layouts:home-menu.my-profile')}
                  />
                </NavLink>
              </div>
              :
              <div className="menu-bottom__profile">
                <a href={`${APP_WEB_URL}/profile`} target="_blank">
                  <MainButton
                    type="simple-menu avatar"
                    iconImg={profilePicture ? profilePicture : images.avatarDiverDefault}
                    text={t('layouts:detail-menu.my-profile')}
                  />
                </a>
              </div>
            }
            {agent.roles.includes('superAdmin') &&
              <Can roles={agent.roles} perform="configuration:management">
                <div className="menu-bottom__profile">
                  <MainButtonDropProfile
                    text={t('layouts:home-menu.config')}
                    iconImg={images.settingsWhiteSvg}
                    type="simple-menu"
                    propOpen={sectionOpen === sections.config}
                    active={sectionOpen.includes(sections.config)}
                    onClick={() => {
                      const section = sectionOpen !== sections.config ? sections.config : '';
                      this.setState({ sectionOpen: section });
                    }}
                  >
                    <div className="main-button-drop-content__item">
                      <NavLink to={`${basePath}/${sections.config}/mail-template`}>
                        <p>{t('layouts:home-menu.email')}</p>
                      </NavLink>
                    </div>
                    <div className="main-button-drop-content__item">
                      <NavLink to={`${basePath}/${sections.config}/destination`}>
                        <p>{t('layouts:home-menu.destination')}</p>
                      </NavLink>
                    </div>
                    <div className="main-button-drop-content__item">
                      <NavLink to={`${basePath}/${sections.config}/fee`}>
                        <p>{t('layouts:home-menu.fee')}</p>
                      </NavLink>
                    </div>
                    <div className="main-button-drop-content__item">
                      <NavLink to={`${basePath}/${sections.config}/dive-levels`}>
                        <p>{t('layouts:home-menu.level')}</p>
                      </NavLink>
                    </div>
                    <div className="main-button-drop-content__item">
                      <NavLink to={`${basePath}/${sections.config}/taxes`}>
                        <p>{t('layouts:home-menu.tax')}</p>
                      </NavLink>
                    </div>
                    <div className="main-button-drop-content__item">
                      <NavLink to={`${basePath}/${sections.config}/measurement-units`}>
                        <p>{t('layouts:home-menu.units')}</p>
                      </NavLink>
                    </div>
                    <div className="main-button-drop-content__item">
                      <NavLink to={`${basePath}/${sections.config}/cancellation-policy`}>
                        <p>{t('layouts:home-menu.cancellation')}</p>
                      </NavLink>
                    </div>
                    <div className="main-button-drop-content__item">
                      <NavLink to={`${basePath}/${sections.config}/contact`}>
                        <p>{t('layouts:home-menu.contact')}</p>
                      </NavLink>
                    </div>
                    <div className="main-button-drop-content__item">
                      <NavLink to={`/legal`} target={'blank'}>
                        <p>Legal</p>
                      </NavLink>
                    </div>
                  </MainButtonDropProfile>
                </div>
              </Can>
            }


            <div className="menu-bottom__logout" onClick={() => this.setState({ modalLogOut: true })}>
              <MainButton
                type="simple-menu logout"
                iconImg={images.logOutWhiteSvg}
                text={t('layouts:home-menu.close-session')}
              />
            </div>
          </div>
        </HomeMenuContainer>
        {/* Modal LogOut */}
        <ModalLogOut
          showModal={modalLogOut}
          onAccept={() => this.setState({ modalLogOut: false }, () => onLogout())}
          onCancel={() => this.setState({ modalLogOut: false })}
          title={t('modalMessages:close-session.title')}
          subtitle={t('modalMessages:close-session.subtitle')}
        />
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}

export default withTranslation('layouts')(connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(HomeMenu)));
