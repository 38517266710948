import styled from 'styled-components';
import { images } from '../../../assets/images';
import { colors } from '../../../assets/colors';

export const ModalInviteCollaboratorContainer = styled.div`
  width: 350px;
  padding: 32px;
  padding-top: 0;

  .item-modal-message-drag {
    height: 160px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    background-image: url(${images.DashedBack});
    background-size: cover;
    margin-bottom: 7px;

    input {
      position: absolute;
      width: 100%;
      opacity: 0;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 2;
    }
    &__dashed {
      position: absolute;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        min-height: 100%;
        height: auto;
      }
    }
    &__icon {
      margin-right: 20px;
      width: 89px;
      pointer-events: none;
      z-index: 2;
    }

    &__text {
      pointer-events: none;
      text-align: center;
      max-width: 200px;
      color: ${colors['PR-001/100']};
      z-index: 2;
      span {
        font-weight: 500;
      }
    }
  }

  .item-modal-message-disclaimer {
    margin-bottom: 32px;
    color: ${colors['NE-002/100']};
  }
  .item-modal-message-bottom {
    display: flex;
    flex-direction: column;

    .item-modal-message-button {
      margin-bottom: 20px;
      width: 100%;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .item-modal-message-button {
    width: 140px;
  }

  .item-modal-message-container {
    &__title {
      margin-bottom: 14px;
      color: ${colors['SE-002/100']};
    }
  }

  .item-modal-message-counter {
    margin-left: 32px;
    display: flex;
    align-items: center;
    line-height: normal;
    font-size: 16px;
    text-align: right;
    color: #004f8b;

    &__icon {
      margin-top: 3px;
    }
  }

  .item-modal-message-select  {
    margin-bottom: 40px;
  }

  .item-modal-message-docs {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: repeat(5, 110px);
    gap: 40px;
  }

  .modal-certifier-form {
    margin-bottom: 40px;
    &__title {
      margin-bottom: 14px;
      color: ${colors['SE-002/100']};
      text-transform: uppercase;
    }
  }

  .form-error {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fd4a22;
  }

  @media (max-width: 550px) {
    background: #a7a7a7d6;
    .item-modal-message-container {
      width: 90%;
      margin: 0 auto;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    }

    .item-modal-message-counter {
      &__icon {
        min-width: 32px;
      }
    }

    .item-modal-message-drag {
      &__text {
        font-size: 14px;
        line-height: 1.3em;
      }
    }
  }
`;
