import React, { Component } from 'react';
import { ERROR_MESSAGES_ES } from '../../../constants/error-messages';
import { isValidEmail } from '../../../utils/validation';
import ModalInviteCollaboratorForm from './modal-invite-collaborator-form';
import services from '../../../services/services';
import { InviteOrganizationForm } from '../collaborators-types';

export interface InviteCollaboratorFormFunctionalProps {
  title: string;
  showModal: boolean;
  organizationType: string;
  onAccept: () => void;
  onCancel: () => void;
  className?: string;
  fillForm?: InviteOrganizationForm;
}

interface InviteCollaboratorFormFunctionalState {
  error: string;
  errorFields: { [key: string]: string };
  form: InviteOrganizationForm;
  [key: string]: any;
}

class InviteCollaboratorFormFunctional extends Component<
  InviteCollaboratorFormFunctionalProps,
  InviteCollaboratorFormFunctionalState
  > {
  state: InviteCollaboratorFormFunctionalState = {
    error: '',
    errorFields: {},
    userID: '',
    form: {
      name: '',
      email: '',
      language: 'es',
    },
  };

  componentDidMount() {
    const { fillForm } = this.props;
    if (fillForm) {
      this.setState({ form: fillForm });
    }
  }

  componentDidUpdate(prevProps: InviteCollaboratorFormFunctionalProps) {
    const { fillForm } = this.props;
    if (fillForm && fillForm !== prevProps.fillForm) {
      this.setState({ form: fillForm });
    }
  }

  cleanPreviousError(keys: string[], callback: Function) {
    const { errorFields } = this.state;
    const keysWithErrors = keys.filter(key => errorFields[key] || key === 'centres');

    if (keysWithErrors && keysWithErrors.length) {
      const aux = { ...errorFields };
      for (const field of Object.keys(aux)) {
        if (keysWithErrors.includes(field) || (keysWithErrors.includes('centres') && field.startsWith('centres.'))) {
          delete aux[field];
        }
      }
      this.setState({ errorFields: aux }, () => callback());
    } else {
      callback();
    }
  }

  handleDataChange(field: string, value: string) {
    this.cleanPreviousError([field], () => {
      // Value
      const form = { ...this.state.form };
      form[field] = value;
      this.setState({ form });
    });
  }

  validateForm() {
    const { form, errorFields } = this.state;

    let formError = false;
    const tempFieldErrors = { ...errorFields };

    Object.keys(form).map(key => {
      const value = form[key];
      if (value === '' || value === null) {
        tempFieldErrors[key] = 'required';
        formError = true;
      } else if (key === 'email' && !isValidEmail(value)) {
        tempFieldErrors[key] = 'invalid-email';
        formError = true;
      } else {
        delete tempFieldErrors[key];
      }
    });

    if (formError) {
      const errorMessage = ERROR_MESSAGES_ES['form-error'];
      return this.setState({ error: errorMessage, errorFields: tempFieldErrors });
    }
    return this.setState({ error: '', errorFields: {} });
  }

  async handleSubmitForm() {
    await this.validateForm();
    const { error } = this.state;
    if (error !== '') {
      return;
    }

    this.inviteCollaborator();
  }

  inviteCollaborator() {
    const { onAccept, organizationType } = this.props;
    const { form } = this.state;

    const endpoint = 'organizations/invite';
    const data = { ...form, type: organizationType };

    services.post({
      endpoint,
      data,
      loader: true,
      then: ({ data: res }: any) => {
        onAccept();
      },
      catch: (err: any) => {
        console.log(err);
        this.setState({ error: ERROR_MESSAGES_ES['server-error'] });
      },
    });
  }

  render() {
    const { error, errorFields, form } = this.state;
    return (
      <ModalInviteCollaboratorForm
        {...this.props}
        error={error}
        errorFields={errorFields}
        form={form}
        handleDataChange={(field, value) => this.handleDataChange(field, value)}
        submitForm={() => this.handleSubmitForm()}
      />
    );
  }
}

export default InviteCollaboratorFormFunctional;
