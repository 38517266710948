import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { BodyTextContainer } from '../text/body-text/body-text-styles';

export const SelectBoxContainer = styled.div`
  position: relative;

  &.show-list {
    z-index: 12;
    .input-box-main {
      .input-box-icon {
        transform: rotate(180deg);
      }
      .input-box-main-field {
        color: ${colors['NE-001/100']};
        border-bottom: 1px solid ${colors['NE-001/100']};
        p {
          color: ${colors['NE-001/100']};
        }
      }
    }
    .input-box-main-list {
      display: block;
      color: ${colors['NE-001/100']};
      p {
        opacity: 0.8;
        transition: all 0.3s ease-in-out;
        &:hover {
        opacity: 1;
        }
      }
    }
  }

  &.error {
    .input-box-main-field {
      /* color: ${colors['CO-003/100']}; */
      border-bottom: 1px solid ${colors['CO-003/100']};
    }
 
    .input-box-error {
      color: ${colors['CO-003/100']};
    }
  }

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: ${colors['NE-002/100']};
    }

    .input-box-main-field {
      background: #F2F2F3;
      border-bottom: 1px solid ${colors['NE-001/100']};
      input {
        &::placeholder {
          color: ${colors['NE-003/100']};
        }
      }

      &:before {
        background: ${colors['NE-002/100']};
      }
    }
  }

  &.complete {
    .input-box-main-field {
      color: ${colors['NE-001/100']};
      input {
        color: ${colors['NE-001/100']};
        &:focus {
          color: ${colors['NE-001/100']};
        }
      }
    }
  }

  .selector-separator {
    background: ${colors['NE-002/100']};
    height: 1px;
    margin: 0px 8px;
  }

  .input-box-multi-value {
    background: ${colors['SE-002/15']};
    width: fit-content;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-left: 3px;
    padding-right: 3px;
    transition: all 0.3s;
    position: relative;
    z-index: 20;
  }

  .input-box-multi-value:hover {
    background: ${colors['CO-004/75']};
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 6px;
  }

  .input-box-topbar-label {
    color: ${colors['NE-002/100']};
    text-transform: uppercase;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
  
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;
    width: 100%;
    padding: 13px 18px 9px;
    padding-right: 30px;
    transition: all 0.2s ease-in-out;
    margin-bottom: 4px;
    color: ${colors['NE-001/100']};
    border-bottom: 1px solid ${colors['NE-002/100']};
    z-index: 0;
    display: flex; flex-direction: column;

    input {
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      color: ${colors['NE-002/100']};
      &::placeholder  {
        color: ${colors['NE-002/100']};
      }
    }
    cursor: pointer;
    height: ${props => (props.multiple ? '48px' : '')};
    min-height: 48px;
    p {
      cursor: inherit;
    }

    &:hover {
      /* background: ${colors['NE-002/100']}; */
    }

    &:focus {
      background: ${colors['SE-002/0']};
      border-bottom: none;
      color: ${colors['NE-001/100']};
      border-bottom: 1px solid ${colors['NE-002/100']};
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 18px;
    z-index: 10;
    transition: all 0.3s ease-in-out ;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    right: 0;
  }

  .input-box-main-list {
    position: absolute;
    top: calc(100% + 10px);
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    background: white;
    width: 100%;
    display: none;
    color: ${colors['NE-001/100']};
    max-height: 250px;


    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;
      display: flex;
      &:hover {
        background: ${colors['SC-001-10']};
      }
    }
  }

  &.required {
    .input-box-topbar-label {
      position: relative;
      padding-left: 10px;
      &::before  {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 9px;
        background: ${colors['PR-001/100']};
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .input-box-error {
    color: ${colors['CO-003/100']};
  }
  &.languages {
    opacity: 0.5;
    &:hover {
    opacity: 1;
    }
    &.show-list  {
      opacity: 1;
      color: #1948a0;
    }
    .input-box-main-field,
    .input-box-main-list {
      li  {
        padding-left: 0;
      }
      padding-left: 0;
      ${BodyTextContainer} {
        font-size: 12px;
        line-height: 20px;
      }
    }
    .input-box-error {
      display: none;
    }
    .input-box-topbar {
      // display: none;
    }
    .input-box-main-field {
      border-bottom: none;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      p {
        color: ${colors['NE-001/100']};
      }
      &:focus {
        color: ${colors['NE-001/100']};
        border: 1px solid ${colors['NE-001/100']};
      }
    }
    .input-box-main-list {
      top: calc(-100% - 60px);
      padding: 8px 12px;
      overflow: initial;
      max-height: unset;
      background: white;
      box-shadow: none;
      li {
        opacity: 0.5;
      &:hover {
        background: #1948a0;
        opacity: 1;
      }
    }
    }
  }

  &.rounded {
    &.error {
      .input-box-main {
        .input-box-main-field {
          border: 1px solid ${colors['CO-003/100']};
        }
      }
    }
    &.disabled {
      .input-box-main {
        .input-box-main-field {
          border: 0px solid ${colors['NE-001/100']};;
        }
      }
    }

    &.show-list {
      .input-box-main {
        .input-box-main-field {
          color: ${colors['NE-001/100']};
          border: 1px solid ${colors['NE-001/100']};
          p {
            color: ${colors['NE-001/100']};
          }
        }
      }
    }
    .input-box-topbar { 
      // display: none ;
    }
      .input-box-error {
        min-height: 18px;
    }
    .input-box-main-list {
      top : 80%;
    }
    .input-box-main {
      .input-box-main-field {
        border: 1px solid ${colors['NE-002/100']};
        border-radius: 5px;
      }
    }
  }

  &.rounded-filter {
    &.error {
      .input-box-main {
        .input-box-main-field {
          border: 0px solid ${colors['CO-003/100']};
          border-bottom: 0px solid ${colors['CO-003/100']};
        }
      }
    }
    &.show-list {
      .input-box-main {
        .input-box-main-field {
          color: ${colors['NE-001/100']};
          border-bottom: 0px solid ${colors['NE-001/100']};
          border: 1px solid ${colors['PR-001-100']};
          p {
            color: ${colors['NE-001/100']};
          }
        }
      }
    }
    .input-box-topbar { 
      display: none ;
    }
    .input-box-main-list {
      top : 80%;
    }
      .input-box-error {
      min-height: 12px;
    }
    .input-box-main {
      .input-box-main-field {
        border: 0px solid ${colors['NE-001/100']};
        border-bottom: 0px solid ${colors['NE-001/100']};
        border-radius: 5px;
      }
    }
  }
  &.rounded-table {
    &.error {
      .input-box-main {
        .input-box-main-field {
          border: 0px solid ${colors['CO-003/100']};
          border-bottom: 0px solid ${colors['CO-003/100']};

        }
      }
    }
    
    &.show-list {
      .input-box-main {
        .input-box-main-field {
          color: ${colors['NE-001/100']};
          border: 0px solid ${colors['NE-001/100']};
          border-bottom: 0px solid ${colors['NE-001/100']};
          p {
            color: ${colors['NE-001/100']};
          }
        }
      }
    }
    .input-box-topbar { 
      display: none ;
    }
      .input-box-error {
      min-height: 12px;
      display: none;
    }
    .input-box-main-list {
      top : 80%;
    }
    .input-box-main {
      .input-box-main-field {
        border: 0px solid ${colors['NE-001/100']};
        border-bottom: 0px solid ${colors['NE-001/100']};
        border-radius: 5px;
      
      }
    }
  }

  &.rounded-title {
    .input-box-error {
      min-height: 12px;
    }
    .input-box-main {
    .input-box-main-field {
        border: 1px solid ${colors['NE-002/100']};
        border-radius: 5px;
      }
    }
    &.show-list {
      .input-box-main {
        .input-box-main-field {
          color: ${colors['NE-001/100']};
          border-bottom: 0px solid ${colors['NE-001/100']};
          border: 1px solid ${colors['PR-001-100']};
          p {
            color: ${colors['NE-001/100']};
          }
        }
      }
    }
  }

  .input-box-option-icon {
    width: 20px;
    margin-right: 10px;
  }
`;
