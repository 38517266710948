import * as React from 'react';
import { CreateActivitiesGeneralDataStyle } from './create-activities-general-data-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../main-layout-style';
import InputBox from '../../../../../components/input-box';
import { images } from '../../../../../assets/images';
import SelectBox from '../../../../../components/select-box';
import { IDropdown } from '../../../../../models/generic';
import { DiveDisciplines, DiveKinds, DiveCollaborationType } from '../../../../../constants/dive-dropdowns';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import InfoText from '../../../../../components/text/info-text/info-text';
import SingleButton from '../../../../../components/single-button/single-button';
import MainButton from '../../../../../components/main-button';
import { getCentres } from '../../../../../services/centre';
import { IDiveGeneralData } from '../../../../../models/activity-template';
import { createCategory, getCategories } from '../../../../../services/categories';

export interface ICreateActivitiesGeneralDataProps {
  generalData?: IDiveGeneralData;
  onNext: (generalData: any) => void;
  onPrev?: () => void;
  centreId: string;
}

export interface ICreateActivitiesGeneralDataState {
  generalData: IDiveGeneralData;
  hasCollaborator: boolean;
  centres: IDropdown[];
  categories: any[];
  errors: { [key: string]: string };
}

export default class CreateActivitiesGeneralData extends React.Component<
  ICreateActivitiesGeneralDataProps,
  ICreateActivitiesGeneralDataState
> {
  constructor(props: ICreateActivitiesGeneralDataProps) {
    super(props);
    this.state = {
      generalData: {
        name: '',
        discipline: '',
        kind: [],
        category: 1,
        minDivers: NaN,
        maxDivers: NaN,
        diveCount: NaN,
        needBoat: false,
        collaboration: {
          centre: NaN,
          type: '',
        },
      },
      hasCollaborator: false,
      centres: [],
      categories: [],
      errors: {
        name: '',
        discipline: '',
        kind: '',
        category: '',
        minDivers: '',
        maxDivers: '',
        diveCount: '',
        units: '',
        amount: '',
        centre: '',
        type: '',
      },
    };
  }

  componentDidMount() {
    const { generalData } = this.props;
    this.getCategories();

    if (generalData) {
      this.setState({
        generalData,
        hasCollaborator: !!generalData.collaboration && !!generalData.collaboration.centre,
      });
    }
  }

  async getCategories() {
    const categories = (await getCategories()).data.data.map((category: any) => {
      //if(category.id.toString() != '2'){
      return { "value": category.id.toString(), "label": category.name }
      // }
    });
    this.setState({ categories });

    return categories;
  }

  componentDidUpdate(prevProps: ICreateActivitiesGeneralDataProps) {
    const { generalData } = this.props;
    if (generalData && prevProps.generalData && prevProps.generalData !== generalData) {
      generalData.needBoat = prevProps.generalData.needBoat;
      this.setState({
        generalData,
        hasCollaborator: !!generalData.collaboration && !!generalData.collaboration.centre,
      });
    }
  }

  async getCentres() {
    const { centres } = this.state;
    const { centreId } = this.props;
    if (centres.length === 0) {
      const resp = await getCentres();
      const centresDropdown = resp.data.data
        .filter((centre: any) => centre.id !== +centreId)
        .map((centre: any) => {
          return { value: centre.id.toString(), label: centre.name };
        });
      this.setState({ centres: centresDropdown });
    }
  }

  validate(field: string, value: string | number | string[], required: boolean) {

    // console.log(field)
    // console.log(value)
    // console.log(typeof(value))
    if (required && ( value < 0  || value === '' || (typeof value !== 'number' && value && value.length === 0))) return 'required';
    //if (required && ((!value && value < 0)  || value === '' || (typeof value !== 'number' && value.length === 0))) return 'required';
    return '';
  }

  handleChange(field: string, value: string | number | string[], required: boolean, subfield?: string) {
    const { generalData, errors } = Object.assign(this.state);
    if (subfield) generalData[subfield][field] = value;
    else generalData[field] = value;
    errors[field] = this.validate(field, value, required);
    this.setState({ generalData, errors });
  }

  handleCheck(field: string, value: boolean) {
    const { generalData } = Object.assign(this.state);
    generalData[field] = value;
    this.setState({ generalData });
  }

  handleNext() {
    const { onNext } = this.props;
    const { generalData, errors, hasCollaborator } = Object.assign(this.state);
    const requiredFields = ['name', 'discipline', 'kind', 'diveCount', 'centre', 'type'];
    requiredFields.forEach(field => {
      errors[field] = this.validate(field, generalData[field], true);
    });
    if (hasCollaborator) {
      errors.centre = generalData.collaboration
        ? this.validate('centre', generalData.collaboration.centre, true)
        : 'required';
      errors.type = generalData.collaboration
        ? this.validate('type', generalData.collaboration.type, true)
        : 'required';
    } else {
      errors.centre = '';
      errors.type = '';
    }
    this.setState({ errors });
    if (Object.values(errors).every(err => err === '')) {
      onNext(generalData);
    }
  }

  render() {
    const { onPrev } = this.props;
    // console.log(this.props)
    const { generalData, errors, hasCollaborator, centres, categories } = this.state;
    return (
      <CreateActivitiesGeneralDataStyle>
        <FormGrid>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                errorCode={errors.name}
                placeholder="Nombre de la actividad"
                className="rounded-title"
                type="email"
                value={generalData.name}
                required={true}
                labelText="Nombre"
                onChange={value => this.handleChange('name', value, true)}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-2">
              <SelectBox
                errorCode={errors.discipline}
                className="rounded-title"
                labelText="Disciplina"
                placeholder="Disciplina"
                required={true}
                optionsText={DiveDisciplines}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={generalData.discipline}
                initialValue={generalData.discipline}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.handleChange('discipline', value, true)}
              />
            </FormGridItem>
            <FormGridItem type="col-2">
              <SelectBox
                errorCode={errors.kind}
                className="rounded-title"
                labelText="Tipo de inmersión (múltiple)"
                placeholder="Tipo de inmersión (múltiple)"
                required={true}
                multiple={true}
                initialValue={''}
                optionsText={DiveKinds}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={''}
                icon={images.arrowDown2BlueSvg}
                defaultMultipleValues={generalData.kind}
                initialMultipleValues={generalData.kind}
                onChange={() => { }}
                onChangeMultiple={values => this.handleChange('kind', values, true)}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                errorCode={errors.minDivers}
                placeholder="Número mínimo de buceadores"
                className="rounded-title"
                type="number"
                value={generalData.minDivers ? generalData.minDivers.toString() : '0'}
                required={true}
                labelText="Número mínimo de buceadores"
                onChange={value => this.handleChange('minDivers', parseInt(value, undefined), true)}
              />
            </FormGridItem>
           <FormGridItem type="col-0">
              <InputBox
                errorCode={errors.maxDivers}
                placeholder="Número máximo de buceadores"
                required={true}
                className="rounded-title"
                type="number"
                value={generalData.maxDivers ? generalData.maxDivers.toString() : ''}
                labelText="Número máximo de buceadores"
                onChange={value => this.handleChange('maxDivers', parseInt(value, undefined), false)}
              /></FormGridItem>
            
          
            <FormGridItem type="col-2">
              {/*<InputBox
                  errorCode={errors.category}
                  labelText="Categoría"
                  placeholder="Inmersión"
                  required={false}
                  disabled={true}
                  type="number"
                  value="Inmersión"
                />
              <SelectBox
                errorCode={errors.category}
                className="rounded-title"
                labelText="Categoría"
                placeholder="Categoría"
                required={true}
                optionsText={categories}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={(generalData.category && generalData.category.toString())}
                initialValue={(generalData.category && generalData.category.toString())}
                icon={images.arrowDown2BlueSvg}
                onChange={value => { this.handleChange('category', parseInt(value, undefined), true) }}
              />*/}
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
          <FormGridItem type="col-2">
              <InputBox
                errorCode={errors.diveCount}
                placeholder="Número de inmersiones"
                className="rounded-title"
                required={true}
                type="number"
                value={generalData.diveCount.toString()}
                labelText="Número de inmersiones"
                onChange={value => this.handleChange('diveCount', parseInt(value, undefined), true)}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <div className="create-activities-other">
                <div className="create-activities-other__text">
                  <BodyDestacadoText>¿Se necesita embarcación?</BodyDestacadoText>
                </div>
                <div className="create-activities-other__check">
                  <DicotomicCheckbox
                    className="rounded"
                    checked={generalData.needBoat}
                    onCheck={() => this.handleCheck('needBoat', true)}
                  >
                    <InfoText>Sí</InfoText>
                  </DicotomicCheckbox>
                </div>
                <div className="create-activities-other__check">
                  <DicotomicCheckbox
                    className="rounded"
                    checked={!generalData.needBoat}
                    onCheck={() => this.handleCheck('needBoat', false)}
                  >
                    <InfoText>No</InfoText>
                  </DicotomicCheckbox>
                </div>
              </div>
            </FormGridItem>
          </FormGridRow>

          {/*<FormGridRow>
            <FormGridItem type="col-0">
              <div className="create-activities-other">
                <div className="create-activities-other__text">
                  <BodyDestacadoText>¿Colaboras con otro centro para esta actividad?</BodyDestacadoText>
                </div>
                <div className="create-activities-other__check">
                  <DicotomicCheckbox
                    className="rounded"
                    checked={hasCollaborator}
                    onCheck={() => {
                      this.setState({ hasCollaborator: true }, () => this.getCentres());
                    }}
                  >
                    <InfoText>Si</InfoText>
                  </DicotomicCheckbox>
                </div>
                <div className="create-activities-other__check">
                  <DicotomicCheckbox
                    className="rounded"
                    checked={!hasCollaborator}
                    onCheck={() => this.setState({ hasCollaborator: false })}
                  >
                    <InfoText>No</InfoText>
                  </DicotomicCheckbox>
                </div>
              </div>
            </FormGridItem>
          </FormGridRow>*/}
          {hasCollaborator && (
            <FormGridRow>
              <FormGridItem type="col-2">
                <SelectBox
                  errorCode={errors.type}
                  className="rounded-title"
                  labelText="centro en el que se hará"
                  placeholder={'Centro en el que se hará'}
                  searchable={true}
                  required={true}
                  initialValue={
                    generalData.collaboration && generalData.collaboration.centre
                      ? generalData.collaboration.centre.toString()
                      : ''
                  }
                  optionsText={centres}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={
                    generalData.collaboration && generalData.collaboration.centre
                      ? generalData.collaboration.centre.toString()
                      : ''
                  }
                  icon={images.arrowDown2BlueSvg}
                  onChange={value => this.handleChange('centre', parseInt(value, undefined), true, 'collaboration')}
                />
              </FormGridItem>
              <FormGridItem type="col-2">
                <SelectBox
                  errorCode={errors.type}
                  className="rounded-title"
                  labelText="cómo colabora"
                  placeholder="¿Cómo colabora?"
                  required={true}
                  initialValue={generalData.collaboration ? generalData.collaboration.type : ''}
                  optionsText={DiveCollaborationType}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={generalData.collaboration ? generalData.collaboration.type : ''}
                  icon={images.arrowDown2BlueSvg}
                  onChange={value => this.handleChange('type', value, true, 'collaboration')}
                />
              </FormGridItem>
            </FormGridRow>
          )}
        </FormGrid>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            {!this.props.generalData ?
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={'Siguiente'} type="primary" onClick={() => this.handleNext()} />
            </div>
            :
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
            }

          </div>
        </div>
      </CreateActivitiesGeneralDataStyle>
    );
  }
}
