export const CURRENCIES = [
    {
        "value": "AED",
        "label": "AED"
    },
    {
        "value": "AFN",
        "label": "AFN"
    },
    {
        "value": "ALL",
        "label": "ALL"
    },
    {
        "value": "AMD",
        "label": "AMD"
    },
    {
        "value": "ANG",
        "label": "ANG"
    },
    {
        "value": "AOA",
        "label": "AOA"
    },
    {
        "value": "ARS",
        "label": "ARS"
    },
    {
        "value": "AUD",
        "label": "AUD"
    },
    {
        "value": "AWG",
        "label": "AWG"
    },
    {
        "value": "AZN",
        "label": "AZN"
    },
    {
        "value": "BAM",
        "label": "BAM"
    },
    {
        "value": "BBD",
        "label": "BBD"
    },
    {
        "value": "BDT",
        "label": "BDT"
    },
    {
        "value": "BGN",
        "label": "BGN"
    },
    {
        "value": "BHD",
        "label": "BHD"
    },
    {
        "value": "BIF",
        "label": "BIF"
    },
    {
        "value": "BMD",
        "label": "BMD"
    },
    {
        "value": "BND",
        "label": "BND"
    },
    {
        "value": "BOB",
        "label": "BOB"
    },
    {
        "value": "BOV",
        "label": "BOV"
    },
    {
        "value": "BRL",
        "label": "BRL"
    },
    {
        "value": "BSD",
        "label": "BSD"
    },
    {
        "value": "BTN",
        "label": "BTN"
    },
    {
        "value": "BWP",
        "label": "BWP"
    },
    {
        "value": "BYN",
        "label": "BYN"
    },
    {
        "value": "BZD",
        "label": "BZD"
    },
    {
        "value": "CAD",
        "label": "CAD"
    },
    {
        "value": "CDF",
        "label": "CDF"
    },
    {
        "value": "CHE",
        "label": "CHE"
    },
    {
        "value": "CHF",
        "label": "CHF"
    },
    {
        "value": "CHW",
        "label": "CHW"
    },
    {
        "value": "CLF",
        "label": "CLF"
    },
    {
        "value": "CLP",
        "label": "CLP"
    },
    {
        "value": "CNY",
        "label": "CNY"
    },
    {
        "value": "COP",
        "label": "COP"
    },
    {
        "value": "COU",
        "label": "COU"
    },
    {
        "value": "CRC",
        "label": "CRC"
    },
    {
        "value": "CUC",
        "label": "CUC"
    },
    {
        "value": "CUP",
        "label": "CUP"
    },
    {
        "value": "CVE",
        "label": "CVE"
    },
    {
        "value": "CZK",
        "label": "CZK"
    },
    {
        "value": "DJF",
        "label": "DJF"
    },
    {
        "value": "DKK",
        "label": "DKK"
    },
    {
        "value": "DOP",
        "label": "DOP"
    },
    {
        "value": "DZD",
        "label": "DZD"
    },
    {
        "value": "EGP",
        "label": "EGP"
    },
    {
        "value": "ERN",
        "label": "ERN"
    },
    {
        "value": "ETB",
        "label": "ETB"
    },
    {
        "value": "EUR",
        "label": "EUR"
    },
    {
        "value": "FJD",
        "label": "FJD"
    },
    {
        "value": "FKP",
        "label": "FKP"
    },
    {
        "value": "GBP",
        "label": "GBP"
    },
    {
        "value": "GEL",
        "label": "GEL"
    },
    {
        "value": "GHS",
        "label": "GHS"
    },
    {
        "value": "GIP",
        "label": "GIP"
    },
    {
        "value": "GMD",
        "label": "GMD"
    },
    {
        "value": "GNF",
        "label": "GNF"
    },
    {
        "value": "GTQ",
        "label": "GTQ"
    },
    {
        "value": "GYD",
        "label": "GYD"
    },
    {
        "value": "HKD",
        "label": "HKD"
    },
    {
        "value": "HNL",
        "label": "HNL"
    },
    {
        "value": "HRK",
        "label": "HRK"
    },
    {
        "value": "HTG",
        "label": "HTG"
    },
    {
        "value": "HUF",
        "label": "HUF"
    },
    {
        "value": "IDR",
        "label": "IDR"
    },
    {
        "value": "ILS",
        "label": "ILS"
    },
    {
        "value": "INR",
        "label": "INR"
    },
    {
        "value": "IQD",
        "label": "IQD"
    },
    {
        "value": "IRR",
        "label": "IRR"
    },
    {
        "value": "ISK",
        "label": "ISK"
    },
    {
        "value": "JMD",
        "label": "JMD"
    },
    {
        "value": "JOD",
        "label": "JOD"
    },
    {
        "value": "JPY",
        "label": "JPY"
    },
    {
        "value": "KES",
        "label": "KES"
    },
    {
        "value": "KGS",
        "label": "KGS"
    },
    {
        "value": "KHR",
        "label": "KHR"
    },
    {
        "value": "KMF",
        "label": "KMF"
    },
    {
        "value": "KPW",
        "label": "KPW"
    },
    {
        "value": "KRW",
        "label": "KRW"
    },
    {
        "value": "KWD",
        "label": "KWD"
    },
    {
        "value": "KYD",
        "label": "KYD"
    },
    {
        "value": "KZT",
        "label": "KZT"
    },
    {
        "value": "LAK",
        "label": "LAK"
    },
    {
        "value": "LBP",
        "label": "LBP"
    },
    {
        "value": "LKR",
        "label": "LKR"
    },
    {
        "value": "LRD",
        "label": "LRD"
    },
    {
        "value": "LSL",
        "label": "LSL"
    },
    {
        "value": "LYD",
        "label": "LYD"
    },
    {
        "value": "MAD",
        "label": "MAD"
    },
    {
        "value": "MDL",
        "label": "MDL"
    },
    {
        "value": "MGA",
        "label": "MGA"
    },
    {
        "value": "MKD",
        "label": "MKD"
    },
    {
        "value": "MMK",
        "label": "MMK"
    },
    {
        "value": "MNT",
        "label": "MNT"
    },
    {
        "value": "MOP",
        "label": "MOP"
    },
    {
        "value": "MRU",
        "label": "MRU"
    },
    {
        "value": "MUR",
        "label": "MUR"
    },
    {
        "value": "MVR",
        "label": "MVR"
    },
    {
        "value": "MWK",
        "label": "MWK"
    },
    {
        "value": "MXN",
        "label": "MXN"
    },
    {
        "value": "MXV",
        "label": "MXV"
    },
    {
        "value": "MYR",
        "label": "MYR"
    },
    {
        "value": "MZN",
        "label": "MZN"
    },
    {
        "value": "NAD",
        "label": "NAD"
    },
    {
        "value": "NGN",
        "label": "NGN"
    },
    {
        "value": "NIO",
        "label": "NIO"
    },
    {
        "value": "NOK",
        "label": "NOK"
    },
    {
        "value": "NPR",
        "label": "NPR"
    },
    {
        "value": "NZD",
        "label": "NZD"
    },
    {
        "value": "OMR",
        "label": "OMR"
    },
    {
        "value": "PAB",
        "label": "PAB"
    },
    {
        "value": "PEN",
        "label": "PEN"
    },
    {
        "value": "PGK",
        "label": "PGK"
    },
    {
        "value": "PHP",
        "label": "PHP"
    },
    {
        "value": "PKR",
        "label": "PKR"
    },
    {
        "value": "PLN",
        "label": "PLN"
    },
    {
        "value": "PYG",
        "label": "PYG"
    },
    {
        "value": "QAR",
        "label": "QAR"
    },
    {
        "value": "RON",
        "label": "RON"
    },
    {
        "value": "RSD",
        "label": "RSD"
    },
    {
        "value": "RUB",
        "label": "RUB"
    },
    {
        "value": "RWF",
        "label": "RWF"
    },
    {
        "value": "SAR",
        "label": "SAR"
    },
    {
        "value": "SBD",
        "label": "SBD"
    },
    {
        "value": "SCR",
        "label": "SCR"
    },
    {
        "value": "SDG",
        "label": "SDG"
    },
    {
        "value": "SEK",
        "label": "SEK"
    },
    {
        "value": "SGD",
        "label": "SGD"
    },
    {
        "value": "SHP",
        "label": "SHP"
    },
    {
        "value": "SLL",
        "label": "SLL"
    },
    {
        "value": "SOS",
        "label": "SOS"
    },
    {
        "value": "SRD",
        "label": "SRD"
    },
    {
        "value": "SSP",
        "label": "SSP"
    },
    {
        "value": "STN",
        "label": "STN"
    },
    {
        "value": "SVC",
        "label": "SVC"
    },
    {
        "value": "SYP",
        "label": "SYP"
    },
    {
        "value": "SZL",
        "label": "SZL"
    },
    {
        "value": "THB",
        "label": "THB"
    },
    {
        "value": "TJS",
        "label": "TJS"
    },
    {
        "value": "TMT",
        "label": "TMT"
    },
    {
        "value": "TND",
        "label": "TND"
    },
    {
        "value": "TOP",
        "label": "TOP"
    },
    {
        "value": "TRY",
        "label": "TRY"
    },
    {
        "value": "TTD",
        "label": "TTD"
    },
    {
        "value": "TWD",
        "label": "TWD"
    },
    {
        "value": "TZS",
        "label": "TZS"
    },
    {
        "value": "UAH",
        "label": "UAH"
    },
    {
        "value": "UGX",
        "label": "UGX"
    },
    {
        "value": "USD",
        "label": "USD"
    },
    {
        "value": "USN",
        "label": "USN"
    },
    {
        "value": "UYI",
        "label": "UYI"
    },
    {
        "value": "UYU",
        "label": "UYU"
    },
    {
        "value": "UYW",
        "label": "UYW"
    },
    {
        "value": "UZS",
        "label": "UZS"
    },
    {
        "value": "VES",
        "label": "VES"
    },
    {
        "value": "VND",
        "label": "VND"
    },
    {
        "value": "VUV",
        "label": "VUV"
    },
    {
        "value": "WST",
        "label": "WST"
    },
    {
        "value": "XAF",
        "label": "XAF"
    },
    {
        "value": "XAG",
        "label": "XAG"
    },
    {
        "value": "XAU",
        "label": "XAU"
    },
    {
        "value": "XBA",
        "label": "XBA"
    },
    {
        "value": "XBB",
        "label": "XBB"
    },
    {
        "value": "XBC",
        "label": "XBC"
    },
    {
        "value": "XBD",
        "label": "XBD"
    },
    {
        "value": "XCD",
        "label": "XCD"
    },
    {
        "value": "XDR",
        "label": "XDR"
    },
    {
        "value": "XOF",
        "label": "XOF"
    },
    {
        "value": "XPD",
        "label": "XPD"
    },
    {
        "value": "XPF",
        "label": "XPF"
    },
    {
        "value": "XPT",
        "label": "XPT"
    },
    {
        "value": "XSU",
        "label": "XSU"
    },
    {
        "value": "XTS",
        "label": "XTS"
    },
    {
        "value": "XUA",
        "label": "XUA"
    },
    {
        "value": "XXX",
        "label": "XXX"
    },
    {
        "value": "YER",
        "label": "YER"
    },
    {
        "value": "ZAR",
        "label": "ZAR"
    },
    {
        "value": "ZMW",
        "label": "ZMW"
    },
    {
        "value": "ZWL",
        "label": "ZWL"
    }
]

export const CURRENCIES_LIST = [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BOV",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYN",
    "BZD",
    "CAD",
    "CDF",
    "CHE",
    "CHF",
    "CHW",
    "CLF",
    "CLP",
    "CNY",
    "COP",
    "COU",
    "CRC",
    "CUC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRU",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MXV",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLL",
    "SOS",
    "SRD",
    "SSP",
    "STN",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "USN",
    "UYI",
    "UYU",
    "UYW",
    "UZS",
    "VES",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XAG",
    "XAU",
    "XBA",
    "XBB",
    "XBC",
    "XBD",
    "XCD",
    "XDR",
    "XOF",
    "XPD",
    "XPF",
    "XPT",
    "XSU",
    "XTS",
    "XUA",
    "XXX",
    "YER",
    "ZAR",
    "ZMW",
    "ZWL"
  ]
  
