import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const InputCounterContainer = styled.div`
  position: relative;
  width: 100%;

  .counter-field-topbar {
    margin-bottom: 7px;
    color: #004f8b;
    font-size: 16px;
    font-weight: 500;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .counter-field-main {
    display: flex;
    width: 100%;
    height: 48px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid ${colors['SE-002/100']};
  }

  .counter-button {
    height: 48px;
    min-width: 48px;
    width: 48px;
    background: ${colors['SE-002/100']};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    border-radius: 3px;
    overflow: hidden;

    transition: 0.3s all ease-in-out;
    img {
      width: 24px;
      height: 24px;
    }
  }

  .counter-input-field {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors['SE-002/100']};
    input {
      height: 100%;
      text-align: center;
      color: ${colors['SE-002/100']};
      padding: 0 10px;
      font-size: 14px;
      line-height: 22px;

      &::placeholder {
        color: #cccccc;
      }
    }
  }

  .input-counter-error {
    color: ${colors['CO-003/100']};
  }
`;
