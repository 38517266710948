import * as React from 'react';
import { MyCenterProfileServicesStyle } from './my-center-proofile-services-style';
import HeaderMain from '../../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../../main-layout-style';
import { images } from '../../../../assets/images';
import { RouteComponentProps, match } from 'react-router-dom';
import MainTableLayout from '../../../../components/main-table/main-table';
import Pagination from '../../../../components/pagination';
import ModalAddService from '../../../../components/modals/moda-add-service/modal-add-service';
import MyCenterProfileNav from '../my-center-profile-nav/my-center-profile-nav';
import { ICentreService } from '../../../../models/centre-forms';
import { deleteService, getServices } from '../../../../services/centre';
import { copyOf } from '../../../../utils/other';
import { DURATION_UNITS_KEY_VALUE, SINGLE_DURATION_UNITS_KEY_VALUE, PLURAL_DURATION_UNITS_KEY_VALUE } from '../../../../constants/duration';
import ModalDelete from '../../../../components/modals/modal-delete/modal-delete';
import services from '../../../../services/services';
import { connect } from 'react-redux';
import IStoreState from '../../../../redux/store/IStoreState';
import { CENTRE_SERVICES_KEY_VALUE } from '../../../../constants/centre-services';
import { havePermissionsDeals } from '../../../../services/staff-centre';

export interface IMyCenterProfileServicesProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  staffPermissions?: any;
}

export interface IMyCenterProfileServicesState {
  modal: string;
  services: ICentreService[];
  selectedService?: ICentreService;
  skipPage: number;
  total: number;
  totalPages: number;
  page: number;
}

const SERVICES_PER_PAGE = 10;

class MyCenterProfileServices extends React.Component<IMyCenterProfileServicesProps, IMyCenterProfileServicesState> {
  constructor(props: IMyCenterProfileServicesProps) {
    super(props);

    this.state = {
      modal: '',
      services: [],
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
    };
  }

  componentDidMount() {
    this.getCentreServices();
  }

  async getCentreServices() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { skipPage } = this.state;
    const params = {
      filter: {
        skip: skipPage,
        limit: SERVICES_PER_PAGE,
      },
    };
    const resp = (await getServices(id, params)).data;
    const services = resp.data;
    const totalPages: number = Math.ceil(resp.count / SERVICES_PER_PAGE);
    this.setState({ services, totalPages, total: resp.count });
  }

  async handleDeleteService() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { selectedService } = this.state;
    if (selectedService && selectedService.id !== undefined) {
      await deleteService(id, selectedService.id.toString());
      services.pushNotification({
        title: '¡Eliminado!',
        text: 'Se ha eliminado el servicio correctamente',
        type: 'red',
      });
      this.setState({ modal: '', selectedService: undefined }, () => {
        this.getCentreServices();
      });
    }
  }

  getTableRows() {
    const { staffPermissions } = this.props;
    const { services } = this.state;
    const tableRows: any[] = [];
    services.forEach(service => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.name.value =
        service.name && CENTRE_SERVICES_KEY_VALUE[service.name]
          ? CENTRE_SERVICES_KEY_VALUE[service.name]
          : service.name;
      tableRow.duration.value =
        service.duration.units === 'activity'
          ? SINGLE_DURATION_UNITS_KEY_VALUE[service.duration.units]
          : service.duration.units === 'noduration' ? 
          SINGLE_DURATION_UNITS_KEY_VALUE[service.duration.units] : 
          service.duration.amount > 1 ? `${service.duration.amount} ${PLURAL_DURATION_UNITS_KEY_VALUE[service.duration.units]}`: 
          `${service.duration.amount} ${SINGLE_DURATION_UNITS_KEY_VALUE[service.duration.units]}`
          ;
      tableRow.price.value = `${service.price / 100} €`;
      if (!staffPermissions || staffPermissions.deals) {
        tableRow.edit.onClick = () => {
          if (service.id !== undefined) this.setState({ selectedService: service, modal: 'add-service' });
        };
        tableRow.delete.onClick = () => {
          if (service.id !== undefined) this.setState({ selectedService: service, modal: 'delete-service' });
        };
      } else {
        tableRow.edit = tableRow.delete = {
          key: '',
          value: ' ',
        };
      }

      if (!havePermissionsDeals()) {
        tableRow.edit = tableRow.delete = {
          key: '',
          value: ' ',
        }; 
      }

      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = SERVICES_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getCentreServices();
      });
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      staffPermissions,
    } = this.props;
    const { modal, selectedService, totalPages, page } = this.state;
    const rows = this.getTableRows();
    return (
      <>
        <HeaderMain
          title={'Perfil del centro'}
          mainButton={
            !staffPermissions || staffPermissions.deals
              ? {
                img: images.createWhiteSvg,
                text: 'Agregar servicio',
                onClick: () => this.setState({ modal: 'add-service', selectedService: undefined }),
                disabled: !havePermissionsDeals(),
              }
              : undefined
          }
        />
        <MainLayoutSection>
          <MyCenterProfileNav centreId={id} />

          <MyCenterProfileServicesStyle>
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout columns={this.tableColumns} rows={rows} rowKeys={this.rowKeys} onOrder={() => { }} />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
                </div>
              )}
            </MainLayoutTableContainer>
          </MyCenterProfileServicesStyle>
        </MainLayoutSection>
        {modal === 'add-service' && (
          <ModalAddService
            centreId={id}
            onSuccess={() => {
              if (selectedService) services.showModalSuccess('¡Editado!', 'El servicio se ha editado con éxito');
              else services.showModalSuccess('¡Añadido!', 'El servicio se ha añadido con éxito');
              this.setState({ modal: '', selectedService: undefined });
              this.getCentreServices();
            }}
            service={selectedService}
            showModal={modal === 'add-service'}
            onClose={() => this.setState({ modal: '' })}
          />
        )}
        {modal === 'delete-service' && (
          <ModalDelete
            title={'Eliminar servicio'}
            subtitle={'¿Deseas eliminar el servicio?'}
            showModal={modal === 'delete-service'}
            onRemove={() => this.handleDeleteService()}
            onCancel={() => this.setState({ modal: '', selectedService: undefined })}
          />
        )}
      </>
    );
  }

  tableColumns = [
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Duración',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Precio',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    name: {
      key: 'highlighted-body',
      value: '',
    },
    duration: {
      key: 'highlighted-body',
      value: '',
    },
    price: {
      key: '',
      value: '',
    },
    edit: {
      key: 'icon-button',
      value: images.edit3Svg,
      onClick: () => { },
    },
    delete: {
      key: 'icon-button',
      value: images.deleteSvg,
      onClick: () => { },
    },
  };

  rowKeys = ['name', 'duration', 'price', 'edit', 'delete'];
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(MyCenterProfileServices);
