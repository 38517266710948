import React from 'react';
import { ChatMessageContainer } from './chat-message-style';
import BodyText from '../text/body-text/body-text';
interface ChatMessageProps {
  messages: any;
  className?: string;
  picture?: string;
}
interface ChatMessageState {}
export default class ChatMessage extends React.Component<ChatMessageProps, ChatMessageState> {
  render() {
    const { messages, className, picture } = this.props;
    return (
      <ChatMessageContainer className={className}>
        <div className="chat-message-avatar">
          {picture ? <img src={picture} alt="" /> : <div style={{ backgroundColor: '#001930', height: '100%' }} />}
        </div>
        <div className="chat-message-text">
          {messages.map((item: any, key: any) => (
            <div key={key} className="chat-message-text__item">
              <BodyText>{item}</BodyText>
            </div>
          ))}
        </div>
      </ChatMessageContainer>
    );
  }
}
