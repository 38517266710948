import React from 'react';
import ModalContainer from '../../../../../components/modals/modal-container';
import { ModalCreateEquipmentCenterStyle } from './modal-create-equipment-center-style';
import { FormGrid, FormGridRow, FormGridItem, FormSpacer } from '../../../../main-layout-style';
import SelectBox from '../../../../../components/select-box';
import InputBox from '../../../../../components/input-box';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';
import { images } from '../../../../../assets/images';
import InputUploadFiles from '../../../../../components/input-upload-file/input-upload-file';
import InputCounter from '../../../../../components/input-counter/input-counter';
import MainButton from '../../../../../components/main-button';
import CalendarBox from '../../../../../components/calendar-box';
import { IStock } from '../../../../../models/centre-forms';
import moment from 'moment';
import {
  STOCK_TYPES_DROPDOWN,
  STOCK_STATUS_DROPDOWN,
  STOCK_TYPES_KEY_VALUES,
} from '../../../../../constants/equipment';
import { patchStock, postStock, setStockImage } from '../../../../../services/centre';
import services from '../../../../../services/services';
import { copyOf } from '../../../../../utils/other';
import * as dateFns from 'date-fns';

interface ModalCreateEquipmentCenterProps {
  centreId: string;
  showModal: boolean;
  onClose: () => void;
  onSuccess: () => void;
  stock?: IStock;
}

interface ModalCreateEquipmentCenterState {
  stock?: IStock;
  otherType: string;
  errors: { [key: string]: string };
}

export default class ModalCreateEquipmentCenter extends React.Component<
  ModalCreateEquipmentCenterProps,
  ModalCreateEquipmentCenterState
  > {
  constructor(props: ModalCreateEquipmentCenterProps) {
    super(props);
    this.state = {
      stock: {
        name: {
          en: '',
          es: '',
          de: '',
          fr: '',
          it: '',
          pt: '',
        },
        units: 0,
        type: '',
        size: '',
        location: '',
        status: '',
        purchasedAt: '',
        lastRevision: '',
        expirationDate: '',
        expirationNotice: '',
      },
      otherType: '',
      errors: {},
    };
  }

  componentDidMount() {
    const { stock } = Object.assign(this.props);
    if (stock) {
      if (STOCK_TYPES_KEY_VALUES[stock.type] === undefined) {
        const stockType = stock.type;
        stock.type = 'other';
        this.setState({ stock, otherType: stockType });
      } else {
        this.setState({ stock });
      }
    }
  }

  componentDidUpdate(prevProps: ModalCreateEquipmentCenterProps) {
    const { stock } = this.props;
    if (stock && prevProps.stock !== stock) {
      if (STOCK_TYPES_KEY_VALUES[stock.type] === undefined) {
        const stockType = stock.type;
        stock.type = 'other';
        this.setState({ stock, otherType: stockType });
      } else {
        this.setState({ stock });
      }
    }
  }

  validate(field: string, value: string | number, required: boolean) {
    if (required && (!value || value === '')) {
      return 'required';
    }
    return '';
  }

  handleChange(field: string, value: string | number, required: boolean) {
    const { stock, errors } = Object.assign(this.state);
    const error = this.validate(field, value, required);
    errors[field] = error;
    if (field === 'name') {
      // Preparado para multilang
      stock[field] = {
        en: value,
        es: value,
        de: value,
        fr: value,
        it: value,
        pt: value,
      };
    } else {
      stock[field] = value;
    }
    this.setState({ stock, errors });
  }

  handleChangePicture(ev: any) {
    const { stock } = Object.assign(this.state);
    stock.file = ev[0] ? ev[0] : undefined;
    this.setState({ stock });
  }

  async handleSubmit() {
    const { centreId, onSuccess } = this.props;
    const { otherType } = Object.assign(this.state);
    const stock = copyOf(this.state.stock);
    const errors: any = {};
    let isCorrect = true;
    Object.entries(stock).forEach((entry: any) => {
      const field = entry[0];
      if (field !== 'file') {
        const value: string | number = typeof entry[1] === 'object' && entry[1] ? entry[1].en : entry[1] || '';
        const required = field === 'name' || field === 'type';
        let error = '';
        error = this.validate(field, value, required);

        if (
          (field === 'purchasedAt' || field === 'lastRevision') &&
          stock[field] &&
          dateFns.isFuture(new Date(stock[field]))
        ) {
          error = 'invalid-date-past';
        }
        if (field === 'expirationDate' && stock[field] && !dateFns.isFuture(new Date(stock[field]))) {
          error = 'invalid-date-future';
        }

        isCorrect = isCorrect && error === '';
        errors[field] = error;
        // console.log(error);
        // console.log(errors);
      }
    });
    if (stock.type === 'other' && otherType === '') {
      errors.otherType = 'required';
      isCorrect = false;
    }
    this.setState({ errors });
    let stockId;
    if (isCorrect) {
      if (stock.type === 'other') stock.type = otherType;
      Object.keys(stock).forEach(key => {
        if (stock[key] === '' || stock[key] === 0) delete stock[key];
      });
      if (this.props.stock && this.props.stock.id !== undefined) {
        stockId = this.props.stock.id;
        await patchStock(centreId, stockId, stock);
        services.showModalSuccess('¡Editado!', 'El stock se ha editado correctamente.');
      } else {
        const resp: any = await postStock(centreId, stock);
        stockId = resp.data.id;
        services.showModalSuccess('¡Creado!', 'El stock se ha creado correctamente.');
      }
      if (this.state.stock && this.state.stock.file) {
        const formData = new FormData();
        formData.set('file', this.state.stock.file.data);
        await setStockImage(centreId, stockId, formData);
      }
      onSuccess();
    }
  }

  render() {
    const { onClose, showModal } = this.props;
    const { stock, errors, otherType } = this.state;
    return (
      <ModalContainer
        title={`${this.props.stock ? 'Editar' : 'Crear'} equipo del centro`}
        className=""
        modalClose={() => onClose()}
        active={showModal}
      >
        <ModalCreateEquipmentCenterStyle>
          <div className="create-user-form">
            <div className="create-user-form__title">
              <BodyDestacadoText>Datos Generales</BodyDestacadoText>
            </div>
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Nombre item"
                      className="rounded-title"
                      required={true}
                      errorCode={errors.name}
                      type="text"
                      value={stock && stock.name.en ? stock.name.en : ''}
                      labelText="Nombre item"
                      onChange={value => this.handleChange('name', value, true)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputCounter
                      placeholder="Número de items"
                      value={stock && stock.units ? stock.units.toString() : ''}
                      errorCode={errors.units}
                      onChange={val => {
                        this.handleChange('units', parseInt(val, undefined), false);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormSpacer />
                <InputUploadFiles
                  multiple={false}
                  required={true}
                  onChange={ev => this.handleChangePicture(ev)}
                  value={stock ? stock.file : ''}
                />

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText="Tipo de equipo"
                      placeholder="Tipo de equipo"
                      required={true}
                      errorCode={errors.type}
                      className="rounded-title"
                      optionsText={STOCK_TYPES_DROPDOWN}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={stock ? stock.type : ''}
                      initialValue={stock ? stock.type : ''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.handleChange('type', value, true)}
                    />
                  </FormGridItem>
                </FormGridRow>
                {stock && stock.type === 'other' && (
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        placeholder="Indique otro tipo de equipo"
                        className="rounded-title"
                        required={true}
                        errorCode={errors.otherType}
                        type="text"
                        value={otherType}
                        labelText="Indique otro tipo de equipo"
                        onChange={value => this.setState({ otherType: value })}
                      />
                    </FormGridItem>
                  </FormGridRow>
                )}
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Talla"
                      className="rounded-title"
                      required={true}
                      errorCode={errors.size}
                      type="text"
                      value={stock && stock.size ? stock.size : ''}
                      labelText="Talla"
                      onChange={value => this.handleChange('size', value, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Ubicación"
                      className="rounded-title"
                      required={true}
                      errorCode={errors.location}
                      type="text"
                      value={stock && stock.location ? stock.location : ''}
                      labelText="Ubicación"
                      onChange={value => this.handleChange('location', value, false)}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText="Estado"
                      placeholder="Estado"
                      required={true}
                      className="rounded-title"
                      optionsText={STOCK_STATUS_DROPDOWN}
                      errorCode={errors.status}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={stock ? stock.status : ''}
                      initialValue={stock ? stock.status : ''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.handleChange('status', value, false)}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <CalendarBox
                      placeholder="Fecha de compra"
                      className="rounded-title"
                      required={true}
                      errorCode={errors.purchasedAt}
                      labelText="Fecha de compra"
                      initialValue={stock ? moment(stock.purchasedAt).format('DD/MM/YYYY') : ''}
                      onChange={value =>
                        this.handleChange('purchasedAt', moment(value, 'DD/MM/YYYY').toISOString(), false)
                      }
                    />
                    {/* {console.log(errors.purchasedAt)} */}
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <CalendarBox
                      placeholder="Última revisión"
                      className="rounded-title"
                      required={true}
                      labelText="Última revisión"
                      errorCode={errors.lastRevision}
                      initialValue={stock ? moment(stock.lastRevision).format('DD/MM/YYYY') : ''}
                      onChange={value =>
                        this.handleChange('lastRevision', moment(value, 'DD/MM/YYYY').toISOString(), false)
                      }
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
            <div className="create-user-form__title">
              <BodyDestacadoText>Caducidad</BodyDestacadoText>
            </div>
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <CalendarBox
                      placeholder="Fecha de caducidad"
                      className="rounded-title"
                      required={true}
                      labelText="Fecha de caducidad"
                      errorCode={errors.expirationDate}
                      initialValue={stock ? moment(stock.expirationDate).format('DD/MM/YYYY') : ''}
                      onChange={value =>
                        this.handleChange('expirationDate', moment(value, 'DD/MM/YYYY').toISOString(), false)
                      }
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Aviso de caducidad"
                      className="rounded-title"
                      required={true}
                      type="text"
                      errorCode={errors.expirationNotice}
                      value={stock && stock.expirationNotice ? stock.expirationNotice : ''}
                      labelText="Usuario"
                      onChange={value => this.handleChange('expirationNotice', value, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>
          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton
                type="primary"
                onClick={() => this.handleSubmit()}
                text={this.props.stock ? 'Editar' : 'Guardar'}
              />
            </div>
          </div>
        </ModalCreateEquipmentCenterStyle>
      </ModalContainer>
    );
  }
}
