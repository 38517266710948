import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const PlanningDateStyle = styled.div`
  .planing-date-disclaimer {
    color: ${colors['NE-002/100']};
    margin-bottom: 26px;
  }

  .planing-colorend {
    color: ${colors['NE-002/100']};
  }

  .planing-date-subtitle {
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  .planing-date-title {
    color: ${colors['SE-002/100']};
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  .planing-date-dicotomic {
    width: 213px;
    margin-bottom: 28px;
  }
  .planing-date-start {
    margin-bottom: 28px;
  }
  .planing-date-start-form {
    width: 100%;
  }
  .planing-date-horary {
    margin-bottom: 28px;
  }
  .planing-date-horary-form {
    display: flex;
    &__item {
      flex: auto;
      margin-right: 20px;
    }
    &__add {
      width: 120px;
    }
    &__times {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      padding: 20px;

      .delate-img {
        cursor: pointer;
        width: 20px;
        margin-right: 10px;
        margin-top: 5px;
        cursor: pointer;
      }
    }
  }

  .planing-date-periodicity {
    margin-bottom: 28px;
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .planing-date-final  {
    width: 338px;
  }

  .input-box-main-field {
    &::before {
      input {
        border: none;
        padding: 0px;
      }
    }
  }

  .mt-6 {
    margin-top: 6px;
  }
`;
