export const DIVER_DOCUMENT_TYPES = [
  /*{
    label: 'Licencia de buceo',
    value: 'diving license',
  },*/
  {
    label: 'Seguro de buceo',
    value: 'diving insurance',
  },
  {
    label: 'Certificado medico',
    value: 'medical certificate',
  },
  {
    label: 'Titulaciones profesionales',
    value: 'profesional titulation',
  },
  {
    label: 'Declaración responsable',
    value: 'respdelaration',
  },
  {
    label: 'Declaración responsable COVID',
    value: 'respdelarationCOVID',
  },
  {
    label: 'Otro',
    value: 'other',
  }
];




export const DIVER_DOCUMENT_KEY_VALUES: { [s: string]: string } = {
  //'diving license': 'Licencia de buceo',
  'diving insurance': 'Seguro de buceo',
  'medical certificate': 'Certificado medico',
  'profesional titulation': 'Titulaciones profesionales',
  'respdelaration': 'Declaración responsable',
  'respdelarationCOVID': 'Declaración responsable COVID',
  'other': 'Otro'
};
