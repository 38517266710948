import * as React from 'react';
import { PlanningHistoryStyle } from './planning-history-style';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import MainTable from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import { getCentreActivities, getCentreDiveSites } from '../../../services/centre';
import { RouteComponentProps, match } from 'react-router';
import moment from 'moment';
import { copyOf, calculateRating, mergeObject } from '../../../utils/other';
import { PLANNING_HISTORY_PER_PAGE } from '../../../constants/data';
import { getActivityHistoryCsv } from '../../../services/activities';
import { ContentBlock } from '../../../components/content-block/content-block';
import SelectBox from '../../../components/select-box';
import { DiveTypes } from '../../../constants/dive-dropdowns';
import MainButton from '../../../components/main-button';
import { IDropdown } from '../../../models/generic';

const STATS_DROPDOWN = [
  { value: '*', label: 'Todos' },
  { value: 'done', label: 'Realizada' },
  { value: 'finished', label: 'Finalizada' },
];

export interface IPlanningHistoryProps extends RouteComponentProps {
  match: match<{
    id: string;
  }>;
  t: any;
}

export interface IPlanningHistoryState {
  section: string;
  renderModal: string;
  total: number;
  totalPages: number;
  skipPage: number;
  activities: any[];
  activeFilter: boolean;
  filterStatus: string;
  filterType: string;
  filterDiveSite: string;
  diveSites: IDropdown[];
  page: number;
}

export default class PlanningHistory extends React.Component<IPlanningHistoryProps, IPlanningHistoryState> {
  constructor(props: IPlanningHistoryProps) {
    super(props);

    this.state = {
      total: 0,
      totalPages: 0,
      skipPage: 0,
      section: 'general',
      renderModal: '',
      activities: [],
      activeFilter: false,
      filterStatus: '',
      filterType: '',
      filterDiveSite: '',
      diveSites: [],
      page: 1,
    };
  }

  componentDidMount() {
    this.getPastActivities();
    this.getSites();
  }

  async getPastActivities() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { skipPage, activeFilter, filterStatus, filterType, filterDiveSite } = this.state;
    const now = moment();
    const params: any = {
      filter: {
        where: {
          date: {
            method: '<',
            value: now.toISOString(),
            type: 'timestamp',
          },
          confirmed: true,
        },
        skip: skipPage,
        limit: PLANNING_HISTORY_PER_PAGE,
        relations: ['staff_activities', 'staff_activities.staff', 'staff.user', 'sites'],
        orderBy: [{ key: 'date', direction: 'DESC' }],
      },
    };
    if (activeFilter && filterStatus && filterStatus !== '' && filterStatus !== '*') {
      params.filter.where.finished = filterStatus === 'finished';
    }
    if (activeFilter && filterType && filterType !== '' && filterType !== '*') {
      params.filter.where.courseInfo = { value: 'null', method: filterType === 'dive' ? 'is' : 'is not' };
    }
    if (activeFilter && filterDiveSite && filterDiveSite !== '' && filterDiveSite !== '*') {
      params.filter.where.id = { value: parseInt(filterDiveSite, undefined), collection: 'sites' };
    }
    const params2 = copyOf(params);
    params2.filter.relations = ['template', 'template.ratings', 'sites'];
    const resp = await getCentreActivities(id, params);
    const resp2 = await getCentreActivities(id, params2);
    const activities = mergeObject(resp.data, resp2.data).data;
    const totalPages: number = Math.ceil(resp.data.count / PLANNING_HISTORY_PER_PAGE);
    this.setState({ activities, totalPages, total: resp.data.count });
  }

  async getSites() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const resp = await getCentreDiveSites(id);
    const diveSites = resp.data.data.map((diveSite: any) => ({
      value: diveSite.id.toString(),
      label: diveSite.name,
    }));
    this.setState({ diveSites });
  }

  getTableRows() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { activities } = this.state;
    const tableRows: any[] = [];
    activities.forEach((activity: any) => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.date.value = moment(activity.date).format('DD/MM/YYYY');
      tableRow.name.value = activity.name;
      tableRow.staff.value =
        activity.staff_activities.length > 0
          ? `${activity.staff_activities[0].staff.user.name} ${activity.staff_activities[0].staff.user.surname}`
          : '-';
      if (activity.staff_activities.length > 1) {
        tableRow.staffCount.value = {
          title: 'Staff',
          items: activity.staff_activities.map((staff: any) => `${staff.staff.user.name} ${staff.staff.user.surname}`),
        };
      }
      tableRow.diveSite.value = activity.sites.length > 0 ? activity.sites[0].name : '';
      if (activity.sites.length > 1) {
        tableRow.diveSiteCount.value = {
          title: 'Puntos de inmersion',
          items: activity.sites.map((site: any) => site.name),
        };
      }
      tableRow.score = activity.template ? calculateRating(activity.template.ratings) : '-';
      tableRow.view.link = `/centres/${id}/planning/calendar/${activity.id}`;
      tableRow.planned.value = images.checkApprovedBlueSvg;
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  async handleDownloadCsv() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const resp: any = await getActivityHistoryCsv(id);
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `activity_history_${moment().format('DD/MM/YYYY')}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = PLANNING_HISTORY_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getPastActivities();
      });
    }
  }

  clearFilter() {
    this.setState({ filterType: '', filterDiveSite: '', filterStatus: '' }, this.getPastActivities);
  }

  render() {
    const {
      section,
      renderModal,
      totalPages,
      filterType,
      filterDiveSite,
      filterStatus,
      diveSites,
      activeFilter,
      page,
    } = this.state;
    const  { t } = this.props;
    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Historial'}
            mainButton={{
              img: images.uploadWhiteSvg,
              text: 'Descargar',
              onClick: () => this.handleDownloadCsv(),
            }}
          />
          <MainLayoutSection>
            <PlanningHistoryStyle>
              <div className="planning-reservations-head">
                <div className="planning-reservations-head__filter">
                  <MainButton
                    onClick={() =>
                      this.setState({ activeFilter: !activeFilter }, () => {
                        // if (activeFilter) this.clearFilter();
                      })
                    }
                    type={activeFilter ? 'secondary-active' : 'secondary'}
                    text="Filtrar"
                    iconImg={activeFilter ? images.filtersLightBlueSvg : images.filtersSvg}
                  />
                </div>
              </div>

              <div className={`planning-reservations-filters${activeFilter ? ' active' : ''}`}>
                <div className="planning-reservations-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Seleccione tipo"
                    optionsText={DiveTypes}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={filterType}
                    initialValue={filterType}
                    icon={images.arrowDown2BlueSvg}
                    onChange={(value: string) => {
                      this.setState({ filterType: value }, () => this.getPastActivities());
                    }}
                  />
                </div>
                <div className="planning-reservations-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Seleccione punto"
                    optionsText={([{ value: '*', label: 'Todos' }] as any).concat(diveSites)}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={filterDiveSite}
                    initialValue={filterDiveSite}
                    icon={images.arrowDown2BlueSvg}
                    onChange={(value: string) => {
                      this.setState({ filterDiveSite: value }, () => this.getPastActivities());
                    }}
                  />
                </div>
                <div className="planning-reservations-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Seleccione estado"
                    optionsText={STATS_DROPDOWN}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={filterStatus}
                    initialValue={filterStatus}
                    icon={images.arrowDown2BlueSvg}
                    onChange={(value: string) => {
                      this.setState({ filterStatus: value }, () => this.getPastActivities());
                    }}
                  />
                </div>
              </div>

              <div className="planning-reservation-table">
                <MainLayoutTableContainer>
                  <div className="diving-center-table">
                    <MainTable
                      columns={this.tableColumns}
                      rows={this.getTableRows()}
                      rowKeys={this.rowKeys}
                      onOrder={() => { }}
                    />
                  </div>
                  {totalPages > 1 && (
                    <div className="diving-center-pagination">
                      <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                    </div>
                  )}
                </MainLayoutTableContainer>
              </div>
            </PlanningHistoryStyle>
          </MainLayoutSection>
        </ContentBlock>
      </>
    );
  }

  tableColumns = [
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Nombre',
      filter: false,
      ascendent: false,
    },
    {
      text: 'Staff asignado',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Punto de inmersión',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Particip.',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Valoración',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Planificada',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  rowKeys = [
    'icon',
    'date',
    'name',
    'staff',
    'staffCount',
    'diveSite',
    'diveSiteCount',
    'participants',
    'score',
    'planned',
    'view',
  ];

  tableRowTemplate = {
    icon: {
      key: '',
      value: '',
    },
    date: {
      key: '',
      value: '12/07/2019',
    },
    name: {
      key: '',
      value: 'Inmersión nivel Nemo',
    },
    staff: {
      key: '',
      value: 'Pepe González Gil',
    },
    staffCount: {
      key: 'count',
      value: '',
    },
    diveSite: {
      key: '',
      value: 'Playa Arrecifes',
    },
    diveSiteCount: {
      key: 'count',
      value: '',
    },
    participants: {
      key: '',
      value: '',
    },
    score: {
      key: 'rate',
      value: '0',
    },
    planned: {
      key: 'icon',
      value: '',
    },
    view: {
      key: 'detail',
      value: '',
    },
  };
}
