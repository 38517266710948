import * as React from 'react';
import ModalContainer from '../modal-container';
import { ModalSliderStyle } from './modal-slider-style';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { images } from '../../../assets/images';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import InfoText from '../../text/info-text/info-text';
import BodyText from '../../text/body-text/body-text';
import SingleButton from '../../single-button/single-button';
import ButtonStar from '../../button-star/button-star';
import ItemUser from '../../item-user/item-user';
import { getDateMonthString } from '../../../utils/time';

export interface IModalSliderProps {
  showModal: boolean;
  onClose: () => void;
  pictures: any[];
  pictureSelected: number;
  handleFav?: Function;
  handleDelete?: Function;
  handleEdit?: Function;
  handleLike?: Function;
  agent?: any;
  canAddFav?: boolean;
}

export interface IModalSliderState {
  settings: Object;
  hideInfo: string;
  indexPicture: number;
}

export default class ModalSlider extends React.Component<IModalSliderProps, IModalSliderState> {
  public slider: any = null;
  constructor(props: IModalSliderProps) {
    super(props);
    this.state = {
      hideInfo: '',
      settings: {
        dots: false,
        initialSlide: props.pictureSelected,
        customPaging: () => <button />,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        loop: true,
        focusOnSelect: false,
        afterChange: () => this.setState({ hideInfo: '' }),
        beforeChange: () => this.setState({ hideInfo: 'hide' }),
      },
      indexPicture: props.pictureSelected,
    };
  }

  nextSlide() {
    const { pictures } = this.props;
    const { indexPicture } = this.state;
    if (indexPicture + 1 < pictures.length) {
      this.setState({ indexPicture: indexPicture + 1 });
    }
    this.slider.slickNext();
  }
  prevSlide() {
    const { indexPicture } = this.state;
    if (indexPicture > 0) {
      this.setState({ indexPicture: indexPicture - 1 });
    }
    this.slider.slickPrev();
  }

  handleFav = (featured: boolean, id: number) => {
    const { handleFav, pictures, onClose } = this.props;
    if (handleFav) {
      if (pictures.length === 1) {
        onClose();
      } else {
        this.setState({ indexPicture: 0 });
      }
      handleFav(featured, id);
    }
  };

   render() {
    const {
      onClose,
      showModal,
      pictures,
      handleDelete,
      handleEdit,
      handleFav,
      handleLike,
      canAddFav,
      agent,
    } = this.props;
    const { settings, hideInfo, indexPicture } = this.state;

    const url = pictures[indexPicture] ? decodeURIComponent(pictures[indexPicture].src) : '';
    const filename = url.substring(url.lastIndexOf('/') + 1);
    const isOwner = agent && pictures[indexPicture] && pictures[indexPicture].uploadedBy.id === agent.id;

    return (
      <ModalContainer invertClose={true} className="full" modalClose={() => onClose()} active={showModal}>
        <ModalSliderStyle>
          <div className="modal-slider-slick">
            <div className="modal-slider-slick__left" onClick={() => this.prevSlide()}>
              <img src={images.arrowLeftWhite2Svg} alt="" />
            </div>
            <div className="modal-slider-slick__right" onClick={() => this.nextSlide()}>
              <img src={images.arrowRightWhite2Svg} alt="" />
            </div>

            <Slider ref={(c: any) => (this.slider = c)} {...settings}>
              {pictures.map((p, index: number) => (
                <div key={`modal-picture-${index}`} className="modal-slider-slick-slide">
                  <img src={p.src} alt="" />
                </div>
              ))}
            </Slider>
          </div>
          <div className={`modal-slider-info ${hideInfo}`}>
            <div className="modal-slider-info-top">
              <div className="modal-slider-info__text">
                <div className="modal-slider-info__text__title">
                  <SubheadDestacadoText>
                    {pictures[indexPicture] &&
                      pictures[indexPicture].description &&
                      pictures[indexPicture].description.es
                      ? pictures[indexPicture].description.es
                      : 'Sin título'}
                  </SubheadDestacadoText>
                </div>
                <div className="modal-slider-info__text__file">
                  <BodyText>{filename}</BodyText>
                </div>
                {pictures[indexPicture] && (
                  <div className="modal-slider-info__text__date">
                    <InfoText>{getDateMonthString(pictures[indexPicture].createdAt)}</InfoText>
                  </div>
                )}
              </div>
              {pictures[indexPicture] && (
                <div className="modal-slider-info__buttons">
                  {handleLike && (
                    <div className="modal-slider-info__buttons__item">
                      <ButtonStar count={pictures[indexPicture].likes.length} classNameIcon={'button-thumbs-up-icon'} />
                    </div>
                  )}
                  {handleFav && canAddFav && (
                    <div className="modal-slider-info__buttons__item">
                      <ButtonStar
                        classNameIcon={`button-star-icon ${pictures[indexPicture].featured ? 'button-star-icon--active' : ''
                          }`}
                        onClick={() => this.handleFav(!pictures[indexPicture].featured, pictures[indexPicture].id)}
                      />
                    </div>
                  )}
                  {handleDelete && canAddFav && isOwner && (
                    <div className="modal-slider-info__buttons__item">
                      <SingleButton
                        className="secondary modal"
                        icon={images.delete2Svg}
                        onClick={() => handleDelete && handleDelete(pictures[indexPicture].id)}
                      />
                    </div>
                  )}
                  {handleEdit && canAddFav && isOwner && (
                    <div className="modal-slider-info__buttons__item">
                      <SingleButton
                        className="secondary modal"
                        icon={images.edit3Svg}
                        onClick={() => handleEdit && handleEdit(pictures[indexPicture].id, pictures[indexPicture])}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {pictures[indexPicture] && (
              <div className="modal-slider-info-bottom">
                <ItemUser
                  name={`${pictures[indexPicture].uploadedBy.name || '-'} ${pictures[indexPicture].uploadedBy.surname ||
                    '-'}`}
                  avatar={images.jellyfishIntro}
                  info={'-'}
                />
              </div>
            )}
          </div>
        </ModalSliderStyle>
      </ModalContainer>
    );
  }
}
