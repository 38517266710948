import React, { Component } from 'react';
import { match, RouteComponentProps } from 'react-router';
import { ConservationActionExtended, IDonation } from '../../../../models/conservation-action';
import {
  getConservationAction,
  getConservationActionDonations,
  patchUpdateConservationAction,
} from '../../../../services/conservation-actions';
import HeaderMain from '../../../../components/header-main/header-main';
import ModalCreateProject from '../modal-create-project/modal-create-project';
import services from '../../../../services/services';
import { MainLayoutSection, MainLayoutTableContainer } from '../../../main-layout-style';
import { CertifierProjectDetailStyle } from './certifier-project-detail-style';
import HeadDestacadoText from '../../../../components/text/head-destacado-text/head-destacado-text';
import TextDestacadoText from '../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../components/text/text-text/text-text';
import SelectBox from '../../../../components/select-box';
import { LANGUAGES } from '../../../../constants/languages';
import SubtitleText from '../../../../components/text/subtitle-text/subtitle-text';
import { COUNTRIES_KEY_LABEL } from '../../../../constants/countries';
import MainTableLayout from '../../../../components/main-table/main-table';
import Pagination from '../../../../components/pagination';
import { copyOf } from '../../../../utils/other';
import moment from 'moment';
import { ORGANIZATION_PROJECT_DETAIL_DONATIONS_PER_PAGE } from '../../../../constants/data';

interface CertifierProjectDetailProps extends RouteComponentProps {
  match: match<{
    id: string;
    organizationId: string;
  }>;
}

interface CertifierProjectDetailState {
  project: ConservationActionExtended;
  renderModal: string;
  selectedLanguage: string;
  certifierId: string;
  donations: IDonation[];
  skipPage: number;
  total: number;
  totalPages: number;
  filterSearch: string;
  page: number;
}

class CertifierProjectDetail extends Component<CertifierProjectDetailProps, CertifierProjectDetailState> {
  state: CertifierProjectDetailState = {
    renderModal: '',
    selectedLanguage: 'es',
    certifierId: '0',
    project: {
      id: -1,
      country: '',
      province: '',
      organization: '',
      file: '',
      description: {
        es: '',
        en: '',
        it: '',
        pt: '',
        fr: '',
        de: '',
      },
      title: '',
      createdAt: '',
      date: '',
      updatedAt: '',
      zone: '',
      donations: [],
    },
    skipPage: 0,
    total: 0,
    totalPages: 0,
    donations: [],
    filterSearch: '',
    page: 1,
  };

  componentDidMount(): void {
    const certifierId: string | null = localStorage.getItem('organizationId');

    if (certifierId) {
      this.setState({ certifierId }, () => {
        this.getProject();
      });
    }
  }

  async getProject() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { certifierId } = this.state;
    const response = await getConservationAction(certifierId, id);
    const project = response.data;
    this.setState({ project }, () => this.getDonations());
  }

  async getDonations() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { certifierId, skipPage, filterSearch } = this.state;
    const params = {
      filter: {
        // where: { name: { value: `%${filterSearch}%`, method: 'iLike', collection: 'user', concat: 'surname' } },
        relations: ['diver', 'diver.user', 'charge'],
        skip: skipPage,
        limit: ORGANIZATION_PROJECT_DETAIL_DONATIONS_PER_PAGE,
      },
    };
    const response: any = await getConservationActionDonations(certifierId, id, params);
    const donations = response.data.data;
    const totalPages: number = Math.ceil(response.data.count / ORGANIZATION_PROJECT_DETAIL_DONATIONS_PER_PAGE);
    this.setState({ donations, totalPages, total: response.data.count });
  }

  getTableRows() {
    const {
      match: {
        params: { id, organizationId },
      },
    } = this.props;
    const { donations } = this.state;
    const tableRows: any[] = [];
    donations.forEach(donation => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.name.value = `${donation.diver.user.name} ${donation.diver.user.surname}`;
      tableRow.date.value = moment(donation.createdAt).format('DD/MM/YYYY');
      tableRow.price.value = donation.amount / 100;
      tableRow.currency.value =
        donation.charge && donation.charge.stripeCharge ? donation.charge.stripeCharge.currency.toUpperCase() : '';
      tableRow.card.value =
        donation.charge &&
          donation.charge.stripeCharge &&
          donation.charge.stripeCharge.payment_method_details &&
          donation.charge.stripeCharge.payment_method_details.card
          ? `...${donation.charge.stripeCharge.payment_method_details.card.last4}`
          : '';

      tableRow.detail.link = organizationId
        ? `/organization/${organizationId}/divers/${donation.diver.id}`
        : `/divers/${donation.diver.id}`;
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = ORGANIZATION_PROJECT_DETAIL_DONATIONS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getDonations();
      });
    }
  }

  searchTimeout: any;
  handleSearch(value: string | null) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState({ filterSearch: value || '' }, () => this.getDonations());
    }, 1000);
  }

  render() {
    const { project, selectedLanguage, certifierId, renderModal, donations, totalPages, page, total } = this.state;
    const formatedDate = new Date(project.createdAt);
    if (project.id < 0) return '';
    return (
      <>
        <HeaderMain
          title={'Detalle del proyecto'}
          goBack={true}
          mainButton={{
            text: project.active ? 'Desactivar' : 'Activar',
            onClick: async () => {
              await patchUpdateConservationAction(certifierId, project.id.toString(), {
                active: !project.active,
              });
              project.active = !project.active;
              this.setState({ project });
            },
            type: project.active ? 'error' : 'primary',
          }}
          secondaryButton={{
            text: 'Editar',
            onClick: () => this.setState({ renderModal: 'modal-edit' }),
          }}
        />
        <CertifierProjectDetailStyle>
          <MainLayoutSection>
            <div className="certifier-project-detail-top">
              <div className="certifier-project-detail-top-left">
                <img src={project.file as string} alt="Imagen del proyecto" />
              </div>

              <div className="certifier-project-detail-top-right">
                <div className="certifier-project-detail-top-right__title">
                  <HeadDestacadoText>{project.title}</HeadDestacadoText>
                </div>

                <div className="certifier-project-detail-top-right-info">
                  <div className="certifier-project-detail-top-right-info__item">
                    <div className="certifier-project-detail-top-right-info__item__title">
                      <TextDestacadoText>Fecha de creación:</TextDestacadoText>
                    </div>
                    <div className="certifier-project-detail-top-right-info__item__text">
                      <TextText>
                        {`${formatedDate.getDate()}/${formatedDate.getMonth() + 1}/${formatedDate.getFullYear()}`}
                      </TextText>
                    </div>
                  </div>

                  {/* <div className="certifier-project-detail-top-right-info__item">
                    <div className="certifier-project-detail-top-right-info__item__title">
                      <TextDestacadoText>Creada por:/Provincia:</TextDestacadoText>
                    </div>
                    <div className="certifier-project-detail-top-right-info__item__text">
                      <TextText>
                        {project.province}/{COUNTRIES_KEY_LABEL[project.country]}
                      </TextText>
                    </div>
                  </div> */}

                  <div className="certifier-project-detail-top-right-info__item">
                    <div className="certifier-project-detail-top-right-info__item__title">
                      <TextDestacadoText>Zona de actuación:</TextDestacadoText>
                    </div>
                    <div className="certifier-project-detail-top-right-info__item__text">
                      <TextText>{project.zone}</TextText>
                    </div>
                  </div>

                  <div className="certifier-project-detail-top-right-info__item">
                    <div className="certifier-project-detail-top-right-info__item__title">
                      <TextDestacadoText>País/Provincia:</TextDestacadoText>
                    </div>
                    <div className="certifier-project-detail-top-right-info__item__text">
                      <TextText>
                        {project.province}/{COUNTRIES_KEY_LABEL[project.country]}
                      </TextText>
                    </div>
                  </div>

                  <div className="certifier-project-detail-top-right-info__item">
                    <div className="certifier-project-detail-top-right-info__item__title">
                      <TextDestacadoText>Buceadores comprometidos:</TextDestacadoText>
                    </div>
                    <div className="certifier-project-detail-top-right-info__item__text">
                      <TextText>{donations.length}</TextText>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!!project.description && (
              <div className="certifier-project-detail-description">
                <div className="certifier-project-detail-description-title">
                  <div className="certifier-project-detail-description-title__text">
                    <TextDestacadoText>Descripción</TextDestacadoText>
                  </div>
                  <div className="certifier-project-detail-description-title__language">
                    <SelectBox
                      className="rounded-filter"
                      optionsText={LANGUAGES}
                      optionKey="value"
                      optionValue="label"
                      labelText=""
                      initialValue={selectedLanguage}
                      defaultValue={selectedLanguage}
                      onChange={value => {
                        this.setState({ selectedLanguage: value });
                      }}
                    />
                  </div>
                </div>

                <div className="certifier-project-detail-description-text">
                  <SubtitleText>{project.description[selectedLanguage]}</SubtitleText>
                </div>
              </div>
            )}
            <HeaderMain
              title={'Buceadores comprometidos'}
              // withSearch={true}
              className={'full'}
              handleSearch={(value: string | null) => this.handleSearch(value)}
              resultCount={total}
            />
            <div className="certifier-project-detail-table">
              {/* <div className="certifier-project-detail-table-title">
                <div className="certifier-project-detail-table-title__text">
                  <TitleText>Buceadores comprometidos</TitleText>
                </div>
              </div> */}

              <MainLayoutTableContainer>
                <div className="diving-center-table">
                  <MainTableLayout
                    columns={this.tableColumns}
                    rows={this.getTableRows()}
                    rowKeys={this.rowKeys}
                    onOrder={() => { }}
                  />
                </div>
                {totalPages > 1 && (
                  <div className="diving-center-pagination">
                    <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                  </div>
                )}
              </MainLayoutTableContainer>
            </div>
          </MainLayoutSection>
        </CertifierProjectDetailStyle>

        {renderModal === 'modal-edit' && certifierId && (
          <ModalCreateProject
            certifierId={certifierId}
            showModal={renderModal === 'modal-edit'}
            onCancel={() => this.setState({ renderModal: '' })}
            onSuccess={() => {
              services.showModalSuccess('¡Editado!', 'El proyecto ha sido editado con éxito');

              this.setState({ renderModal: '' });
              this.getProject();
            }}
            selectedLanguage={selectedLanguage}
            project={project}
          />
        )}
      </>
    );
  }

  tableColumns = [
    // {
    //   text: 'ID',
    //   filter: false,
    //   ascendent: true,
    // },
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    // {
    //   text: 'Punto de inmersión',
    //   filter: false,
    //   ascendent: true,
    // },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Cuantía',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Moneda',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Tarjeta',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    name: {
      key: '',
      value: 'Ana María Vargas-Llosa de las Heras',
    },
    date: {
      key: '',
      value: '30/09/2019',
    },
    price: {
      key: '',
      value: '345.98',
    },
    currency: {
      key: '',
      value: 'Euro — €',
    },
    card: {
      key: '',
      value: '****4321',
    },
    detail: {
      key: 'detail',
      link: '/divers/29385329',
    },
  };

  rowKeys = ['name', 'date', 'price', 'currency', 'card', 'detail'];
}

export default CertifierProjectDetail;
