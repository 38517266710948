import {
  LOADING,
  EXPIRED,
  AGENT,
  NOTIFICATION,
  SUCCESS,
  VALID_CENTRE,
  STAFF_PERMISSIONS,
  CENTRE_CURRENCY,
  CENTRE_STRIPE_USER_ID,
  USERDATA,
  BOOKING_LINES,
  STRIPE_TOKEN_ID,
  IS_DIVER_SELECTED,
  BOOKING_CANCELLED,
  BOOKING_PAYMENT_DATA,
  REFUNDS_BOOKING,
  START_ACTIVITY_DATE,
  ACCOUNT_DIVERS,
  ACCOUNT_DIVERS_GHOST
} from './actions';

export const setLoading = (value?: boolean) => ({ value, type: LOADING });

export const setExpiredToken = (value: boolean) => ({ value, type: EXPIRED });

export const setAgent = (value: any) => ({ value, type: AGENT });

export const addNotification = (notifications: any) => ({ notifications, type: NOTIFICATION });

export const showModalSuccess = (value: any) => ({ value, type: SUCCESS });

export const setValidCentre = (value: any) => ({ value, type: VALID_CENTRE });

export const setStaffPermissions = (value: any) => ({ value, type: STAFF_PERMISSIONS });

export const setCentreCurrency = (value: any) => ({ value, type: CENTRE_CURRENCY });

export const setCentreStripeUserId = (value: any) => ({ value, type: CENTRE_STRIPE_USER_ID });

export const setUserData = (value: any) => ({ value, type: USERDATA });

export const setBookingLines = (value: any) => ({ value, type: BOOKING_LINES });

export const setStripeTokenId = (value: any) => ({ value, type: STRIPE_TOKEN_ID });

export const setIsDiverSelected = (value: any) => ({ value, type: IS_DIVER_SELECTED });

export const setBookingCancelled = (value: any) => ({ value, type: BOOKING_CANCELLED });

export const setBookingPaymentData = (value: any) => ({ value, type: BOOKING_PAYMENT_DATA });

export const setRefundsBooking = (value: any) => ({ value, type: REFUNDS_BOOKING });

export const setStartActivityDate = (value: any) => ({ value, type: START_ACTIVITY_DATE });

export const setAccountDivers = (value: any) => ({ value, type: ACCOUNT_DIVERS });
export const setAccountDiversGhost = (value: any) => ({ value, type: ACCOUNT_DIVERS_GHOST });

