export const DIVE_SITES_DROPDOWN = [
  { label: 'Área Marina Protegida ', value: 'marine reserve' },
  { label: 'Pecio', value: 'flotsam' },
  { label: 'Bajo hielo', value: 'ice' },
  { label: 'Arrecife', value: 'arrec' },
  { label: 'Cuevas', value: 'cave' },
  { label: 'Cenote', value: 'cenote' },
  { label: 'Objetos de interés', value: 'submerged objects' },
  { label: 'Museo subacuático ', value: 'museum' },
  { label: 'Pegado a costa', value: 'coast side' },
  { label: 'Aguas interiores', value: 'inland waters' },
  { label: 'Rocas', value: 'roc' },
  { label: 'Arena', value: 'are' },
  { label: 'Piscina', value: 'pisc' },
  { label: 'Acuario', value: 'acua' },
  { label: 'Piscifactoría', value: 'piscif' },
  { label: 'Estructuras sumergidas', value: 'submerged struct' },
  { label: 'Bajo Rocoso', value: 'groundroc' },
  { label: 'Mar Abierto', value: 'openwater' },
];


export const DIVE_SITE_KEY_VALUES: any = {
  'marine reserve': 'Área Marina Protegida ', 
  'flotsam': 'Pecio', 
  'ice': 'Bajo hielo', 
  'arrec': 'Arrecife', 
  'cave': 'Cuevas', 
  'cenote': 'Cenote', 
  'submerged objects': 'Objetos de interés', 
  'museum': 'Museo subacuático ', 
  'coast side': 'Pegado a costa', 
  'inland waters': 'Aguas interiores', 
  'roc': 'Rocas', 
  'are': 'Arena', 
  'pisc': 'Piscina',
  'acua': 'Acuario', 
  'piscif': 'Piscifactoría', 
  'submerged struct': 'Estructuras sumergidas', 
  'groundroc': 'Bajo Rocoso',
  'openwater': 'Mar Abierto',
};
