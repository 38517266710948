export const POLICIES_DROPDOWN = [
  { label: 'Flexible', value: 'flex' },
  { label: 'Moderada', value: 'moderate' },
  { label: 'Estricta', value: 'strict' },
  { label: 'Fija', value: 'fixed' },
];

export const POLICIES_KEY_VALUE: any = {
  flex: 'Flexible',
  flexible: 'Flexible',
  moderate: 'Moderada',
  strict: 'Estricta',
  fixed: 'Fija',
};
