import * as React from 'react';
import { PlanningAccomodationStyle } from './planning-accomodation-style';
import HeaderMain from '../../../components/header-main/header-main';
import UnavailableContent from '../../../components/unavailable-content/unavailable-content';

export interface IPlanningAccomodationProps {}

export interface IPlanningAccomodationState {}

export default class PlanningAccomodation extends React.Component<
  IPlanningAccomodationProps,
  IPlanningAccomodationState
> {
  constructor(props: IPlanningAccomodationProps) {
    super(props);
    this.state = {};
  }

   render() {
    return (
      <>
        <HeaderMain title={'Alojamiento'} />
        <UnavailableContent />
      </>
      // <PlanningAccomodationStyle>
      //   <p>Accomodation</p>
      // </PlanningAccomodationStyle>
    );
  }
}
