export const TRANSPORT_TYPES_KEY_VALUES: any = {
  car: 'Coche',
  van: 'Furgoneta',
  bus: 'Autobus',
  plane: 'Avión',
};

export const TRANSPORT_DROPDOWN = [
  { label: 'Coche', value: 'car' },
  { label: 'Furgoneta', value: 'van' },
  { label: 'Autobus', value: 'bus' },
  { label: 'Avión', value: 'plane' },
];
