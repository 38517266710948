import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const InputBoxContainer = styled.div`
  position: relative;

  .input-box-advice {
    color: ${colors['PR-001/100']};
  }

  &.error {
    .input-box-topbar-label {
      color: ${colors['/NE-003/20']};
    }
    .input-box-main-field > input {
      border-bottom: 1px solid #fd4a22;
      color: #000019;
    }
    .input-box-error {
      opacity: 1;
      visibility: visible;
      color: #fd4a22;
    }
  }

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label::before {
      background: ${colors['NE-002/100']} !important;
    }

    .input-box-main-field {
      border: 1px ${colors['NE-002/100']};
      background: #f2f2f3;

      input {
        border-bottom: 1px solid ${colors['NE-002/100']};
        color: ${colors['NE-003/100']};
      }

      &:before {
        background: ${colors['NE-002/100']} !important;
      }
    }
  }

  &.focus {
    .input-box-topbar-label {
      color: ${colors['PR-001/100']};
    }

    &.required {
      .input-box-topbar-label {
        position: relative;
        &::before  {
          background: ${colors['PR-001/100']};
        }
      }
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 6px;
  }

  .input-box-topbar-label {
    color: ${colors['NE-002/100']};
    text-transform: uppercase;
    padding-left: 12px;
    transition: all 0.3s ease-in-out;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    opacity: 0.6;
    transition: 0.3s all ease-in-out;
    &.active {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  &.required {
    .input-box-topbar-label {
      position: relative;
      &::before  {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 8px;
        background: ${colors['PR-001/100']};
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .input-box-main-field {
    position: relative;
    margin-bottom: 4px;

    &.required {
      &:before {
        content: '';
        z-index: 9;
        position: absolute;
        top: 23px;
        left: 4px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: ${colors['PR-001-100']};
      }
    }

    &:hover {
      background: ${colors['SC-001-10']};
    }
  }

  input {
    font-family: 'plain';
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    font-weight: 300;
    padding: 14px 20px 14px;
    transition: all 0.2s ease-in-out;
    color: ${colors['NE-001/100']};
    height: 48px;
    border-bottom: 1px solid ${colors['NE-002/100']};

    &::placeholder  {
      color: ${colors['NE-002/100']};
    }
  }

  &:not(.disabled) {
    input {
      &:focus {
        color: ${colors['NE-001/100']};
        border-bottom: 1px solid ${colors['NE-001/100']};
      }
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    right: 0;
  }

  .input-box-error {
    color: ${colors['CO-003/100']};
    opacity: 0;
    visibility: hidden;
  }
  &.tooltip {
    z-index: 99;
  }
  &.rounded {
    &.error {
      .input-box-topbar-label {
        color: ${colors['NE-003/20']};
      }
      .input-box-main-field > input {
        border: 1px solid #fd4a22;
        color: #1948a0;
      }
    }
    &.disabled {
      .input-box-topbar-label {
        color: ${colors['NE-003/20']};
      }
      .input-box-main-field > input {
        border: 1px ${colors['NE-002/100']};
        color: ${colors['NE-003/100']};
      }
    }

    .input-box-main-field {
      border-radius: 5px;
    }
    input {
      border: 1px solid ${colors['NE-002/100']};
      border-radius: 5px;
      &:not(.disabled) {
        &:focus {
          color: ${colors['NE-001/100']};
          border: 1px solid ${colors['NE-001/100']};
        }
      }
    }
    .input-box-error {
      min-height: 18px;
    }
  }
  &.rounded-title {
    &.error {
      .input-box-topbar-label {
        color: ${colors['NE-003/20']};
      }
      .input-box-main-field > input {
        border: 1px solid #fd4a22;
      }
    }
    &.disabled {
      .input-box-topbar-label {
        color: ${colors['NE-003/20']};
      }
      .input-box-main-field > input {
        border: 1px ${colors['NE-002/100']};
        color: ${colors['NE-003/100']};
      }
    }
    .input-box-main-field {
      border-radius: 5px;
    }

    input {
      border: 1px solid ${colors['NE-002/100']};
      border-radius: 5px;
      &:not(.disabled) {
        &:focus {
          color: ${colors['NE-001/100']};
          border: 1px solid ${colors['NE-001/100']};
        }
      }
    }
    .input-box-error {
      min-height: 12px;
    }
  }
  &.chat {
    .input-box-topbar {
      display: none;
    }
    input {
      border: 1px solid ${colors['NE-002/100']};
      border-radius: 5px;
      background: ${colors['NE-002/10']};
    }
    .input-box-error {
      display: none;
    }
  }

  &.search {
    .input-box-error {
      display: none;
    }
    .input-box-main-field {
      background: ${colors['NE-002/10']};
      border-radius: 5px;
      input  {
        border-bottom: 0px;
        height: 40px;
      }
    }
  }
`;
