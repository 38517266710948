import styled, { css } from "styled-components";

export const H2TextContainer = styled.p`
  font-family: "plain";
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;

  ${props => css`
    color: ${props.color};
  `}
`;
