import React from 'react';
import { ModalTaxesStyle } from './modal-taxes-style';
import ModalContainer from '../../../../components/modals/modal-container';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../../../components/text/body-text/body-text';
import InputBox from '../../../../components/input-box';
import MainButton from '../../../../components/main-button';
import { images } from '../../../../assets/images';
import SelectBox from '../../../../components/select-box';
import SingleButton from '../../../../components/single-button/single-button';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import { withTranslation, WithTranslation } from 'react-i18next';
import { COUNTRIES_KEY_LABEL, COUNTRIES } from '../../../../constants/countries';
import { deleteIvaTypes, uploadIvaTypes, createIvaTypes } from '../../../../services/iva-types';
interface ModalTaxesProps extends WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  onAccept: Function;
  taxes: any[];
}
interface ModalTaxesState {
  taxes: any[];
  tax: any;
  taxesDeleted: string[];
  editIndex: number;
}

const INIT_TAXES = {
  country: {
    value: '',
    error: false,
    errorCode: '',
    required: true,
  },
  'type-0': {
    value: '',
    error: false,
    errorCode: '',
    required: true,
  },
  'type-1': {
    value: '',
    error: false,
    errorCode: '',
    required: true,
  },
  'type-2': {
    value: '',
    error: false,
    errorCode: '',
    required: true,
  },
};

class ModalTaxes extends React.Component<ModalTaxesProps, ModalTaxesState> {
  readonly state: ModalTaxesState;
  constructor(props: ModalTaxesProps) {
    super(props);
    this.state = {
      editIndex: -1,
      tax: JSON.parse(JSON.stringify(INIT_TAXES)),
      taxes: props.taxes.map((item: any) => {
        const tax: any = {
          country: {
            value: item.country,
            error: false,
            errorCode: '',
            required: true,
          },
          id: item.id,
          default: item.default,
        };
        item.types.forEach((item: string, index: number) => {
          tax[`type-${index}`] = {
            value: item.toString(),
            error: false,
            errorCode: '',
            required: true,
          };
        });
        return tax;
      }),
      taxesDeleted: [],
    };
  }

  changeValue = (value: string, key: string, subKey?: number) => {
    const { tax, taxes } = this.state;
    if (!subKey) {
      tax[key].value = value;
      this.setState({ tax });
    } else {
      taxes[subKey][key].value = value;
      this.setState({ taxes });
    }
  };

  handleAdd = () => {
    const { tax, taxes } = this.state;
    let isOk = true;
    Object.keys(tax).forEach((k: string) => {
      if (k !== 'id') {
        if (tax[k].required && tax[k].value === '') {
          tax[k].error = true;
          tax[k].errorCode = 'required';
          isOk = false;
        } else {
          tax[k].error = false;
          tax[k].errorCode = '';
        }
      }
    });
    if (isOk) {
      const taxPush = JSON.parse(JSON.stringify(tax));
      const taxIndex = taxes.findIndex(ftax => ftax.country.value === tax.country.value);
      if (taxIndex > -1) taxes[taxIndex] = taxPush;
      else taxes.push(taxPush);
      this.setState({ taxes, tax: JSON.parse(JSON.stringify(INIT_TAXES)) });
    } else {
      this.setState({ tax, editIndex: -1 });
    }
  };

  handleDelete = (index: number) => {
    const { taxes, taxesDeleted } = this.state;
    if (taxes[index].id) {
      taxesDeleted.push(taxes[index].id);
    }
    taxes.splice(index, 1);

    this.setState({ taxesDeleted, taxes });
  };

  handleEdit = (index: number) => {
    const { taxes, editIndex } = this.state;
    let tax: any = {};
    let i: number = index;
    if (index !== editIndex) {
      tax = JSON.parse(JSON.stringify(taxes[index]));
    } else {
      tax = JSON.parse(JSON.stringify(INIT_TAXES));
      i = -1;
    }

    this.setState({ tax, editIndex: i });
  };

  handleAccept = async () => {
    const { taxes, taxesDeleted } = this.state;
    const { taxes: propsTaxes } = this.props;
    const taxesOld = propsTaxes.filter((i: any) => taxesDeleted.findIndex(d => d === i.id) === -1);
    // GUARDAR NUEVOS / ACTUALZIAR CAMBIOS / BORRAR
    this.handleAdd();
    try {
      for (const id of taxesDeleted) {
        await deleteIvaTypes(id);
      }
      for (const item of taxes) {
        const search = taxesOld.find((o: any) => o.id === item.id) || {};
        const data: any = {
          country: item.country.value || search.country,
          types: [item['type-0'].value, item['type-1'].value, item['type-2'].value],
        };
        const save =
          search.country !== data.country ||
          search.types[0] !== data.types[0] ||
          search.types[1] !== data.types[1] ||
          search.types[2] !== data.types[2];
        if (item.id && save) {
          await uploadIvaTypes(item.id, data);
        } else if (!item.id) {
          await createIvaTypes(data);
        }
      }
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { showModal, onCancel, t, onAccept } = this.props;
    const { taxes, tax, editIndex } = this.state;
    return (
      <ModalContainer title={``} active={showModal} modalClose={() => onCancel()}>
        <ModalTaxesStyle>
          <div className="modal-taxes-title">
            <SubheadDestacadoText>{t('modalMessages:modal-taxes.title')}</SubheadDestacadoText>
          </div>
          <div className="modal-taxes-text">
            <BodyText>{t('modalMessages:modal-taxes.text')}</BodyText>
          </div>
          {taxes.length > 0 && (
            <div className="modal-taxes-table">
              <table>
                <thead>
                  <tr>
                    <th>
                      <BodyDestacadoText>{t('modalMessages:modal-taxes.country')}</BodyDestacadoText>
                    </th>
                    <th>
                      <BodyDestacadoText>{t('modalMessages:modal-taxes.tax')}</BodyDestacadoText>
                    </th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {taxes.map((item: any, index: number) => (
                    <tr key={`taxes-${item.id}`}>
                      <td>
                        <BodyDestacadoText>{COUNTRIES_KEY_LABEL[item.country.value]}</BodyDestacadoText>
                      </td>
                      <td>
                        <div className="modal-taxes-table-percent">
                          <BodyText>{item['type-0'].value}%</BodyText>
                          <BodyText>{item['type-1'].value}%</BodyText>
                          <BodyText>{item['type-2'].value}%</BodyText>
                        </div>
                      </td>
                      <td>
                        <SingleButton
                          className={`small ${editIndex === index ? 'primary' : ''}`}
                          icon={editIndex === index ? images.edit3WhiteSvg : images.edit3Svg}
                          onClick={() => this.handleEdit(index)}
                        />
                      </td>
                      <td>
                        <SingleButton
                          className="small"
                          icon={images.deleteSvg}
                          onClick={() => this.handleDelete(index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="modal-taxes-form">
            <div className="modal-taxes-line" />

            <div className="modal-taxes-form-row">
              <div className="modal-taxes-form-row__item">
                <SelectBox
                  className="rounded"
                  labelText={t('inputs:country.label')}
                  placeholder={t('inputs:country.label')}
                  initialValue={''}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={''}
                  icon={images.arrowDown2BlueSvg}
                  searchable={true}
                  required={tax.country.required}
                  optionsText={COUNTRIES}
                  defaultValueFilterText={COUNTRIES_KEY_LABEL[tax.country.value]}
                  onChange={(value: any) => this.changeValue(value, 'country')}
                  errorCode={tax.country.errorCode}
                />
              </div>
            </div>

            <div className="modal-taxes-form-row">
              <div className="modal-taxes-form-row__item">
                <InputBox
                  placeholder={t('inputs:percent.label')}
                  labelText={t('inputs:percent.label')}
                  className="rounded"
                  type="number"
                  required={tax['type-0'].required}
                  errorCode={tax['type-0'].errorCode}
                  value={tax['type-0'].value}
                  onChange={(value: string) => this.changeValue(value, 'type-0')}
                />
              </div>
              <div className="modal-taxes-form-row__item">
                <InputBox
                  placeholder={t('inputs:percent.label')}
                  labelText={t('inputs:percent.label')}
                  className="rounded"
                  type="number"
                  required={tax['type-1'].required}
                  errorCode={tax['type-1'].errorCode}
                  value={tax['type-1'].value}
                  onChange={(value: string) => this.changeValue(value, 'type-1')}
                />
              </div>
              <div className="modal-taxes-form-row__item">
                <InputBox
                  placeholder={t('inputs:percent.label')}
                  labelText={t('inputs:percent.label')}
                  className="rounded"
                  type="number"
                  required={tax['type-2'].required}
                  errorCode={tax['type-2'].errorCode}
                  value={tax['type-2'].value}
                  onChange={(value: string) => this.changeValue(value, 'type-2')}
                />
              </div>
              <div className="modal-taxes-form-row__button">
                <MainButton
                  iconImg={images.addSvg}
                  onClick={() => this.handleAdd()}
                  text={editIndex > -1 ? t('buttons:edit') : t('buttons:add')}
                  type="secondary full-height"
                />
              </div>
            </div>
            {/* 
            <div className="modal-taxes-form-row">
            </div> */}
          </div>

          <div className="modal-taxes-button">
            <MainButton onClick={() => onAccept(() => this.handleAccept())} text={t('buttons:save')} type="primary" />
          </div>
        </ModalTaxesStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(ModalTaxes);
