import * as React from 'react';
import { PlanningDateStyle } from './planning-date-style';
import DicotomicCheckbox from '../../../../components/dicotomic-checkbox';
import InfoText from '../../../../components/text/info-text/info-text';
import LabelText from '../../../../components/text/label-text/label';
import BodyText from '../../../../components/text/body-text/body-text';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import DicotomicButton from '../../../../components/dicotomic-button';
import CalendarBox from '../../../../components/calendar-box';
import MainButton from '../../../../components/main-button';
import InputBox from '../../../../components/input-box';
import { images } from '../../../../assets/images';
import { ITimeBlock, ITimeBlocks } from '../../../../models/generic';
import { copyOf } from '../../../../utils/other';
import moment from 'moment';
import { getTimeFormatted, cleanTime } from '../../../../utils/time';
import SingleButton from '../../../../components/single-button/single-button';

export interface PlanningDateState {
  periodic: boolean;
  periodicity: string;
  timeBlocks: ITimeBlocks[];
  customTimeBlocks: ITimeBlocks[];
  dates: Date[];
  startDate: string;
  endDate: string;
  selectedStartTimeString: string;
  selectedEndTimeString: string;
  selectedCustomStartTimeString: string;
  selectedCustomEndTimeString: string;
  selectedStartTime?: ITimeBlock;
  selectedEndTime?: ITimeBlock;
  selectedCustomStartTime?: ITimeBlock;
  selectedCustomEndTime?: ITimeBlock;
  customRepeatDate?: string;
  errors: { [key: string]: string };
}
interface PlanningDateProps {
  isDive: boolean;
  state?: PlanningDateState;
  onPrev: () => void;
  onNext: (
    state: PlanningDateState,
    timeBlocks: ITimeBlocks[],
    dates: string[],
    periodicity?: { periodicity: string; date?: string; end?: string }
  ) => void;
}

class PlanningDate extends React.Component<PlanningDateProps, PlanningDateState> {
  constructor(props: PlanningDateProps) {
    super(props);
    this.state = {
      periodic: false,
      periodicity: 'daily',
      timeBlocks: [],
      customTimeBlocks: [],
      dates: [],
      startDate: '',
      endDate: '',
      selectedStartTimeString: '',
      selectedEndTimeString: '',
      selectedCustomStartTimeString: '',
      selectedCustomEndTimeString: '',
      errors: {
        selectedStartTime: '',
        selectedEndTime: '',
      },
    };
  }

  componentDidMount() {
    const { state } = this.props;
    if (state) {
      this.setState(state);
    }
  }

  handleChangeTime(time: string, isStart: boolean, isCustom?: boolean) {
    const state = Object.assign(this.state);
    const field = isCustom
      ? isStart
        ? 'selectedCustomStartTime'
        : 'selectedCustomEndTime'
      : isStart
        ? 'selectedStartTime'
        : 'selectedEndTime';
    const timeValues = time.split(':');
    state[`${field}String`] = time;
    state.errors[field] = 'invalid-format';
    if (timeValues.length === 2) {
      try {
        const hour = parseInt(timeValues[0], undefined);
        const minutes = parseInt(timeValues[1], undefined);
        if (hour <= 23 && hour >= 0 && minutes <= 59 && minutes >= 0) {
          state[field] = {
            hour,
            minutes,
            seconds: 0,
          };
          state.errors[field] = '';
        }
      } catch (err) { }
    }
    this.setState(state);
  }

  handleAddTime(isCustom?: boolean) {
    const {
      selectedStartTime,
      selectedCustomEndTime,
      selectedEndTime,
      timeBlocks,
      customRepeatDate,
      customTimeBlocks,
      selectedCustomStartTime,
    } = this.state;
    if (isCustom && (!customRepeatDate || customRepeatDate === '')) {
      this.setState({
        errors: { customRepeatDate: 'required' },
      });
      return;
    }
    const newTimeBlocks = isCustom ? copyOf(customTimeBlocks) : copyOf(timeBlocks);
    const selectStartTime = isCustom ? selectedCustomStartTime : selectedStartTime;
    const selectEndTime = isCustom ? selectedCustomEndTime : selectedEndTime;
    const tBlocks = isCustom ? customTimeBlocks : timeBlocks;
    if (
      !selectStartTime ||
      !selectEndTime ||
      selectStartTime.hour > selectEndTime.hour ||
      (selectStartTime.hour === selectEndTime.hour && selectStartTime.minutes >= selectEndTime.minutes)
    ) {
      if (isCustom) {
        this.setState({
          errors: {
            selectedCustomStartTime: 'invalid-format',
            selectedCustomEndTime: 'invalid-format',
          },
        });
      } else {
        this.setState({
          errors: {
            selectedStartTime: 'invalid-format',
            selectedEndTime: 'invalid-format',
          },
        });
      }
    } else {
      let correctRange = true;
      const startTime = moment()
        .hours(selectStartTime.hour)
        .minutes(selectStartTime.minutes)
        .seconds(0);
      const endTime = moment()
        .hours(selectEndTime.hour)
        .minutes(selectEndTime.minutes)
        .seconds(0);
      tBlocks.forEach(timeBlock => {
        const timeBlockStart = moment()
          .hours(timeBlock.start.hour)
          .minutes(timeBlock.start.minutes)
          .seconds(0);
        const timeBlockEnd = moment()
          .hours(timeBlock.end.hour)
          .minutes(timeBlock.end.minutes)
          .seconds(0);
        if (
          (!timeBlock.start.date || (timeBlock.start.date && selectStartTime.date === timeBlock.start.date)) &&
          (timeBlockEnd.isBetween(startTime, endTime) ||
            timeBlockEnd.isSame(startTime) ||
            timeBlockEnd.isSame(endTime) ||
            timeBlockStart.isBetween(startTime, endTime) ||
            timeBlockStart.isSame(startTime) ||
            timeBlockStart.isSame(endTime) ||
            (timeBlockEnd.isAfter(endTime) && timeBlockStart.isBefore(startTime)))
        ) {
          correctRange = false;
        }
      });
      if (correctRange) {
        if (isCustom) {
          newTimeBlocks.push({
            start: selectStartTime,
            end: selectEndTime,
            date: customRepeatDate,
          });
          this.setState({
            errors: {
              selectedCustomStartTime: '',
              selectedCustomEndTime: '',
            },
            selectedCustomStartTimeString: '',
            selectedCustomEndTimeString: '',
            customTimeBlocks: newTimeBlocks,
          });
        } else {
          newTimeBlocks.push({
            start: selectStartTime,
            end: selectEndTime,
          });
          this.setState({
            errors: {
              selectedStartTime: '',
              selectedEndTime: '',
            },
            selectedStartTimeString: '',
            selectedEndTimeString: '',
            timeBlocks: newTimeBlocks,
          });
        }
      } else {
        if (isCustom) {
          this.setState({
            errors: {
              selectedCustomStartTime: 'introduced-range',
              selectedCustomEndTime: 'introduced-range',
            },
          });
        } else {
          this.setState({
            errors: {
              selectedStartTime: 'introduced-range',
              selectedEndTime: 'introduced-range',
            },
          });
        }
      }
    }
  }

  handleRemoveTime(index: number, isCustom?: boolean) {
    const { timeBlocks, customTimeBlocks } = this.state;
    const newTimeBlocks = isCustom ? copyOf(customTimeBlocks) : copyOf(timeBlocks);
    newTimeBlocks.splice(index, 1);
    const state: any = isCustom ? { customTimeBlocks: newTimeBlocks } : { timeBlocks: newTimeBlocks };
    this.setState(state);
  }

  handleAddCustomTimeBlock() {
    const { customTimeBlocks, timeBlocks, customRepeatDate, startDate } = this.state;
    const newTimeBlocks = copyOf(timeBlocks);
    const now = moment();
    if (customTimeBlocks.length === 0 || !customRepeatDate || customRepeatDate === '') {
      this.setState({ errors: { customTimeBlocks: 'required' } });
      return;
    }
    if (now.isAfter(moment(customRepeatDate))) {
      this.setState({ errors: { customRepeatDate: 'invalid-date-future' } });
      return;
    }
    let error = false;
    timeBlocks.forEach(timeBlock => {
      if (
        cleanTime(timeBlock.start.date).isSame(cleanTime(customRepeatDate)) ||
        cleanTime(startDate).isSame(cleanTime(customRepeatDate))
      ) {
        error = true;
        this.setState({ errors: { customRepeatDate: 'invalid-included-date' } });
      }
    });
    if (error) return;
    customTimeBlocks.forEach(timeBlock => {
      timeBlock.start.date = customRepeatDate;
      timeBlock.end.date = customRepeatDate;
      newTimeBlocks.push({
        start: timeBlock.start,
        end: timeBlock.end,
      });
    });

    this.setState({ timeBlocks: newTimeBlocks, customRepeatDate: undefined, customTimeBlocks: [] });
  }

  handleNext() {
    const { onNext } = this.props;
    const { periodic, timeBlocks, dates, startDate, endDate, periodicity } = this.state;
    const newError: any = {};
    const now = moment();
    if (periodic) {
      const start = moment(startDate);
      const end = moment(endDate);
      if ((startDate === '') && (periodicity !== 'custom')) newError.startDate = 'required';
      if (periodicity !== 'custom' && endDate === '') newError.endDate = 'required';
      if (end.isSameOrBefore(start)) {
        newError.startDate = 'invalid-date-start-end';
        newError.endDate = 'invalid-date-start-end';
      }

      if (moment(moment(new Date(startDate)).format("dddd, MMMM Do YYYY, h:mm:ss a")).isBefore(moment(now).format("dddd, MMMM Do YYYY, h:mm:ss a"))) {
        newError.startDate = 'invalid-date-future';
      }

      //if (start.isBefore(now)) newError.startDate = 'invalid-date-future';
      if (end.isBefore(now)) newError.endDate = 'invalid-date-future';
      const diffInDays = end.diff(start, 'days');
      if (
        (periodicity === 'daily' && diffInDays >= 365) ||
        (periodicity === 'weekly' && diffInDays / 7 >= 365) ||
        (periodicity === 'monthly' && diffInDays / 30 >= 365) ||
        (periodicity === 'workDays' && (diffInDays * 5) / 7 >= 365) ||
        (periodicity === 'weekEnds' && (diffInDays * 2) / 7 >= 365)
      ) {
        newError.startDate = 'invalid-max-years';
        newError.endDate = 'invalid-max-years';
      }
    } else {
      if (dates.length === 0) {
        if (!startDate) {
          newError.startDate = 'required';
        } else {
          dates.push(new Date(startDate));
        }
      }
      if (dates.filter((date: Date) => moment(moment(new Date(date)).format("dddd, MMMM Do YYYY, h:mm:ss a")).isBefore(moment(now).format("dddd, MMMM Do YYYY, h:mm:ss a"))).length > 0) {

        newError.startDate = 'invalid-date-future';
      }
    }
    if (timeBlocks.length === 0) newError.timeBlocks = 'error';
    this.setState({ errors: newError });
    if (Object.values(newError).every(err => err === '')) {
      if (periodic) {
        if (periodicity === 'custom') {
          const datesCustom: Date[] = [];
          timeBlocks.forEach((timeBlock: ITimeBlocks) => {
            if (timeBlock.start.date && !datesCustom.includes(new Date(timeBlock.start.date))) {
              datesCustom.push(new Date(timeBlock.start.date));
            }
          });
          const formattedDatesCustom: any = [];
          for (const date of datesCustom) {
            if (date.toDateString() !== 'Invalid Date') {
              formattedDatesCustom.push(new Date(date));
              //formattedDatesCustom.push(date);
            }
          }
          const formattedTimeBlocks: ITimeBlocks[] = [];
          timeBlocks.forEach((timeBlock: ITimeBlocks, index) => {
            const newTimeBlock = copyOf(timeBlock);
            newTimeBlock.start.date = new Date(datesCustom[index]);
            newTimeBlock.end.date = new Date(datesCustom[index]);
            formattedTimeBlocks.push(newTimeBlock)
          });
          onNext(this.state, formattedTimeBlocks, formattedDatesCustom, { periodicity });
        } else {
          onNext(this.state, timeBlocks, [], { periodicity, date: startDate, end: endDate });
        }
      } else {
        const formattedTimeBlocks: ITimeBlocks[] = [];
        dates.forEach((date: Date) => {
          timeBlocks.forEach((timeBlock: ITimeBlocks) => {
            const newTimeBlock = copyOf(timeBlock);
            newTimeBlock.start.date = date;
            newTimeBlock.end.date = date;
            formattedTimeBlocks.push(newTimeBlock);
          });
        });


        onNext(this.state, formattedTimeBlocks, [dates[0].toDateString()]);
      }
    }
  }

  resetState(periodic: boolean) {
    this.setState({
      periodic,
      periodicity: 'daily',
      timeBlocks: [],
      customTimeBlocks: [],
      dates: [],
      startDate: '',
      endDate: '',
      selectedStartTimeString: '',
      selectedEndTimeString: '',
      selectedCustomStartTimeString: '',
      selectedCustomEndTimeString: '',
      errors: {
        selectedStartTime: '',
        selectedEndTime: '',
      },
    });
  }

  render() {
    const { onPrev, isDive } = this.props;
    const {
      dates,
      periodic,
      periodicity,
      errors,
      selectedStartTimeString,
      selectedEndTimeString,
      selectedCustomStartTimeString,
      selectedCustomEndTimeString,
      timeBlocks,
      customTimeBlocks,
      startDate,
    } = this.state;

    return (
      <PlanningDateStyle>
        <div className="planing-date-disclaimer">
          <BodyText>
            Asigna fechas y horario a esta actividad para que los buceadores puedan reservarla en el buscador.
          </BodyText>
        </div>
        {isDive && (
          <>
            <div className="planing-date-subtitle">
              <BodyDestacadoText>Tipo de periodicidad</BodyDestacadoText>
            </div>
            <div className="planing-date-dicotomic">
              <DicotomicButton
                initialValue={periodic ? 'periodic' : 'punctual'}
                options={[
                  { code: 'punctual', label: 'Puntual' },
                  { code: 'periodic', label: 'Periódica' },
                ]}
                onChange={(value: any) => this.resetState(value === 'periodic')}
              />
            </div>
          </>
        )}
        {periodicity !== 'custom' && (
          <>


            <div className="planing-date-start">
              <div className="planing-date-title">
                <LabelText>{'Fecha de inicio'}</LabelText>
              </div>
              <div className="planing-date-start-form">
                <CalendarBox
                  multiSelect={false}
                  initialValue={moment(startDate).format('DD/MM/YYYY')}
                  className="rounded multiple"
                  labelText=""
                  forbidPastDates={true}
                  placeholder="Fecha de inicio"
                  errorCode={errors.startDate}
                  onChange={value =>
                    this.setState({
                      startDate: moment(value, 'DD/MM/YYYY')
                        .hours(12)
                        .toISOString(),
                    })
                  }
                />
              </div>
            </div>

            <div className="planing-date-horary">
              <div className="planing-date-title">
                <LabelText>Horario de la actividad</LabelText>
              </div>
              <div className="planing-date-horary-form">
                <div className="planing-date-horary-form__item">
                  <InputBox
                    className="rounded"
                    required={true}
                    type="text"
                    errorCode={errors.selectedStartTime}
                    value={selectedStartTimeString}
                    placeholder="Hora de inicio"
                    labelText=""
                    onChange={value => this.handleChangeTime(value, true)}
                    withTooltip={false}
                    cleaveOptions={{ time: true, timePattern: ['h', 'm'] }}
                  />
                </div>
                <div className="planing-date-horary-form__item">
                  <InputBox
                    className="rounded"
                    required={true}
                    type="text"
                    errorCode={errors.selectedEndTime}
                    value={selectedEndTimeString}
                    placeholder="Hora de fin"
                    labelText=""
                    onChange={value => this.handleChangeTime(value, false)}
                    withTooltip={false}
                    cleaveOptions={{ time: true, timePattern: ['h', 'm'] }}
                  />
                </div>
                <div className="planing-date-horary-form__add mt-6">
                  <MainButton
                    className={errors.timeBlocks && errors.timeBlocks !== '' ? 'secondary-red' : ''}
                    onClick={() => this.handleAddTime()}
                    text={'Añadir'}
                    iconImg={images.plusSvg}
                    type="secondary full-height"
                  />
                </div>
              </div>

              <div className="planing-date-horary-form__items">
                {timeBlocks.map((timeBlock: ITimeBlocks, index: number) => {
                  if (!timeBlock.start.date) {
                    return (
                      <div key={index} className="planing-date-horary-form__times">
                        <div className="delate-img">
                          <img src={images.deleteSvg} alt="" onClick={() => this.handleRemoveTime(index)} />
                        </div>
                        {`${getTimeFormatted(timeBlock.start.hour, timeBlock.start.minutes)} - ${getTimeFormatted(
                          timeBlock.end.hour,
                          timeBlock.end.minutes
                        )}`}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </>
        )}
        {periodic && (
          <>
            <div className="planing-date-periodicity">
              <div className="planing-date-title">
                <LabelText>tipo de periodicidad</LabelText>
              </div>
              <div className="planing-date-periodicity__item">
                <DicotomicCheckbox
                  className="rounded"
                  checked={periodicity === 'daily'}
                  onCheck={() => this.setState({ periodicity: 'daily' })}
                >
                  <InfoText>Cada día</InfoText>
                </DicotomicCheckbox>
              </div>
              <div className="planing-date-periodicity__item">
                <DicotomicCheckbox
                  className="rounded"
                  checked={periodicity === 'weekly'}
                  onCheck={() => this.setState({ periodicity: 'weekly' })}
                >
                  <InfoText>Cada semana</InfoText>
                </DicotomicCheckbox>
              </div>
              <div className="planing-date-periodicity__item">
                <DicotomicCheckbox
                  className="rounded"
                  checked={periodicity === 'monthly'}
                  onCheck={() => this.setState({ periodicity: 'monthly' })}
                >
                  <InfoText>Cada mes</InfoText>
                </DicotomicCheckbox>
              </div>
              <div className="planing-date-periodicity__item">
                <DicotomicCheckbox
                  className="rounded"
                  checked={periodicity === 'workDays'}
                  onCheck={() => this.setState({ periodicity: 'workDays' })}
                >
                  <InfoText>Todos los días entre semana (DE LUNES A VIERNES)</InfoText>
                </DicotomicCheckbox>
              </div>
              <div className="planing-date-periodicity__item">
                <DicotomicCheckbox
                  className="rounded"
                  checked={periodicity === 'weekEnds'}
                  onCheck={() => this.setState({ periodicity: 'weekEnds' })}
                >
                  <InfoText>Todos los fines de semana</InfoText>
                </DicotomicCheckbox>
              </div>
              <div className="planing-date-periodicity__item">
                <DicotomicCheckbox
                  className="rounded"
                  checked={periodicity === 'custom'}
                  onCheck={() => this.setState({ periodicity: 'custom' })}
                >
                  <InfoText>Personalizar</InfoText>
                </DicotomicCheckbox>
              </div>
            </div>
            {periodicity !== 'custom' ? (
              <>
                <div className="planing-date-title">
                  <LabelText>Fecha de fin</LabelText>
                </div>
                <div className="planing-date-final">
                  <CalendarBox
                    initialValue="string"
                    className="rounded"
                    labelText=""
                    placeholder="Fecha de fin"
                    errorCode={errors.endDate}
                    onChange={value =>
                      this.setState({
                        endDate: moment(value, 'DD/MM/YYYY')
                          .hours(12)
                          .toISOString(),
                      })
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className="planing-date-title">
                  <LabelText>Se repite el</LabelText>
                </div>
                <div className="planing-date-final">
                  <CalendarBox
                    initialValue="string"
                    className="rounded"
                    labelText=""
                    placeholder="Fecha"
                    errorCode={errors.customRepeatDate}
                    onChange={value =>
                      this.setState({
                        customRepeatDate: moment(value, 'DD/MM/YYYY')
                          .hours(12)
                          .toISOString(),
                      })
                    }
                  />
                </div>
                <div className="planing-date-horary">
                  <div className="planing-date-title">
                    <LabelText>Horario de la actividad</LabelText>
                  </div>
                  <div className="planing-date-horary-form">
                    <div className="planing-date-horary-form__item">
                      <InputBox
                        className="rounded"
                        required={true}
                        type="text"
                        errorCode={errors.selectedCustomStartTime}
                        value={selectedCustomStartTimeString}
                        placeholder="Hora de inicio"
                        labelText=""
                        onChange={value => this.handleChangeTime(value, true, true)}
                        withTooltip={false}
                        cleaveOptions={{ time: true, timePattern: ['h', 'm'] }}
                      />
                    </div>
                    <div className="planing-date-horary-form__item">
                      <InputBox
                        className="rounded"
                        required={true}
                        type="text"
                        errorCode={errors.selectedCustomEndTime}
                        value={selectedCustomEndTimeString}
                        placeholder="Hora de fin"
                        labelText=""
                        onChange={value => this.handleChangeTime(value, false, true)}
                        withTooltip={false}
                        cleaveOptions={{ time: true, timePattern: ['h', 'm'] }}
                      />
                    </div>
                    <div className="planing-date-horary-form__add mt-6">
                      <MainButton
                        className={errors.customTimeBlocks && errors.customTimeBlocks !== '' ? 'secondary-red' : ''}
                        onClick={() => this.handleAddTime(true)}
                        text={'Añadir'}
                        iconImg={images.plusSvg}
                        type="secondary full-height"
                      />
                    </div>
                  </div>
                  <div className="planing-date-horary-form__items">
                    {customTimeBlocks.map((timeBlock: ITimeBlocks, index: number) => (
                      <div
                        key={index}
                        className="planing-date-horary-form__item"
                        onClick={() => this.handleRemoveTime(index, true)}
                      >
                        {`${getTimeFormatted(timeBlock.start.hour, timeBlock.start.minutes)} - ${getTimeFormatted(
                          timeBlock.end.hour,
                          timeBlock.end.minutes
                        )}`}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="planing-date-horary-form__add">
                  <MainButton
                    className={errors.timeBlocks && errors.timeBlocks !== '' ? 'secondary-red' : ''}
                    onClick={() => this.handleAddCustomTimeBlock()}
                    text={'Añadir'}
                    iconImg={images.plusSvg}
                    type="secondary full-height"
                  />
                </div>
                <div className="planing-date-horary-form__items">
                  {timeBlocks.map((timeBlock: ITimeBlocks, index: number) => (
                    <div key={index} className="planing-date-horary-form__times" onClick={() => { }}>
                      {`${moment(timeBlock.start.date ? timeBlock.start.date : startDate).format(
                        'DD/MM/YYYY'
                      )} - ${getTimeFormatted(timeBlock.start.hour, timeBlock.start.minutes)} - ${getTimeFormatted(
                        timeBlock.end.hour,
                        timeBlock.end.minutes
                      )}`}
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            <div className="modal-create-activities-buttons-left__back">
              <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
            </div>
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={'Siguiente'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </PlanningDateStyle>
    );
  }
}

export default PlanningDate;
