import styled from 'styled-components';

export const BookingsDataStyle = styled.div`
  .booking-detail-general-container {
    display: flex;
    width: 100%;
  }
  .booking-detail-general-left {
    margin-right: 60px;
    width: 100%;
    &__card {
      margin-bottom: 120px;
    }
    &__section {
      margin-bottom: 80px;
    }
  }
  .booking-detail-general-double {
    display: flex;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
    &__title {
      width: 50%;
    }
    &__text {
      width: 50%;
    }
  }
  .booking-detail-comments-header {
    display: flex;
    justify-content: space-between;
    span {
      cursor: pointer;
      color: #00a1a5;
      font-family: 'plain';
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .comments {
    border: 1px solid #ddd;
    padding: 2em;
    margin-top: 1em;
  }
`;
