import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const CreateActivitiesCourseInfoStyle = styled.div`
  .create-activities-other-title {
    display: flex;
    margin-bottom: 15px;
    &__text {
      margin-right: 28px;
    }
    &__check  {
      margin-right: 20px;
    }
  }

  .create-activities-other-subtitle {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 15px;
    font-weight: 150;
    color: ${colors['NE-002/100']};
  }

  .add-phase-button {
    display: flex;
    align-items: center;
    min-width: 144px;
    margin-bottom: 24px;
  }

  .summary {
    margin-bottom: 24px;

    .phase-tittle {
      font-size: 15px;
      color: #014251;
      margin-bottom: 5px;
      margin-top: 15px;
    }
    .phase-content {
      color: ${colors['NE-001/100']};
      display: flex;
      align-items: center;

      .delete-img {
        cursor: pointer;
        width: 20px;
        margin-right: 10px;
        margin-top: 5px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
`;
