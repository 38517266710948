import * as React from 'react';
import { ItemUserStyle } from './item-user-style';
import InfoText from '../text/info-text/info-text';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import BodyText from '../text/body-text/body-text';
import { images } from '../../assets/images';

export interface IItemUserProps {
  avatar: string;
  subtitle?: string;
  name: string;
  info: string;
}

export interface IItemUserState {}

export default class ItemUser extends React.Component<IItemUserProps, IItemUserState> {
  constructor(props: IItemUserProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { avatar, subtitle, name, info } = this.props;
    return (
      <ItemUserStyle>
        <div className="item-user-avatar">
          <img src={avatar ? avatar : images.defaultUserSvg} alt="" />
        </div>
        <div className="item-user-info">
          <div className="item-user-info__subtitle">
            <InfoText>{subtitle}</InfoText>
          </div>
          <div className="item-user-info__name">
            <TextDestacadoText>{name}</TextDestacadoText>
          </div>
          {/* <div className="item-user-info__info">
            <BodyText>{info}</BodyText>
          </div> */}
        </div>
      </ItemUserStyle>
    );
  }
}
