import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { images } from '../../assets/images';
import ButtonDropdown from '../buttons/button-dropdown/button-dropdown';
import MiniModalCheckbox from '../modals/mini/checkbox/mini-modal-checkbox';
import { setYear } from 'date-fns/esm';

interface IYearsCheckListProps {
  onChange: Function;
  onDelete: Function;
  value: any;
  maxYear?: number;
  minYear?: number;
}

const YearsChecklist: React.FC<IYearsCheckListProps> = ({ maxYear, minYear, onDelete, onChange, value }) => {
  const { t } = useTranslation(['layouts']);
  const [years, setYears] = useState<any[]>([]);

  useEffect(() => {
    const newYears = [];
    const currentYear = new Date().getFullYear();
    // const max = maxYear && maxYear - currentYear <= 50 ? maxYear : currentYear + 30;
    // const min = minYear && currentYear - minYear <= 50 ? minYear : currentYear - 30;
    // for (let i = max; i >= min; i -= 1) {
    //   newYears.push({ label: i.toString(), value: i.toString() });
    // }
    // setYears(newYears);
    setYears([{ label: currentYear.toString(), value: currentYear.toString() }]);
  }, [maxYear, minYear]);

  let checkList;
  if (years.length > 0) {
    checkList = (
      <ButtonDropdown
        orientationModal={'right'}
        text={t('layouts:config.year')}
        rightIcon={images.arrowDown2Svg}
        component={
          <MiniModalCheckbox
            showSelectionCount={true}
            showDeleteBtn={true}
            options={years}
            value={value}
            onDelete={() => {
              onDelete();
            }}
            onChange={(value: any) => {
              onChange(value);
            }}
            multiple={true}
          />
        }
      />
    );
  }

  return <>{checkList}</>;
};

export default YearsChecklist;
