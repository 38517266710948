import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { NavLink } from 'react-router-dom';
import { ModalCookiesStyle } from './modal-cookies-style';
import { useTranslation } from 'react-i18next';

const ModalCookies: React.FC = () => {
  const [active, setActive] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect((): any => {
    if(!Cookie.get('cookiesAccepted')){
      setActive(true)
    }
  }, []);

  function closeCookiesAlert() {
    Cookie.set('cookiesAccepted', 'true', { expires: 30 });
    setActive(false);
  }

  const cookiesText = t('layouts:cookies.advice');

  return (
    <ModalCookiesStyle className={active ? 'active' : ''}>
      <h6 className="title">
         {t("layouts:cookies.cookies")} 
      </h6>

      <div dangerouslySetInnerHTML={{__html: cookiesText}}>

      </div>
      
      <div className="accept">
        <a className="accept__button" onClick={closeCookiesAlert}>
          {/* {t('agree')} */}
          {t("components:actions.accept")} 
        </a>
      </div>
    </ModalCookiesStyle>
  );
};

export default ModalCookies;
