import React from 'react';
import { ModalCreateOfferStyle } from './modal-create-offers-style';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import { FormGrid, FormGridItem, FormGridRow } from '../../../main-layout-style';
import InputBox from '../../../../components/input-box';
import SelectBox from '../../../../components/select-box';
import { images } from '../../../../assets/images';
import MainButton from '../../../../components/main-button';
import DicotomicCheckbox from '../../../../components/dicotomic-checkbox';
import InfoText from '../../../../components/text/info-text/info-text';
import CalendarBox from '../../../../components/calendar-box';
import ModalContainer from '../../../../components/modals/modal-container';
import { getActivityTemplates, postSale, patchSale } from '../../../../services/centre';
import { IActivityTemplate } from '../../../../models/activity-template';
import { IDropdown } from '../../../../models/generic';
import moment from 'moment';
import { ISale } from '../../../../models/sales';
import services from '../../../../services/services';
import { copyOf } from '../../../../utils/other';

interface ModalCreateOffersProps {
  centreId: string;
  showModal: boolean;
  onCancel: Function;
  onSuccess: () => void;
  sale?: ISale;
}

interface ModalCreateOffersState {
  activityTemplatesDropdown: IDropdown[];
  sale: ISale;
  validUntilEnabled: boolean;
  errors: { [key: string]: string };
}

export default class ModalCreateOffers extends React.Component<ModalCreateOffersProps, ModalCreateOffersState> {
  constructor(props: ModalCreateOffersProps) {
    super(props);
    this.state = {
      activityTemplatesDropdown: [],
      sale: {
        name: '',
        discount: NaN,
        validUntil: '',
        centre: parseInt(props.centreId, undefined),
        templates: [],
      },
      errors: {},
      validUntilEnabled: true,
    };
  }

  componentDidMount() {
    this.getActivityTemplates();
  }

  async getActivityTemplates() {
    const { centreId, sale } = Object.assign(this.props);
    const resp = await getActivityTemplates(centreId);
    const activityTemplates: IActivityTemplate[] = resp.data.data;
    const activityTemplatesDropdown = activityTemplates.map(template => {
      return {
        label: template.name,
        value: template.id.toString(),
      };
    });
    if (sale) {
      sale.templates = sale.templates.map((template: any) => {
        if (template) {
          return template.id.toString();
        }
      });
      let validUntilEnabled = true;
      if (sale.validUntil) {
        sale.validUntil = moment(sale.validUntil, 'YYYY-MM-DD').toISOString();
      } else {
        validUntilEnabled = false;
      }
      this.setState({ activityTemplatesDropdown, sale, validUntilEnabled });
    } else {
      this.setState({ activityTemplatesDropdown });
    }
  }

  validate(field: string, value: string | number | string[], required: boolean) {
    if (required && (!value || value === '' || (typeof value === 'object' && value.length === 0))) return 'required';
    return '';
  }

  handleChange(field: string, value: string | number | string[], required: boolean) {
    const { sale, errors } = Object.assign(this.state);
    sale[field] = value;
    errors[field] = this.validate(field, value, required);
    this.setState({ sale });
  }

  async handleSubmit() {
    const { centreId, onSuccess } = this.props;
    const { errors, validUntilEnabled } = Object.assign(this.state);
    const sale = copyOf(this.state.sale);
    const requiredFields = ['name', 'discount', 'templates'];
    let error = false;
    requiredFields.forEach(field => {
      errors[field] = this.validate(field, sale[field], true);
      if (errors[field] !== '') error = true;
    });
    if (validUntilEnabled) {
      errors.validUntil = this.validate('validUntil', sale.validUntil, true);
      if (errors.validUntil !== '') error = true;
    } else {
      errors.validUntil = '';
      delete sale.validUntil;
    }
    if (error) {
      this.setState({ errors });
    } else {
      sale.templates = sale.templates.map((temp: any) => ({ id: parseInt(temp, undefined) }));
      sale.discount = parseFloat(sale.discount);
      if (!validUntilEnabled) {
        sale.validUntil = null;
      }
      if (this.props.sale && this.props.sale.id) {
        await patchSale(centreId, this.props.sale.id.toString(), sale);
        services.showModalSuccess('¡Editada!', 'La oferta se ha editado con éxito');
      } else {
        await postSale(centreId, sale);
        services.showModalSuccess('¡Creado!', 'La oferta se ha creado con éxito');
      }
      onSuccess();
    }
  }

  render() {
    const { onCancel, showModal } = this.props;
    const { activityTemplatesDropdown, sale, errors, validUntilEnabled } = this.state;
    return (
      <ModalContainer className="" modalClose={() => onCancel()} active={showModal}>
        <ModalCreateOfferStyle>
          <div className="create-user-form">
            <div className="create-user-form__title">
              <BodyDestacadoText>Datos Generales</BodyDestacadoText>
            </div>
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.name}
                      placeholder="Nombre de la oferta"
                      className="rounded"
                      type="text"
                      value={sale.name}
                      labelText="Usuario"
                      onChange={value => this.handleChange('name', value, true)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.discount}
                      placeholder="Descuento aplicado (%)"
                      className="rounded"
                      type="text"
                      value={sale.discount.toString()}
                      labelText="Usuario"
                      onChange={value => this.handleChange('discount', parseInt(value, undefined), true)}
                      cleaveOptions={{
                        numericOnly: true,
                        blocks: [2],
                        sufix: '%',
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>
          <div className="create-user-form">
            <div className="create-user-form__title">
              <BodyDestacadoText>Actividades</BodyDestacadoText>
            </div>
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      errorCode={errors.templates}
                      labelText="país"
                      placeholder="Asociar actividades"
                      required={true}
                      multiple={true}
                      className="rounded"
                      optionsText={activityTemplatesDropdown}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      defaultMultipleValues={sale.templates}
                      initialMultipleValues={sale.templates}
                      onChange={() => {}}
                      onChangeMultiple={values => {
                        this.handleChange('templates', values, true);
                      }}
                      icon={images.arrowDown2BlueSvg}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>
          {validUntilEnabled && (
            <div className="create-user-form">
              <div className="create-user-form__title">
                <BodyDestacadoText>Validez</BodyDestacadoText>
              </div>
              <div className="create-user-form__content">
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <CalendarBox
                        placeholder="Válido hasta"
                        className="rounded"
                        labelText=""
                        errorCode={errors.validUntil}
                        forbidPastDates={true}
                        initialValue={sale.validUntil !== '' ? moment(sale.validUntil).format('DD/MM/YYYY') : ''}
                        onChange={value =>
                          this.handleChange('validUntil', moment(value, 'DD/MM/YYYY').toISOString(), true)
                        }
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
              </div>
            </div>
          )}
          <div className="create-user-radio">
            <DicotomicCheckbox
              className="rounded"
              checked={!validUntilEnabled}
              onCheck={checked => {
                this.setState({ validUntilEnabled: !checked });
              }}
            >
              <InfoText>La oferta tiene una validez indefinida en el tiempo.</InfoText>
            </DicotomicCheckbox>
          </div>
          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton type="primary" onClick={() => this.handleSubmit()} text="Guardar" />
            </div>
          </div>
        </ModalCreateOfferStyle>
      </ModalContainer>
    );
  }
}
