import services from './services';

export const getAds = async (params: any) => {
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'ads',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
}

export const getOneAds = (adsId: string) => {
  const endpoint = `ads/${adsId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadAdsFormData = (adsId: string, data: FormData) => {
  const endpoint = `ads/${adsId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const uploadAds = (adsId: string, data: any) => {
  const endpoint = `ads/${adsId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteAds = (adsId: string) => {
  const endpoint = `ads/${adsId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createAds = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'ads',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};