import * as React from 'react';
import { CreateActivitiesOtherServicesStyle } from './create-activities-other-services-style';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import InfoText from '../../../../../components/text/info-text/info-text';
import { images } from '../../../../../assets/images';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../main-layout-style';
import SelectBox from '../../../../../components/select-box';
import InputBox from '../../../../../components/input-box';
import { TRANSPORT_DROPDOWN } from '../../../../../constants/transport';
import { FOOD_ACCOMMODATION_DROPDOWN } from '../../../../../constants/food-accommodation';
import SingleButton from '../../../../../components/single-button/single-button';
import MainButton from '../../../../../components/main-button';
import { copyOf } from '../../../../../utils/other';
import { IOtherServices } from '../../../../../models/activity-template';

export interface ICreateActivitiesOtherServicesProps {
  otherServices?: IOtherServices;
  onPrev?: () => void;
  onNext: (otherServices: IOtherServices) => void;
  centreId: string;
}

export interface ICreateActivitiesOtherServicesState {
  includeFoodAccommodation: boolean;
  includeTransport: boolean;
  otherServices: IOtherServices;
  errors: {
    foodAccommodation: string;
    departurePoint: string;
    transport: string;
  };
}

export default class CreateActivitiesOtherServices extends React.Component<
  ICreateActivitiesOtherServicesProps,
  ICreateActivitiesOtherServicesState
> {
  constructor(props: ICreateActivitiesOtherServicesProps) {
    super(props);

    this.state = {
      includeFoodAccommodation: false,
      includeTransport: false,
      otherServices: {
        foodAccommodation: '',
        departurePoint: '',
        transport: '',
      },
      errors: {
        foodAccommodation: '',
        departurePoint: '',
        transport: '',
      },
    };
  }

  componentDidMount() {
    this.setOtherServices();
  }

  componentDidUpdate(prevProps: ICreateActivitiesOtherServicesProps) {
    const { otherServices } = this.props;
    if (otherServices !== prevProps.otherServices) {
      this.setOtherServices();
    }
  }

  setOtherServices() {
    const { otherServices } = this.props;
    if (otherServices) {
      this.setState({
        otherServices,
        includeFoodAccommodation: otherServices.foodAccommodation !== '' && !!otherServices.foodAccommodation,
        includeTransport: otherServices.transport !== '' && !!otherServices.transport,
      });
    }
  }

  handleChange(field: string, value: string) {
    const { otherServices } = Object.assign(this.state);
    otherServices[field] = value;
    this.setState({
      otherServices,
    });
  }

  handleNext() {
    const { onNext } = this.props;
    const { otherServices, includeFoodAccommodation, includeTransport } = this.state;
    let hasError = false;
    const errors = { foodAccommodation: '', departurePoint: '', transport: '' };
    if (includeFoodAccommodation && otherServices.foodAccommodation === '') {
      errors.foodAccommodation = 'required';
      hasError = true;
    }
    if (includeTransport && otherServices.departurePoint === '') {
      errors.departurePoint = 'required';
      hasError = true;
    }
    if (includeTransport && otherServices.transport === '') {
      errors.transport = 'required';
      hasError = true;
    }
    this.setState({ errors });
    if (!hasError) {
      const otherServicesFormat = copyOf(otherServices);
      if (!includeTransport) {
        delete otherServicesFormat.departurePoint;
        delete otherServicesFormat.transport;
      }
      if (!includeFoodAccommodation) {
        delete otherServicesFormat.foodAccommodation;
      }
      onNext(otherServicesFormat);
    }
  }

  render() {
    const { onPrev } = this.props;
    const { otherServices, includeFoodAccommodation, includeTransport, errors } = this.state;
    return (
      <CreateActivitiesOtherServicesStyle>
        <div className="create-activities-other-title">
          <div className="create-activities-other-title__text">
            <BodyDestacadoText>¿Incluye régimen de alojamiento y comida?</BodyDestacadoText>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={includeFoodAccommodation}
              onCheck={() => this.setState({ includeFoodAccommodation: true })}
            >
              <InfoText>Si</InfoText>
            </DicotomicCheckbox>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={!includeFoodAccommodation}
              onCheck={() => this.setState({ includeFoodAccommodation: false })}
            >
              <InfoText>No</InfoText>
            </DicotomicCheckbox>
          </div>
        </div>
        {includeFoodAccommodation && (
          <FormGrid>
            <FormGridRow>
              <FormGridItem type={'col-0'}>
                <SelectBox
                  errorCode={errors.foodAccommodation}
                  className="rounded-title"
                  labelText="régimen de comida"
                  placeholder="Régimen de comida"
                  required={true}
                  initialValue={otherServices.foodAccommodation}
                  optionsText={FOOD_ACCOMMODATION_DROPDOWN}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={otherServices.foodAccommodation}
                  icon={images.arrowDown2BlueSvg}
                  onChange={value => this.handleChange('foodAccommodation', value)}
                />
              </FormGridItem>
            </FormGridRow>
          </FormGrid>
        )}
        <div className="create-activities-other-title">
          <div className="create-activities-other-title__text">
            <BodyDestacadoText>¿Incluye desplazamiento?</BodyDestacadoText>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={includeTransport}
              onCheck={() => this.setState({ includeTransport: true })}
            >
              <InfoText>Si</InfoText>
            </DicotomicCheckbox>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={!includeTransport}
              onCheck={() => this.setState({ includeTransport: false })}
            >
              <InfoText>No</InfoText>
            </DicotomicCheckbox>
          </div>
        </div>
        {includeTransport && (
          <FormGrid>
            <FormGridRow>
              <FormGridItem type={'col-0'}>
                <InputBox
                  errorCode={errors.departurePoint}
                  placeholder="Salida desde (nombre del centro)"
                  className="rounded-title"
                  required={true}
                  type="text"
                  value={otherServices.departurePoint ? otherServices.departurePoint : ''}
                  labelText="salida desde (nombre del centro)"
                  onChange={value => this.handleChange('departurePoint', value)}
                />
              </FormGridItem>
            </FormGridRow>
            <FormGridRow>
              <FormGridItem type={'col-0'}>
                <SelectBox
                  errorCode={errors.transport}
                  className="rounded-title"
                  labelText="medio de transporte"
                  placeholder="Medio de transporte"
                  required={true}
                  initialValue={otherServices.transport}
                  optionsText={TRANSPORT_DROPDOWN}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={otherServices.transport}
                  icon={images.arrowDown2BlueSvg}
                  onChange={value => this.handleChange('transport', value)}
                />
              </FormGridItem>
            </FormGridRow>
          </FormGrid>
        )}
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={onPrev ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesOtherServicesStyle>
    );
  }
}
