import * as React from 'react';
import { DropDownStyle } from './drop-down-style';
import { images } from '../../assets/images';
import TextText from '../text/text-text/text-text';

export interface IDropDownProps {
  title: string;
  items: string[];
}

export interface IDropDownState {
  active: boolean;
}

export default class DropDown extends React.Component<IDropDownProps, IDropDownState> {
  constructor(props: IDropDownProps) {
    super(props);

    this.state = {
      active: false,
    };
  }

  render() {
    const { active } = this.state;
    const { children, title, items } = this.props;
    return (
      <DropDownStyle className={active && 'active'}>
        <div className="drop-top" onClick={() => this.setState({ active: !active })}>
          <div className="drop-top__title">
            <p>{`${title} (${items.length})`}</p>
          </div>
          <div className="drop-top__arrow">
            <img src={images.arrowDown2Svg} alt="" />
          </div>
        </div>
        <div className="drop-content">
          {items.map((item, index) => (
            <div className="drop-content__item" key={index}>
              <TextText>{item}</TextText>
            </div>
          ))}
        </div>
      </DropDownStyle>
    );
  }
}
