import * as React from 'react';
import { MainLayoutTableContainer, MainLayoutSection } from '../../../main-layout-style';
import Pagination from '../../../../components/pagination';
import MainTableLayout from '../../../../components/main-table/main-table';
import { images } from '../../../../assets/images';
import ModalCreateEquipment from '../my-center-equipment-inventary/modal-create-equipment/modal-create-equipment';
import { NavigationTabContainer } from '../../../../components/navigation-tabs/navigation-tabs-style';
import { NavLink, RouteComponentProps, match } from 'react-router-dom';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import HeaderMain from '../../../../components/header-main/header-main';
import { getEquipment, deleteEquipment } from '../../../../services/centre';
import { copyOf } from '../../../../utils/other';
import { DURATION_UNITS_KEY_VALUE, SINGLE_DURATION_UNITS_KEY_VALUE } from '../../../../constants/duration';
import ModalDelete from '../../../../components/modals/modal-delete/modal-delete';
import services from '../../../../services/services';
import { ContentBlock } from '../../../../components/content-block/content-block';
import IStoreState from '../../../../redux/store/IStoreState';
import { connect } from 'react-redux';

const EQUIPMENT_PER_PAGE = 10;
export interface IMyCenterEquipmentRentProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  t: any;
  onClickButtonHeader: () => void;
  staffPermissions?: any;
}

export interface IMyCenterEquipmentRentState {
  total: number;
  totalPages: number;
  skipPage: number;
  renderModal: string;
  equipments: any[];
  selectedEquipment?: any;
  page: number;
}

class MyCenterEquipmentRent extends React.Component<IMyCenterEquipmentRentProps, IMyCenterEquipmentRentState> {
  constructor(props: IMyCenterEquipmentRentProps) {
    super(props);
    this.state = {
      total: 0,
      totalPages: 0,
      skipPage: 0,
      equipments: [],
      renderModal: '',
      page: 1,
    };
  }

  componentDidMount() {
    this.getEquipment();
  }

  async getEquipment() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { skipPage } = this.state;
    const params = {
      filter: {
        skip: skipPage,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        limit: EQUIPMENT_PER_PAGE,
        relations: ['stock'],
      },
    };
    const resp = await getEquipment(id, params);
    const equipments = resp.data.data;
    const totalPages: number = Math.ceil(resp.data.count / EQUIPMENT_PER_PAGE);
    this.setState({ equipments, totalPages, total: resp.data.count });
  }

  async handleDelete() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { selectedEquipment } = this.state;
    if (selectedEquipment && selectedEquipment.id) {
      await deleteEquipment(id, selectedEquipment.id);
      this.getEquipment();
      services.pushNotification({
        title: '¡Eliminado!',
        text: 'Equipo eliminado correctamente',
        type: 'red',
      });
    }
  }

  getTableRows() {
    const { staffPermissions } = this.props;
    const { equipments } = this.state;
    const tableRows: any[] = [];
    equipments.forEach(equip => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.name.value = equip.name;
      tableRow.duration.value = `${
        equip.duration.amount && equip.duration.units !== 'activity' ? equip.duration.amount : ''
      } ${SINGLE_DURATION_UNITS_KEY_VALUE[equip.duration.units]}${
        equip.duration.units !== 'activity' && equip.duration.amount > 1 ? 's' : ''
      }`;
      tableRow.price.value = `${equip.price / 100} €`;
      if (!staffPermissions || staffPermissions.deals) {
        tableRow.edit.onClick = () => {
          this.setState({ selectedEquipment: equip, renderModal: 'modal-create' });
        };
        tableRow.delete.onClick = () => {
          this.setState({ selectedEquipment: equip, renderModal: 'modal-delete' });
        };
      } else {
        tableRow.delete = tableRow.edit = {
          key: '',
          value: ' ',
        };
      }
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = EQUIPMENT_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getEquipment();
      });
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t, 
      staffPermissions,
    } = this.props;
    const { renderModal, totalPages, selectedEquipment, page } = this.state;
    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Material del centro'}
            mainButton={
              !staffPermissions || staffPermissions.deals
                ? {
                    img: images.createWhiteSvg,
                    text: 'Crear',
                    onClick: () => this.setState({ selectedEquipment: undefined, renderModal: 'modal-create' }),
                  }
                : undefined
            }
          />
          <MainLayoutSection>
            <NavigationTabContainer>
              <NavLink to={`/centres/${id}/business/equipment/inventary`}>
                <div className="navigation-item">
                  <BodyDestacadoText>Inventario</BodyDestacadoText>
                </div>
              </NavLink>
              <NavLink to={`/centres/${id}/business/equipment/rent`}>
                <div className="navigation-item">
                  <BodyDestacadoText>Alquiler de equipo</BodyDestacadoText>
                </div>
              </NavLink>
              <NavLink to={`/centres/${id}/business/equipment/shop`}>
                <div className="navigation-item">
                  <BodyDestacadoText>Tienda</BodyDestacadoText>
                </div>
              </NavLink>
            </NavigationTabContainer>

            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout
                  columns={this.tableColumns}
                  rows={this.getTableRows()}
                  rowKeys={this.rowKeys}
                  onOrder={() => {}}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                </div>
              )}
            </MainLayoutTableContainer>
          </MainLayoutSection>
          {renderModal === 'modal-create' && (
            <ModalCreateEquipment
              centreId={id}
              onSuccess={() => {
                this.setState({ renderModal: '' });
                this.getEquipment();
              }}
              equipment={selectedEquipment}
              onClose={() => this.setState({ renderModal: '' })}
              showModal={renderModal === 'modal-create'}
            />
          )}
          {renderModal === 'modal-delete' && (
            <ModalDelete
              title={''}
              subtitle={'¿Deseas eliminar el equipo?'}
              showModal={renderModal === 'modal-delete'}
              onRemove={() => {
                this.setState({ renderModal: '' });
                this.handleDelete();
              }}
              onCancel={() => this.setState({ renderModal: '' })}
            />
          )}
        </ContentBlock>
      </>
    );
  }

  tableColumns = [
    {
      text: 'Nombre',
      filter: false,
      ascendent: false,
    },
    {
      text: 'Duración',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Precio',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    name: {
      key: 'highlighted-body',
      value: 'Titulación',
    },
    duration: {
      key: '',
      value: 'Lorem ipsum dolor sit amet consec',
    },
    price: {
      key: '',
      value: 'Certificadora Lorem',
    },
    edit: {
      key: 'icon-button',
      value: images.edit3Svg,
    },
    delete: {
      key: 'icon-button',
      value: images.deleteSvg,
    },
  };

  rowKeys = ['name', 'duration', 'price', 'edit', 'delete'];
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(MyCenterEquipmentRent);
