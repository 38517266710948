import * as React from 'react';
import { NotificationsDetailStyled } from './notifications-detail-style';
import CardNotification from '../../../components/card-notification/card-notification';
import { images } from '../../../assets/images';
import HeadDestacadoText from '../../../components/text/head-destacado-text/head-destacado-text';
import SingleButton from '../../../components/single-button/single-button';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection } from '../../main-layout-style';
import TextDestacadoText from '../../../components/text/text-destacado-text/text-destacado-text';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  getOneNotifications,
  uploadNotifications,
  deleteNotifications,
  favNotifications,
  readNotifications,
} from '../../../services/notifications';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { getDateMonthString } from '../../../utils/time';
import parser from 'html-react-parser';

interface RouteParams {
  id: string;
  notificationId?: string;
}
interface INotificationsDetailProps extends RouteComponentProps<RouteParams> {}

export interface INotificationsDetailState {
  notification: any;
  modal: string;
}

const bussines = [
  { type: 'pendant', total: 23 },
  { type: 'resolved', total: 27 },
  { type: 'closed', total: 12 },
];
const diveSites = [
  { type: 'pendant', total: 23 },
  { type: 'resolved', total: 14 },
  { type: 'closed', total: 10 },
];
const pictures = [
  { type: 'pendant', total: 23 },
  { type: 'resolved', total: 14 },
  { type: 'closed', total: 10 },
];
const performance = [
  { type: 'pendant', total: 23 },
  { type: 'resolved', total: 14 },
  { type: 'closed', total: 10 },
];
const newCentres = [
  { type: 'pendant', total: 23 },
  { type: 'resolved', total: 14 },
  { type: 'closed', total: 10 },
  { type: 'rejected', total: 10 },
];
const users = [{ type: 'resolved', total: 23 }];
const activities = [{ type: 'closed', total: 23 }];
const billing = [{ type: 'percent', total: 23 }];

class NotificationsDetail extends React.Component<INotificationsDetailProps, INotificationsDetailState> {
  constructor(props: INotificationsDetailProps) {
    super(props);

    this.state = {
      notification: {},
      modal: '',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const id = match.params.notificationId ? match.params.notificationId : match.params.id;
    if (id) {
      this.getNotification(id);
    }
  }

  getNotification = async (id: string) => {
    try {
      const response: any = await getOneNotifications(id);
      this.setState({ notification: response.data });
    } catch (err) {
      console.log(err);
    }
  };

  reloadNotification = () => {
    const { match } = this.props;
    const id = match.params.notificationId ? match.params.notificationId : match.params.id;
    if (id) {
      this.getNotification(id);
    }
  };

  handleFav = async () => {
    const { notification } = this.state;
    try {
      await favNotifications(notification.id);
      this.reloadNotification();
    } catch (err) {
      console.log(err);
    }
  };

  handleRead = async () => {
    const { notification } = this.state;
    try {
      await readNotifications(notification.id);
      this.reloadNotification();
    } catch (err) {
      console.log(err);
    }
  };

  handleDelete = async () => {
    const { notification } = this.state;

    try {
      await deleteNotifications(notification.id);
      this.props.history.push('/notifications');
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { notification, modal } = this.state;
    const urlNotifications = `/centres/${notification.centreId}/notifications`
    return (
      <>
        <HeaderMain goBack={true} notifications={urlNotifications} title={'Detalle de la notificación'} />
        <MainLayoutSection>
          <NotificationsDetailStyled>
            <div className="notifications-detail-header">
              <div className="notifications-detail-header-top">
                <div className="notifications-detail-header-left">
                  <div className="notifications-detail-header-left__icon">
                    <img src={images.swimmerSvg} alt="" />
                  </div>
                  <div className="notifications-detail-header-left__title">
                    <HeadDestacadoText>{notification.title}</HeadDestacadoText>
                  </div>
                </div>

                <div className="notifications-detail-header-right">
                  <div className="notifications-detail-header-right__item">
                    <SingleButton
                      onClick={() => this.handleRead()}
                      icon={notification.read ? images.mailOpenBlueSvg : images.mailBlueSvg}
                    />
                  </div>
                  <div className="notifications-detail-header-right__item">
                    <SingleButton
                      onClick={() => this.handleFav()}
                      icon={notification.fav ? images.starFullRedSvg : images.starRedSvg}
                    />
                  </div>
                  <div className="notifications-detail-header-right__item">
                    <SingleButton
                      className="secondary"
                      icon={images.deleteSvg}
                      onClick={() => this.setState({ modal: 'modal-delete' })}
                    />
                  </div>
                </div>
              </div>
              <div className="notifications-detail-header-bottom">
                <TextDestacadoText>{getDateMonthString(notification.createdAt)}</TextDestacadoText>
              </div>
            </div>

            <div className="notifications-detail-text">
              <SubtitleText>{notification && notification.body && parser(notification.body)}</SubtitleText>
            </div>
            {/* <div className="notifications-detail-cards">
              <div className="notifications-detail-cards__row">
                <CardNotification subtitle={'Incidencias'} title={'Negocios'} cardObject={bussines} widthTotal={true} />
              </div>
              <div className="notifications-detail-cards__row">
                <CardNotification
                  subtitle={'Incidencias'}
                  title={'Puntos de inmersión'}
                  cardObject={diveSites}
                  widthTotal={true}
                />
              </div>
              <div className="notifications-detail-cards__row">
                <CardNotification subtitle={'Incidencias'} title={'Imágenes'} cardObject={pictures} widthTotal={true} />
              </div>
              <div className="notifications-detail-cards__row">
                <CardNotification
                  subtitle={'Incidencias'}
                  title={'Mal funcionamiento'}
                  cardObject={performance}
                  widthTotal={true}
                />
              </div>
              <div className="notifications-detail-cards__row">
                <CardNotification subtitle={'Registro'} title={'Nuevos centros '} cardObject={newCentres} />
              </div>
              <div className="notifications-detail-cards__row">
                <div className="notifications-detail-cards__row__item">
                  <CardNotification subtitle={'Registro'} title={'Usuarios'} cardObject={users} />
                </div>
                <div className="notifications-detail-cards__row__item">
                  <CardNotification subtitle={'Planificación'} title={'Actividades'} cardObject={activities} />
                </div>
                <div className="notifications-detail-cards__row__item">
                  <CardNotification subtitle={'Facturas'} title={'Facturado'} cardObject={billing} />
                </div>
              </div>
            </div> */}
          </NotificationsDetailStyled>
        </MainLayoutSection>
        {/* Modal Delete centre  */}
        <ModalDelete
          title="Vas a eliminar la notificación"
          subtitle="¿estás seguro?"
          showModal={modal === 'modal-delete'}
          onRemove={() => this.handleDelete()}
          onCancel={() => this.setState({ modal: '' })}
        />
      </>
    );
  }
}

export default withRouter(NotificationsDetail);
