import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';

import { RolesLayoutStyle } from './roles-style';
import { MainLayoutSection, MainLayoutTableContainer } from '../main-layout-style';

import { images } from '../../assets/images';

import MainTableLayout from '../../components/main-table/main-table';
import HeaderMain from '../../components/header-main/header-main';
import Pagination from '../../components/pagination';
import ModalDelete from '../../components/modals/modal-delete/modal-delete';
import Can from '../../roles/can-component';

import { ROLES_PER_PAGE } from '../../constants/data';

import { getUsers, deleteUsers } from '../../services/users';
import { TableRow, TableCell } from '../../types/table';
import ModalSuccess from '../../components/modals/modal-success/modal-success';
import services from '../../services/services';
import { ROLES_LABEL } from '../../constants/roles';
import { getDayMonthNumberYear } from '../../utils/time';
import ModalUserForm from './create-edit/modal-user-form';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

export interface IRolesLayoutProps extends RouteComponentProps, WithTranslation {
  agent?: any;
}

export interface IRolesLayoutState {
  modal: string;
  roles: any[];
  total: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  page: number;
  orderAscendent: string;
  editId?: string;
}



class Roles extends React.Component<IRolesLayoutProps, IRolesLayoutState> {

  readonly state: IRolesLayoutState;

  private tableColumns: any[];

  private rowKeys: string[];

  constructor(props: IRolesLayoutProps) {
    super(props);

    this.state = {
      modal: '',
      roles: [],
      total: 0,
      totalPages: 0,
      searchFilter: '',
      skipPage: 0,
      orderAscendent: '',
      page: 1,
    };

    this.tableColumns = [
      {
        text: this.props.t('tables:name'),
        filter: false,
        ascendent: true,
      },
      {
        text: this.props.t('tables:company'),
        filter: false,
        ascendent: true,
      },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
      {
        text: this.props.t('tables:role'),
        filter: false,
        ascendent: true,
      },
      {
        text: this.props.t('tables:create'),
        filter: false,
        ascendent: false,
      },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
      // TODO: DIV-3148
      // {
      //   text: '',
      //   filter: false,
      //   ascendent: true,
      // },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
    ];
    // TODO: DIV-3148
    // this.rowKeys = ['name', 'organizations', 'organizations_count', 'role', 'createdAt', 'profile', 'options'];
    this.rowKeys = ['name', 'organizations', 'organizations_count', 'role', 'createdAt', 'options'];
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers = async () => {
    const { skipPage, orderAscendent } = this.state;
    try {
      const params = {
        filter: {
          where: {
            roles: {
              values: [
                'superAdmin',
                'diver',
                'divingCenter_admin',
                'divingCenter_staff',
                'divingCenter_owner',
                'organization_admin',
                'organization_staff',
                'organization_owner',
                'certifier_admin',
                'certifier_staff',
                'certifier_owner'
              ],
              method: 'in',
              collection: 'model',
            },
          },
          skip: skipPage,
          limit: ROLES_PER_PAGE,
          orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
          relations: ['organizations'],
        },
      };
      const resp: any = await getUsers(params);
      const totalPages: number = Math.ceil(resp.data.count / ROLES_PER_PAGE);
      this.setState({ totalPages, roles: resp.data.data, total: resp.data.count });
    } catch (err) {
      this.setState({ roles: [] });
    }
  };

  deleteUsers = async (id: string) => {
    const { t } = this.props;
    try {
      await deleteUsers(id);
      services.pushNotification({
        title: t('push:delete-user.title'),
        text: t('push:delete-user.text'),
        type: 'red',
      });
      this.setState({ modal: '', editId: undefined }, () => this.getUsers());
    } catch (err) { }
  };

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = ROLES_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getUsers();
      });
    }
  }

  formatDataToTableRows(data: any[]) {
    const { t } = this.props;
    return data.map(item => {
      const row: TableRow = {};
      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };
        switch (key) {
          case 'name':
            cell.value =
              item['name'] || item['surname']
                ? `${item[key] ? item[key] : '-'} ${item['surname'] ? item['surname'] : ''}`
                : '-';
            cell.key = 'highlighted-body';
            break;
          case 'roles':
            cell.value = ROLES_LABEL[item[key]];
            cell.key = 'body';
            break;
          case 'organizations':
            cell.value = item.organizations && item.organizations.length > 0 ? item.organizations[0].name : '-';
            cell.key = 'body';
            break;
          case 'createdAt':
            cell.value = getDayMonthNumberYear(item[key]);
            cell.key = 'body';
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });

      if (item && item.organizations.length > 1) {
        row.organizations_count = {
          value: {
            title: 'Organizaciones',
            items: item['organizations'].map((d: any) => d.name),
          },
          type: '',
          key: 'count',
        };
      }

      // TODO: DIV-3148
      // row.profile = {
      //   value: t('buttons:profile').toString(),
      //   key: 'text-button',
      //   type: '',
      //   onClick: () => {},
      // };

      row.options = {
        value: item.id,
        type: '',
        key: 'options',
        actions: [
          {
            label: t('buttons:edit'),
            icon: images.edit3Svg,
            onClick: () => {
              this.setState({ modal: 'modal-create-user', editId: item.id });
            },
          },
          {
            label: t('buttons:delete'),
            icon: images.delete2Svg,
            onClick: () => {
              this.setState({ modal: 'modal-delete', editId: item.id });
            },
          },
        ],
      };

      return row;
    });
  }

  render() {
    const { modal, totalPages, roles, editId, page } = this.state;
    const { agent, t } = this.props;
    return (
      <>
        <HeaderMain
          title={t('layouts:roles.header')}
          withSearch={false}
          mainButton={{
            img: images.createWhiteSvg,
            text: t('buttons:create'),
            onClick: () => this.setState({ modal: 'modal-create-user' }),
          }}
        />
        <MainLayoutSection>
          <RolesLayoutStyle>
            {/* Una tabla por cada centor */}
            <div className="roles-table">
              {/* <div className="roles-table-head">
                <div className="roles-table-head__title">
                  <SubheadDestacadoText>Centro La marina</SubheadDestacadoText>
                </div>
                <div className="roles-table-head__button">
                  <MainButton
                    onClick={() => this.setState({ modal: 'modal-create-user' })}
                    iconImg={images.createWhiteSvg}
                    text={t('buttons:create')}
                    type="primary"
                  />
                </div>
              </div> */}
              <div className="roles-table-content">
                <MainLayoutTableContainer>
                  <Can roles={agent.roles} perform="roles:management">
                    <div className="diving-center-table">
                      <MainTableLayout
                        columns={this.tableColumns}
                        rows={this.formatDataToTableRows(roles)}
                        rowKeys={this.rowKeys}
                        onOrder={() => { }}
                      />
                    </div>
                    {totalPages > 1 && (
                      <div className="diving-center-pagination">
                        <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
                      </div>
                    )}
                  </Can>
                </MainLayoutTableContainer>
              </div>
            </div>
            {/*  */}
          </RolesLayoutStyle>
        </MainLayoutSection>
        {modal === 'modal-create-user' && (
          <ModalUserForm
            onCancel={() => this.setState({ modal: '', editId: undefined })}
            showModal={modal === 'modal-create-user'}
            onAccept={() => {
              this.setState({ modal: '', editId: undefined }, () => {
                this.getUsers();
              });
            }}
            userId={editId}
            editMode={!!editId}
          />
        )}

        {/* Modal Delete centre  */}
        <ModalDelete
          title={t('modalMessages:modal-delete-user.title')}
          subtitle={t('modalMessages:modal-delete-user.subtitle')}
          showModal={modal === 'modal-delete'}
          onRemove={() => {
            if (!!editId) {
              this.deleteUsers(editId);
            }
          }}
          onCancel={() => this.setState({ modal: '' })}
        />

        {/* Modal Success */}

        <ModalSuccess
          title={t('modalMessages:modal-create-user.title')}
          text={t('modalMessages:modal-create-user.subtitle')}
          showModal={modal === 'modal-success'}
          onClose={() => this.setState({ modal: '' })}
        />
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default withTranslation(['intro', 'buttons'])(connect<{}, {}, {}, IStoreState>(mapStateToProps)(Roles));
