import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const ModalCreateEquipmentCenterStyle = styled.div`
  padding: 0 32px 32px 32px;
  width: 416px;
  .create-user-form {
    &__title  {
      color: ${colors['SE-002/100']};
      margin-bottom: 14px;
      text-transform: uppercase;
    }
    &__content   {
    }
  }
  .create-user-buttons {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .create-user-role-container  {
    margin-bottom: 48px;
  }
`;
