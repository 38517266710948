import * as React from 'react';
import { CardDiveDetailStyle } from './card-dive-detail-style';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';
import TextText from '../text/text-text/text-text';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import LabelText from '../text/label-text/label';
import { images } from '../../assets/images';
import { getDateMonthString } from '../../utils/time';

export interface ICardDiveDetailProps {
  activity: any;
  t: any;
}

export interface ICardDiveDetailState {}

export default class CardDiveDetail extends React.Component<ICardDiveDetailProps, ICardDiveDetailState> {
  constructor(props: ICardDiveDetailProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { activity, t } = this.props;
    let img = images.avatar;
    if (activity.template && activity.template.pictures && activity.template.pictures.length > 0) {
      img = activity.template.pictures[0];
    }
    return (
      <CardDiveDetailStyle>
        <div className="card-dive-detail-img">
          <img src={img} alt="" />
        </div>

        <div className="card-dive-detail-content">
          {/* <div className="card-dive-detail-content-subtitle">
            <div className="card-dive-detail-content-subtitle__first">
              <LabelText>zona actuación</LabelText>
            </div>
            <div className="card-dive-detail-content-subtitle__second">
              <LabelText>país</LabelText>
            </div>
          </div> */}
          <div className="card-dive-detail-content__title">
            <SubheadDestacadoText>{activity.name}</SubheadDestacadoText>
          </div>
          <div className="card-dive-detail-content__center">
            <TextText>{ t('general.orgcons') }</TextText>
          </div>
          <div className="card-dive-detail-content__date">
            <TextText>{getDateMonthString(activity.date)}</TextText>
          </div>
          <div className="card-dive-detail-content__info">
            <div className="card-dive-detail-content__info__icon">
              <img src={images.userGreySvg} alt="" />
            </div>

            <TextDestacadoText>
              {activity.price && activity.price.amount ? `${activity.price.amount} ${activity.price.currency}` : '-'}
            </TextDestacadoText>
          </div>
        </div>
      </CardDiveDetailStyle>
    );
  }
}
