import * as React from 'react';
import ModalContainer from '../modal-container';
import { ModalRegistreDiveStyle } from './modal-registre-dive-style';
import LabelText from '../../text/label-text/label';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import MainButton from '../../main-button';
import { images } from '../../../assets/images';
import SingleButton from '../../single-button/single-button';
import { FormGrid, FormGridRow, FormGridItem } from '../../../containers/main-layout-style';
import InputBox from '../../input-box';
import BodyDestacadoText from '../../text/body-destacado-text/body-destacado-text';
import SelectBox from '../../select-box';
import CalendarBox from '../../calendar-box';
import AddItemFormSimple from '../../add-item-form-simple/add-item-form-simple';
import InputUploadFiles from '../../input-upload-file/input-upload-file';
import { DiveKinds, DiveTypes, DiveDisciplines } from '../../../constants/dive-dropdowns';
import { getDiveSites } from '../../../services/dive-sites';
import { IDiveForm } from '../../../models/dive-forms';
import moment from 'moment';
import 'moment/locale/es.js';
import { getDivers } from '../../../services/diver';
import { AttachedFile } from '../../../types/file';
import { IDropdown } from '../../../models/generic';
import { getUserUnit, transformToSI, transformToUser } from '../../../utils/measurement-units';
import { copyOf } from '../../../utils/other';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

export interface IModalRegistreDiveProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onSubmit: (dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) => void;
  onClose: () => void;
  dive?: any;
  activity?: any;
  selectedDiveIndex?: number;
  t: any
}

export interface IModalRegistreDiveState {
  step: number;
  isEditing: boolean;
  diveSites: {
    [key: string]: string;
  }[];
  diveSitesDepth?: {
    [key: string]: number;
  };
  divers: { [key: string]: string }[];
  errors: {
    [key: string]: string;
  };
  selectedDiver: {
    [key: string]: string;
  };
  mainPicture?: AttachedFile[];
  pictures?: AttachedFile[];
  searchLocationLocalFilter: string;
  dive: IDiveForm;
}

class ModalRegistreDive extends React.Component<IModalRegistreDiveProps, IModalRegistreDiveState> {
  constructor(props: IModalRegistreDiveProps) {
    super(props);
    this.state = {
      step: 1,
      isEditing: false,
      diveSites: [],
      divers: [],
      selectedDiver: {},
      dive: {
        type: '',
        date: '',
        kind: [],
        discipline: '',
        depth: NaN,
        duration: NaN,
        site: NaN,
        divers: [],
      },
      searchLocationLocalFilter: '',
      errors: {},
    };
  }

  componentDidMount() {
    const { dive, activity, selectedDiveIndex } = this.props;
    if (dive) {
      const formatDive = JSON.parse(JSON.stringify(dive));
      const divers = dive.divers.map((diver: any) => {
        return { value: diver.id.toString(), label: `${diver.user.name} ${diver.user.surname}` };
      });
      formatDive.site = dive.site.id;
      formatDive.divers = divers;
      formatDive.depth = transformToUser('depth', dive.depth);
      const diveDurationHours = dive.duration / 3600;
      const diveDurationMinutes = dive.duration / 60 - diveDurationHours * 60;
      const diveDurationSeconds = dive.duration - diveDurationHours * 3600 - diveDurationMinutes * 60;
      formatDive.duration = `${diveDurationHours}/${diveDurationMinutes}/${diveDurationSeconds}`;
      delete formatDive.pictures;
      this.setState({ divers, dive: formatDive, isEditing: true });
    }
    if (activity) {
      const divers = activity.bookings.map((booking: any) => {
        const diver = booking.diver;
        return { value: diver.id.toString(), label: `${diver.user.name} ${diver.user.surname}` };
      });
      let date = '';
      if (selectedDiveIndex && activity.divesInfo) {
        date = activity.divesInfo[selectedDiveIndex].start;
      } else if (selectedDiveIndex && activity.courseInfo) {
        date = activity.practicalPhase.blocks[selectedDiveIndex].start;
      }
      const formatDive: IDiveForm = {
        date,
        divers,
        type: activity.courseInfo ? 'training' : 'dive',
        kind: activity.kind ? activity.kind : activity.kind ? activity.template.kind : '',
        discipline: activity.discipline,
        depth: NaN,
        duration: NaN,
        site: NaN,
        activity: activity ? activity.id : NaN,
      };
      const diveSitesDepth: any = {};
      const diveSites = activity.sites.map((diveSite: any) => {
        diveSitesDepth[diveSite.id.toString()] = diveSite.maxDepth;
        return {
          value: diveSite.id.toString(),
          label: `${diveSite.name} (${diveSite.location})`,
          depth: transformToUser('depth', diveSite.maxDepth),
        };
      });
      this.setState({ diveSites, diveSitesDepth, dive: formatDive });
    } else {
      this.getDiveSites();
    }
  }

  validateField(field: string, value: string | number | string[]) {
    const errors = Object.assign(this.state.errors);
    let formError = false;
    if (!value || value === '' || (typeof value !== 'number' && value.length === 0)) {
      errors[field] = 'required';
      formError = true;
    } else {
      errors[field] = '';
    }
    switch (field) {
      case 'duration':
        if (typeof value === 'string') {
          const durationArray = value.split('/');
          errors[field] = durationArray.length !== 3 || durationArray[2] === '' ? 'invalid-format' : '';
        }
    }
    this.setState({ errors });
    return formError;
  }

  handleChange(field: string, value: string | number | string[]) {
    const { dive } = Object.assign(this.state);
    switch (field) {
      case 'date':
        const date = dive.date ? moment(dive.date) : moment();
        dive.date = moment(value, 'DD/MM/YYYY')
          .hours(date.hours())
          .toISOString();
        break;
      case 'hour':
        if (typeof value === 'string') {
          const date = dive.date === '' ? moment().toISOString() : dive.date;
          if (value.length === 5) {
            const [hours, minutes] = value.split(':');
            dive.date = moment(date)
              .hours(parseInt(hours, undefined))
              .minutes(parseInt(minutes, undefined))
              .toISOString();
          }
        }
        break;
      default:
        dive[field] = value;
    }
    this.validateField(field, value);
    return new Promise<void>((res, rej) => {
      this.setState({ dive }, () => {
        res();
      });
    });
  }

  async getDiveSites(searchFilter?: string) {
    const params = searchFilter
      ? {
        filter: {
          where: {
            state: { method: 'ilike', value: `%${searchFilter.toLowerCase()}%` },
          },
        },
      }
      : {};
    const resp: any = await getDiveSites(params, false);
    const diveSitesDepth: any = {};
    const diveSites = resp.data.data.map((diveSite: any) => {
      diveSitesDepth[diveSite.id.toString()] = diveSite.maxDepth;
      return {
        value: diveSite.id.toString(),
        label: `${diveSite.name} (${diveSite.location})`,
      };
    });
    this.setState({ diveSites, diveSitesDepth });
  }

  async getDivers(searchFilter: string) {
    const params = {
      filter: {
        where: {
          name: { method: 'ilike', value: `%${searchFilter.toLowerCase()}%`, collection: 'model' },
        },
        relations: ['user'],
      },
    };
    const resp: any = await getDivers(params, false);
    const divers = resp.data.data.map((diver: any) => {
      if (diver.user) {
        return {
          value: diver.id.toString(),
          label: `${diver.user.name} ${diver.user.surname}`,
        };
      }
    });
    this.setState({ divers });
  }

  onSubmit() {
    const { dive, mainPicture, pictures } = Object.assign(this.state);
    const { onSubmit } = this.props;
    const formattedDive = copyOf(dive);
    const durationArray = formattedDive.duration.split('/');
    formattedDive.divers = formattedDive.divers.map((diver: any) => {
      return { id: parseInt(diver.value, undefined) };
    });
    formattedDive.duration =
      parseInt(durationArray[0], undefined) * 3600 +
      parseInt(durationArray[1], undefined) * 60 +
      parseInt(durationArray[2], undefined);
    formattedDive.depth = Math.round(transformToSI('depth', formattedDive.depth));
    onSubmit(formattedDive, mainPicture, pictures);
    return true;
  }

  validateFirstStepFields() {
    const { dive } = Object.assign(this.state);
    let error = false;
    const firstStepKeys = ['type', 'date', 'kind', 'discipline', 'depth', 'duration', 'site'];
    firstStepKeys.forEach(key => {
      error = this.validateField(key, dive[key]) || error;
    });
    return error;
  }

  firstStep() {
    const { activity, t } = this.props;
    const { diveSites, errors, dive, searchLocationLocalFilter, diveSitesDepth } = this.state;
    return (
      <div className="modal-create-form">
        <div className="modal-create-form__content">
          <FormGrid>
            <FormGridRow>
              <FormGridItem type="col-0">
                <SelectBox
                  className="rounded"
                  labelText=""
                  placeholder={t('components:actions.choose')}
                  required={true}
                  disabled={!!activity && !!dive.type}
                  optionsText={DiveTypes}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={dive.type.toString()}
                  initialValue={dive.type.toString()}
                  icon={images.arrowDown2BlueSvg}
                  errorCode={errors.type}
                  onChange={value => this.handleChange('type', value)}
                />
              </FormGridItem>
            </FormGridRow>
            <FormGridRow>
              <FormGridItem type="col-0">
                <SelectBox
                  className="rounded"
                  labelText={t('components:global.discipline')}
                  placeholder={t('components:global.discipline')}
                  required={true}
                  disabled={!!activity && !!dive.discipline}
                  optionsText={DiveDisciplines}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={dive.discipline.toString()}
                  initialValue={dive.discipline.toString()}
                  icon={images.arrowDown2BlueSvg}
                  errorCode={errors.discipline}
                  onChange={value => this.handleChange('discipline', value)}
                />
              </FormGridItem>
            </FormGridRow>
            <FormGridRow>
              <FormGridItem type="col-0">
                <SelectBox
                  className="rounded"
                  labelText={t('components:global.immersion-type')}
                  placeholder={t('components:global.immersion-type')}
                  required={true}
                  multiple={true}
                  disabled={!!activity && !!dive.kind}
                  optionsText={DiveKinds}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultMultipleValues={dive.kind}
                  initialMultipleValues={dive.kind}
                  icon={images.arrowDown2BlueSvg}
                  errorCode={errors.kind}
                  onChangeMultiple={values => this.handleChange('kind', values)}
                  onChange={() => { }}
                />
              </FormGridItem>
            </FormGridRow>
            <FormGridRow>
              <FormGridItem type="col-2">
                <CalendarBox
                  placeholder={t('components:global.date')}
                  className="rounded"
                  labelText=""
                  initialValue={moment(dive.date).format('DD/MM/YYYY')}
                  errorCode={errors.date}
                  onChange={value => this.handleChange('date', value)}
                />
              </FormGridItem>
              <FormGridItem type="col-2">
                <InputBox
                  // errorCode="Error"
                  placeholder={t('components:global.hour')}
                  className="rounded"
                  type="text"
                  value={moment(dive.date).format('HH:mm')}
                  labelText="Usuario"
                  cleaveOptions={{
                    time: true,
                    timePattern: ['h', 'm'],
                  }}
                  errorCode={errors.date}
                  onBlur={value => this.handleChange('hour', value)}
                />
              </FormGridItem>
            </FormGridRow>
          </FormGrid>
        </div>
        <div className="modal-create-form__title">
          <BodyDestacadoText>{t('components:global.immersion-location')}</BodyDestacadoText>
        </div>
        <div className="modal-create-form__content">
          <FormGrid>
            <FormGridRow>
              <FormGridItem type="col-0">
                {/* input buscador */}
                <InputBox
                  // errorCode="Error"
                  placeholder={t('components:actions.search-by-location')}
                  className="rounded"
                  type="text"
                  value=""
                  labelText={t('components:actions.search-by-location')}
                  onBlur={val => {
                    if (activity) this.setState({ searchLocationLocalFilter: val });
                    else this.getDiveSites(val);
                  }}
                />
              </FormGridItem>
            </FormGridRow>
            <FormGridRow>
              <FormGridItem type="col-0">
                <SelectBox
                  className="rounded"
                  labelText={t('components:general.immersion-point')}
                  placeholder={t('components:general.immersion-point')}
                  required={true}
                  optionsText={
                    activity
                      ? diveSites.filter((diveSites: IDropdown) =>
                        diveSites.label.toLowerCase().includes(searchLocationLocalFilter.toLowerCase())
                      )
                      : diveSites
                  }
                  optionKey={'value'}
                  optionValue={'label'}
                  initialValue={dive.site.toString()}
                  icon={images.arrowDown2BlueSvg}
                  errorCode={errors.site}
                  onChange={value => {
                    const depth = diveSitesDepth ? diveSitesDepth[value] : NaN;
                    this.handleChange('depth', depth).then(() => {
                      this.handleChange('site', parseInt(value, undefined));
                    });
                  }}
                />
              </FormGridItem>
            </FormGridRow>

            <FormGridRow>
              <FormGridItem type="col-2">
                <InputBox
                  // errorCode="Error"
                  placeholder={`${t('components:general.deep')} (${getUserUnit('depth')})`}
                  className="rounded"
                  type="number"
                  value={dive.depth.toString()}
                  labelText={t('components:general.deep')}
                  errorCode={errors.depth}
                  onChange={value => this.handleChange('depth', parseInt(value, undefined))}
                />
              </FormGridItem>
              <FormGridItem type="col-2">
                <InputBox
                  // errorCode="Error"
                  placeholder={t('components:general.total-time')}
                  className="rounded"
                  type="text"
                  value={dive.duration.toString()}
                  labelText={t('components:general.total-time')}
                  errorCode={errors.duration}
                  onChange={value => this.handleChange('duration', value)}
                  cleaveOptions={{
                    delimiter: '/',
                    time: true,
                    timePattern: ['h', 'm', 's'],
                  }}
                />
              </FormGridItem>
            </FormGridRow>
          </FormGrid>
        </div>
      </div>
    );
  }

  addDiver() {
    const { selectedDiver, dive } = Object.assign(this.state);
    if (selectedDiver.value && selectedDiver.label) {
      if (dive.divers.filter((diver: any) => diver.value === selectedDiver.value).length === 0) {
        dive.divers.push(selectedDiver);
      }
    }
    this.setState({ dive, selectedDiver: {} });
  }

  removeDiver(value: string) {
    const { dive } = Object.assign(this.state);
    dive.divers = dive.divers.filter((diver: any) => diver.value !== value);
    this.setState({ dive });
  }

  secondStep() {
    const { activity, t } = this.props;
    const { divers, dive, selectedDiver } = this.state;
    return (
      <div className="modal-create-form">
        <div className="modal-create-form__title">
          <BodyDestacadoText>{t('modalMessages:divebuddy')}</BodyDestacadoText>
        </div>
        <div className="modal-create-form__content">
          <FormGrid>
            <FormGridRow>
              <FormGridItem type="col-1">
                {/* input buscador */}
                <SelectBox
                  placeholder={t('components:actions.search-by-diver')}
                  className="rounded"
                  searchable={!activity}
                  optionsText={divers.filter(diver => !!diver)}
                  optionKey={'value'}
                  optionValue={'label'}
                  labelText={t('components:actions.search-by-diver')}
                  clearValue={selectedDiver.value === undefined}
                  onSearch={value => this.getDivers(value)}
                  onChange={(value: string, label: string | undefined) => {
                    if (label) this.setState({ selectedDiver: { value, label } });
                  }}
                />
              </FormGridItem>
              <FormGridItem type="col-3">
                {/* input buscador */}
                <MainButton
                  iconImg={images.plusSvg}
                  type="secondary full-height"
                  text={t('components:actions.add')}
                  onClick={() => this.addDiver()}
                />
              </FormGridItem>
            </FormGridRow>

            <div className="modal-create-form-list">
              {dive.divers.map((diver: any, index) => {
                return (
                  <div key={diver.value} className="modal-create-form-list__item">
                    <AddItemFormSimple text={diver.label} onDelete={() => this.removeDiver(diver.value)} />
                  </div>
                );
              })}
            </div>
          </FormGrid>
        </div>
      </div>
    );
  }

  thirdStep() {
    const {t} = this.props;
    return (
      <div className="modal-create-form">
        <div className="modal-create-form__title">
          <BodyDestacadoText>{t('components:actions.add-images')}</BodyDestacadoText>
        </div>
        <div className="modal-create-form__content">
          <InputUploadFiles
            multiple={false}
            onChange={(file: AttachedFile[]) => this.setState({ mainPicture: file })}
          />
        </div>
        <div className="modal-create-form__content">
          <InputUploadFiles multiple={true} onChange={(files: AttachedFile[]) => this.setState({ pictures: files })} />
        </div>
      </div>
    );
  }

  renderSteps(step: number) {
    switch (step) {
      case 1:
        return this.firstStep();
      case 2:
        return this.secondStep();
      case 3:
        return this.thirdStep();
      default:
        break;
    }
  }

  handleNext() {
    const { step } = this.state;
    let error = false;
    if (step === 1) {
      error = this.validateFirstStepFields();
    }
    if (!error) this.setState({ step: step + 1 });
  }

  render() {
    const { onClose, showModal, t } = this.props;
    const { step, isEditing } = this.state;
    return (
      <ModalContainer className="" modalClose={() => onClose()} active={showModal}>
        <ModalRegistreDiveStyle>
          <div className="modal-create-title">
            <div className="modal-create-title__step">
              <LabelText>{t('components:general.step')} {step}</LabelText>
            </div>
            <div className="modal-create-title">
              <SubheadDestacadoText>{`${isEditing ? 'Editar' : 'Registrar'} inmersión`}</SubheadDestacadoText>
            </div>
          </div>

          {this.renderSteps(step)}

          <div className="modal-create-buttons">
            {step > 1 && (
              <div className="modal-create-buttons__back">
                <SingleButton
                  className="modal secondary"
                  icon={images.arrowLeftSvg}
                  onClick={() => this.setState({ step: step - 1 })}
                />
              </div>
            )}
            <div className="modal-create-buttons__item">
              <MainButton
                type="primary"
                onClick={() => {
                  step < 3 && this.handleNext();
                  step === 3 && this.onSubmit() && onClose();
                }}
                text={step < 3 ? t('components:actions.next') : isEditing ? t('components:actions.save') : t('components:actions.create')}
              />
            </div>
          </div>
        </ModalRegistreDiveStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalRegistreDive));