import * as React from 'react';
import { CreateActivitiesMinimunRequirementsStyle } from './create-activities-minimum-requirements-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../main-layout-style';
import SelectBox from '../../../../../components/select-box';
import InputBox from '../../../../../components/input-box';
import { images } from '../../../../../assets/images';
import { DiveMinimumRequisitesTypes, MIN_AGE, DiveMinimumRequisitesTypesKeyValues } from '../../../../../constants/dive-dropdowns';
import SingleButton from '../../../../../components/single-button/single-button';
import MainButton from '../../../../../components/main-button';
import { ILevels } from '../../../../../models/levels';
import { getLevels } from '../../../../../services/levels';
import { IDropdown } from '../../../../../models/generic';
import { IMinimumRequirements } from '../../../../../models/activity-template';
import { copyOf } from '../../../../../utils/other';
import { transformToUser, transformToSI } from '../../../../../utils/measurement-units';
import { getCourse, getCourseCentre, getCourses } from '../../../../../services/courses';

export interface ICreateActivitiesMinimunRequirementsProps {
  minimumRequisites?: IMinimumRequirements;
  type?: string;
  activity?: any;
  course?: number;
  centreId: number;
  discipline?: string;
  kind?: string;
  isCourse?: boolean;
  onPrev?: () => void;
  onNext: (minimumRequisites: any) => void;
}

export interface ICreateActivitiesMinimunRequirementsState {
  minimumRequisites: IMinimumRequirements;
  errors: { [key: string]: string };
  levels: IDropdown[];
}

const PLACEHOLDER: any = {
  diveCount: 'Número de inmersiones',
  medicalCertificate: 'Certificado médico',
  speciality: 'Especialidad',
  respdelaration: 'Declaración responsable',
  respdelarationCOVID: 'Declaración responsable COVID',
  divinginsurance: 'Seguro de buceo',
  other: 'Otro',
};

export default class CreateActivitiesMinimunRequirements extends React.Component<
  ICreateActivitiesMinimunRequirementsProps,
  ICreateActivitiesMinimunRequirementsState
  > {
  levels: number[] = [];
  constructor(props: ICreateActivitiesMinimunRequirementsProps) {

    super(props);

    this.state = {
      minimumRequisites: {
        age: '',
        level: '',
        requisites: [],
      },
      levels: [],
      errors: {
        age: '',
        level: '',
        depth: '',
        requisites: '',
      },
    };
  }

  componentDidMount() {
    const { course, minimumRequisites } = this.props;
    this.getLevels();
    if (minimumRequisites) {
      const formattedMinimumRequisites = copyOf(minimumRequisites);
      formattedMinimumRequisites.depth = transformToUser('depth', formattedMinimumRequisites.depth);

      this.setState({ minimumRequisites: formattedMinimumRequisites });
    }
  }

  componentDidUpdate(prevProps: ICreateActivitiesMinimunRequirementsProps) {
    const { minimumRequisites } = this.props;
    if (minimumRequisites && minimumRequisites !== prevProps.minimumRequisites) {
      const formattedMinimumRequisites = copyOf(minimumRequisites);
      formattedMinimumRequisites.depth = transformToUser('depth', formattedMinimumRequisites.depth);
      this.setState({ minimumRequisites: formattedMinimumRequisites });
    }
  }

  async getCourse() {
    const { course } = this.props;
    if (course) {
      const params = {
        filter: {
          relations: ['certifier'],
        },
      };
      const resp = await getCourse(course.toString(), params);
      const courseRequisites = resp.data.minimumRequisites;
      const minimumRequisites: any = {
        age: courseRequisites.age,
        level: courseRequisites.training,
        depth: NaN,
        requisites: [],
      };
      this.setState({ minimumRequisites });
    }
  }
  async getLevels() {
    // console.log("levels", this.props)
    let resp: any;
    let kind: string = '';
    let discipline: string = '';


    if (this.props.activity && this.props.activity.generalDataCourse) { // Course
      kind = this.props.activity.generalDataCourse.courseType;
      discipline = this.props.activity.generalDataCourse.discipline;
    } else if (this.props.activity && this.props.activity.generalData) {  // Inmersión
      discipline = this.props.activity.generalData.discipline;

    } else if (this.props.isCourse) {
      kind = this.props.kind ? this.props.kind : '';
      discipline = this.props.discipline ? this.props.discipline : '';
    } else {
      kind = '';
      discipline = this.props.discipline ? this.props.discipline : '';
    }

    const idCourse = this.props.activity && this.props.activity.generalDataCourse && this.props.activity.generalDataCourse.course ? this.props.activity.generalDataCourse.course : '';
    resp = await getCourseCentre(this.props.centreId, kind, discipline, idCourse);
    const levels = [];
    levels.push({ value: "-1", label: "Ninguna" });
    this.levels[-1] = 14;

    if (resp.data.length !== 0) {
      for (const l of resp.data) {
        levels.push({ value: l.id.toString(), label: l.name });
        this.levels[l.id] = !!l.minimumRequisites && !!l.minimumRequisites.age ? parseInt(l.minimumRequisites.age) : 16;

      }
    }

    this.setState({ levels });
    /*     const certs = [];
          for(const d of resp.data.data[0].certifiers){
            certs.push(d.id);
          }
        const params = {
          options: {loadRelationsIds: true},
            filter: {
              relations: ['certifier'],
               where: {
                'certifier.id': {
                  method: 'in',
                  values: [27]
                }
              },

            },
          };
          resp = await getCourses(params);
     */
  }
  async getLevelsOld() {
    const resp = await getLevels();
    const levels = resp.data.data
      .sort((a: any, b: any) => a.level - b.level)
      .map((level: ILevels) => {
        return { value: level.id.toString(), label: level.name };
      });
    //await this.getCourses();
    this.setState({ levels });
  }

  validate(field: string, value: string | number) {

    if(field == 'depth'){
      if (value < 0 || value === '') return 'required';
    }else{
      if (!value || value === '') return 'required';
    }

    return '';
  }

  handleChange(field: string, value: any) {

    const { minimumRequisites, errors } = Object.assign(this.state);
    if (field === 'level') {
      //value = value.toString();
      minimumRequisites["age"] = this.levels[value].toString();
    }
    minimumRequisites[field] = value;
    if (field !== 'requisites') {
      errors[field] = this.validate(field, value);
    }

    this.setState({ minimumRequisites, errors });
  }

  handleNext() {
    const { onNext, course } = this.props;
    const { minimumRequisites, errors } = Object.assign(this.state);
    Object.keys(errors).forEach(field => {
      if (!(field === 'depth' && course) && field !== 'requisites') {
        errors[field] = this.validate(field, minimumRequisites[field]);
      }
    });
    this.setState({ errors });
    if (Object.values(errors).every(err => err === '')) {
      if (minimumRequisites.depth) {
        minimumRequisites.depth = Math.round(transformToSI('depth', minimumRequisites.depth));
      } else {
        delete minimumRequisites.depth;
      }
      if (minimumRequisites.requisites.length === 0) delete minimumRequisites.requisite;

      onNext(minimumRequisites);
    }
  }

  render() {
    const { onPrev, course, minimumRequisites: minimumRequisitesProps } = this.props;
    const { errors, minimumRequisites, levels } = this.state;

    return (
      <CreateActivitiesMinimunRequirementsStyle>
        <FormGrid>
          <FormGridRow>

            <FormGridItem type="col-2">
              <SelectBox
                //disabled={!!minimumRequisitesProps}
                errorCode={errors.level}
                className="rounded-title"
                labelText="Titulación requerida"
                placeholder="Titulación requerida"
                required={true}
                initialValue={minimumRequisites.level.toString()}
                optionsText={levels}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={minimumRequisites.level.toString()}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.handleChange('level', parseInt(value, undefined))}
              />
            </FormGridItem>
            <FormGridItem type="col-2" className="min-age">
              <InputBox
                errorCode={errors.age}
                placeholder="Edad mínima"
                className="rounded-title"
                type="number"
                required={true}
                value={minimumRequisites.age}
                labelText="Edad mínima"
                onChange={value => this.handleChange('age', value)}
              />
              {/*               <SelectBox
                //disabled={!!minimumRequisitesProps}
                errorCode={errors.age}
                className="rounded-title"
                labelText="Edad mínima"
                required={true}
                placeholder="Edad mínima"
                initialValue={minimumRequisites.age}
                optionsText={MIN_AGE}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={minimumRequisites.age}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.handleChange('age', value)}
              />
 */}            </FormGridItem>
          </FormGridRow>
          {/* {!course && (
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                errorCode={errors.data}
                placeholder="Número de inmersiones"
                className="rounded"
                type="text"
                value={minimumRequisites.data}
                labelText=""
                onChange={value => this.handleChange('data', value)}
              />
            </FormGridItem>
          </FormGridRow>
          )} */}
          {!course && (
            <FormGridRow>
              <FormGridItem type="col-0">
                <InputBox
                  errorCode={errors.depth}
                  placeholder="Profundidad maxima"
                  className="rounded-title"
                  type="number"
                  required={true}
                  value={minimumRequisites.depth ? minimumRequisites.depth.toString() : ''}
                  labelText="Profundidad hasta la que debe estar habilitado para bucear"
                  onChange={value => this.handleChange('depth', parseInt(value, undefined))}
                />
              </FormGridItem>
            </FormGridRow>
          )}
          <FormGridRow>
            <FormGridItem type="col-0">
              <SelectBox
                errorCode={errors.requisites}
                className="rounded-title"
                labelText="Otros requisitos"
                placeholder="Otros requisitos"
                required={true}
                initialMultipleValues={minimumRequisites.requisites ? minimumRequisites.requisites.map((r: any) => r.requisite) : []}
                optionsText={DiveMinimumRequisitesTypes}
                optionKey={'value'}
                optionValue={'label'}
                defaultMultipleValues={minimumRequisites.requisites ? minimumRequisites.requisites.map((r: any) => r.requisite) : []}
                icon={images.arrowDown2BlueSvg}
                onChangeMultiple={values => {
                  const value = values.map((v: string) => {
                    const requisite = minimumRequisites.requisites ? minimumRequisites.requisites.find((r: any) => r.requisite === v) : '';
                    if (requisite) {
                      return { ...requisite };
                    }
                    return { data: '', requisite: v };
                  });

                  this.handleChange('requisites', value);
                }}
                multiple={true}
                onChange={() => { }}
              />
            </FormGridItem>
          </FormGridRow>
          {minimumRequisites.requisites && (minimumRequisites.requisites.map((item: any, index: number) => (
            <FormGridRow key={`requisites-${index}`}>
              <FormGridItem type="col-0">
                <InputBox
                  errorCode={errors.data}
                  required={true}
                  placeholder={DiveMinimumRequisitesTypesKeyValues[item.requisite]}
                  className="rounded-title"
                  type="text"
                  value={item.data}
                  labelText={DiveMinimumRequisitesTypesKeyValues[item.requisite]}
                  onChange={value => {
                    const requisites = minimumRequisites.requisites;
                    if (requisites) requisites[index].data = value;
                    this.handleChange('requisites', requisites);
                  }}
                />
              </FormGridItem>
            </FormGridRow>
          )))}
        </FormGrid>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={onPrev ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesMinimunRequirementsStyle>
    );
  }
}
