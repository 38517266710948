import styled from 'styled-components';

export const ModalCookiesStyle = styled.div`
  width: 380px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 32px;
  z-index: 10;
  color: #fff;

  background: #001930;

  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out all;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    bottom: 0;
    left: 0;
    position: fixed;
    background: #001930;
  }

  /* &:after{
    content: '';
    width: 100%;
    height: 100%;
    background: inherit; 
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 200rem rgba(200, 200, 200, 0.2);
    filter: blur(10px);

    @media only screen and (max-width: 600px) {
     display: none;
    }

   } */

  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .paragraph {
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-bottom: 20px;
    font-weight: 300;
    a {
      font-weight: 500;
      cursor: pointer;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .accept {
    display: flex;
    justify-content: flex-end;
    &__button {
      margin-left: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      text-transform: uppercase;
      color: #ffffff;
      cursor: pointer;
      position: relative;
      z-index: 101;
      padding: 8px 12px;
      border-bottom: 1px solid white;
    }
  }
`;
