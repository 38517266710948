import React, { Component } from 'react';
import { HCStyle } from './help-centre-style';
import { MainLayoutSection } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import { APP_WEB_URL } from '../../../env';

class HELPCENTRE extends Component {
  render() {
    return (
      <>
        <HeaderMain title={'Bienvenid@ al Centro de Ayuda de Diveasapp 💙'} />
        <MainLayoutSection>
          <HCStyle>
            <div className="helpcentrer-section">
              <div className="helpcentrer-section__title">
                <SubheadDestacadoText>Un espacio para ti en el que podrás aprender a utilizar todas las funcionalidades de la plataforma, resolver dudas y contactar con el equipo de Diveasapp para recibir apoyo personalizado. </SubheadDestacadoText>
                <SubheadDestacadoText>Date de alta en el Centro de Ayuda para acceder a tu área personal haciendo click <a href="https://help.diveasapp.es/index.php/darse-de-alta-centro-de-ayuda-diveasapp/" target="_blank">aquí</a>.</SubheadDestacadoText>                  
                <SubheadDestacadoText>Si ya te has dado de alta accede desde <a href="https://help.diveasapp.es/index.php/membership-login/" target="_blank">aquí</a>.</SubheadDestacadoText>
              </div>
            </div>
            <div className="helpcentrer-section">
              <div className="helpcentrer-section__title">
                <ul className="helpcentrer-ul">
                  <li>
                  <div className="helpcentrer-ul__title">Incidencias</div>
                  
                  <div className="helpcentrer-ul__img"><img src="/incidencias_img.png"></img></div>
                  
                  <SubheadDestacadoText>Si ya estás dado/a de alta en el Centro de Ayuda, consulta nuestras Preguntas Frecuentes <a href="https://help.diveasapp.es/index.php/membership-login/" target="_blank">aquí</a></SubheadDestacadoText>
                  <SubheadDestacadoText>Si no encuentras en ellas la solución adecuada, descríbenos tu caso en el <a href="https://help.diveasapp.es/index.php/membership-login/" target="_blank">Formulario de INCIDENCIAS</a> que encontrarás al acceder a tu área personal. Puedes incluir una imagen o documento.</SubheadDestacadoText>
                  <SubheadDestacadoText>Te responderemos asap.</SubheadDestacadoText>


                  </li>
                  <li>
                  <div className="helpcentrer-ul__title">Sugerencias</div>
                  <div className="helpcentrer-ul__img"><img src="/sugerencias_img.png"></img></div>
                   
                  <SubheadDestacadoText>Ayúdanos a mejorar.</SubheadDestacadoText>
                  <SubheadDestacadoText>¿Echas en falta alguna funcionalidad en la aplicación, en el buscador, en tu web de centro? </SubheadDestacadoText>
                  <SubheadDestacadoText>¿Hay alguna persona, colectivo, empresa u organización que deberíamos conocer?</SubheadDestacadoText>
                  <SubheadDestacadoText>Si ya estás dado/a de alta en el Centro de Ayuda, envíanos tu sugerencia rellenando el <a href="https://help.diveasapp.es/index.php/membership-login/" target="_blank">Formulario de SUGERENCIAS</a> que encontrarás al acceder a tu área personal.</SubheadDestacadoText>
                  <SubheadDestacadoText>La estudiaremos y trataremos de implementarla. </SubheadDestacadoText>
                  <SubheadDestacadoText>¡Gracias!</SubheadDestacadoText>

                  </li>
                </ul>
                <SubheadDestacadoText>Si tienes dudas o problemas con el Centro de Ayuda, contáctanos en <a href="mailto:support@diveasapp.com">support@diveasapp.com</a> </SubheadDestacadoText>

 
              </div>
            </div>
          </HCStyle>
        </MainLayoutSection>
      </>
    );
  }
}

export default HELPCENTRE;
