import * as React from 'react';
import { ItemFeatureCenterStyle } from './item-feature-center-style';
import { images } from '../../assets/images';
import TextText from '../text/text-text/text-text';
export interface IItemFeatureCenterProps {
  text: string;
  image: string;
  onClick: Function;
}

export interface IItemFeatureCenterState {}

export default class ItemFeatureCenter extends React.Component<IItemFeatureCenterProps, IItemFeatureCenterState> {
  constructor(props: IItemFeatureCenterProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { text, onClick, image } = this.props;
    return (
      <ItemFeatureCenterStyle>
        <div className="item-feautres-center-container">
          <div className="item-feautres-center-container__icon">
            <img src={image} alt="" />
          </div>
          <div className="item-feautres-center-container__text">
            <TextText>{text}</TextText>
          </div>
        </div>
        <div onClick={() => onClick()} className="item-feautres-center-container-delete">
          <img src={images.closeBlueSvg} alt="" />
        </div>
      </ItemFeatureCenterStyle>
    );
  }
}
