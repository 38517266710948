import React, { Component } from 'react';
import { ConservationAction, ConservationActionExtended } from '../../../../models/conservation-action';
import {
  patchUpdateConservationAction,
  patchUpdateConservationActionImage,
  postCreateConservationAction,
} from '../../../../services/conservation-actions';
import { AttachedFile } from '../../../../types/file';
import { LANGUAGES, LANGUAGES_KEY_VALUES } from '../../../../constants/languages';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import { FormGrid, FormGridItem, FormGridRow } from '../../../main-layout-style';
import MainButton from '../../../../components/main-button';
import ModalContainer from '../../../../components/modals/modal-container';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import InputBox from '../../../../components/input-box';
import { images } from '../../../../assets/images';
import CalendarBox from '../../../../components/calendar-box';
import { ModalCreateProjectStyle } from './modal-create-project-style';
import { isBefore, format, parse } from 'date-fns';
import SelectBox from '../../../../components/select-box';
import { ALL_COUNTRIES, COUNTRIES_KEY_LABEL, COUNTRIES } from '../../../../constants/countries';
import InputArea from '../../../../components/input-area/input-area';
import InputUploadFiles from '../../../../components/input-upload-file/input-upload-file';
import { copyOf } from '../../../../utils/other';
import { getDataFromAddress } from '../../../../services/google-maps';
import { getAdministrativeLevel1, getCountry } from '../../../../utils/maps';
import { IDropdown } from '../../../../models/generic';
import DicotomicCheckbox from '../../../../components/dicotomic-checkbox';
import InfoText from '../../../../components/text/info-text/info-text';
import moment from 'moment';

interface ModalCreateProjectProps {
  showModal: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  selectedLanguage: string;
  certifierId: string;
  project?: ConservationActionExtended;
}

interface ModalCreateProjectState {
  step: number;
  selectedLanguage: string;
  states: IDropdown[];
  project: ConservationAction;
  errors: { [key: string]: string };
}

class ModalCreateProject extends Component<ModalCreateProjectProps, ModalCreateProjectState> {
  state: ModalCreateProjectState = {
    step: 1,
    selectedLanguage: 'es',
    project: {
      title: '',
      description: {},
      date: '',
      country: '',
      province: '',
      zone: '',
      file: null,
      organization: '0',
      donations: null,
      active: false,
    },
    states: [],
    errors: {},
  };

  componentDidMount(): void {
    const { project } = this.props;

    if (project) {
      if (!project.description) project.description = {};
      this.setState({
        project: {
          ...project,
          date: project.date ? moment(project.date).format('DD/MM/YYYY') : '',
        },
      });
    }
  }

  async handleSubmit() {
    const { onSuccess, certifierId, project: projectToEdit } = this.props;
    const { project } = this.state;
    const data: any = {
      ...project,
      organization: certifierId,
    };

    const date = (typeof project.date == 'string' ? project.date : project.date.toLocaleString()).split('/');
    if (date.length === 3) {
      const dateISO = new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0])).toISOString();
      data.date = dateISO;
    } else {
      data.date = null;
    }

    let response;

    if (projectToEdit) {
      response = await patchUpdateConservationAction(certifierId, projectToEdit.id.toString(), {
        ...data,
      });
    } else {
      response = await postCreateConservationAction(certifierId, {
        ...data,
      });
    }

    if (project.file) {
      const projectImage = new FormData();
      projectImage.append('file', (project.file as any).data);
      const newProject = response.data;

      await patchUpdateConservationActionImage(certifierId, newProject.id.toString(), projectImage);
    }

    onSuccess();
  }

  hasErrors(errors: { [key: string]: string }, fields: string[]) {
    return !fields.every(field => !errors[field]);
  }

  validate(value: string | AttachedFile | number | boolean, field: string, required: boolean) {
    if (field !== 'date' && required && value !== false && !value) return 'required';

    if (field === 'date' && value && isBefore(parse(value as string, 'dd/MM/yyyy', new Date()), new Date())) {
      return 'invalid-date-future';
    }
  }

  handleChange(
    value: string | AttachedFile | number | boolean,
    field: string,
    required: boolean = true,
    subField?: string
  ) {
    const { errors, project } = Object.assign(this.state);
    const error = this.validate(value, field, required);
    if (subField) {
      project[subField][field] = value;
      errors[`${subField}.${field}`] = error;
    } else {
      project[field] = value;
      errors[field] = error;
    }
    return new Promise<void>((res, rej) => {
      this.setState({ project, errors }, () => res());
    });
  }

  validateFirstStep() {
    const { errors, project } = Object.assign(this.state);
    const requiredFields = ['title', 'zone', 'country', 'province'];

    for (const field of requiredFields) {
      errors[field] = this.validate(project[field], field, true);
    }

    this.setState({ errors });
    return !this.hasErrors(errors, requiredFields);
  }

  validateSecondStep() {
    const { errors, project } = Object.assign(this.state);
    const requiredFields: string[] = [];

    Object.keys(LANGUAGES_KEY_VALUES).forEach(lang => {
      errors[`description.${lang}`] = this.validate(project.description[lang], 'description', true);

      requiredFields.push(`description.${lang}`);
    });

    this.setState({ errors });
    return !this.hasErrors(errors, requiredFields);
  }

  validateThirdStep() {
    const { errors, project } = Object.assign(this.state);
    const requiredFields = ['file'];

    errors['file'] = this.validate(project['file'], 'file', false);

    this.setState({ errors });
    return !this.hasErrors(errors, requiredFields);
  }

  nextStep() {
    const { step } = this.state;

    switch (step) {
      case 1:
        if (this.validateFirstStep()) {
          this.setState(prevState => ({ step: prevState.step + 1 }));
        }
        break;

      case 2:
        if (this.validateSecondStep()) {
          this.setState(prevState => ({ step: prevState.step + 1 }));
        }
        break;

      case 3:
        if (this.validateThirdStep()) this.handleSubmit();
    }
  }

  previousStep() {
    if (this.state.step > 1) {
      this.setState(prevState => ({ step: prevState.step - 1 }));
    }
  }

  searchTimeout: any;
  handleSearchState(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (value && value !== '') this.searchAddress(value);
    }, 300);
  }

  async searchAddress(value: string) {
    const resp: any = await getDataFromAddress(value);
    const results = resp.data.results;
    let states: any[] = results.map((result: any) => {
      const level1 = getAdministrativeLevel1(result);
      const country = getCountry(result);
      return {
        level1: level1.longName,
        country: country.shortName,
      };
    });
    states = Array.from(new Set(states)).map(state => ({
      country: state.country,
      value: state.level1,
      label: state.level1,
    }));
    this.setState({ states });
  }

  general() {
    const { project, errors, states } = this.state;

    return (
      <div>
        <div className="modal-certifier-form__title">
          <BodyDestacadoText>Datos generales</BodyDestacadoText>
        </div>
        <FormGrid>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                placeholder="Escriba"
                type="text"
                className="rounded-title"
                value={project.title}
                labelText="Nombre del Proyecto"
                required={true}
                errorCode={errors.title}
                icon={images.infoSvg}
                withTooltip={true}
                isTooltipInside={true}
                tooltipTitle=""
                tooltipText="El nombre debe describir el objetivo del proyecto"
                onChange={value => this.handleChange(value, 'title')}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <CalendarBox
                disableWrite={true}
                initialValue={project.date as string}
                labelText="Fecha"
                onChange={value => this.handleChange(value, 'date')}
                placeholder="Indique"
                forbidPastDates={true}
                className="rounded-title"
                errorCode={errors.date}
                adviceText={'Si no lo indica, será permanente en el tiempo'}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                placeholder="Escriba"
                type="text"
                required={true}
                className="rounded-title"
                value={project.zone}
                labelText="Zona de actuación"
                errorCode={errors.zone}
                onChange={value => this.handleChange(value, 'zone')}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <SelectBox
                labelText="Provincia"
                placeholder="Escriba"
                className="rounded-title"
                optionsText={states}
                optionKey={'value'}
                optionValue={'label'}
                onSearch={value => this.handleSearchState(value)}
                required={true}
                errorCode={errors.province}
                initialValue={project.province}
                defaultValue={project.province}
                initialValueFilterText={project.province}
                defaultValueFilterText={project.province}
                icon={images.arrowDown2BlueSvg}
                onChange={async value => {
                  const country = states.filter(state => state.value === value)[0].country;
                  await this.handleChange(value, 'province');
                  await this.handleChange(country, 'country');
                }}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <SelectBox
                labelText="País"
                disabled={true}
                className="rounded-title"
                optionKey={'value'}
                required={true}
                optionValue={'label'}
                optionsText={COUNTRIES}
                initialValue={project.country}
                defaultValue={project.country}
                placeholder=""
                errorCode={errors.country}
                onChange={value => this.handleChange(value, 'country')}
              />
            </FormGridItem>
          </FormGridRow>
        </FormGrid>

        <div className="item-modal-message-container">
          <div className="item-modal-message-bottom">
            <div className="item-modal-message-button">
              <MainButton
                type={'primary'}
                text="Siguiente"
                onClick={() => {
                  this.nextStep();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  description() {
    const { project, selectedLanguage, errors } = this.state;

    return (
      <div>
        <div className="modal-certifier-form__title">
          <BodyDestacadoText>Descripción</BodyDestacadoText>
        </div>

        <div className="modal-certifier-form__languages">
          {LANGUAGES.map((k: any, index: number) => (
            <div className={'modal-certifier-form__languages__item'} key={index}>
              <MainButton
                onClick={() => this.setState({ selectedLanguage: k.value })}
                text={k.label}
                type={
                  selectedLanguage === k.value
                    ? 'primary'
                    : errors[`description.${k.value}`] === '' || !errors[`description.${k.value}`]
                    ? 'secondary'
                    : 'secondary-red'
                }
              />
            </div>
          ))}
        </div>

        <FormGrid>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputArea
                className="rounded"
                name={selectedLanguage}
                placeholder={`Descripción ${LANGUAGES_KEY_VALUES[selectedLanguage]}`}
                required={false}
                errorCode={''}
                value={
                  project.description && project.description[selectedLanguage]
                    ? project.description[selectedLanguage]
                    : ''
                }
                onChange={(ev: any) => this.handleChange(ev.target.value, selectedLanguage, true, 'description')}
              />
            </FormGridItem>
          </FormGridRow>
        </FormGrid>

        <div className="item-modal-message-container">
          <div className="item-modal-message-bottom">
            <div className="item-create-message-button">
              <MainButton
                type={'secondary'}
                iconImg={images.arrowLeftSvg}
                text=""
                onClick={() => {
                  this.previousStep();
                }}
              />
            </div>
            <div className="item-modal-message-button">
              <MainButton
                type={'primary'}
                text="Siguiente"
                onClick={() => {
                  this.nextStep();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  logo = () => {
    const { project, errors } = this.state;

    return (
      <div>
        <div className="modal-certifier-form__title">
          <BodyDestacadoText>Logotipo</BodyDestacadoText>
        </div>

        <div className="item-modal-message-container">
          <InputUploadFiles
            className="dark-blue-label"
            label="Logotipo"
            multiple={false}
            errorCode={errors.file}
            required={false}
            value={typeof project.file === 'string' ? project.file : undefined}
            selectedFiles={project.file && typeof project.file !== 'string' ? [project.file] : undefined}
            onChange={(file: AttachedFile[]) => {
              this.handleChange(file[0], 'file', false);
            }}
          />

          <div className="item-modal-message-bottom">
            <div className="item-create-message-button">
              <MainButton
                type={'secondary'}
                iconImg={images.arrowLeftSvg}
                text=""
                onClick={() => {
                  this.previousStep();
                }}
              />
            </div>
            <div className="item-modal-message-button">
              <MainButton
                type={'primary'}
                text="Guardar"
                onClick={() => {
                  this.nextStep();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderContent() {
    switch (this.state.step) {
      case 1:
        return this.general();

      case 2:
        return this.description();

      case 3:
        return this.logo();

      default:
        break;
    }
  }

  render() {
    const { showModal, onCancel, project } = this.props;
    const { step } = this.state;
    const edit = !!project;

    return (
      <ModalContainer title="" active={showModal} modalClose={onCancel}>
        <ModalCreateProjectStyle>
          <div className="modal-create-project-step">Paso {step}</div>
          <div className="modal-create-project-title">
            <SubheadDestacadoText>
              {edit ? 'Editar proyecto de conservación' : 'Crear proyecto de conservación'}
            </SubheadDestacadoText>
          </div>
          {this.renderContent()}
        </ModalCreateProjectStyle>
      </ModalContainer>
    );
  }
}

export default ModalCreateProject;
