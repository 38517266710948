import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ItemUserStyle = styled.div`
  display: flex;
  .item-user-avatar {
    border-radius: 100%;
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    margin-right: 16px;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .item-user-info {
    &__subtitle  {
      color: ${colors['NE-002/100']};
    }
    &__name {
      color: ${colors['NE-001/100']};
      margin-bottom: 2px;
    }
    &__info {
      color: ${colors['NE-001/50']};
    }
  }
`;
