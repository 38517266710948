import React from 'react';
import { ModalRejectStaffContainer } from './modal-reject-staff-style';
import { images } from '../../../assets/images';
import SubheadText from '../../text/subhead-text/subhead-text';
import MainButton from '../../main-button';
import ModalContainer from '../modal-container';
import InputBox from '../../input-box';
import InputArea from '../../input-area/input-area';
import DicotomicCheckbox from '../../dicotomic-checkbox';
import InfoText from '../../text/info-text/info-text';
import { patchRejectStaff } from '../../../services/activities';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

interface ModalProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  selectedStaff?: any;
  activity: any;
  centreId: string;
  onSuccess: () => void;
  t: any
}

interface ModalState {
  type: string; // canceledActivity | minDivers | admissionRight | other
  reason?: string;
  message?: string;
}

class ModalRejectStaff extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      type: 'canceledActivity',
    };
  }

  async handleReject() {
    const { selectedStaff, centreId, activity, onSuccess } = this.props;
    const { type, reason, message } = this.state;
    if (selectedStaff) {
      const data: any = {
        type,
        reason,
        message,
        staff: [selectedStaff.staff.id],
      };
      await patchRejectStaff(centreId, activity.id.toString(), data);
      onSuccess();
    }
  }

  render() {
    const { showModal, onCancel, activity, t } = this.props;
    const { type, message } = this.state;
    return (
      <ModalContainer modalClose={() => onCancel()} active={showModal}>
        <ModalRejectStaffContainer>
          <div className="modal-reject-staff-activity">
            <p>{activity.name}</p>
          </div>
          <div className="modal-reject-staff-title">
            <p>{t('modalMessages:modal-reject-staff.title')}</p>
          </div>
          <div className="modal-reject-staff-subtitle">
            <p>
            {t('modalMessages:modal-reject-staff.reject-reasons')}
            </p>
          </div>
          <div className="modal-reject-staff-checkbox-container">
            <div className="modal-reject-staff-checkbox">
              <DicotomicCheckbox
                className="rounded"
                checked={type === 'canceledActivity'}
                onCheck={() => this.setState({ type: 'canceledActivity' })}
              >
                <InfoText>{t('modalMessages:modal-reject-staff.activity-cancelled')}</InfoText>
              </DicotomicCheckbox>
            </div>
            <div className="modal-reject-staff-checkbox">
              <DicotomicCheckbox
                className="rounded"
                checked={type === 'minDivers'}
                onCheck={() => this.setState({ type: 'minDivers' })}
              >
                <InfoText>{t('modalMessages:modal-reject-staff.divers-min-number-changed')}</InfoText>
              </DicotomicCheckbox>
            </div>
            <div className="modal-reject-staff-checkbox">
              <DicotomicCheckbox
                className="rounded"
                checked={type === 'admissionRight'}
                onCheck={() => this.setState({ type: 'admissionRight' })}
              >
                <InfoText>{t('modalMessages:modal-reject-staff.admission-rights')}</InfoText>
              </DicotomicCheckbox>
            </div>
            <div className="modal-reject-staff-checkbox">
              <DicotomicCheckbox
                className="rounded"
                checked={type === 'other'}
                onCheck={() => this.setState({ type: 'other' })}
              >
                <InfoText>{t('components:general.others')}</InfoText>
              </DicotomicCheckbox>
            </div>
          </div>
          {type === 'other' && (
            <div className="modal-reject-staff-input">
              <InputBox
                value=""
                labelText=""
                className="rounded"
                placeholder={t('components:actions.write-reasons')}
                onChange={(value: string) => this.setState({ reason: value })}
              />
            </div>
          )}
          <div className="modal-reject-staff-message-title">
            <p>{t('components:global.message')} ({t('components:global.optional')})</p>
          </div>
          <div className="modal-reject-staff-message-input">
            <InputArea
              value={message || ''}
              name=""
              required={true}
              placeholder=""
              onChange={(ev: any) => this.setState({ message: ev.target.value })}
            />
          </div>

          <div className="modal-reject-staff-send-button">
            <MainButton text={t('components:actions.reject')} type="primary" onClick={() => this.handleReject()} />
          </div>
        </ModalRejectStaffContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalRejectStaff));