import * as React from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { images } from '../../../assets/images';
import { DiveDisciplines } from '../../../constants/dive-dropdowns';
import { CERTIFIER_COURSES_PER_PAGE } from '../../../constants/data';
import SelectBox from '../../../components/select-box';
import MainButton from '../../../components/main-button';
import { getCertifierDivers, getCollaboratorCourses } from '../../../services/collaborator';
import { copyOf, mergeObject, createFilterRequest } from '../../../utils/other';
import moment, { Moment } from 'moment';
import { IFilter } from '../../../models/filter';
import { IDropdown } from '../../../models/generic';
import ModalInviteDivers from '../certifier-divers/modal-invite-divers/modal-invite-divers';

interface RouteParams {
  id: string;
}

interface IOrganizationDonationsProps extends RouteComponentProps<RouteParams>, WithTranslation { }

interface IOrganizationDonationsState {
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
  activeFilters: boolean;
  modal: string;
  searchValue: string;
  selectedCourse: number;
  divers: any[];
  certifierCourses: IDropdown[];
}

class OrganizationDonations extends React.Component<IOrganizationDonationsProps, IOrganizationDonationsState> {
  constructor(props: IOrganizationDonationsProps) {
    super(props);
    this.state = {
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
      activeFilters: false,
      modal: '',
      searchValue: '',
      divers: [],
      selectedCourse: NaN,
      certifierCourses: [],
    };
  }

  componentDidMount() {
    this.getOrganizationDonations();
  }

  async getOrganizationDonations() {
    const { searchValue, selectedCourse } = this.state;

    const filters: { [key: string]: IFilter } = {
      name: {
        value: searchValue,
        collection: 'user',
        concat: ['surname'],
      },
      id: {
        value: selectedCourse,
        collection: 'course',
        arraySearch: true,
      },
    };

    const params = {
      filter: {
        where: createFilterRequest(filters),
        relations: ['user', 'donations', 'donations.action', 'donations.charge'],
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
      },
    };
    const certifierId = localStorage.getItem('organizationId') || '';
    const resp = await getCertifierDivers(certifierId, params);
    const divers = resp.data.data;
    this.setState({ divers }, () => this.getCertifierCourses());
  }

  async getCertifierCourses() {
    const certifierId: string | null = localStorage.getItem('organizationId');
    if (certifierId) {
      const resp = await getCollaboratorCourses(certifierId);
      const certifierCourses: any[] = resp.data.data.map((course: any) => ({
        value: course.id.toString(),
        label: course.name,
      }));
      this.setState({ certifierCourses });
    }
  }

  getTableRows() {
    const { divers } = this.state;
    const tableRows: any[] = [];
    divers.forEach(diver => {
      diver.donations.forEach((donation: any) => {
        const tableRow = copyOf(this.tableRowTemplate);
        let currency = 'EUR';
        if (donation.charge && donation.charge.stripeCharge) {
          currency = donation.charge.stripeCharge.currency;
        }
        tableRow.name.value = `${diver.user.name} ${diver.user.surname}`;
        tableRow.action.value = donation.action ? donation.action.title : '';
        tableRow.amount.value = `${donation.amount / 100} ${currency}`;
        tableRow.date.value = donation.action ? moment(donation.createdAt).format('DD/MM/YYYY') : '';
        tableRow.detail.link = `divers/${diver.id}`;
        tableRows.push(tableRow);
      });
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CERTIFIER_COURSES_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ skipPage: skip, page }, () => {
        this.getOrganizationDonations();
      });
    }
  }

  timeoutSearch: any;
  handleSearch(value: string | null) {
    const searchValue = value || '';
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      this.setState({ searchValue, page: 1, skipPage: 0 }, () => this.getOrganizationDonations());
    }, 1000);
  }

  render() {
    const { t } = this.props;
    const { activeFilters, totalPages, modal, certifierCourses, page, total } = this.state;
    return (
      <>
        <HeaderMain
          title={'Mis buceadores'}
          withSearch={true}
          handleSearch={(value: string | null) => this.handleSearch(value)}
          resultCount={total}
        // mainButton={{
        //   text: 'Invitar',
        //   onClick: () => this.setState({ modal: 'invite-divers' }),
        // }}
        />
        <MainLayoutSection>
          <div className="ads-detail-table__content">
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout
                  columns={this.tableColumns}
                  rows={this.getTableRows()}
                  rowKeys={this.rowKeys}
                  onOrder={() => { }}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                </div>
              )}
            </MainLayoutTableContainer>
          </div>
        </MainLayoutSection>
        {modal === 'invite-divers' && (
          <ModalInviteDivers
            showModal={modal === 'invite-divers'}
            onCancel={() => this.setState({ modal: '' })}
            onSuccess={() => this.setState({ modal: '' })}
            title={'Invitar buceadores'}
          />
        )}
      </>
    );
  }

  tableColumns = [
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'acción de conservación',
      filter: false,
      ascendent: true,
    },
    {
      text: 'donación',
      filter: false,
      ascendent: true,
    },
    {
      text: 'fecha',
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: false,
    },
  ];

  tableRowTemplate = {
    name: {
      key: '',
      value: 'Centro Estrellita de los mares',
    },
    action: {
      key: '',
      value: '09/09/2019',
    },
    amount: {
      key: '',
      value: '',
    },
    date: {
      key: '',
      value: '09/09/2019',
    },
    detail: {
      key: 'detail',
      link: '',
    },
  };

  rowKeys = ['name', 'action', 'amount', 'date', 'detail'];
}
export default withTranslation(['intro', 'buttons'])(withRouter(OrganizationDonations));
