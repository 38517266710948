import React from 'react';
import MainButton from '../main-button/';
import { PaginationContainer } from './pagination-style';

interface PaginationProps {
  hasPrevious: boolean;
  hasNext: boolean;
  handlePrevious: any;
  handleNext: any;
  limit: number;
  page: number;
  goTo: (page: number) => void;
  onClick?: (page: number) => void;
}

export const PaginationV2: React.FC<PaginationProps> = ({
  hasPrevious,
  hasNext,
  page,
  handleNext,
  handlePrevious,
  limit,
  goTo,
  onClick,
}) => {
  const currentPage = page + 1;

  return (
    <PaginationContainer>
      <div className="pagination-container">
        {hasPrevious && (
          <div className="pagination-left">
            <MainButton
              type="neutral-small"
              onClick={() => {
                handlePrevious();
                if (onClick) {
                  onClick(currentPage - 1);
                }
              }}
              className={'limit'}
              text="Anterior"
            />
          </div>
        )}

        <div className="pagination-main">
          {currentPage > 1 && (
            <div className="pagination-main__item">
              <MainButton
                type="neutral-small"
                text="1"
                onClick={() => {
                  goTo(0);
                  if (onClick) {
                    onClick(1);
                  }
                }}
              />
            </div>
          )}
          {currentPage > 3 && (
            <div className="pagination-main__item">
              <MainButton disabled={true} className="disabled" type="neutral-small" text="..." />
            </div>
          )}
          {currentPage > 2 && (
            <div className="pagination-main__item">
              <MainButton
                type="neutral-small"
                onClick={() => {
                  goTo(page - 1);
                  if (onClick) {
                    onClick(currentPage - 1);
                  }
                }}
                text={(currentPage - 1).toString()}
              />
            </div>
          )}

          <div className="pagination-main__item">
            <MainButton type="neutral-small" className="actual" text={currentPage.toString()} />
          </div>

          {currentPage + 1 < limit && (
            <div className="pagination-main__item">
              <MainButton
                type="neutral-small"
                onClick={() => {
                  goTo(page + 1);
                  if (onClick) {
                    onClick(currentPage + 1);
                  }
                }}
                text={(currentPage + 1).toString()}
              />
            </div>
          )}
          {currentPage + 2 < limit && (
            <div className="pagination-main__item">
              <MainButton disabled={true} className="disabled" type="neutral-small" text="..." />
            </div>
          )}
          {currentPage < limit && (
            <div className="pagination-main__item">
              <MainButton
                type="neutral-small"
                onClick={() => {
                  goTo(limit - 1);
                  if (onClick) {
                    onClick(limit);
                  }
                }}
                text={limit.toString()}
              />
            </div>
          )}
        </div>

        {hasNext && (
          <div className="pagination-right">
            <MainButton
              type="neutral-small"
              onClick={() => {
                handleNext();
                if (onClick) {
                  onClick(currentPage + 1);
                }
              }}
              className={'limit'}
              text="Siguiente"
            />
          </div>
        )}
      </div>
    </PaginationContainer>
  );
};
