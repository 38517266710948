import * as React from 'react';
import { PlanningEndActivityStyle } from './planning-end-activity-style';
import { PlanningEndActivitySecondStyle } from './planning-end-activity-second-style';
import ModalContainer from '../../../../../components/modals/modal-container';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import InfoText from '../../../../../components/text/info-text/info-text';
import { images } from '../../../../../assets/images';
import MainButton from '../../../../../components/main-button';
import { IActivity } from '../../../../../models/activities';
import moment from 'moment';
import { IId } from '../../../../../models/generic';
import { postCentreActivityFinish } from '../../../../../services/centre';
import ModalRegistreDive from '../../../../../components/modals/modal-registre-dive/modal-registre-dive';
import { IDiveForm } from '../../../../../models/dive-forms';
import { AttachedFile } from '../../../../../types/file';
import { uploadMainPicture, createDives, uploadPictures } from '../../../../../services/dives';
import { patchActivity } from '../../../../../services/activities';
interface PlanningEndActivityProps {
  showModal: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  activity: IActivity;
  centreId: string;
}

interface PlanningEndActivityState {
  step: number;
  staff: IId[];
  renderModal: string;
  selectedDiveIndex: number;
  dates: any;
}

class PlanningEndActivity extends React.Component<PlanningEndActivityProps, PlanningEndActivityState> {
  constructor(props: PlanningEndActivityProps) {
    super(props);
    this.state = {
      step: 1,
      staff: [],
      renderModal: '',
      selectedDiveIndex: 0,
      dates: {},
    };
  }

  async handleSubmit() {
    const { centreId, activity, onSuccess } = this.props;
    const { staff } = this.state;
    const data = {
      staffAttended: staff.map((itemStaff: IId) => itemStaff.id),
    };
    await postCentreActivityFinish(centreId, activity.id.toString(), data);
    onSuccess();
  }

  addStaff(staffId: number) {
    const { staff } = this.state;
    this.setState({ staff: staff.concat([{ id: staffId }]) });
  }

  removeStaff(staffId: number) {
    const { staff } = this.state;
    this.setState({ staff: staff.filter((staff: IId) => staff.id !== staffId) });
  }

  async onCreateDive(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) {
    const { activity } = this.props;
    const { selectedDiveIndex, dates } = this.state;
    const resp: any = await createDives(dive);
    const diveId = resp.data.id.toString();
    let editData = null;
    if (activity && activity.divesInfo) {
      editData = {
        divesInfo: activity.divesInfo,
      };
      editData.divesInfo[selectedDiveIndex].diveId = parseInt(diveId, undefined);
    } else if (activity && activity.courseInfo) {
      editData = {
        courseInfo: activity.courseInfo,
      };
      //editData.courseInfo.practicalPhase.blocks[selectedDiveIndex].diveId = parseInt(diveId, undefined);
    }
    if (editData) {
      await this.onEditActivity(editData);
    }
    if (mainPicture) {
      const formDataMain = new FormData();
      formDataMain.append('file', mainPicture[0].data);
      await uploadMainPicture(diveId, formDataMain);
    }
    if (pictures) {
      const formData = new FormData();
      for (const picture of pictures) {
        formData.append('files', picture.data);
      }
      await uploadPictures(diveId, formData);
    }
    dates[diveId] = dive.date;
    this.setState({ renderModal: '', dates });
  }

  async onEditActivity(data: any) {
    const { activity } = this.props;
    if (activity) {
      await patchActivity(activity.id.toString(), data);

      this.setState({ renderModal: '' });
    }
  }

  renderSecond() {
    const { activity } = this.props;
    const { step, dates } = this.state;
    const divesInfo = activity.divesInfo
      ? activity.divesInfo : null;
    /*: activity.courseInfo && activity.courseInfo.practicalPhase.blocks
      ? activity.courseInfo.practicalPhase.blocks
      : null;*/
    return (
      <PlanningEndActivitySecondStyle>
        <div className="planning-end-activity-second-title-top">
          <p>FINALIZAR ACTIVIDAD</p>
        </div>
        <div className="planning-end-activity-second-title-buttom">
          <p>Inmersiones</p>
        </div>
        <div className="planning-end-activity-second-subtitle">
          <p>
            Registra las inmersiones realizadas en la actividad para que los buceadores tengan un registro validado de
            su actividad y se pueda finalizar la actividad
          </p>
        </div>
        {divesInfo ? (
          divesInfo.map((dive: any, index: number) => {
            let diveRaw = null;
            let date = null;
            if (dive.diveId) {
              diveRaw = activity.dives.filter(diveRaw => diveRaw.id === dive.diveId)[0];

              if (diveRaw) {
                date = diveRaw.date;
              } else {
                date = dates[dive.diveId];
              }
            }
            return (
              <div
                key={index}
                className="planning-end-activity-second-container"
                onClick={() =>
                  !dive.diveId && this.setState({ selectedDiveIndex: index, renderModal: 'create-inmersion' })
                }
              >
                <div className={dive.diveId ? 'registered-title' : 'not-registered-title'}>
                  <p>{dive.diveId ? 'Registrada' : 'No registrada'}</p>
                </div>
                <div className="registered-text">
                  <p>Inmersión {index + 1}</p>
                </div>
                {date && (
                  <div className="registered-date">
                    <p>{moment(date).format('DD MMMM YYYY - HH:MM')}</p>
                  </div>
                )}
              </div>
            );
          })
        ) : (
            <p>No hay puntos de inmersión.</p>
          )}
        <div className="buttons-bottom">
          <div className="buttons-bottom-left">
            <MainButton
              type="secondary"
              text=""
              iconImg={images.arrowLeftSvg}
              onClick={() => this.setState({ step: step - 1 })}
            />
          </div>
          <div className="buttons-bottom-right">
            <MainButton
              type="primary"
              disabled={!divesInfo || !divesInfo.every((diveInfo: any) => !!diveInfo.diveId)}
              text="Finalizar actividad"
              onClick={() => this.handleSubmit()}
            />
          </div>
        </div>
      </PlanningEndActivitySecondStyle>
    );
  }

  renderFirst() {
    const { activity } = this.props;
    const { step, staff } = this.state;
    const staffIds = staff.map((staff: IId) => staff.id);
    return (
      <PlanningEndActivityStyle>
        <div className="planning-end-activity-title-top">
          <p>FINALIZAR ACTIVIDAD</p>
        </div>
        <div className="planning-end-activity-title-buttom">
          <p>Bautismo de buceo en la Playa de los Arrecifes</p>
        </div>
        <div className="planning-end-activity-subtitle">
          <p>Señala el staff que realizó finalmente la actividad con los buceadores.</p>
        </div>
        <div className="planning-end-activity-checkbox-title">
          <p>STAFF PARTICIPANTE</p>
        </div>
        <div className="planning-end-activity-list">
          {activity.staff_activities &&
            activity.staff_activities.map((staff: any, index: number) => (
              <div className="planning-end-activity-list" key={index}>
                <DicotomicCheckbox
                  className="rounded"
                  checked={staffIds.includes(staff.id)}
                  onCheck={checked => {
                    checked ? this.addStaff(staff.id) : this.removeStaff(staff.id);
                  }}
                >
                  <div className="planning-end-activity-image">
                    <img src={images.activityBack} />
                  </div>
                  <InfoText>{`${staff.staff.user.name} ${staff.staff.user.surname}`}</InfoText>
                </DicotomicCheckbox>
              </div>
            ))}
        </div>
        <div className="planning-end-activity-checkbox">
          <DicotomicCheckbox onCheck={() => { }}>
            <InfoText>Soy el único staff responsable de esta actividad</InfoText>
          </DicotomicCheckbox>
        </div>
        <MainButton type="primary" text="Siguiente" onClick={() => this.setState({ step: step + 1 })} />
      </PlanningEndActivityStyle>
    );
  }

  render() {
    const { showModal, onCancel, activity } = this.props;
    const { step, renderModal, selectedDiveIndex } = this.state;
    return [
      renderModal === '' && (
        <ModalContainer modalClose={() => onCancel()} active={showModal}>
          {step === 1 && this.renderFirst()}
          {step === 2 && this.renderSecond()}
        </ModalContainer>
      ),
      activity && renderModal === 'create-inmersion' && (
        <ModalRegistreDive
          showModal={renderModal === 'create-inmersion'}
          activity={activity}
          selectedDiveIndex={selectedDiveIndex}
          onClose={() => this.setState({ renderModal: '' })}
          onSubmit={(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) =>
            this.onCreateDive(dive, mainPicture, pictures)
          }
        />
      ),
    ];
  }
}

export default PlanningEndActivity;
