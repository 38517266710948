import * as React from 'react';
import { ActivityDetailStaffStyle } from './activity-detail-staff-style';
import MainTable from '../../../../components/main-table/main-table';
import { MainLayoutTableContainer } from '../../../main-layout-style';
import { images } from '../../../../assets/images';
import { IActivity } from '../../../../models/activities';
import { copyOf } from '../../../../utils/other';
import ModalInviteStaff from '../../../../components/modals/modal-invite-staff/modal-invite-staff';
import ModalRejectStaff from '../../../../components/modals/modal-reject-staff/modal-reject-staff';
import ModalConfirmation from '../../../../components/modals/modal-confimation/modal-confirmation';
import services from '../../../../services/services';
import IStoreState from '../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import MainButton from '../../../../components/main-button';
import { deleteStaffActivity, patchStaffActivity, patchAddStaffActivity } from '../../../../services/activities';
import ModalContainer from '../../../../components/modals/modal-container';
import PlanningStaff, { PlanningStaffState } from '../../modal-planning-activity/planning-staff/planning-staff';
import { IId } from '../../../../models/generic';
import { Console } from 'console';

export interface IActivityDetailStaffProps {
  activity?: IActivity;
  centreId: string;
  onReload: () => void;
  staffPermissions?: any;
}

export interface IActivityDetailStaffState {
  renderModal: string;
  selectedStaff?: any;
}

class ActivityDetailStaff extends React.Component<IActivityDetailStaffProps, IActivityDetailStaffState> {
  constructor(props: IActivityDetailStaffProps) {
    super(props);

    this.state = {
      renderModal: '',
    };
  }


  getTableRows() {
    const { activity } = this.props;
    const tableRows: any[] = [];
    if (activity && activity.staff_activities && activity.staff_activities.length > 0) {
      activity.staff_activities.forEach((staffActivity: any) => {
        const tableRow = copyOf(this.tableRowTemplate);
        if (staffActivity.staff.user.picture) tableRow.picture.link = staffActivity.staff.user.picture;
        else tableRow.picture.value = 'ghost';
        tableRow.name.value = `${staffActivity.staff.user.name} ${staffActivity.staff.user.surname}`;
        tableRow.role.value = '-';
        tableRow.titulation.value = staffActivity.staff.documents.length > 0 ? staffActivity.staff.documents[0].name : '-';
        if (staffActivity.staff.documents.length > 1) {
          tableRow.titulationCount.value = {
            title: 'Titulación',
            items: staffActivity.staff.documents.map((document: any) => document.name),
          };
        }
        tableRow.additionalTitulation.value =
          staffActivity.staff.aditionalTraining && staffActivity.staff.aditionalTraining.length > 0
            ? staffActivity.staff.aditionalTraining[0]
            : '-';
        if (staffActivity.staff.aditionalTraining && staffActivity.staff.aditionalTraining.length > 1) {
          tableRow.additionalTitulationCount.value = {
            title: 'Formación adicional',
            items: staffActivity.staff.aditionalTraining,
          };
        }
        tableRow.delete.onClick = () => {
          this.modalHandler('modal-delete', staffActivity.staff);
        }
        tableRows.push(tableRow);
      });
    }
    return tableRows;
  }

  async handleDelete() {

    const { activity, centreId, onReload } = this.props;
    const { selectedStaff } = this.state;
    if (selectedStaff && activity && centreId) {
     /*  let newStaffs = [];
      if (activity.staff_activities) {
        for (const staff of activity.staff_activities) {
          if (staff.staff.id != selectedStaff.id) newStaffs.push({ "staff": staff.staff.id, "confirmed": false });
        }
      }
      */
      await deleteStaffActivity(activity.id.toString(), selectedStaff.id);
      services.pushNotification({
        title: '¡Confirmado!',
        text: 'El staff se ha eliminado con éxito',
        type: 'green',
      });
     
    }
    this.closeModal();
    onReload();
  }

  async handleAdd(staffs: any) {
    const { activity, onReload } = this.props;
    const stafs = [];
    if(activity && activity.staff_activities){
      for(const s of staffs.staff){

        const staffFound = activity.staff_activities.find((stf: any)=> parseInt(stf.staff.id) === parseInt(s.staff));
        if(staffFound === undefined){
          stafs.push(s);
        }
      }
    }

    if (activity) {
      await patchAddStaffActivity(activity.id.toString(), stafs);
      services.pushNotification({
        title: '¡Editado!',
        text: 'Los staffs se han editado con éxito',
        type: 'green',
      });
    }
    this.closeModal();
    onReload();
  }

  modalHandler = (modalValue: string, staff?: any) => {
    this.setState({ ...(staff && { selectedStaff: staff }), renderModal: modalValue });
  };

  closeModal() {
    this.setState({ renderModal: '', selectedStaff: undefined });
  }

  render() {
    const { activity, centreId, onReload } = this.props;
    const { renderModal, selectedStaff } = this.state;
    return (
      <ActivityDetailStaffStyle>
        <MainLayoutTableContainer>
          <ModalInviteStaff showModal={false} onCancel={() => this.closeModal()} />
          {activity && renderModal === 'modal-reject' && (
            <ModalRejectStaff
              showModal={true}
              onCancel={() => this.closeModal()}
              activity={activity}
              centreId={centreId}
              onSuccess={() => {
                services.pushNotification({
                  title: 'Rechazado',
                  text: 'El staff se ha rechazado de la actividad',
                  type: 'red',
                });
                this.closeModal();
                onReload();
              }}
              selectedStaff={selectedStaff}
            />
          )}
          <div>
            <MainButton
              iconImg={images.plusSvg}
              text={'Añadir staff'}
              type={'secondary'}
              onClick={() => {
                this.modalHandler('edit-staff');
              }}
            />
          </div>
          <div className="diving-center-table">
            <MainTable
              columns={this.tableColumns}
              rows={this.getTableRows()}
              rowKeys={this.rowKeys}
              onOrder={() => { }}
            />
          </div>
          {renderModal === 'modal-delete' && (
            <ModalConfirmation
              showModal={renderModal === 'modal-delete'}
              subtitle={
                'Al aceptar se eliminará al staff de la actividad.'
              }
              onAccept={() => this.handleDelete()}
              onCancel={() => this.closeModal()}
            />
          )}
          {/* <div className="diving-center-pagination">
            <Pagination page={1} callback={() => {}} limit={22} />
          </div> */}
          {activity && renderModal === 'edit-staff' && (
            <ModalContainer
              title={'Editar Staff'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-staff'}
            >
              <PlanningStaff
                selectedStaffs={
                  activity.staff_activities
                    ? activity.staff_activities.map((staffActivity: any) => staffActivity.staff.id.toString())
                    : undefined
                }
                centreId={centreId}
                edit={true}
                onNext={(state: PlanningStaffState, staffs: IId[], inmediateConfirmation: boolean) => {
                  const staff = staffs.map(staff => ({ staff: +staff.id, confirmed: inmediateConfirmation }));
                  this.handleAdd({
                    staff
                  });
                }}
              />
            </ModalContainer>
          )}
        </MainLayoutTableContainer>
      </ActivityDetailStaffStyle>
    );
  }

  tableColumns = [
    {
      text: '',
      filter: false,
      ascendent: false,
    },
    {
      text: 'nombre del buceador',
      filter: false,
      ascendent: true,
    },
    {
      text: 'rol',
      filter: false,
      ascendent: false,
    },
    {
      text: 'titulacion',
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: false,
    },
    {
      text: 'formación adicional',
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: false,
    },
  ];

  tableRowTemplate = {
    picture: {
      key: 'avatar',
      value: '',
      link: '',
    },
    name: {
      key: '',
      value: 'Juan Pérez Hernández',
    },
    role: {
      key: '',
      value: 'Monitor',
    },
    titulation: {
      key: '',
      value: 'Open Water Certificated',
    },
    titulationCount: {
      key: 'count',
      value: '',
    },
    additionalTitulation: {
      key: '',
      value: 'Experto',
    },
    additionalTitulationCount: {
      key: 'count',
      value: '',
    },
    delete: {
      key: 'icon-button',
      value: images.deleteSvg
    },
  };

  rowKeys = [
    'picture',
    'name',
    'role',
    'titulation',
    'titulationCount',
    'additionalTitulation',
    'additionalTitulationCount',
    'delete'
  ];
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(ActivityDetailStaff);
