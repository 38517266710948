import * as React from 'react';
import { MainLayoutTableContainer, MainLayoutSection } from '../../../main-layout-style';
import Pagination from '../../../../components/pagination';
import MainTableLayout from '../../../../components/main-table/main-table';
import { images } from '../../../../assets/images';
import HeaderMain from '../../../../components/header-main/header-main';
import ModalCreateEquipmentCenter from './modal-create-equipment-center/modal-create-equipment-center';
import { RouteComponentProps, match } from 'react-router';
import { getStock, deleteStock } from '../../../../services/centre';
import { NavigationTabContainer } from '../../../../components/navigation-tabs/navigation-tabs-style';
import { NavLink } from 'react-router-dom';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import { IStock } from '../../../../models/centre-forms';
import { copyOf } from '../../../../utils/other';
import { STOCK_STATUS_KEY_VALUES } from '../../../../constants/equipment';
import moment from 'moment';
import ModalDelete from '../../../../components/modals/modal-delete/modal-delete';
import services from '../../../../services/services';
import { ContentBlock } from '../../../../components/content-block/content-block';
import IStoreState from '../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
const STOCK_ITEMS_PER_PAGE = 10;

export interface IMyCenterEquipmentInventaryProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  t: any;
  staffPermissions?: any;
}

export interface IMyCenterEquipmentInventaryState {
  stock: IStock[];
  renderModal: string;
  total: number;
  totalPages: number;
  skipPage: number;
  selectedStock?: IStock;
  selectedStockId: string | number;
  page: number;
}

class MyCenterEquipmentInventary extends React.Component<
  IMyCenterEquipmentInventaryProps,
  IMyCenterEquipmentInventaryState
> {
  constructor(props: IMyCenterEquipmentInventaryProps) {
    super(props);
    this.state = {
      stock: [],
      renderModal: '',
      total: 0,
      totalPages: 0,
      skipPage: 0,
      selectedStockId: '',
      page: 1,
    };
  }

  componentDidMount() {
    this.getStock();
  }

  async getStock() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { skipPage } = this.state;
    const params = {
      filter: {
        skip: skipPage,
        limit: STOCK_ITEMS_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
      },
    };
    const resp = await getStock(id, params);
    const total = resp.data.count;
    const stock = resp.data.data;
    const totalPages: number = Math.ceil(resp.data.count / STOCK_ITEMS_PER_PAGE);
    this.setState({ stock, total, totalPages });
  }

  async handleRemove() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { selectedStockId } = this.state;
    await deleteStock(id, selectedStockId);
    this.setState({ renderModal: '' });
    services.pushNotification({
      title: '¡Eliminado!',
      text: 'El material se ha eliminado correctamente',
      type: 'red',
    });
    this.getStock();
  }

  getTableRows() {
    const { staffPermissions } = this.props;
    const { stock } = this.state;
    const tableRows: any = [];
    stock.forEach(item => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.name.value = item.name.en;
      tableRow.units.value = item.units ? item.units.toString() : '';
      tableRow.location.value = item.location;
      tableRow.state.value = item.status ? STOCK_STATUS_KEY_VALUES[item.status] : '';
      tableRow['buy-date'].value = item.purchasedAt ? moment(item.purchasedAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      tableRow['last-revision'].value = item.lastRevision
        ? moment(item.lastRevision, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '';
      tableRow.expiration.value = item.expirationDate
        ? moment(item.expirationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '';
      if (!staffPermissions || staffPermissions.deals) {
        tableRow.delete.onClick = () => {
          if (item.id !== undefined) {
            this.setState({ renderModal: 'modal-delete-equipment', selectedStockId: item.id });
          }
        };
        tableRow.detail.onClick = () => {
          if (item.id !== undefined) {
            this.setState({ renderModal: 'modal-create-equipment', selectedStock: item });
          }
        };
      } else {
        tableRow.delete = tableRow.detail = {
          key: '',
          value: ' ',
        };
      }
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = STOCK_ITEMS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getStock();
      });
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t, 
      staffPermissions,
    } = this.props;
    const { renderModal, totalPages, selectedStock, page } = this.state;
    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Material del centro'}
            mainButton={
              !staffPermissions || staffPermissions.deals
                ? {
                    img: images.createWhiteSvg,
                    text: 'Crear',
                    onClick: () => this.setState({ renderModal: 'modal-create-equipment', selectedStock: undefined }),
                  }
                : undefined
            }
          />
          <MainLayoutSection>
            <NavigationTabContainer>
              <NavLink to={`/centres/${id}/business/equipment/inventary`}>
                <div className="navigation-item">
                  <BodyDestacadoText>Inventario</BodyDestacadoText>
                </div>
              </NavLink>
              <NavLink to={`/centres/${id}/business/equipment/rent`}>
                <div className="navigation-item">
                  <BodyDestacadoText>Alquiler de equipo</BodyDestacadoText>
                </div>
              </NavLink>
              <NavLink to={`/centres/${id}/business/equipment/shop`}>
                <div className="navigation-item">
                  <BodyDestacadoText>Tienda</BodyDestacadoText>
                </div>
              </NavLink>
            </NavigationTabContainer>
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout
                  columns={this.tableColumns}
                  rows={this.getTableRows()}
                  rowKeys={this.rowKeys}
                  onOrder={() => {}}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                </div>
              )}
            </MainLayoutTableContainer>
          </MainLayoutSection>
          {renderModal === 'modal-delete-equipment' && (
            <ModalDelete
              title={''}
              subtitle={'Vas a eliminar un equipo del inventario ¿estás seguro?'}
              showModal={renderModal === 'modal-delete-equipment'}
              onCancel={() => this.setState({ renderModal: '' })}
              onRemove={() => this.handleRemove()}
            />
          )}
          {renderModal === 'modal-create-equipment' && (
            <ModalCreateEquipmentCenter
              centreId={id}
              stock={selectedStock}
              onSuccess={() => {
                this.setState({ renderModal: '' });
                this.getStock();
              }}
              onClose={() => this.setState({ renderModal: '' })}
              showModal={renderModal === 'modal-create-equipment'}
            />
          )}
        </ContentBlock>
      </>
    );
  }

  tableColumns = [
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Unidades',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Ubicación',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Estado',
      filter: false,
      ascendent: false,
    },
    {
      text: 'F.Compra',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Última revisión',
      filter: false,
      ascendent: true,
    },
    {
      text: 'F.Caducidad',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    name: {
      key: 'highlighted-body',
      value: '',
    },
    units: {
      key: '',
      value: '',
    },
    location: {
      key: '',
      value: '',
    },
    state: {
      key: '',
      value: '',
    },
    'buy-date': {
      key: '',
      value: '',
    },
    'last-revision': {
      key: '',
      value: '',
    },
    expiration: {
      key: '',
      value: '',
    },
    detail: {
      key: 'icon-button',
      value: images.edit3Svg,
    },
    delete: {
      key: 'icon-button',
      value: images.deleteSvg,
    },
  };

  rowKeys = ['name', 'units', 'location', 'state', 'buy-date', 'last-revision', 'expiration', 'detail', 'delete'];
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(MyCenterEquipmentInventary);
