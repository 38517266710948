import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const PopNotificationLayout = styled.div`
  position: fixed;
  top: 0;
  right: 40px;
  height: 100%;
  width: 240px;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;
  z-index: 9999999;
`;

export const PopNotificationContainer = styled.div`
  width: 100%;
  bottom: 60px;
  right: 60px;
  border-radius: 5px;
  padding: 14px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(25px);
  transition: all 0.3s ease-in-out;
  pointer-events: all;
  margin-bottom: 20px;
  &.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  &.red {
    color: ${colors['CO-003/100']};
    background: ${colors['CO-003/15']};
  }
  &.red-warning {
    color: ${colors['CO-003/100']};
    background: ${colors['CO-003/15']};
  }
  &.green {
    color: ${colors['CO-001/100']};
    background: ${colors['CO-001/15']};
  }
  &.yellow {
    color: ${colors['NE-003/30']};
    background: ${colors['CO-002/50']};
  }

  .pop-notification-head  {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    position: relative;

    &__icon {
      width: 24px;
      margin-right: 8px;
    }
    &__title {
      padding-right: 30px;
    }
    &__close {
      width: 16px;
      opacity: 0.5;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }
  }
`;
