import React, { Component } from 'react';
import { MainButtonContainer } from './main-button-style';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import ButtonLoader from '../button-loader/button-loader';
interface MainButtonProps {
  text: string;
  type?: string;
  iconImg?: any;
  onClick?: Function;
  disabled?: boolean;
  active?: boolean;
  iconRight?: boolean;
  iconImgRight?: any;
  className?: string;
  loading?: boolean;
  loader?: any;
}

class MainButton extends Component<MainButtonProps, {}> {
  render() {
    const {
      text,
      type,
      iconImg,
      onClick,
      disabled,
      active,
      iconRight,
      iconImgRight,
      className,
      loading,
      loader,
    } = this.props;
    return (
      <MainButtonContainer
        disabled={disabled}
        className={`${disabled ? 'disabled' : `${type} ${active && 'active'}`} ${className}`}
        onClick={() => {
          if (!disabled && onClick) {
            onClick();
          }
        }}
      >
        {!loading && iconImg && (
          <div className="main-button-icon left">
            <img src={iconImg} />
          </div>
        )}
        {loading && <div className="main-button-icon left">{loader ? loader : <ButtonLoader />}</div>}
        <div className={`main-button-text ${!!iconImg && 'full'}`}>
          <BodyDestacadoText>{text}</BodyDestacadoText>
        </div>

        {!loading && iconRight && (
          <div className="main-button-icon right">
            <img src={iconImgRight} />
          </div>
        )}
      </MainButtonContainer>
    );
  }
}

export default MainButton;
