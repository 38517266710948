import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const CertifierRolesCreateContainer = styled.div`
  padding: 0 32px 32px 32px;
  width: 416px;
  .modal-create-form {
&__add-zone-btn{
  margin-top: 28px;
}
    
    margin-bottom: 40px;
    &__title  {
      color: ${colors['SE-002/100']};
      margin-bottom: 14px;
    }
  }
  .modal-create-buttons {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .modal-create-role-container  {
    margin-bottom: 48px;
    width: 100%;
  }
  .modal-create-form-states-container {
    width: 100%;
    line-height: 22px;
    &__item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin: 5px;
      margin-left: 15px;
      margin-right: 15px;
      background: ${colors['SE-002/20']};
      border-radius: 3px;
      padding: 5px 
    }
    &__img {
      cursor: pointer;
      margin-top: 3px;
    }
  }
  }
`;
