import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { mediaqueries } from '../../constants/mediaqueries';

export const IntroContainer = styled.div`

  .bgImagePanel{
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .intro-background {
    position: fixed;
    height: 100vh;
    width: 50%;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: all 1.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    z-index: 99;
    &.active {
      width: 100%;
    }
    .intro-background-img  {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 100vh;
      width: 100%;
      img {
        height: 100%;
        min-width: 100%;
        width: auto;
        object-fit: cover;
      }
    }
    .intro-background-logo {
      z-index: 2;
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-top: 142px;
      }
    }
    .intro-background-slider {
      justify-content: center;
      width: 360px;
      z-index: 2;
      color: white;
      text-align: center;
      transition: all 0.6s ease-in-out;
      transition-delay: 1.3s;
      margin-bottom: 20px;
      opacity: 0;
      &.active {
        opacity: 1;
      }
      .intro-slide {
        &:focus {
          border: none;
          outline: none;
        }
        .intro-slide-title {
          margin-bottom: 20px;
        }
        .intro-slide-text {
          margin-bottom: 60px;
        }
      }

      .slick-dots  {
        width: 100%;
        justify-content: center;
        margin-top: 32px;
        li  {
          &:last-of-type button {
            margin-right: 0px;
          }
          &.slick-active button {
            width: 30px;
            height: 14px;
            background: ${colors['PR-001/100']};
            border-radius: 14px;
            transition: all 0.3s ease-in-out;
          }
        }
        li button {
          border: none;
          width: 14px;
          height: 14px;
          display: inline-block;
          background: #e2e5ea;
          border-radius: 14px;
          transition: all 0.6s ease-in-out;
          margin-right: 16px;
          padding: 0;
          cursor: pointer;
        }
      }
      .slick-list {
        overflow: visible;
      }
      .slick-slide {
        transform: scale(0.9);
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      .slick-active {
        transform: scale(1);
        opacity: 1;
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
      }
    }

    .intro-bar {
      width: 95%;
      height: 1px;
      background: #fff;
      opacity: 0.5;
      margin-bottom: 80px;
      &.active {
        width: 0px;
        transition: all 0.3s ease-in-out;
        margin-bottom: 30px;
      }
    }
    .intro-footer-logos {
      display: flex;
      align-items: center;
      z-index: 2;
      justify-content: flex-start;
      transition: all 0.3s ease-in-out;
      border-top: 1px solid ${colors['NE-002/0']};
      padding-top: 20px;
      width: 100%;

      &.active {
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }

      .intro-footer-logos-item {
        height: 50px;
        margin-right: 24px;
        img {
          width: auto;
          height: 50px;
        }
      }
      .intro-footer-logos-text {
        color: ${colors['NE-002/0']};
        &.active {
          display: none;
        }
        .intro-footer-logos-text-title {
          font-size: 10px;
          font-style: italic;
          margin-bottom: 5px;
        }
        .intro-footer-logos-text-text {
          font-size: 10px;
        }
      }
    }
  }

  .intro-form  {
    margin-left: 50%;
    width: 50%;
  }

  .intro-from-footer-logos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 120px 52px 120px;
    padding-top: 27px;
    border-top: 1px solid ${colors['NE-002/100']};
    width: 70%;
    .intro-form-footer-logos {
      height: 50px;
      margin-right: 29px;
      margin-bottom: 40px;
      img {
        height: 100%;
        width: auto;
      }
    }
    .intro-footer-logos-text-title {
      font-size: 10px;
      font-style: italic;
      margin-bottom: 5px;
    }
    .intro-from-footer-logos-text-text {
      font-size: 10px;
    }
  }

  .intro-languages {
    padding: 0 120px;
    margin-bottom: 52px;
    .languages  {
      width: 80px;
    }
  }
  ${mediaqueries['max-width1024']} {
    .intro-background {
      width: 45%;
    }
    .intro-form  {
      margin-left: 45%;
      width: 55%;
    }
  }
  ${mediaqueries['max-width768']} {
    .intro-from-footer-logos {
      margin: 40px;
      width: calc(100% - 80px);
    }
    .intro-background {
      width: 100%;
      visibility: hidden;
      opacity: 0;
      &.active {
        visibility: visible;
        opacity: 1;
        left: 0%;
      }
    }
    .intro-form  {
      margin-left: 0%;
      width: 100%;
    }
    .intro-languages {
      padding: 0 20px;
    }
  }
`;

export const IntroFormContainer = styled.div`
  padding: 80px 120px 60px 120px;
  ${mediaqueries['max-width1140']} {
    padding: 80px 40px 60px 40px;
  }
  ${mediaqueries['max-width768']} {
    padding: 80px 20px 60px 20px;
  }
`;
export const IntroScreenContainer = styled.div`
  padding: 100px 120px 60px 120px;
  ${mediaqueries['max-width1140']} {
    padding: 35px 40px 60px 40px;
  }
  ${mediaqueries['max-width768']} {
    padding: 35px 20px 60px 20px;
  }
`;
