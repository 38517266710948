import * as React from 'react';
import { CreateActivitiesDiveSitesStyle } from './create-activities-dive-sites-style';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import InfoText from '../../../../../components/text/info-text/info-text';
import MainButton from '../../../../../components/main-button';
import { images } from '../../../../../assets/images';
import { getCentreDiveSites } from '../../../../../services/centre';
import { IDropdown, IId } from '../../../../../models/generic';
import SelectBox from '../../../../../components/select-box';
import SingleButton from '../../../../../components/single-button/single-button';

export interface ICreateActivitiesDiveSitesProps {
  diveSites?: IId[];
  onPrev: () => void;
  onNext: (diveSites: IId[]) => void;
  centreId: string;
}

export interface ICreateActivitiesDiveSitesState {
  addPoints: boolean;
  selectedDiveSite?: number;
  diveSites: number[];
  diveSitesDropdown: IDropdown[];
  diveSitesKeyValues: { [key: string]: string };
  error: string;
}

export default class CreateActivitiesDiveSites extends React.Component<
  ICreateActivitiesDiveSitesProps,
  ICreateActivitiesDiveSitesState
> {
  constructor(props: ICreateActivitiesDiveSitesProps) {
    super(props);

    this.state = {
      addPoints: false,
      diveSites: [],
      diveSitesDropdown: [],
      diveSitesKeyValues: {},
      error: '',
    };
  }

  componentDidMount() {
    this.getDiveSites();
    this.setDiveSites();
  }

  componentDidUpdate(prevProps: ICreateActivitiesDiveSitesProps) {
    const { diveSites } = this.props;
    if (prevProps.diveSites !== diveSites) {
      this.setDiveSites();
    }
  }

  setDiveSites() {
    const { diveSites } = this.props;
    if (diveSites) {
      this.setState({ diveSites: diveSites.map(diveSite => diveSite.id), addPoints: diveSites.length > 0 });
    }
  }

  async getDiveSites() {
    const { centreId } = this.props;
    const resp = await getCentreDiveSites(centreId);
    const diveSitesDropdown = resp.data.data.map((diveSite: any) => {
      return {
        label: diveSite.name,
        value: diveSite.id.toString(),
      };
    });
    const diveSitesKeyValues: any = {};
    resp.data.data.forEach((diveSite: any) => {
      diveSitesKeyValues[diveSite.id] = diveSite.name;
    });
    this.setState({ diveSitesDropdown, diveSitesKeyValues });
  }

  handleAdd() {
    const { selectedDiveSite, diveSites } = this.state;
    if (selectedDiveSite && !diveSites.includes(selectedDiveSite)) {
      const newDiveSites = diveSites.concat([selectedDiveSite]);
      this.setState({ diveSites: newDiveSites, selectedDiveSite: NaN, error: '' });
    }
  }

  handleDelete(selectedDiveSite: number) {
    const { diveSites } = this.state;
    if (diveSites.includes(selectedDiveSite)) {
      this.setState({ diveSites: diveSites.filter(diveSite => diveSite !== selectedDiveSite) });
    }
  }

  handleNext() {
    const { onNext } = this.props;
    const { diveSites, addPoints } = this.state;
    if (addPoints && diveSites.length === 0) {
      this.setState({ error: 'required' });
    } else {
      onNext(
        addPoints
          ? diveSites.map(diveSite => {
              return { id: diveSite };
            })
          : []
      );
    }
  }

  render() {
    const { onPrev } = this.props;
    const { diveSitesKeyValues, diveSitesDropdown, diveSites, addPoints, error, selectedDiveSite } = this.state;
    return (
      <CreateActivitiesDiveSitesStyle>
        <div className="create-activities-other-title">
          <div className="create-activities-other-title__text">
            <BodyDestacadoText>¿Añadir un punto de inmersión predefinido?</BodyDestacadoText>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={addPoints}
              onCheck={() => this.setState({ addPoints: true })}
            >
              <InfoText>Si</InfoText>
            </DicotomicCheckbox>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={!addPoints}
              onCheck={() => this.setState({ addPoints: false })}
            >
              <InfoText>No</InfoText>
            </DicotomicCheckbox>
          </div>
        </div>
        {addPoints && (
          <>
            <div className="create-activities-other-form">
              <div className="create-activities-other-form__input">
                <SelectBox
                  className="rounded-title"
                  labelText="Punto de inmersión"
                  placeholder="Punto de inmersión"
                  optionsText={diveSitesDropdown.filter(
                    (item: IDropdown) => !diveSites.includes(parseInt(item.value, undefined))
                  )}
                  searchable={true}
                  optionKey={'value'}
                  optionValue={'label'}
                  icon={images.arrowDown2BlueSvg}
                  onChange={(value: string) => this.setState({ selectedDiveSite: parseInt(value, undefined) })}
                  errorCode={error}
                  required={true}
                  clearValue={selectedDiveSite !== undefined && !selectedDiveSite}
                />
              </div>
              <div className="create-activities-other-form__add">
                <MainButton
                  type="secondary full"
                  text={'Añadir'}
                  iconImg={images.plusSvg}
                  onClick={() => this.handleAdd()}
                />
              </div>
            </div>
            {diveSites.map((diveSite: number) => {
              return (
                <div className="create-activities-other-cards" key={diveSite}>
                  <div className="create-activities-other-cards__text">
                    <InfoText>{diveSitesKeyValues[diveSite.toString()]}</InfoText>
                  </div>
                  <div className="create-activities-other-cards__delete" onClick={() => this.handleDelete(diveSite)}>
                    <img src={images.deleteSvg} alt="" />
                  </div>
                </div>
              );
            })}
          </>
        )}
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            <div className="modal-create-activities-buttons-left__back">
              <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
            </div>
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={'Siguiente'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesDiveSitesStyle>
    );
  }
}
