import styled from 'styled-components';

export const BookingsDetailStyle = styled.div`
  .activereservations {
    margin-bottom: 20px;
    color: #005366;
  }

  .contents {
    display: flex;
    justify-content: space-between;
  }

  .Collapsible {
    padding: 1rem;
    background-color: #f5f5f5;
    margin-bottom: 10px;
  }
  .Collapsible__trigger {
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 15px;
    .title_index {
      color: white;
      padding: 7px;
      border-radius: 50%;
      width: 31px;
      height: 31px;
      display: inline-block;
      text-align: center;
      margin-right: 1rem;
    }
    .title_index_1 {
      background-color: #dda600;
    }
    .title_index_2 {
      background-color: #8cbfc6;
    }
    .title_index_3 {
      background-color: #8cbfc6;
    }
  }
  .is-disabled {
    color: #ddd;
    .title_index_2,
    .title_index_3 {
      background-color: #ddd;
    }
  }
  .Collapsible__contentOuter {
    margin-left: 2em;
  }
  .content-center {
    width: 65%;
  }
  .content-right {
    width: 28%;
  }
  .footer_btn {
    display: flex;
    margin-top: 1.8em;
    justify-content: flex-end;
  }
`;
