import {
  ConservationAction,
  ConservationActionExtended,
  GetAllConservationActionsParams,
} from '../models/conservation-action';
import services from './services';
import { AxiosPromise } from 'axios';
import { IGetResponse } from '../models/generic';

export const getAllConservationActions = (certifierId: string | number, params?: GetAllConservationActionsParams) => {
  return new Promise<AxiosPromise<IGetResponse<ConservationActionExtended>>>((resolve, reject) => {
    const args = {
      params,
      endpoint: `organizations/${certifierId}/conservation-actions`,
      then: resolve,
      catch: reject,
      loader: true,
    };

    services.get(args);
  });
};

export const postCreateConservationAction = (certifierId: string, data: ConservationAction) => {
  return new Promise<AxiosPromise<ConservationActionExtended>>((resolve, reject) => {
    const args = {
      data,
      endpoint: `organizations/${certifierId}/conservation-actions`,
      then: resolve,
      catch: reject,
      loader: true,
    };

    services.post(args);
  });
};

export const patchUpdateConservationAction = (
  certifierId: string,
  projectId: string,
  data: ConservationAction | any
) => {
  return new Promise<AxiosPromise<ConservationActionExtended>>((resolve, reject) => {
    const args = {
      data,
      endpoint: `organizations/${certifierId}/conservation-actions/${projectId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };

    services.patch(args);
  });
};

export const patchUpdateConservationActionImage = (certifierId: string, projectId: string, data: FormData) => {
  return new Promise<AxiosPromise<ConservationActionExtended>>((resolve, reject) => {
    const args = {
      data,
      endpoint: `organizations/${certifierId}/conservation-actions/${projectId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };

    services.patchFormData(args);
  });
};

export const deleteConservationAction = (certifierId: string, projectId: string) => {
  return new Promise<AxiosPromise>((resolve, reject) => {
    const args = {
      endpoint: `organizations/${certifierId}/conservation-actions/${projectId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };

    services.deleteReq(args);
  });
};

export const getConservationAction = (certifierId: string, projectId: string) => {
  return new Promise<AxiosPromise<ConservationActionExtended>>((resolve, reject) => {
    const args = {
      endpoint: `organizations/${certifierId}/conservation-actions/${projectId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };

    services.get(args);
  });
};

export const getConservationActionDivers = (certifierId: string, projectId: string) => {
  return new Promise<AxiosPromise<ConservationActionExtended>>((resolve, reject) => {
    const args = {
      endpoint: `organizations/${certifierId}/conservation-actions/${projectId}/divers`,
      then: resolve,
      catch: reject,
      loader: true,
    };

    services.get(args);
  });
};

export const getConservationActionDonations = (certifierId: string, projectId: string, params?: any) => {
  return new Promise<AxiosPromise<ConservationActionExtended>>((resolve, reject) => {
    const args = {
      params,
      endpoint: `organizations/${certifierId}/conservation-actions/${projectId}/donations`,
      then: resolve,
      catch: reject,
      loader: true,
    };

    services.get(args);
  });
};

export const getConservationActions = (values: any) => {
  return new Promise<AxiosPromise<any>>((resolve, reject) => {
    const args = {
      params: values.params,
      endpoint: `conservation-actions`,
      then: resolve,
      catch: reject,
      loader: true,
    };

    services.get(args);
  });
};
