import React from 'react';
import { HeadDestacadoTextContainer } from './head-destacado-text-styles';

interface HeadTextProps {
  children: any;
  className?: string;
  color?: string;
}

const HeadDestacadoText = ({ children = '', className = '', color = '' }: HeadTextProps) => {
  return (
    <HeadDestacadoTextContainer color={color} className={className}>
      {children}
    </HeadDestacadoTextContainer>
  );
};
export default HeadDestacadoText;
