import React from 'react';
import { ModalChangePassStyle } from './modal-change-pass-style';
import ModalContainer from '../../../components/modals/modal-container';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import { FormGrid, FormGridRow, FormGridItem } from '../../main-layout-style';
import InputBox from '../../../components/input-box';
import MainButton from '../../../components/main-button';
import BodyText from '../../../components/text/body-text/body-text';
import { changePassword } from '../../../services/users';
import { isValidPassword } from '../../../utils/validation';
import { images } from '../../../assets/images';
import { updateObject } from '../../../utils/utility';
import services from '../../../services/services';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ModalChangePassProps extends WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  onAccept: () => void;
  idUser: string;
}

interface ModalChangePassState {
  data: any;
  show: any;
  formIsValid: boolean;
}

class ModalChangePass extends React.Component<ModalChangePassProps, ModalChangePassState> {
  readonly state: ModalChangePassState;
  constructor(props: ModalChangePassProps) {
    super(props);
    this.state = {
      data: {
        newPassword: {
          validation: {
            valid: false,
            error: false,
          },
          touched: false,
          value: '',
          validationRules: {
            password: true,
          },
        },
        password: {
          validation: {
            valid: false,
            error: false,
          },
          touched: false,
          value: '',
          validationRules: {
            password: true,
          },
        },
        repeatPassword: {
          validation: {
            valid: false,
            error: false,
          },
          touched: false,
          value: '',
          validationRules: {
            repeatPassword: true,
          },
        },
      },
      show: {},
      formIsValid: false,
    };
  }

  checkValidity = (value: any, rules: any, form: any) => {
    const validation: any = {
      valid: true,
      error: false,
    };
    if (!rules) {
      return true;
    }
    if (rules.required) {
      const result = value.trim() !== '';
      validation.valid = result && validation.valid;
      validation.error = !result && 'required';
    }

    if (rules.password) {
      const result = isValidPassword(value);
      validation.valid = result && validation.valid;
      validation.error = !result && 'invalid-format';
    }

    if (rules.repeatPassword) {
      const result = value === form.newPassword.value;
      validation.valid = result && validation.valid;
      validation.error = !result && form.repeatPassword.touched && form.newPassword.touched && 'passwords-not-equal';
    }

    return validation;
  };

  inputChangedHandler = (newValue: string, inputIdentifier: string) => {
    const { data } = this.state;
    const updatedFormElement = updateObject(data[inputIdentifier], {
      value: newValue,
      validation: this.checkValidity(newValue, data[inputIdentifier].validationRules, data),
      touched: true,
    });
    let updatedForm = updateObject(data, {
      [inputIdentifier]: updatedFormElement,
    });
    if (inputIdentifier === 'newPassword') {
      const updatedRepeatPassword = updateObject(updatedForm.repeatPassword, {
        validation: this.checkValidity(
          updatedForm.repeatPassword.value,
          updatedForm.repeatPassword.validationRules,
          updatedForm
        ),
      });
      updatedForm = updateObject(updatedForm, { repeatPassword: updatedRepeatPassword });
    }

    let formIsValid = true;
    for (const inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].validation.valid && formIsValid;
    }
    this.setState({ formIsValid, data: updatedForm });
  };

  changePassword = async (data: any) => {
    const { onAccept, idUser, t } = this.props;
    try {
      const response: any = await changePassword(idUser, data);

      onAccept();
    } catch (err) {
      if (err) {
        services.pushNotification({
          title: t('push:wrong-password.title'),
          text: t('push:wrong-password.text'),
          type: 'red',
        });
        console.error(err)
      }
      // TODO: Find the type of err and handle it in the right way     
      // if (err.response && err.response.status === 401) {
      //   services.pushNotification({
      //     title: t('push:wrong-password.title'),
      //     text: t('push:wrong-password.text'),
      //     type: 'red',
      //   });
      // }
    }
  };

  changeValue = (value: string, key: string, subKey?: string) => {
    const { data } = this.state;
    if (!subKey) {
      data[key].value = value;
    } else {
      data[key][subKey].value = value;
    }

    this.setState({ data });
  };

  handleAccept = () => {
    const { data, formIsValid } = this.state;
    if (!formIsValid) return;

    const formData: any = {};
    for (const formElementIdentifier in data) {
      formData[formElementIdentifier] = data[formElementIdentifier].value;
    }
    this.changePassword(formData);
  };

  render() {
    const { showModal, onCancel } = this.props;
    const { data, show, formIsValid } = this.state;
    return (
      <ModalContainer title={``} active={showModal} modalClose={() => onCancel()}>
        <ModalChangePassStyle>
          <div className="modal-change-pass-title">
            <SubheadDestacadoText>Cambiar contraseña</SubheadDestacadoText>
          </div>
          <div className="modal-change-pass-text">
            <BodyText>
              La contraseña debe contener al menos 6 caracteres alfanuméricos entre símbolos, números y letras.
            </BodyText>
          </div>
          <div className="modal-change-pass-form">
            <FormGrid>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    labelText=""
                    placeholder="Nueva contraseña"
                    className="rounded"
                    type={show['newPassword'] ? 'text' : 'password'}
                    icon={!show['newPassword'] ? images.eyeGrayOffSvg : images.eyeGraySvg}
                    iconClick={() => {
                      show['newPassword'] = !show['newPassword'];
                      this.setState({ show });
                    }}
                    required={true}
                    errorCode={data.newPassword.validation.error ? data.newPassword.validation.error : undefined}
                    value={data.newPassword.value}
                    onChange={(value: string) => this.inputChangedHandler(value, 'newPassword')}
                  />
                </FormGridItem>
              </FormGridRow>

              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    labelText=""
                    placeholder="Confirma contraseña"
                    className="rounded"
                    type={show['repeatPassword'] ? 'text' : 'password'}
                    icon={!show['repeatPassword'] ? images.eyeGrayOffSvg : images.eyeGraySvg}
                    iconClick={() => {
                      show['repeatPassword'] = !show['repeatPassword'];
                      this.setState({ show });
                    }}
                    required={true}
                    errorCode={data.repeatPassword.validation.error ? data.repeatPassword.validation.error : undefined}
                    value={data.repeatPassword.value}
                    onChange={(value: string) => this.inputChangedHandler(value, 'repeatPassword')}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    labelText=""
                    placeholder="Contraseña Actual"
                    className="rounded"
                    type={show['password'] ? 'text' : 'password'}
                    icon={!show['password'] ? images.eyeGrayOffSvg : images.eyeGraySvg}
                    iconClick={() => {
                      show['password'] = !show['password'];
                      this.setState({ show });
                    }}
                    required={true}
                    errorCode={data.password.validation.error ? data.password.validation.error : undefined}
                    value={data.password.value}
                    onChange={(value: string) => this.inputChangedHandler(value, 'password')}
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>
          </div>

          <div className="modal-change-pass-button">
            <MainButton
              disabled={!formIsValid}
              onClick={() => this.handleAccept()}
              text="Guardar cambios"
              type="primary"
            />
          </div>
        </ModalChangePassStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['push'])(ModalChangePass);
