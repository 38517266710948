import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Organization } from './collaborators-types';
import { ORGANIZATIONS_PER_PAGE, ORGANIZATION_ACTION } from '../../constants/data';
import services from '../../services/services';
import CollaboratorsLayout from './collaborators-layout';
import { IFilter } from '../../models/filter';
import { createFilterRequest } from '../../utils/other';

interface CollaboratorsState {
  organizationType: string;
  items: Organization[];
  totalItems: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  page: number;
  orderAscendent: string;
  filterValues: { [key: string]: IFilter };
}

class CollaboratorsFunctional extends Component<RouteComponentProps, CollaboratorsState> {
  state: CollaboratorsState = {
    organizationType: '',
    items: [],
    totalItems: 0,
    totalPages: 0,
    searchFilter: '',
    skipPage: 0,
    page: 1,
    orderAscendent: '',
    filterValues: {},
  };

  componentDidMount() {
    this.setState({ organizationType: this.getOrganizationType(this.props) }, () => {
      this.getOrganizations();
    });
  }

  componentDidUpdate() {
    const { organizationType } = this.state;
    const type = this.getOrganizationType(this.props);

    if (organizationType !== type) {
      this.setState({ organizationType: this.getOrganizationType(this.props), filterValues: {} }, () => {
        this.getOrganizations();
      });
    }
  }

  shouldComponentUpdate(nextProps: RouteComponentProps, nextState: CollaboratorsState) {
    const {
      location: { pathname },
    } = this.props;
    const {
      location: { pathname: nextPathname },
    } = nextProps;
    if (pathname !== nextPathname) {
      return true;
    }
    return true;
  }

  getOrganizationType(props: RouteComponentProps) {
    const {
      location: { pathname },
    } = props;
    return pathname.includes('certifier') ? 'certifier' : 'marineConservation';
  }

  getOrganizations() {
    const { searchFilter, skipPage, orderAscendent, organizationType, filterValues } = this.state;
    const actionName = ORGANIZATION_ACTION[organizationType];

    const endpoint = 'organizations';
    const whereFilter = createFilterRequest(filterValues);
    whereFilter.type = organizationType;

    const params = {
      filter: {
        where: whereFilter,
        skip: skipPage,
        limit: ORGANIZATIONS_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: [actionName],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        if (res.count > 0) {
          const items: any[] = res.data.map((item: any) => {
            const { id, name, integrated, email } = item;
            return {
              id,
              name,
              integrated,
              email,
              actionsNo: item[actionName].length,
              diversNo: 0,
            };
          });

          await Promise.all(
            items.map(async item => {
              item.diversNo = await this.getDiversNoByOrganization(item.id);
            })
          );

          const totalItems: number = res.count;
          const totalPages: number = Math.ceil(totalItems / ORGANIZATIONS_PER_PAGE);
          this.setState({ items, totalItems, totalPages });
        } else {
          this.setState({ items: [], totalItems: 0, totalPages: 0 });
        }
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  async getDiversNoByOrganization(id: string) {
    let diversNo = 0;

    const endpoint = `organizations/${id}/divers`;

    await services.get({
      endpoint,
      loader: true,
      then: async ({ data: res }: any) => {
        diversNo = res.length;
      },
      catch: (err: any) => {
        console.log(err);
      },
    });

    return diversNo;
  }

  setPage(page: number) {
    const { totalItems } = this.state;
    const skip: number = ORGANIZATIONS_PER_PAGE * (page - 1);
    if (skip < totalItems) {
      this.setState({ skipPage: skip, page }, () => {
        this.getOrganizations();
      });
    }
  }

  async deleteOrganization(id: string) {
    const endpoint = `organizations/${id}`;

    await services.deleteReq({
      endpoint,
      then: (response: any) => { },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  handleFilterChange(key: string, value: any, method?: string, collection?: string) {
    const { filterValues } = this.state;
    if (!filterValues[key]) filterValues[key] = { value: '' };
    filterValues[key].value = value;
    if (method) filterValues[key].method = method;
    if (collection) filterValues[key].collection = collection;
    this.setState({ filterValues, page: 1, skipPage: 0 }, () => {
      this.getOrganizations();
    });
  }

  handleFilterDelete(key: string) {
    const { filterValues } = this.state;
    delete filterValues[key];
    this.setState({ filterValues, page: 1, skipPage: 0 }, () => {
      this.getOrganizations();
    });
  }

  render() {
    const { organizationType, items, totalPages, page, totalItems } = this.state;
    return (
      <CollaboratorsLayout
        organizationType={organizationType}
        items={items}
        page={page}
        totalPages={totalPages}
        onFilterChange={(key: string, value: any, method?: string, collection?: string) =>
          this.handleFilterChange(key, value, method, collection)
        }
        onFilterDelete={(key: string) => this.handleFilterDelete(key)}
        setPage={page => this.setPage(page)}
        refreshData={() => this.getOrganizations()}
        deleteItem={id => this.deleteOrganization(id)}
        totalItems={totalItems}
      />
    );
  }
}

export default withRouter(CollaboratorsFunctional);
