import * as React from 'react';
import { DiveActivitiesDetailStyle } from './dive-activities-detail-style';
import { RightColunmDetails } from './rigthColunmdetail';
import HeaderMain from '../../../../../components/header-main/header-main';
import { images } from '../../../../../assets/images';
import { NavigationTabContainer } from '../../../../../components/navigation-tabs/navigation-tabs-style';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';
import { MainLayoutSection } from '../../../../main-layout-style';
import CardActivity from '../../../../../components/card-activity/card-activity';
import SingleButton from '../../../../../components/single-button/single-button';
import SubheadDestacadoText from '../../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import ItemSectionDetail from '../../../../../components/item-sections-detail/item-sections-detail';
import BuyResume from '../../../../../components/buy-resume/buy-resume';
import TextDestacadoText from '../../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../../components/text/text-text/text-text';
import CardDiverActivity from '../../../../../components/card-diver-activity/card-diver-activity';
import ItemUser from '../../../../../components/item-user/item-user';
import CardActivityBonus from '../../../../../components/card-activity-bonus/card-activity-bonus';
import { getOneActivities } from '../../../../../services/activities';
import { RouteComponentProps, match } from 'react-router';
import { DURATION_UNITS_KEY_VALUE } from '../../../../../constants/duration';
import { DiveDisciplinesKeyValues, DiveKindsKeyValues } from '../../../../../constants/dive-dropdowns';
import { TRANSPORT_TYPES_KEY_VALUES } from '../../../../../constants/transport';
import { getLevels } from '../../../../../services/levels';
import { ILevels } from '../../../../../models/levels';
import { transformToUser, getUserUnit } from '../../../../../utils/measurement-units';
import { FOOD_ACCOMMODATION_KEY_VALUES } from '../../../../../constants/food-accommodation';
import { COUNTRIES_KEY_LABEL } from '../../../../../constants/countries';
import moment from 'moment';

export interface IDiveActivitiesDetailProps extends RouteComponentProps {
  match: match<{
    id: string;
    activityId: string;
  }>;
}

export interface IDiveActivitiesDetailState {
  activity?: any;
  levels: ILevels[];
}

export default class DiveActivitiesDetail extends React.Component<
  IDiveActivitiesDetailProps,
  IDiveActivitiesDetailState
  > {
  constructor(props: IDiveActivitiesDetailProps) {
    super(props);

    this.state = {
      levels: [],
    };
  }

  componentDidMount() {
    this.getLevels();
  }

  async getActivityDetail() {
    const {
      match: {
        params: { id, activityId },
      },
    } = this.props;
    const params = {
      filter: {
        relations: ['dives', 'services', 'stock'],
      },
    };
    const actId = activityId ? activityId : id;
    const activity = (await getOneActivities(actId, params)).data;
    this.setState({ activity });
  }

  async getLevels() {
    const levels = (await getLevels()).data.data;
    this.setState({ levels }, () => this.getActivityDetail());
  }

  render() {
    const { activity, levels } = this.state;
    const lang = localStorage.i18nextLng ? localStorage.i18nextLng : 'es';
    if (!activity) return <></>;
    const headerSubtitleText = activity.confirmed ? 'CONFIRMADA' : activity.cancelled ? 'CANCELADA' : 'PENDIENTE';
    const headerSubtitleType = activity.confirmed ? 'done' : activity.cancelled ? 'pending' : 'confirmed';
    const type = activity.courseInfo ? "Formación" : "Inmersión";
    const discipline = activity.discipline;
    const startHour = {
      hour: 0,
      minutes: 0,
      seconds: 0,
    };
    const endHour = {
      hour: 0,
      minutes: 0,
      seconds: 0,
    };
    const activityDate = moment(activity.date);
    const now = moment();
    let status = '';
    if (activity.finished) {
      status = 'finished';
    } else if (activityDate.isBefore(now)) {
      status = 'done';
    } else if (activity.confirmed) {
      status = 'confirmed';
    } else {
      status = 'pending';
    }
    const divesInfo = activity.divesInfo
      ? activity.divesInfo
      : activity.courseInfo && activity.courseInfo.practicalPhase.blocks
        ? activity.courseInfo.practicalPhase.blocks
        : null;
    return (
      <>
        <HeaderMain
          goBack={true}
          title={activity.name}
          subtitle={{ type: headerSubtitleType, text: headerSubtitleText }}
        />
        <MainLayoutSection>
          <DiveActivitiesDetailStyle>
            <div className="dive-activity-detail-left">
              <div className="dive-activity-detail-left__card">
                <CardActivity
                            activity={activity}
                            type={type}
                            discipline={discipline}
                name={activity.name} date={activity.date} startHour={startHour} endHour={endHour} />
              </div>
              <div className="dive-activity-detail-left__section">
                <ItemSectionDetail title="Datos generales" icon={images.clockSvg}>
                  {/*
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Nº mínimo buceadores:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>{activity.minDivers}</TextText>
                    </div>
                  </div>
                  {activity.maxDivers && (
                    <div className="dive-activity-detail-double">
                      <div className="dive-activity-detail-double__title">
                        <TextDestacadoText>Nº máximo buceadores:</TextDestacadoText>
                      </div>
                      <div className="dive-activity-detail-double__text">
                        <TextText>{activity.maxDivers}</TextText>
                      </div>
                    </div>
                  )}
                  */}
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Disciplina:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>{DiveDisciplinesKeyValues[activity.discipline]}</TextText>
                    </div>
                  </div>
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Tipo de inmersión:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>{DiveKindsKeyValues[activity.kind]}</TextText>
                    </div>
                  </div>
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Nº de inmersiones:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>{activity.diveCount}</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
              </div>
              <div className="dive-activity-detail-left__section">
                <ItemSectionDetail title="Requisitos mínimos" icon={images.clockSvg}>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Se necesita embarcación:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.needBoat ? 'Si' : 'No'}</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
              </div>
              <div className="dive-activity-detail-left__section">
                <ItemSectionDetail title="Requisitos mínimos" icon={images.clockSvg}>
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Edad mínima:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>{`${activity.minimumRequisites.age} años`}</TextText>
                    </div>
                  </div>
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Perfil mínimo buceador:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>
                        {levels.filter(level => level.level === activity.minimumRequisites.level)[0].name}
                      </TextText>
                    </div>
                  </div>
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Profundidad mínima:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>{`Hasta ${transformToUser('depth', activity.minimumRequisites.depth)} ${getUserUnit(
                        'depth'
                      )}`}</TextText>
                    </div>
                  </div>
                  {activity.minimumRequisites.requisite &&
                    activity.minimumRequisites.requisite === 'medicalCertificate' && (
                      <div className="dive-activity-detail-double">
                        <div className="dive-activity-detail-double__title">
                          <TextDestacadoText>Certificado médico:</TextDestacadoText>
                        </div>
                        <div className="dive-activity-detail-double__text">
                          <TextText>{activity.minimumRequisites.data}</TextText>
                        </div>
                      </div>
                    )}
                  {activity.minimumRequisites.requisite && activity.minimumRequisites.requisite === 'diveCount' && (
                    <div className="dive-activity-detail-double">
                      <div className="dive-activity-detail-double__title">
                        <TextDestacadoText>Nº mínimo inmersiones:</TextDestacadoText>
                      </div>
                      <div className="dive-activity-detail-double__text">
                        <TextText>{activity.minimumRequisites.data}</TextText>
                      </div>
                    </div>
                  )}
                  {activity.minimumRequisites.requisite && activity.minimumRequisites.requisite === 'speciality' && (
                    <div className="dive-activity-detail-double">
                      <div className="dive-activity-detail-double__title">
                        <TextDestacadoText>Especialidad:</TextDestacadoText>
                      </div>
                      <div className="dive-activity-detail-double__text">
                        <TextText>{activity.minimumRequisites.data}</TextText>
                      </div>
                    </div>
                  )}
                </ItemSectionDetail>
              </div>
              <div className="dive-activity-detail-left__section">
                <ItemSectionDetail title="Puntos de inmersión" icon={images.clockSvg}>
                  {(status === 'pending' || status === 'confirmed') &&
                    Array.from(Array(activity.diveCount).keys()).map((dive: any, index: number) => (
                      <div key={index} className="activity-detail-general-card-activity">
                        <CardDiverActivity
                          state={'Inmersión'}
                          title={`Inmersión ${index + 1}`}
                          location={'Punto de inmersión no verificado'}
                          type="default" // default | done | registered | pending | unrealized
                        />
                      </div>
                    ))}
                  {status !== 'pending' &&
                    status !== 'confirmed' &&
                    !activity.cancelled &&
                    divesInfo &&
                    divesInfo.map((dive: any, index: number) => {
                      let locationRaw = null;
                      let diveRaw = null;
                      if (dive.diveId) {
                        diveRaw = activity.dives.filter((diveRaw: any) => diveRaw.id === dive.diveId)[0];
                        if (diveRaw) {
                          const site = diveRaw.site;
                          locationRaw = `${site.location}, ${site.state} ${COUNTRIES_KEY_LABEL[site.country]}`;
                        }
                      }
                      return (
                        <div key={index} className="activity-detail-general-card-activity">
                          <CardDiverActivity
                            state={dive.diveId ? 'Inmersión registrada' : 'Inmersión'}
                            title={`Inmersión ${index + 1}`}
                            location={locationRaw ? locationRaw : 'Punto de inmersión no verificado'}
                            date={diveRaw ? diveRaw.date : undefined}
                            type={dive.diveId ? 'registered' : 'default'} // default | done | registered | pending | unrealize
                          />
                        </div>
                      );
                    })}
                </ItemSectionDetail>
              </div>
              <div className="dive-activity-detail-left__section">
                <ItemSectionDetail title="Servicios" icon={images.clockSvg}>
                  <div className="dive-activity-detail-services">
                    {activity.services.length > 0 ? (
                      activity.services.map((service: any, i:any) => {
                        return (
                          <div key={i} className="dive-activity-detail-services__item">
                            <div className="dive-activity-detail-services__item__icon">
                              <img src={images.flagSvg} alt="" />
                            </div>
                            <div className="dive-activity-detail-services__item__text">
                              <TextText>{service.name}</TextText>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                        <div className="dive-activity-detail-services__item">
                          <div className="dive-activity-detail-services__item__text">
                            <TextText>Sin servicios</TextText>
                          </div>
                        </div>
                      )}
                  </div>
                </ItemSectionDetail>
              </div>
              <div className="dive-activity-detail-left__section">
                <ItemSectionDetail title="Equipo incluido" icon={images.clockSvg}>
                  <div className="dive-activity-detail-services">
                    {activity.stock.length > 0 ? (
                      activity.stock.map((stock: any, i:any) => {
                          return (<div key={i} className="dive-activity-detail-services__item">
                            <div className="dive-activity-detail-services__item__icon">
                              <img src={images.flagSvg} alt="" />
                            </div>
                            <div className="dive-activity-detail-services__item__text">
                              <TextText>{stock ? stock.name : ''}</TextText>
                            </div>
                          </div>);
                      })
                    ) : (
                        <div className="dive-activity-detail-services__item">
                          <div className="dive-activity-detail-services__item__text">
                            <TextText>Sin equipo</TextText>
                          </div>
                        </div>
                      )}
                  </div>
                </ItemSectionDetail>
              </div>
              <div className="dive-activity-detail-left__section">
                <ItemSectionDetail title="Alojamiento y comida" icon={images.clockSvg}>
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Incluído:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>Si</TextText>
                    </div>
                  </div>
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Régimen de comidas:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>Pensión completa</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
                <ItemSectionDetail title="Alojamiento y comida" icon={images.accommodation}>
                  <div className="dive-activity-detail-double">
                    <div className="dive-activity-detail-double__title">
                      <TextDestacadoText>Incluído:</TextDestacadoText>
                    </div>
                    <div className="dive-activity-detail-double__text">
                      <TextText>{activity.meal ? 'Sí' : 'No'}</TextText>
                    </div>
                  </div>
                  {activity.meal && (
                    <div className="dive-activity-detail-double">
                      <div className="dive-activity-detail-double__title">
                        <TextDestacadoText>Régimen de comidas:</TextDestacadoText>
                      </div>
                      <div className="dive-activity-detail-double__text">
                        <TextText>{FOOD_ACCOMMODATION_KEY_VALUES[activity.meal]}</TextText>
                      </div>
                    </div>
                  )}
                </ItemSectionDetail>
              </div>
              <div className="dive-activity-detail-left__section">
                <ItemSectionDetail title="Desplazamiento" icon={images.clockSvg}>
                  {activity.transport && activity.transport.transport ? (
                    <>
                      <div className="dive-activity-detail-double">
                        <div className="dive-activity-detail-double__title">
                          <TextDestacadoText>Incluye desplazamiento:</TextDestacadoText>
                        </div>
                        <div className="dive-activity-detail-double__text">
                          <TextText>Si</TextText>
                        </div>
                      </div>
                      <div className="dive-activity-detail-double">
                        <div className="dive-activity-detail-double__title">
                          <TextDestacadoText>Salida desde:</TextDestacadoText>
                        </div>
                        <div className="dive-activity-detail-double__text">
                          <TextText>{activity.transport.departurePoint}</TextText>
                        </div>
                      </div>
                      <div className="dive-activity-detail-double">
                        <div className="dive-activity-detail-double__title">
                          <TextDestacadoText>Medio de transporte:</TextDestacadoText>
                        </div>
                        <div className="dive-activity-detail-double__text">
                          <TextText>{TRANSPORT_TYPES_KEY_VALUES[activity.transport.transport]}</TextText>
                        </div>
                      </div>
                    </>
                  ) : (
                      <div className="dive-activity-detail-double">
                        <div className="dive-activity-detail-double__title">
                          <TextDestacadoText>Incluye desplazamiento:</TextDestacadoText>
                        </div>
                        <div className="dive-activity-detail-double__text">
                          <TextText>No</TextText>
                        </div>
                      </div>
                    )}
                </ItemSectionDetail>
              </div>
              <div className="dive-activity-detail-left__section">
                <ItemSectionDetail title="Observaciones" icon={images.clockSvg}>
                  {activity.comments[lang] ? (
                    <TextText>activity.comments[lang]</TextText>
                  ) : (
                      <TextText>Sin observaciones</TextText>
                    )}
                </ItemSectionDetail>
              </div>
              {/* <div className="dive-activity-detail-left__section">
                <CardActivityBonus
                  status={{ type: 'done', text: 'Finalizada' }}
                  title="Nombre de la actividad super largo 02"
                  date="2 Septiembre 2019 "
                  hour="09:00"
                  list={['Bautismo de buceo', 'Buceo recreativo', 'Especialidad 01']}
                />
              </div> */}
            </div>

            <div className="dive-activity-detail-right">
              <div className="dive-activity-detail-right__card">
                <div className="dive-activity-user-card">
                  <div className="dive-activity-user-card-line" />
                  <div className="dive-activity-user-card-top">
                    <div className="dive-activity-user-card-top__avatar">
                      <img src={images.avatar} alt="" />
                    </div>
                    <div className="dive-activity-user-card-top__chat">
                      <SingleButton className="full-height secondary" icon={images.messageSvg} onClick={() => { }} />
                    </div>
                  </div>
                  <div className="dive-activity-user-card-name">
                    <SubheadDestacadoText>Anastasia Gil de Biedma</SubheadDestacadoText>
                  </div>
                  <div className="dive-activity-user-card-level">
                    <BodyDestacadoText>NIVEL DE BUCEO</BodyDestacadoText>
                  </div>
                </div>
              </div>
              <div className="dive-activity-buy">
                <BuyResume />
              </div>
              <div className="dive-activity-detail-right-section">
                <div className="dive-activity-detail-right-section__title">
                  <BodyDestacadoText>Buceadores (9)</BodyDestacadoText>
                </div>
                <div className="dive-activity-detail-right-section__item">
                  <ItemUser
                    name="Ana Esther Valenzuela Manel"
                    subtitle="Buceador 01"
                    info="Nivel de buceo"
                    avatar={images.avatar}
                  />
                </div>
                <div className="dive-activity-detail-right-section__item">
                  <ItemUser
                    name="Ana Esther Valenzuela Manel"
                    subtitle="Buceador 01"
                    info="Nivel de buceo"
                    avatar={images.avatar}
                  />
                </div>
              </div>
              <div className="dive-activity-detail-right-section">
                <div className="dive-activity-detail-right-section__title">
                  <BodyDestacadoText>Staff (9)</BodyDestacadoText>
                </div>
                <div className="dive-activity-detail-right-section__item">
                  <ItemUser
                    name="Ana Esther Valenzuela Manel"
                    subtitle="Buceador 01"
                    info="Nivel de buceo"
                    avatar={images.avatar}
                  />
                </div>
                <div className="dive-activity-detail-right-section__item">
                  <ItemUser
                    name="Ana Esther Valenzuela Manel"
                    subtitle="Buceador 01"
                    info="Nivel de buceo"
                    avatar={images.avatar}
                  />
                </div>
              </div>
            </div>
          </DiveActivitiesDetailStyle>
        </MainLayoutSection>
      </>
    );
  }
}
