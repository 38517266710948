import * as React from 'react';

import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import { CertifierFaqLayoutStyle } from './certifier-faq-layout-style';

import { RouteComponentProps, withRouter } from 'react-router';
import { getOneAds } from '../../../services/ads';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { images } from '../../../assets/images/index';

interface RouteParams {
  id: string;
}
interface ICertifierFaqProps extends RouteComponentProps<RouteParams>, WithTranslation {}

interface ICertifierFaqState {
  adActive: boolean;
  modal: string;
  ads: any;
  activeFilters: boolean;
}

class CertifierFaq extends React.Component<ICertifierFaqProps, ICertifierFaqState> {
  constructor(props: ICertifierFaqProps) {
    super(props);

    this.state = {
      adActive: false,
      modal: '',
      ads: {},
      activeFilters: false,
    };
  }

  render() {
    const { activeFilters } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderMain title={t('modalMessages:faq')} withSearch={false} />
        <MainLayoutSection>
          {activeFilters && (
            <div className={`ads-detail-table-filters${activeFilters ? ' active' : ''}`}>{/* To Do ADD Filters */}</div>
          )}

          <CertifierFaqLayoutStyle>
            <div className="faq__section">
              <h3 className="faq__section__title">¿Lorem ipsum dolor sit amet?</h3>
              <p className="faq__section__paragraph">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique perspiciatis ab repudiandae repellat
                omnis hic, animi recusandae amet quod tempore dolores voluptate nihil dolore adipisci! Inventore animi
                dolorem commodi vitae.
              </p>
            </div>
            <div className="faq__section">
              <h3 className="faq__section__title">¿Lorem ipsum dolor sit amet?</h3>
              <p className="faq__section__paragraph">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique perspiciatis ab repudiandae repellat
                omnis hic, animi recusandae amet quod tempore dolores voluptate nihil dolore adipisci! Inventore animi
                dolorem commodi vitae.
              </p>
              <p className="faq__section__paragraph">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique perspiciatis ab repudiandae repellat
                omnis hic, animi recusandae amet quod tempore dolores voluptate nihil dolore adipisci! Inventore animi
                dolorem commodi vitae.
              </p>
              <p className="faq__section__paragraph">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique perspiciatis ab repudiandae repellat
                omnis hic, animi recusandae amet quod tempore dolores voluptate nihil dolore adipisci! Inventore animi
                dolorem commodi vitae.
              </p>
            </div>
            <div className="faq__section">
              <h3 className="faq__section__title">¿Lorem ipsum dolor sit amet?</h3>
              <p className="faq__section__paragraph">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique perspiciatis ab repudiandae repellat
                omnis hic, animi recusandae amet quod tempore dolores voluptate nihil dolore adipisci! Inventore animi
                dolorem commodi vitae.
              </p>
            </div>
          </CertifierFaqLayoutStyle>
        </MainLayoutSection>
      </>
    );
  }
}
export default withTranslation(['modalMessages', 'buttons'])(withRouter(CertifierFaq));
