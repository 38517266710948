import React, { Component } from 'react';
import { ModalUserFormContainer } from './modal-user-form-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../main-layout-style';
import { images } from '../../../assets/images';
import ModalContainer from '../../../components/modals/modal-container';

import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import InputBox from '../../../components/input-box';
import SelectBox from '../../../components/select-box';
import MainButton from '../../../components/main-button';

import { ROLES } from '../../../constants/roles';
import { getOneUsers, uploadUsers, createUsers, getUsers } from '../../../services/users';
import { getDayMonthNumberYear } from '../../../utils/time';
import services from '../../../services/services';
import { isValidEmail } from '../../../utils/validation';

interface ModalUserFormProps {
  onCancel: Function;
  showModal: boolean;
  onAccept: Function;
  editMode: boolean;
  userId?: string;
}
interface ModalUserFormState {
  user: any;
  userObject: any;
}

export default class ModalUserForm extends Component<ModalUserFormProps, ModalUserFormState> {
  readonly state: ModalUserFormState;
  constructor(props: ModalUserFormProps) {
    super(props);
    this.state = {
      user: {
        name: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        surname: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        email: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },

        roles: {
          value: [''],
          error: false,
          errorCode: '',
          required: true,
        },
      },
      userObject: {},
    };
  }

  componentDidMount() {
    const { editMode } = this.props;

    if (editMode) {
      this.getOneUsers();
    }
  }

  getOneUsers = async () => {
    const { userId } = this.props;
    const { user } = this.state;
    if (userId) {
      try {
        const response: any = await getOneUsers(userId);
        Object.keys(user).forEach((k: string) => {
          user[k].value = response.data[k];
        });
        this.setState({ user, userObject: response.data });
      } catch (err) {
        console.log(err);
      }
    }
  };

  createUser = async (user: any) => {
    const { onAccept } = this.props;
    const params = {
      filter: {
        where: { email: user.email },
      },
    };
    try {
      const existingUser = (await getUsers(params)).data.data;
      if (existingUser.length > 0) {
        delete user.name;
        delete user.surname;
        delete user.email;
        await uploadUsers(existingUser[0].id, user);
        services.showModalSuccess(
          'Rol asignado',
          `Se ha asignado el rol al usuario con email ${existingUser[0].email}`
        );
      } else {
        await createUsers(user);
        services.showModalSuccess('Usuario creado', 'Se ha creado el usuario y asignado el rol');
      }
      onAccept();
    } catch (err) {
      console.log(err);
    }
  };

  editUser = async (data: any) => {
    const { onAccept, userId } = this.props;
    try {
      await uploadUsers(userId || '', data);
      services.showModalSuccess('Usuario editado', 'El usuario se ha editado correctamente');
      onAccept();
    } catch (err) { }
  };

  changeValue = (value: string, key: string, subKey?: string) => {
    const { user } = this.state;
    if (!subKey) {
      user[key].value = value;
    } else {
      user[key][subKey].value = value;
    }

    this.setState({ user });
  };

  handleAccept = () => {
    const { user } = this.state;
    const { editMode } = this.props;
    let isOk = true;
    Object.keys(user).forEach((k: string) => {
      if (user[k].required && user[k].value === '') {
        user[k].error = true;
        user[k].errorCode = 'required';
        isOk = false;
      } else if (k === 'email' && !isValidEmail(user[k].value)) {
        user[k].error = true;
        user[k].errorCode = 'invalid-email';
        isOk = false;
      } else {
        user[k].error = false;
        user[k].errorCode = '';
      }
    });
    this.setState({ user });
    if (isOk) {
      const data: any = {};
      Object.keys(user).forEach((k: string) => {
        data[k] = user[k].value;
      });

      if (editMode) {
        this.editUser(data);
      } else {
        this.createUser(data);
      }
    }
  };

  render() {
    const { onCancel, showModal, editMode } = this.props;
    const { user, userObject } = this.state;

    return (
      <ModalContainer
        title={`${editMode ? 'Editar' : 'Crear'} usuario`}
        modalClose={() => onCancel()}
        active={showModal}
      >
        <ModalUserFormContainer>
          <div className="modal-create-form">
            <div className="modal-create-form__title">
              <BodyDestacadoText>Datos Personales</BodyDestacadoText>
            </div>
            <div className="modal-create-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      labelText="Nombre del bono"
                      placeholder="Nombre"
                      className="rounded-title"
                      required={true}
                      type="text"
                      errorCode={user.name.errorCode}
                      value={user.name.value}
                      onChange={(value: string) => this.changeValue(value, 'name')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      labelText="Apellidos"
                      placeholder="Apellidos"
                      className="rounded-title"
                      required={true}
                      type="text"
                      errorCode={user.surname.errorCode}
                      value={user.surname.value}
                      onChange={(value: string) => this.changeValue(value, 'surname')}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      labelText="Correo"
                      className="rounded-title"
                      required={true}
                      placeholder="Correo"
                      type="email"
                      errorCode={user.email.errorCode}
                      value={user.email.value}
                      onChange={(value: string) => this.changeValue(value, 'email')}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>
          <div className="modal-create-form">
            <div className="modal-create-form__title">
              <BodyDestacadoText>Permisos</BodyDestacadoText>
            </div>
            <div className="modal-create-form__content">
              <FormGrid>
                {editMode && (
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        placeholder={'99/99/9999'}
                        className="rounded-title"
                        required={true}
                        labelText="Creación"
                        type="email"
                        value=""
                        disabled={editMode}
                        filledValue={getDayMonthNumberYear(userObject.createdAt)}
                        onChange={value => { }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                )}
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      placeholder="Rol"
                      className="rounded-title"
                      required={true}
                      labelText="Rol"
                      optionsText={
                        user.roles.value.incluldes('organization_staff')
                          ? [...ROLES, { value: 'organization_staff', label: 'Staff colaborador' }]
                          : ROLES
                      }
                      optionKey={'value'}
                      optionValue={'label'}
                      errorCode={user.roles.errorCode}
                      initialValue={user.roles.value}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: string) => this.changeValue(value, 'roles')}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>

          <div className="modal-create-buttons">
            <div className="modal-create-buttons__item">
              <MainButton type="primary" text={editMode ? 'Editar' : 'Añadir'} onClick={() => this.handleAccept()} />
            </div>
            <div className="modal-create-buttons__item">
              <MainButton type="secondary-red" text="Cancelar" onClick={() => onCancel()} />
            </div>
          </div>
        </ModalUserFormContainer>
      </ModalContainer>
    );
  }
}
