import { TableColumn } from '../../types/table';

export interface Organization {
  id: string;
  name: string;
  integrated: boolean;
  diversNo: number;
  actionsNo: number;
}

interface Phone {
  prefix: string;
  number: string;
}

interface ContactPerson {
  name: string;
  surname: string;
  email: string;
  phone: Phone;
  [key: string]: any;
}

export interface OrganizationForm {
  name: string;
  email: string;
  phone: Phone;
  website: string;
  contactPerson: ContactPerson;
  logo: any;
  [key: string]: any;
}

export interface InviteOrganizationForm {
  name: string;
  email: string;
  [key: string]: string;
}

export const certifierTableColumns: TableColumn[] = [
  {
    text: 'Nombre',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Tipo',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Nº Buceadores',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Nº Cursos',
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
];

export const marineTableColumns: TableColumn[] = [
  {
    text: 'Nombre',
    filter: false,
    ascendent: true,
  },
  // {
  //   text: 'Tipo',
  //   filter: false,
  //   ascendent: true,
  // },
  {
    text: 'Nº Buceadores',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Acciones',
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
];

export const rowKeys: string[] = ['name', 'integrated', 'diversNo', 'actionsNo', 'detail', 'options'];
