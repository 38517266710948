import React from 'react';
import { ModalLanguagesStyle } from './modal-languages-style';
import ModalContainer from '../../../../../../components/modals/modal-container';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../../main-layout-style';
import { images } from '../../../../../../assets/images';
import MainButton from '../../../../../../components/main-button';
import SubheadDestacadoText from '../../../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../../../../../components/text/body-text/body-text';
import AddItemFormSimple from '../../../../../../components/add-item-form-simple/add-item-form-simple';
import { DiverProfile } from '../../types';
import { FULL_LANGUAGES, FULL_LANGUAGES_KEY_VALUES } from '../../../../../../constants/languages';
import SelectBox from '../../../../../../components/select-box';
import { IDropdown } from '../../../../../../models/generic';
import { setDiverData } from '../../../../../../services/diver';
import services from '../../../../../../services/services';

interface ModalLanguagesProps {
  showModal: boolean;
  onClose: () => void;
  onSave: () => void;
  person: DiverProfile;
}

interface ModalLanguagesState {
  person?: DiverProfile;
  selectedLanguage: string;
  filteredLanguages: any;
  errors: { [s: string]: string };
}

export default class ModalLanguages extends React.Component<ModalLanguagesProps, ModalLanguagesState> {
  constructor(props: ModalLanguagesProps) {
    super(props);
    this.state = {
      selectedLanguage: '',
      errors: {},
      filteredLanguages: FULL_LANGUAGES,
    };
  }
  componentDidMount() {
    const { person } = this.props;
    if (!person.languages) person.languages = [];
    this.setState({ person });
  }

  componentDidUpdate(prevProps: ModalLanguagesProps) {
    const { person } = this.props;
    if (prevProps.person !== person) {
      if (!person.languages) person.languages = [];
      this.setState({ person });
    }
  }

  async handleSaveChanges() {
    const { person } = this.state;
    const { onSave } = this.props;
    if (person && person.languages) {
      await setDiverData(person.id.toString(), { languages: person.languages });
      services.pushNotification({
        title: 'Genial!',
        text: 'El usuario se ha editado correctamente',
        type: 'green',
      });
      onSave();
    }
  }

  addLanguages() {
    const { person, selectedLanguage, filteredLanguages } = this.state;
    if (person && person.languages && selectedLanguage !== '') {
      person.languages.push(selectedLanguage);
      this.setState({ person, selectedLanguage: '' }, () => {
        const newFilteredLanguages = filteredLanguages.filter(
          (lang: any) => person && !person.languages.includes(lang.value)
        );
        this.setState({ filteredLanguages: newFilteredLanguages });
      });
    }
  }

  render() {
    const { onClose, showModal } = this.props;
    const { person, filteredLanguages } = this.state;
    return (
      <ModalContainer className="" modalClose={() => onClose()} active={showModal}>
        <ModalLanguagesStyle>
          <div className="create-user-title">
            <SubheadDestacadoText>Idiomas</SubheadDestacadoText>
          </div>
          <div className="create-user-text">
            <BodyText>
              Añade los idiomas que domine o conozca el usuario para que quede reflejado en el perfil del buceador.
            </BodyText>
          </div>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-1">
                    <SelectBox
                      // errorCode="Error"
                      placeholder="Idiomas"
                      className="rounded"
                      optionKey={'value'}
                      optionValue={'label'}
                      optionsText={filteredLanguages}
                      searchable={true}
                      labelText="Usuario"
                      onChange={value => {
                        this.setState({ selectedLanguage: value });
                      }}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-3">
                    <MainButton
                      type="secondary full-height"
                      iconImg={images.plusSvg}
                      text="Añadir"
                      onClick={() => {
                        this.addLanguages();
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
              {person &&
                person.languages &&
                person.languages.map((lang: string) => {
                  return (
                    <AddItemFormSimple
                      key={lang}
                      text={FULL_LANGUAGES_KEY_VALUES[lang]}
                      onDelete={() => {
                        if (!person || !person.languages) return;
                        person.languages = person.languages.filter(language => lang !== language);
                        this.setState({ person }, () => {
                          const newFilteredLanguages = filteredLanguages.filter(
                            (lang: any) => person && !person.languages.includes(lang.value)
                          );
                          this.setState({ filteredLanguages: newFilteredLanguages });
                        });
                      }}
                    />
                  );
                })}
            </div>
          </div>

          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton type="primary" onClick={() => this.handleSaveChanges()} text="Guardar Cambios" />
            </div>
          </div>
        </ModalLanguagesStyle>
      </ModalContainer>
    );
  }
}
