import * as React from 'react';
import ModalAddFacilities from '../../components/modals/moda-add-facilities/modal-add-facilites';
import ModalAddService from '../../components/modals/moda-add-service/modal-add-service';
import ModalConfirmation from '../../components/modals/modal-confimation/modal-confirmation';
import ModalCreateAdsMembership from '../../components/modals/modal-create-ads-membership/modal-create-ads-membership';
import ModalCreateStaff from '../../components/modals/modal-create-staff/modal-create-staff';
import ModalCreateUser from '../../components/modals/modal-create-user/modal-create-user';
import ModalDelete from '../../components/modals/modal-delete/modal-delete';
import ModalDiveSite from '../../components/modals/modal-dive-site/modal-dive-site';
import ModalDocument from '../../components/modals/modal-document/modal-document';
import ModalIncidenceDetail from '../../components/modals/modal-incidence-detail/modal-incidence-detail';
import ModalIncidenceManagement from '../../components/modals/modal-incidence-management/modal-incidence-management';
import ModalIncidenceType from '../../components/modals/modal-incidence-type/modal-incidence-type';
import ModalLogOut from '../../components/modals/modal-logout/modal-logout';
import ModalSlider from '../../components/modals/modal-slider/modal-slider';
import ModalSuccess from '../../components/modals/modal-success/modal-success';
import ModalSuccessInfo from '../../components/modals/modal-success-info/modal-success-info';
import ModalUploadFiles from '../../components/modals/modal-upload-files/modal-upload-files';
import ModalInfo from '../../components/modals/modal-info/modal-info';
import { images } from '../../assets/images';
import ModalDiverForm from '../../containers/divers/create/diver/modal-diver-form';
import TextText from '../../components/text/text-text/text-text';
import { RenderIcons } from './render-modals-style';
export interface IRenderModalsProps { }

export interface IRenderModalsState {
  imgList: any;
}

export default class RenderModals extends React.Component<IRenderModalsProps, IRenderModalsState> {
  constructor(props: IRenderModalsProps) {
    super(props);

    const imgListAux: any = [];

    Object.keys(images).map((key, index) => {
      imgListAux.push(images[key]);
    });

    this.state = {
      imgList: imgListAux,
    };
  }

  componentDidMount = () => { };

  render() {
    return (
      <div>
        {/* <ModalAddFacilities showModal={false} onClose={() => { }} items={[]} addItems={() => { }} />
        <ModalAddService showModal={true} onClose={() => { }} />

        <ModalCreateAdsMembership
          onCancel={() => { }}
          showModal={false}
          editMode={false}
          onAccept={() => { }}
        />
        <ModalCreateStaff showModal={false} onCancel={() => { }} /> */}
        {/* <ModalCreateUser /> ---- Le falta el contenedor */}

        {/* <ModalDiveSite showModal={false} onClose={() => { }} />
        <ModalDocument showModal={false} onClose={() => { }} />
        <ModalIncidenceDetail
          showModal={false}
          onCancel={() => { }}
          handleButton={() => { }}
        />
        <ModalIncidenceManagement
          showModal={false}
          onCancel={() => { }}
          onAccept={() => { }}
          typeAction={""}
        /> */}
        {/* <ModalIncidenceType
          showModal={false}
          onCancel={() => { }}
          handleRemove={() => { }}
          typeIncidence="" /> */}
        {/* <ModalLogOut
          title="egdfshj"
          subtitle="ljkhdjgfhkad faskdf asflas d fj"
          showModal={false}
          onCancel={() => { }}
          onAccept={() => { }}
        />
        <ModalSlider showModal={false} onClose={() => { }} />

        <ModalSuccess title="egdfshj" text="gfhjk " showModal={false} onClose={() => { }} /> */}

        {/* <ModalSuccessInfo
          clickAccept={() => {}}
          clickCancel={() => {}}
          title={'gdsayuif'}
          subtitle={'ddgkajhjksfjs adfkaj dklfja dskf'}
        /> */}

        {/* <ModalConfirmation
          title="Vas a validar 5 documentos"
          subtitle={'¿estás seguro?'}
          showModal={false}
          onAccept={() => { }}
          onCancel={() => { }}
        />

        <ModalDelete
          title="Vas a eliminar un centro de buceo"
          subtitle="¿estás seguro?"
          showModal={false}
          onRemove={() => { }}
          onCancel={() => { }}
        />
        <ModalUploadFiles showModal={false} onClose={() => this.setState({ modal: '' })} onConfirm={() => { }} />

      */}
        <ModalInfo
          type="blue"
          title={['Su sesión ha ', <span key="modal-expired">caducado</span>]}
          onClose={() => { }}
          text={['Es necesario que inicie la sesión de nuevo.']}
          icon={images.alertBlueSvg}
          showModal={false}
          buttons={[{ text: 'Aceptar', type: 'primary', onClick: () => { } }]}
        />

        <RenderIcons>
          <div className="render-icons">
            {Object.keys(images).map((key: string, index: number) => (
              <div title={key} key={index} className="render-icons__item">
                <img src={images[key]} alt="" />
              </div>
            ))}
            {/* {this.state.imgList.map((img: any, index: number) => (

            ))} */}
          </div>
        </RenderIcons>
      </div>
    );
  }
}
