import React, { Component } from 'react';
import { images } from '../../assets/images';
import { FileItemModalContainer } from './file-item-modal-styles';
import FileUtils from '../../utils/files';
import DicotomicCheckbox from '../dicotomic-checkbox';
import InfoText from '../text/info-text/info-text';
import { AttachedFile } from '../../types/file';
import ItemFile from '../item-file/item-file';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

interface IModalUploadFilesProps extends WithTranslation {
  file: AttachedFile;
  selectable?: boolean;
  onSelect?: (file: AttachedFile) => void;
  onClear?: () => void;
  multiple: boolean;
  className?: string;
}
interface IFileItemModalState {
  img: string;
  isImage: boolean;
}

class FileItemModal extends Component<IModalUploadFilesProps, IFileItemModalState> {
  state = {
    img: '',
    isImage: true,
  };
  componentDidMount() {
    const { file } = this.props;
    if (this.isImage(file.name)) {
      this.getImage(file.data);
    } else {
      this.setState({ isImage: false });
    }
  }

  getImage = async (file: File) => {
    const url = await FileUtils.readFileUrlAsync(file);
    this.setState({ img: url });
  };

  getIcon() {
    const { file } = this.props;
    const fileName = file.name;
    const fileExtension = FileUtils.getExtension(fileName);
    const img = fileExtension
      .toLowerCase()
      .replace(/x([^x]*)$/, '')
      .replace('e', '');
    return (
      <div className="file-item-container-icon">
        <ItemFile type={img} />
      </div>
    );
  }

  isImage(fileName: string) {
    const fileExtension = FileUtils.getExtension(fileName)
      .toLowerCase()
      .replace(/x([^x]*)$/, '');
    return !(fileExtension === 'xls' || fileExtension === 'pdf' || fileExtension === 'doc');
  }

  render() {
    const { file, onSelect, selectable, multiple, onClear, className, t } = this.props;
    const { img, isImage } = this.state;
    if(!file.error){
      return (
        <FileItemModalContainer className={`${file.error || file.errorType ? 'fail' : ''} ${className}`}>
          <div className={`file-item-container-img`}>
            {isImage ? <img src={file.error || file.errorType ? images.alertSvg : img} alt="" /> : this.getIcon()}
          </div>
          <div className="file-item-container-title">
            <InfoText>{file.name}</InfoText>
          </div>
          <div className="file-item-container-subtitle">
            <p>
              {file.errorType
                ? t('components:file-error-type')
                : file.error
                ? `${t('components:file-item-modal')} 2MB.`
                : ''}
            </p>
          </div>
          {selectable && (
            <div
              className={`file-item-container-checkbox ${!file.error && !file.errorType ? 'active' : ''}`}
              onClick={() => {
                if (!file.error && !file.errorType && onSelect) {
                  onSelect(file);
                }
              }}
            >
              <div className="file-item-container-delete-icon">
                <img
                  src={images.closeWhiteSvg}
                  onClick={() => {
                    onClear && onClear();
                  }}
                />
              </div>
            </div>
          )}
        </FileItemModalContainer>
      );
    }
    else{
      return '';
    }
    
  }
}

export default withTranslation(['intro', 'buttons', 'components'])(FileItemModal);
