import * as React from 'react';
import { PlanningActivityDetailStyle } from './planning-activity-detail-style';
import { WithTranslation } from 'react-i18next';
import HeaderMain from '../../../components/header-main/header-main';
import { NavigationTabContainer } from '../../../components/navigation-tabs/navigation-tabs-style';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import { RouteComponentProps, match } from 'react-router';
import ActivityDetailGeneral from './general/activity-detail-general';
import ActivityDetailStaff from './staff/activity-detail-staff';
import ListBookings from '../../../containers/billing/bookings/booking-list/list-bookings';
import { MainLayoutSection } from '../../main-layout-style';
import { postCentreCancelActivity } from '../../../services/centre';
import { deleteActivities, getOneActivities, patchActivity } from '../../../services/activities';
import { IActivity } from '../../../models/activities';
import { capitalize } from '../../../utils/texts';
import { mergeObject } from '../../../utils/other';
import services from '../../../services/services';
import moment from 'moment';
import { ACTIVITY_STATES_KEY_VALUES } from '../../../constants/states';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import ModalActivityConfirmation from '../../../components/modals/modal-confimation-activity/modal-confirmation-activity';
import ModalActivityCancel from '../../../components/modals/modal-activity-cancelation/modal-activity-cancelation';
import ModalActivityConfirmationCourse from '../../../components/modals/modal-confimation-activity/modal-confirmation-activity-course';
import PlanningEndActivity from './modals/planning-end-activity/planning-end-activity';
import { connect } from 'react-redux';
import IStoreState from '../../../redux/store/IStoreState';
import { getExpirationDate } from '../../../utils/activities';
import { now } from 'lodash';
import * as dateFns from 'date-fns';

export interface IPlanningActivityDetailProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
    activityId: string;
    diver: string;
  }>;
  staffPermissions?: any;
}

export interface IPlanningActivityDetailState {
  activity?: IActivity;
  status: string;
  section: string;
  renderModal: string;
  canDelete: boolean;
  showDelete: boolean;
}

class PlanningActivityDetail extends React.Component<IPlanningActivityDetailProps, IPlanningActivityDetailState> {
  constructor(props: IPlanningActivityDetailProps) {
    super(props);

    this.state = {
      section: 'general',
      renderModal: '',
      status: '',
      canDelete: false,
      showDelete: false,
    };
  }

  componentDidMount() {
    this.getActivity();
  }

  async getActivity() {
    const {
      match: {
        params: { activityId },
      },
    } = this.props;

    const params = {
      filter: {
        relations: ['course', 'course.certifier', 'services', 'sites', 'stock', 'kind'],
      },
    };
    const params2 = {
      filter: {
        relations: ['category', 'exclusiveStock', 'exclusiveServices', 'cancellationPolicy', 'divers.user'],
      },
    };
    const params3 = {
      filter: {
        relations: ['bookings', 'bookings.divers', 'template', 'template.cancellationPolicy'],
      },
    };
    const params4 = {
      filter: {
        relations: ['staff_activities', 'staff_activities.staff', 'staff.user', 'staff.documents'],
      },
    };
    const resp = await getOneActivities(activityId, params);
    const resp2 = await getOneActivities(activityId, params2);
    const resp3 = await getOneActivities(activityId, params3);
    const resp4 = await getOneActivities(activityId, params4);
    const activity: IActivity = mergeObject(mergeObject(mergeObject(resp.data, resp2.data), resp3.data), resp4.data);
    const activityDate = getExpirationDate(activity);
    let canDelete = false;
    if (!resp3.data.bookings || resp3.data.bookings.length == 0) {
      canDelete = true;
    }
    const now = moment();
    let status = '';


    if (activity.finished || activityDate.isBefore(now)) {
      if(activity.bookings && activity.bookings.length > 0){
        status = 'finished';
      }
      else{
        status = 'undone';
      }
    } else if (activity.confirmed && activityDate.isBefore(now)) {
      status = 'done';
    } else if (!activity.confirmed && activityDate.isBefore(now)) {
      status = 'undone';
    } else if (activity.confirmed) {
      status = 'confirmed';
    } else {
      status = 'pending';
    }
    if (activity.cancelled) {
      status = 'cancelled';
    }

    this.setState({ activity, status, canDelete });
  }

  async cancelActivity() {
    const {
      match: {
        params: { id, activityId },
      },
    } = this.props;
    await postCentreCancelActivity(id, activityId);
    services.pushNotification({
      title: '¡Cancelada!',
      text: 'La actividad ha sido cancelada con éxito',
      type: 'red',
    });
    this.getActivity();
  }

  reloadActivity() {
    this.getActivity();
  }
  removeActivity = async () => {
    if (this.state.activity) {
      await deleteActivities(this.state.activity.id.toString());
    }
    this.props.history.goBack();
    this.setState({ showDelete: false });
  }
  renderContent() {
    const { section, activity, status } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;

    switch (section) {
      case 'general':
        return (
          <ActivityDetailGeneral
            activity={activity}
            centreId={id}
            status={status}
            onReload={() => this.reloadActivity()}
          />
        );
      case 'divers':
        return <ListBookings activity={activity} />;
      case 'staff':
        return <ActivityDetailStaff activity={activity} centreId={id} onReload={() => this.reloadActivity()} />;
      case 'rate':
        break;
      default:
        break;
    }
  }
  noMoreReservations = async () => {
    if (this.state.activity && this.state.activity.id) {
      const res = await patchActivity(this.state.activity.id.toString(), { completed: true });
      this.state.activity.completed = true;
      this.setState({ ...this.state });
    }
  }
  moreReservations = async () => {
    if (this.state.activity && this.state.activity.id) {
      const res = await patchActivity(this.state.activity.id.toString(), { completed: false });
      this.state.activity.completed = false;
      this.setState({ ...this.state });
    }

  }
  render() {
    const {
      match: {
        params: { id, activityId },
      },
      staffPermissions,
    } = this.props;
    const { section, activity, renderModal, status } = this.state;


    let subtitleText = ACTIVITY_STATES_KEY_VALUES[status] ? ACTIVITY_STATES_KEY_VALUES[status].toUpperCase() : '';
    if (this.state.activity && this.state.activity.bookings && this.state.activity.bookings.length === 0 && status !== 'undone') {
      subtitleText = "Planificada";
    }

    return (
      <>
        <HeaderMain
          goBack={true}
          title={activity ? capitalize(activity.name) : ''}
          subtitle={{ text: subtitleText, type: 'red' }}
          secondaryButtonBorderRed={
            activity && !activity.completed && !activity.cancelled ? { text: 'No admitir mas reservas', onClick: this.noMoreReservations, className: 'secondary-active' } :
              activity && activity.completed && !activity.cancelled ? { text: 'Admitir mas reservas', onClick: this.moreReservations, className: 'secondary-red' } :
                undefined
          }
          secondaryButtonRed={
            (status === 'pending' || status === 'undone' || status === 'confirmed') && !staffPermissions && !this.state.canDelete
              ? {
                text: 'Cancelar',
                onClick: () => this.setState({ renderModal: 'modal-cancel-activity' }),
              }
              : undefined
          }
          secondaryButton={this.state.canDelete ? { text: "Eliminar Planificación", onClick: () => { this.setState({ showDelete: true }) } } : undefined}
          mainButton={undefined}
        />
        {renderModal === 'modal-cancel-activity' && (
          <ModalActivityCancel
            showModal={renderModal === 'modal-cancel-activity'}
            centreId={id}
            activityId={activityId}
            onSuccess={() => {
              services.pushNotification({
                title: '¡Cancelada!',
                text: 'La actividad se ha cancelado correctamente',
                type: 'red',
              });
              this.setState({ renderModal: '' }, () => this.getActivity());
            }}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}
        {activity && renderModal === 'modal-finish' && (
          <PlanningEndActivity
            centreId={id}
            activity={activity}
            showModal={renderModal === 'modal-finish'}
            onSuccess={() => {
              services.pushNotification({
                title: '¡Finalizada!',
                text: 'La actividad se ha finalizado correctamente',
                type: 'green',
              });
              this.setState({ renderModal: '' }, () => this.getActivity());
            }}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}
        <MainLayoutSection>
          <PlanningActivityDetailStyle>
            <NavigationTabContainer>
              <div
                className={`navigation-item ${section === 'general' && 'active'}`}
                onClick={() => this.setState({ section: 'general' })}
              >
                <BodyDestacadoText>Datos generales</BodyDestacadoText>
              </div>
              <div
                className={`navigation-item ${section === 'divers' && 'active'}`}
                onClick={() => this.setState({ section: 'divers' })}
              >
                <BodyDestacadoText>Reservas</BodyDestacadoText>
              </div>
              <div
                className={`navigation-item ${section === 'staff' && 'active'}`}
                onClick={() => this.setState({ section: 'staff' })}
              >
                <BodyDestacadoText>Staff</BodyDestacadoText>
              </div>
              <div
                className={`navigation-item disable ${section === 'rate' && 'active'}`}
                onClick={() => this.setState({ section: 'rate' })}
              >
                <BodyDestacadoText>Valoraciones</BodyDestacadoText>
              </div>
            </NavigationTabContainer>
            {this.renderContent()}
          </PlanningActivityDetailStyle>
        </MainLayoutSection>
        {renderModal === 'modal-confirm' && activity && !activity.courseInfo && (
          <ModalActivityConfirmation
            activity={activity}
            centreId={id}
            showModal={renderModal === 'modal-confirm'}
            onAccept={() => {
              services.pushNotification({
                title: '¡Confirmada!',
                text: 'La actividad ha sido confirmada con éxito',
                type: 'green',
              });
              this.setState({ renderModal: '' }, () => this.getActivity());
            }}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}

        {renderModal === 'modal-confirm' && activity && activity.courseInfo && (
          <ModalActivityConfirmationCourse
            activity={activity}
            centreId={id}
            showModal={renderModal === 'modal-confirm'}
            onAccept={() => {
              services.pushNotification({
                title: '¡Confirmada!',
                text: 'La actividad ha sido confirmada con éxito',
                type: 'green',
              });
              this.setState({ renderModal: '' }, () => this.getActivity());
            }}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}
        <ModalDelete
          title=""
          subtitle="Estás a punto de eliminar la planificación de la actividad. ¿Estás seguro?"
          onCancel={() => { this.setState({ showDelete: false }) }}
          onRemove={() => { this.removeActivity() }} showModal={this.state.showDelete} />
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(PlanningActivityDetail);
