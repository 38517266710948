import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const CancellationPolicyStyle = styled.div`
  .cancellation-policy-status {
    display: inline-block;
    margin: 5px 0 0 20px;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    padding: 5px 8px 7px 8px;
    border-radius: 2px;

    &--deactivated {
      color: ${colors['CO-003/100']};
      background-color: ${colors['CO-003/10']};
    }

    &--activated {
      color: ${colors['CO-001/100']};
      background-color: ${colors['CO-001/15']};
    }
  }
  .cancellation-levels-tabs {
  }
  .cancellation-levels-line {
    border-bottom: 1px solid ${colors['NE-002/100']};
    margin-bottom: 60px;
  }

  .cancellation-levels-title {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }
  .cancellation-levels-text {
    margin-bottom: 78px;
    p {
      margin-bottom: 20px;
      &:last-child  {
        margin-bottom: 0px;
      }
    }
  }

  .cancellation-levels-table {
    table  {
      text-align: left;
      thead {
        color: ${colors['NE-002/100']};

        th  {
          padding-bottom: 24px;
          width: 280px;
        }
      }
      tbody {
        td {
          padding-bottom: 20px;
        }
      }
    }
  }
`;
