import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const HeaderDropdownStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 22px;

  .header-dropdown__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
  }

  .header-dropdown__item {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: ${colors['SE-004/75']};
    cursor: pointer;
  }
`;
