import React, { Component } from 'react';
import DiversLayout from './divers-layout';
import services from '../../services/services';
import { DIVERS_PER_PAGE } from '../../constants/data';
import { Diver } from './types';
import { RouteComponentProps, match } from 'react-router';
import { getCentreDivers, getCentreDiversSearch } from '../../services/centre';

interface DiversFuncionalProps extends RouteComponentProps {
  match: match<{
    id: string;
  }>;
  t: any;
}

interface DiversFuncionalState {
  items: Diver[];
  totalItems: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  page: number;
  orderAscendent: string;
}

class DiversFuncional extends Component<DiversFuncionalProps, DiversFuncionalState> {
  state: DiversFuncionalState = {
    items: [],
    totalItems: 0,
    totalPages: 0,
    searchFilter: '',
    skipPage: 0,
    page: 1,
    orderAscendent: '',
  };

  componentDidMount() {
    this.getDivers();
  }
  async getDivers() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { searchFilter, skipPage, orderAscendent } = this.state;


    const params = {
      filter: {
        where: { isStaff: false },
        skip: skipPage,
        search: searchFilter,
        limit: DIVERS_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: ['user', 'bookings', 'bookings.activity', 'documents'],
      },
    };

    const divers = (await getCentreDivers(id, params)).data;

    if (divers.count > 0) {
      const items: any[] = await Promise.all(
        divers.data.map(async (item: any) => {

          if (item.documents.length > 0) {
            let titulations = []
            for (let i = 0; i < item.documents.length; i++) {
              if (item.documents[i].kind == "diving license") {
                titulations.push(item.documents[i].name + ' ')
              }
            }
            item.level = titulations;
          }
          const {
            id,
            level,
            bookings,
            dives,
            nif,
            user: { name, surname, picture },
          } = item;
          return {
            id,
            name,
            surname,
            picture,
            level,
            bookings,
            dives,
            ghost: !nif,
          };
        })
      ).then(results => results);

      const totalItems: number = divers.count;
      const totalPages: number = Math.ceil(totalItems / DIVERS_PER_PAGE);
      this.setState({ items, totalItems, totalPages });
    } else {
      this.setState({ items: [], totalItems: 0, totalPages: 0 });
    }
  }

  setPage(page: number) {
    const { totalItems } = this.state;
    const skip: number = DIVERS_PER_PAGE * (page - 1);
    if (skip < totalItems) {
      this.setState({ skipPage: skip, page }, () => {
        this.getDivers();
      });
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t
    } = this.props;
    const { items, totalPages, page, totalItems } = this.state;
    return (
      <DiversLayout t={t}
        items={items}
        totalPages={totalPages}
        page={page}
        setPage={page => this.setPage(page)}
        refreshData={() => this.getDivers()}
        totalItems={totalItems}
        onSearch={(value: string) => {
          this.setState({ searchFilter: value, page: 1, skipPage: 0 }, () => {
            this.getDivers();
          });
        }}
        centreId={id}
      />
    );
  }
}

export default DiversFuncional;
