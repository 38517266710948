import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalInfoStyle = styled.div`
  width: 416px;
  padding: 62px 32px 32px;
  .modal-info-pop  {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    width: 100%;
  }

  .modal-info-img  {
    padding: 20px;
    border-radius: 100%;
    width: 68px;
    background: ${colors['PR-001/10']};
    height: 68px;
    margin-bottom: 20px;
  }
  .modal-info-title {
    margin-bottom: 20px;
    text-align: center;
    span {
      color: ${colors['PR-001/100']};
    }
  }
  .modal-info-text {
    text-align: center;
    margin-bottom: 40px;
  }
  .modal-info-buttons  {
    width: 280px;
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .modal-info-input {
    width: 280px;
  }
  .modal-info-input {
    width: 280px;
  }

  &.red {
    .modal-info-img  {
      background: ${colors['CO-003/15']};
    }
    .modal-info-title {
      span {
        color: ${colors['CO-003/100']};
      }
    }
  }
  &.blue-title {
    .modal-info-title span {
      color: ${colors['PR-001/100']} !important;
    }
  }
`;
