import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalIncidenceDetailStyle = styled.div`
  padding: 32px;
  width: 416px;
  color: ${colors['NE-001/100']};

  .modal-incidence-detail-status {
    margin-bottom: 21px;
    text-transform: uppercase;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 3px;
    &.open {
      color: ${colors['CO-004/100']};
      background: ${colors['CO-004/15']};
    }
    &.solved {
      color: ${colors['CO-001/100']};
      background: ${colors['CO-001/15']};
    }
    &.closed  {
      color: ${colors['PR-001/100']};
      background: ${colors['PR-001/10']};
    }
  }

  .modal-incidence-detail-title {
    margin-bottom: 8px;
    p {
      display: inline;
    }
    &__icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .modal-incidence-detail-date {
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
  }

  .modal-incidence-detail-user  {
    margin-bottom: 40px;
    display: flex;
    &__avatar  {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 12px;
      img  {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }
    }
    &__info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      &__type {
        color: ${colors['NE-002/100']};
      }
    }
  }

  .modal-incidence-detail-image  {
    display: flex;
    margin-bottom: 32px;

    .modal-incidence-detail-image-img {
      width: 140px;
      height: 108px;
      background: #c4c4c4;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 12px;
      position: relative;
      &__veil {
        &:hover {
          opacity: 1;
        }
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(0, 0, 25, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        &__img {
          width: 24px;
          height: 24px;
          display: flex;
        }
      }
      img {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
        z-index: -1;
      }
    }
    .modal-incidence-detail-image-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__bottom {
        &__subtitle {
          color: ${colors['NE-002/100']};
        }
        &__name  {
          font-size: 12px;
          line-height: 18px;
          font-weight: 300;
        }
      }
    }
  }

  .modal-incidence-detail-section {
    margin-bottom: 24px;
    &.blue {
      &__text  {
        color: ${colors['PR-001/100']};
      }
    }
    &:last-child  {
      margin-bottom: 40px;
    }
    &__title {
      margin-bottom: 6px;
      text-transform: uppercase;
      color: ${colors['NE-002/100']};
    }
    &__text {
      p {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .modal-incidence-detail-buttons {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .modal-incident-detail-historic {
    border-top: 1px solid ${colors['NE-002/100']};
    padding-top: 40px;
    .modal-incident-detail-historic-title {
      color: ${colors['NE-002/100']};
      text-transform: uppercase;
    }
    .modal-incident-detail-historic-pop {
      background: ${colors['NE-002/10']};
      border-radius: 5px;
      padding: 17px 12px;
      margin-bottom: 20px;
      &:last-child  {
        margin-bottom: 40px;
      }
    }
    .modal-incident-detail-historic-pop-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
      &__title {
        display: flex;
        align-items: center;
        &__icon {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
        &__text {
          & {
            color: ${colors['CO-001/100']};
          }
        }
      }
      &__date {
        font-size: 10px;
        line-height: 18px;
        font-weight: 300;
      }
    }

    .modal-incident-detail-historic-pop-message  {
      &__text {
        font-size: 10px;
        line-height: 16px;
        font-weight: 300;
        p {
          margin-bottom: 12px;
          &:last-child  {
            margin-bottom: 0px;
          }
        }
      }
      &__center {
        margin-bottom: 12px;
      }

      &__subtitle {
        margin-bottom: 4px;
      }
    }
  }
`;
