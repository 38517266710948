import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalSliderStyle = styled.div`
  position: relative;
  .modal-slider-slick {
    width: 780px;
    z-index: -1;

    &__left {
      width: 30px;
      height: 30px;
      padding: 5px
      position: absolute;
      top: 240px;
      left: 32px;
      z-index: 9;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: white;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 100%;
        opacity: 0.16;
      }
    }
    &__right {
      width: 30px;
      height: 30px;
      padding: 5px
      position: absolute;
      top: 240px;
      right: 32px;
      z-index: 9;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: red;
        position: absolute;
        background: white;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 100%;
        opacity: 0.16;
      }
    }
  }
  .modal-slider-slick-slide  {
    width: 780px;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    .slick-slide {
      &:focus {
        outline: none;
      }
    }
    img {
      height: 100%;
      width: auto;
      min-width: 100%;
      object-fit: contain;
    }
  }
  .modal-slider-info {
    padding: 32px;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    .modal-slider-info-top {
      display: flex;
      justify-content: space-between;
    }
    .modal-slider-info-bottom{
      border-top: 1px solid ${colors['NE-002/100']};
      margin-top: 32px;
      padding-top: 24px;
    }
    &__text {
      &__title {
        margin-bottom: 6px;
        color: ${colors['NE-001/100']};
      }
      &__file {
        margin-bottom: 12px;
        color: ${colors['NE-002/100']};
      }
    }
    &__buttons {
      display: flex;
      &__item {
        margin-right: 20px;
        &:last-child  {
          margin-right: 0px;
        }
      }
    }
  }

    &.hide {
      opacity: 0;
      color: ${colors['NE-001/100']};
    }
  }
`;
