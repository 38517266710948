import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const RolesContainer = styled.div`
  height: 100%;
  .role-managment-table {
    margin-bottom: 60px;
    &__title {
    }
    &__more {
      margin-top: 32px;
      max-width: 111px;
    }
  }
  .role-managment-pagination {
    margin-bottom: 20px;
  }

  .roles-managment-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    &__button {
      width: 105px;
    }
    margin-bottom: 32px;
  }

  .roles-managment-advice {
    color: ${colors['CO-003/75']};
    font-size: 12px;
  }
`;
