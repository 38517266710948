import * as React from 'react';
import { CancellationLineStyle } from './cancelation-line-style';
import LabelText from '../text/label-text/label';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation, } from 'react-i18next';
export interface ICancellationLineProps extends RouteComponentProps, WithTranslation {
  hour72?: string;
  hour48?: string;
  hour24?: string;
}

export interface ICancellationLineState { }

class CancellationLine extends React.Component<ICancellationLineProps, ICancellationLineState> {

  constructor(props: ICancellationLineProps) {
    super(props);
  }

   render() {
    const { hour24, hour48, hour72, t } = this.props;
    return (
      <CancellationLineStyle>
        <div className="cancellation-line-track green">
          <div className="cancellation-line-track-cicle">
            <div className="cancellation-line-track-cicle__percent">
              <LabelText>{hour72}%</LabelText>
            </div>
            <div className="cancellation-line-track-cicle__dot" />
            <div className="cancellation-line-track-cicle__hours">
              <LabelText>72 {t('components:cancelation.hours')}</LabelText>
            </div>
          </div>
        </div>
        <div className="cancellation-line-track yellow">
          <div className="cancellation-line-track-cicle">
            <div className="cancellation-line-track-cicle__percent">
              <LabelText>{hour48}%</LabelText>
            </div>
            <div className="cancellation-line-track-cicle__dot" />
            <div className="cancellation-line-track-cicle__hours">
              <LabelText>48 {t('components:cancelation.hours')}</LabelText>
            </div>
          </div>
        </div>
        <div className="cancellation-line-track pink">
          <div className="cancellation-line-track-cicle">
            <div className="cancellation-line-track-cicle__percent">
              <LabelText>{hour24}%</LabelText>
            </div>
            <div className="cancellation-line-track-cicle__dot" />
            <div className="cancellation-line-track-cicle__hours">
              <LabelText>24 {t('components:cancelation.hours')}</LabelText>
            </div>
          </div>
        </div>
        <div className="cancellation-line-track red">
          <div className="cancellation-line-track-cicle">
            <div className="cancellation-line-track-cicle__percent" />
            <div className="cancellation-line-track-cicle__dot" />
            <div className="cancellation-line-track-cicle__hours">
              <LabelText>{t('components:cancelation.activity')}</LabelText>
            </div>
          </div>
        </div>
      </CancellationLineStyle>
    );
  }
}

export default withTranslation(['components'])(withRouter(CancellationLine));
