import * as React from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import { MainLayoutSection } from '../../main-layout-style';
import { NavigationTabContainer } from '../../../components/navigation-tabs/navigation-tabs-style';
import { NavLink } from 'react-router-dom';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import UnavailableContent from '../../../components/unavailable-content/unavailable-content';

export interface IMyCenterAccomodationProps {}

export interface IMyCenterAccomodationState {
  section: string;
}

export default class MyCenterAccomodation extends React.Component<
  IMyCenterAccomodationProps,
  IMyCenterAccomodationState
> {
  constructor(props: IMyCenterAccomodationProps) {
    super(props);

    this.state = {
      section: 'accomodation',
    };
  }

  render() {
    const { section } = this.state;
    return (
      <>
        <HeaderMain title={'Perfil de centro'} />

        <MainLayoutSection>
          <NavigationTabContainer>
            <div
              className={`navigation-item ${section === 'accomodation' && 'active'}`}
              onClick={() => this.setState({ section: 'accomodation' })}
            >
              <BodyDestacadoText>Alojamiento</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${section === 'food' && 'active'}`}
              onClick={() => this.setState({ section: 'food' })}
            >
              <BodyDestacadoText>Regimen de comidas</BodyDestacadoText>
            </div>
          </NavigationTabContainer>
          <UnavailableContent body={'Si dispones de alojamiento propio, podrás gestionarlo también en Diveasapp.'}/>
        </MainLayoutSection>
      </>
    );
  }
}
