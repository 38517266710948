import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import * as React from 'react';
import { BookingsDataStyle } from './booking-data-style'
import ItemSectionDetail from '../../../../../components/item-sections-detail/item-sections-detail';
import { images } from '../../../../../assets/images';
import TextDestacadoText from '../../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../../components/text/text-text/text-text';
import ModalContainer from '../../../../../components/modals/modal-container';
import { ModalCreateActivitiesStyle } from '../../../../dive-offers/activities/modal-create-activities/modal-create-activities-style';
import BookingFormDivers from '../../booking-form/booking-form-divers/booking-form-divers';
import BookingFormAnotherServices from '../../booking-form/booking-form-another-services/booking-form-another-services';
import IStoreState from '../../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';
import TextArea from '../../../../../components/form-components/input/textarea';
import Button from '../../../../../components/buttons/main-button/button';
import { CENTRE_SERVICES_KEY_VALUE } from '../../../../../constants/centre-services';

const BookingData = (props: any): JSX.Element => {

  const [renderModal, setRenderModal] = useState('')
  const [bookingLines, setBookingLines] = useState<any>([]);
  const [divers, setDivers] = useState<any>([]);
  const [commentsDiver, setCommentsDiver] = useState('')
  const  { t } = useTranslation(['modalMessages', 'layouts']);

  useEffect(() => {
    updateBookingLines(props.booking.lines)
  }, [props.booking.lines]);

  useEffect(() => {
    if (props.userData) {
      addUserToDivers(props.userData)
    }
  }, [props.userData]);

  const addUserToDivers = (userData: any) => {
    const diversList = [userData].concat(props.booking.divers);
    setDivers(diversList)
  }

  const updateBookingLines = (newBookingLines: any = []) => {
    if (newBookingLines.length > 0) {
      if (newBookingLines[0].accountParticipants) {
        setBookingLines(newBookingLines)
      } else {
        const bookingLines = findAccountParticipantsOfLines(newBookingLines)
        bookingLines.pop()
        setBookingLines(bookingLines)
      }
    }
  };

  const findAccountParticipantsOfLines = (lines: any) => {
    for (let i = 0; i < lines.length; i++) {
      const element = lines[i];
      lines[i].accountParticipants = 0;
      for (let j = 0; j < lines.length; j++) {
        const element2 = lines[j];
        if (element.concept === element2.concept) {
          lines[i].accountParticipants += 1
        }
      }
    }
    return deleteDuplicated(lines, 'concept')
  }

  const deleteDuplicated = (arr: any, prop: any) => {
    const newArray = [];
    const lookup = [];

    for (const i in arr) {
      lookup[arr[i][prop]] = arr[i];
    }
    for (const i in lookup) {
      newArray.push(lookup[i]);
    }
    return newArray;
  }

  const saveComments = () => {
    // setCommentsDiver()
  }

  const renderServices = (line: any, pos: number) => {
    return (
      <div className="booking-detail-general-double" key={pos}>
        <div className="booking-detail-general-double__title">
          <TextDestacadoText> {CENTRE_SERVICES_KEY_VALUE[line.concept]}:</TextDestacadoText>
        </div>
        <div className="booking-detail-general-double__text">
          <TextText>x{line.accountParticipants}</TextText>
        </div>
      </div>
    )
  }

  const renderDivers = (diver: any, pos: number) => {
    const titulations = diver.documents.filter((item: any) => item.course);
    const otherDocs = diver.documents.filter((item: any) => !item.course);

    return (
      <div className="booking-detail-general-double" key={pos}>
        <div className="booking-detail-general-double__title">
          <TextText>{diver.diverId}</TextText>
        </div>
        <div className="booking-detail-general-double__text">
          <TextText>{diver.user.name} {diver.user.surname}</TextText>
        </div>
        <div className="booking-detail-general-double__text">
          {titulations.length ? <TextDestacadoText>Titulaciones:</TextDestacadoText> : ""}
          {titulations.length ?
            (titulations.map((item: any, index: number) => {
              return (
                <TextText key={index}>{item.name}</TextText>
              )
            })
            ) : ""}

          {otherDocs.length ? <TextDestacadoText>{t('modalMessages:otherdoc')}:</TextDestacadoText> : ""}
          {otherDocs.length ?
            (otherDocs.map((item: any, index: number) => {
              return (
                <TextText key={index}>{item.name}</TextText>
              )
            })
            ) : ""}

        </div>
      </div>
    )
  }

  return (
    <BookingsDataStyle>
      <div className="booking-detail-general-container">
        <div className="booking-detail-general-left">
          {
            props.create ? <BookingFormDivers /> : ''
          }
          <div className="booking-detail-general-left__section">
            <ItemSectionDetail
              title={t('modalMessages:extraserv')}
              icon={images.serviceServices}
              onEdit={props && props.booking && props.booking.lines == 0 ? () => setRenderModal('edit-another-services') : undefined}
            >
              {
                bookingLines.map((item: any, index: number) => {
                  return renderServices(item, index)
                })
              }
            </ItemSectionDetail>
          </div>

          {
            props.create !== true ? <div className="booking-detail-general-left__section">
              <ItemSectionDetail
                title={t('modalMessages:modal-booking-detail.nrodivers')}
                icon={images.diversSvg}
              // onEdit={() => this.setState({ renderModal: 'edit-divers-data' })}
              >
                <div className="booking-detail-general-double">
                  <div className="booking-detail-general-double__title">
                    <TextText color={'#A6ACB7'}>ID Diveasapp</TextText>
                  </div>
                  <div className="booking-detail-general-double__text">
                    <TextText color={'#A6ACB7'}>{t('layouts:fullname')}</TextText>
                  </div>
                  <div className="booking-detail-general-double__text">
                    <TextText color={'#A6ACB7'}>{t('layouts:title')}</TextText>
                  </div>
                </div>
                {
                  divers.map((item: any, index: number) => {
                    return renderDivers(item, index)
                  })
                }
              </ItemSectionDetail>
            </div> : ''
          }

          <div className="booking-detail-general-left__section">
            <div className="booking-detail-comments">
              <div className="booking-detail-comments-header">
                <TextText>{t('layouts:bookings.comments')}</TextText>
                {props.create ?
                  <span onClick={() => setRenderModal('edit-comments-diver')}>Editar</span>
                  : ''
                }
              </div>
              <div className="comments">
                {props.create ? <p>{commentsDiver}</p> : <p>{props.booking.comments}</p>}
              </div>
            </div>
          </div>

          {/* Modals */}
          {renderModal === 'edit-divers-data' && (
            <ModalContainer
              title={t('layouts:bookings.booking.editdivers')}
              modalClose={() => setRenderModal('')}
              active={renderModal === 'edit-divers-data'}
            >
              <ModalCreateActivitiesStyle>
                <BookingFormDivers divers={props.booking.divers} />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )}
          {renderModal === 'edit-another-services' && (
            <ModalContainer
              title={t('layouts:bookings.booking.editserv')}
              modalClose={() => setRenderModal('')}
              active={renderModal === 'edit-another-services'}
            >
              <ModalCreateActivitiesStyle>
                <BookingFormAnotherServices
                  bookingLines={bookingLines}
                  activity={props.activity}
                  onNext={(newBookingLines: any) => { updateBookingLines(newBookingLines); setRenderModal('') }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )}
          {renderModal === 'edit-comments-diver' && (
            <ModalContainer
              title={t('layouts:bookings.booking.editdivercom')} 
              modalClose={() => setRenderModal('')}
              active={renderModal === 'edit-comments-diver'}
            >
              <ModalCreateActivitiesStyle>
                <TextArea
                  value={commentsDiver}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCommentsDiver(e.target.value)}
                />
                <div className="modal-footer" style={{ marginTop: '3em' }}>
                  <Button
                    onClick={() => saveComments()}
                    text={t('layouts:billing-information.save')}
                    type={'primary'}
                  />
                </div>
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )}
        </div>
      </div>
    </BookingsDataStyle>
  )
}

function mapStateToProps(state: IStoreState) {
  return {
    userData: state.userData.value,
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(BookingData);