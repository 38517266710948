import styled from 'styled-components';

export const CreateActivitiesIncludedEquipmentStyle = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  gap: 16px;

  .create-activities-wrapper {
    margin-bottom: 48px;
  }
  .create-activities-dicotomic-container {
    padding-top: 7px;
     display: flex;
    &__text {
      width: 100%;
    }
    &__icon {
      margin-right: 12px;
      width: 24px;
      height: 24px;
    }
  }
`;
