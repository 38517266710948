import { LANGUAGES_KEY_VALUES, FULL_LANGUAGES_KEY_VALUES } from '../constants/languages';
import {
  ERROR_MESSAGES_ES,
  ERROR_MESSAGES_EN,
  ERROR_MESSAGES_IT,
  ERROR_MESSAGES_FR,
  ERROR_MESSAGES_DE,
  ERROR_MESSAGES_PT,
} from '../constants/error-messages';

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getLanguagesText = (langs: string[]) => {
  let str = '';
  langs.forEach((lang: string) => {
    str += `${LANGUAGES_KEY_VALUES[lang]}, `;
  });
  return str.slice(0, -2);
};

export const getFullLanguagesText = (langs: string[]) => {
  let str = '';
  langs.forEach((lang: string) => {
    str += `${FULL_LANGUAGES_KEY_VALUES[lang]}, `;
  });
  return str.slice(0, -2);
};

export const decimalToGrades = (lat: number, lng: number) => {
  let x = '';
  let y = '';
  const latn = Math.abs(lat); /* Devuelve el valor absoluto de un número, sea positivo o negativo */
  const latgr = Math.floor(latn * 1); /* Redondea un número hacia abajo a su entero más cercano */
  const latmin = Math.floor((latn - latgr) * 60); /* Vamos restando el número entero para transformarlo en minutos */
  const latseg = ((latn - latgr) * 60 - latmin) * 60; /* Restamos el entero  anterior ahora para segundos */
  const latc = `${latgr} º ${latmin} ' ${latseg.toFixed(2)} "`; /* Prolongamos a centésimas de segundo */
  if (lat > 0) {
    x = `${latc} N`; /* Si el número original era positivo, es Norte */
  } else {
    x = `${latc} S`; /* Si el número original era negativo, es Sur */
  } /* Repetimos el proceso para la longitud (Este, -W-Oeste) */
  const lngn = Math.abs(lng);
  const lnggr = Math.floor(lngn * 1);
  const lngmin = Math.floor((lngn - lnggr) * 60);
  const lngseg = ((lngn - lnggr) * 60 - lngmin) * 60;
  const lngc = `${lnggr} º ${lngmin} ' ${lngseg.toFixed(2)} "`;
  if (lng > 0) {
    y = `${lngc} E`;
  } else {
    y = `${lngc} W`;
  }

  return { lat: x, lng: y };
};

export const textWithPoints = (text: string = '', limit: number) =>
  text && text.length > limit ? `${text.substring(0, limit)}...` : text;

export const normalize = (str: string) => {
  if (!str) return '';
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

export const getErrorMessage = (language?: string) => {
  switch (language) {
    case 'es':
      return ERROR_MESSAGES_ES;
    case 'en':
      return ERROR_MESSAGES_EN;
    case 'it':
      return ERROR_MESSAGES_IT;
    case 'fr':
      return ERROR_MESSAGES_FR;
    case 'de':
      return ERROR_MESSAGES_DE;
    case 'pt':
      return ERROR_MESSAGES_PT;
    default:
      return ERROR_MESSAGES_ES;
  }
};
