import React, { Component } from 'react';
import CentresLayout from './centres-layout';
import services from '../../services/services';
import { CENTRES_PER_PAGE } from '../../constants/data';
import { Centre } from './types';
import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { IFilter } from '../../models/filter';
import { createFilterRequest } from '../../utils/other';
import { getOneUsers } from '../../services/users';
import { getDiver, getDivers } from '../../services/diver';
import { WithTranslation, withTranslation } from 'react-i18next';

interface CentresFuncionalState {
  centres: Centre[];
  totalCentres: number;
  page: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  filterValues: { [key: string]: IFilter };
}

class CentresFuncional extends Component<any, CentresFuncionalState> {
  state: CentresFuncionalState = {
    centres: [],
    totalCentres: 0,
    totalPages: 0,
    searchFilter: '',
    skipPage: 0,
    page: 1,
    orderAscendent: '',
    filterValues: {},
  };

  componentDidMount() {
    this.getCentres();
    this.getUser();
  }

  async getUser() {
    const params = {
      filter: {
        relations: ['diver'],
      },
    };
    const params2 = {
      filter: {
        relations: ['staff_centres', 'staff_centres.centre', 'centre.user'],
      },
    };
    const userId = localStorage.getItem('userId') || '';

    const resp = await getOneUsers(userId, params);
    const user = resp.data;
    if (user.diver && !user.diver.isStaff) {
      const resp2 = await getDiver(user.diver.id.toString(), params2);
      const diver = resp2.data;
      const centres: any[] = diver.staff_centres.map((item: any) => {
        const { id, name, rating, address, town, user } = item.centre;
        return {
          id,
          name,
          rating,
          location: `${address}, ${town}`,
          managers: user.name ? `${user.name} ${user.surname}` : '',
        };
      });
      localStorage.setItem('isStaff', 'true');
      this.setState({ centres });
    } else {
      this.getCentres();
    }
  }

  getCentres() {
    const { skipPage, orderAscendent, filterValues } = this.state;
    const endpoint = 'centres';
    const where = createFilterRequest(filterValues);
    where.user = localStorage.userId;
    const params = {
      filter: {
        where,
        skip: skipPage,
        limit: CENTRES_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: ['user', ],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        let centres: any[] = [];
        //if (res.count > 0) {
          const centresUser: any[] = res.data.map((item: any) => {
            const { id, name, rating, address, town, user } = item;
            return {
              id,
              name,
              rating,
              location: `${address}, ${town}`,
              managers: user.name ? `${user.name} ${user.surname}` : '',
            };
          });
          centres.push(...centresUser);
          centres.push(...await this.getCentresStaff(centresUser));
          const totalCentres: number = centres.length;
          const totalPages: number = Math.ceil(totalCentres / CENTRES_PER_PAGE);
          this.setState({ centres, totalCentres, totalPages });
        /*} else {
          this.setState({ centres: [], totalCentres: 0, totalPages: 0 });
        }*/
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  async getCentresStaff(centres: any[]) {

    const params = {
      filter: {
        where: { user: localStorage.userId },
        relations: ['staff_centres', 'staff_centres.centre', 'centre.user'],
      },
    };

    let staffCentres = [];
    const centresId = centres.map(centre => centre.id);
    const diver = (await getDivers(params)).data.data[0];
    if (diver && diver.staff_centres && diver.staff_centres.length > 0) {
      for (const staffCentre of diver.staff_centres) {
        if (staffCentre.centre && staffCentre.centre.id && !centresId.includes(staffCentre.centre.id)) {
          staffCentres.push(staffCentre.centre);
        }
      }
      return staffCentres;
    }
    return [];

  }

  setPage(page: number) {
    const { totalCentres } = this.state;
    const skip: number = CENTRES_PER_PAGE * (page - 1);
    if (skip < totalCentres) {
      this.setState({ skipPage: skip, page }, () => {
        this.getCentres();
      });
    }
  }

  rateCentre(id: string, value: number) {
    const endpoint = 'centre-ratings';
    const data = {
      score: value,
      centre: Number(id),
    };

    services.post({
      endpoint,
      data,
      loader: true,
      then: (response: any) => {
        this.getCentres();
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  async deleteCentre(id: string) {
    const endpoint = `centres/${id}`;
    const { t } = this.props

    await services.deleteReq({
      endpoint,
      then: (response: any) => {
        services.pushNotification({
          text: t('modalMessages:roles-delete.centredel'),
          title: t('modalMessages:roles-delete.delconfirm'),
          type: 'red',
        });
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  handleFilterChange(key: string, value: any, method?: string, collection?: string) {
    const { filterValues } = this.state;
    if (!filterValues[key]) filterValues[key] = { value: '' };
    filterValues[key].value = value;
    if (method) filterValues[key].method = method;
    if (collection) filterValues[key].collection = collection;
    this.setState({ filterValues, page: 1, skipPage: 0 }, () => {
      this.getCentres();
    });
  }

  handleFilterDelete(key: string) {
    const { filterValues } = this.state;
    delete filterValues[key];
    this.setState({ filterValues, page: 1, skipPage: 0 }, () => {
      this.getCentres();
    });
  }

  render() {
    const { centres, totalPages, page, totalCentres } = this.state;
    const { t } = this.props;
    return (
      <CentresLayout
        centres={centres}
        totalPages={totalPages}
        totalCentres={totalCentres}
        page={page}
        setPage={page => this.setPage(page)}
        refreshCentres={() => this.getCentres()}
        deleteCentre={id => this.deleteCentre(id)}
        onFilterChange={(key: string, value: any, method?: string, collection?: string) =>
          this.handleFilterChange(key, value, method, collection)
        }
        onFilterDelete={(key: string) => this.handleFilterDelete(key)}
      />
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default withTranslation(['modalMessages']) (connect<{}, {}, {}, IStoreState>(mapStateToProps)(CentresFuncional));
