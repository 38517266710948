import React, { Component } from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import { CertifierProfileContainer } from './certifier-profile-style';
import { images } from '../../../assets/images';
import { MainLayoutSection, FormGrid, FormGridRow, FormGridItem } from '../../main-layout-style';
import ItemSectionDetail from '../../../components/item-sections-detail/item-sections-detail';
import InputBox from '../../../components/input-box';
import MainButton from '../../../components/main-button';
import InputArea from '../../../components/input-area/input-area';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import DicotomicCheckbox from '../../../components/dicotomic-checkbox';
import InfoText from '../../../components/text/info-text/info-text';
import LabelText from '../../../components/text/label-text/label';
import TextDestacadoText from '../../../components/text/text-destacado-text/text-destacado-text';
import Label02Text from '../../../components/text/label-02-text/label-02';
import { IOrganizationExtended } from '../../../models/organization';
import { LANGUAGES, LANGUAGES_KEY_VALUES } from '../../../constants/languages';
import PhoneBox from '../../../components/phone-box/phone-box';
import { copyOf } from '../../../utils/other';
import { isValidEmail, isValidURL, isValidCif, isValidPhone } from '../../../utils/validation';
import SelectBox from '../../../components/select-box';
import { COUNTRIES } from '../../../constants/countries';
import { patchOrganization, uploadCollaboratorLogo, getOrganization } from '../../../services/collaborator';
import services from '../../../services/services';
import ModalCreateCatalog from '../../../components/modal-generic-file/modal-generic-file';
import { AttachedFile } from '../../../types/file';
import ItemFile from '../../../components/item-file/item-file';
import FileUtils from '../../../utils/files';
import InputUploadFiles from '../../../components/input-upload-file/input-upload-file';
import { withTranslation, WithTranslation } from 'react-i18next';

interface CertifierProfileProps extends WithTranslation {
  renderModal: string;
  t:any;
}

interface CertifierProfileState {
  renderModal: string;
  popNot: boolean;
  selectedLanguage: string;
  certifierProfile?: IOrganizationExtended;
  newLogo: boolean;
  newLogoFile?: AttachedFile;
  errors: { [key: string]: string };
}

class CertifierProfileLayout extends Component<CertifierProfileProps, CertifierProfileState> {
  state: CertifierProfileState = {
    renderModal: '',
    popNot: false,
    selectedLanguage: 'es',
    newLogo: false,
    errors: {},
  };

  componentDidMount() {
    this.getCertifierProfile();
  }

  componentDidUpdate(prevProps: CertifierProfileProps) {
    const { renderModal } = this.props;
    if (renderModal !== prevProps.renderModal) {
      this.setState({ renderModal });
    }
  }

  async getCertifierProfile() {
    const certifierId: string | null = localStorage.getItem('organizationId');
    if (certifierId) {
      const resp = await getOrganization(certifierId);
      const certifierProfile = resp.data;
      this.setState({ certifierProfile, newLogo: !certifierProfile.logo, newLogoFile: undefined });
    }
  }

  async patchCertifierProfile() {
    const { certifierProfile, errors, newLogo, newLogoFile } = Object.assign(this.state);
    const { t } = this.props;
    if (certifierProfile.contactPerson) {
      errors['contactPerson.name'] = this.validate('name', certifierProfile.contactPerson.name, true, 'contactPerson');
      errors['contactPerson.surname'] = this.validate(
        'surname',
        certifierProfile.contactPerson.surname,
        true,
        'contactPerson'
      );
      if (certifierProfile.contactPerson.phone && certifierProfile.contactPerson.phone.number === '') {
        delete certifierProfile.contactPerson.phone;
      }
      if (certifierProfile.contactPerson.email === '') delete certifierProfile.contactPerson.email;
    }
    if (certifierProfile && Object.values(errors).every(err => err === '')) {
      if (newLogo && newLogoFile) {
        const formData = new FormData();
        formData.set('logo', newLogoFile.data);
        await uploadCollaboratorLogo(certifierProfile.id.toString(), formData);
      }
      if ((newLogo && newLogoFile) || (certifierProfile.logo && !newLogo)) {
        delete certifierProfile.logo;
      } else if (!newLogoFile) {
        certifierProfile.logo = null;
      }
      if (certifierProfile.courseCatalog) {
        delete certifierProfile.courseCatalog;
      } else {
        certifierProfile.courseCatalog = null;
      }
      if (certifierProfile.equivalenceChart) {
        delete certifierProfile.equivalenceChart;
      } else {
        certifierProfile.equivalenceChart = null;
      }
      await patchOrganization(certifierProfile.id.toString(), certifierProfile);
      services.pushNotification({
        title: t('layouts:saved'),
        text: t('layouts:savedok'),
        type: 'green',
      });
      this.getCertifierProfile();
    } else {
      this.setState({ errors });
    }
  }

  handleChange(field: string, value: any, required: boolean = false, subfield?: string) {
    const { certifierProfile, errors } = Object.assign(this.state);
    const error = this.validate(field, value, required, subfield);
    if (subfield) {
      if (!certifierProfile[subfield]) certifierProfile[subfield] = {};
      certifierProfile[subfield][field] = value;
      errors[`${subfield}.${field}`] = error;
    } else {
      certifierProfile[field] = value;
      errors[field] = error;
    }
    this.setState({ certifierProfile, errors });
  }

  validate(field: string, value: any, required: boolean, subfield?: string) {
    if (required && (!value || value === '')) return 'required';
    if (field === 'email' && !isValidEmail(value) && value !== '') return 'invalid-email';
    if (
      (field === 'website' || field === 'facebookURL' || field === 'instagramURL' || field === 'insuranceLink') &&
      !isValidURL(value) &&
      value !== ''
    ) {
      return 'invalid-format';
    }

    if (field === 'phone' && !isValidPhone(value.number) && value.number !== '') return 'invalid-phone';
    return '';
  }

  handleChangeDescription(value: string) {
    const { selectedLanguage, certifierProfile } = Object.assign(this.state);
    if (!certifierProfile.description) certifierProfile.description = {};
    certifierProfile.description[selectedLanguage] = value;
    this.setState({ certifierProfile });
  }

  async uploadCatalogEquivalences(file: AttachedFile, isCatalog: boolean) {
    const { certifierProfile } = this.state;
    const { t } = this.props;
    const data = new FormData();
    const key = isCatalog ? 'courseCatalog' : 'equivalenceChart';
    const msg = isCatalog
      ? t('layouts:billing-information.catok')
      : t('layouts:tableok')
    data.append(key, file.data);
    this.setState({ renderModal: '' });
    if (certifierProfile) {
      await uploadCollaboratorLogo(certifierProfile.id.toString(), data);
    }
    this.getCertifierProfile();
    services.pushNotification({
      title: t('layouts:billing-information.saved'),
      text: msg,
      type: 'green',
    });
  }

  render() {
    const { selectedLanguage, certifierProfile, errors, renderModal, newLogo } = this.state;
    const {t} = this.props;
    if (!certifierProfile) return '';
    const isCertifier = certifierProfile.type !== 'marineConservation';

    return (
      <>
        <HeaderMain
          title={t('modalMessages:profile')}
          mainButton={{
            text: t('modalMessages:save'),
            onClick: () => this.patchCertifierProfile(),
          }}
        />
        <MainLayoutSection>
          <CertifierProfileContainer>
            <div className="certifier-profile">
              <div className="certifier-profile-left">
                {newLogo ? (
                  <InputUploadFiles
                    multiple={false}
                    label={ t('modalMessages:logo')}
                    onChange={(files: AttachedFile[]) => this.setState({ newLogoFile: files[0] })}
                  />
                ) : (
                    <div className="certifier-profile-left-logo">
                      <div className="certifier-profile-left-logo__item" onClick={() => { }}>
                        <img src={certifierProfile.logo} alt="" />
                      </div>
                    </div>
                  )}
                {!newLogo && (
                  <div className="certifier-profile-left-delete">
                    <div className="certifier-profile-left-delete__item">
                      <MainButton
                        text={t('buttons:delete')}
                        type="secondary-red"
                        onClick={() => this.setState({ newLogo: true })}
                      />
                    </div>
                  </div>
                )}

                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        errorCode={errors.website}
                        placeholder={t('modalMessages:web')}
                        className="rounded-title"
                        type="text"
                        required={false}
                        value={certifierProfile.website}
                        labelText={t('modalMessages:uriweb')}
                        onChange={value => this.handleChange('website', value)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        errorCode={errors.facebookURL}
                        placeholder="Facebook"
                        className="rounded-title"
                        type="text"
                        required={false}
                        value={certifierProfile.facebookURL}
                        labelText="url facebook"
                        onChange={value => this.handleChange('facebookURL', value)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        errorCode={''}
                        placeholder="Instagram"
                        className="rounded-title"
                        type="text"
                        required={false}
                        value={certifierProfile.instagramURL}
                        labelText="url instagram"
                        onChange={value => this.handleChange('instagramURL', value)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
                {isCertifier && (
                  <div className="certifier-profile-left-section">
                    <div className="certifier-profile-left-section-title">
                      <div className="certifier-profile-left-section-title__text">
                        <BodyDestacadoText>{t('modalMessages:imgcat').toUpperCase()}</BodyDestacadoText>
                      </div>
                      <div
                        className="certifier-profile-left-section-title__button"
                        onClick={() => this.setState({ renderModal: 'edit-catalog' })}
                      >
                        <BodyDestacadoText>{t('modalMessages:edit')}</BodyDestacadoText>
                      </div>
                    </div>
                    {certifierProfile.courseCatalog && (
                      <div className="certifier-profile-left-section-content">
                        <div onClick={() => window.open(certifierProfile.courseCatalog)}>
                          <div>
                            <div>
                              <img src={certifierProfile.courseCatalog} alt="" />
                            </div>
                          </div>
                          <div className="certifier-profile-left-section-content__file">
                            <ItemFile type={FileUtils.getExtension(certifierProfile.courseCatalog)} />
                          </div>
                          <div className="certifier-profile-left-section-content__info">
                            <div className="certifier-profile-left-section-content__info__title">
                              <TextDestacadoText>{t('layouts:home-menu.certifier-catalog')}</TextDestacadoText>
                            </div>
                            <div className="certifier-profile-left-section-content__info__name">
                              <InfoText>{FileUtils.getFileName(certifierProfile.courseCatalog)}</InfoText>
                            </div>
                            <div className="certifier-profile-left-section-content__info__date">
                              <Label02Text>08/09/2019</Label02Text>
                            </div>
                          </div>
                        </div>
                        <div className="certifier-profile-left-section-content__delete">
                          <MainButton
                            text="Eliminar"
                            type="secondary-red"
                            onClick={() => {
                              certifierProfile.courseCatalog = undefined;
                              this.setState({ certifierProfile })
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {isCertifier && (
                  <div className="certifier-profile-left-section">
                    <div className="certifier-profile-left-section-title">
                      <div className="certifier-profile-left-section-title__text">
                        <BodyDestacadoText>{t('layouts:table')}</BodyDestacadoText>
                      </div>
                      <div
                        className="certifier-profile-left-section-title__button"
                        onClick={() => this.setState({ renderModal: 'edit-equivalences' })}
                      >
                        <BodyDestacadoText>{t('modalMessages:edit')}</BodyDestacadoText>
                      </div>
                    </div>
                    {certifierProfile.equivalenceChart && (
                      <div className="certifier-profile-left-section-content">
                        <div onClick={() => window.open(certifierProfile.equivalenceChart)}>
                          <div>
                            <img src={certifierProfile.equivalenceChart} alt="" />
                          </div>
                          <div className="certifier-profile-left-section-content__file">
                            <ItemFile type={FileUtils.getExtension(certifierProfile.equivalenceChart)} />
                          </div>
                          <div className="certifier-profile-left-section-content__info">
                            <div className="certifier-profile-left-section-content__info__title">
                              <TextDestacadoText>{t('layouts:table')}</TextDestacadoText>
                            </div>
                            <div className="certifier-profile-left-section-content__info__name">
                              <InfoText>{FileUtils.getFileName(certifierProfile.equivalenceChart)}</InfoText>
                            </div>
                            <div className="certifier-profile-left-section-content__info__date">
                              <Label02Text>08/09/2019</Label02Text>
                            </div>
                          </div>
                        </div>
                        <div className="certifier-profile-left-section-content__delete">
                          <MainButton
                            text="Eliminar"
                            type="secondary-red"
                            onClick={() => {
                              certifierProfile.equivalenceChart = undefined;
                              this.setState({ certifierProfile })
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="certifier-profile-right">
                <ItemSectionDetail title="Datos" icon={images.dataGeneral}>
                  <FormGrid>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          errorCode={errors.name}
                          placeholder={t('modalMessages:name')}
                          className="rounded-title"
                          type="text"
                          required={true}
                          value={certifierProfile.name}
                          labelText={t('modalMessages:name')}
                          onChange={value => this.handleChange('name', value, true)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    {!isCertifier && (
                      <FormGridRow>
                        <FormGridItem type="col-0">
                          <InputBox
                            errorCode={errors.address}
                            placeholder={t('modalMessages:zoneact')}
                            className="rounded-title"
                            type="text"
                            required={false}
                            value={certifierProfile.address}
                            labelText={t('modalMessages:zoneact')}
                            onChange={value => this.handleChange('address', value)}
                          />
                        </FormGridItem>
                      </FormGridRow>
                    )}
                  </FormGrid>

                  <div className="my-center-profile-description-right-languages-title">
                    <div className="my-center-profile-description-right-languages-title__text">
                      <LabelText>{t('modalMessages:desc')}</LabelText>
                    </div>
                    {/* <div className="my-center-profile-description-right-languages-title__tooltip">
                      <TooltipBox tooltipText={''} />
                    </div> */}
                  </div>
                  <div className="modal-cancelation-policy-form">
                    <div className={'modal-cancelation-policy-languages'}>
                      {LANGUAGES.map((k: any, index: number) => (
                        <div className={'modal-cancelation-policy-languages__item'} key={index}>
                          <MainButton
                            onClick={() => this.setState({ selectedLanguage: k.value })}
                            text={k.label}
                            type={
                              selectedLanguage === k.value
                                ? 'primary'
                                : true // !certifierProfile.description[k.value].error
                                  ? 'secondary'
                                  : 'secondary-red'
                            }
                          />
                        </div>
                      ))}
                    </div>

                    <FormGrid>
                      <FormGridRow>
                        <FormGridItem type="col-0">
                          <InputArea
                            className="rounded"
                            name={selectedLanguage}
                            placeholder={t('modalMessages:desc')+` ${LANGUAGES_KEY_VALUES[selectedLanguage].toLowerCase()}`}
                            required={false}
                            errorCode={''}
                            value={
                              certifierProfile.description && certifierProfile.description[selectedLanguage]
                                ? certifierProfile.description[selectedLanguage]
                                : ''
                            }
                            onChange={(ev: any) => this.handleChangeDescription(ev.target.value)}
                          />
                        </FormGridItem>
                      </FormGridRow>
                    </FormGrid>
                  </div>
                  {/* 
                  <div className="certifier-profile-right-data-save">
                    <MainButton iconImg={images.save} text={'Guardar'} type="primary" onClick={() => {}} />
                  </div> */}
                  {isCertifier && (
                    <>
                      <div className="certifier-profile-right-data-dicotomic">
                        <div className="certifier-profile-right-data-dicotomic__text">
                          <BodyDestacadoText>{t('modalMessages:certon')}</BodyDestacadoText>
                        </div>
                        <div className="certifier-profile-right-data-dicotomic__check">
                          <DicotomicCheckbox
                            className="rounded"
                            checked={!!certifierProfile.onlineTheory}
                            onCheck={() => this.handleChange('onlineTheory', true)}
                          >
                            <InfoText>Si</InfoText>
                          </DicotomicCheckbox>
                        </div>
                        <div className="certifier-profile-right-data-dicotomic__check">
                          <DicotomicCheckbox
                            className="rounded"
                            checked={!certifierProfile.onlineTheory}
                            onCheck={() => this.handleChange('onlineTheory', false)}
                          >
                            <InfoText>No</InfoText>
                          </DicotomicCheckbox>
                        </div>
                      </div>

                      <FormGrid>
                        <FormGridRow>
                          <FormGridItem type="col-0">
                            <InputBox
                              errorCode={errors.insuranceLink}
                              placeholder={t('modalMessages:buylink')}
                              className="rounded-title"
                              type="text"
                              required={false}
                              value={certifierProfile.insuranceLink || ''}
                              labelText={t('modalMessages:secdive')}
                              onChange={value => this.handleChange('insuranceLink', value)}
                            />
                          </FormGridItem>
                        </FormGridRow>
                      </FormGrid>
                    </>
                  )}
                </ItemSectionDetail>
                {isCertifier && (
                  <ItemSectionDetail title={t('modalMessages:fisdata')} icon={images.dataFiscal}>
                    <FormGrid>
                      <FormGridRow>
                        <FormGridItem type="col-0">
                          <InputBox
                            errorCode={errors.cif}
                            placeholder={t('modalMessages:cifcert')}
                            className="rounded-title"
                            type="text"
                            required={false}
                            value={certifierProfile.cif}
                            labelText="cif"
                            onChange={value => this.handleChange('cif', value)}
                          />
                        </FormGridItem>
                      </FormGridRow>
                      <FormGridRow>
                        <FormGridItem type="col-0">
                          <InputBox
                            errorCode={errors.address}
                            placeholder={t('modalMessages:line1add')}
                            className="rounded-title"
                            type="text"
                            required={false}
                            value={certifierProfile.address}
                            labelText={t('modalMessages:address')}
                            onChange={value => this.handleChange('address', value)}
                          />
                        </FormGridItem>
                      </FormGridRow>
                      <FormGridRow>
                        <FormGridItem type="col-0">
                          <InputBox
                            errorCode={errors.address_2}
                            placeholder={t('modalMessages:line2add')}
                            className="rounded-title"
                            type="text"
                            required={false}
                            value={certifierProfile.address_2}
                            labelText={t('modalMessages:address')}
                            onChange={value => this.handleChange('address_2', value)}
                          />
                        </FormGridItem>
                      </FormGridRow>
                      <FormGridRow>
                        <FormGridItem type="col-2">
                          <InputBox
                            errorCode={errors.town}
                            placeholder={t('modalMessages:city')}
                            className="rounded-title"
                            type="text"
                            required={false}
                            value={certifierProfile.town}
                            labelText={t('modalMessages:city')}
                            onChange={value => this.handleChange('town', value)}
                          />
                        </FormGridItem>
                        <FormGridItem type="col-2">
                          <SelectBox
                            labelText={t('modalMessages:country')}
                            placeholder={t('modalMessages:country')}
                            required={false}
                            className="rounded-title"
                            optionsText={COUNTRIES}
                            searchable={true}
                            optionKey={'value'}
                            optionValue={'label'}
                            defaultValue={certifierProfile.country}
                            initialValue={certifierProfile.country}
                            errorCode={errors.country}
                            icon={images.arrowDown2BlueSvg}
                            onChange={(value: string) => {
                              this.handleChange('country', value);
                            }}
                          />
                        </FormGridItem>
                      </FormGridRow>
                    </FormGrid>
                  </ItemSectionDetail>
                )}
                <ItemSectionDetail title="Persona de contacto" icon={images.dataContact}>
                  <FormGrid>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          errorCode={errors['contactPerson.name']}
                          placeholder={t('modalMessages:name')}
                          className="rounded-title"
                          type="text"
                          required={false}
                          value={certifierProfile.contactPerson ? certifierProfile.contactPerson.name : ''}
                          labelText={t('modalMessages:name')}
                          onChange={value => this.handleChange('name', value, false, 'contactPerson')}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          errorCode={errors['contactPerson.surname']}
                          placeholder={t('modalMessages:lastname')}
                          className="rounded-title"
                          type="text"
                          required={false}
                          value={certifierProfile.contactPerson ? certifierProfile.contactPerson.surname : ''}
                          labelText={t('modalMessages:lastname')}
                          onChange={value => this.handleChange('surname', value, false, 'contactPerson')}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          errorCode={errors['contactPerson.email']}
                          placeholder={t('modalMessages:email')}
                          className="rounded-title"
                          type="text"
                          required={false}
                          value={certifierProfile.contactPerson ? certifierProfile.contactPerson.email || '' : ''}
                          labelText={t('modalMessages:email')}
                          onChange={value => this.handleChange('email', value, false, 'contactPerson')}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <PhoneBox
                          className="rounded-title"
                          placeholder={t('modalMessages:phone')}
                          labelText={t('modalMessages:phone')}
                          errorPrefix={''}
                          errorNumber={errors['contactPerson.phone']}
                          defaultPrefix={
                            certifierProfile.contactPerson && certifierProfile.contactPerson.phone
                              ? certifierProfile.contactPerson.phone.prefix
                              : ''
                          }
                          defaultNumber={
                            certifierProfile.contactPerson && certifierProfile.contactPerson.phone
                              ? certifierProfile.contactPerson.phone.number
                              : ''
                          }
                          filledPrefix={
                            certifierProfile.contactPerson && certifierProfile.contactPerson.phone
                              ? certifierProfile.contactPerson.phone.prefix
                              : ''
                          }
                          filledNumber={
                            certifierProfile.contactPerson && certifierProfile.contactPerson.phone
                              ? certifierProfile.contactPerson.phone.number
                              : ''
                          }
                          onChange={(fieldRaw, value) => {
                            const phone =
                              certifierProfile.contactPerson &&
                                certifierProfile.contactPerson.phone &&
                                typeof certifierProfile.contactPerson.phone === 'object'
                                ? copyOf(certifierProfile.contactPerson.phone)
                                : {};
                            if (!phone.prefix) phone.prefix = '+34';
                            const field = fieldRaw === 'phoneNumber' ? 'number' : 'prefix';
                            phone[field] = value;
                            this.handleChange('phone', phone, false, 'contactPerson');
                          }}
                        />
                      </FormGridItem>
                    </FormGridRow>
                  </FormGrid>
                </ItemSectionDetail>
              </div>
            </div>
          </CertifierProfileContainer>
        </MainLayoutSection>
        {renderModal === 'edit-catalog' && isCertifier && (
          <ModalCreateCatalog
            showModal={renderModal === 'edit-catalog'}
            onCancel={() => this.setState({ renderModal: '' })}
            onSave={(file: AttachedFile | null) => {
              {
                if (file) this.uploadCatalogEquivalences(file, true);
              }
            }}
            edit={true}
            title={t('modelMessages:imgcat')}
            text={t('modelMessages:upcert')}
          />
        )}
        {renderModal === 'edit-equivalences' && isCertifier && (
          <ModalCreateCatalog
            showModal={renderModal === 'edit-equivalences'}
            onCancel={() => this.setState({ renderModal: '' })}
            onSave={(file: AttachedFile | null) => {
              {
                if (file) this.uploadCatalogEquivalences(file, false);
              }
            }}
            edit={true}
            title={t('layours:table')}
            text={t('layours:uptable')}
          />
        )}
      </>
    );
  }
}
export default withTranslation(['modalMessages', 'layouts', 'buttons']) (CertifierProfileLayout)