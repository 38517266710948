import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ModalDestinationStyle = styled.div`
  padding: 58px 32px 32px 32px;
  width: 416px;

  .modal-destination-title {
    margin-bottom: 12px;
  }
  .modal-destination-text  {
    margin-bottom: 28px;
    color: ${colors['NE-002/100']};
  }
  .manage-dive-levels-line {
    width: 100%;
    height: 1px;
    background: ${colors['NE-002/100']};
    margin-bottom: 40px;
  }

  .modal-destination-top-row  {
    display: flex;
    margin-bottom: 20px;
    &__input {
      margin-right: 20px;
      width: 50%;
    }

    &__dicotomic {
      padding-top: 26px;
    }
  }

  .file-item-container-img {
    width: 100%;
    height: 110px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 12px;
    background: ${colors['PR-001/100']};
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .modal-destination-tableform {
  }

  .modal-destination-tableform-title {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
    color: ${colors['SE-002/100']};
    &__penalty {
      width: 110px;
      margin-left: 40px;
    }
    &__term  {
      flex: auto;
    }
  }
`;
