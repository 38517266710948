import styled from 'styled-components';

export const CertifierCatalogDetailStyle = styled.div`
  .certifier-catalog-detail-top{
    display: flex;
    margin-bottom: 76px;
  }
  .certifier-catalog-detail-top-left{
    width: 450px;
    border-radius: 6px;
    margin-right: 60px;
    img {
      width: 100%;
      height: auto;
    }

  }
  .certifier-catalog-detail-top-right{
    &__title{
      margin-bottom: 40px;
    }

    
  }
  .certifier-catalog-detail-top-right-info {
    &__item {
      display: flex;
      margin-bottom: 12px;
      &__title {
        width: 190px;
        margin-right: 20px;
      }
      &__text {

      }
    }
  }

  .certifier-catalog-detail-description {
    margin-bottom: 80px;


  }
  .certifier-catalog-detail-description-title  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;

  }
  .certifier-catalog-detail-description-text  {

  }
  .certifier-catalog-detail-table  { 

  }
  .certifier-catalog-detail-table-title  { 
    display: flex;
  }


`;
