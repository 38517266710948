import * as React from 'react';
import ModalContainer from '../modal-container';
import { ModalUploadDocumentStyle } from './modal-upload-document-style';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../text/body-text/body-text';
import InputBox from '../../input-box';
import MainButton from '../../main-button';
import { images } from '../../../assets/images';
import SelectBox from '../../select-box';
import CalendarBox from '../../calendar-box';
import InputUploadFiles from '../../input-upload-file/input-upload-file';
import { DIVER_DOCUMENT_TYPES } from '../../../constants/diver-document-types';
import { getCollaborators, getCollaboratorCourses } from '../../../services/collaborator';
import { transformDateCalendar } from '../../../utils/time';
import moment from 'moment';
import 'moment/locale/es.js';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

interface IModalUploadDocumentProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onClose: Function;
  onSubmit: Function;
  title: string;
  text: string[];
  t: any
}

interface IModalUploadDocumentState {
  kind: string;
  name: string;
  expirationDate: string;
  certifier: number;
  course: number;
  file: any;
  certifiers: { [key: string]: string }[];
  courses?: { [key: string]: string }[];
  errors: {
    kind?: string;
    name?: string;
    expirationDate?: string;
    certifier?: string;
    course?: string;
    file?: string;
  };
}

class ModalUploadDocument extends React.Component<IModalUploadDocumentProps, IModalUploadDocumentState> {
  constructor(props: IModalUploadDocumentProps) {
    super(props);

    this.state = {
      kind: '',
      name: '',
      expirationDate: '',
      certifier: NaN,
      course: NaN,
      file: null,
      certifiers: [],
      errors: {
        kind: '',
        name: '',
        expirationDate: '',
        certifier: '',
        course: '',
        file: '',
      },
    };
  }

  componentDidMount() {
    this.getCertifiers();
  }

  resetState() {
    this.setState({
      kind: '',
      name: '',
      expirationDate: '',
      certifier: NaN,
      file: null,
      certifiers: [],
      errors: {
        kind: '',
        name: '',
        expirationDate: '',
        certifier: '',
        course: '',
        file: '',
      },
    });
    this.handleChange('file', undefined);
  }

  validateField(field: string, value: any) {
    const errors = Object.assign(this.state.errors);
    let formError = false;
    if (!value || value === '') {
      errors[field] = 'required';
      formError = true;
    } else {
      errors[field] = '';
    }
    this.setState({ errors });
    return formError;
  }

  validate(): boolean {
    const state = Object.assign(this.state);
    let formError = false;
    let requiredFields = ['kind', 'name', 'certifier', 'course', 'file'];
    if (state.kind !== 'diving license') {
      requiredFields = ['kind', 'name', 'file'];
    }
    requiredFields.forEach((field: string) => {
      const value = state[field];
      formError = this.validateField(field, value) || formError;
    });

    return formError;
  }

  handleUpload() {
    const { onSubmit } = this.props;
    const { kind, name, expirationDate, certifier, file, course } = this.state;
    if (!this.validate()) {
      if (kind === 'diving license') {
        onSubmit({
          kind,
          name,
          expirationDate,
          certifier,
          file,
          course,
        });
      } else {
        onSubmit({
          kind,
          name,
          expirationDate,
          file,
        });
      }
    }
  }

  async getCertifiers() {
    try {
      let data: any = await getCollaborators();
      data = data.data.data
        .filter((collaborator: { type: string }) => collaborator.type === 'certifier')
        .map((certifier: { name: string; id: number }) => {
          return {
            label: certifier.name,
            value: certifier.id.toString(),
          };
        });
      this.setState({ certifiers: data });
    } catch (err) {
      console.log(err);
    }
  }

  async getCourses(certifier: string | number) {
    try {
      const resp: any = await getCollaboratorCourses(certifier);

      const courses = resp.data.data.map((course: any) => {
        return { label: course.name, value: course.id.toString() };
      });
      this.setState({ courses });
    } catch (err) {
      console.log(err);
    }
  }

  handleChange(field: string, value: any) {
    const newState = Object.assign(this.state);
    this.validateField(field, value);
    newState[field] = field === 'file' && value && value.length === 0 ? undefined : value;
    if (field === 'certifier') this.getCourses(value);
    this.setState(newState);
  }

  handleClose() {
    const { onClose } = this.props;
    this.resetState();
    onClose();
  }

  render() {
    const { showModal, title, text, t } = this.props;
    const { certifiers, errors, kind, name, file, certifier, courses, expirationDate } = this.state;
    return (
      <ModalContainer active={showModal} modalClose={() => this.handleClose()}>
        <ModalUploadDocumentStyle>
          <div className="modal-invite-pop">
            <div className="modal-invite-title">
              <SubheadDestacadoText>{title}</SubheadDestacadoText>
            </div>
            <div className="modal-invite-text">
              {text.map((item, index) => (
                <div className="modal-invite-text__item" key={index}>
                  <BodyText>{item}</BodyText>
                </div>
              ))}
            </div>
            <div className="modal-invite-form">
              <div className="modal-invite-form__item">
                <SelectBox
                  labelText={t('components:global.type')}
                  placeholder={t('components:global.type')}
                  required={true}
                  className="rounded-title"
                  errorCode={errors.kind}
                  optionsText={DIVER_DOCUMENT_TYPES}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={kind}
                  onChange={value => this.handleChange('kind', value)}
                  icon={images.arrowDown2BlueSvg}
                />
              </div>
              <div className="modal-invite-form__item">
                <InputBox
                  placeholder={t('components:global.name')}
                  className="rounded-title"
                  required={true}
                  type="email"
                  value={name}
                  errorCode={errors.name}
                  filledValue={''}
                  labelText={t('components:global.name')}
                  onChange={value => this.handleChange('name', value)}
                />
              </div>
              <div className="modal-invite-form__item">
                <CalendarBox
                  disableWrite={true}
                  placeholder={t('components:global.expiration-date')}
                  className="rounded-title"
                  required={true}
                  labelText={t('components:global.expiration-date')}
                  forbidPastDates={true}
                  minYear={new Date().getFullYear()}
                  errorCode={errors.expirationDate}
                  initialValue={expirationDate ? moment(expirationDate).format('DD/MM/YYYY') : ''}
                  onChange={(value: any) => {
                    if (value) {
                      this.handleChange('expirationDate', transformDateCalendar(value));
                    } else {
                      this.handleChange('expirationDate', value);
                    }
                  }}
                />
              </div>
              {kind === 'diving license' && (
                <>
                  <div className="modal-invite-form__item">
                    <SelectBox
                      labelText={t('components:global.certifier')}
                      placeholder={t('components:global.certifier')}
                      required={true}
                      className="rounded-title"
                      errorCode={errors.certifier}
                      optionsText={certifiers}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      onChange={value => this.handleChange('certifier', value)}
                      icon={images.arrowDown2BlueSvg}
                    />
                  </div>
                  {courses && certifier >= 0 && (
                    <div className="modal-invite-form__item">
                      <SelectBox
                        labelText={t('components:global.course')}
                        placeholder={t('components:global.course')}
                        required={true}
                        className="rounded-title"
                        optionsText={courses}
                        optionKey={'value'}
                        optionValue={'label'}
                        errorCode={errors.course}
                        defaultValue={''}
                        onChange={value => this.handleChange('course', value)}
                        icon={images.arrowDown2BlueSvg}
                      />
                    </div>
                  )}
                </>
              )}
              <div className="modal-invite-form__item">
                <InputUploadFiles
                  multiple={false}
                  onChange={file => this.handleChange('file', file)}
                  errorCode={errors.file}
                  value={file}
                  accept={['*']}
                />
              </div>
            </div>
            <div className="modal-invite-button">
              <MainButton text={t('components:actions.upload')} onClick={() => this.handleUpload()} type="primary" />
            </div>
          </div>
        </ModalUploadDocumentStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalUploadDocument));
