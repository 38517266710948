import React from 'react';
import { ModalNewChatStyle } from './modal-new-chat-style';
import ModalContainer from '../../../../../components/modals/modal-container';
import BodyText from '../../../../../components/text/body-text/body-text';
import SelectBox from '../../../../../components/select-box';
import { getCentreDivers } from '../../../../../services/centre';
import { IDropdown } from '../../../../../models/generic';
import MainButton from '../../../../../components/main-button';
import { openChannel, openOrgChannel } from '../../../../../services/chat';
import { getCertifierDivers } from '../../../../../services/collaborator';

interface ModalNewChatProps {
  centreId: string;
  showModal: boolean;
  onCancel: Function;
  onSuccess: () => void;
}

interface ModalNewChatState {
  diversDropdown: IDropdown[];
  selectedDiver: number;
}

export default class ModalNewChat extends React.Component<ModalNewChatProps, ModalNewChatState> {
  constructor(props: ModalNewChatProps) {
    super(props);
    this.state = {
      diversDropdown: [],
      selectedDiver: NaN,
    };
  }

  componentDidMount() {
    const organizationId = localStorage.getItem('organizationId');
    if (this.props.centreId || organizationId) this.getDivers();
  }

  async getDivers() {
    const { centreId } = this.props;
    const params = {
      filter: {
        relations: ['user'],
      },
    };
    const organizationId = localStorage.getItem('organizationId');

    const resp = organizationId
      ? await getCertifierDivers(organizationId, params)
      : await getCentreDivers(centreId, params);
    const diversDropdown = resp.data.data.map((diver: any) => ({
      value: diver.user.id.toString(),
      label: `${diver.user.name} ${diver.user.surname}`,
    }));
    this.setState({ diversDropdown });
  }

  async handleSubmit() {
    const { centreId, onSuccess } = this.props;
    const { selectedDiver } = this.state;
    const data = {
      user: selectedDiver,
    };
    const organizationId = localStorage.getItem('organizationId');
    if (organizationId) await openOrgChannel(organizationId, data);
    else await openChannel(centreId, data);
    onSuccess();
  }

  render() {
    const { onCancel, showModal } = this.props;
    const { diversDropdown, selectedDiver } = this.state;
    return (
      <ModalContainer className="" modalClose={() => onCancel()} active={showModal} title="Nuevo chat">
        <ModalNewChatStyle>
          <BodyText>{'Selecciona uno de los buceadores de tu lista para iniciar una nueva conversación'}</BodyText>
          <div className="modal-new-chat-section">
            <SelectBox
              className={'rounded'}
              onChange={value => this.setState({ selectedDiver: parseInt(value, undefined) })}
              labelText=""
              optionsText={diversDropdown}
              optionKey={'value'}
              optionValue={'label'}
            />
          </div>
          <div className="modal-new-chat-section">
            <MainButton
              type="primary"
              text="Iniciar conversación"
              disabled={!selectedDiver}
              onClick={() => this.handleSubmit()}
            />
          </div>
        </ModalNewChatStyle>
      </ModalContainer>
    );
  }
}
