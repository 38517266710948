import styled from 'styled-components';
import { colors } from '../../assets/colors';

import { BodyDestacadoTextContainer } from '../text/body-destacado-text/body-destacado-text-styles';

export const NavigationTabContainer = styled.div`
  display: flex;
  margin-bottom: 40px;

  a {
    &.active {
      .navigation-item {
        border-bottom: 2px solid ${colors['PR-001/100']};
        color: ${colors['PR-001/100']};
        &.disable {
          color: ${colors['NE-002/100']};
          border-bottom: 0px solid ${colors['PR-001/100']};
        }
      }
    }
    .navigation-item {
      transition: all 0.3s ease-in-out;
      border-bottom: 2px solid white;
      &.disable {
        pointer-events: none;
        color: ${colors['NE-002/100']};
        border-bottom: 0px solid ${colors['PR-001/100']};
      }
    }
  }
  .navigation-item {
    display: flex;
    cursor: pointer;
    padding: 20px 19px 8px;
    transition: all 0.3s ease-in-out;
    border-bottom: 2px solid white;
    &.disable {
      pointer-events: none;
      color: ${colors['NE-002/100']};
      border-bottom: 0px solid ${colors['PR-001/100']};
    }

    &.active {
      border-bottom: 2px solid ${colors['PR-001/100']};
      color: ${colors['PR-001/100']};
      &.disable {
        pointer-events: none;
        color: ${colors['NE-002/100']};
        border-bottom: 0px solid ${colors['PR-001/100']};
      }
    }
  }

  &.small  {
    .navigation-item {
      padding: 18px 12px 12px 12px;
      ${BodyDestacadoTextContainer} {
        line-height: 18px;
        font-size: 12px;
      }
    }
  }
`;
