import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
// Styles
import MiniModalCheckboxStyle from './mini-modal-checkbox-style';
import { InfoTextContainer as InfoText } from '../../../text/info-text/info-text-styles';
import { useTranslation } from 'react-i18next';

// Assets

import Checkbox from '../../../form-components/checkbox/checkbox';
import { images } from '../../../../assets/images';

export interface ModalRateProps {
  options: any[];
  value?: any;
  onChange?: any;
  multiple?: boolean;
  showSelectionCount?: boolean;
  showDeleteBtn?: boolean;
  onDelete?: () => void;
}

const MiniModalCheckbox: React.FC<ModalRateProps> = (props: ModalRateProps) => {
  const [value, setValue] = useState<any>(props.multiple ? [] : '');
  const { t } = useTranslation(['components']);

  const onChange = (element: any, ev: any) => {
    ev.preventDefault();
    const valueClone = _.cloneDeep(value);
    if (props.multiple) {
      const index: number = valueClone.findIndex((v: any) => v.value.toString() === element.value.toString());
      if (index === -1) {
        valueClone.push(element);
      } else {
        valueClone.splice(index, 1);
      }
      setValue(valueClone);
    } else {
      setValue(element);
    }
    if (props.onChange) {
      props.onChange(props.multiple ? valueClone : element);
    }
  };

  const isActive = (val: any, element: any) => {
    // const T = props.multiple
    //   ? !!val.find((v: any) => v.value.toString() === element.value.toString())
    //   : val.value.toString() === element.value.toString();
    // return T;
    const T = props.multiple
      ? !!val.find((v: any) => v.toString() === element.value.toString())
      : val.value.toString() === element.value.toString();
    return T;
  };

  const deleteBtnHandler = () => {
    setValue(props.multiple ? [] : '');
    props.onDelete && props.onDelete();
  };

  let optionsBar;
  if (props.showSelectionCount || props.showDeleteBtn) {
    optionsBar = (
      <div className="checkbox-list__options-bar">
        {props.showSelectionCount && (
          <div className="checkbox-list__count">
            <InfoText>{`${value.length} ${t('components:mini-modal-checkbox.selected')}`}</InfoText>
          </div>
        )}
        {props.showDeleteBtn && (
          <div className="checkbox-list__delete-btn">
            <InfoText onClick={deleteBtnHandler}>{t('components:mini-modal-checkbox.delete')}</InfoText>
          </div>
        )}
      </div>
    );
  }

  return (
    <MiniModalCheckboxStyle>
      <div className="checkbox-list">
        {optionsBar}
        <div className="checkbox-list__items-container">
          {props.options.map((element: any, index: any) => (
            <div
              className={classnames('checkbox-list__item', {
                active: isActive(props.value || value, element),
              })}
              key={`checkbox-list__item-${index}`}
              onClick={(ev: any) => onChange(element, ev)}
            >
              <Checkbox icon={images.checkWhite} checked={isActive(props.value || value, element)}>
                {element.icon && (
                  <div className="checkbox-list__item-icon">
                    <img src={element.icon} />
                  </div>
                )}
                <div className="checkbox-list__item-text">
                  <InfoText>{element.label}</InfoText>
                </div>
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
    </MiniModalCheckboxStyle>
  );
};

export default MiniModalCheckbox;
