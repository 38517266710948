import styled from 'styled-components';

export const CardDestinationStyle = styled.div`
  position: relative;
  .card-destination-img {
    height: 160px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 18px;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .card-destination-check {
    position: absolute;
    left: 12px;
    top: 12px;
    width: 28px;
    height: 28px;
  }
  .card-destination-actions {
    display: flex;
    &__title {
      flex: auto;
    }
    &__edit {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
    &__delete {
      width: 32px;
      height: 32px;
    }
  }
`;
