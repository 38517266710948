import * as React from 'react';
import { images } from '../../assets/images';
import HeadDestacadoText from '../text/head-destacado-text/head-destacado-text';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import { CardActivityTemplateStyle } from './card-activity-template-style';
import LabelText from '../text/label-text/label';
import { DiveKindsKeyValues } from '../../constants/dive-dropdowns';
import Button from '../buttons/main-button/button';
import CreateActivitiesPrices from '../../containers/dive-offers/activities/modal-create-activities/create-activities-prices/create-activities-prices';
import ModalContainer from '../../components/modals/modal-container';
import { ModalCreateActivitiesStyle } from '../../containers/dive-offers/activities/modal-create-activities/modal-create-activities-style';
import { havePermissionsActivities } from '../../services/staff-centre';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

interface IWithCard {
  price: number;
  type: string;
  typeId: number;
}
export interface ICardActivityTemplateProps extends RouteComponentProps, WithTranslation {
  withCard?: IWithCard;
  name: string;
  backgroundImg?: string;
  dates?: any[];
  sortDates?: boolean;
  discipline?: string;
  kind?: any;
  onPlanActivity: any;
  courseInfo?: any;
  t:any;
  onNext: (price: number, cancellationPolicy: number) => void;
}

export interface ICardActivityTemplateState {
  renderModal: string;
}

class CardActivityTemplate extends React.Component<ICardActivityTemplateProps, ICardActivityTemplateState> {
  constructor(props: ICardActivityTemplateProps) {
    super(props);

    this.state = {
      renderModal: ''
    };
  }


  render() {
    const { withCard, name, backgroundImg, discipline, kind, courseInfo, onNext } = this.props;
    const { renderModal } = this.state;
    const { t } = this.props

    return (
      <CardActivityTemplateStyle className={withCard && 'with-card'}>
        <div className="card-activity-back">
          <img src={backgroundImg ? backgroundImg : images.activityBack} alt="" />
        </div>
        <div className="card-activity-content">
          <div className="card-activity-content-title">
            <div className="card-activity-content-title__text">
              <span>{courseInfo ? 'Formación' : 'Inmersión'}</span>
              <HeadDestacadoText>{name}</HeadDestacadoText>
              {discipline} {kind && kind.length ?
                (<span>
                  { <> ●  {kind.map((kind: string) => kind)} </>}
                </span>)
                : ''}
            </div>
          </div>
        </div>
        {withCard && (
          <div className="card">
            <div className="card-activity-white">
              {havePermissionsActivities() && (
                <div className="item-section-detail-edit" onClick={() => this.setState({ renderModal: 'edit-detail' })}>
                  {/*<BodyDestacadoText>{t('buttons:edit')}</BodyDestacadoText>*/}
                  <img src={images.editSvg} alt="" />
                </div>
              )}
              <div className="card-activity-white__sub-price">
                <BodyDestacadoText>{t('components:buy-resume.pricesince')}</BodyDestacadoText>
              </div>
              <div className="card-activity-white__price">
                <BodyDestacadoText>{withCard.price / 100} € / {t('components:buy-resume.diver')}</BodyDestacadoText>
              </div>
              <div className="card-activity-white__sub-policy">
                <LabelText>{t('components:buy-resume.cancel-politics')}: {withCard.type}</LabelText>
              </div>
              {/*<div className="card-activity-white__policy">*/}
              {/*  <BodyDestacadoText>{withCard.type}</BodyDestacadoText>*/}
              {/*</div>*/}
            </div>
            <div style={{ marginTop: 15 }}>
              <Button
                disabled={!havePermissionsActivities()}
                text={t('components:general.plan-activity-calendar')}
                type={'error'}
                leftIcon={images.calendarWhiteSvg}
                onClick={() => { this.props.onPlanActivity() }}
              />
            </div>
          </div>
        )}
        { withCard && renderModal === 'edit-detail' && (
          <ModalContainer
            title={t('components:general.priceCaptl')}
            modalClose={() => this.setState({ renderModal: '' })}
            active={renderModal === 'edit-detail'}
          >
            <ModalCreateActivitiesStyle>
              <CreateActivitiesPrices
                price={withCard.price}
                cancellationPolicy={withCard.typeId}
                onNext={(price: number, cancellationPolicy: number) => {
                  this.setState({ renderModal: '' })
                  onNext(price, cancellationPolicy);
                }}
              />
            </ModalCreateActivitiesStyle>
          </ModalContainer>


        )
        }
      </CardActivityTemplateStyle>
    );
  }
}

export default withTranslation(['components'])(withRouter(CardActivityTemplate));