import * as React from 'react';
import { AddItemFormCertifierStyle } from './add-item-form-certifier-style';
import InfoText from '../text/info-text/info-text';
import { images } from '../../assets/images';

export interface IAddItemFormCertifierProps {
  title: string;
  certifier: string;
  id: string;
  onDelete: Function;
}

export interface IAddItemFormCertifierState {}

export default class AddItemFormCertifier extends React.Component<
  IAddItemFormCertifierProps,
  IAddItemFormCertifierState
> {
  constructor(props: IAddItemFormCertifierProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { onDelete, title, certifier, id } = this.props;
    return (
      <AddItemFormCertifierStyle>
        <div className="add-item-form-certifier-img">
          <img src={images.turtle} alt="" />
        </div>
        <div className="add-item-form-certifier-text">
          <div className="add-item-form-certifier-text__title">
            <InfoText>{title}</InfoText>
          </div>
          <div className="add-item-form-certifier-text__certifier">
            <p>{certifier}</p>
          </div>
          <div className="add-item-form-certifier-text__id">
            <p>{id}</p>
          </div>
        </div>
        <div className="add-item-form-certifier-delete" onClick={() => onDelete()}>
          <img src={images.deleteSvg} alt="" />
        </div>
      </AddItemFormCertifierStyle>
    );
  }
}
