import React from 'react';
import { ItemCertificationContainer } from './item-certification-style';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import InfoText from '../text/info-text/info-text';
import Label02Text from '../text/label-02-text/label-02';
import { images } from '../../assets/images';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ItemCertificationProps extends WithTranslation {
  img: string;
  title: string;
  certification: string;
  idCertification: string;
  unofficial?: boolean;
  onDelete?: Function;
}
class ItemCertification extends React.Component<ItemCertificationProps, {}> {
  render() {
    const { img, title, certification, idCertification, unofficial, onDelete, t } = this.props;
    return (
      <ItemCertificationContainer>
        <div className="item-certification-img">
          <img src={img} alt="" />
        </div>
        <div className="item-certification-info">
          <div className="item-certification-info__title">
            <TextDestacadoText>{title}</TextDestacadoText>
          </div>
          <div className="item-certification-info__certification">
            <InfoText>{certification}</InfoText>
          </div>
          {idCertification !== '' && (
            <div className="item-certification-info__id">
              <Label02Text>ID {idCertification}</Label02Text>
            </div>
          )}
          {unofficial && (
            <div className="item-certification-info-validation">
              <Label02Text>{t('components:item-certification')}</Label02Text>
            </div>
          )}
        </div>
        {onDelete && (
          <div className="item-certification-delete">
            <img src={images.deleteSvg} alt="" />
          </div>
        )}
      </ItemCertificationContainer>
    );
  }
}

export default withTranslation(['intro', 'buttons', 'components'])(ItemCertification);
