import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const PopCalendarStyle = styled.div`
  padding: 58px 32px 32px 32px;
  width: 420px;

  .pop-calendar-status {
    text-transform: uppercase;
    margin-bottom: 8px;
    padding: 3px 6px;
    display: inline-block;
    &.pendant {
      color: ${colors['CO-004/100']};
      background: ${colors['CO-003/15']};
    }
    &.resolved {
      color: ${colors['CO-001/100']};
      background: ${colors['CO-001/15']};
    }
    &.validated {
      color: ${colors['CO-001/100']};
      background: ${colors['CO-001/15']};
    }
    &.registre {
      color: ${colors['CO-002/100']};
    }
    &.closed {
      color: ${colors['PR-001/100']};
      background: ${colors['CO-001/15']};
    }
    &.rejected {
      color: ${colors['CO-003/100']};
    }
  }

  .pop-calendar-title {
    margin-bottom: 6px;
  }

  .pop-calendar-date-wrapper {
    .pop-calendar-date:last-child {
      margin-bottom: 25px;
    }
  }
  .pop-calendar-date {
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 18px;
    &__day {
      margin-right: 12px;
    }
  }
  .pop-calendar-extra-date {
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .pop-calendar-info  {
    margin-bottom: 25px;
    &__item  {
      align-items: center;
      display: flex;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
      &__icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      &__text {
      }
    }
  }
  .pop-calendar-section {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 36px;
    }
    &__title  {
      color: ${colors['NE-002/100']};
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    &__staff {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 12px;
      }
      &__avatar  {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
          min-height: 100%;
          object-fit: cover;
        }
      }
    }
    &__miss {
      color: ${colors['CO-003/100']};
      font-size: 12px;
      line-height: 16px;
      font-weight: 300;
    }
    &__assistant {
      display: flex;
      margin-bottom: 8px;
      &__avatar {
        margin-right: 6px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
          min-height: 100%;
          object-fit: cover;
        }
      }
      &__more {
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${colors['NE-002/10']};
        width: 32px;
        height: 32px;
        border-radius: 100%;
      }
    }
  }
  .pop-calendar-buttons {
    display: flex;
    justify-content: space-between;
  }
`;
