import * as React from 'react';
import { ContentBlockContainer } from './content-block-style';
import { connect } from 'react-redux';
import IStoreState from '../../redux/store/IStoreState';
import services from '../../services/services';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ContentBlockProps extends WithTranslation { 
  isValidCentre?: boolean;
  children: any;
  t: any;
}


interface ContentBlockState {}

class ContentBlockClass extends React.Component<ContentBlockProps, ContentBlockState> {
  componentDidMount() {
    const { isValidCentre } = this.props;
    if (!isValidCentre) {
      document.body.style.overflow = 'hidden';
      services.pushNotification({
        title: '¡No disponible!',
        text: 'Para obtener acceso a esta sección sube el documento de responsabilidad civil.',
        type: 'yellow',
      });
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    const { isValidCentre } = this.props;
    if (!isValidCentre && document.body.style.overflow !== 'hidden') {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'scroll';
  }

  render() {
    const { children, isValidCentre, t } = this.props;
    if (isValidCentre) return children;
    return (
      <ContentBlockContainer>
        <div className="content-block-message-container">
          <div>
            <p dangerouslySetInnerHTML={{ __html: t('components:content-block.title') }}></p>
            <p dangerouslySetInnerHTML={{ __html: t('components:content-block.text') }}></p>
          </div>
        </div>
        <div className={'block-container-blocker'} />
        {children}
      </ContentBlockContainer>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    isValidCentre: state.isValidCentre.value,
  };
}

export const ContentBlock = withTranslation(['components']) (connect(mapStateToProps)(ContentBlockClass));