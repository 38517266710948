import { TableColumn } from '../../../../types/table';

export interface DiverDocument {
  id: string;
  kind: string;
  name: string;
  certifier: string;
  expirationDate: string;
  validated: boolean;
  file: string;
  docId: string;
}

export const tableColumns: TableColumn[] = [
  /*{
    text: '',
    filter: false,
    ascendent: true,
    key: 'check',
  },*/
  {
    text: 'Formato',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Tipo',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Nombre',
    filter: false,
    ascendent: false,
  },
  {
    text: 'ID',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Certificadora',
    filter: false,
    ascendent: true,
  },
/*  {
    text: 'F. Caducidad',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Validación',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Estado',
    filter: false,
    ascendent: true,
  },*/
  {
    text: '',
    filter: false,
    ascendent: true,
  },
];

export const rowKeys: string[] = [
  //'check',
  'icon',
  'kind',
  'name',
  'docId',
  'certifier',
  //'expirationDate',
  //'validate',
  //'status',
  'options',
];
