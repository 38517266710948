import styled from 'styled-components';

export const CreateActivitiesGalleryStyle = styled.div`
  .selected-pictures {
    margin-bottom: 2em;
    margin-top: 2em;
    border: 1px solid #ddd;
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 40px;
    &__picture {
      width: 100%;
      height: 110px;
      overflow: hidden;
      border-radius: 5px;
      margin-bottom: 12px;
      position: relative;
    }
    &__delete-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    &__icon {
      border-radius: 100%;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: rgba(13, 30, 63, 0.4);
      transition: all 0.3s ease-in-out;
      &:hover {
        background: rgba(13, 30, 63, 1);
      }
    }
  }
`;
