import * as React from 'react';
import { StatsLayoutStyle } from './stats-style';
import { MainLayoutSection } from '../main-layout-style';
import HeaderMain from '../../components/header-main/header-main';
import { Line, Bar, defaults } from 'react-chartjs-2';
import CardStats from '../../components/card-stats/card-stats';
import { colors } from '../../assets/colors';
import LabelText from '../../components/text/label-text/label';
import TextDestacadoText from '../../components/text/text-destacado-text/text-destacado-text';
import H3Text from '../../components/text/h3-text/h3-text';
import SelectBox from '../../components/select-box';
import { images } from '../../assets/images';
import HeadDestacadoText from '../../components/text/head-destacado-text/head-destacado-text';
import TextText from '../../components/text/text-text/text-text';
import InfoText from '../../components/text/info-text/info-text';
import DicotomicCheckbox from '../../components/dicotomic-checkbox';
import { getDivers } from '../../services/diver';
import { getCentres } from '../../services/centre';
import { COUNTRIES_KEY_LABEL } from '../../constants/countries';
import moment, { Moment } from 'moment';
import { copyOf } from '../../utils/other';
import {
  lineDatasetTemplate,
  diversBarDatasetTemplate,
  optionsLine,
  optionsPie,
  data,
  centresBarDatasetTemplate,
} from './stats-templates';
import CalendarBox from '../../components/calendar-box';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { getCertifierDivers, getOrganizationCentres } from '../../services/collaborator';

export interface IStatsLayoutProps extends RouteComponentProps, WithTranslation { }

export interface IStatsLayoutState {
  divers?: any;
  centres?: any;
  numMaleDivers: number;
  numFemaleDivers: number;
  numUnknownDivers: number;
  numMaleInstructors: number;
  numFemaleInstructors: number;
  numUnknownInstructors: number;
  numClubCentres: number;
  numCentreCentres: number;
  numCruiseCentres: number;
  diversCountries: { value: string; label: string }[];
  centresCountries: { value: string; label: string }[];
  signUpChartPeriod: string;
  activityChartPeriod: string;
  billingChartPeriod: string;
  customSingUpDateStart: Moment;
  customSignUpDateEnd: Moment;
  signUpDiversFilter: boolean;
  signUpCentresFilter: boolean;
  signUpClubsFilter: boolean;
  signUpCountryFilter: string;
}

class StatsCertifierLayout extends React.Component<IStatsLayoutProps, IStatsLayoutState> {
  /*
    Periods:
      -today
      -yesterday
      -month
      -3month
      -custom
  */

  constructor(props: IStatsLayoutProps) {
    super(props);

    this.state = {
      numMaleDivers: 0,
      numFemaleDivers: 0,
      numUnknownDivers: 0,
      numMaleInstructors: 0,
      numFemaleInstructors: 0,
      numUnknownInstructors: 0,
      numClubCentres: 0,
      numCentreCentres: 0,
      numCruiseCentres: 0,
      diversCountries: [],
      centresCountries: [],
      signUpChartPeriod: 'today',
      activityChartPeriod: 'today',
      billingChartPeriod: 'today',
      customSingUpDateStart: moment(),
      customSignUpDateEnd: moment(),
      signUpDiversFilter: false,
      signUpCentresFilter: false,
      signUpClubsFilter: false,
      signUpCountryFilter: 'all',
    };
  }

  componentDidMount() {
    this.getDiversStats();
    this.getCentresStats();
  }

  async getDiversStats() {
    const params = {
      filter: {
        relations: ['user'],
        orderBy: [{ key: 'createdAt', direction: 'ASC' }],
      },
    };
    const organizationId: string = localStorage.getItem('organizationId') || '';
    const divers: any = (await getCertifierDivers(organizationId, params)).data.data;
    let numMaleDivers = 0;
    let numFemaleDivers = 0;
    let numUnknownDivers = 0;
    let numMaleInstructors = 0;
    let numFemaleInstructors = 0;
    let numUnknownInstructors = 0;
    const diversCountries: any = [
      {
        value: 'all',
        label: 'Todos los países',
      },
    ];
    divers.forEach((diver: any) => {
      if (diver.user && diver.user.gender && diver.user.gender === 'male') {
        if (diver.isInstructor) numMaleInstructors += 1;
        else numMaleDivers += 1;
      } else if (diver.user && diver.user.gender && diver.user.gender === 'female') {
        if (diver.isInstructor) numFemaleInstructors += 1;
        else numFemaleDivers += 1;
      } else {
        if (diver.isInstructor) numUnknownInstructors += 1;
        else numUnknownDivers += 1;
      }
      if (
        COUNTRIES_KEY_LABEL[diver.country] &&
        diver.country &&
        diversCountries.filter((c: any) => c.value === diver.country).length === 0
      ) {
        diversCountries.push({
          value: diver.country,
          label: COUNTRIES_KEY_LABEL[diver.country],
        });
      }
    });
    this.setState({
      numMaleDivers,
      numFemaleDivers,
      numUnknownDivers,
      numMaleInstructors,
      numFemaleInstructors,
      numUnknownInstructors,
      diversCountries,
      divers,
    });
  }

  async getCentresStats() {
    const params = {
      filter: {
        relations: ['user'],
        orderBy: [{ key: 'createdAt', direction: 'ASC' }],
      },
    };
    const organizationId: string = localStorage.getItem('organizationId') || '';
    const centres: any = (await getOrganizationCentres(organizationId, params)).data.data;
    let numClubCentres = 0;
    let numCentreCentres = 0;
    let numCruiseCentres = 0;
    const centresCountries: any = [
      {
        value: 'all',
        label: 'Todos los países',
      },
    ];
    centres.forEach((centre: any) => {
      switch (centre.type) {
        case 'club':
          numClubCentres += 1;
          break;
        case 'centre':
          numCentreCentres += 1;
          break;
        case 'cruise':
          numCruiseCentres += 1;
          break;
      }
      if (
        COUNTRIES_KEY_LABEL[centre.country] &&
        centre.country &&
        centresCountries.filter((c: any) => c.value === centre.country).length === 0
      ) {
        centresCountries.push({
          value: centre.country,
          label: COUNTRIES_KEY_LABEL[centre.country],
        });
      }
    });
    this.setState({
      numClubCentres,
      numCentreCentres,
      numCruiseCentres,
      centresCountries,
      centres,
    });
  }

  getLineData(elements: { createdAt: string }[], range: string, dateStart: Moment, dateEnd: Moment) {
    if (!elements) return [];
    const elementsData: { t: any; y: number }[] = [{ t: dateStart.toISOString(), y: 0 }];
    elements.forEach((element: any) => {
      const date = moment(element.createdAt);
      switch (range) {
        case 'month':
          date.startOf('month');
          break;
        case 'day':
          date.startOf('day');
          break;
        case 'hour':
          date.startOf('hour');
      }
      if (
        (!dateStart || date.isAfter(dateStart) || date.isSame(dateStart)) &&
        (!dateEnd || date.isBefore(dateEnd) || date.isSame(dateEnd))
      ) {
        let newItem = true;
        elementsData.forEach(elementData => {
          if (elementData.t === date.toISOString()) {
            elementData.y += 1;
            newItem = false;
          }
        });
        if (newItem) {
          elementsData.push({
            t: date.toISOString(),
            y: 1,
          });
        }
      }
    });
    if (elementsData[elementsData.length - 1].t !== dateEnd.toISOString()) {
      elementsData.push({
        t: dateEnd.toISOString(),
        y: 0,
      });
    }
    return elementsData;
  }

  getPeriod(period: string) {
    const { customSingUpDateStart, customSignUpDateEnd } = this.state;
    switch (period) {
      case 'today':
        return { range: 'hour', dateStart: moment().startOf('day'), dateEnd: moment().endOf('day') };
      case 'yesterday':
        return {
          range: 'hour',
          dateStart: moment()
            .add(-1, 'days')
            .startOf('day'),
          dateEnd: moment()
            .add(-1, 'days')
            .endOf('day'),
        };
      case 'month':
        return { range: 'day', dateStart: moment().startOf('month'), dateEnd: moment().endOf('day') };
      case '3month':
        return {
          range: 'day',
          dateStart: moment()
            .add(-3, 'month')
            .startOf('month'),
          dateEnd: moment().endOf('day'),
        };
      case 'custom':
        const diff = customSignUpDateEnd.diff(customSingUpDateStart, 'days');
        return {
          range: diff < 2 ? 'hour' : diff < 150 ? 'day' : 'month',
          dateStart: customSingUpDateStart.startOf('day'),
          dateEnd: customSignUpDateEnd.endOf('day'),
        };
    }
    return {
      range: 'day',
      dateStart: moment(),
      dateEnd: moment().add(1, 'days'),
    };
  }

  setLineColors(object: any, color: string) {
    object.backgroundColor = color;
    object.borderColor = color;
    object.pointBorderColor = color;
    object.pointHoverBackgroundColor = color;
  }

  getSignInStats() {
    const {
      divers,
      centres,
      signUpChartPeriod,
      signUpCentresFilter,
      signUpClubsFilter,
      signUpDiversFilter,
      signUpCountryFilter,
    } = this.state;

    const centresFiltered = centres
      ? centres.filter((c: any) => signUpCountryFilter === 'all' || signUpCountryFilter === c.country)
      : null;
    const diversFiltered = divers
      ? divers
        .filter((d: any) => signUpCountryFilter === 'all' || signUpCountryFilter === d.country)
        .filter((d: any) => !d.isInstructor)
      : null;
    const instructorsFiltered = divers
      ? divers
        .filter((d: any) => signUpCountryFilter === 'all' || signUpCountryFilter === d.country)
        .filter((d: any) => d.isInstructor)
      : null;

    const { range, dateStart, dateEnd } = this.getPeriod(signUpChartPeriod);
    const diversData: { t: any; y: number }[] = this.getLineData(diversFiltered, range, dateStart, dateEnd);
    const instructorsData: { t: any; y: number }[] = this.getLineData(instructorsFiltered, range, dateStart, dateEnd);
    const centresData: { t: any; y: number }[] = this.getLineData(centresFiltered, range, dateStart, dateEnd);
    const diversDataset = copyOf(lineDatasetTemplate);
    const centresDataset = copyOf(lineDatasetTemplate);
    const instructorsDataset = copyOf(lineDatasetTemplate);
    const options = copyOf(optionsLine);
    options.scales.xAxes[0].time.unit = range;

    diversDataset.label = 'Buceadores';
    diversDataset.data = diversData;
    this.setLineColors(diversDataset, colors['CO-004/100']);

    centresDataset.label = 'Centros';
    centresDataset.data = centresData;
    this.setLineColors(centresDataset, colors['SE-005/100']);

    instructorsDataset.label = 'Instructores';
    instructorsDataset.data = instructorsData;
    this.setLineColors(instructorsDataset, colors['SE-002/100']);

    let datasets = [];
    if (signUpDiversFilter) {
      datasets.push(diversDataset);
    }
    if (signUpClubsFilter) {
      datasets.push(instructorsDataset);
    }
    if (signUpCentresFilter) {
      datasets.push(centresDataset);
    }
    if (!signUpCentresFilter && !signUpClubsFilter && !signUpDiversFilter) {
      datasets = [diversDataset, instructorsDataset, centresDataset];
    }

    const signInNumDivers = diversDataset.data.reduce((prev: number, actual: any) => {
      return prev + actual.y;
    }, 0);
    const signInNumCentres = centresDataset.data.reduce((prev: number, actual: any) => {
      return prev + actual.y;
    }, 0);
    const signInNumInstructors = instructorsDataset.data.reduce((prev: number, actual: any) => {
      return prev + actual.y;
    }, 0);

    return {
      dateStart,
      dateEnd,
      signInNumDivers,
      signInNumCentres,
      signInNumInstructors,
      options,
      data: {
        datasets,
        type: 'line',
      },
    };
  }

  getDiversBars() {
    const { numMaleDivers, numFemaleDivers, numUnknownDivers } = this.state;
    const datasets = copyOf(diversBarDatasetTemplate);
    datasets[0].label = 'Numero de buceadores';
    datasets[0].data = [numMaleDivers, numFemaleDivers, numUnknownDivers];
    return {
      datasets,
      labels: ['Hombres', 'Mujeres', 'Desconocido'],
    };
  }

  getInstructorBars() {
    const { numMaleInstructors, numFemaleInstructors, numUnknownInstructors } = this.state;
    const datasets = copyOf(centresBarDatasetTemplate);
    datasets[0].label = 'Numero de instructores';
    datasets[0].data = [numMaleInstructors, numFemaleInstructors, numUnknownInstructors]; // Se deja preparado cruceros
    return {
      datasets,
      labels: ['Hombres', 'Mujeres', 'Desconocido'],
    };
  }

  filterDiversBar(countryCode: string) {
    const { divers } = this.state;
    let numMaleDivers = 0;
    let numFemaleDivers = 0;
    let numUnknownDivers = 0;
    divers.forEach((diver: any) => {
      if (countryCode !== 'all' && diver.country !== countryCode) return;
      if (diver.user && diver.user.gender && diver.user.gender === 'male') {
        numMaleDivers += 1;
      } else if (diver.user && diver.user.gender && diver.user.gender === 'female') {
        numFemaleDivers += 1;
      } else {
        numUnknownDivers += 1;
      }
    });
    this.setState({ numMaleDivers, numFemaleDivers, numUnknownDivers });
  }

  filterCentresBar(countryCode: string) {
    const { centres } = this.state;
    let numClubCentres = 0;
    let numCentreCentres = 0;
    let numCruiseCentres = 0;
    centres.forEach((centre: any) => {
      if (countryCode !== 'all' && centre.country !== countryCode) return;
      switch (centre.type) {
        case 'club':
          numClubCentres += 1;
          break;
        case 'centre':
          numCentreCentres += 1;
          break;
        case 'cruise':
          numCruiseCentres += 1;
          break;
      }
    });
    this.setState({
      numClubCentres,
      numCentreCentres,
      numCruiseCentres,
      centres,
    });
  }

  render() {
    const { t } = this.props;
    const {
      numMaleDivers,
      numFemaleDivers,
      numUnknownDivers,
      numCentreCentres,
      numClubCentres,
      numCruiseCentres,
      numMaleInstructors,
      numFemaleInstructors,
      numUnknownInstructors,
      diversCountries,
      centresCountries,
      signUpChartPeriod,
    } = this.state;
    const totalDivers = numMaleDivers + numFemaleDivers + numUnknownDivers;
    const totalInstructor = numMaleInstructors + numFemaleInstructors + numUnknownInstructors;
    const dataDiverBar = this.getDiversBars();
    const dataInstructorBar = this.getInstructorBars();
    const signInStats = this.getSignInStats();
    return (
      <>
        <HeaderMain title={t('layouts:stats.header')} />
        <MainLayoutSection>
          <StatsLayoutStyle>
            <div className="stats-lines">
              <div className="stats-lines-cards">
                <div className="stats-lines-cards__title">
                  <HeadDestacadoText>{t('layouts:stats.registres')}</HeadDestacadoText>
                </div>
                <div className="stats-lines-cards__gap">
                  <TextText>{`${signInStats.dateStart.format('DD [de] MMM YYYY')} - ${signInStats.dateEnd.format(
                    'DD [de] MMM YYYY'
                  )}`}</TextText>
                </div>
                <div className="stats-lines-cards__item">
                  <CardStats
                    className={'orange'}
                    number={signInStats.signInNumDivers}
                    subtitle={t('layouts:stats.new')}
                    title={t('layouts:stats.divers')}
                  />
                </div>
                <div className="stats-lines-cards__item">
                  <CardStats
                    className={'blue'}
                    number={signInStats.signInNumInstructors}
                    subtitle={t('layouts:stats.new')}
                    title={t('layouts:stats.instructors')}
                  />
                </div>
                <div className="stats-lines-cards__item">
                  <CardStats
                    className={'light-blue'}
                    number={signInStats.signInNumCentres}
                    subtitle={t('layouts:stats.new')}
                    title={t('layouts:stats.dive-centers')}
                  />
                </div>
              </div>

              <div className="stats-lines-graph">
                <div className="stats-lines-graph-filters">
                  <div className="stats-lines-graph-filters__left">
                    <SelectBox
                      // translate
                      className="rounded-filter"
                      labelText={t('inputs:country.label')}
                      placeholder={t('inputs:country.label')}
                      required={true}
                      initialValue={''}
                      optionsText={[...diversCountries.slice(1, -1), ...centresCountries]}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={signUpCountryFilter => {
                        this.setState({ signUpCountryFilter });
                      }}
                    />
                  </div>
                  <div className="stats-lines-graph-filters__right">
                    <div
                      className={`stats-lines-graph-filters__right__item ${signUpChartPeriod === 'today' ? 'active' : ''
                        }`}
                      onClick={() => this.setState({ signUpChartPeriod: 'today' })}
                    >
                      <InfoText>{t('layouts:stats.filters.today')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${signUpChartPeriod === 'yesterday' ? 'active' : ''
                        }`}
                      onClick={() => this.setState({ signUpChartPeriod: 'yesterday' })}
                    >
                      <InfoText>{t('layouts:stats.filters.yesterday')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${signUpChartPeriod === 'month' ? 'active' : ''
                        }`}
                      onClick={() => this.setState({ signUpChartPeriod: 'month' })}
                    >
                      <InfoText>{t('layouts:stats.filters.this-month')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${signUpChartPeriod === '3month' ? 'active' : ''
                        }`}
                      onClick={() => this.setState({ signUpChartPeriod: '3month' })}
                    >
                      <InfoText>{t('layouts:stats.filters.three-months')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${signUpChartPeriod === 'custom' ? '' : ''}`}
                      onClick={() => this.setState({ signUpChartPeriod: 'custom' })}
                    >
                      {signUpChartPeriod !== 'custom' ? (
                        <InfoText>{t('layouts:stats.filters.custom')}</InfoText>
                      ) : (
                          <CalendarBox
                            initialValue={moment().format('DD/MM/YYYY')}
                            labelText=""
                            icon={images.calendarSvg}
                            onChange={(value, err, secondValue) => {
                              this.setState({
                                customSingUpDateStart: moment(value, 'DD/MM/YYYY'),
                                customSignUpDateEnd: moment(secondValue, 'DD/MM/YYYY'),
                                signUpChartPeriod: 'custom',
                              });
                            }}
                            rangeSelect={true}
                            isOutsideRange={date =>
                              moment(date, 'DD/MM/YYYY').isBefore(moment('01/12/2019', 'DD/MM/YYYY')) ||
                              moment(date, 'DD/MM/YYYY').isAfter(moment(new Date()))
                            }
                          />
                        )}
                    </div>
                  </div>
                </div>
                <div className="stats-lines-graph-draw">
                  <div className="stats-lines-graph-draw-filters">
                    <div className="stats-lines-graph-draw-filters__item">
                      <DicotomicCheckbox onCheck={checked => this.setState({ signUpDiversFilter: checked })}>
                        <div className="stats-lines-graph-draw-filters__item__text red">
                          <InfoText>{t('layouts:stats.divers')}</InfoText>
                        </div>
                      </DicotomicCheckbox>
                    </div>

                    <div className="stats-lines-graph-draw-filters__item">
                      <DicotomicCheckbox onCheck={checked => this.setState({ signUpClubsFilter: checked })}>
                        <div className="stats-lines-graph-draw-filters__item__text light-blue">
                          <InfoText>{t('layouts:stats.instructors')}</InfoText>
                        </div>
                      </DicotomicCheckbox>
                    </div>

                    <div className="stats-lines-graph-draw-filters__item">
                      <DicotomicCheckbox onCheck={checked => this.setState({ signUpCentresFilter: checked })}>
                        <div className="stats-lines-graph-draw-filters__item__text blue">
                          <InfoText>{t('layouts:stats.dive-centers')}</InfoText>
                        </div>
                      </DicotomicCheckbox>
                    </div>
                  </div>

                  <StatsLayoutStyle>
                    <Line options={signInStats.options} data={signInStats.data} />
                  </StatsLayoutStyle>
                </div>
              </div>
            </div>

            <div className="stats-bar-resume">
              <div className="stats-bar-resume__title">
                <HeadDestacadoText>Resumen general</HeadDestacadoText>
              </div>
              {/* <div className="stats-bar-resume__date">
                <TextText>1 de Junio, 2019 — 21 de Junio, 2019</TextText>
              </div> */}
            </div>

            <div className="stats-bar-container">
              <div className="stats-bar">
                <div className="stats-bar-cards">
                  <div className="stats-bar-cards__info">
                    <div className="stats-bar-cards__info__total">
                      <LabelText>{t('layouts:stats.total')}</LabelText>
                    </div>
                    <div className="stats-bar-cards__info__title">
                      <TextDestacadoText>{t('layouts:stats.divers')}</TextDestacadoText>
                    </div>
                    <div className="stats-bar-cards__info__number">
                      <H3Text>{totalDivers}</H3Text>
                    </div>
                  </div>
                  <div className="stats-bar-cards__filter">
                    <SelectBox
                      className="rounded-filter"
                      labelText="Certificadora"
                      placeholder="Seleccione"
                      required={true}
                      initialValue={''}
                      optionsText={diversCountries}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.filterDiversBar(value)}
                    />
                  </div>
                </div>

                <div className="stats-bar-graph">
                  <StatsLayoutStyle>
                    <Bar options={optionsPie} data={dataDiverBar} />
                  </StatsLayoutStyle>
                </div>
              </div>
              <div className="stats-bar">
                <div className="stats-bar-cards">
                  <div className="stats-bar-cards__info">
                    <div className="stats-bar-cards__info__total">
                      <LabelText>{t('layouts:stats.total')}</LabelText>
                    </div>
                    <div className="stats-bar-cards__info__title">
                      <TextDestacadoText>{t('layouts:stats.instructors')}</TextDestacadoText>
                    </div>
                    <div className="stats-bar-cards__info__number">
                      <H3Text>{totalInstructor}</H3Text>
                    </div>
                  </div>
                  <div className="stats-bar-cards__filter">
                    <SelectBox
                      className="rounded-filter"
                      labelText="Certificadora"
                      placeholder="Seleccione"
                      required={true}
                      initialValue={''}
                      optionsText={diversCountries}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.filterCentresBar(value)}
                    />
                  </div>
                </div>
                <div className="stats-bar-graph">
                  <StatsLayoutStyle>
                    <Bar options={optionsPie} data={dataInstructorBar} />
                  </StatsLayoutStyle>
                </div>
              </div>
            </div>
          </StatsLayoutStyle>
        </MainLayoutSection>
      </>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(withRouter(StatsCertifierLayout));
