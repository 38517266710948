import services from './services';

export const getNotifications = async (userId: string, params: any) => {
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      endpoint: `notifications/user/${userId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getOneNotifications = (notificationId: string) => {
  const endpoint = `notifications/${notificationId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadNotificationsFormData = (notificationId: string, data: FormData) => {
  const endpoint = `notifications/${notificationId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const uploadNotifications = (notificationId: string, data: any) => {
  const endpoint = `notifications/${notificationId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteNotifications = (notificationId: string) => {
  const endpoint = `notifications/${notificationId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createNotifications = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'notifications',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const readNotifications = (notificationId: string) => {
  const endpoint = `notifications/${notificationId}/read`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const favNotifications = (notificationId: string) => {
  const endpoint = `notifications/${notificationId}/fav`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const countUnreadNotifications = (userId: number, centreId?: number | undefined) => {
//  const endpoint = `notifications/${userId}/countUnread` + centreId ? `/${Number(centreId).toString()}` : '';
  const endpoint = `notifications/${userId}/countUnread`;
  
  const params = {
      centreId: centreId,
  }
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};
