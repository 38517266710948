import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CardConservationDetailStyle = styled.div`
.card-dive-detail-img {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  height: 192px;
  img {
    height: 100%;
    width: auto;
    min-width: 100%;
    object-fit: cover;
  }
}
.card-dive-detail-content {

  &__title {

margin-bottom: 16px;
  }
  &__center {
    margin-bottom: 8px;
  }
  &__date {
    margin-bottom: 8px;
  }

  &__info {
    color: ${colors['SE-002/100']};
    display: flex;
    align-items: center;
    
    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;


    }
   }
  
}
.card-dive-detail-content-subtitle {
  color: ${colors['CO-004/100']};
  margin-bottom: 2px;
  display: flex;

  &__item {
    position: relative;
    padding-left: 20px;
    text-transform: uppercase;
    &:first-child {
    padding-left: 0px;
    &::before {
      display: none;
    }

    }
    &::before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background:${colors['CO-004/50']};
      position: absolute;
      left: 8px;
      top : 6px;

    }

  }
}
`;
