import * as React from 'react';
import { ActivityTemplatesDetailGeneralStyle } from './activity-templates-detail-general-style';
import CardActivityTemplate from '../../../../../components/card-activity-template/card-activity-template';
import ItemSectionDetail from '../../../../../components/item-sections-detail/item-sections-detail';
import { images } from '../../../../../assets/images';
import TextDestacadoText from '../../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../../components/text/text-text/text-text';
import { IActivityTemplate } from '../../../../../models/activity-template';
import { DiveDisciplinesKeyValues, DiveKindsKeyValues, DiveCourseTypes } from '../../../../../constants/dive-dropdowns';
import { getLevels } from '../../../../../services/levels';
import { CANCELLATION_POLICIES_KEY_VALUES } from '../../../../../constants/cancellation-policies';
import { capitalize, getLanguagesText } from '../../../../../utils/texts';
import { TRANSPORT_TYPES_KEY_VALUES } from '../../../../../constants/transport';
import { IMultilang, IId } from '../../../../../models/generic';
import { getUserLanguageComment, copyOf } from '../../../../../utils/other';
import ModalRegistreDive from '../../../../../components/modals/modal-registre-dive/modal-registre-dive';
import { IDiveForm } from '../../../../../models/dive-forms';
import { AttachedFile } from '../../../../../types/file';
import { createDives, uploadMainPicture, uploadPictures } from '../../../../../services/dives';
import services from '../../../../../services/services';
import ModalContainer from '../../../../../components/modals/modal-container';
import CreateActivitiesGeneralData from '../../../../dive-offers/activities/modal-create-activities/create-activities-general-data/create-activities-general-data';
import {
  IDiveGeneralData,
  IMinimumRequirements,
  IOtherServices,
  ICourseTemplateInfo,
  IBoatsData,
  ICourseGeneralData,
} from '../../../../../models/activity-template';
import { deleteActivityTemplatePictures, patchActivityTemplate, postActivityTemplatePictures } from '../../../../../services/activity-templates';
import CreateActivitiesMinimunRequirements from '../../../../dive-offers/activities/modal-create-activities/create-activities-minimum-requirements/create-activities-minimum-requirements';
import CreateActivitiesOtherServices from '../../../../dive-offers/activities/modal-create-activities/create-activities-other-services/create-activities-other-services';
import CreateActivitiesDetails from '../../../../dive-offers/activities/modal-create-activities/create-activities-details/create-activities-details';
import CreateActivitiesIncludedServices from '../../../../dive-offers/activities/modal-create-activities/create-activities-included-services/create-activities-included-services';
import CreateActivitiesIncludedEquipment from '../../../../dive-offers/activities/modal-create-activities/create-activities-included-equipment/create-activities-included-equipment';
import CreateActivitiesBoats from '../../../../dive-offers/activities/modal-create-activities/create-activities-boats/create-activities-boats';
import { ModalCreateActivitiesStyle } from '../../../../dive-offers/activities/modal-create-activities/modal-create-activities-style';
import { getCentres } from '../../../../../services/centre';
import CreateActivitiesCourseInfo from '../../../../dive-offers/activities/modal-create-activities/create-activities-course-info/create-activities-course-info';
import CreateActivitiesGallery from '../../modal-create-activities/create-activities-gallery/create-activities-gallery';
import { transformToUser, getUserUnit } from '../../../../../utils/measurement-units';
import PlanningDiveSite from '../../../../planning/modal-planning-activity/planning-dive-site/planning-dive-site';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CENTRE_SERVICES_KEY_ICON, CENTRE_SERVICES_KEY_VALUE } from '../../../../../constants/centre-services';
import CreateActivitiesExclusiveness
  from '../../modal-create-activities/create-activities-exclusiveness/create-activities-exclusiveness';
import CreateActivitiesAdditionalInfo
  from '../../modal-create-activities/create-activities-additional-info/create-activities-additional-info';
import ModalPlanningActivity from '../../../../planning/modal-planning-activity/modal-planning-activity';
import CreateActivitiesGeneralDataTraining from '../../../../dive-offers/activities/modal-create-activities/create-activities-general-data-training/create-activities-general-data-training';
import { getCourse, getCourseCentre, getCourses } from '../../../../../services/courses';
import parser from 'html-react-parser';
import { havePermissionsActivities } from '../../../../../services/staff-centre';
import DetailGeneralDouble from '../../../../../components/item-sections-detail/detail-general-double/detail-general-double';
export interface IActivityTemplatesDetailGeneralProps extends WithTranslation {
  activity?: IActivityTemplate;
  centreId: string;
  onReload: () => void;
  status: string;
}

export interface IActivityTemplatesDetailGeneralState {
  levels?: any[];
  renderModal: string;
  centres: any;
  selectedDiveIndex: number;
}

class ActivityTemplatesDetailGeneral extends React.Component<
  IActivityTemplatesDetailGeneralProps,
  IActivityTemplatesDetailGeneralState
> {
  constructor(props: IActivityTemplatesDetailGeneralProps) {
    super(props);
    this.state = {
      renderModal: '',
      centres: {},
      selectedDiveIndex: 0,
    };
  }

  componentDidMount() {
    const { activity } = this.props;
    localStorage.removeItem('arrayImages')
    if (activity) {
      this.getLevels(activity.minimumRequisites.level);
    }
  }

  componentDidUpdate(prevProps: IActivityTemplatesDetailGeneralProps) {
    const { activity } = this.props;

    if (activity && !prevProps.activity) {
      this.getLevels(activity.minimumRequisites.level);
    }
  }

  async getLevels(minimunRequisites: any) {
    const { activity } = this.props;
    const { levels } = this.state;
    if (activity && !levels) {
      const res = await getCourses();
      const levels = res.data.data;
      this.setState({ levels });
    }
  }

  async getCentres() {
    const resp = await getCentres();
    const centres: any = {};
    resp.data.data.forEach((centre: any) => {
      centres[centre.id] = centre.name;
    });
    this.setState({ centres });
  }

  async onCreateDive(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) {
    const { onReload } = this.props;
    const resp: any = await createDives(dive);
    const diveId = resp.data.id.toString();
    let editData = null;
    /*if (activity && activity.courseInfo) {
      editData = {
        courseInfo: activity.courseInfo,
      };
      editData.courseInfo.practicalPhase.blocks[selectedDiveIndex].diveId = parseInt(diveId, undefined);
    }*/
    if (editData) {
      await this.onEditActivity(editData);
    }
    if (mainPicture) {
      const formDataMain = new FormData();
      formDataMain.append('file', mainPicture[0].data);
      await uploadMainPicture(diveId, formDataMain);
    }
    if (pictures) {
      const formData = new FormData();
      for (const picture of pictures) {
        formData.append('files', picture.data);
      }
      await uploadPictures(diveId, formData);
    }
    this.setState({ renderModal: '' });
    onReload();
    services.showModalSuccess('¡Genial!', 'La inmersión se ha creado correctamente');
  }

  async onEditActivity(data: any) {
    const { activity, onReload } = this.props;
    if (activity) {

      await patchActivityTemplate(activity.id.toString(), data);
      services.pushNotification({
        title: '¡Editado!',
        text: 'La actividad se ha editado con éxito',
        type: 'green',
      });
      this.setState({ renderModal: '' });
      onReload();
    }
  }

  onEditGallery = async (pictures: any, picturesDelete: any) => {

    const { activity, onReload } = this.props;
    if (activity) {
      const id = activity.id;
      if (localStorage.getItem('arrayImages')) {
        const images = localStorage.getItem('arrayImages');
        if (images) {
          const formData = new FormData();
          let arrayImages: any = images.split(',');
          for (let i = 0; i < arrayImages.length; i++) {
            if (localStorage.getItem(arrayImages[i])) {
              let file = this.dataURLtoFile(localStorage.getItem(arrayImages[i]), arrayImages[i]);
              formData.append('files', file);
              localStorage.removeItem(arrayImages[i])
            }
          }
          await postActivityTemplatePictures(id.toString(), formData);
          localStorage.removeItem('arrayImages')
        }
      }
    }


    /*
    if (activity && pictures.length !== 0) {
      const formData = new FormData();
      pictures.forEach((picture: any) => {
        formData.append('files', picture.data);
      });
      const id = activity.id;
      await postActivityTemplatePictures(id.toString(), formData);
    }*/


    if (this.props.activity && picturesDelete.length !== 0) {
      const id = this.props.activity.id;
      await deleteActivityTemplatePictures(id.toString(), { pictures: picturesDelete });
    }
    this.setState({ renderModal: '' });
    onReload();
  }


  dataURLtoFile(dataurl: any, filename: string) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }



  renderSites(item: any, index: number) {
    return (
      <div className="activity-detail-general-right-section__sites" key={index}>
        <div style={{ marginBottom: '2em' }}>
          <p style={{ color: '#61656D', fontSize: '20px' }}>{index + 1} - {item.name}</p>
        </div>
        <div style={{ paddingLeft: 20 }}>
          <div className="activity-detail-general-double">
            <div className="activity-detail-general-double__title">
              <TextDestacadoText>Ubicación:</TextDestacadoText>
            </div>
            <div className="activity-detail-general-double__text">
              <TextText>{item.latitude} {item.longitude}</TextText>
            </div>
          </div>
          <div className="activity-detail-general-double">
            <div className="activity-detail-general-double__title">
              <TextDestacadoText>Punto de inmersión:</TextDestacadoText>
            </div>
            <div className="activity-detail-general-double__text">
              <TextText>{item.name}</TextText>
            </div>
          </div>
          <div className="activity-detail-general-double">
            <div className="activity-detail-general-double__title">
              <TextDestacadoText>Lugar:</TextDestacadoText>
            </div>
            <div className="activity-detail-general-double__text">
              <TextText>{item.state}</TextText>
            </div>
          </div>
          <div className="activity-detail-general-double">
            <div className="activity-detail-general-double__title">
              <TextDestacadoText>Profundidad mín - máx:</TextDestacadoText>
            </div>
            <div className="activity-detail-general-double__text">
              <TextText>{item.minDepth} - {item.maxDepth} m</TextText>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { activity, centreId, t } = this.props;
    const { levels, renderModal, selectedDiveIndex } = this.state;
    //const confirmedDivers = activity.bookings ? activity.bookings : [];
    //const confirmedStaff = activity.staff_activities ? activity.staff_activities.filter(staff => staff.staff) : [];
    //const dates = activity ? activity.timeBlocks.filter((t: any) => !!t.start.date) : [];

    if (!activity || !levels) return '';
    let levelName = 'Ninguna';

    if (parseInt(activity.minimumRequisites.level) !== -1 && levels && activity.minimumRequisites) {
      for (const level of levels) {
        if (level.id === activity.minimumRequisites.level) {
          levelName = level.name
        }
      }
    }

    let typeCourse = [];
    for (let i = 0; i < DiveCourseTypes.length; i++) {
      if (activity.course && DiveCourseTypes[i].value == activity.course.kind) {
        typeCourse.push(DiveCourseTypes[i].label)
      }
    }

    return (
      <ActivityTemplatesDetailGeneralStyle>
        <div className="activity-detail-general-hero">
          <CardActivityTemplate
            withCard={{
              price: activity.price,
              type: activity && activity.cancellationPolicy ? CANCELLATION_POLICIES_KEY_VALUES[activity.cancellationPolicy.type] : '',
              typeId: activity && activity.cancellationPolicy ? activity.cancellationPolicy.id : '',
            }}
            name={activity.name}
            discipline={DiveDisciplinesKeyValues[activity.discipline]}
            kind={
              activity && activity.kind ?
                activity.kind.map((kind: string) => {
                  return DiveKindsKeyValues[kind] + ' ';
                })
                :
                typeCourse &&
                typeCourse.map((course: string) => {
                  return course + ' ';
                })
            }
            sortDates={true}
            // dates={dates.length > 0 ? dates : undefined}
            backgroundImg={activity.pictures && activity.pictures.length > 0 ? activity.pictures[0] : undefined}
            onPlanActivity={() => this.setState({ renderModal: 'modal-plan-activity' })}
            courseInfo={activity.courseInfo}
            onNext={(price: number, cancellationPolicy: number) => {

              this.onEditActivity({ price, cancellationPolicy });
            }}
          />
        </div>
        <div className="activity-detail-general-container">
          <div className="activity-detail-general-left">

            <div className="activity-detail-general-left__section">
              {activity.courseInfo ? (
                <ItemSectionDetail
                  title={t('components:general.datosgral')}
                  disabled={!havePermissionsActivities()}
                  icon={images.dataGeneral}
                  onEdit={() => this.setState({ renderModal: 'edit-general-data-formation' })}
                >
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{t('components:general.certifier')}:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.course && activity.course.certifier ? activity.course.certifier.name : ''}</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{t('components:general.curesp')}:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.course ? activity.course.name : ''}</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{t('components:general.type')}:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{typeCourse}</TextText>
                    </div>
                  </div>
                  {/*
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Nº mínimo buceadores:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.diveCount}</TextText>
                    </div>
                  </div>
                  {activity.maxDivers && (
                    <div className="activity-detail-general-double">
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>Nº máximo buceadores:</TextDestacadoText>
                      </div>
                      <div className="activity-detail-general-double__text">
                        <TextText>{activity.maxDivers}</TextText>
                      </div>
                    </div>
                  )}
                  */}
                </ItemSectionDetail>
              ) : (
                <ItemSectionDetail
                  title={t('components:general.datosgral')}
                  icon={images.dataGeneral}
                  disabled={!havePermissionsActivities()}
                  onEdit={() => this.setState({ renderModal: 'edit-general-data' })}
                >
                  {/*
                      <div className="activity-detail-general-double">
                        <div className="activity-detail-general-double__title">
                          <TextDestacadoText>Nº mínimo buceadores:</TextDestacadoText>
                        </div>
                        <div className="activity-detail-general-double__text">
                          <TextText>{activity.minDivers}</TextText>
                        </div>
                      </div>
                      {activity.maxDivers && (
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Nº máximo buceadores:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{activity.maxDivers}</TextText>
                          </div>
                        </div>
                      )}
                    */}
                  {/*<div className="activity-detail-general-double">
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>Duración:</TextDestacadoText>
                      </div>
                    </div>*/}
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{t('components:general.disc')}:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{DiveDisciplinesKeyValues[activity.discipline]}</TextText>
                    </div>
                  </div>
                  {activity.kind && activity.kind.length > 0 && (
                    <div className="activity-detail-general-double">
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>{t('components:general.typedive')}:</TextDestacadoText>
                      </div>
                      <div className="activity-detail-general-double__text">
                        <TextText>
                          {capitalize(
                            activity.kind
                              .map((kind: string) => {
                                return DiveKindsKeyValues[kind];
                              })
                              .toString()
                              .replace(/,/g, ', ')
                              .toLowerCase()
                          )}
                        </TextText>
                      </div>
                    </div>
                  )}
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{t('components:general.nroinm')}:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.diveCount}</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
              )}
            </div>

            <div className="activity-detail-general-left__section">
              <ItemSectionDetail
                title={t('components:general.reqmin')}
                disabled={!havePermissionsActivities()}
                icon={images.minimunRequirementsSvg}
                onEdit={() => this.setState({ renderModal: 'edit-minimum-requirements' })}>
                <div className="activity-detail-general-double">
                  <div className="activity-detail-general-double__title">
                    <TextDestacadoText>{t('components:general.edadmin')}:</TextDestacadoText>
                  </div>
                  <div className="activity-detail-general-double__text">
                    <TextText>{activity.minimumRequisites ? activity.minimumRequisites.age : ''} años</TextText>
                  </div>
                </div>
                <div className="activity-detail-general-double">
                  <div className="activity-detail-general-double__title">
                    <TextDestacadoText>{t('components:general.titreq')}:</TextDestacadoText>
                  </div>
                  <div className="activity-detail-general-double__text">
                    <TextText>{levelName}</TextText>
                  </div>
                </div>
                {activity.minimumRequisites && activity.minimumRequisites.depth && (
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{t('components:general.min-deep')}:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>
                        Hasta {`${transformToUser('depth', activity.minimumRequisites.depth)} ${getUserUnit('depth')}`}
                      </TextText>
                    </div>
                  </div>
                )}

                {activity.minimumRequisites && activity.minimumRequisites.requisites &&
                  activity.minimumRequisites.requisites.map((item: any, i: number) => (
                    <div key={i} className="activity-detail-general-double">
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>{t(`components:general.${item.requisite}`)}:</TextDestacadoText>
                      </div>
                      <div className="activity-detail-general-double__text">
                        <TextText>{item.data}</TextText>
                      </div>
                    </div>
                  ))}
              </ItemSectionDetail>

            </div>
            {!activity.courseInfo && (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="Embarcaciones"
                  disabled={!havePermissionsActivities()}
                  icon={images.boatsSvg}
                  onEdit={() => this.setState({ renderModal: 'edit-boats' })}
                >
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{t('components:general.needboat')}:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.needBoat ? t('components:general.yes') : t('components:general.no')}</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
              </div>
            )}
            {activity.courseInfo && activity.courseInfo.theoreticPhases && activity.courseInfo.theoreticPhases.length ? (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  disabled={!havePermissionsActivities()}
                  title={t('components:general.fasteo')}
                  icon={images.theorySvg}
                  onEdit={activity.courseInfo ? () => this.setState({ renderModal: 'edit-course-blocks' }) : undefined}
                >

                  {activity.courseInfo.theoreticPhases.map((theoreticPhase: any, key) => (
                    theoreticPhase && theoreticPhase.onlineTheory && theoreticPhase.onlineTheory.sessions > 0 && (
                      <div key={key} className="activity-detail-general-left-section_card">
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>{t('components:general.nroseson')}:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{theoreticPhase.onlineTheory.sessions} {theoreticPhase.onlineTheory.sessions > 1 ? t('components:card-activity.sessions') :  t('components:card-activity.session')}</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>{t('components:general.lang')}:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{getLanguagesText(theoreticPhase.onlineTheory.languages)}</TextText>
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                  {activity.courseInfo.theoreticPhases.map((theoreticPhase: any, key) => (
                    theoreticPhase && theoreticPhase.presentialTheory && theoreticPhase.presentialTheory.sessions > 0 && (
                      <div key={key} className="activity-detail-general-left-section_card">
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>{t('components:general.nrosepre')}:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{theoreticPhase.presentialTheory.sessions} {theoreticPhase.presentialTheory.sessions > 1 ? t('components:card-activity.sessions') : t('components:card-activity.session')}</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>{t('components:general.lang')}:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{getLanguagesText(theoreticPhase.presentialTheory.languages)}</TextText>
                          </div>
                        </div>
                      </div>
                    )
                  ))}

                  {/*
                      <div className="activity-detail-general-double">
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>Centro colaborador:</TextDestacadoText>
                      </div>
                      <div className="activity-detail-general-double__text">
                        <TextText>
                          {activity.courseInfo.theoreticPhase.centre
                            ? centres[activity.courseInfo.theoreticPhase.centre] || ''
                            : 'No'}
                        </TextText>
                      </div>
                    </div>
                    */}


                </ItemSectionDetail>
              </div>
            ) : ''}

            <div className="activity-detail-general-left__section">
              <ItemSectionDetail
                disabled={!havePermissionsActivities()}
                title={ t('components:general.servinc')}
                icon={images.servicesSvg}
                onEdit={() => this.setState({ renderModal: 'edit-services' })}
              >
                <div className="activity-detail-general-services">
                  {activity.services &&
                    activity.services.length > 0 &&
                    activity.services.map((serviceItem: any, index: number) => (
                      <div className="activity-detail-general-services__item" key={index}>
                        <div className="activity-detail-general-services__item__icon">
                          <img src={CENTRE_SERVICES_KEY_ICON[serviceItem.name]} alt="" />
                        </div>
                        <div className="activity-detail-general-services__item__text">
                          <TextText>{CENTRE_SERVICES_KEY_VALUE[serviceItem.name]}</TextText>
                        </div>
                      </div>
                    ))}
                </div>
              </ItemSectionDetail>
            </div>

            <div className="activity-detail-general-left__section">
              <ItemSectionDetail
                disabled={!havePermissionsActivities()}
                title={t('components:general.textdescact')}
                onEdit={() => this.setState({ renderModal: 'edit-observations' })}
              >
                <div className="editorContent">
                  {activity.descriptions ? parser(getUserLanguageComment(activity.descriptions)) : ''}
                </div>
              </ItemSectionDetail>

            </div>

            {/*<div className="activity-detail-general-left__section">
              <ItemSectionDetail
                title="Equipo incluido"
                icon={images.equipmentSvg}
                onEdit={() => this.setState({ renderModal: 'edit-stock' })}
              >
                <div className="activity-detail-general-services">
                  {activity.stock &&
                    activity.stock.length > 0 &&
                    activity.stock.map((stockItem: any, index: number) => (
                      <div className="activity-detail-general-services__item" key={index}>
                        <div className="activity-detail-general-services__item__icon">
                          <img src={images.flagSvg} alt="" />
                        </div>
                        <div className="activity-detail-general-services__item__text">
                          <TextText>{stockItem.name.en}</TextText>
                        </div>
                      </div>
                    ))}
                </div>
              </ItemSectionDetail>
            </div>
            */}

            {/*
            <div className="activity-detail-general-left__section">
              <ItemSectionDetail
                title="Alojamiento y comida"
                icon={images.accomodationAndFoodSvg}
                onEdit={() => this.setState({ renderModal: 'edit-transport-meal' })}
              >
                <div className="activity-detail-general-double">
                  <div className="activity-detail-general-double__title">
                    <TextDestacadoText>Incluído:</TextDestacadoText>
                  </div>
                  <div className="activity-detail-general-double__text">
                    <TextText>{activity.meal ? 'Sí' : 'No'}</TextText>
                  </div>
                </div>
                {activity.meal && (
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Régimen de comidas:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{FOOD_ACCOMMODATION_KEY_VALUES[activity.meal]}</TextText>
                    </div>
                  </div>
                )}
              </ItemSectionDetail>
            </div>

            */}
            {activity.transport && (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  disabled={!havePermissionsActivities()}
                  title={t('components:general.desp')}
                  icon={images.transportSvg}
                  onEdit={() => this.setState({ renderModal: 'edit-transport-meal' })}
                >
                  {/* <div className="activity-detail-general-double">
                  <div className="activity-detail-general-double__title">
                    <TextDestacadoText>Incluye desplazamiento:</TextDestacadoText>
                  </div>
                  <div className="activity-detail-general-double__text">
                    <TextText>20</TextText>
                  </div>
                </div> */}
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{t('components:general.salde')}:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{capitalize(activity.transport.departurePoint)}</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{t('components:general.medtrans')}:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{TRANSPORT_TYPES_KEY_VALUES[activity.transport.transport]}</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
              </div>
            )}

            {/*{activity.comments && (*/}
            {/*  <div className="activity-detail-general-left__section">*/}
            {/*    <ItemSectionDetail*/}
            {/*      title="Observaciones"*/}
            {/*      icon={images.observationsSvg}*/}
            {/*      onEdit={() => this.setState({ renderModal: 'edit-observations' })}*/}
            {/*    >*/}
            {/*      <TextText>{getUserLanguageComment(activity.comments)}</TextText>*/}
            {/*    </ItemSectionDetail>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>

          <div className="activity-detail-general-right">
            <div className="activity-detail-general-right__section">
              <div className="activity-detail-general-right-section__observaciones">
                <div className="title">
                  <TextDestacadoText>{t('components:general.obs')}</TextDestacadoText>
                  {havePermissionsActivities() && (
                    <img src={images.editSvg} alt="" onClick={() => this.setState({ renderModal: 'edit-observations' })} />
                  )}
                </div>
                <div style={{ marginBottom: 10 }} />
                <div className="editorContent">
                  {activity.comments ? parser(getUserLanguageComment(activity.comments)) : ''}
                </div>
              </div>

              {/*  <ItemSectionSmallDetail
                title={`Buceadores (${confirmedDivers.length}${activity.maxDivers ? `/${activity.maxDivers}` : ''})`}
                onEdit={status === 'pending' ? () => this.setState({ renderModal: 'edit-dive' }) : undefined}
              >
                {confirmedDivers.map((booking: any, index: number) => {
                  return (
                    <div className="activity-detail-general-right-section__item" key={index}>
                      <ItemUser
                        name={`${booking.diver.user.name} ${booking.diver.user.surname}`}
                        subtitle={`Buceador ${index + 1}`}
                        info="Nivel de buceo (TODO)"
                        avatar={booking.diver.user.picture}
                      />
                    </div>
                  );
                })}
              </ItemSectionSmallDetail> */}
            </div>
            {activity.courseInfo && activity.courseInfo.practicalPhases && activity.courseInfo.practicalPhases.length ? (
              <div className="activity-detail-general-right__section">
                <ItemSectionDetail
                  disabled={!havePermissionsActivities()}
                  title={t('components:general.fasprac')}
                  icon={images.theorySvg}
                  onEdit={activity.courseInfo ? () => this.setState({ renderModal: 'edit-course-blocks' }) : undefined}
                >
                  {activity.courseInfo.practicalPhases.map((practicalPhase: any, key) => (
                    practicalPhase && practicalPhase.closedWaterDives && (
                      <div key={key} className="activity-detail-general-right-section_card">
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>{t('components:general.divepisc')}:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{practicalPhase.closedWaterDives.sessions}</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>{t('components:general.langp')}:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{practicalPhase.closedWaterDives.languages && getLanguagesText(practicalPhase.closedWaterDives.languages)}</TextText>
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                  {activity.courseInfo.practicalPhases.map((practicalPhase: any, key) => (
                    practicalPhase && practicalPhase.openWaterDives && (
                      <div key={key} className="activity-detail-general-right-section_card">
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>{t('components:general.diveopen')}:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{practicalPhase.openWaterDives.sessions}</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>{t('components:general.langp')}:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{practicalPhase.openWaterDives.languages && getLanguagesText(practicalPhase.openWaterDives.languages)}</TextText>
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                  {/*
                    <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Centro colaborador:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>
                        {activity.courseInfo.practicalPhase.centre
                          ? centres[activity.courseInfo.practicalPhase.centre] || ''
                          : 'No'}
                      </TextText>
                    </div>
                  </div>
                  */}
                </ItemSectionDetail>
              </div>
            ) : ''}

            {activity.sites && activity.sites.length !== 0 && (
              <div className="activity-detail-general-right__section">
                <ItemSectionDetail title={t('components:general.divep')} icon={images.diveSvg} disabled={!havePermissionsActivities()} onEdit={() => this.setState({ renderModal: 'edit-dive-sites' })}>
                  {activity.sites && (
                    activity.sites.map((item: any, index: number) => {
                      return this.renderSites(item, index)
                    }))
                  }
                </ItemSectionDetail>
              </div>
            )
            }


            {activity.additionalInformation && (
              <div className="activity-detail-general-right__section">
                <ItemSectionDetail disabled={!havePermissionsActivities()} title={t('components:general.adinfo')} icon={images.infoSvg} onEdit={() => this.setState({ renderModal: 'edit-additional-info' })}>
                  {activity.additionalInformation ? activity.additionalInformation.es : ''}
                </ItemSectionDetail>
              </div>
            )}

            {activity.exclusiveness && (
              <div className="activity-detail-general-right__section">
                <ItemSectionDetail disabled={!havePermissionsActivities()} title={t('components:general.exc')} icon={images.servicesSvg} onEdit={() => this.setState({ renderModal: 'edit-exclusiveness' })}>
                  {/*<div className="activity-detail-general-double">*/}
                  {/*  <div className="activity-detail-general-double__title">*/}
                  {/*    <TextDestacadoText>Ofertar actividad como exclusiva:</TextDestacadoText>*/}
                  {/*  </div>*/}
                  {/*  <div className="activity-detail-general-double__text">*/}
                  {/*    <TextText>{}</TextText>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="activity-detail-general-double">*/}
                  {/*  <div className="activity-detail-general-double__title">*/}
                  {/*    <TextDestacadoText>Desplazamiento:</TextDestacadoText>*/}
                  {/*  </div>*/}
                  {/*  <div className="activity-detail-general-double__text">*/}
                  {/*    <TextText>{}</TextText>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {
                    activity.exclusiveness && (
                      <div className="activity-detail-general-double">
                        <div className="activity-detail-general-double__title">
                          <TextDestacadoText>{t('components:general.priceCaptl')}:</TextDestacadoText>
                        </div>
                        <div className="activity-detail-general-double__text">
                          <TextText>{activity.exclusiveness.price}</TextText>
                        </div>
                      </div>
                    )
                  }
                  {/*<div className="activity-detail-general-double">*/}
                  {/*  <div className="activity-detail-general-double__title">*/}
                  {/*    <TextDestacadoText>Equipo incluido:</TextDestacadoText>*/}
                  {/*  </div>*/}
                  {/*  <div className="activity-detail-general-double__text">*/}
                  {/*    <TextText>{}</TextText>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="activity-detail-general-double">*/}
                  {/*  <div className="activity-detail-general-double__title">*/}
                  {/*    <TextDestacadoText>Servicio incluido:</TextDestacadoText>*/}
                  {/*  </div>*/}
                  {/*  <div className="activity-detail-general-double__text">*/}
                  {/*    <TextText>{}</TextText>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </ItemSectionDetail>
              </div>
            )}

            {/* {confirmedStaff.length > 0 && (
              <div className="activity-detail-general-right-section">
                <ItemSectionSmallDetail
                  title={`Staff (${confirmedStaff.length})`}
                  onEdit={status === 'pending' ? () => this.setState({ renderModal: 'edit-staff' }) : undefined}
                >
                  {confirmedStaff.map((staff: any, index: number) => {
                    return (
                      <div className="activity-detail-general-right-section__item" key={index}>
                        <ItemUser
                          name={`${staff.staff.user.name} ${staff.staff.user.surname}`}
                          subtitle={`Staff ${index + 1}`}
                          info="Nivel de buceo"
                          avatar={staff.staff.user.picture}
                        />
                      </div>
                    );
                  })}
                </ItemSectionSmallDetail>
              </div>
            )} */}

            <div className="activity-detail-general-right__section">
              <ItemSectionDetail
                title={t('components:general.images')}
                icon={images.boatsSvg}
                disabled={!havePermissionsActivities()}
                onEdit={() => this.setState({ renderModal: 'edit-gallery' })}
              >
                <div className="activity-detail-general-services">
                  {activity.pictures &&
                    activity.pictures.length > 0 &&
                    activity.pictures.map((picture: any, index: number) => (
                      <div className="activity-detail-general-services__item" key={index}>
                        <div className="activity-detail-general-services__item__text">
                          <img src={picture.toString()} alt='' />
                        </div>
                      </div>
                    ))}
                </div>
              </ItemSectionDetail>
            </div>

          </div>
        </div>
        {
          activity && renderModal === 'create-inmersion' && (
            <ModalRegistreDive
              showModal={renderModal === 'create-inmersion'}
              activity={activity}
              selectedDiveIndex={selectedDiveIndex}
              onClose={() => this.setState({ renderModal: '' })}
              onSubmit={(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) =>
                this.onCreateDive(dive, mainPicture, pictures)
              }
            />
          )
        }
        {
          activity && renderModal === 'edit-general-data-formation' && (
            <ModalContainer
              title={t('components:actions.editgraldata')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-general-data-formation'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesGeneralDataTraining
                  centreId={centreId}
                  generalData={{
                    name: activity.name,
                    discipline: activity.discipline,
                    minDivers: activity.minDivers,
                    maxDivers: activity.maxDivers || NaN,
                    category: activity.category || NaN,
                    certifier: activity.course.certifier.id || '',
                    course: activity.course.id || 0,
                    courseType: activity.course.kind || '',
                  }}
                  onNext={(generalDataCourse: ICourseGeneralData) => {
                    this.onEditActivity(generalDataCourse);
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-general-data' && (
            <ModalContainer
              title={t('components:actions.editgraldata')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-general-data'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesGeneralData
                  centreId={centreId}
                  generalData={{
                    name: activity.name,
                    discipline: activity.discipline,
                    kind: activity.kind ? activity.kind : [],
                    category: activity.category || NaN,
                    minDivers: activity.minDivers,
                    maxDivers: activity.maxDivers || NaN,
                    diveCount: activity.diveCount,
                    needBoat: activity.needBoat,
                    collaboration: activity.collaboration,
                  }}
                  onNext={(generalData: IDiveGeneralData) => {
                    this.onEditActivity(generalData);
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-boats' && (
            <ModalContainer
              title={t('components:actions.editboats')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-boats'}>
              <ModalCreateActivitiesStyle>
                <CreateActivitiesBoats
                  boats={{
                    needBoat: activity.needBoat,
                  }}
                  onNext={(boats: IBoatsData) => {
                    this.onEditActivity(boats);
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-minimum-requirements' && (
            <ModalContainer
              title={t('components:actions.editreqmin')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-minimum-requirements'}>
              <ModalCreateActivitiesStyle>
                <CreateActivitiesMinimunRequirements
                  centreId={parseInt(centreId)}
                  minimumRequisites={{
                    age: activity.minimumRequisites.age.toString(),
                    level: activity.minimumRequisites.level.toString(),
                    depth: activity.minimumRequisites.depth,
                    requisites: activity.minimumRequisites.requisites || [],
                  }}
                  course={activity.course ? activity.course.id : undefined}
                  onNext={(minimumRequisites: IMinimumRequirements) => {
                    const activityEdit = copyOf(minimumRequisites);
                    activityEdit.level = parseInt(activityEdit.level, undefined);
                    this.onEditActivity({ minimumRequisites: activityEdit });
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-transport-meal' && (
            <ModalContainer
              title={t('components:actions.edittransal')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-transport-meal'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesOtherServices
                  centreId={centreId}
                  otherServices={{
                    foodAccommodation: activity.meal,
                    departurePoint: activity.transport ? activity.transport.departurePoint : undefined,
                    transport: activity.transport ? activity.transport.transport : undefined,
                  }}
                  onNext={(otherServices: IOtherServices) => {
                    this.onEditActivity({
                      meal: otherServices.foodAccommodation ? otherServices.foodAccommodation : null,
                      transport:
                        otherServices.departurePoint && otherServices.transport
                          ? {
                            departurePoint: otherServices.departurePoint,
                            transport: otherServices.transport,
                          }
                          : null,
                    });
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-observations' && (
            <ModalContainer
              title={t('components:actions.editobs')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-observations'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesDetails
                  languagesComments={activity.comments}
                  languagesDescriptions={activity.descriptions}
                  onNext={(languagesDescriptions: IMultilang, languagesComments: IMultilang) => {
                    this.onEditActivity({
                      descriptions: languagesDescriptions,
                      comments: languagesComments
                    });
                  }}
                  centreId={centreId}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-services' && (
            <ModalContainer
              title={t('components:actions.editserv')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-services'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesIncludedServices
                  services={activity.services ? activity.services.map((service: any) => ({ id: service.id })) : []}
                  onNext={(services: IId[]) => {
                    this.onEditActivity({
                      services,
                    });
                  }}
                  centreId={centreId}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-stock' && (
            <ModalContainer
              title={t('components:actions.editstock')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-stock'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesIncludedEquipment
                  stock={activity.stock ? activity.stock.map((stockItem: any) => ({ id: stockItem.id })) : undefined}
                  onNext={(stock: IId[]) => {
                    this.onEditActivity({
                      stock,
                    });
                  }}
                  centreId={centreId}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-course-blocks' && (
            <ModalContainer
              title={t('components:actions.editcourse')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-course-blocks'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesCourseInfo
                  courseTemplateInfo={activity.courseInfo}
                  onNext={(courseInfo: ICourseTemplateInfo) => {
                    this.onEditActivity({
                      courseInfo,
                    });
                  }}
                  centreId={centreId}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }

        {
          activity && renderModal === 'edit-dive-sites' && (
            <ModalContainer
              title={t('components:actions.editdive')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-dive-sites'}
            >
              <ModalCreateActivitiesStyle>
                <PlanningDiveSite
                  diveSites={activity.sites ? activity.sites.map((site: any) => site.id) : []}
                  centreId={centreId}
                  edit={true}
                  onNext={(state: any, sites: IId[]) => {
                    this.onEditActivity({
                      sites,
                    });
                    /*this.handleChangeState('site', state).then(() => {
                    this.handleNext(['sites'], [sites]);
                    });*/
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }

        {
          activity &&
          renderModal === 'edit-staff' &&
          {
            /* <ModalContainer
            title={'Editar Staff'}
            modalClose={() => this.setState({ renderModal: '' })}
            active={renderModal === 'edit-staff'}
          ></ModalContainer> */
          }
        }

        {
          activity && renderModal === 'edit-gallery' && (
            <ModalContainer
              title={t('components:actions.editgal')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-gallery'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesGallery
                  pictures={activity.pictures}
                  onlyDialog={true}
                  onNext={(pictures: AttachedFile[], picturesDeleted: string[]) => {
                    this.onEditGallery(pictures, picturesDeleted);
                  }}
                  onPrev={() => { }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-additional-info' && (
            <ModalContainer
              title={t('components:actions.editinfogral')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-additional-info'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesAdditionalInfo
                  onNext={(additionalInformation: string) => this.onEditActivity({ additionalInformation })}
                  additionalInformation={activity.additionalInformation ? activity.additionalInformation.es : ''}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-exclusiveness' && (
            <ModalContainer
              title={t('components:actions.editinfoad')}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-exclusiveness'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesExclusiveness
                  isEditing={true}
                  onNext={(
                    exclusiveStock: IId[],
                    exclusiveServices: IId[],
                    exclusiveness: {
                      included: boolean;
                      price: number;
                    }
                  ) => {
                    this.onEditActivity({ exclusiveStock, exclusiveServices, exclusiveness });
                  }}
                  onPrev={() => { }}
                  centreId={centreId}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          renderModal === 'modal-plan-activity' && (
            <ModalPlanningActivity
              onCreateActivity={(activitiesType: string) => { }}
              onSuccess={() => {
                this.setState({ renderModal: '' })
                // this.getActivityTemplates();
              }}
              centreId={centreId}
              onCancel={() => this.setState({ renderModal: '' })}
              showModal={renderModal === 'modal-plan-activity'}
              activityId={activity.id.toString()}
            />
          )
        }

      </ActivityTemplatesDetailGeneralStyle >
    );
  }

}

export default withTranslation(['buttons','components'])(ActivityTemplatesDetailGeneral);
