import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CardActivityTemplateStyle = styled.div`
  height: 300px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  width: 100%;

  &.with-card {
    display: flex;
    justify-content: space-between;
    height: 265px;
  }

  .card-activity-back {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .card-activity-content {
    color: white;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 45px 24px;
    display: flex;
    flex-direction: column;
  }
  .card-activity-content-title {
    &__text {
      margin-bottom: 10% span {
        text-transform: uppercase;
        margin-bottom: 0.5em;
        display: block;
      }
    }
    &__subtext {
      p {
        size: 16px;
      }
    }
    &__vote {
      display: flex;
      margin-top: 1em;
      .stars {
        margin-right: 2em;
      }
    }
    &__edit {
      /* cursor: pointer; */
    }
  }
  .card-activity-more-dates {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${colors['NE-002/0']};
    margin: 5px 0 0 25px;
  }

  .card-activity-content-info {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    display: flex;
    &__item {
      display: flex;
      margin-right: 24px;
      &__icon {
        margin-right: 8px;
      }
      &__text {
      }
    }
  }
  .card {
    min-width: 300px;
    width: 80%;
  }
  .card-activity-white {
    padding: 46px 24px 24px;
    margin-top: 20px;
    color: initial;
    border-radius: 5px;
    z-index: 2;
    background: #007c8f;
    min-width: 300px;
    width: 80%;
    opacity: 0.7;
    &__sub-price {
      color: white;
      margin-bottom: 4px;
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
    }
    &__price {
      p {
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 22px;
        font-weight: 500;
      }
    }
    &__sub-policy {
      p {
        color: white;
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
      }
    }
    &__policy {
      p {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
      }
    }
  }

  .date-tooltip-container {
    & > div {
      justify-content: flex-start;
      margin-top: 15px;
    }
    & .item-team-count {
      padding: 4px;
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: ${colors['NE-001/100']};
      }
    }

    & .item-team-pop.active {
      p {
        color: ${colors['NE-001/100']};
      }
      &::before {
        background-color: ${colors['NE-002/0']};
      }
      background-color: ${colors['NE-002/0']};
    }
  }
  .item-section-detail-edit {
    color: white;
    cursor: pointer;
    float: right;
  }
`;
