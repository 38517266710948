import styled from 'styled-components';

export const DiverDiveDetailStyle = styled.div`
  .diver-dive-detail-title {
    margin-bottom: 24px;
  }
  .diver-dive-detail-date {
    display: flex;
    margin-bottom: 80px;
    &__item  {
      display: flex;
      align-items: center;
      margin-right: 25px;
      &__icon {
        margin-right: 8px;
        width: 24px;
        height: 24px;
      }
    }
  }
  .diver-dive-detail-map  {
    margin-bottom: 80px;
  }

  .diver-dive-detail-container {
    display: flex;
  }

  .diver-dive-detail-container-left {
    width: 100%;
  }

  .diver-dive-detail-container-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 18px;
    &__item {
      height: 110px;
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }
    }
  }

  .diver-dive-detail-container-right {
    min-width: 320px;
    flex: auto;
    margin-left: 80px;
  }
  .diver-dive-detail-container-right-users {
    &__item {
      margin-bottom: 24px;
    }
  }
`;
