import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import MainButton from '../main-button';
import { PaginationContainer } from './pagination-style';

interface PaginationState {
  page: number;
  previous: string;
  next: string;
}

interface PaginationProps extends WithTranslation {
  page: number;
  callback: (page: number) => void;
  limit: number;
  t: any;
}

class Pagination extends Component<PaginationProps, PaginationState> {
  state: PaginationState = {
    page: 1,
    previous: '',
    next: '',
  };

  componentDidMount() {
    this.setState({
      previous: this.props.page === 1 ? 'limit' : '',
      page: this.props.page,
    });
  }

  previous = (first: boolean = false) => {
    if (this.state.page !== 1) {
      const auxPage: number = first ? 2 : this.state.page;
      let previous;
      previous = auxPage === 2 ? 'limit' : '';
      this.setState({ previous, page: auxPage - 1, next: '' });
      this.props.callback(auxPage - 1);
    }
  };

  next = (last: boolean = false) => {
    if (this.state.page !== Number(this.props.limit)) {
      const auxPage = last ? this.props.limit - 1 : this.state.page;
      let next;
      next = auxPage === Number(this.props.limit) ? 'limit' : '';
      this.setState({ next, page: auxPage + 1, previous: '' });
      this.props.callback(auxPage + 1);
    }
  };

  static getDerivedStateFromProps(props:any, state:any) {
    if(props.page !== state.page) {
        return { ...state, page: props.page };
    } else return state
  }

  render() {
    // if (this.props.page !== this.state.page) {
    //   this.setState({ ...this.state, page: this.props.page });
    // }
    const { limit, t } = this.props;
    const { previous, page } = this.state;

    if (limit <= 1) {
      return null;
    }

    return (
      <PaginationContainer>
        <div className="pagination-container">
          {page !== 1 && (
            <div className="pagination-left">
              <MainButton type="neutral-small" onClick={() => this.previous()} className={previous} text="Anterior" />
            </div>
          )}

          <div className="pagination-main">
            {page > 1 && (
              <div className="pagination-main__item">
                <MainButton
                  type="neutral-small"
                  onClick={() => {
                    this.setState({
                      page: 1,
                      next: '2',
                      previous: '',
                    });
                    this.props.callback(1);
                  }}
                  text="1"
                />
              </div>
            )}
            {page > 3 && (
              <div className="pagination-main__item">
                <MainButton className="disabled" type="neutral-small" onClick={() => this.previous(true)} text="..." />
              </div>
            )}
            {page > 2 && (
              <div className="pagination-main__item">
                <MainButton type="neutral-small" onClick={() => this.previous()} text={(page - 1).toString()} />
              </div>
            )}

            <div className="pagination-main__item">
              <MainButton type="neutral-small" className="actual" text={page.toString()} />
            </div>
            {page + 1 < limit && (
              <div className="pagination-main__item">
                <MainButton type="neutral-small" onClick={() => this.next()} text={(page + 1).toString()} />
              </div>
            )}
            {page + 2 < limit && (
              <div className="pagination-main__item">
                <MainButton className="disabled" type="neutral-small" onClick={() => this.previous(true)} text="..." />
              </div>
            )}
            {page < limit && (
              <div className="pagination-main__item">
                <MainButton type="neutral-small" onClick={() => this.next(true)} text={limit.toString()} />
              </div>
            )}
          </div>

          {page !== limit && (
            <div className="pagination-right">
              <MainButton
                type="neutral-small"
                onClick={() => this.next()}
                className={Number(limit) === Number(page) ? 'limit' : ''}
                text={t('components:actions.next')}
              />
            </div>
          )}
        </div>
      </PaginationContainer>
    );
  }
}

export default withTranslation(['components']) (Pagination);
