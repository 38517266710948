import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const DiveSiteDetailMapStyle = styled.div`
  width: 100%;
  height: 400px;

  position: relative;

  .dive-site-map {
    width: 100%;
    height: 100%;
  }

  .dive-site-card {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    padding: 40px;
    z-index: 100;
  }
  .dive-site-card-container  {
    background: rgba(0, 0, 25, 0.75);
    border-radius: 5px;
    width: 320px;
    height: 100%;
    padding: 45px 24px 33px;
    color: white;
    overflow-y: hidden;
  }

  .dive-site-card-title {
    margin-bottom: 24px;
    &__subtitle {
      margin-bottom: 4px;
      color: ${colors['NE-002/100']};
      text-transform: uppercase;
    }
    &__title  {
    }
  }

  .dive-site-card-row {
    display: flex;
    margin-bottom: 24px;
    &__item {
      width: 50%;
      padding-right: 12px;
      &:last-child {
        padding-right: 0px;
      }
      &__subtitle {
        color: ${colors['NE-002/100']};
        margin-bottom: 5px;
        text-transform: uppercase;

        &--description {
          width: 50%;
        }
      }
    }
  }
  .dive-site-card-item {
    width: 100%;
    padding-right: 12px;
    &:last-child {
      padding-right: 0px;
    }
    &__subtitle {
      color: ${colors['NE-002/100']};
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    &__text {
      overflow-wrap: break-word;
    }
  }
`;
