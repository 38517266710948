import React from 'react';
import { IFormGenerator } from '../../models/form-generator';
import InputGenerator from '../input-generator/input-generator';
import { updateObject, checkValidity } from '../../utils/utility';

interface IFormGeneratorProps {
  form: IFormGenerator;
  setForm: Function;
  setFormValid: Function;
  dynamic?: { [key: string]: any };
}

export const inputChangedHandler = (value: any, inputIdentifier: string, form: any) => {
  const updatedFormElement = updateObject(form[inputIdentifier], {
    value,
    validation: checkValidity(value, form[inputIdentifier].validationRules),
    touched: true,
  });
  const updatedForm = updateObject(form, {
    [inputIdentifier]: updatedFormElement,
  });
  return updatedForm;
};

export const validateForm = (form: IFormGenerator) => {
  let formIsValid = true;
  for (const inputIdentifier in form) {
    formIsValid = form[inputIdentifier].validation.valid && formIsValid;
  }
  return formIsValid;
};

const FormGenerator: React.FC<IFormGeneratorProps> = props => {
  const { form, setForm, setFormValid, dynamic } = props;
  const formElements = Object.entries(form)
    .reduce((ac: any[], cv) => ac.concat([{ id: cv[0], input: cv[1] }]), [])
    .map(el => (
      <div key={el.id} className={el.input.containerClassNames ? el.input.containerClassNames.join(' ') : ''}>
        <InputGenerator
          dynamic={dynamic}
          elementType={el.input.elementType}
          elementConfig={el.input.elementConfig}
          touched={el.input.touched}
          value={el.input.value}
          error={el.input.validation.error}
          disabled={el.input.elementConfig.isDisabled && el.input.elementConfig.isDisabled(form, dynamic)}
          onChange={(value: any) => {
            let updatedForm = inputChangedHandler(value, el.id, form);
            if (el.input.elementConfig.onChangeFunctionKey && dynamic) {
              updatedForm = dynamic[el.input.elementConfig.onChangeFunctionKey]
                ? dynamic[el.input.elementConfig.onChangeFunctionKey](updatedForm)
                : updatedForm;
            }
            setForm(updatedForm);
            setFormValid(validateForm(updatedForm));
          }}
        />
      </div>
    ));

  return <>{formElements}</>;
};

export default FormGenerator;
