import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TextText from '../../../../../components/text/text-text/text-text';
import styles from './booking-form-divers.module.scss';
import Button from '../../../../../components/buttons/main-button/button';
import { images } from '../../../../../assets/images';
import { FormGrid, FormGridItem, FormGridRow } from '../../../../main-layout-style';
import SelectBox from '../../../../../components/select-box';
import InputBox from '../../../../../components/input-box';
import ItemSectionDetail from '../../../../../components/item-sections-detail/item-sections-detail';
import { getOneBookings, uploadBookings } from '../../../../../services/bookings';
import { RouteComponentProps, withRouter } from 'react-router';
import { normalizePhoneNumber } from '../../../../../utils/normalizeInput';
import IStoreState from '../../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { getCentreDivers } from '../../../../../services/centre';
import ModalContainer from '../../../../../components/modals/modal-container';
import { ModalCreateActivitiesStyle } from '../../../../dive-offers/activities/modal-create-activities/modal-create-activities-style';
import services from '../../../../../services/services';
import {
  setAccountDivers,
  setAccountDiversGhost,
  setBookingLines,
  setIsDiverSelected,
} from '../../../../../redux/actions';

interface IBookingFormDiversProps {
  divers: []
}

const BookingFormDivers = (props: any): JSX.Element => {
  const { t } = useTranslation(['modalMessages', 'layouts']);

  const {match: {params: { id } }} = props;
  const [searchDiverInfo, setSearchDiverInfo] = useState('');
  const [anotherDiversList, setAnotherDiversList] = useState<any>([]);
  const [otherDiversList, setOtherDiversList] = useState<any>([]);
  const [diversSelected, setDiversSelected] = useState<any>([]);
  const [accountPhone, setAccountPhone] = useState('');
  const [centreDivers, setCentreDivers] = useState([]);
  const [centreDiversResults, setCentreDiversResults] = useState<any>([]);
  const [openCentreDiversResults, setOpenCentreDiversResults] = useState(false);
  const [renderModal, setRenderModal] = useState('');
  const node = useRef<any>();
  const diverType = [
    {
      value: t('layouts:adult'),
      label: 'adults'
    },
    {
      value: t('layouts:child'),
      label: 'children'
    }
  ];
  const titulacionType = [
    {
      value: t('layouts:nontit'),
      label: '0'
    },
    {
      value: t('layouts:titname'),
      label: '1'
    }
  ]

  useEffect(() => {
    getCentreDiverList()
  }, []);

  // Close div's results when click outside of "search diver input"
  useEffect(() => {
    document.addEventListener("mousedown", handleClickSearchDiverInput);
    return () => {
      document.removeEventListener("mousedown", handleClickSearchDiverInput);
    };
  }, [node]);

  const getCentreDiverList = async () => {
    const params = {
      filter: {
        relations: ['user']
      }
    }
    const res = (await getCentreDivers(id, params)).data;
    setCentreDivers(res.data)
  }

  const handleChangeSearchDiverInfo = (value: string) => {
    const results = centreDivers.filter((item: any, index) => {
      const name = item.user.name.toLowerCase();
      const surname = item.user.surname.toLowerCase();
      const diverId = item.diverId.toLowerCase();
      return diverId.includes(value.toLowerCase()) || name.includes(value.toLowerCase()) || surname.includes(value.toLowerCase())
    });
    const different = checkDivers(results);
    setCentreDiversResults(different);
    setOpenCentreDiversResults(true)
  };

  const checkDivers = (results: any[]) => {
    const different = results.filter((item1: any) => !diversSelected.some((item: any) => item1.id === item.id));
    return different;
  };

  const selectDiver = (value: any) => {
    const buildName = `(${value.diverId}) - ${value.user.name} ${value.user.surname}`;
    const diver = [...diversSelected, value];
    setSearchDiverInfo(buildName);
    setOpenCentreDiversResults(false);
    setDiversSelected(diver);
    services.setValue(setIsDiverSelected(true));
    services.setValue(setAccountDivers(diver));
    setTimeout(() => {
      setSearchDiverInfo('');
    }, 500);
  }

  const handleClickSearchDiverInput = (e: any) => {
    if (node.current) {
      if (node.current.contains(e.target)) {
        return;
      }
      // outside click
      setOpenCentreDiversResults(false)
    }
  };

  const searchDiver = () => {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <TextText color={'#323438'}>{t('modalMessages:searchbyid')}</TextText>
          <div style={{ marginTop: '1.5rem' }}>
            <InputBox
              placeholder={t('modalMessages:diveid')}
              className=""
              type="text"
              value={searchDiverInfo}
              labelText={t('modalMessages:diveid')}
              onChange={(value) => handleChangeSearchDiverInfo(value)}
            />
            {
              openCentreDiversResults ?
                <div className={styles.centreDiversResults} ref={node}>
                  {
                    centreDiversResults.map((item: any, index: number) => {
                      return (
                        <div key={index} className={styles.diverResult} onClick={() => selectDiver(item)}>
                          ({item.diverId}) - {item.user.name} {item.user.surname}
                        </div>
                      )
                    })
                  }
                </div>: ''
            }
          </div>
        </div>
      </div>
    )
  };

  const addAnotherDiver = () => {
    const newDiver = {
      type: 'adults',
      name: '',
      surname: '',
      email: '',
      titulacion: '',
      phone: '',
      dni: '',
      titulacion2: ''
    }
    setAnotherDiversList([...anotherDiversList, newDiver])
    // const list = [...anotherDiversList, newDiver]
    // handleChangeTypeOfDiver(list)
  };

  const saveOtherDivers = () => {
    setRenderModal('')
    setOtherDiversList(anotherDiversList)
    services.setValue(setAccountDiversGhost(anotherDiversList))
  }

  const deleteDiver = (item: any, pos: number) => {
    const array = [...anotherDiversList]
    const result = array.filter((value, pos) => value !== item)
    setAnotherDiversList(result);
    // handleChangeTypeOfDiver(result)
  };

  const handleChange = (value: any, label: any, pos: number, type?: string) => {
    let name = label;
    let isTypeSelected = false;
    if (type) {
      isTypeSelected = true;
      name = type
    } else {
      isTypeSelected = false;
    }
    const nextState = anotherDiversList.map((el: any, index: number) => index === pos - 1 ? { ...el, [name]: value } : el);
    setAnotherDiversList(nextState);
    // if (isTypeSelected) handleChangeTypeOfDiver(nextState)
  };

  const removeDiverOfList = (pos: number, isDiversSelected: boolean) => {
    if (isDiversSelected) {
      if (diversSelected.length === 1) {
        setDiversSelected([]);
        services.setValue(setIsDiverSelected(false));
        services.setValue(setAccountDivers([]));
      }
      else {
        const res = diversSelected.splice(pos - 1, 1);
        setDiversSelected(res)
        services.setValue(setAccountDivers(res));
      }
    } else {
      if (anotherDiversList.length === 1){
        setAnotherDiversList([])
        setOtherDiversList([])
        services.setValue(setAccountDiversGhost([]))
      } else {
        const res = anotherDiversList.splice(pos - 1, 1);
        setAnotherDiversList(res)
        setOtherDiversList(res)
        services.setValue(setAccountDiversGhost(res))
      }
    }
  }

  const seeDiverOfList = (pos: number) => {
    console.log(diversSelected[pos]);
  }

  const renderAnotherDiver = (item: any, pos: number) => {
    return (
      <div className={styles.dive_container} key={pos}>
        <div className={styles.header}>
          <div className={styles.name_container}>
            <div className={styles.name_container_actions}>
              <h4>Buceador {pos}</h4>
              <Button type={'secondary'} onClick={() => deleteDiver(item, pos)} text={t('modalMessages:deldive')} />
            </div>
          </div>
          <div className={styles.diver_form}>
            <FormGrid>
              <FormGridRow>
                <FormGridItem type="col-2">
                  <SelectBox
                    className=""
                    labelText={t('modalMessages:diver')}
                    optionsText={diverType}
                    optionKey={'label'}
                    optionValue={'value'}
                    icon={images.arrowDown2BlueSvg}
                    onChange={(value, label) => handleChange(value, label, pos, 'type')}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-2">
                  <InputBox
                    placeholder={t('modalMessages:name')}
                    className=""
                    type="text"
                    value={item.name}
                    labelText={t('modalMessages:name')}
                    onChange={value => handleChange(value, 'name', pos)}
                  />
                </FormGridItem>
                <FormGridItem type="col-2">
                  <InputBox
                    placeholder={t('modalMessages:lastname')}
                    className=""
                    type="text"
                    value={item.surname}
                    labelText={t('modalMessages:lastname')}
                    onChange={value => handleChange(value, 'surname', pos)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-2">
                  <InputBox
                    placeholder={t('modalMessages:email')}
                    className=""
                    type="text"
                    value={item.email}
                    labelText={t('modalMessages:email')}
                    onChange={value => handleChange(value, 'email', pos)}
                  />
                </FormGridItem>
                <FormGridItem type="col-2">
                  <InputBox
                    placeholder="Ex: 57489390 -S"
                    className=""
                    type="text"
                    value={item.dni}
                    labelText={t('modalMessages:id')}
                    onChange={value => handleChange(value, 'dni', pos)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-2">
                  <SelectBox
                    className=""
                    labelText={t('modalMessages:divename')}
                    optionsText={titulacionType}
                    optionKey={'label'}
                    optionValue={'value'}
                    icon={images.arrowDown2BlueSvg}
                    onChange={(value, label) => handleChange(value, label, pos)}
                  />
                </FormGridItem>
                <FormGridItem type="col-2">
                  <InputBox
                    placeholder={t('layouts:titname')}
                    className=""
                    type="text"
                    value={item.titulacion2}
                    labelText={t('layouts:titname')}
                    onChange={value => handleChange(value, 'titulacion', pos)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-2">
                  <InputBox
                    placeholder="+34 000 00 00"
                    className=""
                    type="text"
                    value={item.phone}
                    labelText={t('layouts:tel')}
                    onChange={(e) => formatPhone(e, pos)}
                  // onChange={value => handleChange(value, 'phone', pos)}
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>
          </div>
        </div>
      </div>
    )
  }

  const renderDiversListSelected = (diver: any, pos: number) => {
    return (
      <div className="booking-detail-general-double" key={pos}>
        <div className="booking-detail-general-double__title">
          <TextText>{diver.diverId}</TextText>
        </div>
        <div className="booking-detail-general-double__text">
          <TextText>{diver.user.name} {diver.user.surname}</TextText>
        </div>
        <div className="booking-detail-general-double__text">
          <TextText>{ }</TextText>
        </div>
        <div className="booking-detail-general-double__text">
          <img style={{ width: '25px', marginRight: '10px', cursor: 'pointer' }} src={images.eyeSvg} onClick={() => seeDiverOfList(pos)} />
          <img style={{ width: '25px', cursor: 'pointer' }} src={images.deleteSvg} onClick={() => removeDiverOfList(pos, true)} />
        </div>
      </div>
    )
  }
  const renderDiversList = (diver: any, pos: number) => {
    return (
      <div className="booking-detail-general-double" key={pos}>
        <div className="booking-detail-general-double__title">
          <TextText>000000000</TextText>
        </div>
        <div className="booking-detail-general-double__text">
          <TextText>{diver.name} {diver.surname}</TextText>
        </div>
        <div className="booking-detail-general-double__text">
          <TextText>{ }</TextText>
        </div>
        <div className="booking-detail-general-double__text">
          <img style={{ width: '25px', marginRight: '10px', cursor: 'pointer' }} src={images.eyeSvg} onClick={() => seeDiverOfList(pos)} />
          <img style={{ width: '25px', cursor: 'pointer' }} src={images.deleteSvg} onClick={() => removeDiverOfList(pos, false)} />
        </div>
      </div>
    )
  }

  const editDivers = async () => {
    const {
      match: {
        params: { bookingId },
      },
    } = props;
    const params = {
      filter: {
        relations: ['divers', 'divers.user', 'payment', 'payment.paymentType'],
      },
    };
    const booking = (await getOneBookings(bookingId, params)).data;
    const list = diversSelected.concat(anotherDiversList)
    booking.divers = list;
    await uploadBookings(bookingId, booking);
    // this.sendNotification('¡Confirmado!', 'La reserva se ha confirmado con éxito', 'green');
  };

  const formatPhone = (value: any, pos: number) => {
    // const value = e.target.value
    setAccountPhone(prevCompanyPhone => normalizePhoneNumber(value, prevCompanyPhone));
    handleChange(accountPhone, 'phone', pos)
  };

  return (
    <div style={{marginTop: '2em'}}>
      <div className={styles.header}>
        <TextText color={'#00A1A5'}>{t('layouts:bookings.options.addivers')}</TextText>
      </div>
      {searchDiver()}
      <div className={styles.addNewDiver}>
        <Button
          type={'primary'}
          text={t('layouts:bookings.options.addiver')}
          disabled={diversSelected.length === 0}
          leftIcon={images.plusWhiteSvg}
          onClick={() => {setRenderModal('add-other-divers'); addAnotherDiver()}}
        />
      </div>
      <div className={styles.diver_list}>
        <ItemSectionDetail
          title={t('layouts:bookings.options.nrodivers')}
          icon={images.diversSvg}
        >
          <div className={styles.general_double} style={{ marginBottom: '15px' }}>
            <div className={styles.text}>
              <TextText color={'#A6ACB7'}>ID Diveasapp</TextText>
            </div>
            <div className={styles.text}>
              <TextText color={'#A6ACB7'}>{t('layouts:fullname')}</TextText>
            </div>
            <div className={styles.text}>
              <TextText color={'#A6ACB7'}>{t('layouts:title')}</TextText>
            </div>
            <div className={styles.text}>
              <TextText color={'#A6ACB7'}>{}</TextText>
            </div>
          </div>
        </ItemSectionDetail>
        {
          diversSelected.map((item: any, index: number) => {
            return renderDiversListSelected(item, index)
          })
        }
        {
          otherDiversList.map((item: any, index: number) => {
            return renderDiversList(item, index)
          })
        }
      </div>

      {renderModal === 'add-other-divers' && (
        <ModalContainer
          title={t('layout:bookings.options.addivers')}
          modalClose={() => setRenderModal('')}
          active={renderModal === 'add-other-divers'}
        >
          <ModalCreateActivitiesStyle>
            <>
              <Button
                type={'primary'}
                text={t('layout:bookings.options.addiver')}
                leftIcon={images.plusWhiteSvg}
                onClick={() => addAnotherDiver()}
              />
              {
                anotherDiversList.map((item: any, i: number) => {
                  return (
                    renderAnotherDiver(item, i + 1)
                  )
                })
              }
              <div className="footer">
                <Button
                  type={'primary'}
                  text={'Guardar'}
                  onClick={() => saveOtherDivers()}
                />
              </div>
            </>
          </ModalCreateActivitiesStyle>
        </ModalContainer>
      )}
    </div>
  );
};

function mapStateToProps(state: IStoreState) {
  return {
    // bookingLines: state.bookingLines.value,
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(BookingFormDivers));
