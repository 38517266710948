import React, { Component } from 'react';
import { DetailMenuContainer } from './detail-menu-style';
import { images } from '../../assets/images/index';
import MainButton from '../main-button';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import ModalLogOut from '../modals/modal-logout/modal-logout';
import MainButtonDrop from '../main-button-drop';
import MainButtonDropProfile from '../main-button-drop-profile';
import LabelText from '../text/label-text/label';
import services from '../../services/services';
import Scrollbars from 'react-custom-scrollbars';
import InfoText from '../text/info-text/info-text';
import { withTranslation, WithTranslation } from 'react-i18next';
import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { setValidCentre, setStaffPermissions, setCentreCurrency, setCentreStripeUserId } from '../../redux/actions';
import ModalInfo from '../modals/modal-info/modal-info';
import SelectBox from '../../components/select-box';
import Can from "../../roles/can-component";
import { countUnreadNotifications } from '../../services/notifications';
import { getDivers } from '../../services/diver';
import { APP_WEB_URL } from '../../env';

interface DetailMenuProps extends RouteComponentProps, WithTranslation {
  centreId: string | null;
  onLogout: () => void;
  agent?: any;
  staffPermissions?: any;
  profilePicture?: string;
}

interface DetailMenuState {
  modalLogOut: boolean;
  sectionOpen: string;
  activeSection: string;
  keyNavItem?: string;
  unReadNotifications: number;
  centre: {
    id: string;
    name: string;
    type: string;
  };
  renderModal: string;
  centres: any[];
}
class DetailMenu extends Component<DetailMenuProps, DetailMenuState> {
  state: DetailMenuState = {
    modalLogOut: false,
    sectionOpen: '',
    activeSection: this.props.history.location.pathname,
    keyNavItem: this.props.history.location.pathname,
    unReadNotifications: 0,
    centre: {
      id: '',
      name: '',
      type: '',
    },
    renderModal: '',
    centres: [],
  };

  componentDidMount() {


    this.getCentre();
    this.getCentres();

  }

  async countUnreadNotifications(centreId: number) {
    const user = localStorage.getItem("userId");
    if (user) {
      const res: any = await countUnreadNotifications(parseInt(user), centreId);
      if (res.data.length !== 0 && parseInt(res.data[0].count) !== 0) {
        this.state.unReadNotifications = parseInt(res.data[0].count);
        this.setState({ ...this.state });
      }
    }

  }

  getCentre() {
    const { centreId } = this.props;
    if (!centreId) {
      return;
    }

    const params = {
      filter: {
        relations: ['staff_centres', 'staff_centres.staff', 'staff.user'],
      },
    };

    const endpoint = `centres/${centreId}`;
    const isStaff = !!localStorage.getItem('isStaff');
    const userId = parseInt(localStorage.getItem('userId') || '0', undefined);
    services.get({
      endpoint,
      params,
      loader: true,
      then: ({ data: res }: any) => {
        const { name, type, validated, currency, stripeAccountId } = res;
        const centre = {
          name,
          type,
          id: centreId,
        };
        const isValid = !!validated; // res.type !== 'centre' || !!res.bankAccount;
        services.setValue(setCentreStripeUserId(stripeAccountId));
        services.setValue(setValidCentre(isValid));
        services.setValue(setCentreCurrency(currency));
        if (!isValid) this.setState({ renderModal: 'modal-advice-validated' });
        if (isStaff) {
          const centre = res.staff_centres.filter((staffCentre: any) => staffCentre.staff ? staffCentre.staff.user.id === userId : false)[0];
          if (centre) {
            const { activities, deals, divers, incidences, instructor, manageStaff, messaging, sites } = centre;
            const permisions = {
              activities,
              deals,
              divers,
              incidences,
              instructor,
              manageStaff,
              messaging,
              sites,
            };
            services.setValue(setStaffPermissions(permisions));
          } else {
            services.setValue(setStaffPermissions(null));
          }
        } else {
          services.setValue(setStaffPermissions(null));
        }
        this.countUnreadNotifications(Number(centre.id)).then(() => {
          this.setState({ centre });
        })

      },
      catch: (err: any) => {
        console.log(err);
      },
    });

  }

  getCentres() {
    const endpoint = 'centres';
    const params = {
      filter: {
        where: { user: localStorage.userId },
        orderBy: [{ key: 'createdAt', direction: 'ASC' }],
        relations: ['user'],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        const centres: any[] = res.data.map((item: any) => {
          const { id, name, rating, address, town, user } = item;
          return {
            id,
            name,
            rating,
            location: `${address}, ${town}`,
            managers: user.name ? `${user.name} ${user.surname}` : '',
          };
        }).map((centre: any) => { return { "value": centre.id.toString(), "label": centre.name } })

        centres.push(...await this.getCentresStaff(centres));
        this.setState({ centres });
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }


  async getCentresStaff(centres: any[]) {

    const params = {
      filter: {
        where: { user: localStorage.userId },
        relations: ['staff_centres', 'staff_centres.centre', 'centre.user'],
      },
    };

    let staffCentres = [];
    const centresId = centres.map(centre => centre.value);
    const diver = (await getDivers(params)).data.data[0];
    if (diver && diver.staff_centres && diver.staff_centres.length > 0) {
      for (const staffCentre of diver.staff_centres) {
        if (staffCentre.centre && staffCentre.centre.id && !centresId.includes(staffCentre.centre.id.toString())) staffCentres.push(staffCentre.centre)
      }
      return staffCentres.map((centre: any) => { return { "value": centre.id.toString(), "label": centre.name } });
    }
    return [];

  }

  static getDerivedStateFromProps(nextProps: any, prevState: DetailMenuState) {
    if (nextProps.history.location.pathname !== prevState.activeSection) {
      return { activeSection: nextProps.history.location.pathname };
    }
    return null;
  }

  componentDidUpdate(prevProps: any, prevState: DetailMenuState) {
    if (prevProps.history.location.pathname !== this.props.history.location.pathname) {
      this.setState({ activeSection: this.props.history.location.pathname });
    }
  }

  render() {
    const { modalLogOut, activeSection, sectionOpen, centre, renderModal, centres } = this.state;
    const { onLogout, t, agent, profilePicture } = this.props;

    const CENTRE_URL = `/centres/${centre.id}/`;
    const CENTRE_FRONT = `${APP_WEB_URL}/dive-center/${centre.id}`;

    const sections: { [key: string]: string } = {
      business: 'business',
      planning: 'planning',
      diveSite: 'dive-site',
      divers: 'divers',
      offers: 'offers',
      billing: 'billing',
      reservations: 'reservations',
      incidences: 'incidences',
      notifications: 'notifications',
      messages: 'messages',
      config: 'configuration',
    };

    return (
      <>
        <DetailMenuContainer>
          <div className="menu-top">
            <div className="menu-logo">
              <a href={CENTRE_FRONT} target="_blank">
                <img src={images.diveasappLogoWhiteSvg} alt="" />
              </a>
            </div>
            <div className="menu-back">
              {agent.roles.includes('superAdmin') ? (
                <div className="menu-back-superadmin" onClick={() => this.props.history.push('/')}>
                  <div className="menu-back-superadmin__text">
                    <InfoText>{t('layouts:detail-menu.super-admin')}</InfoText>
                  </div>
                  <div className="menu-back-superadmin__back">
                    <LabelText>{t('buttons:back')}</LabelText>
                  </div>
                </div>
              ) : ''}
              {/* (
                  <NavLink to="/">
                    <div className="menu-back-button">
                      <div className="menu-back-button__arrow">
                        <img src={images.arrowBackPale} alt="" />
                      </div>

                        <div className="menu-back-button__text">
                        <LabelText>{centre.name}</LabelText>
                      </div>


                    </div>
                  </NavLink>
                )} */}
              {!agent.roles.includes('superAdmin') ?
                <div className="menu-centers-button">
                  <SelectBox
                    className="rounded-title"
                    optionsText={centres}
                    required={true}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={centre.id}
                    initialValue={centre.id}
                    icon={images.arrowDown2WhiteSvg}
                    onChange={(value: any) => {
                      this.props.history.push(`/centres/${value}/planning/calendar`);
                      window.location.reload();
                    }}
                  />
                </div>
                : ''}
            </div>
          </div>
          <Scrollbars
            renderTrackVertical={props => <div {...props} className="track-vertical" />}
            renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
            style={{ width: '100%', height: '100%' }}
            autoHide={false}
            autoHideTimeout={200}
            autoHideDuration={200}
          >
            <div className="menu-items">
              <div className="menu-items__button">

                <MainButtonDrop
                  text={t('layouts:detail-menu.my-center')}
                  iconImg={images.myDiveCenterMenu}
                  type="simple-menu"
                  propOpen={sectionOpen === sections.business}
                  active={activeSection.includes(CENTRE_URL + sections.business)}
                  onClick={() => {
                    const section = sectionOpen !== sections.business ? sections.business : '';
                    this.setState({ sectionOpen: section });
                    this.countUnreadNotifications(Number(centre.id));
                  }}
                >
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.business}/profile/data`}>
                      <p>{t('layouts:detail-menu.center-profile')}</p>
                    </NavLink>
                  </div>
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.diveSite}`}>
                      <p>{t('layouts:detail-menu.dive-sites')}</p>
                    </NavLink>
                  </div>
                  {/*<div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.business}/equipment/inventary`}>
                      <p>{t('layouts:detail-menu.equipment')}</p>
                    </NavLink>
                  </div>*/}
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.business}/staff`}>
                      <p>{t('layouts:detail-menu.staff')}</p>
                    </NavLink>
                  </div>
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.business}/documentation`}>
                      <p>{t('layouts:detail-menu.documentation')}</p>
                    </NavLink>
                  </div>

                  {/* <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.business}/accomodation`}>
                      <p>{t('layouts:detail-menu.accomodation-feed')}</p>
                    </NavLink>
                  </div> */}
                </MainButtonDrop>
              </div>

              <div className="menu-items__button">
                <MainButtonDrop
                  text={t('layouts:detail-menu.dive-offers')}
                  iconImg={images.tagWhiteSvg}
                  type="simple-menu"
                  propOpen={sectionOpen === sections.offers}
                  active={activeSection.includes(CENTRE_URL + sections.offers)}
                  onClick={() => {
                    const section = sectionOpen !== sections.offers ? sections.offers : '';
                    this.setState({ sectionOpen: section });
                    this.countUnreadNotifications(Number(centre.id));
                  }}
                >
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.offers}/activities`}>
                      <p>{t('layouts:detail-menu.activities')}</p>
                    </NavLink>
                  </div>
                  {/*<div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.offers}/offers`}>
                      <p>{t('layouts:detail-menu.offers')}</p>
                    </NavLink>
                  </div>
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.offers}/bonuses`}>
                      <p>{t('layouts:detail-menu.bonuses')}</p>
                    </NavLink>
                  </div>*/}
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.offers}/cancellation`}>
                      <p>{t('layouts:detail-menu.cancellation')}</p>
                    </NavLink>
                  </div>
                </MainButtonDrop>
              </div>

              <div className="menu-items__button">
                <NavLink to={`${CENTRE_URL}${sections.divers}`}>
                  <MainButton
                    text={t('layouts:detail-menu.my-divers')}
                    iconImg={images.myDivesMenu}
                    type="simple-menu"
                    active={activeSection.includes(CENTRE_URL + sections.divers)}
                    onClick={() => {
                      const section = sectionOpen !== sections.divers ? sections.divers : '';
                      this.setState({ sectionOpen: section });
                      this.countUnreadNotifications(Number(centre.id));
                    }}
                  />
                </NavLink>
              </div>



              {/* <div className="menu-items__button">
                <NavLink to={`${CENTRE_URL}${sections.diveSite}`}>
                  <MainButton
                    text={t('layouts:detail-menu.dive-sites')}
                    iconImg={images.layersWhiteSvg}
                    type="simple-menu"
                    active={activeSection.includes(CENTRE_URL + sections.diveSite)}
                    onClick={() => {
                      const section = sectionOpen !== sections.diveSite ? sections.diveSite : '';
                      this.setState({ sectionOpen: section });
                    }}
                  />
                </NavLink>
              </div>*/}

              <div className="menu-items__button">
                <NavLink to={`${CENTRE_URL}${sections.billing}/bookings`}>
                  <MainButton
                    text={t('layouts:detail-menu.bookings')}
                    iconImg={images.alertWhiteSvg}
                    type="simple-menu"
                    active={activeSection.includes(CENTRE_URL + sections.reservations)}
                    onClick={() => {
                      const section = sectionOpen !== sections.reservations ? sections.reservations : '';
                      this.setState({ sectionOpen: section });
                      this.countUnreadNotifications(Number(centre.id));
                    }}
                  />
                </NavLink>
              </div>

              { /*<div className="main-button-drop-content__item">
                <NavLink to={`${CENTRE_URL}${sections.billing}/bookings`}>
                  <p>{t('layouts:home-menu.bookings')}</p>
                </NavLink>
              </div>*/ }





              <div className="menu-items__button">
                <MainButtonDrop
                  text={t('layouts:detail-menu.planning')}
                  iconImg={images.PlanificationMenu}
                  type="simple-menu"
                  propOpen={sectionOpen === sections.planning}
                  active={activeSection.includes(CENTRE_URL + sections.planning)}
                  onClick={() => {
                    const section = sectionOpen !== sections.planning ? sections.planning : '';
                    this.setState({ sectionOpen: section });
                    this.countUnreadNotifications(Number(centre.id));
                  }}
                >
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.planning}/calendar`}>
                      <p>{t('layouts:detail-menu.calendar')}</p>
                    </NavLink>
                  </div>
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.planning}/reservations`}>
                      <p>{t('layouts:detail-menu.reserve')}</p>
                    </NavLink>
                  </div>
                  {/*<div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.planning}/history`}>
                      <p>{t('layouts:detail-menu.history')}</p>
                    </NavLink>
                  </div>
                   <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.planning}/accomodation`}>
                      <p>{t('layouts:detail-menu.accomodation')}</p>
                    </NavLink>
                  </div> */}
                  {/* <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.planning}/reports`}>
                      <p>{t('layouts:detail-menu.reports')}</p>
                    </NavLink>
                  </div> */}
                </MainButtonDrop>
              </div>

              {/*
              <div className="menu-items__button">
                <NavLink to={`${CENTRE_URL}${sections.messages}/chat`}>
                  <MainButton
                    text={t('layouts:detail-menu.messages')}
                    iconImg={images.alertWhiteSvg}
                    type="simple-menu"
                    active={activeSection.includes(CENTRE_URL + sections.messages)}
                    onClick={() => {
                      const section = sectionOpen !== sections.messages ? sections.messages : '';
                      this.setState({ sectionOpen: section });
                      this.countUnreadNotifications(Number(centre.id)); 
                    }}
                  />
                </NavLink>
              </div>*/}

              <div className="menu-items__button">
                <MainButtonDrop
                  text={t('layouts:detail-menu.billing')}
                  iconImg={images.fileWhiteSvg}
                  type="simple-menu"
                  propOpen={sectionOpen === sections.billings}
                  active={activeSection.includes(CENTRE_URL + sections.billings)}
                  onClick={() => {
                    const section = sectionOpen !== sections.billings ? sections.billings : '';
                    this.setState({ sectionOpen: section });
                    this.countUnreadNotifications(Number(centre.id));
                  }}
                >
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${CENTRE_URL}${sections.billing}/bills`}>
                      <p>{t('layouts:detail-menu.bills')}</p>
                    </NavLink>
                  </div>

                  <div className="main-button-drop-content__item">
                    <NavLink to={`/centres/${sections.billing}/billing-information`}>
                      <p>{t('layouts:home-menu.billing-information')}</p>
                    </NavLink>
                  </div>

                </MainButtonDrop>
              </div>


              <div className="menu-items__button">
                <NavLink to={`${CENTRE_URL}notifications`}>
                  <MainButton
                    text={(this.state.unReadNotifications !== 0 ? `(${this.state.unReadNotifications}) ` : '') + t('layouts:detail-menu.notification')}
                    iconImg={this.state.unReadNotifications !== 0 ? images.alertCircleRedSvg : images.alertWhiteSvg}
                    type="simple-menu"
                    active={activeSection.includes(CENTRE_URL + sections.notifications)}
                    onClick={() => {
                      const section = sectionOpen !== sections.notifications ? sections.notifications : '';
                      this.setState({ sectionOpen: section });
                      this.countUnreadNotifications(Number(centre.id));
                    }}
                  />
                </NavLink>
              </div>
              {/*
              <div className="menu-items__button">
              <NavLink to={`${CENTRE_URL}${sections.billing}`}>
                <MainButton
                  text="Facturación"
                  iconImg={images.fileWhiteSvg}
                  type="simple-menu"
                  active={activeSection.includes(CENTRE_URL + sections.billing)}
                  onClick={() => {
                    const section = sectionOpen !== sections.billing ? sections.billing : '';
                    this.setState({ sectionOpen: section });
                  }}
                />
              </NavLink>
            </div> 

              
              <div className="menu-items__button">
                <NavLink to={`${CENTRE_URL}${sections.incidences}`}>
                  <MainButton
                    text={t('layouts:detail-menu.incidences')}
                    iconImg={images.alertWhiteSvg}
                    type="simple-menu"
                    active={activeSection.includes(CENTRE_URL + sections.incidences)}
                    onClick={() => {
                      const section = sectionOpen !== sections.incidences ? sections.incidences : '';
                      this.setState({ sectionOpen: section });
                    }}
                  />
                </NavLink>
              </div>
           */ }

            </div>
          </Scrollbars>
          {/*  */}
          <div className="menu-bottom">

            {agent.roles.includes('superAdmin') ?
              <div className="menu-bottom__profile">
                <NavLink to="/my-profile">
                  <MainButton
                    type="simple-menu avatar"
                    iconImg={profilePicture ? profilePicture : images.avatar}
                    text={t('layouts:home-menu.my-profile')}
                  />
                </NavLink>
              </div>
              :

              <div className="menu-bottom__profile">
                <a href={`${APP_WEB_URL}/profile`} target="_blank">
                  <MainButton
                    type="simple-menu avatar"
                    iconImg={profilePicture ? profilePicture : images.avatarDiverDefault}
                    text={t('layouts:detail-menu.my-profile')}
                  />
                </a>
              </div>
            }
            <div className="menu-bottom__profile">
              <MainButtonDropProfile
                text={t('layouts:home-menu.config')}
                iconImg={images.settingsWhiteSvg}
                type="simple-menu"
                propOpen={sectionOpen === sections.config}
                active={activeSection.includes(CENTRE_URL + sections.config)}
                onClick={() => {
                  const section = sectionOpen !== sections.config ? sections.config : '';
                  this.setState({ sectionOpen: section });
                  this.countUnreadNotifications(Number(centre.id));
                }}
              >
                <div className="main-button-drop-content__item">
                  <NavLink to={`${CENTRE_URL}${sections.config}/measurement-units`}>
                    <p>{t('layouts:home-menu.units')}</p>
                  </NavLink>
                </div>
                {/*<div className="main-button-drop-content__item">
                  <NavLink to={`${CENTRE_URL}${sections.config}/faq`}>
                    <p>{t('layouts:detail-menu.faq')}</p>
                  </NavLink>
                </div>*/}
                <div className="main-button-drop-content__item">
                  <NavLink to={`${CENTRE_URL}${sections.config}/helpcentre`}>
                    <p>{t('layouts:detail-menu.helpcentre')}</p>
                  </NavLink>
                </div>
                <div className="main-button-drop-content__item">
                  <NavLink to={`/legal`} target={'blank'}>
                    <p>Legal</p>
                  </NavLink>
                </div>
              </MainButtonDropProfile>
            </div>





            <div className="menu-bottom__logout" onClick={() => this.setState({ modalLogOut: true })}>
              <MainButton
                type="simple-menu logout"
                iconImg={images.logOutWhiteSvg}
                text={t('layouts:detail-menu.close-session')}
              />
            </div>
          </div>
        </DetailMenuContainer>

        {/* Modal LogOut */}
        <ModalLogOut
          showModal={modalLogOut}
          onAccept={() => this.setState({ modalLogOut: false }, () => onLogout())}
          onCancel={() => this.setState({ modalLogOut: false })}
          title={t('modalMessages:close-session.title')}
          subtitle={t('modalMessages:close-session.subtitle')}
        />

        {
          renderModal === 'modal-advice-validated' && (
            <ModalInfo
              onClose={() => this.setState({ renderModal: '' })}
              showModal={true}
              icon={images.alertBlueSvg}
              type={'blue-title'}
              title={['Atención']}
              text={['Centro pendiente de validar. Falta documentación o la estamos revisando.']}
              buttons={[
                {
                  text: 'Aceptar',
                  type: 'primary',
                  onClick: () => this.setState({ renderModal: '' }),
                },
              ]}
            />
          )
        }
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(
  withTranslation(['intro', 'buttons', 'layouts', 'modalMessages'])(withRouter(DetailMenu))
);
