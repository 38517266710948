import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalConfirmationActivityContainer = styled.div`
  width: 780px;
  /*height: 516px;*/
  height: auto;
  padding: 62px 32px 32px;

  .modal-confirmation-activity-name {
    font-family: Plain;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${colors['SE-002/100']};
    margin-bottom: 2px;
  }

  .modal-confirmation-activity-title {
    font-family: Plain;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: ${colors['NE-001/100']};
    margin-bottom: 12px;
  }

  .modal-confirmation-activity-subtitle {
    font-family: Plain;
    font-size: 14px;
    font-weight: 100;
    line-height: 22px;
    color: ${colors['NE-002/100']};
    margin-bottom: 28px;
  }

  .modal-confirmation-activity-paragraph {
    color: ${colors['NE-001/100']};
    font-family: Plain;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 28px;
  }

  .modal-confirmation-activity-inmersion-number {
    font-family: Plain;
    font-size: 14px;
    line-height: 22px;
    color: ${colors['NE-001/100']};
    margin-bottom: 14px;
  }

  .modal-confirmation-activity {
    display: flex;
    justify-content: space-between;
  }

  .modal-confirmation-activity-time-left {
    width: 338px;
  }

  .modal-confirmation-activity-time-right {
    width: 338px;
  }

  .modal-confirmation-activity-calendar {
    margin-bottom: 10px;
  }

  .modal-confirmation-activity-button-add {
    width: 119px;
    margin-bottom: 48px;
  }

  .modal-confirmation-activity-buttons-container {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-top: 64px;
  }
  .modal-confirmation-activity-button-back {
    width: auto;
  }

  .modal-confirmation-activity-button-next {
    width: 144px;
  }

  .modal-confirmation-activity-buttons {
    display: flex;
    margin-top: 30px;
  }

  .modal-confirmation-activity-text {
    font-family: Plain;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    p {
      margin-bottom: 30px;
    }
  }

  .modal-confirmation-activity-button-back {
    width: 46px;
    margin-right: 12px;
  }
`;
