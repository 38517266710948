import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ModalContainer from '../../../../../components/modals/modal-container';
import { Booking } from '../../../../../models/bookings';
import { ModalBookingDetailBodyStyle } from './modal-booking-cancel-passed-style';
import { images } from '../../../../../assets/images';
import Button from '../../../../../components/buttons/main-button/button';
import { getDayOfMonth } from '../../../../../utils/time';

interface IModalBookingDetailProps {
  showModal: boolean;
  modalClose: Function;
  title: string;
  text: string;
}

const ModalBookingCancelPassed: React.FC<IModalBookingDetailProps> = ({ title, text, showModal, modalClose }) => {
  const { t } = useTranslation(['modalMessages', 'layouts']);


  const modalBody = (
    <ModalBookingDetailBodyStyle>
      <div className="booking-title">{title}</div>
      <div className="booking-number">{text}</div>
    </ModalBookingDetailBodyStyle>
  );

  return <ModalContainer active={showModal} modalClose={modalClose} children={modalBody} />;
};

export default ModalBookingCancelPassed;
