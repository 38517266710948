export enum incidenceType {
  unofficialCentre = 'unofficialCentre',
  badDescriptionActivity = 'badDescriptionActivity',
  lowerPrices = 'lowerPrices',
  unprofessionalism = 'unprofessionalism',
  badMaterial = 'badMaterial',
  activityCancelled = 'activityCancelled',
  fakeActivityDescription = 'fakeActivityDescription',
  paymentIncidences = 'paymentIncidences',
  refundIncidences = 'refundIncidences',
  wrongCoordinates = 'wrongCoordinates',
  wrongDescription = 'wrongDescription',
  wrongName = 'wrongName',
  unsuitableContent = 'unsuitableContent',
  siteDoesNotMatch = 'siteDoesNotMatch',
  pictureDoesNotMatch = 'pictureDoesNotMatch',
  copyright = 'copyright',
  unauthorizedPerson = 'unauthorizedPerson',
  containsAds = 'containsAds',
  other = 'other',
}

export enum incidenceReason {
  solved = 'solved',
  wronglySolved = 'wronglySolved',
  other = 'other',
}

export const ImageReference: { [key: string]: string } = {
  'centre-pictures': 'Centro',
  'dive-site-pictures': 'Punto de inmersión',
  'room-picture': 'Habitación',
};

export const INCIDENCE_STATES_KEY_VALUES = {
  open: 'Abierta',
  solved: 'Resuelta',
  closed: 'Cerrada',
};

export const INCIDENCE_STATES_DROPDOWN = [{ value: 'open', label: 'Abierta' }, { value: 'solved', label: 'Resuelta' }];
