import styled from 'styled-components';

export const PayDataStyle = styled.div`
  .pay-detail-general-container {
    display: flex;
    width: 100%;
  }
  .pay-detail-general-left {
    margin-right: 60px;
    width: 100%;
    &__card {
      margin-bottom: 120px;
    }
    &__section {
      margin-bottom: 80px;
    }
  }
  .pay-detail-general-double {
    display: flex;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
    &__title {
      width: 50%;
    }
    &__text {
      width: 50%;
    }
  }
`;
