import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ActivityDetailGeneralStyle = styled.div`
  width: 100%;

  .activity-detail-general-hero {
    margin-bottom: 120px;
  }
  .activity-detail-general-container {
    display: flex;
    width: 100%;
  }
  .activity-detail-general-left {
    width: 50%;
    margin-right: 50px;
    &__card {
      margin-bottom: 120px;
    }
    &__section {
      margin-bottom: 80px;
    }
    &__text {
      all: revert;
      font-size: revert;
      p,
      ul,
      li,
      em,
      ol,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      strong {
        font-size: revert !important;
        font-weight: revert !important;
        font-style: revert !important;
        text-transform: revert !important;
        margin-top: revert !important;
        margin-bottom: revert !important;
        line-height: revert !important;
        color: revert !important;
        margin: revert !important;
        display: revert !important;
        -webkit-margin-before: revert !important;
        -webkit-margin-after: revert !important;
        -webkit-margin-start: revert !important;
        -webkit-margin-end: revert !important;
      }
    }
  }

  .activity-detail-general-left-section {
    margin-bottom: 52px;

    &_card-planning {
      border: 1px solid #00a1a5;
      padding: 20px;
      margin-bottom: 20px;

      &_item {
        display: flex;
      }

      .detail {
        margin-left: auto;
      }
    }

    &_card {
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 20px;

      &_item {
        display: flex;
      }

      .detail {
        margin-left: auto;
      }
    }
  }

  .activity-detail-general-left-dive-sites {
    display: flex;
    &__img {
      width: 32px;
    }
  }

  .activity-detail-general-double {
    display: flex;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
    &__title {
      width: 50%;
    }
    &__text {
      width: 50%;
    }
  }

  .activity-detail-general-card-activity {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .activity-detail-general-services {
    display: flex;
    flex-wrap: wrap;

    &__item  {
      display: flex;
      width: 50%;
      align-items: center;
      padding-right: 12px;
      margin-bottom: 14px;
      &__icon {
        width: 30px;
        height: 30px;
        margin-right: 12px;
      }
    }
  }

  .activity-detail-general-right {
    width: 60%;
    &__card {
      margin-bottom: 120px;
    }
    &__section {
      margin-bottom: 80px;
    }
    &__text {
      all: revert;
      font-size: revert;
      p,
      ul,
      li,
      em,
      ol,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      strong {
        font-size: revert !important;
        font-weight: revert !important;
        font-style: revert !important;
        text-transform: revert !important;
        margin-top: revert !important;
        margin-bottom: revert !important;
        line-height: revert !important;
        color: revert !important;
        margin: revert !important;
        display: revert !important;
        -webkit-margin-before: revert !important;
        -webkit-margin-after: revert !important;
        -webkit-margin-start: revert !important;
        -webkit-margin-end: revert !important;
      }
    }
  }

  .activity-detail-general-right-section {
    margin-bottom: 52px;
    &__title {
      color: ${colors['NE-002/100']};
      padding-bottom: 8px;
      margin-bottom: 24px;
      border-bottom: 1px solid ${colors['NE-002/100']};
      display: flex;
      justify-content: space-between;
    }

    &__item {
      margin-bottom: 28px;
      &:last-child  {
        margin-bottom: 0px;
      }
    }

    &__observaciones {
      padding: 1em;
      margin-bottom: 60px;
      margin-top: 20px;
      background: #f0f8f9;
      border-radius: 5px;
      .title {
        display: flex;
        justify-content: space-between;
        img {
          width: 16px;
          cursor: pointer;
        }
      }
    }

    &__sites {
      background: #f3f3f3;
      padding: 1em;
      border-radius: 5px;
      margin-bottom: 15px;
    }

    &_card-planning {
      background-color: #d8f4f8;
      padding: 20px;
      margin-bottom: 20px;

      &_item {
        display: flex;
      }

      .detail {
        margin-left: auto;
      }
    }

    &_card {
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 20px;

      &_item {
        display: flex;
      }

      .detail {
        margin-left: auto;
      }
    }
  }

  .activity-detail-general-right-section_card_site {
    background: #f3f3f3;
    border-radius: 5px;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;

    &_item {
      display: flex;
    }

    .detail {
      margin-left: auto;
    }
  }

  &__buceadores {
    padding-top: 30px;
  }

  .dive-activity-user-card {
    height: 232px;
    padding: 40px 32px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }

  .dive-activity-buy {
    margin-bottom: 94px;
  }
  .dive-activity-user-card-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    &__avatar  {
      width: 72px;
      height: 72px;
      overflow: hidden;
      border-radius: 100%;
      img {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }
    }
  }
  .dive-activity-user-card-name {
    margin-bottom: 10px;
  }
  .dive-activity-user-card-level {
    margin-bottom: 10px;
    color: ${colors['NE-002/100']};
  }

  .dive-activity-user-card-line {
    position: absolute;
    height: 9px;
    background: ${colors['SE-002/100']};
    width: 100%;
    left: 0;
    bottom: 0px;
  }

  .item-section-detail-edit {
    color: ${colors['PR-001/100']};
    cursor: pointer;
  }
  .planing-date-disclaimer {
    color: ${colors['NE-002/100']};
    margin-bottom: 26px;
  }

  .planing-date-subtitle {
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  .planing-date-title {
    color: ${colors['SE-002/100']};
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  .planing-date-dicotomic {
    width: 213px;
    margin-bottom: 28px;
  }
  .planing-date-start {
    margin-bottom: 28px;
  }
  .planing-date-start-form {
    width: 100%;
  }
  .planing-date-horary {
    margin-bottom: 28px;
  }
  .planing-date-horary-form {
    display: flex;
    &__item {
      flex: auto;
      margin-right: 20px;
    }
    &__add {
      width: 120px;
    }
    &__times {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      padding: 20px;

      .delate-img {
        cursor: pointer;
        width: 20px;
        margin-right: 10px;
        margin-top: 5px;
        cursor: pointer;
      }
    }
  }

  .planing-date-periodicity {
    margin-bottom: 28px;
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .planing-date-final  {
    width: 338px;
  }

  .input-box-main-field {
    &::before {
      input {
        border: none;
        padding: 0px;
      }
    }
  }

  .mt-6 {
    margin-top: 6px;
  }
`;
