import * as React from 'react';

import HeaderMain from '../../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../../main-layout-style';
import MainTableLayout from '../../../../components/main-table/main-table';
import Pagination from '../../../../components/pagination';
import { RouteComponentProps, withRouter, match } from 'react-router';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { images } from '../../../../assets/images/index';
import { getOrganization, getOrganizationCentres } from '../../../../services/collaborator';
import { copyOf, createFilterRequest } from '../../../../utils/other';

import Services from '../../../../services/services';
import moment, { Moment, max } from 'moment';
import { getCentre } from '../../../../services/centre';
import { ICentreForm } from '../../../../models/centre-forms';
import { CertifierCenterDetailContainer } from './certifier-center-detail-style';
import { NavigationTabContainer } from '../../../../components/navigation-tabs/navigation-tabs-style';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import { NavLink } from 'react-router-dom';
import ItemSectionDetail from '../../../../components/item-sections-detail/item-sections-detail';
import TitleText from '../../../../components/text/title-text/title-text';
import { H1TextContainer } from '../../../../components/text/h1-text/h1-text-styles';
import TextDestacadoText from '../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../components/text/text-text/text-text';
import { CENTRE_TYPES_KEY_VALUE } from '../../../../constants/centres';
import { COUNTRIES_KEY_LABEL } from '../../../../constants/countries';
import { IActivity } from '../../../../models/activities';
import { DiveDisciplinesKeyValues } from '../../../../constants/dive-dropdowns';
import { CERTIFIER_CENTRE_DETAIL_ACTIVITIES_PER_PAGE } from '../../../../constants/data';

interface ICertifierCenterDetailProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
  }>;
}

interface ICertifierCenterDetailState {
  centre?: ICentreForm;
  activities?: IActivity[];
  filterSearch: string;
  currentSection: string;
  totalPages: number;
  total: number;
  page: number;
}

class CertifierCenterDetail extends React.Component<ICertifierCenterDetailProps, ICertifierCenterDetailState> {
  constructor(props: ICertifierCenterDetailProps) {
    super(props);
    this.state = {
      filterSearch: '',
      currentSection: 'profile',
      totalPages: 0,
      page: 1,
      total: 0,
    };
  }

  componentDidMount() {
    this.getCentre();
  }

  async getCentre() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const params = {
      filter: {
        relations: ['activities', 'activities.bookings', 'activities.course', 'course.certifier'],
      },
    };
    const resp = await getCentre(id, params);
    const centre: ICentreForm = resp.data;
    const certifierId = parseInt(localStorage.getItem('organizationId') || '0', undefined);
    centre.activities = centre.activities.filter(
      (activity: any) => activity.course && activity.course.certifier && activity.course.certifier === certifierId
    );
    const totalPages: number = Math.ceil(centre.activities.length / CERTIFIER_CENTRE_DETAIL_ACTIVITIES_PER_PAGE) - 1;
    this.setState({ total: centre.activities.length, centre, totalPages, activities: centre.activities });
  }

  getTableRows() {
    const tableRows: any[] = [];
    const { centre, page } = this.state;
    const { t } = this.props;
    if (!centre || !centre.activities) return [];
    centre.activities
      .slice(page - 1 * CERTIFIER_CENTRE_DETAIL_ACTIVITIES_PER_PAGE, page * CERTIFIER_CENTRE_DETAIL_ACTIVITIES_PER_PAGE)
      .forEach((activity: IActivity) => {
        const tableRow = copyOf(this.tableRowTemplate);
        tableRow.name.value = activity.name;
        tableRow.type.value = [activity.divesInfo ? t('modalMessages:inm') : t('modalMessages:courses')];
        tableRow.discipline.value = DiveDisciplinesKeyValues[activity.discipline];
        tableRow['num_divers'].value = activity.bookings ? activity.bookings.length : '0';
        tableRow.date.value = moment(activity.date).format('DD/MM/YYYY');
        tableRows.push(tableRow);
      });
    return tableRows;
  }

  renderProfile() {
    const { centre } = this.state;
    const { t } = this.props;
    if (!centre) return '';
    return (
      <>
        <div className="certifier-centre-detail-logo-container">
          <img src={centre.logo} />
        </div>
        <div className="certifier-centre-detail-title-container">
          <H1TextContainer>{centre.name}</H1TextContainer>
        </div>
        <div className="certifier-centre-detail-data-container">
          <ItemSectionDetail title="Datos generales" icon={images.dataGeneral}>
            <div className="certifier-centre-detail-data-item">
              <div className="certifier-centre-detail-data-item__title">
                <TextDestacadoText>Creación:</TextDestacadoText>
              </div>
              <div className="certifier-centre-detail-data-item__text">
                <TextText>{moment(centre.createdAt).format('DD/MM/YYYY')}</TextText>
              </div>
            </div>
            <div className="certifier-centre-detail-data-item">
              <div className="certifier-centre-detail-data-item__title">
                <TextDestacadoText>{t('modalMessages:type')}:</TextDestacadoText>
              </div>
              <div className="certifier-centre-detail-data-item__text">
                <TextText>{CENTRE_TYPES_KEY_VALUE[centre.type]}</TextText>
              </div>
            </div>
            <div className="certifier-centre-detail-data-item">
              <div className="certifier-centre-detail-data-item__title">
                <TextDestacadoText>{t('modalMessages:web')}:</TextDestacadoText>
              </div>
              <div className="certifier-centre-detail-data-item__text">
                <TextText>{centre.website}</TextText>
              </div>
            </div>
            <div className="certifier-centre-detail-data-item">
              <div className="certifier-centre-detail-data-item__title">
                <TextDestacadoText>{t('modalMessages:email')}:</TextDestacadoText>
              </div>
              <div className="certifier-centre-detail-data-item__text">
                <TextText>{centre.email}</TextText>
              </div>
            </div>
            <div className="certifier-centre-detail-data-item">
              <div className="certifier-centre-detail-data-item__title">
                <TextDestacadoText>{t('modalMessages:phone')}:</TextDestacadoText>
              </div>
              <div className="certifier-centre-detail-data-item__text">
                <TextText>{centre.phone ? `${centre.phone.prefix} ${centre.phone.number}` : ''}</TextText>
              </div>
            </div>
            <div className="certifier-centre-detail-data-item">
              <div className="certifier-centre-detail-data-item__title">
                <TextDestacadoText>Facebook:</TextDestacadoText>
              </div>
              <div className="certifier-centre-detail-data-item__text">
                <TextText>{centre.facebook}</TextText>
              </div>
            </div>
            <div className="certifier-centre-detail-data-item">
              <div className="certifier-centre-detail-data-item__title">
                <TextDestacadoText>Instagram:</TextDestacadoText>
              </div>
              <div className="certifier-centre-detail-data-item__text">
                <TextText>{centre.instagram}</TextText>
              </div>
            </div>
          </ItemSectionDetail>
          <ItemSectionDetail title="Datos de contacto" icon={images.dataContact}>
            <div className="certifier-centre-detail-data-left">
              <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>Direccion:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>{centre.address}</TextText>
                </div>
              </div>
              <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>{t('modalMessages:city')}:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>{centre.town}</TextText>
                </div>
              </div>
              <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>{t('modalMessages:state')}:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>{centre.state}</TextText>
                </div>
              </div>
              <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>{t('modalMessages:zip')}:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>{centre.postalCode}</TextText>
                </div>
              </div>
              <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>{t('modalMessages:country')}:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>{COUNTRIES_KEY_LABEL[centre.country]}</TextText>
                </div>
              </div>
              {/* <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>Teléfono:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>
                    {centre.personResponsible && centre.personResponsible.phone
                      ? `${centre.personResponsible.phone.prefix} ${centre.personResponsible.phone.number}`
                      : ''}
                  </TextText>
                </div>
              </div> */}
              {/* <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>Email:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>{centre.personResponsible ? centre.personResponsible.email : ''}</TextText>
                </div>
              </div> */}
              <div className="certifier-centre-detail-data-subtitle">
                <BodyDestacadoText>{t('modalMessages:modal-center-form.personal-data')}</BodyDestacadoText>
              </div>
              <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>{t('modalMessages:name')}:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>{centre.personResponsible ? centre.personResponsible.name : ''}</TextText>
                </div>
              </div>
              <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>{t('modalMessages:phone')}:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>
                    {centre.personResponsible && centre.personResponsible.phone
                      ? `${centre.personResponsible.phone.prefix} ${centre.personResponsible.phone.number}`
                      : ''}
                  </TextText>
                </div>
              </div>
              <div className="certifier-centre-detail-data-item">
                <div className="certifier-centre-detail-data-item__title">
                  <TextDestacadoText>{t('modalMessages:email')}:</TextDestacadoText>
                </div>
                <div className="certifier-centre-detail-data-item__text">
                  <TextText>{centre.personResponsible ? centre.personResponsible.email : ''}</TextText>
                </div>
              </div>
            </div>
          </ItemSectionDetail>
        </div>
      </>
    );
  }

  searchTimeout: any;
  handleSearch(value: string | null) {
    const { centre, activities } = Object.assign(this.state);
    const filteredValue = (value || '').toLowerCase();
    centre.activities = activities.filter((activity: IActivity) => activity.name.toLowerCase().includes(filteredValue));
    const totalPages: number = Math.ceil(centre.activities.length / CERTIFIER_CENTRE_DETAIL_ACTIVITIES_PER_PAGE) - 1;
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState({ centre, totalPages, page: 1 });
    }, 500);
  }

  setPage(page: number) {
    this.setState({ page });
  }

  renderActivities() {
    const { totalPages, page } = this.state;
    return (
      <div className="ads-detail-table__content">
        <MainLayoutTableContainer>
          <div className="diving-center-table">
            <MainTableLayout
              columns={this.tableColumns}
              rows={this.getTableRows()}
              rowKeys={this.rowKeys}
              onOrder={() => { }}
            />
          </div>
          {totalPages > 1 && (
            <div className="diving-center-pagination">
              <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
            </div>
          )}
        </MainLayoutTableContainer>
      </div>
    );
  }

  render() {
    const {
      match: {
        params: { id },
      },t
    } = this.props;
    const { centre, currentSection, total } = this.state;
    if (!centre) return '';
    return (
      <CertifierCenterDetailContainer>
        <HeaderMain
          title={t('modalMessages:dive-center')}
          goBack={true}
          withSearch={currentSection === 'activities'}
          handleSearch={(value: string) => this.handleSearch(value)}
          resultCount={total}
        />
        <MainLayoutSection>
          <NavigationTabContainer>
            <div
              className={`navigation-item ${currentSection === 'profile' ? 'active' : ''}`}
              onClick={() => this.setState({ currentSection: 'profile' })}
            >
              <BodyDestacadoText>Perfil</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${currentSection === 'activities' ? 'active' : ''}`}
              onClick={() => this.setState({ currentSection: 'activities' })}
            >
              <BodyDestacadoText>Actividades</BodyDestacadoText>
            </div>
          </NavigationTabContainer>
          {currentSection === 'profile' ? this.renderProfile() : this.renderActivities()}
        </MainLayoutSection>
      </CertifierCenterDetailContainer>
    );
  }

  tableColumns = [
    {
      text: this.props.t('modalMessages:name'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:type'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:discipline'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('modalMessages:modal-booking-detail.nrodivers'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:date').toLowerCase(),
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    name: {
      key: '',
      value: '',
    },
    type: {
      key: '',
      value: '',
    },
    discipline: {
      key: '',
      value: '',
    },
    num_divers: {
      key: '',
      value: '',
    },
    date: {
      key: '',
      link: '',
    },
  };

  rowKeys = ['name', 'type', 'discipline', 'num_divers', 'date'];
}
export default withTranslation(['intro', 'buttons', 'modalMessages'])(withRouter(CertifierCenterDetail));
