import React, { useState, useRef } from 'react';
import { HeaderDropdownStyle } from './header-dropdown-style';

interface IHeaderDropdownProps {
  title: string;
  items: any[];
}
const HeaderDropdown: React.FC<IHeaderDropdownProps> = ({ title, items }) => {
  return (
    <HeaderDropdownStyle>
      <div className="header-dropdown__title">{title}</div>
      {items.map((item: any, i: number) => (
        <div key={i} className="header-dropdown__item">
          {item}
        </div>
      ))}
    </HeaderDropdownStyle>
  );
};

export default HeaderDropdown;
