import React, { Component } from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import SingleButton from '../../../components/single-button/single-button';
import { images } from '../../../assets/images';
import { CERTIFIER_PROJECTS_PER_PAGE } from '../../../constants/data';
import { deleteConservationAction, getAllConservationActions } from '../../../services/conservation-actions';
import { ConservationActionExtended, ConservationActionTableRow } from '../../../models/conservation-action';
import { copyOf } from '../../../utils/other';
import ModalCreateProject from './modal-create-project/modal-create-project';
import services from '../../../services/services';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import moment from 'moment';

interface RouteParams {
  id: string;
}

interface CertifierProjectProps extends RouteComponentProps<RouteParams>, WithTranslation { }

interface CertifierProjectState {
  adActive: boolean;
  modal: string;
  ads: any;
  activeFilters: boolean;
  certifierId?: string;
  projects: ConservationActionExtended[];
  totalProjects: number;
  totalPages: number;
  skipPage: number;
  page: number;
  filterSearch: string;
  selectedProject?: ConservationActionExtended;
}

class CertifierProject extends Component<CertifierProjectProps, CertifierProjectState> {
  state: CertifierProjectState = {
    adActive: false,
    modal: '',
    ads: {},
    activeFilters: false,
    filterSearch: '',
    projects: [],
    totalProjects: 0,
    totalPages: 0,
    skipPage: 0,
    page: 1,
  };

  componentDidMount(): void {
    this.getCertifierProjects();
  }

  getCertifierProjects = async () => {
    const { skipPage, filterSearch } = this.state;

    const certifierId: string | null = localStorage.getItem('organizationId');

    const params = {
      filter: {
        where: { title: { value: `%${filterSearch}%`, method: 'iLike' } },
        relations: ['donations'],
        skip: skipPage,
        limit: CERTIFIER_PROJECTS_PER_PAGE,
      },
    };

    if (certifierId) {
      let response;
      try {
        response = await getAllConservationActions(certifierId, params);
      } catch (error) {
        console.log(error);
        return;
      }

      const projects = response.data.data;
      const totalProjects = response.data.count;

      const totalPages: number = Math.ceil(totalProjects / CERTIFIER_PROJECTS_PER_PAGE);

      this.setState({ certifierId, projects, totalPages, totalProjects });
    }
  };

  getTableRows = () => {
    const { projects } = this.state;

    const tableRows: ConservationActionTableRow[] = projects.map(project => {
      const { id, title, date, zone, province, country } = project;
      const tableRow: ConservationActionTableRow = copyOf(this.tableRowTemplate);

      const formatedDate = date ? moment(date).format('DD/MM/YYYY') : '-';

      tableRow.name.value = title;
      tableRow.date.value = formatedDate.length > 0 ? `${formatedDate}` : '';
      tableRow.actingZone.value = `${zone} -- ${province}, ${COUNTRIES_KEY_LABEL[country]}`;
      tableRow.divers.value = project.donations.length.toString();
      tableRow.detail.link = `projects/${id}`;
      tableRow.options.actions[0].onClick = () => {
        this.setState({ selectedProject: project, modal: 'create-project' });
      };
      tableRow.options.actions[1].onClick = () => {
        this.setState({ selectedProject: project, modal: 'delete-project' });
      };

      return tableRow;
    });

    return tableRows;
  };

  async handleRemove() {
    const { selectedProject, certifierId } = this.state;

    if (selectedProject && certifierId) {
      await deleteConservationAction(certifierId, selectedProject.id.toString());
      services.pushNotification({
        title: '¡Eliminado!',
        text: 'El proyecto se ha eliminado correctamente',
        type: 'red',
      });
      this.setState({ modal: '', selectedProject: undefined }, () => {
        this.getCertifierProjects();
      });
    }
  }

  setPage = (page: number) => {
    const { totalProjects } = this.state;
    const skip: number = CERTIFIER_PROJECTS_PER_PAGE * (page - 1);

    if (skip < totalProjects) {
      this.setState({ skipPage: skip, page }, () => {
        this.getCertifierProjects();
      });
    }
  };

  searchTimeout: any;
  handleSearch(value: string | null) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState({ filterSearch: value || '', page: 1, skipPage: 0 }, () => this.getCertifierProjects());
    }, 1000);
  }

  render() {
    const { activeFilters, modal, certifierId, totalPages, selectedProject, page, totalProjects } = this.state;

    return (
      <>
        <HeaderMain
          title={'Proyectos de conservación'}
          handleSearch={(value: string | null) => this.handleSearch(value)}
          resultCount={totalProjects}
          mainButton={{
            text: 'Crear',
            onClick: () => this.setState({ modal: 'create-project', selectedProject: undefined }),
          }}
          withSearch={true}
        />

        <MainLayoutSection>
          {activeFilters && (
            <div className={`ads-detail-table-filters${activeFilters ? ' active' : ''}`}>
              <SingleButton icon={images.filtersSvg} className="small secondary" />
              {/* TODO ADD Filters */}
            </div>
          )}

          <div className="ads-details-table__content">
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout
                  columns={this.tableColumns}
                  rows={this.getTableRows()}
                  rowKeys={this.rowKeys}
                  onOrder={() => { }}
                />
              </div>

              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                </div>
              )}
            </MainLayoutTableContainer>
          </div>
        </MainLayoutSection>

        {modal === 'create-project' && certifierId && (
          <ModalCreateProject
            certifierId={certifierId}
            showModal={modal === 'create-project'}
            onCancel={() => this.setState({ selectedProject: undefined, modal: '' })}
            onSuccess={() => {
              if (selectedProject) {
                services.showModalSuccess('¡Editado!', 'El proyecto de conservación ha sido editado con éxito');
              } else services.showModalSuccess('¡Creado!', 'El proyecto de conservación ha sido creado con éxito');

              this.setState({ modal: '', selectedProject: undefined });

              this.getCertifierProjects();
            }}
            selectedLanguage="es"
            project={selectedProject}
          />
        )}

        {modal === 'delete-project' && certifierId && (
          <ModalDelete
            title=""
            subtitle="¿Estas seguro de querer eliminar este proyecto de la lista de Proyectos de conservación?"
            showModal={modal === 'delete-project'}
            onRemove={() => this.handleRemove()}
            onCancel={() => this.setState({ selectedProject: undefined, modal: '' })}
          />
        )}
      </>
    );
  }

  tableColumns = [
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Zona de actuación',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Buceadores Comprometidos',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  rowKeys = ['name', 'date', 'actingZone', 'divers', 'detail', 'options'];

  tableRowTemplate: ConservationActionTableRow = {
    name: {
      key: '',
      value: 'Ivan De Menezes',
    },
    date: {
      key: '',
      value: '21/03/2019',
    },
    actingZone: {
      key: '',
      value: 'Local/Regional - Murcia, España',
    },
    divers: {
      key: '',
      value: '264',
    },
    detail: {
      key: 'detail',
      link: '/projects/',
    },
    options: {
      key: 'options',
      actions: [
        {
          label: 'Editar',
          icon: images.otherSvg,
          onClick: () => { },
        },
        {
          label: 'Eliminar',
          icon: images.delete2Svg,
          onClick: () => { },
        },
      ],
    },
  };
}

export default withTranslation(['intro', 'buttons'])(withRouter(CertifierProject));
