import * as React from 'react';
import { DiveSiteGalleryStyle } from './dive-site-gallery-style';
import HeadDestacadoText from '../../../components/text/head-destacado-text/head-destacado-text';
import CardPhotoCenter from '../../../components/card-photo-center/card-photo-center';
import SelectBox from '../../../components/select-box';
import { RouteComponentProps, withRouter } from 'react-router';
import { getOneDiveSites } from '../../../services/dive-sites';
import { uploadDiveSitesPictures, deleteDiveSitesPictures } from '../../../services/dive-sites-pictures';
import ModalSlider from '../../../components/modals/modal-slider/modal-slider';
import services from '../../../services/services';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import ModalTitlePhoto from '../../../components/modals/modal-title-photo/modal-title-photo';
import { connect } from 'react-redux';

interface RouteParams {
  id: string;
  siteId: string;
}
export interface IDiveSiteGalleryProps extends RouteComponentProps<RouteParams> {
  setSelectedPictures?: Function;
  agent: any;
  clearValue?: boolean;
  canAddFav?: boolean;
}

export interface IDiveSiteGalleryState {
  featuredPictures: any[];
  pictures: any[];
  selectedPictures: any[];
  modal: string;
  pictureSelected: number;
  modalPictures: any[];
  idPicture: string;
  pictureEdit: any;
  siteId: string;
}

class DiveSiteGallery extends React.Component<IDiveSiteGalleryProps, IDiveSiteGalleryState> {
  constructor(props: IDiveSiteGalleryProps) {
    super(props);

    this.state = {
      featuredPictures: [],
      pictures: [],
      selectedPictures: [],
      modalPictures: [],
      modal: '',
      pictureSelected: -1,
      idPicture: '',
      pictureEdit: {},
      siteId: '',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const siteId = match.params.siteId ? match.params.siteId : match.params.id;
    if (siteId) {
      this.setState({ siteId }, () => {
        this.getDiveSite(siteId);
      });
    }
    window.addEventListener('reload-dive-site', this.reloadPicture);
  }

  componentDidUpdate(prevProps: IDiveSiteGalleryProps) {
    const { clearValue } = this.props;
    const { selectedPictures } = this.state;
    if (clearValue && prevProps.clearValue !== clearValue && selectedPictures.length > 0) {
      this.setState({ selectedPictures: [] });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('reload-dive-site', this.reloadPicture);
  }

  reloadPicture = () => {
    const { siteId } = this.state;
    if (siteId) {
      this.getDiveSite(siteId);
    }
  };

  removePictures = () => {
    const { selectedPictures: rawSelectedPictures, pictures, featuredPictures } = this.state;

    const selectedPictures = rawSelectedPictures.map((pic: any) => pic.id);
    const filterPictures = pictures.filter(element => !selectedPictures.find((pic: any) => element.id === pic.id));
    const filterFeaturedPictures = featuredPictures.filter(
      element => !selectedPictures.find((pic: any) => element.id === pic.id)
    );

    this.setState({
      pictures: filterPictures,
      featuredPictures: filterFeaturedPictures,
      selectedPictures: [],
    });
  };

  getDiveSite = async (id: string, showSlider?: boolean) => {
    try {
      const { modalPictures } = this.state;
      const params = {
        filter: {
          relations: ['pictures', 'pictures.likes'],
        },
      };

      const response: any = await getOneDiveSites(id, params);
      const featuredPictures: any[] = response.data.pictures.filter((p: any) => p.featured);
      const pictures: any[] = response.data.pictures.filter((p: any) => !p.featured);
      let modal = '';
      if (showSlider && modalPictures.length > 0) {
        modal = 'modal-slider';
      }
      this.setState({ featuredPictures, pictures, modal, pictureSelected: 0 });
    } catch (err) { }
  };

  handleSelected = (checked: boolean, id: string, picture: any) => {
    const { selectedPictures, featuredPictures } = this.state;
    const { setSelectedPictures } = this.props;

    if (!checked) {
      const filter = selectedPictures.filter((p: any) => p.id !== id);
      this.setState({ selectedPictures: filter });
      if (setSelectedPictures) {
        setSelectedPictures(filter);
      }
    } else {
      const newSelectedPictures = selectedPictures.concat([{ ...picture }]);
      this.setState({ selectedPictures: newSelectedPictures });
      if (setSelectedPictures) {
        setSelectedPictures(newSelectedPictures);
      }
    }
  };

  handleFav = async (fav: boolean, id: string) => {
    const data = new FormData();
    data.set('featured', fav.toString());
    const { featuredPictures, pictures } = this.state;

    try {
      await uploadDiveSitesPictures(id, data);
      if (fav) {
        const index = pictures.findIndex(p => p.id === id);
        pictures[index].featured = !pictures[index].featured;
        featuredPictures.push(pictures[index]);
        pictures.splice(index, 1);
      } else {
        const index = featuredPictures.findIndex(p => p.id === id);
        featuredPictures[index].featured = !featuredPictures[index].featured;
        pictures.push(featuredPictures[index]);
        featuredPictures.splice(index, 1);
      }
      this.setState({ featuredPictures, pictures });
    } catch (err) { }
  };

  deletePictureDiveSite = async () => {
    const { siteId, idPicture } = this.state;
    try {
      await deleteDiveSitesPictures(idPicture);
      services.pushNotification({
        title: 'Fotografía eliminada',
        text: 'Se ha eliminado la fotografía con éxito.',
        type: 'red',
      });
      const { modalPictures } = this.state;
      const index = modalPictures.findIndex(p => p.id.toString() === idPicture.toString());
      if (index !== -1) {
        modalPictures.splice(index, 1);
      }
      this.setState({ modalPictures }, () => {
        if (siteId) {
          this.getDiveSite(siteId, true);
        }
      });
    } catch (err) { }
  };

  editPictureDiveSite = async (data: any) => {
    const { siteId, idPicture } = this.state;

    try {
      const formData = new FormData();
      formData.set('description', JSON.stringify(data.description));
      await uploadDiveSitesPictures(idPicture, formData);
      services.pushNotification({
        title: 'Fotografía editada',
        text: 'Se ha editado la fotografía con éxito.',
        type: 'green',
      });
      const { modalPictures } = this.state;
      const index = modalPictures.findIndex(p => p.id.toString() === idPicture.toString());
      if (index !== -1) {
        modalPictures[index].description = Object.assign({}, data.description);
      }
      this.setState({ modalPictures }, () => {
        if (siteId) {
          this.getDiveSite(siteId, true);
        }
      });
    } catch (err) { }
  };

  render() {
    const { agent, canAddFav } = this.props;
    const {
      featuredPictures,
      pictures,
      modal,
      pictureSelected,
      modalPictures,
      idPicture,
      pictureEdit,
      selectedPictures,
    } = this.state;
    return (
      <DiveSiteGalleryStyle>
        {featuredPictures.length > 0 && (
          <div className="dive-site-gallery-section">
            <div className="dive-site-gallery-section__title">
              <div className="dive-site-gallery-section__title__text">
                <HeadDestacadoText>Destacadas</HeadDestacadoText>
              </div>
              {/* TODO: SUPERADMIN NO ESTÄ
             <div className="dive-site-gallery-section__title__buttons">
              <SingleButton className="secondary" icon={images.alertSvg} onClick={() => {}} />
            </div> */}
            </div>
            <div className="dive-site-gallery-section__grid">
              {featuredPictures.map((fp: any, index: number) => (
                <CardPhotoCenter
                  key={`featured-${fp.id}`}
                  isFav={true}
                  picture={fp}
                  selected={!!selectedPictures.find((sp: any) => sp.id === fp.id)}
                  canAddFav={canAddFav}
                  handleFav={(fav: boolean, id: string) => this.handleFav(fav, id)}
                  handleSelected={
                    agent.roles.includes('superAdmin')
                      ? (checked: boolean, id: string) => this.handleSelected(checked, id, featuredPictures[index])
                      : undefined
                  }
                  onClick={() =>
                    this.setState({
                      modal: 'modal-slider',
                      pictureSelected: index,
                      modalPictures: featuredPictures,
                    })
                  }
                />
              ))}
            </div>
          </div>
        )}
        <div className="dive-site-gallery-section">
          <div className="dive-site-gallery-section__title">
            <div className="dive-site-gallery-section__title__text">
              <HeadDestacadoText>Galería</HeadDestacadoText>
            </div>
            {/* TODO: NO ENTIENDO ESTE SELECT
            <div className="dive-site-gallery-section__title__buttons">
              <SelectBox
                labelText="país"
                placeholder="Seleccione certificadora"
                required={true}
                className="rounded-filter"
                // errorCode={"error"}
                optionsText={[]}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={''}
                onChange={() => {}}
                icon={images.arrowDown2BlueSvg}
                // onChange={(value) => {
                //     handleDataChange("insured", "doc_type", value);
                // }}
              />
            </div>
            */}
          </div>
          <div className="dive-site-gallery-section__grid">
            {pictures.map((fp: any, index: number) => (
              <CardPhotoCenter
                key={`picture-${fp.id}`}
                isFav={false}
                picture={fp}
                canAddFav={canAddFav}
                handleFav={(fav: boolean, id: string) => this.handleFav(fav, id)}
                selected={!!selectedPictures.find((sp: any) => sp.id === fp.id)}
                // handleSelected={
                //   agent.roles.includes('superAdmin')
                //     ? (checked: boolean, id: string) => this.handleSelected(checked, id, pictures[index])
                //     : undefined
                // }
                handleSelected={(checked: boolean, id: string) => this.handleSelected(checked, id, pictures[index])}
                onClick={() =>
                  this.setState({
                    modal: 'modal-slider',
                    pictureSelected: index,
                    modalPictures: pictures,
                  })
                }
              />
            ))}
          </div>
        </div>
        {modal === 'modal-slider' && (
          <ModalSlider
            onClose={() => this.setState({ modal: '' })}
            showModal={modal === 'modal-slider'}
            pictures={modalPictures}
            pictureSelected={pictureSelected}
            handleFav={(fav: boolean, id: string) => this.handleFav(fav, id)}
            handleDelete={(id: string) => this.setState({ idPicture: id, modal: 'modal-delete-picture' })}
            handleEdit={(id: string, picture: any) =>
              this.setState({ idPicture: id, modal: 'modal-edit-picture', pictureEdit: picture })
            }
            agent={agent}
            canAddFav={canAddFav}
          />
        )}

        {modal === 'modal-delete-picture' && (
          <ModalDelete
            title={'Vas a eliminar la imagen'}
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete-picture'}
            onRemove={() => {
              this.deletePictureDiveSite();
            }}
            onCancel={() => this.setState({ modal: 'modal-slider' })}
          />
        )}
        {modal === 'modal-edit-picture' && (
          <ModalTitlePhoto
            idPicture={idPicture}
            picture={pictureEdit}
            showModal={modal === 'modal-edit-picture'}
            onAccept={(data: any) => {
              this.editPictureDiveSite(data);
            }}
            onCancel={() => this.setState({ modal: 'modal-slider' })}
          />
        )}
      </DiveSiteGalleryStyle>
    );
  }
}

function mapStateToProps(
  state = {
    agent: { value: {} },
  }
) {
  return {
    agent: state.agent.value,
  };
}

export default withRouter(connect(mapStateToProps)(DiveSiteGallery));
