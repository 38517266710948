import { combineReducers } from 'redux';
import {
  LOADING,
  EXPIRED,
  AGENT,
  NOTIFICATION,
  SUCCESS,
  VALID_CENTRE,
  STAFF_PERMISSIONS,
  CENTRE_CURRENCY,
  CENTRE_STRIPE_USER_ID,
  USERDATA,
  BOOKING_LINES,
  STRIPE_TOKEN_ID, IS_DIVER_SELECTED, BOOKING_PAYMENT_DATA, START_ACTIVITY_DATE, ACCOUNT_DIVERS, ACCOUNT_DIVERS_GHOST,
} from '../actions/actions';

export const loading = (state: any = { value: false }, action: any) => {
  switch (action.type) {
    case LOADING:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const expiredToken = (state: any = { value: false }, action: any) => {
  switch (action.type) {
    case EXPIRED:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const agent = (state: any = { value: {} }, action: any) => {
  switch (action.type) {
    case AGENT:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const notifications = (state: any = { notifications: [] }, action: any) => {
  switch (action.type) {
    case NOTIFICATION:
      return Object.assign({}, state, { notifications: action.notifications });
    default:
      return state;
  }
};

export const modalSuccess = (state: any = { value: {} }, action: any) => {
  switch (action.type) {
    case SUCCESS:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const isValidCentre = (state: any = { value: {} }, action: any) => {
  switch (action.type) {
    case VALID_CENTRE:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const staffPermissions = (state: any = { value: {} }, action: any) => {
  switch (action.type) {
    case STAFF_PERMISSIONS:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const centreCurrency = (state: any = { value: {} }, action: any) => {
  switch (action.type) {
    case CENTRE_CURRENCY:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const centreStripeUserId = (state: any = { value: null }, action: any) => {
  switch (action.type) {
    case CENTRE_STRIPE_USER_ID:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const userData = (state: any = { value: null }, action: any) => {
  switch (action.type) {
    case USERDATA:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const bookingLines = (state: any = { value: null }, action: any) => {
  switch (action.type) {
    case BOOKING_LINES:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const stripeTokenId = (state: any = { value: null }, action: any) => {
  switch (action.type) {
    case STRIPE_TOKEN_ID:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const isDiverSelected = (state: any = { value: null }, action: any) => {
  switch (action.type) {
    case IS_DIVER_SELECTED:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const bookingPaymentData = (state: any = { value: null }, action: any) => {
  switch (action.type) {
    case BOOKING_PAYMENT_DATA:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const startActivityDate = (state: any = { value: null }, action: any) => {
  switch (action.type) {
    case START_ACTIVITY_DATE:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const accountDivers = (state: any = { value: null }, action: any) => {
  switch (action.type) {
    case ACCOUNT_DIVERS:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const accountDiversGhost = (state: any = { value: null }, action: any) => {
  switch (action.type) {
    case ACCOUNT_DIVERS_GHOST:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  expiredToken,
  agent,
  notifications,
  modalSuccess,
  isValidCentre,
  staffPermissions,
  centreCurrency,
  centreStripeUserId,
  userData,
  bookingLines,
  stripeTokenId,
  isDiverSelected,
  bookingPaymentData,
  startActivityDate,
  accountDivers,
  accountDiversGhost
});
