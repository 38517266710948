// tslint:disable
import React, { Component } from 'react';
import * as dateFns from 'date-fns';
import es from 'date-fns/locale/es';
import { CalendarItemContainer } from './calendar-item-planning-style';
import moment from 'moment';
import 'moment/locale/es.js';
import { isValidDate } from '../../utils/validation';
import { capitalizeFirstLetter } from '../../utils/other';
import Label02Text from '../text/label-02-text/label-02';

interface CalendarItemPlanningProps {
  initialValue: string;
  forbidFutureDates?: boolean;
  rangeSelect?: boolean;
  minAge?: number;
  maxAge?: number;
  className?: string;
  multiSelect?: boolean;
  onClickDay: (day: string) => void;
  onChangeMulti?: (values: string[]) => void;
  eventPoints?: {
    [s: string]: {
      pending?: boolean;
      confirmed?: boolean;
      done?: boolean;
      finished?: boolean;
      resolved?: boolean;
      urgent?: boolean;
    };
  };
}

class CalendarItemPlanning extends Component<CalendarItemPlanningProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showYears: false,
      showMonths: false,
      rangeSelectStep: 0,
      currentDate: new Date(),
      secondDate: props.rangeSelect ? new Date() : null,
      multiValues: [],
      act: 0
    };
  }

  componentDidMount() {
    const { initialValue } = this.props;

    this.setState({ currentDate: new Date(initialValue), act: 0 });
  }

  componentDidUpdate() {
    const { currentDate } = this.state;
    const { initialValue, rangeSelect } = this.props;

    if (!rangeSelect && initialValue && isValidDate(initialValue, '/')) {
      const initialDate = moment(initialValue, 'DD/MM/YYYY').toDate();
      if (initialDate.getTime() !== currentDate.getTime()) {
        if (rangeSelect) {
          this.setState({ currentDate: initialDate, secondDate: initialDate });
        } else {
          this.setState({ currentDate: initialDate });
        }
      }
    }
  }

  isAllowSelect(date: Date) {
    const { forbidFutureDates, minAge } = this.props;
    if (minAge && dateFns.differenceInYears(new Date(), date) < minAge) {
      return false;
    }
    if (forbidFutureDates && dateFns.isFuture(date)) {
      return false;
    }
    return true;
  }

  renderHeader() {
    const dateFormatMonth = 'MMMM';
    const dateFormatYear = 'yyyy';

    return (
      <div className="calendar-header">
        {/* Current Month */}
        <div className="calendar-header__date">
          <div className="current-month">
            <p onClick={() => this.setState({ showMonths: true })}>
              {capitalizeFirstLetter(dateFns.format(this.state.currentDate, dateFormatMonth, { locale: es }))}
            </p>
          </div>
          {/* Current Year */}
          <div className="current-year">
            <p onClick={() => this.setState({ showYears: true })}>
              {capitalizeFirstLetter(dateFns.format(this.state.currentDate, dateFormatYear, { locale: es }))}
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderDaysHeader() {
    const dateFormat = 'dd';
    const days: any[] = [];
    // "L","M","X","J","V","S","D"
    const startDate = dateFns.startOfWeek(this.state.currentDate, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar-days-day" key={i}>
          <div>
            <p>{dateFns.format(dateFns.addDays(startDate, i), dateFormat, { locale: es })}</p>
          </div>
        </div>
      );
    }
    return <div className="calendar-days">{days}</div>;
  }

  renderCells() {
    const { multiSelect, onClickDay, eventPoints } = this.props;

    const { currentDate, multiValues } = this.state;
    const monthStart = dateFns.startOfMonth(currentDate);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = dateFns.endOfWeek(monthEnd, { weekStartsOn: 1 });
    const dateFormat = 'dd';
    const rows: any[] = [];
    let days: any[] = [];
    let day = startDate;
    let formattedDate = '';
    let multiSelected = false;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);


        const cloneDay = day;
        if (multiSelect) {
          multiSelected = false;
          multiValues.forEach((multiValue: string) => {
            if (!multiSelected) {
              multiSelected = dateFns.isSameDay(day, new Date(multiValue));
            }
          });
        }
        const formattedAuxDate = dateFns.format(day, 'dd-MM-yyyy');

        const isMonthDay =
          (dateFns.isBefore(monthStart, cloneDay) && dateFns.isAfter(monthEnd, cloneDay)) ||
          dateFns.isSameDay(monthStart, cloneDay);
        days.push(
          <div
            className={`col cell`}
            key={dateFns.format(day, 'dd-MM-yyyy')}
            onClick={() => onClickDay(cloneDay.toISOString())}
          >
            {/* Days */}
            <div className={`cell-container ${isMonthDay ? '' : 'disabled'}`}>
              <div>
                <Label02Text>{formattedDate}</Label02Text>
              </div>
              <div className="cell-points">
                {eventPoints && eventPoints[formattedAuxDate] && (
                  <>
                    {eventPoints[formattedAuxDate].pending && <div className="points-pending" />}
                    {eventPoints[formattedAuxDate].confirmed && <div className="points-confirmed" />}
                    {eventPoints[formattedAuxDate].done && <div className="points-done" />}
                    {eventPoints[formattedAuxDate].finished && <div className="points-finished" />}
                    {eventPoints[formattedAuxDate].resolved && <div className="points-resolved" />}
                    {eventPoints[formattedAuxDate].urgent && <div className="points-urgent" />}
                    {JSON.stringify(eventPoints[formattedAuxDate]) === '{}' && <div className="points-no-reservations" />}
                  </>
                )}
              </div>
            </div>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(

        <div className="row" key={dateFns.format(day, 'dd-MM-yyyy')}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  render() {
    const { rangeSelect, className } = this.props;
    return (
      <CalendarItemContainer className={`${rangeSelect ? 'range' : ''} ${className}`}>
        <div className="calendar">
          {this.renderHeader()}
          {this.renderDaysHeader()}
          {this.renderCells()}
        </div>
      </CalendarItemContainer>
    );
  }
}

export default CalendarItemPlanning;
