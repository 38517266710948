import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CardCheckContainer = styled.div`
  padding: 29px 23px 24px 29px;
  border: 1px solid ${colors['NE-001/100']};
  color: ${colors['NE-001/100']};
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  .card-check-box {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: white;
    border: 1px solid ${colors['NE-001/100']};
    margin-bottom: 24px;
    img {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
  }

  .card-check-title {
    margin-bottom: 10px;
  }
  .card-check-subtitle {
  }

  &.active {
    border: 1px solid ${colors['NE-001/100']};
    background: ${colors['NE-001/100']};
    color: white;

    .card-check-box {
      img {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.light-blue {
    border: 0px solid ${colors['NE-001/100']};
    background: ${colors['NE-002/10']};
    color: ${colors['SE-002/100']};
    .card-check-box  {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      border: 0px;
    }
    &.active {
      border: 0px solid ${colors['NE-001/100']};
      background: ${colors['SE-002/100']};
      color: white;
    }
  }

  &.dark-blue {
    border: 0px solid ${colors['NE-001/100']};
    background: ${colors['NE-002/10']};
    color: ${colors['SE-004/100']};
    .card-check-box  {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      border: 0px;
    }
    &.active {
      border: 0px solid ${colors['NE-001/100']};
      background: ${colors['SE-004/100']};
      color: white;
    }
  }
`;
