import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const PlanningStaffStyle = styled.div`
  width: 780px;
  padding: 32px;
  padding-top: 48px;

  .planning-staff-disclaimer {
    color: ${colors['NE-002/100']};
    margin-bottom: 28px;
  }
  .planning-staff-select {
    margin-bottom: 40px;
    max-width: 80%;
  }

  .planning-staff-subtitle {
    margin-bottom: 14px;
  }
  .planning-staff-check {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 50px;
      }
    }
  }
`;
