import * as React from 'react';
import { Switch, Route } from 'react-router';
import MyCenterEquipmentInventary from './my-center-equipment-inventary/my-center-equipment-inventary';
import MyCenterEquipmentRent from './my-center-equipment-rent/my-center-equipment-rent';
import MyCenterEquipmentShop from './my-center-equipment-shop/my-center-equipment-shop';

export interface IMyCenterEquipmentProps {}

export interface IMyCenterEquipmentState {}

export default class MyCenterEquipment extends React.Component<IMyCenterEquipmentProps, IMyCenterEquipmentState> {
  constructor(props: IMyCenterEquipmentProps) {
    super(props);
  }

  render() {
    return (
      <>
        <Switch>
          <Route path="/centres/:id/business/equipment/inventary" component={MyCenterEquipmentInventary} />
          <Route path="/centres/:id/business/equipment/rent" component={MyCenterEquipmentRent} />
          <Route path="/centres/:id/business/equipment/shop" component={MyCenterEquipmentShop} />
        </Switch>
      </>
    );
  }
}
