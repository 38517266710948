import styled from 'styled-components';

export const MainTableContainerV2 = styled.div`
  .table {
    max-height: calc(100vh - 360px);
    min-height: 340px;
    font-family: 'plain';

    .tr {
      min-width: 100%;
      min-height: 60px;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      overflow: hidden;
      min-width: 56px;

      &:last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .td {
      padding: 8px 16px;
      background: white;
      min-height: 56px;
      font-size: 14px;
      color: #000019;
      font-weight: 300;

      &.bold-text {
        font-weight: 500;
      }

      &.sticky-actions {
        overflow: visible;
        box-shadow: -8px 1px 18px 0px rgba(0, 0, 0, 0.08);
      }

      &.actions {
        overflow: visible;
      }
    }

    .th {
      padding: 12px 16px;
      background: #f8fafd;
      color: #000019;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      &.sticky-actions {
        box-shadow: -8px 1px 18px 0px rgba(0, 0, 0, 0.08);
      }
    }

    &.sticky {
      overflow: auto;
      .header {
        position: sticky;
        top: 0;
        z-index: 1;
        width: fit-content;
        color: #004f8b;
        min-width: 100%;
        background: #f1f1f1;
        .th {
          display: flex;
          justify-content: space-between;
        }
      }

      .body {
        position: relative;
        z-index: 0;
        color: #3372a2;
        background: white;
        overflow: initial;
        .tr {
          border-bottom: 1px solid #f1f1f1;
        }
      }

      [data-sticky-last-left-td] {
        box-shadow: '4px 0px 6px rgba(0, 0, 0, 0.04)';
      }

      [data-sticky-first-right-td] {
        box-shadow: '4px 0px 6px rgba(0, 0, 0, 0.04)';
      }
    }
  }
  .is-sticky {
    box-shadow: '4px 0px 6px rgba(0, 0, 0, 0.04)';
    border: 1.1px solid #f1f1f1;
    border-top: 0;
    border-bottom: 0;
  }
  .is-sticky-right {
    transition: 0.3s ease-in-out;
    box-shadow: -4px 0px 6px rgba(0, 0, 0, 0);
  }
  .is-sticky-left {
    transition: 0.3s ease-in-out;
    box-shadow: 4px 0px 6px rgba(0, 0, 0, 0);
  }

  &.overflow {
    .is-sticky-right {
      box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.04);
    }
    .is-sticky-left {
      box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.04);
    }
  }

  &.is-check-sticky {
    .tr {
      .th {
        width: 100%;
        :first-child {
          position: sticky;
          max-width: 62px;
          min-width: 62px;
          left: 0;
        }
      }
      .td {
        width: 100%;
        :first-child {
          position: sticky;
          max-width: 62px;
          min-width: 62px;
          min-height: 56px;
          left: 0;
        }
      }
    }
  }
`;
