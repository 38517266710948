import styled from 'styled-components';

export const ModalNewChatStyle = styled.div`
  padding: 0px 32px 32px 32px;
  width: 416px;
  .modal-new-chat-section {
    margin-top: 28px;
    margin-bottom: 8px;
  }
`;
