import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalRegistreDiveStyle = styled.div`
  padding: 58px 32px 32px 32px;
  width: 416px;

  .modal-create-title {
    margin-bottom: 40px;
    &__step {
      color: ${colors['SE-002/100']};
    }
  }
  .modal-create-form {
    margin-bottom: 40px;

    &__title  {
      color: ${colors['SE-002/100']};
      margin-bottom: 14px;
      text-transform: uppercase;
    }
    &__content {
      margin-bottom: 40px;
    }
  }

  .modal-create-form-list {
    &__item {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .modal-create-buttons {
    display: flex;

    &__item {
      width: 100%;
    }
    &__back {
      width: 60px;
      margin-right: 12px;
    }
  }
`;
