import * as React from 'react';
import { DiverActivitiesLayoutStyle } from './diver-activities-style';
import MainTableLayout from '../../../../components/main-table/main-table';
import { MainLayoutTableContainer } from '../../../main-layout-style';
import { RouteComponentProps, match } from 'react-router';
import Pagination from '../../../../components/pagination';
import { getBookings, getBookingsByCentre, getBookingsTableByCentre } from '../../../../services/diver';
import { copyOf, calculateRating } from '../../../../utils/other';
import moment from 'moment';
import { getCentreStaffBookings } from '../../../../services/centre';

const DIVERS_ACTIVITIES_PER_PAGE = 10;

export interface IDiverActivitiesLayoutProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
    staff: string;
  }>;
  isCentre: boolean;
  isOrganization: boolean;
}

interface IDiverActivitiesLayoutState {
  bookings: any[];
  skipPage: number;
  totalItems: number;
  totalPages: number;
  orderAscendent: boolean;
  page: number;
}

export default class DiverActivitiesLayout extends React.Component<
  IDiverActivitiesLayoutProps,
  IDiverActivitiesLayoutState
> {
  constructor(props: IDiverActivitiesLayoutProps) {
    super(props);

    this.state = {
      bookings: [],
      totalItems: 0,
      totalPages: 0,
      skipPage: 0,
      orderAscendent: true,
      page: 1
    };
  }

  componentDidMount() {
    this.getDiverActivities();
  }

  async getDiverActivities() {
    const {
      match: {
        params: { id, diver, staff },
      },
    } = this.props;

    const { skipPage } = this.state;
    let params: any;
    if (staff) {
      params = {
        filter: {
          relations: ['activity', 'activity.centre', /*'activity.ratings',*/ 'activity.tickets'],
        },
        skip: skipPage,
        limit: DIVERS_ACTIVITIES_PER_PAGE,
      };
    } else {
      params = {
        filter: {
          relations: ['activity', 'activity.centre', /*'activity.ratings',*/ 'activity.tickets', 'bookings'],
        },
        skip: skipPage,
        limit: DIVERS_ACTIVITIES_PER_PAGE,
      };
    }
    const diverId = diver ? diver : id;
    //const bookings = staff
    //  ? ((await getCentreStaffBookings(id, staff, params)).data.data).filter((booking: any) => booking.activity.centre.id == id)
    //  : (await getBookingsTableByCentre(Number(diverId), Number(id), this.state.page, DIVERS_ACTIVITIES_PER_PAGE));
    //: (await getBookings(diverId, params)).data.data;*/
    //const bookings = await getBookingsTable(Number(diverId) , this.state.page, DIVERS_ACTIVITIES_PER_PAGE).data.data;
    //.filter((booking: any) => booking.activity.centre.id == id)
    let totalPagesList = 1
    if (staff) {
      const bookingsTot = await getCentreStaffBookings(id, staff, params)
      const bookings = bookingsTot.data.data
      if (bookings) {
        totalPagesList = Math.floor(bookingsTot.data.count / DIVERS_ACTIVITIES_PER_PAGE);
      }
      if (bookings) {
        const listBookigns = bookings
        this.setState({ bookings: listBookigns, totalItems: bookingsTot.data.count, totalPages: totalPagesList });
      }
    }
    else {

      const bookings = await getBookingsTableByCentre(Number(diverId), Number(id), this.state.page, DIVERS_ACTIVITIES_PER_PAGE)

      totalPagesList = Math.floor(bookings.data.count / DIVERS_ACTIVITIES_PER_PAGE);

      const listBookigns = bookings.data.data.filter((booking: any) => booking.activity.centre.id == id)
      this.setState({ bookings: listBookigns, totalItems: bookings.data.count, totalPages: totalPagesList });
    }

  }



  isActivityDone(activityDate: any) {
    const actualDate = new Date();
    const bookingDate = new Date(activityDate);
    return actualDate > bookingDate;
  }



  getStatus(booking: any) {
    //Solicitudes
    if (booking.cancelled == 'noCancelled' && !booking.confirmed) {
      if (booking.rejected) return 'Rechazada';
      return 'Pendiente';

      //Confirmadas
    } else if (booking.cancelled == 'noCancelled' && booking.confirmed && !this.isActivityDone(booking.activityDate)) {
      if (booking.activityCancelled) return 'Pend. Cambio';
      //Pagos
      if (booking.payment && booking.payment.paymentType) {
        if (booking.payment.paymentType.name === 'Stripe') {
          if (booking.payment.paymentResponse) {
            if (booking.payment.paymentCorrect) {
              return 'Cobro online';
            } else {
              return 'Error en el cobro';
            }
          } else {
            return 'Pend. Cobro';
          }
        } else {
          return 'Cobro en CTB'
        }
      }

      //Historial
    } else if (!booking.isCancelled && booking.confirmed && this.isActivityDone(booking.activityDate)) {
      if (booking.presented) return 'Resuelta';
      return 'NP';

      //Cancelaciones
    } else if (booking.isCancelled) {
      if (booking.cancelled === 'canceledByUser') return 'CXL Buc';
      return 'CXL Centro';
    }
  }




  getTableRows(): any[] {

    const {
      match: {
        params: { id, diver },
      },
    } = this.props;
    const { bookings } = this.state;
    const tableRows: any[] = [];
    bookings.forEach((booking: any) => {
      const activity = booking.activity;
      if (activity) {
        const tableRow = copyOf(tableRowTemplate);
        tableRow.activity.value = activity.courseInfo ? 'Formación' : 'Inmersión';
        tableRow.name.value = activity.name;
        tableRow.center.value = activity.centre ? activity.centre.name : '';
        tableRow.price.value = `${activity.price / 100} €`;
        tableRow.date.value = moment(activity.date).format('DD/MM/YYYY');
        tableRow.reservation.value = booking.bookingId;
        //tableRow.bonus.value = activity.tickets.length > 0 ? activity.tickets[0].name : '-';
        /*tableRow.count.value = {
          title: 'Bonos aplicados',
          items: activity.tickets,
        };*/
        tableRow.status.value = this.getStatus(booking);
        tableRow.detail.link = diver   ///centres/94/billing/bookings/344
          ? `/centres/${id}/billing/bookings/${booking.id}`
          : `/centres/${id}/billing/bookings/${booking.id}`;
        tableRows.push(tableRow);

      }
    });
    return tableRows;
  }
  setPage(page: number) {
    const { totalItems } = this.state;
    const skip: number = DIVERS_ACTIVITIES_PER_PAGE * (page - 1);
    if (skip < totalItems) {
      this.setState({ page, skipPage: skip }, () => {
        this.getDiverActivities();
      });
    }
  }

  render() {
    const { totalPages, page } = this.state;

    return (
      <DiverActivitiesLayoutStyle>
        <MainLayoutTableContainer>
          <div className="diving-center-table">
            <MainTableLayout columns={tableColumns} rows={this.getTableRows()} rowKeys={rowKeys} onOrder={() => { }} />
          </div>
          {totalPages > 1 && (
            <div className="diving-center-pagination">
              <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
            </div>
          )}
        </MainLayoutTableContainer>
      </DiverActivitiesLayoutStyle>
    );
  }
}

const rowKeys = ['activity', 'name', 'center', 'price', 'date', 'reservation', 'count', 'status', 'detail'];
const tableRowTemplate = {
  activity: {
    key: 'highlighted-body',
    value: 'Bono especial iniciación ',
  },
  name: {
    key: '',
    value: 'Buceo recreativo',
  },
  center: {
    key: '',
    value: 'Pasillo 3, estante 7',
  },
  price: {
    key: '',
    value: '12 €',
  },
  date: {
    key: '',
    value: '09/12/2020',
  },
  reservation: {
    key: '',
    value: 'RE00000',
  },/*
  bonus: {
    key: '',
    value: 'Si',
  },*/
  count: {
    key: 'count',
  },
  status: {
    key: '',
    value: 'Confirmada',
  },
  detail: {
    key: 'detail',
    value: '',
  },
};
const tableColumns = [
  {
    text: 'Tipo de actividad',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Nombre actividad',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Centro',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Precio',
    filter: false,
    ascendent: false,
  },
  {
    text: 'Fecha',
    filter: false,
    ascendent: true,
  },
  {
    text: 'ID Reserva',
    filter: false,
    ascendent: true,
  },/*
  {
    text: 'Bono Aplicado',
    filter: false,
    ascendent: true,
  },*/
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Estado',
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
];
