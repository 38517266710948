import styled from 'styled-components';
import { colors } from '../../assets/colors';
export const NoContentContainer = styled.div`
  margin: 30px;
  text-align: center;
`;

export const MainTableWrapper = styled.div`
  /* overflow-x: scroll;
  overflow-y: hidden; */
`;
export const MainTableContainer = styled.table`
  width: 100%;
  thead {
    tr {
      th  {
        background: #f8fafd;
        padding: 20px 16px;
        text-align: left;
        text-transform: uppercase;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 14px 16px;
        text-align: left;
        vertical-align: middle;
        p {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 200px;
}
        }
      }
    }
  }

  .table-check{
    display: flex;
    &__count{
      font-size: 12px;
      margin-left: 3px;
    }
  }

  .table-icon {
    width: 32px;
    height: 32px;
  }
  .table-avatar {
    border-radius: 100%;
    overflow: hidden;
    width: 36px;
    height: 36px;
    &.ghost {
      background: #1948a0;
    }
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .table-icon-number  {
    height: 32px;
    background: #f8fafd;
    border-radius: 3px;
    padding: 9px 4px;
    font-size: 10px;
    line-height: 14px;
    color: ${colors['NE-001/100']};
    display: flex;
    justify-content: center;
  }
  .table {
    .single-button-icon {
      cursor: initial;
    }
  } 
`;

export const TableFiltersStyle = styled.div`
  .table-filter-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    &__buttons {
      display: flex;
      &__item  {
        margin-left: 12px;
      }
    }
  }
`;
