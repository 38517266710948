import React, { Component } from 'react';
import DiverProfileLayout from './diver-profile-layout';
import { RouteComponentProps, match } from 'react-router';
import services from '../../../../services/services';
import { DiverProfile } from './types';
import { HeaderButtons } from '../diver-detail';
import { deleteDiver, getDocuments } from '../../../../services/diver';
import { getDivers } from '../../../../services/diver';

interface DiverProfileFuncionalProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
    staff: string;
  }>;
  isCentre: boolean;
  isOrganization: boolean;
}

interface DiverProfileFuncionalState {
  person: DiverProfile;
  highestLevelDocument?: any;
  renderModal: string;
}

class DiverProfileFuncional extends Component<DiverProfileFuncionalProps, DiverProfileFuncionalState>
  implements HeaderButtons {
  state: DiverProfileFuncionalState = {
    renderModal: '',
    person: {
      id: NaN,
      userId: NaN,
      name: '',
      surname: '',
      picture: '',
      nif: '',
      birthday: '',
      gender: '',
      phoneNumber: '',
      email: '',
      address: '',
      town: '',
      state: '',
      postalCode: '',
      country: '',
      phone: {
        prefix: '',
        number: '',
      },
      facebook: '',
      instagram: '',
      languages: [],
      specialities: [],
      diveCount: 0,
      lastDiveDate: '',
      siteCount: 0,
      isStaff: false,
      divesqty: 0
    },
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const {
      match: {
        params: { diver },
      },
    } = this.props;
    this.getDiverProfile();
    this.getDiverDocuments();
    this.getDiveData();
    this.getSiteCount();
    this.getUserDiveDate();
    // diver && this.getStaffData();
  }

  getDiverProfile() {
    const {
      match: {
        params: { id, diver, staff },
      },
    } = this.props;

    const endpoint = staff ? `centres/${id}/staff/${staff}` : diver ? `centres/${id}/divers/${diver}` : `divers/${id}`;

    const params = {
      filter: {
        relations: diver ? ['user', 'size'] : ['user', 'size', 'staff_centres'],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        const {
          id,
          nif,
          birthday,
          phone,
          phone: phoneNumber,
          address,
          town,
          state,
          postalCode,
          country,
          emergencyContact,
          facebook,
          instagram,
          size,
          specialities,
          dives,
          aditionalTraining,
          rating,
          languages,
          user,
          isStaff,
          isInstructor,
          staff_centres,
          centres,
          divesqty,
          user: { name, surname, picture, email, gender },
        } = res;

        const person: DiverProfile = {
          id,
          name,
          surname,
          picture,
          nif,
          birthday,
          gender,
          email,
          address,
          languages,
          town,
          state,
          postalCode,
          country,
          rating,
          aditionalTraining,
          emergencyContact,
          
          facebook,
          instagram,
          isStaff,
          isInstructor,
          staff_centres,

          size,
          phone,
          specialities,
          divesqty,
          userId: user.id,
          centres: centres && centres.flat(),
          diveCount: 0,
          lastDiveDate: '',
          siteCount: 0,
          phoneNumber:
            typeof phoneNumber === 'object' && phoneNumber
              ? `${phoneNumber.prefix} ${phoneNumber.number}`
              : phoneNumber,
        };
        this.setState({ person });
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  async getDiverDocuments() {
    const {
      match: {
        params: { id, diver },
      },
    } = this.props;
    const params = {
      filter: {
        where: {
          id: { method: 'is not', value: 'null', collection: 'course' },
          validated: { method: 'is not', value: 'null' },
        },
        relations: ['course', 'course.level'],
      },
    };
    const documents = (await getDocuments(id, params)).data.data;
    let highestLevelDocument: any;
    documents.forEach((document: any) => {
      if (!highestLevelDocument || highestLevelDocument.course.level < document.course.level) {
        highestLevelDocument = document;
      }
    });
    this.setState({ highestLevelDocument });
  }

  async getSiteCount() {
    const {
      match: {
        params: { id, diver, staff },
      },
    } = this.props;

    let result = { siteCount: 0 };

    const endpoint = staff
      ? `centres/${id}/staff/${staff}/site-count`
      : diver
        ? `centres/${id}/divers/${diver}/site-count`
        : `divers/${id}/site-count`;

    await services.get({
      endpoint,
      loader: true,
      then: ({ data: res }: any) => {
        if (res) result = { siteCount: res };
      },
      catch: (err: any) => { },
    });
    
    const person = { ...this.state.person, ...result };
    this.setState({ person });
  }

  async getDiveData() {
    const {
      match: {
        params: { id, diver, staff },
      },
    } = this.props;

    let result = { diveCount: 0, lastDiveDate: '' };

    const endpoint = staff
      ? `centres/${id}/staff/${staff}/dives`
      : diver
        ? `centres/${id}/divers/${diver}/dives`
        : `divers/${id}/dives`;

    const params = {
      filter: {
        orderBy: [{ key: 'date', direction: 'DESC' }],
      },
    };

    await services.get({
      endpoint,
      params,
      loader: true,
      then: ({ data: res }: any) => {
        const { count, data } = res;
        result = {
          diveCount: count,
          lastDiveDate: count > 0 ? data[0].date : '',
        };
      },
      catch: (err: any) => { },
    });

    const person = { ...this.state.person, ...result };
    this.setState({ person });
  }

  async getStaffData() {
    const {
      match: {
        params: { id, diver },
      },
    } = this.props;

    let result = { isStaff: false, centres: [] };

    const endpoint = `centres/${id}/divers/${diver}/staff`;

    const params = {
      filter: {
        where: {
          role: {
            value: 'planner',
            collection: 'staff_centres',
          },
        },
      },
    };

    await services.get({
      endpoint,
      params,
      loader: true,
      then: ({ data: res }: any) => {
        const { staff_centres } = res;

        result = {
          isStaff: true,
          centres: staff_centres.map(({ centre: { name, town } }: any) => `${name}${!!town ? `, ${town}` : ''}`),
        };
      },
      catch: (err: any) => { },
    });

    const person = { ...this.state.person, ...result };
    this.setState({ person });
  }

  /**
   * Gets dive data for this user to show divesqty
   * in the profile
   */
  async getUserDiveDate(){
      const params = {
        filter: {
          where: { user: localStorage.userId }
        },
      };
      const diver = (await getDivers(params)).data.data[0];
      
    const person = { ...this.state.person, ...{divesqty:diver.divesqty} };
    this.setState({ person });
  }

  handleMainButtonClick() {
    this.setState({ renderModal: 'modal-delete' });
  }

  async deleteDiver() {
    const { person } = this.state;
    await deleteDiver(person.id);
    services.pushNotification({
      title: '¡Eliminado!',
      text: 'El usuario se ha eliminado con éxito',
      type: 'red',
    });
    this.props.history.push('/users');
  }

  render() {
    const { isCentre, isOrganization } = this.props;
    const { person, renderModal, highestLevelDocument } = this.state;
    
    return (
      <DiverProfileLayout
        person={person}
        reload={() => this.loadData()}
        renderModal={renderModal}
        isCentre={isCentre}
        isOrganization={isOrganization}
        highestLevelDocument={highestLevelDocument}
        onRemoveDiver={() => this.deleteDiver()}
        onCancelRemove={() => this.setState({ renderModal: '' })}
      />
    );
  }
}

export default DiverProfileFuncional;
