import * as React from 'react';
import { MyProfileStyle } from './my-profile-style';
import { MainLayoutSection } from '../main-layout-style';
import HeaderMain from '../../components/header-main/header-main';
import SubtitleText from '../../components/text/subtitle-text/subtitle-text';
import TextDestacadoText from '../../components/text/text-destacado-text/text-destacado-text';
import { images } from '../../assets/images';
import MainButton from '../../components/main-button';
import TitleText from '../../components/text/title-text/title-text';
import BodyText from '../../components/text/body-text/body-text';
import SelectBox from '../../components/select-box';
import ModalEditProfile from './modal-edit-profile/modal-edit-profile';
import ModalChangePass from './modal-change-pass/modal-change-pass';
import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { LANGUAGES } from '../../constants/languages';
import services from '../../services/services';
import { getOneUsers, uploadUsers } from '../../services/users';
import { setAgent } from '../../redux/actions';
import i18n from 'i18next';
import { Router } from 'react-router';

export interface IMyProfileProps {
  agent: any;
}

export interface IMyProfileState {
  renderModal: string;
  linkProfile: string;
}

class MyProfile extends React.Component<IMyProfileProps, IMyProfileState> {
  constructor(props: IMyProfileProps) {
    super(props);

    this.state = {
      renderModal: '',
      linkProfile: ''
    };
  }

  componentDidMount() {
    const userId = localStorage.getItem('userId');
    const centreId = localStorage.getItem('centreId');
    if (centreId && Number(centreId) > 0) {
      //window.location.href = '/centres/'+centreId+'/planning/calendar';
    }
  }



  getUser = async () => {
    try {
      const response: any = await getOneUsers(localStorage.userId);
      services.setValue(setAgent(response.data));
      services.pushNotification({
        title: '¡Genial!',
        text: 'Se ha cambiado los datos correctamente',
        type: 'green',
      });
    } catch (err) { }
  };

  updateUser = async (data: any) => {
    const { agent } = this.props;

    try {
      const lang = data.lang;
      let user = agent;
      user.lang = lang;
      const response: any = await uploadUsers(localStorage.userId, user);
      localStorage.setItem('lang', JSON.stringify(lang));
      services.setValue(setAgent({ ...agent, lang }));
      services.pushNotification({
        title: '¡Genial!',
        text: 'Se ha cambiado el idioma correctamente',
        type: 'green',
      });
      i18n.changeLanguage(data.lang);
    } catch (err) {
      console.log(err);
    }
  };

  render() {

    const { renderModal } = this.state;
    const { agent } = this.props;
    if (!this.state.linkProfile) {
      services.setLink("/profile", (newLink) => {
        this.setState({ ...this.state, linkProfile: newLink });
      });

    }

    //    `${APP_WEB_URL}/profile`
    return (
      <>
        <HeaderMain title={'Perfil'} />
        <MainLayoutSection>
          <MyProfileStyle>
            <div className="my-profile-text">
              <SubtitleText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laboru.
              </SubtitleText>
            </div>

            <div className="my-profile-subtitle">
              <TextDestacadoText>DATOS PERSONALES</TextDestacadoText>
            </div>

            <div className="my-profile-user">
              <div className="my-profile-user-avatar">
                <img src={agent.picture ? agent.picture : images.avatar} alt="" />



              </div>

              <div className="my-profile-user-info">
                <div className="my-profile-user-info__name">
                  <TitleText>
                    {agent.name} {agent.surname}
                  </TitleText>
                </div>
                <div className="my-profile-user-info__email">
                  <BodyText>{agent.email}</BodyText>
                </div>


                <div className="my-profile-user-info__pass">
                  <div className="my-profile-user-info__pass__item" />
                  <div className="my-profile-user-info__pass__item" />
                  <div className="my-profile-user-info__pass__item" />
                  <div className="my-profile-user-info__pass__item" />
                  <div className="my-profile-user-info__pass__item" />
                  <div className="my-profile-user-info__pass__item" />
                  <div className="my-profile-user-info__pass__item" />
                  <div className="my-profile-user-info__pass__item" />
                </div>



                <div className="my-profile-user-info__buttons">
                  <div className="my-profile-user-info__buttons__item">
                    <MainButton
                      type="primary"
                      onClick={() => this.setState({ renderModal: 'edit-profile' })}
                      text="Editar perfil"
                    />
                  </div>
                  <div className="my-profile-user-info__buttons__item">
                    <MainButton
                      type="secondary"
                      onClick={() => this.setState({ renderModal: 'pass' })}
                      text="Cambiar contraseña"
                    />
                  </div>
                </div>
                <div className="my-profile-user-info__link">

                  <BodyText>
                    <a href={this.state.linkProfile} target="_blank">Accede a tu perfil de buceador en Diveasapp</a>
                  </BodyText>
                </div>
              </div>



            </div>

            <div className="my-profile-subtitle">
              <TextDestacadoText>IDIOMA</TextDestacadoText>
            </div>

            <div className="my-profile-language">
              <SelectBox
                labelText="SELECCIONE UNA OPCIÓN"
                placeholder="Seleccione"
                required={true}
                className="rounded-title"
                optionsText={LANGUAGES}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={agent.lang}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.updateUser({ lang: value })}
              />
            </div>
          </MyProfileStyle>
        </MainLayoutSection>
        {renderModal === 'edit-profile' && (
          <ModalEditProfile
            showModal={renderModal === 'edit-profile'}
            onCancel={() => this.setState({ renderModal: '' })}
            onAccept={() =>
              this.setState({ renderModal: '' }, () => {
                this.getUser();
              })
            }
            user={agent}
          />
        )}
        {renderModal === 'pass' && (
          <ModalChangePass
            idUser={agent.id}
            showModal={renderModal === 'pass'}
            onCancel={() => this.setState({ renderModal: '' })}
            onAccept={() =>
              this.setState({ renderModal: '' }, () => {
                services.showModalSuccess('Genial!', 'Se ha cambiado la contraseña correctamente');
              })
            }
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(MyProfile);
