import React, { Component } from 'react';
import { ListItemAddedContainer } from './item-certifier-style';
import { images } from '../../assets/images';
import BodyText from '../text/body-text/body-text';
import InfoText from '../text/info-text/info-text';

interface ListItemAddedProps {
  text: string;
  id: string;
  onRemove: () => void;
  img?: string;
  className?: string;
}

export default class ListItemAdded extends Component<ListItemAddedProps> {
  render() {
    const { text, onRemove, img, className, id } = this.props;
    return (
      <ListItemAddedContainer className={className}>
        {img && (
          <div className="item-certifier-img">
            <img src={img} alt="" />
          </div>
        )}
        <div className="item-certifier-border">
          <div className="item-certifier-text">
            <div className="item-certifier-text__certifier">
              <InfoText>{text}</InfoText>
            </div>
            <div className="item-certifier-text__id">
              <InfoText>{id}</InfoText>
            </div>
          </div>
          <div className="item-certifier-delete" onClick={onRemove}>
            <img src={images.deleteSvg} alt="" />
          </div>
        </div>
      </ListItemAddedContainer>
    );
  }
}
