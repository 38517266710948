import * as React from 'react';
import { ButtonStarStyle } from './button-star-style';
import { images } from '../../assets/images';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';

export interface IButtonStarProps {
  count?: string;
  onClick?: Function;
  classNameIcon: string;
}

export interface IButtonStarState {
}

export default class ButtonStar extends React.Component<IButtonStarProps, IButtonStarState> {
  constructor(props: IButtonStarProps) {
    super(props);
  }

  render() {
    const { count, classNameIcon, onClick } = this.props
    return (
      <ButtonStarStyle onClick={() => onClick && onClick()}>
        <div className={classNameIcon} />
        {count !== undefined ? (
          <div className="button-star-count">
            <BodyDestacadoText>{count}</BodyDestacadoText>
          </div>
        ) : ''}
      </ButtonStarStyle>
    );
  }
}
