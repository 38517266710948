import React, { Component } from 'react';
import { ModalDeleteContainer } from './modal-delete-style';
import { images } from '../../../assets/images';
import SubheadText from '../../text/subhead-text/subhead-text';
import MainButton from '../../main-button';
import ModalContainer from '../modal-container';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
interface ErrorModalProps extends RouteComponentProps, WithTranslation {
  title: string;
  subtitle: string;
  className?: string;
  showModal: boolean;
  onRemove: Function;
  onCancel: Function;
  t: any
}
class ModalDelete extends Component<ErrorModalProps, {}> {
  render() {
    const { className, title, subtitle, showModal, onCancel, onRemove, t } = this.props;
    return (
      <ModalContainer
        className="center"
        modalClose={() => onCancel()}
        active={showModal}
      >
        <ModalDeleteContainer className={className}>
          <div className="modal-delete-pop">
            <div className="modal-delete-img">
              <img src={images.delete2RedSvg} alt="" />
            </div>
            <div className="modal-delete-title">
              <SubheadText>{title}</SubheadText>
              <SubheadText>{subtitle}</SubheadText>
            </div>
            <div className="modal-delete-buttons">
              <div className="modal-delete-buttons__item">
                <MainButton
                  text={t('components:actions.delete')}
                  type="error"
                  onClick={onRemove}
                />
              </div>
              <div className="modal-delete-buttons__item">
                <MainButton
                  text={t('components:actions.cancel')}
                  type="secondary"
                  onClick={onCancel}
                />
              </div>
            </div>
          </div>
        </ModalDeleteContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalDelete));

