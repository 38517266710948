import React, { Component } from 'react';
import { CertifierRolesCreateContainer } from './certifier-roles-create-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import { images } from '../../../../assets/images';
import ModalContainer from '../../../../components/modals/modal-container';

import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import InputBox from '../../../../components/input-box';
import SelectBox from '../../../../components/select-box';
import MainButton from '../../../../components/main-button';

import { CERTIFIER_ROLES } from '../../../../constants/roles';
import { getDataFromAddress } from '../../../../services/google-maps';
import { getAdministrativeLevel1 } from '../../../../utils/maps';
import { IDropdown } from '../../../../models/generic';
import { isValidEmail } from '../../../../utils/validation';
import { copyOf } from '../../../../utils/other';
import { patchOrganizationStaff, postOrganizationStaff } from '../../../../services/collaborator';
import services from '../../../../services/services';

interface CertifierRolesCreateProps {
  onCancel: Function;
  showModal: boolean;
  onAccept: Function;
  user?: {
    name: string;
    surname: string;
    email: string;
    role: string;
    states: string[];
    id?: number;
  };
}
interface CertifierRolesCreateState {
  user: {
    name: string;
    surname: string;
    email: string;
    role: string;
    states: string[];
    id?: number;
  };
  states: IDropdown[];
  selectedPlace: string;
  errors: { [key: string]: string };
}

export default class CertifierRolesCreate extends Component<CertifierRolesCreateProps, CertifierRolesCreateState> {
  readonly state: CertifierRolesCreateState;
  constructor(props: CertifierRolesCreateProps) {
    super(props);
    this.state = {
      user: {
        name: '',
        surname: '',
        email: '',
        role: 'manager',
        states: [],
      },
      selectedPlace: '',
      states: [],
      errors: {},
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setState({ user });
    }
  }

  componentDidUpdate(prevProps: CertifierRolesCreateProps) {
    const { user } = this.props;
    if (user && user !== prevProps.user) {
      this.setState({ user });
    }
  }

  validate(field: string, value: string | string[]) {
    if (!value || value === '') return 'required';
    if (field === 'email' && typeof value === 'string' && !isValidEmail(value)) return 'invalid-email';
    if (field === 'states' && value.length === 0) return 'add-state';
    return '';
  }

  handleAddState() {
    const { selectedPlace, user } = Object.assign(this.state);
    if (selectedPlace !== '' && !user.states.includes(selectedPlace)) {
      const newStates = copyOf(user.states);
      newStates.push(selectedPlace);
      user.states = newStates;
      this.setState({ user });
    }
  }

  handleRemoveState(value: string) {
    const { user } = Object.assign(this.state);
    const newStates = user.states.filter((state: string) => state !== value);
    user.states = newStates;
    this.setState({ user });
  }

  async handleSubmit() {
    const { onAccept } = this.props;
    const editMode = !!this.props.user;
    const { user, errors } = Object.assign(this.state);
    const organizationId: string | null = localStorage.getItem('organizationId');
    if (!editMode) {
      Object.keys(user).forEach((field: string) => {
        if (field !== 'states' || user.role === 'local') {
          errors[field] = this.validate(field, user[field]);
        } else if (field === 'states') {
          errors[field] = '';
        }
      });
    } else {
      if (user.role === 'local') {
        errors.states = this.validate('states', user.states);
      } else {
        errors.states = '';
      }
    }

    const hasError = !Object.values(errors).every(err => err === '');
    this.setState({ errors });
    if (!hasError && organizationId) {
      if (editMode) {
        const data = {
          states: user.states,
          countries: [],
          unlimited: user.role === 'manager',
        };
        await patchOrganizationStaff(organizationId, user.id.toString(), data);
        services.showModalSuccess('¡Genial!', `El usuario se ha editado con éxito`);
      } else {
        const data = {
          name: user.name,
          surname: user.surname,
          email: user.email,
          states: user.states,
          countries: [],
          unlimited: user.role === 'manager',
        };
        await postOrganizationStaff(organizationId, data);
        services.showModalSuccess('¡Genial!', `El usuario se ha creado con éxito`);
      }

      onAccept();
    }
  }

  handleChange(field: string, value: string | string[]) {
    const { user } = Object.assign(this.state);
    user[field] = value;
    this.setState({ user });
  }

  searchTimeout: any;
  handleSearchState(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (value && value !== '') this.searchAddress(value);
    }, 300);
  }

  async searchAddress(value: string) {
    const resp: any = await getDataFromAddress(value);
    const results = resp.data.results;
    let states: any[] = results.map((result: any) => {
      const level1 = getAdministrativeLevel1(result);
      return level1.longName;
    });
    states = Array.from(new Set(states)).map(state => ({ value: state, label: state }));
    this.setState({ states });
  }

  render() {
    const { onCancel, showModal } = this.props;
    const { user, errors, states, selectedPlace } = this.state;
    const editMode = !!this.props.user;

    return (
      <ModalContainer
        title={`${editMode ? 'Editar' : 'Crear nuevo'} rol`}
        modalClose={() => onCancel()}
        active={showModal}
      >
        <CertifierRolesCreateContainer>
          {!editMode && (
            <div className="modal-create-form">
              <div className="modal-create-form__title">
                <BodyDestacadoText>Datos Personales</BodyDestacadoText>
              </div>
              <div className="modal-create-form__content">
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        labelText="Nombre"
                        placeholder="Nombre"
                        className="rounded-title"
                        required={true}
                        type="text"
                        errorCode={errors.name}
                        value={user.name}
                        onChange={(value: string) => this.handleChange('name', value)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        labelText="Apellidos"
                        placeholder="Apellidos"
                        className="rounded-title"
                        required={true}
                        type="text"
                        errorCode={errors.surname}
                        value={user.surname}
                        onChange={(value: string) => this.handleChange('surname', value)}
                      />
                    </FormGridItem>
                  </FormGridRow>

                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        labelText="Email"
                        placeholder="Email"
                        className="rounded-title"
                        required={true}
                        type="email"
                        errorCode={errors.email}
                        value={user.email}
                        onChange={(value: string) => this.handleChange('email', value)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
              </div>
            </div>
          )}
          <div className="modal-create-form">
            <div className="modal-create-form__title">
              <BodyDestacadoText>Permisos</BodyDestacadoText>
            </div>
            <div className="modal-create-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText="role"
                      placeholder="role"
                      className="rounded-title"
                      optionsText={CERTIFIER_ROLES}
                      optionKey={'value'}
                      optionValue={'label'}
                      required={true}
                      errorCode={''}
                      initialValue={user.role}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: string) => this.handleChange('role', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
                {user.role === 'local' && (
                  <>
                    <FormGridRow>
                      <FormGridItem type="col-1">
                        <SelectBox
                          labelText="Zona de actuación"
                          placeholder="Zona de actuación"
                          className="rounded-title"
                          optionsText={states}
                          optionKey={'value'}
                          optionValue={'label'}
                          onSearch={value => this.handleSearchState(value)}
                          required={true}
                          errorCode={errors.states}
                          initialValue={selectedPlace}
                          defaultValue={selectedPlace}
                          initialValueFilterText={selectedPlace}
                          defaultValueFilterText={selectedPlace}
                          icon={images.arrowDown2BlueSvg}
                          onChange={(value: string) => this.setState({ selectedPlace: value })}
                        />
                      </FormGridItem>
                      <FormGridItem type="col-3">
                        <div className="modal-create-form__add-zone-btn">
                          <MainButton
                            type="primary"
                            iconImg={images.createWhiteSvg}
                            text={'Añadir'}
                            onClick={() => this.handleAddState()}
                          />
                        </div>
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <div className="modal-create-form-states-container">
                        {user.states.map((state: string, index: number) => (
                          <div className="modal-create-form-states-container__item" key={index}>
                            <div> {state}</div>
                            <div
                              onClick={() => this.handleRemoveState(state)}
                              className="modal-create-form-states-container__img"
                            >
                              <img src={images.deleteSvg} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </FormGridRow>
                  </>
                )}
              </FormGrid>
            </div>
          </div>

          <div className="modal-create-buttons">
            <div className="modal-create-buttons__item">
              <MainButton type="primary" text={editMode ? 'Editar' : 'Crear'} onClick={() => this.handleSubmit()} />
            </div>
          </div>
        </CertifierRolesCreateContainer>
      </ModalContainer>
    );
  }
}
