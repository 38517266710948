import * as React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { RouteComponentProps, withRouter, match } from 'react-router';
import { BookingsStyle } from '../../bookings-style';
import { MainLayoutTableContainer } from '../../../../main-layout-style';
import { images } from '../../../../../assets/images';
import MainTable from '../../../../../components/main-table/main-table';
import { BookingDetail } from '../../../../../models/bookings';
import { copyOf } from '../../../../../utils/other';
import { getTimeFormatted } from '../../../../../utils/time';
import { ITimeBlocks } from '../../../../../models/generic';
import ModalConfirmation from '../../../../../components/modals/modal-confimation/modal-confirmation';
import services from '../../../../../services/services';
import { uploadBookings, getOneBookings, confirmBooking, cancelBooking, getOneBookingsFilter, rejectBooking } from '../../../../../services/bookings';
import ReassignActivity from '../../../../../components/modals/modal-reassign-activity/modal-reassign-activity';
import ModalContainer from '../../../../../components/modals/modal-container';
import moment from 'moment';
import ModalBookingDetail from '../../modal-bookings/modal-booking-detail/modal-booking-detail';
import ModalBookingCancelPassed from '../../modal-bookings/modal-cancel-passed/modal-booking-cancel-passed-detail';
import ModalBookingDetailDivers from '../../modal-bookings/modal-booking-detail-divers/modal-booking-detail-divers';
import { isBefore, format, parse } from 'date-fns';
import { t } from 'i18next';
import SubheadText from '../../../../../components/text/subhead-text/subhead-text';

export interface IBookingsTypeProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
  }>;
  centreCurrency?: string;
  onReload: () => void;
  section: string;
  subsection?: string | null;
  bookings: any[];
  bookingsRejects?: any[];
  t:any;
}

export interface IBookingsTypeState {
  modal: string;
  
  selectedBooking: BookingDetail;
}

class BookingsType extends React.Component<IBookingsTypeProps, IBookingsTypeState> {
  constructor(props: IBookingsTypeProps) {
    super(props);
    
    this.state = {
      modal: '',
      selectedBooking: {
        id: '',
        activityId: '',
        bookingId: '',
        createdAt: '',
        centreId: 0,
        activity: '',
        discipline: '',
        activityDate: '',
        numDivers: '',
        divers: [],
        price: '',
        diverId: 0,
        timeBlocks: [],
        status: '',
        source: '',
        comments: '',
        discount: 0,
        diverCount:0
      },
    };
  }

  getTableRowTemplates() {
    const { section, subsection, t } = this.props;

    let actions = [];
    let divers = [];
    switch (section) {
      case 'solicitudes':
        return {
          booking: {
            key: '',
            value: 'RE0000001',
          },
          departureDate: {
            key: '',
            value: '09/08/2019',
          },
          departureHour: {
            key: '',
            value: '2 h',
          },
          activityName: {
            key: '',
            value: 'Cueva oscura',
          },
          discipline: {
            key: '',
            value: 'Buceo recreativo',
          },
          numDivers: {
            key: 'icon-text-button',
            value: 3,
            icon: images.eyeLightBlueSvg,
            onClick: () => { },
          },
          price: {
            key: '',
            value: '15.00 €',
          },
          paid: {
            key: '',
            value: '15.00 €',
          },
          status: {
            key: '',
            value: 'Incompleta',
          },
          actions: {
            key: 'options',
            actions: [
              {
                label: t('modalMessages:confirm'),
                icon: images.checkSvg,
                onClick: () => { },
              },
              {
                label: t('buttons:reject'),
                icon: images.closeSvg,
                onClick: () => { },
              },
              {
                label: t('buttons:reasactv'),
                icon: images.arrowDown2Svg,
                onClick: () => { },
              },
              {
                label: t('buttons:details'),
                icon: images.editSvg,
                onClick: () => { },
              }
            ]
          }
        };
      case 'rechazadas':
        return {
          booking: {
            key: '',
            value: 'RE0000001',
          },
          departureDate: {
            key: '',
            value: '09/08/2019',
          },
          departureHour: {
            key: '',
            value: '2 h',
          },
          activityName: {
            key: '',
            value: 'Cueva oscura',
          },
          discipline: {
            key: '',
            value: 'Buceo recreativo',
          },
          numDivers: {
            key: 'icon-text-button',
            value: 3,
            icon: images.eyeLightBlueSvg,
            onClick: () => { },
          },
          price: {
            key: '',
            value: '15.00 €',
          },
          paid: {
            key: '',
            value: '15.00 €',
          },

          status: {
            key: '',
            value: 'Incompleta',
          },
          actions: {
            key: 'options',
            actions: [
              {
                label: t('buttons:details'),
                icon: images.editSvg,
                onClick: () => { },
              }
            ]
          }
        };

      case 'confirmadas':
        return {
          booking: {
            key: '',
            value: 'RE0000001',
          },
          departureDate: {
            key: '',
            value: '09/08/2019',
          },
          departureHour: {
            key: '',
            value: '2 h',
          },
          activityName: {
            key: '',
            value: 'Cueva oscura',
          },
          discipline: {
            key: '',
            value: 'Buceo recreativo',
          },
          numDivers: {
            key: 'icon-text-button',
            value: 3,
            icon: images.eyeLightBlueSvg,
            onClick: () => { },
          },
          price: {
            key: '',
            value: '15.00 €',
          },
          paid: {
            key: '',
            value: '15.00 €',
          },

          status: {
            key: '',
            value: 'Incompleta',
          },
          actions: {
            key: 'options',
            actions: [
              {
                label: t('buttons:reasactv'),
                icon: images.arrowDown2Svg,
                onClick: () => { },
              },
              {
                label: t('buttons:cancelres'),
                icon: images.closeSvg,
                onClick: () => { },
              },
              {
                label: t('buttons:details'),
                icon: images.editSvg,
                onClick: () => { },
              }
            ]
          }
        };

      case 'historial':
        return {
          booking: {
            key: '',
            value: 'RE0000001',
          },
          departureDate: {
            key: '',
            value: '09/08/2019',
          },
          departureHour: {
            key: '',
            value: '2 h',
          },
          activityName: {
            key: '',
            value: 'Cueva oscura',
          },
          discipline: {
            key: '',
            value: 'Buceo recreativo',
          },
          numDivers: {
            key: 'icon-text-button',
            value: 3,
            icon: images.eyeLightBlueSvg,
            onClick: () => { },
          },
          price: {
            key: '',
            value: '15.00 €',
          },
          paid: {
            key: '',
            value: '15.00 €',
          },

          status: {
            key: '',
            value: 'Incompleta',
          },
          actions: {
            key: 'options',
            actions: [
              {
                label: t('buttons:tonp'),
                icon: images.arrowDown2Svg,
                onClick: () => { },
              },
              {
                label: t('buttons:details'),
                icon: images.editSvg,
                onClick: () => { },
              }
            ]
          }
        };

      case 'cancelaciones':
        return {
          booking: {
            key: '',
            value: 'RE0000001',
          },
          departureDate: {
            key: '',
            value: '09/08/2019',
          },
          departureHour: {
            key: '',
            value: '2 h',
          },
          activityName: {
            key: '',
            value: 'Cueva oscura',
          },
          discipline: {
            key: '',
            value: 'Buceo recreativo',
          },
          numDivers: {
            key: 'icon-text-button',
            value: 3,
            icon: images.eyeLightBlueSvg,
            onClick: () => { },
          },
          price: {
            key: '',
            value: '15.00 €',
          },
          paid: {
            key: '',
            value: '15.00 €',
          },
          status: {
            key: '',
            value: 'Incompleta',
          },
          actions: {
            key: 'options',
            actions: [
              {
                label: t('buttons:details'),
                icon: images.editSvg,
                onClick: () => { },
              }
            ]
          }
        };

      default:
        break;
    }
  }



  getTimeFormatBlock(timeBlocks: any) {
    let startHour = 24;
    let startMinutes = 0;
    let endHour = 24;
    let endMinutes = 0;

    timeBlocks.forEach((timeBlock: ITimeBlocks) => {
      if (timeBlock.start.hour < startHour) {
        startHour = timeBlock.start.hour;
        startMinutes = timeBlock.start.minutes;
      }
      if (timeBlock.end.hour < endHour) {
        endHour = timeBlock.end.hour;
        endMinutes = timeBlock.end.minutes;
      }
    });
    return getTimeFormatted(startHour, startMinutes) //+ " - " + getTimeFormatted(endHour, endMinutes)
    //return timeBlock[0].start.hour + ':' +timeBlock[0].start.minutes + timeBlock[0].start.seconds
  }

  getPaid(booking: any, section: string): string {
    console.log(booking);
    this.state.selectedBooking.diverCount = booking.divers
    if (booking.paid < 0) {
      return ((-booking.paid) / 100).toString() + ' €';
    }
    else if (booking.paid == 0){
      return '0 €';
    }
    return (((booking.paid) / 100) - (booking.paid/100 * booking.discount / 100)).toString() + ' €';


  }
  getTableRows() {
    const { section, bookings, subsection } = this.props;
    let bookPrice = 0;
    const tableRows: any[] = [];
    const now = moment();
    const dateMoment = moment(now, 'DD/MM/YYYY');
    if (bookings && bookings.length) {
      for (const booking of bookings) {
        const tableRow = copyOf(this.getTableRowTemplates());
        tableRow.booking.value = booking.reservation;
        tableRow.departureDate.value = booking.date;
        tableRow.departureHour.value = booking.hour;
        tableRow.activityName.value = booking.name;
        tableRow.discipline.value = booking.discipline;
        tableRow.numDivers.value = booking.divers;
        tableRow.numDivers.onClick = () => { this.modalHandler('modal-booking-detail-divers', booking) };
        /*tableRow.numDivers.onClick = () => {};*/
        tableRow.price.value = (booking.price / 100).toString() + ' €';
        tableRow.status.value = booking.status;
        tableRow.paid.value = this.getPaid(booking, section);


        const {
          match: {
            params: { id },
          },
        } = this.props;

        switch (section) {
          case 'solicitudes':
            tableRow.actions.actions[0].onClick = () => {
              this.modalHandler('modal-confirm', booking);
            };
            tableRow.actions.actions[1].onClick = () => {
              this.modalHandler('modal-reject', booking);
            };
            tableRow.actions.actions[2].onClick = () => {
              this.modalHandler('modal-reassign-activity', booking);
            };
            tableRow.actions.actions[3].onClick = () => {
              this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
            };
            break;
          case 'confirmadas':
            tableRow.actions.actions[0].onClick = () => {
              this.modalHandler('modal-reassign-activity', booking);
            };

            const dateBooking = parse(booking.date as string, 'dd/MM/yyyy', new Date()) //booking.hour
            let timeBooking = booking.hour.split(':');
            dateBooking.setHours(dateBooking.getHours() + Number(timeBooking[0]));
            dateBooking.setMinutes(dateBooking.getMinutes() + Number(timeBooking[1]));

            if (isBefore(dateBooking, new Date())) {
              tableRow.actions.actions[1].onClick = () => {
                this.modalHandler('modal-cancel-passed', booking);
              };
              tableRow.actions.actions[2].onClick = () => {
                this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
              };
            } 
            else {
              tableRow.actions.actions[1].onClick = () => {
                this.modalHandler('modal-cancel', booking);
              };
              tableRow.actions.actions[2].onClick = () => {
                this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
              };
            }

            break;
          case 'rechazadas':
            tableRow.actions.actions[0].onClick = () => {
              this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
            };
            break;
          case 'historial':
            tableRow.actions.actions[0].onClick = () => {
              this.modalHandler('modal-chante-to-np', booking);
            };
            tableRow.actions.actions[1].onClick = () => {
              this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
            };

            break;
          case 'cancelaciones':
            tableRow.actions.actions[0].onClick = () => {
              this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
            };
            break;
        }

        tableRows.push(tableRow);
      }
    }
    return tableRows;
  }

  getTableRowsRejects() {
    const { section, bookingsRejects, t } = this.props;
    const {
      match: {
        params: { id },
      }
    } = this.props;  

    const tableRows: any[] = [];
    let bookPrice = 0;
    if (bookingsRejects && bookingsRejects.length) {
      for (const booking of bookingsRejects) {
        const tableRow = copyOf(this.getTableRowTemplates());
        tableRow.booking.value = booking.bookingId;
        tableRow.departureDate.value = moment(booking.activityDate).format('DD/MM/YYYY');
        tableRow.departureHour.value = this.getTimeFormatBlock(booking.timeBlocks);
        tableRow.activityName.value = booking.activity;
        tableRow.discipline.value = booking.discipline;
        tableRow.numDivers.value = booking.numDivers;
        tableRow.numDivers.divers = booking.divers;
        tableRow.numDivers.onClick = () => { this.modalHandler('modal-booking-detail-divers', booking) };
        if (booking.numDivers > 1) {
          bookPrice = booking.lines.slice(-1)[0].price * (booking.numDivers - 1);
        }
        booking.price = (Object.keys(booking.lines).length != 0 ? booking.lines.reduce((a: number, b: any) => a + b.price) + bookPrice : 0 + bookPrice) / 100;
        tableRow.price.value = booking.price.toString() + ' €';

        tableRow.status.value = booking.status;
        tableRow.paid.value = this.getPaid(booking, section);
        tableRow.actions = {
          key: 'options',
          actions: [
            {
              label: t('buttons:details'),
              icon: images.editSvg,
              onClick: () => { },
            }
          ]
        };
        tableRow.actions.actions[0].onClick = () => {
          this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
        };
        tableRows.push(tableRow);
      }
    }
    return tableRows;
  }

  sendNotification = (title: string, msg: string, type: string) => {
    services.pushNotification({
      title: title,
      text: msg,
      type: type,
    });
  }

  modalHandler = async (modalValue: string, booking?: any) => {
    const res = booking ? await getOneBookingsFilter(booking.id) : {};
    if (res.status !== 200 || res.data.data.length === 0) {
      this.setState({ modal: modalValue });
      return;
    }
    const book = res.data.data[0];
    book.centreId = booking.centreId;
    book.activityId = booking.activityId;
    this.setState({ ...(booking && { selectedBooking: book }), modal: modalValue });
  };

  async handleConfirm() {
    const {
      match: {
        params: { id },
      },
      onReload,
      t
    } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      await confirmBooking(id, selectedBooking.activityId.toString(), selectedBooking.id.toString());
      this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmsucc'), 'green');
    }
    this.modalHandler('');
    onReload();
  }

  async handleReject() {
    const { onReload, t } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      const params = {
        filter: {
          relations: ['activity', 'divers'],
        },
      };
      const booking = (await getOneBookings(selectedBooking.id.toString(), params)).data;
      booking.rejected = true;
      booking.activity = booking.activity.id
      ///:id/reject

      await rejectBooking(booking.id, booking);
      this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmrej'), 'green');
    }
    this.modalHandler('')
    onReload();

  }




  async handleReassignActivity(activitySelected: any) {
    const { onReload, t } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      const params = {
        filter: {
          relations: ['activity', 'divers'],
        },
      };
      let booking = (await getOneBookings(selectedBooking.id.toString(), params)).data;
      booking.activity = +activitySelected;
      await uploadBookings(booking.id, { ...booking, isReasign: true });
      this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmreas'), 'green');
    }
    this.modalHandler('')
    onReload();
  }

  async handleCancel() {
    const { onReload, t } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      const params = {
        filter: {
          relations: ['divers'],
        },
      };
      let booking = (await getOneBookings(selectedBooking.id.toString(), params)).data;
      booking.cancelled = 'canceledByCentre';
      await cancelBooking(booking.id, { 'cancellationType': 'canceledByCentre' })
      //await uploadBookings(booking.id, booking);
      this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmcancel'), 'green');
    }
    this.modalHandler('')
    onReload();
  }

  async handleChangeToNP() {
    const { onReload, t } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      const params = {
        filter: {
          relations: ['divers'],
        },
      };
      let booking = (await getOneBookings(selectedBooking.id.toString(), params)).data;
      booking.presented = false;
      await uploadBookings(booking.id, booking);
      if (!booking.presented) {

        this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmnewstat'), 'green');
      } else {
        this.sendNotification(t('buttons:error'), t('modalMessages:modal-confirmation-activity.confirmnopres'), 'yellow');
      }
    }
    this.modalHandler('')
    onReload();
  }
  getTableColmns() {
    const { section, bookings, subsection, t } = this.props;
    if (section === "cancelaciones") {
      this.tableColumns[7].text = t('modalMessages:modal-bookings-refund.return')
    } else {
      this.tableColumns[7].text = t('modalMessages:modal-bookings-refund.payed')
    }
    return this.tableColumns;
  }
  render() {
    const { t, bookingsRejects } = this.props;
    const { modal, selectedBooking } = this.state;

    return (
      <BookingsStyle>
        <MainLayoutTableContainer>
          <div className="diving-center-table">
            <MainTable
              columns={this.getTableColmns()}
              rows={this.getTableRows()}
              rowKeys={this.rowKeys}
              onOrder={() => { }}
            />
          </div>
          {bookingsRejects && bookingsRejects.length > 0 &&
            <div className="diving-center-table">
              <div className="title-reject">{t('modalMessages:rejected')}</div>
              <MainTable
                rows={this.getTableRowsRejects()}
                rowKeys={this.rowKeys}
                onOrder={() => { }}
              />
            </div>
          }
        </MainLayoutTableContainer>
        {modal === 'modal-confirm' && (
          <ModalConfirmation
            showModal={modal === 'modal-confirm'}
            subtitle={
              t('modalMessages:pressres')
            }
            onAccept={() => this.handleConfirm()}
            onCancel={() => this.modalHandler('')}
          >
            {selectedBooking.activity.maxDivers ? 
              <SubheadText>Plazas disponibles: {selectedBooking.activity.maxDivers - selectedBooking.diverCount} </SubheadText>
              :
              <SubheadText>Plazas ocupadas: { selectedBooking.diverCount} </SubheadText>
            }
          </ModalConfirmation>
        )}
        {modal === 'modal-reject' && (
          <ModalConfirmation
            showModal={modal === 'modal-reject'}
            subtitle={
              t('modalMessages:pressrej')
            }
            onAccept={() => this.handleReject()}
            onCancel={() => this.modalHandler('')}
          />
        )}
        {modal === 'modal-reassign-activity' && (
          <ModalContainer
            title={t('buttons:reasactv')}
            modalClose={() => this.modalHandler('')}
            active={modal === 'modal-reassign-activity'}
          >
            <ReassignActivity
              selectedBooking={selectedBooking}
              onNext={(activitySelected: any) => {
                this.handleReassignActivity(activitySelected);
              }}
            />
          </ModalContainer>
        )}
        {modal === 'modal-cancel' && (
          <ModalConfirmation
            showModal={modal === 'modal-cancel'}
            subtitle={
              t('modalMessages:presscancel')
            }
            onAccept={() => this.handleCancel()}
            onCancel={() => this.modalHandler('')}
          />
        )}

        {modal === 'modal-cancel-passed' && (
          <ModalBookingCancelPassed
            showModal={modal === 'modal-cancel-passed'}
            title={t('buttons:cancelres')}
            text={
              t('modalMessages:modal-cancelation-activity.cancel-no')
            }
            modalClose={() => this.modalHandler('')}
          />
        )}

        {modal === 'modal-chante-to-np' && (
          <ModalConfirmation
            showModal={modal === 'modal-chante-to-np'}
            subtitle={
              t('modalMessages:pressnopres')
            }
            onAccept={() => this.handleChangeToNP()}
            onCancel={() => this.modalHandler('')}
          />
        )}
        {modal === 'modal-booking-detail' && (
          <ModalBookingDetail
            booking={selectedBooking}
            showModal={modal === 'modal-booking-detail'}
            modalClose={() => this.modalHandler('')}
          />
        )}

        {modal === 'modal-booking-detail-divers' && (
          <ModalBookingDetailDivers
            booking={selectedBooking}
            showModal={modal === 'modal-booking-detail-divers'}
            modalClose={() => this.modalHandler('')}
          />
        )}

      </BookingsStyle>
    );
  }

  rowKeys = [
    'booking',
    'departureDate',
    'departureHour',
    'activityName',
    'discipline',
    'numDivers',
    'price',
    'paid',
    'status',
    'actions',
  ];

  tableColumns = [
    {
      text: this.props.t('layouts:bookings.res'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('layouts:bookings.datedepart'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('layouts:bookings.inithour'),
      filter: false,
      ascendent: false,
    },
    {
      text:  this.props.t('layouts:actname'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('layouts:disc'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('layouts:bookings.options.nrodivers'),
      filter: false,
      ascendent: false,
    },
    {
      text:  this.props.t('layouts:bookings.booking.price'),
      filter: false,
      ascendent: false,
    },
    {
      text:  this.props.t('modalMessages:modal-bookings-refund.payed'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('buttons:detail'),
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: false,
    },
  ];

}

export default withTranslation(['layouts', 'buttons', 'modalMessages'])(withRouter(BookingsType));
