import React, { Component } from 'react';
import { MainLayoutSection, MainLayoutTableContainer } from '../main-layout-style';
import ModalSuccess from '../../components/modals/modal-success/modal-success';
import HeaderMain from '../../components/header-main/header-main';
import { images } from '../../assets/images';
import Pagination from '../../components/pagination';
import MainTable from '../../components/main-table/main-table';
import { Diver, rowKeys, tableColumns } from './types';
import { GhostDiverForm } from '../../types/user';
import { TableRow, TableCell } from '../../types/table';
import GhostDiverFormFunctional from './create/ghost-diver/ghost-diver-form-functional';
import DiverFormFunctional from './create/diver/diver-form-functional';
import moment from 'moment';
import 'moment/locale/es.js';
import { deleteDiver } from '../../services/diver';
import services from '../../services/services';
import { ContentBlock } from '../../components/content-block/content-block';
import ModalInfo from '../../components/modals/modal-info/modal-info';
import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { havePermissionsDivers } from '../../services/staff-centre';


interface DiversLayoutProps {
  items: Diver[];
  totalPages: number;
  setPage: (page: number) => void;
  refreshData: () => void;
  centreId: string;
  onSearch: (value: string) => void;
  staffPermissions: any;
  page: number;
  totalItems: number;
  t:any;
}
interface DiversLayoutState {
  renderModal: string;
  newGhostDiver?: GhostDiverForm;
}

class DiversLayout extends Component<DiversLayoutProps, DiversLayoutState> {
  state: DiversLayoutState = {
    renderModal: 'modal-diver',
  };

  formatDataToTableRows(data: { [key: string]: any }[]) {
    return data.map(item => {
      const row: TableRow = {};

      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };
        switch (key) {
          case 'name':
            cell.value = `${item.name} ${item.surname}`;
            cell.key = 'highlighted-body';
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });

      row.avatar = {
        value: item.ghost || !item.picture ? 'ghost' : '',
        type: '',
        key: 'avatar',
        link: item.picture || '',
      };

      row.certifier = {
        value: !!item.certifier ? item.certifier.name : '-',
        type: '',
        key: 'body',
      };
      //if (!item.ghost) {
      row.detail = {
        key: 'icon-button',
        value: images.eyeSvg,
        type: '',
        link: `divers/${item.id}`,
      };
      //}

      /*row['activity'] = {
        value: item.bookings[0] ? item.bookings[0].activity.name || '-' : '-',
        type: '',
        key: '',
      };

      if (item.bookings.length > 1) {
        row['activity-count'] = {
          value: {
            title: 'Actividades',
            items: item.bookings,
          },
          type: '',
          key: 'count',
        };
      }*/

      row['level'] = {
        value: item.level && item.level.length > 0 ? item.level[0] : '-',
        type: '',
        key: '',
      }

      if (item.level && item.level.length > 1) {
        row['level'] = {
          value: {
            title: 'Titulaciones',
            items: item.level,
          },
          type: '',
          key: 'count',
        };
      }

      /*
      row['dives'] = {
        value: !!item.dives.length ? item.dives.length : '-',
        type: '',
        key: '',
      };

      const lastDive = !!item.dives.length && item.dives[item.dives.length - 1].date;
      row['last-dive'] = {
        value: !!lastDive ? moment(lastDive, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-',
        type: '',
        key: '',
      };*/

      return row;
    });
  }

  async deleteDiver(diverId: string | number) {
    await deleteDiver(diverId);
    services.pushNotification({
      text: '¡Eliminado!',
      title: 'El usuario se ha eliminado con éxito',
      type: 'red',
    });
  }

  timeoutSearch: any;
  handleSearch(value: string | null) {
    const { onSearch } = this.props;
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      if (!value) onSearch('');
      else onSearch(value);
    }, 500);
  }

  render() {
    const { renderModal, newGhostDiver } = this.state;
    const { items, totalPages, centreId, setPage, refreshData, staffPermissions, page, totalItems, t } = this.props;

    return (
      <>
        <ContentBlock>
          {/* Header */}
          <HeaderMain
            resultCount={totalItems}
            title={'Mis buceadores'}
            withSearch={true}
            handleSearch={(value: string) => {
              this.handleSearch(value);
            }}
            mainButton={
              (!staffPermissions || staffPermissions.divers) && {
                img: images.createWhiteSvg,
                text: 'Crear',
                disabled: !havePermissionsDivers(),
                onClick: () => this.setState({ renderModal: 'modal-ghost-diver' }),
              }
            }
          />

          {/* Content */}
          <MainLayoutSection>
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTable
                  columns={tableColumns}
                  rows={this.formatDataToTableRows(items)}
                  rowKeys={rowKeys}
                  onOrder={() => { }}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} limit={totalPages} callback={page => setPage(page)} />
                </div>
              )}
            </MainLayoutTableContainer>
          </MainLayoutSection>

          {/* Create Ghost Diver */}
          {renderModal === 'modal-ghost-diver' && (
            <GhostDiverFormFunctional
              title="Crear buceador sin perfil en Diveasapp"
              showModal={renderModal === 'modal-ghost-diver'}
              onAccept={newGhostDiver =>
                this.setState({ newGhostDiver, renderModal: 'modal-success-ghost-diver' }, () => {
                  refreshData();
                })
              }
              onCancel={() => this.setState({ renderModal: '' })}
              centreID={centreId}
            />
          )}
          {/* Modal Success Ghost Diver */}

          <ModalInfo
            type="blue blue-title"
            title={[<span key="i">¡Genial!</span>]}
            text={[
              `Se acaba de enviar una invitación
            al email del buceador que acabas de crear.`,
              `Es posible que no lo complete inmediatamente,
          así que si quieres puedes completar su perfil
          con los datos que creas necesarios.`,
              `El buceador podrá modificarlos y completarlos
          una vez que hay aceptado la invitación.`,
            ]}
            onClose={() => this.setState({ renderModal: '' })}
            icon={images.checkCircleBlueSvg}
            showModal={renderModal === 'modal-success-ghost-diver'}
            buttons={[
              {
                text: 'Completar perfil',
                type: 'primary',
                onClick: () => this.setState({ renderModal: 'modal-diver' }),
              },
            ]}
          />

          {/* Fill diver data */}

          {renderModal === 'modal-diver' && newGhostDiver && typeof newGhostDiver !== 'undefined' && (
            <DiverFormFunctional
              title="Perfil del buceador"
              showModal={renderModal === 'modal-diver'}
              onAccept={() =>
                this.setState({ renderModal: 'modal-success-diver' }, () => {
                  refreshData();
                })
              }
              onCancel={() => this.setState({ renderModal: '' })}
              centreID={centreId}
              ghostDiver={newGhostDiver}
            />
          )}

          {/* Modal Success Diver */}
          <ModalSuccess
            title="¡Genial!"
            text="Los datos introducidos completarán
          el registro del buceador. Una vez que este
          se haya registrado, podrá completar
          su perfil e, incluso, modificar
          los datos existentes."
            showModal={renderModal === 'modal-success-diver'}
            onClose={() => this.setState({ renderModal: '', newGhostDiver: undefined })}
          />

          {/* <ModalDelete
          title={'Eliminar buceador'}
          subtitle={
            'Una vez eliminado el perfil del buceador, este no tendrá acceso a la aplicación de DiveAsapp y no podrá disfrutar de sus servicios.'
          }
          showModal={renderModal === 'modal-delete'}
          onRemove={() => this.deleteDiver()}
          onCancel={() => {}}
        /> */}
        </ContentBlock>
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(DiversLayout);
