import * as React from 'react';
import { BuyResumeStyle } from './buy-resume-style';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import { images } from '../../assets/images';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import BodyText from '../text/body-text/body-text';
import InfoText from '../text/info-text/info-text';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

export interface IBuyResumeProps extends RouteComponentProps, WithTranslation {
  activityName?: string;
  img?: string;
}

export interface IBuyResumeState {}

class BuyResume extends React.Component<IBuyResumeProps, IBuyResumeState> {
  constructor(props: IBuyResumeProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, activityName, img } = this.props;
    return (
      <BuyResumeStyle>
        <div className="buy-resume-title">
          <SubheadDestacadoText>{t('components:buy-resume.title')}</SubheadDestacadoText>
        </div>
        <div className="buy-resume-detail">
          <BodyDestacadoText>{t('components:buy-resume.see-collection')}</BodyDestacadoText>
        </div>
        <div className="buy-resume-activity">
          <div className="buy-resume-activity__img">
            <img src={img || images.avatar} alt="" />
          </div>
          <div className="buy-resume-activity__text">
            <TextDestacadoText>{activityName}</TextDestacadoText>
          </div>
        </div>

        <div className="buy-resume-item">
          <div className="buy-resume-item-product">
            <div className="buy-resume-item-product__title">
              <TextDestacadoText>{t('components:buy-resume.diver')}</TextDestacadoText>
            </div>
            <div className="buy-resume-item-product__resume">
              <BodyText>20.00 € / {t('components:buy-resume.diver')} </BodyText>
            </div>
          </div>
          <div className="buy-resume-item-amount">
            <TextDestacadoText>x2</TextDestacadoText>
          </div>
          <div className="buy-resume-item-price">
            <TextDestacadoText>40.00 €</TextDestacadoText>
          </div>
        </div>

        {/* <div className="buy-resume-item">
          <div className="buy-resume-item-product">
            <div className="buy-resume-item-product__title">
              <TextDestacadoText>Buceador</TextDestacadoText>
            </div>
            <div className="buy-resume-item-product__resume">
              <BodyText>20.00 € / Buceador </BodyText>
            </div>
          </div>
          <div className="buy-resume-item-amount">
            <TextDestacadoText>x2</TextDestacadoText>
          </div>
          <div className="buy-resume-item-price">
            <TextDestacadoText>40.00 €</TextDestacadoText>
          </div>
        </div> */}

        {/* <div className="buy-resume-item">
          <div className="buy-resume-item-product">
            <div className="buy-resume-item-product__title">
              <TextDestacadoText>Buceador</TextDestacadoText>
            </div>
            <div className="buy-resume-item-product__resume">
              <BodyText>20.00 € / Buceador </BodyText>
            </div>
          </div>
          <div className="buy-resume-item-amount">
            <TextDestacadoText>x2</TextDestacadoText>
          </div>
          <div className="buy-resume-item-price">
            <TextDestacadoText>40.00 €</TextDestacadoText>
          </div>
        </div> */}

        <div className="buy-resume-bonus">
          <InfoText>{t('components:buy-resume.bonus-daily-activity')}</InfoText>
        </div>
        <div className="buy-resume-resume">
          <div className="buy-resume-resume__item">
            <div className="buy-resume-resume__item__title">
              <InfoText>{t('components:buy-resume.subtotal')}</InfoText>
            </div>
            <div className="buy-resume-resume__item__amount">
              <InfoText>21.20 €</InfoText>
            </div>
          </div>
          <div className="buy-resume-resume__item">
            <div className="buy-resume-resume__item__title">
              <InfoText>{t('components:buy-resume.taxes')}</InfoText>
            </div>
            <div className="buy-resume-resume__item__amount">
              <InfoText>21.20 €</InfoText>
            </div>
          </div>
          <div className="buy-resume-resume__item">
            <div className="buy-resume-resume__item__title">
              <InfoText>{t('components:buy-resume.discount-percent')}</InfoText>
            </div>
            <div className="buy-resume-resume__item__amount">
              <InfoText>21.20 €</InfoText>
            </div>
          </div>
        </div>
        <div className="buy-resume-total">
          <div className="buy-resume-total__title">
            <BodyDestacadoText>{t('components:buy-resume.total')}</BodyDestacadoText>
          </div>
          <div className="buy-resume-total__amount">
            <BodyDestacadoText>21.20 €</BodyDestacadoText>
          </div>
        </div>
      </BuyResumeStyle>
    );
  }
}

export default withTranslation(['intro', 'buttons', 'components'])(withRouter(BuyResume));
