export const LANGUAGES: any = [
  { value: 'es', label: 'Español' },
  { value: 'en', label: 'Ingles' },
  { value: 'it', label: 'Italiano' },
  { value: 'pt', label: 'Portugués' },
  { value: 'fr', label: 'Frances' },
  { value: 'de', label: 'Aleman' },
  { value: 'cat', label: 'Catalán' },
  { value: 'glg', label: 'Gallego' },
  { value: 'eus', label: 'Euskera' },
];

export const LANGUAGES_KEY_VALUES: any = {
  de: 'Alemán',
  cat: 'Catalán',
  es:  'Español',
  eus: 'Euskera',
  fr: 'Francés',
  glg: 'Gallego',
  en: 'Inglés',
  it: 'Italian',
  pt: 'Portugués',
};

export const FULL_LANGUAGES: any = [
  {
    label: 'Catalán',
    value: 'cat',
  },
  {
    label: 'Inglés',
    value: 'en',
  },
  {
    label: 'Euskera',
    value: 'eus',
  },
  {
    label: 'Francés',
    value: 'fr',
  },
  {
    label: 'Alemán',
    value: 'de',
  },
  {
    label: 'Gallego',
    value: 'glg',
  },
  {
    label: 'Italiano',
    value: 'it',
  },
  {
    label: 'Portugués',
    value: 'pt',
  },
  {
    label: 'Español',
    value: 'es',
  },
];

export const FULL_LANGUAGES_KEY_VALUES: any = {
  de: 'Alemán',
  cat: 'Catalán',
  es:  'Español',
  eus: 'Euskera',
  fr: 'Francés',
  glg: 'Gallego',
  en: 'Inglés',
  it: 'Italiano',
  pt: 'Portugués',
};