import React from 'react';
import { IntroContainer } from './intro-style';
import { images } from '../../assets/images';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import HeadDestacadoText from '../../components/text/head-destacado-text/head-destacado-text';
import TextText from '../../components/text/text-text/text-text';
import SignIn from '../../navigation/sign-in';
import LanguageSelector from '../../components/language-selector/language-selector';
import { withRouter, RouteComponentProps } from 'react-router';
import { APP_WEB_URL } from '../../env';
import queryString from 'query-string';

interface IntroProps extends RouteComponentProps {
  getUser: () => void;
  [key: string]: any;
}

interface IntroState {
  intro: boolean;
  settings: Object;
  renderModal: string;
}

class Intro extends React.Component<IntroProps, IntroState> {
  slider: any = null;
  
  state: IntroState = {
    intro: false,
    settings: {
      dots: true,
      // appendDots: (dots: any) => <ul>{dots}</ul>,
      customPaging: () => <button />,
      infinite: false,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '20px',
      arrows: false,
      focusOnSelect: false,
    },
    renderModal: '',
  };
  

  componentDidMount() {   
    setTimeout(() => {
      this.setState({ intro: false });
    }, 2000);
    
    /*
    const ul = document.getElementsByClassName('slick-dots') as HTMLCollectionOf<HTMLElement>;
    if (ul && ul.length) {
      ul[0].style.display = 'flex';
    }*/
  }

  render() {

    const languajes = [
      { label: 'ES', value: 'ES' },
      { label: 'EN', value: 'EN' },
    ];
    const { reassignCentre, reassignOrg, token, jwt } = queryString.parse(window.location.search);
    const { intro, settings, renderModal } = this.state;
    if(!localStorage.getItem('userId') && !jwt){
      //window.location.href = APP_WEB_URL;
    }
    return (
      <IntroContainer>
        <div className="bgImagePanel">
        </div>
       {/*} <div className={`intro-background ${intro && 'active'}`}>
          <div className="intro-background-img">
            <img
              src={this.props.location.pathname.indexOf('/sign-up') !== -1 ? images.signUpBack : images.scubaDiverIntro}
              alt=""
            />
          </div>
          <div className="intro-background-logo">
            <img src={images.diveasappLogoWhiteSvg} alt="" />
          </div>
          <div className={`intro-background-slider ${!intro && 'active'}`}>
            <Slider ref={(c: any) => (this.slider = c)} {...settings}>
              <div className="intro-slide">
                <div className="intro-slide-title">
                  <HeadDestacadoText>Lorem ipsum</HeadDestacadoText>
                </div>
                <div className="intro-slide-text">
                  <TextText>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit varius, sapien nisl primis ornare.
                  </TextText>
                </div>
              </div>
              <div className="intro-slide">
                <div className="intro-slide-title">
                  <HeadDestacadoText>Lorem ipsum</HeadDestacadoText>
                </div>
                <div className="intro-slide-text">
                  <TextText>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit varius, sapien nisl primis ornare.
                  </TextText>
                </div>
              </div>
              <div className="intro-slide">
                <div className="intro-slide-title">
                  <HeadDestacadoText>Lorem ipsum</HeadDestacadoText>
                </div>
                <div className="intro-slide-text">
                  <TextText>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit varius, sapien nisl primis ornare.
                  </TextText>
                </div>
              </div>
            </Slider>
          </div>

          <div className={`intro-footer-logos ${!intro && 'active'}`}>
            <div className={`intro-footer-logos-item`}>
              <img src={images.logoInfoIntro} alt="" />
            </div>
            <div className={`intro-footer-logos-item`}>
              <img src={images.logoMurcia} alt="" />
            </div>
            <div className={`intro-footer-logos-item`}>
              <img src={images.gobiernoEspana} alt="" />
            </div>
            <div className={`intro-footer-logos-item`}>
              <img src={images.logoEuropeIntro} alt="" />
            </div>
          </div>
        </div>
        <div className="intro-form">
          <SignIn getUser={this.props.getUser} />
          <div className="intro-languages">
            <LanguageSelector />
          </div>
          <div className="intro-from-footer-logos">
            <div className="intro-form-footer-logos">
              <img src={images.logoInfoIntro} alt="" />
            </div>
            <div className="intro-form-footer-logos">
              <img src={images.logoMurcia} alt="" />
            </div>
            <div className="intro-form-footer-logos">
              <img src={images.gobiernoEspana} alt="" />
            </div>
            <div className="intro-form-footer-logos">
              <img src={images.logoEuropeIntro} alt="" />
            </div>
          </div>
    </div>*/}
      </IntroContainer>
    );
  }
}

export default withRouter(Intro);
