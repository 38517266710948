import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const ModalSuperAdminCreateBillStyle = styled.div`
  .superadmin-create-bill {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 33px;
    padding: 48px 32px 32px 32px;
    align-items: start;
    max-width: 780px;


&__file-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:90px;
}

    &-full-length{
      grid-column: 1/-1;
    }

    &__modal-title {
      margin-bottom: 2px;

      grid-column: 1/-1;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: ${colors['SE-002/100']};
      text-transform: uppercase;
    }

    &__invoice-preview{
      height: 100%;
      width:422px;
      background-color: ${colors['NE-002/10']};
      position: relative;
    }

    &__invoice-preview-content-box{
      display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 33px;
    align-items: start;
    width:262px;
    }

    &__invoice-preview-content{
      grid-column: 1/-1;
      margin-bottom:168px;
    }
    &__invoice-preview-label{
      margin-bottom:4px;

      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: ${colors['NE-002/100']};
      text-transform: uppercase;
    }

    &__invoice-preview-value{
      margin-bottom:16px;

      font-size: 12px;
      line-height: 18px;
    }


    &__textarea{
      grid-column: 1/-1;
      
     textarea{
      height:192px;
      &::placeholder{
        color: ${colors['NE-002/100']};
      }
     }
    }

    &__step-title {
      margin-bottom: 12px;

      grid-column: 1/-1;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }

    &__intro {
      margin-bottom: 26px;

      grid-column: 1/-1;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: ${colors['NE-002/100']};
    }

    &__added-lines-title{
        margin-bottom: 14px;

        grip-column: 1/-1;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: ${colors['SE-002/100']};
        grid-column: 1/-1;
    }

    &__added-lines-table{
        margin-bottom: 46px;
        
        grid-column: 1/-1;
        .table{
            min-height: 0px;
        }
    }

    &__amount-box{
        display: grid;
        align-items:flex-end;
        grid-template-columns: 199px 119px
        column-gap: 20px;

        .input-body {
          padding: 10px;
          min-height: 48px;
        }

        p {
          letter-spacing: 0.5px;
        }
    }

    &__buttons-row {
      margin-top: 48px;
      grid-column: 1/-1;
      display: flex;
      & :not(:last-child) {
        margin-right: 12px;
      }
    }

    button {
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }

    .MB-26 {
      margin-bottom: 26px;
    }

    .MB-45 {
      margin-bottom: 45px;
    }

    .MB-20{
      margin-bottom: 20px;
    }

    .MB-16{
      margin-bottom: 16px;
    }

   
  }
`;
