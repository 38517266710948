import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ModalContainer from '../../../../../components/modals/modal-container';
import { BookingDetail } from '../../../../../models/bookings';
import { ModalBookingDetailBodyStyle } from './modal-booking-detail-style';
import { images } from '../../../../../assets/images';
import Button from '../../../../../components/buttons/main-button/button';
import { getDayOfMonth } from '../../../../../utils/time';
import { getDiver } from '../../../../../services/diver';

interface IModalBookingDetailProps {
  showModal: boolean;
  modalClose: Function;
  booking: BookingDetail;
}



const ModalBookingDetailDivers: React.FC<IModalBookingDetailProps> = ({ booking, showModal, modalClose }) => {
  const { t } = useTranslation(['modalMessages', 'layouts']);
  const {
    id,
    bookingId,
    createdAt,
    activity,
    discipline,
    activityDate,
    numDivers,
    price,
    diverId,
    divers,
    status,
  } = booking;

  const formattedDate = getDayOfMonth(t, createdAt);
  const paymentType = '';
  const bookingType = '';
  const prePaidAmount = '';
  const pendingAmount = '';
  const otherDetails = Object.entries({ paymentType, bookingType, prePaidAmount, pendingAmount, price });

  const [diver, setDiver] = useState({
    diver: {
      diverId: '',
      nif: '',
      user: {
        name: '',
        surname: '',
        email: '',
        picture: ''
      },
      id: 0
    }
    
  });
  async function getDiverData(id: any) {
    const params = {
      filter: {
        relations: ['user'],
      },
    };
    const resp = await getDiver(id, params);
    setDiver({ diver: resp.data })
  }

  useEffect(() => {
    getDiverData(booking.diverId);
  }, []);

  /*async getDiverData(id: any){
    const res = getDiver(id)
    console.log(res)
  }*/

  /*const diverData = getDiverData(booking.diverId)*/
  //const getDiverInfo = () => {(
  /*const res = await getDiver(booking.diverId)
  console.log(res)*/
  //)}

  const otherDiversMap = divers.map((item) => (
    <div className="booking-other-details-divers__row">
      <div className="booking-other-details-divers__detail-value">
        <a href={"/centres/" + booking.centreId + "/divers/" + item.id}  target="_blank">
          {item.user.name} {item.user.surname} {item.user.email} </a></div>
    </div>
  ));



  const modalBody = (
    <ModalBookingDetailBodyStyle>
      <div className="booking-title">{t('layouts:bookings.booking.diverData')}</div>
      <div className="booking-diver">
        <div className="booking-diver__data">
          <a href={"/centres/" + booking.centreId + "/divers/" + booking.diverId}  target="_blank"> 
          <img src={diver.diver && diver.diver.user.picture ?
            diver.diver.user.picture : images.ellipse
          } alt="diver img" />
          <div className="booking-diver__info">
            <div className="booking-diver__name">{diver.diver && diver.diver.user.name ?
              diver.diver.user.name : ''
            } {diver.diver && diver.diver.user.surname ?
              diver.diver.user.surname : ''
              } </div>
            <div className="booking-diver__id">{diver.diver && diver.diver.user.email ?
              diver.diver.user.email : ''
            }</div>
          </div>
          </a>
        </div>
      </div>
      <div className="booking-other-details">Otros buceadores de la reserva:</div>
      <div className="booking-other-details-divers">{otherDiversMap}</div>
    </ModalBookingDetailBodyStyle>
  );

  return <ModalContainer active={showModal} modalClose={modalClose} children={modalBody} />;
};

export default ModalBookingDetailDivers;
