import styled from 'styled-components';

export const DivingCenterLayoutContainer = styled.div`
  height: 100%;
  .dive-center-head {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .dive-center-filters {
    display: none;
    &.active {
      display: flex;
    }
    margin-bottom: 40px;
    &__item {
      margin-right: 20px;
      width: 213px;
    }
  }
`;
