import styled, { css } from 'styled-components';
import { mediaqueries } from '../../../constants/mediaqueries';

export const InfoTextContainer = styled.p`
  font-family: 'plain';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  ${props => css`
    color: ${props.color};
  `}

  ${mediaqueries['max-width768']} {
    font-size: 12px;
    line-height: 18px;
  }
`;
