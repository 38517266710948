import services from './services';
import { IConfigurationForm, IContactForm } from '../models/configuration-forms';

export const getConfiguration = () => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      endpoint: 'configuration',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postConfiguration = (data: IConfigurationForm | IContactForm | any): any => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'configuration',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
