import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter, match } from 'react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import IStoreState from '../../../../redux/store/IStoreState';
import HeaderMain from '../../../../components/header-main/header-main';
import { ContentBlock } from '../../../../components/content-block/content-block';
import { MainLayoutSection } from '../../../main-layout-style';
import { BookingsDetailStyle } from './bookings-detail-style';
import Collapsible from 'react-collapsible';
import ContentRight from './content-right/content-right';
import { IActivity } from '../../../../models/activities';
import { getCentreActivity } from '../../../../services/centre';
import { getOneBookings, uploadBookings, cancelBooking, confirmBooking, rejectBooking, getBookingsActivity } from '../../../../services/bookings';
import { getExpirationDate } from '../../../../utils/activities';
import moment from 'moment';
import { mergeObject } from '../../../../utils/other';
import BookingData from './booking-data/booking-data';
import PayData from './pay-data/pay-data';
import Button from '../../../../components/buttons/main-button/button';
import { images } from '../../../../assets/images';
import services from '../../../../services/services';
import ActivityDetailBooking from '../../../planning/activity-detail/booking/activity-detail-booking';
import { getCourse } from '../../../../services/courses';
import { ACTIVITY_STATES_KEY_VALUES } from '../../../../constants/states';

export interface IBookingsDetailProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
    bookingId: string
  }>;
  userData: any;
  t: any;
}

export interface IBookingsDetailState {
  activity?: IActivity;
  status: string;
  booking: any;
  levelName: string;
  resActives: number;
}

class BookingsDetail extends React.Component<IBookingsDetailProps, IBookingsDetailState> {
  constructor(props: IBookingsDetailProps) {
    super(props);
    this.state = {
      status: '',
      booking: {
        services: [],
        divers: [],
        comments: '',
        commentsCentre: '',
        activity: {},
        diverId: null,
        lines: [],
        bookingId: '000000',
        id: 0,
        confirmed: false,
        rejected: false,
        cancelled: false
      },
      resActives: 0,
      levelName: 'Ninguna',
    };
  }

  componentDidMount() {
    this.getBooking()
  }

  async getBooking() {
    const {
      match: {
        params: { id, bookingId },
      },
    } = this.props;
    const params = {
      filter: {
        relations: ['activity', 'divers', 'divers.documents', 'divers.user'],
      },
    };
    const paramss = {
      filter: {
        relations: ['payment', 'payment.paymentType'],
      },
    };

    const res = (await getOneBookings(bookingId, params)).data;
    const res2 = (await getOneBookings(bookingId, paramss)).data;

    const bookingData = mergeObject(res, res2);

    const activityId = bookingData.activity.id
    const params1 = {
      filter: {
        relations: ['dives', 'dives.site', 'services', 'stock'],
      },
    };
    const params2 = {
      filter: {
        relations: ['template', 'template.cancellationPolicy'],
      },
    };
    const params3 = {
      filter: {
        relations: ['bookings', 'bookings.divers', 'divers.user', 'sites'],
      },
    };
    const params4 = {
      filter: {
        relations: ['staff_activities', 'staff_activities.staff', 'staff.user', 'staff.documents'],
      },
    };
    const resp = await getCentreActivity(id, activityId, params1);
    const resp2 = await getCentreActivity(id, activityId, params2);
    const resp3 = await getCentreActivity(id, activityId, params3);
    const resp4 = await getCentreActivity(id, activityId, params4);
    const activity = mergeObject(mergeObject(mergeObject(resp.data, resp2.data), resp3.data), resp4.data);

    if (activity && activity.minimumRequisites && activity.minimumRequisites.level) {
      this.getCourse(activity.minimumRequisites.level.toString())
    }

    const paramsTable = {
      filter: {
        where: { confirmed: true, rejected: false, cancelled: 'noCancelled' },
      }
    };
    const resptable = await getBookingsActivity(activityId, paramsTable)
    let totalDivers = 0;
    for (let i = 0; i < resptable.data.data.length; i++) {
      totalDivers = totalDivers + resptable.data.data[i].divers.length + 1
    }
    this.setState({ resActives: totalDivers });



    const activityDate = getExpirationDate(activity);

    const now = moment();
    let status = '';
    if (activity.finished) {
      status = 'finished';
    } else if (activity.confirmed && activityDate.isBefore(now)) {
      status = 'done';
    } else if (!activity.confirmed && activityDate.isBefore(now)) {
      status = 'undone';
    } else if (activity.confirmed) {
      status = 'confirmed';
    } else {
      status = 'pending';
    }
    if (activity.cancelled) {
      status = 'cancelled';
    }

    this.setState({
      activity, status, booking: {
        services: bookingData.services ? bookingData.services : [],
        divers: bookingData.divers,
        comments: bookingData.comments,
        commentsCentre: bookingData.commentsCentre,
        activity: bookingData.activity,
        createdAt: bookingData.createdAt,
        updatedAt: bookingData.updatedAt,
        diverId: bookingData.diverId,
        confirmationDate: bookingData.confirmationDate,
        lines: bookingData.lines,
        bookingId: bookingData.bookingId,
        id: bookingId,
        confirmed: bookingData.confirmed,
        rejected: bookingData.rejected,
        cancelled: bookingData.cancelled,
      }
    });
  }

  async getCourse(courseId: string) {
    if (courseId === "-1") {
      this.setState({ levelName: "Ninguna" });
    } else {
      const res = await getCourse(courseId);
      this.setState({ levelName: res.data ? res.data.name : "Ninguna" });
    }

  }

  reloadActivity() {
    this.getBooking();
  }

  renderTrigger(title: string, index: number) {
    return (
      <>
        <span className={`title_index title_index_${index}`}>{index}</span>
        <span>{title}</span>
      </>
    )
  }

  updateCommentsState(e: any) {
    this.setState((state) => ({
      booking:
      {
        services: state.booking.services,
        divers: state.booking.divers,
        comments: e,
        activity: state.booking.activity,
        createdAt: state.booking.createdAt,
        diverId: state.booking.diverId,
        confirmationDate: state.booking.confirmationDate,
        lines: state.booking.lines,
        bookingId: state.booking.bookingId,
        id: state.booking.id,
      }
    }));
  }

  async confirmBooking() {
    const {
      match: {
        params: { id, bookingId },
      }, t
    } = this.props;
    const { booking } = this.state;

    await confirmBooking(id, booking.activity.id, bookingId);
    this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmsucc'), 'green');
    this.props.history.goBack()
  }

  async rejectBooking() {
    const {
      match: {
        params: { bookingId },
      }, t
    } = this.props;

    await rejectBooking(bookingId)
    this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmrej'), 'green');
    this.props.history.goBack()
  }

  async cancelBooking() {
    const {
      match: {
        params: { bookingId },
      }, t
    } = this.props;
    await cancelBooking(bookingId, { 'cancellationType': 'canceledByCentre' })
    this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmcancel'), 'green');
    this.props.history.goBack()
  }

  sendNotification = (title: string, msg: string, type: string) => {
    services.pushNotification({
      title,
      text: msg,
      type
    });
  }

  renderOnlyBtnForRequest(bookingId: string) {
    const { userData, t } = this.props;
    return (
      <HeaderMain
        title={`${bookingId} - ${userData.user ? userData.user.name : ''} ${userData.user ? userData.user.surname : ''} `}
        goBack={true}
        mainButton={{
          img: images.checkCircleWhiteSvg,
          text: t('modalMessages:confirmres'),
          onClick: () => { this.confirmBooking() },
        }}
        secondaryButtonBorderRed={{
          img: images.closeRedSvg,
          text: t('modalMessages:rejsol'),
          onClick: () => { this.rejectBooking() },
        }}
      />
    )
  }

  renderOnlyBtnForCancel(bookingId: string) {
    const { userData, t } = this.props;

    return (
      <HeaderMain
        title={`${bookingId} - ${userData.user ? userData.user.name : ''} ${userData.user ? userData.user.surname : ''}`}
        subtitle={ACTIVITY_STATES_KEY_VALUES[this.state.status]}
        goBack={true}
        secondaryButtonBorderRed={{
          img: images.closeRedSvg,
          text: t('modalMessages:modal-cancelation-activity.cancel-res'),
          onClick: () => { this.cancelBooking() },
        }}
      />
    )
  }

  renderOnlyHeader(bookingId: string) {
    const { userData } = this.props;
    return (
      <HeaderMain
        title={`${bookingId} - ${userData.user ? userData.user.name : ''} ${userData.user ? userData.user.surname : ''} `}
        subtitle={ACTIVITY_STATES_KEY_VALUES[this.state.status]}
        goBack={true}
      />
    )
  }

  renderRequest() {
    const { t } = this.props;
    return (
      <>
        <div style={{ marginRight: '1em' }}>
          <Button
            onClick={() => this.confirmBooking()}
            text={t('modalMessages:confirmres')}
            leftIcon={images.checkCircleWhiteSvg}
            type={'primary'}
          />
        </div>
        <Button
          onClick={() => this.rejectBooking()}
          text={t('modalMessages:rejsol')}
          leftIcon={images.closeRedSvg}
          className={'border-red'}
        />
      </>
    )
  }

  renderCancel() {
    const { t } = this.props;
    return (
      <>
        <Button
          onClick={() => this.cancelBooking()}
          text={t('modalMessages:modal-cancelation-activity.cancel-res')}
          leftIcon={images.closeRedSvg}
          className={'border-red'}
        />
      </>
    )
  }


  render() {
    const {
      match: {
        params: { id, bookingId },
      },
      t
    } = this.props;
    const { activity, booking, resActives } = this.state;
    const bcancelled = this.state && this.state.booking && this.state.booking.cancelled !== 'noCancelled' && this.state.booking.cancelled !== false ? t('modalMessages:modal-bookings-refund.rescancelled') : '';

    /*
    REVISAR EL NÜEMRO DE RESERVAS CONFIRMADAS EN LA ACTIVIDAD (buceadores)
    */

    return (
      <>
        <ContentBlock>
          {(activity && !activity.cancelled && activity.bookBefore && (new Date(activity.bookBefore) >= new Date())) ?
            !booking.confirmed && !booking.rejected ? this.renderOnlyBtnForRequest(booking.bookingId)
              : booking.confirmed && booking.cancelled == 'noCancelled' ? this.renderOnlyBtnForCancel(booking.bookingId)
                : booking.rejected ? this.renderOnlyHeader(booking.bookingId) : this.renderOnlyHeader(booking.bookingId)
            : this.renderOnlyHeader(booking.bookingId)

          }
          <MainLayoutSection>
            <BookingsDetailStyle>
              {
                activity && activity.cancelled &&
                <div className="canceledActivity">{activity.cancelled} {t('modalMessages:actcancel')}</div>
              }

              <div className="canceledActivity">{bcancelled}</div>

              <div className="activereservations">

                <p>{t('modalMessages:actw')} {resActives} {resActives !== 1 ? t('modalMessages:confirmdivers') : t('modalMessages:confirmdiver')}</p></div>
              <div className="contents">
                <div className="content-center">
                  <Collapsible trigger={this.renderTrigger(t('modalMessages:actdata'), 1)} transitionTime={200} triggerTagName='div'>
                    {
                      booking && activity && !activity.cancelled ?
                        <ActivityDetailBooking
                          activity={activity}
                          levelName={this.state.levelName}
                          booking={booking}
                          centreId={id}
                          onReload={() => this.reloadActivity()}
                        />
                        : ''
                    }
                  </Collapsible>
                  <Collapsible trigger={this.renderTrigger(t('modalMessages:resdata'), 2)} transitionTime={200} triggerTagName='div' open={true}>
                    <BookingData
                      booking={booking}
                      activity={activity}
                    />
                  </Collapsible>
                  <Collapsible trigger={this.renderTrigger(t('modalMessages:paydata'), 3)} transitionTime={200} triggerTagName='div'>
                    <PayData
                      bookingId={bookingId}
                    />
                  </Collapsible>

                  <div className="footer_btn">
                    {activity && !activity.cancelled && (new Date(activity.bookBefore) > new Date()) &&
                      !booking.confirmed && !booking.rejected ? this.renderRequest()
                      : booking.confirmed && activity && !activity.cancelled && booking.cancelled == 'noCancelled' && (new Date(activity.bookBefore) > new Date()) ? this.renderCancel() : ''
                    }
                  </div>
                </div>
                <div className="content-right">
                  <ContentRight
                    booking={booking}
                    updateCommentsState={(e: any) => this.updateCommentsState(e)}
                  />
                </div>
              </div>
            </BookingsDetailStyle>
          </MainLayoutSection>
        </ContentBlock>
      </>
    )
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    userData: state.userData.value || {}
  };
}

export default withTranslation(['modalMessages'])(connect<{}, {}, {}, IStoreState>(mapStateToProps)(BookingsDetail));
