import React from 'react';
import * as Twilio from 'twilio-chat';
import * as _ from 'lodash';
import moment from 'moment';
import { ChatContainer } from './chat-style';
import { MainLayoutSection } from '../../main-layout-style';
import {
  centreChatLogin,
  getChatUsers,
  getChatActivities,
  archiveChannel,
  recoverChannel,
  deleteChannel,
  orgChatLogin,
  archiveOrgChannel,
  deleteOrgChannel,
  recoverOrgChannel,
} from '../../../services/chat';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
// tslint:disable-next-line: max-line-length
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import SingleButton from '../../../components/single-button/single-button';
import ChatUser from '../../../components/chat-user/chat-user';
import ChatMessage from '../../../components/chat-message/chat-message';
import BodyText from '../../../components/text/body-text/body-text';
import InputBox from '../../../components/input-box';
import Scrollbars from 'react-custom-scrollbars';
import { User } from '../../divers/super-admin/types';
import { getCentre } from '../../../services/centre';
import ModalNewChat from './modals/modal-new-chat/modal-new-chat';
import { match, RouteComponentProps } from 'react-router-dom';
import ModalArchiveChat from './modals/modal-archive-chat/modal-archive-chat';
import ModalRecoverChat from './modals/modal-recovery-chat/modal-recover-chat';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { ContentBlock } from '../../../components/content-block/content-block';
import { getOrganization } from '../../../services/collaborator';

interface ChatProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  t: any;
}

interface ChatState {
  renderModal: string;
  section: string;
  channels: any[];
  users?: User[];
  activeChannel?: any;
  picture: string;
  filter: string;
  message: string;
}

export default class Chat extends React.Component<ChatProps, ChatState> {
  state: ChatState = {
    renderModal: '',
    section: 'all',
    channels: [],
    picture: images.defaultUserSvg,
    filter: '',
    message: '',
  };

  scrollBar: any = null;

  componentDidMount() {
    const isCentre = !!this.getCentreId();
    const organizationId = localStorage.getItem('organizationId');
    if (organizationId) {
      this.chatLoginOrg(organizationId);
      this.getOrgPicture(organizationId);
    } else if (isCentre) {
      this.chatLoginCentre();
      this.getCentrePicture();
    }
  }

  private client: Twilio.Client | undefined;
  private channels: any[] = [];

  async getCentrePicture() {
    const { data } = await getCentre(`${this.getCentreId()}`);
    this.setState({ picture: data.logo });
  }

  async getOrgPicture(orgId: string) {
    const { data } = await getOrganization(orgId);
    this.setState({ picture: data.logo });
  }

  async chatLoginCentre() {
    const { data } = await centreChatLogin(this.getCentreId());
    this.client = await Twilio.Client.create(data.jwt);
    this.retrieveChannels();
  }

  async chatLoginOrg(orgId: string) {
    const { data } = await orgChatLogin(parseInt(orgId, undefined));
    this.client = await Twilio.Client.create(data.jwt);
    this.retrieveChannels();
  }

  async retrieveChannels() {
    if (!this.client) return;
    const { items: channels } = await this.client.getSubscribedChannels();
    this.channels = channels;
    await this.mathToChannels();
  }

  async mathToChannels() {
    if (!this.channels.length) return;

    //Usuarios
    const userEmails = this.channels.map(channel => {
      const name = channel.uniqueName;
      if (!name.includes('activity-')) {
        const members = name.split(',');
        channel.partner = members.find((member: string) => member !== this.getStringId()) || '';
        return channel.partner;
      }
      return '';
    }).filter((activity: any) => activity != '');
    const users = await this.getChatUsers(userEmails);

    //Actividades
    const activitiesId = this.channels.map(channel => {
      const name = channel.uniqueName;
      if (!name.includes('centre-')) {
        const members = name.split('-');
        channel.activity = (members.find((member: string) => member !== 'activity') || '');
        return channel.activity
      }
      return '';
    }).filter((activity: any) => activity != '');
    const activities = await this.getChatActivities(activitiesId);

    this.channels = this.channels.filter(ch => (!ch.attributes || !ch.attributes.deleted));
    this.channels = this.channels.map(channel => {
      channel.messages = [];
      channel.partner = users.find(user => user.email === channel.partner);
      channel.activity = activities.find(activity => activity.id.toString() == channel.activity);
      if (channel.activity) channel.activity.picture = channel.activity.pictures[0];
      return channel;
    });

    this.channelEventListeners();
  }

  async channelEventListeners() {
    for (const channel of this.channels) {
      const { items } = await channel.getMessages();
      channel.messages = items.map((item: any) => item.state);
      channel.lastPartnerMessage = this.getLastPartnerMessage(channel.messages);
      channel.on('messageAdded', (message: any) => {
        this.addMessageToGroup(channel.sid, message.state);
      });
    }
    this.setState({ channels: this.channels });
  }

  getStringId() {
    const organizationId = localStorage.getItem('organizationId');
    return `${organizationId ? 'org' : 'centre'}-${organizationId ? organizationId : this.getCentreId()}`;
  }

  getLastPartnerMessage(messages: any[] = []) {
    const thisCentre = this.getStringId();
    const partnerMessages = messages.filter((message: any) => message.author !== thisCentre);
    const [lastMessage] = partnerMessages.slice(-1);
    return lastMessage;
  }

  formMessageGroups() {
    const { activeChannel, channels } = this.state;
    if (!activeChannel) return [];
    const channel = channels.find(ch => ch.sid === activeChannel);
    const userPicture = channel.partner ? channel.partner.picture : channel.activity.picture;
    const messageGroups = [];
    let group: any = { messages: [] };
    let author;
    for (const message of channel.messages) {
      const dateChanged = this.checkMessageDates(message, group);
      if (dateChanged) {
        messageGroups.push(group, { timestamp: message.timestamp });
        author = message.author;
        group = this.startMessageGroup(author, userPicture);
      } else if (author !== message.author) {
        if (author) messageGroups.push(_.cloneDeep(group));
        author = message.author;
        group = this.startMessageGroup(author, userPicture);
      }
      group.messages.push(message);
    }
    if (group.timestamp || (group.messages && group.messages.length)) messageGroups.push(group);
    return messageGroups;
  }

  startMessageGroup(author: string, userPicture: string) {
    const authorIsCentre = author.includes(this.getStringId());
    const className = authorIsCentre ? 'right' : 'left';
    const picture = authorIsCentre ? this.state.picture : userPicture;
    return { className, picture, messages: [] };
  }

  checkMessageDates(message: any, group: { messages: any[] }) {
    const [lastMessage] = group.messages.slice(-1);
    if (!lastMessage) return true;
    const lastDate = new Date(lastMessage.timestamp);
    const date = new Date(message.timestamp);
    return (
      date.getDate() !== lastDate.getDate() ||
      lastDate.getMonth() !== date.getMonth() ||
      lastDate.getFullYear() !== date.getFullYear()
    );
  }

  addMessageToGroup(channelId: string, message: any) {
    const { channels, activeChannel } = this.state;
    const channelIndex = channels.findIndex((ch: any) => ch.sid === channelId);
    channels[channelIndex].messages = channels[channelIndex].messages || [];
    channels[channelIndex].messages.push(message);
    if (message.auth !== this.getStringId()) {
      channels[channelIndex].lastPartnerMessage = message;
    }
    this.setState({ channels }, () => {
      if (channelId === activeChannel) this.scrollChatBoxToBottom();
    });
  }

  async getChatUsers(emails: string[]) {
    const {
      data: { data },
    } = await getChatUsers(emails);
    return data;
  }

  async getChatActivities(activities: string[]) {
    const {
      data: { data },
    } = await getChatActivities(activities);
    return data;
  }

  getCentreId(): any {
    return _.get(this, 'props.match.params.id');
  }

  sendMessage(body: string) {
    const { channels, activeChannel } = this.state;
    const channel = channels.find(ch => ch.sid === activeChannel);
    if (channel) channel.sendMessage(body);
    this.setState({ message: '' });
  }

  scrollChatBoxToBottom() {
    if (this.scrollBar) this.scrollBar.scrollToBottom();
  }

  setActiveChannel(activeChannel: string) {
    const { channels } = this.state;
    const channel = channels.find(ch => ch.sid === activeChannel);
    if (channel) {
      this.setState({ activeChannel }, this.scrollChatBoxToBottom);
      //this.updateLastConsumedMessage(activeChannel);
    }
  }

  updateLastConsumedMessage(sid: string) {
    const { channels } = this.state;
    const channel = channels.find(ch => ch.sid === sid);
    if (channel) {
      if (this.getCentreId()) channel.setAllMessagesConsumed();
      //channel.lastConsumedMessageIndex = channel.messages.length;
      this.setState({ channels });
    }
  }

  async archiveChannel(channel: string) {
    const organizationId = localStorage.getItem('organizationId');
    if (this.getCentreId()) {
      await archiveChannel(this.getCentreId(), channel);
    } else if (organizationId) {
      await archiveOrgChannel(parseInt(organizationId, undefined), channel);
    }
    this.retrieveChannels();
  }

  async deleteChannel(channel: string) {
    const organizationId = localStorage.getItem('organizationId');
    if (this.getCentreId()) {
      await deleteChannel(this.getCentreId(), channel);
    } else if (organizationId) {
      await deleteOrgChannel(parseInt(organizationId, undefined), channel);
    }
    this.retrieveChannels();
  }

  async recoverChannel(channel: string) {
    const organizationId = localStorage.getItem('organizationId');
    if (this.getCentreId()) {
      await recoverChannel(this.getCentreId(), channel);
    } else if (organizationId) {
      await recoverOrgChannel(parseInt(organizationId, undefined), channel);
    }
    this.retrieveChannels();
  }

  render() {
    const {
      match: {
        params: { id },
      }, t
    } = this.props;
    const { section, channels, activeChannel: channelSID, filter, renderModal, message } = this.state;

    const filteredChannels = filter
      ? channels
      : channels.filter(ch => {
        if (!ch.partner || !ch.partner.name) {
          if (!ch.activity || !ch.activity.name) return false;
          const fullName = `${ch.activity.name}`;
          return fullName.toLowerCase().includes(filter.toLowerCase());
        }
        const fullName = `${ch.partner.name} ${ch.partner.surname}`;
        return fullName.toLowerCase().includes(filter.toLowerCase());
      });

    const activeChannel = channelSID ? channels.find(ch => ch.sid === channelSID) : null;
    const activeChannels = filteredChannels.filter(
      ch => (!ch.attributes || !ch.attributes.archived)
    );
    const archivedChannels = filteredChannels.filter(
      ch => ch.attributes && ch.attributes.archived
    );

    const groups = this.formMessageGroups();
    return (
      <>
        <ContentBlock>
          {/* Header */}
          <HeaderMain
            title={'Mensajes'}
            withSearch={false}
            mainButton={{
              img: images.createWhiteSvg,
              text: 'Nuevo chat',
              onClick: () => this.setState({ renderModal: 'modal-new-chat' }),
            }}
            handleSearch={(filter: string) => this.setState({ filter })}
          />
          {renderModal === 'modal-new-chat' && (
            <ModalNewChat
              centreId={id}
              showModal={renderModal === 'modal-new-chat'}
              onCancel={() => this.setState({ renderModal: '' })}
              onSuccess={() => {
                this.setState({ renderModal: '' }, () => {
                  if (this.getCentreId()) {
                    this.chatLoginCentre();
                    this.getCentrePicture();
                  }
                });
              }}
            />
          )}
          <MainLayoutSection>
            <ChatContainer>
              <div className="chat-users">
                <div className="chat-navigation">
                  <div
                    className={`navigation-item ${section === 'all' ? 'active' : ''}`}
                    onClick={() => this.setState({ section: 'all' })}
                  >
                    <BodyDestacadoText>Todos ({activeChannels.length})</BodyDestacadoText>
                  </div>
                  <div
                    className={`navigation-item ${section === 'archive' ? 'active' : ''}`}
                    onClick={() => this.setState({ section: 'archive' })}
                  >
                    <BodyDestacadoText>Archivados ({archivedChannels.length})</BodyDestacadoText>
                  </div>
                </div>
                <Scrollbars
                  renderTrackVertical={props => <div {...props} className="track-vertical" />}
                  renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
                  style={{ width: '100%', height: 'calc(100vh - 301px)' }}
                  autoHide={true}
                  autoHideTimeout={200}
                  autoHideDuration={200}
                >
                  {section !== 'archive' ? (
                    <div className="chat-users-container">
                      {activeChannels.map((channel, index) => {
                        return (
                          <div className="chat-users-container__item" key={index}>
                            <ChatUser
                              t = { t }
                              className="_"
                              name={channel.partner ? `${channel.partner.name} ${channel.partner.surname}` : channel.activity.name}
                              channel={channel}
                              lastMessage={channel.lastPartnerMessage}
                              avatar={channel.partner ? channel.partner.picture : channel.activity.picture}
                              onClick={() => this.setActiveChannel(channel.sid)}
                              archive={() => {
                                this.setActiveChannel(channel.sid);
                                this.setState({ renderModal: 'modal-archive-chat' });
                              }}
                              remove={() => {
                                this.setActiveChannel(channel.sid);
                                this.setState({ renderModal: 'modal-delete-chat' });
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                      <div className="chat-users-container">
                        {archivedChannels.map((channel, index) => {
                          return (
                            <div className="chat-users-container__item" key={index}>
                              <ChatUser
                                t = { t }
                                className="_"
                                name={channel.partner ? `${channel.partner.name} ${channel.partner.surname}` : channel.activity.name}
                                channel={channel}
                                lastMessage={channel.lastPartnerMessage}
                                avatar={channel.partner ? channel.partner.picture : channel.activity.picture}
                                onClick={() => this.setActiveChannel(channel.sid)}
                                recover={() => {
                                  this.setActiveChannel(channel.sid);
                                  this.setState({ renderModal: 'modal-recover-chat' });
                                }}
                                remove={() => {
                                  this.setActiveChannel(channel.sid);
                                  this.setState({ renderModal: 'modal-delete-chat' });
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                </Scrollbars>
              </div>

              {activeChannel && (
                <div className="chat-conversation">
                  <div className="chat-conversation-top">
                    <div className="chat-conversation-top__name">
                      <SubheadDestacadoText>
                        {activeChannel.partner ? `${activeChannel.partner.name} ${activeChannel.partner.surname}` : `${activeChannel.activity.name}`}
                      </SubheadDestacadoText>
                    </div>
                    <div className="chat-conversation-top__buttons">
                      <div className="chat-conversation-top__buttons__item">
                        <SingleButton
                          className="secondary"
                          icon={
                            activeChannel.attributes && activeChannel.attributes.archived
                              ? images.arrowLeftSvg
                              : images.inboxSvg
                          }
                          onClick={() =>
                            this.setState({
                              renderModal:
                                activeChannel.attributes && activeChannel.attributes.archived
                                  ? 'modal-recover-chat'
                                  : 'modal-archive-chat',
                            })
                          }
                        />
                      </div>
                      <div className="chat-conversation-top__buttons__item">
                        <SingleButton
                          className="secondary"
                          icon={images.delete2Svg}
                          onClick={() => this.setState({ renderModal: 'modal-delete-chat' })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="chat-conversation-container">
                    <Scrollbars
                      renderTrackVertical={props => <div {...props} className="track-vertical" />}
                      renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
                      style={{ width: '100%', height: 'calc(100vh - 334px)' }}
                      autoHide={true}
                      autoHideTimeout={200}
                      autoHideDuration={200}
                      ref={(c: any) => (this.scrollBar = c)}
                    >
                      {groups.map((group: any, index) => {
                        if (group.timestamp) {
                          return (
                            <div className="chat-conversation-container__date" key={index}>
                              <BodyText>{moment(group.timestamp).format('DD [de] MMMM, HH:mm')}</BodyText>
                            </div>
                          );
                        }
                        return (
                          <div className="chat-conversation-container__item" key={index}>
                            <ChatMessage
                              messages={group.messages.map((item: any) => item.body)}
                              className={group.className}
                              picture={group.picture}
                            />
                          </div>
                        );
                      })}
                    </Scrollbars>
                  </div>
                  <div className="chat-conversation-input">
                    <InputBox
                      placeholder="Escribe aquí"
                      className="chat"
                      type="text"
                      value={message}
                      onChange={(val: string) => this.setState({ message: val })}
                      labelText="Usuario"
                      onKeyUp={(e: any) => {
                        if (e.which === 13 && e.target && e.target.value) this.sendMessage(e.target.value);
                      }}
                      icon={images.sendSvg}
                      iconClick={() => this.sendMessage(message)}
                    />
                  </div>
                </div>
              )}
            </ChatContainer>
          </MainLayoutSection>

          {renderModal === 'modal-archive-chat' && (
            <ModalArchiveChat
              onSuccess={() => {
                this.archiveChannel(activeChannel.uniqueName);
                this.setState({ renderModal: '' });
              }}
              onCancel={() => this.setState({ renderModal: '' })}
              showModal={renderModal === 'modal-archive-chat'}
            />
          )}

          {renderModal === 'modal-recover-chat' && (
            <ModalRecoverChat
              onSuccess={() => {
                this.recoverChannel(activeChannel.uniqueName);
                this.setState({ renderModal: '' });
              }}
              onCancel={() => this.setState({ renderModal: '' })}
              showModal={renderModal === 'modal-recover-chat'}
            />
          )}

          {renderModal === 'modal-delete-chat' && (
            <ModalDelete
              showModal={renderModal === 'modal-delete-chat'}
              onCancel={() => this.setState({ renderModal: '' })}
              onRemove={() => {
                this.deleteChannel(activeChannel.uniqueName);
                this.setState({ renderModal: '', activeChannel: null });
              }}
              title="Eliminar chat"
              subtitle="¿Estas seguro que deseas eliminar este chat?"
            />
          )}
        </ContentBlock>
      </>
    );
  }
}
