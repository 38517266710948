export const CENTRES_PER_PAGE: number = 10;
export const ROLES_PER_PAGE: number = 10;
export const DIVERS_PER_PAGE: number = 10;
export const DIVERS_DIVES_PER_PAGE: number = 10;
export const ORGANIZATIONS_PER_PAGE: number = 10;
export const ADS_PER_PAGE: number = 10;
export const NOTIFICATION_DURATION: number = 7;
export const DIVE_SITE_PER_PAGE: number = 10;
export const DIVES_PER_PAGE: number = 10;
export const REVIEWS_PER_PAGE: number = 10;
export const CENTRE_DIVE_OFFERS_PER_PAGE: number = 10;
export const CENTRES_ROLES_PER_PAGE: number = 2;
export const CENTRE_OFFERS_PER_PAGE: number = 10;
export const MAX_ROLES_CENTRES_PER_PAGE: number = 3;
export const PLANNING_HISTORY_PER_PAGE: number = 10;
export const PLANNING_RESERVES_PER_PAGE: number = 10;
export const CENTRE_TICKETS_PER_PAGE: number = 10;
export const CERTIFIER_COURSES_PER_PAGE: number = 10;
export const CERTIFIER_PROJECTS_PER_PAGE: number = 10;
export const CERTIFIER_CENTRES_PER_PAGE: number = 10;
export const CERTIFIER_CATALOG_DETAIL_DIVERS_PER_PAGE: number = 5;
export const CERTIFIER_CENTRE_DETAIL_ACTIVITIES_PER_PAGE: number = 10;
export const ORGANIZATION_ROLES_PER_PAGE: number = 10;
export const ORGANIZATION_PROJECT_DETAIL_DONATIONS_PER_PAGE: number = 5;

export const ROLE_TYPES: { [key: string]: string } = {
  manager: 'Gestor',
  planner: 'Planificador',
  staff: 'Staff'
};

export const ORGANIZATION_ACTION: { [key: string]: string } = {
  certifier: 'courses',
  marineConservation: 'conservationActions',
};
