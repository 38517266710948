import * as React from 'react';
import { PlanningReservationStyle } from './planning-reservations-style';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import MainTable from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import { RouteComponentProps, match } from 'react-router';
import moment from 'moment';
import { copyOf, createFilterRequest } from '../../../utils/other';
import ModalPlanningActivity from '../modal-planning-activity/modal-planning-activity';
import ModalCreateActivities from '../../dive-offers/activities/modal-create-activities/modal-create-activities';
import { ContentBlock } from '../../../components/content-block/content-block';
import MainButton from '../../../components/main-button';
import SelectBox from '../../../components/select-box';
import { getCentreActivitiesTable, getCentreOneStaff } from '../../../services/centre';
import { getBookingsCentre } from '../../../services/bookings';
import { getOneUsers } from '../../../services/users';
import { DiveKinds, DiveDisciplines, DiveCourseTypes } from '../../../constants/dive-dropdowns';
import { PLANNING_CALENDAR_ACTIVITY_STATES as ACTIVITY_STATES_NEW } from '../../../constants/states';
import { CENTRE_SERVICES_KEY_VALUE } from '../../../constants/centre-services';
import _ from 'lodash';
import { normalize } from '../../../utils/texts';
import { CENTRE_DIVE_OFFERS_PER_PAGE } from '../../../constants/data';

moment.locale('ES');

export interface IPlaningReservationProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver?: string;
  }>;
  t: any;
  isValidCentre: boolean;
  staffPermissions?: any;
}
export interface IPlanningCalendarProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver?: string;
  }>;
  isValidCentre: boolean;
  staffPermissions?: any;
}
export interface IPlaningReservationState {
  selectedDate: Date;
  total: number;
  section: string;
  totalPages: number;
  skipPage: number;
  page: number;
  events: any;
  renderModal: string;
  filters: boolean;
  newActivityType: string;
  activities: any[];
  loaded: boolean;
  availableKinds: any[];
  availableKindsCourse: any[];
  availableTypes: any[];
  selectedTypes: string;
  availableIds: any[];
  availableSites: any[];
  availableDisciplines: any[];
  availableStaff: any[];
  filterName: any;
  selectedDiscipline: any[];
  selectedStatus: string;
  selectedStatusActivity: string;
  selectedKinds: string[];
  selectedKindsCourse: string[];
  selectedSites: string[];
  selectedIds: any[];
  selectedStaff: string[];
  searchValue: string;
  activityId?: string;
  selectedActivity?: any;
  resPayments: any[];
  activeFilter: boolean;
  selectedPeriod?: 'month' | 'week' | 'work_week' | 'day' | 'agenda';
  activitiesNames: any;
}
export default class PlaningReservation extends React.Component<IPlaningReservationProps, IPlaningReservationState> {
  constructor(props: IPlaningReservationProps) {
    super(props);

    this.state = {
      selectedDate: new Date(),
      selectedPeriod: 'month',
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
      section: 'all',
      loaded: false,
      filters: false,
      searchValue: '',
      renderModal: '',
      newActivityType: '',
      filterName: '',
      selectedKinds: [],
      selectedKindsCourse: [],
      selectedTypes: '',
      selectedSites: [],
      selectedIds: [],
      selectedStaff: [],
      selectedDiscipline: [],
      selectedStatus: '',
      selectedStatusActivity: '',
      availableKinds: [],
      availableKindsCourse: [],
      availableTypes: [],
      availableIds: [],
      availableSites: [],
      availableStaff: [],
      availableDisciplines: [],
      activities: [],
      events: [],
      resPayments: [],
      activeFilter: false,
      activitiesNames: []
    };
  }

  componentDidMount() {
    let centreId = localStorage.getItem('centreId') + '';
    let diverId = localStorage.getItem('diverId') + '';
    let userId = localStorage.getItem('userId') + '';

    if (diverId === 'null') {
      const diver: any = this.getUserId(userId.toString()).then(() => {
        if (diver) {
          getCentreOneStaff(centreId, diver)
            .then((resp) => {
              for (let i = 0; i < resp.data.staff_centres.length; i++) {
                if (centreId === resp.data.staff_centres[0].centre.id) {
                  localStorage.setItem('staff_centre', JSON.stringify(resp.data.staff_centres[0]));
                }
                break;
              }
            });
        }
      })
    } else {
      getCentreOneStaff(centreId, diverId)
        .then((resp) => {
          for (let i = 0; i < resp.data.staff_centres.length; i++) {
            if (centreId === resp.data.staff_centres[0].centre.id) {
              localStorage.setItem('staff_centre', JSON.stringify(resp.data.staff_centres[0]));
            }
            break;
          }
        });
    }

    this.getAvailables();

    this.getFutureActivities();
  }

  async getUserId(userId: string) {
    const params = {
      filter: {
        relations: ['diver'],
      },
    };
    const resp = await getOneUsers(userId, params);
    if (resp.data) {
      //localStorage.setItem('diverId', resp.data.diver.id)
      return resp.data.diver.id;
    }
  }

  async getAvailables() {

    const {
      match: {
        params: { id },
      },
    } = this.props;

    let kinds: string[] = [];
    let kindsCourse: string[] = [];
    let disciplines: string[] = [];

    const availableKinds: any[] = [];
    const availableKindsCourse: any[] = [];
    const availableSites: any[] = [];
    const availableStaff: any[] = [];
    const availableDisciplines: any[] = [];

    let availableTypes: any[] = [{ value: '*', label: 'Todos' }];
    const sites: string[] = [];
    const staffs: string[] = [];


    let allActivities = (await getCentreActivitiesTable(id, {})).data.data;

    allActivities.forEach((activity: any) => {


      if (activity.kind) {
        if ((availableTypes.filter((type: any) => type.value === "1")).length < 1) {
          availableTypes.push({ value: '1', label: 'Inmersión' });
        }
      } else if (activity.course) {
        if ((availableTypes.filter((type: any) => type.value === "0")).length < 1) {
          availableTypes.push({ value: '0', label: 'Formación' });
        }
      }

      if (activity.template) {
        kinds = _.union(kinds, activity.template.kind);
      }

      if (activity.course) {
        kindsCourse.push(activity.course.kind);
      }

      activity.sites.forEach((site: any) => {
        if (!sites.includes(site.id)) {
          sites.push(site.id);
          availableSites.push({
            value: site.id.toString(),
            label: site.name,
          });
        }
      });


      if (!disciplines.includes(activity.discipline)) {
        disciplines.push(activity.discipline);
      }

      activity.staff_activities.forEach((staff: any) => {
        if (staff.staff && !staffs.includes(staff.staff.id)) {
          staffs.push(staff.staff.id);
          availableStaff.push({
            value: staff.staff.id.toString(),
            label: staff.staff.user ? `${staff.staff.user.name} ${staff.staff.user.surname}` : '',
          });
        }
      });

    });

    DiveKinds.forEach((kind: any) => {
      if (kinds.includes(kind.value)) {
        availableKinds.push(kind);
      }
    });

    DiveCourseTypes.forEach((kind: any) => {
      if (kindsCourse.includes(kind.value)) {
        availableKindsCourse.push(kind);
      }
    });

    DiveDisciplines.forEach((discipline: any) => {
      if (disciplines.includes(discipline.value)) {
        availableDisciplines.push(discipline);
      }
    });

    this.setState({ availableKinds, availableKindsCourse, availableTypes, availableSites, availableStaff, availableDisciplines }, () => {
      this.getTableRows();
    });
  }


  async getFutureActivities() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const { selectedDate, selectedPeriod, skipPage, filterName, selectedTypes, selectedKinds, selectedKindsCourse, selectedDiscipline, selectedStaff, selectedSites, selectedStatus, searchValue } = this.state;

    let customFilters = [];

    const start = !selectedPeriod
      ? moment(selectedDate).startOf('year')
      : selectedPeriod === 'week'
        ? moment(selectedDate).startOf('week')
        : moment(selectedDate).startOf('month');
    const end = moment(selectedDate).endOf('year')


    const paramsPayments = {
      filter: {
        where: {
          createdAt: { method: 'between', values: [start.toISOString(), end.toISOString()], type: 'timestamp' },
        },
      },
    }

    let resPayments = await getBookingsCentre(id, paramsPayments);

    if (resPayments) {
      resPayments = resPayments.data.data;
    }


    //Filtros
    const filters: any = {};
    if (filterName !== '' && filterName !== '*' && filterName) {
      filters.name = { value: filterName };
    }

    if (selectedKinds.length > 0) {
      selectedKinds.forEach((kind: string) => {
        filters.kind = { value: kind, arraySearch: true };
      });
    }

    // if (selectedKindsCourse.length > 0) {
    // filters.course = selectedKindsCourse[0];
    // }

    if (selectedDiscipline.length > 0) {
      selectedDiscipline.forEach((discipline: string) => {
        filters.discipline = { value: discipline };
      });
    }


    if (selectedTypes !== '' && selectedTypes !== '*') {
      filters.courseInfo = { value: null, method: selectedTypes === '1' ? 'is' : 'is not' };
    }

    if (selectedStatus !== '' && selectedStatus !== '*') {
      switch (selectedStatus) {
        case 'done':
          filters.completed = { value: true };
          break;
        case 'cancelled':
          filters.cancelled = { value: true };
          break;
        case 'confirmed':
          filters.confirmed = { value: true };
          break;
        case 'pending':
          filters.confirmed = { value: false };
          break;
        default:
          filters.confirmed = { value: false };
          break;
      }
    }

    // if (selectedSites.length > 0) {
    //   filters.sites = { value: selectedSites[0], method: 'contains' };
    // }

    // if (selectedStaff.length > 0) {
    //   filters.staff_activities = { value: selectedStaff[0], method: 'contains' };
    // }

    if (searchValue) {
      filters.name = { value: searchValue };
    }

    const whereFilter = createFilterRequest(filters);
    whereFilter.date = { method: 'between', values: [start.toISOString(), end.toISOString()], type: 'timestamp' };

    const params = {
      filter: {
        where: whereFilter,
        skip: skipPage,
        limit: CENTRE_DIVE_OFFERS_PER_PAGE,
        orderBy: [{ key: 'date', direction: 'ASC' }]
      },
    };
    let result = (await getCentreActivitiesTable(id, params));
    let activities = result.data.data;

    const totalPages: number = Math.ceil(result.data.count / CENTRE_DIVE_OFFERS_PER_PAGE);


    this.setState({ activities, resPayments, totalPages, total: result.data.count }, () => {
      this.getTableRows();
    });

  }

  getRow(activity: any, id: string) {
    const tableRow = copyOf(this.tableRowTemplate);
    tableRow.date.value = moment(activity.date).format('DD/MM/YYYY');
    tableRow.name.value = activity.name;
    tableRow.type.key = activity.courseInfo ? '0' : '1';
    tableRow.type.value = activity.courseInfo ? 'Formación' : 'Inmersión';
    tableRow.who.value =
      activity.staff_activities && activity.staff_activities.length > 0
        ? `${activity.staff_activities[0].staff.user.name} ${activity.staff_activities[0].staff.user.surname}`
        : '-';
    if (activity.staff_activities && activity.staff_activities.length > 1) {
      tableRow.whoCount.value = {
        title: 'Staff',
        items: activity.staff_activities.map((staff: any) => staff.staff.user && staff.staff.user.name ? `${staff.staff.user.name} ${staff.staff.user.surname}` : 'Staff'),
      };
    }
    tableRow.where.value = activity.sites && activity.sites.length > 0 ? activity.sites[0].name : '';
    if (activity.sites && activity.sites.length > 1) {
      tableRow.whereCount.value = {
        title: 'Puntos de inmersion',
        items: activity.sites.map((site: any) => site.name),
      };
    }
    let diversCount = 0;
    if (activity.bookings) {
      for (const booking of activity.bookings) {
        if (booking.divers && booking.divers.length) diversCount = diversCount + booking.divers.length;
      }
    }
    tableRow.part.value = diversCount.toString();
    //tableRow.status.key = activity.cancelled ? 'cancelled' : activity.confirmed ?: 'pending';
    tableRow.view.link = `/centres/${id}/planning/calendar/${activity.id}`;
    //tableRows.push(tableRow);

    return tableRow;
  }

  getTableRows() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { activities, filters, filterName, selectedKinds, selectedKindsCourse, selectedDiscipline, selectedTypes, selectedSites, selectedStatus, selectedStaff, activitiesNames } = this.state;

    const tableRows: any[] = [];

    activities.forEach((activity: any) => {

      let row: any = [];
      if (filters) {
        if (this.checkValues(activity)) {
          row = this.getRow(activity, id);
        }
      }
      else {
        row = this.getRow(activity, id);
      }

      if (row.name) {
        if (filterName === '' && selectedKinds.length <= 0 && selectedKindsCourse.length <= 0 && selectedDiscipline.length <= 0 && selectedTypes === '' && selectedStatus === '' && selectedSites.length <= 0 && selectedStaff.length <= 0) {
          this.state.activitiesNames.push({ "label": activity.name, "value": activity.name })
        }
        tableRows.push(row);
      }

    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CENTRE_DIVE_OFFERS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ skipPage: skip, page: page }, () => {
        this.getFutureActivities();
      });
    }
  }

  closeModal() {
    this.setState({ renderModal: '' });
    this.clearFilter();
  }

  clearFilter() {
    //this.setState({ filterType: '', filterDiveSite: '', filterStatus: '' }, this.getFutureActivities);
  }

  checkValues(activity: any) {
    const {
      events,
      selectedKinds,
      selectedKindsCourse,
      selectedTypes,
      selectedSites,
      selectedIds,
      selectedStatus,
      selectedStatusActivity,
      selectedStaff,
      selectedDiscipline,
      searchValue,
      selectedPeriod,
      filters,
    } = this.state;
    let filteredEvents = events;

    if (filters && selectedKinds.length > 0) {
      let valid = false;
      selectedKinds.forEach((kind: string) => {
        if (activity.kind && activity.kind.includes(kind)) {
          valid = true;
        }
      });
      return valid;
    }

    if (filters && selectedDiscipline.length > 0) {

      let valid = false;
      selectedDiscipline.forEach((discipline: string) => {
        if (activity.discipline && activity.discipline.includes(discipline)) {
          valid = true;
        }
      });
      return valid;
    }

    if (filters && selectedIds.length > 0) {
      let valid = false;
      selectedIds.forEach((id: string) => {
        if (activity.id.toString() === id) {
          valid = true;
        }
      });
      return valid;
    }

    if (filters && selectedTypes.length > 0) {
      let valid = true;
      return valid;
    }

    if (filters && selectedSites.length > 0) {
      // let valid = false;
      // selectedSites.forEach(site => {
      //   if (activity.sites && activity.sites.map((site: any) => site.id.toString()).includes(site)) {
      //     valid = true;
      //   }
      // });
      // return valid;
      let valid = true;
      return valid;
    }

    if (filters && selectedStaff.length > 0) {
      // let valid = false;
      // selectedStaff.forEach(staff => {
      //   if (activity.staff_activities && activity.staff_activities.map((s: any) => s.staff.id.toString()).includes(staff)) {
      //     valid = true;
      //   }
      // });
      // return valid;
      let valid = true;
      return valid;
    }

    if (filters && selectedStatus !== '' && selectedStatus !== '*') {
      let valid = true;
      return valid;
    }

    if (filters && selectedStatusActivity !== '' && selectedStatusActivity !== '*') {
      let valid = true;
      if (activity.status !== selectedStatusActivity) {
        valid = false;
      }
      return valid;
    }

    if (searchValue !== '') {

      let res = false;

      if (normalize(activity.title).includes(normalize(searchValue))) {
        res = normalize(activity.title).includes(normalize(searchValue))
      }
      if (activity.sites.length > 0) {
        for (let i = 0; i < activity.sites.length; i++) {
          if (normalize(activity.sites[i].name).includes(normalize(searchValue))) {
            res = normalize(activity.sites[i].name).includes(normalize(searchValue))
          }

        }
      }

      if (activity.staff && activity.staff.length > 0) {
        for (let i = 0; i < activity.staff_activities.length; i++) {
          if (activity.staff_activities[i].staff.user.name && normalize(activity.staff_activities[i].staff.user.name).includes(normalize(searchValue))) {
            res = normalize(activity.staff_activities[i].staff.user.name).includes(normalize(searchValue))
          }
          if (activity.staff_activities[i].staff.user.surname && normalize(activity.staff_activities[i].staff.user.surname).includes(normalize(searchValue))) {
            res = normalize(activity.staff_activities[i].staff.user.surname).includes(normalize(searchValue))
          }
        }
      }

      if (activity.bookings.length > 0) {
        for (let i = 0; i < activity.bookings.length; i++) {
          if (activity.bookings[i].bookingId && normalize(activity.bookings[i].bookingId).includes(normalize(searchValue))) {
            res = normalize(activity.bookings[i].bookingId).includes(normalize(searchValue))
          }
          if (activity.bookings[i].diverData && activity.bookings[i].diverData.user) {
            if (activity.bookings[i].diverData.user.name && normalize(activity.bookings[i].diverData.user.name).includes(normalize(searchValue))) {
              res = normalize(activity.bookings[i].diverData.user.name).includes(normalize(searchValue))
            }
            if (activity.bookings[i].diverData.user.email && normalize(activity.bookings[i].diverData.user.email).includes(normalize(searchValue))) {
              res = normalize(activity.bookings[i].diverData.user.email).includes(normalize(searchValue))
            }
          }

        }
      }

      if (activity.services.length > 0) {
        for (let i = 0; i < activity.services.length; i++) {
          if (activity.services[i].name && normalize(CENTRE_SERVICES_KEY_VALUE[activity.services[i].name]).includes(normalize(searchValue))) {
            res = normalize(CENTRE_SERVICES_KEY_VALUE[activity.services[i].name]).includes(normalize(searchValue))
          }
        }
      }


      return res;

    }

    if (selectedPeriod === 'month') {
      filteredEvents = filteredEvents.map((event: any) => ({
        ...event,
        title: `${event.title} - (${event.hour})`,
      }));
    }
    return filteredEvents;
  }

  searchTimeout: any;
  handleSearch(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState({ searchValue: value, page: 1, skipPage: 0 }, () => {
        this.getFutureActivities();
      });
    }, 500);
  }

  render() {
    const {
      match: {
        params: { id },
      },
      staffPermissions,
    } = this.props;
    const {
      renderModal,
      filters,
      newActivityType,
      activityId,
      availableKinds,
      availableKindsCourse,
      availableTypes,
      availableSites,
      availableDisciplines,
      availableStaff,
      selectedKinds,
      selectedKindsCourse,
      selectedTypes,
      selectedSites,
      selectedStatus,
      selectedDiscipline,
      selectedStaff,
      totalPages,
      page,
      total,
      activeFilter,
      activitiesNames
    } = this.state;

    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Planificación'}
            withSearch={true}
            resultCount={total}
            handleSearch={(value: string) => this.handleSearch(value)}
            mainButton={
              !staffPermissions || staffPermissions.activities
                ? {
                  img: images.arrowUpRightWhiteSvg,
                  text: 'Planificar nueva',
                  onClick: () => this.setState({ renderModal: 'modal-plan-activity' }),
                }
                : undefined
            }
          />


          <MainLayoutSection>
            <PlanningReservationStyle>
              <div className="planning-reservations-head">
                <div className="planning-reservations-head__filter">
                  <MainButton
                    onClick={() =>
                      this.setState({ filters: !filters }, () => {
                        // if (activeFilter) this.clearFilter();
                      })
                    }
                    type={filters ? 'secondary-active' : 'secondary'}
                    text="Filtrar"
                    iconImg={activeFilter ? images.filtersLightBlueSvg : images.filtersSvg}
                  />
                </div>
              </div>

              {filters && (
                <div className={`planning-reservations-filters${filters ? ' active' : ''}`}>
                  <div className="planning-reservations-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Nombre Actividad"
                      optionsText={[{ label: 'Todos', value: '*' }].concat(activitiesNames)}
                      className="rounded-filter"
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        this.setState({ filterName: value, page: 1, skipPage: 0 }, () => {
                          this.getFutureActivities();
                        });
                      }}
                    />
                  </div>
                  <div className="planning-reservations-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Tipo de actividad"
                      required={true}
                      className="rounded-filter"
                      multiple={false}
                      optionsText={availableTypes}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={selectedTypes}
                      initialValue={selectedTypes}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => {
                        this.setState({ selectedTypes: value, page: 1, skipPage: 0 }, () => {
                          this.getFutureActivities();
                        });
                      }}

                    />
                  </div>
                  <div className="planning-reservations-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Tipo de inmersión"
                      required={true}
                      className="rounded-filter"
                      multiple={true}
                      optionsText={availableKinds}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedKinds}
                      initialMultipleValues={selectedKinds}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: any[]) => {
                        this.setState({ selectedKinds: values }, () => {
                          this.getFutureActivities();
                        });
                      }}
                    />
                  </div>
                  {/* <div className="planning-reservations-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Tipo de curso"
                      required={true}
                      className="rounded-filter"
                      multiple={true}
                      optionsText={availableKindsCourse}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedKindsCourse}
                      initialMultipleValues={selectedKindsCourse}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: any[]) => {
                        this.setState({ selectedKindsCourse: values }, () => {
                          this.getFutureActivities();
                        });
                      }}
                    />
                  </div> */}
                  <div className="planning-reservations-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Disciplina"
                      required={true}
                      className="rounded-filter"
                      multiple={true}
                      optionsText={availableDisciplines}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedDiscipline}
                      initialMultipleValues={selectedDiscipline}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: any[]) => {
                        this.setState({ selectedDiscipline: values }, () => {
                          this.getFutureActivities();
                        });
                      }}
                    />
                  </div>
                  {/* <div className="planning-reservations-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Staff"
                      required={true}
                      multiple={true}
                      className="rounded-filter"
                      optionsText={availableStaff}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedStaff}
                      initialMultipleValues={selectedStaff}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: string[]) => {
                        this.setState({ selectedStaff: values }, () => {
                          this.getFutureActivities();
                        });
                      }}
                    />
                  </div> */}
                  {/* <div className="planning-reservations-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Punto de inmersión"
                      required={true}
                      multiple={true}
                      className="rounded-filter"
                      optionsText={availableSites}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedSites}
                      initialMultipleValues={selectedSites}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: string[]) => {
                        this.setState({ selectedSites: values, page: 1, skipPage: 0 }, () => {
                          this.getFutureActivities();
                        });
                      }}
                    />
                  </div> */}
                  <div className="planning-reservations-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Estado Reservas"
                      required={true}
                      className="rounded-filter"
                      optionsText={[{ value: '*', label: 'Todos' }].concat(ACTIVITY_STATES_NEW)}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={selectedStatus}
                      initialValue={selectedStatus}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => {
                        this.setState({ selectedStatus: value, page: 1, skipPage: 0 }, () => {
                          this.getFutureActivities();
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              {/* <NavigationTabContainer>
                <div
                  className={`navigation-item ${section === 'all' && 'active'}`}
                  onClick={() => this.setState({ section: 'all' }, () => this.getFutureActivities())}
                >
                  <BodyDestacadoText>Todas</BodyDestacadoText>
                </div>
                <div
                  className={`navigation-item ${section === 'requests' && 'active'}`}
                  onClick={() => this.setState({ section: 'requests' }, () => this.getFutureActivities())}
                >
                  <BodyDestacadoText>Solicitudes</BodyDestacadoText>
                </div>
              </NavigationTabContainer> */}
              <div className="planning-reservation-table">
                <MainLayoutTableContainer>
                  <div className="diving-center-table">
                    <MainTable
                      columns={this.tableColumns}
                      rows={this.getTableRows()}
                      rowKeys={this.rowKeys}
                      onOrder={() => { }}
                    />
                  </div>
                  {totalPages > 1 && (
                    <div className="diving-center-pagination">
                      <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                    </div>
                  )}
                </MainLayoutTableContainer>
              </div>
            </PlanningReservationStyle>
            {renderModal === 'modal-plan-activity' && (
              <ModalPlanningActivity
                onCreateActivity={(activitiesType: string) =>
                  this.setState({ newActivityType: activitiesType, renderModal: 'modal-create-activity' })
                }
                onSuccess={() => {
                  this.setState({ activityId: undefined }, () => {
                    this.closeModal();
                  });
                }}
                centreId={id}
                onCancel={() => this.closeModal()}
                showModal={renderModal === 'modal-plan-activity'}
                activityId={activityId}
              />
            )}
            {renderModal === 'modal-create-activity' && (
              <ModalCreateActivities
                newActivityType={newActivityType}
                centreId={id}
                onSuccess={(id?: string) => {
                  this.setState({ activityId: id, renderModal: 'modal-plan-activity' });
                }}

                onCancel={() => this.closeModal()}
                showModal={renderModal === 'modal-create-activity'}
              />
            )}
          </MainLayoutSection>
        </ContentBlock>
      </>
    );
  }

  rowKeys = ['emp', 'date', 'name', 'type', 'who', 'whoCount', 'where', 'whereCount', 'part', 'status', 'view'];

  tableColumns = [
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Fecha de salida',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Nombre actividad',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Tipo de actividad',
      filter: false,
      ascendent: true,
    },
    {
      text: 'staff asignado',
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: false,
    },
    {
      text: 'Punto de inmersión',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Buceadores',
      filter: false,
      ascendent: true,
    },/*
    {
      text: 'Estado actividad',
      filter: false,
      ascendent: true,
    },*/
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    emp: {
      key: 'icon',
      value: images.bookPlanification,
    },

    date: {
      key: 'highlighted-body',
      value: '12/07/2019',
    },
    name: {
      key: '',
      value: 'Inmersión lorem ipsum dolor',
    },
    type: {
      key: '',
      value: 'Inmersión',
    },
    who: {
      key: '',
      value: 'Pepe González Gil',
    },
    whoCount: {
      key: 'count',
      value: '',
    },
    where: {
      key: '',
      value: 'Playa Los Arrecifes',
    },
    whereCount: {
      key: 'count',
      value: '',
    },
    part: {
      key: '',
      value: '12',
    },/*
    status: {
      key: 'icon',
      value: images.stateReject,
    },*/
    view: {
      key: 'detail',
      value: images.eyeSvg,
    },
  };
}
