import styled from 'styled-components';

export const FAQStyle = styled.div`
  .faq-section  {
    margin-bottom: 60px;
    &__title {
      margin-bottom: 24px;
    }
    &__text {
      p {
        margin-bottom: 24px;
        &:last-child  {
          margin-bottom: 0px;
        }
      }
    }
  }
  a {
    color:rgb(0, 161, 165);
  }
`;
