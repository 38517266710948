import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const InputButtonFilesContainer = styled.div`
  width: 100%;
  cursor: pointer;
  &.dark-blue-label {
    .item-modal-message-label {
      color: #1948a0;
      padding-left: 0;
    }
  }

  &.error {
    .item-modal-message-drag {
      &__dashed::before {
        border: 4px dashed #fd4a22;
      }
    }
    .input-box-error {
      opacity: 1;
      visibility: visible;
      color: #fd4a22;
    }
  }

  .item-modal-message-drag {
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    /* margin-bottom: 12px; */

    input {
      position: absolute;
      width: 100%;

      /* opacity: 0; */
      top: 0;
      left: 0;
      height: 100%;
      z-index: 2;
    }
    &__dashed {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      text-align: center;
      padding: 10px;
      margin-bottom: 20px;

      &::before {
        content: '';
        position: absolute;
        border: 4px dashed #bcc5d7;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
      }
    }
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &__icon {
      margin-right: 20px;
      width: 36px;
      pointer-events: none;
      z-index: 2;
    }

    &__text {
      pointer-events: none;
      max-width: 200px;
      color: ${colors['NE-002/100']};
      z-index: 2;
      span {
        font-weight: 500;
      }
    }
  }

  .item-modal-message-disclaimer {
    margin-bottom: 32px;
    color: ${colors['NE-002/100']};
  }
  .item-modal-message-bottom {
    display: flex;
    justify-content: space-between;
    &__left {
      display: flex;
      .item-modal-message-button {
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .item-modal-message-button {
    width: 140px;
  }

  .item-modal-message-counter {
    margin-left: 32px;
    display: flex;
    align-items: center;
    line-height: normal;
    font-size: 16px;
    text-align: right;
    color: #004f8b;

    &__icon {
      margin-top: 3px;
    }
  }

  .item-modal-message-select  {
    margin-bottom: 40px;
  }

  .item-modal-message-label  {
    color: ${colors['NE-002/100']};
    text-transform: uppercase;
    /* padding-left: 12px; */
    transition: all 0.3s ease-in-out;
    margin-bottom: 6px;
  }

  &.required {
    .item-modal-message-label  {
      position: relative;
      padding-left: 12px;
      &::before  {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 8px;
        background: ${colors['PR-001/100']};
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .item-modal-message-docs {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 40px;
    &.full {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &.full {
    .item-modal-message-drag  {
      height: 300px;
      &__dashed  {
        height: 100%;
        margin-bottom: 0px;
      }
    }
  }
  &.multiple {
    .item-modal-message-drag  {
      height: 140px;
      margin-bottom: 0px;
      &__dashed  {
        height: 100%;
        margin-bottom: 0px;
      }
    }
  }
`;
