import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './booking-form-another-services.module.scss'
import TextText from '../../../../../components/text/text-text/text-text';
import { connect, useSelector } from 'react-redux';
import IStoreState from '../../../../../redux/store/IStoreState';
import InfoText from '../../../../../components/text/info-text/info-text';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import MainButton from '../../../../../components/main-button';
import { getOneBookings, uploadBookings } from '../../../../../services/bookings';
import services from '../../../../../services/services';
import { withRouter } from 'react-router';
import { getServices } from '../../../../../services/centre';
import { setBookingLines } from '../../../../../redux/actions';

interface IBookingFormAnotherServicesProps {
  booking: {};
}

const BookingFormAnotherServices = (props: any): JSX.Element => {
  const { t } = useTranslation(['modalMessages', 'layouts']);
  const [bookingServices, setBookingServices] = useState<any[]>([]);
  const [servicesSelected, setServicesSelected] = useState<any[]>([]);
  const [accountUser, setAccountUser] = useState([]);
  const [centreServices, setCentreServices] = useState<any>([])

  useEffect(() => {
    getServicesByCentre()
  }, [])

  const getServicesByCentre = async () => {
    const {
      match: {
        params: { id },
      },
    } = props;
    const res = await getServices(id);
    const centreServicesData = res.data.data;
    setCentreServices(centreServicesData)
    const bookingLines = checkBookingLines(centreServicesData)
    const differentServices = getDifferentServices(bookingLines);
    setBookingServices(differentServices)
  };

  const checkBookingLines = (centreServices: any[]) => {
    const bookingLines = props.bookingLines;
    let selected: any = []
    for (let i = 0; i < centreServices.length; i++) {
      const element1 = centreServices[i].description.es;
      centreServices[i].checked = false;
      for (let j = 0; j < bookingLines.length; j++) {
        const element2 = bookingLines[j].concept
        if (element1 === element2) {
          centreServices[i].checked = true;
          selected = [...selected, centreServices[i]]
          break;
        }
      }
    }
    setServicesSelected(selected)
    return centreServices;
  }

  const getDifferentServices = (centreServices: any) => {
    const activityServices = props.activity.services;
    const different = centreServices.filter((item1: any) => !activityServices.some((item: any) => item1.description.es === item.description.es));
    return different
  }

  const renderAnotherServicesAvailable = (item: any, i: number) => {
    return (
      <div className={styles.collapsible} key={i}>
        <DicotomicCheckbox
          key={i}
          checked={servicesSelected.includes(item)}
          onCheck={checked => handleCheck(checked, item)}
        >
          <div className="create-activities-dicotomic-container">
            {/*<div className="create-activities-dicotomic-container__icon">*/}
            {/*  /!*<img src={images.seaSvg} alt="" />*!/*/}
            {/*</div>*/}
            <div className="create-activities-dicotomic-container__text">
              <InfoText>{item.description.es} - ({item.price / 100} €)</InfoText>
            </div>
          </div>
        </DicotomicCheckbox>
      </div>
    )
  }

  const handleCheck = (checked: boolean, service: any) => {
    if (checked) { // add
      setServicesSelected([...servicesSelected, service])
    } else {
      const res = servicesSelected.filter(el => el.id !== service.id);
      setServicesSelected(res)
    }
  }

  const save = async () => {
    const {
      match: {
        params: { bookingId },
      },
    } = props;
    const params = {
      filter: {
        relations: ['divers'],
      },
    };
    const linesUpdated = getLinesFromServices()
    if (bookingId !== undefined) {  // it is edit booking
      const booking = (await getOneBookings(bookingId, params)).data;
      booking.lines = linesUpdated;
      await uploadBookings(bookingId, booking);
      props.onNext(linesUpdated)
      services.setValue(setBookingLines(linesUpdated))
      sendNotification(t('layouts:bookings.confirmed'), t('layouts:bookings.booking.updated'), 'green');
    } else { // it is create booking
      props.onNext(linesUpdated)
      services.setValue(setBookingLines(linesUpdated))
    }
  }

  const getLinesFromServices = () => {
    const lines = []
    for (const service of servicesSelected) {
      lines.push({ id: service.id, price: service.price, discount: 0, concept: service.description.es });
    }
    return lines
  }

  const sendNotification = (title: string, msg: string, type: string) => {
    services.pushNotification({
      title,
      text: msg,
      type,
    });
  }

  return (
    <>
      <div className={styles.header}>
        <TextText color={'#00A1A5'}>Otros servicios extras</TextText>
      </div>
      {
        bookingServices.map((item: any, i: number) => {
          return (
            renderAnotherServicesAvailable(item, i)
          )
        })
      }
      <div className={styles.footer_btn}>
        <MainButton text={t('layouts:billing-information.save')} type="primary" onClick={() => save()} />
      </div>
    </>
  );
};

function mapStateToProps(state: IStoreState) {
  return {
    // bookingLines: state.bookingLines.value,
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(BookingFormAnotherServices));
