import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const DiverProfileContainer = styled.div`
  .my-divers-personal {
    display: flex;
    justify-content: space-between;
  }

  .my-divers-personal-left  {
    width: 450px;
    margin-right: 60px;
  }
  .my-divers-personal-right {
    flex: auto;
  }
  .my-divers-personal-left-avatar  {
    /*height: 250px;*/
    overflow: hidden;
    background: #c4c4c4;
    border-radius: 5px;
    margin-bottom: 40px;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .my-divers-personal-left-top  {
  }

  .my-divers-personal-left-name  {
    text-align: center;
    margin-bottom: 52px;
    padding: 0 31px;
    color: #000019;
  }

  .my-divers-personal-left-resume {
    background: ${colors['NE-002/10']};
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 19px 20px;
    margin-bottom: 56px;
    flex-wrap: wrap;
    &__title {
      color: ${colors['PR-001/100']};
    }
    &__subtitle  {
      color: ${colors['SE-002/100']};
      text-transform: uppercase;
    }
    &__dive  {
      margin-right: 24px;
      min-width: 100px;
    }
    &__last  {
      margin-right: 24px;
      min-width: 100px;
    }
    &__spots  {
      min-width: 100px;
    }
  }

  .my-divers-personal-left-staff {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    &__name {
      text-align: center;
      margin-bottom: 20px;
      padding: 0 31px;
      color: #000019;
    }
    &__center {
      margin-bottom: 12px;
      color: ${colors['NE-002/100']};
    }
    &__rate {
    }

    &__roles {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      column-gap: 25px;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 10px;
      justify-content: center;
    }

    &__staff {
      color: ${colors['SE-005/100']};
    }

    &__instructor {
      color: ${colors['SE-001/100']};
    }
  }

  .my-divers-personal-left-section {
    margin-bottom: 52px;
    &__title  {
      color: ${colors['NE-002/100']};
      padding-bottom: 8px;
      border-bottom: 1px solid ${colors['NE-002/100']};
      margin-bottom: 24px;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      &__edit {
        color: ${colors['PR-001/100']};
        text-transform: capitalize;
        cursor: pointer;
      }
    }

    &__specialty {
      &__item {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0px;
          &__title {
            color: ${colors['NE-001/100']};
          }
          &_date {
            color: ${colors['NE-001/50']};
          }
        }
      }
    }

    &__validation {
      &__item {
        &__title {
          color: ${colors['NE-001/100']};
          margin-bottom: 4px;
        }
        &__date {
          color: ${colors['NE-001/50']};
        }
      }
    }

    &__item {
      margin-bottom: 15px;
      &__title  {
        margin-bottom: 4px;
      }
      &__subtitle {
        color: ${colors['NE-001/50']};
      }
    }
  }

  .my-divers-personal-right-title {
    display: flex;
    border-bottom: 1px solid ${colors['NE-002/100']};
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 40px;
    width: 100%;
    &__icon {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }
    &__text {
      color: ${colors['NE-001/100']};
    }
  }
  .my-divers-personal-right-subtitle {
    color: ${colors['NE-002/100']};
    margin-bottom: 24px;
    text-transform: uppercase;
  }
  .my-divers-personal-right-item {
    display: flex;
    &:last-child {
      margin-bottom: 0px;
    }
    margin-bottom: 20px;
    &__title {
      width: 180px;
    }
    &__text {
      &.capitalize {
        text-transform: capitalize;
      }
    }
  }

  .my-divers-personal-right-table {
    margin-bottom: 52px;
    color: ${colors['NE-001/100']};
  }
`;
