import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const MarineReserveDetailStyle = styled.div`
  margin-bottom: 160px;
  .dive-site-detail-hero {
    margin-bottom: 120px;
  }

  .dive-site-detail-next {
    margin-bottom: 40px;
    &__title  {
      margin-bottom: 40px;
    }
    &__text {
      color: ${colors['NE-002/100']};
    }
  }

  .dive-site-detail-next-container  {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
`;
