import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const SingleButtonContainer = styled.div`
  border-radius: 5px;
  position: relative;
  .single-button-icon {
    padding: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .single-button-drop {
    z-index: 9;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;

    top: -12px;
    right: calc(100% + 15px);

    .single-button-drop-veil   {
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .single-button-drop-container {
      position: relative;
      z-index: 9;
      background: white;
      box-shadow: 0px 0px 6px rgba(0, 0, 25, 0.08);
    }

    &::after  {
      content: '';
      width: ;
    }
    &__item {
      display: flex;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;
      padding: 12px;
      transition: 0.3s all ease-in-out;
      position: relative;
      z-index: 1;

      &:hover {
        background: #f8fafd;
      }

      &:last-child {
        margin-bottom: 0px;
      }
      &__icon {
        width: 32px;
        margin-right: 12px;
      }
    }
  }
  &.small {
    width: 32px;
    .single-button-icon {
      padding: 5px;
    }
  }
  &.table {
    width: 32px;
    .single-button-icon {
      padding: 0px;
    }
  }
  &.openDrop {
    .single-button-drop {
      opacity: 1;
      visibility: visible;
    }
  }

  &.primary {
    background: ${colors['PR-001/100']};
    color: white;
    &:hover {
      background: ${colors['PR-001/75']};
    }
  }
  &.secondary {
    background: ${colors['NE-002/10']};
    color: ${colors['NE-001/100']};
  }
  &.error {
    background: ${colors['CO-003/100']};
    color: white;
    &:hover {
      background: ${colors['CO-003/75']};
    }
  }
  &.success {
    background: ${colors['CO-001/100']};
    color: white;
    &:hover {
      background: ${colors['CO-001/75']};
    }
  }
  &.disabled {
    background: ${colors['CO-001/100']};
    color: ${colors['NE-003/100']};
  }
  &.modal {
    width: 40px;
    height: 40px;
  }
  &.full-height {
    width: 48px;
    height: 48px;
  }

  &.light-blue {
    color: ${colors['PR-001/100']};
    background: none;
  }
  &.light-grey {
    color: ${colors['NE-003/100']};
    background: none;
    &:hover {
      color: ${colors['NE-001/100']};
    }
  }
`;
