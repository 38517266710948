import { TableColumn } from '../../types/table';

export interface Incidence {
  id: string;
  ref: string;
  refName: string;
  type: string;
  date: string;
  status: string;
}

export const filtersBySection: { [key: string]: string[] } = {
  business: ['centre'],
  sites: ['site'],
  pictures: ['centre-pictures', 'dive-site-pictures', 'room-picture'],
};

export const filtersByStatus: { [key: string]: string[] } = {
  open: ['open', 'solved'],
  closed: ['closed'],
};

const sectionName: { [key: string]: string } = {
  business: 'Centro',
  sites: 'Punto de inmersión',
  pictures: 'Referencia',
};

export const getIncidencesTableColumns = (section: string, closed: boolean): TableColumn[] => [
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: sectionName[section],
    filter: false,
    ascendent: true,
  },
  {
    text: 'Tipo de incidencia',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Fecha',
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: false,
  },
  ...(!closed
    ? [
        {
          text: '',
          filter: false,
          ascendent: false,
        },
      ]
    : []),
];

export const getIncidencesRowKeys = (closed: boolean): string[] => [
  'status',
  'ref',
  'type',
  'date',
  'detail',
  ...(!closed ? ['options'] : []),
];
