import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalCreateStaffContainer = styled.div`
  padding: 36px 32px 32px 32px;
  width: 416px;
  .create-user-form {
    margin-bottom: 40px;
    &__title  {
      color: ${colors['SE-002/100']};
      margin-bottom: 14px;
    }
  }
  .create-user-buttons {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .create-user-role-container  {
    margin-bottom: 48px;
  }

  .create-staff-container {
  }

  .modal-create-staff-step {
    margin-bottom: 2px;
    color: ${colors['SE-002/100']};
  }
  .modal-create-staff-title {
    margin-bottom: 2px;
    color: ${colors['NE-001/100']};
    margin-bottom: 40px;
  }

  .create-staff-section {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .create-staff-section-title {
    display: flex;
    justify-content: space-between;
    color: ${colors['SE-002/100']};
    margin-bottom: 4px;
  }

  .create-staff-section-list {
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .create-staff-button-second {
    display: flex;
    &__arrow {
      margin-right: 12px;
    }
    &__save {
      flex: auto;
    }
  }
`;
