import React, { Component } from 'react';
import { MainLayout, MainLayoutContent } from '../containers/main-layout-style';
import OrganizationMenu from '../components/organization-menu/organization-menu';
import { Switch, Route, withRouter, RouteComponentProps, match } from 'react-router';
import CertifierProfileLayout from '../containers/certifier/certifier-profile/certifier-profile';
import CertifierProjectDetail from '../containers/certifier/certifier-project/certifier-project-detail/certifier-project-detail';
import CertifierProject from '../containers/certifier/certifier-project/certifier-project-layout';
import CertifierCatalogDetail from '../containers/certifier/certifier-catalog/certifier-catalgo-detail/certifier-catalog-detail';
import DiverDiveDetail from '../containers/divers/detail/dives/detail/diver-dive-detail';
import Chat from '../containers/messages/chat/chat';
import DiverDetail from '../containers/divers/detail/diver-detail';
import CertifierCatalog from '../containers/certifier/certifier-catalog/certifier-catalog-layout';
import CertifierCenterDetail from '../containers/certifier/certifier-center/certifier-center-detail/certifier-center-detail-layout';
import CertifierCenter from '../containers/certifier/certifier-center/certifier-center-layout';
import CertifierDivers from '../containers/certifier/certifier-divers/certifier-divers-layout';
import OrganizationDonations from '../containers/certifier/organization-donations/organization-donations';
import CertifierFaq from '../containers/certifier/certifier-faq/certifier-faq-layout';
import StatsCertifierLayout from '../containers/stats/stats-certifier-layout';
import StatsOrganizationLayout from '../containers/stats/stats-organization-layout';
import NotificationsDetail from '../containers/notifications/detail/notifications-detail';
import Notifications from '../containers/notifications/notifications';
import CertifierRoles from '../containers/certifier/certifier-roles/certifier-roles';
import { getOrganization } from '../services/collaborator';
import BillingInformation from '../containers/billing/billing-information/billing-information';

interface OrganizationPanelProps extends RouteComponentProps {
  match: match<{
    id: string;
    organizationId: string;
  }>;
  t: any;
  onLogout: () => void;
  profilePicture?: string;
}

interface OrganizationPanelState {
  isCertifier?: boolean;
}

class OrganizationPanel extends Component<OrganizationPanelProps, OrganizationPanelState> {
  constructor(props: OrganizationPanelProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getOrganization();
  }

  async getOrganization() {
    const {
      match: {
        params: { organizationId },
      }
    } = this.props;
    if (isNaN(+organizationId)) {
      const localOrganizationId = localStorage.getItem('organizationId');
      if (!localOrganizationId || isNaN(+localOrganizationId)) {
        this.props.history.replace('/');
      }
    }
    const id = !isNaN(+organizationId) ? organizationId : localStorage.getItem('organizationId');
    if (!id) {
      return;
    }
    const resp = await getOrganization(id);
    const isCertifier = resp.data.type === 'certifier';
    localStorage.setItem('organizationId', id);
    if (isCertifier) localStorage.setItem('isCertifier', 'true');
    else localStorage.removeItem('isCertifier');
    this.setState({ isCertifier });
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t,
      profilePicture,
    } = this.props;
    const { isCertifier } = this.state;
    const basePath = '/organization/:organizationId';
    return (
      <>
        {isCertifier === undefined ? (
          <></>
        ) : (
          <MainLayout>
            {/* Menu */}
            <OrganizationMenu centreId={id} onLogout={this.props.onLogout} profilePicture={profilePicture} />
            {/* MainLayout */}
            <MainLayoutContent>
              <Switch>
                <Route path={`${basePath}/profile`} component={CertifierProfileLayout} />
                {!isCertifier && <Route path={`${basePath}/projects/:id`} component={CertifierProjectDetail} />}
                {!isCertifier && <Route path={`${basePath}/projects`} component={CertifierProject} />}
                {isCertifier && <Route path={`${basePath}/catalog/:id`} component={CertifierCatalogDetail} />}
                {isCertifier && <Route path={`${basePath}/divers/:diver/dives/:diveId`} component={DiverDiveDetail} />}
                <Route path={`${basePath}/divers/:id`} component={DiverDetail} />
                {isCertifier && <Route path={`${basePath}/catalog`} component={CertifierCatalog} />}
                {isCertifier && <Route path={`${basePath}/centres/:id`} component={CertifierCenterDetail} />}
                {isCertifier && <Route path={`${basePath}/centres`} component={CertifierCenter} />}
                {isCertifier && <Route path={`${basePath}/divers`} component={CertifierDivers} />}
                {!isCertifier && <Route path={`${basePath}/divers`} component={OrganizationDonations} />}
                {!isCertifier && <Route path={`/organization/billings`} component={BillingInformation} />}
                <Route path={`${basePath}/configuration`} component={CertifierFaq} />
                <Route path={`${basePath}/stats`} component={StatsCertifierLayout} />
                <Route path={`${basePath}/organization-stats`} component={StatsOrganizationLayout} />
                <Route path={`${basePath}/messages`} component={Chat} />
                <Route path={`${basePath}/notifications/detail/:id`} component={NotificationsDetail} />
                <Route
                  path={`${basePath}/notifications`}
                  render={() => <Notifications {...this.props} type="organization" t = { t } />}
                />
                {isCertifier && <Route path={`${basePath}/roles`} component={CertifierRoles} />}
              </Switch>
            </MainLayoutContent>
          </MainLayout>
        )}
      </>
    );
  }
}
export default withRouter(OrganizationPanel);
