import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const PlanningPriceStyle = styled.div`
  .planning-price-disclaimer {
    margin-bottom: 28px;
    color: ${colors['NE-002/100']};
  }

  .planning-price-form {
    display: flex;
    &__item {
      flex: auto;
      margin-right: 40px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;
