import * as React from 'react';
import { IActivity } from '../../../../models/activities';
import { getLevels } from '../../../../services/levels';
import { ITimeBlocks } from '../../../../models/generic';
import { IDiveForm } from '../../../../models/dive-forms';
import { AttachedFile } from '../../../../types/file';
import { createDives, uploadMainPicture, uploadPictures } from '../../../../services/dives';
import services from '../../../../services/services';
import { deleteActivityPictures, patchActivity, postActivityPictures } from '../../../../services/activities';
import { getCentres } from '../../../../services/centre';
import { WithTranslation } from 'react-i18next';
import { getTimeFormatted } from '../../../../utils/time';
import { getCourse } from '../../../../services/courses';
import { isRegExp } from 'lodash';


export interface IActivityDetailGeneralProps extends WithTranslation {
  activity?: IActivity;
  centreId: string;
  onReload: () => void;
  status: string;
  noRenderHeader?: boolean
}

export interface IActivityDetailGeneralState {
  levels?: any;
  levelName: string;
  renderModal: string;
  centres: any;
  selectedDiveIndex: number;
  hasBookings: boolean;
  levelsLoaded: boolean;
  curEditTime: {
    date: string,
    timeBlocks: ITimeBlocks[],
    curTime: ITimeBlocks,
    curInmputs: {
      start: string,
      end: string
    }
  }
}

export class ActivityDetailGeneralController extends React.Component<IActivityDetailGeneralProps, IActivityDetailGeneralState> {
  constructor(props: IActivityDetailGeneralProps) {
    super(props);
    this.state = {
      renderModal: '',
      levelName: '',
      centres: {},
      selectedDiveIndex: 0,
      hasBookings: false,
      levelsLoaded: false,
      curEditTime: {
        date: '',
        timeBlocks: [],
        curInmputs: {
          start: '',
          end: ''
        },
        curTime: {
          start: {
            hour: 0,
            minutes: 0,
            seconds: 0
          },
          end: {
            hour: 0,
            minutes: 0,
            seconds: 0
          }
        }
      }
    };
  }

  componentDidMount() {
    const { activity } = this.props;

    if (activity) {
      if (activity.course) this.getCourse(activity.course.id)

    }
  }

  componentDidUpdate(prevProps: IActivityDetailGeneralProps) {
    const { activity } = this.props;
    if (activity && !prevProps.activity) {
      if (activity.course) this.getCourse(activity.course.id)
      if (!activity.course && activity.minimumRequisites && activity.minimumRequisites.level !== -1) this.getCourse(activity.minimumRequisites.level.toString())

    }
  }

  async getLevels() {
    const { activity } = this.props;
    const { levels } = this.state;
    if (activity && !levels) {
      const resp = await getLevels();
      const levels = resp.data.data;

      this.setState({ levels, levelsLoaded: true });
    }
  }

  async getCentres() {
    const resp = await getCentres();
    const centres: any = {};
    resp.data.data.forEach((centre: any) => {
      centres[centre.id] = centre.name;
    });
    this.setState({ centres });
  }

  async onCreateDive(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) {
    const { onReload, activity } = this.props;
    const { selectedDiveIndex } = this.state;
    const resp: any = await createDives(dive);
    const diveId = resp.data.id.toString();
    let editData = null;
    if (activity && activity.divesInfo) {
      editData = {
        divesInfo: activity.divesInfo,
      };
      editData.divesInfo[selectedDiveIndex].diveId = parseInt(diveId, undefined);
    } else if (activity && activity.courseInfo) {
      editData = {
        courseInfo: activity.courseInfo,
      };
    }
    if (editData) {
      await this.onEditActivity(editData);
    }
    if (mainPicture) {
      const formDataMain = new FormData();
      formDataMain.append('file', mainPicture[0].data);
      await uploadMainPicture(diveId, formDataMain);
    }
    if (pictures) {
      const formData = new FormData();
      for (const picture of pictures) {
        formData.append('files', picture.data);
      }
      await uploadPictures(diveId, formData);
    }
    this.setState({ renderModal: '' });
    onReload();
    services.showModalSuccess('¡Genial!', 'La inmersión se ha creado correctamente');
  }

  onEditActivity = async (data: any, action?: (e: any) => void) => {
    const { activity, onReload } = this.props;

    if (activity) {
      const result = await patchActivity(activity.id.toString(), data);
      services.pushNotification({
        title: '¡Editado!',
        text: 'La actividad se ha editado con éxito',
        type: 'green',
      });
      this.setState({ renderModal: '' });
      onReload();
      if (action) {
        action(data.minimumRequisites.level.toString())
      }
    }
  }

  onEditGalleryOLD = async (pictures: any, picturesDelete: any) => {

    const { activity, onReload } = this.props;
    if (activity && pictures.length !== 0) {
      const formData = new FormData();
      pictures.forEach((picture: any) => {
        formData.append('files', picture.data);
      });
      const id = activity.id;
      await postActivityPictures(id.toString(), formData);
    }
    if (this.props.activity && picturesDelete.length !== 0) {
      const id = this.props.activity.id;
      await deleteActivityPictures(id.toString(), { pictures: picturesDelete });
    }
    this.setState({ renderModal: '' });
    onReload();
  }

  onEditGallery = async (pictures: any, picturesDelete: any) => {

    const { activity, onReload } = this.props;
    if (activity) {
      const id = activity.id;
      if (localStorage.getItem('arrayImages')) {
        const images = localStorage.getItem('arrayImages');
        if (images) {
          const formData = new FormData();
          let arrayImages: any = images.split(',');
          for (let i = 0; i < arrayImages.length; i++) {
            if (localStorage.getItem(arrayImages[i])) {
              let file = this.dataURLtoFile(localStorage.getItem(arrayImages[i]), arrayImages[i]);
              formData.append('files', file);
              localStorage.removeItem(arrayImages[i])
            }
          }
          await postActivityPictures(id.toString(), formData);
          localStorage.removeItem('arrayImages');

        }
      }
    }
    if (this.props.activity && picturesDelete.length !== 0) {
      const id = this.props.activity.id;
      await deleteActivityPictures(id.toString(), { pictures: picturesDelete });
    }
    this.setState({ renderModal: '' });
    onReload();
  }


  dataURLtoFile(dataurl: any, filename: string) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  onEditPrices = async (data: any, action?: (e: any) => void) => {
    const { activity, onReload } = this.props;
    this.setState({ renderModal: '' });
    onReload();
  }

  getCourse = async (courseId: string) => {
    if (courseId === "-1") {
      this.setState({ levelName: "Ninguna" });
    } else {
      const res = await getCourse(courseId);
      this.setState({ levelName: res.data ? res.data.name : "Ninguna" });
    }

  }

  getHours() {
    const { activity } = this.props;
    let biggestHour = 0;
    let biggestMinute = 0;
    let lowestHour = 23;
    let lowestMinute = 59;
    if (activity) {
      activity.timeBlocks.forEach((timeBlock: ITimeBlocks) => {
        if (
          timeBlock.end.hour > biggestHour ||
          (timeBlock.end.hour === biggestHour && timeBlock.end.minutes > biggestMinute)
        ) {
          biggestHour = timeBlock.end.hour;
          biggestMinute = timeBlock.end.minutes;
        }
        if (
          timeBlock.start.hour < lowestHour ||
          (timeBlock.start.hour === lowestHour && timeBlock.start.minutes > lowestMinute)
        ) {
          lowestHour = timeBlock.start.hour;
          lowestMinute = timeBlock.start.minutes;
        }
      });
    }
    return {
      startHour: {
        hour: lowestHour,
        minutes: lowestMinute,
        seconds: 0,
      },
      endHour: {
        hour: biggestHour,
        minutes: biggestMinute,
        seconds: 0,
      },
    };
  }

  formattedHours(timeBlock: any) {
    return getTimeFormatted(timeBlock.start.hour, timeBlock.start.minutes) + ' - ' + getTimeFormatted(timeBlock.end.hour, timeBlock.end.minutes);
  }

  datesActivity() {
    const { activity } = this.props;
    let dates: any;
    if (activity && activity.timeBlocks) {

      if (activity.timeBlocks && activity.timeBlocks.length > 1) {
        dates = activity.timeBlocks.sort((a: any, b: any) => {
          const dateA = new Date(a.start.date);
          dateA.setHours(a.start.hour);
          dateA.setMinutes(a.start.minutes);
          const dateB = new Date(b.start.date);
          dateB.setHours(b.start.hour);
          dateB.setMinutes(b.start.minutes);
          return b - a;
        });
      } else {
        dates = activity.timeBlocks;
      }
    }
    return dates;
  }



  oneditPrices = () => {
    this.setState({ renderModal: 'edit-general-prices' })
  }
  oneditDates = () => {
    if (this.props.activity && this.props.activity.courseInfo) {
      this.setState({ renderModal: 'edit-times-curse' })
    } else {

      if (this.props.activity) this.setState({
        curEditTime: {
          date: this.props.activity.date,
          timeBlocks: [...this.props.activity.timeBlocks],
          curInmputs: {
            start: '',
            end: ''
          },
          curTime: {
            start: {
              hour: 0,
              minutes: 0,
              seconds: 0
            },
            end: {
              hour: 0,
              minutes: 0,
              seconds: 0
            }
          }
        }, renderModal: 'edit-times-inmersion'
      })
    }
  }
  courseTemplateInfo(activity: IActivity): any {
    const courseInfo = {
      courseTemplateInfo: activity.template.courseInfo,
      courseActivityInfo: activity.courseInfo,
      startDate: activity.date
    };
    return courseInfo;
  }

  addTimeToInmersion() {
    const start = this.state.curEditTime.curInmputs.start;
    const end = this.state.curEditTime.curInmputs.end;
    const startArray = start.split(':');
    const endArray = end.split(':');
    if (startArray.length !== 2 || endArray.length !== 2) {
      alert("Horas incorrectas");
      return;
    }
    if (parseInt(start.replace(":", "")) > parseInt(end.replace(":", ""))) {
      alert("Inicio posterior a finail");
      return;
    }
    this.state.curEditTime.curTime.start.hour = parseInt(startArray[0]);
    this.state.curEditTime.curTime.start.minutes = parseInt(startArray[1]);


    this.state.curEditTime.curTime.end.hour = parseInt(endArray[0]);
    this.state.curEditTime.curTime.end.minutes = parseInt(endArray[1]);
    this.state.curEditTime.timeBlocks.push(this.state.curEditTime.curTime)
    this.state.curEditTime.curInmputs.start = '';
    this.state.curEditTime.curInmputs.end = '';
    this.setState({ ...this.state });
  }

  delTimeToInmersion(index: number) {
    this.state.curEditTime.timeBlocks.splice(index, 1);
    this.setState({ ...this.state });
  }

  saveTimeInmersion(e: any) {
    if (this.state.curEditTime.timeBlocks.length === 0) {
      alert("No puede guardarse una actividad sin horario");
      return;
    }
    for (const b in this.state.curEditTime.timeBlocks) {
      this.state.curEditTime.timeBlocks[b].start.date = this.state.curEditTime.date;
      this.state.curEditTime.timeBlocks[b].end.date = this.state.curEditTime.date;
    }
    this.onEditActivity({ date: this.state.curEditTime.date, timeBlocks: this.state.curEditTime.timeBlocks })
    if (this.props.activity) this.props.activity.timeBlocks = this.state.curEditTime.timeBlocks;
    this.setState({ ...this.state, renderModal: '' });
  }
  getFirstDate(timeBlocks: ITimeBlocks[], fecha: string) {
    let curDate = "";
    for (const t of timeBlocks) {
      if (t.start.date) {
        curDate = t.start.date > curDate ? t.start.date : curDate;
      }
    }
    return curDate !== "" ? curDate : fecha;
  }

}
