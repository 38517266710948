import React, { Component } from 'react';
import { MainLayout, MainLayoutContent } from '../containers/main-layout-style';
import DetailMenu from '../components/detail-menu/detail-menu';
import { Switch, Route, withRouter, RouteComponentProps, match } from 'react-router';
import Chat from '../containers/messages/chat/chat';
import MyCenterEquipment from '../containers/my-center/my-center-equipment/my-center-equipment';
import MyCenterStaff from '../containers/my-center/my-center-staff/my-center-staff';
import MyCenterDocumentation from '../containers/my-center/my-center-documentation/my-center-documentation';
import MyCenterAccomodation from '../containers/my-center/my-center-accomodation/my-center-accomodation';
import MyCenterProfile from '../containers/my-center/my-center-profile/my-center-profile';
import DiversFuncional from '../containers/divers/divers-functional';
import DiverDetail from '../containers/divers/detail/diver-detail';
import DiverBonusesDetail from '../containers/divers/detail/bonuses/bonuses-detail/diver-bonuses-detail-layout';
import DiveSite from '../containers/dive-site/dive-site-layout/dive-site';
import DiveSiteDetailSwitch from '../containers/dive-site/dive-site-detail-switch/dive-site-detail-switch';
import Incidences from '../containers/incidences/incidences';
import DiveActivitiesDetail from '../containers/divers/detail/activities/detail/dive-activities-detail';
import DiverDiveDetail from '../containers/divers/detail/dives/detail/diver-dive-detail';
import ChatNotifications from '../containers/messages/chat-notification/chat-notifications';
import PlanningCalendar from '../containers/planning/calendar/planning-calendar';
import PlanningAccomodation from '../containers/planning/accommodation/planning-accomodation';
import PlanningHistory from '../containers/planning/history/planning-history';
import PlanningReport from '../containers/planning/report/planning-report';
import PlanningReservation from '../containers/planning/reservations/planning-reservations';
import PlanningActivityDetail from '../containers/planning/activity-detail/planning-activity-detail';
import ActivityTemplatesDetail from '../containers/dive-offers/activities/activity-templates-detail/activity-templates-detail';
import BillingInformation from '../containers/billing/billing-information/billing-information';
import CancellationPolicy from '../containers/configuration/cancellation-policy/cancellation-policy';
import Bonuses from '../containers/dive-offers/bonuses/bonuses-layout';
import NotFoundContainer from '../components/not-found/not-found';
import DiveOffersActivities from '../containers/dive-offers/activities/dive-offers-activities';
import DiveOfferTable from '../containers/dive-offers/offers/offers';
import measurementUnits from '../containers/configuration/measurement-units/measurement-units';
import FAQ from '../containers/configuration/faq/configuration-faq';
import HELPCENTRE from '../containers/configuration/helpcentre/help-centre';
import Notifications from '../containers/notifications/notifications';
import NotificationsDetail from '../containers/notifications/detail/notifications-detail';
import Bookings from '../containers/billing/bookings/bookings';
import ListBookings from '../containers/billing/bookings/booking-list/list-bookings';
import Bills from '../containers/billing/bills/bills';
import Legal from '../containers/legal/legal';
import BookingsDetail from '../containers/billing/bookings/bookings-detail/bookings-detail';
import BookingCreate from '../containers/billing/bookings/booking-create/booking-create';

interface DivingCenterDetailProps extends RouteComponentProps {
  match: match<{
    id: string;
  }>;
  t: any;
  onLogout: () => void;
  profilePicture?: string;
}

interface DivingCenterDetailState {
  isValid: boolean;
}

class CentrePanel extends Component<DivingCenterDetailProps, DivingCenterDetailState> {
  constructor(props: DivingCenterDetailProps) {
    super(props);
  }

  render() {
    let {
      match: {
        params: { id },
      },
      t,
    } = this.props;

    let localCentreId = localStorage.getItem('centreId');
    const { profilePicture } = this.props;
    if (id && localCentreId && localCentreId !== id && !isNaN(+id)) {
      localStorage.setItem('centreId', id);
      localCentreId = id;
    }
    if (!id || isNaN(+id)) {
      const centreId = localCentreId;
      if (centreId) {
        id = centreId;
      } else {
        this.props.history.replace('/');
      }
    } else if (!localCentreId) {
      localStorage.setItem('centreId', id);
    }

    return (
      <MainLayout>
        {/* Menu */}
        <DetailMenu centreId={isNaN(+id) ? null : id} onLogout={this.props.onLogout} profilePicture={profilePicture} />
        {/* MainLayout */}
        <MainLayoutContent>
          <Switch>
            {/* Divers */}
            <Route exact={true} path="/centres/:id/divers" component={DiversFuncional} />
            <Route exact={true} path="/centres/:id/divers/:diver" component={DiverDetail} />
            <Route exact={true} path="/centres/:id/divers/:diver/dives/:diveId" component={DiverDiveDetail} />
            <Route exact={true} path="/centres/:id/divers/:diver/bonus-detail" component={DiverBonusesDetail} />
            <Route
              exact={true}
              path="/centres/:id/divers/:diver/activities/:activityId"
              component={DiveActivitiesDetail}
            />
            {/*  Staff */}
            <Route exact={true} path="/centres/:id/staff/:staff" component={DiverDetail} />
            <Route exact={true} path="/centres/:id/staff/:staff/dives/:diveId" component={DiverDiveDetail} />
            <Route exact={true} path="/centres/:id/staff/:staff/bonus-detail" component={DiverBonusesDetail} />
            <Route
              exact={true}
              path="/centres/:id/staff/:staff/activities/:activityId"
              component={DiveActivitiesDetail}
            />
            {/* Activities */}
            <Route path="/centres/:id/offers/activities/:activityTemplatesId" component={ActivityTemplatesDetail} />

            {/* Planning */}
            <Route path="/centres/:id/planning/accomodation" component={PlanningAccomodation} />
            <Route path="/centres/:id/planning/calendar/:activityId" component={PlanningActivityDetail} />
            <Route path="/centres/:id/planning/calendar" component={PlanningCalendar} />
            <Route path="/centres/:id/planning/history" component={PlanningHistory} />
            <Route path="/centres/:id/planning/reports" component={PlanningReport} />
            <Route path="/centres/:id/planning/reservations" component={PlanningReservation} />

            {/* Messages */}
            <Route path="/centres/:id/messages/chat" component={Chat} />
            <Route path="/centres/:id/notifications/:notificationId" component={NotificationsDetail} />
            <Route
              path="/centres/:id/notifications"
              render={() => <Notifications {...this.props} type="centre" />}
            />
            {/* My Centre */}
            <Route path="/centres/:id/business/profile" component={MyCenterProfile} />
            <Route path="/centres/:id/business/equipment" component={MyCenterEquipment} />
            <Route path="/centres/:id/business/staff" component={MyCenterStaff} />
            <Route path="/centres/:id/business/documentation" component={MyCenterDocumentation} />
            <Route path="/centres/:id/business/accomodation" component={MyCenterAccomodation} />
            {/* Billing */}
            <Route
              path="/centres/billing/billing-information"
              render={() => <BillingInformation centreId={isNaN(+id) ? null : id} />}
            />
            <Route path="/centres/:id/billing/bookings/create" strict={true} component={BookingCreate} />
            <Route path="/centres/:id/billing/bookings/old" component={Bookings} />
            <Route path="/centres/:id/billing/bookings/:bookingId" strict={true} component={BookingsDetail} />
            <Route path="/centres/:id/billing/bookings" component={ListBookings} />


            <Route path="/centres/:id/billing/bills" component={Bills} />

            {/* Offers */}
            <Route path="/centres/:id/offers/activities" component={DiveOffersActivities} />
            <Route path="/centres/:id/offers/cancellation" component={CancellationPolicy} />
            <Route path="/centres/:id/offers/bonuses/:bonusId" component={DiverBonusesDetail} />
            <Route path="/centres/:id/offers/bonuses" component={Bonuses} />
            <Route path="/centres/:id/offers/offers" component={DiveOfferTable} />

            {/* Dive site */}
            <Route path="/centres/:id/dive-site/detail/:siteId" component={DiveSiteDetailSwitch} />
            <Route path="/centres/:id/dive-site" component={DiveSite} />

            {/* Incidences */}
            <Route path="/centres/:id/incidences" component={Incidences} />
            {/* Config */}
            <Route path="/centres/:id/configuration/measurement-units" component={measurementUnits} />
            <Route path="/centres/:id/configuration/faq" component={FAQ} />
            <Route path="/centres/:id/configuration/helpcentre" component={HELPCENTRE} />
            {/* 404 */}
            <Route path="/" component={NotFoundContainer} />
          </Switch>
        </MainLayoutContent>
      </MainLayout>
    );
  }
}
export default withRouter(CentrePanel);
