import styled from 'styled-components';

export const ModalArchiveChatStyle = styled.div`
  padding: 32px 32px 32px 32px;
  width: 416px;

  .modal-icon-container {
    margin: 0 auto 20px;
    padding: 18px;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    display: block;
    background-color: #e5f6f6;

    & .modal-icon {
      width: 32px;
      height: 32px;
    }
  }

  .modal-text {
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    margin-bottom: 40px;
  }
`;
