import * as dateFns from 'date-fns';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-GB';
import it from 'date-fns/locale/it';
import de from 'date-fns/locale/de';
import pt from 'date-fns/locale/pt';
import fr from 'date-fns/locale/fr';
import moment from 'moment';
import { TFunction } from 'i18next';

const locales: any = { es, en, it, de, pt, fr };

/**
 *
 * @param {Date} date DD/MM/YYYY
 * @param {string} language es | en | ca
 * @returns {number} days between minimun start and maximun finish
 */
export const getDayMonthNumberYear = (d: any, lang: string = 'es') => {
  const date = new Date(d);
  if (!d) return '';
  return dateFns.format(date, 'dd/MM/yyyy', { locale: locales[lang] });
};

export const getTimeFormat = (d: any, lang: string = 'es') => {
  const date = new Date(d);
  if (!d) return '';
  return dateFns.format(date, 'HH:mm', { locale: locales[lang] });
};

export const getDateLongString = (d: any, lang: string = 'es') => {
  const date = new Date(d);
  if (!d) return '';

  return dateFns.format(date, 'EEEE, dd MMMM yyyy', { locale: locales[lang] });
};

export const getDateMonthString = (d: any, lang: string = 'es', format: string = 'dd MMMM yyyy') => {
  const date = new Date(d);
  if (!d) return '';
  return dateFns.format(date, format, { locale: locales[lang] });
};

export const getDateQuery = (d: any, lang: string = 'es') => {
  const date = new Date(d);
  if (!d) return '';
  return dateFns.format(date, 'yyyy-MM-dd HH:mm:ss', { locale: locales[lang] });
};

export const getDateMonthAndYearString = (d: any, lang: string = 'es') => {
  const date = new Date(d);
  if (!d) return '';
  return dateFns.format(date, 'MMMM yyyy', { locale: locales[lang] });
};

export const getDayOfMonth = (t: TFunction, date?: string) => {
  if (!t) return '';
  return moment(date).format(`dddd D [${t('layouts:config.of')}] MMMM`);
};

export const getTimeFormatted = (hours: number, minutes: number) => {
  const time = moment()
    .hours(hours)
    .minutes(minutes);
  return time.format('HH:mm');
};

export const cleanTime = (date?: string) => {
  return moment(date)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
};

export const getDateFormatted = (date?: string, inputFormat?: string) => {
  return moment(date, inputFormat).toISOString();
};

export const formatTime = (n: number) => (n ? (n < 10 ? `0${n}` : n) : '00');

export const transformDateCalendar = (calendarDate: string) => {
  const date = calendarDate.split('/');
  const currentDate = new Date();
  if (date.length === 3) {
    const formattedDate = new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]));
    formattedDate.setHours(currentDate.getHours());
    formattedDate.setMinutes(currentDate.getMinutes());
    return formattedDate.toISOString();
  }
  return new Date().toISOString();
};

