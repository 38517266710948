import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const AdsDetailStyle = styled.div`
  .dive-site-hero {
    display: flex;
    margin-bottom: 60px;
  }
  .dive-site-hero-slider {
    height: 284px;
    width: calc(40% - 60px);
    border-radius: 5px;
    background: #c4c4c4;
    overflow: hidden;
    margin-right: 60px;

    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .dive-site-hero-info {
    width: 60%;
    &__type {
      display: flex;
      &__item  {
        padding: 10px 16px;
        color: ${colors['CO-004/100']};
        background: ${colors['CO-004/15']};
        &.active {
          color: ${colors['CO-001/100']};
          background: ${colors['CO-001/15']};
        }
      }
      margin-bottom: 32px;
    }
    &__title {
      margin-bottom: 40px;
    }
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &__icon {
        margin-right: 16px;
        width: 32px;
        height: 32px;
      }
    }
  }

  .ads-detail-text {
    margin-bottom: 80px;
  }

  .ads-detail-table {
    &__title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }
  }
`;
