const FileUtils = {
  getName: (filename?: string) => {
    if (!filename) return '';
    const name = filename.replace(/\.[^/.]+$/, '');
    return name;
  },
  getExtension: (filename?: string) => {
    if (!filename) return '';
    const re = /(?:\.([^.]+))?$/.exec(filename);
    const extension = re === null || re[1] === undefined ? '' : re[1].replace(/[0-9]/g, '');
    return extension;
  },
  getFileName(url?: string) {
    if (!url) return '';
    const fileName: any = decodeURIComponent(url)
      .split('/')
      .pop();
    let fileNameArray = fileName.split('.');
    fileNameArray.pop();
    fileNameArray.push(this.getExtension(fileName));
    fileNameArray = fileNameArray.join('.');
    return fileNameArray;
  },
  formatBytes(bytes: number, sentDecimals?: number) {
    let decimals = sentDecimals;
    if (bytes === 0) return '0 Bytes';
    if (!decimals) {
      decimals = 0;
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const result = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    return `${result} ${sizes[i]}`;
  },
  readFileUrlAsync: (file: File) => {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  },
};

export default FileUtils;
