import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const InputAreaContainer = styled.div`
  .input-s-title {
    font-family: 'worksans-alt', sans-serif;
    font-size: 12px;
    line-height: 20px;
    color: ${colors['NE-002/100']};
    margin-bottom: 6px;
    text-transform: uppercase;
  }
  

  &.required {
    .input-s-title {
      position: relative;
      &::before  {
        background: ${colors['PR-001/100']};
      }
    }
  }

  &.required {
    .input-s-title {
      position: relative;
      padding-left: 12px;
      &::before  {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 8px;
        background: ${colors['PR-001/100']};
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &.disable  {
    pointer-events: none;
  }

  .input-s-input {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 22px;
  }

  .input-s-exp  {
    display: flex;
    justify-content: space-between;

    input  {
      width: calc((100% - 20px) / 2);

    }
  }

  .input-s-phone {
    display: flex;
    align-items: flex-end;

    .input-s-input {
      margin-bottom: 0;

      &:first-of-type {
        margin-right: 12px;
        width: 70px;
      }
    }
    .input-s-error {
      position: absolute;
    }

    .input-select-arrow {
      right: 14px;
      top: 9px;
    }
  }

  textarea {
    font-size: 14px;  
    line-height: 22px;
    width: 100%;
    font-weight: 300;
    padding: 14px 20px;
    resize: none;
    border: 1px solid ${colors['NE-002/100']};
    transition: all 0.2s ease-in-out;
    margin-bottom: 4px;
    background-color: #fafbfc;
    /* overflow-y: hidden; */
    overflow-y: scroll;
    border-radius: 5px;
    &::placeholder  {
      color: ${colors['NE-002/100']};
    }
    &:focus {
      color: ${colors['SE-002/100']};
      border: 1px solid ${colors['SE-002/100']};
    }
  }

  .input-s-error {
    font-family: 'worksans-alt', sans-serif;
    letter-spacing: -0.02em;
    line-height: 24px;
    font-size: 14px;
    min-height: 24px;
    color: ${colors['CO-003/100']};
    /* opacity: 0; */
    /* visibility: hidden; */

    position: relative;
    /* p {
      &::before  {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: ${colors['CO-003/100']};
        position: absolute;
        left: 0px;
        top: 8px;
      }
    } */
  }
  .icon-password {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 8px;
    top: 6px;
    display: flex;
    cursor: pointer;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }

  &.error {
    textarea {
      border: 1px solid ${colors['CO-003/100']};
      color: red;
    }
    .input-box-topbar-label {
      color: ${colors['/NE-003/20']};
    }
    .input-s-title {
      opacity: 1;
      visibility: visible;
      color: ${colors['NE-003/20']};
      /* p {
        &::before  {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: ${colors['CO-003/100']};
          position: absolute;
          left: 0px;
          top: 8px;
        }
      } */
    }
  }
`;
