import React from 'react';
import { ElementsConsumer, CardElement, CardNumberElement } from "@stripe/react-stripe-js";
import CardSection from "./cardSection";
import styles from '../../containers/billing/bookings/booking-form/booking-form-pay-data/booking-form-pay-data.module.scss';
import MainButton from '../main-button';
import services from '../../services/services';
import { setStripeTokenId } from '../../redux/actions';
import { useTranslation } from 'react-i18next';

const CheckoutForm = (props: any) => {

    const save = async () => {
        const { stripe, elements } = props;
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            // error
        } else {
            // return tokenId
            services.setValue(setStripeTokenId(result.token.id))
        }
    }
    
    const { t } = useTranslation(['components']);

    return (
        <>
            <CardSection />
            <div className={styles.footer_btn}>
                <MainButton text={t('components:actions.save-card')} type="primary" onClick={() => save()} />
            </div>
        </>
    );
}

const InjectedCheckoutForm = () => {
    return (
      <ElementsConsumer>
          {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} />
          )}
      </ElementsConsumer>
    );
}

export default InjectedCheckoutForm