import styled from 'styled-components';

export const RenderIcons = styled.div`
  display: flex;
  width: 100%;
  background: lightgray;
  .render-icons {
    display: flex;
    flex-wrap: wrap;
    &__item {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      margin: 20px;
    }
  }
`;
