import React, { Component } from 'react';
import { MainTableContainer, NoContentContainer, MainTableWrapper } from './main-table-style';
import { RouteComponentProps, withRouter } from 'react-router';
import { images } from '../../assets/images/index';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import StarRate from '../star-rate/star-rate';
import BodyText from '../text/body-text/body-text';
import SingleButton from '../single-button/single-button';
import ItemTeam from '../item-team/item-team';
import { TableCell } from '../../types/table';
import LabelText from '../text/label-text/label';
import DicotomicCheckbox from '../dicotomic-checkbox';
import ItemFile from '../item-file/item-file';
import MainButton from '../main-button';
import SelectBox from '../select-box';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MainStateContainer } from './main-state-style';

interface MainTableProps extends RouteComponentProps, WithTranslation {
  rows: any[];
  columns?: any[];
  rowKeys: string[];
  rowKeyLink?: string;
  rowLink?: string;
  onOrder: (key: string, ascendent: boolean) => void;
  onSelect?: (rows: any[], all: boolean, allValue?: boolean) => void;
  rowsSelected?: any[];
  filter?: Function;
  filters?: { [key: string]: string };
  titleTable?: string;
}

interface MainTableState {
  activeColumnHead: ActiveObject;
  allValue: boolean;
}

interface ActiveObject {
  [key: string]: any;
}

class MainTableLayout extends Component<MainTableProps, MainTableState> {
  state: MainTableState = {
    activeColumnHead: {},
    allValue: false,
  };

  selectRow(item: any, index: number) {
    const { onSelect, rows } = this.props;
    if (onSelect) {
      item.value = !item.value;
      rows[index].check = item;
      onSelect(rows, false, false);
      const { allValue } = this.state;
      if (allValue) {
        this.setState({ allValue: false });
      }
    }
  }

  selectAllRows() {
    const { onSelect, rows } = this.props;

    if (onSelect) {
      const { allValue } = this.state;
      const checkedRows = rows.length > 0 ? rows.every((row: any) => (row.check ? !!row.check.value : false)) : false;
      let checked: boolean;
      this.setState(
        (prevState: MainTableState) => {
          checked = checkedRows ? false : !prevState.allValue;
          return { allValue: checked };
        },
        () => {
          const newRows = rows.map((r: any) => ({
            ...r,
            check: {
              ...r.check,
              value: checked,
            },
          }));
          onSelect(newRows, true, checked);
        }
      );
    }
  }

  handleClickOnColumnHead(key: string, ascendent: boolean) {
    const { onOrder } = this.props;
    let { activeColumnHead } = this.state;
    let auxAscendent = ascendent;

    if (activeColumnHead[key]) {
      activeColumnHead = {};
    } else {
      activeColumnHead[key] = 'active';
      auxAscendent = true;
    }

    this.setState({ activeColumnHead }, () => {
      onOrder(key, auxAscendent);
    });
  }

  renderCellTable(item: TableCell, index: number) {
    if (!item) {
      return;
    }

    switch (item.key) {
      case 'icon':
        return (
          <div className="table-icon">
            <img src={item.value.toString()} alt="" />
          </div>
        );
      case 'state':
        return (
          <MainStateContainer>
            <tbody>
              <p className={`${item.type}`}>{item.value}</p>
            </tbody>
          </MainStateContainer>
        );
      case 'avatar':
        return (
          <div className={`table-avatar ${item.value}`}>
            {item.value !== 'ghost' && !!item.link && <img src={item.link} alt="" />}
          </div>
        );
      case 'centreId':
        return (
          <BodyText title={typeof item.value === 'string' ? item.value : undefined}>
            {item.value}
          </BodyText>
        );
      case 'highlighted-body':
        return (
          <BodyDestacadoText title={typeof item.value === 'string' ? item.value : undefined}>
            {item.value}
          </BodyDestacadoText>
        );
      case 'rate':
        return (
          <StarRate
            number={Number(item.value)}
            editable={!!(item.actions && item.actions.length)}
            onRate={value => {
              if (item.actions && item.actions.length) {
                item.actions[0].onClick(value);
              }
            }}
          />
        );
      case 'check':
        return (
          <DicotomicCheckbox
            className="table"
            name="name"
            checked={!!item.value}
            onCheck={() => this.selectRow(item, index)}
          />
        );
      case 'file':
        return <ItemFile type={item.value} />;
      case 'select':
        return (
          <SelectBox
            labelText=""
            placeholder="País"
            required={true}
            className="rounded-table"
            suggestedOptionsText={[{ label: 'España' }]}
            optionsText={[]}
            optionKey={'label'}
            optionValue={'label'}
            // defaultValue={person.country}
            // errorCode={errorFields['country']}
            icon={images.arrowDown2BlueSvg}
            onChange={() => { }}
          />
        );
      case 'phone':
        return (
          <BodyText>
            <a href={`tel:${item.value}`}>{item.value}</a>
          </BodyText>
        );
      case 'email':
        return (
          <BodyText>
            <a href={`mailto:${item.value}`}>{item.value}</a>
          </BodyText>
        );
      case 'success':
        return !!item.value ? <SingleButton className="table" icon={images.stateSuccess} onClick={() => { }} /> : '-';
      case 'pending':
        return !!item.value ? <SingleButton className="table" icon={images.statePending} onClick={() => { }} /> : '-';
      case 'reject':
        return !!item.value ? <SingleButton className="table" icon={images.stateReject} onClick={() => { }} /> : '-';
      case 'official':
        return !!item.value ? <SingleButton className="table" icon={images.medalOficialSvg} onClick={() => { }} /> : '-';
      case 'count':
        if (
          !item.value ||
          typeof item.value === 'string' ||
          typeof item.value === 'number' ||
          typeof item.value === 'boolean' ||
          item.value.items.length === 0
        ) {
          return;
        } else {
          return <ItemTeam title={item.value.title} items={item.value.items} />;
        }

      case 'detail':
        return (
          <SingleButton
            className="small secondary"
            icon={images.eyeSvg}
            onClick={() => {
              if (!!item.link) this.props.history.push(item.link);
              else if (!!item.onClick) item.onClick();
            }}
          />
        );
      case 'icon-button':
        return (
          <SingleButton
            className="small secondary"
            icon={item.value.toString()}
            onClick={() => {
              if (item.onClick) item.onClick();
              else !!item.link && this.props.history.push(item.link);
            }}
          />
        );
      case 'icon-text-button':
        return (
          <SingleButton
            className="small secondary"
            text={item.value.toString()}
            icon={item.icon.toString()}
            onClick={() => {
              if (item.onClick) item.onClick();
              else !!item.link && this.props.history.push(item.link);
            }}
          />
        );
      case 'text-button':
        return (
          <MainButton
            className={`${item.type} table`}
            text={item.icon ? item.value.toString() + ' ' + item.icon.toString() : item.value.toString()}
            onClick={() => {
              if (!!item.onClick) item.onClick();
              else !!item.link && this.props.history.push(item.link);
            }
            }
          />
        );
      case 'options':
        return (
          <SingleButton className="small secondary" drop={true} icon={images.otherSvg} onClick={() => { }}>
            {item.actions &&
              item.actions.map((action, key) => (
                <div key={key} className="single-button-drop__item" onClick={() => action.onClick()}>
                  {action.icon && (
                    <div className="single-button-drop__item__icon">
                      <SingleButton className="secondary" icon={action.icon} />
                    </div>
                  )}
                  <div className="single-button-drop__item__text">
                    <BodyDestacadoText>{action.label}</BodyDestacadoText>
                  </div>
                </div>
              ))}
          </SingleButton>
        );
      case 'numDivers':
        //const { divers } = item;
        return (
          <div>
            {/*item.divers &&
            item.divers.forEach((diver) => (
            <p>{diver.id}</p>
            ))*/}
          </div>
        );

      default:
        return <BodyText title={typeof item.value === 'string' ? item.value : undefined}>{item.value || '-'}</BodyText>;
    }
  }
  renderHeadTable(item: any) {
    const { rows } = this.props;
    const { allValue } = this.state;
    const checkedRows = rows.length > 0 ? rows.every((row: any) => (row.check ? !!row.check.value : false)) : false;

    switch (item.key) {
      case 'check':
        const checkedCount = rows.filter((row: any) => row.check.value).length;
        return (
          <div className="table-check">
            <DicotomicCheckbox
              name="name"
              className="table"
              disabled={!rows.length}
              onCheck={() => this.selectAllRows()}
              checked={allValue || checkedRows}
            />
            {checkedCount > 0 && <span className="table-check__count">{checkedCount}</span>}
          </div>
        );

      case 'highlighted':
        return <LabelText>{item.text}</LabelText>;

      default:
        return <LabelText>{item.text}</LabelText>;
    }
  }
  filterRows() {

    const { filters, rows } = this.props;
    if (!filters || Object.keys(filters).length === 0) return rows;
    const filteredRows = rows.filter((row: any) => {
      const rowKeys = Object.keys(row);
      let includeRow = true;
      Object.entries(filters).forEach(filter => {
        if (rowKeys.includes(filter[0])) {
          if (
            row[filter[0]].value.toLowerCase().indexOf(filter[1].toLowerCase()) > -1 ||
            row[filter[0]].value === '*'
          ) {
            includeRow = includeRow && true;
          } else includeRow = false;
        }
      });
      return includeRow;
    });
    return filteredRows;
  }

  render() {
    const { columns, rowKeys, filter, titleTable, t } = this.props;

    const rows = this.filterRows();
    return (
      <>
        {/*       
        {filter && (
          <TableFiltersStyle>
            <div className="table-filter-buttons">
              <div className="table-filter-buttons__text">
                <TitleText>{titleTable}</TitleText>
              </div>
              <div className="table-filter-buttons__buttons">
                <div className="table-filter-buttons__buttons__item">
                  <SingleButton className="secondary modal" icon={images.searchSvg} onClick={() => {}} />
                </div>
                <div className="table-filter-buttons__buttons__item">
                  <MainButton iconImg={images.filtersSvg} text="Filtrar" type="secondary" />
                </div>
              </div>
            </div>
          </TableFiltersStyle>
        )} */}
        <MainTableWrapper>
          <MainTableContainer>
            <thead>
              <tr>
                {columns && columns.map((column, index) => (
                  <th
                    key={index}
                    onClick={() =>
                      column.filter ? this.handleClickOnColumnHead(rowKeys[index], column.ascendent) : ''
                    }
                  >
                    {this.renderHeadTable(column)}
                  </th>
                ))}
              </tr>
            </thead>
            {rows.length > 0 && (
              <tbody>
                {rows.map((row, j) => (
                  <tr key={`${j}-${Math.random()}`} className="main-table-row">
                    {rowKeys.map((key: string, i) => (
                      <td key={i} className={`${row[key] && row[key].className ? row[key].className : ''}`}>
                        {this.renderCellTable(row[key], j)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </MainTableContainer>
        </MainTableWrapper>
        {rows.length === 0 && <NoContentContainer>{t('tables:no-content')}</NoContentContainer>}
      </>
    );
  }
}

export default withTranslation(['tables'])(withRouter(MainTableLayout));
