import React, { Component } from 'react';
import { InputCoordBoxContainer } from './input-coord-box-style';
import { images } from '../../assets/images';
import TooltipBox from '../tooltip-box/tooltip-box';
import { ERROR_MESSAGES_ES } from '../../constants/error-messages';
import { isNumeric, isValidEmail, isValidDocumentID } from '../../utils/validation';
import TextText from '../text/text-text/text-text';
import LabelText from '../text/label-text/label';
import InfoText from '../text/info-text/info-text';
import Cleave from 'cleave.js/react';
import { ConvertDMSToDD, ConvertDDToDMS, ConvertDDToDDM, ConvertDDMToDD } from '../../utils/measurement-units';

interface InputCoordBoxProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  errorCode?: string;
  required?: boolean;
  placeholder?: string;
  filledValue?: string;
  value: string;
  labelText: string;
  isLat: boolean;
  type?: string;
  onChange?: (value: string, error?: boolean) => void;
  onBlur?: (value: string, error?: boolean) => void;
  onKeyUp?: (event: any) => void;
  clearValue?: boolean;
  autocompleteOff?: boolean;
  withTooltip?: boolean;
  isTooltipInside?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
  max?: number;
  min?: number;
}

interface InputCoordBoxState {
  prevInputValue: string;
  focus: boolean;
  showTooltip: boolean;
  error: string;
  gradesValue: string;
  minutesValue: string;
  secondsValue: string;
  units: string;
  isFocus: boolean;
}

class InputCoordBox extends Component<InputCoordBoxProps, {}> {
  state: InputCoordBoxState = {
    prevInputValue: '',
    focus: false,
    showTooltip: false,
    error: '',
    gradesValue: '',
    minutesValue: '',
    secondsValue: '',
    isFocus: false,
    units: 'N',
  };

  componentDidMount() {
    const { value, isLat, type } = this.props;
    if (value) {
      const floatValue = parseFloat(value);
      if (floatValue) {
        const coord: any = type === 'minute' ? ConvertDDToDDM(floatValue, isLat) : ConvertDDToDMS(floatValue, isLat);

        this.setState({
          units: coord.dir,
          gradesValue: coord.deg,
          minutesValue: coord.min,
          secondsValue: coord.sec ? coord.sec : '',
        });
      }
    } else {
      this.setState({ units: isLat ? 'N' : 'W' });
    }
  }

  componentDidUpdate(prevProps: InputCoordBoxProps) {
    const { value, isLat, type } = this.props;
    if (prevProps.value !== value) {
      const floatValue = parseFloat(value);
      if (floatValue) {
        const coord: any = type === 'minute' ? ConvertDDToDDM(floatValue, isLat) : ConvertDDToDMS(floatValue, isLat);
        this.setState({
          units: coord.dir,
          gradesValue: coord.deg,
          minutesValue: coord.min,
          secondsValue: coord.sec,
        });
      }
    }
  }

  handleBlur(value: string) {
    const { onBlur } = this.props;
    onBlur ? onBlur(value) : '';
    this.setState({ isFocus: false });
  }

  handleChange() {
    const { onChange, type } = this.props;
    const { gradesValue, minutesValue, secondsValue, units } = this.state;
    if (type === 'minute') {
    } else {
    }
    const decimalValue =
      type === 'minute'
        ? ConvertDDMToDD(parseFloat(gradesValue) || 0, parseFloat(minutesValue) || 0, units)
        : ConvertDMSToDD(
            parseFloat(gradesValue) || 0,
            parseFloat(minutesValue) || 0,
            parseFloat(secondsValue) || 0,
            units
          );
    onChange && onChange(decimalValue.toString());
  }

  handleFocus(e: any) {
    this.setState({ isFocus: true });
  }

  render() {
    const {
      disabled,
      className,
      icon,
      iconDisabled,
      required,
      autocompleteOff,
      labelText,
      value,
      filledValue,
      placeholder,
      errorCode,
      withTooltip,
      isTooltipInside,
      tooltipTitle,
      tooltipText,
      onKeyUp,
      isLat,
      type,
    } = this.props;

    const { showTooltip, gradesValue, minutesValue, secondsValue, isFocus, units } = this.state;
    const errorText = errorCode ? ERROR_MESSAGES_ES[errorCode] : '';
    return (
      <InputCoordBoxContainer
        className={`${className} ${disabled ? 'disabled' : ''}
        ${errorCode ? ' error' : ''} ${isFocus && 'focus'}
        ${required ? ' required' : ''} ${showTooltip ? 'tooltip' : ''}`}
      >
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText>{labelText}</LabelText>
          </div>
          {withTooltip && !isTooltipInside && (
            <div
              className={`input-box-topbar-icon ${showTooltip ? 'active' : ''}`}
              onClick={() =>
                this.setState((prevState: InputCoordBoxState) => ({ showTooltip: !prevState.showTooltip }))
              }
            >
              <img src={disabled ? images.infoSvg : images.infoSvg} />
              <div className="input-box-tooltip">
                <TooltipBox active={showTooltip} tooltipTitle={tooltipTitle} tooltipText={tooltipText} />
              </div>
            </div>
          )}
        </div>

        <div className="input-box-main">
          <div className={'input-box-main-field'}>
            <div className="input-wrapper">
              <input
                type={'number'}
                autoComplete={autocompleteOff ? 'nope' : ''}
                placeholder={'0.0'}
                value={gradesValue}
                maxLength={200}
                onFocus={e => this.handleFocus(e)}
                onBlur={e => {
                  if (!disabled) {
                    this.handleChange();
                  }
                }}
                onChange={e => {
                  if (!disabled) {
                    this.setState({ gradesValue: e.target.value });
                  }
                }}
                onKeyUp={e => {
                  if (!disabled && onKeyUp) onKeyUp(e);
                  // if (e.which === 13) this.setState({ gradesValue: '' });
                }}
                readOnly={disabled}
              />
              <div className="input-box-field">{'º'}</div>
              <input
                type={'number'}
                autoComplete={autocompleteOff ? 'nope' : ''}
                value={minutesValue}
                placeholder={'0.0'}
                maxLength={200}
                onFocus={e => this.handleFocus(e)}
                onBlur={e => {
                  if (!disabled) {
                    this.handleChange();
                  }
                }}
                onChange={e => {
                  if (!disabled) {
                    this.setState({ minutesValue: e.target.value });
                  }
                }}
                onKeyUp={e => {
                  if (!disabled && onKeyUp) onKeyUp(e);
                  // if (e.which === 13) this.setState({ gradesValue: '' });
                }}
                readOnly={disabled}
              />
              <div className="input-box-field">{"'"}</div>
              {type !== 'minute' && [
                <input
                  key="input-second"
                  type={'number'}
                  autoComplete={autocompleteOff ? 'nope' : ''}
                  value={secondsValue}
                  placeholder={'0.0'}
                  maxLength={200}
                  onFocus={e => this.handleFocus(e)}
                  onBlur={e => {
                    if (!disabled) {
                      this.handleChange();
                    }
                  }}
                  onChange={e => {
                    if (!disabled) {
                      this.setState({});
                      this.setState({ secondsValue: e.target.value });
                    }
                  }}
                  onKeyUp={e => {
                    if (!disabled && onKeyUp) onKeyUp(e);
                    // if (e.which === 13) this.setState({ gradesValue: '' });
                  }}
                  readOnly={disabled}
                />,
                <div key="div-second" className="input-box-field">
                  {' '}
                  {'"'}
                </div>,
              ]}
              <select
                value={units}
                onChange={(ev: any) => this.setState({ units: ev.target.value }, () => this.handleChange())}
              >
                {isLat ? (
                  <>
                    <option value="N">N</option>
                    <option value="S">S</option>
                  </>
                ) : (
                  <>
                    <option value="W">W</option>
                    <option value="E">E</option>
                  </>
                )}
              </select>
            </div>
          </div>

          <div
            className="input-box-icon"
            onClick={() => {
              if (withTooltip && isTooltipInside) {
                this.setState((prevState: InputCoordBoxState) => ({
                  showTooltip: !prevState.showTooltip,
                }));
              }
            }}
          >
            <img src={disabled ? iconDisabled : icon} />

            {withTooltip && isTooltipInside && (
              <div className="input-box-tooltip">
                <TooltipBox dark={true} active={showTooltip} tooltipTitle={tooltipTitle} tooltipText={tooltipText} />
              </div>
            )}
          </div>
        </div>
        <div className="input-box-error">{!disabled && errorCode && <InfoText>{`${errorText}`}</InfoText>}</div>
      </InputCoordBoxContainer>
    );
  }
}

export default InputCoordBox;
