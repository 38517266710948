import { TableColumn } from '../../../types/table';
import { useTranslation } from 'react-i18next';


export interface CentreStaff {
  name: string;
  location: string;
  staff: Staff[];
}

export interface Staff {
  id: string;
  img: string;
  name: string;
  surname: string;
  rating: number;
  role: string;
  email: string;
}

export interface StaffForm {
  userId?: string;
  name: string;
  surname: string;
  email: string;
  centres: Role[];
  [key: string]: any;
}

export interface Role {
  role: string;
  centre: string;
  name: string;
}

export const tableColumns = (t: any) => [
  {
    text: 'Nombre',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Rol',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Fech. creación',
    filter: false,
    ascendent: true,
  },
  {
    text: t('Email'),
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
];

export const rowKeys: string[] = ['name', 'role', 'createdAt', 'email', 'detail', 'options'];
