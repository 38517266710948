import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalInviteStyle = styled.div`
  width: 416px;
  padding: 48px 32px 32px;
  .modal-invite-pop  {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    width: 100%;
  }

  .modal-invite-title  {
    margin-bottom: 12px;
    width: 100%;
  }
  .modal-invite-text  {
    width: 100%;
    margin-bottom: 28px;
    color: ${colors['NE-002/100']};
    &__item {
      margin-bottom: 12px;
      &:last-child  {
        margin-bottom: 0px;
      }
    }
  }
  .modal-invite-form {
    width: 100%;
     margin-bottom: 28px;
    &__item {
      margin-bottom: 12px;
      &:last-child  {
        margin-bottom: 0px;
      }
    }
  }
  .modal-invite-button  {
    width: 100%;
  }
`;
