import * as React from 'react';
import { DiveSiteDetailMapStyle } from './dive-site-detail-map-style';
import GoogleMap from 'google-map-react';
import CardMap from '../../components/card-map/card-map';
import { mapStyle } from '../../constants/map-style';
import LabelText from '../text/label-text/label';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';
import Label02Text from '../text/label-02-text/label-02';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import { GMAPS_KEY } from '../../env';
import { images } from '../../assets/images';
import { decimalToGrades, textWithPoints } from '../../utils/texts';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { DiveKindsKeyValues } from '../../constants/dive-dropdowns';
import { transformToUser, getUserUnit } from '../../utils/measurement-units';
import { getUserLanguageComment } from '../../utils/other';
import { ConvertDMSToDD, ConvertDDToDMS, ConvertDDToDDM, ConvertDDMToDD } from '../../utils/measurement-units';
export interface IDiveSiteDetailMapProps extends WithTranslation {
  item: any;
  agent?: {
    units: any
  };
}

export interface IDiveSiteDetailMapState { }

class DiveSiteDetailMap extends React.Component<IDiveSiteDetailMapProps, IDiveSiteDetailMapState> {
  constructor(props: IDiveSiteDetailMapProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, t, agent } = this.props;
    const units = JSON.parse(localStorage.getItem('units') || '[]');

    /*
        if(!agent && !agent.units){
          agent.units = JSON.parse(localStorage.getItem('units'))
        }*/
    /*const coordinates: any = item
      ? decimalToGrades(parseFloat(item.latitude), parseFloat(item.longitude))
      : { lat: 0, lng: 0 };*/
    let coordLat: any;
    let coordLng: any;
    let coords: any;
    if (item.latitude) {
      const floatValue = parseFloat(item.latitude);


      if (units.coordinates === 'degree') {
        coordLat = ConvertDDToDMS(floatValue, true);
        coordLat = coordLat.deg + 'º ' + coordLat.min + "' " + coordLat.dir
      } else if (units.coordinates === 'minute') {
        coordLat = ConvertDDToDDM(floatValue, true);
        coordLat = coordLat.dir + ' ' + coordLat.deg + 'º ' + coordLat.min
      }
      let floatValueLng = parseFloat(item.longitude);
      if (units.coordinates === 'degree') {
        coordLng = ConvertDDToDMS(floatValueLng, true);
        coordLng = coordLng.deg + 'º ' + coordLng.min + "' " + coordLng.dir
      } else if (units.coordinates === 'minute') {
        coordLng = ConvertDDToDDM(floatValueLng, true);
        coordLng = coordLng.dir + ' ' + coordLng.deg + 'º ' + coordLng.min
      }
      else {
        coords = decimalToGrades(parseFloat(item.latitude), parseFloat(item.longitude))
      }

      //const coord: any = type === 'minute' ? ConvertDDToDDM(floatValue, isLat) : ConvertDDToDMS(floatValue, isLat);

    }
    /*
          if (item.longitude) {
            const floatValue = parseFloat(item.longitude);
            if (floatValue) {
              coordLng = agent.units.type === 'minute' ? ConvertDDMToDD(floatValue, item.longitude) : ConvertDMSToDD(floatValue, item.longitude);
            }
          } else {
            coordLng = decimalToGrades(parseFloat(item.latitude), parseFloat(item.longitude))
          }*/

    const description = item && item.description ? getUserLanguageComment(item.description) : false;

    return (
      <DiveSiteDetailMapStyle>
        <div className="dive-site-card">
          <div className="dive-site-card-container">
            {item && item.kind && (
              <div className="dive-site-card-title">
                <div className="dive-site-card-title__subtile">
                  <LabelText>
                    {t('components:dive-site-detail-map.kind-of')}{' '}
                    {item && item.kind ? t('components:dive-site-detail-map.site') : '' // t('components:dive-site-detail-map.reserve')}
                    }
                  </LabelText>
                </div>
                <div className="dive-site-card-title">
                  {/* TODO: IDIOMAS */}
                  {/* TODO: ADD MORE SHAPE OPTIONS */}
                  <SubheadDestacadoText>{item ? DiveKindsKeyValues[item.kind] : ''}</SubheadDestacadoText>
                </div>
              </div>
            )}

            <div className="dive-site-card-row">
              <div className="dive-site-card-row__item">
                <div className="dive-site-card-row__item__subtitle">
                  <Label02Text>{t('components:dive-site-detail-map.lat')}</Label02Text>
                </div>
                <div className="dive-site-card-row__item__text">
                  <BodyDestacadoText>{coordLat && units.coordinates === 'degree' || units.coordinates === 'minute' ? coordLat : coords && coords.lat}
                  </BodyDestacadoText>
                </div>
              </div>

              <div className="dive-site-card-row__item">
                <div className="dive-site-card-row__item__subtitle">
                  <Label02Text>{t('components:dive-site-detail-map.lng')}</Label02Text>
                </div>
                <div className="dive-site-card-row__item__text">
                  <BodyDestacadoText>{coordLng && units.coordinates === 'degree' || units.coordinates === 'minute' ? coordLng : coords && coords.lng}</BodyDestacadoText>
                </div>
              </div>
            </div>
            {item && item.minDepth && (
              <div className="dive-site-card-row">
                <div className="dive-site-card-row__item">
                  <div className="dive-site-card-row__item__subtitle">
                    <Label02Text>{t('components:dive-site-detail-map.minDepth')}</Label02Text>
                  </div>
                  <div className="dive-site-card-row__item__text">
                    <BodyDestacadoText>
                      {item ? transformToUser('depth', item.minDepth) : ''} {getUserUnit('depth')}
                    </BodyDestacadoText>
                  </div>
                </div>
                <div className="dive-site-card-row__item">
                  <div className="dive-site-card-row__item__subtitle">
                    <Label02Text>{t('components:dive-site-detail-map.maxDepth')}</Label02Text>
                  </div>
                  <div className="dive-site-card-row__item__text">
                    <BodyDestacadoText>
                      {item ? transformToUser('depth', item.maxDepth) : ''} {getUserUnit('depth')}
                    </BodyDestacadoText>
                  </div>
                </div>
              </div>
            )}
            {item && item.radius && (
              <div className="dive-site-card-row">
                <div className="dive-site-card-row__item">
                  <div className="dive-site-card-row__item__subtitle">
                    <Label02Text>{t('components:dive-site-detail-map.radius')}</Label02Text>
                  </div>
                  <div className="dive-site-card-row__item__text">
                    <BodyDestacadoText>
                      {item ? `${transformToUser('distance', item.radius)} ${getUserUnit('distance')}` : ''}
                    </BodyDestacadoText>
                  </div>
                </div>
              </div>
            )}

            {item && item.reserve && (
              <div className="dive-site-card-item">
                <div className="dive-site-card-row__item__subtitle">
                  <Label02Text>{t('components:dive-site-detail-map.marine-reserve')}</Label02Text>
                </div>
                <div className="dive-site-card-item__text">
                  <BodyDestacadoText>{item ? item.reserve.name : ''}</BodyDestacadoText>
                </div>
              </div>
            )}

            {item && description && (
              <div className="dive-site-card-item">
                <div className="dive-site-card-row__item__subtitle dive-site-card-row__item__subtitle--description">
                  <Label02Text>{t('components:dive-site-detail-map.description')}</Label02Text>
                </div>
                <div className="dive-site-card-item__text">
                  <BodyDestacadoText>{description}</BodyDestacadoText>
                </div>
              </div>
            )}
          </div>
        </div>
        {item && item.latitude && item.longitude && (
          <div className="dive-site-map">
            <GoogleMap
              options={{
                styles: mapStyle,
                mapTypeId: 'hybrid',
              }}
              bootstrapURLKeys={{ key: GMAPS_KEY }}
              defaultCenter={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
              defaultZoom={9.5}
            >
              <CardMap
                key={`card-map-1`}
                className='CardMap'
                spot={'dive-spot'}
                lng={item.lng}
                lat={item.lat}
                type={{ icon: '', text: item.name }}
                title={item.title}
                locationDive={item.state + ' ' + item.location}
                depth={item.depth}
                rate={item.rate} // RAITING O RATINGS?
                next={item.next}
                imagesSite={[]}
                link={'#'}
              />
            </GoogleMap>
          </div>
        )}
      </DiveSiteDetailMapStyle>
    );
  }
}

export default withTranslation(['intro', 'buttons', 'components'])(DiveSiteDetailMap);
