import * as React from 'react';
import { MarineReserveRateStyle } from './marine-reserve-rate-style';
import H2Text from '../../../components/text/h2-text/h2-text';
import StarRate from '../../../components/star-rate/star-rate';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import { images } from '../../../assets/images';
import ItemReview from '../../../components/item-review/item-review';
import Pagination from '../../../components/pagination';
import { RouteComponentProps, withRouter } from 'react-router';
import { getOneMarineReserve } from '../../../services/marine-reserve';
import { getDateMonthAndYearString } from '../../../utils/time';
import { REVIEWS_PER_PAGE } from '../../../constants/data';
import { getDiveSiteRatings } from '../../../services/dive-site-raiting';
import { getDiveDetail } from '../../../services/diver';

interface RouteParams {
  id: string;
}

export interface IMarineReserveRateProps extends RouteComponentProps<RouteParams> {}

export interface IMarineReserveRateState {
  marineReserve: any;
  reviews: any[];
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
}

class MarineReserveRate extends React.Component<IMarineReserveRateProps, IMarineReserveRateState> {
  constructor(props: IMarineReserveRateProps) {
    super(props);

    this.state = {
      marineReserve: {
        visibilityRating: 0,
        flowRating: 0,
        marineLifeRating: 0,
      },
      reviews: [],
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.getMarineReserve(match.params.id);
      this.getReviews(match.params.id);
    }
  }

  getMarineReserve = async (id: string) => {
    try {
      const params = {};
      const response: any = await getOneMarineReserve(id, params);

      this.setState({ marineReserve: response.data });
    } catch (err) {}
  };

  getReviews = async (id: string) => {
    const { skipPage } = this.state;
    const params = {
      filter: {
        where: {
          id: { value: id, collection: 'reserve' },
        },
        skip: skipPage,
        limit: REVIEWS_PER_PAGE,
        relations: ['site', 'site.reserve', 'diver'],
      },
    };
    try {
      const reviews: any[] = [];
      const response: any = await getDiveSiteRatings(params);
      const data = response.data.data;
      for (const item of data) {
        let userRes: any = {};
        if (item.diver.id) {
          userRes = await getDiveDetail(item.diver.id, ['user']);
        }
        reviews.push({
          img: userRes.data && userRes.data.picture ? userRes.data.picture : images.jellyfishIntro,
          name: userRes.data ? `${userRes.data.name} ${userRes.data.surname}` : '-',
          date: getDateMonthAndYearString(item.createdAt),
          comment: [item.comment],
          visibility: item.visibility,
          flowing: item.flow,
          diversity: item.marineLife,
        });
      }
      const totalPages = Math.ceil(response.data.count / REVIEWS_PER_PAGE);

      this.setState({ totalPages, reviews, total: response.data.count });
    } catch (err) {}
  };

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = REVIEWS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        const { match } = this.props;
        if (match.params.id) {
          this.getReviews(match.params.id);
        }
      });
    }
  }

  render() {
    const { marineReserve, reviews, totalPages, total, page } = this.state;
    const rating: number = marineReserve.visibilityRating + marineReserve.flowRating + marineReserve.marineLifeRating;
    return (
      <MarineReserveRateStyle>
        <div className="dive-site-rate-hero">
          <div className="dive-site-rate-hero-total">
            <div className="dive-site-rate-hero-total__number">
              <H2Text>{rating}</H2Text>
            </div>
            <div className="dive-site-rate-hero-total__stars">
              <StarRate type={'big'} number={rating / 2} />
            </div>
            <div className="dive-site-rate-hero-total__reviews">
              <SubtitleText>{total} evaluaciones</SubtitleText>
            </div>
          </div>

          <div className="dive-site-rate-hero-detail">
            <div className="dive-site-rate-hero-detail__item">
              <div className="dive-site-rate-hero-detail__item__text">
                <SubheadDestacadoText>Visibilidad</SubheadDestacadoText>
              </div>
              <div className="dive-site-rate-hero-detail__item__stars">
                <StarRate type="middle" number={marineReserve.visibilityRating / 2} />
              </div>
            </div>
            <div className="dive-site-rate-hero-detail__item">
              <div className="dive-site-rate-hero-detail__item__text">
                <SubheadDestacadoText>Corriente</SubheadDestacadoText>
              </div>
              <div className="dive-site-rate-hero-detail__item__stars">
                <StarRate type="middle" number={marineReserve.flowRating / 2} />
              </div>
            </div>
            <div className="dive-site-rate-hero-detail__item">
              <div className="dive-site-rate-hero-detail__item__text">
                <SubheadDestacadoText>Vida marina</SubheadDestacadoText>
              </div>
              <div className="dive-site-rate-hero-detail__item__stars">
                <StarRate type="middle" number={marineReserve.marineLifeRating / 2} />
              </div>
            </div>
          </div>
        </div>

        <div className="dive-site-rate-reviews">
          {reviews.map((item: any, index: number) => (
            <div key={index} className="dive-site-rate-reviews__item">
              <ItemReview
                img={item.img}
                name={item.name}
                date={item.date}
                comment={item.comment}
                visibility={item.visibility}
                flowing={item.flowing}
                diversity={item.diversity}
              />
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <div className="dive-site-rate-pagination">
            <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
          </div>
        )}
      </MarineReserveRateStyle>
    );
  }
}

export default withRouter(MarineReserveRate);
