import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ItemMailTemplateStyle = styled.div`
  display: flex;
  padding-top: 19px;
  width: 100%;
  .item-mail-temp-number {
    padding: 0 24px;
  }

  .item-mail-temp-content  {
    display: flex;
    width: 100%;
    padding-bottom: 19px;
    border-bottom: 1px solid ${colors['NE-002/100']};
    &__title {
      flex: auto;
    }
    &__detail {
      cursor: pointer;
    }
    &__languages {
      display: flex;
      margin-right: 80px;

      &__item {
        border-radius: 3px;
        background: ${colors['NE-002/10']};
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        height: 32px;
        width: 32px;
        font-size: 10px;
        line-height: 14px;

        &.disabled {
          opacity: 0.5;
          filter: grayscale(80%);
        }

        &.black  {
          color: ${colors['NE-001/100']};
        }
        &.yellow  {
          color: ${colors['CO-002/100']};
        }
        &.green  {
          color: ${colors['CO-001/100']};
        }
        &.red  {
          color: ${colors['CO-003/100']};
        }
        &.light-blue  {
          color: ${colors['PR-001/100']};
        }
        &.blue  {
          color: ${colors['SE-002/100']};
        }
      }
    }
  }
`;
