import styled from 'styled-components';

export const ButtonLoaderStyle = styled.div`
  @keyframes spinner-body {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .spinner-body div {
    left: 47px;
    top: 22px;
    position: absolute;
    animation: spinner-body linear 1s infinite;
    background: #fff;
    width: 6px;
    height: 12px;
    border-radius: 0px / 0px;
    transform-origin: 3px 28px;
  }
  .spinner-body div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9s;
    background: #fff;
  }
  .spinner-body div:nth-child(2) {
    transform: rotate(36deg);
    animation-delay: -0.8s;
    background: #fff;
  }
  .spinner-body div:nth-child(3) {
    transform: rotate(72deg);
    animation-delay: -0.7s;
    background: #fff;
  }
  .spinner-body div:nth-child(4) {
    transform: rotate(108deg);
    animation-delay: -0.6s;
    background: #fff;
  }
  .spinner-body div:nth-child(5) {
    transform: rotate(144deg);
    animation-delay: -0.5s;
    background: #fff;
  }
  .spinner-body div:nth-child(6) {
    transform: rotate(180deg);
    animation-delay: -0.4s;
    background: #fff;
  }
  .spinner-body div:nth-child(7) {
    transform: rotate(216deg);
    animation-delay: -0.3s;
    background: #fff;
  }
  .spinner-body div:nth-child(8) {
    transform: rotate(252deg);
    animation-delay: -0.2s;
    background: #fff;
  }
  .spinner-body div:nth-child(9) {
    transform: rotate(288deg);
    animation-delay: -0.1s;
    background: #fff;
  }
  .spinner-body div:nth-child(10) {
    transform: rotate(324deg);
    animation-delay: 0s;
    background: #fff;
  }
  .spinner {
    width: 28px;
    height: 28px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
  }
  .spinner-body {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.28);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  .spinner-body div {
    box-sizing: content-box;
  }
`;
