import React, { Component } from 'react';
import { StarRateContainer } from './star-rate-style';
import { images } from '../../assets/images';

interface StarRateProps {
  number: number;
  editable?: boolean;
  onRate?: (value: number) => void;
  type?: string;
}
interface StarRateState {
  numberHover?: number;
}

export default class StarRate extends Component<StarRateProps, StarRateState> {
  state: StarRateState = {};

  render() {
    const { number, editable, onRate, type } = this.props;
    const { numberHover } = this.state;

    const stars = [];
    // tslint:disable-next-line: no-increment-decrement
    for (let i = 1; i <= 5; i++) {
      stars.push(
        // tslint:disable-next-line: jsx-wrap-multiline
        <div
          key={i}
          className="star-item"
          onClick={() => {
            if (onRate) {
              onRate(i);
            }
          }}
          onMouseEnter={() => {
            if (editable) {
              this.setState({ numberHover: i });
            }
          }}
          onMouseLeave={() => {
            if (editable) {
              this.setState({ numberHover: undefined });
            }
          }}
        >
          <img
            src={
              (!!numberHover && i <= numberHover) || (!numberHover && i <= number) ? images.starRedFullSvg : images.starBlackSvg
            }
            alt=""
          />
        </div>
      );
    }
    return <StarRateContainer className={type}>{stars}</StarRateContainer>;
  }
}
