export const FOOD_ACCOMMODATION_DROPDOWN = [
  { value: 'breakfast', label: 'Sólo desayuno' },
  { value: 'half pension', label: 'Media pensión' },
  { value: 'full pension', label: 'Pensión completa' },
  { value: 'all but alcohol', label: 'Todo incluido (excepto bebidas alcohólicas)' },
  { value: 'all included', label: 'Todo incluido' },
];

export const FOOD_ACCOMMODATION_KEY_VALUES: any = {
  breakfast: 'Sólo desayuno',
  'half pension': 'Media pensión',
  'full pension': 'Pensión completa',
  'all but alcohol': 'Todo incluido (excepto bebidas alcohólicas)',
  'all included': 'Todo incluido',
};
