import * as React from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import ModalCreateDocument from './modal-create-document/modal-create-document';
import { RouteComponentProps, match } from 'react-router';
import { getCentreDocuments, deleteCentreDocument } from '../../../services/centre';
import { ICentreDocument } from '../../../models/document-forms';
import { copyOf } from '../../../utils/other';
import FileUtils from '../../../utils/files';
import moment from 'moment';
import { CENTRE_DOCUMENTS_TYPES_KEY_VALUES } from '../../../constants/documents';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import services from '../../../services/services';
import ModalDocument from '../../../components/modals/modal-document/modal-document';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { havePermissionsDeals } from '../../../services/staff-centre';
const CENTRE_DOCUMENTS_PER_PAGE = 10;

export interface IMyCenterDocumentationProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  staffPermissions?: any;
}

export interface IMyCenterDocumentationState {
  renderModal: string;
  documents: ICentreDocument[];
  selectedDocument?: ICentreDocument;
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
}

class MyCenterDocumentation extends React.Component<IMyCenterDocumentationProps, IMyCenterDocumentationState> {
  constructor(props: IMyCenterDocumentationProps) {
    super(props);

    this.state = {
      renderModal: '',
      documents: [],
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
    };
  }

  componentDidMount() {
    this.getCentreDocuments();
  }

  async getCentreDocuments() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { skipPage } = this.state;
    const params = {
      filter: {
        skip: skipPage,
        limit: CENTRE_DOCUMENTS_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
      },
    };
    const resp: any = await getCentreDocuments(id, params);
    const documents = resp.data.data;
    const totalPages: number = Math.ceil(resp.data.count / CENTRE_DOCUMENTS_PER_PAGE);
    this.setState({ documents, totalPages, total: resp.data.count });
  }

  async handleRemove() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { selectedDocument } = this.state;
    if (selectedDocument && selectedDocument.id !== undefined) {
      await deleteCentreDocument(id, selectedDocument.id.toString());
      services.pushNotification({
        title: '¡Eliminado!',
        text: 'El documento se ha eliminado con éxito',
        type: 'red',
      });

      this.getCentreDocuments();
    }
  }

  getTableRows() {
    const { staffPermissions } = this.props;
    const { documents } = this.state;
    const tableRows: any[] = [];
    documents.forEach((document: ICentreDocument) => {
      const tableRow = copyOf(this.tableRowTemplate);

      const itemIcon = FileUtils.getExtension(document.file).toLowerCase()
      /*.toLowerCase()
      .replace(/x([^x]*)$/, '')
      .replace(/[0-9]/g, '')
      .replace('e', '');*/
      tableRow.icon.className = 'file-extension-icon';
      tableRow.icon.value = itemIcon;
      tableRow.name.value = document.name;
      tableRow.type.value = CENTRE_DOCUMENTS_TYPES_KEY_VALUES[document.type];
      tableRow.id.value = document.customId;
      //tableRow.expiration.value = document.expirationDate ? moment(document.expirationDate).format('DD/MM/YYYY') : '-';
      if (!staffPermissions || staffPermissions.deals) {
        tableRow.delete.onClick = () => {
          this.setState({ selectedDocument: document, renderModal: 'modal-delete-document' });
        };
      } else {
        tableRow.delete = {
          key: '',
          value: ' ',
        };
      }
      tableRow.detail.onClick = () => {
        this.setState({ selectedDocument: document, renderModal: 'modal-view-document' });
      };
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CENTRE_DOCUMENTS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getCentreDocuments();
      });
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      staffPermissions,
    } = this.props;
    const { renderModal, totalPages, selectedDocument, page } = this.state;
    return (
      <>
        <HeaderMain
          title={'Documentación'}
          mainButton={
            !staffPermissions || staffPermissions.deals
              ? {
                disabled: !havePermissionsDeals(),
                img: images.createWhiteSvg,
                text: 'Agregar documento',
                onClick: () => this.setState({ renderModal: 'create-document' }),
              }
              : undefined
          }
        />
        <MainLayoutSection>
          <MainLayoutTableContainer>
            <div className="diving-center-table">
              <MainTableLayout
                columns={this.tableColumns}
                rows={this.getTableRows()}
                rowKeys={this.rowKeys}
                onOrder={() => { }}
              />
            </div>
            {totalPages > 1 && (
              <div className="diving-center-pagination">
                <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
              </div>
            )}
          </MainLayoutTableContainer>
        </MainLayoutSection>
        {renderModal === 'create-document' && (
          <ModalCreateDocument
            centreId={id}
            onSuccess={() => {
              this.setState({ renderModal: '' });
              this.getCentreDocuments();
            }}
            onClose={() => this.setState({ renderModal: '' })}
            showModal={renderModal === 'create-document'}
          />
        )}
        {renderModal === 'modal-delete-document' && (
          <ModalDelete
            title={''}
            subtitle={'Vas a eliminar un document del centro ¿estás seguro?'}
            showModal={renderModal === 'modal-delete-document'}
            onCancel={() => this.setState({ renderModal: '' })}
            onRemove={() => {
              this.setState({ renderModal: '' });
              this.handleRemove();
            }}
          />
        )}
        {renderModal === 'modal-view-document' && selectedDocument !== undefined && (
          <ModalDocument
            showModal={renderModal === 'modal-view-document'}
            onClose={() => {
              this.setState({ renderModal: '' });
            }}
            type={CENTRE_DOCUMENTS_TYPES_KEY_VALUES[selectedDocument.type]}
            title={selectedDocument.name}
            id={selectedDocument.customId}
            expirationDate={selectedDocument.expirationDate}
            file={selectedDocument.file}
          />
        )}
      </>
    );
  }

  tableColumns = [
    {
      text: 'Formato',
      filter: false,
      ascendent: true,
      key: '',
    },
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Tipo de documento',
      filter: false,
      ascendent: false,
    },
    {
      text: 'ID',
      filter: false,
      ascendent: true,
    },/*
    {
      text: 'F. Caducidad',
      filter: false,
      ascendent: true,
    },*/
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    icon: {
      key: 'file',
      value: 'pdf',
    },
    name: {
      key: 'highlighted-body',
      value: 'Seguro de buceo para inmersiones',
    },
    type: {
      key: '',
      value: 'Seguro',
    },
    id: {
      key: '',
      value: '2938593',
    },/*
    expiration: {
      key: '',
      value: '18/09/2022',
    },*/
    detail: {
      key: 'detail',
      value: '/centers/1',
    },
    delete: {
      key: 'icon-button',
      value: images.deleteSvg,
    },
  };

  rowKeys = ['icon', 'name', 'type', 'id', 'detail', 'delete'];
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(MyCenterDocumentation);
