import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const DicotomicCheckboxContainer = styled.div`
  &.mandatory {
    .checkbox-container input:checked ~ .checkmark-container {
      background: ${colors['NE-004-30']};

      .checkmark-item {
        background: ${colors['TX-001-100']};
      }
    }
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-container {
        justify-content: flex-end;

        .checkmark-item {
          background: ${colors['PR-001-100']};
        }

        img {
          max-width: 14px;
        }
      }
    }
  }

  .checkbox-error {
    color: red;
    display: block;
  }

  .checkmark-container {
    padding-top: 3px;
    display: inline-block;
    transition: 0.3s all ease-in-out;
    margin-right: 13px;

    img {
      max-width: 10px;
    }
  }

  .checkmark-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 14px;
    height: 14px;
    border: 1px solid ${colors['PR-001/100']};
    background: white;
  }

  &.error {
    .checkmark-item {
      border: 1px solid ${colors['CO-003/100']};
    }
  }

  &.active {
    .checkmark-item {
      background: ${colors['PR-001/100']};
      img {
        filter: invert(100%);
      }
    }
  }

  &.disable {
    color: ${colors['NE-003/100']};
    pointer-events: none;
    .checkmark-item {
      border: 1px solid ${colors['NE-003/100']};
      background: none;
    }
  }

  &.table {
    .checkbox-container {
      .checkmark-container {
        margin-right: 0px;
        padding-top: 0px;
      }
    }
  }
  &.light-blue {
  }
  &.switch {
    background: salmon;
    width: 44px;
    height: 21px;
    border-radius: 100px;
    position: relative;
    background: ${colors['NE-002/10']};
    transition: all 0.3s ease-in-out;
    .checkmark-container {
      margin: 0;
      padding-top: 0;
    }
    .checkmark-item {
      transition: all 0.3s ease-in-out;
      border-radius: 100%;
      width: 15px;
      height: 15px;
      position: absolute;
      left: 3px;
      top: 3px;
      border: 0px;
      background: ${colors['NE-002/100']};
      img {
        display: none;
      }
    }

    &.active {
      background: ${colors['PR-001/100']};
      .checkmark-item {
        border-radius: 100%;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 25px;
        background: white;
      }
    }
  }

  &.rounded {
    .checkmark-container {
      border-radius: 100%;

      img {
        max-width: 10px;
      }
    }

    .checkmark-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      width: 14px;
      height: 14px;
      transition: all 0.3s ease-in-out;
      &::before  {
        content: '';
        width: 6px;
        height: 6px;
        background: white;
        border-radius: 100%;
      }
      img {
        display: none;
      }
    }
    &.active {
      .checkmark-item {
        background: ${colors['PR-001/100']};
      }
    }
  }
`;
