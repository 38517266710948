import React, { Component } from 'react';
import { OrganizationMenuContainer } from './organization-menu-style';
import { images } from '../../assets/images/index';
import MainButton from '../main-button';
import { NavLink, withRouter, RouteComponentProps, match } from 'react-router-dom';
import ModalLogOut from '../modals/modal-logout/modal-logout';
import MainButtonDrop from '../main-button-drop';
import LabelText from '../text/label-text/label';
import InfoText from '../text/info-text/info-text';
import { withTranslation, WithTranslation } from 'react-i18next';
import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { getOrganization } from '../../services/collaborator';
interface OrganizationMenuProps extends RouteComponentProps<any>, WithTranslation {
  match: match<{
    organizationId: string;
  }>;
  centreId: string;
  onLogout: () => void;
  agent?: any;
  staffPermissions?: any;
  profilePicture?: string;
}

interface OrganizationMenuState {
  modalLogOut: boolean;
  sectionOpen: string;
  activeSection: string;
  keyNavItem?: string;
  isCertifier?: boolean;
}
class OrganizationMenu extends Component<OrganizationMenuProps, OrganizationMenuState> {
  state: OrganizationMenuState = {
    modalLogOut: false,
    sectionOpen: '',
    activeSection: this.props.history.location.pathname,
    keyNavItem: this.props.history.location.pathname,
  };

  componentDidMount() {
    this.getOrganization();
  }

  async getOrganization() {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;
    const id = !isNaN(+organizationId) ? organizationId : localStorage.getItem('organizationId');
    if (!id) {
      return;
    }
    const resp = await getOrganization(id);
    const isCertifier = resp.data.type === 'certifier';
    this.setState({ isCertifier });
  }

  static getDerivedStateFromProps(nextProps: any, prevState: OrganizationMenuState) {
    if (nextProps.history.location.pathname !== prevState.activeSection) {
      return { activeSection: nextProps.history.location.pathname };
    }
    return null;
  }

  componentDidUpdate(prevProps: any, prevState: OrganizationMenuState) {
    if (prevProps.history.location.pathname !== this.props.history.location.pathname) {
      this.setState({ activeSection: this.props.history.location.pathname });
    }
  }

  render() {
    const { modalLogOut, activeSection, sectionOpen, isCertifier } = this.state;
    const { onLogout, t, agent, staffPermissions, profilePicture } = this.props;
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;
    const sections: { [key: string]: string } = {
      profile: 'profile',
      projects: 'projects',
      catalog: 'catalog',
      divers: 'divers',
      centres: 'centres',
      stats: 'stats',
      orgStats: 'organization-stats',
      messages: 'messages',
      notifications: 'notifications',
      roles: 'roles',
      collaborators: 'collaborators',
      config: 'configuration',
      billings: 'billings',
    };
    const id = !isNaN(+organizationId) ? organizationId : localStorage.getItem('organizationId');

    const basePath = `/organization/${id}`;
    return (
      <>
        <OrganizationMenuContainer>
          <div className="menu-top">
            <div className="menu-logo">
              <NavLink to="/">
                <img src={images.diveasappLogoWhiteSvg} alt="" />
              </NavLink>
            </div>
            <div className="menu-back">
              {agent.roles.includes('superAdmin') && (
                <div
                  className="menu-back-superadmin"
                  onClick={() =>
                    isCertifier
                      ? this.props.history.push('/collaborators/certifiers')
                      : this.props.history.push('/collaborators/marine-conservation')
                  }
                >
                  <div className="menu-back-superadmin__text">
                    <InfoText>{t('layouts:detail-menu.super-admin')}</InfoText>
                  </div>
                  <div className="menu-back-superadmin__back">
                    <LabelText>{t('buttons:back')}</LabelText>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Scrollbars
            renderTrackVertical={props => <div {...props} className="track-vertical" />}
            renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
            style={{ width: '100%', height: '100%' }}
            autoHide={false}
            autoHideTimeout={200}
            autoHideDuration={200}
          >
            <div className="menu-items">
              <div className="menu-items__button">
                <NavLink to={`${basePath}/${sections.profile}`}>
                  <MainButton
                    text={t(
                      isCertifier ? 'layouts:home-menu.certifier-profile' : 'layouts:home-menu.organization-profile'
                    )}
                    iconImg={images.profileCertifierMenu}
                    type="simple-menu"
                    active={activeSection.includes(sections.profile)}
                    onClick={() => {
                      const section = sectionOpen !== sections.profile ? sections.profile : '';
                      this.setState({ sectionOpen: section });
                    }}
                  />
                </NavLink>
              </div>

              {!isCertifier && (
                <div className="menu-items__button">
                  <NavLink to={`${basePath}/${sections.projects}`}>
                    <MainButton
                      text={t('layouts:home-menu.certifier-projects')}
                      iconImg={images.projectsSvg}
                      type="simple-menu"
                      active={activeSection.includes(sections.projects)}
                      onClick={() => {
                        const section = sectionOpen !== sections.projects ? sections.projects : '';
                        this.setState({ sectionOpen: section });
                      }}
                    />
                  </NavLink>
                </div>
              )}

              {isCertifier && (
                <div className="menu-items__button">
                  <NavLink to={`${basePath}/${sections.catalog}`}>
                    <MainButton
                      text={t('layouts:home-menu.certifier-catalog')}
                      iconImg={images.certifierCatalogWhite}
                      type="simple-menu"
                      active={activeSection === '/' || activeSection.includes(sections.catalog)}
                      onClick={() => {
                        const section = sectionOpen !== sections.catalog ? sections.catalog : '';
                        this.setState({ sectionOpen: section });
                      }}
                    />
                  </NavLink>
                </div>
              )}

              {isCertifier && (
                <div className="menu-items__button">
                  <NavLink to={`${basePath}/${sections.centres}`}>
                    <MainButton
                      text={t('layouts:home-menu.certifier-dive-center')}
                      iconImg={images.listDivingCenterWhite}
                      type="simple-menu"
                      active={activeSection.includes(sections.centres)}
                      onClick={() => {
                        const section = sectionOpen !== sections.centres ? sections.centres : '';
                        this.setState({ sectionOpen: section });
                      }}
                    />
                  </NavLink>
                </div>
              )}
              <div className="menu-items__button">
                <NavLink to={`${basePath}/${sections.divers}`}>
                  <MainButton
                    text={t('layouts:home-menu.certifier-my-divers')}
                    iconImg={images.myDivesMenu}
                    type="simple-menu"
                    active={activeSection.includes(sections.divers)}
                    onClick={() => {
                      const section = sectionOpen !== sections.divers ? sections.divers : '';
                      this.setState({ sectionOpen: section });
                    }}
                  />
                </NavLink>
              </div>
              {!isCertifier && (
                <div className="menu-items__button">
                  <NavLink to={`/organization/${sections.billings}`}>
                    <MainButton
                      text={t('layouts:home-menu.billing')}
                      iconImg={images.fileWhiteSvg}
                      type="simple-menu"
                      active={activeSection.includes(sections.billings)}
                      onClick={() => {
                        const section = sectionOpen !== sections.billings ? sections.billings : '';
                        this.setState({ sectionOpen: section });
                      }}
                    />
                  </NavLink>
                </div>
              )}

              {isCertifier && (
                <div className="menu-items__button">
                  <NavLink to={`${basePath}/${sections.stats}`}>
                    <MainButton
                      text={t('layouts:home-menu.certifier-stats')}
                      iconImg={images.chartWhiteSvg}
                      type="simple-menu"
                      active={activeSection.includes(sections.stats)}
                      onClick={() => {
                        const section = sectionOpen !== sections.stats ? sections.stats : '';
                        this.setState({ sectionOpen: section });
                      }}
                    />
                  </NavLink>
                </div>
              )}

              {!isCertifier && (
                <div className="menu-items__button">
                  <NavLink to={`${basePath}/${sections.orgStats}`}>
                    <MainButton
                      text={t('layouts:home-menu.certifier-stats')}
                      iconImg={images.chartWhiteSvg}
                      type="simple-menu"
                      active={activeSection.includes(sections.orgStats)}
                      onClick={() => {
                        const section = sectionOpen !== sections.orgStats ? sections.orgStats : '';
                        this.setState({ sectionOpen: section });
                      }}
                    />
                  </NavLink>
                </div>
              )}
              {!agent.roles.includes('organization_staff') && isCertifier && (
                <div className="menu-items__button">
                  <NavLink to={`${basePath}/${sections.roles}`}>
                    <MainButton
                      text={t('layouts:home-menu.certifier-roles')}
                      iconImg={images.rolesManagementMenu}
                      type="simple-menu"
                      active={activeSection.includes(sections.roles)}
                      onClick={() => {
                        const section = sectionOpen !== sections.roles ? sections.roles : '';
                        this.setState({ sectionOpen: section });
                      }}
                    />
                  </NavLink>
                </div>
              )}
              <div className="menu-items__button">
                {/* <MessagesDropdown
                      activeSection={activeSection}
                      sectionOpen={sectionOpen}
                      sections={sections}
                      to={[
                        `${basePath}/${sections.messages}`,
                        `${basePath}/${sections.notifications}`
                      ]}
                      setSectionOpen={this.setSectionOpen}
                    /> */}
                <MainButtonDrop
                  text={t('layouts:home-menu.certifier-notifications')}
                  iconImg={images.messageCircleWhiteSvg}
                  type="simple-menu"
                  propOpen={sectionOpen === sections.collaborators}
                  active={sectionOpen.includes(sections.collaborators)}
                  onClick={() => {
                    const section = sectionOpen !== sections.collaborators ? sections.collaborators : '';
                    this.setState({ sectionOpen: section });
                  }}
                >
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${basePath}/${sections.messages}`}>
                      <p>{t('layouts:home-menu.certifier-notifications')}</p>
                    </NavLink>
                  </div>
                  <div className="main-button-drop-content__item">
                    <NavLink to={`${basePath}/${sections.notifications}`}>
                      <p>{t('layouts:home-menu.notification')}</p>
                    </NavLink>
                  </div>
                </MainButtonDrop>
              </div>
              <div className="menu-items__button">
                <NavLink to={`${basePath}/${sections.config}`}>
                  <MainButton
                    text={t('layouts:home-menu.certifier-config')}
                    iconImg={images.settingsWhiteSvg}
                    type="simple-menu"
                    active={activeSection.includes(sections.config)}
                    onClick={() => {
                      const section = sectionOpen !== sections.config ? sections.config : '';
                      this.setState({ sectionOpen: section });
                    }}
                  />
                </NavLink>
              </div>
            </div>
          </Scrollbars>

          {/*  */}
          <div className="menu-bottom">
            <div className="menu-bottom__profile">
              <NavLink to="/my-profile">
                <MainButton
                  type="simple-menu avatar"
                  iconImg={profilePicture ? profilePicture : images.avatar}
                  text={t('layouts:detail-menu.my-profile')}
                />
              </NavLink>
            </div>
            <div className="menu-bottom__logout" onClick={() => this.setState({ modalLogOut: true })}>
              <MainButton
                type="simple-menu logout"
                iconImg={images.logOutWhiteSvg}
                text={t('layouts:detail-menu.close-session')}
              />
            </div>
          </div>
        </OrganizationMenuContainer>
        {/* Modal LogOut */}
        <ModalLogOut
          showModal={modalLogOut}
          onAccept={() => this.setState({ modalLogOut: false }, () => onLogout())}
          onCancel={() => this.setState({ modalLogOut: false })}
          title={t('modalMessages:close-session.title')}
          subtitle={t('modalMessages:close-session.subtitle')}
        />
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(
  withTranslation(['intro', 'buttons', 'layouts', 'modalMessages'])(withRouter(OrganizationMenu))
);
