import React from 'react';
import { ModalInviteStaffContainer } from './modal-invite-staff-style';
import { images } from '../../../assets/images';
import SubheadText from '../../text/subhead-text/subhead-text';
import MainButton from '../../main-button';
import ModalContainer from '../modal-container';
import InputBox from '../../input-box';
import InputArea from '../../input-area/input-area';
import DicotomicCheckbox from '../../dicotomic-checkbox';
import InfoText from '../../text/info-text/info-text';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

interface ModalProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  t: any
}
class ModalInviteStaff extends React.Component<ModalProps, {}> {
  render() {
    const { showModal, onCancel, t } = this.props;
    return (

      <ModalContainer
        modalClose={() => onCancel()}
        active={showModal}
      >
        <ModalInviteStaffContainer>
          <div className="modal-invite-staff-activity">
            <p>{t('modalMessages:modal-invite-staff.title')}</p>
          </div>
          <div className="modal-invite-staff-title">
            <p>{t('modalMessages:modal-invite-staff.invite-staff')}</p>
          </div>
          <div className="modal-invite-staff-details-container">
            <div className="modal-invite-staff-details-title">
              <p>{t('modalMessages:modal-invite-staff.title')}</p>
            </div>
            <div className="modal-invite-staff-details-date">
              <p>14 de junio, 14:30</p>
            </div>
          </div>
          <div className="modal-invite-staff-subtitle">
            <p>{t('components:actions.send-invitation-email')}</p>
          </div>
          <div className="modal-invite-staff-info-container">
            <div className="modal-invite-staff-input">
              <InputBox
                value=""
                labelText=""
                className="rounded"
                placeholder={t('components:actions.choose-period')}
              />
            </div>
            <div className="modal-invite-staff-add-button">
              <MainButton
                text={t('components:actions.add')}
                type="secondary"
                iconImg={images.plusSvg}
              />
            </div>
          </div>
          <div className="modal-invite-staff-subtitle">
            <p>{t('components:actions.add-staff')}</p>
          </div>
          <div className="modal-invite-staff-info-container">
            <div className="modal-invite-staff-input">
              <InputBox
                value=""
                labelText=""
                className="rounded"
                placeholder={t('components:actions.choose')}
              />
            </div>
            <div className="modal-invite-staff-add-button">
              <MainButton
                text={t('components:actions.add')}
                type="secondary"
                iconImg={images.plusSvg}
              />
            </div>
          </div>
          <div className="modal-invite-staff-checkbox">
            <DicotomicCheckbox onCheck={() => { }}>
              <InfoText>
                {t('modalMessages:modal-invite-staff.staff-added-note')}
            </InfoText>
            </DicotomicCheckbox>
          </div>
          <div className="modal-invite-staff-send-button">
            <MainButton
              text={t('components:actions.send')}
              type="primary"
            />
          </div>
        </ModalInviteStaffContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalInviteStaff));
