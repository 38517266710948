import * as React from 'react';
import ModalContainer from '../modals/modal-container';
import { PopCalendarStyle } from './pop-calendar-style';
import Label02Text from '../text/label-02-text/label-02';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';
import LabelText from '../text/label-text/label';
import { images } from '../../assets/images';
import InfoText from '../text/info-text/info-text';
import MainButton from '../main-button';
import moment from 'moment';
import { capitalize } from '../../utils/texts';
import { ITimeBlocks, ITimeBlock } from '../../models/generic';
import { getTimeFormatted } from '../../utils/time';
import { getOneActivities, patchActivity } from '../../services/activities';
import { mergeObject } from '../../utils/other';
import { withRouter, RouteComponentProps } from 'react-router';
import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { getExpirationDate } from '../../utils/activities';
import { transformToUser, getUserUnit } from '../../utils/measurement-units';
import ItemTeam from '../item-team/item-team';
import services from '../../services/services';
import { postCentreCancelActivity } from '../../services/centre';
import ModalActivityCancel from '../../components/modals/modal-activity-cancelation/modal-activity-cancelation';
import { WithTranslation, withTranslation } from 'react-i18next';
moment.locale('es');

export interface IPopCalendarProps extends RouteComponentProps, WithTranslation {
  centreId: string;
  activityId: string;
  onClose: Function;
  showModal: boolean;
  onConfirm: () => void;
  onFinish: () => void;
  staffPermissions?: any;
  t:any;
}

export interface IPopCalendarState {
  activity?: any;
  renderModal: string;
}

class PopCalendar extends React.Component<IPopCalendarProps, IPopCalendarState> {
  constructor(props: IPopCalendarProps) {
    super(props);
    this.state = {
      renderModal: ''
    };
  }

  componentDidMount() {
    this.getActivity();
  }

  async getActivity() {
    const { activityId } = this.props;
    const params = {
      filter: {
        relations: ['sites', 'staff_activities', 'staff_activities.staff', 'staff.user'],
      },
    };
    const params2 = {
      filter: {
        relations: ['bookings', 'bookings.divers', 'divers.user'],
      },
    };
    const resp = await getOneActivities(activityId, params);
    const resp2 = await getOneActivities(activityId, params2);
    const activity = mergeObject(resp.data, resp2.data);
    this.setState({ activity });
  }
  noMoreReservations = async () => {
    if (this.state.activity && this.state.activity.id) {
      const res = await patchActivity(this.state.activity.id.toString(), { completed: true });
      this.state.activity.completed = true;
      this.setState({ ...this.state });
      this.props.onClose();
    }
  }
  moreReservations = async () => {
    if (this.state.activity && this.state.activity.id) {
      const res = await patchActivity(this.state.activity.id.toString(), { completed: false });
      this.state.activity.completed = false;
      this.setState({ ...this.state });
      this.props.onClose();
    }
  }

  cancelActivity = async () => {
    const { t } = this.props;
    await postCentreCancelActivity(this.props.centreId, this.state.activity.id);
    services.pushNotification({
      title: t('components:pop-calendar.canceled'),
      text: t('components:pop-calendar.cancelsuccess'),
      type: 'red',
    });
    this.props.onClose();
  }

  render() {

    const { onClose, showModal, centreId, activityId, onConfirm, onFinish, staffPermissions, t } = this.props;
    const { activity, renderModal } = this.state;
    if (!activity) return '';
    const now = moment();
    const activityExpDate = getExpirationDate(activity);
    const isCourse = activity.courseInfo !== null;
    const date = capitalize(moment(activity.date.substring(0, 10)).format('dddd, DD [de] MMMM'));
    const confirmedBookings = activity.bookings ? activity.bookings.filter((booking: any) => booking.confirmed) : [];
    const unconfirmedBookings = activity.bookings ? (activity.minDivers || 0) - activity.bookings.filter((booking: any) =>
      !booking.confirmed).length : (activity.minDivers || 0);
    const statusLabel = activity.cancelled
      ? t('components:pop-calendar.canceled')
      : activity.finished
        ? t('components:pop-calendar.ended')
        : moment(activityExpDate).isBefore(now) && activity.confirmed
          ? t('components:pop-calendar.done')
          : moment(activityExpDate).isBefore(now) && !activity.confirmed
            ? t('components:pop-calendar.notdone')
            : activity.confirmed
              ? t('components:pop-calendar.confirmed')
              : t('components:pop-calendar.pendconf');
    const statusClass = moment(activityExpDate).isBefore(now) ? 'closed' : activity.confirmed ? 'resolved' : 'pendant';
    let startHour = 24;
    let startMinutes = 0;
    let endHour = 24;
    let endMinutes = 0;

    activity.timeBlocks.forEach((timeBlock: ITimeBlocks) => {
      if (timeBlock.start.hour < startHour) {
        startHour = timeBlock.start.hour;
        startMinutes = timeBlock.start.minutes;
      }
      if (timeBlock.end.hour < endHour) {
        endHour = timeBlock.end.hour;
        endMinutes = timeBlock.end.minutes;
      }
    });
    let dates = activity.timeBlocks.filter((t: any) => !!t.start.date);

    //let dates: any;
    //let dates: any | [];
    if (activity.timeBlocks && activity.timeBlocks.length > 1) {
      dates = activity.timeBlocks.sort((a: any, b: any) => {
        const dateA = new Date(a.start.date);
        dateA.setHours(a.start.hour);
        dateA.setMinutes(a.start.minutes);
        const dateB = new Date(b.start.date);
        dateB.setHours(b.start.hour);
        dateB.setMinutes(b.start.minutes);
        return b - a;
      });
    } else {
      dates = activity.timeBlocks;
    }
    let totalDivers = 0;
    if (activity.bookings && activity.bookings.length > 0) {
      //totalDivers = 1;
      for (let i = 0; i < activity.bookings.length; i++) {
        if (activity.bookings[i].rejected == false && activity.bookings[i].confirmed == true && activity.bookings[i].cancelled == 'noCancelled') {
          totalDivers = totalDivers + (activity.bookings[i].divers.length + 1)
        }
      }
    }
    const dateFuture = moment(this.state.activity.date, "YYYY-MM-DD HH:mm:ss") < moment(new Date(), "YYYY-MM-DD HH:mm:ss");

    let pending = 0;
    for (let i = 0; i < activity.bookings.length; i++) {
      if (!activity.bookings[i].confirmed && !activity.bookings[i].rejected) {
        pending++;

      }
    }
    const aforo:number = activity.maxDivers - totalDivers
    
    if (!dates[0].start.date) {
      dates[0].start.date = activity.date
    }
    return (
      <ModalContainer className="" modalClose={() => onClose()} active={showModal}>
        <PopCalendarStyle>
          {/*<div className={`pop-calendar-status ${statusClass}`}>
            <Label02Text>{statusLabel}</Label02Text>
          </div>*/}
          <div className="pop-calendar-title">
            <SubheadDestacadoText>{activity.name}</SubheadDestacadoText>
          </div>



          {dates.length > 0 ? (
            <div className="pop-calendar-date-wrapper">
              {dates ?
                <div className="pop-calendar-date">
                  <div className="pop-calendar-date__day">
                    <p>{t('components:pop-calendar.initact')}:</p>
                  </div>
                  <div className="pop-calendar-date__hour">
                    <p> {capitalize(moment(dates[0].start.date).format('dddd, DD [de] MMMM'))}</p>
                    <p>{`${getTimeFormatted(dates[0].start.hour, (dates[0].start.minutes + dates[0].start.seconds))} - ${getTimeFormatted(
                      dates[0].end.hour, (dates[0].end.minutes + dates[0].end.seconds)
                    )}`}</p>
                  </div>
                </div>
                : (
                  <></>
                )
              }
            </div>
          ) : (
            <div className="pop-calendar-date">
              <div className="pop-calendar-date__day">
                <p>{t('components:pop-calendar.initact')}: </p>
              </div>
              <div className="pop-calendar-date__hour">
                <p>{date}</p>
                <p>{`${getTimeFormatted(startHour, startMinutes)} - ${getTimeFormatted(endHour, endMinutes)}`}</p>
              </div>
            </div>
          )}
          <div className="pop-calendar-info">
            <div className="pop-calendar-info__item">
              <div className="pop-calendar-info__item__icon">
                <img src={isCourse ? images.trainingSvg : images.diveSvg} alt="" />
              </div>
              <div className="pop-calendar-info__item__text">
                <InfoText>{isCourse ? 'Curso/especialidad' : 'Inmersión'}</InfoText>
              </div>
            </div>
            {activity.sites.length > 0 && (
              <>
                <div className="pop-calendar-info__item">
                  <div className="pop-calendar-info__item__icon">
                    <img src={images.diveSite} alt="" />
                  </div>
                  <div className="pop-calendar-info__item__text">
                    <InfoText>
                      {activity.sites[0].name}
                      {activity.sites.length > 1 ? ` +${activity.sites.length - 1}` : ''}
                    </InfoText>
                  </div>
                </div>
                <div className="pop-calendar-info__item">
                  <div className="pop-calendar-info__item__icon">
                    <img src={images.userBookings} alt="" />
                  </div>
                  <div className="pop-calendar-info__item__text">
                    <InfoText>{pending} {t('components:pop-calendar.solres')} {pending > 0 && (`${totalDivers} buceadores`)}</InfoText>
                  </div>
                </div>
              </>
            )}
            {aforo > 0 && (
              <div className="pop-calendar-info__item">
                <div className="pop-calendar-info__item__icon">
                  <img src={images.userPlusSvg} alt="avatarDiver" />
                </div>
                <div className="pop-calendar-info__item__text">
                  <InfoText>{aforo} Plazas disponibles </InfoText>
                </div>
              </div>
            )}
          </div>
          <div className="pop-calendar-section">
            <div className="pop-calendar-section__title">
              <LabelText>{t('components:pop-calendar.staffas')}</LabelText>
            </div>
            {activity.staff_activities.map((staff: any) => (
              <div className="pop-calendar-section__staff" key={staff.id}>
                <div className="pop-calendar-section__staff__avatar">
                  <img src={staff.staff.user.picture ? staff.staff.user.picture : ''} alt="" />
                </div>
                <div className="pop-calendar-section__staff__name">
                  <InfoText className={`${!staff.confirmed ? "pop-calendar-section__miss" : ""}`}>
                    {staff.staff.user.name} {staff.staff.user.surname} {!staff.confirmed ? '(nc)' : ''}
                  </InfoText>
                </div>
              </div>
            ))}
          </div>
          <div className="pop-calendar-section">
            <div className="pop-calendar-section__title">
              <LabelText>{t('components:pop-calendar.staffas')}: {totalDivers && totalDivers}</LabelText>
            </div>
            <div className="pop-calendar-section__assistant">

              {/*confirmedBookings.map((booking: any, index: number) => {
                if (index < 3) {
                  return (
                    <div className="pop-calendar-section__assistant__avatar">
                      <img src={booking.diver ? booking.diver.user.picture : images.avatarDiverDefault} alt="" /> 
                    </div>
                  );
                }
              })}
              {confirmedBookings.length > 3 && (
                <div className="pop-calendar-section__assistant__more">
                  <Label02Text>+{confirmedBookings.length - 3}</Label02Text>
                </div>
              )*/}
            </div>
            {/*unconfirmedBookings > 0 && (
              <div className="pop-calendar-section__miss">
                <p>Faltan {unconfirmedBookings}</p>
              </div>
            )*/}

          </div>
          <div className="pop-calendar-buttons">
            {/*moment(activity.date).isAfter(now) &&
              !activity.finished &&
              !activity.cancelled &&
              !activity.confirmed &&
              !staffPermissions && (
                <div className="pop-calendar-buttons__item">
                  <MainButton text="Confirmar" type="primary" onClick={() => onConfirm()} />
                </div>
              )*/}
            {!activity.finished && moment(activity.date).isBefore(now) && activity.confirmed && !staffPermissions && (
              <div className="pop-calendar-buttons__item">
                <MainButton text={t('components:pop-calendar.toend')} type="primary" onClick={() => onFinish()} />
              </div>
            )}
            <div className="pop-calendar-buttons__item">
              <MainButton
                text={t('components:pop-calendar.vdet')}
                type="primary"
                onClick={() => this.props.history.push(`/centres/${centreId}/planning/calendar/${activityId}`)}
              />
            </div>
            {!this.state.activity.cancelled && !dateFuture &&
              <div className="pop-calendar-buttons__item">
                <MainButton
                  text={activity.completed ? t('components:pop-calendar.sires') : t('components:pop-calendar.nores')}
                  type="secondary"
                  className={activity.completed ? "secondary-red" : "secondary-active"}
                  onClick={activity.completed ? this.moreReservations : this.noMoreReservations}
                />
              </div>
            }
            {!this.state.activity.cancelled && !dateFuture &&
              <div className="pop-calendar-buttons__item">
                <MainButton
                  text={t('components:pop-calendar.tocancel')}
                  type="secondary-border-red"
                  onClick={() => this.setState({ renderModal: 'modal-cancel-activity' })}
                />
              </div>
            }
            {renderModal === 'modal-cancel-activity' && (
              <ModalActivityCancel
                showModal={renderModal === 'modal-cancel-activity'}
                centreId={this.props && this.props.centreId}
                activityId={activityId}
                onSuccess={() => {
                  services.pushNotification({
                    title: t('components:pop-calendar.canceled'),
                    text: t('components:pop-calendar.cancelsuccess'),
                    type: 'red',
                  });
                  this.setState({ renderModal: '' }, () => this.getActivity());
                }}
                onCancel={() => this.setState({ renderModal: '' })}
              />
            )}
          </div>
        </PopCalendarStyle>
      </ModalContainer>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default withTranslation(['components']) (connect(mapStateToProps)(withRouter(PopCalendar)));
