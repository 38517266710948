import * as React from 'react';
import { DiveSiteDiveStyle } from './dive-site-dive-style';
import { MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { images } from '../../../assets/images';
import { getDives, deleteDives } from '../../../services/dives';
import { DIVES_PER_PAGE } from '../../../constants/data';
import { RouteComponentProps, withRouter } from 'react-router';
import services from '../../../services/services';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { getDayMonthNumberYear } from '../../../utils/time';
import { DiveTypes } from '../../../constants/dive';
import { transformToUser, getUserUnit } from '../../../utils/measurement-units';

interface RouteParams {
  id: string;
  siteId: string;
}
export interface IDiveSiteDiveProps extends RouteComponentProps<RouteParams> {}

export interface IDiveSiteDiveState {
  modal: string;
  dives: any[];
  total: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  editId: string;
  siteId: string;
  page: number;
}

class DiveSiteDive extends React.Component<IDiveSiteDiveProps, IDiveSiteDiveState> {
  state: IDiveSiteDiveState;
  tableColumns: any[];
  rowKeys: string[];

  constructor(props: IDiveSiteDiveProps) {
    super(props);

    this.state = {
      modal: '',
      dives: [],
      total: 0,
      totalPages: 0,
      searchFilter: '',
      skipPage: 0,
      orderAscendent: '',
      editId: '',
      siteId: '',
      page: 1,
    };

    this.tableColumns = [
      {
        text: 'Fecha',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Tipo de inmersión',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Buceador',
        filter: false,
        ascendent: true,
      },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
      // {
      //   text: 'Valoración',
      //   filter: false,
      //   ascendent: false,
      // },
      {
        text: 'Profundidad',
        filter: false,
        ascendent: true,
      },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
    ];

    // this.rowKeys = ['date', 'type', 'diver', 'rate', 'deep', 'actions'];
    this.rowKeys = ['date', 'type', 'diver', 'diverCount', 'deep', 'actions'];
  }

  componentDidMount() {
    const { match } = this.props;
    const siteId = match.params.siteId ? match.params.siteId : match.params.id;
    if (siteId) {
      this.setState({ siteId }, () => {
        this.getDives(siteId);
      });
    }
  }

  getDives = async (idDiveSite: string) => {
    const { skipPage, orderAscendent } = this.state;
    try {
      const params = {
        filter: {
          where: {
            id: { value: idDiveSite, collection: 'site' },
          },
          skip: skipPage,
          limit: DIVES_PER_PAGE,
          orderBy: [{ key: 'date', direction: orderAscendent ? 'ASC' : 'DESC' }],
          relations: ['site', 'divers', 'divers.user'],
        },
      };

      const resp: any = await getDives(params);
      const totalPages: number = Math.ceil(resp.data.count / DIVES_PER_PAGE);
      this.setState({ totalPages, dives: resp.data.data, total: resp.data.count });
    } catch (err) {
      this.setState({ dives: [] });
    }
  };

  deleteDives = async (id: string) => {
    try {
      await deleteDives(id);
      services.pushNotification({
        title: 'Inmersión eliminada',
        text: 'Se ha eliminado una inmersión con éxito.',
        type: 'red',
      });
      this.setState({ modal: '', editId: '' });
    } catch (err) {}
  };

  formatDataToTableRows(data: any[]) {
    return data.map((item: any) => {
      return {
        date: {
          key: 'highlighted-body',
          value: getDayMonthNumberYear(item.date),
        },
        type: {
          key: '',
          value: DiveTypes[item.type],
        },
        diver: {
          key: '',
          value: item.divers.length > 0 ? `${item.divers[0].user.name} ${item.divers[0].user.surname}` : '-',
        },
        diverCount: {
          key: 'count',
          value: {
            title: 'Buceadores',
            items: item.divers.slice(1).map((d: any) => `${d.user.name} ${d.user.surname}`),
          },
        },
        deep: {
          key: '',
          value: `${transformToUser('depth', item.depth)} ${getUserUnit('depth')}`,
        },
        actions: {
          key: 'options',
          actions: [
            {
              label: 'Ver',
              icon: images.eyeSvg,
              onClick: () => this.props.history.push(`/dives/${item.id}`),
            },
            {
              label: 'Eliminar',
              icon: images.deleteSvg,
              onClick: () => this.setState({ modal: 'modal-delete', editId: item.id }),
            },
          ],
        },
      };
    });
  }

  setPage(page: number) {
    const { total, siteId } = this.state;
    const skip: number = DIVES_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getDives(siteId);
      });
    }
  }

  render() {
    const { modal, totalPages, dives, editId, page } = this.state;
    return (
      <DiveSiteDiveStyle>
        <MainLayoutTableContainer>
          <div className="diving-center-table">
            <MainTableLayout
              columns={this.tableColumns}
              rows={this.formatDataToTableRows(dives)}
              rowKeys={this.rowKeys}
              onOrder={() => {}}
            />
          </div>
          {totalPages > 1 && (
            <div className="diving-center-pagination">
              <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
            </div>
          )}
        </MainLayoutTableContainer>
        {/* Modal Delete centre  */}
        {modal === 'modal-delete' && (
          <ModalDelete
            title="Vas a eliminar una inmersión"
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete'}
            onRemove={() => {
              if (!!editId) {
                this.deleteDives(editId);
              }
            }}
            onCancel={() => this.setState({ modal: '' })}
          />
        )}
      </DiveSiteDiveStyle>
    );
  }
}

export default withRouter(DiveSiteDive);
