import * as React from 'react';
import { connect } from 'react-redux';
import { MainLayoutSection } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import { MeasurementUnitsStyle } from './measurement-units-style';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import { images } from '../../../assets/images';
import DicotomicCheckbox from '../../../components/dicotomic-checkbox';
import TextText from '../../../components/text/text-text/text-text';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { getOneUsers, uploadUsers } from '../../../services/users';
import { IUnitTypes } from '../../../models/measurament-units-types';
import services from '../../../services/services';
import IStoreState from '../../../redux/store/IStoreState';
import { setAgent } from '../../../redux/actions';

export interface IMeasurementUnitsProps extends WithTranslation {
  agent?: any;
}

export interface IMeasurementUnitsState {
  units: IUnitTypes;
  renderModal: string;
}

class MeasurementUnits extends React.Component<IMeasurementUnitsProps, IMeasurementUnitsState> {
  constructor(props: IMeasurementUnitsProps) {
    super(props);

    this.state = {
      renderModal: '',
      units: {
        depth: '',
        distance: '',
        temperature: '',
        weight: '',
        pressure: '',
        drift: '',
        wind: '',
        coordinates: '',
      },
    };
  }

  componentDidMount() {
    if (localStorage.userId) {
      this.getUserMeasurementUnits();
    }
  }

  async getUserMeasurementUnits() {
    const units = (await getOneUsers(localStorage.userId)).data.units;
    this.setState({ units });
  }

  async onSave() {
    const { units } = this.state;
    const { agent } = this.props;
    let user = agent;
    user.units = units;
    await uploadUsers(localStorage.userId, user);
    localStorage.setItem('units', JSON.stringify(units));
    services.setValue(setAgent({ ...agent, units }));
    services.pushNotification({
      title: '¡Guardado!',
      text: 'La unidades de medida se han guardado correctamente',
      type: 'green',
    });
  }

  handleCheck(field: string, value: string) {
    const { units } = Object.assign(this.state);
    units[field] = value;
    this.setState({ units });
  }

  render() {
    const { t } = this.props;
    const {
      units: { depth, distance, temperature, weight, pressure, drift, wind, coordinates },
    } = this.state;
    return (
      <>
        <HeaderMain
          title={t('layouts:config.units.header')}
          mainButton={{
            img: images.checkCircleWhiteSvg,
            text: t('buttons:save-changes'),
            onClick: () => this.onSave(),
          }}
        />
        <MainLayoutSection>
          <MeasurementUnitsStyle>
            {/* <div className="measurement-units-text">
              <Trans i18nKey={'layouts:config.taxes.text'}>
                <SubtitleText>{'-'}</SubtitleText>
                <SubtitleText>{'-'}</SubtitleText>
              </Trans>
            </div> */}
            <div className="measurement-units-table">
              <table>
                <thead>
                  <tr>
                    <th>
                      <BodyDestacadoText>{t('layouts:config.units.magnitude')}</BodyDestacadoText>
                    </th>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.units.unit')}</BodyDestacadoText>
                    </td>
                    <td />
                    <td />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.units.distance')}</BodyDestacadoText>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={distance === 'km'}
                        className="rounded"
                        onCheck={() => this.handleCheck('distance', 'km')}
                      >
                        <TextText>{t('layouts:config.units.kilometres')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={distance === 'mile'}
                        className="rounded"
                        onCheck={() => this.handleCheck('distance', 'mile')}
                      >
                        <TextText>{t('layouts:config.units.miles')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.units.depth')}</BodyDestacadoText>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={depth === 'meters'}
                        className="rounded"
                        onCheck={() => this.handleCheck('depth', 'meters')}
                      >
                        <TextText>{t('layouts:config.units.meters')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={depth === 'feet'}
                        className="rounded"
                        onCheck={() => this.handleCheck('depth', 'feet')}
                      >
                        <TextText>{t('layouts:config.units.feets')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.units.temperature')}</BodyDestacadoText>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={temperature === 'celsius'}
                        className="rounded"
                        onCheck={() => this.handleCheck('temperature', 'celsius')}
                      >
                        <TextText>{t('layouts:config.units.celsius')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={temperature === 'farenheit'}
                        className="rounded"
                        onCheck={() => this.handleCheck('temperature', 'farenheit')}
                      >
                        <TextText>{t('layouts:config.units.fahrenheit')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.units.weight')}</BodyDestacadoText>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={weight === 'kg'}
                        className="rounded"
                        onCheck={() => this.handleCheck('weight', 'kg')}
                      >
                        <TextText>{t('layouts:config.units.kilograms')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={weight === 'pound'}
                        className="rounded"
                        onCheck={() => this.handleCheck('weight', 'pound')}
                      >
                        <TextText>{t('layouts:config.units.pounds')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.units.pressure')}</BodyDestacadoText>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={pressure === 'bar'}
                        className="rounded"
                        onCheck={() => this.handleCheck('pressure', 'bar')}
                      >
                        <TextText>{t('layouts:config.units.bar')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={pressure === 'psi'}
                        className="rounded"
                        onCheck={() => this.handleCheck('pressure', 'psi')}
                      >
                        <TextText>{t('layouts:config.units.psi')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.units.flowing')}</BodyDestacadoText>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={drift === 'knot'}
                        className="rounded"
                        onCheck={() => this.handleCheck('drift', 'knot')}
                      >
                        <TextText>{t('layouts:config.units.knot-sec')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={drift === 'km/h'}
                        className="rounded"
                        onCheck={() => this.handleCheck('drift', 'km/h')}
                      >
                        <TextText>{t('layouts:config.units.kilometres-hour')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={drift === 'm/s'}
                        className="rounded"
                        onCheck={() => this.handleCheck('drift', 'm/s')}
                      >
                        <TextText>{t('layouts:config.units.meters-sec')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.units.wind')}</BodyDestacadoText>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={wind === 'knot'}
                        className="rounded"
                        onCheck={() => this.handleCheck('wind', 'knot')}
                      >
                        <TextText>{t('layouts:config.units.knot-sec')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={wind === 'km/h'}
                        className="rounded"
                        onCheck={() => this.handleCheck('wind', 'km/h')}
                      >
                        <TextText>{t('layouts:config.units.kilometres-hour')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={wind === 'm/s'}
                        className="rounded"
                        onCheck={() => this.handleCheck('wind', 'm/s')}
                      >
                        <TextText>{t('layouts:config.units.meters-sec')}</TextText>
                      </DicotomicCheckbox>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.units.coordinates')}</BodyDestacadoText>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={coordinates === 'degree'}
                        className="rounded"
                        onCheck={() => this.handleCheck('coordinates', 'degree')}
                      >
                        <TextText>hddd/dddddº</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={coordinates === 'second'}
                        className="rounded"
                        onCheck={() => this.handleCheck('coordinates', 'second')}
                      >
                        <TextText>hdddº/mm’/ss,s”</TextText>
                      </DicotomicCheckbox>
                    </td>
                    <td>
                      <DicotomicCheckbox
                        checked={coordinates === 'minute'}
                        className="rounded"
                        onCheck={() => this.handleCheck('coordinates', 'minute')}
                      >
                        <TextText>hddd/mm,mmmm</TextText>
                      </DicotomicCheckbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </MeasurementUnitsStyle>
        </MainLayoutSection>
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default withTranslation(['intro', 'buttons'])(
  connect<{}, {}, {}, IStoreState>(mapStateToProps)(MeasurementUnits)
);
