import * as React from 'react';
import { DiveSiteDiveStyle } from './marine-reserve-dive-style';
import { MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { images } from '../../../assets/images';
import { getDives, deleteDives } from '../../../services/dives';
import { DIVES_PER_PAGE } from '../../../constants/data';
import { RouteComponentProps, withRouter } from 'react-router';
import services from '../../../services/services';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { getDayMonthNumberYear } from '../../../utils/time';
import { getOneMarineReserve } from '../../../services/marine-reserve';
import { COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import { deleteDiveSites } from '../../../services/dive-sites';
import ModalDiveSite from '../../../components/modals/modal-dive-site/modal-dive-site';

interface RouteParams {
  id: string;
}
export interface IDiveSiteDiveProps extends RouteComponentProps<RouteParams> {}

export interface IDiveSiteDiveState {
  modal: string;
  diveSites: any[];
  rows: any[];
  cards: any[];
  marineReserve: any;
  diveSiteId: string;
  textDelete: string;
}

class DiveSiteDive extends React.Component<IDiveSiteDiveProps, IDiveSiteDiveState> {
  state: IDiveSiteDiveState;

  constructor(props: IDiveSiteDiveProps) {
    super(props);

    this.state = {
      modal: '',
      diveSites: [],
      marineReserve: {},
      diveSiteId: '',
      rows: [],
      cards: [],
      textDelete: '',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.getMarineReserve(match.params.id);
    }
    window.addEventListener('reload-marine-reserve', this.reloadMarineReserve);
  }

  componentWillUnmount() {
    window.removeEventListener('reload-marine-reserve', this.reloadMarineReserve);
  }

  reloadMarineReserve = () => {
    const { match } = this.props;
    this.getMarineReserve(match.params.id);
  };

  getColumns = () => {
    return [
      {
        text: 'Nombre',
        filter: false,
        ascendent: true,
      },
      {
        text: 'País',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Provincia',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Valoración',
        filter: false,
        ascendent: false,
      },
      {
        text: 'Creación',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Creado por',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Validado',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Validado por',
        filter: false,
        ascendent: true,
      },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
    ];
  };

  getKeysColumns = () => {
    return ['name', 'country', 'province', 'rating', 'createdAt', 'createdBy', 'validated', 'validatedBy', 'actions'];
  };

  getMarineReserve = async (id: string) => {
    try {
      const params = {
        filter: {
          relations: ['sites', 'sites.reserve'],
        },
      };

      const response: any = await getOneMarineReserve(id, params);
      const diveSites: any[] = response.data.sites.map((site: any) => site);
      this.setState({ diveSites, marineReserve: response.data }, () => this.getRows());
    } catch (err) {}
  };

  getRows = () => {
    const { diveSites } = this.state;
    const rows = diveSites.map((site: any) => {
      const row: any = {
        id: {
          value: site.id,
        },
        name: {
          key: 'highlighted-body',
          value: site.name,
        },
        country: {
          key: '',
          value: COUNTRIES_KEY_LABEL[site.country],
        },
        province: {
          key: '',
          value: site.state,
        },
        rating: {
          key: 'rate',
          value: site.rating,
        },
        createdAt: {
          key: '',
          value: getDayMonthNumberYear(site.createdAt),
        },
        createdBy: {
          key: '',
          value: site.createdBy ? `${site.createdBy.name} ${site.createdBy.surname}` : '',
        },
        validated: {
          key: '',
          value: site.validated ? site.validated.createdAt : '-',
        },
        validatedBy: {
          key: '',
          value: site.validated && site.validated.user ? site.validated.user.email : '-',
        },
        actions: {
          key: 'options',
          value: '',
          actions: [
            {
              label: 'Ver',
              icon: images.eyeSvg,
              onClick: () => this.props.history.push(`/dive-sites/dive-site/detail/${site.id}`),
            },
            {
              label: 'Editar',
              icon: images.edit3Svg,
              onClick: () => this.setState({ diveSiteId: site.id, modal: 'create-site' }),
            },
            {
              label: 'Eliminar',
              icon: images.delete2Svg,
              onClick: () =>
                this.setState({
                  diveSiteId: site.id,
                  modal: 'modal-delete',
                  textDelete: 'Vas a eliminar un punto de inmersión',
                }),
            },
          ],
        },
      };
      return row;
    });
    this.setState({ rows });
  };

  getSpot = (type?: string) => {
    switch (type) {
      case 'site':
        return 'dive-spot';
      case 'marine-reserve':
        return 'marine-reserve';
      default:
        return 'invalid-spot';
    }
  };

  deleteDiveSite = async (id: string) => {
    const { match } = this.props;
    try {
      await deleteDiveSites(id);
      services.pushNotification({
        title: 'Punto de inmersión eliminado',
        text: 'Se ha eliminado el punto de inmersión con éxito.',
        type: 'red',
      });
      this.setState({ modal: '', diveSiteId: '' }, () => this.getMarineReserve(match.params.id));
    } catch (err) {}
  };

  render() {
    const { modal, rows, textDelete, diveSiteId, diveSites, cards } = this.state;
    const { match } = this.props;
    return (
      <DiveSiteDiveStyle>
        <MainLayoutTableContainer>
          <div className="diving-center-table">
            <MainTableLayout
              columns={this.getColumns()}
              rows={rows}
              rowKeys={this.getKeysColumns()}
              onOrder={() => {}}
            />
          </div>
        </MainLayoutTableContainer>
        {/* Modal Delete centre  */}
        {modal === 'modal-delete' && (
          <ModalDelete
            title={textDelete}
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete'}
            onRemove={() => {
              if (!!diveSiteId) {
                this.deleteDiveSite(diveSiteId);
              }
            }}
            onCancel={() => this.setState({ modal: '' })}
          />
        )}
        {modal === 'create-site' && (
          <ModalDiveSite
            showModal={modal === 'create-site'}
            diveSiteId={diveSiteId}
            divesSites={diveSites}
            cards={cards}
            onClose={(load?: boolean) =>
              this.setState({ modal: '', diveSiteId: '' }, () => {
                if (load) this.getMarineReserve(match.params.id);
              })
            }
          />
        )}
      </DiveSiteDiveStyle>
    );
  }
}

export default withRouter(DiveSiteDive);
