import React, { Component } from 'react';
import { ModalCreateCenterContainer } from './modal-create-center-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../main-layout-style';
import InputBox from '../../../components/input-box';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import MainButton from '../../../components/main-button';
import { images } from '../../../assets/images';
import SelectBox from '../../../components/select-box';
import PhoneBox from '../../../components/phone-box/phone-box';
import ModalContainer from '../../../components/modals/modal-container';
import { COUNTRIES, COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import { Certifier } from '../../../types/company';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import { getOrganizations } from '../../../services/collaborator';
import { IOrganization } from '../../../models/organization';
import { ICentreForm } from '../../../models/centre-forms';
import { getCentre, editCentre } from '../../../services/centre';
import { getFieldValue, copyOf } from '../../../utils/other';
import {
  isValidEmail,
  isValidPhone,
  isValidURL,
  isValidPostalCode,
  isValidIBAN,
  isUnderAge,
} from '../../../utils/validation';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { CENTRE_TYPES,CENTRE_FEE_TYPES } from '../../../constants/centres';
import ListItemAdded from '../../../components/list-item-added/list-item-added';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import services from '../../../services/services';
import { getDataFromAddress } from '../../../services/google-maps';
import { getAdministrativeLevel1, getTown, getCountry, getPostalCode } from '../../../utils/maps';
import { IDropdown } from '../../../models/generic';
import { createUsers } from '../../../services/users';
import classNames from 'classnames';
import classnames from 'classnames';

interface ModalCreateCentreProps extends WithTranslation {
  showModal: boolean;
  onAccept: Function;
  onCancel: Function;
  className?: string;
  agent?: any;
  editMode?: boolean;
  centreID?: string;
}

interface ModalCreateCentreState {
  centre: ICentreForm;
  selectedCertifier: string;
  selectedCertifierId: string;
  selectedCertifierName: string;
  clearCertifier: boolean;
  tempCertifier?: Certifier;
  accessEmail?: string;
  places: IDropdown[];
  step: number;
  errors: { [key: string]: string };
  allCertifiers: { value: string; label: string }[];
}

class ModalCreateCentre extends Component<ModalCreateCentreProps, ModalCreateCentreState> {
  constructor(props: ModalCreateCentreProps) {
    super(props);
    this.state = {
      clearCertifier: false,
      step: 3,
      errors: {},
      allCertifiers: [{ value: '', label: '' }],
      selectedCertifier: '',
      selectedCertifierId: '',
      selectedCertifierName: '',
      places: [],
      centre: {
        name: '',
        type: 'centre', // TODO: por defecto centre hasta que vuelva los clubs DIV-2994
        certifiers: [],
        country: '',
        town: '',
        lat: '',
        lng: '',
        address: '',
        state: '',
        postalCode: '',
        feeType: '',
        // bankAccount: {
        //   owner: {
        //     name: '',
        //     surname: '',
        //     town: '',
        //     country: '',
        //     postalCode: '',
        //     address: '',
        //     birthday: '',
        //   },
        //   country: '',
        //   currency: '',
        //   iban: '',
        // },
        personResponsible: {
          name: '',
          email: '',
          phone: {
            prefix: '+34',
            number: '',
          },
        },
        email: '',
        phone: {
          prefix: '+34',
          number: '',
        },
        website: '',
      },
    };
  }

  componentDidMount() {
    const { agent, editMode } = this.props;
    const { centre } = this.state;

    if (!!Object.keys(agent).length && !!agent.id) {
      if (!agent.roles.includes('superAdmin')) centre.user = agent.id;
      this.setState({ centre }, () => {
        this.getCertifiers();
      });
    }
    if (editMode) {
      this.getCentre();
    }
  }

  async getCertifiers() {
    try {
      const organizations: IOrganization = await getOrganizations();
      const allCertifiers = organizations.data.data
        .filter((item: any) => item.type === 'certifier')
        .map((item: any) => ({
          value: item.id.toString(),
          label: item.name,
        }));
      /*       allCertifiers.push({
              value: '-1',
              label: 'Otra',
            }); */
      this.setState({ allCertifiers });
    } catch (err) {
      console.log(err);
    }
  }

  async getCentre() {
    const { centreID } = this.props;
    if (centreID) {
      try {
        const { data }: ICentreForm = await getCentre(centreID);
        if (data.certifiers) {
          data.certifiers = data.certifiers.map((certifier: any) => ({
            ownId: certifier.ownId,
            certifierName: certifier.certifierName,
            certifier: certifier.certifier ? certifier.certifier.id : '',
          }));
        }

        this.setState({ centre: data });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async createCentre(centre: ICentreForm) {
    const { onAccept, t } = this.props;
    // centre.certifiers = centre.certifiers.map(cert => {
    //   if (cert.certifier !== -1) return cert;
    //   return {
    //     ownId: cert.ownId,
    //     certifierName: cert.certifierName,
    //   };
    // });
    /*
    try {
      if (centre.website === '') centre.website = undefined;
      if (centre.personResponsible && centre.personResponsible.name === '') centre.personResponsible = undefined;
      if (accessEmail) {
        const data = {
          centre,
          email: accessEmail,
        };
        if (centre.certifiers && centre.certifiers.length > 0) {
          centre.certifiers = centre.certifiers.map((cert: any) => {
            if (cert.certifier === '') {
              cert.certifier = -1;
            }
            return cert;
          });
        }
        await createUsers(data);
      } else await createCentre(centre);
      services.showModalSuccess(
        '¡Genial!',
        'Se ha creado un nuevo centro de buceo pendiente de validación por parte de DiveAsapp.'
      );
      onAccept();
    } catch (err) {
      console.log(err);
    }
    */
    try {
      if (centre.certifiers && centre.certifiers.length > 0) {
        centre.certifiers = centre.certifiers.map((cert: any) => {
          if (cert.certifier === '') {
            cert.certifier = -1;
          }
          return cert;
        });
      }

      const userData = {
        name: centre.name,
        email: centre.email,
        roles: [],
        centre: {
          name: centre.name,
          type: centre.type,
          email: centre.email ? centre.email : undefined,
          certifiers: centre.certifiers ? centre.certifiers : '',
          address: centre.address,
          postalCode: centre.postalCode,
          town: centre.town,
          lat: centre.lat,
          lng: centre.lng,
          state: centre.state,
          country: centre.country,
          phone: centre.phone,
          website: centre.website ? centre.website : undefined,
          feeType: centre.feeType,
        },
      };
      await createUsers(userData);

      services.showModalSuccess(t('modalMessages:success-title'), t('modalMessages:pending-centre'));
      onAccept();
    } catch (err) {
      console.log(err);
    }
  }

  async editCentre(centre: ICentreForm) {
    const { onAccept, centreID, t } = this.props;
    if (
      centre.bankAccount &&
      centre.bankAccount.owner.birthday !== '' &&
      centre.bankAccount.owner.birthday.indexOf('/') > -1
    ) {
      centre.bankAccount.owner.birthday = moment(centre.bankAccount.owner.birthday, 'DD/MM/YYYY').toISOString();
    }
    if (centreID) {
      // centre.certifiers = centre.certifiers.map((cert: any) => {
      //   if (cert.certifier !== -1) return cert;
      //   return {
      //     ownId: cert.ownId,
      //     certifierName: cert.certifierName,
      //   };
      // });

      try {
        if (centre.website === '') centre.website = null;
        if (centre.email === '') centre.email = null;
        if (centre.phone && centre.phone.number === '') centre.phone = null;
        if (centre.personResponsible && centre.personResponsible.name === '') centre.personResponsible = null;
        if (centre.certifiers && centre.certifiers.length > 0) {
          centre.certifiers = centre.certifiers.map((cert: any) => {
            if (cert.certifier === '') {
              cert.certifier = -1;
            }
            return cert;
          });
        }
        await editCentre(centreID, centre);
        services.showModalSuccess(t('modalMessages:edited'), t('modalMessages:editsuccess'));
        onAccept();
      } catch (err) {
        console.log(err);
      }
    }
  }
  validateFirstStep(): boolean {
    const { editMode } = this.props;

    let firstStepRequiredFields = [];

    if (editMode) {
      firstStepRequiredFields = [
        'centre.name',
        'centre.address',
        'centre.town',
        'centre.country',
        'centre.type',
        'centre.postalCode',
        'centre.state',
      ];
    } else {
      firstStepRequiredFields = [
        'centre.email',
        'centre.name',
        'centre.address',
        'centre.town',
        'centre.country',
        'centre.phone.prefix',
        'centre.phone.number',
        'centre.type',
        'centre.postalCode',
        //'centre.certifiers',
        'centre.state',
        // 'accessEmail',
      ];
    }
    return this.validate(firstStepRequiredFields);
  }

  validateSecondStep(): boolean {
    return false;
    /*const secondStepRequiredFields = [
      'centre.bankAccount.owner.name',
      'centre.bankAccount.owner.surname',
      'centre.bankAccount.owner.country',
      'centre.bankAccount.owner.postalCode',
      'centre.bankAccount.owner.address',
      'centre.bankAccount.owner.birthday',
      'centre.bankAccount.owner.town',
      'centre.bankAccount.currency',
      'centre.bankAccount.iban',
    ];
    
    return this.validate(secondStepRequiredFields);*/
  }

  validateThirdStep(): boolean {
    return false;
    /*const thirdStepRequiredFields = ['centre.personResponsible.name'];
    return this.validate(thirdStepRequiredFields);*/
  }

  validate(fields: string[]): boolean {
    let error = false;
    fields.forEach(field => {
      error = !this.validateField(field, getFieldValue(this.state, field), true) || error;
    });
    return error;
  }

  validateField(field: string, value: any, required: boolean) {
    const { errors } = Object.assign(this.state);
    const fieldAttribute = field.split('.').slice(-1)[0];
    let error = '';
    if (required && (value === '' || !value || value.length === 0)) {
      //error = field === 'centre.certifiers' ? 'add-certifier' : 'required';
      error = 'required';
    } else {
      switch (fieldAttribute) {
        /*         case 'accessEmail': */
        case 'email':
          error = isValidEmail(value) ? '' : 'invalid-email';
          break;
        case 'number':
          error = isValidPhone(value) ? '' : 'invalid-phone';
          break;
        case 'website':
          error = isValidURL(value) ? '' : 'invalid-format';
          break;
        case 'postalCode':
          error = isValidPostalCode(value) ? '' : 'invalid-postal-code';
          break;
        case 'iban':
          error = isValidIBAN(value) ? '' : 'invalid-iban';
          break;
        case 'birthday':
          error = !isUnderAge(18, moment(value).format('DD/MM/YYYY')) ? '' : 'under-18';
          break;
        default:
          break;
      }
    }
    if (!required && (value === '' || !value || value.length === 0)) error = '';
    errors[field] = error;
    this.setState({ errors });
    return errors[field] === '';
  }

  handleChange(
    field: string,
    value: string | { certifier?: number; id?: string; certifierName?: string }[],
    required: boolean = false,
    excludeField?: string
  ) {
    let newState = _.update(this.state, field, () => value);
    if (excludeField) {
      const { [excludeField]: excludedField, ...filteredState } = newState;
      newState = filteredState;
    }

    this.validateField(field, value, required);
    return new Promise(resolve => {
      this.setState(newState, () => resolve(''));
    });
  }

  handleFeeChange(value: string) {
    this.state.centre.feeType = value;
  }

  handleCertifierRemove(id: string = '', certifier: number = -1, certifierName?: string) {
    const {
      centre: { certifiers },
    } = this.state;
    const newCertifiers = certifiers.filter(cert => {
      if (id !== '') return cert.id !== id;
      if (certifier !== -1) return cert.certifier !== certifier;
    });
    const centre = copyOf(this.state.centre);
    centre.certifiers = newCertifiers;
    this.setState({
      centre,
      selectedCertifier: certifier.toString(),
      selectedCertifierName: certifierName ? certifierName : '',
    });
  }

  addCertifier() {
    const {
      centre: { certifiers },
      selectedCertifier,
      selectedCertifierId,
      selectedCertifierName,
    } = this.state;
    if (selectedCertifierName !== '') {
      const certifierCopy = copyOf(certifiers);

      certifierCopy.push({
        certifier: parseInt(selectedCertifier, undefined),
        ownId: selectedCertifierId,
        certifierName: selectedCertifierName,
      });

      this.setState({ selectedCertifierId: '', selectedCertifierName: '' }, () => {
        this.handleChange('centre.certifiers', certifierCopy, true);
      });
    }
  }

  searchTimeout: any;
  handleSearchAddress(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (value && value !== '') this.searchAddress(value);
    }, 300);
  }

  async searchAddress(value: string) {
    const resp: any = await getDataFromAddress(value);
    const results = resp.data.results;
    const places: any[] = results.map((result: any) => {
      const level1 = getAdministrativeLevel1(result);
      const town = getTown(result);
      const country = getCountry(result);
      const postalCode = getPostalCode(result);
      return {
        level1,
        town,
        country,
        postalCode,
        value: result.formatted_address,
        label: result.formatted_address,
      };
    });
    this.setState({ places });
  }

  firstStep() {
    const {
      clearCertifier,
      errors,
      allCertifiers,
      centre,
      selectedCertifierName,
      selectedCertifierId,
      selectedCertifier,
      places,
      accessEmail,
    } = this.state;
    const { t, agent, editMode } = this.props;
    const formatedCertifiersOptions = allCertifiers.filter(certifier => {
      let ret = true;
      if (centre.certifiers) {
        for (const cert of centre.certifiers) {
          if (cert.certifier && cert.certifier !== -1 && certifier.value === cert.certifier.toString()) ret = false;
        }
      }
      return ret;
    });
    return (
      <>
        <div key="first-step" className="modal-create-form">
          {false && agent.roles.includes('superAdmin') && !editMode && (
            <>
              <div className="modal-create-form__title">
                <BodyDestacadoText>DATOS DE ACCESO</BodyDestacadoText>
              </div>
              <div className="modal-create-form__content">
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        placeholder="Email"
                        className="rounded-title"
                        type="text"
                        value={accessEmail || ''}
                        labelText="Email"
                        required={true}
                        errorCode={errors['accessEmail']}
                        onChange={value => {
                          this.handleChange('accessEmail', value, true);
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
              </div>
            </>
          )}
          <div className="modal-create-form__title">
            <BodyDestacadoText>{t('modalMessages:modal-center-form.company-data')}</BodyDestacadoText>
          </div>
          <div className="modal-create-form__content">
            <FormGrid>
              {// TODO: por defecto centre hasta que vuelva los clubs DIV-2994
              false && (
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText={t('inputs:company-type.label')}
                      placeholder={t('inputs:company-type.label')}
                      required={true}
                      className="rounded-title"
                      optionsText={CENTRE_TYPES}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={centre.type}
                      initialValue={centre.type}
                      errorCode={errors['centre.type']}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        this.handleChange('centre.type', value, true);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              )}
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder={t('inputs:name.label')}
                    className="rounded-title"
                    type="text"
                    value={centre.name}
                    labelText={t('inputs:name.label')}
                    required={true}
                    errorCode={errors['centre.name']}
                    onChange={value => {
                      this.handleChange('centre.name', value, true);
                    }}
                  />
                </FormGridItem>
              </FormGridRow>

              <FormGridRow>
                <FormGridItem type="col-0">
                  <SelectBox
                    key={'certifierSelect'}
                    labelText={t('inputs:certifier.label')}
                    placeholder={t('inputs:certifier.label')}
                    required={false}
                    className="rounded-title"
                    optionsText={formatedCertifiersOptions}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={selectedCertifier}
                    //errorCode={errors['centre.certifiers']}
                    icon={images.arrowDown2BlueSvg}
                    onChange={(value, label) => {
                      if (label && value !== '-1') {
                        this.handleChange('selectedCertifierName', label, false).then(() => {
                          this.handleChange('selectedCertifier', value, false);
                        });
                      } else {
                        this.setState({ selectedCertifierName: '' });
                        this.handleChange('selectedCertifier', value, false, 'selectedCertifierName');
                      }
                    }}
                  />
                </FormGridItem>
                {selectedCertifier === '-1' && (
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        key={'certifierName'}
                        placeholder={t('inputs:external-certifier.placeholder')}
                        className="rounded-title"
                        type="text"
                        value={selectedCertifierName}
                        labelText={t('inputs:external-certifier.placeholder')}
                        errorCode={errors['selectedCertifierName']}
                        onChange={value => {
                          this.handleChange('selectedCertifierName', value, true);
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                )}
                <FormGridItem type="col-1">
                  <InputBox
                    key={'certifierId'}
                    placeholder={t('inputs:id.label')}
                    className="rounded-title"
                    type="text"
                    value={selectedCertifierId}
                    labelText={t('inputs:id.label')}
                    required={true}
                    errorCode={errors['selectedCertifierId']}
                    onChange={value => {
                      this.handleChange('selectedCertifierId', value, false);
                    }}
                  />
                </FormGridItem>
                <FormGridItem type="col-3">
                  <MainButton
                    disabled={false}
                    type="secondary"
                    className={'full-height MT-26'}
                    iconImg={images.checkCircleSvg}
                    text={t('buttons:add')}
                    onClick={() => this.addCertifier()}
                  />
                </FormGridItem>
              </FormGridRow>

              <FormGrid>
                <FormGridRow type="row-wrap">
                  {!!centre.certifiers &&
                    centre.certifiers.map((item, key) => (
                      <FormGridItem key={`${key}-${Math.random()}`} type="w-1/3">
                        <ListItemAdded
                          id={item.ownId ? item.ownId.toString() : ''}
                          key={`${key}-${Math.random()}`}
                          text={item.certifierName ? item.certifierName : ''}
                          onRemove={() => this.handleCertifierRemove(item.id, item.certifier, item.certifierName)}
                        />
                      </FormGridItem>
                    ))}
                </FormGridRow>
              </FormGrid>
            </FormGrid>
          </div>

          <div className="modal-create-form__content">
            <FormGrid>
              <FormGridRow type="form-row-responsive">
                <FormGridItem type="col-0">
                  {/* <InputBox
                    placeholder={t('inputs:address.label')}
                    className="rounded"
                    type="text"
                    value={centre.address}
                    errorCode={errors['centre.address']}
                    labelText=""
                    onChange={value => {
                      this.handleChange('centre.address', value, true).then(() => {
                        this.handleSearchAddress(value);
                      });
                    }}
                  /> */}

                  <SelectBox
                    required={true}
                    placeholder={t('modalMessages:address')}
                    className="rounded-title"
                    optionsText={places}
                    errorCode={errors['address']}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={centre ? centre.address : ''}
                    initialValue={centre ? centre.address : ''}
                    initialValueFilterText={centre ? centre.address : ''}
                    defaultValueFilterText={centre ? centre.address : ''}
                    labelText={t('modalMessages:addressfisc')}
                    onSearch={value => {
                      this.handleSearchAddress(value);
                    }}
                    onChange={async (value, label) => {
                      const place: any = places.filter(place => place.value === value)[0];
                      await this.handleChange('centre.address', label || '', true);
                      await this.handleChange('centre.state', place.level1.longName, true);
                      await this.handleChange('centre.town', place.town.longName);
                      await this.handleChange('centre.country', place.country.shortName);
                      await this.handleChange('centre.lat', place.country.lng);
                      await this.handleChange('centre.lng', place.country.shortName);
                      await this.handleChange('centre.postalCode', place.postalCode.longName);
                    }}
                  />
                </FormGridItem>
                <FormGridItem type="col-2">
                  <InputBox
                    placeholder={t('inputs:postal-code.label')}
                    className="rounded-title"
                    type="text"
                    value={centre.postalCode}
                    errorCode={errors['centre.postalCode']}
                    required={true}
                    labelText={t('inputs:postal-code.label')}
                    onChange={value => {
                      this.handleChange('centre.postalCode', value, true);
                    }}
                  />
                </FormGridItem>
              </FormGridRow>

              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder={t('inputs:city.label')}
                    className="rounded-title"
                    type="text"
                    value={centre.town}
                    errorCode={errors['centre.town']}
                    labelText={t('inputs:city.label')}
                    required={true}
                    onChange={value => {
                      this.handleChange('centre.town', value, true);
                    }}
                  />
                </FormGridItem>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder={t('inputs:province.label')}
                    className="rounded-title"
                    type="text"
                    disabled={false}
                    value={centre.state ? centre.state : ''}
                    errorCode={errors['centre.state']}
                    required={true}
                    labelText={t('inputs:province.label')}
                    onChange={value => {
                      this.handleChange('centre.state', value, true);
                    }}
                  />
                </FormGridItem>
                <FormGridItem type="col-0">
                  <SelectBox
                    labelText={t('inputs:country.label')}
                    placeholder={t('inputs:country.label')}
                    searchable={true}
                    required={true}
                    disabled={false}
                    className="rounded-title"
                    initialValue={centre.country}
                    clearValue={clearCertifier}
                    optionsText={COUNTRIES}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={centre.country}
                    initialValueFilterText={COUNTRIES_KEY_LABEL[centre.country]}
                    defaultValueFilterText={COUNTRIES_KEY_LABEL[centre.country]}
                    icon={images.arrowDown2BlueSvg}
                    errorCode={errors['centre.country']}
                    onChange={(value, label) => {
                      this.handleChange('centre.country', value, true);
                    }}
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>
          </div>
          <div className="modal-create-form__content">
            <FormGridRow>
              <FormGridItem type="col-0">
                <InputBox
                  labelText={t('inputs:web.label')}
                  placeholder={t('inputs:web.label')}
                  className="rounded-title"
                  type="text"
                  value={centre.website ? centre.website : ''}
                  errorCode={errors['centre.website']}
                  onChange={value => {
                    this.handleChange('centre.website', value, false);
                  }}
                />
              </FormGridItem>
            </FormGridRow>
            <FormGrid>
              <FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <PhoneBox
                      className="rounded-title"
                      labelText={t('inputs:phone-simple.label')}
                      required={editMode ? false : true}
                      placeholder={t('inputs:phone-simple.label')}
                      errorPrefix={errors['centre.phone.prefix']}
                      errorNumber={errors['centre.phone.number']}
                      defaultPrefix={centre.phone ? centre.phone.prefix : ''}
                      defaultNumber={centre.phone ? centre.phone.number : ''}
                      filledPrefix={centre.phone ? centre.phone.prefix : ''}
                      filledNumber={centre.phone ? centre.phone.number : ''}
                      onChange={(field, value) => {
                        if (field === 'phonePrefix') {
                          this.handleChange('centre.phone.prefix', value, false);
                        } else {
                          this.handleChange('centre.phone.number', value, false);
                        }
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('inputs:email-simple.label')}
                      className="rounded-title"
                      type="text"
                      value={centre.email ? centre.email : ''}
                      required={editMode ? false : true}
                      labelText={t('inputs:email-simple.label')}
                      errorCode={errors['centre.email']}
                      onChange={value => {
                        this.handleChange('centre.email', value, false);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGridRow>
              {agent.roles.includes('superAdmin') && (
                <FormGridRow>
                  <FormGridItem type="col-3">
                    <SelectBox
                      labelText="Comisión por reserva"
                      required={true}
                      className="rounded-title"
                      optionsText={CENTRE_FEE_TYPES}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={centre.feeType}
                      initialValue={centre.feeType}
                      // errorCode={errors['centre.feeType']}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        this.handleChange('centre.feeType', value, false);
                        // this.handleFeeChange(value);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              )}
            </FormGrid>
          </div>
        </div>
      </>
    );
  }

  /*   secondStep() {
      const { centre, errors } = this.state;
      const { t } = this.props;
      return (
        <>
          <div key="second-step" className="modal-create-form">
            <div className="modal-create-form__title">
              <BodyDestacadoText>{t('modalMessages:modal-center-form.billing-data')}</BodyDestacadoText>
            </div>
            <div className="modal-create-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('inputs:iban.label')}
                      className="rounded"
                      type="text"
                      value={centre.bankAccount ? centre.bankAccount.iban : ''}
                      key="iban"
                      labelText=""
                      cleaveOptions={''}
                      errorCode={errors['centre.bankAccount.iban']}
                      onChange={value => {
                        this.handleChange('centre.bankAccount.iban', value, true).then(() => {
                          this.handleChange('centre.bankAccount.country', value.substring(0, 2), true);
                        });
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
  
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      placeholder={t('inputs:coin.label')}
                      className="rounded"
                      optionsText={CURRENCIES}
                      searchable={true}
                      optionKey="value"
                      optionValue="label"
                      initialValue={centre.bankAccount ? centre.bankAccount.currency : ''}
                      defaultValue={centre.bankAccount ? centre.bankAccount.currency : ''}
                      initialValueFilterText={centre.bankAccount ? centre.bankAccount.currency : ''}
                      defaultValueFilterText={centre.bankAccount ? centre.bankAccount.currency : ''}
                      key="currency"
                      labelText=""
                      errorCode={errors['centre.bankAccount.currency']}
                      onChange={value => {
                        this.handleChange('centre.bankAccount.currency', value, true);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
  
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('inputs:address.label')}
                      className="rounded"
                      type="text"
                      value={centre.bankAccount ? centre.bankAccount.owner.address : ''}
                      labelText=""
                      errorCode={errors['centre.bankAccount.owner.address']}
                      onChange={value => {
                        this.handleChange('centre.bankAccount.owner.address', value, true);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('inputs:city.label')}
                      className="rounded"
                      type="text"
                      value={centre.bankAccount ? centre.bankAccount.owner.town : ''}
                      labelText=""
                      errorCode={errors['centre.bankAccount.owner.town']}
                      onChange={value => {
                        this.handleChange('centre.bankAccount.owner.town', value, true);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText=""
                      placeholder={t('inputs:country.label')}
                      required={true}
                      className="rounded"
                      optionsText={COUNTRIES}
                      searchable={true}
                      optionKey={'value'}
                      optionValue={'label'}
                      initialValueFilterText={
                        centre.bankAccount ? COUNTRIES_KEY_LABEL[centre.bankAccount.owner.country] : ''
                      }
                      defaultValueFilterText={
                        centre.bankAccount ? COUNTRIES_KEY_LABEL[centre.bankAccount.owner.country] : ''
                      }
                      defaultValue={centre.bankAccount ? centre.bankAccount.owner.country : ''}
                      errorCode={errors['centre.bankAccount.owner.country']}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        this.handleChange('centre.bankAccount.owner.country', value, true);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
  
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('inputs:postal-code.label')}
                      className="rounded"
                      type="text"
                      value={centre.bankAccount ? centre.bankAccount.owner.postalCode : ''}
                      labelText=""
                      errorCode={errors['centre.bankAccount.owner.postalCode']}
                      onChange={value => {
                        this.handleChange('centre.bankAccount.owner.postalCode', value, true);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('inputs:name-owner.label')}
                      className="rounded"
                      type="text"
                      value={centre.bankAccount ? centre.bankAccount.owner.name : ''}
                      labelText=""
                      errorCode={errors['centre.bankAccount.owner.name']}
                      onChange={value => {
                        this.handleChange('centre.bankAccount.owner.name', value, true);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('inputs:surname-owner.label')}
                      className="rounded"
                      type="text"
                      value={centre.bankAccount ? centre.bankAccount.owner.surname : ''}
                      labelText=""
                      errorCode={errors['centre.bankAccount.owner.surname']}
                      onChange={value => {
                        this.handleChange('centre.bankAccount.owner.surname', value, true);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <CalendarBox
                      placeholder={t('inputs:date-of-birth.label')}
                      className="rounded"
                      labelText=""
                      initialValue={moment(centre.bankAccount ? centre.bankAccount.owner.birthday : '').format(
                        'DD/MM/YYYY'
                      )}
                      errorCode={errors['centre.bankAccount.owner.birthday']}
                      onChange={value =>
                        this.handleChange(
                          'centre.bankAccount.owner.birthday',
                          moment(value, 'DD/MM/YYYY').toISOString(),
                          true
                        )
                      }
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>
        </>
      );
    }
  
    thirdStep() {
      const { centre, errors } = this.state;
      const { t } = this.props;
      return (
        <>
          <div key="third-step" className="modal-create-form">
            <div className="modal-create-form__title">
              <BodyDestacadoText>{t('modalMessages:modal-center-form.personal-data')}</BodyDestacadoText>
            </div>
            <div className="modal-create-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('inputs:name-complete.label')}
                      className="rounded"
                      type="text"
                      value={centre.personResponsible ? centre.personResponsible.name : ''}
                      labelText=""
                      errorCode={errors['centre.personResponsible.name']}
                      onChange={value => {
                        this.handleChange('centre.personResponsible.name', value, false);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('inputs:email-simple.label')}
                      className="rounded"
                      type="text"
                      value={
                        centre.personResponsible && centre.personResponsible.email ? centre.personResponsible.email : ''
                      }
                      labelText=""
                      errorCode={errors['centre.personResponsible.email']}
                      onChange={value => {
                        this.handleChange('centre.personResponsible.email', value, false);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <PhoneBox
                      className="rounded"
                      labelText=""
                      placeholder={t('inputs:phone-simple.label')}
                      errorPrefix={errors['centre.personResponsible.phone.prefix']}
                      errorNumber={errors['centre.personResponsible.phone.number']}
                      filledPrefix={
                        centre.personResponsible && centre.personResponsible.phone
                          ? centre.personResponsible.phone.prefix
                          : ''
                      }
                      filledNumber={
                        centre.personResponsible && centre.personResponsible.phone
                          ? centre.personResponsible.phone.number
                          : ''
                      }
                      onChange={(field, value) => {
                        if (field === 'phonePrefix') {
                          this.handleChange('centre.personResponsible.phone.prefix', value, false);
                        } else {
                          this.handleChange('centre.personResponsible.phone.number', value, false);
                        }
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>
        </>
      );
    }
   */
  renderSteps(step: number) {
    switch (step) {
      case 1:
        return this.firstStep();
      case 2:
      //      return this.secondStep();
      case 3:
        // return this.thirdStep(); !SE HAN ELIMINADO LOS PASOS 2 Y 3
        return this.firstStep();
      default:
        break;
    }
  }

  nextStep() {
    const { step, centre } = this.state;
    const { editMode } = this.props;
    switch (step) {
      case 1:
        if (!this.validateFirstStep()) {
          if (centre.type === 'club') this.setState({ step: step + 2 });
          else this.setState({ step: step + 1 });
        }
        break;
      case 2:
        if (!this.validateSecondStep()) {
          this.setState({ step: step + 1 });
        }
        break;
      case 3:
        if (!this.validateFirstStep()) {
          const centreForm = copyOf(centre);
          if (centre.type === 'club') delete centreForm.bankAccount;
          if (editMode) {
            this.editCentre(centreForm);
          } else {
            this.createCentre(centreForm);
          }
        }
        break;
      default:
        break;
    }
  }

  render() {
    const { step } = this.state;
    const { onCancel, showModal, className, editMode, t } = this.props;

    return (
      <ModalContainer key="modal-centre-form" className={className} active={showModal} modalClose={() => onCancel()}>
        <ModalCreateCenterContainer key="modal-centre-form-container">
          <div className="modal-create-title">
            {/* <div className="modal-create-title__step">
              <LabelText>
                {t('modalMessages:modal-center-form.step')} {step}
              </LabelText>
            </div> */}
            <div className="modal-create-title">
              <SubheadDestacadoText>
                {editMode
                  ? t('modalMessages:modal-center-form.edit-company')
                  : t('modalMessages:modal-center-form.create-company')}
              </SubheadDestacadoText>
            </div>
          </div>

          {this.renderSteps(step)}
          <div className="modal-create-buttons">
            {/* {step > 1 && (
              <div className="modal-create-buttons__back">
                <SingleButton
                  className="modal secondary"
                  icon={images.arrowLeftSvg}
                  onClick={() => {
                    if (centre.type === 'club') this.setState({ step: step - 2 });
                    else this.setState({ step: step - 1 });
                  }}
                />
              </div>
            )} */}
            <div className="modal-create-buttons__item">
              <MainButton
                type="primary"
                onClick={() => this.nextStep()}
                text={step < 3 ? t('buttons:next') : editMode ? t('buttons:save') : t('buttons:create')}
              />
            </div>
          </div>
        </ModalCreateCenterContainer>
      </ModalContainer>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default withTranslation(['intro', 'buttons'])(
  connect<{}, {}, {}, IStoreState>(mapStateToProps)(ModalCreateCentre)
);
