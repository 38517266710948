import rules from "./rbac-rules";
import { Rules } from "./rbac-types";
import React, { Component } from "react";
import { isArray } from "lodash";

const check = (rules: Rules, roles: string[], action: string, data?: any[]) => {

  let permissions = roles.length !== 0 ? rules[roles[0]] : rules[""];

  for (const rol of roles) {
    permissions = rules[rol]
    if (permissions ) {

      if (permissions.static && Object.keys(permissions.static).length !== 0 && permissions.static.includes(action)) {
        // static rule not provided for action
        return true;
      }
      if (permissions.dynamic && Object.keys(permissions.dynamic).length !== 0) {
        let permissionCondition = permissions.dynamic[action];
        if (!permissionCondition) {
          // dynamic rule not provided for action
          return false;
        }
        return permissionCondition(data);
      }
    }
  }
  return false;

};

export const isAllowed = (roles: any, perform: string, data?: any[]) => {
  let rol: string[];
  if (roles && !isArray(roles)) {
    rol = [roles];
  } else if (isArray(roles)) {
    rol = roles;
  } else {
    rol = [""];
  }

  return check(rules, rol, perform, data);
}

interface CanProps {
  roles: string[];
  perform: string;
  data?: any[];
}

class Can extends Component<CanProps> {
  render() {
    if (check(rules, this.props.roles, this.props.perform, this.props.data)) return this.props.children;

    return false;
  }
}

export default Can;