import React, { useState, useRef } from 'react';

// Components

// Styles
import ButtonDropdownStyle from './button-dropdown-style';

// Assets
import { images as icons } from '../../../assets/images';
import Button from '../main-button/button';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

export interface ButtonDropdownProps {
  text: string;
  orientationModal?: 'left' | 'center' | 'right';
  component?: any;
  leftIcon?: string;
  rightIcon?: string;
}

const ButtonDropdown: React.FC<ButtonDropdownProps> = (props: ButtonDropdownProps) => {
  const { text, orientationModal, component, leftIcon, rightIcon } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggleContainer = useRef(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const onClickOutsideHandler = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useOutsideClick(toggleContainer, onClickOutsideHandler);

  return (
    <ButtonDropdownStyle ref={toggleContainer} isOpen={isOpen} orientationModal={orientationModal}>
      <Button
        className={`filter ${isOpen ? 'open' : ''}`}
        text={text}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        onClick={() => {
          handleClick();
        }}
      />
      {component && <div className="dropdown">{component}</div>}
    </ButtonDropdownStyle>
  );
};

export default ButtonDropdown;
