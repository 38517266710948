import services from './services';

export const getIvaTypes = async (params: any) => {
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'iva-types',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
}

export const getOneIvaTypes = (ivaTypeId: string, params?: any) => {
  const endpoint = `iva-types/${ivaTypeId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadIvaTypesFormData = (ivaTypeId: string, data: FormData) => {
  const endpoint = `iva-types/${ivaTypeId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const uploadIvaTypes = (ivaTypeId: string, data: any) => {
  const endpoint = `iva-types/${ivaTypeId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteIvaTypes = (ivaTypeId: string) => {
  const endpoint = `iva-types/${ivaTypeId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createIvaTypes = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'iva-types',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
export const createIvaTypesFormData = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'iva-types',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};