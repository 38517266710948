import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';

import { CertifierRolesStyle } from './certifier-roles-style';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';

import { images } from '../../../assets/images';

import MainTableLayout from '../../../components/main-table/main-table';
import HeaderMain from '../../../components/header-main/header-main';
import Pagination from '../../../components/pagination';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';

import { ORGANIZATION_ROLES_PER_PAGE } from '../../../constants/data';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import CertifierRolesCreate from './certifier-roles-create/certifier-roles-create';
import { getOrganizationStaff, deleteOrganizationStaff } from '../../../services/collaborator';
import { copyOf } from '../../../utils/other';
import moment from 'moment';
import services from '../../../services/services';

export interface ICertifierRolesProps extends RouteComponentProps, WithTranslation {
  agent?: any;
}

export interface ICertifierRolesState {
  modal: string;
  roles: any[];
  total: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  page: number;
  editId?: string;
  organizationId: string;
  userData?: {
    name: string;
    surname: string;
    email: string;
    role: string;
    states: string[];
    id?: number;
  };
}

class CertifierRoles extends React.Component<ICertifierRolesProps, ICertifierRolesState> {
  readonly state: ICertifierRolesState;

  constructor(props: ICertifierRolesProps) {
    super(props);

    this.state = {
      modal: '',
      roles: [],
      total: 0,
      totalPages: 0,
      page: 1,
      searchFilter: '',
      skipPage: 0,
      organizationId: '',
    };
  }

  componentDidMount() {
    this.getStaff();
  }

  async getStaff(value?: string) {
    const { skipPage } = this.state;
    const organizationId: string | null = localStorage.getItem('organizationId');
    if (organizationId) {
      const params: any = {
        filter: {
          relations: ['staff_organizations', 'staff_organizations.organization', 'user'],
          skip: skipPage,
          limit: ORGANIZATION_ROLES_PER_PAGE,
          orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        },
      };
      if (value) {
        params.filter.where = {
          name: { method: 'ilike', value: `%${value.toLowerCase()}%`, collection: 'user' },
        };
      }
      const resp: any = await getOrganizationStaff(organizationId, params);
      const totalPages: number = Math.ceil(resp.data.count / ORGANIZATION_ROLES_PER_PAGE);
      this.setState({ organizationId, totalPages, roles: resp.data.data, total: resp.data.count });
    }
  }

  timeoutSearch: any;
  handleSearch = (value: string) => {
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      this.setState({ page: 1, skipPage: 0, totalPages: 0 }, () => this.getStaff(value));
    }, 500);
  };

  async handleRemove() {
    const { userData } = this.state;
    const organizationId: string | null = localStorage.getItem('organizationId');
    if (userData && userData.id) {
      await deleteOrganizationStaff(organizationId || '', userData.id.toString());
      services.pushNotification({
        title: '¡Eliminado!',
        text: 'El usuario se ha eliminado con éxito',
        type: 'red',
      });
      this.setState({ userData: undefined, modal: '' }, () => {
        this.getStaff();
      });
    }
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = ORGANIZATION_ROLES_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ skipPage: skip, page }, () => {
        this.getStaff();
      });
    }
  }

  getTableRows() {
    const { t } = this.props;
    const { roles, organizationId } = this.state;
    const tableRows: any[] = [];
    roles.forEach(role => {
      const tableRow = copyOf(this.tableRowTemplate);
      const staffOrganization = role.staff_organizations.filter(
        (org: any) => org.organization.id.toString() === organizationId
      );
      let userData: any;
      if (staffOrganization.length > 0) {
        userData = {
          name: role.user.name,
          surname: role.user.surname || '',
          email: role.user.email,
          role: staffOrganization[0].unlimited ? 'manager' : 'local',
          states: staffOrganization[0].states,
          id: role.id,
        };
      }
      tableRow.name.value = `${role.user.name} ${role.user.surname || ''}`;
      tableRow.rol.value =
        staffOrganization.length > 0 && staffOrganization[0].unlimited ? 'Gestor principal' : 'Gestor local';
      tableRow.createdAt.value = moment(role.createdAt).format('DD/MM/YYYY');
      tableRow.email.value = role.user.email;
      tableRow.phone.value = role.phone ? `${role.phone.prefix} ${role.phone.number}` : '';
      tableRow.options.actions[1].onClick = () => {
        this.setState({ userData, modal: 'modal-delete' });
      };
      tableRow.options.actions[0].onClick = () => {
        this.setState({ userData, modal: 'modal-create-user' });
      };
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  render() {
    const { modal, totalPages, userData, page, total } = this.state;
    const { agent, t } = this.props;
    return (
      <>
        <HeaderMain
          title={t('layouts:roles.header')}
          withSearch={true}
          handleSearch={(value: string) => {
            this.handleSearch(value);
          }}
          resultCount={total}
          mainButton={{
            img: images.createWhiteSvg,
            text: t('buttons:create'),
            onClick: () => this.setState({ modal: 'modal-create-user' }),
          }}
        />
        <MainLayoutSection>
          <CertifierRolesStyle>
            {/* Una tabla por cada centor */}
            <div className="roles-table">
              {/* <div className="roles-table-head">
                <div className="roles-table-head__title">
                  <SubheadDestacadoText>Centro La marina</SubheadDestacadoText>
                </div>
                <div className="roles-table-head__button">
                  <MainButton
                    onClick={() => this.setState({ modal: 'modal-create-user' })}
                    iconImg={images.createWhiteSvg}
                    text={t('buttons:create')}
                    type="primary"
                  />
                </div>
              </div> */}
              <div className="roles-table-content">
                <MainLayoutTableContainer>
                  <div className="diving-center-table">
                    <MainTableLayout
                      columns={this.tableColumns}
                      rows={this.getTableRows()}
                      rowKeys={this.rowKeys}
                      onOrder={() => { }}
                    />
                  </div>
                  {totalPages > 1 && (
                    <div className="diving-center-pagination">
                      <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
                    </div>
                  )}
                </MainLayoutTableContainer>
              </div>
            </div>
            {/*  */}
          </CertifierRolesStyle>
        </MainLayoutSection>
        {modal === 'modal-create-user' && (
          <CertifierRolesCreate
            onCancel={() => this.setState({ modal: '', userData: undefined })}
            showModal={modal === 'modal-create-user'}
            user={userData}
            onAccept={() => {
              this.setState({ modal: '', userData: undefined }, () => this.getStaff());
            }}
          />
        )}

        {/* Modal Delete centre  */}
        {modal === 'modal-delete' && (
          <ModalDelete
            title={t('modalMessages:modal-delete-user.title')}
            subtitle={t('modalMessages:modal-delete-user.subtitle')}
            showModal={modal === 'modal-delete'}
            onRemove={() => this.handleRemove()}
            onCancel={() => this.setState({ modal: '', userData: undefined })}
          />
        )}
      </>
    );
  }

  tableRowTemplate = {
    name: {
      value: '',
      key: '',
    },
    rol: {
      value: '',
      key: '',
    },
    createdAt: {
      value: '',
      key: '',
    },
    email: {
      value: '',
      key: '',
    },
    phone: {
      value: '',
      key: '',
    },
    options: {
      value: '',
      key: 'options',
      actions: [
        {
          label: 'Editar',
          icon: images.editSvg,
          onClick: () => { },
        },
        {
          label: 'Eliminar',
          icon: images.deleteSvg,
          onClick: () => { },
        },
      ],
    },
  };

  tableColumns = [
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Rol',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Creación',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Email',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Teléfono',
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  rowKeys = ['name', 'rol', 'createdAt', 'email', 'phone', 'options'];
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default withTranslation(['intro', 'buttons'])(connect<{}, {}, {}, IStoreState>(mapStateToProps)(CertifierRoles));
