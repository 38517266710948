import * as React from 'react';
import { PlanningCourseDateStyle } from './planning-course-date-style';
import InfoText from '../../../../components/text/info-text/info-text';
import LabelText from '../../../../components/text/label-text/label';
import { FormGridItem } from '../../../main-layout-style';
import CalendarBox from '../../../../components/calendar-box';
import MainButton from '../../../../components/main-button';
import InputBox from '../../../../components/input-box';
import { images } from '../../../../assets/images';
import { ITimeBlock } from '../../../../models/generic';
import moment from 'moment';
import SingleButton from '../../../../components/single-button/single-button';
import SelectBox from '../../../../components/select-box';
import { ICourseTemplateInfo } from '../../../../models/activity-template';
import { IDropdown, IId } from '../../../../models/generic';
import { getCentreDiveSites } from '../../../../services/centre';
import { copyOf } from '../../../../utils/other';
import { getTimeFormatted } from '../../../../utils/time';
import { ICourseActivityInfo } from '../../../../models/activities';

interface PlanningCourseDateProps {
  state?: PlanningCourseDateState;
  courseTemplateInfo: ICourseTemplateInfo;
  courseActivityInfo?: ICourseActivityInfo;
  startDate?: string;
  edit?: boolean;
  centreId: string;
  onPrev?: () => void;
  onNext: (
    state: PlanningCourseDateState,
    dates: string[],
    courseActivityInfo: ICourseActivityInfo,
    sites: IId[],
  ) => void;
}

export interface PlanningCourseDateState {
  courseActivityInfo: ICourseActivityInfo;
  startDate: string;
  modeSessionTheoreticDropdown: any[];
  modeSessionPracticalDropdown: any[];
  typeSessionDropdown: any[];
  selectedTypeSession: string;
  selectedModeSessionTheoretic: string;
  selectedModeSessionPractical: string;
  diveSitesDropdown: IDropdown[];
  selectedDiveSite: number;
  selectedStartTimeString: string;
  selectedEndTimeString: string;
  selectedStartTime?: ITimeBlock;
  selectedEndTime?: ITimeBlock;
  selectedDate: string;
  dates: string[];
  sites: number[];
  countSessions: any;
  planningFinished: boolean;
  errors: { [key: string]: string };
  clearDate: boolean;
}

class PlanningCourseDate extends React.Component<PlanningCourseDateProps, PlanningCourseDateState> {
  constructor(props: PlanningCourseDateProps) {
    super(props);
    this.state = {
      courseActivityInfo: {
        theoreticSessions: [],
        practicalSessions: [],
      },
      startDate: '',
      typeSessionDropdown: [],
      modeSessionTheoreticDropdown: [],
      modeSessionPracticalDropdown: [],
      diveSitesDropdown: [],
      selectedTypeSession: '',
      selectedModeSessionTheoretic: '',
      selectedModeSessionPractical: '',
      selectedDiveSite: NaN,
      selectedStartTimeString: '',
      selectedEndTimeString: '',
      selectedDate: '',
      dates: [],
      sites: [],
      countSessions: {
        theoreticOnline: 0,
        theoreticPresential: 0,
        practicalClosedWaterDives: 0,
        practicalOpenWaterDives: 0
      },
      planningFinished: false,
      clearDate: true,
      errors: {
        selectedTypeSession: '',
        selectedModeSessionTheoretic: '',
        selectedModeSessionPractical: '',
        selectedDiveSite: '',
        selectedStartTime: '',
        selectedEndTime: '',
        selectedDate: '',
        startDate: '',
      },
    };
  }

  async componentDidMount() {

    await this.setCourseInfo();
    this.getInitialCountSessions();
    this.getInitialSites();
    this.getDropdownSession();
    this.getDiveSites();

    //const { state } = this.props;
    //if (state) this.setState(state);

  }


  componentDidUpdate(prevProps: PlanningCourseDateProps) {
    const { courseActivityInfo } = this.props;
    if (courseActivityInfo !== prevProps.courseActivityInfo) {
      this.setCourseInfo();
    }
  }

  setCourseInfo() {
    const { courseActivityInfo, startDate } = this.props;
    if (courseActivityInfo) this.setState({ courseActivityInfo, });
    if (startDate) this.setState({ startDate });
  }

  async getDiveSites() {
    const { centreId } = this.props;

    const resp = await getCentreDiveSites(centreId);
    const sites = resp.data.data;
    const diveSitesDropdown = sites.map((diveSite: any) => {
      return {
        label: diveSite.name,
        value: diveSite.id.toString(),
      };
    });
    const diveSitesKeyValues: any = {};
    sites.forEach((diveSite: any) => {
      diveSitesKeyValues[diveSite.id] = diveSite.name;
    });
    this.setState({ diveSitesDropdown });
  }

  async getInitialCountSessions() {
    const { courseTemplateInfo, courseActivityInfo } = this.props;
    const { countSessions } = this.state;

    if (courseTemplateInfo.theoreticPhases) {
      for (const phase of courseTemplateInfo.theoreticPhases) {
        if (phase.onlineTheory && phase.onlineTheory.sessions) countSessions.theoreticOnline += phase.onlineTheory.sessions;
        if (phase.presentialTheory && phase.presentialTheory.sessions) countSessions.theoreticPresential += phase.presentialTheory.sessions;
      }
    }
    if (courseTemplateInfo.practicalPhases) {
      for (const phase of courseTemplateInfo.practicalPhases) {
        if (phase.closedWaterDives && phase.closedWaterDives.sessions) countSessions.practicalClosedWaterDives += phase.closedWaterDives.sessions;
        if (phase.openWaterDives && phase.openWaterDives.sessions) countSessions.practicalOpenWaterDives += phase.openWaterDives.sessions;
      }
    }
    if (courseActivityInfo && ((courseActivityInfo.theoreticSessions && courseActivityInfo.theoreticSessions.length) || (courseActivityInfo.practicalSessions && courseActivityInfo.practicalSessions.length))) {
      if (courseActivityInfo.theoreticSessions) {
        for (const session of courseActivityInfo.theoreticSessions) {
          if (session.onlineTheory) countSessions.theoreticOnline -= 1;
          if (session.presentialTheory) countSessions.theoreticPresential -= 1;
        }
      }
      if (courseActivityInfo.practicalSessions) {
        for (const session of courseActivityInfo.practicalSessions) {
          if (session.closedWaterDives) countSessions.practicalClosedWaterDives -= 1;
          if (session.openWaterDives) countSessions.practicalOpenWaterDives -= 1;
        }
      }
    }

    this.checkPlanningFinished();

    this.setState({ countSessions });
  }

  checkPlanningFinished() {
    const { countSessions, planningFinished } = this.state;
    let newPlanningFinished = copyOf(planningFinished);

    if ((countSessions.theoreticOnline === 0) &&
      (countSessions.theoreticPresential === 0) &&
      (countSessions.practicalClosedWaterDives === 0) &&
      (countSessions.practicalOpenWaterDives === 0)) {
      newPlanningFinished = true;
    } else {
      newPlanningFinished = false;
    }
    this.setState({ planningFinished: newPlanningFinished });
  }

  async getDropdownSession() {
    const { typeSessionDropdown, modeSessionTheoreticDropdown, modeSessionPracticalDropdown, countSessions, planningFinished } = this.state;

    let newTypeSessionDropdown = copyOf(typeSessionDropdown);
    newTypeSessionDropdown = [];

    let newModeSessionTheoreticDropdown = copyOf(modeSessionTheoreticDropdown);
    newModeSessionTheoreticDropdown = [];

    let newModeSessionPracticalDropdown = copyOf(modeSessionPracticalDropdown);
    newModeSessionPracticalDropdown = [];

    if ((countSessions.theoreticOnline > 0) || (countSessions.theoreticPresential > 0)) newTypeSessionDropdown.push({ 'value': '1', 'label': 'Teórica' });
    if (countSessions.theoreticOnline > 0) newModeSessionTheoreticDropdown.push({ 'value': '1', 'label': 'Online' });
    if (countSessions.theoreticPresential > 0) newModeSessionTheoreticDropdown.push({ 'value': '2', 'label': 'Presencial' });

    if ((countSessions.practicalClosedWaterDives > 0) || (countSessions.practicalOpenWaterDives > 0)) newTypeSessionDropdown.push({ 'value': '2', 'label': 'Práctica' });
    if (countSessions.practicalClosedWaterDives > 0) newModeSessionPracticalDropdown.push({ 'value': '1', 'label': 'Aguas cerradas' });
    if (countSessions.practicalOpenWaterDives > 0) newModeSessionPracticalDropdown.push({ 'value': '2', 'label': 'Aguas abiertas' })

    this.checkPlanningFinished();

    this.setState({ typeSessionDropdown: newTypeSessionDropdown, modeSessionTheoreticDropdown: newModeSessionTheoreticDropdown, modeSessionPracticalDropdown: newModeSessionPracticalDropdown });
  }

  getInitialSites() {
    const { courseActivityInfo, sites } = this.state;

    let newSites = copyOf(sites);
    newSites = [];

    if (courseActivityInfo && courseActivityInfo.practicalSessions && courseActivityInfo.practicalSessions.length) {
      for (const phase of courseActivityInfo.practicalSessions) {

        if (phase.closedWaterDives && phase.closedWaterDives.diveSite) newSites.push(phase.closedWaterDives.diveSite);
        if (phase.openWaterDives && phase.openWaterDives.diveSite) newSites.push(phase.openWaterDives.diveSite);
      }
    }
    this.setState({ sites: newSites })
  }

  handleChangeTime(time: string, isStart: boolean) {
    const state = Object.assign(this.state);
    const field = isStart
      ? 'selectedStartTime'
      : 'selectedEndTime';
    const timeValues = time.split(':');
    state[`${field}String`] = time;
    state.errors[field] = 'invalid-format';
    if (timeValues.length === 2) {
      try {
        const hour = parseInt(timeValues[0], undefined);
        const minutes = parseInt(timeValues[1], undefined);
        if (hour <= 23 && hour >= 0 && minutes <= 59 && minutes >= 0) {
          state[field] = {
            hour,
            minutes,
            seconds: 0,
          };
          state.errors[field] = '';
        }
      } catch (err) { }
    }
    this.setState(state);
  }

  validate(value: string | boolean | number | string[]) {
    if (!value || (typeof value === 'object' && value.length === 0)) return 'required';
    return '';
  }
  checkConflicts(): boolean{
    const aDates: any[] = [];
    const curDate:any = this.getDateAndTimes({
      start: {
        date: this.state.selectedDate,
        hour: this.state.selectedStartTime ? this.state.selectedStartTime.hour : 0,
        minutes: this.state.selectedStartTime ? this.state.selectedStartTime.minutes : 0,
      },
      end: {
        date: this.state.selectedDate,
        hour: this.state.selectedEndTime ? this.state.selectedEndTime.hour : 0,
        minutes: this.state.selectedEndTime ? this.state.selectedEndTime.minutes : 0,
      }
    });
    const startDate= this.datoToTime(this.state.startDate, 0,0);
    if (curDate.start < startDate){
      return true;
    }
    if(curDate.start >= curDate.end){
      return true;
    }
    


    if(this.state.courseActivityInfo && this.state.courseActivityInfo.practicalSessions && this.state.courseActivityInfo.practicalSessions.length!== 0 ){
      for(const ses of this.state.courseActivityInfo.practicalSessions){
        if(ses.closedWaterDives){
          const d:any = this.getDateAndTimes(ses.closedWaterDives.timeBlock);
          if(this.checkOverWrite(curDate,d )){
            return true;
          }
        }
        if(ses.openWaterDives){
          const d:any = this.getDateAndTimes(ses.openWaterDives.timeBlock);
          if(this.checkOverWrite(curDate,d )){
            return true;
          }
        }
      }
    }
    if(this.state.courseActivityInfo && this.state.courseActivityInfo.theoreticSessions && this.state.courseActivityInfo.theoreticSessions.length!== 0 ){
      for(const ses of this.state.courseActivityInfo.theoreticSessions){

        if(ses.onlineTheory){
          const d:any = this.getDateAndTimes(ses.onlineTheory.timeBlock);
          if(this.checkOverWrite(curDate,d )){
            return true;
          }
        }
        if(ses.presentialTheory){
          const d:any = this.getDateAndTimes(ses.presentialTheory.timeBlock);
          if(this.checkOverWrite(curDate,d )){
            return true;
          }
        }
      }
    }

    return false;
  }
  checkOverWrite(date1:any, date2:any){
    if(date1.start > date2.start && date1.start< date2.end){
      return true;
    }
    if(date1.end > date2.start && date1.end < date2.end){
      return true;
    }
    return false;
  }
  datoToTime(date: string, hour: number, min: number){
    const da = new Date(date)
    const fecha = new Date( Date.UTC(da.getFullYear(), da.getMonth(), da.getDate(), hour, min)).getTime();
    return fecha;
  }
  getDateAndTimes(timeBlock:any){
    
    return {
      start: this.datoToTime(timeBlock.start.date, timeBlock.start.hour, timeBlock.start.minutes),
      end: this.datoToTime(timeBlock.end.date, timeBlock.end.hour, timeBlock.end.minutes),
    }
    return moment(new Date(timeBlock.start.date), 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' + getTimeFormatted(timeBlock.start.hour, timeBlock.start.minutes) + ' - ' + getTimeFormatted(timeBlock.end.hour, timeBlock.end.minutes);

  }
  handleAddSession() {
    const {
      errors,
      selectedDate,
      selectedModeSessionTheoretic,
      selectedModeSessionPractical,
      selectedTypeSession,
      selectedDiveSite,
      selectedStartTime,
      selectedEndTime,
      courseActivityInfo,
      sites,
      countSessions
    } = Object.assign(this.state);
    const state = Object.assign(this.state);
    if(this.checkConflicts()){
      alert("Conflicto de fechas");
      return;
    }


    if (selectedTypeSession === "1") {
      Object.keys(errors).forEach((field: any) => {
        if ((field != 'selectedModeSessionPractical') && (field != 'selectedDiveSite') && (field != 'startDate')) {
          errors[field] = this.validate(state[field]);
        }
        errors.selectedModeSessionPractical = '';
        errors.selectedDiveSite = '';
        errors.startDate = '';
      });
    } else {
      Object.keys(errors).forEach((field: any) => {
        if ((field != 'selectedModeSessionTheoretic') && (field != 'startDate')&& (field != 'selectedDiveSite') ) {
          errors[field] = this.validate(state[field]);
        }
        errors.selectedModeSessionTheoretic = '';
        errors.startDate = '';
      });
    }
    this.setState({ errors });

    if (Object.values(errors).every(err => err === '')) {

      const timeBlockSession: any = {}
      if (selectedStartTime) selectedStartTime.date = selectedDate;
      if (selectedEndTime) selectedEndTime.date = selectedDate;
      timeBlockSession.start = selectedStartTime;
      timeBlockSession.end = selectedEndTime;

      const newSites = copyOf(sites);


      if (selectedTypeSession === "1") {
        let theoreticSession: any = {};
        if (selectedModeSessionTheoretic === "1") {
          theoreticSession.onlineTheory = {};
          theoreticSession.onlineTheory.timeBlock = timeBlockSession;
          countSessions.theoreticOnline -= 1;
        } else {
          theoreticSession.presentialTheory = {};
          theoreticSession.presentialTheory.timeBlock = timeBlockSession;
          countSessions.theoreticPresential -= 1;
        }
        courseActivityInfo.theoreticSessions.push(theoreticSession);
      } else {
        newSites.push(selectedDiveSite);
        let practicalSessions: any = {};
        if (selectedModeSessionPractical === "1") {
          practicalSessions.closedWaterDives = {};
          practicalSessions.closedWaterDives.timeBlock = timeBlockSession;
          practicalSessions.closedWaterDives.diveSite = selectedDiveSite;
          countSessions.practicalClosedWaterDives -= 1;
        } else {
          practicalSessions.openWaterDives = {};
          practicalSessions.openWaterDives.timeBlock = timeBlockSession;
          practicalSessions.openWaterDives.diveSite = selectedDiveSite;
          countSessions.practicalOpenWaterDives -= 1;
        }
        courseActivityInfo.practicalSessions.push(practicalSessions);
      }

      if(isNaN(newSites)){
        this.setState({ courseActivityInfo, sites: [], countSessions, selectedDate: '', clearDate: true });
      }else{
        this.setState({ courseActivityInfo, sites: newSites, countSessions, selectedDate: '', clearDate: true });
      }
      
      this.getDropdownSession();
      this.resetState();
    }

  }

  handleRemoveSession(index: number, type: string, sub?: string) {
    const { courseActivityInfo, sites, countSessions } = this.state;

    const newCourseInfo = copyOf(courseActivityInfo);
    const newSites = copyOf(sites);

    if (type === 'theoretic') {
      if (sub === 'onlineTheory') {
        countSessions.theoreticOnline += 1;
      } else {
        countSessions.theoreticPresential += 1;
      }
      newCourseInfo.theoreticSessions.splice(index, 1);
    } else {
      let siteToDelete;
      if (sub === 'closedWaterDives') {
        countSessions.practicalClosedWaterDives += 1;
        siteToDelete = newCourseInfo.practicalSessions[index].closedWaterDives.diveSite;
      } else {
        countSessions.practicalOpenWaterDives += 1;
        siteToDelete = newCourseInfo.practicalSessions[index].openWaterDives.diveSite;
      }
      newCourseInfo.practicalSessions.splice(index, 1);
      newSites.splice(newSites.indexOf(+siteToDelete), 1);
    }

    this.setState({ courseActivityInfo: newCourseInfo, sites: newSites, countSessions });
    this.getDropdownSession();
  }

  resetState() {
    this.setState({
      selectedDate: '',
      selectedStartTimeString: '',
      selectedEndTimeString: '',
      selectedTypeSession: '0',
      selectedModeSessionTheoretic: '',
      selectedModeSessionPractical: '',
      selectedDiveSite: NaN,
    });
  }

  formattedDate(timeBlock: any) {
    return moment(new Date(timeBlock.start.date), 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' + getTimeFormatted(timeBlock.start.hour, timeBlock.start.minutes) + ' - ' + getTimeFormatted(timeBlock.end.hour, timeBlock.end.minutes);
  }


  handleNext() {
    const { onNext } = this.props;
    const { courseActivityInfo, sites, startDate, planningFinished, errors } = this.state;
    
    if ((this.validate(startDate) === '') && (planningFinished)) {
      const siteNotRepeated = Array.from(new Set(sites));
      const sitesFormatted = siteNotRepeated.map(site => {
        return { id: +site };
      })
      
      if (courseActivityInfo.theoreticSessions && courseActivityInfo.theoreticSessions.length === 0) delete courseActivityInfo.theoreticSessions;
      if (courseActivityInfo.practicalSessions && courseActivityInfo.practicalSessions.length === 0) delete courseActivityInfo.practicalSessions;

      onNext(this.state, [startDate], courseActivityInfo, sitesFormatted);
    } else if (this.validate(startDate) != '') {
      errors.startDate = this.validate(startDate);
    } else if (!planningFinished) {

    }

  }

  render() {
    const { onPrev, courseTemplateInfo, edit } = this.props;
    const {
      errors,
      selectedStartTimeString,
      selectedEndTimeString,
      selectedDate,
      typeSessionDropdown,
      modeSessionTheoreticDropdown,
      modeSessionPracticalDropdown,
      selectedTypeSession,
      selectedModeSessionTheoretic,
      selectedModeSessionPractical,
      diveSitesDropdown,
      selectedDiveSite,
      courseActivityInfo,
      startDate,
      planningFinished
    } = this.state;

    return (
      <PlanningCourseDateStyle>
        { !edit ? (
          <div>
            <div className="planing-date">
              <div className="planing-title">
                <LabelText>{'Fecha de inicio de la actividad'}</LabelText>
              </div>
              <div className="planing-date-form">
                <CalendarBox
                  initialValue={moment(startDate).format('DD/MM/YYYY')}
                  className="rounded multiple"
                  labelText=""
                  forbidPastDates={true}
                  placeholder="Fecha de inicio"
                  errorCode={errors.startDate}
                  onChange={value => {
                    errors.startDate = this.validate(value);
                    this.setState({
                      startDate: moment(value, 'DD/MM/YYYY')
                        .hours(12)
                        .toISOString(),
                    })
                  }
                  }
                />
              </div>
            </div>
          </div>
        ) : ''}

        <div className="planning">
          {courseTemplateInfo.theoreticPhases && courseTemplateInfo.theoreticPhases.length ? (
            <div className="planning-phase-tittle">FASE TEÓRICA
              {
                courseTemplateInfo.theoreticPhases.map((phase, index) => {
                  if (phase.onlineTheory && phase.onlineTheory.sessions) {
                    return (
                      <div key={index} className="planning-phase-content">
                        <InfoText> Teórica-online | {phase.onlineTheory.sessions} sesiones</InfoText>
                      </div>
                    );

                  }
                })
              }
              {
                courseTemplateInfo.theoreticPhases.map((phase, index) => {
                  if (phase.presentialTheory && phase.presentialTheory.sessions) {
                    return (
                      <div key={index} className="planning-phase-content">
                        <InfoText> Teórica-presencial | {phase.presentialTheory.sessions} sesiones</InfoText>
                      </div>
                    );
                  }
                })
              }
            </div>
          ) : ''}

          {courseTemplateInfo.practicalPhases && courseTemplateInfo.practicalPhases.length ? (
            <div className="planning-phase-tittle">FASE PRACTICA
              {
                courseTemplateInfo.practicalPhases.map((phase, index) => {
                  if (phase.closedWaterDives && phase.closedWaterDives.sessions) {
                    return (
                      <div key={index} className="planning-phase-content">
                        <InfoText> Práctica-aguas cerradas | {phase.closedWaterDives.sessions} sesiones</InfoText>
                      </div>

                    );
                  }
                })
              }
              {
                courseTemplateInfo.practicalPhases.map((phase, index) => {
                  if (phase.openWaterDives && phase.openWaterDives.sessions) {
                    return (
                      <div key={index} className="planning-phase-content">
                        <InfoText> Práctica-aguas abiertas | {phase.openWaterDives.sessions} sesiones</InfoText>
                      </div>

                    );
                  }
                })
              }
            </div>
          ) : ''}
        </div>
        {!planningFinished && (
          <div>
            <div className="planing-type">
              <div className="planing-type-form">
                <div className="planing-type-session">
                  <div className="planing-title">
                    <LabelText>{'Tipo de sesión'}</LabelText>
                  </div>
                  <FormGridItem type="col-2">
                    <SelectBox
                      errorCode={errors.selectedTypeSession}
                      className="rounded"
                      labelText="Tipo de sesión"
                      placeholder={'Tipo de sesión'}
                      required={true}
                      initialValue={''}
                      optionsText={typeSessionDropdown}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={'0'}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        errors.selectedTypeSession = this.validate(value);
                        this.setState({ selectedTypeSession: value })
                      }
                      }
                    />
                  </FormGridItem>
                </div>
                {selectedTypeSession === '1' ? (
                  <div className="planing-mode-session">
                    <div className="planing-title">
                      <LabelText>{'Modo de sesión'}</LabelText>
                    </div>
                    <FormGridItem type="col-2">
                      <SelectBox
                        errorCode={errors.selectedModeSessionTheoretic}
                        className="rounded"
                        labelText="Modo de sesión"
                        placeholder={'Modo de sesión'}
                        required={true}
                        initialValue={''}
                        optionsText={modeSessionTheoreticDropdown}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={''}
                        icon={images.arrowDown2BlueSvg}
                        onChange={value => {
                          errors.selectedModeSessionTheoretic = this.validate(value);
                          this.setState({ selectedModeSessionTheoretic: value })
                        }
                        }
                      />
                    </FormGridItem>
                  </div>
                ) : ''}
                {selectedTypeSession === '2' ? (
                  <div className="planing-mode-session">
                    <div className="planing-title">
                      <LabelText>{'Modo de sesión'}</LabelText>
                    </div>
                    <FormGridItem type="col-1">
                      <SelectBox
                        errorCode={errors.selectedModeSessionPractical}
                        className="rounded"
                        labelText="Modo de sesión"
                        placeholder={'Modo de sesión'}
                        required={true}
                        initialValue={''}
                        optionsText={modeSessionPracticalDropdown}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={''}
                        icon={images.arrowDown2BlueSvg}
                        onChange={value => {
                          errors.selectedModeSessionPractical = this.validate(value);
                          this.setState({ selectedModeSessionPractical: value })
                        }
                        }
                      />
                    </FormGridItem>
                    <div className="planing-title">
                      <LabelText>{'Selecciona punto de inmersión'}</LabelText>
                    </div>
                    <FormGridItem type="col-1">
                      <SelectBox
                        labelText=""
                        placeholder="Punto de inmersión"
                        required={false}
                        errorCode={errors.selectedDiveSite}
                        className="rounded"
                        optionsText={diveSitesDropdown}
                        searchable={true}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={''}
                        initialValue={''}
                        clearValue={!selectedDiveSite}
                        icon={images.arrowDown2BlueSvg}
                        onChange={value => {
                          errors.selectedDiveSite = this.validate(value);
                          this.setState({ selectedDiveSite: parseInt(value, undefined) });
                        }}
                      />
                    </FormGridItem>
                  </div>
                ) : ''}
              </div>
            </div>

            <div className="planing-date">
              <div className="planing-title">
                <LabelText>{'Fecha de la sesión'}</LabelText>
              </div>
              <div className="planing-date-form">
                <CalendarBox
                  initialValue={moment(selectedDate).format('DD/MM/YYYY')}
                  className="rounded multiple"
                  labelText=""
                  clear={this.state.clearDate}
                  forbidPastDates={true}
                  placeholder="Fecha de inicio"
                  errorCode={errors.selectedDate}
                  cleaded={() => this.setState({...this.state, clearDate: false})}
                  onChange={value => {
                    errors.selectedDate = this.validate(value);
                    this.setState({
                      selectedDate: moment(value, 'DD/MM/YYYY')
                        .hours(12)
                        .toISOString(),
                    })
                  }
                  }
                />
              </div>
            </div>

            <div className="planing-date-horary">
              <div className="planing-title">
                <LabelText>Horario de la sesión</LabelText>
              </div>
              <div className="planing-date-horary-form">
                <div className="planing-date-horary-form__item">
                  <InputBox
                    className="rounded"
                    required={true}
                    type="text"
                    errorCode={errors.selectedStartTime}
                    value={selectedStartTimeString}
                    placeholder="Hora de inicio"
                    labelText=""
                    onChange={value => {
                      errors.selectedStartTimeString = this.validate(value);
                      this.handleChangeTime(value, true)
                    }
                    }
                    withTooltip={false}
                    cleaveOptions={{ time: true, timePattern: ['h', 'm'] }}
                  />
                </div>
                <div className="planing-date-horary-form__item">
                  <InputBox
                    className="rounded"
                    required={true}
                    type="text"
                    errorCode={errors.selectedEndTime}
                    value={selectedEndTimeString}
                    placeholder="Hora de fin"
                    labelText=""
                    onChange={value => {
                      errors.selectedEndTimeString = this.validate(value);
                      this.handleChangeTime(value, false)
                    }
                    }
                    withTooltip={false}
                    cleaveOptions={{ time: true, timePattern: ['h', 'm'] }}
                  />
                </div>
              </div>
            </div>

            <div className="planing-add-session">
              <MainButton
                onClick={() => this.handleAddSession()}
                text={'Añadir sesión'}
                iconImg={images.plusSvg}
                type="secondary full-height"
              />
            </div>
          </div>
        )}
        <div className="summary">
          {courseActivityInfo.theoreticSessions && courseActivityInfo.theoreticSessions.length ? (
            <div className="session-tittle">PLANIFICACIÓN FASE TEÓRICA
              {
                courseActivityInfo.theoreticSessions.map((session, index) => {
                  if (session.onlineTheory) {
                    return (
                      <div key={index} className="session-content">
                        <InfoText> Teórica-online | {this.formattedDate(session.onlineTheory.timeBlock)} </InfoText>
                        <div className="delete-img">
                          {<img src={images.deleteSvg} alt="" onClick={() => this.handleRemoveSession(index, 'theoretic', 'onlineTheory')} />}
                        </div>
                      </div>
                    );

                  }
                })
              }
              {
                courseActivityInfo.theoreticSessions.map((session, index) => {
                  if (session.presentialTheory) {
                    return (
                      <div key={index} className="session-content">
                        <InfoText> Teórica-presencial | {this.formattedDate(session.presentialTheory.timeBlock)}</InfoText>
                        <div className="delete-img">
                          {<img src={images.deleteSvg} alt="" onClick={() => this.handleRemoveSession(index, 'theoretic', 'presentialTheory')} />}
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          ) : ''}

          {courseActivityInfo.practicalSessions && courseActivityInfo.practicalSessions.length ? (
            <div className="session-tittle">PLANIFICACIÓN FASE PRACTICA
              {
                courseActivityInfo.practicalSessions.map((session, index) => {
                  if (session.closedWaterDives) {
                    return (
                      <div key={index} className="session-content">
                        <InfoText> Práctica-Aguas cerradas | {this.formattedDate(session.closedWaterDives.timeBlock)}</InfoText>
                        <div className="delete-img">
                          {<img src={images.deleteSvg} alt="" onClick={() => this.handleRemoveSession(index, 'practical', 'closedWaterDives')} />}
                        </div>
                      </div>
                    );
                  }

                })
              }
              {
                courseActivityInfo.practicalSessions.map((session, index) => {
                  if (session.openWaterDives) {
                    return (
                      <div key={index} className="session-content">
                        <InfoText> Práctica-Aguas abiertas | {this.formattedDate(session.openWaterDives.timeBlock)}</InfoText>
                        <div className="delete-img">
                          {<img src={images.deleteSvg} alt="" onClick={() => this.handleRemoveSession(index, 'practical', 'openWaterDives')} />}
                        </div>
                      </div>
                    );
                  }

                })
              }
            </div>
          ) : ''}
        </div>


        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={onPrev ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </PlanningCourseDateStyle>
    );
  }
}

export default PlanningCourseDate;
