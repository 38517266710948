import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const UnavailableContentContaniner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
  .no-content-img {
    max-width: 387px;
    display: flex;
  }

  .no-content-text {
    &__title {
      font-size: 14px;
      line-height: normal;
      text-align: center;
      color: ${colors['TX-001-50']};
      margin-bottom: 16px;
    }
    &__text {
      font-size: 12px;
      line-height: normal;
      text-align: center;
      color: ${colors['TX-001-50']};
      max-width: 380px;
    }
  }
`;
