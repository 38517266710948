import styled from 'styled-components';

const LegalStyle = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: absolute;
  top: 0;
  z-index: 200;
  background: #001930;
  padding: 8rem 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon-close{
    top: 3rem;
    right: 4rem;
    position: absolute;
    z-index: 200;
    cursor: pointer;
    opacity: 0.7;

    &:hover{
        opacity: 1;
    }
  }

  .wrapper-legal{
    width: 60%
    max-width: 800px;
    padding: 40px;

      .title{
        font-family: Canela;
        font-weight: 500;
        font-size: 42px;
        color: #FFFFFF;
        padding: 0;
      }
    
      .text{
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            color: #BCC5D7;

        p{
            margin: 0;
            margin-top: 2rem;
        }
      }

  }

  .table-cookies{
    width: 100%;
    height: auto;
    display: block;

    background: rgba(255, 255, 255, 0.05);
    margin-top: 40px;
    padding: 20px;

    font-family: Plain;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #BCC5D7;


    table{
        width: 100%;
        text-align: left;
    
        & > * {
            border: none;
        }

        tr{
            border-bottom: 1px solid rgba(188, 197, 215, 0.3);

            td, th{
                padding: 12px 0;
            }
        }

        tr:last-child{
            border-bottom: none;
        }

        tr th {
          line-height: 32px;
            font-weight: 500;
            color: #6C778B;
        }

        tr td:first-child {
            width: 35%;
            padding-right: 32px;
            color: #fff;
            font-weight: 500;
        }

        tr td:second-child {
            width: 65%;
        }

    }

  }

`;
export default LegalStyle;
