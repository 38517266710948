import * as React from 'react';
import { images } from '../../assets/images';
import HeadDestacadoText from '../text/head-destacado-text/head-destacado-text';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import { CardActivityStyle } from './card-activity-style';
import LabelText from '../text/label-text/label';
import moment from 'moment';
import { ITimeBlock } from '../../models/generic';
import { getTimeFormatted, getDateFormatted } from '../../utils/time';
import ItemTeam from '../item-team/item-team';
import Button from '../buttons/main-button/button';
import { DiveDisciplinesKeyValues, DiveKeyValueCourseTypes, DiveKindsKeyValues, DiveCourseTypes } from '../../constants/dive-dropdowns';
import { IActivity } from '../../models/activities';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation, } from 'react-i18next';
interface IWithCard {
  price: number;
  type: string;
}
export interface ICardActivityProps extends RouteComponentProps, WithTranslation {
  withCard?: IWithCard;
  name: string;
  date: string;
  startHour: ITimeBlock;
  endHour: ITimeBlock;
  backgroundImg?: string;
  dates?: any[];
  sortDates?: boolean;
  type: string;
  discipline: string;
  activity: IActivity;
  bookings?: boolean;
  oneditPrices?: () => void;
  oneditDates?: () => void;
}

export interface ICardActivityState { }

class CardActivity extends React.Component<ICardActivityProps, ICardActivityState> {
  constructor(props: ICardActivityProps) {
    super(props);

    this.state = {};
  }
  getDates = () => {
    const { activity } = this.props;
    const ret = {
      start: "",
      end: "",
      sesions: ""
    };
    if (!activity || !activity.timeBlocks || !activity.timeBlocks.length) {
      return ret
    }
    let init: number = 0;
    let end: number = 0;
    let days = 0;
    for (const d of activity.timeBlocks) {
      const i = d.start.date ? new Date(d.start.date).getTime() : 0;
      const f = d.end.date ? new Date(d.end.date).getTime() : 0;
      if (init === 0 || i < init) {
        init = i;
        ret.start = d.start.date ? new Date(d.start.date).toLocaleDateString() : "";
      }
      if (f > end) {
        end = f;
        ret.end = d.end.date ? new Date(d.end.date).toLocaleDateString() : "";
      }

    }
    days += activity.courseInfo && activity.courseInfo.practicalSessions ? activity.courseInfo.practicalSessions.length : 0;
    days += activity.courseInfo && activity.courseInfo.theoreticSessions ? activity.courseInfo.theoreticSessions.length : 0;
    ret.sesions = days.toString();
    return ret;
  }
  render() {
    const { withCard, name, backgroundImg, dates: datesArr, sortDates, activity, t } = this.props;
    let dates: any;
    if (sortDates && datesArr && datesArr.length > 1) {
      dates = datesArr.sort((a: any, b: any) => {
        const dateA = new Date(a.start.date);
        dateA.setHours(a.start.hour);
        dateA.setMinutes(a.start.minutes);
        const dateB = new Date(b.start.date);
        dateB.setHours(b.start.hour);
        dateB.setMinutes(b.start.minutes);
        return b - a;
      });
    } else {
      dates = datesArr;
    }

    let timeInfo: any;
    if (activity.courseInfo) {
      timeInfo = this.getDates();
    }
    let courseType: any = activity.kind && activity.kind.length ? activity.kind : activity.course.kind;

    if (activity && activity.kind) {
      courseType =
        activity.kind
          .map((kind: string) => {
            return DiveKindsKeyValues[kind];
          })
          .toString()
          .replace(/,/g, ', ')
          .toLowerCase()
    } else if (!!courseType) {
      courseType = DiveKeyValueCourseTypes[activity.course.kind];
    }
    let dateStart = "";
    if (this.props.date) {
      dateStart = moment(this.props.date).format('DD/MM/YYYY') + " - " + t('components:general.start-hour');
      dateStart += " " + (this.props.startHour.hour < 9 ? "0" + this.props.startHour.hour.toString() : this.props.startHour.hour.toString());
      dateStart += ":" + (this.props.startHour.minutes < 9 ? "0" + this.props.startHour.minutes.toString() : this.props.startHour.minutes.toString());
    }

    return (
      <CardActivityStyle className={withCard && 'with-card'}>
        <div className="card-activity-back">
          <img src={backgroundImg ? backgroundImg : images.activityBack} alt="" />
        </div>
        <div className="card-activity-content">
          <div className="card-activity-content-title">
            <div className="card-activity-content-title__text">
              {this.props.type}
              <HeadDestacadoText>{name}</HeadDestacadoText>
              {DiveDisciplinesKeyValues[this.props.discipline]} {courseType && <> ●  {courseType}</>}
              {
                timeInfo && <p><small>
                  {timeInfo.start} - {timeInfo.end} ({timeInfo.sesions} {t('components:card-activity.sessions')})
                </small></p>
              }
            </div>
          </div>
        </div>
        {withCard && (
          <div className="card">
            <div className="card-activity-white">
              <img src={images.editSvg} onClick={() => { if (this.props.oneditPrices) this.props.oneditPrices() }} />
              <div className="card-activity-white__sub-price">
                <BodyDestacadoText>{t('components:buy-resume.pricesince')}</BodyDestacadoText>
              </div>
              <div className="card-activity-white__price">
                <BodyDestacadoText>{withCard.price / 100} € / {t('components:buy-resume.diver')} </BodyDestacadoText>
              </div>
              <div className="card-activity-white__sub-policy">
                <LabelText>{t('components:buy-resume.cancel-politics')}: {withCard.type} </LabelText>
              </div>
            </div>
            <div style={{ marginTop: 15 }}>
              {(dates ?
                <Button
                  text={t('components:general.planned-activity')}
                  dates={t('components:general.start-date')+': ' + dateStart}
                  type={'error'}
                  leftIcon={this.props.bookings ? images.editSvg : images.calendarWhiteSvg}
                  onClick={() => { if (this.props.oneditDates) this.props.oneditDates() }}
                />
                :
                <Button
                  text={t('components:general.planned-activity')}
                  dates={t('components:general.start-date') +': '+ dateStart}
                  type={'error'}
                  leftIcon={this.props.bookings ? images.editSvg : images.calendarWhiteSvg}
                  onClick={() => { if (this.props.oneditDates) this.props.oneditDates() }}
                />
              )}

            </div>
          </div>
        )}
      </CardActivityStyle>
    );
  }
}


export default withTranslation(['components'])(withRouter(CardActivity));