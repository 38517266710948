import React, { Component } from 'react';
import { DiverDivesContainer } from './diver-dives-style';
import DiveCard from '../../../../components/dive-card/dive-card';
import MainTable from '../../../../components/main-table/main-table';
import Pagination from '../../../../components/pagination';
import { tableColumns, rowKeys, DiverDive, DiverDiveStats } from './types';
import { TableRow, TableCell } from '../../../../types/table';
import { DiverDivesFunctionalProps } from './diver-dives-functional';
import moment from 'moment';
import 'moment/locale/es.js';
import { images } from '../../../../assets/images';
import ModalRegistreDive from '../../../../components/modals/modal-registre-dive/modal-registre-dive';
import { IDiveForm } from '../../../../models/dive-forms';
import { AttachedFile } from '../../../../types/file';
import { DiveTypes } from '../../../../constants/dive';
import { DiveKindsKeyValues } from '../../../../constants/dive-dropdowns';
import ModalInfo from '../../../../components/modals/modal-info/modal-info';

interface DiverDivesProps extends DiverDivesFunctionalProps {
  renderModal: string;
  closeModal: () => void;
  stats: DiverDiveStats;
  items: DiverDive[];
  totalPages: number;
  setPage: (page: number) => void;
  validateDives: (diveId?: string | number) => void;
  deleteDives: (diveId?: string | number) => void;
  rowsSelected: any[];
  onSelectRows: (rows: any[], all: boolean, allValue?: boolean) => void;
  onCreateDive: (dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) => void;
  onRenderModal: (rendeModal: string) => void;
  onValidateSingle: () => void;
  validateMultiple: boolean;
  page: number
}
interface DiverDivesState {
  renderModal: string;
  rowClicked: boolean;
  clickedDiveId: string;
}

export default class DiverDivesLayout extends Component<DiverDivesProps, DiverDivesState> {
  state: DiverDivesState = {
    renderModal: '',
    rowClicked: false,
    clickedDiveId: '',
  };

  componentDidUpdate(prevProps: DiverDivesProps) {
    const { renderModal } = this.props;
    if (prevProps.renderModal !== renderModal) {
      this.setState({ renderModal });
    }
  }

  formatDataToTableRows(data: { [key: string]: any }[]) {
    const {
      match: {
        params: { id, diver },
      },
      rowsSelected,
      onRenderModal,
      onValidateSingle,
      isOrganization,
    } = this.props;
    return data.map(item => {
      const row: TableRow = {};
      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };
        switch (key) {
          case 'date':
            cell.value = moment(item[key], 'YYYY-MM-DD').format('DD/MM/YYYY');
            cell.key = 'highlighted-body';
            break;
          case 'activity':
            cell.value = item[key] || '-';
            cell.key = 'highlighted-body';
            break;
          case 'type':
            cell.value = DiveTypes[item[key]];
            cell.key = 'type';
            break;
          case 'kind':
            cell.key = 'body';
            cell.value = '';
            item[key].forEach((kind: string) => {
              cell.value += `, ${DiveKindsKeyValues[kind]}`;
            });
            cell.value = cell.value.substring(1);
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });

      row.check = {
        value: rowsSelected.filter(rowSelected => rowSelected.id === item.id).length > 0,
        type: '',
        key: 'check',
      };

      // const validatedBy =
      //   item.validated && item.validated.user ? `${item.validated.user.name} ${item.validated.user.surname}` : '-';

      // row.validate = {
      //   value: validatedBy,
      //   type: '',
      //   key: validatedBy,
      // };

      // row.status = {
      //   value: true,
      //   type: 'icon',
      //   key: item.validated ? 'success' : 'reject',
      // };

      row.detail = {
        value: '',
        type: '',
        key: 'options',
        actions: [
          {
            label: 'Ver',
            icon: images.eyeSvg,
            onClick: () => {
              if (!!diver) this.props.history.push(`/centres/${id}/divers/${diver}/dives/${item.id}`);
              else this.props.history.push(`/users/detail/${id}/dives/${item.id}`);
            },
          },
          {
            label: 'Validar',
            icon: images.checkCircleSvg,
            onClick: () => {
              onRenderModal('modal-validate-dive');
              onValidateSingle();
              this.setState({
                rowClicked: true,
                clickedDiveId: item.id,
                renderModal: 'modal-validate-dive',
              });
            },
          },
        ],
      };
      if (item.validated) {
        row.detail.actions = [
          {
            label: 'Vereeee',
            icon: images.eyeSvg,
            onClick: () => {
              if (!!diver) this.props.history.push(`/centres/${id}/divers/${diver}/dives/${item.id}`);
              else this.props.history.push(`/users/detail/${id}/dives/${item.id}`);
            },
          },
        ];
      }

      if (!diver && row.detail.actions) {
        row.detail.actions.push({
          label: 'Eliminar',
          icon: images.delete2Svg,
          onClick: () => {
            onRenderModal('modal-delete-dive');
            onValidateSingle();
            this.setState({
              rowClicked: true,
              clickedDiveId: item.id,
              renderModal: 'modal-delete-dive',
            });
          },
        });
      }

      if (isOrganization) {
        row.detail = {
          value: '',
          type: '',
          key: 'detail',
          link: `/users/${id}/dives/${item.id}`,
        };
      }
      return row;
    });
  }

  render() {
    const {
      closeModal,
      stats,
      items,
      totalPages,
      setPage,
      validateDives,
      deleteDives,
      rowsSelected,
      onSelectRows,
      onCreateDive,
      validateMultiple,
      page
    } = this.props;
    const { rowClicked, clickedDiveId, renderModal } = this.state;
    return (
      <>
        <DiverDivesContainer>
          <div className="top-card-container">
            <div className="top-card-container__item">
              <DiveCard type="red" title={`${stats.diveCount}`} text="Inmersiones" subtext="realizadas en total" />
            </div>
            <div className="top-card-container__item">
              <DiveCard
                type="turquoise"
                title={`${stats.diveNotValidatedCount}`}
                text="Inmersiones"
                subtext="REALIZADAS como staff"
              />
            </div>
            <div className="top-card-container__item">
              <DiveCard
                type="blue"
                title={`${stats.diveNotValidatedCount}`}
                text="Inmersiones"
                subtext="pendientes de validar"
              />
            </div>
            <div className="top-card-container__item">
              <DiveCard
                type="yellow"
                title={stats.lastDiveDate ? moment(stats.lastDiveDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
                text="Fecha"
                subtext="Última inmersión"
              />
            </div>
          </div>
          <div className="my-divers-dive-table">
            <MainTable
              columns={tableColumns}
              rows={this.formatDataToTableRows(items)}
              rowKeys={rowKeys}
              rowsSelected={rowsSelected}
              onSelect={(rows, all, allValue) => onSelectRows(rows, all, allValue)}
              onOrder={() => {}}
            />
          </div>
          {totalPages > 1 && <Pagination page={page} limit={totalPages} callback={page => setPage(page)} />}
        </DiverDivesContainer>

        {/* Modal Confirmacion */}
        <ModalInfo
          type="blue"
          icon={images.checkCircleBlueSvg}
          title={[
            <span key="modal-validate-dive">
              {!validateMultiple || rowsSelected.length < 2
                ? 'Validar inmersion'
                : `Validar ${rowsSelected.length} inmersiones`}
            </span>,
          ]}
          text={[
            'Una vez validada la inmersión del buceador, esta aparecerá reflejada en el perfil del buceador y le permitirá tener acceso a determinados servicios dentro de la aplicación.',
          ]}
          showModal={renderModal === 'modal-validate-dive'}
          onClose={() => {
            this.setState({ clickedDiveId: '' });
            closeModal();
          }}
          buttons={[
            {
              text: 'Validar',
              type: 'primary',
              onClick: () => {
                validateDives(clickedDiveId);
              },
            },
            { text: 'Eliminar', type: 'secondary-red', onClick: () => deleteDives(clickedDiveId) },
          ]}
        />

        <ModalInfo
          type="red"
          icon={images.closeCircleRedSvg}
          title={[
            <span key="modal-delete-dive">
              {!validateMultiple || rowsSelected.length < 2
                ? 'Eliminar inmersión'
                : `Eliminar ${rowsSelected.length} inmersiones`}
            </span>,
          ]}
          text={[
            !validateMultiple || rowsSelected.length < 2
              ? 'Una vez eliminada la inmersión, no se podrá recupear.'
              : 'Una vez eliminadas las inmersiones, no se podrán recupear.',
          ]}
          showModal={renderModal === 'modal-delete-dive'}
          onClose={() => {
            this.setState({ clickedDiveId: '' });
            closeModal();
          }}
          buttons={[
            {
              text: 'Eliminar',
              type: 'secondary-red',
              onClick: () => deleteDives(clickedDiveId),
            },
            { text: 'Cancelar', type: 'secondary-red', onClick: () => closeModal() },
          ]}
        />

        {/* Modal Añadir Inmersión */}
        {renderModal === 'modal-registre-dive' && (
          <ModalRegistreDive
            onSubmit={(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) =>
              onCreateDive(dive, mainPicture, pictures)
            }
            showModal={renderModal === 'modal-registre-dive'}
            onClose={() => {
              this.setState({ clickedDiveId: '' });
              closeModal();
            }}
          />
        )}
      </>
    );
  }
}
