import React from 'react';
import { ModalConfirmationActivityContainer } from './modal-confirmation-activity-style';
import { images } from '../../../assets/images';
import MainButton from '../../main-button';
import ModalContainer from '../modal-container';
import CalendarBox from '../../calendar-box';
import InputBox from '../../input-box';
import moment from 'moment';
import 'moment/locale/es.js';
import DicotomicCheckbox from '../../dicotomic-checkbox';
import InfoText from '../../text/info-text/info-text';
import SingleButton from '../../single-button/single-button';
import { IActivity } from '../../../models/activities';
import { patchCentreActivity, postCentreActivityConfirm } from '../../../services/centre';
import SelectBox from '../../select-box';
import { copyOf, mergeObject } from '../../../utils/other';
import { t } from 'i18next';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

const BLOCK_TYPE_DROPDOWN = [
  { value: 'theoreticPhase', label: 'Fase teórica' },
  { value: 'practicalPhase', label: 'Fase práctica' },
];

interface ModalProps extends RouteComponentProps, WithTranslation {
  centreId: string;
  activity?: IActivity;
  className?: String;
  showModal: boolean;
  t:any;
  onAccept: () => void;
  onCancel: () => void;
}

interface ICourseBlock {
  start: string;
  finish: string;
}

interface ModalState {
  step: number;
  selectedType: string;
  blockNumber: number;
  sendEmail: boolean;
  date?: string;
  start?: string;
  end?: string;
  courseInfo: {
    theoreticPhase: {
      blocks: ICourseBlock[];
    };
    practicalPhase: {
      blocks: ICourseBlock[];
    };
  };
  errors: {
    date: string;
    start: string;
    end: string;
  };
}

class ModalActivityConfirmationCourse extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      step: 0,
      blockNumber: 0,
      selectedType: 'theoreticPhase',
      sendEmail: false,
      start: '',
      end: '',
      courseInfo: {
        theoreticPhase: {
          blocks: [],
        },
        practicalPhase: {
          blocks: [],
        },
      },
      errors: {
        date: '',
        start: '',
        end: '',
      },
    };
  }

  handleChangeHour(isStart: boolean, value: string) {
    if (isStart) this.setState({ start: value });
    else this.setState({ end: value });
  }

  handleAddBlock() {
    const { start, end, date, courseInfo, selectedType, blockNumber } = this.state;
    const errors = {
      date: '',
      start: '',
      end: '',
    };
    if (!start || start === '') errors.start = 'required';
    if (!end || end === '') errors.end = 'required';
    if (!date || date === '') errors.date = 'required';
    const now = moment();
    const dateMoment = moment(date, 'DD/MM/YYYY');
    if (start && end && date) {
      const startArray = start.split(':');
      const endArray = end.split(':');
      if (startArray.length < 2) {
        errors.start = 'invalid-format';
      }
      if (endArray.length < 2) {
        errors.end = 'invalid-format';
      }
      if (dateMoment.isBefore(now)) {
        errors.date = 'invalid-date-future';
      }
      if (startArray.length === 2 && endArray.length === 2) {
        if (startArray[0] > endArray[0] || (startArray[0] === endArray[0] && startArray[0] >= endArray[0])) {
          errors.start = 'invalid-range';
          errors.end = 'invalid-range';
        }
      }
      if (Object.values(errors).every(err => err === '')) {
        const startDate = moment(dateMoment)
          .hour(parseInt(startArray[0], undefined))
          .seconds(parseInt(startArray[0], undefined));
        const endDate = moment(dateMoment)
          .hour(parseInt(endArray[0], undefined))
          .seconds(parseInt(endArray[0], undefined));
        const newCourseInfo = copyOf(courseInfo);
        newCourseInfo[selectedType].blocks.push({
          start: startDate,
          finish: endDate,
        });
        this.setState({
          errors,
          courseInfo: newCourseInfo,
          blockNumber: blockNumber + 1,
        });
        return;
      }
    }
    this.setState({ errors });
  }

  handlePrevStep() {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  async handleSubmit() {
    const { onAccept, activity, centreId } = this.props;
    const { sendEmail, courseInfo } = this.state;
    if (activity && activity.courseInfo) {
      const dataConfirm: any = {
        sendEmail,
      };
      const data: any = {
        courseInfo: mergeObject(courseInfo, activity.courseInfo),
      };
      await patchCentreActivity(centreId, activity.id.toString(), data);
      await postCentreActivityConfirm(centreId, activity.id.toString(), dataConfirm);
      onAccept();
    }
  }

  renderFirstStep() {
    const { errors, step, date, start, end, courseInfo, blockNumber, selectedType } = this.state;
    const { activity, t } = this.props;
    return (
      <>
        <div className="modal-confirmation-activity-name">
          <p>{activity ? activity.name : ''}</p>
        </div>
        <div className="modal-confirmation-activity-title">
          <p>{t('modalMessages:modal-confirmation-activity.confirm-activity-periodical')}</p>
        </div>
        {activity && activity.courseInfo && (
          <div className="modal-confirmation-activity-subtitle">
            <p>{t('modalMessages:modal-confirmation-activity.choose-date-hours-block')}</p>
          </div>
        )}
        <div className="modal-confirmation-activity-inmersion-number">
          <p>{`Bloque ${blockNumber + 1}`}</p>
        </div>
        <div className="modal-confirmation-activity">
          <div className="modal-confirmation-activity-time-left">
            <SelectBox
              className="rounded"
              optionsText={BLOCK_TYPE_DROPDOWN}
              optionKey={'value'}
              optionValue={'label'}
              initialValue={selectedType}
              defaultValue={selectedType}
              labelText=""
              onChange={value => this.setState({ selectedType: value })}
            />
          </div>
          <div className="modal-confirmation-activity-time-right">
            <CalendarBox
              placeholder={t('components:general.date')}
              className="rounded"
              labelText=""
              errorCode={errors.date}
              initialValue={date || ''}
              onChange={value => this.setState({ date: value })}
            />
          </div>
        </div>
        <div className="modal-confirmation-activity">
          <div className="modal-confirmation-activity-time-left">
            <InputBox
              placeholder={t('components:general.start-hour')}
              className="rounded"
              type="text"
              value={start || ''}
              labelText={t('components:general.user')}
              errorCode={errors.start}
              onChange={value => this.handleChangeHour(true, value)}
              cleaveOptions={{
                time: true,
                timePattern: ['h', 'm'],
              }}
            />
          </div>
          <div className="modal-confirmation-activity-time-right">
            <InputBox
              placeholder={t('components:general.end-hour')}
              className="rounded"
              type="text"
              value={end || ''}
              labelText={t('components:general.user')}
              errorCode={errors.end}
              onChange={value => this.handleChangeHour(false, value)}
              cleaveOptions={{
                time: true,
                timePattern: ['h', 'm'],
              }}
            />
          </div>
        </div>
        {blockNumber > 0 && courseInfo.theoreticPhase.blocks.length > 0 && (
          <>
            <div>Fase teorica</div>
            <div>
              {courseInfo.theoreticPhase.blocks.map((block: ICourseBlock, index: number) => (
                <div key={index}>
                  <div>{t('components:general.start')`: ${moment(block.start).format('DD/MM/YYYY hh:mm')}`}</div>
                  <div>{t('components:general.end')`: ${moment(block.finish).format('DD/MM/YYYY hh:mm')}`}</div>
                </div>
              ))}
            </div>
          </>
        )}
        {blockNumber > 0 && courseInfo.practicalPhase.blocks.length > 0 && (
          <>
            <div>Fase práctica</div>
            <div>
              {courseInfo.practicalPhase.blocks.map((block: ICourseBlock, index: number) => (
                <div key={index}>
                  <div>{t('components:general.start')`: ${moment(block.start).format('DD/MM/YYYY hh:mm')}`}</div>
                  <div>{t('components:general.end')`: ${moment(block.finish).format('DD/MM/YYYY hh:mm')}`}</div>
                </div>
              ))}
            </div>
          </>
        )}
        <div className="modal-confirmation-activity-button-add">
          <MainButton iconImg={images.plusSvg} text={t('components:actions.add')} type="secondary" onClick={() => this.handleAddBlock()} />
        </div>
        <div className="modal-confirmation-activity-button-next">
          <MainButton text={t('components:actions.next')} type="primary" onClick={() => this.setState({ step: step + 1 })} />
        </div>
      </>
    );
  }

  renderSecondStep() {
    const { activity, t } = this.props;
    const { sendEmail } = this.state;
    return (
      <>
        <div className="modal-confirmation-activity-name">
          <p>{activity ? activity.name : ''}</p>
        </div>
        <div className="modal-confirmation-activity-title">
          <p>{t('modalMessages:modal-confirmation-activity.confirm-activity')}</p>
        </div>
        <div className="modal-confirmation-activity-paragraph">
          <p>
          {t('modalMessages:modal-confirmation-activity.confirm-advice')}
          </p>
        </div>
        <div className="modal-confirmation-activity-paragraph">
          <p>{t('modalMessages:modal-confirmation-activity.confirm-advice2')}</p>
        </div>
        <DicotomicCheckbox
          checked={sendEmail}
          onCheck={checked => {
            this.setState({ sendEmail: !sendEmail });
          }}
        >
          <div className="create-activities-dicotomic-container">
            <div className="create-activities-dicotomic-container__text">
              <InfoText>
                {t('components:content-block.notify')}
              </InfoText>
            </div>
          </div>
        </DicotomicCheckbox>
        <div className="modal-confirmation-activity-buttons-container">
          <div className="modal-confirmation-activity-button-back">
            <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => this.handlePrevStep()} />
          </div>
          <div className="modal-confirmation-activity-button-next">
            <MainButton text={t('modalMessages:confirm')} type="primary" onClick={() => this.handleSubmit()} />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { className, showModal, onCancel, activity, t } = this.props;
    const { step } = this.state;
    return (
      <ModalContainer className="center" modalClose={() => onCancel()} active={showModal}>
        <ModalConfirmationActivityContainer className={className}>
          {activity && step < 1 ? this.renderFirstStep() : this.renderSecondStep()}
        </ModalConfirmationActivityContainer>
      </ModalContainer>
    );
  }
}


export default withTranslation(['modalMessages','components'])(withRouter(ModalActivityConfirmationCourse));