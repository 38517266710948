/**
 <DicotomicCheckbox
    name=""
    className="mandatory"
    checkboxText="Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, expedita!"
/>
*/

import React, { Component, Children } from 'react';
import { DicotomicCheckboxContainer } from './dicotomic-checkbox-style';
import { images } from '../../assets/images';
import InfoText from '../text/info-text/info-text';

interface DicotomicCheckboxProps {
  name?: string;
  disabled?: boolean;
  showError?: boolean;
  errorText?: string;
  className?: string;
  checked?: boolean;
  onCheck: (checked: boolean) => void;
  children?: any;
}

interface DicotomicCheckboxState {
  checked: boolean;
}

class DicotomicCheckbox extends Component<DicotomicCheckboxProps, DicotomicCheckboxState> {
  state: DicotomicCheckboxState;

  constructor(props: DicotomicCheckboxProps) {
    super(props);
    this.state = {
      checked: !!props.checked
    }
  }

  componentDidUpdate() {
    const { checked } = this.state;
    const { checked: filledValue } = this.props;

    if (filledValue !== undefined && filledValue !== checked) {
      this.setState({ checked: filledValue })
    }
  }

  onChange = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked }, () => {
      this.props.onCheck(!checked);
    });
  }

  render() {
    const { name, disabled, showError, children, errorText, className } = this.props;
    const { checked } = this.state;
    return (
      <DicotomicCheckboxContainer className={`${className} ${showError ? 'error' : ''} ${checked ? 'active' : ''}`}>
        <label className="checkbox-container">
          <input
            name={name}
            disabled={disabled}
            checked={checked}
            type="checkbox"
            onChange={this.onChange}
          />
          <div className="checkmark-container">
            <div className="checkmark-item">
              {checked &&
                <img src={images.checkSvg} alt="Aceptar" />}
            </div>
          </div>
          {children}
        </label>
        <div className="checkbox-error">{showError && <InfoText>{errorText}</InfoText>}</div>
      </DicotomicCheckboxContainer>
    );
  }
}

export default DicotomicCheckbox;
