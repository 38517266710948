import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { BodyDestacadoTextContainer } from '../text/body-destacado-text/body-destacado-text-styles';

export const MainButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors['NE-004-0']};
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  /* transition: 0.3s all ease-in-out; */
  height: 40px;
  width: 100%;
  background: ${colors['PR-001-100']};
  font-weight: 500;
  font-size: 13px;
  color: ${colors['NE-004-0']};
  padding: 0 12px;

  .main-button-text {
    &.full {
      flex: auto;
      text-align: left;
    }
  }
  .main-button-icon {
    margin-right: 20px;
    display: flex;
    align-items: center;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;

    &.left {
      margin-right: 8px;
    }
    &.right {
      margin-left: 8px;
    }
    img {
      /* max-width: 18px; */
    }
  }

  &.primary {
    background: ${colors['PR-001/100']};
    color: white;
    &:hover {
      background: ${colors['PR-001/75']};
    }
  }
  &.primary-red {
    background: ${colors['PR-001/100']};
    color: ${colors['CO-003/100']};
    &:hover {
      background: ${colors['PR-001/75']};
    }
  }
  &.dark-blue {
    background: ${colors['SE-002/100']};
    color: white;
    /* height: 48px; */
    &:hover {
      background: ${colors['SE-002/100']};
    }
  }
  &.secondary {
    background: ${colors['NE-002/10']};
    color: ${colors['NE-001/100']};
  }
  &.secondary-opacity {
    background: ${colors['NE-002/10']};
    color: ${colors['NE-002/100']};
  }
  &.secondary-red {
    background: ${colors['NE-002/10']};
    color: ${colors['CO-003/100']};
  }
  &.secondary-border-red {
    background: transparent;
    color: #ed441e;
    border: 1px solid;
  }
  &.secondary-active {
    background: ${colors['NE-002/10']};
    color: ${colors['PR-001/100']};
  }
  &.error {
    background: ${colors['CO-003/100']};
    color: white;
    &:hover {
      background: ${colors['CO-003/75']};
    }
  }
  &.success {
    background: ${colors['CO-001/100']};
    color: white;
    &:hover {
      background: ${colors['CO-001/75']};
    }
  }
  &.disabled {
    opacity: 0.5;
    background: #f8fafd;
    pointer-events: none;
    color: ${colors['NE-003/75']};
  }
  &.full-height {
    height: 48px;
  }
  &.section-menu {
    .main-button-icon {
    }
  }
  &.simple-menu  {
    color: white;
    opacity: 0.8;
    padding-right: 6px;
    font-weight: 300;
    .main-button-icon {
      width: 18px;
      height: 18px;
      img {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }
    }
    ${BodyDestacadoTextContainer} {
      font-size: 13x;
      font-weight: 300;
      line-height: 22px;
    }
    &.logout {
      padding: 0;
    }

    &.avatar {
      padding: 0;
      .main-button-icon {
        &.left {
          border-radius: 100%;
          overflow: hidden;
          width: 18px;
          height: 18px;
          img {
            width: 100%;
            height: auto;
            min-height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &:hover {
      opacity: 1;
    }
    &.active {
      opacity: 1;
      background: rgb(0, 161, 165, 0.3);
    }
  }

  &.light-blue {
    color: ${colors['PR-001/100']};
  }
  &.map-fav {
    background: #fff1f0;
    border-radius: 5px;
    color: #ff7368;
  }

  &.pending {
    color: ${colors['CO-004/100']};
  }
`;
