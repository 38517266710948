import React from 'react';
import { ModalContainerStyle } from './modal-container-style';
import { images } from '../../assets/images/index';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';

interface ModalContainerProps {
  children: any;
  title?: string;
  subtitle?: string;
  invertClose?: boolean;
  modalClose: Function;
  active: boolean;
  className?: string;
  closeVeil?: boolean;
}
interface ModalContainerState {}
export default class ModalContainer extends React.Component<ModalContainerProps, ModalContainerState> {
  componentDidMount() {
    if (this.props.active) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }

  componentDidUpdate(prevProps: ModalContainerProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'scroll';
      }
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'scroll';
  }

  render() {
    const { children, title, subtitle, invertClose, active, modalClose, className, closeVeil } = this.props;
    return (
      <ModalContainerStyle className={`${className} ${active ? 'active' : ''}`}>
        <div className="modal-container-veil" onClick={() => closeVeil && modalClose()} />
        <div className="modal-container-content">
          {title ? (
            <div className="modal-container-header">
              <div className="modal-container-header__title">
                <SubheadDestacadoText>{title}</SubheadDestacadoText>
              </div>
              <div className="modal-container-header__close" onClick={() => modalClose()}>
                <img src={images.closeSvg} alt="" />
              </div>
            </div>
          ) : (
            <div className="modal-container-close">
              <div className="modal-container-close__button" onClick={() => modalClose()}>
                {/* <img src={invertClose ? images.closeWhiteSvg : images.closeSvg} alt="" /> */}
                <img src={invertClose ? images.closeCrossWhiteSvg : images.closeSvg} alt="" />
              </div>
            </div>
          )}
          {children}
        </div>
      </ModalContainerStyle>
    );
  }
}
