import styled from 'styled-components';
import { images } from '../../assets/images';

export const IntroContainer = styled.div`

  .bgImagePanel{
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    background-image: url(${images.introPanel});
    background-size: cover;
  }
  .text{
    background:#ffffff;
    color:#ff000;
    display: table;
    margin: 50px auto;
    padding:20px 30px;
    border-radius:5px;
    text-align:center;
  }

  }
`;
