import services from './services';

export const getUsers = async (params: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'users',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};
export const getUser = async (userId:string) =>{
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      endpoint: `users/${userId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
}
export const getOneUsers = (userId: string, params?: any) => {
  const endpoint = `users/${userId}`;
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadUsersFormData = (userId: string, data: FormData) => {
  const endpoint = `users/${userId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const deletePicture = (userId: string) => {
  const endpoint = `users/${userId}/picture`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const uploadUsers = (userId: string, data: any) => {
  const endpoint = `users/${userId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteUsers = (userId: string) => {
  const endpoint = `users/${userId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createUsers = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'users',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
export const changePassword = (userId: string, data: any) => {
  const endpoint = `users/${userId}/password`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};




export const confirmUser = (params?: any) => {
  const endpoint = `users/confirm?jwt=${params}&isWeb=true`;
  return new Promise<any>((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};
