import * as React from 'react';
import { CardMapMarineStyle } from './card-map-marine-style';
import { images } from '../../assets/images/index';
import 'slick-carousel/slick/slick.css';
import LabelText from '../text/label-text/label';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import Label02Text from '../text/label-02-text/label-02';
import { NavLink } from 'react-router-dom';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
interface Type {
  icon: string;
  text: string;
}
export interface ICardMapReserveProps extends WithTranslation {
  lng: number;
  lat: number;
  title: string;
  location: string;
  total: string;
  link: string;
  key: any;
  onClickCard?: () => void;
}

export interface ICardMapReserveState {
  active: boolean;
}

class CardMapReserve extends React.Component<ICardMapReserveProps, ICardMapReserveState> {
  slider: any = null;
  constructor(props: ICardMapReserveProps) {
    super(props);

    this.state = {
      active: false,
    };
  }
  componentDidMount() {
    const ul = document.getElementsByClassName('slick-dots') as HTMLCollectionOf<HTMLElement>;
    if (ul && ul.length) {
      // tslint:disable-next-line:no-increment-decrement
      for (let index = 0; index < ul.length; index++) {
        ul[index].style.display = 'flex';
      }
    }
  }

  public render() {
    const { lng, lat, title, location, total, key, link, t, onClickCard } = this.props;
    const { active } = this.state;
    return (
      <CardMapMarineStyle lat={lat} lng={lng} key={key}>
        <div className="card-map-marker">
          <div className={`card-map-veil ${active && 'active'}`} onClick={() => this.setState({ active: false })} />
          <div
            className="card-map-marker__icon"
            onClick={() => {
              if (onClickCard) onClickCard();
              this.setState({ active: true });
            }}
          >
            <img src={images.diveSiteMarineReserve} alt="" />
          </div>
          <NavLink to={link}>
            <div className={`card-map-pop ${active && 'active'}`}>
              <div className="card-map-pop-container">
                <div className="card-map-pop-container__type">
                  <div className="card-map-pop-container__type__icon">
                    <img src={images.seaSvg} alt="" />
                  </div>
                  <div className="card-map-pop-container__type__text">
                    <LabelText>{t('components:general.marine_reserves')}</LabelText>
                  </div>
                </div>
                <div className="card-map-pop-container__title">
                  <BodyDestacadoText>{title}</BodyDestacadoText>
                </div>
                <div className="card-map-pop-container__location">
                  <p>{location}</p>
                </div>

                {/* <div className="card-map-pop-container__rate">
                <StarRate number={rate} />
              </div> */}
                <div className="card-map-pop-container__next">
                  <Label02Text>
                    {t('components:card-map-marine')} {total}
                  </Label02Text>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      </CardMapMarineStyle>
    );
  }
}

export default withTranslation(['intro', 'buttons', 'components'])(CardMapReserve);
