import * as React from 'react';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import Pagination from '../../../../components/pagination';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';

import { BillsSuperAdminLayoutStyle } from './bills-superadmin-layout-style';
import HeaderMain from '../../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer, MainLayoutWithHeaderSearch } from '../../../main-layout-style';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import SelectBox from '../../../../components/select-box';
import { images } from '../../../../assets/images';
import { getCharges } from '../../../../services/charges';
import { capitalize } from '../../../../utils/texts';
import FormatUtils from '../../../../utils/formatter';
import CalendarBox from '../../../../components/calendar-box';
import Select from '../../../../components/form-components/input/select';
import ButtonDropdown from '../../../../components/buttons/button-dropdown/button-dropdown';
import MiniModalCheckbox from '../../../../components/modals/mini/checkbox/mini-modal-checkbox';
import SingleButton from '../../../../components/single-button/single-button';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import OopsContent from '../../../../components/oops-content/oops-content';
import { MainTableV2 } from '../../../../components/main-table-v2/main-table-layout-v2';
import ModalInfo from '../../../../components/modals/modal-info/modal-info';
import services from '../../../../services/services';
import HeaderDropdown from '../../../../components/dropdowns/header-dropdown/header-dropdown';
import { STATUS_OPTIONS, getBillTableColumns } from '../../data/superadmin-bills-data';
import ModalSuperAdminCreateBill from './modal-superadmin-create-bill/modal-superadmin-create-bill';
import ModalSuccess from '../../../../components/modals/modal-success/modal-success';
import { postBill, getBills } from '../../../../services/bills';
import { getDayMonthNumberYear } from '../../../../utils/time';
import { IFilter } from '../../../../models/filter';
import { updateObject } from '../../../../utils/utility';
import { createFilterRequest } from '../../../../utils/other';
import _ from 'lodash';

const PAGINATION_LIMIT = 10;

export interface IBillsSuperAdminLayoutProps extends RouteComponentProps, WithTranslation {}

export interface IBillsSuperAdminLayoutState {
  modal: string;
  filters: { [key: string]: IFilter };
  searchedText: any;
  noResult: any;
  preResult: any[];
  bills: any;
  pages: number;
  page: number;
  count: number;
  downloadUrl: string | null;
  firstLoad: boolean;
}

class BillsSuperAdminLayout extends React.Component<IBillsSuperAdminLayoutProps, IBillsSuperAdminLayoutState> {
  constructor(props: IBillsSuperAdminLayoutProps) {
    super(props);

    this.state = {
      modal: '',
      filters: {},
      searchedText: null,
      noResult: null,
      preResult: [],
      bills: null,
      pages: 1,
      page: 1,
      count: 0,
      downloadUrl: null,
      firstLoad: false,
    };
  }

  componentDidMount() {
    this.fetchBills();
  }

  statusOptions = STATUS_OPTIONS(this.props.t);

  billsMap = (bill: any) => ({
    billNumber: bill.billId || '',
    concept: bill.concept,
    amount: `${bill.total} €`,
    id: bill.id,
    pdfUrl: bill.pdfUrl,
    centre: bill.centre ? bill.centre.name : this.props.t('layouts:center-deleted'),
    createdAt: getDayMonthNumberYear(bill.createdAt),
    paymentStatus: this.props.t(`layouts:bills.payment-status.${bill.paymentStatus}`) || '',
    centreId: bill.centre ? bill.centre.centreId : '-',
    status: (
      <img
        src={
          bill.status === 'completed'
            ? images.stateSuccess
            : bill.status === 'pending'
            ? images.statePending
            : images.stateReject
        }
        className="main-layout-status-icon"
        alt="status icon"
      />
    ),
  });

  fetchBills = async (newFilters: any = {}) => {
    const params = {
      filter: {
        skip: (this.state.page - 1) * PAGINATION_LIMIT,
        limit: PAGINATION_LIMIT,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        relations: ['centre'],
        ...newFilters,
      },
    };
    const { data } = await getBills(params);
    const bills = data.data.map(this.billsMap);
    this.setState((prevState: IBillsSuperAdminLayoutState) => ({
      bills,
      pages: Math.ceil(data.count / PAGINATION_LIMIT),
      count: data.count,
      firstLoad: !prevState.bills,
    }));
  };

  fetchPreResult = async () => {
    const params = {
      filter: {
        // limit: 3,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        relations: ['centre'],
        where: createFilterRequest(this.state.filters),
      },
    };
    const { data } = await getBills(params, false);
    const centreList = data.data
      .reduce((ac: any[], cv: any) => {
        const centreIncluded = !!ac.find((bill: any) => bill.centre.id === cv.centre.id);
        return centreIncluded ? ac : [...ac, cv];
      }, [])
      .slice(0, 3)
      .map((bill: any) => (
        <div
          key={bill.id}
          onClick={() => {
            this.handleSetFilter('name', bill.centre.name, 'centre', undefined, this.onPressEnterHandler);
          }}
        >
          {bill.centre.name}
        </div>
      ));
    this.setState(prevState => ({
      preResult: prevState.filters.name && prevState.filters.name.value === prevState.searchedText ? [] : centreList,
    }));
  };

  modalHandler = (value: string) => {
    this.setState({ modal: value });
  };

  handleSetFilter(key: string, value: any, collection: string = 'model', method?: string, callback?: Function) {
    const { filters } = this.state;
    const newFilters = { ...filters };
    if (!newFilters[key]) newFilters[key] = { value: '' };
    newFilters[key] = updateObject(newFilters[key], { value, collection, ...(method && { method }) });
    this.setState({ filters: newFilters }, () => {
      callback && callback();
    });
  }

  handleRemoveFilter(key: string, callback?: Function) {
    const { filters } = this.state;
    const newFilters = { ...filters };
    delete newFilters[key];
    this.setState({ filters: newFilters }, () => {
      callback && callback();
    });
  }

  downloadBill = () => {
    if (this.state.downloadUrl && this.state.downloadUrl !== '') {
      this.setState({ modal: '' }, () => {
        window.open(this.state.downloadUrl || '', '_blank');
      });
    } else {
      this.setState({ modal: '' }, () => {
        services.pushNotification({
          title: this.props.t('push:error-download-bill.title'),
          text: this.props.t('push:error-download-bill.text'),
          type: 'red-warning',
        });
      });
    }
  };

  onDetailHandler = (billId: number) => {
    this.props.history.push(`${this.props.location.pathname}/${billId}`);
  };

  onDownloadHandler = (downloadUrl: string) => {
    this.setState({ downloadUrl, modal: 'modal-download' });
  };

  onPressEnterHandler = () => {
    this.fetchBills({ where: createFilterRequest(this.state.filters) });
    this.setState(prevState => ({
      preResult: [],
      searchedText: prevState.filters.name ? prevState.filters.name.value : '',
    }));
  };

  searchFilterHandler = (value: string | null) => {
    if (value && value !== '') {
      if (value.length >= 4) {
        this.handleSetFilter('name', value, 'centre', undefined, this.fetchPreResult);
      } else {
        this.handleSetFilter('name', value, 'centre');
      }
    } else this.handleRemoveFilter('name');
  };

  dateChangeHandler = (dateFrom: string, error?: any, dateTo?: string) => {
    const callback = () => {
      this.fetchBills({ where: createFilterRequest(this.state.filters) });
    };
    if (dateFrom && dateTo && dateFrom !== '' && dateTo !== '') {
      this.handleSetFilter('createdAt', `${dateFrom}-${dateTo}`, undefined, 'between', callback);
    } else this.handleRemoveFilter('createdAt', callback);
  };

  closeSearchHandler = () => {
    const callback = this.state.searchedText
      ? () => {
          this.fetchBills({ where: createFilterRequest(this.state.filters) });
        }
      : undefined;
    this.handleRemoveFilter('name', callback);
    this.setState({ preResult: [], searchedText: null });
  };

  createBillHandler = async (centreId: string, data: any) => {
    const { data: billCreated } = await postBill(centreId, data);
    if (this.state.page === 1) {
      const newBills = [this.billsMap(billCreated), ...this.state.bills];
      if (newBills.length >= PAGINATION_LIMIT) {
        newBills.pop();
      }
      this.setState((prevState: IBillsSuperAdminLayoutState) => ({
        modal: 'modal-success',
        bills: newBills,
        count: prevState.count + 1,
        pages: Math.ceil((prevState.count + 1) / PAGINATION_LIMIT),
      }));
    } else {
      this.setState({ page: 1 }, this.fetchBills);
    }
  };

  render() {
    const { t } = this.props;
    const { pages, bills, noResult, searchedText, modal, preResult, page, filters, firstLoad, count } = this.state;

    let resultMessage;
    if (searchedText) {
      resultMessage = (
        <div className="main-layout-title__result-message">
          {bills && bills.length > 0
            ? t('layouts:bills.result-message', { searched: searchedText, length: bills.length })
            : t('layouts:bills.result-empty-message')}
        </div>
      );
    } else if (bills && bills.length <= 0) {
      resultMessage = (
        <div className="main-layout-title__result-message">{t('layouts:bookings.result-empty-message')}</div>
      );
    }

    const billsHeader = (
      <div className="main-layout-title">
        {resultMessage}
        <div className="main-layout-title__filters">
          <div className="main-layout-title__filter-item">
            <CalendarBox
              rangeSelect={true}
              placeholder="Fecha"
              className="filter"
              maxYear={new Date().getFullYear()}
              labelText=""
              initialValue=""
              onChange={this.dateChangeHandler}
            />
          </div>
          {/* <div className="main-layout-title__filter-item">
            <ButtonDropdown
              orientationModal={'right'}
              text={t('layouts:bills.status.title')}
              rightIcon={images.arrowDown2Svg}
              component={
                <MiniModalCheckbox
                  showSelectionCount={true}
                  showDeleteBtn={true}
                  options={this.statusOptions}
                  onDelete={() => {}}
                  value={''}
                  onChange={(value: any) => {}}
                  multiple={true}
                />
              }
            />
          </div> */}
        </div>
      </div>
    );

    const hasFilters = Object.keys(filters).length > 0;
    let content;
    if (bills && bills.length === 0 && searchedText === null && !hasFilters && firstLoad) {
      content = <OopsContent body={t('layouts:bills-superadmin.no-content')} />;
    }

    if (bills && bills.length === 0 && (searchedText !== null || hasFilters)) {
      content = <MainLayoutSection>{billsHeader}</MainLayoutSection>;
    }

    if (bills && bills.length > 0) {
      content = (
        <MainLayoutSection>
          {billsHeader}
          <div className="main-layout-table">
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableV2
                  totalPages={pages}
                  columns={getBillTableColumns(t, this.onDetailHandler, this.onDownloadHandler)}
                  data={bills}
                  disableSort={true}
                />
              </div>

              {this.state.pages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination
                    page={page}
                    callback={(page: any) => {
                      this.setState({ page }, () => {
                        this.fetchBills();
                      });
                    }}
                    limit={this.state.pages}
                  />
                </div>
              )}
            </MainLayoutTableContainer>
          </div>
        </MainLayoutSection>
      );
    }

    let dropdownChildren;
    if (preResult.length > 0) {
      dropdownChildren = <HeaderDropdown items={preResult} title={t('layouts:bookings.search-dropdown-title')} />;
    }

    return (
      <BillsSuperAdminLayoutStyle>
        <HeaderMain
          searchValue={filters.name ? filters.name.value : ''}
          placeholderSearch={t('layouts:bills-superadmin.search-placeholder')}
          className={classNames({ 'search-empty': noResult })}
          title={t('layouts:bills.header')}
          withSearch={true}
          secondaryButton={{
            type: 'primary',
            text: t('buttons:create'),
            onClick: () => {
              this.setState({ modal: 'modal-create' });
            },
            img: images.createWhiteSvg,
          }}
          disabled={(!bills || (bills && bills.length === 0)) && searchedText === null && !hasFilters}
          onPressEnter={this.onPressEnterHandler}
          handleSearch={this.searchFilterHandler}
          onCloseSearch={this.closeSearchHandler}
          dropdown={true}
          searched={searchedText}
          dropdownChildren={dropdownChildren}
        />
        {content}
        <ModalInfo
          type="blue"
          title={[t('modalMessages:modal-bills-download.title')]}
          onClose={() => {
            this.modalHandler('');
          }}
          icon={images.downloadGreenSvg}
          showModal={modal === 'modal-download'}
          buttons={[
            {
              text: t('buttons:download'),
              type: 'primary',
              onClick: this.downloadBill,
            },
          ]}
        />
        <ModalSuperAdminCreateBill
          onSubmit={this.createBillHandler}
          showModal={modal === 'modal-create'}
          onClose={() => {
            this.modalHandler('');
          }}
        />
        <ModalSuccess
          title={t('modalMessages:success-title')}
          text={t('modalMessages:bill-created')}
          showModal={modal === 'modal-success'}
          onClose={() => {
            this.modalHandler('');
          }}
        />
      </BillsSuperAdminLayoutStyle>
    );
  }
}

export default withTranslation(['intro', 'buttons', 'tables', 'layouts', 'modalMessages', 'push'])(
  withRouter(BillsSuperAdminLayout)
);
