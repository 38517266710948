import { TableColumn } from '../../../types/table';

export interface User {
  email: string;
  id: string;
  name: string;
  surname: string;
  createdAt: string;
  certifier: string;
  lastDocument: string;
}

export const tableColumns: TableColumn[] = [
  {
    text: 'Nombre',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Staff',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Fecha Registro',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Certificadora',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Titulación pendiente de validar',
    filter: false,
    ascendent: true,
  },
  {
    text: '',
    filter: false,
    ascendent: true,
  },
];

export const rowKeys: string[] = ['name', 'staff', 'register-date', 'certifier', 'lastInvalidDegree', 'options'];
