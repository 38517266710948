import React, { Component } from 'react';
import { DiverProfileContainer } from './diver-profile-style';
import { images } from '../../../../assets/images';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import HeadDestacadoText from '../../../../components/text/head-destacado-text/head-destacado-text';
import TitleText from '../../../../components/text/title-text/title-text';
import LabelText from '../../../../components/text/label-text/label';
import ItemCertification from '../../../../components/item-certification/item-certfication';
import TextDestacadoText from '../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../components/text/text-text/text-text';
import StarRate from '../../../../components/star-rate/star-rate';
import { DiverProfile } from './types';
import moment from 'moment';
import 'moment/locale/es.js';
import ItemSectionDetail from '../../../../components/item-sections-detail/item-sections-detail';
import ModalEquipment from './modals/moda-equipment/modal-equipment';
import ModalAditionalPreparation from './modals/modal-additional-preparation/modal-additional-preparation';
import ModalLanguages from './modals/modal-languages/modal-languages';
import ModalSpecialization from './modals/modal-specialization/modal-specialization';
import ModalQualification from './modals/modal-qualification/modal-qualification';
import ModalPersonal from './modals/moda-personal/modal-personal';
import ModalDelete from '../../../../components/modals/modal-delete/modal-delete';
import { GENDER_KEY_VALUES } from '../../../../constants/gender';
import { FULL_LANGUAGES_KEY_VALUES } from '../../../../constants/languages';
import { COUNTRIES_KEY_LABEL } from '../../../../constants/countries';
import { transformToUser, getUserUnit } from '../../../../utils/measurement-units';

interface DiverProfileProps {
  renderModal: string;
  person: DiverProfile;
  highestLevelDocument?: any;
  isCentre: boolean;
  isOrganization: boolean;
  reload: () => void;
  onRemoveDiver: () => void;
  onCancelRemove: () => void;
}

interface DiverProfileState {
  renderModal: string;
  popNot: boolean;
}

export default class DiverProfileLayout extends Component<DiverProfileProps, DiverProfileState> {
  state: DiverProfileState = {
    renderModal: '',
    popNot: false,
  };

  componentDidUpdate(prevProps: DiverProfileProps) {
    const { renderModal } = this.props;
    if (renderModal !== prevProps.renderModal) {
      this.setState({ renderModal });
    }
  }

  render() {
    const { renderModal } = this.state;
    const {
      person,
      reload,
      onRemoveDiver,
      onCancelRemove,
      highestLevelDocument,
      isCentre,
      isOrganization,
    } = this.props;

    return (
      <>
        <DiverProfileContainer>
          <div className="my-divers-personal">
            <div className="my-divers-personal-left">
              <div className="my-divers-personal-left-avatar">
                <img src={person.picture} alt="" />
              </div>
              {!person.isStaff && !person.isInstructor ? (
                <div className="my-divers-personal-left-name">
                  <HeadDestacadoText>{`${person.name ? person.name : ''} ${
                    person.surname ? person.surname : ''
                  }`}</HeadDestacadoText>
                </div>
              ) : (
                <div className="my-divers-personal-left-staff">
                  <div className="my-divers-personal-left-staff__roles">
                    {person.isStaff && <span className="my-divers-personal-left-staff__staff">Staff</span>}
                    {person.isInstructor && (
                      <span className="my-divers-personal-left-staff__instructor">Instructor</span>
                    )}
                  </div>
                  <div className="my-divers-personal-left-staff__name">
                    <HeadDestacadoText>{`${person.name ? person.name : ''} ${
                      person.surname ? person.surname : ''
                    }`}</HeadDestacadoText>
                  </div>

                  {!!person.centres &&
                    person.centres.map((centre: any, key: any) => (
                      <div key={centre.id} className="my-divers-personal-left-staff__center">
                        <TextDestacadoText>{centre.name}</TextDestacadoText>
                      </div>
                    ))}
                </div>
              )}

              <div className="my-divers-personal-left-resume">
                {/*<div className="my-divers-personal-left-resume__dive">
                  <div className="my-divers-personal-left-resume__title">
                    <TitleText>{person.diveCount}</TitleText>
                  </div>
                  <div className="my-divers-personal-left-resume__subtitle">
                    <LabelText>Inmersiones</LabelText>
                  </div>
                </div>
                <div className="my-divers-personal-left-resume__last">
                  <div className="my-divers-personal-left-resume__title">
                    <TitleText>
                      {!!person.lastDiveDate ? moment(person.lastDiveDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
                    </TitleText>
                  </div>
                  <div className="my-divers-personal-left-resume__subtitle">
                    <LabelText>Última inmersión</LabelText>
                  </div>
                    </div>
                <div className="my-divers-personal-left-resume__spots">
                  <div className="my-divers-personal-left-resume__title">
                    <TitleText>{person.siteCount}</TitleText>
                  </div>
                  <div className="my-divers-personal-left-resume__subtitle">
                    <LabelText>Puntos visitados</LabelText>
                  </div>
                </div>*/}
              </div>
              {highestLevelDocument && (
                <div className="my-divers-personal-left-section">
                  <div className="my-divers-personal-left-section__title">
                    <BodyDestacadoText>TITULACIÓN</BodyDestacadoText>
                  </div>
                  <div className="my-divers-personal-left-section__certification">
                    <div className="my-divers-personal-left-section__certification__item">
                      <ItemCertification
                        img={images.turtle}
                        title={highestLevelDocument.course.name}
                        certification={highestLevelDocument.name}
                        idCertification={highestLevelDocument.docId ? highestLevelDocument.docId : ''}
                        unofficial={!Object.keys(highestLevelDocument.validated).includes('organization')}
                      />
                    </div>
                  </div>
                </div>
              )}

              {!!person.specialities && !!person.specialities.length && (
                <div className="my-divers-personal-left-section">
                  <div className="my-divers-personal-left-section__title">
                    <BodyDestacadoText>Especialidades</BodyDestacadoText>
                    {!isCentre && !isOrganization && (
                      <div
                        className="my-divers-personal-left-section__title__edit"
                        onClick={() => this.setState({ renderModal: 'modal-specialization' })}
                      >
                        <BodyDestacadoText>Editar</BodyDestacadoText>
                      </div>
                    )}
                  </div>
                  <div className="my-divers-personal-left-section__certification">
                    <div className="my-divers-personal-left-section__certification__item">
                      <ItemCertification
                        img={images.turtle}
                        title="Nombre de la especilización"
                        certification="Sigue nandando certificate"
                        idCertification="2U39252U9"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="my-divers-personal-right">
              <ItemSectionDetail
                title="Información personal"
                icon={images.userSvg}
                onEdit={
                  !isCentre && !isOrganization ? () => this.setState({ renderModal: 'modal-personal' }) : undefined
                }
              >
                <div className="my-divers-personal-right-subtitle">
                  <BodyDestacadoText>DATOS DE CONTACTO</BodyDestacadoText>
                </div>
                <div className="my-divers-personal-right-table">
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>DNI:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{person.nif}</TextText>
                    </div>
                  </div>
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>F. nacimiento:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{!!person.birthday ? moment(person.birthday).format('DD/MM/YYYY') : '-'}</TextText>
                    </div>
                  </div>
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>Teléfono:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{person.phoneNumber}</TextText>
                    </div>
                  </div>
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>Email:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{person.email}</TextText>
                    </div>
                  </div>
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>Dirección:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{person.address}</TextText>
                    </div>
                  </div>
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>Localidad:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{person.town}</TextText>
                    </div>
                  </div>
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>Provincia:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{person.state}</TextText>
                    </div>
                  </div>
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>Código postal:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{person.postalCode}</TextText>
                    </div>
                  </div>
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>País:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{person.country}</TextText>
                    </div>
                  </div>
                  <div className="my-divers-personal-right-item">
                    <div className="my-divers-personal-right-item__title">
                      <TextDestacadoText>Nº Inmersiones:</TextDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-item__text">
                      <TextText>{person.divesqty}</TextText>
                    </div>
                  </div>
                </div>

                {person.emergencyContact && (
                  <>
                    <div className="my-divers-personal-right-subtitle">
                      <BodyDestacadoText>CONTACTO PARA EMERGENCIAS</BodyDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-table">
                      <div className="my-divers-personal-right-item">
                        <div className="my-divers-personal-right-item__title">
                          <TextDestacadoText>Nombre:</TextDestacadoText>
                        </div>
                        <div className="my-divers-personal-right-item__text">
                          <TextText>{person.emergencyContact.name}</TextText>
                        </div>
                      </div>
                      <div className="my-divers-personal-right-item">
                        <div className="my-divers-personal-right-item__title">
                          <TextDestacadoText>Teléfono:</TextDestacadoText>
                        </div>
                        <div className="my-divers-personal-right-item__text">
                          <TextText>{`${person.emergencyContact.phone.prefix} ${person.emergencyContact.phone.number}`}</TextText>
                        </div>
                      </div>
                      <div className="my-divers-personal-right-item">
                        <div className="my-divers-personal-right-item__title">
                          <TextDestacadoText>Email:</TextDestacadoText>
                        </div>
                        <div className="my-divers-personal-right-item__text">
                          <TextText>{person.emergencyContact.email}</TextText>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {(!!person.facebook || !!person.instagram) && (
                  <>
                    <div className="my-divers-personal-right-subtitle">
                      <BodyDestacadoText>Redes sociales</BodyDestacadoText>
                    </div>
                    <div className="my-divers-personal-right-table">
                      {!!person.facebook && (
                        <div className="my-divers-personal-right-item">
                          <div className="my-divers-personal-right-item__title">
                            <TextDestacadoText>Facebook:</TextDestacadoText>
                          </div>
                          <div className="my-divers-personal-right-item__text">
                            <TextText>{person.facebook}</TextText>
                          </div>
                        </div>
                      )}
                      {!!person.instagram && (
                        <div className="my-divers-personal-right-item">
                          <div className="my-divers-personal-right-item__title">
                            <TextDestacadoText>Instagram:</TextDestacadoText>
                          </div>
                          <div className="my-divers-personal-right-item__text">
                            <TextText>{person.instagram}</TextText>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </ItemSectionDetail>
            </div>
          </div>
          {renderModal === 'modal-qualification' && (
            <ModalQualification
              showModal={renderModal === 'modal-qualification'}
              onClose={() => this.setState({ renderModal: '' })}
            />
          )}
          {renderModal === 'modal-preparation' && (
            <ModalAditionalPreparation
              showModal={renderModal === 'modal-preparation'}
              onClose={() => this.setState({ renderModal: '' })}
              onSave={() => {
                this.setState({ renderModal: '' });
                reload();
              }}
              person={person}
            />
          )}
          {renderModal === 'modal-equipment' && (
            <ModalEquipment
              showModal={renderModal === 'modal-equipment'}
              onClose={() => this.setState({ renderModal: '' })}
              onSave={() => {
                this.setState({ renderModal: '' });
                reload();
              }}
              size={person.size}
              person={person}
            />
          )}
          {renderModal === 'modal-languages' && (
            <ModalLanguages
              showModal={renderModal === 'modal-languages'}
              onClose={() => this.setState({ renderModal: '' })}
              person={person}
              onSave={() => {
                this.setState({ renderModal: '' });
                reload();
              }}
            />
          )}
          {renderModal === 'modal-specialization' && (
            <ModalSpecialization
              showModal={renderModal === 'modal-specialization'}
              onClose={() => this.setState({ renderModal: '' })}
            />
          )}
          {renderModal === 'modal-personal' && (
            <ModalPersonal
              showModal={renderModal === 'modal-personal'}
              onClose={() => this.setState({ renderModal: '' })}
              onSave={() => {
                this.setState({ renderModal: '' });
                reload();
              }}
              person={person}
            />
          )}
          {renderModal === 'modal-delete' && (
            <ModalDelete
              title={'Eliminar buceador'}
              subtitle={
                'Una vez eliminado el perfil del buceador, este no tendrá acceso a la aplicación de DiveAsapp y no podrá disfrutar de sus servicios.'
              }
              showModal={renderModal === 'modal-delete'}
              onRemove={() => onRemoveDiver()}
              onCancel={() => onCancelRemove()}
            />
          )}
        </DiverProfileContainer>
      </>
    );
  }
}
