import * as React from 'react';
import { DiveSiteStyle } from './dive-site-style';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { images } from '../../../assets/images';
import { NavigationTabContainer } from '../../../components/navigation-tabs/navigation-tabs-style';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import SingleButton from '../../../components/single-button/single-button';
import MainButton from '../../../components/main-button';
import GoogleMap from 'google-map-react';
import Geocode from 'react-geocode';
import { mapStyle } from '../../../constants/map-style';
import CardMap from '../../../components/card-map/card-map';

import ModalDiveSite from '../../../components/modals/modal-dive-site/modal-dive-site';
import services from '../../../services/services';
import { DIVE_SITE_PER_PAGE } from '../../../constants/data';
import { withRouter, RouteComponentProps, match } from 'react-router';
import { getDiveSites, deleteDiveSites, validateDiveSites, byOwnerFavorite } from '../../../services/dive-sites';
import { GMAPS_KEY } from '../../../env';
import { getDayMonthNumberYear } from '../../../utils/time';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import ModalInfo from '../../../components/modals/modal-info/modal-info';
import { COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import SelectBox from '../../../components/select-box';
import { DIVE_SITES_DROPDOWN, DIVE_SITE_KEY_VALUES } from '../../../constants/dive-sites';
import { IFilter } from '../../../models/filter';
import { createFilterRequest, getDistance } from '../../../utils/other';
import { IDropdown } from '../../../models/generic';
import { getCentres, getCentreDiveSites, addCentreDiveSites, removeCentreDiveSites, getCentre } from '../../../services/centre';
import { ContentBlock } from '../../../components/content-block/content-block';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { transformToUser, getUserUnit } from '../../../utils/measurement-units';
import { havePermissionsSites } from '../../../services/staff-centre';

interface RouteParams { }

export interface IDiveSiteProps extends RouteComponentProps<RouteParams> {
  match: match<{
    id: string;
  }>;
  agent?: any;
  staffPermissions?: any;
  t:any;
}

export interface IDiveSiteState {
  modal: string;
  isAdmin: boolean;
  section: string;
  display: string;
  divesSites: any[];
  divesSitesCenter: any[];
  sitesInsideBounds: any[];
  userSites: any[];
  cards: any[];
  cardGroups: any[][];
  rows: any[];
  total: number;
  totalPages: number;
  page: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  diveSiteId: string;
  textDelete: string;
  header: boolean;
  filters: boolean;
  filterValues: { [key: string]: IFilter };
  zoomLevel: number;
  centres: IDropdown[];
  center: {
    lat: number;
    lng: number;
  };
  infoCentre: any;
}

class DiveSite extends React.Component<IDiveSiteProps, IDiveSiteState> {

  constructor(props: IDiveSiteProps) {
    super(props);
    this.state = {
      modal: '',
      section: 'validated',
      display: 'map',
      isAdmin: true,
      divesSites: [],
      divesSitesCenter: [],
      sitesInsideBounds: [],
      userSites: [],
      rows: [],
      cards: [],
      cardGroups: [],
      total: 0,
      totalPages: 0,
      searchFilter: '',
      skipPage: 0,
      zoomLevel: 9,
      orderAscendent: '',
      page: 1,
      diveSiteId: '',
      textDelete: '',
      header: false,
      filters: false,
      filterValues: {},
      centres: [],
      center: { lat: 40.24594, lng: 3.42922 },
      infoCentre: [],
    };
  }

  componentDidMount() {
    this.getCoords().then(() => {
      this.getDiveSites();
      this.getCentres();
      this.getFavouritesCentres();
    });

  }

  async getCoords() {
    const centreId = localStorage.getItem('centreId')
    if (centreId) {
      const currentCentre = await getCentre(centreId);
      if (currentCentre.data) {
        const coords = await this.getCoordinates(currentCentre.data.address).then(data => {
          this.setState({ center: data, infoCentre: currentCentre.data })
        }
        );
      }
    }
  }


  componentDidUpdate(prevProps: IDiveSiteProps, prevState: IDiveSiteState) {
    const { match } = this.props;
    if (prevState.section !== this.state.section) {
      this.setState({ page: 1 }, () => this.getDiveSites());
    }
  }


  async getFavouritesCentres() {
    const userId = localStorage.getItem('userId')
    const centreId = localStorage.getItem('centreId')
    if (userId && centreId) {
      const params = {
        userId: parseInt(userId),
        centreId: parseInt(centreId),
      };
      const resp: any = await byOwnerFavorite(params);
      this.setState({ userSites: resp.data });
    }

  }



  getCategory = (type?: string) => {
    switch (type) {
      case 'dive-site':
        return 'site';
      case 'marine-reserve':
        return 'marine reserve';
      default:
        return 'invalid site';
    }
  };

  getSpot = (type?: string) => {

    switch (type) {
      case 'site':
      case 'flotsam':
      case 'depth':
      case 'submerged objects':
      case 'coast side':
      case 'cenote':
      case 'marine-reserve':
      case 'marine reserve':
        return 'dive-spot';
      //case 'marine reserve':
      //return 'marine-reserve';
      default:
        //return 'invalid-spot';
        return 'dive-spot';
    }
  };

  async getCentres() {
    const centresRaw = (await getCentres()).data.data;
    const centres: IDropdown[] = centresRaw
      .filter((centre: any) => centre.hasValidated)
      .map((centre: any) => {
        return { value: centre.id.toString(), label: centre.name };
      });
    this.setState({ centres });
  }

  getCoordinates = async (address: string) => {
    try {
      Geocode.setApiKey(GMAPS_KEY);
      const res = await Geocode.fromAddress(address);
      if (res.status === "OK") {
        return res.results[0].geometry.location;
      }
    } catch (err) {
      console.log(err);
    }
  }



  getDiveSites = async () => {
    const {
      match: {
        params: { id },
      },
      agent,
    } = this.props;
    const { searchFilter, skipPage, orderAscendent, section, filterValues, display } = this.state;
    const whereFilter = createFilterRequest(filterValues);
    const validated = section === 'validated' ? 'is not' : 'is';
    if (!whereFilter.validated || section !== 'validated') {
      whereFilter.validated = { method: validated, value: 'null' };
    }
    const params: any = {
      filter: {
        where: whereFilter,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: ['pictures', 'reserve', 'centres', 'centres.user'],
      },
    };

    if (display === 'table') {
      params.filter.skip = skipPage;
      params.filter.limit = DIVE_SITE_PER_PAGE;
    }

    try {
      const response: any = await getDiveSites(params);
      /* const response: any = await getDiveSites({filter: {where: {id: 14}, relations: ['pictures', 'reserve', 'centres', 'centres.user'] }}); */
      // id ? await getCentreDiveSites(id, params) : await getDiveSites(params);
      let resCentre: any = {};
      if (id) {
        resCentre = await getCentreDiveSites(id, params);
      }

      const totalPages: number = Math.ceil(response.data.count / DIVE_SITE_PER_PAGE);
      const total = response.data.count;
      const divesSites = response.data.data;
      const avarageLat = divesSites.reduce((prevVal: number, site: any) => prevVal + parseFloat(site.latitude), 0) / divesSites.length;
      const avarageLng = divesSites.reduce((prevVal: number, site: any) => prevVal + parseFloat(site.longitude), 0) / divesSites.length;

      this.setState(
        {
          totalPages,
          total,
          divesSites,
          divesSitesCenter: resCentre.data ? resCentre.data.data : [],
          sitesInsideBounds: divesSites,
          center: {
            lat: id ? this.state.center.lat : avarageLat,
            lng: id ? this.state.center.lng : avarageLng,
          },
          zoomLevel: id ? 5 : 3,
        },
        () => {
          this.getRows();
          this.getCardsMaps();
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  getColumns = () => {
    const { section } = this.state;
    if (section === 'validated') {
      return [
        {
          text: '',
          filter: false,
          ascendent: true,
        },
        {
          text: 'Nombre',
          filter: false,
          ascendent: true,
        },
        {
          text: 'País',
          filter: false,
          ascendent: true,
        },
        {
          text: 'Provincia',
          filter: false,
          ascendent: true,
        },
        {
          text: 'Valoración',
          filter: false,
          ascendent: false,
        },
        {
          text: 'Creación',
          filter: false,
          ascendent: true,
        },
        {
          text: 'Creado por',
          filter: false,
          ascendent: true,
        },
        // {
        //   text: 'Validado',
        //   filter: false,
        //   ascendent: true,
        // },
        // {
        //   text: 'Validado por',
        //   filter: false,
        //   ascendent: true,
        // },
        {
          text: '',
          filter: false,
          ascendent: true,
        },
      ];
    }
    return [
      {
        text: '',
        filter: false,
        ascendent: true,
      },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Nombre',
        filter: false,
        ascendent: true,
      },
      {
        text: 'País',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Provincia',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Creación',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Creado por',
        filter: false,
        ascendent: true,
      },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
    ];
  };

  getKeysColumns = () => {
    const { section } = this.state;
    if (section === 'validated') {
      return [
        'icon',
        'name',
        'country',
        'province',
        'rating',
        'createdAt',
        'createdBy',
        // 'validated',
        // 'validatedBy',
        'actions',
      ];
    }
    return [
      'check',
      'icon',
      'name',
      'country',
      'province',
      'rating',
      'createdAt',
      'createdBy',
      'validated',
      'validatedBy',
      'actions',
    ];
  };

  getRows = () => {
    const {
      staffPermissions,
      match: {
        params: { id },
      },
      agent,
    } = this.props;
    const { sitesInsideBounds, section, divesSitesCenter, userSites, divesSites } = this.state;
    const divesSitesCenterList = divesSitesCenter.length > 0 ? divesSitesCenter : divesSites;

    this.setState({divesSitesCenter:divesSitesCenterList});
    const rows = divesSitesCenter
      .map((site: any) => {
        const row: any = {
          id: {
            value: site.id,
          },
          check: {
            key: 'check',
            value: section === 'validated' ? images.diveSiteValidated : images.diveSiteNoValidated,
          },
          icon: {
            key: 'icon',
            value: section === 'validated' ? images.diveSiteValidated : images.diveSiteNoValidated,
          },
          name: {
            key: 'highlighted-body',
            value: site.name,
          },
          country: {
            key: '',
            value: COUNTRIES_KEY_LABEL[site.country],
          },
          province: {
            key: '',
            value: site.state,
          },
          rating: {
            key: 'rate',
            value: site.rating,
          },
          createdAt: {
            key: '',
            value: getDayMonthNumberYear(site.createdAt),
          },
          createdBy: {
            key: '',
            value: site.ownerName ? site.ownerName + ' ' + site.ownerSurName : '-',
          },
          /*
          ownerName: "kiko rodriguez"
          ownerSurName: "rodriguez"
          createdBy: {
            key: '',
            value: site.createdBy
              ? site.createdBy.name
                ? `${site.createdBy.name} ${site.createdBy.surname}`
                : site.createdBy.email
              : '-',
          },*/

          validated: {
            key: '',
            value: site.validated ? getDayMonthNumberYear(site.validated.timestamp) : '-',
          },
          validatedBy: {
            key: '',
            value: site.validated && site.validated.user ? site.validated.user.email : '-',
          },
        };
        if (((staffPermissions && !staffPermissions.sites) || id) && agent.role === 'staff') {
          row.actions = {
            key: 'options',
            value: '',
            actions: [
              {
                label: 'Ver',
                icon: images.eyeSvg,
                onClick: () => this.props.history.push(`${this.props.history.location.pathname}/detail/${site.id}`),
              },
            ],
          };
          if (divesSitesCenter.filter((s: any) => s.id === site.id).length === 0) {
            row.actions.actions.push({
              label: 'Añadir Favorito',
              icon: images.starEmptySvg,
              onClick: () =>
                this.setState({
                  diveSiteId: site.id,
                  modal: 'modal-info-favorite',
                }),
            });
          } else {
            row.actions.actions.push({
              label: 'Quitar Favorito',
              icon: images.starFullSvg,
              onClick: () =>
                this.setState({
                  diveSiteId: site.id,
                  modal: 'modal-delete-favorite',
                }),
            });
          }
        } else if (section === 'validated') {
          row.actions = {
            key: 'options',
            value: '',
            actions: [
              {
                label: 'Ver',
                icon: images.eyeSvg,
                onClick: () => this.props.history.push(`${this.props.history.location.pathname}/detail/${site.id}`),
              },
            ],
          };
          if (
            (site.centres && site.centres[0] && site.centres[0].user && site.centres[0].user.id === agent.id) ||
            agent.roles.includes('superAdmin') ||
            agent.roles.includes('divingCenter_admin') ||
            agent.roles.includes('divingCenter_owner')
          ) {
            row.actions.actions.push(
              {
                label: 'Editar',
                icon: images.edit3Svg,
                onClick: () =>
                  this.setState({
                    diveSiteId: site.id,
                    modal: 'create-site',
                  }),
              },
              {
                label: 'Eliminar',
                icon: images.delete2Svg,
                onClick: () =>
                  this.setState({
                    diveSiteId: site.id,
                    modal: 'modal-delete',
                    textDelete: 'Vas a eliminar un punto de inmersión',
                  }),
              }
            );
          }
        } else {
          row.actions = {
            key: 'options',
            value: '',
            actions: [
              {
                label: 'Ver',
                icon: images.eyeSvg,
                onClick: () => this.props.history.push(`${this.props.history.location.pathname}/detail/${site.id}`),
              },
              {
                label: 'Editar',
                icon: images.edit3Svg,
                onClick: () => this.setState({ diveSiteId: site.id, modal: 'create-site' }),
              },
              {
                label: 'Validar',
                icon: images.checkCircleSvg,
                onClick: () => this.setState({ diveSiteId: site.id, modal: 'modal-info', header: false }),
              },
              {
                label: 'Rechazar',
                icon: images.closeCircleSvg,
                onClick: () =>
                  this.setState({
                    diveSiteId: site.id,
                    modal: 'modal-delete',
                    textDelete: 'Vas a rechazar el punto de inmersión',
                    header: false,
                  }),
              },
            ],
          };
        }

        if (section !== 'validated') {
          row.check = {
            key: 'check',
            value: false,
            // onSelect: (rows: any[], all: boolean) => console.log(rows, all),
          };
        }
        return row;
      });
    this.setState({ rows });
  };

  getCardsMaps = async () => {
    const { divesSites, divesSitesCenter } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const pending: any[] = divesSites.map(async (site: any, index: number) => {
      return {
        location: site.location,
        spot: this.getSpot(site.validated && site.validated.timestamp ? site.kind : ''),
        images: site.pictures && site.pictures.length > 0 ? site.pictures.map((p: any) => p.src).slice(0, 3) : [],
        type: {
          icon: images.beachGreySvg,
          text: DIVE_SITE_KEY_VALUES[site.kind],
        },
        title: site.name,
        depth: `${transformToUser('depth', site.minDepth)} ${getUserUnit('depth')} - ${transformToUser(
          'depth',
          site.maxDepth
        )} ${getUserUnit('depth')}`,
        rate: site.rating,
        next: '', // 'inmersiones próximos 7 días',
        lat: site.latitude,
        lng: site.longitude,
        id: site.id,
        // addFav: id ? divesSitesCenter.filter((s: any) => s.id !== site.id).length === 0 : false,
        addFav: !!divesSitesCenter.find((s: any) => s.id === site.id),
      };
    });
    const cards: any[] = await Promise.all(pending);
    this.setState({ cards }, () => this.setCardGroups());
  };

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = DIVE_SITE_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getDiveSites();
      });
    }
  }

  deleteDiveSite = async (id: string) => {
    const { header } = this.state;
    if (!header) {
      try {
        await deleteDiveSites(id);
        services.pushNotification({
          title: 'Punto de inmersión eliminado',
          text: 'Se ha eliminado el punto de inmersión con éxito.',
          type: 'red',
        });
        this.setState({ modal: '', diveSiteId: '' }, () => this.getDiveSites());
      } catch (err) { }
    } else {
      const { rows } = this.state;
      for (const r of rows) {
        try {
          await deleteDiveSites(r.id.value);
        } catch (err) { }
      }
      services.pushNotification({
        title: 'Punto de inmersión eliminado',
        text: 'Se ha eliminado los puntos de inmersión con éxito.',
        type: 'red',
      });
      this.setState({ modal: '' });
    }
  };

  validateDiveSite = async (id: string) => {
    const { header } = this.state;
    if (header) {
      try {
        await validateDiveSites(id);
        services.pushNotification({
          title: 'Validado',
          text: 'Se ha validado el punto de inmersión correctamente.',
          type: 'green',
        });
        this.setState({ modal: '', diveSiteId: '' }, () => this.getDiveSites());
      } catch (err) { }
    } else {
      const { rows } = this.state;

      for (const r of rows) {
        try {
          await validateDiveSites(r.id.value);
        } catch (err) { }
      }
      services.pushNotification({
        title: 'Validado',
        text: 'Se ha validado los puntos de inmersión correctamente.',
        type: 'green',
      });
      this.setState({ modal: '' });
    }
  };

  addFavorite = async (idSite: string) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    try {
      await addCentreDiveSites(id, { site: idSite });
      services.pushNotification({
        title: 'Añadido',
        text: 'Se ha añadido a favoritos.',
        type: 'green',
      });
      this.setState({ modal: '', diveSiteId: '' }, () => this.getDiveSites());
    } catch (err) { }
  };
  removeFavorite = async (idSite: string) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    try {
      await removeCentreDiveSites(id, idSite);
      services.pushNotification({
        title: 'Eliminado',
        text: 'Se ha eliminado de favoritos.',
        type: 'red',
      });
      this.setState({ modal: '', diveSiteId: '' }, () => this.getDiveSites());
    } catch (err) { }
  };

  handleCheck = (newRows: any[], all: boolean) => {
    this.setState({ rows: newRows });
  };

  handleFilterChange(key: string, value: any, method?: string, collection?: string, jsonSearch?: string) {
    const { filterValues } = this.state;
    filterValues[key] = { value: '' };
    filterValues[key].value = value;
    if (method) filterValues[key].method = method;
    if (collection) filterValues[key].collection = collection;
    if (jsonSearch) filterValues[key].jsonSearch = jsonSearch;
    this.setState({ filterValues }, () => {
      this.getDiveSites();
    });
  }

  setCardGroups(zoomLevel: number = 4) {
    const { cards } = this.state;
    const cardGroups: any[] = [];
    cards.forEach((card: any) => {
      if (cardGroups.length === 0) cardGroups.push([card]);
      else {
        let added = false;
        cardGroups.forEach((cardGroup: any) => {
          const distance = getDistance(cardGroup[0].lat, cardGroup[0].lng, card.lat, card.lng);
          if (!added && distance < 500 / (zoomLevel * 2) && zoomLevel < 9) {
            added = true;
            cardGroup.push(card);
          }
        });
        if (!added) {
          cardGroups.push([card]);
        }
      }
    });
    this.setState({ cardGroups });
  }

  handleZoomChange(zoomLevel: number) {
    this.setState({ zoomLevel }, () => {
      this.setCardGroups(zoomLevel);
    });
  }

  handleBoundChange(ev: any) {
    const { divesSites } = this.state;
    const bounds = ev.bounds;
    const sitesInsideBounds: any[] = [];
    divesSites.forEach((site: any) => {
      if (
        bounds.nw.lat > site.latitude &&
        bounds.nw.lng < site.longitude &&
        bounds.se.lat < site.latitude &&
        bounds.se.lng > site.longitude
      ) {
        sitesInsideBounds.push(site);
      }
    });
    this.setState({ sitesInsideBounds });
  }



  closeCards = (index: number) => {

    const someElementActive = document.getElementsByClassName("card-map-pop");
    for (let i = 0; i < someElementActive.length; i++) {
      if (i == index) {
        someElementActive[i].classList.remove("false")
        someElementActive[i].classList.add("active")
      } else {
        someElementActive[i].classList.remove("active")
        someElementActive[i].classList.add("false")
      }
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      staffPermissions,
      agent,
      t
    } = this.props;
    const {
      modal,
      section,
      display,
      isAdmin,
      cards,
      cardGroups,
      totalPages,
      page,
      rows,
      diveSiteId,
      textDelete,
      divesSites,
      filters,
      centres,
      center,
      zoomLevel
    } = this.state;

    const validate = section !== 'validated' && rows.filter(r => r.check && r.check.value).length > 0;
    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Puntos de inmersión'}
            mainButton={
              !staffPermissions || staffPermissions.sites
                ? !validate && section === 'validated'
                  ? {
                    img: images.createWhiteSvg,
                    text: 'Crear',
                    disabled: !havePermissionsSites(),
                    onClick: () => this.setState({ modal: 'create-site' }), // "create-reserve"
                  }
                  : validate && section !== 'validated'
                    ? {
                      text: 'Validar',
                      onClick: () => this.setState({ modal: 'modal-info', header: true }), // "create-reserve"
                    }
                    : undefined
                : undefined
            }
            secondaryButtonRed={
              !validate || (staffPermissions && !staffPermissions.sites)
                ? undefined
                : {
                  text: 'Rechazar',
                  onClick: () =>
                    this.setState({
                      modal: 'modal-delete',
                      textDelete: 'Vas a rechazar los puntos de inmersión selecionados',
                      header: true,
                    }), // "create-reserve"
                }
            }
          />
          <MainLayoutSection>
            <DiveSiteStyle>
              <div className="dive-site-head">
                {/* <div className="dive-site-head__nav">
                  <NavigationTabContainer>
                    <div
                      className={`navigation-item ${section === 'validated' && 'active'}`}
                      onClick={() => this.setState({ section: 'validated' })}
                    >
                      <BodyDestacadoText>Validados</BodyDestacadoText>
                    </div>
                    <div
                      className={`navigation-item ${section === 'no-validated' && 'active'}`}
                      onClick={() => this.setState({ section: 'no-validated' })}
                    >
                      <BodyDestacadoText>No validados</BodyDestacadoText>
                    </div>
                  </NavigationTabContainer>
                </div> */}
                <div className="dive-site-head__buttons">
                  <div className="dive-site-head__buttons__filter">
                    <MainButton
                      iconImg={filters ? images.filtersLightBlueSvg : images.filtersSvg}
                      text="Filtrar"
                      type={`${filters ? 'secondary-active' : 'secondary'}`}
                      onClick={() => this.setState({ filters: !filters })}
                    />
                  </div>
                  <div className="dive-site-head__buttons__item">
                    <SingleButton
                      className="secondary modal"
                      icon={display === 'map' ? images.mapBlueSvg : images.mapSvg}
                      onClick={() => this.setState({ display: 'map' })}
                    />
                  </div>
                  <div className="dive-site-head__buttons__item">
                    <SingleButton
                      className="secondary modal"
                      icon={display === 'table' ? images.listBlueSvg : images.listSvg}
                      onClick={() => this.setState({ display: 'table' }, this.getRows)}
                    />
                  </div>
                </div>
              </div>

              <div className={`dive-site-filters${filters ? ' active' : ''}`}>
                <div className="dive-site-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Tipo de punto"
                    required={true}
                    className="rounded-filter"
                    // errorCode={"error"}
                    optionsText={[{ value: '*', label: 'Todos' }].concat(DIVE_SITES_DROPDOWN)}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={''}
                    icon={images.arrowDown2BlueSvg}
                    onChange={value => {
                      this.handleFilterChange('kind', value === '*' ? '' : value);
                    }}
                  />
                </div>
              </div>

              {/*  */}
              {/* Se muestra en el dashboard de centro pero no en el menu de SuperAdmin */}
              {!isAdmin && (
                <div className="dive-center-legend">
                  {section === 'validated' ? (
                    <>
                      <div className="dive-center-legend__item">
                        <div className="dive-center-legend__item__icon">
                          <img src={images.diveSiteMarineReserve} alt="" />
                        </div>
                        <div className="dive-center-legend__item__text">
                          <BodyDestacadoText>Reserva marina</BodyDestacadoText>
                        </div>
                      </div>
                      <div className="dive-center-legend__item">
                        <div className="dive-center-legend__item__icon">
                          <img src={images.diveSiteValidated} alt="" />
                        </div>
                        <div className="dive-center-legend__item__text">
                          <BodyDestacadoText>Punto de inmersión</BodyDestacadoText>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="dive-center-legend__item">
                      <div className="dive-center-legend__item__icon">
                        <img src={images.diveSiteNoValidated} alt="" />
                      </div>
                      <div className="dive-center-legend__item__text">
                        <BodyDestacadoText>Punto no validado</BodyDestacadoText>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {display === 'table' ? (
                <MainLayoutTableContainer>
                  <div className="diving-center-table">
                    <MainTableLayout
                      columns={this.getColumns()}
                      rows={rows}
                      rowKeys={this.getKeysColumns()}
                      onOrder={() => { }}
                    />
                  </div>
                  {/*totalPages > 0 && (
                    <div className="diving-center-pagination">
                      <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
                    </div>
                  )*/}
                </MainLayoutTableContainer>
              ) : (
                <div className="dive-site-map">
                  <GoogleMap
                    options={{
                      styles: mapStyle,
                      mapTypeId: 'hybrid',
                      minZoom: 4,
                      maxZoom: 16,
                    }}
                    zoom={zoomLevel}
                    bootstrapURLKeys={{ key: GMAPS_KEY }}
                    defaultCenter={center}
                    //defaultZoom={id ? 7 : 3}
                    defaultZoom={3}

                    center={isNaN(center.lat) ? { lat: 0, lng: 0 } : center}
                    onZoomAnimationEnd={zoomLevel => this.handleZoomChange(zoomLevel)}
                    onChange={ev => this.handleBoundChange(ev)}
                  >

                    {/* <CardMap lat={28.457044} lng={-16.83126} title="My Marker" /> */}
                    {cardGroups.map((cardGroup: any, index: number) => {
                      if (cardGroup.length === 1) {
                        const c = cardGroup[0];
                        return (
                          <CardMap
                            className='CardMap'
                            key={`card-map-${index}`}
                            isStaff={agent.role === 'staff'}
                            handleEdit={() => this.setState({ diveSiteId: c.id, modal: 'create-site' })}
                            onClickCard={() => {
                              this.closeCards(index)
                              this.setState(
                                // !Mandatory 2 setState in order to allow map to center always
                                {
                                  zoomLevel: zoomLevel,
                                  center: {
                                    lat: parseFloat(c.lat) + 0.001,
                                    lng: parseFloat(c.lng) + 0.001,
                                  },
                                },
                                () =>
                                  this.setState({
                                    zoomLevel: zoomLevel,
                                    center: {
                                      lat: parseFloat(c.lat),
                                      lng: parseFloat(c.lng),
                                    },
                                  })
                              );
                            }}
                            spot={c.spot}
                            lng={c.lng}
                            lat={c.lat}
                            type={c.type}
                            title={c.title}
                            locationDive={c.location}
                            depth={c.depth}
                            rate={c.rate} // RAITING O RATINGS?
                            next={c.next}
                            imagesSite={c.images}
                            link={'/centres/' + id + '/dive-site/detail/' + c.id}
                            addFav={
                              id
                                ? () =>
                                  this.setState({
                                    diveSiteId: c.id,
                                    modal: !c.addFav ? 'modal-info-favorite' : 'modal-delete-favorite',
                                  })
                                : undefined
                            }
                            removeFav={!c.addFav}
                          />
                        );
                      }
                      const c = cardGroup[0];
                      return (
                        <CardMap
                          key={`card-map-${index}`}
                          isStaff={agent.role === 'staff'}
                          handleEdit={() => this.setState({ diveSiteId: c.id, modal: 'create-site' })}
                          spot={'group-spot'}
                          lng={c.lng}
                          lat={c.lat}
                          title={`${c.title} + ${cardGroup.length}`}
                          type={{ icon: images.circleGreenSvg, text: `${cardGroup.length}` }}
                          locationDive={c.location}
                          depth={c.depth}
                          rate={c.rate} // RAITING O RATINGS?
                          next={c.next}
                          imagesSite={c.images}
                          link={'/centres/' + id + '/dive-site/detail/' + c.id}
                          addFav={
                            id
                              ? () =>
                                this.setState({
                                  diveSiteId: c.id,
                                  modal: !c.addFav ? 'modal-info-favorite' : 'modal-delete-favorite',
                                })
                              : undefined
                          }
                          removeFav={!c.addFav}
                        />
                      );
                    })}
                  </GoogleMap>
                </div>
              )}
            </DiveSiteStyle>


            {/**
 * renderMarkers(map, maps) {
  let marker = new maps.Marker({
    position: myLatLng,
    map,
    title: 'Hello World!'
  });
}
 *                             onClickCard={() => {
                              this.setState(
                                // !Mandatory 2 setState in order to allow map to center always
                                {
                                  zoomLevel: 9,
                                  center: {
                                    lat: parseFloat(c.lat) + 0.001,
                                    lng: parseFloat(c.lng) + 0.001,
                                  },
                                },
                                () =>
                                  this.setState({
                                    zoomLevel: 9,
                                    center: {
                                      lat: parseFloat(c.lat),
                                      lng: parseFloat(c.lng),
                                    },
                                  })
                              );
                            }}
 *
 *
 */}


          </MainLayoutSection>
          {modal === 'modal-delete' && (
            <ModalDelete
              title={textDelete}
              subtitle="¿estás seguro?"
              showModal={modal === 'modal-delete'}
              onRemove={() => {
                if (!!diveSiteId) {
                  this.deleteDiveSite(diveSiteId);
                }
              }}
              onCancel={() => this.setState({ modal: '' })}
            />
          )}
          {modal === 'modal-info' && (
            <ModalInfo
              type="blue"
              title={[<span key="modal-info-validate-dive">Validar punto de inmersión</span>]}
              onClose={() => this.setState({ modal: '', diveSiteId: '' })}
              text={[
                `Una vez que se haya validado el punto de inmersión,
           este aparecerá reflejado en la plataforma pudiéndose realizar y registrar inmersiones en él.`,
              ]}
              icon={images.checkCircleBlueSvg}
              showModal={modal === 'modal-info'}
              buttons={[
                { text: 'Validar', type: 'primary', onClick: () => this.validateDiveSite(diveSiteId) },
                { text: 'Rechazar', type: 'secondary-red', onClick: () => this.deleteDiveSite(diveSiteId) },
              ]}
            />
          )}
          {modal === 'modal-info-favorite' && (
            <ModalInfo
              type="blue"
              title={[<span key="modal-info-validate-dive">Añadir punto a favorito</span>]}
              onClose={() => this.setState({ modal: '', diveSiteId: '' })}
              text={[`¿Quieres añadir este punto a favoritos para poder añadirlo a tu lista y utilizarlo en tus actividades?`]}
              icon={images.alertBlueSvg}
              showModal={modal === 'modal-info-favorite'}
              buttons={[{ text: 'Aceptar', type: 'primary', onClick: () => this.addFavorite(diveSiteId) }]}
            />
          )}
          {modal === 'modal-delete-favorite' && (
            <ModalDelete
              title={'Quitar punto a favorito'}
              subtitle="¿Estás seguro?"
              showModal={modal === 'modal-delete-favorite'}
              onRemove={() => {
                this.removeFavorite(diveSiteId);
              }}
              onCancel={() => this.setState({ modal: '', diveSiteId: '' })}
            />
          )}
          {modal === 'create-site' && (
            <ModalDiveSite
              showModal={modal === 'create-site'}
              centreId={id}
              diveSiteId={diveSiteId}
              divesSites={divesSites}
              cards={cards}
              onClose={(load?: boolean) =>
                this.setState({ modal: '', diveSiteId: '' }, () => {
                  if (load) {
                    window.location.reload();
                    //this.getDiveSites();
                  }
                })
              }
            />
          )}
        </ContentBlock>
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
    agent: state.agent.value,
  };
}

export default connect(mapStateToProps)(withRouter(DiveSite));
