import styled, { css } from 'styled-components';

export const SubtitleTextContainer = styled.p`
  font-family: 'plain';
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;

  ${props => css`
    color: ${props.color};
  `}
`;
