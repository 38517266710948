import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const PlanningCalendarStyle = styled.div`
  .planning-calendar {
    min-height: 600px;
    height: 73vh;
    margin-bottom: 80px;
  }

  .planning-calendar-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .planning-calendar-header-left {
    display: flex;
  }

  .caption-container {
    display: flex;
    right: 0;
    position: relative;
    margin-top: 24px;
    margin-bottom: 24px;
    justify-content: flex-end;
    &__item {
      display: flex;
      font-size: 12px;
      margin-left: 24px;
    }
    &__circle {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-right: 6px;
      margin-top: 3px;
    }
    .red {
      border: 2px solid ${colors['CO-003/75']};
    }
    .green {
      border: 2px solid ${colors['CO-001/100']};
    }
    .dark-blue {
      border: 2px solid ${colors['PR-001/100']};
    }
    .yellow{
      border: 2px solid ${colors['CO-002/100']};
    }
    .blue {
      border: 2px solid ${colors['SE-001/75']};
    }
    .grey {
      border: 2px solid ${colors['SE-004/10']};
    }
  }

  .planning-calendar-header-current {
    background: #f8fafd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    &__arrow {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
    &__date  {
      padding: 0 12px;
    }
  }
  .planning-calendar-header-item {
    margin-left: 16px;
  }

  .planning-calendar-filters {
    display: flex;
    margin-bottom: 60px;
    &__item  {
      width: 213px;
      margin-right: 20px;
    }
  }

  .anual-calendar  {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }

  /* Calendar Style */

  /* MONTH */
  .rbc-header {
    padding: 20px 0;
    text-align: left;
    border: none;

    span {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #0d1e3f;
    }
    /*Day selected
    span {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #00A1A5;
    }*/
  }
  .rbc-today {
    background-color: #eaf6ff;
  }

  .rbc-date-cell {
    text-align: left;
    font-size: 20px;
    line-height: 28px;
    background: none;
  }
  .rbc-month-row + .rbc-month-row,
  .rbc-month-view,
  .rbc-day-bg + .rbc-day-bg,
  .rbc-header + .rbc-header {
    border: none;
    background: none;
  }

  .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
    background: none;
  }

  /* WEEK */
  .rbc-time-view,
  .rbc-time-header-content,
  .rbc-time-header.rbc-overflowing {
    border: none;
  }

  .rbc-time-slot {
    font-size: 12px;
    line-height: 20px;
    color: ${colors['NE-002/100']};
  }

  .rbc-event {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0px;
    background-color: none;
    color: none;
    cursor: pointer;
    width: 100%;
    &:focus  {
      outline: none;
    }
  }

  /* calendar styles */

  /* .rbc-calendar {
    margin-bottom: 120px;
    font-family: 'plain', sans-serif;

    .rbc-toolbar-label {
      font-size: 14px;
      letter-spacing: 1px;
      color: #000000;
      text-transform: uppercase;
      font-weight: 500;
    }
    .rbc-btn-group {
      button {
        border: 1px solid #000;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        color: #000;
        border-radius: 0;
        cursor: pointer;
        transition: 0.3s all ease-in-out;

        &:active,
        &.rbc-active,
        &:focus,
        &:focus,
        &.active,
        &:hover {
          background: #000000;
          color: #fff;
          border: 1px solid #000;
        }

        &.rbc-active {
          &:focus {
            background: #000000;
            color: #fff;
          }
          &:focus {
            background: #000000;
            color: #fff;
          }
        }
      }
    }
  }

  .rbc-header {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 36px;
    letter-spacing: 1px;
    color: #000;
    line-height: 1.3em;
  }

  .rbc-toolbar {
    visibility: hidden;
    margin-bottom: 18px;
  }

  .rbc-date-cell {
    background: white;
    & > a {
      color: #000;
      text-align: left;
      font-size: 20px;
      line-height: 28px;
      margin: 4px 0;
      display: block;
    }
  }
  .rbc-time-content {

  }
  .rbc-month-row {
    overflow: initial;
    height: auto;
    .rbc-month-header {
      margin-bottom: 40px;
    }
  }

  .cell-item {
    padding: 6px 20px;
    display: flex;
    overflow: hidden;
  }
  .rbc-event {
    outline: none;
    border-radius: 0 0 5px 5px;
    flex-direction: column-reverse;
  }

  .rbc-time-header-content {
    .rbc-row {
      .rbc-time-header-cell {
        background: white;
        .rbc-today  {
          color: #00a1a5;

          a  {
            span {
              color: #00a1a5;
            }
          }
        }
      }
    }
  }

  .cell-item-title {
    font-family: 'plain';
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.3em;
    margin-left: 8px;
  }

  .cell-item-desc {
    line-height: 1.1em;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .rbc-ellipsis,
  .rbc-event-label,
  .rbc-show-more {
    display: block;
    overflow: initial;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  .rbc-row-segment {
    .rbc-event-content {
      display: block;
      overflow: initial;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }
  }

  .rbc-agenda-time-cell,
  .rbc-agenda-date-cell {
    font-size: 14px;
  }

  .rbc-event.rbc-selected {
    width: auto !important;
  }

  .rbc-event {
    position: relative;
    width: auto;
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.45);
    }
  }
  .rbc-off-range-bg {
    opacity: 0;
  }
  .rbc-date-cell.rbc-off-range {
    visibility: hidden;
    opacity: 0;
  }
  .rbc-event-content {
    position: relative;
    z-index: 2;
  }

  .rbc-row-segment {
    font-size: 10px;
    line-height: 14px;
    color: #bcc5d7;
    background: white;
  }

  .rbc-month-view {
    height: auto;
  }

  .rbc-day-slot {
    .rbc-event-content {
      z-index: auto;
    }
    .rbc-event {
      border: none;
      position: absolute;
      .rbc-event-label {
        margin-bottom: 4px;
        margin-top: 1px;
      }
      .cell-item {
        padding: 0;
        height: 100%;
        overflow: hidden;

        .cell-item-desc {
          display: none;
        }
      }
    }
  } */
`;
