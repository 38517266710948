import * as React from 'react';
import { PlanningPriceStyle } from './planning-price-style';
import BodyText from '../../../../components/text/body-text/body-text';
import InputBox from '../../../../components/input-box';
import SingleButton from '../../../../components/single-button/single-button';
import { images } from '../../../../assets/images';
import MainButton from '../../../../components/main-button';
import IStoreState from '../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import Checkbox from '../../../../components/form-components/checkbox/checkbox';

interface PlanningPriceProps {
  state?: PlanningPriceState;
  price?: number;
  onPrev: () => void;
  onNext: (state: PlanningPriceState, price: number) => void;
  centreCurrency?: string;
}

export interface PlanningPriceState {
  price: number;
  error: string;
}

class PlanningPrice extends React.Component<PlanningPriceProps, PlanningPriceState> {
  constructor(props: PlanningPriceProps) {
    super(props);
    this.state = {
      price: NaN,
      error: '',
    };
  }
  componentDidMount() {
    const { price, state } = this.props;
    if (state) {
      this.setState(state);
    } else if (price) {
      this.setState({ price: price / 100 });
    }
  }

  componentDidUpdate(prevProps: PlanningPriceProps) {
    const { price } = this.props;
    if (price && prevProps.price !== price) {
      this.setState({ price: price / 100 });
    }
  }

  handleNext() {
    const { onNext } = this.props;
    const { price } = this.state;
    if (!price) {
      this.setState({ error: 'required' });
    } else {
      this.setState({ error: '' });
      onNext(this.state, Math.round(price * 100));
    }
  }

  render() {
    const { onPrev, centreCurrency } = this.props;
    const { price, error } = this.state;
    return (
      <PlanningPriceStyle>
        <div className="planning-price-disclaimer">
          <BodyText>
            Puedes editar el precio de tu actividad si lo deseas o mantener el precio configurado en la plantilla
            original.
          </BodyText>
        </div>
        <div className="planning-price-form">
          <div className="planning-price-form__item">
            <InputBox
              className="rounded"
              required={true}
              type="number"
              errorCode={error}
              value={price.toString()}
              placeholder={`Precio de la actividad (${centreCurrency})`}
              labelText=""
              onChange={price => this.setState({ price: parseFloat(price) })}
              withTooltip={false}
            />
          </div>
        </div>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            <div className="modal-create-activities-buttons-left__back">
              <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
            </div>
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={'Siguiente'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </PlanningPriceStyle>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    centreCurrency: state.centreCurrency.value,
  };
}

export default connect(mapStateToProps)(PlanningPrice);
