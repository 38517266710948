import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ItemFileContainer = styled.div`
  border-radius: 5px;
  background: ${colors['NE-002/10']};
  width: 32px;
  height: 32px;
  .item-file-icon {
    padding: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  &.pdf {
    color: ${colors['CO-003/100']};
  }
  &.doc {
    color: ${colors['SE-002/100']};
  }
  &.png {
    color: ${colors['CO-004/100']};
  }
  &.jpg {
    color: ${colors['PR-001/100']};
  }
  &.xls {
    color: ${colors['CO-001/100']};
  }
  &.image {
    overflow: hidden;
    .item-file-icon {
       padding: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        min-height: 100%;
      }
    }
  }
`;
