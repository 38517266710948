import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalInviteStaffContainer = styled.div`
  width: 780px;
  padding: 62px 32px 32px;
  .modal-invite-staff-activity {
    font-family: Plain;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 2px;
    color: ${colors['SE-002/100']};
  }

  .modal-invite-staff-title {
    font-family: Plain;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: ${colors['NE-001/100']};
    margin-bottom: 40px;
  }

  .modal-invite-staff-details-container {
    border-radius: 5px;
    background: ${colors['NE-002/10']};
    padding: 28px 20px;
    margin-bottom: 40px;
    .modal-invite-staff-details-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: ${colors['NE-001/100']};
      margin-bottom: 12px;
    }
    .modal-invite-staff-details-date {
      font-family: Plain;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .modal-invite-staff-subtitle {
    font-family: Plain;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: ${colors['NE-001/100']};
    margin-bottom: 14px;
  }

  .modal-invite-staff-info-container {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    margin-bottom: 40px;
    .modal-invite-staff-input {
      width: 577px;
    }
    .modal-invite-staff-add-button {
      width: 119px;
    }
  }

  .modal-invite-staff-checkbox {
    margin-bottom: 50px;
  }

  .modal-invite-staff-send-button {
    width: 144px;
  }
`;
