export const BOOK_BEFORE_DROPDOWN = [
  { value: 'sameDay', label: 'Mismo dia' },
  { value: 'day', label: '1 dia' },
  { value: 'week', label: '1 semana' },
];

export const BOOK_BEFORE_KEY_VALUES: any = {
  sameDay: 'Mismo dia',
  day: '1 dia',
  week: '1 semana',
};
