import React from 'react';
import ModalContainer from '../modals/modal-container';
import { ModalGenericFileStyle } from './modal-generic-file-style';
import InputUploadFiles from '../input-upload-file/input-upload-file';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../text/body-text/body-text';
import ItemFile from '../item-file/item-file';
import InfoText from '../text/info-text/info-text';
import MainButton from '../main-button';
import { AttachedFile } from '../../types/file';
import moment from 'moment';
import FileUtils from '../../utils/files';
import { images } from '../../assets/images';

interface ModalCreateCatalogProps {
  showModal: boolean;
  onCancel: () => void;
  onSave: (selectedFile: AttachedFile | null) => void;
  edit: boolean;
  title: string;
  text?: string;
}
interface ModalCreateStaffState {
  selectedFile: AttachedFile | null;
}

export default class ModalCreateCatalog extends React.Component<ModalCreateCatalogProps, ModalCreateStaffState> {
  constructor(props: ModalCreateCatalogProps) {
    super(props);
    this.state = {
      selectedFile: null,
    };
  }

  render() {
    const { showModal, onCancel, edit, onSave, title, text } = this.props;
    const { selectedFile } = this.state;

    return (
      <ModalContainer title={''} active={showModal} modalClose={() => onCancel()}>
        <ModalGenericFileStyle>
          <div className="modal-generic-file-title">
            <SubheadDestacadoText>{title}</SubheadDestacadoText>
          </div>
          <div className="modal-generic-file-subtitle">
            <BodyText>{text}</BodyText>
          </div>
          <div className="modal-generic-file-subtitle-input">
            <InputUploadFiles
              multiple={false}
              required={true}
              label=""
              onChange={(file: AttachedFile[]) => {
                this.setState({ selectedFile: file[0] });
              }}
              accept={['*']}
              value={''}
            />
          </div>

          {/* Añadir Item */}
          {selectedFile && (
            <div className="modal-generic-file-container">
              <div className="card-file">
                <div className="card-file__type">
                  <ItemFile type={FileUtils.getExtension(selectedFile.name)} />
                </div>
                <div className="card-file__text">
                  <InfoText className="card-file__text__file">{selectedFile.name}</InfoText>
                  <InfoText className="card-file__text__date">
                    {moment(selectedFile.data.lastModifiedDate).format('DD/MM/YYYY')}
                  </InfoText>
                </div>

                {/* <div className="card-file__delete" onClick={() => this.setState({ selectedFile: null })}>
                  <img src={images.deleteSvg} />
                </div> */}
              </div>
            </div>
          )}
          <div className="modal-generic-file-button">
            <MainButton
              type={'primary'}
              text="Guardar"
              onClick={() => {
                onSave(selectedFile);
              }}
              disabled={!selectedFile}
            />
          </div>
        </ModalGenericFileStyle>
      </ModalContainer>
    );
  }
}
