import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ModalCreateCatalogStyle = styled.div`
  width: 440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 32px;
  padding-top: 48px;

  .modal-create-catalog {
    &-title {
      margin-top: 8px;
      margin-bottom: 48px;
    }

    &-step {
      font-family: Plain;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #007d91;
    }
  }

  // Buttons of next / previous
  .item-modal-message-bottom {
    display: flex;
    //flex-direction: column;

    .item-modal-message-button {
      margin-right: 20px;
      width: 100%;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .item-modal-message-container {
    &__title {
      margin-bottom: 14px;
      color: ${colors['SE-002/100']};
    }
  }

  .modal-create-add-languages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;
  }
`;
