import { Certifier } from '../../types/company';
import { TableColumn } from '../../types/table';

interface Activity {
  id: string;
  name: string;
}

interface Dive {
  date: string;
}

export interface Diver {
  id: string;
  name: string;
  surname: string;
  picture: string;
  bookings: any[]; // Activity[];
  level: string;
  certifiers?: Certifier[];
  dives: Dive[];
  ghost: boolean;
}

export const tableColumns: TableColumn[] = [
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Nombre',
    filter: false,
    ascendent: true,
  },/*
  {
    text: 'Actividad',
    filter: false,
    ascendent: true,
  },*/
  {
    text: '',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Titulación',
    filter: false,
    ascendent: true,
  },
 /* {
    text: 'Certificadora',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Inmersiones',
    filter: false,
    ascendent: true,
  },
  {
    text: 'Última inmersión',
    filter: false,
    ascendent: true,
  },*/
  {
    text: '',
    filter: false,
    ascendent: true,
  },
];

export const rowKeys: string[] = [
  'avatar',
  'name',
  //'activity',
  'activity-count',
  'level',
  //'certifier',
  //'dives',
  //'last-dive',
  'detail',
];
