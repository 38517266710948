import React from 'react';
import { ZeroContentStyle } from './zero-content-style';

interface IZeroContentProps {
  icon?: string;
  text: string;
}

const ZeroContent: React.FC<IZeroContentProps> = (props: IZeroContentProps) => {
  const { icon, text } = props;
  return (
    <ZeroContentStyle>
      <div className="zero-content-icon">{icon ? <img src={icon} alt="" /> : 'Zero'}</div>
      <div className="zero-content-text">
        <p>{text}</p>
      </div>
    </ZeroContentStyle>
  );
};

export default ZeroContent;
