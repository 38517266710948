import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ModalContainerStyle = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  z-index: 999;

  &.max-index {
    z-index: 9999;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-container-content {
    background: white;
    position: relative;
    margin: 100px auto;
    /* padding: 32px 68px; */
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-radius: 5px;
    z-index: 10;
  }
  .modal-container-header {
    display: flex;
    justify-content: space-between;
    padding: 32px 32px 40px 32px;
    &__title {
      margin-right: 20px;
      color: ${colors['NE-001/100']};
    }
    &__close {
      cursor: pointer;
      width: 28px;
      height: 28px;
      z-index: 999;
    }
  }
  .modal-container-veil {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #0d1e3f;
    opacity: 0.5;
    z-index: 0;
  }

  .modal-container-close {
    display: flex;
    justify-content: flex-end;
    z-index: 99;
    &__button {
      cursor: pointer;
      width: 28px;
      height: 28px;
      position: absolute;
      right: 32px;
      top: 32px;
    }
  }
`;
