import React from 'react';
import LegalStyle from './legal-style';

const Legal = () => {
  return (
    <LegalStyle>
      <div className="wrapper-legal">
        <h2 className="title">Terms and Conditions</h2>

        <div className="text">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus
            errort amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus error et soluta
            dolorem atque ea magni laborum repellat architecto voluptatum distinctio nostrum officia deserunt. Deleniti.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus error
            et soluta dolorem atque ea magni laborum repellat architecto voluptatum distinctio nostrum officia deserunt.
            Deleniti.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus error
            et soluta dolorem atque ea magni laborum repellat architecto voluptatum distinctio nostrum officia deserunt.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus error
            et soluta dolorem atque ea magni laborum repellat architecto voluptatum distinctio nostrum officia deserunt.
            Deleniti.
          </p>
        </div>

        <div className="text">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus error
            et soluta dolorem atque ea magni laborum repellat architecto voluptatum distinctio nostrum officia deserunt.
            Deleniti.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus error
            et soluta dolorem atque ea magni laborum repellat architecto voluptatum distinctio nostrum officia deserunt.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus error
            et soluta dolorem atque ea magni laborum repellat architecto voluptatum distinctio nostrum officia deserunt.
            Deleniti.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus error
            et soluta dolorem atque ea magni laborum repellat architecto voluptatum distinctio nostrum officia deserunt.
            Deleniti.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla beatae, illo, sapiente tempore ducimus error
            et soluta dolorem atque ea magni laborum repellat architecto voluptatum distinctio nostrum officia deserunt.
            Deleniti.
          </p>
        </div>

        <div className="table-cookies">
          <table>
            <tr>
              <th>Heading</th>
              <th>Basic info 1st layer</th>
            </tr>
            <tr>
              <td>DATA CONTROLLER</td>
              <td>WANADIVEASAP S.L C/ Salvador Jiménez no 3B, 30880, Águilas, Murcia</td>
            </tr>
            <tr>
              <td>PURPOSE OF PROCESSING</td>
              <td>
                Provide information about DIVEASAPP, as well as offers to eventually purchase a DIVEASAPP product and/or
                service. (CHECK THE I ACCEPT BOX.
              </td>
            </tr>
            <tr>
              <td>PURPOSE OF PROCESSING</td>
              <td>
                Provide information about DIVEASAPP, as well as offers to eventually purchase a DIVEASAPP product and/or
                service. (CHECK THE I ACCEPT BOX.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </LegalStyle>
  );
};

export default Legal;
