import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CardBonusStyle = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
  padding: 24px 20px;
  position: relative;
  overflow: hidden;
  &::after  {
    content: '';
    width: 100%;
    position: absolute;
    height: 8px;
    bottom: 0px;
    left: 0px;
    background: ${colors['SE-002/100']};
  }
  .card-bonus-title {
    margin-bottom: 6px;
    color: ${colors['NE-001/100']};
  }
  .card-bonus-subtitle {
    color: ${colors['SE-002/100']};
    text-transform: uppercase;
  }

  &.blue {
    background: ${colors['SE-002/100']};
    .card-bonus-title {
      color: white;
    }
    .card-bonus-subtitle {
      color: white;
    }
  }
`;
