import moment from 'moment';

export const getExpirationDate = (activity: any) => {
  let activityExpDate: any;

  if (activity.timeBlocks.length > 1) {
    const activityLastDate = activity.timeBlocks.reduce((cv: any, ac: any) => (cv.end.date > ac.end.date ? cv : ac))
      .end;
    activityExpDate = moment(activityLastDate.date).set({
      hour: activityLastDate.hour,
      minutes: activityLastDate.minutes,
    });
  } else {
    activityExpDate = moment(activity.date).set({
      hour: activity.timeBlocks && activity.timeBlocks.length ? activity.timeBlocks[0].end.hour : '',
      minutes: activity.timeBlocks && activity.timeBlocks.length ? activity.timeBlocks[0].end.minutes : '',
    });
  }

  return activityExpDate;
};
