export const LOADING = 'LOADING';
export const EXPIRED = 'EXPIRED';
export const AGENT = 'AGENT';
export const NOTIFICATION = 'NOTIFICATION';
export const SUCCESS = 'SUCCESS';
export const VALID_CENTRE = 'VALID_CENTRE';
export const STAFF_PERMISSIONS = 'STAFF_PERMISSIONS';
export const CENTRE_CURRENCY = 'CENTRE_CURRENCY';
export const CENTRE_STRIPE_USER_ID = 'CENTRE_STRIPE_USER_ID';
export const USERDATA = 'USERDATA';
export const BOOKING_LINES = 'BOOKING_LINES';
export const STRIPE_TOKEN_ID = 'STRIPE_TOKEN_ID';
export const IS_DIVER_SELECTED = 'IS_DIVER_SELECTED';
export const BOOKING_CANCELLED = 'BOOKING_CANCELLED';
export const BOOKING_PAYMENT_DATA = 'BOOKING_PAYMENT_DATA';
export const REFUNDS_BOOKING = 'REFUNDS_BOOKING';
export const START_ACTIVITY_DATE = 'START_ACTIVITY_DATE';
export const ACCOUNT_DIVERS = 'ACCOUNT_DIVERS';
export const ACCOUNT_DIVERS_GHOST = 'ACCOUNT_DIVERS_GHOST';
