import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalChangePassStyle = styled.div`
  padding: 36px 32px 32px 32px;
  width: 416px;

  .modal-change-pass-title {
    margin-bottom: 12px;
  }
  .modal-change-pass-text {
    margin-bottom: 28px;
    color: ${colors['NE-002/100']};
  }
  .modal-change-pass-form {
    margin-bottom: 38px;
  }
`;
