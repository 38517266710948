import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import { MainLayoutWithHeaderSearch } from '../../../main-layout-style';

export const BillsSuperAdminLayoutStyle = styled(MainLayoutWithHeaderSearch)`
  display: flex;
  flex-direction: column;
  height: 100%;

  & .bill-button {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${colors['NE-002/10']};
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      width: 13.47px;
      height: 13.47px;
    }
  }
`;
