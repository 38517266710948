import * as React from 'react';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter, match } from 'react-router';
import { BookingsStyle } from './bookings-style';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection } from '../../main-layout-style';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import { getBookingsCentre, getBookingsActivity, getBookings } from '../../../services/bookings';
import { connect } from 'react-redux';
import IStoreState from '../../../redux/store/IStoreState';
import { currencyFormat } from '../../../utils/other';
import { DiveDisciplinesKeyValues } from '../../../constants/dive-dropdowns';
import { NavigationTabContainer } from '../../../components/navigation-tabs/navigation-tabs-style';
import BookingsType from '../bookings/bookings-type/bookings-type'
import { getOneActivities } from '../../../services/activities';
import { getOneBookingPayment } from '../../../services/bookingPayment';
import { createFilterRequest } from '../../../utils/other';
import { images } from '../../../assets/images';


const PAGINATION_LIMIT = 1000;


export interface IBookingsProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
  }>;
  centreCurrency?: string;
  activity?: any;
}

export interface IBookingsState {
  noResult: any;
  modal: string;
  preResult: any[];
  superAdmin: boolean;
  page: number;
  pages: number;
  searchValue: string | null;
  skipPage: number;
  section: string;
  subsection?: string | null;
  bookings: any[];
  bookingsConfirmed: any[];
}

class Bookings extends React.Component<IBookingsProps, IBookingsState> {
  constructor(props: IBookingsProps) {
    super(props);

    this.state = {
      noResult: null,
      modal: '',
      preResult: [],
      page: 1,
      pages: 1,
      superAdmin: false,
      searchValue: '',
      skipPage: 0,
      section: 'solicitudes',
      subsection: 'rechazadas',
      bookings: [],
      bookingsConfirmed: [],
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;
    if (!id && history.location.pathname.indexOf('centres') === -1) {
      this.setState({ superAdmin: true }, () => {
        this.getBookingsData();
        this.getBookingsDataConfirmed();
      });
    } else {
      this.getBookingsData();
      this.getBookingsDataConfirmed();
    }
  }

  getBookingsDataConfirmed = async () => {
    try {
      const {
        match: {
          params: { id },
        }, activity
      } = this.props;

      const { searchValue, skipPage } = this.state;

      const params: any = {
        filter: {
          //skip: skipPage,
          where: { confirmed: true },
          limit: PAGINATION_LIMIT,
          orderBy: [{ key: 'bookingId', direction: 'DESC' }],
          relations: ['activity']
        },
      };

      if (searchValue) {
        const filters: any = {};
        filters.bookingId = { value: searchValue };
        params.filter.where = createFilterRequest(filters);
      }

      let res: any = {};
      if (activity) {
        res = await getBookingsActivity(activity.id, params);
      } else {
        if (this.state.superAdmin) {
          res = await getBookings(params);
        } else {
          res = await getBookingsCentre(id, params);
        }
      }

      const bookingsConfirmed: any = res.data.data;
      for (const booking of bookingsConfirmed) {
        const params = {
          filter: {
            relations: ['sites', 'staff_activities', 'staff_activities.staff', 'staff.user'],
          },
        };
        const activity = (await getOneActivities(booking.activity.id, params)).data;
        const params2 = {
          filter: {
            relations: ['paymentType'],
          },
        };


        booking.payment = (await getOneBookingPayment(booking.payment.id, params2)).data;
        booking.activityCancelled = activity.cancelled;
        booking.activity = activity.cancelled ? '' : activity.name;
        booking.activityDate = activity.cancelled ? '' : activity.date;
        //booking.timeBlocks = activity.cancelled ? [] : activity.timeBlocks;
        booking.timeBlocks = activity.timeBlocks;
        booking.activityId = activity.id;
        booking.discipline = DiveDisciplinesKeyValues[activity.discipline];
        booking.rejected = booking.rejected;
        booking.id = booking.id;
        booking.bookingId = booking.bookingId;
        booking.confirmed = booking.confirmed;
        booking.cancelled = booking.cancelled;
        booking.isCancelled = booking.cancelled === 'noCancelled' ? false : true;
        booking.createdAt = booking.createdAt;
        booking.numDivers = booking.divers.length > 0 ? booking.divers.length + 1 : 1;
        booking.price = Object.keys(booking.lines).length != 0 ? `${currencyFormat(booking.lines.reduce((a: number, b: any) => a + b.price, 0))} ${typeof this.props.centreCurrency === 'string' ? this.props.centreCurrency : '€'}` : '';
        booking.status = this.getStatus(booking);
        booking.source = booking.cardExample;
        booking.centreId = id;
        booking.comments = booking.comments;
      }
      //this.setState({ bookings, pages: Math.ceil(res.data.count / PAGINATION_LIMIT) });

      this.setState({ bookingsConfirmed });

    } catch (err) {
      console.log(err);
    }
  };


  getBookingsData = async () => {
    try {
      const {
        match: {
          params: { id },
        }, activity
      } = this.props;

      const { searchValue, skipPage } = this.state;

      const params: any = {
        filter: {
          //skip: skipPage,
          where: { confirmed: false },
          limit: PAGINATION_LIMIT,
          orderBy: [{ key: 'bookingId', direction: 'DESC' }]
        },
      };

      if (searchValue) {
        const filters: any = {};
        filters.bookingId = { value: searchValue };
        params.filter.where = createFilterRequest(filters);
      }

      let res: any = {};
      if (activity) {
        res = await getBookingsActivity(activity.id, params);
      } else {
        if (this.state.superAdmin) {
          res = await getBookings(params);
        } else {
          res = await getBookingsCentre(id, params);
        }
      }

      const bookings: any = res.data.data;




      for (const booking of bookings) {
        const params = {
          filter: {
            relations: ['sites', 'staff_activities', 'staff_activities.staff', 'staff.user'],
          },
        };
        const activity = (await getOneActivities(booking.activity.id, params)).data;
        const params2 = {
          filter: {
            relations: ['paymentType'],
          },
        };

        booking.payment = (await getOneBookingPayment(booking.payment.id, params2)).data;
        booking.activityCancelled = activity.cancelled;
        booking.activity = activity.cancelled ? '' : activity.name;
        booking.activityDate = activity.cancelled ? '' : activity.date;
        //booking.timeBlocks = activity.cancelled ? [] : activity.timeBlocks;
        booking.timeBlocks = activity.timeBlocks;
        booking.activityId = activity.id;
        booking.discipline = DiveDisciplinesKeyValues[activity.discipline];
        booking.rejected = booking.rejected;
        booking.id = booking.id;
        booking.bookingId = booking.bookingId;
        booking.confirmed = booking.confirmed;
        booking.cancelled = booking.cancelled;
        booking.isCancelled = booking.cancelled === 'noCancelled' ? false : true;
        booking.createdAt = booking.createdAt;
        booking.numDivers = booking.divers ? booking.divers.length + 1 : 1;
        booking.price = Object.keys(booking.lines).length != 0 ? `${currencyFormat(booking.lines.reduce((a: number, b: any) => a + b.price, 0))} ${typeof this.props.centreCurrency === 'string' ? this.props.centreCurrency : '€'}` : '';
        booking.status = this.getStatus(booking);
        booking.source = booking.cardExample;
        booking.centreId = id;
        booking.comments = booking.comments;
      }
      //this.setState({ bookings, pages: Math.ceil(res.data.count / PAGINATION_LIMIT) });

      this.setState({ bookings });

    } catch (err) {
      console.log(err);
    }
  };

  getStatus(booking: any) {

    //Solicitudes
    if (!booking.isCancelled && !booking.confirmed) {
      if (booking.rejected) return this.props.t(`layouts:bookings.status.rejected`);
      return this.props.t(`layouts:bookings.status.pending`);

      //Confirmadas
    } else if (!booking.isCancelled && booking.confirmed && !this.isActivityDone(booking.activityDate)) {
      if (booking.activityCancelled) return this.props.t(`layouts:bookings.status.pendingChange`);
      //Pagos
      if (booking.payment.paymentType) {
        if (booking.payment.paymentType.name === 'Stripe') {
          if (booking.payment.paymentResponse) {
            if (booking.payment.paymentCorrect) {
              return this.props.t(`layouts:bookings.status.onlinePayment`);
            } else {
              return this.props.t(`layouts:bookings.status.errorPayment`);
            }
          } else {
            return this.props.t(`layouts:bookings.status.pendingPayment`);
          }
        } else {
          return this.props.t(`layouts:bookings.status.CTBPayment`)
        }
      }

      //Historial
    } else if (!booking.isCancelled && booking.confirmed && this.isActivityDone(booking.activityDate)) {
      if (booking.presented) return this.props.t(`layouts:bookings.status.finished`);
      return this.props.t(`layouts:bookings.status.didNotShow`);

      //Cancelaciones
    } else if (booking.isCancelled) {
      if (booking.cancelled === 'canceledByUser') return this.props.t(`layouts:bookings.status.canceledDiver`);
      return this.props.t(`layouts:bookings.status.canceledCenter`);
    }
  }

  async reloadBookings(section: string) {
    this.getBookingsData();
    this.setState({ section });
    window.location.reload();
  }

  isActivityDone(activityDate: any) {
    const actualDate = new Date();
    const bookingDate = new Date(activityDate);
    return actualDate > bookingDate;
  }

  renderContent() {
    const { section, bookings, bookingsConfirmed } = this.state;
    if (bookings || bookingsConfirmed) {
      switch (section) {
        case 'solicitudes':
          return (
            <BookingsType
              section={'solicitudes'}
              subsection={'rechazadas'}
              bookings={bookings.filter((booking: any) => !booking.isCancelled && !booking.confirmed && !booking.rejected)}
              bookingsRejects={bookings.filter((booking: any) => !booking.isCancelled && !booking.confirmed && booking.rejected)}
              onReload={() => this.reloadBookings('solicitudes')}
            />
          );
        case 'confirmadas':
          return (
            <BookingsType
              section={'confirmadas'}
              bookings={bookingsConfirmed.filter((booking: any) => !booking.isCancelled && booking.confirmed && !this.isActivityDone(booking.activityDate))}
              onReload={() => this.reloadBookings('confirmadas')}
            />);
        case 'historial':
          return (
            <BookingsType
              section={'historial'}
              bookings={bookingsConfirmed.filter((booking: any) => !booking.isCancelled && booking.confirmed && this.isActivityDone(booking.activityDate))}
              onReload={() => this.reloadBookings('historial')}
            />);
        case 'cancelaciones':
          return (
            <BookingsType
              section={'cancelaciones'}
              bookings={bookingsConfirmed.filter((booking: any) => booking.isCancelled)}
              onReload={() => this.reloadBookings('cancelaciones')}
            />);
        default:
          break;
      }
    }
  }

  searchTimeout: any;
  handleSearch(value: string | null) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState({ searchValue: value, page: 1, skipPage: 0 }, () => {
        this.getBookingsData();
      });
    }, 500);
  }

  render() {
    const { t } = this.props;
    const { noResult, section } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;


    /*mainButton={{
      text: 'Crear reserva',
      onClick: () => { this.props.history.push(`/centres/${id}/billing/bookings/create`) }
    }}*/

    return (
      <BookingsStyle>
        <HeaderMain
          className={classNames({ 'search-empty': noResult })}
          title={t('layouts:bookings.header')}
          withSearch={true}
          handleSearch={(value: string | null) => this.handleSearch(value)}
        />
        <MainLayoutSection>
          <NavigationTabContainer>
            <div
              className={`navigation-item ${section === 'solicitudes' && 'active'}`}
              onClick={() => this.setState({ section: 'solicitudes' })}
            >
              <BodyDestacadoText>{t('modalMessages:solic').toUpperCase()}</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${section === 'confirmadas' && 'active'}`}
              onClick={() => this.setState({ section: 'confirmadas' })}
            >
              <BodyDestacadoText>{t('modalMessages:confirmed').toUpperCase()}</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${section === 'historial' && 'active'}`}
              onClick={() => this.setState({ section: 'historial' })}
            >
              <BodyDestacadoText>{t('modalMessages:hist').toUpperCase()}</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${section === 'cancelaciones' && 'active'}`}
              onClick={() => this.setState({ section: 'cancelaciones' })}
            >
              <BodyDestacadoText>{t('modalMessages:canceled').toUpperCase()}</BodyDestacadoText>
            </div>

          </NavigationTabContainer>
          {this.renderContent()}
        </MainLayoutSection>
      </BookingsStyle>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    centreCurrency: state.centreCurrency.value,
    loading: state.loading.value,
  };
}

export default connect(mapStateToProps)(
  withTranslation(['intro', 'buttons', 'tables', 'layouts', 'modalMessages'])(withRouter(Bookings))
);
