import * as React from 'react';
import { DiveSiteRateStyle } from './dive-site-rate-style';
import H2Text from '../../../components/text/h2-text/h2-text';
import StarRate from '../../../components/star-rate/star-rate';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import { images } from '../../../assets/images';
import ItemReview from '../../../components/item-review/item-review';
import Pagination from '../../../components/pagination';
import { RouteComponentProps, withRouter } from 'react-router';
import { getOneDiveSites } from '../../../services/dive-sites';
import { getDateMonthAndYearString } from '../../../utils/time';
import { REVIEWS_PER_PAGE } from '../../../constants/data';
import { getDiveSiteRatings } from '../../../services/dive-site-raiting';

interface RouteParams {
  id: string;
  siteId: string;
}

export interface IDiveSiteRateProps extends RouteComponentProps<RouteParams> {}

export interface IDiveSiteRateState {
  diveSite: any;
  reviews: [];
  total: number;
  totalPages: number;
  skipPage: number;
  siteId: string;
  page: number;
}

class DiveSiteRate extends React.Component<IDiveSiteRateProps, IDiveSiteRateState> {
  constructor(props: IDiveSiteRateProps) {
    super(props);

    this.state = {
      diveSite: {
        visibilityRating: 0,
        flowRating: 0,
        marineLifeRating: 0,
      },
      reviews: [],
      total: 0,
      totalPages: 0,
      skipPage: 0,
      siteId: '',
      page: 1,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const siteId = match.params.siteId ? match.params.siteId : match.params.id;
    if (siteId) {
      this.setState({ siteId }, () => {
        this.getDiveSite(siteId);
        this.getReviews(siteId);
      });
    }
  }

  getDiveSite = async (id: string) => {
    try {
      const params = {};
      const response: any = await getOneDiveSites(id, params);

      this.setState({ diveSite: response.data });
    } catch (err) {}
  };

  getReviews = async (id: string) => {
    const { skipPage } = this.state;
    const params = {
      filter: {
        where: {
          id: { value: id, collection: 'site' },
        },
        skip: skipPage,
        limit: REVIEWS_PER_PAGE,
        relations: ['site', 'diver', 'diver.user'],
      },
    };

    const response: any = await getDiveSiteRatings(params);
    const reviews = response.data.data
      ? response.data.data.map((r: any) => ({
          img: r.diver && r.diver.user && r.diver.user.picture ? r.diver.user.picture : images.jellyfishIntro,
          name: r.diver && r.diver.user ? `${r.diver.user.name} ${r.diver.user.surname}` : '-',
          date: getDateMonthAndYearString(r.createdAt),
          comment: [r.comment],
          visibility: r.visibility,
          flowing: r.flow,
          diversity: r.marineLife,
        }))
      : [];

    const totalPages: number = Math.ceil(response.data.count / REVIEWS_PER_PAGE);
    this.setState({ totalPages, reviews, total: response.data.count });
  };

  setPage(page: number) {
    const { total, siteId } = this.state;
    const skip: number = REVIEWS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        if (siteId) {
          this.getReviews(siteId);
        }
      });
    }
  }

  render() {
    const { diveSite, reviews, totalPages, total, page } = this.state;
    const rating: number = diveSite.visibilityRating + diveSite.flowRating + diveSite.marineLifeRating;
    return (
      <DiveSiteRateStyle>
        <div className="dive-site-rate-hero">
          <div className="dive-site-rate-hero-total">
            <div className="dive-site-rate-hero-total__number">
              <H2Text>{rating}</H2Text>
            </div>
            <div className="dive-site-rate-hero-total__stars">
              <StarRate type={'big'} number={rating / 2} />
            </div>
            <div className="dive-site-rate-hero-total__reviews">
              <SubtitleText>{total} evaluaciones</SubtitleText>
            </div>
          </div>

          <div className="dive-site-rate-hero-detail">
            <div className="dive-site-rate-hero-detail__item">
              <div className="dive-site-rate-hero-detail__item__text">
                <SubheadDestacadoText>Visibilidad</SubheadDestacadoText>
              </div>
              <div className="dive-site-rate-hero-detail__item__stars">
                <StarRate type="middle" number={diveSite.visibilityRating / 2} />
              </div>
            </div>
            <div className="dive-site-rate-hero-detail__item">
              <div className="dive-site-rate-hero-detail__item__text">
                <SubheadDestacadoText>Corriente</SubheadDestacadoText>
              </div>
              <div className="dive-site-rate-hero-detail__item__stars">
                <StarRate type="middle" number={diveSite.flowRating / 2} />
              </div>
            </div>
            <div className="dive-site-rate-hero-detail__item">
              <div className="dive-site-rate-hero-detail__item__text">
                <SubheadDestacadoText>Vida marina</SubheadDestacadoText>
              </div>
              <div className="dive-site-rate-hero-detail__item__stars">
                <StarRate type="middle" number={diveSite.marineLifeRating / 2} />
              </div>
            </div>
          </div>
        </div>

        <div className="dive-site-rate-reviews">
          {reviews.map((item: any, index: number) => (
            <div key={index} className="dive-site-rate-reviews__item">
              <ItemReview
                img={item.img}
                name={item.name}
                date={item.date}
                comment={item.comment}
                visibility={item.visibility}
                flowing={item.flowing}
                diversity={item.diversity}
              />
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <div className="dive-site-rate-pagination">
            <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
          </div>
        )}
      </DiveSiteRateStyle>
    );
  }
}

export default withRouter(DiveSiteRate);
