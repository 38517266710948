import React from 'react';
import { TextTextContainer } from './text-text-styles';

interface TextTextProps {
  children: any;
  className?: string;
  color?: string;
}

const TextText = ({ children = '', className = '', color = '' }: TextTextProps) => {
  return (
    <TextTextContainer color={color} className={className}>
      {children}
    </TextTextContainer>
  );
};
export default TextText;
