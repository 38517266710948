import React from 'react';
import { InputAreaContainer } from './input-area-style';
import { ERROR_MESSAGES_ES } from '../../constants/error-messages';
import InfoText from '../text/info-text/info-text';
import LabelText from '../text/label-text/label';
import BodyText from '../text/body-text/body-text';

interface InputAreaProps {
  autoFocus?: boolean;
  placeholder?: string;
  value: string;
  name: string;
  disabled?: boolean;
  autoComplete?: string;
  required: boolean;
  onFocus?: Function;
  onChange?: Function;
  onBlur?: Function;
  className?: string;
  labelText?: string;
  errorText?: string;
  error?: boolean;
  showMessage?: boolean;
  errorCode?: string;
  textAreaRef?: any;
}
interface InputState {
  hidden: boolean;
}

class InputArea extends React.Component<InputAreaProps, InputState> {
  constructor(props: InputAreaProps) {
    super(props);

    this.state = { hidden: false };
    this.input = React.createRef();
  }
  input: any;

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      if (this.input != null) {
        this.input.focus();
      }
    }

    let observe: any;
    if ((window as any).attachEvent) {
      observe = function (element: any, event: any, handler: any) {
        element.attachEvent(`on ${event}`, handler);
      };
    } else {
      observe = function (element: any, event: any, handler: any) {
        element.addEventListener(event, handler, false);
      };
    }
    const text = this.input;
    function resize() {
      if (text !== null) {
        text.style.height = `${text.scrollHeight} px`;
        text.style.height = 'auto';
      }
    }
    /* 0-timeout to get the already changed text */
    function delayedResize() {
      window.setTimeout(resize, 0);
    }
    observe(text, 'change', resize);
    observe(text, 'cut', delayedResize);
    observe(text, 'paste', delayedResize);
    observe(text, 'drop', delayedResize);
    observe(text, 'keydown', delayedResize);

    resize();
  }

  renderTitle(text: string) {
    return <p>{text}</p>;
  }

  renderInput() {
    const {
      placeholder = '',
      value = '',
      name = '',
      disabled = false,
      autoComplete = 'off',
      required = false,
      onFocus = () => { },
      onChange = () => { },
      onBlur = () => { },
      textAreaRef = () => { },
    } = this.props;
    const { hidden } = this.state;
    return (
      <textarea
        ref={c => {
          this.input = c;
          if (textAreaRef) {
            textAreaRef(c);
          }
        }}
        autoComplete={autoComplete}
        placeholder={placeholder}
        value={value}
        onFocus={ev => onFocus(ev)}
        onChange={ev => onChange(ev)}
        onBlur={ev => onBlur(ev)}
        name={name}
        disabled={disabled}
        required={required}
      />
    );
  }

  renderMessage() {
    const { errorText = '', errorCode } = this.props;
    const errorText2 = errorCode ? ERROR_MESSAGES_ES[errorCode] : '';
    if (errorText === '' && errorText2 === '') return null;
    return <p>{errorText || errorText2}</p>;
  }

  render() {
    const { className, labelText, error, showMessage, required } = this.props;
    return (
      <InputAreaContainer className={`${required && 'required'} ${className} ${error && 'error'}`}>
        {labelText && (
          <div className="input-s-title">
            <LabelText>{this.renderTitle(labelText)}</LabelText>
          </div>
        )}
        <div style={{ position: 'relative' }}>
          <BodyText>{this.renderInput()}</BodyText>
        </div>
        <div className="input-s-error">
          <InfoText>{(error || showMessage) && this.renderMessage()}</InfoText>
        </div>
      </InputAreaContainer>
    );
  }
}

export default InputArea;
