import styled from 'styled-components';

export const HCStyle = styled.div`
  .helpcentrer-section  {
    margin-bottom: 60px;
    max-width: 90%;
    text-align: center;
    margin: auto;
    font-family: 'plain';
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    &__title {
      margin-bottom: 24px;
      text-align: center;
    }
    &__text {
      p {
        margin-bottom: 24px;
        &:last-child  {
          margin-bottom: 0px;
        }
      }
    }
  }
  .helpcentrer-ul {
    display: flex;
    &__title {
      color: rgb(0, 161, 165);
      margin-bottom: 24px;
      text-align: center;
    }
    &__img {
      padding: 20px;
    }
  }
  .helpcentrer-ul li {
    width: 50%;
    padding: 20px;
  }
  a {
    color: rgb(0, 161, 165);
  }
`;
