import services from './services';
import {
  ICentreForm,
  ICentreService,
  IFacilities,
  IStock,
  IEquipment,
  ICentreCancellation,
  IBankAccount,
} from '../models/centre-forms';
import { IEmailInvitation } from '../models/generic';
import { IGhostStaff } from '../models/ghost-staff';
import { ISale } from '../models/sales';

export const getCentres = (params?: any, loader: boolean = true): any => {
  const endpoint = `centres/`;
  return new Promise((resolve, reject) => {
    const args = {
      params,
      loader,
      endpoint,
      then: resolve,
      catch: reject,
    };
    services.get(args);
  });
};

export const createCentre = (data: ICentreForm) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'centres',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const getCentre = (centreId: string, param?: any): any => {
  const endpoint = `centres/${centreId}`;
  const params = !param
    ? {
      filter: {
        relations: ['user', 'certifiers', 'certifiers.certifier'],
      },
    }
    : param;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      params,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCentrePictures = (centreId: string, params?: any): any => {
  const endpoint = `centres/${centreId}/pictures`;
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const deleteCentrePicture = (centreId: string, picId: string) => {
  const endpoint = `centres/${centreId}/pictures/${picId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const uploadCentrePictures = (centreId: string, data: any): any => {
  const endpoint = `centres/${centreId}/pictures`;
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};

export const uploadLogo = (centreId: string, data: any): any => {
  const endpoint = `centres/${centreId}/upload-logo`;
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};

export const editCentre = (
  centreId: string,
  data:
    | ICentreForm
    | { facilities: IFacilities }
    | { cancellation: ICentreCancellation }
    | { bankAccount: IBankAccount }
) => {
  const endpoint = `centres/${centreId}`;
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
      error: 'centre',
    };
    services.patch(args);
  });
};

export const requestInfo = (centreId: string, message: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      data: {
        message,
      },
      endpoint: `centres/${centreId}/request-info`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const declineCentre = (centreId: string, message?: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      data: {
        message,
      },
      endpoint: `centres/${centreId}/reject`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const validateCentre = (centreId: string, message: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      data: {
        message,
      },
      endpoint: `centres/${centreId}/validate`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const getServices = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/services`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const addService = (centreId: string, service: ICentreService) => {
  return new Promise((resolve, reject) => {
    const args = {
      data: service,
      endpoint: `centres/${centreId}/services`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const editService = (centreId: string, serviceId: string, service: ICentreService) => {
  return new Promise((resolve, reject) => {
    const args = {
      data: service,
      endpoint: `centres/${centreId}/services/${serviceId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteService = (centreId: string, serviceId: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `centres/${centreId}/services/${serviceId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const getCentreDiveSites = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/sites`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const addCentreDiveSites = (centreId: string, data?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `centres/${centreId}/sites/add`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const removeCentreDiveSites = (centreId: string, idSite: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `centres/${centreId}/sites/${idSite}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const postCentreDiveSites = (centreId: string, data: any, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `centres/${centreId}/sites`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const getStock = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/stock`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const deleteStock = (centreId: string, stockId: string | number, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/stock/${stockId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const postStock = (centreId: string, data: IStock, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `centres/${centreId}/stock`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchStock = (centreId: string, stockId: string | number, data: IStock, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `centres/${centreId}/stock/${stockId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const getEquipment = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/equipment`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const deleteEquipment = (centreId: string, equipId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/equipment/${equipId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const postEquipment = (centreId: string, data: IEquipment, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `centres/${centreId}/equipment`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchEquipment = (centreId: string, equipId: string, data: IEquipment, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `centres/${centreId}/equipment/${equipId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const setStockImage = (centreId: string, stockId: string, data: any, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `centres/${centreId}/stock/${stockId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const inviteCentre = (data: IEmailInvitation) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `centres/invite`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const getCentreStaff = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/staff`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCentreOneStaff = (centreId: string, staffId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/staff/${staffId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postCentreStaff = (centreId: string, data: IGhostStaff, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      params,
      endpoint: `centres/${centreId}/staff`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const inviteCentreStaff = (centreId: string, data: IGhostStaff, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      params,
      endpoint: `centres/${centreId}/staff/invite`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchCentreStaff = (centreId: string, staffId: string, data: IGhostStaff, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      params,
      endpoint: `centres/${centreId}/staff/${staffId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteCentreStaff = (centreId: string, staffId: string, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/staff/${staffId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const getCentreStaffBookings = (centreId: string, staffId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/staff/${staffId}/bookings`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCentreDocuments = (centreId: string, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/documents`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postCentreDocument = (centreId: string, data: any, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      params,
      endpoint: `centres/${centreId}/documents`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchCentreDocument = (centreId: string, documentId: string, data: any, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      params,
      endpoint: `centres/${centreId}/documents/${documentId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const deleteCentreDocument = (centreId: string, documentId: string, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/documents/${documentId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const getActivityTemplates = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/templates`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postSale = (centreId: string, data: ISale, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      params,
      endpoint: `centres/${centreId}/sales`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchSale = (centreId: string, saleId: string, data: ISale, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      params,
      endpoint: `centres/${centreId}/sales/${saleId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const getSales = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/sales`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCentreIncidences = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/incidences`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCentreDivers = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/divers`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCentreDiversSearch = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/divers`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};



export const getCentreDiver = (centreId: string, diverId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/divers/${diverId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCentreActivities = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/activities`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCentreActivitiesTable = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/activities/table`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCentreActivity = (centreId: string, activityId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/activities/${activityId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};


export const getActivityTemplate = (activityTemplateId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `activity-templates/${activityTemplateId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postCentreConfirmActivity = (centreId: string, activityId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/activities/${activityId}/confirm`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchCentreActivity = (centreId: string, activityId: string, data: any, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `centres/${centreId}/activities/${activityId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const getCentreBookings = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/bookings`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postCentreCancelActivity = (centreId: string, activityId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/activities/${activityId}/cancel`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const getCentreTickets = (centreId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `centres/${centreId}/tickets`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postCentreTicket = (centreId: string, data: any, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `centres/${centreId}/tickets`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchCentreTicket = (centreId: string, ticketId: string, data: any, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      data,
      endpoint: `centres/${centreId}/tickets/${ticketId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteCentreTicket = (centreId: string, ticketId: string) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      endpoint: `centres/${centreId}/tickets/${ticketId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const postCentreActivityInviteDivers = (centreId: string, activityId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `centres/${centreId}/activities/${activityId}/invite-divers`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const postCentreActivityCancel = (centreId: string, activityId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `centres/${centreId}/activities/${activityId}/cancel`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const postCentreActivityConfirm = (centreId: string, activityId: string, data?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `centres/${centreId}/activities/${activityId}/confirm`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const postCentreActivityFinish = (centreId: string, activityId: string, data?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `centres/${centreId}/activities/${activityId}/finish`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const getCentreVat = (centreId: string) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      endpoint: `billing/${centreId}/vat-document`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postCentreVat = (centreId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      data,
      endpoint: `billing/${centreId}/vat-document`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};

export const deleteCentreVat = (centreId: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `billing/${centreId}/vat-document`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const resendConfirmationEmail = (centreId: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `centres/${centreId}/confirm`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
