import * as React from 'react';
import { DiveSiteDetailStyle } from './dive-site-detail-style';
import HeadDestacadoText from '../../../components/text/head-destacado-text/head-destacado-text';
import DiveSiteDetailMap from '../../../components/dive-site-detail-map/dive-site-detail-map';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import CardDiveDetail from '../../../components/card-dive-detail/card-dive-detail';
import { RouteComponentProps, withRouter } from 'react-router';
import { getOneDiveSites } from '../../../services/dive-sites';
import { getConservationActions } from '../../../services/conservation-actions';
import BodyText from '../../../components/text/body-text/body-text';
import { getDateQuery } from '../../../utils/time';
import { getActivities } from '../../../services/activities';
import CardConservationDetail from '../../../components/card-conservation-detail/card-conservation-detail';
import { images } from '../../../assets/images';

interface RouteParams {
  id: string;
  siteId?: string;
}

export interface IDiveSiteDetailProps extends RouteComponentProps<RouteParams> {
  diveSite: any;
  agent: any;
  t: any;
}

export interface IDiveSiteDetailState {
  diveSite?: any;
  activities: [];
  conservations: any[];
  centre?: number;
}

class DiveSiteDetail extends React.Component<IDiveSiteDetailProps, IDiveSiteDetailState> {
  state: IDiveSiteDetailState;

  constructor(props: IDiveSiteDetailProps) {
    super(props);

    this.state = {
      // diveSite: {
      //   reserve: {},
      //   description: {},
      // },
      activities: [],
      conservations: [],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.siteId) {
      this.getActivities(match.params.siteId);
    } else if (match.params.id) {
      this.getActivities(match.params.id);
    }
  }

  getDiveSite = async (id: string) => {
    try {
      const params = {
        filter: {
          relations: ['reserve', 'pictures'],
        },
      };
      const response: any = await getOneDiveSites(id /*, params*/);

      this.setState({ diveSite: response.data }, () => this.getConservationActions());
    } catch (err) { }
  };

  getActivities = async (id: string) => {
    const centre = localStorage.getItem('centreId');

    const params = {
      filter: {
        where: {
          date: { method: '>=', value: getDateQuery(new Date()) },
          //id: { value: id, collection: 'sites' },
          id: { value: centre, collection: 'centre' },
        },
        limit: 25,
        relations: ['sites', 'template', 'centre'],
      },
    };
    const response: any = await getActivities(params);
    let arrayActivities: any = [];
    response.data.data.forEach((activity: any) => {
      for (let i = 0; i < activity.sites.length; i++) {
        if (activity.sites[i].id == id) {
          arrayActivities.push(activity)
        }
      }
    });

    this.setState({ activities: arrayActivities });
  };

  getConservationActions = async () => {
    const { diveSite } = this.props;
    const params = {
      filter: {
        where: {
          province: diveSite.state,
        },
        limit: 4,
        relations: ['organization', 'donations'],
      },
    };

    const response: any = await getConservationActions({ params });
    this.setState({ conservations: response.data.data });
  };

  render() {
    const { activities, conservations } = this.state;

    const { diveSite, agent, t } = this.props;

    return (
      <>
        <DiveSiteDetailStyle>
          <div className="dive-site-detail-hero">
            <DiveSiteDetailMap item={diveSite} agent={agent} />
          </div>
          <div className="dive-site-detail-next">
            <div className="dive-site-detail-next__title">
              <HeadDestacadoText>Próximas actividades</HeadDestacadoText>
            </div>
            {!diveSite.validated ? (
              <div className="dive-site-detail-next__text">
                <SubtitleText>
                  Las actividades estarán disponibles cuando el punto de inmersión haya sido validado.
                </SubtitleText>
              </div>
            ) : (
              <div className="dive-site-detail-next-container">
                {activities.length > 0 ? (
                  activities.map((activity: any) => (
                    <CardDiveDetail t={t} key={`activity-${activity.id}`} activity={activity} />
                  ))
                ) : (
                  <BodyText>No hay próximas activiades</BodyText>
                )}
              </div>
            )}
          </div>
          <div className="dive-site-detail-next">
            <div className="dive-site-detail-next__title">
              <HeadDestacadoText>Proyectos de conservación</HeadDestacadoText>
            </div>
            {!diveSite.validated ? (
              <div className="dive-site-detail-next__text">
                <SubtitleText>
                  Las actividades estarán disponibles cuando el punto de inmersión haya sido validado.
                </SubtitleText>
              </div>
            ) : (
              <div className="dive-site-detail-next-container">
                {conservations.length > 0 ? (
                  conservations.map((conservation: any) => (
                    <CardConservationDetail key={`activity-${conservation.id}`} conservation={conservation} />
                  ))
                ) : (
                  <BodyText>No hay proyectos de conservación</BodyText>
                )}
              </div>
            )}
          </div>
          {/* <PopReserve /> */}
        </DiveSiteDetailStyle>
        {/* <ModalTitlePhoto onAccept={() => {}} showModal={true} onCancel={() => {}} /> */}
      </>
    );
  }
}

export default withRouter(DiveSiteDetail);
