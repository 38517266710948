import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ItemSectionDetailStyle = styled.div`
  .item-section-detail-top  {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid ${colors['NE-002/100']};
    margin-bottom: 40px;
    align-items: center;
  }

  .item-section-detail-icon {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }

  .item-section-detail-text {
    display: flex;
  }
  .item-section-detail-edit {
    color: ${colors['PR-001/100']};
    cursor: pointer;
  }
`;
