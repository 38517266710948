import * as React from 'react';
import { AddItemFormSimpleStyle } from './add-item-form-simple-style';
import InfoText from '../text/info-text/info-text';
import { images } from '../../assets/images';

export interface IAddItemFormSimpleProps {
  text: string;
  onDelete: Function;
}

export interface IAddItemFormSimpleState {}

export default class AddItemFormSimple extends React.Component<IAddItemFormSimpleProps, IAddItemFormSimpleState> {
  constructor(props: IAddItemFormSimpleProps) {
    super(props);

    this.state = {};
  }

   render() {
    const { onDelete, text } = this.props;
    return (
      <AddItemFormSimpleStyle>
        <div className="add-item-form-simple-text">
          <InfoText>{text}</InfoText>
        </div>
        <div className="add-item-form-simple-delete" onClick={() => onDelete()}>
          <img src={images.deleteSvg} alt="" />
        </div>
      </AddItemFormSimpleStyle>
    );
  }
}
