import React, { Component } from 'react';
import { UsersSuperAdminLayoutStyle } from './users-seradmin-functional-style';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import Pagination from '../../../components/pagination';
import MainTable from '../../../components/main-table/main-table';
import { User, rowKeys, tableColumns } from './types';
import { GhostDiverForm } from '../../../types/user';
import { TableRow, TableCell } from '../../../types/table';
import GhostDiverFormFunctional from './../create/ghost-diver/ghost-diver-form-functional';
import DiverFormFunctional from './../create/diver/diver-form-functional';
import moment from 'moment';
import 'moment/locale/es.js';
import MainButton from '../../../components/main-button';
import SelectBox from '../../../components/select-box';
import CalendarBox from '../../../components/calendar-box';
import { withRouter, RouteComponentProps } from 'react-router';
import { deleteDiver } from '../../../services/diver';
import services from '../../../services/services';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { DIVER_TYPES_DROPDOWN } from '../../../constants/diver-types';
import ModalInfo from '../../../components/modals/modal-info/modal-info';

interface DiversLayoutProps extends RouteComponentProps {
  items: User[];
  totalPages: number;
  totalItems: number;
  page: number;
  setPage: (page: number) => void;
  refreshData: () => void;
  certifiers: any;
  onFilterChange: (key: string, value: any, method?: string, collection?: string, concat?: string[]) => void;
  onFilterDelete: (key: string) => void;
  onFilterType: (filterType: string) => void;
}

interface DiversLayoutState {
  renderModal: string;
  activeFilter: boolean;
  newGhostDiver?: GhostDiverForm;
  selectedDiverId: string;
  filters: { [key: string]: string };
}

class UsersSuperAdminLayout extends Component<DiversLayoutProps, DiversLayoutState> {
  state: DiversLayoutState = {
    renderModal: 'modal-diver',
    activeFilter: false,
    selectedDiverId: '',
    filters: {},
  };

  formatDataToTableRows(data: { [key: string]: any }[]) {
    return data.map(item => {
      const row: TableRow = {};

      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };
        switch (key) {
          case 'name':
            cell.value = item.name && item.surname ? `${item.name} ${item.surname}` : '-';
            cell.key = 'highlighted-body';
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });

      row['register-date'] = {
        value: moment(item.createdAt)
          .utc()
          .format('DD/MM/YYYY'),
        type: '',
        key: '',
      };

      row.options = {
        value: item.id,
        type: '',
        key: 'options',
        actions: [
          {
            label: 'Ver',
            icon: images.eyeSvg,
            onClick: () => {
              this.props.history.push(`/users/detail/${item.id}`);
            },
          },
          {
            label: 'Eliminar',
            icon: images.deleteSvg,
            onClick: () => {
              this.setState({ renderModal: 'modal-delete', selectedDiverId: item.id });
            },
          }
        ],
      };

      return row;
    });
  }

  async deleteDiver() {
    const { selectedDiverId } = this.state;
    const { refreshData } = this.props;
    await deleteDiver(selectedDiverId);
    services.pushNotification({
      title: '¡Eliminado!',
      text: 'El usuario se ha eliminado con éxito',
      type: 'red',
    });
    this.setState({ renderModal: '' });
    refreshData();
  }

  handleSearchTimeout: any;
  handleSearch(value: string) {
    const { onFilterChange, onFilterDelete } = this.props;
    clearTimeout(this.handleSearchTimeout);
    this.handleSearchTimeout = setTimeout(() => {
      if (value && value !== '' && value.length > 3) {
        const name = value.split(' ')[0];
        onFilterChange('name', name, undefined, 'user', ['surname']);
      } else if (!value || value.length === 3) {
        onFilterDelete('name');
      }
    }, 500);
  }

  render() {
    const { renderModal, newGhostDiver, activeFilter, filters } = this.state;
    const { items, totalPages, setPage, refreshData, onFilterChange, onFilterDelete, onFilterType, page, totalItems } = this.props;
    return (
      <>
        {/* Header */}
        <HeaderMain
          title={'Gestión de usuarios'}
          withSearch={true}
          resultCount={totalItems}
          handleSearch={(value: string) => {
            this.handleSearch(value);
          }}
          mainButton={{
            img: images.createWhiteSvg,
            text: 'Crear',
            onClick: () => this.setState({ renderModal: 'modal-ghost-diver' }),
          }}
        />

        {/* Content */}
        <MainLayoutSection>
          <UsersSuperAdminLayoutStyle>
            <div className="dive-center-head">
              <div className="dive-center-head__filter">
                <MainButton
                  onClick={() => this.setState({ activeFilter: !activeFilter })}
                  type={activeFilter ? 'secondary-active' : 'secondary'}
                  text="Filtrar"
                  iconImg={activeFilter ? images.filtersLightBlueSvg : images.filtersSvg}
                />
              </div>
            </div>

            <div className={`dive-center-filters${activeFilter ? ' active' : ''}`}>
              <div className="dive-center-filters__item">
                <SelectBox
                  labelText=""
                  placeholder="Tipo de buceador"
                  className="rounded-filter"
                  optionsText={[{ label: 'Todos', value: '*' }].concat(DIVER_TYPES_DROPDOWN)}
                  optionKey={'value'}
                  optionValue={'label'}
                  icon={images.arrowDown2BlueSvg}
                  onChange={value => {
                    onFilterType(value);
                  }}
                />
              </div>
              <div className="dive-center-filters__item">
                <CalendarBox
                  placeholder="Fecha de registro"
                  className="filter"
                  disabled={true}
                  labelText=""
                  initialValue={''}
                  onChange={value => {
                    if (value) onFilterChange('createdAt', moment(value, 'DD/MM/YYYY'));
                    else onFilterDelete('createdAt');
                  }}
                />
              </div>
              {/*
                <div className="dive-center-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Certificadora"
                    className="rounded-filter"
                    optionsText={[{ label: 'Todas', value: '*' }].concat(certifiers)}
                    optionKey={'value'}
                    optionValue={'label'}
                    icon={images.arrowDown2BlueSvg}
                    onChange={() => {}}
                  />
                </div>
                <div className="dive-center-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Titulación"
                    className="rounded-filter"
                    optionsText={[{ label: 'TODO:CHECK' }]}
                    optionKey={'label'}
                    optionValue={'label'}
                    icon={images.arrowDown2BlueSvg}
                    onChange={() => {}}
                  />
                </div>*/}
            </div>

            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTable
                  columns={tableColumns}
                  rows={this.formatDataToTableRows(items)}
                  rowKeys={rowKeys}
                  onOrder={() => { }}
                  filters={filters}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} limit={totalPages} callback={page => setPage(page)} />
                </div>
              )}
            </MainLayoutTableContainer>
          </UsersSuperAdminLayoutStyle>
        </MainLayoutSection>

        {/* Create Ghost Diver */}
        {renderModal === 'modal-ghost-diver' && (
          <GhostDiverFormFunctional
            title="Crear buceador fantasma"
            showModal={renderModal === 'modal-ghost-diver'}
            onAccept={newGhostDiver => {
              this.setState({ newGhostDiver, renderModal: 'modal-success-ghost-diver' });
            }}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}
        {/* Modal Success Ghost Diver */}

        {/* <ModalSuccess
          title="¡Genial!"
          text="Se acaba de enviar una invitación
          al email del buceador que acabas de crear.
          Es posible que no lo complete inmediatamente,
          así que si quieres puedes completar su perfil
          con los datos que creas necesarios.
          El buceador podrá modificarlos y completarlos
          una vez que hay aceptado la invitación."
          showModal={renderModal === 'modal-success-ghost-diver'}
          onClose={() => this.setState({ renderModal: 'modal-diver' })}
        /> */}

        <ModalInfo
          type="blue blue-title"
          title={[<span key="i">¡Genial!</span>]}
          text={[
            `Se acaba de enviar una invitación
            al email del buceador que acabas de crear.`,
            `Es posible que no lo complete inmediatamente,
          así que si quieres puedes completar su perfil
          con los datos que creas necesarios.`,
            `El buceador podrá modificarlos y completarlos
          una vez que hay aceptado la invitación.`,
          ]}
          onClose={() => this.setState({ renderModal: '' })}
          icon={images.checkCircleBlueSvg}
          showModal={renderModal === 'modal-success-ghost-diver'}
          buttons={[
            {
              text: 'Completar perfil',
              type: 'primary',
              onClick: () => this.setState({ renderModal: 'modal-diver' }),
            },
          ]}
        />

        {/* Fill diver data */}

        {newGhostDiver && typeof newGhostDiver !== 'undefined' && (
          <DiverFormFunctional
            title="Perfil del buceador"
            showModal={renderModal === 'modal-diver'}
            onAccept={() =>
              this.setState({ renderModal: 'modal-success-diver' }, () => {
                refreshData();
              })
            }
            onCancel={() => this.setState({ renderModal: '' })}
            ghostDiver={newGhostDiver}
          />
        )}
        <ModalDelete
          title={'Eliminar buceador'}
          subtitle={
            'Una vez eliminado el perfil del buceador, este no tendrá acceso a la aplicación de DiveAsapp y no podrá disfrutar de sus servicios.'
          }
          showModal={renderModal === 'modal-delete'}
          onRemove={() => this.deleteDiver()}
          onCancel={() => this.setState({ renderModal: '' })}
        />
        {/* Modal Success Diver */}
        {/*
        <ModalSuccess
          title="¡Genial!"
          text="Los datos introducidos completarán
          el registro del buceador. Una vez que este
          se haya registrado, podrá completar
          su perfil e, incluso, modificar
          los datos existentes."
          showModal={renderModal === 'modal-success-diver'}
          onClose={() => this.setState({ renderModal: '', newGhostDiver: undefined })}
        /> */}
      </>
    );
  }
}

export default withRouter(UsersSuperAdminLayout);
