import * as React from 'react';
import ModalContainer from '../modal-container';
import { ModalIncidentTypeStyle } from './modal-incident-type-style';
import Label02Text from '../../text/label-02-text/label-02';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import { images } from '../../../assets/images';
import BodyText from '../../text/body-text/body-text';
import ChatUser from '../../chat-user/chat-user';
import InfoText from '../../text/info-text/info-text';
import BodyDestacadoText from '../../text/body-destacado-text/body-destacado-text';
import LabelText from '../../text/label-text/label';
import MainButton from '../../main-button';
import InputBox from '../../input-box';
import DicotomicCheckbox from '../../dicotomic-checkbox';
import { NavigationTabContainer } from '../../navigation-tabs/navigation-tabs-style';
import { IIncidenceTypeForm } from '../../../models/incidence-types-forms';
import {
  createTypesIncidence,
  getTypesIncidence,
  deleteTypesIncidence,
  uploadTypesIncidence,
  updateTypesIncidences,
} from '../../../services/type-incidences';
import services from '../../../services/services';
import ModalDelete from '../modal-delete/modal-delete';
import { FormGridRow, FormGridItem } from '../../../containers/main-layout-style';
import InputArea from '../../input-area/input-area';
import { incidenceType } from '../../../constants/incidences';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

export interface IModalIncidenceTypeProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  handleRemove: Function;
  typeIncidence: string;
  reloadType: boolean;
  setReloadType: Function;
  t: any;
}

export interface IModalIncidenceTypeState {
  type: string;
  incidenceType: IIncidenceTypeForm;
  incidenceTypes: any[];
  modal: string;
  typeId: string;
  languages: any[];
  selectedLanguage: string;
  initialIncidenceTypes: any[];
}

const INCIDENCE_TYPE_FORM = {
  name: {
    es: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
    },
    pt: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
    },
    en: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
    },
    fr: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
    },
    it: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
    },
    de: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
    },
  },
  category: {
    value: '',
    error: false,
    errorCode: '',
    required: false,
  },
};

class ModalIncidenceType extends React.Component<IModalIncidenceTypeProps, IModalIncidenceTypeState> {
  readonly state: IModalIncidenceTypeState;

  constructor(props: IModalIncidenceTypeProps) {
    super(props);

    this.state = {
      type: props.typeIncidence || 'bussiness',
      incidenceType: JSON.parse(JSON.stringify(INCIDENCE_TYPE_FORM)),
      incidenceTypes: [],
      modal: '',
      typeId: '',
      languages: [
        { value: 'es', label: 'ES' },
        { value: 'pt', label: 'PT' },
        { value: 'en', label: 'EN' },
        { value: 'fr', label: 'FR' },
        { value: 'it', label: 'IT' },
        { value: 'de', label: 'DE' },
      ],
      selectedLanguage: 'es',
      initialIncidenceTypes: [],
    };
  }

  onCancel = () => {
    this.setState(
      (prevState: any) => ({
        type: 'bussiness',
        incidenceType: JSON.parse(JSON.stringify(INCIDENCE_TYPE_FORM)),
        incidenceTypes: prevState.initialIncidenceTypes,
        selectedLanguage: 'es',
      }),
      () => this.props.onCancel()
    );
  };

  componentDidMount() {
    this.getIncidenceTypes();
  }

  componentDidUpdate(prevProps: IModalIncidenceTypeProps) {
    if (prevProps.reloadType !== this.props.reloadType && this.props.reloadType) {
      this.getIncidenceTypes();
      this.props.setReloadType();
    }
  }

  getIncidenceTypes = async () => {
    const params = {
      filter: {
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
      },
    };
    const response: any = await getTypesIncidence(params);
    const incidenceTypes = response.data.data.map((incidence: any) => ({ ...incidence, touched: false }));
    this.setState({ incidenceTypes, initialIncidenceTypes: incidenceTypes });
  };

  changeValue = (value: string, key: string, subKey?: string) => {
    const { incidenceType } = this.state;
    if (!subKey) {
      incidenceType[key].value = value;
    } else {
      incidenceType[key][subKey].value = value;
    }

    this.setState({ incidenceType });
  };

  deleteType = async (typeId: string) => {
    try {
      await deleteTypesIncidence(typeId);
      this.setState({ modal: '', typeId: '' }, () => this.getIncidenceTypes());
    } catch (err) {
      this.setState({ modal: '', typeId: '' });
    }
  };

  validForm = () => {
    const newIncidenceType = { ...this.state.incidenceType, name: { ...this.state.incidenceType.name } };
    const { name: form } = newIncidenceType;
    let valid = true;
    for (const lang in form) {
      form[lang] = { ...form[lang], error: form[lang].value === '' };
      valid = form[lang].value !== '' && valid;
    }
    this.setState({ incidenceType: newIncidenceType });
    return valid;
  };

  handleAdd = async () => {
    const { incidenceType, type } = this.state;
    const isOk = true;

    // Object.keys(incidenceType).forEach((k: string) => {
    //   if (incidenceType[k].required && incidenceType[k].value === '') {
    //     incidenceType[k].error = true;
    //     incidenceType[k].errorCode = 'required';
    //     isOk = false;
    //   } else if (k === 'name') {
    //     Object.keys(incidenceType[k]).forEach((kd: string) => {
    //       if (incidenceType[k][kd].required && incidenceType[k][kd].value === '') {
    //         incidenceType[k][kd].error = true;
    //         incidenceType[k][kd].errorCode = 'required';
    //         isOk = false;
    //       } else {
    //         incidenceType[k][kd].error = false;
    //         incidenceType[k][kd].errorCode = '';
    //       }
    //     });
    //   } else {
    //     incidenceType[k].error = false;
    //     incidenceType[k].errorCode = '';
    //   }
    // });
    const valid = this.validForm();
    if (!valid) return;
    this.setState({ incidenceType });
    if (isOk) {
      const name: any = {};
      Object.keys(incidenceType.name).forEach((k: string) => {
        name[k] = incidenceType.name[k].value;
      });
      const data: any = {
        name,
        category: type,
      };
      try {
        const { incidenceTypes, initialIncidenceTypes } = this.state;
        const res: any = await createTypesIncidence({ ...data, active: false });
        const newIncidence = { ...res.data, touched: false };
        const newIncidenceTypes = [newIncidence, ...incidenceTypes];
        const newInicialIncidenceTypes = [newIncidence, ...initialIncidenceTypes];
        this.setState({
          incidenceTypes: newIncidenceTypes,
          initialIncidenceTypes: newInicialIncidenceTypes,
          incidenceType: JSON.parse(JSON.stringify(INCIDENCE_TYPE_FORM)),
          selectedLanguage: 'es',
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  onAcceptHandler = async () => {
    const { incidenceTypes } = this.state;
    const { t } = this.props;
    const updatedIncidenceTypes = incidenceTypes.filter((incidenceType: any) => incidenceType.touched);
    if (updatedIncidenceTypes.length <= 0) {
      this.onCancel();
      return;
    }
    try {
      const response: any = await updateTypesIncidences({ data: updatedIncidenceTypes });

      const newIncidences = response.data
        .map((incidence: any) => ({ ...incidence, touched: false }))
        .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      this.setState({ incidenceTypes: newIncidences, initialIncidenceTypes: newIncidences }, () => {
        this.onCancel();
        services.pushNotification({
          type: 'green',
          title: t('push:save-incidence-types.title'),
          text: t('push:save-incidence-types.text'),
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  onCheckHandler = (id: number) => {
    const { incidenceTypes } = this.state;
    const newIncidenceTypes = [...incidenceTypes];
    const index = newIncidenceTypes.findIndex((incidenceType: any) => incidenceType.id === id);
    newIncidenceTypes[index] = { ...newIncidenceTypes[index], active: !newIncidenceTypes[index].active, touched: true };
    this.setState({ incidenceTypes: newIncidenceTypes });
  };

  handleCategory = async (index: number) => {
    const { incidenceTypes, type } = this.state;

    const indexCategory: number = incidenceTypes[index].category.indexOf(type);
    if (indexCategory > -1) {
      // TODO POP CUANDO ESTE BACKEND
      incidenceTypes[index].category = type;
    } else {
      // TODO PUSH CUANDO ESTE BACKEND
      incidenceTypes[index].category = type;
    }
    const data = {
      name: incidenceTypes[index].name,
      category: incidenceTypes[index].category,
    };
    try {
      await uploadTypesIncidence(incidenceTypes[index].id, data);
      this.setState({ incidenceTypes });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { showModal, onCancel, handleRemove, t } = this.props;
    const { type, incidenceType, incidenceTypes, typeId, modal, languages, selectedLanguage } = this.state;
    return (
      <ModalContainer title={`Tipo de incidencia`} active={showModal} modalClose={this.onCancel}>
        <ModalIncidentTypeStyle>
          <NavigationTabContainer className="small">
            <div
              className={`navigation-item ${type === 'bussiness' && 'active'}`}
              onClick={() => this.setState({ type: 'bussiness' })}
            >
              <BodyDestacadoText>{t('components:general.bussiness')}</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${type === 'diveSite' && 'active'}`}
              onClick={() => this.setState({ type: 'diveSite' })}
            >
              <BodyDestacadoText>{t('components:general.immersion-points')}</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${type === 'pictures' && 'active'}`}
              onClick={() => this.setState({ type: 'pictures' })}
            >
              <BodyDestacadoText>{t('components:general.images')}</BodyDestacadoText>
            </div>
          </NavigationTabContainer>

          <div className={'modal-incident-languages'}>
            {languages.map((k: any, index: number) => (
              <div className={'modal-incident-languages__item'} key={index}>
                <MainButton
                  onClick={() => this.setState({ selectedLanguage: k.value })}
                  text={k.label}
                  type={
                    selectedLanguage === k.value
                      ? `${incidenceType.name[k.value].error ? 'primary-red' : 'primary'}`
                      : !incidenceType.name[k.value].error
                      ? 'secondary'
                      : 'secondary-red'
                  }
                />
              </div>
            ))}
          </div>

          <FormGridRow key={`description-${selectedLanguage}`}>
            <div className="sign-up-diver-form-container-col2">
              <div className="sign-up-diver-form-container-col2__item">
                <InputBox
                  className={'rounded'}
                  type={'text'}
                  labelText={`${t('components:general.name')} ${selectedLanguage}`}
                  placeholder={`${t('components:general.name')} ${selectedLanguage}`}
                  required={incidenceType.name[selectedLanguage].required}
                  errorCode={incidenceType.name[selectedLanguage].errorCode}
                  value={incidenceType.name[selectedLanguage].value}
                  onChange={(value: string) => this.changeValue(value, 'name', selectedLanguage)}
                />
              </div>
              <div className="sign-up-diver-form-container-col2__button">
                <MainButton className="full-height" type="secondary" onClick={() => this.handleAdd()} text="Añadir" />
              </div>
            </div>
          </FormGridRow>

          <div className="modal-incident-type-list">
            {incidenceTypes
              .filter((it: any) => it.category === type)
              .map((it: any, index: number) => (
                <div key={`incidence-type-${it.id}`} className="modal-incident-type-list__item">
                  <DicotomicCheckbox
                    name="name"
                    errorText="Error"
                    onCheck={() => {
                      this.onCheckHandler(it.id);
                    }}
                    checked={it.active}
                  >
                    <InfoText>{it.name.es}</InfoText>
                  </DicotomicCheckbox>
                  <div className="modal-incident-type-list__item__icon" onClick={() => handleRemove(it.id, type)}>
                    <img src={images.deleteSvg} alt="" />
                  </div>
                </div>
              ))}
          </div>

          <div className="modal-incidence-detail-buttons">
            <div className="modal-incidence-detail-buttons__item">
              <MainButton className="primary" onClick={this.onAcceptHandler} text={t('components:actions.accept')} />
            </div>
          </div>
        </ModalIncidentTypeStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components', 'push'])(withRouter(ModalIncidenceType));
