import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter, match } from 'react-router';
import { BookingsStyle } from './../bookings-style';
import { MainLayoutTableContainer } from '../../../main-layout-style';
import { images } from '../../../../assets/images';
import MainTable from '../../../../components/main-table/main-table';
import { BookingDetail } from '../../../../models/bookings';
import { copyOf } from '../../../../utils/other';
import { getTimeFormatted } from '../../../../utils/time';
import { ITimeBlocks } from '../../../../models/generic';
import ModalConfirmation from '../../../../components/modals/modal-confimation/modal-confirmation';
import services from '../../../../services/services';
import { uploadBookings, getOneBookings, confirmBooking, cancelBooking } from '../../../../services/bookings';
import ReassignActivity from '../../../../components/modals/modal-reassign-activity/modal-reassign-activity';
import ModalContainer from '../../../../components/modals/modal-container';
import moment from 'moment';
import ModalBookingDetail from '../modal-bookings/modal-booking-detail/modal-booking-detail';
import ModalBookingDetailDivers from '../modal-bookings/modal-booking-detail-divers/modal-booking-detail-divers';
import bookings from '../bookings';

export interface IBookingsTypeProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
  }>;
  centreCurrency?: string;
  onReload: () => void;
  section: string;
  subsection?: string | null;
  bookings: any[];
  bookingsRejects?: any[];
}

export interface IBookingsTypeState {
  modal: string;
  selectedBooking: BookingDetail;
}

class BookingsType extends React.Component<IBookingsTypeProps, IBookingsTypeState> {
  constructor(props: IBookingsTypeProps) {
    super(props);

    this.state = {
      modal: '',
      selectedBooking: {
        id: '',
        activityId: '',
        bookingId: '',
        createdAt: '',
        centreId: 0,
        activity: '',
        discipline: '',
        activityDate: '',
        numDivers: '',
        divers: [],
        price: '',
        diverId: 0,
        timeBlocks: [],
        status: '',
        source: '',
        comments: '',
        discount: 0,
      },
    };
  }

  getTableRowTemplates() {
    const { section, t } = this.props;

    switch (section) {
      case 'solicitudes':
        return {
          booking: {
            key: '',
            value: 'RE0000001',
          },
          departureDate: {
            key: '',
            value: '09/08/2019',
          },
          departureHour: {
            key: '',
            value: '2 h',
          },
          activityName: {
            key: '',
            value: 'Cueva oscura',
          },
          discipline: {
            key: '',
            value: 'Buceo recreativo',
          },
          numDivers: {
            key: 'icon-text-button',
            value: 3,
            icon: images.eyeLightBlueSvg,
            onClick: () => { },
          },
          price: {
            key: '',
            value: '15.00 €',
          },
          status: {
            key: '',
            value: 'Incompleta',
          },
          actions: {
            key: 'options',
            actions: [
              {
                label: t('buttons:confirm'),
                icon: images.checkSvg,
                onClick: () => { },
              },
              {
                label: t('buttons:reject'),
                icon: images.closeSvg,
                onClick: () => { },
              },
              {
                label: t('buttons:details'),
                icon: images.editSvg,
                onClick: () => { },
              }
            ]
          }
        };

      case 'confirmadas':
        return {
          booking: {
            key: '',
            value: 'RE0000001',
          },
          departureDate: {
            key: '',
            value: '09/08/2019',
          },
          departureHour: {
            key: '',
            value: '2 h',
          },
          activityName: {
            key: '',
            value: 'Cueva oscura',
          },
          discipline: {
            key: '',
            value: 'Buceo recreativo',
          },
          numDivers: {
            key: 'icon-text-button',
            value: 3,
            icon: images.eyeLightBlueSvg,
            onClick: () => { },
          },
          price: {
            key: '',
            value: '15.00 €',
          },
          status: {
            key: '',
            value: 'Incompleta',
          },
          actions: {
            key: 'options',
            actions: [
              {
                label: t('buttons:cancelres'),
                icon: images.arrowDown2Svg,
                onClick: () => { },
              },
              {
                label: t('buttons:cancel'),
                icon: images.closeSvg,
                onClick: () => { },
              },
              {
                label: t('buttons:details'),
                icon: images.editSvg,
                onClick: () => { },
              }
            ]
          }
        };

      case 'historial':
        return {
          booking: {
            key: '',
            value: 'RE0000001',
          },
          departureDate: {
            key: '',
            value: '09/08/2019',
          },
          departureHour: {
            key: '',
            value: '2 h',
          },
          activityName: {
            key: '',
            value: 'Cueva oscura',
          },
          discipline: {
            key: '',
            value: 'Buceo recreativo',
          },
          numDivers: {
            key: 'icon-text-button',
            value: 3,
            icon: images.eyeLightBlueSvg,
            onClick: () => { },
          },
          price: {
            key: '',
            value: '15.00 €',
          },
          status: {
            key: '',
            value: 'Incompleta',
          },
          actions: {
            key: 'options',
            actions: [
              {
                label: t('buttons:tonp'),
                icon: images.arrowDown2Svg,
                onClick: () => { },
              },
              {
                label: t('buttons:details'),
                icon: images.editSvg,
                onClick: () => { },
              }
            ]
          }
        };

      case 'cancelaciones':
        return {
          booking: {
            key: '',
            value: 'RE0000001',
          },
          departureDate: {
            key: '',
            value: '09/08/2019',
          },
          departureHour: {
            key: '',
            value: '2 h',
          },
          activityName: {
            key: '',
            value: 'Cueva oscura',
          },
          discipline: {
            key: '',
            value: 'Buceo recreativo',
          },
          numDivers: {
            key: 'icon-text-button',
            value: 3,
            icon: images.eyeLightBlueSvg,
            onClick: () => { },
          },
          price: {
            key: '',
            value: '15.00 €',
          },
          status: {
            key: '',
            value: 'Incompleta',
          },
          actions: {
            key: 'options',
            actions: [
              {
                label: t('buttons:details'),
                icon: images.editSvg,
                onClick: () => { },
              }
            ]
          }
          
        };

      default:
        break;
    }
  }



  getTimeFormatBlock(timeBlocks: any) {
    let startHour = 24;
    let startMinutes = 0;
    let endHour = 24;
    let endMinutes = 0;

    timeBlocks.forEach((timeBlock: ITimeBlocks) => {
      if (timeBlock.start.hour < startHour) {
        startHour = timeBlock.start.hour;
        startMinutes = timeBlock.start.minutes;
      }
      if (timeBlock.end.hour < endHour) {
        endHour = timeBlock.end.hour;
        endMinutes = timeBlock.end.minutes;
      }
    });
    return getTimeFormatted(startHour, startMinutes) //+ " - " + getTimeFormatted(endHour, endMinutes)
    //return timeBlock[0].start.hour + ':' +timeBlock[0].start.minutes + timeBlock[0].start.seconds
  }

  getTableRows() {
    const { section, bookings } = this.props;

    let bookPrice = 0;
    const tableRows: any[] = [];

    if (bookings && bookings.length) {
      for (const booking of bookings) {
        const tableRow = copyOf(this.getTableRowTemplates());
        tableRow.booking.value = booking.bookingId;
        tableRow.departureDate.value = moment(booking.activityDate).format('DD|MM|YYYY');
        tableRow.departureHour.value = this.getTimeFormatBlock(booking.timeBlocks);
        tableRow.activityName.value = booking.activity;
        tableRow.discipline.value = booking.discipline;
        tableRow.numDivers.value = booking.numDivers;
        tableRow.numDivers.onClick = () => { this.modalHandler('modal-booking-detail-divers', booking) };
        /*tableRow.numDivers.onClick = () => {};*/
        if (booking.numDivers > 1) {
          bookPrice = booking.lines.slice(-1)[0].price * (booking.numDivers - 1);
        }
        booking.price = (Object.keys(booking.lines).length !== 0 ? booking.lines.reduce((a: number, b: any) => a + b.price, 0) + bookPrice : 0 + bookPrice) / 100;
        tableRow.price.value = booking.price.toString() + ' €';
        tableRow.status.value = booking.status;

        const {
          match: {
            params: { id },
          },
        } = this.props;
        switch (section) {
          case 'solicitudes':
            tableRow.actions.actions[0].onClick = () => {
              this.modalHandler('modal-confirm', booking);
            };
            tableRow.actions.actions[1].onClick = () => {
              this.modalHandler('modal-reject', booking);
            };
            tableRow.actions.actions[2].onClick = () => {
              this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
            };
            break;
          case 'confirmadas':
            tableRow.actions.actions[0].onClick = () => {
              this.modalHandler('modal-reassign-activity', booking);
            };
            tableRow.actions.actions[1].onClick = () => {
              this.modalHandler('modal-cancel', booking);
            };
            tableRow.actions.actions[2].onClick = () => {
              this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
            };
            break;
          case 'historial':
            tableRow.actions.actions[0].onClick = () => {
              this.modalHandler('modal-chante-to-np', booking);
            };
            tableRow.actions.actions[1].onClick = () => {
              this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
            };
            break;
          case 'cancelaciones':
            tableRow.actions.actions[0].onClick = () => {
              this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
            };
            break;
          default:
            break;
        }

        tableRows.push(tableRow);
      }
    }
    return tableRows;
  }

  getTableRowsRejects() {
    const { bookingsRejects } = this.props;
    const {
      match: {
        params: { id },
      },t
    } = this.props;

    const tableRows: any[] = [];
    let bookPrice = 0;
    if (bookingsRejects && bookingsRejects.length) {
      for (const booking of bookingsRejects) {

        const tableRow = copyOf(this.getTableRowTemplates());
        tableRow.booking.value = booking.bookingId;
        tableRow.departureDate.value = moment(booking.activityDate).format('DD|MM|YYYY');
        tableRow.departureHour.value = this.getTimeFormatBlock(booking.timeBlocks);
        tableRow.activityName.value = booking.activity;
        tableRow.discipline.value = booking.discipline;
        tableRow.numDivers.value = booking.numDivers;
        tableRow.numDivers.divers = booking.divers;
        tableRow.numDivers.onClick = () => { this.modalHandler('modal-booking-detail-divers', booking) };
        if (booking.numDivers > 1) {
          bookPrice = booking.lines.slice(-1)[0].price * (booking.numDivers - 1);
        }
        booking.price = (Object.keys(booking.lines).length !== 0 ? booking.lines.reduce((a: number, b: any) => a + b.price, 0) + bookPrice : 0 + bookPrice) / 100;
        tableRow.price.value = booking.price.toString() + ' €';
        tableRow.status.value = booking.status;

        tableRow.actions = {
          key: 'options',
          actions: [
            {
              label: t('buttons:details'),
              icon: images.editSvg,
              onClick: () => { },
            }
          ]
        };
        tableRow.actions.actions[0].onClick = () => {
          this.props.history.push(`/centres/${id}/billing/bookings/${booking.id}`)
        };
        tableRows.push(tableRow);
      }
    }
    return tableRows;
  }

  sendNotification = (title: string, msg: string, type: string) => {
    services.pushNotification({
      title: title,
      text: msg,
      type: type,
    });
  }

  modalHandler = (modalValue: string, booking?: any) => {
    this.setState({ ...(booking && { selectedBooking: booking }), modal: modalValue });
  };

  async handleConfirm() {
    const {
      match: {
        params: { id },
      }, t, 
      onReload
    } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      confirmBooking(id, selectedBooking.activityId.toString(), selectedBooking.id.toString());
      this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmsucc'), 'green');
    }
    this.modalHandler('');
    onReload();
  }

  async handleReject() {
    const { onReload, t } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      const params = {
        filter: {
          relations: ['divers'],
        },
      };
      const booking = (await getOneBookings(selectedBooking.id.toString(), params)).data;
      booking.rejected = true;
      await uploadBookings(booking.id, booking);
      this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmrej'), 'green');
    }
    this.modalHandler('')
    onReload();

  }

  async handleReassignActivity(activitySelected: any) {
    const { onReload, t } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      const params = {
        filter: {
          relations: ['activity', 'divers'],
        },
      };
      let booking = (await getOneBookings(selectedBooking.id.toString(), params)).data;
      booking.activity = +activitySelected;
      await uploadBookings(booking.id, booking);
      this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmreas'), 'green');
    }
    this.modalHandler('')
    onReload();
  }

  async handleCancel() {
    const { onReload, t } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      const params = {
        filter: {
          relations: ['divers'],
        },
      };
      let booking = (await getOneBookings(selectedBooking.id.toString(), params)).data;
      booking.cancelled = 'canceledByCentre';
      await cancelBooking(booking.id, { 'cancellationType': 'canceledByCentre' })
      //await uploadBookings(booking.id, booking);
      this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmcancel'), 'green');
    }
    this.modalHandler('')
    onReload();
  }

  async handleChangeToNP() {
    const { onReload, t } = this.props;
    const { selectedBooking } = this.state;
    if (selectedBooking) {
      const params = {
        filter: {
          relations: ['divers'],
        },
      };
      let booking = (await getOneBookings(selectedBooking.id.toString(), params)).data;
      if (booking.presented) {
        booking.presented = false;
        await uploadBookings(booking.id, booking);
        this.sendNotification(t('modalMessages:modal-confirmation-activity.confirmed'), t('modalMessages:modal-confirmation-activity.confirmnewstat'), 'green');
      } else {
        this.sendNotification('Error', t('modalMessages:modal-confirmation-activity.confirmnopres'), 'yellow');
      }
    }
    this.modalHandler('')
    onReload();
  }

  render() {
    const { bookingsRejects, t } = this.props;
    const { modal, selectedBooking } = this.state;

    return (
      <BookingsStyle>
        <MainLayoutTableContainer>
          <div className="diving-center-table">
            <MainTable
              columns={this.tableColumns}
              rows={this.getTableRows()}
              rowKeys={this.rowKeys}
              onOrder={() => { }}
            />
          </div>
          {bookingsRejects && bookingsRejects.length > 0 &&
            <div className="diving-center-table">
              <div className="title-reject">{t('modalMessages:rejected')}</div>
              <MainTable
                rows={this.getTableRowsRejects()}
                rowKeys={this.rowKeys}
                onOrder={() => { }}
              />
            </div>
          }
        </MainLayoutTableContainer>
        {modal === 'modal-confirm' && (
          <ModalConfirmation
            showModal={modal === 'modal-confirm'}
            subtitle={
              t('modalMessages:pressrej')
            }
            onAccept={() => this.handleConfirm()}
            onCancel={() => this.modalHandler('')}
          />
        )}
        {modal === 'modal-reject' && (
          <ModalConfirmation
            showModal={modal === 'modal-reject'}
            subtitle={
              t('modalMessages:pressrej')
            }
            onAccept={() => this.handleReject()}
            onCancel={() => this.modalHandler('')}
          />
        )}
        {modal === 'modal-reassign-activity' && (
          <ModalContainer
            title={t('modalMessages:modal-cancelation-activity.cancel-res')}
            modalClose={() => this.modalHandler('')}
            active={modal === 'modal-reassign-activity'}
          >
            <ReassignActivity
              selectedBooking={selectedBooking}
              onNext={(activitySelected: any) => {
                this.handleReassignActivity(activitySelected);
              }}
            />
          </ModalContainer>
        )}
        {modal === 'modal-cancel' && (
          <ModalConfirmation
            showModal={modal === 'modal-cancel'}
            subtitle={
              t('modalMessages:presscancel')
            }
            onAccept={() => this.handleCancel()}
            onCancel={() => this.modalHandler('')}
          />
        )}
        {modal === 'modal-chante-to-np' && (
          <ModalConfirmation
            showModal={modal === 'modal-chante-to-np'}
            subtitle={
              t('modalMessages:pressnopres')
            }
            onAccept={() => this.handleChangeToNP()}
            onCancel={() => this.modalHandler('')}
          />
        )}
        {modal === 'modal-booking-detail' && (
          <ModalBookingDetail
            booking={selectedBooking}
            showModal={modal === 'modal-booking-detail'}
            modalClose={() => this.modalHandler('')}
          />
        )}

        {modal === 'modal-booking-detail-divers' && (
          <ModalBookingDetailDivers
            booking={selectedBooking}
            showModal={modal === 'modal-booking-detail-divers'}
            modalClose={() => this.modalHandler('')}
          />
        )}

      </BookingsStyle>
    );
  }

  rowKeys = [
    'booking',
    'departureDate',
    'departureHour',
    'activityName',
    'discipline',
    'numDivers',
    'price',
    'status',
    'actions',
  ];

  tableColumns = [
    {
      text: this.props.t('layouts:bookings.res'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('layouts:bookings.datedepart'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('layouts:bookings.sched'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('layouts:bookings.actname'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('layouts:bookings.disc'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('layouts:bookings.options.nrodivers'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('layouts:bookings.booking.price'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('layouts:bills-superadmin.columns.status'),
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: false,
    },
  ];

}

export default withTranslation(['layouts', 'buttons', 'modalMessages'])(withRouter(BookingsType));
