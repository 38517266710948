import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalLogOutContainer = styled.div`
  width: 416px;
  padding: 62px 32px 32px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;

  .modal-logout-img  {
    padding: 20px;
    border-radius: 100%;
    width: 68px;
    height: 68px;
    background: ${colors['PR-001/10']};
    margin-bottom: 20px;
  }
  .modal-logout-title {
    text-align: center;
    margin-bottom: 40px;

    &__text {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: ${colors['PR-001/100']};
    }

    &__description {
      margin-top: 20px;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      max-width: 306px;
    }
  }
  .modal-logout-buttons  {
    width: 280px;
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;
