export const STATE_DROPDOWN = [
  { value: 'success', label: 'Validado' },
  { value: 'pending', label: 'Pendiente' },
  { value: 'rejected', label: 'Rechazado' },
];

export const STATE_KEY_VALUES = {
  success: 'VALIDADO',
  pending: 'PENDIENTE',
  rejected: 'RECHAZADO',
};

export const ACTIVITY_STATES: any = [
  { value: 'done', label: 'Realizado' },
  { value: 'finished', label: 'Finalizado' },
  { value: 'confirmed', label: 'Confirmado' },
  { value: 'pending', label: 'Pendiente' },
];


export const ACTIVITY_STATES_NEW: any = [
  { value: 'resolved', label: 'Resuelta' },
  { value: 'urgent', label: 'Urgente' },
  { value: 'pending', label: 'Pendiente' },
  { value: '', label: 'Sin reservas' },
];

export const PLANNING_CALENDAR_ACTIVITY_STATES: any = [
  { value: 'done', label: 'Realizadas' },
  { value: 'cancelled', label: 'Canceladas' },
  { value: 'confirmed', label: 'Confirmadas' },
  { value: 'pending', label: 'Pendientes de confirmación' },
];


export const PLANNING_CALENDAR_ACTIVITY_STATES_NEW: any = [
  { value: 'resolved', label: 'Realizadas' },
  { value: 'resolved', label: 'Canceladas' },
  { value: 'resolved', label: 'Confirmadas' },
  { value: 'urgent', label: 'Pendientes de confirmación' },
];

export const ACTIVITY_STATES_KEY_VALUES: any = {
  done: 'Realizado',
  undone: 'No realizado',
  finished: 'Finalizado',
  confirmed: 'Confirmado',
  pending: 'Activada',
  cancelled: 'Cancelado'
};
