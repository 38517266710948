import React from 'react';

import TextArea from '../form-components/input/textarea';
import DicotomicCheckbox from '../dicotomic-checkbox';
import CardSelection from '../card-selection/card-selection';
import { IInputGenerator } from '../../models/form-generator';
import { InputGeneratorStyle } from './input-generator-style';

import InputBox from '../input-box';
import SelectBox from '../select-box';
import CalendarBox from '../calendar-box';
import configurationContact from '../../containers/configuration/contact/configuration-contact';

const InputGenerator: React.FC<IInputGenerator> = props => {
  const { elementConfig: config } = props;
  const inputClasses = config.classNames ? [...config.classNames] : [];
  let inputElement = null;

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <InputBox
          value={props.value}
          labelText={config.label}
          errorCode={props.error}
          placeholder={config.placeholder}
          onChange={(value: string) => {
            props.onChange(value);
          }}
          required={config.required}
          disabled={props.disabled}
          className={inputClasses.join(' ')}
          {...config.inputProps}
        />
      );
      break;
    case 'textarea':
      inputClasses.push('input-generator-textarea');
      inputElement = (
        <TextArea
          value={props.value}
          placeholder={config.placeholder}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.target.value);
          }}
          disabled={props.disabled}
          className={inputClasses.join(' ')}
        />
      );
      break;
    case 'checkbox':
      inputClasses.push('input-generator-checkbox');
      if (config.checkboxProps && config.checkboxProps.options) {
        const options = config.checkboxProps.options.map(opt => (
          <DicotomicCheckbox
            key={opt.label}
            checked={opt.value === props.value}
            disabled={props.disabled}
            className={opt.classNames.join(' ')}
            onCheck={() => {
              props.onChange(opt.value);
            }}
          >
            {opt.label && <span className="input-generator-checkbox__label">{opt.label}</span>}
          </DicotomicCheckbox>
        ));
        inputElement = (
          <div className={inputClasses.join(' ')}>
            {config.label && <span className="input-generator-checkbox__title">{config.label}</span>}
            {options}
          </div>
        );
      }
      break;
    case 'select':
      const options =
        config.selectProps && config.selectProps.optionsText
          ? config.selectProps.optionsText
          : [{ value: '', label: '' }];
      inputElement = (
        <SelectBox
          initialValue={props.value}
          labelText={config.label}
          placeholder={config.placeholder}
          optionsText={options}
          optionKey="value"
          optionValue="label"
          onChange={(value: string) => {
            props.onChange(value);
          }}
          clearValue={
            props.value === '' &&
            (!config.selectProps || !config.selectProps.searchValue || config.selectProps.searchValue === '')
          }
          required={config.required}
          disabled={props.disabled}
          className={inputClasses.join(' ')}
          {...config.selectProps}
          onSearch={(value: string) => {
            if (config.selectProps && config.selectProps.onSelectSearch) {
              config.selectProps.onSelectSearch(value, props.dynamic);
            }
          }}
        />
      );
      break;
    case 'calendar':
      inputElement = (
        <CalendarBox
          initialValue={props.value}
          labelText={config.label}
          placeholder={config.placeholder}
          clearValue={props.value === ''}
          required={config.required}
          disabled={props.disabled}
          onChange={(value: string) => {
            props.onChange(value);
          }}
          className={inputClasses.join(' ')}
          {...config.calendarBoxProps}
        />
      );
  }
  return <InputGeneratorStyle>{inputElement}</InputGeneratorStyle>;
};

export default InputGenerator;
