import styled from 'styled-components';

export const ModalEditProfileStyle = styled.div`
  padding: 36px 32px 32px 32px;
  width: 416px;
  .modal-edit-profile-title {
    margin-bottom: 12px;
    margin-bottom: 40px;
  }
  .modal-edit-profile-avatar {
    margin-bottom: 40px;
    display: flex;
    &__img {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 20px;
      img {
        width: 100%;
        min-height: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    &__buttons {
      &__item {
        margin-bottom: 12px;
        position: relative;
        &__input {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }
    }
  }

  .modal-edit-profile-form {
    margin-bottom: 38px;
  }
`;
