import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ItemReviewStyle = styled.div`
  display: flex;

  .item-review-avatar {
    width: 52px;
    min-width: 52px;
    height: 52px;
    min-height: 52px;
    border-radius: 100%;
    margin-right: 24px;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .item-review-title {
    margin-bottom: 5px;
  }
  .item-review-date {
    margin-bottom: 12px;
    color: ${colors['NE-002/100']};
  }
  .item-review-comment {
    margin-bottom: 16px;
  }
  .item-review-rate {
    display: flex;
    .item-review-rate-item  {
      display: flex;
      align-items: center;
      margin-right: 16px;

      &.red {
        color: ${colors['CO-004/100']};
        .item-review-rate-item-icon {
          background: ${colors['CO-004/15']};
        }
      }
      &.blue {
        color: ${colors['PR-001/100']};
        .item-review-rate-item-icon {
          background: ${colors['PR-001/10']};
        }
      }
      &.green {
        color: ${colors['CO-001/100']};
        .item-review-rate-item-icon {
          background: ${colors['CO-001/15']};
        }
      }
      .item-review-rate-item-icon {
        width: 28px;
        height: 28px;
        margin-right: 6px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;
