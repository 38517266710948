import * as React from 'react';
import { ConfigurationContactStyle } from './configuration-contact-style';
import { MainLayoutSection, FormGrid, FormGridRow, FormGridItem } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import { images } from '../../../assets/images';
import InputBox from '../../../components/input-box';
import MainButton from '../../../components/main-button';
import { IContactForm } from '../../../models/configuration-forms';
import { isValidPhoneESP, isValidEmail, isValidPostalCode, isValidURL } from '../../../utils/validation';
import { getConfiguration, postConfiguration } from '../../../services/configuration';
import services from '../../../services/services';
import SelectBox from '../../../components/select-box';
import { COUNTRIES, COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import { copyOf } from '../../../utils/other';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

export interface IConfigurationContactProps extends RouteComponentProps, WithTranslation {}

export interface IConfigurationContactState {
  contact: IContactForm;
  errors: { [key: string]: string };
}

class ConfigurationContact extends React.Component<IConfigurationContactProps, IConfigurationContactState> {
  constructor(props: IConfigurationContactProps) {
    super(props);

    this.state = {
      contact: {
        name: '',
        address: '',
        postalCode: '',
        town: '',
        country: '',
        website: '',
        contactEmail: '',
        phone1: '',
        phone2: '',
      },
      errors: {},
    };
  }

  componentDidMount() {
    this.getConfiguration();
  }

  async getConfiguration() {
    const config: any = (await getConfiguration()).data;
    const contact: IContactForm = {
      name: config.name ? config.name : '',
      address: config.address ? config.address : '',
      postalCode: config.postalCode ? config.postalCode : '',
      town: config.town ? config.town : '',
      country: config.country ? config.country : '',
      website: config.website ? config.website : '',
      contactEmail: config.contactEmail ? config.contactEmail : '',
      phone1: config.phone1 ? config.phone1.number : '',
      phone2: config.phone2 ? config.phone2.number : '',
    };
    this.setState({ contact });
  }

  validate() {
    const { contact } = this.state;
    let error = false;
    Object.entries(contact).forEach((field: any) => {
      error = error || this.validateField(field[0], field[1]);
    });
    return error;
  }

  validateField(field: string, value: string | number) {
    const errors = Object.assign(this.state);
    errors[field] = '';
    if (!value || value === '') errors[field] = 'required';
    else {
      switch (field) {
        case 'postalCode':
          errors[field] = isValidPostalCode(value.toString()) ? '' : 'invalid-postal-code';
          break;
        case 'website':
          errors[field] = isValidURL(value.toString()) ? '' : 'invalid-format';
          break;
        case 'contactEmail':
          errors[field] = isValidEmail(value.toString()) ? '' : 'invalid-email';
          break;
        case 'phone2':
        case 'phone1':
          errors[field] = isValidPhoneESP(value.toString()) ? '' : 'invalid-phone';
          break;
      }
    }
    this.setState({ errors });
    return errors[field] !== '';
  }

  handleChange(field: string, value: string | number) {
    const { contact } = Object.assign(this.state);
    contact[field] = value;
    this.validateField(field, value);
  }

  async handleSave() {
    const { contact } = this.state;
    const { t } = this.props;
    if (!this.validate()) {
      const contactFormat = copyOf(contact);
      contactFormat.phone1 = {
        number: contact.phone1,
        prefix: '+34',
      };
      contactFormat.phone2 = {
        number: contact.phone2,
        prefix: '+34',
      };
      await postConfiguration(contactFormat);
      services.pushNotification({
        title: t('push:save-config.title'),
        text: t('push:save-config.text'),
        type: 'green',
      });
    }
  }

  render() {
    const { errors, contact } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderMain title={t('layouts:config.config.header')} />
        <MainLayoutSection>
          <ConfigurationContactStyle>
            <div className="config-contact-title">
              <div className="config-contact-title__icon">
                <img src={images.homeSvg} alt="" />
              </div>
              <div className="config-contact-title__text">
                <SubheadDestacadoText>{t('layouts:config.config.title')}</SubheadDestacadoText>
              </div>
            </div>

            <div className="config-contact-form">
              <div className="config-contact-form__left">
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        className="rounded-title"
                        errorCode={errors.name}
                        required={true}
                        type="text"
                        value={contact.name}
                        placeholder={t('inputs:name.placeholder')}
                        labelText={t('inputs:name.label')}
                        onChange={val => this.handleChange('name', val)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        className="rounded-title"
                        errorCode={errors.address}
                        required={true}
                        type="text"
                        value={contact.address}
                        placeholder={t('inputs:address.placeholder')}
                        labelText={t('inputs:address.label')}
                        onChange={val => this.handleChange('address', val)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        className="rounded-title"
                        errorCode={errors.postalCode}
                        required={true}
                        type="text"
                        value={contact.postalCode.toString()}
                        labelText={t('inputs:postal-code.label')}
                        placeholder={t('inputs:postal-code.placeholder')}
                        onChange={val => this.handleChange('postalCode', val)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        className="rounded-title"
                        errorCode={errors.town}
                        required={true}
                        type="text"
                        value={contact.town}
                        placeholder={t('inputs:city.placeholder')}
                        labelText={t('inputs:city.label')}
                        onChange={val => this.handleChange('town', val)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <SelectBox
                        placeholder={t('inputs:country.placeholder')}
                        className="rounded-title"
                        errorCode={errors.country}
                        required={true}
                        optionKey={'value'}
                        optionValue={'label'}
                        optionsText={COUNTRIES}
                        defaultValue={contact.country}
                        initialValue={contact.country}
                        defaultValueFilterText={COUNTRIES_KEY_LABEL[contact.country]}
                        searchable={true}
                        icon={images.arrowDown2BlueSvg}
                        labelText={t('inputs:country.label')}
                        onChange={val => this.handleChange('country', val)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
              </div>
              <div className="config-contact-form__right">
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        placeholder={t('inputs:web.placeholder')}
                        className="rounded-title"
                        errorCode={errors.website}
                        required={true}
                        type="text"
                        value={contact.website}
                        labelText={t('inputs:web.label')}
                        onChange={val => this.handleChange('website', val)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        placeholder={t('inputs:email.placeholder')}
                        className="rounded-title"
                        errorCode={errors.contactEmail}
                        required={true}
                        type="text"
                        value={contact.contactEmail}
                        labelText={t('inputs:email.label')}
                        onChange={val => this.handleChange('contactEmail', val)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        placeholder={`${t('inputs:phone.label')} 01`}
                        className="rounded-title"
                        errorCode={errors.phone1}
                        required={true}
                        type="text"
                        value={contact.phone1}
                        labelText={`${t('inputs:phone.label')} 01`}
                        onChange={val => this.handleChange('phone1', val)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        placeholder={`${t('inputs:phone.label')} 02`}
                        className="rounded-title"
                        errorCode={errors.phone2}
                        required={true}
                        type="text"
                        value={contact.phone2}
                        labelText={`${t('inputs:phone.label')} 02`}
                        onChange={val => this.handleChange('phone2', val)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
              </div>
            </div>
            <div className="config-contact-buttons">
              <div className="config-contact-buttons__item">
                <MainButton type="primary" text={t('buttons:save')} onClick={() => this.handleSave()} />
              </div>
              <div className="config-contact-buttons__item">
                <MainButton type="secondary" text={t('buttons:cancel')} onClick={() => this.getConfiguration()} />
              </div>
            </div>
          </ConfigurationContactStyle>
        </MainLayoutSection>
      </>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(withRouter(ConfigurationContact));
