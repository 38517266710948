import React, { Component } from 'react';
import MainTable from '../../../../components/main-table/main-table';
import Pagination from '../../../../components/pagination';
import { MainLayoutTableContainer } from '../../../main-layout-style';
import { tableColumns, rowKeys, DiverDocument } from './types';
import { DiverDocumentsFunctionalProps } from './diver-documents-functional';
import { TableRow, TableCell } from '../../../../types/table';
import moment from 'moment';
import 'moment/locale/es.js';
import ModalDocument from '../../../../components/modals/modal-document/modal-document';
import FileUtils from '../../../../utils/files';
import ModalUploadDocument from '../../../../components/modals/modal-upload-document/modal-upload-document';
import { IUploadDocumentForm } from '../../../../models/document-forms';
import { images } from '../../../../assets/images';
import ModalInfo from '../../../../components/modals/modal-info/modal-info';
import { DIVER_DOCUMENT_KEY_VALUES } from '../../../../constants/diver-document-types';

interface DiverDocumentsProps extends DiverDocumentsFunctionalProps {
  renderModal: string;
  onRenderModal: (modal: string) => void;
  closeModal: () => void;
  items: DiverDocument[];
  totalPages: number;
  setPage: (page: number) => void;
  rowsSelected: any[];
  onSelectRows: (rows: any[], all: boolean, allValue?: boolean) => void;
  onViewDocument: (id: number) => void;
  onUploadDocument: (files: FormData) => any;
  onValidateDocument: (id: number, rowClicked: boolean) => void;
  onDeleteDocument: (id: number, rowClicked: boolean) => void;
  viewDocument: any;
  page: number;
}

interface DiverDocumentsState {
  showModalDocument: boolean;
  selectedDocumentId: number;
  rowClicked: boolean;
}

export default class DiverDocuments extends Component<DiverDocumentsProps, DiverDocumentsState> {
  state = {
    showModalDocument: false,
    selectedDocumentId: 0,
    rowClicked: false,
  };

  formatDataToTableRows(data: { [key: string]: any }[]) {
    const {
      match: {
        params: { staff },
      },
    } = this.props;
    const { rowsSelected, isCentre, isOrganization } = this.props;

    return data.map(item => {

      const row: TableRow = {};
      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };
        switch (key) {
          case 'expirationDate':
            cell.value = moment(item[key], 'YYYY-MM-DD').format('DD/MM/YYYY');
            cell.key = 'body';
            break;
          case 'kind':
            (cell.value = item[key] ? DIVER_DOCUMENT_KEY_VALUES[item[key]] : '-'), (cell.key = 'highlighted-body');
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });
      row.check = {
        value: rowsSelected.find((row: any) => row.id === item.id),
        type: '',
        key: 'check',
      };

      const itemIcon = FileUtils.getExtension(item.file)
        .toLowerCase()
        .replace(/x([^x]*)$/, '')
        .replace(/[0-9]/g, '');
      row.icon = {
        value: itemIcon.replace('e', ''),
        type: '',
        className: 'file-extension-icon',
        key: 'file',
      };

      row.validate = {
        value: item.validated
          ? item.validated.user.roles.includes('divingCenter_admin')
            ? 'centro'
            : item.validated.user.roles.includes('superAdmin')
              ? 'superAdmin'
              : item.validated.user.roles.includes('organization_admin')
                ? 'CERTIFICADORA PADI'
                : 'validado'
          : '',
        type: item.validated
          ? item.validated.user.roles.includes('divingCenter_admin') || item.validated.user.roles.includes('superAdmin')
            ? 'state-center'
            : 'state-certificate'
          : '',
        key: 'state',
      };


      /*row.status = {
        value: true,
        type: '',
        key: item.validated ? (item.validated.user ? 'success' : 'official') : 'reject',
      };*/

      row.options = {
        value: item.id,
        type: '',
        key: 'options',
        actions: [
          {
            label: 'Ver',
            icon: images.eyeSvg,
            onClick: () => this.onClickDetail(item.id),
          },
        ],
      };
      if (!isCentre && !isOrganization && row.options.actions) {
        row.options.actions.push({
          label: 'Eliminar',
          icon: images.deleteSvg,
          onClick: () => this.onClickDelete(item.id),
        });
      }
      if (!item.validated && !staff) {
        row.options.actions = [
          {
            label: 'Ver',
            icon: images.eyeSvg,
            onClick: () => this.onClickDetail(item.id),
          },
          /*{
            label: 'Validar',
            icon: images.checkCircleSvg,
            onClick: () => this.onClickValidate(item.id),
          },*/
        ];
        if (!isCentre && !isOrganization && row.options.actions) {
          row.options.actions.push({
            label: 'Eliminar',
            icon: images.deleteSvg,
            onClick: () => this.onClickDelete(item.id),
          });
        }
      }
      return row;
    });
  }

  onClickValidate(id: number) {
    const { onRenderModal } = this.props;
    onRenderModal('modal-validate-document');
    this.setState({ selectedDocumentId: id, rowClicked: true });
  }

  onClickDelete(id: number) {
    const { onRenderModal } = this.props;
    onRenderModal('modal-delete-document');
    this.setState({ selectedDocumentId: id, rowClicked: true });
  }

  onClickDetail(id: number) {
    const { onViewDocument } = this.props;
    onViewDocument(id);
    this.setState({ showModalDocument: true });
  }

  onCloseModalDocument() {
    this.setState({ showModalDocument: false });
  }

  onCloseModalUploadDocument() {
    const { closeModal } = this.props;
    closeModal();
  }

  handleUploadDocument(form: IUploadDocumentForm) {
    const { onUploadDocument } = this.props;
    const formData = new FormData();
    formData.set('kind', form.kind);
    formData.set('name', form.name);
    if (form.expirationDate && form.expirationDate !== '') {
      formData.set('expirationDate', form.expirationDate);
    }
    if (form.certifier && form.course) {
      formData.set('certifier', form.certifier);
      formData.set('course', form.course);
    }
    formData.set('file', form.file[0].data);
    onUploadDocument(formData);
  }

  getValidateButtons() {
    const { isCentre, isOrganization, onDeleteDocument, onValidateDocument } = this.props;
    const { selectedDocumentId, rowClicked } = this.state;
    const buttons = [
      /*{
        text: 'Validar',
        type: 'primary',
        onClick: () => {
          onValidateDocument(selectedDocumentId, rowClicked);
        },
      },*/
    ];

    if (!isCentre && !isOrganization) {
      buttons.push({
        text: 'Eliminar',
        type: 'secondary-red',
        onClick: () => onDeleteDocument(selectedDocumentId, rowClicked),
      });
    }
    return buttons;
  }

  render() {
    const {
      renderModal,
      onRenderModal,
      items,
      totalPages,
      setPage,
      onValidateDocument,
      rowsSelected,
      onSelectRows,
      viewDocument,
      onDeleteDocument,
      isCentre,
      isOrganization,
      page,
    } = this.props;
    const { showModalDocument, selectedDocumentId, rowClicked } = this.state;
    return (
      <>
        <MainLayoutTableContainer>
          <div className="diving-center-table">
            <MainTable
              columns={tableColumns}
              rows={this.formatDataToTableRows(items)}
              rowKeys={rowKeys}
              onSelect={(rows, all, allValue) => {
                onSelectRows(rows, all, allValue);
              }}
              onOrder={() => { }}
            />
          </div>
          {totalPages > 1 && <Pagination page={page} limit={totalPages} callback={page => setPage(page)} />}
        </MainLayoutTableContainer>

        {/* Modal Confirmacion */}

        <ModalInfo
          type="blue"
          icon={images.checkCircleBlueSvg}
          title={[
            <span key="modal-validate-document">
              {rowsSelected.length < 2 || rowClicked
                ? 'Validar documento'
                : `Validar ${rowsSelected.length} documentos`}
            </span>,
          ]}
          text={[
            'Una vez validado el documento del buceador, este aparecerá reflejado en el perfil del buceador y le permitirá tener acceso a determinados servicios dentro de la aplicación.',
          ]}
          showModal={renderModal === 'modal-validate-document'}
          onClose={() => {
            onRenderModal('');
            this.setState({ rowClicked: false });
          }}
          buttons={this.getValidateButtons()}
        />

        <ModalInfo
          type="red"
          icon={images.closeCircleRedSvg}
          title={[
            <span key="modal-delete-document">
              {rowsSelected.length < 2 || rowClicked
                ? 'Eliminar documento'
                : `Eliminar ${rowsSelected.length} documentos`}
            </span>,
          ]}
          text={[
            rowsSelected.length < 2 || rowClicked
              ? 'Una vez eliminado el documento, no se podrá recupear.'
              : 'Una vez eliminados los documentos, no se podrán recupear.',
          ]}
          showModal={renderModal === 'modal-delete-document'}
          onClose={() => {
            onRenderModal('');
            this.setState({ rowClicked: false });
          }}
          buttons={[
            {
              text: 'Eliminar',
              type: 'secondary-red',
              onClick: () => onDeleteDocument(selectedDocumentId, rowClicked),
            },
            { text: 'Cancelar', type: 'secondary-red', onClick: () => onRenderModal('') },
          ]}
        />

        {/* Modal View Document */}
        <ModalDocument
          showModal={showModalDocument}
          onClose={() => {
            this.onCloseModalDocument();
          }}
          title={viewDocument.name}
          id={viewDocument.docId}
          expirationDate={viewDocument.expirationDate}
          file={viewDocument.file}
          certifier={viewDocument.certifier ? viewDocument.certifier.name : ''}
          isCentre={isCentre || isOrganization}
          onValidate={() => {
            this.setState({ showModalDocument: false });
            this.onClickValidate(viewDocument.id);
          }}
          onDelete={() => {
            this.setState({ showModalDocument: false });
            onRenderModal('modal-delete-document');
            // this.onClickDelete(viewDocument.id);
          }}
        />

        {/* Modal Upload Document*/}
        {renderModal === 'modal-upload-document' && (
          <ModalUploadDocument
            title="Subir documentos"
            text={[
              'Suba los documentos del buceador para validación y que pueda disfrutar de todas las experiencias que ofrece DiveAsapp.',
            ]}
            showModal={renderModal === 'modal-upload-document'}
            onClose={() => {
              this.onCloseModalUploadDocument();
            }}
            onSubmit={(form: IUploadDocumentForm) => {
              this.handleUploadDocument(form);
            }}
          />
        )}
      </>
    );
  }
}
