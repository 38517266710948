import * as React from 'react';
import { CreateActivitiesDetailsStyle } from './create-activities-details-style';
import { FormGridRow, FormGridItem, FormGrid } from '../../../../main-layout-style';
import InputArea from '../../../../../components/input-area/input-area';
import MainButton from '../../../../../components/main-button';
import { images } from '../../../../../assets/images';
import SelectBox from '../../../../../components/select-box';
import { IMultilang } from '../../../../../models/generic';
import { LANGUAGES, LANGUAGES_KEY_VALUES } from '../../../../../constants/languages';
import SingleButton from '../../../../../components/single-button/single-button';
import { copyOf } from '../../../../../utils/other';
import { getCourse } from '../../../../../services/courses';
import { Editor } from '@tinymce/tinymce-react';
import {  TINYMCE_KEY } from '../../../../../env';

const languagesTemplate = {
  en: '',
  es: '',
  de: '',
  fr: '',
  it: '',
  pt: '',
};

export interface ICreateActivitiesDetailsProps {
  languagesDescriptions?: IMultilang;
  languagesComments?: IMultilang;
  onPrev?: () => void;
  onNext: (languagesDescriptions: IMultilang, languagesComments: IMultilang) => void;
  centreId: string;
  course?: any;
}

export interface ICreateActivitiesDetailsState {
  descriptions: any;
  comments: any;
  selectedLanguageDescriptions: string;
  selectedLanguagesDescriptions: string[];
  selectedLanguageComments: string;
  selectedLanguagesComments: string[];
  errors: any;
}

export default class CreateActivitiesDetails extends React.Component<
  ICreateActivitiesDetailsProps,
  ICreateActivitiesDetailsState
  > {
  constructor(props: ICreateActivitiesDetailsProps) {
    super(props);

    this.state = {
      descriptions: copyOf(languagesTemplate),
      comments: copyOf(languagesTemplate),
      selectedLanguageDescriptions: '',
      selectedLanguagesDescriptions: [],
      selectedLanguageComments: '',
      selectedLanguagesComments: [],
      errors: copyOf(languagesTemplate),
    };
  }

  async componentDidMount() {
    const { languagesDescriptions, languagesComments, course } = this.props;
    if (languagesDescriptions) {
      this.setState({ descriptions: languagesDescriptions, selectedLanguagesDescriptions: Object.keys(languagesDescriptions) });
    } else if (course) {
      const courseDescription = (await getCourse(course)).data.description;
      this.setState({ descriptions: courseDescription, selectedLanguagesDescriptions: Object.keys(courseDescription) });
    }
    if (languagesComments) {
      this.setState({ comments: languagesComments, selectedLanguagesComments: Object.keys(languagesComments) });
    }
  }

  componentDidUpdate(prevProps: ICreateActivitiesDetailsProps) {
    const { languagesDescriptions, languagesComments } = this.props;
    if (languagesDescriptions && prevProps.languagesDescriptions !== languagesDescriptions) {
      this.setState({ descriptions: languagesDescriptions, selectedLanguagesDescriptions: Object.keys(languagesDescriptions) });
    }
    if (languagesComments && prevProps.languagesComments !== languagesComments) {
      this.setState({ comments: languagesComments, selectedLanguagesComments: Object.keys(languagesComments) });
    }
  }

  handleNext() {
    const { onNext } = this.props;
    const { selectedLanguagesDescriptions: selectedLanguagesDescription, descriptions, selectedLanguagesComments, comments } = this.state;
    const errors: any = copyOf(languagesTemplate);
    let hasError = false;

    const descriptionFormatted = copyOf(descriptions);
    Object.keys(descriptionFormatted).forEach((lang: string) => {
      if (descriptionFormatted[lang] === '' || !selectedLanguagesDescription.includes(lang)) delete descriptionFormatted[lang];
    });
    selectedLanguagesDescription.forEach((lang: string) => {
      if (!descriptions[lang] || descriptions[lang] === '') {
        errors[lang] = 'required';
        hasError = true;
      } else errors[lang] = '';
    });

    const commentsFormatted = copyOf(comments);
    Object.keys(commentsFormatted).forEach((lang: string) => {
      if (commentsFormatted[lang] === '' || !selectedLanguagesComments.includes(lang)) delete commentsFormatted[lang];
    });
    selectedLanguagesComments.forEach((lang: string) => {
      if (!comments[lang] || comments[lang] === '') {
        errors[lang] = 'required';
        hasError = true;
      } else errors[lang] = '';
    });

    // if (selectedLanguages.length === 0) {
    //   errors.selectedLanguages = 'required';
    //   hasError = true;
    // } else {
    //   errors.selectedLanguages = '';
    // }
    this.setState({ errors });
    if (!hasError) {
      onNext(descriptionFormatted, commentsFormatted);
    }
  }

  handleChangeComments(e:any) {
    //e.preventDefault();
    const { comments, selectedLanguageComments, errors } = Object.assign(this.state);
    const comment = e;
    comments[selectedLanguageComments] = comment;
    if (!comment || comment === '') errors[selectedLanguageComments] = 'required';
    else errors[selectedLanguageComments] = '';
    //this.setState({ comments, errors });
  }

  handleChangeDescriptions(e:any) {
    //e.preventDefault();
    // console.log(e);
    const description =  e;
    const { descriptions, selectedLanguageDescriptions, errors } = Object.assign(this.state);
    descriptions[selectedLanguageDescriptions] = description;
    if (!description || description === '') errors[selectedLanguageDescriptions] = 'required';
    else errors[selectedLanguageDescriptions] = '';
    //this.setState({ descriptions, errors });
  }


handleChangeUpdate(e:any) {
    e.preventDefault();
    /*const description =  e.target.getContent();
    const { descriptions, selectedLanguageDescriptions, errors } = Object.assign(this.state);
    descriptions[selectedLanguageDescriptions] = description;
    if (!description || description === '') errors[selectedLanguageDescriptions] = 'required';
    else errors[selectedLanguageDescriptions] = '';
    this.setState({ descriptions, errors });*/
  }

  static getDerivedStateFromProps(props:any, state:any) {

    const { selectedLanguageDescriptions, selectedLanguagesDescriptions,selectedLanguageComments, selectedLanguagesComments} = state;

    let newState = {}

    if ((selectedLanguageDescriptions === '' && selectedLanguagesDescriptions.length > 0) || (selectedLanguagesDescriptions.length > 0 && !selectedLanguagesDescriptions.includes(selectedLanguageDescriptions))) {
      newState = {...newState, selectedLanguageDescriptions: selectedLanguagesDescriptions[0]}
    }

    if ((selectedLanguageComments === '' && selectedLanguagesComments.length > 0) || (selectedLanguagesComments.length > 0 && !selectedLanguagesComments.includes(selectedLanguageComments))) {
      newState = {...newState, selectedLanguageComments: selectedLanguagesComments[0]}
    }

    return newState && Object.keys(newState).length === 0 && newState.constructor === Object ? newState : false
  }

  render() {
    const { onPrev } = this.props;
    const { comments, descriptions, errors, selectedLanguagesDescriptions, selectedLanguagesComments, selectedLanguageDescriptions, selectedLanguageComments } = this.state;

    return (
      <CreateActivitiesDetailsStyle>
        <div>
          <div className="subtitle">Descripción</div>
          <FormGrid>
            <FormGridRow>
              <FormGridItem type="col-0">
                <SelectBox
                  className="rounded-title"
                  labelText="Idiomas mostrados"
                  placeholder="Idiomas mostrados"
                  multiple={true}
                  required={true}
                  initialMultipleValues={selectedLanguagesDescriptions}
                  errorCode={errors.selectedLanguagesDescriptions}
                  optionsText={LANGUAGES}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={''}
                  icon={images.arrowDown2BlueSvg}
                  onChange={() => { }}
                  onChangeMultiple={selectedLanguagesDescriptions => this.setState({ selectedLanguagesDescriptions: selectedLanguagesDescriptions })}
                />
              </FormGridItem>
            </FormGridRow>
            <div className={'modal-create-add-languages'}>
              {selectedLanguagesDescriptions.map((lang: string, index: number) => (
                <div className={'modal-create-add-languages__item'} key={index}>
                  <MainButton
                    onClick={() => this.setState({ selectedLanguageDescriptions: lang })}
                    text={LANGUAGES_KEY_VALUES[lang]}
                    type={selectedLanguageDescriptions === lang ? 'primary' : errors[lang] === '' ? 'secondary' : 'secondary-red'}
                  />
                </div>
              ))}
            </div>
            {selectedLanguageDescriptions !== '' && selectedLanguagesDescriptions.includes(selectedLanguageDescriptions) && (
              <FormGridRow key={`description-${selectedLanguageDescriptions}`}>
                <FormGridItem type="col-0">
{/*                  <InputArea
                    name={selectedLanguageDescriptions}
                    placeholder={`Descripción ${selectedLanguageDescriptions}`}
                    required={true}
                    showMessage={errors[selectedLanguageDescriptions] !== ''}
                    errorCode={errors[selectedLanguageDescriptions]}
                    value={descriptions[selectedLanguageDescriptions]}
                    onChange={(event: any) => this.handleChangeDescriptions(event.target.value)}
                  />*/}
                  <Editor

                    apiKey={TINYMCE_KEY}
                    initialValue={descriptions[selectedLanguageDescriptions]}
                    init={{
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                      'bold italic | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat '
                    }}
                    /*onKeyDown={(event: any) => this.handleChangeUpdate(event)}
                    onKeyUp={(event: any) => this.handleChangeUpdate(event)}*/
                    onEditorChange={(event: any) => this.handleChangeDescriptions(event)}
                    ></Editor>
                </FormGridItem>
              </FormGridRow>
            )}
          </FormGrid>
        </div>
        {/*---------------------------------*/}
        <div>
          <div className="subtitle">Observaciones</div>
          <FormGrid>
            <FormGridRow>
              <FormGridItem type="col-0">
                <SelectBox
                  className="rounded-title"
                  labelText="Idiomas mostrados"
                  placeholder="Idiomas mostrados"
                  multiple={true}
                  required={true}
                  initialMultipleValues={selectedLanguagesComments}
                  errorCode={errors.selectedLanguagesComments}
                  optionsText={LANGUAGES}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={''}
                  icon={images.arrowDown2BlueSvg}
                  onChange={() => { }}
                  onChangeMultiple={selectedLanguagesComments => this.setState({ selectedLanguagesComments: selectedLanguagesComments })}
                />
              </FormGridItem>
            </FormGridRow>
            <div className={'modal-create-add-languages'}>
              {selectedLanguagesComments.map((lang: string, index: number) => (
                <div className={'modal-create-add-languages__item'} key={index}>
                  <MainButton
                    onClick={() => this.setState({ selectedLanguageComments: lang })}
                    text={LANGUAGES_KEY_VALUES[lang]}
                    type={selectedLanguageComments === lang ? 'primary' : errors[lang] === '' ? 'secondary' : 'secondary-red'}
                  />
                </div>
              ))}
            </div>
            {selectedLanguageComments !== '' && selectedLanguagesComments.includes(selectedLanguageComments) && (
              <FormGridRow key={`observacion-${selectedLanguageComments}`}>
                <FormGridItem type="col-0">
{/*                   <InputArea
                    name={selectedLanguageComments}
                    placeholder={`Observación ${selectedLanguageComments}`}
                    required={true}
                    showMessage={errors[selectedLanguageComments] !== ''}
                    errorCode={errors[selectedLanguageComments]}
                    value={comments[selectedLanguageComments]}
                    onChange={(event: any) => this.handleChangeComments(event.target.value)}
                  /> */}

                <Editor
                    apiKey={TINYMCE_KEY}
                    initialValue={comments[selectedLanguageComments]}
                    init={{
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                      'bold italic | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat '
                    }}
                    /*onKeyUp={(event: any) => this.handleChangeUpdate(event)}*/
                    onEditorChange={(event: any) => this.handleChangeComments(event)}
                    ></Editor>

                </FormGridItem>
              </FormGridRow>
            )}
          </FormGrid>
        </div>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}

            <div className="modal-create-activities-buttons-left__next">
              {/*<MainButton text={onPrev ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />*/}
              <MainButton text={'Guardar y publicar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesDetailsStyle>
    );
  }
}
