import React from 'react';
import { ModalBonusesContainer } from './modal-bonuses-style';
import ModalContainer from '../../../../components/modals/modal-container';
import InputBox from '../../../../components/input-box';
import { images } from '../../../../assets/images';
import SelectBox from '../../../../components/select-box';
import MainButton from '../../../../components/main-button';
import DicotomicCheckbox from '../../../../components/dicotomic-checkbox';
import InfoText from '../../../../components/text/info-text/info-text';
import { getActivityTemplates, postCentreTicket, patchCentreTicket } from '../../../../services/centre';
import { IDropdown, IId } from '../../../../models/generic';
import { IActivityTemplate } from '../../../../models/activity-template';
import { copyOf } from '../../../../utils/other';
import { TICKET_DURATION_UNITS_DROPDOWN_2 } from '../../../../constants/duration';

interface ModalProps {
  centreId: string;
  className?: String;
  showModal: boolean;
  ticket?: any;
  onSuccess: (isEdit: boolean) => void;
  onCancel: () => void;
}

interface ModalState {
  selectedActivity?: string;
  activityTemplates: IActivityTemplate[];
  activityTemplatesDropdown: IDropdown[];
  templates: string[];
  name: string;
  uses: number;
  sharedUsage: boolean;
  price: number;
  duration: string;
  edit: boolean;
  errors: {
    name: string;
    uses: string;
    price: string;
    duration: string;
    templates: string;
  };
}

export default class ModalBonuses extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      edit: false,
      name: '',
      uses: NaN,
      sharedUsage: false,
      price: NaN,
      duration: '',
      templates: [],
      activityTemplates: [],
      activityTemplatesDropdown: [],
      errors: {
        name: '',
        uses: '',
        price: '',
        duration: '',
        templates: '',
      },
    };
  }

  componentDidMount() {
    this.getActivityTemplates();
  }

  async getActivityTemplates() {
    const { centreId, ticket } = this.props;
    const resp = await getActivityTemplates(centreId);
    const activityTemplates = resp.data.data.filter((activityTemplate: any) => !activityTemplate.courseInfo);
    const activityTemplatesDropdown = activityTemplates.map((activity: any) => ({
      label: activity.name,
      value: activity.id.toString(),
    }));
    this.setState({ activityTemplates, activityTemplatesDropdown }, () => {
      if (ticket) {
        this.setState({
          edit: true,
          name: ticket.name,
          uses: ticket.uses,
          sharedUsage: ticket.sharedUsage,
          price: ticket.price / 100,
          duration: ticket.duration ? ticket.duration : '',
          templates: ticket.templates.map((template: IId) => template.id.toString()),
        });
      }
    });
  }

  handleAdd() {
    const { selectedActivity, templates, errors } = this.state;
    const newErrors = Object.assign(errors);
    if (selectedActivity) {
      const newSelectedActivities = copyOf(templates);
      newSelectedActivities.push(selectedActivity);
      newErrors.templates = '';
      this.setState({ selectedActivity: undefined, templates: newSelectedActivities, errors: newErrors });
    }
  }

  handleRemove(activityId: string) {
    const { templates } = this.state;
    this.setState({ templates: templates.filter(activity => activity !== activityId) });
  }

  handleChange(field: string, value: string | number | boolean, required: boolean = true) {
    const state = Object.assign(this.state);
    state[field] = value;
    if (typeof value !== 'boolean') {
      state.errors[field] = this.validateField(field, value, required);
    }
    this.setState(state);
  }

  validateField(field: string, value: string | number | object, required: boolean) {
    if (required && (!value || value === '' || (Array.isArray(value) && value.length === 0))) return 'required';
    if (typeof value === 'number' && value < 0) return 'invalid-negative';
    return '';
  }

  async handleSubmit() {
    const { centreId, onSuccess, ticket } = this.props;
    const state = Object.assign(this.state);
    let error = false;
    Object.keys(state.errors).forEach((field: string) => {
      state.errors[field] = this.validateField(field, state[field], true);
      if (state.errors[field] !== '') error = true;
    });
    this.setState(state);
    if (!error) {
      const data: any = {
        templates: state.templates.map((template: string) => ({ id: parseInt(template, undefined) })),
        name: state.name,
        uses: state.uses,
        sharedUsage: state.sharedUsage,
        duration: state.duration,
        price: Math.round(state.price * 100),
        activities: [centreId],
      };

      if (ticket) await patchCentreTicket(centreId, ticket.id, data);
      else await postCentreTicket(centreId, data);
      onSuccess(!!ticket);
    }
  }

  render() {
    const { showModal, onCancel } = this.props;
    const {
      name,
      price,
      uses,
      edit,
      duration,
      activityTemplatesDropdown,
      activityTemplates,
      templates,
      sharedUsage,
      errors,
    } = this.state;
    return (
      <ModalContainer modalClose={() => onCancel()} active={showModal}>
        <ModalBonusesContainer>
          <div className="modal-bonuses-title">
            <p>{edit ? 'Editar bono' : 'Crear bono'}</p>
          </div>
          <div className="modal-bonuses-inputbox">
            <InputBox
              errorCode={errors.name}
              value={name}
              labelText=""
              className="rounded"
              placeholder="Nombre del bono"
              onChange={(value: string) => this.handleChange('name', value)}
            />
          </div>
          <div className="modal-bonuses-subtitle">
            <p>ACTIVIDADES ASOCIADAS</p>
          </div>

          <div className="modal-bonuses-add-container">
            <div className="modal-bonuses-selecbox">
              <SelectBox
                errorCode={errors.templates}
                labelText=""
                className="rounded"
                placeholder="Actividad inmersión"
                required={true}
                initialValue={''}
                optionsText={activityTemplatesDropdown.filter(
                  (activity: IDropdown) => !templates.includes(activity.value)
                )}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={''}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.setState({ selectedActivity: value })}
              />
            </div>
            <div className="modal-bonuses-button-add">
              <MainButton type="secondary" text="Añadir" iconImg={images.plusSvg} onClick={() => this.handleAdd()} />
            </div>
          </div>
          {templates.map((selectedActivity: any, index: number) => {
            const activity = activityTemplates.filter(
              (activity: IActivityTemplate) => activity.id.toString() === selectedActivity
            )[0];
            return (
              <div className="modal-bonuses-results" key={index}>
                <div className="results-text">
                  <div className="results-type">
                    <p>{activity.courseInfo ? 'Formación' : 'Inmersión'}</p>
                  </div>
                  <div className="results-activity">
                    <p>{activity.name}</p>
                  </div>
                </div>
                <div className="results-delete" onClick={() => this.handleRemove(selectedActivity)}>
                  <img src={images.deleteSvg} />
                </div>
              </div>
            );
          })}
          <div className="modal-bonuses-subtitle">
            <p>CARACTERÍSTICAS DEL BONO</p>
          </div>
          <div className="modal-bonuses-features">
            <div className="features-title">
              <p>Número de usos</p>
            </div>
            <div className="features-inputbox">
              <InputBox
                errorCode={errors.uses}
                value={uses.toString()}
                type="number"
                labelText=""
                className="rounded"
                placeholder="Usos"
                onChange={(value: string) => {
                  this.handleChange('uses', parseInt(value, undefined));
                }}
              />
            </div>
          </div>
          <div className="modal-bonuses-features">
            <div className="features-title">
              <p>Uso compartido del bono</p>
            </div>
            <div className="create-activities-other-title__check">
              <DicotomicCheckbox
                className="rounded"
                checked={sharedUsage}
                onCheck={() => this.handleChange('sharedUsage', true)}
              >
                <InfoText>Si</InfoText>
              </DicotomicCheckbox>
            </div>
            <div className="create-activities-other-title__check">
              <DicotomicCheckbox
                className="rounded"
                checked={!sharedUsage}
                onCheck={() => this.handleChange('sharedUsage', false)}
              >
                <InfoText>No</InfoText>
              </DicotomicCheckbox>
            </div>
          </div>
          <div className="modal-bonuses-features">
            <div className="features-title">
              <p>Bono válido durante</p>
            </div>
            <div className="features-inputbox-select">
              {/*units !== 'undefined' && (
                <div className="features-inputbox">
                  <InputBox
                    errorCode={errors.amount}
                    value={amount.toString()}
                    type="number"
                    labelText=""
                    className="rounded"
                    placeholder="Cantidad"
                    onChange={(value: string) => this.handleChange('amount', parseInt(value, undefined))}
                  />
                </div>
              )*/}
              <div className="features-selectbox">
                <SelectBox
                  labelText=""
                  className="rounded"
                  placeholder="Unidad"
                  required={true}
                  initialValue={duration}
                  optionsText={TICKET_DURATION_UNITS_DROPDOWN_2}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={duration}
                  icon={images.arrowDown2BlueSvg}
                  onChange={value => this.handleChange('duration', value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-bonuses-subtitle">
            <p>CARACTERÍSTICAS DEL BONO</p>
          </div>
          <div className="modal-bonuses-inputbox">
            <InputBox
              errorCode={errors.price}
              value={price.toString()}
              labelText=""
              type="number"
              className="rounded"
              placeholder="Precio del bono"
              onChange={value => this.handleChange('price', parseFloat(value))}
            />
          </div>
          <div className="modal-bonuses-button">
            <MainButton type="primary" text="Guardar" onClick={() => this.handleSubmit()} />
          </div>
        </ModalBonusesContainer>
      </ModalContainer>
    );
  }
}
