import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const InputGeneratorStyle = styled.div`
  .input-generator-checkbox {
    display: flex;
    & > *:not(:last-child) {
      margin-right: 36px;
    }

    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    &__label {
      font-size: 12px;
      line-height: 18px;
    }

    & .active .checkmark-item {
      background: ${colors['PR-001/100']};
    }

    & .checkmark-item {
      position: relative;
      width: 16px;
      height: 16px;
      border: 1px solid ${colors['PR-001/100']};
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
      }
    }
  }

  .input-generator-textarea {
    textarea {
      height: 192px;
      &::placeholder {
        color: ${colors['NE-002/100']};
      }
    }
  }
`;
