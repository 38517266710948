import styled, { css } from 'styled-components';

export const ButtonStyle = styled.button`
  width: 100%;
  cursor: pointer;
  font-size: 'plain';
  font-weight: normal;
  width: auto;
  height: auto;
  min-height: 48px;
  /*padding: 10px 24px 8px 24px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out all;
  border-radius: 6px;
  border: none;

  font-size: 16px;
  line-height: 24px;
  border-radius: 5px;
  padding: 8px 22px;
  border-radius: 3px;
  cursor: pointer;

  .button__head {
    display: flex;
    flex-direction: column;
  }

  .button-text {
    flex: auto;
    text-align: center;
    font-weight: 500;
  }
  .button-right-icon,
  .button-left-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    img {
      width: 100%;
      height: 100%;
      ${props => props.filterSVG};
    }
  }

  ${props =>
    props.isIconSingle === false &&
    css`
      .button-left-icon {
        margin-right: 12px;
      }
      .button-right-icon {
        margin-left: 12px;
      }
    `}

  /* STATES */

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  /* STYLES */

  &.primary {
    background: #00a1a5;
    color: #fff;
    padding: 10px 32px 8px 32px;
  }

  &.secondary {
    background: #f8fafd;
    color: #000019;
  }

  &.error {
    background: #ed441e;
    color: #ffffff;
  }

  &.error-minimal {
    background: #f8fafd;
    color: #ed441e;
  }

  &.success {
    background: #6edf9b;
    color: #ffffff;
  }

  &.border-red {
    background: transparent;
    color: #ed441e;
    border: 1px solid;
  }

  &.minimal {
    background: transparent;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #00a1a5;
    border: none;
    padding: 6px 0px;
  }

  &.transparent {
    padding: 0;
    line-height: auto;
    min-height: auto;
    background: transparent;
    border: none;
  }

  &.mini {
    padding: 8px;
    line-height: auto;
    min-height: auto;
    background: #f8fafd;

    .button-right-icon,
    .button-left-icon {
      width: 22px;
      height: 22px;
      min-width: 22px;
      min-height: 22px;
    }
  }

  &.favorite {
    padding: 4px;
    line-height: auto;
    min-height: auto;
    background: #f8fafd;
    color: #00a1a5;
    font-family: 'plain';
    font-size: 12px;
    line-height: 18px;

    display: flex;
    flex-direction: wrap;
    align-items: center;

    .button-right-icon,
    .button-left-icon {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
    }

    .button-left-icon {
      margin-right: 2px;
    }
    .button-right-icon {
      margin-left: 2px;
    }
  }

  /* SPECIAL BUTTONS */

  &.button-login {
    & .button-text {
      text-align: left;
    }

    @media only screen and (max-width: 950px) {
      width: 100%;
      text-align: left;
      color: #00a1a5;
      font-weight: bold;
      background: transparent;
      padding: 40px 0;
      margin: 0;
      border-top: 0.5px solid #bcc5d7;
      border-bottom: 0.5px solid #bcc5d7;
    }
  }

  &.total {
    width: 100%;
  }

  &.download {
    width: 100%;
    background: #f8fafd;
    color: #405364;
    border: none;

    .button-left-icon,
    .button-right-icon {
      background: #ffffff;
      border-radius: 5px;
      img {
        padding: 4px;
      }
    }
  }

  &.map {
    height: 60px;
    font-family: 'plain';
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    padding: 18px 20px;
    background: transparent;
    border: 1px solid #001930;
    color: #001930;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      background: #001930;
      color: #ffffff;
      img {
        filter: invert(100%);
      }
    }

    @media (max-width: 768px) {
      .button-text {
        display: none;
      }

      .button-left-icon,
      .button-right-icon {
        margin: 0;
      }
    }
  }

  &.filter {
    background: #ffffff;
    max-height: 40px;
    border: none;
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    padding: 8px 16px 10px 16px;
    box-shadow: 0px 4px 10px rgba(202, 209, 216, 0.4);
    .button-right-icon {
      transition: 0.3s ease-in-out all;
      transform: rotate(0);
    }
    &.open {
      .button-right-icon {
        transform: rotate(180deg);
      }
    }

    @media (max-width: 768px) {
      padding: 6px 16px 8px 16px;

      .button-right-icon,
      .button-left-icon {
        display: none;
      }
    }
  }

  &.order {
    height: 40px;
    font-size: 14px;
    line-height: 22px;
    padding: 11px 16px;
    background: #fff;
    color: #000019;
    box-shadow: 0px 4px 10px rgba(202, 209, 216, 0.4);

    &.active {
      color: #00a1a5;
    }
  }

  &.state {
    background: #e2e4e8;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #6c778b;
  }

  &.simple {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 25, 0.08);
    border-radius: 5px;

    &.disabled {
      background: #f5f5f7;
      border: 1px solid #bcc5d7;
      color: #bcc5d7;
    }
  }

  &.simple-icon {
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
    padding: 9px;
    background: #ffffff;
    border-radius: 5px;
  }

  &.select {
    background: #ffffff;
    border: 1px solid #001930;
    border-radius: 5px;

    .button-text {
      font-weight: 300;
    }
  }

  &.margin-top {
    margin-top: 40px;
  }

  &.info-min {
    margin-top: 8px;
    min-height: auto;
    padding: 2px 6px;
    font-size: 10px;
    line-height: 16px;
    background: #f5f5f7;
    border-radius: 3px;
    text-transform: uppercase;
    color: #6c778b;
  }

  &.dark {
    background: #005267;
    padding: 8px 13px;
  }

  &.legal {
    background: #00a1a5;
    color: #fff;
    padding: 10px 32px 8px 32px;

    @media (max-width: 768px) {
      padding: 10px 16px;
      .button-text {
        display: none;
      }
      .button-left-icon,
      .button-right-icon {
        margin: 0;
      }
    }
  }

  &.modal-confirmated-option {
    width: 100%;
    border-radius: 5px;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
    justify-content: space-between;
    padding: 16px 24px;
    margin-bottom: 12px;
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }

    .button-overtext {
      color: #005267;
      font-family: 'plain';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 4px;
    }
    .button-text {
      font-family: 'plain';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
      padding: 0;
      text-align: left;
    }
  }
`;
