import React, { Component } from 'react';
import RolesLayout from './roles-layout';
import { Role, CentreStaff, Staff } from './types';
import services from '../../../services/services';
import { MAX_ROLES_CENTRES_PER_PAGE, CENTRES_ROLES_PER_PAGE, ROLE_TYPES } from '../../../constants/data';
import { connect } from 'react-redux';
import IStoreState from '../../../redux/store/IStoreState';

interface RolesFunctionalProps {
  agent?: any;
}

interface RolesFunctionalState {
  items: CentreStaff[];
  totalItems: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  page: number;
}

class RolesFunctional extends Component<RolesFunctionalProps, RolesFunctionalState> {
  state: RolesFunctionalState = {
    items: [],
    totalItems: 0,
    totalPages: 0,
    searchFilter: '',
    skipPage: 0,
    orderAscendent: '',
    page: 1,
  };

  componentDidMount() {
    this.getCentres();
  }

  getCentres() {
    const { agent } = this.props;
    const { searchFilter, skipPage, orderAscendent } = this.state;

    const endpoint = 'centres';

    const params = {
      filter: {
        where: {
          user: agent.id,
        },
        skip: skipPage,
        limit: CENTRES_ROLES_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: ['staff_centres', 'staff_centres.staff', 'staff.user'],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        if (res.count > 0) {
          const items: any[] = await Promise.all(
            res.data.map(async (item: any) => {
              const { name, location, staff_centres, validated } = item;

              const staff: Staff = item.staff_centres
                .map((person: any) => {
                  if (!person || !person.staff || !person.staff.user) {
                    return;
                  }
                  const {
                    role,
                    staff: {
                      id,
                      createdAt,
                      rating,
                      user: { name, surname, email },
                    },
                  } = person;
                  return {
                    id,
                    name,
                    surname,
                    createdAt,
                    email,
                    rating: rating || 0,
                    centreName: item.name,
                    centreID: item.id,
                    role: ROLE_TYPES[role],
                    rawItem: person
                  };
                })
                .filter((e: any) => !!e);

              return {
                name,
                location,
                staff,
                staff_centres,
                validated,
                centreID: item.id,

              };
            })
          ).then(results => results);

          const totalItems: number = res.count;
          const totalPages: number = Math.ceil(totalItems / CENTRES_ROLES_PER_PAGE);
          this.setState({ items, totalItems, totalPages });
        } else {
          this.setState({ items: [], totalItems: 0, totalPages: 0 });
        }
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  setPage(page: number) {
    const { totalItems } = this.state;
    const skip: number = CENTRES_ROLES_PER_PAGE * (page - 1);
    if (skip < totalItems) {
      this.setState({ page, skipPage: skip }, () => {
        this.getCentres();
      });
    }
  }

  // async deleteStaff(centre: string, staff: string) {
  //   const endpoint = `centres/${centre}/staff/${staff}`;

  //   await services.deleteReq({
  //     endpoint,
  //     then: (response: any) => { },
  //     catch: (err: any) => {
  //       console.log(err);
  //     },
  //   });
  // }



  render() {
    const { items, totalPages, page } = this.state;
    return (
      <RolesLayout
        items={items}
        totalPages={totalPages}
        setPage={page => this.setPage(page)}
        page={page}
        refreshData={() => this.getCentres()}
      />
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(RolesFunctional);
