import * as React from 'react';
import { ConfigurationTemplateDetailStyle, ConfigItemLanguage } from './configuration-template-detail-style';
import HeaderMain from '../../../../components/header-main/header-main';
import { MainLayoutSection, FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import { images } from '../../../../assets/images';
import TextDestacadoText from '../../../../components/text/text-destacado-text/text-destacado-text';
import DicotomicButton from '../../../../components/dicotomic-button';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import InputBox from '../../../../components/input-box';
import MainButton from '../../../../components/main-button';
import InputArea from '../../../../components/input-area/input-area';
import { colors } from '../../../../assets/colors/colors';
import { isValidEmail } from '../../../../utils/validation';
import { ITemplateEmail, ILanguage } from '../../../../models/template-emails';
import { RouteComponentProps, match } from 'react-router';
import { getConfiguration, postConfiguration } from '../../../../services/configuration';
import { copyOf } from '../../../../utils/other';
import { IContactForm } from '../../../../models/configuration-forms';
import services from '../../../../services/services';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

const languagesLabels = {
  es: 'Castellano',
  en: 'Inglés',
  it: 'Italiano',
  de: 'Alemán',
  fr: 'Francés',
  pt: 'Portugués',
};

export interface IConfigurationTemplateDetailProps extends WithTranslation {
  match: match<{
    templateName: string;
  }>;
}

export interface IConfigurationTemplateDetailState {
  selectedLanguage: string;
  languages: ITemplateEmail;
  originalConfiguration?: any;
  templateName: string;
}

class ConfigurationTemplateDetail extends React.Component<
  IConfigurationTemplateDetailProps,
  IConfigurationTemplateDetailState
  > {
  constructor(props: IConfigurationTemplateDetailProps) {
    super(props);
    const language: ILanguage = {
      active: false,
      name: '',
      from: '',
      subject: '',
      headline: '',
      subtitle: '',
      welcome: '',
      p1: '',
      p2: '',
      p3: '',
      button: '',
      p1PostButton: '',
      p2PostButton: '',
      p3PostButton: '',
      p4PostButton: '',
      thanks: '',
      firm: '',
      p1Footer: '',
      p2Footer: '',
      errors: {},
    };
    this.state = {
      templateName: '',
      selectedLanguage: 'es',
      languages: {
        es: copyOf(language),
        en: copyOf(language),
        it: copyOf(language),
        de: copyOf(language),
        fr: copyOf(language),
        pt: copyOf(language),
      },
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { templateName },
      },
    } = this.props;
    this.setState({ templateName }, () => {
      this.getLanguages();
    });
  }

  async getLanguages() {
    const { templateName } = this.state;
    const originalConfiguration: IContactForm = copyOf((await getConfiguration()).data);
    const languages: ITemplateEmail = copyOf(originalConfiguration.emails[templateName]);
    Object.values(languages).forEach((language: ILanguage) => {
      language.errors = {};
    });
    if (languages) {
      this.setState({ languages, originalConfiguration });
    }
  }

  async saveLanguage() {
    const {
      match: {
        params: { templateName },
      },
      t,
    } = this.props;
    const { selectedLanguage, originalConfiguration } = this.state;
    const language = copyOf(Object.assign(this.state.languages)[selectedLanguage]);
    delete language.errors;
    if (!this.validate()) {
      const configuration = copyOf(originalConfiguration);
      configuration.emails[templateName][selectedLanguage] = language;
      const { data } = await postConfiguration({ emails: configuration.emails });
      this.setState({ originalConfiguration: data });
      services.pushNotification({
        type: 'green',
        title: t('push:save-template.title'),
        text: `${t('push:save-template.text')} ${Object.assign(languagesLabels)[selectedLanguage].toLowerCase()}`,
      });
    }
  }

  clearFields() {
    const {
      match: {
        params: { templateName },
      },
    } = this.props;
    const { originalConfiguration, selectedLanguage, languages } = Object.assign(this.state);
    const language: ILanguage = copyOf(originalConfiguration.emails[templateName][selectedLanguage]);
    language.name = '';
    language.errors = {};
    languages[selectedLanguage] = language;
    this.setState({ languages });
  }

  validate() {
    const { selectedLanguage } = this.state;
    const language = Object.assign(this.state.languages)[selectedLanguage];
    let error = false;
    Object.entries(language).forEach(field => {
      if (typeof field[1] === 'string') {
        error = error || this.validateField(field[0], field[1]);
      }
    });
    return error;
  }

  validateField(field: string, value: string) {
    const { selectedLanguage, languages } = Object.assign(this.state);
    const errors = languages[selectedLanguage].errors;
    errors[field] = '';
    if (!value || value === '') errors[field] = 'required';
    else {
      switch (field) {
        case 'from':
          errors[field] = isValidEmail(value.toString()) ? '' : 'invalid-email';
          break;
      }
    }
    this.setState({ languages });
    return errors[field] !== '';
  }

  handleChange(field: string, value: string) {
    const { selectedLanguage, languages } = Object.assign(this.state);
    const language = languages[selectedLanguage];
    if (field === 'active') {
      language.active = value === 'on';
    } else {
      language[field] = value;
      this.validateField(field, value);
    }
    languages[selectedLanguage] = language;
    this.setState({ languages });
  }

  renderLanguageForm() {
    const { selectedLanguage } = this.state;
    const { t } = this.props;
    const language = Object.assign(this.state.languages)[selectedLanguage];

    const dicotomicObj = [
      {
        code: 'on',
        label: t('buttons:active'),
      },
      {
        code: 'off',
        label: t('buttons:disable'),
      },
    ];
    return (
      <div className="config-template-detail-form" key={selectedLanguage}>
        <div className="config-template-detail-form-title">
          <div className="config-template-detail-form-title__text">
            <SubheadDestacadoText>{Object.assign(languagesLabels)[selectedLanguage]}</SubheadDestacadoText>
          </div>
          <div className="config-template-detail-form-title__button">
            <DicotomicButton
              initialValue={language.active ? 'on' : 'off'}
              onChange={(val: string) => this.handleChange('active', val)}
              options={dicotomicObj}
            />
          </div>
        </div>
        <div className="config-template-detail-form-section">
          <div className="config-template-detail-form-section__title">
            <BodyDestacadoText>{t('layouts:config.templates.general-data')}</BodyDestacadoText>
          </div>
          <div className="config-template-detail-form-section__form">
            <FormGrid>
              <FormGridRow>
                {/*<FormGridItem type="col-2">
                   <InputBox
                    key={selectedLanguage}
                    placeholder={t('inputs:name.label')}
                    className="rounded-title"
                    errorCode={language.errors.name}
                    value={language.name}
                    required={true}
                    type="text"
                    labelText="NOMBRE DEL EMISOR"
                    onChange={(val: string) => this.handleChange('name', val)}
                  />
                </FormGridItem> 
                */}
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder={t('inputs:email-transmitter.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.from}
                    value={language.from}
                    required={true}
                    type="text"
                    labelText={t('inputs:email-transmitter.label')}
                    onChange={(val: string) => this.handleChange('from', val)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder={t('inputs:subject.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.subject}
                    required={true}
                    type="text"
                    value={language.subject}
                    labelText={t('inputs:subject.label')}
                    onChange={(val: string) => this.handleChange('subject', val)}
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>
          </div>
        </div>
        <div className="config-template-detail-form-section">
          <div className="config-template-detail-form-section__title">
            <BodyDestacadoText>{t('layouts:config.templates.specific-data')}</BodyDestacadoText>
          </div>
          <div className="config-template-detail-form-section__form">
            <FormGrid>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder={t('inputs:welcome.placeholder-mail')}
                    className="rounded-title"
                    errorCode={language.errors.welcome}
                    required={true}
                    type="text"
                    value={language.welcome}
                    labelText={t('inputs:welcome.label')}
                    onChange={(val: string) => this.handleChange('welcome', val)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder={t('inputs:title.placeholder-mail')}
                    className="rounded-title"
                    errorCode={language.errors.title}
                    required={true}
                    type="text"
                    value={language.headline}
                    labelText={t('inputs:title.label')}
                    onChange={(val: string) => this.handleChange('headline', val)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder={t('inputs:subtitle.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.subtitle}
                    type="text"
                    required={true}
                    value={language.subtitle}
                    labelText={t('inputs:subtitle.label')}
                    onChange={(val: string) => this.handleChange('subtitle', val)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputArea
                    labelText={t('inputs:p1.label')}
                    placeholder={t('inputs:p1.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.p1}
                    error={!!language.errors.p1}
                    value={language.p1}
                    name={''}
                    required={true}
                    onChange={(val: React.FormEvent<HTMLInputElement>) =>
                      this.handleChange('p1', val.currentTarget.value)
                    }
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputArea
                    labelText={t('inputs:p2.label')}
                    placeholder={t('inputs:p2.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.p2}
                    error={!!language.errors.p2}
                    value={language.p2}
                    name={''}
                    required={true}
                    onChange={(val: React.FormEvent<HTMLInputElement>) =>
                      this.handleChange('p2', val.currentTarget.value)
                    }
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputArea
                    labelText={t('inputs:p3.label')}
                    placeholder={t('inputs:p3.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.p3}
                    error={!!language.errors.p3}
                    value={language.p3}
                    name={''}
                    required={true}
                    onChange={(val: React.FormEvent<HTMLInputElement>) =>
                      this.handleChange('p3', val.currentTarget.value)
                    }
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    labelText={t('inputs:button.label')}
                    placeholder={t('inputs:button.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.button}
                    value={language.button}
                    required={true}
                    onChange={(val: string) => this.handleChange('button', val)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputArea
                    labelText={t('inputs:p1PostButton.label')}
                    placeholder={t('inputs:p1PostButton.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.p1PostButton}
                    error={!!language.errors.p1PostButton}
                    value={language.p1PostButton}
                    name={''}
                    required={true}
                    onChange={(val: React.FormEvent<HTMLInputElement>) =>
                      this.handleChange('p1PostButton', val.currentTarget.value)
                    }
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputArea
                    labelText={t('inputs:p2PostButton.label')}
                    placeholder={t('inputs:p2PostButton.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.p2PostButton}
                    error={!!language.errors.p2PostButton}
                    value={language.p2PostButton}
                    name={''}
                    required={true}
                    onChange={(val: React.FormEvent<HTMLInputElement>) =>
                      this.handleChange('p2PostButton', val.currentTarget.value)
                    }
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputArea
                    labelText={t('inputs:p3PostButton.label')}
                    placeholder={t('inputs:p3PostButton.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.p3PostButton}
                    error={!!language.errors.p3PostButton}
                    value={language.p3PostButton}
                    name={''}
                    required={true}
                    onChange={(val: React.FormEvent<HTMLInputElement>) =>
                      this.handleChange('p3PostButton', val.currentTarget.value)
                    }
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputArea
                    labelText={t('inputs:p4PostButton.label')}
                    placeholder={t('inputs:p4PostButton.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.p4PostButton}
                    error={!!language.errors.p4PostButton}
                    value={language.p4PostButton}
                    name={''}
                    required={true}
                    onChange={(val: React.FormEvent<HTMLInputElement>) =>
                      this.handleChange('p4PostButton', val.currentTarget.value)
                    }
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    labelText={t('inputs:thanks.label')}
                    placeholder={t('inputs:thanks.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.thanks}
                    value={language.thanks}
                    required={true}
                    onChange={(val: string) => this.handleChange('thanks', val)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    labelText={t('inputs:firm.label')}
                    placeholder={t('inputs:firm.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.firm}
                    value={language.firm}
                    required={true}
                    onChange={(val: string) => this.handleChange('firm', val)}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputArea
                    labelText={t('inputs:p1Footer.label')}
                    placeholder={t('inputs:p1Footer.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.p1Footer}
                    error={!!language.errors.p1Footer}
                    value={language.p1Footer}
                    name={''}
                    required={true}
                    onChange={(val: React.FormEvent<HTMLInputElement>) =>
                      this.handleChange('p1Footer', val.currentTarget.value)
                    }
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputArea
                    labelText={t('inputs:p2Footer.label')}
                    placeholder={t('inputs:p2Footer.placeholder')}
                    className="rounded-title"
                    errorCode={language.errors.p2Footer}
                    error={!!language.errors.p2Footer}
                    value={language.p2Footer}
                    name={''}
                    required={true}
                    onChange={(val: React.FormEvent<HTMLInputElement>) =>
                      this.handleChange('p2Footer', val.currentTarget.value)
                    }
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>
          </div>
        </div>
        <div className="config-contact-buttons">
          <div className="config-contact-buttons__item">
            <MainButton type="primary" text={t('buttons:save')} onClick={() => this.saveLanguage()} />
          </div>
          <div className="config-contact-buttons__item">
            <MainButton type="secondary" text={t('buttons:cancel')} onClick={() => this.clearFields()} />
          </div>
        </div>
      </div>
    );
  }

  selectLanague(lang: string) {
    this.setState({ selectedLanguage: lang });
  }

  render() {
    const { selectedLanguage, languages } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderMain title={t('layouts:config.templates.header')} goBack={true} />
        <MainLayoutSection>
          <ConfigurationTemplateDetailStyle>
            <div className="config-template-detail-languages">
              <div className="config-template-detail-languages__title">
                <BodyDestacadoText>{t('layouts:config.templates.languages')}</BodyDestacadoText>
              </div>
              <ConfigItemLanguage>
                <div className="config-item-language-item-icon">
                  {languages.es.active && <img src={images.checkSuccessSvg} alt="" />}
                </div>
                <div className="config-item-language-item-text" onClick={() => this.selectLanague('es')}>
                  <TextDestacadoText color={selectedLanguage === 'es' ? colors['SE-002/100'] : ''} cursor={'pointer'}>
                    {t('layouts:config.templates.es')}
                  </TextDestacadoText>
                </div>
              </ConfigItemLanguage>
              <ConfigItemLanguage>
                <div className="config-item-language-item-icon">
                  {languages.en.active && <img src={images.checkSuccessSvg} alt="" />}
                </div>
                <div className="config-item-language-item-text" onClick={() => this.selectLanague('en')}>
                  <TextDestacadoText color={selectedLanguage === 'en' ? colors['SE-002/100'] : ''} cursor={'pointer'}>
                    {t('layouts:config.templates.en')}
                  </TextDestacadoText>
                </div>
              </ConfigItemLanguage>
              <ConfigItemLanguage>
                <div className="config-item-language-item-icon">
                  {languages.it.active && <img src={images.checkSuccessSvg} alt="" />}
                </div>
                <div className="config-item-language-item-text" onClick={() => this.selectLanague('it')}>
                  <TextDestacadoText color={selectedLanguage === 'it' ? colors['SE-002/100'] : ''} cursor={'pointer'}>
                    {t('layouts:config.templates.it')}
                  </TextDestacadoText>
                </div>
              </ConfigItemLanguage>
              <ConfigItemLanguage>
                <div className="config-item-language-item-icon">
                  {languages.de.active && <img src={images.checkSuccessSvg} alt="" />}
                </div>

                <div className="config-item-language-item-text" onClick={() => this.selectLanague('de')}>
                  <TextDestacadoText color={selectedLanguage === 'de' ? colors['SE-002/100'] : ''} cursor={'pointer'}>
                    {t('layouts:config.templates.ge')}
                  </TextDestacadoText>
                </div>
              </ConfigItemLanguage>
              <ConfigItemLanguage>
                <div className="config-item-language-item-icon">
                  {languages.fr.active && <img src={images.checkSuccessSvg} alt="" />}
                </div>
                <div className="config-item-language-item-text" onClick={() => this.selectLanague('fr')}>
                  <TextDestacadoText color={selectedLanguage === 'fr' ? colors['SE-002/100'] : ''} cursor={'pointer'}>
                    {t('layouts:config.templates.fr')}
                  </TextDestacadoText>
                </div>
              </ConfigItemLanguage>
              <ConfigItemLanguage>
                <div className="config-item-language-item-icon">
                  {languages.pt.active && <img src={images.checkSuccessSvg} alt="" />}
                </div>

                <div className="config-item-language-item-text" onClick={() => this.selectLanague('pt')}>
                  <TextDestacadoText color={selectedLanguage === 'pt' ? colors['SE-002/100'] : ''} cursor={'pointer'}>
                    {t('layouts:config.templates.pt')}
                  </TextDestacadoText>
                </div>
              </ConfigItemLanguage>
            </div>
            {this.renderLanguageForm()}
          </ConfigurationTemplateDetailStyle>
        </MainLayoutSection>
      </>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(ConfigurationTemplateDetail);
