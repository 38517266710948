import styled from 'styled-components';

export const ModalInviteCenterStyle = styled.div`
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 32px;
  padding-top: 48px;

  .modal-invite-center-subhead {
    font-family: Plain;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #bcc5d7;
    margin: 24px 0;
  }

  .modal-invite-center-flex-button {
    display: flex;

    &__max {
      flex-grow: 1;
      margin-right: 20px;
    }

    &__min {
    }
  }
`;
