export const COUNTRIES_KEY_LABEL: any = {
AD: 'Andorra',
AE: 'Emiratos Árabes Unidos',
AF: 'Afganistán',
AG: 'Antigua y Barbuda',
AI: 'Anguila',
AL: 'Albania',
AM: 'Armenia',
AN: 'Antillas Holandesas',
AO: 'Angola',
AQ: 'Antártida',
AR: 'Argentina',
AU: 'Austria',
AS: 'Australia',
AW: 'Aruba',
AZ: 'Azerbaiyán',
BA: 'Bosnia y Herzegovina',
BB: 'Barbados',
BD: 'Bangladesh',
BE: 'Bélgica',
BF: 'Burkina Faso',
BH: 'Bahrein',
BI: 'Burundi',
BJ: 'Benin',
BM: 'Bermudas',
BO: 'Bolivia',
BR: 'Brasil',
BS: 'Bahamas',
BT: 'Bután',
BU: 'Isla Bouvet',
BV: 'Bulgaria',
BW: 'Botswana',
BX: 'Brunei Darussalam',
BY: 'Bielorrusia',
BZ: 'Belice',
CA: 'Canadá',
CC: 'Cocos (Keeling)',
CF: 'República Centroafricana',
CG: 'Congo',
CH: 'Suiza',
CI: "Cote D'Ivoire (Costa de Marfil)",
CK: 'Islas Cook',
CL: 'Chile',
CM: 'Camerún',
CN: 'China',
CO: 'Colombia',
CR: 'Costa Rica',
CU: 'Cuba',
CV: 'Cabo Verde',
CX: 'Isla de Navidad',
CY: 'Chipre',
CZ: 'República Checa',
DE: 'Alemania',
DJ: 'Djibouti',
DK: 'Dinamarca',
DM: 'Dominica',
DO: 'República Dominicana',
DZ: 'Argelia',
EC: 'Ecuador',
EE: 'Estonia',
EG: 'Egipto',
EH: 'Sáhara Occidental',
ER: 'Eritrea',
ES: 'España',
ET: 'Etiopía',
FI: 'Finlandia',
FJ: 'Fiji',
FK: 'Islas Malvinas (Falkland)',
FM: 'Micronesia',
FO: 'Islas Feroe',
FR: 'Francia',
GA: 'Gabón',
GB: 'Gran Bretaña (Reino Unido)',
GD: 'Granada',
GE: 'Georgia',
GF: 'Guayana Francesa',
GH: 'Ghana',
GI: 'Gibraltar',
GL: 'Groenlandia',
GM: 'Gambia',
GN: 'Guinea',
GP: 'Guadalupe',
GQ: 'Guinea Ecuatorial',
GR: 'Grecia',
GS: 'Georgia del Sur y Islas Sandwich del Sur',
GT: 'Guatemala',
GU: 'Guam',
GW: 'Guinea-Bissau',
GY: 'Guayana',
HK: 'Hong Kong',
HM: 'Islas Heard y McDonald',
HN: 'Honduras',
HR: 'Croacia',
HT: 'Haití',
HU: 'Hungría',
ID: 'Indonesia',
IE: 'Irlanda',
IL: 'Israel',
IN: 'India',
IO: 'Territorio Británico del Océano Índico',
IQ: 'Irak',
IR: 'Irán',
IS: 'Islandia',
IT: 'Italia',
JM: 'Jamaica',
JO: 'Jordania',
JP: 'Japón',
KE: 'Kenia',
KG: 'Kirguistán',
KH: 'Camboya',
KI: 'Kiribati',
KM: 'Comoras',
KN: 'Saint Kitts y Nevis',
KP: 'Corea del Norte',
KR: 'Corea del Sur',
KW: 'Kuwait',
KY: 'Las Islas Caimán',
KZ: 'Kazajstán',
LA: 'Laos',
LB: 'Líbano',
LC: 'Santa Lucía',
LI: 'Liechtenstein',
LK: 'Sri Lanka',
LR: 'Liberia',
LS: 'Lesoto',
LT: 'Lituania',
LU: 'Luxemburgo',
LV: 'Letonia',
LY: 'Libia',
MA: 'Marruecos',
MC: 'Mónaco',
MD: 'Moldavia',
MG: 'Madagascar',
MH: 'Islas Marshall',
MK: 'Macedonia',
ML: 'Malí',
MM: 'Myanmar',
MN: 'Mongolia',
MO: 'Macao',
MP: 'Islas Marianas del Norte',
MQ: 'Martinica',
MR: 'Mauritania',
MS: 'Montserrat',
MT: 'Malta',
MU: 'Mauricio',
MV: 'Maldivas',
MW: 'Malawi',
MX: 'México',
MY: 'Malasia',
MZ: 'Mozambique',
NA: 'Namibia',
NC: 'Nueva Caledonia',
NF: 'Norfolk Island',
NG: 'Nigeria',
NI: 'Nicaragua',
NE: 'Países Bajos',
NO: 'Noruega',
NP: 'Nepal',
NR: 'Nauru',
NU: 'Niue',
NZ: 'Nueva Zelanda',
OM: 'Omán',
PA: 'Panamá',
PE: 'Perú',
PF: 'Polinesia francés',
PG: 'Papua Nueva Guinea',
PH: 'Filipinas',
PK: 'Pakistán',
PO: 'Polonia',
PM: 'San Pedro y Miquelón',
PN: 'Pitcairn',
PR: 'Puerto Rico',
PT: 'Portugal',
PW: 'Palau',
PY: 'Paraguay',
QA: 'Qatar',
RE: 'Reunión',
RO: 'Rumania',
RU: 'La Federación de Rusia',
RW: 'Ruanda',
SA: 'Arabia Saudita',
SB: 'Las Islas Salomón',
SC: 'Seychelles',
SD: 'Sudán',
SE: 'Suecia',
SG: 'Singapur',
SH: 'Santa Elena',
SI: 'Eslovenia',
SJ: 'Svalbard y Jan Mayen',
SK: 'República Eslovaca',
SL: 'Sierra Leona',
SM: 'San Marino',
SN: 'Senegal',
SO: 'Somalia',
SR: 'Suriname',
ST: 'Santo Tomé y Príncipe',
SV: 'El Salvador',
SY: 'Siria',
SZ: 'Swazilandia',
TC: 'Islas Turcas y Caicos',
TD: 'Chad',
TF: 'Territorios del sur francés',
TG: 'Togo',
TH: 'Tailandia',
TI: 'Tayikistán',
TK: 'Tokelau',
TM: 'Turkmenistán',
TN: 'Túnez',
TO: 'Tonga',
TP: 'Timor Oriental',
TR: 'Turquía',
TT: 'Trinidad y Tobago',
TV: 'Tuvalu',
TW: 'Taiwan',
TZ: 'Tanzania',
UA: 'Ucrania',
UG: 'Uganda',
UK: 'Reino Unido',
UM: 'Islas menores  de EE.UU.',
US: 'Estados Unidos de América (EE.UU.)',
UY: 'Uruguay',
UZ: 'Uzbekistán',
VA: 'Ciudad del Vaticano',
VC: 'San Vicente y las Granadinas',
VE: 'Venezuela',
VG: 'Islas Vírgenes (Británicas)',
VN: 'Vietnam',
VU: 'Vanuatu',
WF: 'Islas Wallis y Futuna',
WS: 'Samoa',
YE: 'Yemen',
YT: 'Mayotte',
YU: 'Yugoslavia',
ZA: 'Sudáfrica',
ZM: 'Zambia',
ZR: 'Zaire',
ZW: 'Zimbabue',
}

export const COUNTRIES = [
 {value: 'AD', label: 'Andorra'}, 
 {value: 'AE', label: 'Emiratos Árabes Unidos'}, 
 {value: 'AF', label: 'Afganistán'}, 
 {value: 'AG', label: 'Antigua y Barbuda'}, 
 {value: 'AI', label: 'Anguila'}, 
 {value: 'AL', label: 'Albania'}, 
 {value: 'AM', label: 'Armenia'}, 
 {value: 'AN', label: 'Antillas Holandesas'}, 
 {value: 'AO', label: 'Angola'}, 
 {value: 'AQ', label: 'Antártida'}, 
 {value: 'AR', label: 'Argentina'}, 
 {value: 'AU', label: 'Austria'}, 
 {value: 'AS', label: 'Australia'}, 
 {value: 'AW', label: 'Aruba'}, 
 {value: 'AZ', label: 'Azerbaiyán'}, 
 {value: 'BA', label: 'Bosnia y Herzegovina'}, 
 {value: 'BB', label: 'Barbados'}, 
 {value: 'BD', label: 'Bangladesh'}, 
 {value: 'BE', label: 'Bélgica'}, 
 {value: 'BF', label: 'Burkina Faso'}, 
 {value: 'BH', label: 'Bahrein'}, 
 {value: 'BI', label: 'Burundi'}, 
 {value: 'BJ', label: 'Benin'}, 
 {value: 'BM', label: 'Bermudas'}, 
 {value: 'BO', label: 'Bolivia'}, 
 {value: 'BR', label: 'Brasil'}, 
 {value: 'BS', label: 'Bahamas'}, 
 {value: 'BT', label: 'Bután'}, 
 {value: 'BU', label: 'Isla Bouvet'}, 
 {value: 'BV', label: 'Bulgaria'}, 
 {value: 'BW', label: 'Botswana'}, 
 {value: 'BX', label: 'Brunei Darussalam'}, 
 {value: 'BY', label: 'Bielorrusia'}, 
 {value: 'BZ', label: 'Belice'}, 
 {value: 'CA', label: 'Canadá'}, 
 {value: 'CC', label: 'Cocos (Keeling)'}, 
 {value: 'CF', label: 'República Centroafricana'}, 
 {value: 'CG', label: 'Congo'}, 
 {value: 'CH', label: 'Suiza'}, 
 {value: 'CI', label: "Cote D'Ivoire (Costa de Marfil)"},
  {value: 'CK', label: 'Islas Cook'}, 
 {value: 'CL', label: 'Chile'}, 
 {value: 'CM', label: 'Camerún'}, 
 {value: 'CN', label: 'China'}, 
 {value: 'CO', label: 'Colombia'}, 
 {value: 'CR', label: 'Costa Rica'}, 
 {value: 'CU', label: 'Cuba'}, 
 {value: 'CV', label: 'Cabo Verde'}, 
 {value: 'CX', label: 'Isla de Navidad'}, 
 {value: 'CY', label: 'Chipre'}, 
 {value: 'CZ', label: 'República Checa'}, 
 {value: 'DE', label: 'Alemania'}, 
 {value: 'DJ', label: 'Djibouti'}, 
 {value: 'DK', label: 'Dinamarca'}, 
 {value: 'DM', label: 'Dominica'}, 
 {value: 'DO', label: 'República Dominicana'}, 
 {value: 'DZ', label: 'Argelia'}, 
 {value: 'EC', label: 'Ecuador'}, 
 {value: 'EE', label: 'Estonia'}, 
 {value: 'EG', label: 'Egipto'}, 
 {value: 'EH', label: 'Sáhara Occidental'}, 
 {value: 'ER', label: 'Eritrea'}, 
 {value: 'ES', label: 'España'}, 
 {value: 'ET', label: 'Etiopía'}, 
 {value: 'FI', label: 'Finlandia'}, 
 {value: 'FJ', label: 'Fiji'}, 
 {value: 'FK', label: 'Islas Malvinas (Falkland)'}, 
 {value: 'FM', label: 'Micronesia'}, 
 {value: 'FO', label: 'Islas Feroe'}, 
 {value: 'FR', label: 'Francia'}, 
 {value: 'GA', label: 'Gabón'}, 
 {value: 'GB', label: 'Gran Bretaña (Reino Unido)'}, 
 {value: 'GD', label: 'Granada'}, 
 {value: 'GE', label: 'Georgia'}, 
 {value: 'GF', label: 'Guayana Francesa'},
{value: 'GH', label: 'Ghana'}, 
 {value: 'GI', label: 'Gibraltar'}, 
 {value: 'GL', label: 'Groenlandia'}, 
 {value: 'GM', label: 'Gambia'}, 
 {value: 'GN', label: 'Guinea'}, 
 {value: 'GP', label: 'Guadalupe'}, 
 {value: 'GQ', label: 'Guinea Ecuatorial'}, 
 {value: 'GR', label: 'Grecia'}, 
 {value: 'GS', label: 'Georgia del Sur y Islas Sandwich del Sur'}, 
 {value: 'GT', label: 'Guatemala'}, 
 {value: 'GU', label: 'Guam'}, 
 {value: 'GW', label: 'Guinea-Bissau'}, 
 {value: 'GY', label: 'Guayana'}, 
 {value: 'HK', label: 'Hong Kong'}, 
 {value: 'HM', label: 'Islas Heard y McDonald'}, 
 {value: 'HN', label: 'Honduras'}, 
 {value: 'HR', label: 'Croacia'}, 
 {value: 'HT', label: 'Haití'}, 
 {value: 'HU', label: 'Hungría'}, 
 {value: 'ID', label: 'Indonesia'}, 
 {value: 'IE', label: 'Irlanda'}, 
 {value: 'IL', label: 'Israel'}, 
 {value: 'IN', label: 'India'}, 
 {value: 'IO', label: 'Territorio Británico del Océano Índico'}, 
 {value: 'IQ', label: 'Irak'}, 
 {value: 'IR', label: 'Irán'}, 
 {value: 'IS', label: 'Islandia'}, 
 {value: 'IT', label: 'Italia'}, 
 {value: 'JM', label: 'Jamaica'}, 
 {value: 'JO', label: 'Jordania'}, 
 {value: 'JP', label: 'Japón'}, 
 {value: 'KE', label: 'Kenia'}, 
 {value: 'KG', label: 'Kirguistán'}, 
 {value: 'KH', label: 'Camboya'}, 
 {value: 'KI', label: 'Kiribati'}, 
 {value: 'KM', label: 'Comoras'}, 
 {value: 'KN', label: 'Saint Kitts y Nevis'}, 
 {value: 'KP', label: 'Corea del Norte'}, 
 {value: 'KR', label: 'Corea del Sur'}, 
 {value: 'KW', label: 'Kuwait'}, 
 {value: 'KY', label: 'Las Islas Caimán'}, 
 {value: 'KZ', label: 'Kazajstán'}, 
 {value: 'LA', label: 'Laos'}, 
 {value: 'LB', label: 'Líbano'}, 
 {value: 'LC', label: 'Santa Lucía'}, 
 {value: 'LI', label: 'Liechtenstein'}, 
 {value: 'LK', label: 'Sri Lanka'}, 
 {value: 'LR', label: 'Liberia'}, 
 {value: 'LS', label: 'Lesoto'}, 
 {value: 'LT', label: 'Lituania'}, 
 {value: 'LU', label: 'Luxemburgo'}, 
 {value: 'LV', label: 'Letonia'}, 
 {value: 'LY', label: 'Libia'}, 
 {value: 'MA', label: 'Marruecos'}, 
 {value: 'MC', label: 'Mónaco'}, 
 {value: 'MD', label: 'Moldavia'}, 
 {value: 'MG', label: 'Madagascar'}, 
 {value: 'MH', label: 'Islas Marshall'}, 
 {value: 'MK', label: 'Macedonia'}, 
 {value: 'ML', label: 'Malí'}, 
 {value: 'MM', label: 'Myanmar'}, 
 {value: 'MN', label: 'Mongolia'}, 
 {value: 'MO', label: 'Macao'}, 
 {value: 'MP', label: 'Islas Marianas del Norte'}, 
 {value: 'MQ', label: 'Martinica'}, 
 {value: 'MR', label: 'Mauritania'}, 
 {value: 'MS', label: 'Montserrat'}, 
 {value: 'MT', label: 'Malta'}, 
 {value: 'MU', label: 'Mauricio'}, 
 {value: 'MV', label: 'Maldivas'}, 
 {value: 'MW', label: 'Malawi'}, 
 {value: 'MX', label: 'México'}, 
 {value: 'MY', label: 'Malasia'}, 
 {value: 'MZ', label: 'Mozambique'}, 
 {value: 'NA', label: 'Namibia'}, 
 {value: 'NC', label: 'Nueva Caledonia'},
 {value: 'NF', label: 'Norfolk Island'}, 
 {value: 'NG', label: 'Nigeria'}, 
 {value: 'NI', label: 'Nicaragua'}, 
 {value: 'NE', label: 'Países Bajos'}, 
 {value: 'NO', label: 'Noruega'}, 
 {value: 'NP', label: 'Nepal'}, 
 {value: 'NR', label: 'Nauru'}, 
 {value: 'NU', label: 'Niue'}, 
 {value: 'NZ', label: 'Nueva Zelanda'}, 
 {value: 'OM', label: 'Omán'}, 
 {value: 'PA', label: 'Panamá'}, 
 {value: 'PE', label: 'Perú'}, 
 {value: 'PF', label: 'Polinesia francés'}, 
 {value: 'PG', label: 'Papua Nueva Guinea'}, 
 {value: 'PH', label: 'Filipinas'}, 
 {value: 'PK', label: 'Pakistán'}, 
 {value: 'PO', label: 'Polonia'}, 
 {value: 'PM', label: 'San Pedro y Miquelón'}, 
 {value: 'PN', label: 'Pitcairn'}, 
 {value: 'PR', label: 'Puerto Rico'}, 
 {value: 'PT', label: 'Portugal'}, 
 {value: 'PW', label: 'Palau'}, 
 {value: 'PY', label: 'Paraguay'}, 
 {value: 'QA', label: 'Qatar'}, 
 {value: 'RE', label: 'Reunión'}, 
 {value: 'RO', label: 'Rumania'}, 
 {value: 'RU', label: 'La Federación de Rusia'}, 
 {value: 'RW', label: 'Ruanda'}, 
 {value: 'SA', label: 'Arabia Saudita'}, 
 {value: 'SB', label: 'Las Islas Salomón'}, 
 {value: 'SC', label: 'Seychelles'}, 
 {value: 'SD', label: 'Sudán'}, 
 {value: 'SE', label: 'Suecia'}, 
 {value: 'SG', label: 'Singapur'}, 
 {value: 'SH', label: 'Santa Elena'}, 
 {value: 'SI', label: 'Eslovenia'}, 
 {value: 'SJ', label: 'Svalbard y Jan Mayen'}, 
 {value: 'SK', label: 'República Eslovaca'}, 
 {value: 'SL', label: 'Sierra Leona'}, 
 {value: 'SM', label: 'San Marino'}, 
 {value: 'SN', label: 'Senegal'}, 
 {value: 'SO', label: 'Somalia'}, 
 {value: 'SR', label: 'Suriname'}, 
 {value: 'ST', label: 'Santo Tomé y Príncipe'}, 
 {value: 'SV', label: 'El Salvador'}, 
 {value: 'SY', label: 'Siria'}, 
 {value: 'SZ', label: 'Swazilandia'}, 
 {value: 'TC', label: 'Islas Turcas y Caicos'}, 
 {value: 'TD', label: 'Chad'}, 
 {value: 'TF', label: 'Territorios del sur francés'}, 
 {value: 'TG', label: 'Togo'}, 
 {value: 'TH', label: 'Tailandia'}, 
 {value: 'TI', label: 'Tayikistán'}, 
 {value: 'TK', label: 'Tokelau'}, 
 {value: 'TM', label: 'Turkmenistán'}, 
 {value: 'TN', label: 'Túnez'}, 
 {value: 'TO', label: 'Tonga'}, 
 {value: 'TP', label: 'Timor Oriental'}, 
 {value: 'TR', label: 'Turquía'}, 
 {value: 'TT', label: 'Trinidad y Tobago'}, 
 {value: 'TV', label: 'Tuvalu'}, 
 {value: 'TW', label: 'Taiwan'}, 
 {value: 'TZ', label: 'Tanzania'}, 
 {value: 'UA', label: 'Ucrania'}, 
 {value: 'UG', label: 'Uganda'}, 
 {value: 'UK', label: 'Reino Unido'}, 
 {value: 'UM', label: 'Islas menores  de EE.UU.'}, 
 {value: 'US', label: 'Estados Unidos de América (EE.UU.)'}, 
 {value: 'UY', label: 'Uruguay'}, 
 {value: 'UZ', label: 'Uzbekistán'}, 
 {value: 'VA', label: 'Ciudad del Vaticano'}, 
 {value: 'VC', label: 'San Vicente y las Granadinas'}, 
 {value: 'VE', label: 'Venezuela'}, 
 {value: 'VG', label: 'Islas Vírgenes (Británicas)'}, 
 {value: 'VN', label: 'Vietnam'}, 
 {value: 'VU', label: 'Vanuatu'}, 
 {value: 'WF', label: 'Islas Wallis y Futuna'}, 
 {value: 'WS', label: 'Samoa'}, 
 {value: 'YE', label: 'Yemen'}, 
 {value: 'YT', label: 'Mayotte'}, 
 {value: 'YU', label: 'Yugoslavia'}, 
 {value: 'ZA', label: 'Sudáfrica'}, 
 {value: 'ZM', label: 'Zambia'}, 
 {value: 'ZR', label: 'Zaire'}, 
 {value: 'ZW', label: 'Zimbabue'},
]

export const COUNTRIES_KEY_LABEL_ENGLISH: any = {
  "": "",
  "AF": "Afghanistan",
  "AX": "Aland Islands",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctica",
  "AG": "Antigua And Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia And Herzegovina",
  "BW": "Botswana",
  "BV": "Bouvet Island",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory",
  "BN": "Brunei Darussalam",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Christmas Island",
  "CC": "Cocos (Keeling) Islands",
  "CO": "Colombia",
  "KM": "Comoros",
  "CG": "Congo",
  "CD": "Congo, Democratic Republic",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "CI": "Cote D\"Ivoire",
  "HR": "Croatia",
  "CU": "Cuba",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "ES": "España",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FK": "Falkland Islands (Malvinas)",
  "FO": "Faroe Islands",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GF": "French Guiana",
  "PF": "French Polynesia",
  "TF": "French Southern Territories",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HM": "Heard Island & Mcdonald Islands",
  "VA": "Holy See (Vatican City State)",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran, Islamic Republic Of",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IM": "Isle Of Man",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KR": "Korea",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Lao People\"s Democratic Republic",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libyan Arab Jamahiriya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macao",
  "MK": "Macedonia",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia, Federated States Of",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "AN": "Netherlands Antilles",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "MP": "Northern Mariana Islands",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestinian Territory, Occupied",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PN": "Pitcairn",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RE": "Reunion",
  "RO": "Romania",
  "RU": "Russian Federation",
  "RW": "Rwanda",
  "BL": "Saint Barthelemy",
  "SH": "Saint Helena",
  "KN": "Saint Kitts And Nevis",
  "LC": "Saint Lucia",
  "MF": "Saint Martin",
  "PM": "Saint Pierre And Miquelon",
  "VC": "Saint Vincent And Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome And Principe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "GS": "South Georgia And Sandwich Isl.",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SJ": "Svalbard And Jan Mayen",
  "SZ": "Swaziland",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syrian Arab Republic",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad And Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TC": "Turks And Caicos Islands",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UM": "United States Outlying Islands",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VE": "Venezuela",
  "VN": "Viet Nam",
  "VG": "Virgin Islands, British",
  "VI": "Virgin Islands, U.S.",
  "WF": "Wallis And Futuna",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
}

export const COUNTRIES_ENGLISH = [
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Aland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua And Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia And Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, Democratic Republic" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Cote D\"Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard Island & Mcdonald Islands" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran, Islamic Republic Of" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle Of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KR", label: "Korea" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People\"s Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libyan Arab Jamahiriya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia, Federated States Of" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "AN", label: "Netherlands Antilles" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian Territory, Occupied" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda" },
  { value: "BL", label: "Saint Barthelemy" },
  { value: "SH", label: "Saint Helena" },
  { value: "KN", label: "Saint Kitts And Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "PM", label: "Saint Pierre And Miquelon" },
  { value: "VC", label: "Saint Vincent And Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome And Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia And Sandwich Isl." },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard And Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad And Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks And Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UM", label: "United States Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Viet Nam" },
  { value: "VG", label: "Virgin Islands, British" },
  { value: "VI", label: "Virgin Islands, U.S." },
  { value: "WF", label: "Wallis And Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" }
]

export const ALL_COUNTRIES = [
  "afghanistan",
  "aland islands",
  "albania",
  "algeria",
  "american samoa",
  "andorra",
  "angola",
  "anguilla",
  "antarctica",
  "antigua and barbuda",
  "argentina",
  "armenia",
  "aruba",
  "australia",
  "austria",
  "azerbaijan",
  "bahamas",
  "bahrain",
  "bangladesh",
  "barbados",
  "belarus",
  "belgium",
  "belize",
  "benin",
  "bermuda",
  "bhutan",
  "bolivia",
  "bosnia and herzegovina",
  "botswana",
  "bouvet island",
  "brazil",
  "british indian ocean territory",
  "brunei darussalam",
  "bulgaria",
  "burkina faso",
  "burundi",
  "cambodia",
  "cameroon",
  "canada",
  "cape verde",
  "cayman islands",
  "central african republic",
  "chad",
  "chile",
  "china",
  "christmas island",
  "cocos (keeling) islands",
  "colombia",
  "comoros",
  "congo",
  "congo, democratic republic",
  "cook islands",
  "costa rica",
  "cote d\"ivoire",
  "croatia",
  "cuba",
  "cyprus",
  "czech republic",
  "denmark",
  "djibouti",
  "dominica",
  "dominican republic",
  "ecuador",
  "egypt",
  "el salvador",
  "equatorial guinea",
  "eritrea",
  "españa",
  "estonia",
  "ethiopia",
  "falkland islands (malvinas)",
  "faroe islands",
  "fiji",
  "finland",
  "france",
  "french guiana",
  "french polynesia",
  "french southern territories",
  "gabon",
  "gambia",
  "georgia",
  "germany",
  "ghana",
  "gibraltar",
  "greece",
  "greenland",
  "grenada",
  "guadeloupe",
  "guam",
  "guatemala",
  "guernsey",
  "guinea",
  "guinea-bissau",
  "guyana",
  "haiti",
  "heard island & mcdonald islands",
  "holy see (vatican city state)",
  "honduras",
  "hong kong",
  "hungary",
  "iceland",
  "india",
  "indonesia",
  "iran, islamic republic of",
  "iraq",
  "ireland",
  "isle of man",
  "israel",
  "italy",
  "jamaica",
  "japan",
  "jersey",
  "jordan",
  "kazakhstan",
  "kenya",
  "kiribati",
  "korea",
  "kuwait",
  "kyrgyzstan",
  "lao people\"s democratic republic",
  "latvia",
  "lebanon",
  "lesotho",
  "liberia",
  "libyan arab jamahiriya",
  "liechtenstein",
  "lithuania",
  "luxembourg",
  "macao",
  "macedonia",
  "madagascar",
  "malawi",
  "malaysia",
  "maldives",
  "mali",
  "malta",
  "marshall islands",
  "martinique",
  "mauritania",
  "mauritius",
  "mayotte",
  "mexico",
  "micronesia, federated states of",
  "moldova",
  "monaco",
  "mongolia",
  "montenegro",
  "montserrat",
  "morocco",
  "mozambique",
  "myanmar",
  "namibia",
  "nauru",
  "nepal",
  "netherlands",
  "netherlands antilles",
  "new caledonia",
  "new zealand",
  "nicaragua",
  "niger",
  "nigeria",
  "niue",
  "norfolk island",
  "northern mariana islands",
  "norway",
  "oman",
  "pakistan",
  "palau",
  "palestinian territory, occupied",
  "panama",
  "papua new guinea",
  "paraguay",
  "peru",
  "philippines",
  "pitcairn",
  "poland",
  "portugal",
  "puerto rico",
  "qatar",
  "reunion",
  "romania",
  "russian federation",
  "rwanda",
  "saint barthelemy",
  "saint helena",
  "saint kitts and nevis",
  "saint lucia",
  "saint martin",
  "saint pierre and miquelon",
  "saint vincent and grenadines",
  "samoa",
  "san marino",
  "sao tome and principe",
  "saudi arabia",
  "senegal",
  "serbia",
  "seychelles",
  "sierra leone",
  "singapore",
  "slovakia",
  "slovenia",
  "solomon islands",
  "somalia",
  "south africa",
  "south georgia and sandwich isl.",
  "spain",
  "sri lanka",
  "sudan",
  "suriname",
  "svalbard and jan mayen",
  "swaziland",
  "sweden",
  "switzerland",
  "syrian arab republic",
  "taiwan",
  "tajikistan",
  "tanzania",
  "thailand",
  "timor-leste",
  "togo",
  "tokelau",
  "tonga",
  "trinidad and tobago",
  "tunisia",
  "turkey",
  "turkmenistan",
  "turks and caicos islands",
  "tuvalu",
  "uganda",
  "ukraine",
  "united arab emirates",
  "united kingdom",
  "united states",
  "united states outlying islands",
  "uruguay",
  "uzbekistan",
  "vanuatu",
  "venezuela",
  "viet nam",
  "virgin islands, british",
  "virgin islands, u.s.",
  "wallis and futuna",
  "western sahara",
  "yemen",
  "zambia",
  "zimbabwe"
];
