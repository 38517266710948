import * as React from 'react';
import { CreateActivitiesExclusivenessStyle } from './create-activities-exclusiveness-style';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import InfoText from '../../../../../components/text/info-text/info-text';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../main-layout-style';
import InputBox from '../../../../../components/input-box';
import SelectBox from '../../../../../components/select-box';
import { images } from '../../../../../assets/images';
import { IId, IDropdown } from '../../../../../models/generic';
import { getServices, getStock } from '../../../../../services/centre';
import SingleButton from '../../../../../components/single-button/single-button';
import MainButton from '../../../../../components/main-button';
import { CENTRE_SERVICES_KEY_VALUE } from '../../../../../constants/centre-services';

export interface ICreateActivitiesExclusivenessProps {
  isEditing: boolean;
  exclusiveEquipment?: IId[];
  exclusiveServices?: IId[];
  exclusiveness?: {
    included: boolean;
    price: number;
  };
  onPrev: () => void;
  onNext: (
    exclusiveEquipment: IId[],
    exclusiveServices: IId[],
    exclusiveness: {
      included: boolean;
      price: number;
    }
  ) => void;
  centreId: string;
}

export interface ICreateActivitiesExclusivenessState {
  exclusiveness: {
    included: boolean;
    price: number;
  };
  exclusiveEquipment: IId[];
  exclusiveServices: IId[];
  servicesList: IDropdown[];
  equipmentList: IDropdown[];
}

export default class CreateActivitiesExclusiveness extends React.Component<
  ICreateActivitiesExclusivenessProps,
  ICreateActivitiesExclusivenessState
  > {
  constructor(props: ICreateActivitiesExclusivenessProps) {
    super(props);

    this.state = {
      exclusiveness: {
        included: false,
        price: NaN,
      },
      exclusiveEquipment: [],
      exclusiveServices: [],
      servicesList: [],
      equipmentList: [],
    };
  }

  componentDidMount() {
    this.setProps().then(() => {
      this.getCentreServices();
      this.getCentreEquipment();
    });
  }

  componentDidUpdate(prevProps: ICreateActivitiesExclusivenessProps) {
    const { exclusiveServices, exclusiveEquipment, exclusiveness } = this.props;
    if (
      exclusiveness !== prevProps.exclusiveness ||
      exclusiveEquipment !== prevProps.exclusiveEquipment ||
      exclusiveServices !== prevProps.exclusiveServices
    ) {
      this.setProps();
    }
  }

  setProps() {
    const { exclusiveServices, exclusiveEquipment, exclusiveness } = this.props;
    const state: any = {};
    if (exclusiveServices) state.exclusiveServices = exclusiveServices;
    if (exclusiveEquipment) state.exclusiveEquipment = exclusiveEquipment;
    if (exclusiveness) state.exclusiveness = exclusiveness;
    return new Promise<void>((res, rej) => {
      this.setState(state, () => {
        res();
      });
    });
  }

  async getCentreServices() {
    const { centreId } = this.props;
    const resp = await getServices(centreId);
    const servicesList = resp.data.data.map((service: any) => {
      return { value: service.id.toString(), label: CENTRE_SERVICES_KEY_VALUE[service.name] };
    });
    this.setState({ servicesList });
  }

  async getCentreEquipment() {
    const { centreId } = this.props;
    const resp = await getStock(centreId);
    const equipmentList = resp.data.data.map((service: any) => {
      return { value: service.id.toString(), label: service.name };
    });
    this.setState({ equipmentList });
  }

  handleCheck(checked: boolean, serviceId: string) {
    const { exclusiveServices } = Object.assign(this.state);
    const service = { id: parseInt(serviceId, undefined) };
    if (checked && exclusiveServices.filter((serv: IId) => serv.id === service.id).length === 0) {
      exclusiveServices.push(service);
      this.setState({ exclusiveServices });
    } else if (!checked && exclusiveServices.filter((serv: IId) => serv.id === service.id).length > 0) {
      this.setState({ exclusiveServices: exclusiveServices.filter((serv: IId) => serv.id !== service.id) });
    }
  }

  handleChange(field: string, value: boolean | number) {
    const { exclusiveness } = Object.assign(this.state);
    exclusiveness[field] = value;
    this.setState({ exclusiveness });
  }

  handleNext() {
    const { onNext } = this.props;
    const { exclusiveness, exclusiveEquipment, exclusiveServices } = this.state;
    onNext(exclusiveEquipment, exclusiveServices, exclusiveness);
  }

  render() {
    const { onPrev, isEditing } = this.props;
    const { exclusiveness, exclusiveEquipment, exclusiveServices, servicesList, equipmentList } = this.state;
    return (
      <CreateActivitiesExclusivenessStyle>
        <div className="create-activities-other-title">
          <div className="create-activities-other-title__text">
            <BodyDestacadoText>¿Quieres ofertarlo como una actividad exclusiva?</BodyDestacadoText>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={exclusiveness.included}
              onCheck={() => this.handleChange('included', true)}
            >
              <InfoText>Si</InfoText>
            </DicotomicCheckbox>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={!exclusiveness.included}
              onCheck={() => this.handleChange('included', false)}
            >
              <InfoText>No</InfoText>
            </DicotomicCheckbox>
          </div>
        </div>
        <FormGrid>
          <FormGridRow>
            <FormGridItem type={'col-0'}>
              <InputBox
                // errorCode="Error"
                placeholder="Precio"
                className="rounded-title"
                type="number"
                value={exclusiveness.price.toString()}
                labelText="precio de la actividad"
                required={true}
                onChange={value => this.handleChange('price', parseFloat(value))}
              />
            </FormGridItem>
          </FormGridRow>
        </FormGrid>
        {exclusiveness.included && (
          <>
            <div className="create-activities-other-title">
              <div className="create-activities-other-title__text">
                <BodyDestacadoText>Equipo incluido</BodyDestacadoText>
              </div>
            </div>

            <FormGrid>
              <FormGridRow>
                <FormGridItem type={'col-0'}>
                  <SelectBox
                    labelText="Seleccione el equipo que incluye"
                    placeholder="Seleccione el equipo que incluye"
                    required={true}
                    className="rounded-title"
                    optionsText={equipmentList}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={''}
                    initialValue={''}
                    initialMultipleValues={exclusiveEquipment.map(equip => equip.id.toString())}
                    defaultMultipleValues={exclusiveEquipment.map(equip => equip.id.toString())}
                    multiple={true}
                    icon={images.arrowDown2BlueSvg}
                    onChange={() => { }}
                    onChangeMultiple={value =>
                      this.setState({
                        exclusiveEquipment: value.map(equip => {
                          return {
                            id: parseInt(equip, undefined),
                          };
                        }),
                      })
                    }
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>

            <div className="create-activities-other-title">
              <div className="create-activities-other-title__text">
                <BodyDestacadoText>Servicios incluidos</BodyDestacadoText>
              </div>
            </div>
            <div className="create-activities-explusiveness">
              {servicesList.map((service: IDropdown, index: number) => {
                return (
                  <DicotomicCheckbox
                    key={index}
                    onCheck={checked => this.handleCheck(checked, service.value)}
                    checked={exclusiveServices.filter(serv => serv.id.toString() === service.value).length > 0}
                  >
                    <div className="create-activities-dicotomic-container">
                      <div className="create-activities-dicotomic-container__icon">
                        <img src={images.seaSvg} alt="" />
                      </div>
                      <div className="create-activities-dicotomic-container__text">
                        <InfoText>{service.label}</InfoText>
                      </div>
                    </div>
                  </DicotomicCheckbox>
                );
              })}
            </div>
          </>
        )}
        <div className="modal-create-activities-buttons mt-20">
          <div className="modal-create-activities-buttons-left">
            <div className="modal-create-activities-buttons-left__back">
              <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
            </div>
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={isEditing ? 'Editar' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesExclusivenessStyle>
    );
  }
}
