import React from 'react';
import { TextDestacadoTextContainer } from './text-destacado-text-styles';

interface TextDestacadoTextProps {
  children: any;
  className?: string;
  color?: string;
  cursor?: string;
}

const TextDestacadoText = ({ children = '', className = '', color = '', cursor = '' }: TextDestacadoTextProps) => {
  return (
    <TextDestacadoTextContainer color={color} className={className} cursor={cursor}>
      {children}
    </TextDestacadoTextContainer>
  );
};
export default TextDestacadoText;
