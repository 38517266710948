import * as React from 'react';
import { ItemReviewStyle } from './item-review-style';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import InfoText from '../text/info-text/info-text';
import BodyText from '../text/body-text/body-text';
import LabelText from '../text/label-text/label';
import { images } from '../../assets/images';

export interface IItemReviewProps {
  img: string;
  name: string;
  date: string;
  comment: string[];
  visibility: number;
  flowing: number;
  diversity: number;
}

export interface IItemReviewState { }

export default class ItemReview extends React.Component<IItemReviewProps, IItemReviewState> {
  constructor(props: IItemReviewProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { img, name, date, comment, visibility, flowing, diversity } = this.props;
    return (
      <ItemReviewStyle>
        <div className="item-review-avatar">
          <img src={img} alt="" />
        </div>

        <div className="item-review-container">
          <div className="item-review-title">
            <TextDestacadoText>{name}</TextDestacadoText>
          </div>

          <div className="item-review-date">
            <InfoText>{date}</InfoText>
          </div>

          <div className="item-review-comment">
            {comment.map((paragraph: string, index: number) => (
              <div key={index} className="item-review-comment__item">
                <BodyText>{paragraph}</BodyText>
              </div>
            ))}
          </div>

          <div className="item-review-rate">
            <div className="item-review-rate-item blue">
              <div className="item-review-rate-item-icon ">
                <img src={images.eyeLightBlueSvg} alt="" />
              </div>
              <div className="item-review-rate-item-number">
                <LabelText>{visibility}</LabelText>
              </div>
            </div>

            <div className="item-review-rate-item green">
              <div className="item-review-rate-item-icon ">
                <img src={images.seaGreenSvg} alt="" />
              </div>
              <div className="item-review-rate-item-number">
                <LabelText>{flowing}</LabelText>
              </div>
            </div>

            <div className="item-review-rate-item red">
              <div className="item-review-rate-item-icon">
                <img src={images.fishRedSvg} alt="" />
              </div>
              <div className="item-review-rate-item-number">
                <LabelText>{diversity}</LabelText>
              </div>
            </div>
          </div>
        </div>
      </ItemReviewStyle>
    );
  }
}
