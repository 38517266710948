import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const PlanningCourseDateStyle = styled.div`
  .planning {
    margin-bottom: 30px;

    .planning-phase-tittle {
      font-size: 15px;
      color: #014251;
      margin-bottom: 5px;
      margin-top: 15px;
    }
    .planning-phase-content {
      color: ${colors['NE-001/100']};
      display: flex;
      align-items: center;
    }
  }

  .planing-title {
    color: ${colors['SE-002/100']};
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .planing-type {
    margin-bottom: 15px;
  }

  .planing-type-form {
    display: flex;
  }

  .planing-type-session {
    width: 100%;
  }
  .planing-mode-session {
    width: 100%;
    display: grid;
  }
  .planing-dive-site {
    width: 100%;
  }

  .planing-date {
    margin-bottom: 15px;
  }
  .planing-date-form {
    width: 100%;
  }

  .planing-date-horary {
  }
  .planing-date-horary-form {
    display: flex;
    &__item {
      flex: auto;
      margin-right: 20px;
    }
    &__add {
      width: 120px;
    }
    &__times {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      padding: 20px;
    }
  }

  .planing-add-session {
    margin-bottom: 20px;
  }

  .summary {
    margin-bottom: 30px;

    .session-tittle {
      font-size: 15px;
      color: #014251;
      margin-bottom: 5px;
      margin-top: 15px;
    }
    .session-content {
      color: ${colors['NE-001/100']};
      display: flex;
      align-items: center;

      .delete-img {
        cursor: pointer;
        width: 20px;
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 5px;
        cursor: pointer;
      }
    }
  }
`;
