import React from 'react';
import { ModalCancelationActivityContainer } from './modal-activity-cancelation-style';
import MainButton from '../../main-button';
import ModalContainer from '../modal-container';
import DicotomicCheckbox from '../../dicotomic-checkbox';
import InputBox from '../../input-box';
import InputArea from '../../input-area/input-area';
import { postCentreActivityCancel } from '../../../services/centre';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';

// eslint-disable-next-line no-undef
interface ModalProps extends RouteComponentProps, WithTranslation {
  centreId: string;
  activityId: string;
  className?: String;
  showModal: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  t: any;
}

interface ModalState {
  hasDiverMsg: boolean;
  hasStaffMsg: boolean;
  diverMessage: string;
  staffMessage: string;
}

class ModalActivityCancel extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      hasDiverMsg: false,
      hasStaffMsg: false,
      diverMessage: '',
      staffMessage: '',
    };
  }

  async handleSubmit() {
    const { activityId, centreId, onSuccess } = this.props;
    const { diverMessage, hasDiverMsg, staffMessage, hasStaffMsg } = this.state;
    const data: any = {};
    if (hasDiverMsg) data['diverMessage'] = diverMessage;
    if (hasStaffMsg) data['staffMessage'] = staffMessage;
    await postCentreActivityCancel(centreId, activityId, data);
    onSuccess();
  }

  render() {
    const { showModal, onCancel,t } = this.props;
    const { hasDiverMsg, hasStaffMsg, staffMessage, diverMessage } = this.state;
    return (
      <ModalContainer className="center" modalClose={() => onCancel()} active={showModal}>
        <ModalCancelationActivityContainer>
          {/*<div className="modal-cancelation-activity-name">
            <p>Nombre de la actividad</p>
          </div>*/}

          <div className="modal-cancelation-activity-title">
            <p>{t('modalMessages:modal-cancelation-activity.cancel-activity')}</p>
          </div>

          <div className="modal-cancelation-activity-alert">
            <p>
            {t('modalMessages:modal-cancelation-activity.cancel-advice')}
            </p>
            <p>
            {t('modalMessages:modal-cancelation-activity.cancel-advice2')}
            </p>
          </div>
          {/*}
          <div className="modal-cancelation-activity-message">
            <div className="message-checkbox">
              <DicotomicCheckbox
                className={'switch'}
                onCheck={(checked: boolean) => this.setState({ hasDiverMsg: checked })}
              />
            </div>
            <div className="message-text">
              <p>Mensaje para los buceadores con reserva</p>
            </div>
          </div>
          {hasDiverMsg && (
            <>
              <div className="modal-cancelation-activity-inputarea-title">
                <p>Observaciones</p>
              </div>
              <div className="modal-cancelation-activity-inputarea-content">
                <InputArea
                  value={diverMessage}
                  name=""
                  required={true}
                  placeholder="Mensaje para buceadores"
                  onChange={(ev: any) => this.setState({ diverMessage: ev.target.value })}
                />
              </div>
            </>
          )}
          <div className="modal-cancelation-activity-message last-child">
            <div className="message-checkbox">
              <DicotomicCheckbox
                className={'switch'}
                onCheck={(checked: boolean) => this.setState({ hasStaffMsg: checked })}
              />
            </div>
            <div className="message-text">
              <p>Mensaje para el staff</p>
            </div>
          </div>
          {hasStaffMsg && (
            <>
              <div className="modal-cancelation-activity-inputarea-title">
                <p>Observaciones</p>
              </div>
              <div className="modal-cancelation-activity-inputarea-content">
                <InputArea
                  value={staffMessage}
                  name=""
                  required={true}
                  placeholder="Mensaje para staff"
                  onChange={(ev: any) => this.setState({ staffMessage: ev.target.value })}
                />
              </div>
            </>
          )}
          */}
          <div className="modal-cancelation-activity-button">
            <MainButton type="primary" text={t('modalMessages:modal-cancelation-activity.cancel-activity')} onClick={() => this.handleSubmit()} />
          </div>
        </ModalCancelationActivityContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages'])(withRouter(ModalActivityCancel));