  import accesibilityBlueSvg from './accesibility-blue.svg';
  import accesibilitySvg from './accesibility.svg';
  import activitySvg from './activity.svg';
  import alertBlueSvg from './alert-blue.svg';
  import alertCircleRedSvg from './alert-circle-red.svg';
  import alertSvg from './alert.svg';
  import alertWhiteSvg from './alert-white.svg';
  import archiveBlueSvg from './archive-blue.svg';
  import archiveSvg from './archive.svg';
  import arrowDown2BlueSvg from './arrow-down-blue-2.svg';
  import arrowDown2Svg from './arrow-down-2.svg';
  import arrowDown2WhiteSvg from './arrow-down-2-white.svg';
  import arrowDownLeftSvg from './arrow-down-left.svg';
  import arrowDownRightSvg from './arrow-down-right.svg';
  import arrowDownSvg from './arrow-down.svg';
  import arrowLeft2Svg from './arrow-left-2.svg';
  import arrowLeftSvg from './arrow-left.svg';
  import arrowLeftWhite2Svg from './arrow-left-white-2.svg';
  import arrowLeftWhiteSvg from './arrow-left-white.svg';
  import arrowRight2Svg from './arrow-right-2.svg';
  import arrowRightWhite2Svg from './arrow-right-white-2.svg';
  import arrowUp2Svg from './arrow-up-2.svg';
  import arrowUpRightSvg from './arrow-up-right.svg';
  import arrowUpRightWhiteSvg from './arrow-up-right-white.svg';
  import arrowUpSvg from './arrow-up.svg';
  import attachSvg from './attach.svg';
  import beachBlueSvg from './beach-blue.svg';
  import beachGreySvg from './beach-grey.svg';
  import beachSvg from './beach.svg';
  import bellSvg from './bell.svg';
  import bellWhiteSvg from './bell-white.svg';
  import calendarSvg from './calendar.svg';
  import calendarWhiteSvg from './calendar-white.svg';
  import calendarDisableSvg from './calendar-disable.svg';
  import cameraSvg from './camera.svg';
  import chartSvg from './chart.svg';
  import chartWhiteSvg from './chart-white.svg';
  import checkApprovedBlueSvg from './check-approved-blue.svg';
  import checkCircleBlueSvg from './check-circle-blue.svg';
  import checkCircleGreenSvg from './check-circle-green.svg';
  import checkCircleSvg from './check-circle.svg';
  import checkCircleGreySvg from './check-circle-grey.svg';
  import checkCircleWhiteSvg from './check-circle-white.svg';
  import checkSuccessSvg from './check-success.svg';
  import checkWhite from './check-white.svg';
  import checkSvg from './check.svg';
  import checkBlueSvg from './check-blue.svg';
  import circleBlueSvg from './circle-blue.svg';
  import circleGreenSvg from './circle-green.svg';
  import circleNumberSvg from './circle-number.svg';
  import circleRedSvg from './circle-red.svg';
  import circleSvg from './circle.svg';
  import clockSvg from './clock.svg';
  import clockWhiteSvg from './clock-white.svg';
  import closeBlueSvg from './close-blue.svg';
  import closeCircleRedSvg from './close-circle-red.svg';
  import closeCircleSvg from './close-circle.svg';
  import closeRedSvg from './close-red.svg';
  import closeGreenSvg from './close-green.svg';
  import closeSvg from './close.svg';
  import closeCrossWhiteSvg from './close-cross-white.svg';
  import closeWhiteSvg from './close-white.svg';
  import createSvg from './create.svg';
  import createWhiteSvg from './create-white.svg';
  import creditCardSvg from './credit-card.svg';
  // import crossWhite from './cross-white.svg';
  import dashedBorder from './dashed-border.svg';
  import dashedBorderSmall from './input-filte-dot-small.svg';
  import delete2RedSvg from './delete-2-red.svg';
  import delete2Svg from './delete-2.svg';
  import deleteSvg from './delete.svg';
  import deleteGreenSvg from './delete-green.svg';
  import discoBlueSvg from './disco-blue.svg';
  import discoSvg from './disco.svg';
  import diveasappLogoIntroSvg from './diveasapp-logo-intro.svg';
  import diveasappLogoWhiteSvg from './logo-dive-white.svg';
  import downloadBlueSvg from './download-blue.svg';
  import downloadGreenSvg from './download-green.svg';
  import downloadSvg from './download.svg';
  import edit2Svg from './edit-2.svg';
  import edit3Svg from './edit-3.svg';
  import edit4Svg from './edit-4.svg';
  import edit3WhiteSvg from './edit-3-white.svg';
  import editSvg from './edit.svg';
  import externalLinkGrey from './external-link-grey.svg';
  import eyeGrayOffSvg from './eye-gray-close.svg';
  import eyeGraySvg from './eye-gray.svg';
  import eyeLightBlueSvg from './eye-light-blue.svg';
  import eyeOffSvg from './eye-off.svg';
  import eyeSvg from './eye.svg';
  import facebookSvg from './facebook.svg';
  import fileBlueSvg from './file-blue.svg';
  import fileSvg from './file.svg';
  import fileWhiteSvg from './file-white.svg';
  import filters2Svg from './filters-2.svg';
  import filtersSvg from './filters.svg';
  import filtersLightBlueSvg from './filters-light-blue.svg';
  import fishRedSvg from './fish-red.svg';
  import fishSvg from './fish.svg';
  import flagSvg from './flag.svg';
  import folderSvg from './folder.svg';
  import fullScreenWhite from './full-screen-white.svg';
  import gmailSvg from './gmail.svg';
  import googleSvg from './google.svg';
  import gridSvg from './grid.svg';
  import heartSvg from './heart.svg';
  import heartRedSvg from './heart-red.svg';
  import heartWhiteSvg from './heart-white.svg';
  import homeSvg from './home.svg';
  import homeWhiteSvg from './home-white.svg';
  import imagesBlueSvg from './images-blue.svg';
  import imagesSvg from './images.svg';
  import inboxSvg from './inbox.svg';
  import infoBlueSvg from './info-blue.svg';
  import infoGreySvg from './info-grey.svg';
  import infoSvg from './info.svg';
  import invalidSite from './invalid-site.svg';
  import jacuzziBlueSvg from './jacuzzi-blue.svg';
  import jacuzziSvg from './jacuzzi.svg';
  import keySvg from './key.svg';
  import kindergartenBlueSvg from './kindergarten-blue.svg';
  import kindergartenSvg from './kindergarten.svg';
  import layersSvg from './layers.svg';
  import layersWhiteSvg from './layers-white.svg';
  import equipmentLight from './equipmentLight.svg';
  import linkSvg from './link.svg';
  import listBlueSvg from './list-blue.svg';
  import listSvg from './list.svg';
  import loaderSvg from './loader.svg';
  import locationSvg from './location.svg';
  import lockSvg from './lock.svg';
  import loginSvg from './login.svg';
  import logOutBlueSvg from './log-out-blue.svg';
  import logOutSvg from './log-out.svg';
  import logOutWhiteSvg from './log-out-white.svg';
  import mailOpenBlueSvg from './mail-open-blue.svg';
  import mailSvg from './mail.svg';
  import mailBlueSvg from './mail-blue.svg';
  import mailWhiteSvg from './mail-white.svg';
  import mapBlueSvg from './map-blue.svg';
  import mapSvg from './map.svg';
  import menuSvg from './menu.svg';
  import messageCircleGreenSvg from './message-circle-green.svg';
  import messageCircleSvg from './message-circle.svg';
  import messageCircleWhiteSvg from './message-circle-white.svg';
  import messageSvg from './message.svg';
  import minusSvg from './minus.svg';
  import minusWhiteSvg from './minus-white.svg';
  import otherSvg from './other.svg';
  import parkingBlueSvg from './parking-blue.svg';
  import parkingSvg from './parking.svg';
  import pauseSvg from './pause.svg';
  import percentBlueSvg from './percent-blue.svg';
  import phoneSvg from './phone.svg';
  import gobiernoEspana from './gobierno-espana.png';
  import playSvg from './play.svg';
  import plusSvg from './plus.svg';
  import plusWhiteSvg from './plus-white.svg';
  import printSvg from './print.svg';
  import projectsSvg from './projects.svg';
  import pubBlueSvg from './pub-blue.svg';
  import pubSvg from './pub.svg';
  import puntoInmersionSelectedSvg from './punto-inmersion-selected.svg';
  import minimunRequirementsSvg from './minimun-requirements.svg';
  import puntoInmersionSvg from './punto-inmersion.svg';
  import restaurantBlueSvg from './restaurant-blue.svg';
  import restaurantSvg from './restaurant.svg';
  import saveSvg from './save.svg';
  import saveWhiteSvg from './save-white.svg';
  import seaGreenSvg from './sea-green.svg';
  import searchSvg from './search.svg';
  import seaSvg from './sea.svg';
  import sendSvg from './send.svg';
  import settingsSvg from './settings.svg';
  import settingsWhiteSvg from './settings-white.svg';
  import shareSvg from './share.svg';
  import shopBlueSvg from './shop-blue.svg';
  import shopSvg from './shop.svg';
  import shopWhiteSvg from './shop-white.svg';
  import starEmptySvg from './star-empty.svg';
  import starFullRedSvg from './star-full-red.svg';
  import starFullSvg from './star-full.svg';
  import starSvg from './star.svg';
  import starWhiteSvg from './star-white.svg';
  import starRedSvg from './star-empty-red.svg';
  import starBlackSvg from './star-black.svg';
  import statePending from './state-pending.svg';
  import stateReject from './state-reject.svg';
  import stateSuccess from './state-success.svg';
  import stopSvg from './stop.svg';
  import stripeSvg from './Stripe.svg';
  import swimmerBlueSvg from './swimmer-blue.svg';
  import swimmerSvg from './swimmer.svg';
  import tagSvg from './tag.svg';
  import tagWhiteSvg from './tag-white.svg';
  import thumbsUp from './thumbs-up.svg';
  import unlockSvg from './unlock.svg';
  import uploadSvg from './upload.svg';
  import uploadWhiteSvg from './upload-white.svg';
  import userDobleSvg from './user-doble.svg';
  import userDobleWhiteSvg from './user-doble-white.svg';
  import userGreySvg from './user-grey.svg';
  import userPlusSvg from './user-plus.svg';
  import userSvg from './user.svg';
  import defaultUserSvg from './user-default.svg';
  import userWhiteSvg from './user-white.svg';
  import wifiBlueSvg from './wifi-blue.svg';
  import wifiFreeBlueSvg from './wifi-free-blue.svg';
  import wifiFreeSvg from './wifi-free.svg';
  import wifiSvg from './wifi.svg';
  import medalOficialSvg from './medal-oficial.svg';
  import cellBackground from './cell-background.svg';
  import warningRedSvg from './warning-red.svg';
  import boatsSvg from './boats.svg';

  import checkAllGreenSvg from './check-all-green.svg';
  import checkCircleAllBlueSvg from './check-ciclre-all-blue.svg';
  import exclamationRed from './exclamation-red.svg';
  import percentRed from './percent-red.svg';
  import rejectedRed from './rejected-red.svg';

  import arrowBackPale from './arrow-back-pale-blue.svg';
  import rowCertificate from './cell-row-certificate.svg';
  import rowCenter from './cell-row-center.svg';
  import signUpBack from './sign-up-back.png';
  import bookPlanification from './book-planification.svg';
  import profileCertifierMenu from './profile-certifier-menu.svg';
  import actCertifierMenu from './act-certifier-menu.svg';

  // IMG
  import activityBack from './activity-back.jpeg';
  import avatar from './avatar.png';
  import avatarDefault from './avatar-default.png';
  import avatarDiver from './avatar-diver.png';
  import avatarDiverDefault from './buceadores_default.png';
  import avatarCenterDefault from './centros_default.png';

 
  import introPanel from './intro-panel.jpg';
  import jellyfishIntro from './jellyfish-intro.png';
  import scubaDiverIntro from './scuba-diver-intro.png';
  import ellipse from './Ellipse.png';
  // import logoInfoIntro from './logo-info-intro.png';
  import logoInfoIntro from './galpemur.png';
  import logoMurcia from './logo-murcia.png';
  import logoEuropeIntro from './femp-ue.png';
  import modalSuccessJelly from './modal-success-jelly.png';
  import modalSuccessJellyNew from './modal-success-jelly-new.png';
  import noContentFish from './no-content-fish.png';
  import notFound from './not-found.png';
  import comingSoon from './coming-soon.png';
  import turtle from './turtle.jpeg';

  import myDivesMenu from './my-dives-menu.svg';
  import myDiveCenterMenu from './my-dive-center-menu.svg';
  import billingMenu from './billing-menu.svg';
  import immersionPointFlag from './immersion-piont.svg';
  import immersionPointNoFlag from './inmersion-point-no.svg';
  import rolesManagementMenu from './roles-management-menu.svg';
  import divingCompaniestMenu from './diving-companies-menu.svg';
  import divingCompaniestMenuBlack from './diving-companies-menu-black.svg';
  import listDivingCenter from './list-diving-center.svg';
  import listDivingCenterWhite from './list-diving-center-white.svg';
  import inmmersionPointsMenu from './inmmersion-points-menu.svg';
  import PlanificationMenu from './planification-menu.svg';
  import installationsNotifications from './installations.svg';
  import certifierCatalog from './certifier-catalog.svg';
  import certifierCatalogWhite from './certifier-catalog-white.svg';
  import addImageSvg from './add-image.svg';
  import notFound2 from './404.png';
  import diversSvg from './divers.svg';
  import diversMenuSvg from './divers-menu.svg';
  import diversGreenSvg from './divers-green.svg';
  import depthSvg from './depth.svg';
  import trainingSvg from './training.svg';
  import diveSvg from './dive.svg';
  import dataGeneral from './data-general.svg';
  import dataFiscal from './data-fiscal.svg';
  import dataContact from './data-contact.svg';
  import diveSite from './dive-site.svg';
  import userBookings from './users-bookings.svg';

  import diveSiteNoValidated from './dive-site-no-validated.svg';
  import diveSiteValidated from './dive-site-validated.svg';
  import diveSiteMarineReserve from './dive-site-marine-reserve.svg';

  import serviceMarineReserve from './service-accesoreservamarina.svg';
  import servicePetCare from './service-cuidadomascotas.svg';
  import serviceMedicalCertificate from './service-expedicioncertificadomedico.svg';
  import serviceKindergarten from './service-guarderia.svg';
  import serviceKayak from './service-kayak.svg';
  import serviceEquipment from './service-montajeequipo.svg';
  import serviceNitrox from './service-nitrox.svg';
  import servicePaddleSurf from './service-padelsurf.svg';
  import serviceBottleSurcharge from './service-recargabotella.svg';
  import serviceAirport from './service-recogidaaeropuerto.svg';
  import serviceHotel from './service-recogidahotel.svg';
  import servicePort from './service-recogidapuerto.svg';
  import equipmentFull from './equipmentFull.svg';
  import serviceReportage from './service-reportajesubacuatico.svg';
  import serviceServices from './service-servicios.svg';
  import serviceSnorkel from './service-snorkel.svg';
  import serviceTransport from './service-transporte.svg';
  import serviceYoga from './service-yoga.svg';
  import menuCollaborators from './menu-collaborators.svg';
  import adsMenu from './ads-menu.svg';
  import theorySvg from './theory.svg';
  import servicesSvg from './services.svg';
  import equipmentSvg from './equipment.svg';
  import accomodationAndFoodSvg from './accomodation-and-food.svg';
  import transportSvg from './transport.svg';
  import observationsSvg from './observations.svg';
  import nitroxSvg from './nitrox.svg';
  import tecnicalDivingSvg from './tecnical-diving.svg';
  import adaptedDivingSvg from './adapted-diving.svg'
  import equipmentScooter from './equipmentScooter.svg';
  import computer from './computer.svg';
  import wetsuit from './wetsuit.svg';
  import wetsemi from './wetsemi.svg';
  import equipmentCamera from './equipmentCamera.svg';
  import logoDiveasapp from './logo.svg';
  import seguro from './seguro.png'
  import inmersion_nocturna from './nocturna.svg'
  import ducha_caliente from './ducha_caliente.svg'
  import guia from './inmersion-01.svg'
  import boya_deco from './boya_deco.svg'
  import cuchillo from './cuchillo.svg'
  import lastre from './lastre.svg'
  import escarpines from './escarpines.svg'
  import aletas from './aletas.svg'
  import chaleco from './chaleco.svg'
  import trajeHumedo from './trajeHumedo.svg'
  import mascara from './mascara.svg'
  import regulador from './regulador.svg'
  import embarcacion from './embarcacion.svg'
  import recargaNitrox from './recargaNitrox.svg'
  import r12l from './12l.svg'
  import r15l from './15l.svg'
  import r18l from './18l.svg'
  import bibotella from './bibotella.svg'
  import alas from './alas.svg'
  import boyadeco from './boyadeco.svg'
  import supequlig from './supequlig.svg'
  import supnoc from './supnoc.svg'
  import apnea from './apnea.svg'
  import alqsidemount from './alqsidemount.svg'
  import alq12l from './alq12l.svg'
  import alq15l from './alq15l.svg'
  import alq18l from './alq18l.svg'
  import others from './others.svg'
  import equipmentRev from './equipmentRev.svg'
  import divingtravel from './viajes.svg'
  import compresor from './compresor.svg'
  
  export const images: { [key: string]: any } = {
    compresor,
    divingtravel,
    others,
    equipmentRev,
    alq12l,
    alq15l,
    alq18l,
    alqsidemount,
    apnea,
    userBookings,
    diveSite,
    accesibilityBlueSvg,
    menuCollaborators,
    adsMenu,
    accesibilitySvg,
    activityBack,
    activitySvg,
    alas,
    alertBlueSvg,
    alertCircleRedSvg,
    alertSvg,
    alertWhiteSvg,
    aletas,
    archiveBlueSvg,
    archiveSvg,
    arrowDown2BlueSvg,
    arrowDown2Svg,
    arrowDown2WhiteSvg,
    arrowDownLeftSvg,
    arrowDownRightSvg,
    arrowDownSvg,
    arrowLeft2Svg,
    arrowLeftSvg,
    arrowLeftWhite2Svg,
    arrowLeftWhiteSvg,
    arrowRight2Svg,
    arrowRightWhite2Svg,
    arrowUp2Svg,
    arrowUpRightSvg,
    arrowUpRightWhiteSvg,
    arrowUpSvg,
    attachSvg,
    bibotella,
    beachBlueSvg,
    beachGreySvg,
    beachSvg,
    bellSvg,
    bellWhiteSvg,
    boya_deco,
    calendarSvg,
    cameraSvg,
    cellBackground,
    chartSvg,
    chartWhiteSvg,
    checkAllGreenSvg,
    checkApprovedBlueSvg,
    checkCircleAllBlueSvg,
    checkCircleBlueSvg,
    checkCircleGreenSvg,
    checkCircleGreySvg,
    checkCircleSvg,
    arrowBackPale,
    chaleco,
    checkCircleWhiteSvg,
    checkSuccessSvg,
    checkWhite,
    checkSvg,
    checkBlueSvg,
    circleBlueSvg,
    circleGreenSvg,
    circleNumberSvg,
    circleRedSvg,
    circleSvg,
    calendarDisableSvg,
    clockSvg,
    closeBlueSvg,
    diveSiteNoValidated,
    diveSiteValidated,
    diveSiteMarineReserve,
    closeCircleRedSvg,
    closeCircleSvg,
    closeSvg,
    closeRedSvg,
    closeGreenSvg,
    closeCrossWhiteSvg,
    closeWhiteSvg,
    computer,
    createSvg,
    createWhiteSvg,
    creditCardSvg,
    cuchillo,
    dashedBorder,
    dashedBorderSmall,
    delete2RedSvg,
    delete2Svg,
    deleteSvg,
    deleteGreenSvg,
    discoBlueSvg,
    ducha_caliente,
    signUpBack,
    gobiernoEspana,
    discoSvg,
    diveasappLogoIntroSvg,
    downloadBlueSvg,
    downloadGreenSvg,
    downloadSvg,
    edit2Svg,
    edit3Svg,
    edit4Svg,
    edit3WhiteSvg,
    editSvg,
    embarcacion,
    escarpines,
    exclamationRed,
    diveasappLogoWhiteSvg,
    equipmentCamera,
    externalLinkGrey,
    eyeGrayOffSvg,
    eyeGraySvg,
    eyeLightBlueSvg,
    eyeOffSvg,
    eyeSvg,
    heartRedSvg,
    facebookSvg,
    fileBlueSvg,
    fileSvg,
    divingCompaniestMenuBlack,
    fileWhiteSvg,
    filters2Svg,
    filtersLightBlueSvg,
    filtersSvg,
    fishRedSvg,
    equipmentFull,
    fishSvg,
    flagSvg,
    avatarDiverDefault,
    avatarCenterDefault,
    equipmentScooter,
    diversMenuSvg,
    diversGreenSvg,
    folderSvg,
    fullScreenWhite,
    gmailSvg,
    googleSvg,
    gridSvg,
    guia,
    heartSvg,
    heartWhiteSvg,
    homeSvg,
    homeWhiteSvg,
    imagesBlueSvg,
    imagesSvg,
    logoMurcia,
    equipmentLight,
    inboxSvg,
    infoBlueSvg,
    infoGreySvg,
    infoSvg,
    inmersion_nocturna,
    invalidSite,
    jacuzziBlueSvg,
    jacuzziSvg,
    keySvg,
    kindergartenBlueSvg,
    kindergartenSvg,
    layersSvg,
    certifierCatalog,
    certifierCatalogWhite,
    layersWhiteSvg,
    medalOficialSvg,
    lastre,
    linkSvg,
    listBlueSvg,
    defaultUserSvg,
    listSvg,
    loaderSvg,
    locationSvg,
    lockSvg,
    loginSvg,
    logOutBlueSvg,
    logOutSvg,
    logOutWhiteSvg,
    mailBlueSvg,
    mailOpenBlueSvg,
    mailSvg,
    mailWhiteSvg,
    mapBlueSvg,
    mapSvg,
    mascara,
    menuSvg,
    messageCircleGreenSvg,
    messageCircleSvg,
    messageCircleWhiteSvg,
    messageSvg,
    minusSvg,
    minusWhiteSvg,
    notFound,
    otherSvg,
    parkingBlueSvg,
    parkingSvg,
    pauseSvg,
    percentBlueSvg,
    percentRed,
    phoneSvg,
    playSvg,
    plusSvg,
    plusWhiteSvg,
    printSvg,
    projectsSvg,
    pubBlueSvg,
    pubSvg,
    puntoInmersionSelectedSvg,
    puntoInmersionSvg,
    r12l,
    r15l,
    r18l,
    recargaNitrox,
    regulador,
    rejectedRed,
    restaurantBlueSvg,
    restaurantSvg,
    rowCertificate,
    rowCenter,
    saveSvg,
    saveWhiteSvg,
    seaGreenSvg,
    searchSvg,
    seaSvg,
    seguro,
    sendSvg,
    settingsSvg,
    settingsWhiteSvg,
    shareSvg,
    shopBlueSvg,
    shopSvg,
    shopWhiteSvg,
    starEmptySvg,
    starFullRedSvg,
    starFullSvg,
    starRedSvg,
    starSvg,
    starWhiteSvg,
    starBlackSvg,
    statePending,
    stateReject,
    stateSuccess,
    stopSvg,
    stripeSvg,
    swimmerBlueSvg,
    swimmerSvg,
    tagSvg,
    tagWhiteSvg,
    thumbsUp,
    unlockSvg,
    uploadSvg,
    uploadWhiteSvg,
    userDobleSvg,
    userDobleWhiteSvg,
    userGreySvg,
    userPlusSvg,
    userSvg,
    userWhiteSvg,
    wifiBlueSvg,
    wifiFreeBlueSvg,
    wifiFreeSvg,
    wifiSvg,
    bookPlanification,
    avatar,
    avatarDefault,
    avatarDiver,
    jellyfishIntro,
    scubaDiverIntro,
    logoInfoIntro,
    logoEuropeIntro,
    modalSuccessJelly,
    modalSuccessJellyNew,
    noContentFish,
    turtle,
    myDivesMenu,
    myDiveCenterMenu,
    billingMenu,
    immersionPointFlag,
    comingSoon,
    rolesManagementMenu,
    divingCompaniestMenu,
    listDivingCenterWhite,
    listDivingCenter,
    PlanificationMenu,
    inmmersionPointsMenu,
    immersionPointNoFlag,
    installationsNotifications,
    notFound2,
    profileCertifierMenu,
    actCertifierMenu,
    addImageSvg,
    diversSvg,
    depthSvg,
    trainingSvg,
    diveSvg,
    dataGeneral,
    dataFiscal,
    dataContact,
    serviceMarineReserve,
    servicePetCare,
    serviceMedicalCertificate,
    serviceKindergarten,
    serviceKayak,
    serviceEquipment,
    serviceNitrox,
    servicePaddleSurf,
    serviceBottleSurcharge,
    serviceAirport,
    serviceHotel,
    servicePort,
    serviceReportage,
    serviceServices,
    serviceSnorkel,
    serviceTransport,
    serviceYoga,
    ellipse,
    warningRedSvg,
    clockWhiteSvg,
    calendarWhiteSvg,
    minimunRequirementsSvg,
    trajeHumedo,
    theorySvg,
    servicesSvg,
    equipmentSvg,
    accomodationAndFoodSvg,
    transportSvg,
    observationsSvg,
    nitroxSvg,
    adaptedDivingSvg,
    tecnicalDivingSvg,
    boatsSvg,
    wetsuit,
    wetsemi,
    introPanel,
    logoDiveasapp,
    boyadeco,
    supequlig,
    supnoc
  };
