import React from 'react';
import { ModalPersonalStyle } from './modal-personal-style';
import ModalContainer from '../../../../../../components/modals/modal-container';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../../main-layout-style';
import SelectBox from '../../../../../../components/select-box';
import { images } from '../../../../../../assets/images';
import InputBox from '../../../../../../components/input-box';
import MainButton from '../../../../../../components/main-button';
import SubheadDestacadoText from '../../../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../../../../../components/text/body-text/body-text';
import BodyDestacadoText from '../../../../../../components/text/body-destacado-text/body-destacado-text';
import CalendarBox from '../../../../../../components/calendar-box';
import { DiverProfile } from '../../types';
import { GENDER_DROPDOWN } from '../../../../../../constants/gender';
import { COUNTRIES } from '../../../../../../constants/countries';
import moment from 'moment';
import PhoneBox from '../../../../../../components/phone-box/phone-box';
import {
  isValidPhone,
  isValidEmail,
  isValidPostalCode,
  isValidURL,
  isValidDocumentID,
} from '../../../../../../utils/validation';
import { copyOf } from '../../../../../../utils/other';
import { uploadUsers } from '../../../../../../services/users';
import { setDiverData } from '../../../../../../services/diver';
import services from '../../../../../../services/services';
import _ from 'lodash';
import { getDataFromAddress } from '../../../../../../services/google-maps';
import { getAdministrativeLevel1, getTown, getCountry, getPostalCode } from '../../../../../../utils/maps';
import { IDropdown } from '../../../../../../models/generic';

interface ModalPersonalProps {
  showModal: boolean;
  onClose: () => void;
  onSave: () => void;
  person: DiverProfile;
}

interface ModalPersonalState {
  person?: DiverProfile;
  places: IDropdown[];
  errors: { [s: string]: string };
}

export default class ModalPersonal extends React.Component<ModalPersonalProps, ModalPersonalState> {
  constructor(props: ModalPersonalProps) {
    super(props);
    this.state = {
      places: [],
      errors: {},
    };
  }
  componentDidMount() {
    const { person } = this.props;
    if (person.birthday) person.birthday = moment(person.birthday).format('DD/MM/YYYY');
    this.setState({ person });
  }

  validateField(field: string, value: string, required: boolean) {
    const { errors } = this.state;
    const fieldType = field.split('.').pop();
    errors[field] = '';
    switch (fieldType) {
      case 'phone':
        errors[field] = !isValidPhone(value) ? 'invalid-phone' : '';
        break;
      case 'email':
        errors[field] = !isValidEmail(value) ? 'invalid-email' : '';
        break;
      case 'postalCode':
        errors[field] = !isValidPostalCode(value) ? 'invalid-postal-code' : '';
        break;
      case 'facebook':
      case 'instagram':
        errors[field] = !isValidURL(value) ? 'invalid-format' : '';
        break;
      // case 'nif':
      //   errors[field] = !isValidDocumentID(value, 'NIF') ? 'invalid-dni' : '';
      //   break;
    }
    errors[field] = required && (value === '' || !value) ? 'required' : errors[field];
    if ((!required && value === '') || !value) {
      errors[field] = '';
    }
    this.setState({ errors });
    return errors[field] === '';
  }

  handleChange(field: string, value: string, required: boolean) {
    const { person } = this.state;
    if (person) {
      const updatedPerson = _.update(person, field, () => value);
      this.setState({ person: updatedPerson }, () => {
        this.validateField(field, value, required);
      });
    }
  }

  checkErrors() {
    const { errors } = this.state;
    let error = false;
    Object.values(errors).forEach(err => {
      error = error || err !== '';
    });
    return error;
  }

  validate() {
    const { person } = this.state;
    if (!person) return false;
    let error = false;
    if (person.emergencyContact) {
      error = error || !this.validateField('emergencyContact.name', person.emergencyContact.name, true);
      error = error || !this.validateField('emergencyContact.email', person.emergencyContact.email, true);
      error =
        error ||
        !this.validateField(
          'emergencyContact.phone.number',
          person.emergencyContact.phone ? person.emergencyContact.phone.number : '',
          true
        );
    }
    error = error || this.checkErrors();
    return !error;
  }

  async handleSaveChanges() {
    const { person } = this.state;
    const { onSave } = this.props;
    if (person && this.validate()) {
      const userData = {
        name: person.name,
        surname: person.surname,
        gender: person.gender,
      };
      const diverData = copyOf(person);
      delete diverData.name;
      delete diverData.surname;
      delete diverData.id;
      delete diverData.userId;
      if (diverData && diverData.phone && !diverData.phone.prefix) {
        diverData.phone.prefix = '+34';
      }
      if (diverData.emergencyContact && diverData.emergencyContact.phone && !diverData.emergencyContact.phone.prefix) {
        diverData.emergencyContact.phone.prefix = '+34';
      }
      diverData.birthday = moment(diverData.birthday, 'DD/MM/YYYY').toISOString();
      await uploadUsers(person.userId.toString(), userData);
      await setDiverData(person.id.toString(), diverData);
      services.pushNotification({
        title: 'Genial!',
        text: 'El usuario se ha editado correctamente',
        type: 'green',
      });
      onSave();
    }
  }

  searchTimeout: any;
  handleSearchAddress(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (value && value !== '') this.searchAddress(value);
    }, 300);
  }

  async searchAddress(value: string) {
    const resp: any = await getDataFromAddress(value);
    const results = resp.data.results;
    const places: any[] = results.map((result: any) => {
      const level1 = getAdministrativeLevel1(result);
      const town = getTown(result);
      const country = getCountry(result);
      const postalCode = getPostalCode(result);
      return {
        level1,
        town,
        country,
        postalCode,
        value: result.formatted_address,
        label: result.formatted_address,
      };
    });
    this.setState({ places });
  }

  validForm = () => {
    const { person } = this.props;
    const invalid =
      (person.phone && person.phone.prefix && (!person.phone || person.phone.number === '')) ||
      (person.emergencyContact &&
        person.emergencyContact.phone &&
        (!person.emergencyContact.phone.number || person.emergencyContact.phone.number === '')) ||
      (person.emergencyContact && (!person.emergencyContact.name || person.emergencyContact.name === ''));
    return !invalid;
  };

  render() {
    const { onClose, showModal, person } = this.props;
    const { errors, places } = this.state;
    return (
      <ModalContainer className="" modalClose={() => onClose()} active={showModal}>
        <ModalPersonalStyle>
          <div className="create-user-title">
            <SubheadDestacadoText>Información personal</SubheadDestacadoText>
          </div>
          <div className="create-user-text">
            <BodyText>
              Edite los datos personales del buceador y contacto de emergencias para que pueda estar localizado en todo
              momento
            </BodyText>
          </div>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <FormGrid>
                <div className="create-user-form-title">
                  <BodyDestacadoText>Información personal</BodyDestacadoText>
                </div>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      placeholder="Nombre"
                      className="rounded-title"
                      labelText="Nombre"
                      required={true}
                      type="text"
                      errorCode={errors.name}
                      value={person ? person.name : ''}
                      onChange={val => this.handleChange('name', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      placeholder="Apellidos"
                      className="rounded-title"
                      labelText="Apellidos"
                      required={true}
                      type="text"
                      errorCode={errors.surname}
                      value={person ? person.surname : ''}
                      onChange={val => this.handleChange('surname', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.nif}
                      placeholder="DNI"
                      className="rounded-title"
                      labelText="DNI"
                      required={true}
                      type="text"
                      value={person ? person.nif : ''}
                      onChange={val => this.handleChange('nif', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <CalendarBox
                      placeholder="Fecha de nacimiento"
                      className="rounded-title"
                      labelText="Fecha de nacimiento"
                      required={true}
                      errorCode={errors.birthday}
                      initialValue={person ? person.birthday : ''}
                      onChange={val => {
                        this.handleChange('birthday', val, false);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText="Sexo"
                      placeholder="Sexo"
                      className="rounded-title"
                      required={true}
                      optionsText={GENDER_DROPDOWN}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={person ? person.gender : ''}
                      initialValue={person ? person.gender : ''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={val => this.handleChange('gender', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <PhoneBox
                      placeholder="Teléfono"
                      labelText="Teléfono"
                      required={true}
                      className="rounded-title"
                      errorNumber={
                        person.phone && person.phone.prefix && (!person.phone || person.phone.number === '')
                          ? 'invalid-format'
                          : undefined
                      }
                      defaultPrefix={person && person.phone ? person.phone.prefix : ''}
                      defaultNumber={person && person.phone ? person.phone.number : ''}
                      filledPrefix={person && person.phone ? person.phone.prefix : ''}
                      filledNumber={person && person.phone ? person.phone.number : ''}
                      onChange={(field, value) => {
                        if (field === 'phonePrefix') {
                          this.handleChange('phone.prefix', value, false);
                        } else {
                          this.handleChange('phone.number', value, false);
                        }
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      placeholder="Email"
                      labelText="Email"
                      className="rounded-title"
                      type="email"
                      errorCode={errors.email}
                      value={person ? person.email : ''}
                      onChange={val => this.handleChange('email', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      required={true}
                      placeholder="Dirección"
                      className="rounded-title"
                      optionsText={places}
                      errorCode={errors['address']}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={person ? person.address : ''}
                      initialValue={person ? person.address : ''}
                      initialValueFilterText={person ? person.address : ''}
                      defaultValueFilterText={person ? person.address : ''}
                      labelText="Dirección fiscal"
                      onSearch={value => {
                        this.handleSearchAddress(value);
                      }}
                      onChange={async (value, label) => {
                        const place: any = places.filter(place => place.value === value)[0];
                        await this.handleChange('address', label || '', true);
                        await this.handleChange('state', place.level1.longName, true);
                        await this.handleChange('town', place.town.longName, true);
                        await this.handleChange('country', place.country.shortName, true);
                        await this.handleChange('postalCode', place.postalCode.longName, true);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      placeholder="Localidad"
                      className="rounded-title"
                      required={true}
                      type="text"
                      disabled={true}
                      errorCode={errors.town}
                      value={person ? person.town : ''}
                      labelText="Localidad"
                      onChange={val => this.handleChange('town', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      placeholder="Provincia"
                      className="rounded-title"
                      type="text"
                      disabled={true}
                      errorCode={errors.state}
                      value={person ? person.state : ''}
                      labelText="Provincia"
                      onChange={val => this.handleChange('state', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      placeholder="Código Postal"
                      className="rounded-title"
                      required={true}
                      type="text"
                      disabled={true}
                      errorCode={errors.postalCode}
                      value={person ? person.postalCode : ''}
                      labelText="Código Postal"
                      onChange={val => this.handleChange('postalCode', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      className="rounded-title"
                      labelText="País"
                      placeholder="Seleccione"
                      required={true}
                      disabled={true}
                      initialValue={person ? person.country : ''}
                      optionsText={COUNTRIES}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={person ? person.country : ''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={val => this.handleChange('country', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>

          <div className="create-user-form">
            <div className="create-user-form__content" key={`${person ? person.id : ''}-contactuser`}>
              <FormGrid>
                <div className="create-user-form-title">
                  <BodyDestacadoText>CONTACTO PARA EMERGENCIAS</BodyDestacadoText>
                </div>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      key={`${person ? person.id : ''}-contactuser-name`}
                      placeholder="Nombre"
                      className="rounded-title"
                      required={true}
                      type="text"
                      errorCode={
                        person.emergencyContact &&
                        (!person.emergencyContact.name || person.emergencyContact.name === '')
                          ? 'required'
                          : undefined
                      }
                      value={person && person.emergencyContact ? person.emergencyContact.name : ''}
                      labelText="Nombre"
                      onChange={val => this.handleChange('emergencyContact.name', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <PhoneBox
                      key={`${person ? person.id : ''}-contactuser-phone`}
                      placeholder="Teléfono"
                      className="rounded-title"
                      labelText="Teléfono"
                      required={true}
                      // errorNumber={errors['emergencyContact.phone.number']}
                      errorNumber={
                        person.emergencyContact &&
                        person.emergencyContact.phone &&
                        (!person.emergencyContact.phone.number || person.emergencyContact.phone.number === '')
                          ? 'invalid-format'
                          : undefined
                      }
                      filledPrefix={
                        person && person.emergencyContact && person.emergencyContact.phone
                          ? person.emergencyContact.phone.prefix
                          : ''
                      }
                      filledNumber={
                        person && person.emergencyContact && person.emergencyContact.phone
                          ? person.emergencyContact.phone.number
                          : ''
                      }
                      onChange={(field, value) => {
                        if (field === 'phonePrefix') {
                          this.handleChange('emergencyContact.phone.prefix', value, false);
                        } else {
                          this.handleChange('emergencyContact.phone.number', value, false);
                        }
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      key={`${person ? person.id : ''}-contactuser-email`}
                      placeholder="Email"
                      className="rounded-title"
                      type="email"
                      required={true}
                      errorCode={errors['emergencyContact.email']}
                      value={person && person.emergencyContact ? person.emergencyContact.email : ''}
                      labelText="Email"
                      onChange={val => this.handleChange('emergencyContact.email', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <FormGrid>
                <div className="create-user-form-title">
                  <BodyDestacadoText>Redes sociales</BodyDestacadoText>
                </div>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      placeholder="Facebook"
                      className="rounded-title"
                      errorCode={errors.facebook}
                      type="text"
                      required={true}
                      value={person ? person.facebook : ''}
                      labelText="Facebook"
                      onChange={val => this.handleChange('facebook', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      placeholder="Instagram"
                      className="rounded-title"
                      errorCode={errors.instagram}
                      type="text"
                      required={true}
                      value={person ? person.instagram : ''}
                      labelText="Instagram"
                      onChange={val => this.handleChange('instagram', val, false)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>

          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton
                disabled={!this.validForm()}
                type="primary"
                onClick={() => {
                  if (!this.validForm()) {
                    return;
                  }
                  this.handleSaveChanges();
                }}
                text="Guardar Cambios"
              />
            </div>
          </div>
        </ModalPersonalStyle>
      </ModalContainer>
    );
  }
}
