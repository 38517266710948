import styled, { css } from 'styled-components';

export const TextDestacadoTextContainer = styled.p`
  font-family: 'plain';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  ${props => css`
    cursor: ${props.cursor};
  `}
  ${props => css`
    color: ${props.color};
  `}
`;
