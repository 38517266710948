import styled from 'styled-components';
import { colors } from '../../assets/colors';
export const RowNotificationsStyle = styled.div`
  .row-notification-container {
    display: flex;
    align-items: center;
    min-height: 60px;
    &__check {
      padding: 22px 24px 22px 28px;
    }
    &__text {
      display: flex;
      padding: 0 28px;
      align-items: center;
      flex: auto;
      &__icon {
        img {
          width: 22px;
          height: 22px;
        }
        margin-right: 12px;
      }
      &__title {
      }
    }
    &__type {
      font-size: 10px;
      line-height: 14px;
      margin-right: 40px;
      background: ${colors['CO-004/15']};
      border-radius: 3px;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 12px;
      color: ${colors['CO-004/100']};
    }
    &__date {
      margin-right: 40px;
      min-width: 130px;
      text-align: right;
    }
    &__status {
      padding: 0 12px;
      cursor: pointer;
      img  {
        width: 32px;
        height: 32px;
      }
    }
    &__fav {
      margin-right: 12px;
      padding: 0 12px;
      cursor: pointer;
      img  {
        width: 32px;
        height: 32px;
      }
    }
    &__detail {
    }
  }
`;
