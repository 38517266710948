import * as React from 'react';
import { ConfigurationTemplateStyle } from './configuration-template-style';
import ItemMailTemplate from '../../../components/item-mail-template/item-mail-template';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection } from '../../main-layout-style';
import { getConfiguration } from '../../../services/configuration';
import { ITemplateEmail } from '../../../models/template-emails';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
export interface IConfigurationTemplateProps extends WithTranslation { }

export interface IConfigurationTemplateState {
  emails?: {
    confirmAccount: ITemplateEmail;
    newCompany: ITemplateEmail;
    newCertifier: ITemplateEmail;
    newStaff: ITemplateEmail;
    newDiver: ITemplateEmail;
    newManager: ITemplateEmail;
    newOrganization: ITemplateEmail;
    newAdmin: ITemplateEmail;
    resetPassword: ITemplateEmail;
    changedPassword: ITemplateEmail;
    cancelDive: ITemplateEmail;
    modifiedDive: ITemplateEmail;
    confirmActivity: ITemplateEmail;
    cancelActivityStaff: ITemplateEmail;
    cancelActivityDiver: ITemplateEmail;
    confirmBooking: ITemplateEmail;
    requestBooking: ITemplateEmail;
    rejectBooking: ITemplateEmail;
    cancelBooking: ITemplateEmail;
    validateCentre: ITemplateEmail;
    requestInfoCentre: ITemplateEmail;
  };
}

class ConfigurationTemplate extends React.Component<IConfigurationTemplateProps, IConfigurationTemplateState> {
  constructor(props: IConfigurationTemplateProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getConfigurationEmails();
  }

  async getConfigurationEmails() {
    const emails: any = (await getConfiguration()).data.emails;
    this.setState({ emails });
  }

  render() {
    const { emails } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderMain title={t('layouts:config.templates.header')} />
        <MainLayoutSection>
          {emails && (
            <ConfigurationTemplateStyle>
              <ItemMailTemplate
                number="01"
                title="Plantilla email confirmación registro de una cuenta"
                emailLanguages={emails.confirmAccount}
                templateName={'confirmAccount'}
              />
              <ItemMailTemplate
                number="02"
                title="Plantilla email confirmación registro de una empresa de buceo"
                emailLanguages={emails.newCompany}
                templateName={'newCompany'}
              />
              <ItemMailTemplate
                number="03"
                title="Plantilla email invitación nueva Certificadora"
                emailLanguages={emails.newCertifier}
                templateName={'newCertifier'}
              />
              <ItemMailTemplate
                number="04"
                title="Plantilla email invitación nuevo Staff"
                emailLanguages={emails.newStaff}
                templateName={'newStaff'}
              />
              <ItemMailTemplate
                number="05"
                title="Plantilla email invitación nuevo Diver"
                emailLanguages={emails.newDiver}
                templateName={'newDiver'}
              />
              <ItemMailTemplate
                number="06"
                title="Plantilla email invitación nuevo Manager"
                emailLanguages={emails.newManager}
                templateName={'newManager'}
              />
              <ItemMailTemplate
                number="07"
                title="Plantilla email invitación nueva Organización de conservación marina"
                emailLanguages={emails.newOrganization}
                templateName={'newOrganization'}
              />
              <ItemMailTemplate
                number="08"
                title="Plantilla email invitación nuevo admin"
                emailLanguages={emails.newAdmin}
                templateName={'newAdmin'}
              />
              <ItemMailTemplate
                number="09"
                title="Plantilla email cambiar contraseña de acceso"
                emailLanguages={emails.resetPassword}
                templateName={'resetPassword'}
              />
              <ItemMailTemplate
                number="10"
                title="Plantilla email cambiar contraseña de acceso"
                emailLanguages={emails.changedPassword}
                templateName={'changedPassword'}
              />
              <ItemMailTemplate
                number="11"
                title="Plantilla email cancelación de una inmersión planificada"
                emailLanguages={emails.cancelDive}
                templateName={'cancelDive'}
              />
              <ItemMailTemplate
                number="12"
                title="Plantilla email modificación de una inmersión planificada"
                emailLanguages={emails.modifiedDive}
                templateName={'modifiedDive'}
              />
              <ItemMailTemplate
                number="13"
                title="Plantilla email confirmación actividad"
                emailLanguages={emails.confirmActivity}
                templateName={'confirmActivity'}
              />
              <ItemMailTemplate
                number="14"
                title="Plantilla email cancelación actividad para el Stafff"
                emailLanguages={emails.cancelActivityStaff}
                templateName={'cancelActivityStaff'}
              />
              <ItemMailTemplate
                number="15"
                title="Plantilla email cancelación actividad para los buceadores"
                emailLanguages={emails.cancelActivityDiver}
                templateName={'cancelActivityDiver'}
              />
              <ItemMailTemplate
                number="16"
                title="Plantilla email confirmación de una reserva"
                emailLanguages={emails.confirmBooking}
                templateName={'confirmBooking'}
              />
              <ItemMailTemplate
                number="17"
                title="Plantilla email solicitud de una reserva"
                emailLanguages={emails.requestBooking}
                templateName={'requestBooking'}
              />
              <ItemMailTemplate
                number="18"
                title="Plantilla email rechazar una reserva"
                emailLanguages={emails.rejectBooking}
                templateName={'rejectBooking'}
              />
              <ItemMailTemplate
                number="19"
                title="Plantilla email cancelación de una reserva"
                emailLanguages={emails.cancelBooking}
                templateName={'cancelBooking'}
              />
            </ConfigurationTemplateStyle>
          )}
        </MainLayoutSection>
      </>
    );
  }
}
export default withTranslation(['intro', 'buttons'])(ConfigurationTemplate);
