import * as React from 'react';
import { MainLayoutSection } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import { DiveLevelsStyle } from './dive-levels-style';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import BodyText from '../../../components/text/body-text/body-text';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import ModalManageDiveLevels from './modal-manage-dive-levels/modal-manage-dive-levels';
import { images } from '../../../assets/images';
import { getLevels } from '../../../services/levels';
import services from '../../../services/services';
import ModalInfo from '../../../components/modals/modal-info/modal-info';
export interface IDiveLevelsProps {}

export interface IDiveLevelsState {
  renderModal: string;
  levels: any[];
  accept: Function;
}

export default class DiveLevels extends React.Component<IDiveLevelsProps, IDiveLevelsState> {
  constructor(props: IDiveLevelsProps) {
    super(props);

    this.state = {
      renderModal: '',
      levels: [],
      accept: () => {},
    };
  }

  componentDidMount() {
    this.getLevels();
  }

  getLevels = async () => {
    try {
      const params = {
        filter: {
          orderBy: [{ key: 'level', direction: 'ASC' }],
        },
      };
      const res: any = await getLevels(params);
      this.setState({ levels: res.data.data });
    } catch (err) {}
  };

  render() {
    const { renderModal, levels, accept } = this.state;
    return (
      <>
        <HeaderMain
          title={'Niveles de buceo'}
          mainButton={{
            text: 'Administrar',
            onClick: () => this.setState({ renderModal: 'modal-manage-dive-levels' }),
          }}
        />
        <MainLayoutSection>
          <DiveLevelsStyle>
            <div className="dive-levels-text">
              <SubtitleText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laboru.
              </SubtitleText>

              <SubtitleText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur.
              </SubtitleText>
            </div>
            <div className="dive-levels-table">
              <table>
                <thead>
                  <tr>
                    <th>
                      <BodyDestacadoText>NOMBRE DEL NIVEL</BodyDestacadoText>
                    </th>
                    <th>
                      <BodyDestacadoText>ESCALA</BodyDestacadoText>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {levels.map(item => (
                    <tr key={`level-${item.id}`}>
                      <td>
                        <BodyDestacadoText>{item.name}</BodyDestacadoText>
                      </td>
                      <td>
                        <BodyText>{`0${item.level}`}</BodyText>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </DiveLevelsStyle>
        </MainLayoutSection>

        {(renderModal === 'modal-manage-dive-levels' || renderModal === 'modal-info') && (
          <ModalManageDiveLevels
            showModal={renderModal === 'modal-manage-dive-levels'}
            onCancel={() => this.setState({ renderModal: '' })}
            onAccept={(accept: Function) => this.setState({ accept, renderModal: 'modal-info' })}
            levels={levels}
          />
        )}

        {renderModal === 'modal-info' && (
          <ModalInfo
            type="blue"
            icon={images.checkAllGreenSvg}
            title={['Confirmar edición']}
            onClose={() => this.setState({ renderModal: 'modal-manage-dive-levels' })}
            text={[
              `Al guardar los cambios realizados sobre niveles,
              se aplicarán en toda la plataforma.
              Si elimina algún nivel añadido posteriormente los registros vinculados
              se relacionarán con un nivel predefinido en la escala equivalente.`,
            ]}
            showModal={renderModal === 'modal-info'}
            buttons={[
              {
                text: 'Aceptar',
                type: 'primary',
                onClick: async () => {
                  try {
                    await accept();
                    this.setState({ renderModal: '' }, () => {
                      this.getLevels();
                      services.showModalSuccess('Genial!', 'Se ha guardado correctamente');
                    });
                  } catch (err) {
                    this.setState({ renderModal: 'modal-manage-dive-levels' });
                  }
                },
              },
              {
                text: 'Cancelar',
                type: 'secondary-red',
                onClick: () => this.setState({ renderModal: 'modal-manage-dive-levels' }),
              },
            ]}
          />
        )}
      </>
    );
  }
}
