import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const NotFoundContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  font-family: 'plain';
  padding: 0 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: ${colors['NE-003/30']};
  .not-found-content  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }

  .not-found-arrow {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 0px;

    &__item {
      display: flex;
      cursor: pointer;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;

      img {
      }
    }
  }

  .not-found-img {
    width: 800px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 0;
  }

  .not-found-item {
    max-width: 500px;
    margin-right: 20px;
    width: 450px;
    z-index: 1;

    &__title {
      font-size: 120px;
      line-height: 180px;
      margin-bottom: 20px;
      font-weight: 500;
      color: ${colors['CO-004/100']};
    }
    &__subtitle  {
      margin-bottom: 20px;
      color: ${colors['NE-002/0']};
    }

    &__content {
      margin-bottom: 60px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      width: 361px;
      color: ${colors['NE-002/0']};
    }

    &__return {
      max-width: 240px;
      width: 120px;
    }
  }
`;
