import * as React from 'react';
import { PlanningPreferencesStyle } from './planning-preferences-style';
import BodyText from '../../../../components/text/body-text/body-text';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import { images } from '../../../../assets/images';
import DicotomicCheckbox from '../../../../components/dicotomic-checkbox';
import InfoText from '../../../../components/text/info-text/info-text';
import CalendarBox from '../../../../components/calendar-box';
import moment from 'moment';
import SingleButton from '../../../../components/single-button/single-button';
import MainButton from '../../../../components/main-button';
import SelectBox from '../../../../components/select-box';
import { BOOK_BEFORE_DROPDOWN } from '../../../../constants/activities-book-before';
import { PlanningDateState } from '../planning-date/planning-date';
import { differenceInCalendarDays } from 'date-fns';

interface PlanningPreferencesProps {
  state?: PlanningPreferencesState;
  date?: PlanningDateState;
  isDive?: boolean;
  onPrev: () => void;
  onNext: (state: PlanningPreferencesState, bookBefore: string, inmediateBooking: boolean) => void;
}

export interface PlanningPreferencesState {
  bookBefore: string;
  inmediateBooking: boolean;
  errors: string;
}

class PlanningPreferences extends React.Component<PlanningPreferencesProps, PlanningPreferencesState> {
  constructor(props: PlanningPreferencesProps) {
    super(props);
    this.state = {
      bookBefore: '',
      inmediateBooking: true,
      errors: '',
    };
  }

  componentDidMount() {
    const { state } = this.props;
    if (state) {
      this.setState(state);
    }
  }

  handleNext() {
    const { onNext } = this.props;
    const { bookBefore, inmediateBooking } = this.state;
    if (!bookBefore || bookBefore === '') {
      this.setState({ errors: 'required' });
    } else {
      this.setState({ errors: '' });
      onNext(this.state, bookBefore, inmediateBooking);
    }
  }

  render() {
    const { onPrev, date, isDive } = this.props;
    const { bookBefore, inmediateBooking, errors } = this.state;

    const today = new Date();
    let dateFirst: any = '';
    let compare = false;
    if (date && (date.dates || date.startDate)) {
      if (date.startDate) {
        dateFirst = date.startDate;
      }
      if (date.dates && date.dates.length) {
        dateFirst = date.dates.sort(
          (a: string | Date, b: string | Date) => new Date(a).getTime() - new Date(b).getTime()
        )[0];
      }
      compare = differenceInCalendarDays(new Date(dateFirst), today) < 7;
    }
    return (
      <PlanningPreferencesStyle>
        <div className="planning-preferences-subtitle">
          <BodyDestacadoText>Tiempo de antelación</BodyDestacadoText>
        </div>
        <div className="planning-preferences-text">
          <BodyText>Los buceadores podrán ver esta actividad en el buscador y realizar una reserva hasta</BodyText>
        </div>
        <div className="planning-preferences-period">
          <SelectBox
            className={'rounded'}
            optionsText={BOOK_BEFORE_DROPDOWN.filter((item: any) => (compare ? item.value !== 'week' : true))}
            optionKey={'value'}
            optionValue={'label'}
            labelText={''}
            initialValue={bookBefore}
            defaultValue={bookBefore}
            errorCode={errors}
            onChange={value => this.setState({ bookBefore: value })}
          />
        </div>
        {/* <div className="planning-preferences-subtitle">
          <BodyDestacadoText>Preferencias en la confirmación de las reservas</BodyDestacadoText>
        </div>
        <div className="planning-preferences-ckeck">
          <div className="planning-preferences-ckeck__item">
            <DicotomicCheckbox
              className="rounded"
              checked={inmediateBooking}
              onCheck={() => this.setState({ inmediateBooking: true })}
            >
              <InfoText>
                {`Automática (las reservas que soliciten los buceadores se confirmarán de forma automática)${
                  isDive
                    ? ' - *Las actividades que se confirmen en automático tienen más visibilidad en el buscador'
                    : ''
                }.`}
              </InfoText>
            </DicotomicCheckbox>
          </div>
          <div className="planning-preferences-ckeck__item">
            <DicotomicCheckbox
              className="rounded"
              checked={!inmediateBooking}
              onCheck={() => this.setState({ inmediateBooking: false })}
            >
              <InfoText>
                {`Manual (Te llegará una solicitud de reserva y la tendrás que aceptar para que se confirme)${
                  isDive ? ` - *Tendrás 24h para confirmarla o se interpretará como rechazada` : ''
                }.`}
              </InfoText>
            </DicotomicCheckbox>
          </div>
        </div> */}
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            <div className="modal-create-activities-buttons-left__back">
              <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
            </div>
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={'Planificar y publicar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </PlanningPreferencesStyle>
    );
  }
}

export default PlanningPreferences;
