import * as React from 'react';
import { RowNotificationsStyle } from './row-notifications-style';
import { images } from '../../assets/images';
import BodyText from '../text/body-text/body-text';
import DicotomicCheckbox from '../dicotomic-checkbox';
import { getDateMonthString } from '../../utils/time';
import { uploadNotifications, readNotifications, favNotifications } from '../../services/notifications';
import { withRouter, RouteComponentProps } from 'react-router';
import SingleButton from '../single-button/single-button';

export interface IRowNotificationsProps extends RouteComponentProps {
  notification: any;
  onSelected: Function;
  reloadNotifications: Function;
  notificationSelected: boolean;
  centreId?: string;
}

export interface IRowNotificationsState {}

class RowNotifications extends React.Component<IRowNotificationsProps, IRowNotificationsState> {
  constructor(props: IRowNotificationsProps) {
    super(props);
  }

  handleFav = async () => {
    const { notification, reloadNotifications } = this.props;
    try {
      await favNotifications(notification.id);
      reloadNotifications();
    } catch (err) {
      console.log(err);
    }
  };

  handleRead = async (callback?: any) => {
    const { notification, reloadNotifications } = this.props;
    try {
      await readNotifications(notification.id);
      await reloadNotifications();
      callback && callback();
    } catch (err) {
      console.log(err);
    }
  };

  navigateToDetail = () => {
    const { centreId, notification } = this.props;
    this.props.history.push(
      centreId
        ? `/centres/${centreId}/notifications/${notification.id}`
        : `/notifications/detail/${notification.id}`
    );
  };

  render() {
    const { notification, notificationSelected, onSelected, centreId } = this.props;

    return (
      <RowNotificationsStyle>
        <div className="row-notification-container">
          <div className="row-notification-container__check">
            <DicotomicCheckbox
              className="table"
              name="name"
              checked={notificationSelected}
              onCheck={() => onSelected(!notificationSelected, notification.id)}
            />
          </div>
          <div className="row-notification-container__text">
            <div className="row-notification-container__text__icon">
              <img src={images.installationsNotifications} alt="" />
            </div>
            <div className="row-notification-container__text__title">
              <BodyText>{notification.title}</BodyText>
            </div>
          </div>
          {/*<div className="row-notification-container__type">
            <p>{notification.type || 'Tipo de notificación'}</p>
          </div>*/}
          <div className="row-notification-container__date">
            <BodyText>{getDateMonthString(notification.createdAt)}</BodyText>
          </div>
          <div className="row-notification-container__status" onClick={() => this.handleRead()}>
            <img src={notification.read ? images.mailOpenBlueSvg : images.mailSvg} alt="" />
          </div>
          <div className="row-notification-container__fav" onClick={() => this.handleFav()}>
            <img src={notification.fav ? images.starFullRedSvg : images.starRedSvg} alt="" />
          </div>
          <div className="row-notification-container__detail">
            <SingleButton
              icon={images.eyeSvg}
              onClick={() => {
                if (notification.read) {
                  this.navigateToDetail();
                } else {
                  this.handleRead(this.navigateToDetail);
                }
              }}
              className="table"
            />
          </div>
        </div>
      </RowNotificationsStyle>
    );
  }
}

export default withRouter(RowNotifications);
