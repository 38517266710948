import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import ModalContainer from '../../../../../components/modals/modal-container';
import { images } from '../../../../../assets/images';
import Button from '../../../../../components/buttons/main-button/button';
import { ModalSuperAdminCreateBillStyle } from './modal-superadmin-create-bill-style';
import services from '../../../../../services/services';
import { getCentres } from '../../../../../services/centre';
import { MainTableV2 } from '../../../../../components/main-table-v2/main-table-layout-v2';
import { getBillLinesTableColumns } from '../../../data/superadmin-bills-data';
import { MainLayoutTableContainer } from '../../../../main-layout-style';
import TextArea from '../../../../../components/form-components/input/textarea';
import { IFormGenerator } from '../../../../../models/form-generator';
import { TFunction } from 'i18next';
import { updateObject, transformEuNum, transformToEuNum } from '../../../../../utils/utility';
import { getDayMonthNumberYear, getDateFormatted, transformDateCalendar } from '../../../../../utils/time';

import FormGenerator from '../../../../../components/form-generator/form-generator';

import InputBox from '../../../../../components/input-box';

interface IModalSuperAdminCreateBillProps {
  showModal: boolean;
  onClose: () => void;
  onSubmit: (centreId: string, data: any) => void;
}

interface BillLine {
  concept: string;
  quantity: number;
  unit: string;
  price: string;
  amount: string;
  iva?: number;
}

const getForm1 = (t: TFunction, dynamic?: { [key: string]: any }) => {
  return {
    concept: {
      elementType: 'input',
      validation: {
        valid: false,
        error: false,
      },
      touched: false,
      value: '',
      validationRules: {
        required: true,
      },
      containerClassNames: ['superadmin-create-bill-full-length', 'MB-26'],
      elementConfig: {
        classNames: ['rounded-title'],
        label: t('modalMessages:modal-superadmin-create-bill.step-1.bill-concept.label'),
        placeholder: t('modalMessages:modal-superadmin-create-bill.step-1.bill-concept.placeholder'),
        required: true,
      },
    },
    company: {
      elementType: 'select',
      validation: {
        valid: false,
        error: false,
      },
      touched: false,
      value: '',
      validationRules: {
        required: true,
      },
      containerClassNames: ['MB-26'],
      elementConfig: {
        selectProps: {
          optionsText: dynamic ? dynamic.company.optionsText : [],
          searchable: true,
          onSelectSearch: (value: string, dynamic: any) => {
            dynamic['handleSelectSearch'](value);
          },
          searchValue: '',
        },
        classNames: ['rounded-title '],
        label: t('modalMessages:modal-superadmin-create-bill.step-1.company.label'),
        placeholder: t('modalMessages:modal-superadmin-create-bill.step-1.company.placeholder'),
        required: true,
        onChangeFunctionKey: 'onCentreChangeHandler',
      },
    },
    vat: {
      elementType: 'input',
      validation: {
        valid: true,
        error: false,
      },
      touched: false,
      value: '',
      validationRules: {},
      elementConfig: {
        classNames: ['rounded-title'],
        label: t('modalMessages:modal-superadmin-create-bill.step-1.vat.label'),
        placeholder: t('modalMessages:modal-superadmin-create-bill.step-1.vat.placeholder'),
        required: true,
        isDisabled: () => true,
      },
    },
    dateOfIssue: {
      elementType: 'calendar',
      validation: {
        valid: true,
        error: false,
      },
      touched: false,
      value: moment().format('DD/MM/YYYY'),
      validationRules: {
        required: true,
      },
      containerClassNames: ['MB-45'],
      elementConfig: {
        classNames: ['rounded-title'],
        label: t('modalMessages:modal-superadmin-create-bill.step-1.date-of-issue.label'),
        placeholder: t('modalMessages:modal-superadmin-create-bill.step-1.date-of-issue.placeholder'),
        required: true,
        isDisabled: () => true,
        calendarBoxProps: {
          disableWrite: true,
        },
      },
    },
    expirationDate: {
      elementType: 'calendar',
      validation: {
        valid: false,
        error: false,
      },
      touched: false,
      value: '',
      validationRules: {
        required: true,
      },
      elementConfig: {
        classNames: ['rounded-title'],
        label: t('modalMessages:modal-superadmin-create-bill.step-1.expiration-date.label'),
        placeholder: t('modalMessages:modal-superadmin-create-bill.step-1.expiration-date.placeholder'),
        required: true,
        // isDisabled: (form: IFormGenerator) => form.paymentCondition.value !== 'deferred',
        isDisabled: (form: IFormGenerator) => false,
        calendarBoxProps: {
          forbidPastDates: true,
          disableWrite: true,
        },
      },
    },
    paymentCondition: {
      elementType: 'checkbox',
      validation: {
        valid: true,
        error: false,
      },
      touched: false,
      value: 'instant',
      validationRules: {},
      containerClassNames: ['superadmin-create-bill-full-length'],
      elementConfig: {
        onChangeFunctionKey: 'paymentConditionHandler',
        checkboxProps: {
          options: [
            {
              label: t('modalMessages:modal-superadmin-create-bill.step-1.payment-type.immediately'),
              classNames: ['rounded'],
              value: 'instant',
            },
            {
              label: t('modalMessages:modal-superadmin-create-bill.step-1.payment-type.postponed'),
              value: 'deferred',
              classNames: ['rounded'],
            },
          ],
        },
        label: t('modalMessages:modal-superadmin-create-bill.step-1.payment-type.label'),
      },
    },
  };
};

const getForm2 = (t: TFunction) => {
  return {
    concept: {
      elementType: 'input',
      validation: {
        valid: false,
        error: false,
      },
      touched: false,
      value: '',
      validationRules: {
        required: true,
      },
      containerClassNames: ['superadmin-create-bill-full-length', 'MB-26'],
      elementConfig: {
        classNames: ['rounded-title'],
        label: t('modalMessages:modal-superadmin-create-bill.step-2.concept.label'),
        placeholder: t('modalMessages:modal-superadmin-create-bill.step-2.concept.placeholder'),
        required: true,
      },
    },
    quantity: {
      elementType: 'input',
      validation: {
        valid: false,
        error: false,
      },
      touched: false,
      value: '',
      validationRules: {
        required: true,
      },
      containerClassNames: ['MB-26'],
      elementConfig: {
        inputProps: {
          cleaveOptions: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralDecimalMark: ',',
            numeralDecimalScale: 0,
            delimiter: '.',
          },
        },
        classNames: ['rounded-title'],
        label: t('modalMessages:modal-superadmin-create-bill.step-2.quantity.label'),
        placeholder: t('modalMessages:modal-superadmin-create-bill.step-2.quantity.placeholder'),
        required: true,
      },
    },
    unit: {
      elementType: 'input',
      validation: {
        valid: false,
        error: false,
      },
      touched: false,
      value: '',
      validationRules: {
        required: true,
      },
      elementConfig: {
        classNames: ['rounded-title'],
        label: t('modalMessages:modal-superadmin-create-bill.step-2.unit.label'),
        placeholder: t('modalMessages:modal-superadmin-create-bill.step-2.unit.placeholder'),
        required: true,
      },
    },
    price: {
      elementType: 'input',
      validation: {
        valid: false,
        error: false,
      },
      touched: false,
      value: '',
      validationRules: {
        required: true,
      },
      containerClassNames: ['MB-20'],
      elementConfig: {
        inputProps: {
          cleaveOptions: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralDecimalMark: ',',
            delimiter: '.',
          },
        },
        classNames: ['rounded-title'],
        label: t('modalMessages:modal-superadmin-create-bill.step-2.price.label'),
        placeholder: t('modalMessages:modal-superadmin-create-bill.step-2.price.placeholder'),
        required: true,
      },
    },
  };
};

const ModalSuperAdminCreateBill: React.FC<IModalSuperAdminCreateBillProps> = ({ showModal, onClose, onSubmit }) => {
  const { t } = useTranslation(['modalMessages', 'layouts', 'buttons', 'push', 'tables']);
  const [step, setStep] = useState<string>('step-1');
  const [centreList, setCentreList] = useState([]);
  const [selectedCentre, setSelectedCentre] = useState<any>(null);
  const [billLines, setBillLines] = useState<BillLine[]>([]);
  const [form1, setForm1] = useState<any>(null);
  const [form2, setForm2] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>(null);
  const [form1Valid, setForm1Valid] = useState<boolean>(false);
  const [form2Valid, setForm2Valid] = useState<boolean>(false);
  const [searchTimeout, setSearchTimeout] = useState<any>(null);
  const [observations, setObservations] = useState<string>('');
  const [total, setTotal] = useState<any>(null);

  useEffect(() => {
    onInit();
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, []);

  const resetState = () => {
    setStep('step-1');
    setForm1(initialValues.form1);
    setForm2(initialValues.form2);
    setForm1Valid(false);
    setForm2Valid(false);
    setBillLines([]);
    setObservations('');
  };

  const onModalCloseHandler = () => {
    onClose();
    resetState();
  };

  const calculateTotal = () => {
    const totalWithoutIva = billLines.reduce((ac, cv) => ac + +transformEuNum(cv.amount), 0);
    const iva = selectedCentre && selectedCentre.iva ? totalWithoutIva * (selectedCentre.iva / 100) : 0;
    const total = transformToEuNum((totalWithoutIva + iva).toFixed(2));
    setTotal({ total, iva });
  };

  const onSubmitHandler = () => {
    const { concept, expirationDate, paymentCondition, company } = form1;

    const charges = billLines.map(line => ({
      ...line,
      quantity: line.quantity.toString(),
      price: line.price.toString(),
    }));
    const data = {
      charges,
      iva: total.iva,
      observation: observations,
      billingType: 'generated',
      concept: concept.value,
      total: total.total.toString(),
      // expirationDate: expirationDate.value !== '' ? getDateFormatted(expirationDate.value, 'dd/mm/yyyy') : null,
      expirationDate: expirationDate.value !== '' ? transformDateCalendar(expirationDate.value) : null,
      paymentCondition: paymentCondition.value,
      paymentStatus: 'prepaid',
      status: 'completed',
    };

    onSubmit(company.value, data);
    onModalCloseHandler();
  };

  const onInit = async () => {
    const centres = await searchCentre();
    const centreSelectOptions = centres.map((centre: any) => ({ label: centre.name, value: centre.id.toString() }));
    const form1 = getForm1(t, { company: { optionsText: centreSelectOptions } });
    const form2 = getForm2(t);
    setForm1(form1);
    setForm2(form2);
    setInitialValues({ form1, form2 });
  };

  const onCentreChangeHandler = (form: IFormGenerator): IFormGenerator => {
    let newForm = { ...form };
    const centre: any = centreList.find((centre: any) => centre.id === +newForm.company.value);
    if (centre) {
      setSelectedCentre(centre);
      const newVat = updateObject(newForm.vat, { value: `${centre.iva}%`, validation: { valid: true, error: false } });
      // const newDateOfIssue = updateObject(newForm.dateOfIssue, {
      //   value: getDayMonthNumberYear(centre.createdAt),
      //   validation: { valid: true, error: false },
      // });
      newForm = updateObject(newForm, { vat: newVat });
    }
    return newForm;
  };

  const paymentConditionHandler = (form: IFormGenerator): IFormGenerator => {
    const newForm = { ...form };
    // let newExpDate;
    // if (form.paymentCondition.value === 'instant') {
    //   newExpDate = updateObject(newForm.expirationDate, { value: '', validation: { valid: true, error: false } });
    //   newForm = updateObject(newForm, { expirationDate: newExpDate });
    // } else if (form.expirationDate.validation.valid) {
    //   newExpDate = updateObject(newForm.expirationDate, { validation: { valid: false, error: false } });
    //   newForm = updateObject(newForm, { expirationDate: newExpDate });
    // }
    return newForm;
  };

  const onAddLineHandler = () => {
    if (!form2Valid) {
      return;
    }
    const line = {
      concept: form2.concept.value,
      quantity: form2.quantity.value,
      unit: form2.unit.value,
      price: `${form2.price.value} €`,
      amount: `${Number(+getAmount()).toLocaleString('de-DE', { minimumFractionDigits: 2 })} €`,
    };
    setBillLines(billLines.concat([line]));
    setForm2(initialValues.form2);
    setForm2Valid(false);
  };

  const getAmount = () => {
    if (!form2) {
      return '';
    }
    const amount = +transformEuNum(form2.quantity.value) * +transformEuNum(form2.price.value);
    return isNaN(amount) ? '0' : amount.toString();
  };

  const stepChangeHandler = (step: string) => {
    setStep(step);
  };

  const handleSelectSearch = (value: string) => {
    const newFormTest = cloneDeep(form1);
    newFormTest.company.elementConfig.selectProps.searchValue = value;
    setForm1(newFormTest);
    // clearTimeout(searchTimeout);
    // setSearchTimeout(
    //   setTimeout(async () => {
    //     const centres = await searchCentre(value, false);
    //     const centreSelectOptions = centres.map((centre: any) => ({ label: centre.name, value: centre.id.toString() }));
    //     const clonedForm = cloneDeep(form1);
    //     clonedForm.company.elementConfig.selectProps.optionsText = centreSelectOptions;
    //     setForm1((prev: any) => ({
    //       ...clonedForm,
    //       company: {
    //         ...clonedForm.company,
    //         elementConfig: {
    //           ...clonedForm.company.elementConfig,
    //           selectProps: {
    //             ...clonedForm.company.elementConfig.selectProps,
    //             searchValue: prev.company.elementConfig.selectProps.searchValue,
    //           },
    //         },
    //       },
    //     }));
    //   }, 300)
    // );
  };

  const searchCentre = async (name: string = '', loader: boolean = true) => {
    const params: any = {
      filter: {
        where: {
          confirmed: {
            value: true,
          },
          hasValidated: {
            value: true,
          },
        },
      },
    };
    const {
      data: { data: centres },
    } = await getCentres(params, loader);
    setCentreList(centres);
    return centres;
  };

  const deleteLineHandler = (index: number) => {
    const filteredLines = billLines.filter((_, i: any) => i !== index);
    setBillLines(filteredLines);
  };

  const editLineHandler = (index: number) => {
    const selectedLine = { ...billLines[index] };
    const clonedForm = cloneDeep(form2);
    clonedForm.price.value = selectedLine.price;
    clonedForm.quantity.value = selectedLine.quantity;
    clonedForm.concept.value = selectedLine.concept;
    clonedForm.unit.value = selectedLine.unit;
    for (const formElementIdentifier in clonedForm) {
      clonedForm[formElementIdentifier].validation.valid = true;
    }
    const filteredLines = billLines.filter((_, i: any) => i !== index);
    setBillLines(filteredLines);
    setForm2(clonedForm);
    setForm2Valid(true);
  };

  let content;

  if (step === 'step-1') {
    let form1Elements;
    if (form1) {
      form1Elements = (
        <FormGenerator
          form={form1}
          setForm={setForm1}
          setFormValid={setForm1Valid}
          dynamic={{ onCentreChangeHandler, handleSelectSearch, paymentConditionHandler }}
        />
      );
    }

    content = (
      <>
        <div className="superadmin-create-bill__modal-title">
          {t('modalMessages:modal-superadmin-create-bill.title')}
        </div>
        <div className="superadmin-create-bill__step-title">
          {t(`modalMessages:modal-superadmin-create-bill.${step}.title`)}
        </div>
        <div className="superadmin-create-bill__intro">
          {t('modalMessages:modal-superadmin-create-bill.step-1.intro')}
        </div>
        {form1Elements}
        <div className="superadmin-create-bill__buttons-row">
          <Button
            disabled={!form1Valid}
            text={t('buttons:next')}
            type="primary"
            onClick={() => {
              stepChangeHandler('step-2');
            }}
          />
        </div>
      </>
    );
  }

  if (step === 'step-2') {
    let form2Elements;
    if (form2) {
      form2Elements = <FormGenerator form={form2} setForm={setForm2} setFormValid={setForm2Valid} />;
    }

    content = (
      <>
        <div className="superadmin-create-bill__modal-title">
          {t('modalMessages:modal-superadmin-create-bill.title')}
        </div>
        <div className="superadmin-create-bill__step-title">
          {t(`modalMessages:modal-superadmin-create-bill.${step}.title`)}
        </div>
        <div className="superadmin-create-bill__intro">
          {t('modalMessages:modal-superadmin-create-bill.step-2.intro')}
        </div>
        <div className="superadmin-create-bill__added-lines-title">
          {t('modalMessages:modal-superadmin-create-bill.step-2.added-lines')}
        </div>
        <div className="superadmin-create-bill__added-lines-table">
          <MainTableV2
            emptyMessage={t('modalMessages:modal-superadmin-create-bill.step-2.empty')}
            totalPages={1}
            columns={getBillLinesTableColumns(editLineHandler, deleteLineHandler, t)}
            data={billLines}
          />
        </div>
        {form2Elements}
        <div className="superadmin-create-bill__amount-box">
          <InputBox
            disabled={true}
            value={`${Number(+getAmount()).toLocaleString('de-DE', { minimumFractionDigits: 2 })} €`}
            onChange={() => {}}
            className="rounded-title"
            required={true}
            labelText={t('modalMessages:modal-superadmin-create-bill.step-2.amount.label')}
            placeholder={t('modalMessages:modal-superadmin-create-bill.step-2.amount.placeholder')}
          />
          <Button
            disabled={!form2Valid}
            className="MB-16"
            leftIcon={images.plusSvg}
            text={t('buttons:add')}
            onClick={onAddLineHandler}
          />
        </div>
        <div className="superadmin-create-bill__buttons-row">
          <Button
            iconSingle={images.arrowLeftSvg}
            onClick={() => {
              stepChangeHandler('step-1');
            }}
          />
          <Button
            disabled={billLines.length <= 0}
            text={t('buttons:next')}
            type="primary"
            onClick={() => {
              calculateTotal();
              stepChangeHandler('step-3');
            }}
          />
        </div>
      </>
    );
  }

  if (step === 'step-3') {
    content = (
      <>
        <div className="superadmin-create-bill__modal-title">
          {t('modalMessages:modal-superadmin-create-bill.title')}
        </div>
        <div className="superadmin-create-bill__step-title">
          {t(`modalMessages:modal-superadmin-create-bill.${step}.title`)}
        </div>
        <div className="superadmin-create-bill__intro">
          {t('modalMessages:modal-superadmin-create-bill.step-3.intro')}
        </div>
        <div className="superadmin-create-bill__textarea">
          <TextArea
            label={t('modalMessages:modal-superadmin-create-bill.step-3.observations.label')}
            placeholder={t('modalMessages:modal-superadmin-create-bill.step-3.observations.placeholder')}
            value={observations}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setObservations(e.target.value);
            }}
          />
        </div>
        <div className="superadmin-create-bill__buttons-row">
          <Button
            iconSingle={images.arrowLeftSvg}
            onClick={() => {
              stepChangeHandler('step-2');
            }}
          />
          <Button
            text={t('buttons:next')}
            type="primary"
            onClick={() => {
              stepChangeHandler('step-4');
            }}
          />
        </div>
      </>
    );
  }

  if (step === 'step-4') {
    const centre: any = centreList.find((centre: any) => centre.id === +form1.company.value);
    const previewValues = [
      { label: t('tables:centre'), value: centre ? centre.name : '' },
      { label: t('tables:total'), value: `${total.total} €` },
      { label: t('tables:date-of-issue'), value: form1.dateOfIssue.value },
      ...[form1.expirationDate.value && { label: t('tables:date-of-expiration'), value: form1.expirationDate.value }],
    ].map(item => (
      <React.Fragment key={item.label + item.value}>
        <div className="superadmin-create-bill__invoice-preview-label">{item.label}</div>
        <div className="superadmin-create-bill__invoice-preview-value">{item.value}</div>
      </React.Fragment>
    ));

    content = (
      <>
        <div className="superadmin-create-bill__invoice-preview">
          <img src={images.fileSvg} className="superadmin-create-bill__file-icon" alt="file icon" />
        </div>
        <div className="superadmin-create-bill__invoice-preview-content-box">
          <div className="superadmin-create-bill__modal-title">
            {t('modalMessages:modal-superadmin-create-bill.title')}
          </div>
          <div className="superadmin-create-bill__step-title">
            {t(`modalMessages:modal-superadmin-create-bill.${step}.title`)}
          </div>

          <div className="superadmin-create-bill__invoice-preview-content">{previewValues}</div>
          <div className="superadmin-create-bill__buttons-row">
            <Button
              iconSingle={images.arrowLeftSvg}
              onClick={() => {
                stepChangeHandler('step-2');
              }}
            />
            <Button
              text={t('buttons:generate-bill')}
              type="primary"
              onClick={() => {
                onSubmitHandler();
              }}
            />
          </div>
        </div>
      </>
    );
  }

  const modalBody = (
    <ModalSuperAdminCreateBillStyle>
      <div className="superadmin-create-bill">{content}</div>
    </ModalSuperAdminCreateBillStyle>
  );

  return <ModalContainer active={showModal} modalClose={onModalCloseHandler} children={modalBody} />;
};

export default ModalSuperAdminCreateBill;
