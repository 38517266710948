import * as React from 'react';
import ModalContainer from '../../../../components/modals/modal-container';
import LabelText from '../../../../components/text/label-text/label';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import MainButton from '../../../../components/main-button';
import { ModalCreateActivitiesStyle } from './modal-create-activities-style';
import CreateActivitiesGeneralData from './create-activities-general-data/create-activities-general-data';
import CreateActivitiesMinimunRequirements from './create-activities-minimum-requirements/create-activities-minimum-requirements';
import CreateActivitiesIncludedServices from './create-activities-included-services/create-activities-included-services';
import CreateActivitiesDiveSites from './create-activities-dive-sites/create-activities-dive-sites';
import CreateActivitiesGallery from './create-activities-gallery/create-activities-gallery';
import CreateActivitiesPrices from './create-activities-prices/create-activities-prices';
import CreateActivitiesDetails from './create-activities-details/create-activities-details';
import CreateActivitiesCourseInfo from './create-activities-course-info/create-activities-course-info';
import CardCheck from '../../../../components/card-check/card-check';
import { AttachedFile } from '../../../../types/file';
import { IMultilang, IId } from '../../../../models/generic';
import CreateActivitiesGeneralDataTraining from './create-activities-general-data-training/create-activities-general-data-training';
import {
  ICourseTemplateInfo,
  IMinimumRequirements,
  IDiveGeneralData,
  ICourseGeneralData,
  IActivityTemplateForm,
  IActivityTemplate,
} from '../../../../models/activity-template';
import {
  postActivityTemplate,
  postActivityTemplatePictures,
  deleteActivityTemplatePictures,
  patchActivityTemplate,
} from '../../../../services/activity-templates';
import services from '../../../../services/services';
import { getIdArray } from '../../../../utils/other';

export interface IModalCreateActivitiesProps {
  centreId: string;
  showModal: boolean;
  onSuccess: (id?: string) => void;
  onCancel: Function;
  activityEdit?: IActivityTemplate;
  newActivityType?: string;
}

export interface IModalCreateActivitiesState {
  step: number;
  activitiesType: string;
  activity: IActivityTemplateForm;
  images?: string;
}

export default class ModalCreateActivities extends React.Component<
  IModalCreateActivitiesProps,
  IModalCreateActivitiesState
> {
  constructor(props: IModalCreateActivitiesProps) {
    super(props);

    this.state = {
      step: 0,
      activitiesType: 'dive',
      activity: {},
      images: '',
    };
  }

  componentDidMount() {
    const { newActivityType } = this.props;
    localStorage.removeItem('arrayImages')
    if (newActivityType) this.setState({ activitiesType: newActivityType, step: 1 });
    else this.setActivityEdit();
  }

  componentDidUpdate(prevProps: IModalCreateActivitiesProps) {
    const { activityEdit } = this.props;
    if (activityEdit && activityEdit !== prevProps.activityEdit) {
      this.setActivityEdit();
    }
  }

  setActivityEdit() {

    const { activityEdit } = this.props;
    const { activity } = Object.assign(this.state);
    if (!activityEdit) return;
    const isCourse = !!activityEdit.courseInfo;
    if (isCourse) {
      activity.courseInfo = activityEdit.courseInfo;
      activity.generalDataCourse = {
        name: activityEdit.name,
        discipline: activityEdit.discipline,
        minDivers: activityEdit.minDivers,
        maxDivers: activityEdit.maxDivers ? activityEdit.maxDivers : undefined,
        category: activityEdit.category,
        certifier: activityEdit.course && activityEdit.course.certifier ? activityEdit.course.certifier.id : NaN,
        course: !!activityEdit.course ? activityEdit.course.id : NaN,
        courseType: activityEdit.course && activityEdit.course.kind ? activityEdit.course.kind : ''
      };

      if (!!activityEdit.course) activity.generalDataCourse.course = activityEdit.course.id;

    } else {
      if (activityEdit.stock) {
        activity.stock = getIdArray(activityEdit.stock);
      }
      activity.generalData = {
        name: activityEdit.name,
        discipline: activityEdit.discipline,
        kind: activityEdit.kind,
        minDivers: activityEdit.minDivers,
        maxDivers: activityEdit.maxDivers ? activityEdit.maxDivers : undefined,
        diveCount: activityEdit.diveCount,
        category: activityEdit.category,
        needBoat: activityEdit.needBoat,
        collaboration: activityEdit.collaboration ? activityEdit.collaboration : undefined,
      };
    }
    activity.minimumRequisites = activityEdit.minimumRequisites;
    if (activityEdit.services) {
      activity.services = getIdArray(activityEdit.services);
    }
    if (activityEdit.sites) {
      activity.diveSites = getIdArray(activityEdit.sites);
    }
    if (activityEdit.meal || activityEdit.transport) {
      activity.otherServices = {
        foodAccommodation: activityEdit.meal ? activityEdit.meal : '',
        departurePoint: activityEdit.transport ? activityEdit.transport.departurePoint : '',
        transport: activityEdit.transport ? activityEdit.transport.transport : '',
      };
    }
    activity.price = activityEdit.price;
    if (activityEdit.cancellationPolicy) {
      activity.cancellationPolicy = activityEdit.cancellationPolicy.id;
    }
    activity.languagesComments = activityEdit.comments;
    activity.languagesDescriptions = activityEdit.descriptions;
    if (activityEdit.exclusiveness) {
      activity.exclusiveness = activityEdit.exclusiveness;
    }
    if (activityEdit.exclusiveStock) {
      activity.exclusiveStock = getIdArray(activityEdit.exclusiveStock);
    }
    if (activityEdit.exclusiveServices) {
      activity.exclusiveServices = getIdArray(activityEdit.exclusiveServices);
    }
    if (activityEdit.pictures) {
      activity.pictures = activityEdit.pictures;
    }
    this.setState({ activity, step: 1, activitiesType: isCourse ? 'course' : 'dive' });

  }

  handlePrev() {
    const { step } = this.state;
    if (step > 0) {
      this.setState({ step: step - 1 });
    }
  }

  handleNext(obj: any) {

    const { step, activity, activitiesType } = Object.assign(this.state);
    Object.entries(obj).forEach((entry: any[]) => {
      const key = entry[0];
      const value = entry[1];
      activity[key] = value;
    });
    //if ((activitiesType === 'dive' && step === 10) || (activitiesType !== 'dive' && step === 9)) {
    if (((activitiesType === 'dive' || activitiesType === 'travel') && step === 7) || ((activitiesType !== 'dive' && activitiesType !== 'travel') && step === 8)) {
      this.handleSubmit();
    } else {
      this.setState({ activity, step: step + 1 });
    }
  }

  async handleSubmit() {
    const { centreId, onSuccess, activityEdit, newActivityType } = this.props;
    const { activity, activitiesType } = this.state;
    const formAct: any = { centre: parseInt(centreId, undefined) };
    if ((activitiesType === 'dive' || activitiesType === 'travel') && activity.generalData) {
      formAct.updateAllPrices = activity.updateAllPrices,
      formAct.name = activity.generalData.name;
      formAct.discipline = activity.generalData.discipline;
      formAct.kind = activity.generalData.kind;
      formAct.minDivers = activity.generalData.minDivers;
      if (activity.generalData.maxDivers) formAct.maxDivers = activity.generalData.maxDivers;
      formAct.diveCount = activity.generalData.diveCount;
      formAct.needBoat = activity.generalData.needBoat;
      formAct.category = activitiesType === 'dive' ? 1 : 4; //activity.generalData.category;
      if (activity.generalData.collaboration && activity.generalData.collaboration.centre) formAct.collaboration = activity.generalData.collaboration;
      formAct.stock = activity.stock;

    } else if (activity.generalDataCourse) {
      formAct.name = activity.generalDataCourse.name;
      formAct.course = activity.generalDataCourse.course;
      formAct.discipline = activity.generalDataCourse.discipline;
      formAct.minDivers = activity.generalDataCourse.minDivers;
      formAct.maxDivers = activity.generalDataCourse.maxDivers;
      formAct.category = activity.generalDataCourse.category;
      formAct.certifier = activity.generalDataCourse.certifier;
      formAct.courseType = activity.generalDataCourse.courseType;
      const courseInfo: any = {};
      if (activity.courseInfo) {
        if (activity.courseInfo.theoreticPhases && activity.courseInfo.theoreticPhases.length) {
          const phases = [];
          for (const theoricPhase of activity.courseInfo.theoreticPhases) {
            const phase: any = {};
            if (theoricPhase.onlineTheory && theoricPhase.onlineTheory.sessions) phase.onlineTheory = theoricPhase.onlineTheory;

            if (theoricPhase.presentialTheory && theoricPhase.presentialTheory.sessions) phase.presentialTheory = theoricPhase.presentialTheory;
            phases.push(phase);
          }
          courseInfo.theoreticPhases = phases;
        }

        if (activity.courseInfo.practicalPhases && activity.courseInfo.practicalPhases.length) {
          const phases = [];
          let diveCount = 0;
          for (const practicalPhases of activity.courseInfo.practicalPhases) {
            const phase: any = {};
            if (practicalPhases.closedWaterDives && practicalPhases.closedWaterDives.sessions) {
              phase.closedWaterDives = practicalPhases.closedWaterDives;
              diveCount = + practicalPhases.closedWaterDives.sessions
            }
            if (practicalPhases.openWaterDives && practicalPhases.openWaterDives.sessions) {
              phase.openWaterDives = practicalPhases.openWaterDives;
              diveCount = + practicalPhases.openWaterDives.sessions;
            }
            phases.push(phase);
          }
          formAct.diveCount = diveCount;
          courseInfo.practicalPhases = phases;
        }
      }
      formAct.courseInfo = courseInfo;
    }
    formAct.comments = activity.languagesComments;
    formAct.descriptions = activity.languagesDescriptions;
    formAct.minimumRequisites = activity.minimumRequisites;
    formAct.sites = activity.diveSites;
    formAct.services = activity.services;
    formAct.exclusiveStock = activity.exclusiveStock;
    formAct.exclusiveServices = activity.exclusiveServices;
    if (activity.exclusiveness && activity.exclusiveness.price) {
      formAct.exclusiveness = activity.exclusiveness;
    }
    formAct.cancellationPolicy = activity.cancellationPolicy;
    formAct.price = activity.price;

    if (activity.otherServices) {
      formAct.meal = activity.otherServices.foodAccommodation;
      if (activity.otherServices.departurePoint && activity.otherServices.transport) {
        formAct.transport = {
          departurePoint: activity.otherServices.departurePoint,
          transport: activity.otherServices.transport,
        };
      }
    }

    let resp;
    if (activityEdit) {
      resp = await patchActivityTemplate(activityEdit.id.toString(), formAct);
      if (!newActivityType) services.showModalSuccess('¡Editado!', 'La actividad se ha editado con éxito');
    } else {
      resp = await postActivityTemplate(formAct);
      if (!newActivityType) services.showModalSuccess('¡Creado!', 'La actividad se ha creado con éxito');
    }

    if (activity.pictures && activity.pictures.length > 0) {
      const formData = new FormData();
      activity.pictures.forEach(picture => {
        formData.append('files', picture.data);
      });
      await postActivityTemplatePictures(resp.data.id.toString(), formData);
    }

    if (localStorage.getItem('arrayImages')) {
      const images = localStorage.getItem('arrayImages');
      if (images) {
        const formData = new FormData();
        let arrayImages: any = images.split(',');
        for (let i = 0; i < arrayImages.length; i++) {
          if (localStorage.getItem(arrayImages[i])) {
            let file = this.dataURLtoFile(localStorage.getItem(arrayImages[i]), arrayImages[i]);
            formData.append('files', file);
            localStorage.removeItem(arrayImages[i])
          }
        }
        await postActivityTemplatePictures(resp.data.id.toString(), formData);
        localStorage.removeItem('arrayImages')
      }
    }


    console.log(activity.deletedPictures)
    if (activity.deletedPictures && activity.deletedPictures.length > 0) {
      await deleteActivityTemplatePictures(resp.data.id.toString(), { pictures: activity.deletedPictures });

    }
    onSuccess(resp.data.id.toString());
  }


  dataURLtoFile(dataurl: any, filename: string) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  renderStep() {
    const { centreId } = this.props;
    const { step, activitiesType, activity } = this.state;

    if (activitiesType === 'dive' || activitiesType === 'travel') {
      switch (step) {
        case 0:
          return <p>Hola</p>;

        case 1:
          return (
            <CreateActivitiesGeneralData
              centreId={centreId}
              generalData={activity.generalData}
              onNext={(generalData: IDiveGeneralData) => {
                this.handleNext({ generalData });
              }}
            // onPrev={() => this.handlePrev()}
            />
          );

        case 2:
          return (
            <CreateActivitiesMinimunRequirements
              minimumRequisites={activity.minimumRequisites}
              type={activitiesType}
              activity={activity}
              centreId={parseInt(centreId)}
              onNext={(minimumRequisites: IMinimumRequirements) => {
                this.handleNext({ minimumRequisites });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 3:
          return (
            <CreateActivitiesIncludedServices
              services={activity.services}
              centreId={centreId}
              onNext={(services: IId[]) => {
                this.handleNext({ services });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        /*case 4:
          return (
            <CreateActivitiesIncludedEquipment
              stock={activity.stock}
              centreId={centreId}
              onNext={(stock: IId[]) => {
                this.handleNext({ stock });
              }}
              onPrev={() => this.handlePrev()}
            />
          );*/

        case 4:
          return (
            <CreateActivitiesDiveSites
              diveSites={activity.diveSites}
              centreId={centreId}
              onNext={(diveSites: IId[]) => {
                this.handleNext({ diveSites });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        /*case 5:
          return (
            <CreateActivitiesOtherServices
              otherServices={activity.otherServices}
              centreId={centreId}
              onNext={(otherServices: IOtherServices) => {
                this.handleNext({ otherServices });
              }}
              onPrev={() => this.handlePrev()}
            />
          );*/

        case 5:
          return (
            <CreateActivitiesGallery
              pictures={activity.pictures}
              onNext={(pictures: AttachedFile[], deletedPictures: string[]) => {
                this.handleNext({ pictures, deletedPictures });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 6:
          return (
            <CreateActivitiesPrices
              price={activity.price}
              cancellationPolicy={activity.cancellationPolicy}
              updateAllPrices={activity.updateAllPrices}
              centreId={centreId}
              onNext={(price: number, cancellationPolicy: number, updateAllPrices?:boolean) => {
                this.handleNext({
                  price,
                  cancellationPolicy,
                  updateAllPrices
                });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 7:
          return (
            <CreateActivitiesDetails
              languagesComments={activity.languagesComments}
              languagesDescriptions={activity.languagesDescriptions}
              centreId={centreId}
              onNext={(languagesDescriptions: IMultilang, languagesComments: IMultilang) => {
                this.handleNext({ languagesDescriptions, languagesComments });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        /*case 10:
          return (
            <CreateActivitiesExclusiveness
              isEditing={!!activityEdit}
              exclusiveServices={activity.exclusiveServices}
              exclusiveEquipment={activity.exclusiveStock}
              exclusiveness={activity.exclusiveness}
              centreId={centreId}
              onNext={(
                exclusiveStock: IId[],
                exclusiveServices: IId[],
                exclusiveness: {
                  included: boolean;
                  price: number;
                }
              ) => {
                this.handleNext({ exclusiveStock, exclusiveServices, exclusiveness });
              }}
              onPrev={() => this.handlePrev()}
            />
          );*/

        case 9:
          return '';
        default:
          return '';
      }
    } else {
      switch (step) {
        case 0:
          return <p>Hola</p>;

        case 1:
          return (
            <CreateActivitiesGeneralDataTraining
              centreId={centreId}
              generalData={activity.generalDataCourse}
              onNext={(generalDataCourse: ICourseGeneralData) => {
                this.handleNext({ generalDataCourse });
              }}
            // onPrev={() => this.handlePrev()}
            />
          );

        case 2:
          return (
            <CreateActivitiesCourseInfo
              courseTemplateInfo={activity.courseInfo}
              centreId={centreId}
              onNext={(courseInfo: ICourseTemplateInfo) => {
                this.handleNext({ courseInfo });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 3:
          return (
            <CreateActivitiesMinimunRequirements
              course={activity.generalDataCourse ? activity.generalDataCourse.course : undefined}
              minimumRequisites={activity.minimumRequisites}
              activity={activity}
              centreId={parseInt(centreId)}
              type={activitiesType}
              onNext={(minimumRequisites: IMinimumRequirements) => {
                this.handleNext({ minimumRequisites });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 4:
          return (
            <CreateActivitiesIncludedServices
              services={activity.services}
              centreId={centreId}
              onNext={(services: IId[]) => {
                this.handleNext({ services });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 5:
          return (
            <CreateActivitiesDiveSites
              diveSites={activity.diveSites}
              centreId={centreId}
              onNext={(diveSites: IId[]) => {
                this.handleNext({ diveSites });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        /*case 5:
          return (
            <CreateActivitiesOtherServices
              otherServices={activity.otherServices}
              centreId={centreId}
              onNext={(otherServices: IOtherServices) => {
                this.handleNext({ otherServices });
              }}
              onPrev={() => this.handlePrev()}
            />
          );*/

        case 6:
          return (
            <CreateActivitiesGallery
              pictures={activity.pictures}
              onNext={(pictures: AttachedFile[], deletedPictures: string[]) => {
                this.handleNext({ pictures, deletedPictures });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 7:
          return (
            <CreateActivitiesPrices
              price={activity.price}
              cancellationPolicy={activity.cancellationPolicy}
              centreId={centreId}
              onNext={(price: number, cancellationPolicy: number) => {
                this.handleNext({
                  price,
                  cancellationPolicy,
                });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 8:
          return (
            <CreateActivitiesDetails
              languagesComments={activity.languagesComments}
              languagesDescriptions={activity.languagesDescriptions}
              centreId={centreId}
              course={activity.generalDataCourse ? activity.generalDataCourse.course : null}
              onNext={(languagesDescriptions: IMultilang, languagesComments: IMultilang) => {
                this.handleNext({ languagesDescriptions, languagesComments });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        /*case 10:
          return (
            <CreateActivitiesExclusiveness
              isEditing={!!activityEdit}
              exclusiveServices={activity.exclusiveServices}
              exclusiveEquipment={activity.exclusiveStock}
              exclusiveness={activity.exclusiveness}
              centreId={centreId}
              onNext={(
                exclusiveEquipment: IId[],
                exclusiveServices: IId[],
                exclusiveness: {
                  included: boolean;
                  price: number;
                }
              ) => {
                this.handleNext({ exclusiveEquipment, exclusiveServices, exclusiveness });
              }}
              onPrev={() => this.handlePrev()}
            />
          );*/

        case 9:
          return '';
        default:
          return '';
      }
    }
  }


  getTitle() {
    const { step, activitiesType } = this.state;
    if (activitiesType === 'dive') {
      switch (step) {
        case 0:
          return '¿Qué tipo de actividad quieres crear?';
        case 1:
          return 'Datos generales';
        case 2:
          return 'Requisitos mínimos';
        case 3:
          return 'Servicios incluidos';
        case 4:
          return 'Puntos de inmersión';
        /*case 5:
          return 'Otros servicios';*/
        case 5:
          return 'Galería de imágenes';
        case 6:
          return 'Precios';
        case 7:
          return 'Descripción de la actividad';
        default:
      }
    } else  if (activitiesType === 'travel') {
      switch (step) {
        case 0:
          return '¿Qué tipo de actividad quieres crear?';
        case 1:
          return 'Datos generales';
        case 2:
          return 'Requisitos mínimos';
        case 3:
          return 'Servicios incluidos';
        case 4:
          return 'Puntos de inmersión';
        /*case 5:
          return 'Otros servicios';*/
        case 5:
          return 'Galería de imágenes';
        case 6:
          return 'Precios';
        case 7:
          return 'Descripción de la actividad';
        default:
      }
    } else {
      switch (step) {
        case 0:
          return '¿Qué tipo de actividad quieres crear?';
        case 1:
          return 'Datos generales';
        case 2:
          return 'Contenido del curso/especialización';
        case 3:
          return 'Requisitos mínimos';
        case 4:
          return 'Servicios incluidos';
        case 5:
          return 'Puntos de inmersión';
        case 6:
          return 'Galería de imágenes';
        case 7:
          return 'Precios';
        case 8:
          return 'Descripción de la actividad';
        default:
      }
    }
  }

  /*
  comentada para quitar los pasos de equipo y exclusividad
  getTitle() {
    const { step, activitiesType } = this.state;
    if (activitiesType === 'dive') {
      switch (step) {
        case 0:
          return '¿Qué tipo de actividad quieres crear?';
        case 1:
          return 'Datos generales';
        case 2:
          return 'Requisitos mínimos';
        case 3:
          return 'Servicios incluidos';
        case 4:
          return 'Equipo incluido';
        case 5:
          return 'Puntos de inmersión';
        case 6:
          return 'Otros servicios';
        case 7:
          return 'Galería de imágenes';
        case 8:
          return 'Precios';
        case 9:
          return 'Descripción de la actividad';
        case 10:
          return 'Exclusividad';
        default:
      }
    } else {
      switch (step) {
        case 0:
          return '¿Qué tipo de actividad quieres crear?';
        case 1:
          return 'Datos generales';
        case 2:
          return 'Contenido del curso/especialización';
        case 3:
          return 'Requisitos mínimos';
        case 4:
          return 'Servicios incluidos';
        case 5:
          return 'Puntos de inmersión';
        case 6:
          return 'Otros servicios';
        case 7:
          return 'Galería de imágenes';
        case 8:
          return 'Precios';
        case 9:
          return 'Descripción de la actividad';
        case 10:
          return 'Exclusividad';
        default:
      }
    }
  }*/

  render() {
    const { onCancel, showModal } = this.props;
    const { step, activitiesType } = this.state;

    return (
      <ModalContainer className="" modalClose={() => onCancel()} active={showModal}>
        <ModalCreateActivitiesStyle>
          <div className="modal-create-activities-title">
            <div className="modal-create-activities-title__step">
              <LabelText>{step < 1 ? 'Crear actividad' : `Paso ${step}`}</LabelText>
            </div>
            <div className="modal-create-activities-title">
              <SubheadDestacadoText>{this.getTitle()}</SubheadDestacadoText>
            </div>
          </div>

          <div className={'modal-create-activities-container'}>
            {step === 0 ? (
              <div className={'modal-create-activities-type'}>
                <div
                  className={'modal-create-activities-type__item'}
                  onClick={() => this.setState({ activitiesType: 'dive' })}
                >
                  <CardCheck type="dark-blue" text="Inmersión" title="" isActive={activitiesType === 'dive'} />
                </div>
                <div
                  className={'modal-create-activities-type__item'}
                  onClick={() => this.setState({ activitiesType: 'travel' })}
                >
                  <CardCheck type="dark-blue" text="Viaje" title="" isActive={activitiesType === 'travel'} />
                </div>
                <div
                  className={'modal-create-activities-type__item'}
                  onClick={() => this.setState({ activitiesType: 'course' })}
                >
                  <CardCheck type="dark-blue" text="Formación" title="" isActive={activitiesType === 'course'} />
                </div>
              </div>
            ) : (
              this.renderStep()
            )}
          </div>
          <div className="modal-create-activities-buttons">
            <div className="modal-create-activities-buttons-left">
              {/*step > 0 && (
                <div className="modal-create-activities-buttons-left__back">
                  <SingleButton
                    className={'secondary'}
                    icon={images.arrowLeftSvg}
                    onClick={() => this.setState({ step: step - 1 })}
                  />
                </div>
              )*/}
              {step < 1 && (
                <div className="modal-create-activities-buttons-left__next">
                  <MainButton
                    text={step < 1 ? 'Crear' : 'Siguiente'}
                    type="primary"
                    onClick={() => this.setState({ step: step + 1 })}
                  />
                </div>
              )}
            </div>
          </div>
        </ModalCreateActivitiesStyle>
      </ModalContainer>
    );
  }
}
