import { IFilter } from '../models/filter';
import { getDateQuery } from './time';
import { getConfiguration } from '../services/configuration';
import { LANGUAGES_KEY_VALUES } from '../constants/languages';
import { IMultilang, IRating, ITimeBlocks } from '../models/generic';
import moment from 'moment';

export const compare = (a: any, b: any) => {
  if (a.last_nom < b.last_nom) return -1;
  if (a.last_nom > b.last_nom) return 1;
  return 0;
};

export const capitalizeFirstLetter = (text: string) => {
  if (typeof text !== 'string') return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getIdArray = (elements: any) => {
  return elements.map((elem: any) => {
    return { id: elem.id };
  });
};

export const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  return dist;
};

export const getUserLanguageComment = (lang: IMultilang | any) => {
  const userLang: string | null = localStorage.getItem('i18nextLng');
  if (userLang && lang[userLang]) return lang[userLang];
  if (lang.en) return lang.en;
  if (lang.es) return lang.es;
  if (lang.de) return lang.de;
  if (lang.fr) return lang.fr;
  if (lang.it) return lang.it;
  if (lang.pt) return lang.pt;
  return '';
};

export const calculateRating = (ratings: IRating[]) => {
  if (ratings.length === 0) return 0;
  let globalScore = 0;
  ratings.forEach((rating: IRating) => {
    globalScore += rating.centreScore;
    globalScore += rating.locationScore;
    globalScore += rating.staffScore;
  });
  return globalScore / ratings.length / 3;
};

export const calculateActivityDuration = (timeBlocks: ITimeBlocks[] = []) => {
  let hours = 0;
  let minutes = 0;
  timeBlocks.forEach((timeBlock: ITimeBlocks) => {
    const start = moment();
    const end = moment();
    start
      .hour(timeBlock.start.hour)
      .minute(0)
      .seconds(0);
    end
      .hour(timeBlock.end.hour)
      .minute(0)
      .seconds(0);
    hours += end.diff(start, 'hours', true);
    start
      .hour(0)
      .minute(timeBlock.start.minutes)
      .seconds(0);
    end
      .hour(0)
      .minute(timeBlock.end.minutes)
      .seconds(0);
    minutes += end.diff(start, 'minutes', true);
  });
  
  if(minutes < 0){
    hours = hours - 1
    minutes = -minutes
  }

  return { minutes, hours };
};



export const getActiveTemplateLanguages = (template: string) => {
  return new Promise<string[]>(async (res, rej) => {
    const languagesDropdown: any[] = [];
    const emails: any = (await getConfiguration()).data.emails;
    const emailTemplate = Object.entries(emails[template]).filter((email: any) => email[1].active);
    emailTemplate.forEach(lang => {
      languagesDropdown.push({
        value: lang[0],
        label: LANGUAGES_KEY_VALUES[lang[0]],
      });
    });
    res(languagesDropdown);
  });
};

export const createFilterRequest = (filters: { [key: string]: IFilter }) => {
  const filterReq: any = {};
  Object.entries(filters).forEach((filter: any) => {
    const filterKey: string = filter[0];
    const filterVal: IFilter = filter[1];
    filterReq[filterKey] = filterVal.collection ? {
      collection: filterVal.collection ? filterVal.collection : 'model',
    } : {};
    if (filterVal.jsonSearch) {
      filterReq[filterKey].jsonSearch = filterVal.jsonSearch;
    }
    if (filterVal.arraySearch) {
      filterReq[filterKey].arraySearch = true;
    }
    if (filterVal.concat) {
      filterReq[filterKey].concat = filterVal.concat;
    }

    switch (typeof filterVal.value) {
      case 'string':
        if (filterVal.arraySearch) {
          if (filterVal.method) {
            filterReq[filterKey].method = filterVal.method;
          }
          filterReq[filterKey].value = filterVal.value;
        } else {
          if (filterVal.method === 'between') {
            const [dateFrom, dateTo] = filterVal.value.split('-');
            filterReq[filterKey].values = [
              `${moment(dateFrom, 'DD/MM/YYYY')
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss')}`,
              `${moment(dateTo, 'DD/MM/YYYY')
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss')}`,
            ];
            filterReq[filterKey].type = 'timestamp';
            filterReq[filterKey].method = 'between';
          } else {
            filterReq[filterKey].method = filterVal.method ? filterVal.method : 'iLike';
            filterReq[filterKey].value = `%${filterVal.value}%`;
          }
        }
        break;
      case 'object':
        if (Array.isArray(filterVal.value)) {
          filterReq[filterKey].method = filterVal.method ? filterVal.method : 'in';
          filterReq[filterKey].values = filterVal.value;
          break;
        } else if (filterVal.value) {
          filterReq[filterKey].value = getDateQuery(filterVal.value.toISOString());
          filterReq[filterKey].values = [
            `${filterVal.value.startOf('day').format('YYYY-MM-DD HH:mm:ss')}`,
            `${filterVal.value.endOf('day').format('YYYY-MM-DD HH:mm:ss')}`,
          ];
          filterReq[filterKey].type = 'timestamp';
          filterReq[filterKey].method = 'between';
          break;
        } else if (filterVal.value === null) {
          filterReq[filterKey].method = filterVal.method ? filterVal.method : 'in';
          filterReq[filterKey].value = 'null';
          break;
        }
      case 'boolean':
      case 'number':
        filterReq[filterKey].value = filterVal.value;
        break;
      default:
        filterReq[filterKey].method = filterVal.method ? filterVal.method : 'iLike';
        filterReq[filterKey].value = filterVal.value;
    }
  });
  return filterReq;
};

export const getFieldValue = (state: any, fields: string): any => {
  let stateValue = Object.assign(state);
  const fieldsArray = fields.split('.');
  fieldsArray.forEach(field => {
    if (stateValue) {
      stateValue = stateValue[field];
    }
  });
  return stateValue;
};

export const copyOf = (value: any) => {
  return JSON.parse(JSON.stringify(value));
};

const isMergeableObject = (val: any) => {
  const nonNullObject = val && typeof val === 'object';

  return (
    nonNullObject &&
    Object.prototype.toString.call(val) !== '[object RegExp]' &&
    Object.prototype.toString.call(val) !== '[object Date]'
  );
};

const emptyTarget = (val: any) => {
  return Array.isArray(val) ? [] : {};
};

const cloneIfNecessary = (value: any, optionsArgument: any): any => {
  const clone = optionsArgument && optionsArgument.clone === true;
  return clone && isMergeableObject(value) ? deepmerge(emptyTarget(value), value, optionsArgument) : value;
};

const defaultArrayMerge = (target: any, source: any, optionsArgument: any): any => {
  const destination = target.slice();
  source.forEach((e: any, i: any) => {
    if (typeof destination[i] === 'undefined') {
      destination[i] = cloneIfNecessary(e, optionsArgument);
    } else if (isMergeableObject(e)) {
      destination[i] = deepmerge(target[i], e, optionsArgument);
    } else if (target.indexOf(e) === -1) {
      destination.push(cloneIfNecessary(e, optionsArgument));
    }
  });
  return destination;
};

export const mergeObject = (target: any, source: any, optionsArgument?: any): any => {
  const destination: any = {};
  if (isMergeableObject(target)) {
    Object.keys(target).forEach((key: string) => {
      destination[key] = cloneIfNecessary(target[key], optionsArgument);
    });
  }
  Object.keys(source).forEach((key: string) => {
    if (!isMergeableObject(source[key]) || !target[key]) {
      destination[key] = cloneIfNecessary(source[key], optionsArgument);
    } else {
      destination[key] = deepmerge(target[key], source[key], optionsArgument);
    }
  });

  return destination;
};

const deepmerge = (target: any, source: any, optionsArgument: any): any => {
  const array = Array.isArray(source);
  const options = optionsArgument || { arrayMerge: defaultArrayMerge };
  const arrayMerge = options.arrayMerge || defaultArrayMerge;

  if (array) {
    return Array.isArray(target)
      ? arrayMerge(target, source, optionsArgument)
      : cloneIfNecessary(source, optionsArgument);
  }
  return mergeObject(target, source, optionsArgument);
};

deepmerge.all = (array: any, optionsArgument: any): any => {
  if (!Array.isArray(array) || array.length < 2) {
    throw new Error('first argument should be an array with at least two elements');
  }

  // we are sure there are at least 2 values, so it is safe to have no initial value
  return array.reduce((prev, next) => {
    return deepmerge(prev, next, optionsArgument);
  });
};

export function currencyFormat(num: string) {
  const n: number = parseFloat(num);
  return n
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // use . as a separator
}

export function textWithPoints(text: string = '', limit: number = 0) {
  const points = '...';
  let textUpdate = '';
  if (text && text.length > limit) {
    textUpdate = text.substring(0, limit) + points;
  }

  return textUpdate;
}
