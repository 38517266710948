import * as React from 'react';
import { CreateActivitiesCourseInfoStyle } from './create-activities-course-info-style';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import InfoText from '../../../../../components/text/info-text/info-text';
import { images } from '../../../../../assets/images';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../main-layout-style';
import SelectBox from '../../../../../components/select-box';
import InputBox from '../../../../../components/input-box';
import SingleButton from '../../../../../components/single-button/single-button';
import MainButton from '../../../../../components/main-button';
import { LANGUAGES } from '../../../../../constants/languages';
import { ICourseTemplateInfo, ITheoreticPhase, IPracticalPhase } from '../../../../../models/activity-template';
import { copyOf } from '../../../../../utils/other';

export interface ICreateActivitiesCourseInfoProps {
  courseTemplateInfo?: ICourseTemplateInfo;
  onPrev?: () => void;
  onNext: (courseTemplateInfo: ICourseTemplateInfo) => void;
  centreId: string;
}

export interface ICreateActivitiesCourseInfoState {
  courseTemplateInfo: ICourseTemplateInfo;
  theoreticPhase: ITheoreticPhase;
  practicalPhase: IPracticalPhase;
  //centres: IDropdown[];
  //theoreticPhaseCenterCollaborate: boolean;
  //practicalPhaseCenterCollaborate: boolean;
  errors: {
    theoreticPhase: {
      onlineTheory: {
        sessions: string;
        languages: string;
      },
      presentialTheory: {
        sessions: string;
        languages: string;
      }
      //'centre': string;
    },
    practicalPhase: {
      closedWaterDives: {
        sessions: string;
        languages: string;
      },
      openWaterDives: {
        sessions: string;
        languages: string;
      }
      //'centre': string;
    }
  };
}

export default class CreateActivitiesCourseInfo extends React.Component<
  ICreateActivitiesCourseInfoProps,
  ICreateActivitiesCourseInfoState
  > {
  constructor(props: ICreateActivitiesCourseInfoProps) {
    super(props);

    this.state = {
      courseTemplateInfo: {
        theoreticPhases: [],
        practicalPhases: [],
      },
      //centres: [],
      theoreticPhase: {
        onlineTheory: {
          sessions: NaN,
          languages: [],
        },
        presentialTheory: {
          sessions: NaN,
          languages: [],
        },
        //centre: NaN,
      },
      practicalPhase: {
        closedWaterDives: {
          sessions: NaN,
          languages: [],
        },
        openWaterDives: {
          sessions: NaN,
          languages: [],
        }
        //centre: NaN,
      },
      //theoreticPhaseCenterCollaborate: false,
      //practicalPhaseCenterCollaborate: false,
      errors: {
        theoreticPhase: {
          onlineTheory: {
            sessions: '',
            languages: '',
          },
          presentialTheory: {
            sessions: '',
            languages: '',
          }
          //'centre': '',
        },
        practicalPhase: {
          closedWaterDives: {
            sessions: '',
            languages: '',
          },
          openWaterDives: {
            sessions: '',
            languages: '',
          }
          //'centre': '',
        }
      }
    };
  }

  componentDidMount() {
    //this.getCentres();
    this.setCourseInfo();
  }

  componentDidUpdate(prevProps: ICreateActivitiesCourseInfoProps) {
    const { courseTemplateInfo } = this.props;
    if (courseTemplateInfo !== prevProps.courseTemplateInfo) {
      this.setCourseInfo();
    }
  }

  setCourseInfo() {
    const { courseTemplateInfo } = this.props;
    if (courseTemplateInfo) {
      this.setState({
        courseTemplateInfo,
        //theoreticPhaseCenterCollaborate: !!courseTemplateInfo.theoreticPhase.centre,
        //practicalPhaseCenterCollaborate: !!courseTemplateInfo.practicalPhase.centre,
      });
    }
  }

  /*
  async getCentres() {
    const { centres } = this.state;
    if (centres.length === 0) {
      const resp = await getCentres();
      const centresDropdown = resp.data.data.map((centre: any) => {
        return { value: centre.id.toString(), label: centre.name };
      });
      this.setState({ centres: centresDropdown });
    }
  }*/


  handleChange(value: boolean | number | string[], phase: string, field: string, subfield: string) {
    const { theoreticPhase, practicalPhase, errors } = Object.assign(this.state);
    if (phase === 'theoretic') {
      theoreticPhase[field][subfield] = value;
      errors[`theoreticPhase.${field}.${subfield}`] = this.validate(value);
      this.setState({ errors, theoreticPhase });
    } else {
      practicalPhase[field][subfield] = value;
      errors[`practicalPhase.${field}.${subfield}`] = this.validate(value);
      this.setState({ errors, practicalPhase });
    }
  }

  validate(value: boolean | number | string[]) {
    if (!value || (typeof value === 'object' && value.length === 0)) return 'required';
    return '';
  }

  handleNext() {
    const { onNext } = this.props;
    const { courseTemplateInfo } = Object.assign(this.state);

    if (courseTemplateInfo.theoreticPhases && courseTemplateInfo.theoreticPhases.length === 0) delete courseTemplateInfo.theoreticPhases;
    if (courseTemplateInfo.practicalPhases && courseTemplateInfo.practicalPhases.length === 0) delete courseTemplateInfo.practicalPhases;

    if ((courseTemplateInfo.theoreticPhases && courseTemplateInfo.theoreticPhases.length > 0) || (courseTemplateInfo.practicalPhases && courseTemplateInfo.practicalPhases.length > 0)) {
      onNext(courseTemplateInfo);
    } else {
      //TODO Controlar error cuando no ha rellenado nada.
    }
  }

  handleAddPhase(type: string) {

    const { errors, courseTemplateInfo, theoreticPhase, practicalPhase } = Object.assign(this.state);

    if (type === 'theoretic') {
      if (!courseTemplateInfo.theoreticPhases) courseTemplateInfo.theoreticPhases = [];
      //Online
      Object.keys(errors.theoreticPhase.onlineTheory).forEach((error: any) => {
        errors.theoreticPhase.onlineTheory[error] = this.validate(theoreticPhase.onlineTheory[error]);
      });

      //Presencial
      Object.keys(errors.theoreticPhase.presentialTheory).forEach((error: any) => {
        errors.theoreticPhase.presentialTheory[error] = this.validate(theoreticPhase.presentialTheory[error]);
      });

      if ((Object.values(errors.theoreticPhase.onlineTheory).every(err => err === '')) || (Object.values(errors.theoreticPhase.presentialTheory).every(err => err === ''))) {
        const newTheoreticPhase = {
          onlineTheory: {},
          presentialTheory: {}
        };

        if (Object.values(errors.theoreticPhase.onlineTheory).every(err => err === '')) {
          newTheoreticPhase.onlineTheory = theoreticPhase.onlineTheory;
        } else {
          Object.keys(errors.theoreticPhase.onlineTheory).forEach((error: any) => { errors.theoreticPhase.onlineTheory[error] = ''; });
        }

        if (Object.values(errors.theoreticPhase.presentialTheory).every(err => err === '')) {
          newTheoreticPhase.presentialTheory = theoreticPhase.presentialTheory;
        } else {
          Object.keys(errors.theoreticPhase.presentialTheory).forEach((error: any) => { errors.theoreticPhase.presentialTheory[error] = ''; });
        }

        courseTemplateInfo.theoreticPhases.push(newTheoreticPhase);

        const phase = {
          onlineTheory: {
            sessions: NaN,
            languages: [],
          },
          presentialTheory: {
            sessions: NaN,
            languages: [],
          },
          //centre: NaN,
        }

        this.setState({ courseTemplateInfo, theoreticPhase: phase });
      }

    } else {
      if (!courseTemplateInfo.practicalPhases) courseTemplateInfo.practicalPhases = [];
      //ClosedWaterDives
      Object.keys(errors.practicalPhase.closedWaterDives).forEach((error: any) => {
        errors.practicalPhase.closedWaterDives[error] = this.validate(practicalPhase.closedWaterDives[error]);
      });

      //OpenWaterDives
      Object.keys(errors.practicalPhase.openWaterDives).forEach((error: any) => {
        errors.practicalPhase.openWaterDives[error] = this.validate(practicalPhase.openWaterDives[error]);
      });

      if ((Object.values(errors.practicalPhase.closedWaterDives).every(err => err === '')) || (Object.values(errors.practicalPhase.openWaterDives).every(err => err === ''))) {
        const newPracticalPhase = {
          closedWaterDives: {},
          openWaterDives: {}
        };

        if (Object.values(errors.practicalPhase.closedWaterDives).every(err => err === '')) {
          newPracticalPhase.closedWaterDives = practicalPhase.closedWaterDives;
        } else {
          Object.keys(errors.practicalPhase.closedWaterDives).forEach((error: any) => { errors.practicalPhase.closedWaterDives[error] = ''; });
        }

        if (Object.values(errors.practicalPhase.openWaterDives).every(err => err === '')) {
          newPracticalPhase.openWaterDives = practicalPhase.openWaterDives;
        } else {
          Object.keys(errors.practicalPhase.openWaterDives).forEach((error: any) => { errors.practicalPhase.openWaterDives[error] = ''; });
        }

        courseTemplateInfo.practicalPhases.push(newPracticalPhase);

        const phase = {
          closedWaterDives: {
            sessions: NaN,
            languages: [],
          },
          openWaterDives: {
            sessions: NaN,
            languages: [],
          }
          //centre: NaN,
        }

        this.setState({ courseTemplateInfo, practicalPhase: phase });
      }
    }

    this.setState({ errors });

  }

  handleRemovePhase(index: number, type: string, sub?: string) {
    const { courseTemplateInfo } = this.state;
    const newCourseInfo = copyOf(courseTemplateInfo);

    if (type === 'theoretic') {
      newCourseInfo.theoreticPhases.splice(index, 1);
    } else {
      newCourseInfo.practicalPhases.splice(index, 1);
    }
    this.setState({ courseTemplateInfo: newCourseInfo });
  }

  render() {
    const { onPrev } = this.props;
    const {
      courseTemplateInfo,
      theoreticPhase,
      practicalPhase,
      errors,
      //theoreticPhaseCenterCollaborate,
      //practicalPhaseCenterCollaborate,
      //centres,
    } = this.state;


    return (
      <CreateActivitiesCourseInfoStyle>
        {/*FASE TEORICA*/}
        {courseTemplateInfo.theoreticPhases && courseTemplateInfo.theoreticPhases.length ? '' :
          <div>
            <div className="create-activities-other-subtitle">{'FASE TEÓRICA'}</div>
            <div>
              {/* Teoría Online*/}
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      errorCode={errors.theoreticPhase.onlineTheory.sessions}
                      placeholder="Sesiones online obligatorias"
                      className="rounded-title"
                      type="number"
                      value={theoreticPhase.onlineTheory.sessions.toString()}
                      labelText="Sesiones online obligatorias"
                      onChange={value => this.handleChange(parseInt(value, undefined), 'theoretic', 'onlineTheory', 'sessions')}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-2">
                    <SelectBox
                      errorCode={errors.theoreticPhase.onlineTheory.languages}
                      className="rounded-title"
                      labelText="Idiomas"
                      placeholder="Idioma/s"
                      multiple={true}
                      initialValue={''}
                      optionsText={LANGUAGES}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      searchable={true}
                      defaultMultipleValues={theoreticPhase.onlineTheory.languages}
                      initialMultipleValues={theoreticPhase.onlineTheory.languages}
                      icon={images.arrowDown2BlueSvg}
                      onChange={() => { }}
                      onChangeMultiple={value => this.handleChange(value, 'theoretic', 'onlineTheory', 'languages')}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
            <div>
              {/* Teoría Presencial*/}
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      errorCode={errors.theoreticPhase.presentialTheory.sessions}
                      placeholder="Sesiones presenciales obligatorias"
                      className="rounded-title"
                      type="number"
                      value={theoreticPhase.presentialTheory.sessions.toString()}
                      labelText="Sesiones presenciales obligatorias"
                      onChange={value => this.handleChange(parseInt(value, undefined), 'theoretic', 'presentialTheory', 'sessions')}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-2">
                    <SelectBox
                      errorCode={errors.theoreticPhase.presentialTheory.languages}
                      className="rounded-title"
                      labelText="Idiomas"
                      placeholder="Idioma/s"
                      multiple={true}
                      initialValue={''}
                      optionsText={LANGUAGES}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      searchable={true}
                      defaultMultipleValues={theoreticPhase.presentialTheory.languages}
                      initialMultipleValues={theoreticPhase.presentialTheory.languages}
                      icon={images.arrowDown2BlueSvg}
                      onChange={() => { }}
                      onChangeMultiple={value => this.handleChange(value, 'theoretic', 'presentialTheory', 'languages')}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>

            {/*<div className="create-activities-other-title">
            <div className="create-activities-other-title__text">
              <BodyDestacadoText>¿Colaboras con otro centro para esta la fase teórica?</BodyDestacadoText>
            </div>
            <div className="create-activities-other-title__check">
              <DicotomicCheckbox
                className="rounded"
                checked={theoreticPhaseCenterCollaborate}
                onCheck={() => this.setState({ theoreticPhaseCenterCollaborate: true })}
              >
                <InfoText>Si</InfoText>
              </DicotomicCheckbox>
            </div>
            <div className="create-activities-other-title__check">
              <DicotomicCheckbox
                className="rounded"
                checked={!theoreticPhaseCenterCollaborate}
                onCheck={() => this.setState({ theoreticPhaseCenterCollaborate: false })}
              >
                <InfoText>No</InfoText>
              </DicotomicCheckbox>
            </div>
          </div> 
          {theoreticPhaseCenterCollaborate && (
            <FormGridRow>
              <FormGridItem type={'col-0'}>
                <SelectBox
                  errorCode={errors['theoreticPhase.centre']}
                  className="rounded"
                  labelText=""
                  placeholder="Centro en el que se hará"
                  initialValue={courseTemplateInfo.theoreticPhase.centre.toString()}
                  optionsText={centres}
                  optionKey={'value'}
                  searchable={true}
                  optionValue={'label'}
                  defaultValue={courseTemplateInfo.theoreticPhase.centre.toString()}
                  icon={images.arrowDown2BlueSvg}
                  onChange={value => this.handleChange('centre', parseInt(value, undefined), 'theoreticPhase', false)}
                />
              </FormGridItem>
            </FormGridRow>
          )}*/}

            <div className="add-phase-button">
              <MainButton iconImg={images.plusSvg} text='Añadir fase teórica' type="secundary" onClick={() => this.handleAddPhase('theoretic')} />
            </div>
          </div>
        }


        {/*FASE PRACTICA*/}
        {courseTemplateInfo.practicalPhases && courseTemplateInfo.practicalPhases.length ? '' :
          <div>
            <FormGrid>
              <div className="create-activities-other-subtitle">{'FASE PRÁCTICA'}</div>
              <FormGridRow>
                <FormGridItem type="col-2">
                  <InputBox
                    errorCode={errors.practicalPhase.closedWaterDives.sessions}
                    placeholder="Nº inmersiones piscina/aguas confinadas"
                    className="rounded-title"
                    type="number"
                    value={practicalPhase.closedWaterDives.sessions.toString()}
                    labelText="Nº inmersiones piscina/aguas confinadas"
                    onChange={value => this.handleChange(parseInt(value, undefined), 'practical', 'closedWaterDives', 'sessions')}
                  />
                </FormGridItem>
                <FormGridItem type="col-2">
                  <SelectBox
                    errorCode={errors.practicalPhase.closedWaterDives.languages}
                    className="rounded-title"
                    labelText="Idiomas"
                    placeholder="Idioma/s"
                    multiple={true}
                    initialValue={''}
                    optionsText={LANGUAGES}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={''}
                    searchable={true}
                    initialMultipleValues={practicalPhase.closedWaterDives.languages}
                    defaultMultipleValues={practicalPhase.closedWaterDives.languages}
                    icon={images.arrowDown2BlueSvg}
                    onChange={() => { }}
                    onChangeMultiple={value => this.handleChange(value, 'practical', 'closedWaterDives', 'languages')}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-2">
                  <InputBox
                    errorCode={errors.practicalPhase.openWaterDives.sessions}
                    placeholder="Nº inmersiones en aguas abiertas"
                    className="rounded-title"
                    type="number"
                    value={practicalPhase.openWaterDives.sessions.toString()}
                    labelText="Nº inmersiones en aguas abiertas"
                    onChange={value => this.handleChange(parseInt(value, undefined), 'practical', 'openWaterDives', 'sessions')}
                  />
                </FormGridItem>
                <FormGridItem type="col-2">
                  <SelectBox
                    errorCode={errors.practicalPhase.openWaterDives.languages}
                    className="rounded-title"
                    labelText="Idiomas"
                    placeholder="Idioma/s"
                    multiple={true}
                    initialValue={''}
                    optionsText={LANGUAGES}
                    optionKey={'value'}
                    optionValue={'label'}
                    defaultValue={''}
                    searchable={true}
                    initialMultipleValues={practicalPhase.openWaterDives.languages}
                    defaultMultipleValues={practicalPhase.openWaterDives.languages}
                    icon={images.arrowDown2BlueSvg}
                    onChange={() => { }}
                    onChangeMultiple={value => this.handleChange(value, 'practical', 'openWaterDives', 'languages')}
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>
            {/*<div className="create-activities-other-title">
          <div className="create-activities-other-title__text">
            <BodyDestacadoText>¿Colaboras con otro centro para la fase práctica?</BodyDestacadoText>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={practicalPhaseCenterCollaborate}
              onCheck={() => this.setState({ practicalPhaseCenterCollaborate: true })}
            >
              <InfoText>Si</InfoText>
            </DicotomicCheckbox>
          </div>
          <div className="create-activities-other-title__check">
            <DicotomicCheckbox
              className="rounded"
              checked={!practicalPhaseCenterCollaborate}
              onCheck={() => this.setState({ practicalPhaseCenterCollaborate: false })}
            >
              <InfoText>No</InfoText>
            </DicotomicCheckbox>
          </div>
        </div>
          {practicalPhaseCenterCollaborate && (
            <FormGrid>
              <FormGridRow>
                <FormGridItem type={"col-2"}>
                  <SelectBox
                    errorCode={errors['practicalPhase.centre']}
                    className="rounded"
                    labelText=""
                    placeholder="Centro en el que se hará"
                    initialValue={courseTemplateInfo.practicalPhase.centre.toString()}
                    optionsText={centres}
                    optionKey={'value'}
                    searchable={true}
                    optionValue={'label'}
                    defaultValue={courseTemplateInfo.practicalPhase.centre.toString()}
                    icon={images.arrowDown2BlueSvg}
                    onChange={value => this.handleChange(parseInt(value, undefined), 'practicalPhase', 'centre')}
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>
          )}*/}
            <div className="add-phase-button">
              <MainButton iconImg={images.plusSvg} text='Añadir fase práctica' type="secundary" onClick={() => this.handleAddPhase('practical')} />
            </div>
          </div>
        }

        {/*Fases añadidas*/}
        <div className="summary">
          {courseTemplateInfo.theoreticPhases && courseTemplateInfo.theoreticPhases.length ? (
            <div className="phase-tittle">FASE TEÓRICA
              {
                courseTemplateInfo.theoreticPhases.map((phase, index) => {
                  if ((phase.onlineTheory && phase.onlineTheory.sessions) && (phase.presentialTheory && phase.presentialTheory.sessions)) {
                    return (
                      <div className="phase-content">
                        <div>
                          <InfoText> Teórica-online | {phase.onlineTheory.sessions} sesiones</InfoText>
                          <InfoText> Teórica-presencial | {phase.presentialTheory.sessions} sesiones</InfoText>
                        </div>
                        <div className="delete-img">
                          <img src={images.deleteSvg} alt="" onClick={() => this.handleRemovePhase(index, 'theoretic')} />
                        </div>
                      </div>
                    );
                  }
                  if (phase.onlineTheory && phase.onlineTheory.sessions) {
                    return (
                      <div className="phase-content">
                        <div>
                          <InfoText> Teórica-online | {phase.onlineTheory.sessions} sesiones</InfoText>
                        </div>
                        <div className="delete-img">
                          <img src={images.deleteSvg} alt="" onClick={() => this.handleRemovePhase(index, 'theoretic')} />
                        </div>
                      </div>
                    );
                  }
                  if (phase.presentialTheory && phase.presentialTheory.sessions) {
                    return (
                      <div className="phase-content">
                        <div>
                          <InfoText> Teórica-presencial | {phase.presentialTheory.sessions} sesiones</InfoText>
                        </div>
                        <div className="delete-img">
                          <img src={images.deleteSvg} alt="" onClick={() => this.handleRemovePhase(index, 'theoretic')} />
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          ) : ''}

          {courseTemplateInfo.practicalPhases && courseTemplateInfo.practicalPhases.length ? (
            <div className="phase-tittle">FASE PRACTICA
              {
                courseTemplateInfo.practicalPhases.map((phase, index) => {
                  if ((phase.closedWaterDives && phase.closedWaterDives.sessions) && (phase.openWaterDives && phase.openWaterDives.sessions)) {
                    return (
                      <div className="phase-content">
                        <div>
                          <InfoText> Práctica-aguas cerradas | {phase.closedWaterDives.sessions}</InfoText>
                          <InfoText> Práctica-aguas abiertas | {phase.openWaterDives.sessions}</InfoText>
                        </div>
                        <div className="delete-img">
                          <img src={images.deleteSvg} alt="" onClick={() => this.handleRemovePhase(index, 'practical')} />
                        </div>
                      </div>
                    );
                  }
                  if (phase.closedWaterDives && phase.closedWaterDives.sessions) {
                    return (
                      <div className="phase-content">
                        <div>
                          <InfoText> Práctica-aguas cerradas | {phase.closedWaterDives.sessions}</InfoText>
                        </div>
                        <div className="delete-img">
                          <img src={images.deleteSvg} alt="" onClick={() => this.handleRemovePhase(index, 'practical')} />
                        </div>
                      </div>
                    );
                  }
                  if (phase.openWaterDives && phase.openWaterDives.sessions) {
                    return (
                      <div className="phase-content">
                        <div>
                          <InfoText> Práctica-aguas abiertas | {phase.openWaterDives.sessions}</InfoText>
                        </div>
                        <div className="delete-img">
                          <img src={images.deleteSvg} alt="" onClick={() => this.handleRemovePhase(index, 'practical')} />
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          ) : ''}
        </div>


        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={onPrev ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesCourseInfoStyle>
    );
  }
}
