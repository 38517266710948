import React, { useRef } from 'react';
import { ModalDocumentContainer } from './modal-document-style';
import { images } from '../../../assets/images';
import SingleButton from '../../single-button/single-button';
import TitleText from '../../text/title-text/title-text';
import TextDestacadoText from '../../text/text-destacado-text/text-destacado-text';
import LabelText from '../../text/label-text/label';
import InfoText from '../../text/info-text/info-text';
import ModalContainer from '../modal-container';
import PdfViewer from '../../pdf-viewer/pdf-viewer';
import printJS from 'print-js';
import { saveAs } from 'file-saver';
import FileUtils from '../../../utils/files';
import MainButton from '../../main-button';
import { getDayMonthNumberYear } from '../../../utils/time';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

const CORS_URL = 'https://cors-anywhere.herokuapp.com/';
const GOOGLE_DOC_VIEWER_URL = 'https://docs.google.com/gview?url=';

interface ModalDocumentProps extends RouteComponentProps, WithTranslation {
  className?: string;
  title?: string;
  file?: any;
  id?: number | string;
  expirationDate?: Date | string;
  certifier?: string;
  linkURL?: string;
  type?: string;
  showModal: boolean;
  isCentre?: boolean;
  onClose: () => void;
  onValidate?: () => void;
  onDelete?: () => void;
  t: any
}

interface ModalDocumentState { }

class ModalDocument extends React.Component<ModalDocumentProps, ModalDocumentState> {
  refIframe: React.RefObject<HTMLIFrameElement>;

  constructor(props: ModalDocumentProps) {
    super(props);
    this.refIframe = React.createRef();
  }

  printDocument() {
    const { file } = this.props;

    const fileName = FileUtils.getFileName(file);
    const fileExtension = FileUtils.getExtension(fileName);
    if (file) {
      switch (fileExtension.toLowerCase()) {
        case 'pdf':
          printJS(CORS_URL + file);
          break;
        case 'png':
        case 'jpg':
        case 'jpeg':
          printJS(file, 'image');
          break;
        case 'doc':
        case 'docx':
        case 'xls':
        case 'xlsx':
        case 'csv':
          window.open(GOOGLE_DOC_VIEWER_URL + file, '_blank');
          break;
        default:
      }
    }
  }

  downloadDocument() {
    const { file } = this.props;
    if (file) {
      const fileName = FileUtils.getFileName(file);
      saveAs(CORS_URL + file, fileName);
    }
  }

  render() {
    const {
      onClose,
      showModal,
      title,
      file,
      id,
      expirationDate,
      certifier,
      type,
      onDelete,
      onValidate,
      isCentre,
      t
    } = this.props;
    const fileName = FileUtils.getFileName(file);
    const fileExtension = FileUtils.getExtension(fileName) || '';
    const iframeSrc = `${GOOGLE_DOC_VIEWER_URL}${file}&embedded=true`;
    return (
      <ModalContainer className="center" modalClose={() => onClose()} active={showModal}>
        <ModalDocumentContainer>
          <div className="modal-document-preview">
            {file && fileExtension.toLocaleLowerCase() === 'pdf' ? (
              <PdfViewer file={file} />
            ) : file &&
              (fileExtension.toLocaleLowerCase() === 'png' ||
                fileExtension.toLocaleLowerCase() === 'jpg' ||
                fileExtension.toLocaleLowerCase() === 'jpeg') ? (
              <div className="modal-document-preview__img">
                <img src={file} />
              </div>
            ) : file &&
              (fileExtension.toLocaleLowerCase() === 'doc' ||
                fileExtension.toLocaleLowerCase() === 'docx' ||
                fileExtension.toLocaleLowerCase() === 'xls' ||
                fileExtension.toLocaleLowerCase() === 'xlsx') ? (
              <iframe className="modal-document-preview__doc" ref={this.refIframe} src={iframeSrc} />
            ) : (
              <div className="modal-document-preview__empty">
                <img src={images.fileBlueSvg} alt="" />
              </div>
            )}
          </div>

          <div className="modal-document-info">
            <div className="modal-document-info-top">
              <div className="modal-document-info-top__title">
                <TitleText>{title}</TitleText>
              </div>
              <div className="modal-document-info-top__name">
                <TextDestacadoText>{fileName}</TextDestacadoText>
              </div>
              {certifier !== undefined && (
                <>
                  <div className="modal-document-info-top__subtitle">
                    <LabelText>{t('components:general.certifier')}</LabelText>
                  </div>
                  <div className="modal-document-info-top__info">
                    <InfoText>{certifier || '-'}</InfoText>
                  </div>
                </>
              )}
              <div className="modal-document-info-top__subtitle">
                <LabelText>ID</LabelText>
              </div>
              <div className="modal-document-info-top__info">
                <InfoText>{id}</InfoText>
              </div>
              {type !== undefined && (
                <>
                  <div className="modal-document-info-top__subtitle">
                    <LabelText>{t('components:general.type')}</LabelText>
                  </div>
                  <div className="modal-document-info-top__info">
                    <InfoText>{type}</InfoText>
                  </div>
                </>
              )}
              {expirationDate !== '-' && (
                <>
                  <div className="modal-document-info-top__subtitle">
                    <LabelText>{t('components:general.expiration-date')}</LabelText>
                  </div>
                  <div className="modal-document-info-top__info">
                    <InfoText>{getDayMonthNumberYear(expirationDate)}</InfoText>
                  </div>
                </>
              )}
              <div className="modal-document-info-top__buttons">
                <div className="modal-document-info-top__buttons__item">
                  <a href={file} target="_blank" download>
                    <SingleButton
                      className="secondary"
                      icon={images.downloadSvg}
                    />
                  </a>
                </div>
                <div className="modal-document-info-top__buttons__item">
                  <SingleButton className="secondary" icon={images.printSvg} onClick={() => this.printDocument()} />
                </div>
              </div>
            </div>

            <div className="modal-document-info-bottom">
              {/*onValidate && (
                <div className="modal-document-info-bottom__item">
                  <MainButton text="Validar" type="primary" onClick={() => onValidate()} />
                </div>
              )*/}
              {!isCentre && onDelete && (
                <div className="modal-document-info-bottom__item">
                  <MainButton text={t('components:actions.del')} type="secondary-red" onClick={() => onDelete()} />
                </div>
              )}
            </div>
          </div>
        </ModalDocumentContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalDocument));
