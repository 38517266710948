import React from 'react';
import { ModalEquipmentStyle } from './modal-equipment-style';
import ModalContainer from '../../../../../../components/modals/modal-container';
import { FormGridRow, FormGridItem } from '../../../../../main-layout-style';
import SelectBox from '../../../../../../components/select-box';
import { images } from '../../../../../../assets/images';
import InputBox from '../../../../../../components/input-box';
import MainButton from '../../../../../../components/main-button';
import SubheadDestacadoText from '../../../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../../../../../components/text/body-text/body-text';
import BodyDestacadoText from '../../../../../../components/text/body-destacado-text/body-destacado-text';
import { Size, DiverProfile } from '../../types';
import { patchSize, postSize } from '../../../../../../services/size';
import { BUOYANCY_CONTROL_TYPE_DROPDOWN, SUIT_TYPE_DROPDOWN } from '../../../../../../constants/size';
import services from '../../../../../../services/services';
import { copyOf } from '../../../../../../utils/other';
import { transformToSI, transformToUser, getUserUnit } from '../../../../../../utils/measurement-units';

interface ModalEquipmentProps {
  person: DiverProfile;
  size?: Size;
  showModal: boolean;
  onSave: () => void;
  onClose: () => void;
}

interface ModalEquipmentState {
  editMode: boolean;
  size: Size;
  errors: Size;
}

export default class ModalEquipment extends React.Component<ModalEquipmentProps, ModalEquipmentState> {
  constructor(props: ModalEquipmentProps) {
    super(props);
    this.state = {
      editMode: false,
      size: {
        booties: '',
        fins: '',
        bottle: '',
        buoyancyControlType: '',
        buoyancyControlSize: '',
        suitType: '',
        suitSize: '',
        ballast: '',
      },
      errors: {
        booties: '',
        fins: '',
        bottle: '',
        buoyancyControlType: '',
        buoyancyControlSize: '',
        suitType: '',
        suitSize: '',
        ballast: '',
      },
    };
  }

  componentDidMount() {
    const { size } = this.props;
    if (size) {
      try {
        const formattedSize = copyOf(size);
        formattedSize.ballast = transformToUser('weight', parseFloat(size.ballast)).toString();
        this.setState({ size: formattedSize, editMode: true });
      } catch (err) {
        this.setState({ size, editMode: true });
      }
    }
  }

  componentDidUpdate(prevProps: ModalEquipmentProps) {
    const { size } = this.props;
    if (size && size !== prevProps.size) {
      try {
        const formattedSize = copyOf(size);
        formattedSize.ballast = transformToUser('weight', parseFloat(size.ballast)).toString();
        this.setState({ size: formattedSize, editMode: true });
      } catch (err) {
        this.setState({ size, editMode: true });
      }
    }
  }

  validateField(key: string, value: string) {
    const { errors } = Object.assign(this.state);
    if (!value || value === '') errors[key] = 'required';
    else errors[key] = '';
    return errors[key];
  }

  validate() {
    const { errors, size } = Object.assign(this.state);
    let error = false;
    Object.keys(errors).forEach(key => {
      errors[key] = this.validateField(key, size[key]);
      error = error || errors[key] !== '';
    });
    this.setState({ errors });
    return error;
  }

  handleChange(key: string, value: string) {
    const { size, errors } = Object.assign(this.state);
    size[key] = value;
    const error = this.validateField(key, value);
    errors[key] = error;
    this.setState({ size, errors });
  }

  async handleSubmit() {
    const { onSave, person } = this.props;
    const { size, editMode } = this.state;
    if (!this.validate()) {
      const formattedSize = copyOf(size);
      formattedSize.ballast = transformToSI('weight', parseFloat(size.ballast)).toString();
      if (editMode && size.id) await patchSize(size.id, formattedSize);
      else await postSize(person.id, formattedSize);
      services.pushNotification({
        title: '¡Guardado!',
        text: 'El equipo de buceo se ha guardado con éxito',
        type: 'green',
      });
      onSave();
    }
  }

  render() {
    const { onClose, showModal } = this.props;
    const {
      size: { booties, fins, bottle, buoyancyControlType, buoyancyControlSize, suitType, suitSize, ballast },
      errors,
    } = this.state;

    return (
      <ModalContainer className="" modalClose={() => onClose()} active={showModal}>
        <ModalEquipmentStyle>
          <div className="create-user-title">
            <SubheadDestacadoText>Equipo de buceo preferido</SubheadDestacadoText>
          </div>
          <div className="create-user-text">
            <BodyText>
              Especifica las tallas del bueador en el equipo de buceo para que queden reflejadas en el perfil del
              buceador.
            </BodyText>
          </div>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <div className="create-user-input">
                <div className="create-user-input__title">
                  <BodyDestacadoText>ESCARPINES</BodyDestacadoText>
                </div>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.booties}
                      placeholder="Indique talla"
                      className="rounded"
                      type="text"
                      value={booties}
                      labelText="Usuario"
                      onChange={value => this.handleChange('booties', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </div>
              <div className="create-user-input">
                <div className="create-user-input__title">
                  <BodyDestacadoText>ALETAS</BodyDestacadoText>
                </div>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.fins}
                      placeholder="Indique talla"
                      className="rounded"
                      type="text"
                      value={fins}
                      labelText="Usuario"
                      onChange={value => this.handleChange('fins', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </div>
              <div className="create-user-input">
                <div className="create-user-input__title">
                  <BodyDestacadoText>CONTROL DE FLOTABILIDAD</BodyDestacadoText>
                </div>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <SelectBox
                      errorCode={errors.buoyancyControlType}
                      className="rounded"
                      labelText="Certificadora"
                      placeholder="Seleccione tipo"
                      required={true}
                      initialValue={buoyancyControlType}
                      optionsText={BUOYANCY_CONTROL_TYPE_DROPDOWN}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={buoyancyControlType}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.handleChange('buoyancyControlType', value)}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-2">
                    <InputBox
                      errorCode={errors.buoyancyControlSize}
                      placeholder="Indique talla"
                      className="rounded"
                      type="text"
                      value={buoyancyControlSize}
                      labelText="Usuario"
                      onChange={value => this.handleChange('buoyancyControlSize', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </div>
              <div className="create-user-input">
                <div className="create-user-input__title">
                  <BodyDestacadoText>BOTELLA</BodyDestacadoText>
                </div>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.bottle}
                      placeholder="Litros de aire"
                      className="rounded"
                      type="number"
                      value={bottle}
                      labelText="Usuario"
                      onChange={value => this.handleChange('bottle', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </div>
              <div className="create-user-input">
                <div className="create-user-input__title">
                  <BodyDestacadoText>TRAJE DE BUCEO</BodyDestacadoText>
                </div>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <SelectBox
                      className="rounded"
                      errorCode={errors.suitType}
                      labelText=""
                      placeholder="Seleccione tipo"
                      required={true}
                      initialValue={suitType}
                      optionsText={SUIT_TYPE_DROPDOWN}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={suitType}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.handleChange('suitType', value)}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-2">
                    <InputBox
                      errorCode={errors.suitSize}
                      placeholder="Indique la talla"
                      className="rounded"
                      type="text"
                      value={suitSize}
                      labelText="Usuario"
                      onChange={value => this.handleChange('suitSize', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </div>
              <div className="create-user-input">
                <div className="create-user-input__title">
                  <BodyDestacadoText>LASTRE</BodyDestacadoText>
                </div>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.ballast}
                      placeholder={`Indique lastre (${getUserUnit('weight')})`}
                      className="rounded"
                      type="number"
                      value={ballast}
                      labelText="Usuario"
                      onChange={value => this.handleChange('ballast', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </div>
            </div>
          </div>

          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton type="primary" onClick={() => this.handleSubmit()} text="Guardar Cambios" />
            </div>
          </div>
        </ModalEquipmentStyle>
      </ModalContainer>
    );
  }
}
