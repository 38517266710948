import styled from 'styled-components';

export const CollaboratorsStyle = styled.div`
  .diving-center-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
  }
  .diving-center-filters {
    display: none;
    &.active {
      display: flex;
    }
    margin-bottom: 60px;
    &__item {
      margin-right: 20px;
      width: 213px;
    }
  }
`;
