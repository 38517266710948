import services from './services';

export const deleteSale = (saleId: string, params?: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint: `sales/${saleId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};
