import * as React from 'react';
import { ItemTagStyle } from './item-tag-style';
import InfoText from '../text/info-text/info-text';
import { images } from '../../assets/images';

export interface IItemTagProps {
  text: string;
  onClick: Function;
}

export interface IItemTagState {}

export default class ItemTag extends React.Component<IItemTagProps, IItemTagState> {
  constructor(props: IItemTagProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { text, onClick } = this.props;
    return (
      <ItemTagStyle>
        <div className="item-tag-text">
          <InfoText>{text}</InfoText>
        </div>
        <div className="item-tag-delete" onClick={() => onClick()}>
          <img src={images.closeBlueSvg} alt="" />
        </div>
      </ItemTagStyle>
    );
  }
}
