import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import IStoreState from '../../../../../redux/store/IStoreState';
import TextArea from '../../../../../components/form-components/input/textarea';
import Button from '../../../../../components/buttons/main-button/button';

const CreateActivitiesAdditionalInfo =(props: any): JSX.Element => {

  const [additionalInfo, setAdditionalInfo] = useState<any>(props.additionalInformation);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAdditionalInfo(value)
  };

  const handleNext = () => {
    const { onNext } = props;
    const info = {
      es: additionalInfo
    }
    onNext(info);
  };

  return (
    <>
      <TextArea
        value={additionalInfo}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}/>
      <div className="modal-footer" style={{marginTop: '2em'}}>
        <Button
          onClick={() => handleNext()}
          text="Editar"
          type={'primary'}
        />
      </div>
    </>
  );
};

function mapStateToProps(state: IStoreState) {
  return {};
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(CreateActivitiesAdditionalInfo));
