import styled, { css } from "styled-components";

export const MenuTextContainer = styled.p`
  font-family: "plain";
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  ${props => css`
    color: ${props.color};
  `}
`;
