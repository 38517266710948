import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const MyProfileStyle = styled.div`
  .my-profile-text {
    margin-bottom: 48px;
  }

  .my-profile-subtitle {
    color: ${colors['NE-002/100']};
    margin-bottom: 32px;
  }
  .my-profile-user {
    display: flex;
  }
  .my-profile-user-avatar {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 28px;
    img {
      width: 100%;
      min-height: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .my-profile-user-info {
    margin-bottom: 80px;
    &__name  {
      margin-bottom: 16px;
    }
    &__email {
      margin-bottom: 12px;
    }
    &__link {
      margin-top: 30px;
      margin-bottom: 12px;
    }
    &__pass  {
      margin-bottom: 48px;
      display: flex;
      &__item {
        min-width: 7px;
        min-height: 7px;
        border-radius: 100%;
        background: ${colors['NE-002/100']};
        margin-right: 8px;
      }
    }
    &__buttons {
      display: flex;
      &__item {
        margin-right: 12px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .my-profile-language {
    width: 468px;
    margin-bottom: 80px;
  }
`;
