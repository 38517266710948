import React, { Component } from 'react';
import { PaginationContainer } from './pagination-style';
import { images } from '../../assets/images';

interface PaginationState {
  page: number;
  previous: string;
  next: string;
}

interface PaginationProps {
  page: number;
  callback: (page: number) => void;
  limit: number;
}

class Pagination extends Component<PaginationProps, PaginationState> {
  state: PaginationState = {
    page: 1,
    previous: '',
    next: '',
  };

  componentDidMount() {
    this.setState({ previous: this.props.page === 1 ? 'limit' : '', page: this.props.page });
  }

  componentDidUpdate(prevProps: PaginationProps) {
    if (this.props.page !== this.state.page) {
      this.setState({ previous: this.props.page === 1 ? 'limit' : '', page: this.props.page });
    }
  }

  previous = (skip: number = 1, first: boolean = false) => {
    if (this.state.page !== 1) {
      const auxPage: number = first ? 2 : this.state.page;
      let previous;
      previous = auxPage === 2 ? 'limit' : '';
      this.setState({ previous, page: auxPage - 1, next: '' });
      this.props.callback(auxPage - 1);
    }
  };

  next = (skip: number = 1, last: boolean = false) => {
    if (this.state.page !== Number(this.props.limit)) {
      const auxPage = last ? this.props.limit - 1 : this.state.page;
      let next;
      next = auxPage === Number(this.props.limit) ? 'limit' : '';
      this.setState({ next, page: auxPage + 1, previous: '' });
      this.props.callback(auxPage + 1);
    }
  };

  render() {
    const { limit } = this.props;
    const { previous, page } = this.state;

    return (
      <PaginationContainer>
        <div className="pagination-container">
          <div className="pagination-left">
            <span onClick={() => this.previous(1)} className={previous}>
              <img src={images.arrowLeft2Svg} />
            </span>
          </div>

          <div className="pagination-main">
            {page > 1 && <span onClick={() => this.previous(0, true)}>1</span>}
            {page > 3 && (
              <div className="dots-container">
                <div className="dots-item">...</div>
              </div>
            )}
            {page > 2 && <span onClick={() => this.previous(1)}>{page - 1}</span>}

            <span className="actual">{page}</span>

            {page + 1 < limit && <span onClick={() => this.next(1)}>{page + 1}</span>}
            {page + 2 < limit && (
              <div className="dots-container">
                <div className="dots-item">...</div>
              </div>
            )}
            {page < limit && <span onClick={() => this.next(0, true)}>{limit}</span>}
          </div>

          <div className="pagination-right">
            <span onClick={() => this.next(1)} className={Number(limit) === Number(page) ? 'limit' : ''}>
              <img src={images.arrowRight2Svg} />
            </span>
          </div>
        </div>
      </PaginationContainer>
    );
  }
}

export default Pagination;
