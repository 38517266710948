import * as React from 'react';
import { CardBonusStyle } from './card-bonus.style';
import TitleText from '../text/title-text/title-text';
import LabelText from '../text/label-text/label';
import HeadDestacadoText from '../text/head-destacado-text/head-destacado-text';

export interface ICardBonusProps {
  title: string;
  subtitle: string;
  className?: string;
}

export default class CardBonus extends React.Component<ICardBonusProps, {}> {
  render() {
    const { title, subtitle, className } = this.props;
    return (
      <CardBonusStyle className={className}>
        <div className="card-bonus-title">
          {className === 'blue' ? <HeadDestacadoText>{title}</HeadDestacadoText> : <TitleText>{title}</TitleText>}
        </div>
        <div className="card-bonus-subtitle">
          <LabelText>{subtitle}</LabelText>
        </div>
      </CardBonusStyle>
    );
  }
}
