import React, { Component } from 'react';
import { ModalCollaboratorFormContainer } from './modal-collaborator-form-style';
import MainButton from '../../../components/main-button';
import ModalContainer from '../../../components/modals/modal-container';
import { FormGrid, FormGridRow, FormGridItem } from '../../main-layout-style';
import InputBox from '../../../components/input-box';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import PhoneBox from '../../../components/phone-box/phone-box';
import InputUploadFiles from '../../../components/input-upload-file/input-upload-file';
import { capitalizeFirstLetter } from '../../../utils/other';
import { isValidEmail, isValidURL, isValidPhone, isValidForm } from '../../../utils/validation';
import { createCollaborator, uploadCollaboratorLogo } from '../../../services/collaborator';
import { ICollaboratorForm } from '../../../models/collaborator-forms';
import SelectBox from '../../../components/select-box';
import { LANGUAGES } from '../../../constants/languages';

interface ModalCollaboratorFormProps {
  title: string;
  showModal: boolean;
  organizationType: string;
  onAccept: () => void;
  onCancel: () => void;
}

interface ModalCollaboratorFormState {
  name: string;
  email: string;
  phoneNumber: string;
  phonePrefix: string;
  website: string;
  contactPersonName: string;
  contactPersonSurname: string;
  contactPersonEmail: string;
  contactPersonPhoneNumber: string;
  contactPersonPhonePrefix: string;
  languages: string[];
  logo: any;
  errors: {
    name: string;
    email: string;
    phoneNumber: string;
    phonePrefix: string;
    website: string;
    contactPersonName: string;
    contactPersonSurname: string;
    contactPersonEmail: string;
    contactPersonPhoneNumber: string;
    contactPersonPhonePrefix: string;
    logo: string;
  };
}

class ModalCollaboratorForm extends Component<ModalCollaboratorFormProps, ModalCollaboratorFormState> {
  state = {
    name: '',
    email: '',
    phoneNumber: '',
    phonePrefix: '+34',
    website: '',
    contactPersonName: '',
    contactPersonSurname: '',
    contactPersonEmail: '',
    contactPersonPhoneNumber: '',
    contactPersonPhonePrefix: '+34',
    languages: [],
    logo: null,
    errors: {
      name: '',
      email: '',
      phoneNumber: '',
      phonePrefix: '',
      website: '',
      contactPersonName: '',
      contactPersonSurname: '',
      contactPersonEmail: '',
      contactPersonPhoneNumber: '',
      contactPersonPhonePrefix: '',
      logo: '',
    },
  };

  validate(value: string, field: string) {
    const errors = Object.assign(this.state.errors);
    if (value && value !== '') {
      switch (field) {
        case 'contactPersonEmail':
        case 'email':
          errors[field] = isValidEmail(value) ? '' : 'invalid-email';
          break;
        case 'website':
          errors[field] = isValidURL(value) ? '' : 'invalid-format';
          break;
        case 'contactPersonPhoneNumber':
        case 'phoneNumber':
          errors[field] = isValidPhone(value) ? '' : 'invalid-phone';
          break;
        default:
          errors[field] = '';
      }
    } else {
      switch (field) {
        case 'name':
          errors[field] = 'required';
          break;
        default:
          errors[field] = '';
      }
    }
    this.setState({ errors });
  }

  handleInputBlur(value: string, field: string) {
    const state = Object.assign(this.state);
    this.validate(value, field);
    state[field] = value;
    this.setState(state);
  }

  handleInputChange(value: string, field: string) {
    this.handleInputBlur(value, field);
  }

  async submitForm() {
    const { errors, languages } = this.state;
    const { organizationType, onAccept } = this.props;
    const state = Object.assign(this.state);
    Object.keys(errors).forEach(field => {
      this.validate(state[field], field);
    });
    if (isValidForm(errors)) {
      const collaborator: ICollaboratorForm = {
        languages,
        name: state.name,
        email: state.email === '' ? undefined : state.email,
        phone: state.phoneNumber === '' ? undefined : ({ prefix: state.phonePrefix === '' ? undefined : state.phonePrefix, number: state.phoneNumber }),
        website: state.website === '' ? undefined : state.website,
        type: organizationType === '' ? undefined : organizationType,
        contactPerson: (state.contactPersonName === '' || state.contactPersonSurname === '' || state.contactPersonEmai === '' || state.contactPersonPhoneNumber === '') ? undefined : {
          name: state.contactPersonName,
          surname: state.contactPersonSurname,
          email: state.contactPersonEmail,
          phone: {
            prefix: state.contactPersonPhonePrefix,
            number: state.contactPersonPhoneNumber,
          },
        },
      };

      try {
        const formData = new FormData();
        formData.set('logo', state.logo);
        const resp: any = (await createCollaborator(collaborator)).data;
        if (state.logo) {
          await uploadCollaboratorLogo(resp.id, formData);
        }
        onAccept();
      } catch (err) { }
    }
  }

  render() {
    const { errors } = this.state;
    const { title, onCancel } = this.props;
    return (
      <ModalContainer title={title} modalClose={() => onCancel()} active={true}>
        <ModalCollaboratorFormContainer className={''}>
          <div className="modal-certifier-form">
            <div className="modal-certifier-form__left">
              <div className="modal-certifier-form__title">
                <BodyDestacadoText>Datos generales</BodyDestacadoText>
              </div>
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Nombre de la certificadora"
                      className="rounded-title"
                      required={true}
                      labelText="Nombre de la certificadora"
                      type="text"
                      value=""
                      errorCode={errors['name']}
                      onChange={value => {
                        this.handleInputChange(value, 'name');
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Email"
                      className="rounded-title"
                      required={true}
                      labelText="Email"
                      type="text"
                      value=""
                      errorCode={errors['email']}
                      onChange={value => {
                        this.handleInputChange(value, 'email');
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <PhoneBox
                      placeholder="Teléfono"
                      className="rounded-title"
                      required={true}
                      labelText="Teléfono"
                      errorPrefix={errors['phonePrefix']}
                      errorNumber={errors['phoneNumber']}
                      onChange={(field, value) => {
                        this.handleInputChange(value, field);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      placeholder="Web"
                      className="rounded-title"
                      required={true}
                      labelText="Web"
                      type="text"
                      value=""
                      errorCode={errors['website']}
                      onChange={value => {
                        this.handleInputChange(value, 'website');
                      }}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-2">
                    <SelectBox
                      labelText="Idiomas"
                      className="rounded-title"
                      required={true}
                      placeholder="Idiomas"
                      optionKey={'value'}
                      optionValue={'label'}
                      optionsText={LANGUAGES}
                      multiple={true}
                      onChangeMultiple={languages => this.setState({ languages })}
                      onChange={() => { }}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
            <div className="modal-certifier-form__right">
              <div className="modal-certifier-form__title">
                <BodyDestacadoText>Datos persona de contacto</BodyDestacadoText>
              </div>
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Nombre"
                      className="rounded-title"
                      required={true}
                      labelText="Nombre"
                      type="text"
                      value=""
                      errorCode={errors['contactPersonName']}
                      onChange={value => {
                        this.handleInputChange(value, 'contactPersonName');
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Apellidos"
                      className="rounded-title"
                      required={true}
                      labelText="Apellidos"
                      type="text"
                      value=""
                      errorCode={errors['contactPersonSurname']}
                      onChange={value => {
                        this.handleInputChange(value, 'contactPersonSurname');
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Email"
                      className="rounded-title"
                      required={true}
                      labelText="Email"
                      type="text"
                      value=""
                      errorCode={errors['contactPersonEmail']}
                      onChange={value => {
                        this.handleInputChange(value, 'contactPersonEmail');
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <PhoneBox
                      placeholder="Teléfono"
                      className="rounded-title"
                      required={true}
                      labelText="Teléfono"
                      errorPrefix={errors['contactPersonPhonePrefix']}
                      errorNumber={errors['contactPersonPhoneNumber']}
                      onChange={(field, value) => {
                        this.handleInputChange(value, `contactPerson${capitalizeFirstLetter(field)}`);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>

          <div className="item-modal-message-container">
            <InputUploadFiles
              className="dark-blue-label"
              label="Logotipo"
              multiple={false}
              errorCode={errors['logo']}
              required={true}
              onChange={files => {
                this.handleInputChange(files[0] ? files[0].data : null, 'logo');
              }}
            />

            <div className="item-modal-message-bottom">
              <div className="item-modal-message-bottom__left">
                <div className="item-modal-message-button">
                  <MainButton
                    type={'primary'}
                    text="Guardar"
                    onClick={() => {
                      this.submitForm();
                    }}
                  />
                </div>
                <div className="item-modal-message-button">
                  <MainButton type={'secondary'} text="Cancelar" onClick={() => onCancel()} />
                </div>
              </div>
            </div>
          </div>
        </ModalCollaboratorFormContainer>
      </ModalContainer>
    );
  }
}

export default ModalCollaboratorForm;
