import React, { useEffect } from 'react';
import { ButtonStyle } from './button-style';
import { filterSVG } from '../../../utils/filterSVG';
import { BodyDestacadoTextContainer } from '../../text/body-destacado-text/body-destacado-text-styles';

export interface IButtonProps {
  className?: string;
  leftIcon?: string;
  rightIcon?: string;
  disabled?: boolean;
  type?: 'disabled' | 'error' | 'primary' | 'secondary' | 'total' | 'download';
  onClick: (ev?: any) => void;
  overtext?: string;
  text?: string;
  dates?: string;
  iconSingle?: string;
  colorIcon?: string;
}

const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  const { className, overtext, text, type, leftIcon, rightIcon, onClick, disabled, iconSingle, colorIcon, dates } = props;

  return (
    <ButtonStyle
      isIconSingle={iconSingle ? true : false}
      className={`${className} ${type} ${disabled ? 'disabled' : ''}`}
      filterSVG={filterSVG(colorIcon)}
      onClick={(ev: any) => (onClick ? onClick(ev) : '')}
    >
      {leftIcon && (
        <div className="button-left-icon">
          <img src={leftIcon ? leftIcon : ''} alt="" />
        </div>
      )}
      {!iconSingle && text ? (
        <div className="button__head">
          <div className="button-overtext">{overtext}</div>
          <div className="button-text">{!dates && text} {dates && dates}</div>
        </div>
      ) : (
        <div className="button-left-icon">
          <img src={iconSingle ? iconSingle : ''} alt="" />
        </div>
      )}
      {rightIcon && (
        <div className="button-right-icon">
          <img src={rightIcon ? rightIcon : ''} alt="" />
        </div>
      )}
    </ButtonStyle>
  );
};

export default Button;

// añadir props alt??
