import * as React from 'react';
import { CreateActivitiesGeneralDataTrainingStyle } from './create-activities-general-data-training-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../main-layout-style';
import InputBox from '../../../../../components/input-box';
import { images } from '../../../../../assets/images';
import SelectBox from '../../../../../components/select-box';
import { IDropdown } from '../../../../../models/generic';
import { DiveDisciplines, DiveCourseTypes } from '../../../../../constants/dive-dropdowns';
import SingleButton from '../../../../../components/single-button/single-button';
import MainButton from '../../../../../components/main-button';
import { getCollaboratorCourses } from '../../../../../services/collaborator';
import { ICourseGeneralData } from '../../../../../models/activity-template';
import { getCentre } from '../../../../../services/centre';
import { createCategory, getCategories } from '../../../../../services/categories';

export interface ICreateActivitiesGeneralDataTrainingProps {
  generalData?: ICourseGeneralData;
  onNext: (generalData: ICourseGeneralData) => void;
  onPrev?: () => void;
  centreId: string;
}

export interface ICreateActivitiesGeneralDataTrainingState {
  generalData: ICourseGeneralData;
  selectedCertifier: number;
  hasCollaborator: boolean;
  courses: IDropdown[];
  certifiers: IDropdown[];
  categories: any[];
  errors: { [key: string]: string };
}

export default class CreateActivitiesGeneralDataTraining extends React.Component<
  ICreateActivitiesGeneralDataTrainingProps,
  ICreateActivitiesGeneralDataTrainingState
> {
  constructor(props: ICreateActivitiesGeneralDataTrainingProps) {
    super(props);
    this.state = {
      generalData: {
        name: '',
        discipline: '',
        minDivers: NaN,
        maxDivers: NaN,
        category: 0, //TODO, Cambiar en un futuro.
        certifier: NaN,
        course: 0,
        courseType: '',
      },
      selectedCertifier: NaN,
      hasCollaborator: false,
      certifiers: [],
      courses: [],
      categories: [],
      errors: {
        name: '',
        discipline: '',
        minDivers: '',
        category: '',
        maxDivers: '',
        certifier: '',
        course: '',
        courseType: '',
      },
    };
  }

  componentDidMount() {
    const { generalData } = this.props;
    this.getCategories();
    if (generalData) {
      const selectedCertifier = generalData.certifier ? generalData.certifier : NaN;
      this.setState({ generalData, selectedCertifier }, () => {
        this.getCertifiers();
      });
    } else {
      this.getCertifiers();
    }
  }

  componentDidUpdate(prevProps: ICreateActivitiesGeneralDataTrainingProps) {
    const { generalData } = this.props;

    if (generalData && prevProps.generalData !== generalData) {
      const selectedCertifier = generalData.certifier ? generalData.certifier : NaN;
      this.setState({ generalData, selectedCertifier });
    }
  }

  async getCategories() {
    const categoriesContent = (await getCategories()).data.data.map((category: any) => {
      return { "value": category.id.toString(), "label": category.name }
    });

    let categories: any = [];
    categoriesContent.forEach(function (category: any) {
      if (category.value === '2' || category.value === '3') {
        categories.push({ "value": category.value.toString(), "label": category.label });
      }

    });

    this.setState({ categories });

    return categories;
  }


  async getCourses() {
    const { courses, selectedCertifier, generalData } = this.state;
    if (selectedCertifier && generalData.discipline !== '' && generalData.courseType !== '') {
      const params = {
        filter: {
          orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        },
      };
      const resp = await getCollaboratorCourses(selectedCertifier, params);
      const generalDataNew = Object.assign(generalData);

      if (resp.data) {
        const coursesDropdown = resp.data.data
          .filter(
            (course: any) => course.discipline === generalData.discipline && course.kind === generalData.courseType
          )
          .map((course: any) => {
            return { value: course.id.toString(), label: course.name };
          });
        this.setState({ courses: coursesDropdown, generalData: generalDataNew });
      }
    }
  }

  async getCertifiers() {
    const { centreId } = this.props;
    const { certifiers } = this.state;
    if (certifiers.length === 0) {
      const params = {
        filter: {
          relations: ['certifiers', 'certifiers.certifier'],
        },
      };
      const resp = await getCentre(centreId, params);
      const certifiersDropdown = resp.data.certifiers
        .map((centreCert: any) => {
          if (centreCert.certifier) {
            return { value: centreCert.certifier.id.toString(), label: centreCert.certifier.name };
          }
        })
        .filter((cert: any) => !!cert);
      this.setState({ certifiers: certifiersDropdown }, () => this.getCourses());
    }
  }

  validate(field: string, value: string | number, required: boolean) {
    if (required && (!value || value === '')) return 'required';
    return '';
  }

  handleChange(field: string, value: string | number, required: boolean, subfield?: string) {
    const { generalData, errors } = Object.assign(this.state);
    if (subfield) generalData[subfield][field] = value;
    else generalData[field] = value;
    errors[field] = this.validate(field, value, required);
    return new Promise<void>((res, rej) => {
      this.setState({ generalData, errors }, () => {
        res();
      });
    });
  }

  handleNext() {
    const { onNext } = this.props;
    const { generalData, errors, selectedCertifier } = Object.assign(this.state);
    const requiredFields = ['name', 'discipline', 'category', 'course'];
    requiredFields.forEach(field => {
      errors[field] = this.validate(field, generalData[field], true);
    });
    this.setState({ errors });
    if (Object.values(errors).every(err => err === '')) {
      generalData.certifier = selectedCertifier;
      onNext(generalData);
    }
  }

  render() {
    const { onPrev } = this.props;
    const { generalData, errors, courses, certifiers, selectedCertifier, categories } = this.state;

    return (
      <CreateActivitiesGeneralDataTrainingStyle>
        <FormGrid>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                errorCode={errors.name}
                placeholder="Nombre de la actividad"
                className="rounded-title"
                type="email"
                value={generalData.name}
                required={true}
                labelText="Nombre"
                onChange={value => this.handleChange('name', value, true)}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-2">
              <SelectBox
                errorCode={errors.certifiers}
                className="rounded-title"
                labelText="Certificadora"
                placeholder={'Certificadora'}
                required={true}
                initialValue={selectedCertifier ? selectedCertifier.toString() : ''}
                optionsText={certifiers}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={selectedCertifier ? selectedCertifier.toString() : ''}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.setState({ selectedCertifier: parseInt(value, undefined) }, () => this.getCourses())}
              />
            </FormGridItem>
            <FormGridItem type="col-2">
              <SelectBox
                errorCode={errors.discipline}
                className="rounded-title"
                labelText="Disciplina"
                placeholder={'Disciplina'}
                required={true}
                initialValue={generalData.discipline}
                optionsText={DiveDisciplines}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={generalData.discipline}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.handleChange('discipline', value, true).then(() => this.getCourses())}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-2">
              <SelectBox
                errorCode={errors.courseType}
                className="rounded-title"
                labelText="Tipo"
                placeholder="Tipo"
                required={true}
                initialValue={generalData.courseType}
                optionsText={DiveCourseTypes}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={generalData.courseType}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.handleChange('courseType', value, false).then(() => this.getCourses())}
              />
            </FormGridItem>
            <FormGridItem type="col-2">
              <SelectBox
                errorCode={errors.course}
                className="rounded-title"
                labelText="Curso/Especialidad"
                disabled={!selectedCertifier || generalData.discipline === '' || generalData.courseType === ''}
                placeholder={'Curso/Especialidad'}
                required={true}
                initialValue={generalData.course ? generalData.course.toString() : ''}
                optionsText={courses}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={generalData.course ? generalData.course.toString() : ''}
                icon={images.arrowDown2BlueSvg}
                onChange={async (value, label) => await this.handleChange('course', parseInt(value, undefined), true)}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-2">
              <SelectBox
                errorCode={errors.category}
                className="rounded-title"
                labelText="Categoría"
                placeholder="Categoría"
                required={true}
                initialValue={generalData.category ? generalData.category.toString() : ''}
                optionsText={categories}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={generalData.category ? generalData.category.toString() : ''}
                icon={images.arrowDown2BlueSvg}
                onChange={value => { this.handleChange('category', parseInt(value, undefined), true) }}
              />
            </FormGridItem>
          </FormGridRow>
          {/*
          <FormGridRow>
            <FormGridItem type="col-2">
              <InputBox
                errorCode={errors.minDivers}
                placeholder="Número mínimo de buceadores"
                className="rounded-title"
                type="number"
                value={generalData.minDivers.toString()}
                required={true}
                labelText="Número mínimo de buceadores"
                onChange={value => this.handleChange('minDivers', parseInt(value, undefined), true)}
              />
            </FormGridItem>
            <FormGridItem type="col-2">
              <InputBox
                errorCode={errors.maxDivers}
                placeholder="Número máximo de buceadores"
                className="rounded-title"
                type="number"
                value={generalData.maxDivers ? generalData.maxDivers.toString() : ''}
                required={true}
                labelText="Número máximo de buceadores"
                onChange={value => this.handleChange('maxDivers', parseInt(value, undefined), false)}
              />
            </FormGridItem>
          </FormGridRow>
          */}
        </FormGrid>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={'Siguiente'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesGeneralDataTrainingStyle>
    );
  }
}
