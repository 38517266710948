import React, { Component } from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import { MainLayoutSection } from '../../main-layout-style';
import { NavigationTabContainer } from '../../../components/navigation-tabs/navigation-tabs-style';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import { RouteComponentProps, withRouter, match } from 'react-router-dom';
import DiverProfileFunctional from './profile/diver-profile-functional';
import DiverDocumentsFunctional from './documents/diver-documents-functional';
import DiverDivesFunctional from './dives/diver-dives-functional';
import ModalConfirmation from '../../../components/modals/modal-confimation/modal-confirmation';
import DiverBonusesLayout from './bonuses/diver-bonuses-layout';
import DiverActivitiesLayout from './activities/diver-activities-layout';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';

export interface HeaderButtons {
  handleMainButtonClick: () => void;
}

interface DiverDetailProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
    staff: string;
  }>;
  agent?: any;
}

interface DiverDetailState {
  renderModal: string;
  activeTab: string;
  searchFilter: string;
  multipleSelect: any;
  isCentre: boolean;
  isOrganization: boolean;
  isCertifier: boolean;
}

class DiverDetail extends Component<DiverDetailProps, DiverDetailState> {
  contentComponent: any;

  static tabs = {
    personal: 'personal',
    documents: 'documents',
    dives: 'dives',
    bonuses: 'bonuses',
    activities: 'activities',
  };

  constructor(props: DiverDetailProps) {
    super(props);
    this.state = {
      renderModal: '',
      activeTab: DiverDetail.tabs.personal,
      searchFilter: '',
      multipleSelect: false,
      isCentre: false,
      isOrganization: false,
      isCertifier: false,
    };

    this.contentComponent = React.createRef();
  }

  componentDidMount() {
    const {
      match: {
        params: { diver, staff },
      },
    } = this.props;
    const isOrganization = !!localStorage.getItem('organizationId');
    const isCertifier = !!localStorage.getItem('isCertifier');
    this.setState({ isOrganization, isCertifier, isCentre: !!diver || !!staff });
  }

  renderHeader() {
    const { activeTab, multipleSelect, isCentre, isOrganization, isCertifier } = this.state;
    const {
      tabs: { personal, documents, dives, bonuses },
    } = DiverDetail;

    const icons = undefined;
    const withSearch = undefined;
    let mainButton = undefined;
    let secondaryButtonRed = undefined;

    switch (activeTab){
      case personal:
        if (!isCentre && !isOrganization) {
          secondaryButtonRed = {
            text: 'Eliminar',
            onClick: () => this.contentComponent.current.handleMainButtonClick(),
          };
        }
        break;
      case documents:
        //withSearch;
        if ((!multipleSelect || multipleSelect === 'reject') && (!isOrganization || isCertifier)) {
          if (!multipleSelect) {
            mainButton = {
              img: images.uploadWhiteSvg,
              text: 'Subir documento',
              onClick: () => this.contentComponent.current.handleMainButtonClick(),
            };
          } else {
            mainButton = {
              img: undefined,
              text: 'Validar',
              onClick: () => this.contentComponent.current.handleMainButtonClick(),
            };
          }
        }
        if ((!isOrganization || isCertifier) && multipleSelect) {
          if (multipleSelect) {
            secondaryButtonRed = {
              text: 'Eliminar',
              onClick: () => this.contentComponent.current.handleSecondaryButtonClick(),
            };
          }
        }
        break;
      case dives:
        if ((!isCentre && !isOrganization) || multipleSelect) {
          mainButton = {
            text: multipleSelect === 'reject' ? 'Validar' : 'Añadir inmersión',
            onClick: () => this.contentComponent.current.handleMainButtonClick(),
          };
        }
        if (!isCentre && !isOrganization) {
          if (multipleSelect) {
            secondaryButtonRed = {
              text: 'Eliminar',
              onClick: () => this.contentComponent.current.handleSecondaryButtonClick(),
            };
          }
        }
        break;
      case bonuses:
        break;
    }

    return (
      <HeaderMain
        goBack={true}
        title={this.props.location.pathname.includes('staff') ? 'Perfil del staff' : 'Perfil del buceador'}
        icons={icons}
        mainButton={activeTab === 'dives' ? undefined : mainButton}
        // mainButton={ mainButton }
        secondaryButtonRed={secondaryButtonRed}
      />
    );
  }

  renderContent() {
    const { activeTab, isCentre, isOrganization, isCertifier } = this.state;
    const {
      match: {
        params: { id, diver },
      },
    } = this.props;
    const {
      tabs: { personal, documents, dives, bonuses, activities },
    } = DiverDetail;


    switch (activeTab) {
      case personal:
        return (
          <DiverProfileFunctional
            ref={this.contentComponent}
            {...this.props}
            isCentre={isCentre}
            isOrganization={isOrganization}
          />
        );
      case documents:
        return (
          <DiverDocumentsFunctional
            ref={this.contentComponent}
            {...this.props}
            onMultipleSelect={(multiple: boolean) => this.setState({ multipleSelect: multiple })}
            isCentre={isCentre}
            isOrganization={isOrganization}
          />
        );
      case dives:
        return (
          <DiverDivesFunctional
            ref={this.contentComponent}
            {...this.props}
            onMultipleSelect={(multiple: boolean) => this.setState({ multipleSelect: multiple })}
            isCentre={isCentre}
            isOrganization={isOrganization}
          />
        );
      case bonuses:
        return (
          <DiverBonusesLayout
            ref={this.contentComponent}
            {...this.props}
            isCentre={isCentre}
            isOrganization={isOrganization}
          />
        );
      case activities:
        return (
          <DiverActivitiesLayout
            ref={this.contentComponent}
            {...this.props}
            isCentre={isCentre}
            isOrganization={isOrganization}
          />
        );
    }
  }

  render() {
    const { agent } = this.props;
    const { activeTab, renderModal, isOrganization, isCertifier } = this.state;

    return (
      <>
        {/* Header */}
        {this.renderHeader()}

        {/* Content */}
        <MainLayoutSection>
          {((isOrganization && isCertifier) || !isOrganization) && (
            <NavigationTabContainer>
              <div
                className={`navigation-item ${activeTab === DiverDetail.tabs.personal && 'active'}`}
                onClick={() => this.setState({ activeTab: DiverDetail.tabs.personal, multipleSelect: false })}
              >
                <BodyDestacadoText>Datos personales</BodyDestacadoText>
              </div>
              <div
                className={`navigation-item ${activeTab === DiverDetail.tabs.documents && 'active'}`}
                onClick={() => this.setState({ activeTab: DiverDetail.tabs.documents, multipleSelect: false })}
              >
                <BodyDestacadoText>Documentos</BodyDestacadoText>
              </div>
              {/*
              <div
                className={`navigation-item ${activeTab === DiverDetail.tabs.dives && 'active'}`}
                onClick={() => this.setState({ activeTab: DiverDetail.tabs.dives, multipleSelect: false })}
              >
                <BodyDestacadoText>Inmersiones</BodyDestacadoText>
              </div>
              {!isOrganization && (
                <div
                  className={`navigation-item ${activeTab === DiverDetail.tabs.bonuses && 'active'}`}
                  onClick={() => this.setState({ activeTab: DiverDetail.tabs.bonuses, multipleSelect: false })}
                >
                  <BodyDestacadoText>Bonos</BodyDestacadoText>
                </div>
              )}
              */}
              <div
                className={`navigation-item ${activeTab === DiverDetail.tabs.activities && 'active'}`}
                onClick={() => this.setState({ activeTab: DiverDetail.tabs.activities, multipleSelect: false })}
              >
                <BodyDestacadoText>Reservas</BodyDestacadoText>
              </div>
            </NavigationTabContainer>
          )}

          {/* Page Content */}
          {this.renderContent()}
        </MainLayoutSection>

        {/* <ModalDocument showModal={true} onClose={() => {}} /> */}

        {/* Modal Confirmacion */}

        <ModalConfirmation
          title="Vas a validar 5 documentos"
          subtitle={'¿estás seguro?'}
          showModal={renderModal === 'modal-confirmation'}
          onAccept={() => this.setState({ renderModal: 'modal-success' })}
          onCancel={() => {}}
        />
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(DiverDetail);
