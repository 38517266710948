import * as React from 'react';
import { CardFacilitiesStyle } from './card-facilities-style';
import { images } from '../../assets/images';
import LabelText from '../text/label-text/label';

export interface ICardFacilitiesProps {
  isActive?: boolean;
  img: string;
  text: string;
  number?: number;
}

export interface ICardFacilitiesState {}
export default class CardFacilities extends React.Component<ICardFacilitiesProps, ICardFacilitiesState> {
  constructor(props: ICardFacilitiesProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { isActive, img, text, number } = this.props;

    // console.log(this.props)
    return (
      <CardFacilitiesStyle className={`${isActive ? 'active' : ''}`}>
        <div className="card-facilities-img">
          {number !== 0 && (
            <div className="card-facilities-img__number">
              <LabelText>{number}</LabelText>
            </div>
          )}
          <img src={img} alt="" />
        </div>
        <div className="card-facilities-text">
          <LabelText>{text}</LabelText>
        </div>
      </CardFacilitiesStyle>
    );
  }
}
