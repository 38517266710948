import React, { Component } from 'react';
import UsersSuperAdminLayout from './users-superadmin-layout';
import services from '../../../services/services';
import { DIVERS_PER_PAGE } from '../../../constants/data';
import { User } from './types';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import 'moment/locale/es.js';
import { copyOf, mergeObject, createFilterRequest } from '../../../utils/other';
import { getDivers } from '../../../services/diver';
import { getCollaborators } from '../../../services/collaborator';
import { IFilter } from '../../../models/filter';

interface DiversFuncionalProps extends RouteComponentProps { }

interface DiversFuncionalState {
  items: User[];
  totalItems: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  page: number;
  orderAscendent: string;
  certifiers: { [key: string]: string }[];
  filterValues: { [key: string]: IFilter };
  filterType: string;
}

class UsersSuperAdminFuncional extends Component<DiversFuncionalProps, DiversFuncionalState> {
  state: DiversFuncionalState = {
    items: [],
    totalItems: 0,
    totalPages: 0,
    searchFilter: '',
    skipPage: 0,
    page: 1,
    orderAscendent: '',
    certifiers: [],
    filterValues: {},
    filterType: '',
  };

  componentDidMount() {
    this.getDivers();
    this.getCertifiers();
  }

  async getDivers() {
    const { skipPage, orderAscendent, filterValues, filterType } = this.state;
    const where = createFilterRequest(filterValues);
    switch (filterType) {
      case 'diver':
        where.isInstructor = false;
        where.isStaff = false;
        break;
      case 'staff':
        where.isStaff = true;
        break;
      case 'instructor':
        where.isInstructor = true;
        break;
      default:
    }


    let params;
    if (filterValues && filterValues.name) {
      params = {
        filter: {
          skip: skipPage,
          search: filterValues.name.value,
          limit: DIVERS_PER_PAGE,
          orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
          relations: ['user', 'staff_organizations', 'staff_centres'],
        },
      };
    } else {
      params = {
        filter: {
          where,
          skip: skipPage,
          limit: DIVERS_PER_PAGE,
          orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
          relations: ['user', 'staff_organizations', 'staff_centres'],
        },
      };
    }

    const params2 = copyOf(params);
    params2.filter.relations = ['user', 'documents', 'documents.certifier'];
    const divers0 = await getDivers(params);
    const divers1 = await getDivers(params2);
    const res = mergeObject(divers0, divers1).data;

    if (res.count > 0) {
      const items: any[] = await Promise.all(
        res.data.map(async (item: any) => {
          const { staff_centres, staff_organizations, user, documents } = item;
          const name = user ? user.name : '-';
          const surname = user ? user.surname : '';
          const ghost = user ? user.ghost : '-';
          const createdAt = user ? user.createdAt : '-';

          const noValidatedDocs =
            !!documents &&
            documents
              .filter((e: any) => !e.validated && !!e.certifier)
              .sort((a: any, b: any) => {
                const keyA = moment(a.createdAt)
                  .utc()
                  .unix();
                const keyB = moment(b.createdAt)
                  .utc()
                  .unix();
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              });
          const pendingDoc = !!noValidatedDocs && !!noValidatedDocs.length;

          return {
            name,
            surname,
            ghost,
            createdAt,
            staff: item.isStaff ? 'Si' : 'No',
            id: item.id,
            certifier: pendingDoc ? noValidatedDocs[0].certifier.name : '-',
            lastInvalidDegree: pendingDoc ? noValidatedDocs[0].name : '-',
          };
        })
      ).then(results => results);

      const totalItems: number = res.count;
      const totalPages: number = Math.ceil(totalItems / DIVERS_PER_PAGE);
      this.setState({ items, totalItems, totalPages });
    } else {
      this.setState({ items: [], totalItems: 0, totalPages: 0 });
    }
  }

  async getCertifiers() {
    try {
      let data: any = await getCollaborators();
      data = data.data.data
        .filter((collaborator: { type: string }) => collaborator.type === 'certifier')
        .map((certifier: { name: string; id: number }) => {
          return {
            label: certifier.name,
            value: certifier.id.toString(),
          };
        });
      this.setState({ certifiers: data });
    } catch (err) {
      console.log(err);
    }
  }

  setPage(page: number) {
    const { totalItems } = this.state;
    const skip: number = DIVERS_PER_PAGE * (page - 1);
    if (skip < totalItems) {
      this.setState({ skipPage: skip, page }, () => {
        this.getDivers();
      });
    }
  }

  handleFilterChange(key: string, value: any, method?: string, collection?: string, concat?: string[]) {
    const { filterValues } = this.state;
    if (!filterValues[key]) filterValues[key] = { value: '' };
    filterValues[key].value = value;
    if (method) filterValues[key].method = method;
    if (concat) filterValues[key].concat = concat;
    if (collection) filterValues[key].collection = collection;

    this.setState({ filterValues, page: 1, skipPage: 0 }, () => {
      this.getDivers();
    });
  }

  handleFilterDelete(key: string) {
    const { filterValues } = this.state;
    delete filterValues[key];
    this.setState({ filterValues, page: 1, skipPage: 0 }, () => {
      this.getDivers();
    });
  }

  render() {
    const { items, totalPages, certifiers, page, totalItems } = this.state;
    return (
      <UsersSuperAdminLayout
        items={items}
        totalPages={totalPages}
        setPage={(page: number) => this.setPage(page)}
        page={page}
        totalItems={totalItems}
        refreshData={() => this.getDivers()}
        certifiers={certifiers}
        onFilterChange={(key: string, value: any, method?: string, collection?: string, concat?: string[]) =>
          this.handleFilterChange(key, value, method, collection, concat)
        }
        onFilterDelete={(key: string) => this.handleFilterDelete(key)}
        onFilterType={(filterType: string) => this.setState({ filterType }, () => this.getDivers())}
      />
    );
  }
}

export default UsersSuperAdminFuncional;
