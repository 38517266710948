import React from 'react';
import { ModalLogOutContainer } from './modal-logout-style';
import MainButton from '../../main-button';
import { images } from '../../../assets/images';
import SubheadText from '../../text/subhead-text/subhead-text';
import ModalContainer from '../modal-container';

interface ModalLogOutProps {
  showModal: boolean;
  onAccept: Function;
  onCancel: Function;
  title: string;
  subtitle: string;
  className?: string;
}

interface ModalLogOutState {}

export default class ModalLogOut extends React.Component<ModalLogOutProps, ModalLogOutState> {
  render() {
    const { onAccept, onCancel, title, subtitle, showModal, className } = this.props;
    return (
      <ModalContainer className={className || 'center'} active={showModal} modalClose={() => onCancel()}>
        <ModalLogOutContainer>
          <div className="modal-logout-img">
            <img src={images.logOutBlueSvg} alt="" />
          </div>
          <div className="modal-logout-title">
            <p className="modal-logout-title__text">{title}</p>
            <p className="modal-logout-title__description">{subtitle}</p>
          </div>
          <div className="modal-logout-buttons">
            <div className="modal-logout-buttons__item">
              <MainButton text="Salir" type="primary" onClick={() => onAccept()} />
            </div>
            {/* <div className="modal-delete-buttons__item">
              <MainButton text="Cancelar" type="secondary" onClick={() => onCancel()} />
            </div> */}
          </div>
        </ModalLogOutContainer>
      </ModalContainer>
    );
  }
}
