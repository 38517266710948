import * as React from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import ModalCreateCatalog from './modal-create-catalog/modal-create-catalog';
import services from '../../../services/services';
import { copyOf, createFilterRequest } from '../../../utils/other';
import { images } from '../../../assets/images';
import { DiveDisciplinesKeyValues, DiveDisciplines } from '../../../constants/dive-dropdowns';
import { COURSE_KINDS_KEY_VALUES, COURSE_KINDS_DROPDOWN } from '../../../constants/course';
import { CERTIFIER_COURSES_PER_PAGE } from '../../../constants/data';
import { getCollaboratorCourses, deleteCollaboratorCourse } from '../../../services/collaborator';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import SelectBox from '../../../components/select-box';
import MainButton from '../../../components/main-button';
import { CertifierCatalogContainer } from './certifier-catalog-layout-style';
import { getLevels } from '../../../services/levels'

interface RouteParams {
  id: string;
}

interface ICertifierCatalogProps extends RouteComponentProps<RouteParams>, WithTranslation { }

interface ICertifierCatalogState {
  adActive: boolean;
  modal: string;
  ads: any;
  activeFilters: boolean;
  certifierId?: string;
  courses: any[];
  searchValue: string;
  disciplineFilter: string;
  kindFilter: string;
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
  selectedCourse?: any;
  edit: boolean;
  profiles: any[];
}

class CertifierCatalog extends React.Component<ICertifierCatalogProps, ICertifierCatalogState> {
  constructor(props: ICertifierCatalogProps) {
    super(props);
    this.state = {
      adActive: false,
      modal: '',
      ads: {},
      activeFilters: false,
      courses: [],
      searchValue: '',
      disciplineFilter: '',
      kindFilter: '',
      total: 0,
      page: 1,
      totalPages: 0,
      skipPage: 0,
      edit: false,
      profiles: [],
    };
  }

  componentDidMount() {
    this.getCertifierCourses();
    this.getDiveProfiles();
  }

  async getCertifierCourses() {
    const { skipPage, searchValue, kindFilter, disciplineFilter } = this.state;
    const certifierId: string | null = localStorage.getItem('organizationId');
    const filters = {
      name: { value: searchValue },
      kind: { value: kindFilter },
      discipline: { value: disciplineFilter },
    };
    const params = {
      filter: {
        where: createFilterRequest(filters),
        relations: ['documents'],
        skip: skipPage,
        limit: CERTIFIER_COURSES_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
      },
    };
    if (certifierId) {
      const resp = await getCollaboratorCourses(certifierId, params);
      const courses = resp.data.data;
      const totalPages: number = Math.ceil(resp.data.count / CERTIFIER_COURSES_PER_PAGE);
      this.setState({ certifierId, courses, totalPages, total: resp.data.count });
    }
  }

  async handleRemove() {
    const { selectedCourse, certifierId } = this.state;
    const {t} = this.props;
    if (selectedCourse && certifierId) {
      await deleteCollaboratorCourse(certifierId, selectedCourse.id.toString());
      services.pushNotification({
        title: t('modalMesages:roles-delete.del'),
        text: t('modalMesages:coursedel'),
        type: 'red',
      });
      this.setState({ modal: '', selectedCourse: undefined }, () => {
        this.getCertifierCourses();
      });
    }
  }

  async getDiveProfiles() {
    const {t}= this.props;
    const resp = await getLevels();
    const profiles = [{ value: '-1', label: t('buttons:noneopt') }].concat(
      resp.data.data
        .map((profile: any) => {
          return { value: profile.id.toString(), label: profile.name };
        })
    );
    this.setState({ profiles });
  }

  getTableRows() {
    const { courses } = this.state;
    const tableRows: any[] = [];
    courses.forEach((course: any) => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.name.value = course.name;
      tableRow.discipline.value = DiveDisciplinesKeyValues[course.discipline];
      tableRow.kind.value = COURSE_KINDS_KEY_VALUES[course.kind];
      tableRow.certifiedDivers.value = course.documents.length;
      tableRow.detail.link = `catalog/${course.id}`;
      tableRow.actions.actions[0].onClick = () => {
        this.setState({ selectedCourse: course, modal: 'create-catalog', edit: true });
      };
      tableRow.actions.actions[1].onClick = () => {
        this.setState({ selectedCourse: course, modal: 'delete-catalog' });
      };
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CERTIFIER_COURSES_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getCertifierCourses();
      });
    }
  }

  timeoutSearch: any;
  handleSearch(value: string | null) {
    const searchValue = value || '';
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      this.setState({ searchValue, page: 1, skipPage: 0 }, () => this.getCertifierCourses());
    }, 500);
  }

  render() {
    const { activeFilters, modal, certifierId, totalPages, selectedCourse, page, edit, total, profiles } = this.state;
    const { t } = this.props;
    return (
      <CertifierCatalogContainer>
        <HeaderMain
          title={t('buttons:catalog')}
          withSearch={true}
          resultCount={total}
          handleSearch={(value: string | null) => this.handleSearch(value)}
          mainButton={{
            text: 'Crear',
            onClick: () => this.setState({ modal: 'create-catalog', selectedCourse: undefined, edit: false }),
          }}
        />
        <MainLayoutSection>
          <div className="catalog-head">
            <div className="catalog-head__filter">
              <MainButton
                onClick={() => this.setState({ activeFilters: !activeFilters })}
                type={activeFilters ? 'secondary-active' : 'secondary'}
                text={t('buttons:filter')}
                iconImg={activeFilters ? images.filtersLightBlueSvg : images.filtersSvg}
              />
            </div>
          </div>

          <div className={`catalog-filters${activeFilters ? ' active' : ''}`}>
            <div className="catalog-filters__item">
              <SelectBox
                labelText=""
                placeholder={t('modalMessages:discipline')}
                className="rounded-filter"
                optionsText={[{ value: '*', label: 'Todas' }].concat(DiveDisciplines)}
                optionKey={'value'}
                optionValue={'label'}
                icon={images.arrowDown2BlueSvg}
                onChange={(value, label) => {
                  const disciplineFilter = value === '*' ? '' : value;
                  this.setState({ disciplineFilter }, () => this.getCertifierCourses());
                }}
              />
            </div>
            <div className="catalog-filters__item">
              <SelectBox
                labelText=""
                placeholder={t('modalMessages:type')}
                className="rounded-filter"
                optionsText={[{ value: '*', label: 'Todos' }].concat(COURSE_KINDS_DROPDOWN)}
                optionKey={'value'}
                optionValue={'label'}
                icon={images.arrowDown2BlueSvg}
                onChange={(value, label) => {
                  const kindFilter = value === '*' ? '' : value;
                  this.setState({ kindFilter }, () => this.getCertifierCourses());
                }}
              />
            </div>
            <div className="catalog-filters__item">
              <SelectBox
                labelText=""
                placeholder={t('modalMessages:levelad')}
                className="rounded-filter"
                optionsText={[{ value: '*', label: t('modalMessages:all') }].concat(profiles)}
                optionKey={'value'}
                optionValue={'label'}
                icon={images.arrowDown2BlueSvg}
                onChange={(value, label) => {
                  const kindFilter = value === '*' ? '' : value;
                  this.setState({ kindFilter }, () => this.getCertifierCourses());
                }}
              />
            </div>
          </div>

          {activeFilters && <div className="ads-detail-table-filters">{/* To Do ADD Filters */}</div>}

          <div className="ads-detail-table__content">
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout
                  columns={this.tableColumns}
                  rows={this.getTableRows()}
                  rowKeys={this.rowKeys}
                  onOrder={() => { }}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                </div>
              )}
            </MainLayoutTableContainer>
          </div>
          {modal === 'create-catalog' && certifierId && (
            <ModalCreateCatalog
              certifierId={certifierId}
              showModal={modal === 'create-catalog'}
              onCancel={() => this.setState({ selectedCourse: undefined, modal: '' })}
              onSuccess={() => {
                if (selectedCourse) services.showModalSuccess(t('modalMessages:edited'), t('modalMessages:coursedit'));
                else services.showModalSuccess(t('modalMessages:created'), t('modalMessages:coursecreated'));
                this.setState({ modal: '', selectedCourse: undefined });
                this.getCertifierCourses();
              }}
              editMode={edit}
              course={selectedCourse}
            />
          )}
          {modal === 'delete-catalog' && (
            <ModalDelete
              title={''}
              subtitle={t('modalMessages:confdel')}
              showModal={modal === 'delete-catalog'}
              onRemove={() => this.handleRemove()}
              onCancel={() => this.setState({ selectedCourse: undefined, modal: '' })}
            />
          )}
        </MainLayoutSection>
      </CertifierCatalogContainer>
    );
  }

  tableColumns = [
    {
      text: this.props.t('modalMessages:name'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:discipline'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:type'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:certdivers'),
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    name: {
      key: '',
      value: 'Manuel Javier Hernández Valverde - Gil',
    },
    discipline: {
      key: '',
      value: 'Centro Estrellita de los mares',
    },
    kind: {
      key: '',
      value: '09/09/2019',
    },
    certifiedDivers: {
      key: '',
      value: 'Rafael Gómez Zuluaga',
    },
    detail: {
      key: 'detail',
      link: '',
    },
    actions: {
      key: 'options',
      value: '',
      actions: [
        {
          label: this.props.t('modalMessages:edit'),
          icon: images.editSvg,
          onClick: () => { },
        },
        {
          label: this.props.t('modalMessages:roles-delete.deleted'),
          icon: images.delete2Svg,
          onClick: () => { },
        },
      ],
    },
  };

  rowKeys = ['name', 'discipline', 'kind', 'certifiedDivers', 'detail', 'actions'];
}
export default withTranslation(['intro', 'buttons', 'modalMessages'])(withRouter(CertifierCatalog));
