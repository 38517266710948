import React, { Component } from 'react';
import { ModalUploadFilesContainer } from './modal-upload-files-style';
import { images } from '../../../assets/images';
import MainButton from '../../main-button';
import FileItemModal from '../../file-item-modal/file-item-modal';
import moment from 'moment';
import 'moment/locale/es.js';
import ModalContainer from '../modal-container';
import BodyText from '../../text/body-text/body-text';
import InfoText from '../../text/info-text/info-text';
import { AttachedFile } from '../../../types/file';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ModalUploadFilesProps extends WithTranslation{
  className?: string;
  showModal: boolean;
  onClose: Function;
  onConfirm: (files: AttachedFile[]) => any;
  t: any;
}

interface ModalUploadFilesState {
  selectedFiles: AttachedFile[];
}

class ModalUploadFiles extends Component<ModalUploadFilesProps, ModalUploadFilesState> {
  state: ModalUploadFilesState = {
    selectedFiles: [],
  };
  componentDidMount() {
    document.body.classList.add('no-scroll');
  }
  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
  }

  countCorrectFiles() {
    const { selectedFiles } = this.state;
    return selectedFiles.reduce((sum, item) => {
      if (!item.error) {
        return sum + 1;
      }
      return sum;
    }, 0);
  }
  // sendFile(messageID: string, file: AttachedFile) {
  //     const endpoint = `files`

  //     const formData = new FormData()
  //     formData.set('attachableType', 'message')
  //     formData.set('attachableId', messageID)
  //     formData.set('file', file.data)

  //     Services.postFormData({
  //         loader: true,
  //         endpoint,
  //         formData,
  //         then: (response: any) => {
  //             console.log(response)
  //         },
  //         catch: (err:any) => {

  //             console.log(err.response)

  //         }
  //     })
  // }

  uploadFile(files: any) {
    const { selectedFiles } = this.state;
    const { t } = this.props;
    const inputFiles: AttachedFile[] = selectedFiles.slice();
    let error = false;
    let imagesError = '';
    let imagesCount = 0;
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1024 * 1024 * 2) {
        // max size 2MB
        error = true;
        imagesError = imagesError + " " + files[i].name;
        imagesCount++;
      }
      else {
        inputFiles.push({
          error,
          data: files[i],
          name: files[i].name,
          size: files[i].size,
          creation_date: moment(files[i].lastModified).format('DD MMM YYYY'),
        });
      }
    }
    if (error) {
      if (imagesCount > 1) {
        alert( t('modalMessages:modal-upload.imgs') + imagesError + t('modalMessages:modal-upload.maxerror'))
      }
      else {
        alert(t('modalMessages:modal-upload.img') + imagesError + t('modalMessages:modal-upload.maxerror'))
      }
    }
    this.setState({ selectedFiles: inputFiles });
  }

  removeFile(item: any) {
    const { selectedFiles } = this.state;
    let tempFiles = selectedFiles.slice();

    tempFiles = tempFiles.filter(e => e !== item);

    this.setState({ selectedFiles: tempFiles });
  }

  confirmFiles() {
    const { selectedFiles } = this.state;
    const { onConfirm } = this.props;

    const tempFiles = selectedFiles.slice();

    this.setState({ selectedFiles: [] }, () => {
      onConfirm(tempFiles);
    });
  }

  onRemoveImgHandler = (file: any) => {
    const { selectedFiles } = this.state;

    this.setState({ selectedFiles: selectedFiles.filter(f => f.data !== file.data) })

  };



  render() {
    const { onClose, showModal, className, t } = this.props;
    const { selectedFiles } = this.state;

    const filesCount = this.countCorrectFiles();

    return (
      <ModalContainer className={className} title="Subir imágenes" active={showModal} modalClose={() => onClose()}>
        <ModalUploadFilesContainer>
          <div className="item-modal-message-container">
            <div className="item-modal-message-drag">
              <form className="uploader" encType="multipart/form-data">
                <input
                  multiple={true}
                  type="file"
                  name="file"
                  id="file"
                  onChange={(e: any) => {
                    this.uploadFile(e.target.files);
                    e.target.value = null;
                  }}
                  accept="application/pdf, application/msword, image/*"
                />
              </form>

              <div className="item-modal-message-drag__dashed">
                <img src={images.dashedBorder} alt="" />
              </div>
              <div className="item-modal-message-drag__icon">
                <img src={images.imagesBlueSvg} alt="" />
              </div>
              <div className="item-modal-message-drag__text">
                <BodyText>
                  {t('modalMessages:model-upload.dragdrop')} <span>{t('modalMessages:model-upload.click')}</span>
                </BodyText>
              </div>
            </div>
            <div className="item-modal-message-disclaimer">
              <InfoText>{t('modalMessages:model-upload.maxsize')}</InfoText>
            </div>
            {/* <div className="item-modal-message-select">
              <DicotomicCheckbox onCheck={() => {}}>
                <InfoText>{`4 de 12 seleccionados`}</InfoText>
              </DicotomicCheckbox>
            </div> */}

            <div className="item-modal-message-docs">
              {selectedFiles.map((item, i) => {
                if (item.data === null || Number(item.size) > 1024 * 1024 * 2) {
                  return;
                }
                else {
                  return (
                    <FileItemModal
                      selectable={true}
                      key={item.data.name}
                      file={item}
                      onSelect={this.onRemoveImgHandler}
                      multiple={true}
                    />
                  );
                }

              })}
            </div>

            <div className="item-modal-message-bottom">
              <div className="item-modal-message-bottom__left">
                <div className="item-modal-message-button">
                  <MainButton
                    type={`primary  ${filesCount <= 0 ? 'disabled' : ''}`}
                    text={t('modalMessages:modal-upload.upload')}
                    onClick={() => {
                      if (filesCount > 0) {
                        this.confirmFiles();
                      }
                    }}
                  />
                </div>
                <div className="item-modal-message-button">
                  <MainButton type={'secondary'} text="Cancelar" onClick={() => onClose()} />
                </div>
              </div>
              {/* <div className="item-modal-message-bottom__right">
                <div className="item-modal-message-button">
                  <MainButton type={'secondary-red'} text="Eliminar" onClick={() => onClose()} />
                </div>
              </div> */}

              {/* <p>{filesCount}</p> */}
            </div>
          </div>
        </ModalUploadFilesContainer>
      </ModalContainer>
    );
  }
}
export default withTranslation(['modalMessages']) (ModalUploadFiles);