import * as React from 'react';
import { RouteComponentProps, withRouter, match } from 'react-router';
import Pagination from '../../../../../components/pagination';
import { withTranslation, WithTranslation } from 'react-i18next';
import classNames from 'classnames';

import HeaderMain from '../../../../../components/header-main/header-main';
import { BillsSuperadminDetailStyle } from './bills-superadmin-detail-style';
import { MainLayoutSection, MainLayoutTableContainer } from '../../../../main-layout-style';
import { images } from '../../../../../assets/images';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';
import { MainTableV2 } from '../../../../../components/main-table-v2/main-table-layout-v2';
import ModalInfo from '../../../../../components/modals/modal-info/modal-info';
import services from '../../../../../services/services';
import LabelText from '../../../../../components/text/label-text/label';
import SubheadDestacadoText from '../../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import Label02Text from '../../../../../components/text/label-02-text/label-02';
import InfoText from '../../../../../components/text/info-text/info-text';
import TextDestacadoText from '../../../../../components/text/text-destacado-text/text-destacado-text';
import { getBill } from '../../../../../services/bills';
import { Charge } from '../../../../../models/bills';
import { getBillDetailChargesColumn } from '../../../data/superadmin-bills-data';
import { getDateMonthString } from '../../../../../utils/time';
import { transformEuNum, transformToEuNum } from '../../../../../utils/utility';

const PAGINATION_LIMIT = 10;

export interface IBillsSuperAdminDetailProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
    billId: string;
  }>;
}

export interface IBillsSuperAdminDetailState {
  modal: string;
  billStatus: string;
  bill: any;
  pages: number;
  page: number;
  count: number;
  firstLoad: boolean;
  charges: Charge[];
}

class BillsSuperAdminDetail extends React.Component<IBillsSuperAdminDetailProps, IBillsSuperAdminDetailState> {
  constructor(props: IBillsSuperAdminDetailProps) {
    super(props);

    this.state = {
      charges: [],
      pages: 1,
      page: 1,
      count: 0,
      firstLoad: false,
      modal: '',
      billStatus: 'paid',
      bill: null,
    };
  }

  componentDidMount() {
    this.fetchBill();
  }

  fetchBill = async () => {
    const {
      match: {
        params: { billId },
      },
    } = this.props;
    const params = {
      filter: {
        relations: ['centre'],
      },
    };
    const { data: bill } = await getBill(billId, params);
    const billStatus = bill.billingType === 'generated' ? 'issued' : '';
    const count = bill.charges.length;
    const pages = Math.ceil(count / PAGINATION_LIMIT);
    const charges = this.getCharges(this.state.page, bill);

    this.setState({ pages, charges, count, bill, billStatus });
  };

  getCharges = (page: number, bill: any = this.state.bill): Charge[] => {
    const sliceBegin = page > 1 ? (page - 1) * PAGINATION_LIMIT : 0;
    const sliceEnd = (page - 1) * PAGINATION_LIMIT + PAGINATION_LIMIT;
    const charges = bill.charges.slice(sliceBegin, sliceEnd);
    return charges;
  };

  downloadBill = () => {
    if (this.state.bill.pdfUrl && this.state.bill.pdfUrl !== '') {
      this.setState({ modal: '' }, () => {
        window.open(this.state.bill.pdfUrl || '', '_blank');
      });
    } else {
      this.setState({ modal: '' }, () => {
        services.pushNotification({
          title: this.props.t('push:error-download-bill.title'),
          text: this.props.t('push:error-download-bill.text'),
          type: 'red-warning',
        });
      });
    }
  };

  calculateTotal = (total: string, iva: number) => {
    return transformToEuNum((+transformEuNum(total) - iva).toFixed(2));
  };

  render() {
    const { t } = this.props;
    const { page, pages, count, bill, billStatus, charges, modal } = this.state;

    const statusClasses = classNames('status', {
      'status--partial-refund': billStatus === 'partial-refund',
      'status--total-refund': billStatus === 'total-refund',
      'status--paid': billStatus === 'paid',
      'status--payment-pending': billStatus === 'payment-pending',
      'status--issued': billStatus === 'issued',
    });

    return (
      <>
        {bill && (
          <BillsSuperadminDetailStyle>
            <HeaderMain
              goBack={true}
              className=""
              title={t('layouts:bills-superadmin-detail.header')}
              // mainButton={{
              //   type: 'primary',
              //   text: t('buttons:create'),
              //   onClick: () => {
              //     this.setState({ modal: 'modal-create' });
              //   },
              //   img: images.createWhiteSvg,
              // }}
              secondaryButton={{
                text: '',
                onClick: () => {
                  this.setState({ modal: 'modal-download' });
                },
                img: images.downloadSvg,
              }}
            />
            <div className={statusClasses}>{t(`layouts:bills.status.${billStatus}`)}</div>

            <div className="bills-detail-hero">
              <div className="bills-detail-hero-left">
                <div className="bills-detail-hero-left-info">
                  <div className="bills-detail-hero-left-info-content">
                    <div className="bills-detail-hero-left-info-content__title">
                      <LabelText>{t('layouts:bills-superadmin-detail.n-bill')}</LabelText>
                    </div>
                    <div className="bills-detail-hero-left-info-content__text">
                      <SubheadDestacadoText>{bill.billId}</SubheadDestacadoText>
                    </div>
                  </div>
                  <div className="bills-detail-hero-left-info-content">
                    <div className="bills-detail-hero-left-info-content__title">
                      <Label02Text>{t('layouts:bills-superadmin-detail.concept')}</Label02Text>
                    </div>
                    <div className="bills-detail-hero-left-info-content__text">
                      <BodyDestacadoText>{bill.concept}</BodyDestacadoText>
                    </div>
                  </div>
                  <div className="bills-detail-hero-left-info-content">
                    <div className="bills-detail-hero-left-info-content__dates">
                      <div>
                        <div className="bills-detail-hero-left-info-content__title">
                          <Label02Text>{t('layouts:bills-superadmin-detail.date-of-issue')}</Label02Text>
                        </div>
                        <div className="bills-detail-hero-left-info-content__text">
                          <BodyDestacadoText>
                            {getDateMonthString(bill.createdAt, undefined, 'dd MMMM, yyyy')}
                          </BodyDestacadoText>
                        </div>
                      </div>

                      {bill.expirationDate && (
                        <div>
                          <div className="bills-detail-hero-left-info-content__title">
                            <Label02Text>{t('layouts:bills-superadmin-detail.date-of-expiration')}</Label02Text>
                          </div>
                          <div className="bills-detail-hero-left-info-content__text">
                            <BodyDestacadoText>
                              {getDateMonthString(bill.expirationDate, undefined, 'dd MMMM, yyyy')}
                            </BodyDestacadoText>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bills-detail-hero-left-card">
                  <div className="bills-detail-hero-left-card-subtitle">
                    <Label02Text>Receptor</Label02Text>
                  </div>
                  <div className="bills-detail-hero-left-card-title">
                    <BodyDestacadoText>
                      {bill.centre ? bill.centre.name : this.props.t('layouts:center-deleted')}
                    </BodyDestacadoText>
                  </div>
                  {bill.centre && (
                    <div className="bills-detail-hero-left-card-info">
                      {bill.vatNumber && (
                        <div className="bills-detail-hero-left-card-info__item">
                          <InfoText>{`VAT ${bill.vatNumber}`}</InfoText>
                        </div>
                      )}
                      <div className="bills-detail-hero-left-card-info__item">
                        <InfoText>{this.props.t('layouts:bills-superadmin-detail.dive-center')}</InfoText>
                      </div>
                      <div className="bills-detail-hero-left-card-info__item">
                        <InfoText>{bill.centre.fiscalAddress}</InfoText>
                      </div>
                      <div className="bills-detail-hero-left-card-info__item">
                        <InfoText>{`${bill.centre.fiscalPostalCode ? `${bill.centre.fiscalPostalCode} -` : ''}  ${bill.centre.fiscalState
                          }`}</InfoText>
                      </div>
                      <div className="bills-detail-hero-left-card-info__item">
                        <InfoText>{bill.centre.fiscalCountry}</InfoText>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="bills-detail-hero-right">
                <div className="bills-detail-hero-right__title">
                  <SubheadDestacadoText>{t('layouts:bills-superadmin-detail.total')}</SubheadDestacadoText>
                </div>
                <div className="bills-detail-hero-right__subtitle">
                  <div className="bills-detail-hero-right__subtitle__title">
                    <BodyDestacadoText>{t('layouts:bills-superadmin-detail.total-base-available')}</BodyDestacadoText>
                  </div>
                  <div className="bills-detail-hero-right__subtitle__number">
                    <BodyDestacadoText>{this.calculateTotal(bill.total, bill.iva)} €</BodyDestacadoText>
                  </div>
                </div>
                <div className="bills-detail-hero-right__subtitle">
                  <div className="bills-detail-hero-right__subtitle__title">
                    <BodyDestacadoText>{t('layouts:bills-superadmin-detail.total-vat')}</BodyDestacadoText>
                  </div>
                  <div className="bills-detail-hero-right__subtitle__number">
                    <BodyDestacadoText>{transformToEuNum(parseFloat(bill.iva).toFixed(2))} €</BodyDestacadoText>
                  </div>
                </div>
                <div className="bills-detail-hero-right-total">
                  <BodyDestacadoText>{t('layouts:bills-superadmin-detail.total-to-pay')}</BodyDestacadoText>
                  <TextDestacadoText>{bill.total} €</TextDestacadoText>
                </div>
              </div>
            </div>
            {charges.length >= 1 && (
              <>
                <div className="bills-detail-table-title">
                  <SubheadDestacadoText>
                    {`${t('layouts:bills-superadmin-detail.lines')} (${count})`}
                  </SubheadDestacadoText>
                </div>
                <MainLayoutSection>
                  <div className="main-layout-table">
                    <MainLayoutTableContainer>
                      <div className="diving-center-table">
                        <MainTableV2 totalPages={pages} columns={getBillDetailChargesColumn(t)} data={charges} />
                      </div>
                      {this.state.pages > 1 && (
                        <div className="diving-center-pagination">
                          <Pagination
                            page={page}
                            callback={(page: any) => {
                              this.setState({ page, charges: this.getCharges(page) });
                            }}
                            limit={this.state.pages}
                          />
                        </div>
                      )}
                    </MainLayoutTableContainer>
                  </div>
                </MainLayoutSection>
              </>
            )}
            <ModalInfo
              type="blue"
              title={[t('modalMessages:modal-bills-download.title')]}
              onClose={() => {
                this.setState({ modal: '' });
              }}
              icon={images.downloadGreenSvg}
              showModal={modal === 'modal-download'}
              buttons={[
                {
                  text: t('buttons:download'),
                  type: 'primary',
                  onClick: this.downloadBill,
                },
              ]}
            />
          </BillsSuperadminDetailStyle>
        )}
      </>
    );
  }
}

export default withTranslation(['intro', 'buttons', 'tables', 'layouts', 'modalMessages', 'push'])(
  withRouter(BillsSuperAdminDetail)
);
