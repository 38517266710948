import { TableColumn } from '../../types/table';
import { Certifier, PersonResponsible } from '../../types/company';

export interface Centre {
  id: string;
  name: string;
  rating: number;
  location: string;
  managers: string[];
}

export interface CentreForm {
  name: string;
  centreId?: string;
  certifiers: Certifier[];
  phonePrefix: string;
  phoneNumber: string;
  email: string;
  website: string;
  personResponsible: PersonResponsible;
  address: string;
  town: string;
  country: string;
  [key: string]: any;
}

export const tableColumns: TableColumn[] = [
  {
    text: '',
    filter: false,
    ascendent: false,
  },
  {
    text: 'Nombre',
    filter: false,
    ascendent: false,
  },
  {
    text: 'Valoración',
    filter: false,
    ascendent: false,
  },
  {
    text: 'Ubicación',
    filter: false,
    ascendent: false,
  },
  {
    text: 'Gestor',
    filter: false,
    ascendent: false,
  },
  {
    text: '',
    filter: false,
    ascendent: false,
  },
  {
    text: '',
    filter: false,
    ascendent: false,
  },
  {
    text: '',
    filter: false,
    ascendent: false,
  },
];

export const rowKeys: string[] = [
  'icon',
  'name',
  'rating',
  'location',
  'manager',
  'manager-count',
  'detail',
  'options',
];
