import React, { Component } from 'react';
import services from '../../services/services';
import { IntroContainer } from './entre-jwt-Styles';
import { images } from '../../assets/images';
import { setAgent, setExpiredToken } from '../../redux/actions';
import i18n from 'i18next';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { initializeFirebase } from '../../services/pushNotifications';
import { getOneUsers } from '../../services/users';
import { APP_WEB_URL } from '../../env';
interface RouteParams { id?: string }


interface IEnterJwtProps extends RouteComponentProps<RouteParams> {
    getUser: () => void
}

class EnterJwt extends React.Component<IEnterJwtProps>{

    constructor(props: IEnterJwtProps) {
        super(props);

        this.state = {
            diverIdState: 0
        }

    }

    componentDidMount() {
        this.goUrl()
    }

    async goUrl() {
        if (this.props.match.params.id) {
            const res = await services.decript(this.props.match.params.id);
            if (res.status !== 201) {
                this.props.history.push("/login");
            }

            localStorage.removeItem('token');
            localStorage.removeItem('centreId');
            localStorage.removeItem('tokenPush');
            localStorage.removeItem('units');
            localStorage.removeItem('userId');
            localStorage.removeItem('diverId');
            const token = res.data.object.token;
            const userId = res.data.object.userId;
            const diverId = res.data.object.diverId;
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId.toString());
            if (diverId) localStorage.setItem('diverId', diverId.toString());
            initializeFirebase(parseInt(res.data.object.userId));
            const resp = await getOneUsers(userId);
            localStorage.setItem('user', JSON.stringify(resp.data));
            window.location.href = res.data.object.url;


        } else {
            this.props.history.push("/login");
            window.location.href = APP_WEB_URL;
        }
    }
    render() {
        return <>
            <IntroContainer>
                <div className="bgImagePanel">
                    <div className="text">
                        <img src={images.logoDiveasapp} alt="Diveasapp" />
                        <img src={images.logOutBlueSvg} alt="" />
                        <h3>Accediendo al panel.... </h3></div>
                </div>
            </IntroContainer>
        </>;
    }

}
export default (withRouter(EnterJwt));
