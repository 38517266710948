import services from './services';
import { IDiveForm } from '../models/dive-forms';
import { DiverProfile, Size } from '../containers/divers/detail/profile/types';

export const setDiverData = (
  userId: string,
  data: DiverProfile | { size: Size } | { aditionalTraining: string[] } | { languages: string[] }
) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `divers/${userId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteDiver = (userId: string | number) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `divers/${userId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const uploadDocument = (userId: string, data: FormData) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `divers/${userId}/documents`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const validateDocument = (documentId: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `diver-documents/${documentId}/validate`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const deleteDocument = (documentId: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `diver-documents/${documentId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createDive = (userId: string, data: IDiveForm) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `divers/${userId}/dives`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const uploadDivePictures = (diveId: string, data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `dives/${diveId}/upload-pictures`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};

export const uploadDiveMainPictures = (diveId: string, data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `dives/${diveId}/upload-main-picture`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};

export const getDocuments = async (diverId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: `divers/${diverId}/documents`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getBookings = async (userId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: `divers/${userId}/bookings`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};


export const getBookingsTableByCentre = async (diverId: number, centre: number, page: number = 0, limit: number = 0) => {
        
  const endpoint = `bookings/table/${centre}`;
  //const params = {"where": {"diverId": diverId}}
  const params:any = {filter: { where: {"diverId": diverId}, orderBy: { key: 'bookingId', direction: 'DESC' } } }
  
  if(page!== 0 ){
      params.filter.limit = limit;
      if(page!==1){
          params.filter.skip = (page-1)*limit;
      }
  }
  return new Promise<any>((resolve, reject) => {
      const args: any = {
          endpoint,
          params, 
          then: resolve,
          catch: reject,
          loader: true,
      };
      services.get(args);
  });

}



export const getBookingsByCentre = async (userId: string, centreId: string,  params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: `divers/${userId}/bookings/byCentre/${centreId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getDivers = (params?: any, loader: boolean = true) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      loader,
      params,
      endpoint: `divers`,
      then: resolve,
      catch: reject,
    };
    services.get(args);
  });
};

export const getDiver = (userId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint: `divers/${userId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const deleteDive = (userId: string, diveId: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `divers/${userId}/dives/${diveId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const getDiveDetail = (diveId: string, relations: string[] = []) => {
  const params = {
    filter: {
      relations,
    },
  };
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint: `dives/${diveId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};
