import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const OopsContentStyle = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .oops-content-title {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 20px;
    color: ${colors['CO-004/100']};
  }

  .oops-content-body {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 300;
    color: ${colors['SE-003/75']};
    max-width: 308px;
    line-height: 28px;
    text-align: center;
  }
`;
