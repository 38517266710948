import styled from 'styled-components';

export const PaginationContainer = styled.div`
  .pagination-container {
    display: flex;
    align-items: center;
  }

  .pagination-left {
    min-width: 20px;
    padding: 0 16px;
    img {
      max-width: 16px;
    }
  }

  .pagination-main {
    display: flex;
    justify-content: center;
    align-items: center;

    /* width: 240px; */
    &__item  {
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      &:last-child  {
        margin-right: 0px;
      }
    }
  }

  .pagination-right {
    min-width: 20px;
    padding: 0 16px;
    img {
      max-width: 16px;
    }
  }

  span {
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    opacity: 1;
    pointer-events: all;

    &.limit {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 16px;
  }

  .dots-item {
    display: flex;
    color: #000019;
    span {
      margin: 0;
      cursor: default;
      width: 4px;
      height: 4px;
      background: #000019;
      border-radius: 50%;
      display: block;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
