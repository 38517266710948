export const DiveKinds = [
  //{ value: 'bautism', label: 'Bautismo de buceo' },
  { value: 'divestay', label: 'Buceo + alojamiento' },
  { value: 'diveboard', label: 'Crucero de buceo vida a bordo' },
  { value: 'diveorg', label: 'Viaje de buceo organizado' },
  { value: 'fromCoast', label: 'Buceo desde costa' },
  { value: 'fromBoat', label: 'Buceo desde embarcación' },
  { value: 'drift', label: 'Buceo a la deriva' },
  { value: 'nightly', label: 'Buceo nocturno' },
  { value: 'marineReserve', label: 'Buceo en reservas marinas' },
  { value: 'bottomCleaning', label: 'Buceo para la limpieza de fondos marinos' },
  { value: 'reef', label: 'Buceo en arrecife' },
  { value: 'flotsam', label: 'Buceo en pecio' },
  { value: 'deep', label: 'Buceo profundo' },
  { value: 'cave', label: 'Buceo en cuevas' },
  { value: 'cenote', label: 'Buceo en cenotes' },
  { value: 'ice', label: 'Buceo en hielo' },
  { value: 'inlandWaters', label: 'Buceo en aguas interiores' },
  { value: 'altitude', label: 'Buceo en altitud' },
  { value: 'mindfull', label: 'Mindfulness Diving' },
  { value: 'obs', label: 'Observación de especies' },
];
export const DiveKindsKeyValues: { [s: string]: string } = {
  //bautism: 'Bautismo de buceo',
  divestay: 'Buceo + alojamiento',
  diveboard: 'Crucero de buceo vida a bordo',
  diveorg: 'Viaje de buceo organizado',
  mindfull: 'Mindfulness Diving',
  fromCoast: 'Buceo desde costa',
  fromBoat: 'Buceo desde embarcación',
  drift: 'Buceo a la deriva',
  nightly: 'Buceo nocturno',
  marineReserve: 'Buceo en reservas marinas',
  bottomCleaning: 'Buceo para la limpieza de fondos marinos',
  reef: 'Buceo en arrecife',
  flotsam: 'Buceo en pecio',
  deep: 'Buceo profundo',
  cave: 'Buceo en cuevas',
  cenote: 'Buceo en cenote',
  ice: 'Buceo en hielo',
  inlandWaters: 'Buceo en aguas interiores',
  altitude: 'Buceo en altitud',
  'inland waters': 'Buceo en aguas interiores',
  'marine reserve': 'Reserva Marina',
  'submerged objects': 'Objetos sumergidos',
  'coast side': 'Pegado a costa',
  depth: 'Bajo hielo',
  museum: 'Museo',
  obs:'Observación de especies'
};
export const DiveTypes = [
  { value: '*', label: 'Todos' },
  { value: 'dive', label: 'Inmersión' },
  { value: 'training', label: 'Formación' },
];
export const DiveTypesKeyValues: any = {
  dive: 'Inmersión',
  training: 'Formación',
};
export const DiveDisciplines = [
  { value: 'nat', label: 'Natación' },
  { value: 'skl', label: 'Snorkel' },
  { value: 'rec', label: 'Buceo recreativo' },
  { value: 'tec', label: 'Buceo técnico' },
  { value: 'apn', label: 'Buceo en apnea' },
  { value: 'pro', label: 'Buceo profesional' },
  { value: 'ctf', label: 'Buceo científico' },
  { value: 'sya', label:'Seguridad y apoyo'}
];

export const DiveDisciplinesKeyValues: { [s: string]: string } = { 
  nat: 'Natación',
  skl: 'Snorkel',
  rec: 'Buceo recreativo',
  tec: 'Buceo técnico',
  apn: 'Buceo en apnea',
  pro: 'Buceo profesional',
  ctf: 'Buceo científico',
  sya: 'Seguridad y apoyo'
};

export const DiveCollaborationType = [
  { value: 'completeActivity', label: 'Actividad completa' },
  { value: 'theoreticalPhase', label: 'Fase teórica' },
  { value: 'practicalPhase', label: 'Fase práctica' },
];

export const DiveMinimumRequisitesTypes = [
  { value: 'diveCount', label: 'Número de inmersiones' },
  { value: 'medicalCertificate', label: 'Certificado médico' },
  { value: 'speciality', label: 'Especialidad' },
  { value: 'respdelaration', label: 'Declaración responsable' },
  { value: 'respdelarationCOVID', label: 'Declaración responsable COVID' },
  { value: 'divinginsurance', label: 'Seguro de buceo' },
  { value: 'other', label: 'Otro' },
];




export const DiveMinimumRequisitesTypesKeyValues: any = {
  diveCount: 'Número de inmersiones',
  medicalCertificate: 'Certificado médico',
  speciality: 'Especialidad',
  respdelaration: 'Declaración responsable',
  respdelarationCOVID: 'Declaración responsable COVID',
  divinginsurance: 'Seguro de buceo',
  other: 'Otro',
};


export const DiveCourseCategory = [
  { value: 'bau', label: 'Bautismo' },
  { value: 'cur', label: 'Curso' },
];

export const DiveCourseValueCategory = [
  { bau: 'Bautismo' },
  { cur: 'Curso' },
];

export const DiveLevel = [
  {value:'sin', label:'Sin experiencia'},
  {value:'ini', label:'Experiencia inicial'},
  {value:'bas', label:'Básico'},
  {value:'int', label:'Intermedio'},
  {value:'exp', label:'Experto'},
  {value:'inst', label:'Instructor'},
]

export const DiveKeyLevel = [{
  sin:'Sin experiencia',
  ini:'Experiencia inicial',
  bas:'Básico',
  int:'Intermedio',
  exp:'Experto',
  inst:'Instructor'
}]

export const DiveCourseTypes = [
  { value: 'kids', label: 'Kids' },
  { value: 'mermaidKids', label: 'Mermaid Kids' },
  { value: 'discover', label: 'Experiencia de descubrimiento' },
  { value: 'levelCourse', label: 'Curso de Nivel' },
  { value: 'specialty', label: 'Especialidad' },
  { value: 'review', label: 'Repaso' },
  { value: 'security', label: 'Seguridad y apoyo' },
  { value: 'adapteddiving', label: 'Buceo adaptado' },
  { value: 'mermaid', label: 'Mermaid' },
  { value: 'crossover', label: 'Cross Over' }
];
export const DiveKeyValueCourseTypes: { [s: string]: string } = {
  kids: 'Kids',
  mermaidKids: 'Mermaid Kids',
  discover: 'Experiencia de descubrimiento',
  levelCourse: 'Curso de Nivel',
  specialty: 'Especialidad',
  review: 'Repaso',
  security: 'Seguridad y apoyo',
  adapteddiving: 'Buceo adaptado',
  mermaid: 'Mermaid',
  crossover: 'Cross Over'
}

export const MIN_AGE = [
  { value: 'training', label: 'Edad mínima conforme a la titulación requerida' },
  { value: '+14', label: '+14' },
  { value: '+16', label: '+16' },
  { value: '+18', label: '+18' },
];

export const MIN_AGE_KEY: any = {
  training: 'Edad mínima conforme a la titulación requerida',
  '+14': '+14',
  '+16': '+16',
  '+18': '+18',
};
