import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CalendarItemContainer = styled.div`
  background: white;
  color: ${colors['PR-001-100']};
  font-size: 13px;
  font-family: 'plain';
  box-shadow: 0px 0px 6px rgba(0, 0, 25, 0.08);
  border-radius: 5px;
  min-width: 260px;
  &.range {
  }
  .arrows {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .calendar-select-type  {
    display: flex;
  }

  .day-selected {
    background: yellow;
  }

  .current-month {
    /* text-transform: uppercase; */
    color: #000019;
    font-family: 'plain';
    font-size: 20px;
    line-height: 28px;
    padding: 6px 4px 6px 0;
    position: relative;
    cursor: pointer;
    .pick-arrow {
      position: absolute;
      z-index: 9;
      right: 0;

      img {
        max-width: 14px;
        cursor: pointer;
      }

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }
    }
  }

  .current-year {
    text-transform: uppercase;
    color: #0d1e3f;
    font-family: 'plain';
    font-size: 12px;
    line-height: 18px;
    padding: 6px 16px 6px 0;
    position: relative;
    cursor: pointer;


    .pick-arrow {
      position: absolute;
      z-index: 9;
      right: 0;

      img {
        max-width: 14px;
        cursor: pointer;
      }

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }
    }
  }

  .calendar-header  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__date  {
      /* width: 200px; */
      display: flex;
      font-weight: 500;
      align-items: flex-end;
    }
  }

  .calendar-arrow-container {
    display: flex;
    align-items: center;
  }

  /* GRID */

  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin-left: 5px;
  }

  /* Calendar */

  .calendar {
    display: block;
    position: relative;
    width: 100%;
    padding: 12px 16px 12px;
  }

  .header {
    /* text-transform: uppercase; */
    font-weight: 700;
    padding: 1.5em 0;
    border-bottom: 1px solid ${colors['PR-001/100']};
  }

  .icon {
    cursor: pointer;
    transition: 0.15s ease-out;

    /* &:hover {
      color: ${colors['PR-001/100']};
    } */

    &:first-of-type {
      margin-left: 1em;
    }

    &:last-of-type {
      margin-right: 1em;
    }
  }

  .calendar-days {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
  }

  .calendar-days-day {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    padding: 4px 0;;
    width: 20px;
    margin: 3px;
    justify-content: center;
    align-items: center;
    color: ${colors['NE-002/100']};
  }

  .cell-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center
  }

  .cell {
    position: relative;    
    min-height: 20px;
    min-width: 20px;
    max-width: 20px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    flex: auto;
    transition: 0.25s ease-out;

    p{margin: 0;}

     /* &.selected {
      border-radius: 50% 0% 0% 50%;
      &:last-of-type {
        border-radius: 0 50% 50% 0;
      }
    }  */

     &::after  {
      content: '';
      height: 20px;
      width: 20px;
      background: ${colors['PR-001/100']};
      color: ${colors['NE-004-0']};
      color: white;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -1;
      opacity: 0;
    } 

   &:hover {
      background: ${colors['PR-001/100']};
      transition: 0.5s ease-out;
      color: white;

      .bg {
        opacity: 0.05;
        transition: 0.5s ease-in;
      }

      &::after  {
        opacity: 0.3;
      }
    } 
  }

   .no-allow {
    color: ${colors['TX-001-30']};
    opacity: 0.5;
    pointer-events: none;
  }

  .selected {
    position: relative;
    z-index: 1;
    color: white;
    background: ${colors['PR-001/100']};
    .bg {
      opacity: 0.05;
      transition: 0.5s ease-in;
    }

    &::after  {
      content: '';
      color: ${colors['NE-004-0']};
      height: 20px;
      width: 20px;
      min-height: 20px;
      max-height: 20px;
      min-width: 20px;
      max-width: 20px;
      background: ${colors['PR-001/100']};
      position: absolute;
      left: 0px;
      top: 0px;
      border-radius: 100%;
      z-index: -1;
    }
    &:hover {
      background: white;
    }
  } 

  /* .selected-multi {
    position: relative;
    z-index: 1;
    color: white;
    background: ${colors['PR-001/100']};
    .bg {
      opacity: 0.05;
      transition: 0.5s ease-in;
    }
    &:hover {
      background: ${colors['CO-004/100']};
    }
  } */

  .in-selected {
    position: relative;
    z-index: 1;
    color: white;
    border-radius: 100%;
    background: ${colors['PR-001/100']};
    .bg {
      opacity: 0.05;
      transition: 0.5s ease-in;
    }

    &::after  {
      content: '';
      color: ${colors['NE-004-0']};
      width: 30px;
      height: 30px;
      background: ${colors['PR-001-100']};
      position: absolute;
      left: -10px;
      top: -10px;

      z-index: -1;
    }
    &:hover {
      background: white;
    } 
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 14px;
      cursor: pointer;
    }
  }

  .select-month-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 9;
    display: none;
    &.active {
      display: block;
    }
  }

  .select-month-container-row {
    display: flex;
    color: ${colors['PR-001-100']};
    height: 25%;
  }

  .select-month-container-item {
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    text-transform: capitalize;

    /* &:hover {
      background: ${colors['NE-004-30']};
    } */
  }

  .year-select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: white;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    z-index: 12;
    display: none;

    &.active {
      display: block;
    }

    li {
      padding: 16px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .year-select-close {
    text-align: right;
    padding: 10px 16px;

    img {
      max-width: 16px;
      cursor: pointer;
    }
  }


  &.anual {
    background: white;
    font-size: 13px;
    font-family: 'plain';
    box-shadow: none;
    border-radius: 0px;
    
    .current-year {
      display: none; 
    }
    .arrows {
      display: none;
    }
  }
`;
