// tslint:disable: jsx-key
// @ts-ignore
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import { useBlockLayout, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { images as icons } from '../../assets/images';
import { CheckboxStyle as CheckboxContainer } from '../form-components/checkbox/checkbox-style';
import { PaginationV2 } from '../pagination-v2/pagination-v2';
import ZeroContent from '../zero-content/zero-content';
import { MainTableContainerV2 } from './main-table-style-v2';
import { useSticky } from './useSticky';

interface MainTableV2Props {
  columns: any[];
  data: any;
  selection?: boolean;
  multipleSelection?: boolean;
  paginate?: boolean;
  totalPages?: number;
  initialSelection?: { data?: number[] | null; key?: string };
  handleCheck?: (selectedState: any) => void;
  handleOrder?: (order: string) => void;
  onPaginate?: (page: number) => void;
  emptyMessage?: any;
  disableSort?: boolean;
}

const IndeterminateCheckbox = React.forwardRef<any, any>(({ indeterminate, checked, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef: any = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const checkboxContainerStyles: any = {
    active: checked,
    'mid-state': indeterminate,
  };

  return (
    <CheckboxContainer className={`table ${cn(checkboxContainerStyles)}`}>
      <div className="checkbox-wrapper">
        <label className="checkbox-container">
          <input type="checkbox" ref={resolvedRef} {...rest} checked={checked} />
          <div className="checkmark-container">
            <div className="checkmark-item" />
          </div>
        </label>
      </div>
    </CheckboxContainer>
  );
});

const InputRadio = (props: any) => {
  const [check, setCheck] = useState<any>({});

  const handleCheck = (value: any) => {
    setCheck({
      [value.target.name]: value.target.value,
    });
  };

  const radioContainerStyles: any = {
    active: check[props.value.toString()],
  };

  return (
    <CheckboxContainer className={`rounded table ${cn(radioContainerStyles)}`}>
      <div className="checkbox-wrapper">
        <label className="checkbox-container">
          <input type="radio" value={props.value} onChange={(evt: any) => handleCheck(evt)} />
          <div className="checkmark-container">
            <div className="checkmark-item" />
          </div>
        </label>
      </div>
    </CheckboxContainer>
  );
};

export const MainTableV2: React.FC<MainTableV2Props> = ({
  columns,
  data,
  selection,
  multipleSelection,
  handleCheck,
  handleOrder,
  initialSelection,
  paginate,
  totalPages,
  onPaginate,
  emptyMessage,
  disableSort,
}) => {
  const mainTable: any = useRef();
  const tableBody: any = useRef();
  const [overflow, setOverflow] = useState<boolean>(false);

  const getWidthColumn = (column: any) => {
    let cellSize: number = 0;
    let offsetColumn: number = 0;

    if (!column.sizeCol) {
      offsetColumn = column.width;
    }

    if (mainTable && mainTable.current && column.sizeCol) {
      cellSize = ((mainTable.current.offsetWidth - offsetColumn) / 16) * column.sizeCol;
      return {
        minWidth: cellSize > 200 ? cellSize : 200,
        width: cellSize > 200 ? cellSize : 200,
        maxWidth: cellSize > 200 ? cellSize : 200,
        display: 'flex',
        alignItems: 'center',
      };
    }

    return {
      minWidth: column.width,
      width: column.width,
      maxWidth: column.width,
      display: 'flex',
      alignItems: 'center',
    };
  };

  const tableOptions = {
    columns,
    data,
    handleOrder,
    manualSortBy: true,
    manualPagination: true,
    pageCount: totalPages,
    disableSortBy: disableSort || false,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    gotoPage,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { selectedRowIds, sortBy, pageIndex },
  }: any = useTable(tableOptions, useSortBy, useSticky, useBlockLayout, usePagination, useRowSelect, (hooks: any) => {
    if (multipleSelection && !selection) {
      hooks.flatColumns.push((flatColumns: any) => [
        {
          id: 'multiple-selection',
          Header: (params: any) => <IndeterminateCheckbox {...params.getToggleAllRowsSelectedProps()} />,
          Cell: (params: any) => <IndeterminateCheckbox {...params.row.getToggleRowSelectedProps()} />,
        },
        ...flatColumns,
      ]);
    }

    if (selection && !multipleSelection) {
      hooks.flatColumns.push((flatColumns: any) => [
        {
          id: 'selection',
          Header: () => null,
          Cell: (cell: any) => {
            return <InputRadio value={cell.row.original.id} name="radio-id" />;
          },
        },
        ...flatColumns,
      ]);
    }
  });

  const detectOverflow = () => {
    const mainTableWidth = mainTable.current && mainTable.current.offsetWidth;
    setOverflow(columns.length * 200 > mainTableWidth);
  };

  useEffect(() => {
    if (handleOrder) {
      const columnToSort = sortBy[0];
      if (columnToSort) {
        const operator = columnToSort.desc ? '-' : '+';
        handleOrder(`${operator}${columnToSort.id}`);
        return;
      }
      handleOrder('');
    }
  }, [sortBy]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      detectOverflow();
    }, 1000);
    window.addEventListener('resize', () => detectOverflow());
    return () => {
      window.removeEventListener('resize', () => detectOverflow());
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    if (handleCheck) {
      const rowsIdsSelected = Object.keys(selectedRowIds);
      const originalIdsSelected = rows
        .filter((row: any) => rowsIdsSelected.includes(row.id))
        .map((r: any) => r.original.id);

      handleCheck(originalIdsSelected);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (initialSelection && initialSelection.data) {
      const selectedIDS = initialSelection.data;
      for (const row of rows) {
        let rowValueToCompare = row.original.id;
        if (initialSelection.key) {
          if (!row.original[initialSelection.key]) {
            throw new Error(`[TableInitSelection] Key ${initialSelection.key} not found on entity`);
          }

          if (!Array.isArray(row.original[initialSelection.key])) {
            throw new Error(`[TableInitSelection] Entity data must be an array.`);
          }

          rowValueToCompare = row.original[initialSelection.key].map((entity: any) => entity.id);
        }

        selectedIDS.forEach(selectedID => {
          if (Array.isArray(rowValueToCompare)) {
            if (rowValueToCompare.includes(selectedID)) {
              row.toggleRowSelected(true);
            }
          } else {
            if (row.original.id === selectedID) {
              row.toggleRowSelected(true);
            }
          }
        });
      }
    }
  }, [initialSelection]);

  const emptyMessageDefault = '¡Oops! No hemos encontrado ningún resultado, prueba con otra búsqueda';
  return rows.length < 1 ? (
    <ZeroContent icon={icons.infoSvg} text={emptyMessage || emptyMessageDefault} />
  ) : (
    <>
      <MainTableContainerV2
        ref={mainTable}
        className={`${overflow ? 'overflow' : ''}
        ${selection || multipleSelection ? 'is-check-sticky' : ''}
        `}
      >
        <div className="table sticky" {...getTableProps()} style={{ width: '100%' }}>
          <div className="header">
            {headerGroups.map((headerGroup: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any) => {
                  return (
                    <div
                      {...column.getHeaderProps([
                        column.getSortByToggleProps(),
                        {
                          className: `th ${column.className}`,
                          style: getWidthColumn(column),
                        },
                      ])}
                    >
                      {['selection', 'multiple-selection'].includes(column.id) ? (
                        <>{column.render('Header')}</>
                      ) : (
                        <p>{column.render('Header')}</p>
                      )}
                      {column.canSort && (
                        <span>
                          <img
                            src={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? icons.filterDown
                                  : icons.filterUp
                                : icons.filterNone
                            }
                          />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body" ref={tableBody}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map((cell: any) => {
                    return (
                      <div
                        {...cell.getCellProps({
                          className: `td ${cell.column.className}`,
                          style: getWidthColumn(cell.column),
                        })}
                      >
                        {['selection', 'multiple-selection'].includes(cell.column.id) || cell.column.custom ? (
                          <>{cell.render('Cell')}</>
                        ) : (
                          <>{cell.render('Cell')}</>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </MainTableContainerV2>
      {paginate && pageCount > 1 && (
        <div className="table-wrapper-navigation">
          <PaginationV2
            page={pageIndex}
            hasPrevious={canPreviousPage}
            hasNext={canNextPage}
            handleNext={nextPage}
            handlePrevious={previousPage}
            goTo={gotoPage}
            limit={pageCount}
            onClick={onPaginate && (page => onPaginate(page))}
          />
        </div>
      )}
    </>
  );
};
