import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import { MainButtonContainer } from '../../../../components/main-button/main-button-style';

export const MyCenterProfileDescriptionStyle = styled.div`
  display: flex;
  justify-content: space-between;

  .my-center-profile-description-left,
  .my-center-profile-description-right {
    min-width: calc(50% - 55px);
  }
  .my-center-profile-description-right {
    &__title {
      color: #bcc5d7;
      padding-left: 10px;
      position: relative;
      margin-bottom: 6px;
      text-transform: uppercase;
      &::before {
        content: '';
        width: 4px;
        height: 4px;
        background: ${colors['PR-001/100']};
        border-radius: 100%;
        position: absolute;
        left: 0px;
        top: 8px;
      }
    }
    &__grid {
      display: grid;
      gap: 13px;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      grid-template-rows: repeat(auto-fill, 120px);
      margin-bottom: 22px;
      &__item {
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        width: 100%;
        height: 100%;
        min-height: 120px;
        max-height: 120px;
        img {
          width: 100%;
          height: auto;
          min-height: 100%;
          object-fit: cover;
        }
        &__veil {
          position: absolute;
          width: 100%;
          height: 100%;
          background: #000019aa;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
        }
      }
    }
    &__add {
      display: flex;
    }
  }

  .my-center-profile-description-left {
    margin-right: 80px;

    .my-center-profile-desxcription-form {
      margin-bottom: 40px;
    }
    .certifier-languages-container {
      width: 100%;
      &__item {
      }
    }
    .center-languages-container {
      display: flex;
      flex-wrap: wrap;
      &__item {
        margin-right: 9px;
        margin-bottom: 9px;
      }
    }
    .feautres-center-container  {
      box-shadow: 0px 0px 6px rgba(0, 0, 25, 0.08);
      border-radius: 5px;
    }
  }

  .my-center-profile-description-right-languages {
    margin-bottom: 40px;
    &__input {
      margin-bottom: 42px;
    }
    &__button  {
      display: flex;
      ${MainButtonContainer} {
        width: auto;
      }
    }
  }

  .my-center-profile-description-right-languages-title {
    margin-bottom: 12px;
    display: flex;

    &__text {
      color: #bcc5d7;
      padding-left: 10px;
      position: relative;

      text-transform: uppercase;
      &::before {
        content: '';
        width: 4px;
        height: 4px;
        background: ${colors['PR-001/100']};
        border-radius: 100%;
        position: absolute;
        left: 0px;
        top: 8px;
      }
    }
  }

  .my-center-profile-description-right-languages-choose {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 12px;
    margin-bottom: 16px;
  }

  .selected-pictures {
    margin-bottom: 2em;
    margin-top: 2em;
    border: 1px solid #ddd;
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 40px;
    &__picture {
      width: 100%;
      height: 110px;
      overflow: hidden;
      border-radius: 5px;
      margin-bottom: 12px;
      position: relative;
    }
    &__delete-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    &__icon {
      border-radius: 100%;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: rgba(13, 30, 63, 0.4);
      transition: all 0.3s ease-in-out;
      &:hover {
        background: rgba(13, 30, 63, 1);
      }
    }
  }
`;
