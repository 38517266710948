import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';
//import { LabelTextContainer } from '../../../../../components/text/label-text/label-text-styles';

export const BillsSuperadminDetailStyle = styled.div`
  .header-main-right-button.secondary {
    min-width: auto;
    .main-button-icon.left {
      margin: 0;
    }
  }

  .status {
    padding: 0 73px;
    margin-top: -40px;

    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;

    &--paid {
      color: ${colors['CO-001/100']};
    }

    &--partial-refund {
      color: ${colors['SE-005/75']};
    }

    &--total-refund {
      color: ${colors['SE-001/100']};
    }

    &--payment-pending {
      color: ${colors['CO-004/100']};
    }

    &--issued {
      color: ${colors['SE-005/100']};
    }
  }

  .bills-detail-hero {
    padding: 24px 40px 58px 48px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .bills-detail-hero-left {
    background: ${colors['SE-004/100']};
    display: flex;
    padding: 20px;
    border-radius: 5px;
    flex: auto;
    align-items: flex-end;

    .bills-detail-hero-left-info  {
      margin-right: 24px;
      flex: auto;
    }
  }
  .bills-detail-hero-left-info-content {
    &__dates {
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      gap-column: 10%;
    }

    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0px;
    }
    &__title {
      text-transform: uppercase;
      p {
        text-align: left;
      }
      color: ${colors['NE-002/100']};
      margin-bottom: 4px;
    }
    &__text {
      color: white;
    }
  }
  .bills-detail-hero-left-card {
    background: #ffffff;
    width: 274px;
    border-radius: 5px;
    padding: 28px 16px 20px;
    align-self: start;
  }
  .bills-detail-hero-left-card-subtitle {
    text-transform: uppercase;
    color: ${colors['NE-002/100']};
    margin-bottom: 12px;
    p {
      text-align: left;
    }
  }
  .bills-detail-hero-left-card-title {
    margin-bottom: 8px;
    color: ${colors['SE-004/100']};
  }

  .bills-detail-hero-left-card-info {
    &__item {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .bills-detail-hero-right {
    width: 364px;
    margin-left: 60px;
    padding: 48px 0 0;
    &__title {
      margin-bottom: 35px;
      padding: 0 18px;
    }
    &__subtitle {
      text-transform: uppercase;

      padding: 0 18px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      &__title  {
        color: ${colors['NE-002/100']};
        & p {
          font-size: 12px !important;
          line-height: 20px !important;
        }
      }
      &:last-child  {
        margin-bottom: 24px;
      }
    }
  }
  .bills-detail-hero-right-total {
    text-transform: uppercase;

    background: ${colors['NE-002/10']};
    padding: 22px 18px;
    display: flex;
    justify-content: space-between;
  }

  .bills-detail-table-title {
    padding: 24px 40px 0px 48px;
  }
`;
