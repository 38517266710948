import styled from 'styled-components';

export const DiveOffersActivitiesStyle = styled.div`
  .dive-offers-activities-head {
    display: flex;
    margin-bottom: 40px;
    justify-content: flex-end;
    &__filter {
    }
  }
  .dive-offers-activities-filters {
    display: none;
    &.active {
      display: flex;
    }
    margin-bottom: 30px;
    &__item {
      margin-right: 20px;
      width: 213px;
    }
  }
`;
