import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const TaxesStyle = styled.div`
  .taxes-text {
    margin-bottom: 78px;
    p {
      margin-bottom: 20px;
      &:last-child  {
        margin-bottom: 0px;
      }
    }
  }

  .taxes-table {
    table  {
      text-align: left;
      thead {
        color: ${colors['NE-002/100']};

        th  {
          padding-bottom: 24px;
          width: 200px;
        }
      }
      tbody {
        td {
          padding-bottom: 20px;
          padding-right: 32px;
        }
      }
    }
  }
`;
