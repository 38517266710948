import * as React from 'react';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import ModalCreateStaff from '../../../components/modals/modal-create-staff/modal-create-staff';
import { RouteComponentProps, match } from 'react-router';
import { getCentreStaff, deleteCentreStaff, getCentre } from '../../../services/centre';
import { copyOf, mergeObject } from '../../../utils/other';
import moment, { Moment } from 'moment';
import { staffRights } from '../../../constants/staff-rights';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import services from '../../../services/services';
import { ContentBlock } from '../../../components/content-block/content-block';
import { connect } from 'react-redux';
import IStoreState from '../../../redux/store/IStoreState';
import { havePermissionsManageStaff } from '../../../services/staff-centre';
const STAFF_PER_PAGE = 10;

export interface IMyCenterStaffProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  t: any;
  staffPermissions?: any;
}

export interface IMyCenterStaffState {
  modal: string;
  selectedStaff?: any;
  staffs?: any[];
  total: number;
  page: number;
  totalPages: number;
  skipPage: number;
  filterName: string;
  owner: number;
}

const ROLES: any = {
  owner: 'Owner',
  manager: 'Gestor total',
  staff: 'Staff',
};

class MyCenterStaff extends React.Component<IMyCenterStaffProps, IMyCenterStaffState> {
  constructor(props: IMyCenterStaffProps) {
    super(props);

    this.state = {
      modal: '',
      total: 0,
      totalPages: 0,
      page: 1,
      skipPage: 0,
      filterName: '',
      owner: 0,
    };
  }

  componentDidMount() {
    this.getCentreStaff();
  }

  async getCentreStaff() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { skipPage, filterName } = this.state;
    const params: any = {
      filter: {
        where: {
          id: { value: 'false', collection: 'deleted' },
        },
        search: filterName,
        relations: ['staff_activities', 'staff_activities.activity', 'activity.centre', 'user'],
        orderBy: [{ key: 'createdAt' }],
      },
      skip: skipPage,
      limit: STAFF_PER_PAGE,
    };

    /*if (filterName !== '') {
      params.filter.where['name'] = {
        value: `%${filterName}%`,
        method: 'iLike',
        collection: 'user',
        concat: ['surname'],
      };
    }*/
    const paramsCentre: any = {
      filter: {
        search: filterName,
        relations: ['staff_centres', 'staff_centres.centre', 'user'],
      },
    };
    const centre = await getCentre(id, paramsCentre);
    let owner = centre.data.user.id;
    const params2 = copyOf(params);
    params2.filter.relations = ['staff_centres', 'staff_centres.centre', 'user'];
    const params3 = copyOf(params);
    params3.filter.relations = ['documents', 'documents.course', 'course.level', 'user'];

    const resp = await getCentreStaff(id, params);
    const resp2 = await getCentreStaff(id, params2);
    const resp3 = await getCentreStaff(id, params3);
    const totalPages: number = Math.ceil(resp.data.count / STAFF_PER_PAGE);
    const staffs = mergeObject(mergeObject(resp, resp2), resp3).data.data;

    // console.log("staffs",staffs)
    let totalStaff:any = [];
    for (let i = 0; i < staffs.length; i++) {
      if (staffs[i].user){
        if(staffs[i].user.id == owner) {
          owner = staffs[i].id;
        }
        totalStaff.push(staffs[i])
      }
    }
    let totalReg = 0;
    if(filterName){
      totalReg = totalStaff.length;
    }
    else{
      totalReg = resp.data.count;
    }
    this.setState({ staffs: totalStaff, totalPages, total: totalReg, owner: owner });
  }

  async handleRemoveStaff() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { selectedStaff } = this.state;
    await deleteCentreStaff(id, selectedStaff.id.toString());
    services.pushNotification({
      title: '¡Eliminado!',
      text: 'Se ha eliminado el staff con éxito',
      type: 'red',
    });
    this.setState({ selectedStaff: undefined, modal: '' });
    this.getCentreStaff();
  }

  getTableRows() {
    const {
      match: {
        params: { id },
      },
      staffPermissions,
    } = this.props;
    const { staffs } = this.state;
    if (!staffs) return [];
    const tableRows: any[] = [];
    const now = moment();
    staffs.forEach((staff: any) => {

        const tableRow = copyOf(this.tableRowTemplate);
        let nextActivity: Moment | undefined;
        const performedActivities = staff.staff_activities
          .filter((act: any) => {
            if (act.activity && act.activity.centre.id == id) {
              const date = moment(act.activity.date);
              return date.isBefore(now);
            } else {
              return false;
            }
          })
          .map((act: any) => {
            return act.activity.name;
          });
        const plannedActivities = staff.staff_activities
          .filter((act: any) => {

            if (act.activity && act.activity.centre.id == id) {
              const date = moment(act.activity.date);
              if (date.isAfter(now) && (!nextActivity || date.isBefore(nextActivity))) {
                nextActivity = date;
              }
              return date.isAfter(now);
            } else {
              return false;
            }
          })
          .map((act: any) => {
            return act.activity.name;
          });
        let highestTitulation = '-';
        let highestTitulationLevel = 0;
        staff.documents.forEach((doc: any) => {
          if (doc.course && doc.course.level && doc.course.level.level > highestTitulationLevel) {
            highestTitulation = doc.name;
            highestTitulationLevel = doc.course.level.level;
          }
        });
        const staffCentre = staff.staff_centres.filter(
          (staffCentre: any) => staffCentre.centre ? staffCentre.centre.id === parseInt(id, undefined) : false
        )[0];
        if(staff.id == this.state.owner){
          staffCentre.role = 'owner';
        }
        const staffCentreRights = [];
        // console.log(staffCentre)
        // console.log("staffCentre::", staffCentre)

        if (staffCentre) {
          if (staffCentre.divers) staffCentreRights.push(staffRights.divers);
          if (staffCentre.sites) staffCentreRights.push(staffRights.sites);
          //if (staffCentre.incidences) staffCentreRights.push(staffRights.incidences);
          //if (staffCentre.messaging) staffCentreRights.push(staffRights.messaging);
          if (staffCentre.manageStaff) staffCentreRights.push(staffRights.manageStaff);
          if (staffCentre.activities) staffCentreRights.push(staffRights.activities);
          //if (staffCentre.deals) staffCentreRights.push(staffRights.deals);
        }

        // console.log("staffCentre::",staffCentre)

        if (staff.picture) tableRow.picture.link = staff.picture;
        else tableRow.picture.value = 'ghost';
        tableRow.name.value = `${staff.user.name} ${staff.user.surname}`;
        // console.log(staffCentre);
        tableRow.role.value = ROLES[staffCentre.role];
        tableRow.rating.value = staff.rating;
        tableRow.titulation.value = highestTitulation;
        if (performedActivities.length > 0) {
          tableRow.act.value = performedActivities[0];
        }
        if (performedActivities.length > 1) {
          tableRow['num-act'].value = {
            title: 'Actividades realizadas',
            items: performedActivities,
          };
        }
        if (plannedActivities.length > 0) {
          tableRow.plan.value = plannedActivities[0];
        }
        if (plannedActivities.length > 1) {
          tableRow['num-plan-act'].value = {
            title: 'Actividades previstas',
            items: plannedActivities,
          };
        }
        tableRow['next-act'].value = nextActivity ? nextActivity.format('DD/MM/YYYY') : '-';
        if (staffCentreRights.length > 0) {
          tableRow.perm.value = staffCentreRights[0];
        }

        if (staffCentreRights.length > 0) {
          tableRow['permissions'].value = {
            title: 'Permisos',
            items: staffCentreRights,
          };
        }
        tableRow.options.value = staff.id;
        if ((!staffPermissions || staffPermissions.manageStaff) && (staffCentre.role != 'owner')) {
          tableRow.options.actions[0].onClick = () => {
            this.setState({ selectedStaff: staffCentre, modal: 'modal-create-staff' });
          };
          tableRow.options.actions[1].onClick = () => {
            this.setState({ selectedStaff: staff, modal: 'modal-delete-staff' });
          };
        } else {
          tableRow.options = {
            key: '',
            value: '',
          };
        }

        if (!havePermissionsManageStaff()) {
          tableRow.options = {
            key: '',
            value: '',
          };
        }

        tableRow.detail.link = `/centres/${id}/staff/${staff.id}`;
        tableRows.push(tableRow);
    });
    return tableRows;
  }


  getTableRow222s() {
    const {
      match: {
        params: { id },
      },
      staffPermissions,
    } = this.props;
    const { staffs } = this.state;
    if (!staffs) return [];
    const tableRows: any[] = [];
    const now = moment();
    staffs.forEach((staff: any) => {

      const tableRow = copyOf(this.tableRowTemplate);
      let nextActivity: Moment | undefined;
      const performedActivities = staff.staff_activities
        .filter((act: any) => {
          if (act.activity && act.activity.centre.id == id) {
            const date = moment(act.activity.date);
            return date.isBefore(now);
          } else {
            return false;
          }
        })
        .map((act: any) => {
          return act.activity.name;
        });
      const plannedActivities = staff.staff_activities
        .filter((act: any) => {

          if (act.activity && act.activity.centre.id == id) {
            const date = moment(act.activity.date);
            if (date.isAfter(now) && (!nextActivity || date.isBefore(nextActivity))) {
              nextActivity = date;
            }
            return date.isAfter(now);
          } else {
            return false;
          }
        })
        .map((act: any) => {
          return act.activity.name;
        });
      let highestTitulation = '-';
      let highestTitulationLevel = 0;
      staff.documents.forEach((doc: any) => {
        if (doc.course && doc.course.level && doc.course.level.level > highestTitulationLevel) {
          highestTitulation = doc.name;
          highestTitulationLevel = doc.course.level.level;
        }
      });


      /*const staffCentre = staff.staff_centres.filter(
        (staffCentre: any) => staffCentre.centre ? staffCentre.centre.id === parseInt(id, undefined) : false
      )[0];*/


      if (staff.id == this.state.owner) {
        staff.staff_centres.role = 'owner';
      } else {
        staff.staff_centres.role = staff.staff_centres.role;
      }

      const staffCentreRights = [];

      if (staff.staff_centres) {
        if (staff.staff_centres.divers) staffCentreRights.push(staffRights.divers);
        if (staff.staff_centres.sites) staffCentreRights.push(staffRights.sites);
        //if (staffCentre.incidences) staffCentreRights.push(staffRights.incidences);
        //if (staffCentre.messaging) staffCentreRights.push(staffRights.messaging);
        if (staff.staff_centres.manageStaff) staffCentreRights.push(staffRights.manageStaff);
        if (staff.staff_centres.activities) staffCentreRights.push(staffRights.activities);
        //if (staffCentre.deals) staffCentreRights.push(staffRights.deals);
      }



      if (staff.picture) tableRow.picture.link = staff.picture;
      else tableRow.picture.value = 'ghost';
      tableRow.name.value = `${staff.user.name} ${staff.user.surname}`;
      tableRow.role.value = staff.staff_centres.role ? ROLES[staff.staff_centres.role] : '';
      tableRow.rating.value = staff.rating;
      tableRow.titulation.value = highestTitulation;
      if (performedActivities.length > 0) {
        tableRow.act.value = performedActivities[0];
      }
      if (performedActivities.length > 1) {
        tableRow['num-act'].value = {
          title: 'Actividades realizadas',
          items: performedActivities,
        };
      }
      if (plannedActivities.length > 1) {
        tableRow.plan.value = plannedActivities[0];
      }
      if (plannedActivities.length > 1) {
        tableRow['num-plan-act'].value = {
          title: 'Actividades previstas',
          items: plannedActivities,
        };
      }
      tableRow['next-act'].value = nextActivity ? nextActivity.format('DD/MM/YYYY') : '-';
      if (staffCentreRights.length > 0) {
        tableRow.perm.value = staffCentreRights[0];
      }

      if (staffCentreRights.length > 1) {
        tableRow['permissions'].value = {
          title: 'Permisos',
          items: staffCentreRights,
        };
      }
      tableRow.options.value = staff.id;
      if ((!staffPermissions || staffPermissions.manageStaff) && (staff.staff_centres.role != 'owner')) {
        tableRow.options.actions[0].onClick = () => {
          this.setState({ selectedStaff: staff.staff_centres, modal: 'modal-create-staff' });
        };
        tableRow.options.actions[1].onClick = () => {
          this.setState({ selectedStaff: staff, modal: 'modal-delete-staff' });
        };
      } else {
        tableRow.options = {
          key: '',
          value: '',
        };
      }

      if (!havePermissionsManageStaff()) {
        tableRow.options = {
          key: '',
          value: '',
        };
      }

      tableRow.detail.link = `/centres/${id}/staff/${staff.id}`;
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = STAFF_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ skipPage: skip, page }, () => {
        this.getCentreStaff();
      });
    }
  }

  searchTimeout: any;
  handleSearch(search: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      const filterName = search ? search : '';
      this.setState({ filterName, page: 1, skipPage: 0 }, () => this.getCentreStaff());
    }, 500);
  }

  render() {
    const { modal, selectedStaff, totalPages, page, total } = this.state;
    const {
      match: {
        params: { id },
      },
      t,
      staffPermissions,
    } = this.props;

    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Staff'}
            withSearch={true}
            resultCount={total}
            handleSearch={(search: string) => this.handleSearch(search)}
            mainButton={
              !staffPermissions || staffPermissions.manageStaff
                ? {
                  img: images.createWhiteSvg,
                  disabled: !havePermissionsManageStaff(),
                  text: 'Invitar staff',
                  onClick: () => this.setState({ selectedStaff: undefined, modal: 'modal-create-staff' }),
                }
                : undefined
            }
          />
          <MainLayoutSection>
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout
                  columns={this.tableColumns}
                  rows={this.getTableRows()}
                  rowKeys={this.rowKeys}
                  onOrder={() => { }}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                </div>
              )}
            </MainLayoutTableContainer>
          </MainLayoutSection>
          {modal === 'modal-create-staff' && (
            <ModalCreateStaff
              centreId={id}
              staff={selectedStaff}
              showModal={modal === 'modal-create-staff'}
              onCancel={() => this.setState({ modal: '' })}
              onSuccess={() => {
                this.getCentreStaff();
                this.setState({ modal: '' });
              }}
            />
          )}
          {modal === 'modal-delete-staff' && (
            <ModalDelete
              title={''}
              subtitle={''}
              showModal={modal === 'modal-delete-staff'}
              onRemove={() => {
                this.handleRemoveStaff();
              }}
              onCancel={() => this.setState({ modal: '' })}
            />
          )}
        </ContentBlock>
      </>
    );
  }

  tableColumns = [
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Rol',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Valoración',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Titulación',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Act. realizadas',
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: false,
    },
    {
      text: 'Act. previstas',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Próxima act.',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Permisos',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    picture: {
      key: 'avatar',
    },
    name: {
      key: 'highlighted-body',
      value: 'Botella de aire',
    },
    role: {
      key: '',
      value: 'manager',
    },
    rating: {
      key: 'rate',
      value: 0,
    },
    titulation: {
      key: '',
      value: 'Pasillo 3, estante 7',
    },
    act: {
      key: '',
      value: '',
    },
    'num-act': {
      key: 'count',
      value: {
        title: 'Actividades realizadas',
        items: [],
      },
    },
    plan: {
      key: '',
      value: '',
    },
    'num-plan-act': {
      key: 'count',
      value: {
        title: 'Actividades previstas',
        items: [],
      },
    },
    'next-act': {
      key: '',
      value: '24/09/2022',
    },
    perm: {
      key: '',
      value: '',
    },
    permissions: {
      key: 'count',
      value: {
        title: 'Permisos',
        items: [],
      },
    },
    detail: {
      key: 'detail',
      value: '/centers/1',
    },
    options: {
      value: 0,
      type: '',
      key: 'options',
      actions: [
        {
          label: 'Editar',
          icon: images.edit3Svg,
        },
        {
          label: 'Eliminar',
          icon: images.delete2Svg,
        },
      ],
    },
  };



  rowKeys = [
    'picture',
    'name',
    'role',
    'rating',
    'titulation',
    'act',
    'num-act',
    'plan',
    'num-plan-act',
    'next-act',
    'perm',
    'permissions',
    'detail',
    'options',
  ];
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(MyCenterStaff);
