import React from 'react';
import { ModalSuccessContainer } from './modal-success-style';
import { images } from '../../../assets/images';
import HeadDestacadoText from '../../text/head-destacado-text/head-destacado-text';
import SubtitleText from '../../text/subtitle-text/subtitle-text';
import ModalContainer from '../modal-container';

// FIGMA
// a/modal/success

interface ModalSuccessProps {
  title: string;
  text: string;
  className?: string;
  showModal: boolean;
  onClose: () => void;
}
interface ModalSuccessState { }
export default class ModalSuccess extends React.Component<ModalSuccessProps, ModalSuccessState> {
  render() {
    const { className, title, text, onClose, showModal } = this.props;
    return (
      <ModalContainer invertClose={true} className="center" modalClose={() => onClose()} active={showModal}>
        <ModalSuccessContainer className={className}>
          <div className="modal-success-pop">
            <div className="modal-success-img">
              <img src={images.modalSuccessJellyNew} alt="" />
            </div>
            <div className="modal-success-title">
              <HeadDestacadoText>{title}</HeadDestacadoText>
            </div>
            <div className="modal-success-text">
              <SubtitleText>{text}</SubtitleText>
            </div>
          </div>
        </ModalSuccessContainer>
      </ModalContainer>
    );
  }
}
