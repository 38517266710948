import styled from 'styled-components';

export const CertifierCatalogContainer = styled.div`
  .catalog-head {
    display: flex;
    justify-content: flex-end;
    &__filter {
      max-width: 120px;
      margin-bottom: 40px;
    }
  }
  .catalog-filters {
    display: none;
    &.active {
      display: flex;
    }
    margin-bottom: 40px;
    &__item {
      margin-right: 20px;
      width: 213px;
    }
  }
`;
