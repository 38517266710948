import services from './services';

export const centreChatLogin = (id: number) => {
  return new Promise<{ data: { jwt: string } }>((resolve, reject) => {
    const args = {
      endpoint: `centres/${id}/chat/login`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getChatUsers = async (emails: string[]) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const params = {
      filter: {
        where: {
          email: {
            method: 'in',
            values: emails,
          },
        },
      },
    };
    const args = {
      params,
      endpoint: `users`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getChatActivities = async (activities: string[]) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const params = {
      filter: {
        where: {
          id: {
            method: 'in',
            values: activities,
          },
        },
      },
    };
    const args = {
      params,
      endpoint: `activities`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const archiveChannel = async (centreId: number, channel: string) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const args = {
      endpoint: `centres/${centreId}/chat/channels/${channel}/archive`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.put(args);
  });
};

export const recoverChannel = async (centreId: number, channel: string) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const args = {
      endpoint: `centres/${centreId}/chat/channels/${channel}/recover`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.put(args);
  });
};

export const deleteChannel = async (centreId: number, channel: string) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const args = {
      endpoint: `centres/${centreId}/chat/channels/${channel}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const openChannel = (centreId: string, data: any) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const args = {
      data,
      endpoint: `centres/${centreId}/chat/open-channel/`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

// Organizations

export const orgChatLogin = (id: number) => {
  return new Promise<{ data: { jwt: string } }>((resolve, reject) => {
    const args = {
      endpoint: `organizations/${id}/chat/login`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const archiveOrgChannel = async (orgId: number, channel: string) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const args = {
      endpoint: `organizations/${orgId}/chat/channels/${channel}/archive`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.put(args);
  });
};

export const recoverOrgChannel = async (orgId: number, channel: string) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const args = {
      endpoint: `organizations/${orgId}/chat/channels/${channel}/recover`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.put(args);
  });
};

export const deleteOrgChannel = async (orgId: number, channel: string) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const args = {
      endpoint: `organizations/${orgId}/chat/channels/${channel}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const openOrgChannel = (orgId: string, data: any) => {
  return new Promise<{ data: { data: any[]; count: number } }>((resolve, reject) => {
    const args = {
      data,
      endpoint: `organizations/${orgId}/chat/open-channel/`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
