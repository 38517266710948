import styled from 'styled-components';
import { CheckboxStyle } from '../../../components/form-components/checkbox/checkbox-style';
import { colors } from '../../../assets/colors';

export const BillsStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .modal-container-content {
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-info-title {
    margin-bottom: 20px;
    text-align: center;

    &__content {
      margin-bottom: 0px;
      color: ${colors['NE-001/100']} !important;
    }
  }
  .search-empty {
    & input {
      color: ${colors['CO-004/100']} !important;
    }
  }

  .bills-title {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'text filters';
    align-items: center;
    margin-bottom: 40px;

    &__result-message {
      font-size: 12px;
      color: ${colors['CO-004/100']};
    }

    &__filters {
      grid-area: filters;
      display: flex;
      justify-self: flex-end;

      & .checkbox-list {
        min-width: max-content;
      }
    }

    &__filter-item {
      margin-right: 12px;
      &:last-child {
        margin-right: 0px;
      }

      ${CheckboxStyle} {
        .checkbox-item--icon {
          width: 22px;
          height: 22px;
        }
      }
    }
    .bills-table {
      margin-bottom: 80px;
    }
  }
  .bills-search-dropdown {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 22px;
    &__title {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
    }

    &__result {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: ${colors['SE-004/75']};
    }
  }

  & .checkbox-list__items-container {
    max-height: 155px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .bill-button {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${colors['NE-002/10']};
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      width: 13.47px;
      height: 13.47px;
    }
  }
`;
