import * as React from 'react';
import { MainLayoutSection } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import { CancellationPolicyStyle } from './cancellation-policy-style';
import CancellationLine from '../../../components/cancellation-line/cancellation-line';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import { NavigationTabContainer } from '../../../components/navigation-tabs/navigation-tabs-style';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import BodyText from '../../../components/text/body-text/body-text';
import TitleText from '../../../components/text/title-text/title-text';
import ModalCancelationPolicy from './modal-cancelation-policy/modal-cancelation-policy';
import { getCancellationPolicies } from '../../../services/cancellation-policies';
import services from '../../../services/services';
import ModalInfo from '../../../components/modals/modal-info/modal-info';
import { images } from '../../../assets/images';
import { POLICIES_KEY_VALUE } from '../../../constants/policies';
import { RouteComponentProps, match } from 'react-router-dom';
import { getCentre } from '../../../services/centre';
import { ContentBlock } from '../../../components/content-block/content-block';

export interface ICancellationPolicyProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  t: any;
}

export interface ICancellationPolicyState {
  section: string;
  renderModal: string;
  policies: [];
  policySelected: any;
  centreCancellation?: any;
  accept: Function;
}

export default class CancellationPolicy extends React.Component<ICancellationPolicyProps, ICancellationPolicyState> {
  constructor(props: ICancellationPolicyProps) {
    super(props);

    this.state = {
      section: '',
      policies: [],
      renderModal: '',
      policySelected: {},
      accept: () => {},
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    // if (id) this.getCentre();
    // else
    this.getCancellationPolicies();
  }

  getCancellationPolicies = async (reload?: boolean) => {
    const { centreCancellation } = this.state;
    try {
      const params = {
        filter: {
          orderBy: [{ key: 'createdAt', direction: 'ASC' }],
        },
      };
      const res: any = await getCancellationPolicies(params);
      const policies = res.data.data;
      if (centreCancellation) {
        Object.entries(centreCancellation).forEach(entry => {
          const type = entry[0];
          const active = entry[1];
          policies.filter((pol: any) => pol.type === type)[0].active = active;
        });
      }
      if (!reload) {
        const policySelected = policies[0] ? policies[0] : {};
        this.setState({ policySelected, policies, section: policySelected.id });
      } else {
        const policySelected = policies.find((d: any) => d.id === this.state.section);
        this.setState({ policySelected, policies });
      }
    } catch (err) {}
  };

  getCentre = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const resp = await getCentre(id);
    const centreCancellation = resp.data.cancellation;
    this.setState({ centreCancellation }, () => {
      this.getCancellationPolicies();
    });
  };

  render() {
    const {
      match: {
        params: { id },
      },
      t
    } = this.props;
    const { section, renderModal, policies, policySelected, accept, centreCancellation } = this.state;

    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Políticas de cancelación'}
            mainButton={
              !id
                ? { text: 'Administrar', onClick: () => this.setState({ renderModal: 'modal-cancelation' }) }
                : undefined
            }
          />
          <MainLayoutSection>
            <CancellationPolicyStyle>
              <div className="cancellation-levels-tabs">
                <NavigationTabContainer>
                  {policies.map((policy: any) => (
                    <div
                      key={`policy-${policy.id}`}
                      className={`navigation-item ${section === policy.id && 'active'}`}
                      onClick={() => this.setState({ section: policy.id, policySelected: policy })}
                    >
                      <BodyDestacadoText>{POLICIES_KEY_VALUE[policy.type]}</BodyDestacadoText>
                    </div>
                  ))}
                </NavigationTabContainer>
              </div>
              <div className="cancellation-levels-line">
                <CancellationLine
                  hour72={policySelected['>72']}
                  hour48={policySelected['48-72']}
                  hour24={policySelected['<48']}
                />
              </div>

              <div className="cancellation-levels-title">
                <TitleText>{policySelected.name}</TitleText>
                <div
                  className={`cancellation-policy-status ${
                    (centreCancellation && centreCancellation[policySelected.type]) || policySelected.active
                      ? 'cancellation-policy-status--activated'
                      : 'cancellation-policy-status-deactivated'
                  }`}
                >
                  {(centreCancellation && centreCancellation[policySelected.type]) || policySelected.active
                    ? 'ACTIVADA'
                    : 'DESACTIVADA'}
                </div>
              </div>

              <div className="cancellation-levels-text">
                <SubtitleText>{policySelected.description ? policySelected.description.es : '-'}</SubtitleText>
              </div>
              <div className="cancellation-levels-table">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <BodyDestacadoText>PLAZO DE CANCELACIÓN</BodyDestacadoText>
                      </th>
                      <th>
                        <BodyDestacadoText>PENALIZACIÓN</BodyDestacadoText>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <BodyDestacadoText>Más de 72 horas antes del servicio</BodyDestacadoText>
                      </td>
                      <td>
                        <BodyText>{policySelected['>72']}%</BodyText>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <BodyDestacadoText>Entre 72 y 48 horas antes del servicio</BodyDestacadoText>
                      </td>
                      <td>
                        <BodyText>{policySelected['48-72']}%</BodyText>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <BodyDestacadoText>Entre 48 y 24 horas antes del servicio</BodyDestacadoText>
                      </td>
                      <td>
                        <BodyText>{policySelected['<48']}%</BodyText>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CancellationPolicyStyle>
          </MainLayoutSection>
          {(renderModal === 'modal-cancelation' || renderModal === 'modal-info') && (
            <ModalCancelationPolicy
              centreId={id}
              centreCancellation={centreCancellation}
              policies={policies}
              showModal={renderModal === 'modal-cancelation'}
              onCancel={() => this.setState({ renderModal: '' })}
              onAccept={(accept: Function) => {
                services.showModalSuccess('Genial!', 'Se ha guardado correctamente');
                this.setState({ accept, renderModal: '' }, () => this.getCancellationPolicies(true));
              }}
            />
          )}
          {renderModal === 'modal-info' && (
            <ModalInfo
              type="blue"
              icon={images.checkAllGreenSvg}
              title={['Confirmar edición']}
              onClose={() => this.setState({ renderModal: 'modal-manage-dive-levels' })}
              text={[
                `A continuación se realizarán las modificaciones solicitadas.
              Tenga en cuenta que estas afectarán a los servicios de la plataforma asociados.`,
              ]}
              showModal={renderModal === 'modal-info'}
              buttons={[
                {
                  text: 'Aceptar',
                  type: 'primary',
                  onClick: async () => {
                    try {
                      await accept();
                      this.setState({ renderModal: '' }, () => {
                        this.getCancellationPolicies(true);
                        services.showModalSuccess('Genial!', 'Se ha guardado correctamente');
                      });
                    } catch (err) {
                      this.setState({ renderModal: 'modal-manage-dive-levels' });
                    }
                  },
                },
                {
                  text: 'Cancelar',
                  type: 'secondary-red',
                  onClick: () => this.setState({ renderModal: 'modal-cancelation' }),
                },
              ]}
            />
          )}
        </ContentBlock>
      </>
    );
  }
}
