import React from 'react';
import { LabelTextContainer } from './label-text-styles';

interface LabelTextProps {
  children: any;
  className?: string;
  color?: string;
}

const LabelText = ({ children = '', className = '', color = '' }: LabelTextProps) => {
  return (
    <LabelTextContainer color={color} className={className}>
      {children}
    </LabelTextContainer>
  );
};
export default LabelText;
