import React from 'react';

import { ModalCreateDocumentStyle } from './modal-create-document-style';
import ModalContainer from '../../../../components/modals/modal-container';
import { FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import SelectBox from '../../../../components/select-box';
import { images } from '../../../../assets/images';
import MainButton from '../../../../components/main-button';
import InputBox from '../../../../components/input-box';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import InputUploadFiles from '../../../../components/input-upload-file/input-upload-file';
import CalendarBox from '../../../../components/calendar-box';
import { CENTRE_DOCUMENTS_TYPES_DROPDOWN } from '../../../../constants/documents';
import moment from 'moment';
import { AttachedFile } from '../../../../types/file';
import { postCentreDocument, patchCentreDocument } from '../../../../services/centre';
import { transformDateCalendar } from '../../../../utils/time';

interface ModalCreateDocumentProps {
  centreId: string;
  showModal: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface ModalCreateDocumentState {
  document: {
    name: string;
    type: string;
    customId: string;
    expirationDate: string;
    expirationNotice: string;
    file: AttachedFile[];
  };
  errors: {
    name: string;
    type: string;
    customId: string;
    expirationDate: string;
    expirationNotice: string;
    file: string;
  };
}

export default class ModalCreateDocument extends React.Component<ModalCreateDocumentProps, ModalCreateDocumentState> {
  constructor(props: ModalCreateDocumentProps) {
    super(props);
    this.state = {
      document: {
        name: '',
        type: '',
        customId: '',
        expirationDate: '',
        expirationNotice: '',
        file: [],
      },
      errors: {
        name: '',
        type: '',
        customId: '',
        expirationDate: '',
        expirationNotice: '',
        file: '',
      },
    };
  }

  validate(field: string, value: string, required: boolean) {
    if (required && (!value || value === '')) return 'required';
    if (field === 'expirationDate' && moment().isAfter(moment(value))) return 'invalid-date-future';
    return '';
  }

  handleChange(field: string, value: string, required: boolean = true) {
    const { document, errors } = Object.assign(this.state);
    document[field] = value;
    errors[field] = this.validate(field, value, required);
    this.setState({ document });
  }

  handleChangeFile(file: AttachedFile[]) {
    const { document, errors } = Object.assign(this.state);
    if (file.length > 0) {
      document.file = file;
      errors.file = '';
    } else {
      document.file = [];
      errors.file = 'required';
    }
    this.setState({ document, errors });
  }

  async handleSave() {
    const { centreId, onSuccess } = this.props;
    const { document, errors } = Object.assign(this.state);
    const requiredFields = ['name', 'type'];
    requiredFields.forEach(field => {
      errors[field] = this.validate(field, document[field], true);
    });
    if (document.file.length > 0) {
      errors.file = '';
    } else {
      errors.file = 'required';
    }
    this.setState({ errors });
    if (Object.values(errors).every(err => err === '')) {
      if (document.expirationDate === '') delete document.expirationDate;
      if (document.expirationNotice === '') delete document.expirationNotice;
      const resp: any = await postCentreDocument(centreId, document);
      const formData = new FormData();
      formData.set('file', document.file[0].data);
      await patchCentreDocument(centreId, resp.data.id.toString(), formData);
      onSuccess();
    }
  }

  render() {
    const { onClose, showModal } = this.props;
    const { document, errors } = this.state;
    return (
      <ModalContainer title={'Crear documento'} className="" modalClose={() => onClose()} active={showModal}>
        <ModalCreateDocumentStyle>
          <div className="create-user-form">
            <div className="create-user-form__title">
              <BodyDestacadoText>Datos Generales</BodyDestacadoText>
            </div>
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.name}
                      placeholder="Nombre documento"
                      className="rounded-title"
                      type="email"
                      value={document.name}
                      labelText="Nombre documento"
                      required={true}
                      onChange={value => this.handleChange('name', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputUploadFiles
                      errorCode={errors.file}
                      className={'full'}
                      multiple={false}
                      required={true}
                      label="Documento"
                      onChange={ev => {
                        this.handleChangeFile(ev);
                      }}
                      accept={['*']}
                    />
                  </FormGridItem>
                </FormGridRow>

                {/* Cuando se añade el equipo completo */}
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText="Tipo de documento"
                      placeholder="Tipo de documento"
                      required={true}
                      className="rounded-title"
                      optionsText={CENTRE_DOCUMENTS_TYPES_DROPDOWN}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      errorCode={errors.type}
                      initialValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.handleChange('type', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.customId}
                      placeholder="Nº ID"
                      className="rounded-title"
                      type="text"
                      value={document.customId}
                      labelText="Nº ID"
                      required={false}
                      onChange={value => this.handleChange('customId', value)}
                    />
                  </FormGridItem>
                </FormGridRow>
                {/* /// */}

                <div className="create-user-form__title">
                  <BodyDestacadoText>CADUCIDAD</BodyDestacadoText>
                </div>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <CalendarBox
                      disableWrite={true}
                      placeholder="Fecha de caducidad"
                      className="rounded-title"
                      labelText="Fecha de caducidad"
                      forbidPastDates={true}
                      required={true}
                      initialValue={document.expirationDate ? moment(document.expirationDate).format('DD/MM/YYYY') : ''}
                      errorCode={errors.expirationDate}
                      onChange={value => {
                        if (value) {
                          this.handleChange('expirationDate', transformDateCalendar(value), false);
                        } else {
                          this.handleChange('expirationDate', value, false);
                        }
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                {/*<FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      errorCode={errors.expirationNotice}
                      placeholder="Aviso de caducidad"
                      className="rounded-title"
                      type="email"
                      required={true}
                      value={document.expirationNotice}
                      labelText="Aviso de caducidad"
                      onChange={value => this.handleChange('expirationNotice', value, false)}
                    />
                  </FormGridItem>
                </FormGridRow>*/}
              </FormGrid>
            </div>
          </div>
          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton type="primary" onClick={() => this.handleSave()} text="Guardar" />
            </div>
          </div>
        </ModalCreateDocumentStyle>
      </ModalContainer>
    );
  }
}
