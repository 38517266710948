import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ModalBonusesContainer = styled.div`
  padding: 58px 32px 32px 32px;
  width: 416px;
  .modal-bonuses-title {
    font-family: Plain;
    font-size: 20px;
    line-height: 28px;
    color: ${colors['NE-001/100']};
    margin-bottom: 40px;
    font-weight: 500;
  }

  .modal-bonuses-inputbox {
    width: 100%;
    margin-bottom: 40px;
  }

  .modal-bonuses-subtitle {
    font-family: Plain;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px;
    color: ${colors['SE-002/100']};
    font-weight: 500;
  }

  .modal-bonuses-add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .modal-bonuses-selecbox {
      width: 216px;
    }
    .modal-bonuses-button-add {
      width: 116px;
    }
  }

  .modal-bonuses-results {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 10px;
    background: ${colors['NE-002/10']};
    margin-bottom: 12px;
    &.last-child {
      margin-bottom: 40px;
    }
    .results-type {
      font-family: Plain;
      font-size: 10px;
      line-height: 16px;
      font-weight: 100;
      color: ${colors['SE-002/100']};
      margin-bottom: 4px;
    }
    .results-activity {
      font-family: Plain;
      font-size: 12px;
      line-height: 18px;
      font-weight: 300;
      color: ${colors['SE-002/100']};
    }
    .results-delete {
      width: 32px;
      cursor: pointer;
      & img {
        width: 18px;
      }
    }
  }

  .modal-bonuses-features {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    &:last-child {
      margin-bottom: 40px;
    }
    .features-title {
      font-family: Plain;
      font-size: 14px;
      line-height: 22px;
      font-weight: 100;
    }
    .features-inputbox {
      display: flex;
      width: 121px;
      .input-box-error {
        display: none;
      }
    }

    .features-inputbox-select {
      display: flex;
      align-items: center;
      .features-number {
        font-family: Plain;
        font-size: 14px;
        line-height: 22px;
        margin-right: 20px;
        color: ${colors['NE-002/100']};
      }
      .features-selectbox {
        width: 121px;
        margin-left: 12px;
        .input-box-error {
          display: none;
        }
      }
    }
  }
`;
