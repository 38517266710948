import services from './services';

export const getDiveSites = async (params: any, loader: boolean = true) => {
  
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      loader,
      endpoint: 'dive-sites',
      then: resolve,
      catch: reject,
    };
    services.get(args);
  });
};

export const getOneDiveSites = (diveSitesId: string, params?: any) => {
  const endpoint = `dive-sites/${diveSitesId}`;
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadDiveSites = (diveSitesId: string, data: any) => {
  const endpoint = `dive-sites/${diveSitesId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const validateDiveSites = (diveSitesId: string) => {
  const endpoint = `dive-sites/${diveSitesId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const deleteDiveSites = (diveSitesId: string) => {
  const endpoint = `dive-sites/${diveSitesId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createDiveSites = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'dive-sites',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const byOwnerFavorite = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'dive-sites/byOwnerFavorite',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

