export const colors = {
  'PR-001/100': '#00A1A5',
  'PR-001/75': '#40B8BB',
  'PR-001/50': '#80D0D2',
  'PR-001/30': '#B3E3E4',
  'PR-001/10': '#EAF6F6',
  'SE-001/100': '#55BFF6',
  'SE-001/75': '#7FCFF8',
  'SE-001/50': '#AADFFA',
  'SE-001/30': '#CCECFC',
  'SE-001/15': '#EEF9FE',
  'SE-002/100': '#007D91',
  'SE-002/75': '#409DAC',
  'SE-002/50': '#80BEC8',
  'SE-002/30': '#B3D8DE',
  'SE-002/20': '#E5F2F4',
  'SE-002/15': '#E5F2F4',
  'SE-002/5': '#E5F2F4',
  'SE-003/100': '#005267',
  'SE-003/75': '#407D8D',
  'SE-003/50': '#80A8B3',
  'SE-003/30': '#B3CBD1',
  'SE-003/15': '#E5EEF0',
  'SE-004/100': '#001930',
  'SE-004/75': '#405364',
  'SE-004/50': '#808C97',
  'SE-004/30': '#B3BAC1',
  'SE-004/10': '#F5F6F7',
  'SE-005/100': '#143181',
  'SE-005/75': '#4f64a0',
  'SE-005/50': '#8998c0',
  'SE-005/30': '#b9c1d9',
  'SE-005/5': '#f3f5f9',
  'CO-001/100': '#6EDF9B',
  'CO-001/75': '#92E7B4',
  'CO-001/50': '#B6EFCD',
  'CO-001/30': '#D3F5E1',
  'CO-001/15': '#E9FAF0',
  'CO-002/100': '#FFEA55',
  'CO-002/75': '#FFEF80',
  'CO-002/50': '#FFF4AA',
  'CO-002/30': '#FFF9CC',
  'CO-002/15': '#FFF9CC',
  'CO-003/10': '#FDECE8',
  'CO-003/100': '#ED441E',
  'CO-003/75': '#F27356',
  'CO-003/50': '#F6A18E',
  'CO-003/30': '#FAC7BB',
  'CO-003/15': '#FDECE8',
  'CO-004/100': '#FF7368',
  'CO-004/75': '#FF968E',
  'CO-004/50': '#FFB9B3',
  'CO-004/30': '#FFD5D2',
  'CO-004/15': '#FFF1F0',
  'NE-001/100': '#000019',
  'NE-001/75': '#404053',
  'NE-001/50': '#80808c',
  'NE-001/30': '#b3b3ba',
  'NE-001/10': '#e5e5e8',
  'NE-002/100': '#bcc5d7',
  'NE-002/10': '#f8fafd',
  'NE-002/0': '#ffffff',
  'NE-003/100': '#6c778b',
  'NE-003/75': '#a9a9b0',
  'NE-003/20': '#f2f2f3',
  'NE-003/10': '#f1f3f8',
  'NE-003/30': '#020202',
};
