import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CardPhotoCenterStyle = styled.div`
  .card-photo-center-img  {
    position: relative;
    border-radius: 5px;
    background: #c4c4c4;
    height: 160px;
    margin-bottom: 16px;
    overflow: hidden;

    &__img  {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }
    }
    &__check  {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 2;
    }
    &__fav  {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 16px;
      right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 2;
      img {
        width: 24px;
        height: 24px;
      }
    }
    &__rate {
      position: absolute;
      bottom: 12px;
      right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      padding: 0 6px 0 2px;
      background: ${colors['PR-001/10']};
      color: ${colors['PR-001/100']};
      &__icon {
        margin: 2px;
        height: 12px;
        width: 12px;
        display: flex;
        align-items: center;
        img {
          height: 12px;
          width: 12px;
        }
      }
      &__number {
        font-size: 10px;
        line-height: 16px;
      }
    }
  }

  .card-photo-center-info {
    text-align: center;
    cursor: pointer;

    &__title {
      color: ${colors['NE-001/100']};
      margin-bottom: 2px;
    }
    &__file {
      color: ${colors['NE-002/100']};
    }
  }
`;
