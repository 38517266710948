import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const HeaderMainContainer = styled.div`
  padding: 0px 40px 40px;

  .header-main-container {
    display: flex;
    width: 100%;
    min-height: 120px;
    justify-content: space-between;
    align-items: center;
  }
  .header-main-advice {
    font-size: 12px;
    line-height: 18px;
    color: ${colors['CO-004/100']};
  }
  .header-main-left  {
    display: flex;
    align-items: baseline;
    &__arrow {
      margin-right: 12px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    &__title {
    }
  }
  .header-main-left-subtitle  {
    margin-top: 4px;
    text-transform: uppercase;
    &.pending  {
      color: ${colors['CO-004/100']};
    }
    &.confirmed {
      color: ${colors['CO-001/100']};
    }
    &.done {
      color: ${colors['PR-001/100']};
    }
  }

  .header-main-right  {
    display: flex;
  }

  .header-main-right-icons {
    display: flex;
    &__item {
      margin-right: 12px;
      width: 40px;
      height: 40px;
    }
  }
  .header-main-right-button {
    min-width: 112px;
    &.secondary {
      margin-left: 12px;
    }
  }

  .header-main-search {
    width: 100%;
    position: relative;
    .header-main-close  {
      position: absolute;
      right: 18px;
      top: 15px;
      width: 22px;
      height: 22px;
      z-index: 2;
      cursor: pointer;
    }

    &__dropdown {
      opacity: 0;
      transition: opacity 0.2s translate 0.2s 0.05s;
      transform: translateY(-25%);
      pointer-events: none;

      position: absolute;
      top: 120%;
      left: 0;
      width: 100%;
      border-radius: 5px;
      padding: 24px 30px 32px 30px;
      box-shadow: 0px 0px 20px rgba(0, 0, 25, 0.12);
      background-color: ${colors['NE-002/0']};
      z-index: 500;

      &--visible {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
      }
    }
  }

  &.full {
    padding: 40px 0px;
  }
`;
