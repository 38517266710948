import * as React from 'react';
import ModalContainer from '../modal-container';
import { ModalIncidenceManagementStyle } from './modal-incident-management-style';

import { images } from '../../../assets/images';
import BodyText from '../../text/body-text/body-text';
import DicotomicCheckbox from '../../dicotomic-checkbox';
import InfoText from '../../text/info-text/info-text';
import MainButton from '../../main-button';
import InputArea from '../../input-area/input-area';
import BodyDestacadoText from '../../text/body-destacado-text/body-destacado-text';
import { IIncidenceStatusForm } from '../../../models/incidence-status-forms';
import { solveIncidence, closeIncidence, reopenIncidence } from '../../../services/incidences';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

export interface IModalIncidenceManagementProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  onAccept: () => void;
  isEditing?: boolean;
  typeAction: string;
  incidenceId?: string;
  t: any
}

export interface IModalIncidenceManagementState {
  dataForm: IIncidenceStatusForm;
}



class ModalIncidenceManagement extends React.Component<
  IModalIncidenceManagementProps,
  IModalIncidenceManagementState
  > {

  readonly state: IModalIncidenceManagementState;
  private TITLE: any = {
    reopen: useTranslation('components:actions.reopen'),
    close: useTranslation('components:actions.close-incidence'),
    solved: useTranslation('components:actions.solved'),
  }

  private TEXT: any = {
    reopen: useTranslation('modalMessages:modal-incidence.reopen-reasons-notification'),
    close: useTranslation('modalMessages:modal-incidence.close-reasons'),
    solved: useTranslation('modalMessages:modal-incidence.resolved-reasons'),
  }

  private OPTION: any = {
    reopen: useTranslation('modalMessages:modal-incidence.incidence-resolved-error'),
    close: useTranslation('modalMessages:modal-incidence.incidence-resolved-ok'),
    solved: useTranslation('modalMessages:modal-incidence.incidence-resolved-ok'),
  }

  private DATA_FORM: any = {
    reason: {
      value: 'other',
      error: false,
      errorCode: '',
      required: true,
    },
    message: {
      value: '',
      error: false,
      errorCode: '',
      required: false,
    },
  }
  constructor(props: IModalIncidenceManagementProps) {
    super(props);

    this.state = {
      dataForm: JSON.parse(JSON.stringify(this.DATA_FORM)),
    };
  }



  changeValue = (value: string, key: string) => {
    const { dataForm } = this.state;

    dataForm[key].value = value;

    this.setState({ dataForm });
  }

  handleAccept = async () => {
    const { dataForm } = this.state;
    const { typeAction, incidenceId = '', onAccept } = this.props;
    let isOk = true;
    Object.keys(dataForm).forEach((k: string) => {
      if (dataForm[k].required && dataForm[k].value === '') {
        dataForm[k].error = true;
        dataForm[k].errorCode = 'required';
        isOk = false;
      } else {
        dataForm[k].error = false;
        dataForm[k].errorCode = '';
      }
    });
    this.setState({ dataForm });
    if (isOk) {
      const data: any = {};
      Object.keys(dataForm).forEach((k: string) => {
        data[k] = dataForm[k].value
      });
      try {
        if (typeAction === 'reopen') {
          await reopenIncidence(incidenceId, data);
        } else if (typeAction === 'close') {
          await solveIncidence(incidenceId, data);
          await closeIncidence(incidenceId, data);
        } else {
          await solveIncidence(incidenceId, data);
        }
        onAccept();
      } catch (err) {
        console.log(err);
      }
    }
  }

  render() {
    const { showModal, onCancel, typeAction, t } = this.props;
    const { dataForm } = this.state
    return (
      <ModalContainer title={this.TITLE[typeAction]} active={showModal} modalClose={() => onCancel()}>
        <ModalIncidenceManagementStyle>
          <div className="modal-incident-management-text">
            <BodyText>
              {this.TEXT[typeAction]}
            </BodyText>
          </div>
          <div className="modal-incident-management-reason">
            <div className="modal-incident-management-reason__item">
              <DicotomicCheckbox
                name="name"
                errorText="Error"
                className="rounded"
                onCheck={() => this.changeValue(typeAction === 'reopen' ? 'wronglySolved' : 'solved', 'reason')}
                checked={dataForm.reason.value !== 'other'}
              >
                <InfoText>{this.OPTION[typeAction]}</InfoText>
              </DicotomicCheckbox>
            </div>
            <div className="modal-incident-management-reason__item">
              <DicotomicCheckbox
                name="name"
                errorText="Error"
                className="rounded"
                onCheck={() => this.changeValue('other', 'reason')}
                checked={dataForm.reason.value === 'other'}
              >
                <InfoText>{t('components:general.other-reasons')}</InfoText>
              </DicotomicCheckbox>
            </div>
          </div>

          {/* Text Area ?? */}

          <div className="modal-incidence-detail-input">
            <div className="modal-incidence-detail-input__title">
              <BodyDestacadoText>{t('components:general.message')}</BodyDestacadoText>
            </div>
            <InputArea
              name={"message"}
              placeholder={t('modalMessages:modal-incidence.reopen-reasons')}
              required={dataForm.message.required}
              errorCode={dataForm.message.errorCode}
              error={dataForm.message.error}
              value={dataForm.message.value}
              onChange={(e: any) => this.changeValue(e.target.value, "message")}
            />
          </div>

          <div className="modal-incidence-detail-buttons">
            <div className="modal-incidence-detail-buttons__item">
              <MainButton className="primary" onClick={() => this.handleAccept()} text={t('components:actions.accept')} />
            </div>
            <div className="modal-incidence-detail-buttons__item">
              <MainButton className="secondary" onClick={() => onCancel()} text={t('components:actions.cancel')} />
            </div>
          </div>
        </ModalIncidenceManagementStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalIncidenceManagement));

