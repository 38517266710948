import * as React from 'react';
import { DiveOfferTableStyle } from './offers-style';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import { images } from '../../../assets/images';
import MainTable from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import ModalCreateOffers from './modal-create-offers/modal-create-offers';
import { RouteComponentProps, match } from 'react-router';
import { getSales } from '../../../services/centre';
import { ISale } from '../../../models/sales';
import { CENTRE_OFFERS_PER_PAGE } from '../../../constants/data';
import { copyOf } from '../../../utils/other';
import moment from 'moment';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { deleteSale } from '../../../services/sales';
import services from '../../../services/services';
import { ContentBlock } from '../../../components/content-block/content-block';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';

export interface DiveOfferTableProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  t: any;
  staffPermissions?: any;
}

export interface DiveOfferTableState {
  activeFilter: boolean;
  renderModal: string;
  selectedSale?: ISale;
  sales: ISale[];
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
}

class DiveOfferTable extends React.Component<DiveOfferTableProps, DiveOfferTableState> {
  constructor(props: DiveOfferTableProps) {
    super(props);
    this.state = {
      activeFilter: false,
      renderModal: 'modal-create-activity',
      sales: [],
      total: 0,
      totalPages: 0,
      skipPage: 0,
      page: 1,
    };
  }

  componentDidMount() {
    this.getSales();
  }

  async getSales() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { skipPage } = this.state;
    const params = {
      filter: {
        relations: ['templates'],
        skip: skipPage,
        limit: CENTRE_OFFERS_PER_PAGE,
      },
    };
    const resp = await getSales(id, params);
    const sales = resp.data.data;
    const totalPages: number = Math.ceil(resp.data.count / CENTRE_OFFERS_PER_PAGE);
    this.setState({ sales, totalPages, total: resp.data.count });
  }

  async handleRemove() {
    const { selectedSale } = this.state;
    if (selectedSale && selectedSale.id) {
      await deleteSale(selectedSale.id.toString());
      services.pushNotification({
        title: '¡Eliminada!',
        text: 'La oferta se ha eliminado con éxito',
        type: 'red',
      });
      this.closeModal();
      this.getSales();
    }
  }

  getTableRows() {
    const { sales } = this.state;
    const tableRows: any[] = [];
    sales.forEach((sale: ISale) => {
      const tableRow = copyOf(this.tableRowTemplate);
      tableRow.name.value = sale.name;
      tableRow.discount.value = `${sale.discount}%`;
      tableRow.validity.value = sale.validUntil ? moment(sale.validUntil).format('DD/MM/YYYY') : 'Indefinida';
      tableRow.status.value = sale.validUntil
        ? moment(sale.validUntil).isBefore(moment())
          ? 'Desactivida'
          : 'Actividada'
        : 'Actividada';
      tableRow.detail.onClick = () => {
        this.setState({ selectedSale: sale, renderModal: 'create-offer' });
      };
      tableRow.delete.onClick = () => {
        this.setState({ selectedSale: sale, renderModal: 'delete-offer' });
      };
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CENTRE_OFFERS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getSales();
      });
    }
  }

  closeModal() {
    this.setState({ renderModal: '', selectedSale: undefined });
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t,
      staffPermissions,
    } = this.props;
    const { renderModal, totalPages, selectedSale, page } = this.state;
    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Ofertas'}
            mainButton={
              !staffPermissions || staffPermissions.deals
                ? {
                    img: images.createWhiteSvg,
                    text: 'Crear',
                    onClick: () => this.setState({ renderModal: 'create-offer' }),
                  }
                : undefined
            }
          />
          <MainLayoutSection>
            <DiveOfferTableStyle>
              <div className="dive-offers-activities-table">
                <MainLayoutTableContainer>
                  <div className="diving-center-table">
                    <MainTable
                      columns={this.tableColumns}
                      rows={this.getTableRows()}
                      rowKeys={this.rowKeys}
                      onOrder={() => {}}
                    />
                  </div>
                  {totalPages > 1 && (
                    <div className="diving-center-pagination">
                      <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                    </div>
                  )}
                </MainLayoutTableContainer>
              </div>
            </DiveOfferTableStyle>
          </MainLayoutSection>
          {renderModal === 'create-offer' && (
            <ModalCreateOffers
              centreId={id}
              onSuccess={() => {
                this.closeModal();
                this.getSales();
              }}
              onCancel={() => this.closeModal()}
              showModal={renderModal === 'create-offer'}
              sale={selectedSale}
            />
          )}
          {renderModal === 'delete-offer' && (
            <ModalDelete
              title={''}
              subtitle={''}
              showModal={renderModal === 'delete-offer'}
              onRemove={() => this.handleRemove()}
              onCancel={() => this.closeModal()}
            />
          )}
        </ContentBlock>
      </>
    );
  }

  tableColumns = [
    {
      text: 'Nombre',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Descuento aplicado',
      filter: false,
      ascendent: true,
    },
    {
      text: 'Validez',
      filter: false,
      ascendent: false,
    },
    {
      text: 'Estado',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    name: {
      key: 'highlighted-body',
      value: 'Inmersión en Cabo de Gata',
    },
    discount: {
      key: '',
      value: 'Bautismo de buceo',
    },
    validity: {
      key: '',
      value: 'Buceo recreativo',
    },
    status: {
      key: '',
      value: '2 días',
    },
    detail: {
      key: 'icon-button',
      value: images.eyeSvg,
      onClick: () => {},
    },
    delete: {
      key: 'icon-button',
      value: images.deleteSvg,
      onClick: () => {},
    },
  };

  rowKeys = ['name', 'discount', 'validity', 'status', 'detail', 'delete'];
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(DiveOfferTable);
