import React from 'react';
import { ModalConfirmationContainer } from './modal-confirmation-style';
import { images } from '../../../assets/images';
import SubheadText from '../../text/subhead-text/subhead-text';
import MainButton from '../../main-button';
import ModalContainer from '../modal-container';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ModalProps extends WithTranslation{
  className?: String;
  title?: String;
  subtitle?: String;
  showModal: boolean;
  onAccept: () => void;
  onCancel: () => void;
  t: any;
  children?: any;
}
class ModalConfirmation extends React.Component<ModalProps, {}> {
  render() {
    const { className, title, subtitle, showModal, onAccept, onCancel, t, children,} = this.props;
    return (

      <ModalContainer
        className="center"
        modalClose={() => onCancel()}
        active={showModal}
      >
        <ModalConfirmationContainer className={className}>
          <div className="modal-delete-pop">
            <div className="modal-delete-img">
              <img src={images.alertBlueSvg} alt="" />
            </div>
            <div className="modal-delete-title">
              {title && <SubheadText>{title}</SubheadText>}
              {subtitle &&<SubheadText>{subtitle}</SubheadText>}
              {children}
            </div>
            <div className="modal-delete-buttons">
              <div className="modal-delete-buttons__item">
                <MainButton text={t("modalMessages:confirm")} type="primary" onClick={onAccept} />
              </div>
              <div className="modal-delete-buttons__item">
                <MainButton text={t("modalMessages:cancel")} type="secondary" onClick={onCancel} />
              </div>
            </div>
          </div>
        </ModalConfirmationContainer>
      </ModalContainer>
    );
  }
}
export default withTranslation(['modalMessages'])(ModalConfirmation);