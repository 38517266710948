import React, { Component } from 'react';
import { UnavailableContentContaniner } from './unavailable-content-style';
import { images } from '../../assets/images';
import HeadDestacadoText from '../text/head-destacado-text/head-destacado-text';
import SubtitleText from '../text/subtitle-text/subtitle-text';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

interface UnavailableContentProps extends WithTranslation {
  body?: string;
}

class UnavailableContent extends Component<UnavailableContentProps, {}> {
  render() {
    const { t, body} = this.props;
    return (
      <UnavailableContentContaniner>
        <div className="no-content-img">
          <img src={images.comingSoon} alt="" />
        </div>
        <div className="no-content-text">
          <div className="no-content-text__title">
            <HeadDestacadoText>{t('components:unavailable-content.soon')}</HeadDestacadoText>
          </div>
          <div className="no-content-text__text">
            <SubtitleText>{body?body:t('components:unavailable-content.text')}</SubtitleText>
          </div>
        </div>
      </UnavailableContentContaniner>
    );
  }
}
export default withTranslation(['intro', 'buttons', 'components'])(UnavailableContent);
