import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ModalCreateProjectStyle = styled.div`
  width: 440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 32px;
  padding-top: 48px;

  .modal-create-project {
    &-title {
      margin-top: 8px;
      margin-bottom: 48px;
    }

    &-step {
      font-family: Plain;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #007d91;
    }
  }

  // Buttons of next / previous
  .item-modal-message-bottom {
    display: flex;
    //flex-direction: column;

    .item-modal-message-button {
      margin-right: 20px;
      width: 100%;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .item-modal-message-container {
    &__title {
      margin-bottom: 14px;
      color: ${colors['SE-002/100']};
    }
  }

  .item-modal-message-counter {
    margin-left: 32px;
    display: flex;
    align-items: center;
    line-height: normal;
    font-size: 16px;
    text-align: right;
    color: #004f8b;

    &__icon {
      margin-top: 3px;
    }
  }

  .item-modal-message-select  {
    margin-bottom: 40px;
  }

  .item-modal-message-docs {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: repeat(5, 110px);
    gap: 40px;
  }

  .modal-certifier-form {
    margin-bottom: 40px;
    &__title {
      margin-bottom: 14px;
      color: ${colors['SE-002/100']};
      text-transform: uppercase;
    }
  }

  .modal-certifier-form__languages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;

    .&__item {
      margin-bottom: 12px;
    }
  }

  .form-error {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fd4a22;
  }
`;
