import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const DiveActivitiesDetailStyle = styled.div`
  display: flex;
  width: 100%;

  .dive-activity-detail-left {
    width: 60%;
    margin-right: 60px;
    &__card {
      margin-bottom: 120px;
    }
    &__section {
      margin-bottom: 80px;
    }
  }

  .dive-activity-detail-double {
    display: flex;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
    &__title {
      width: 50%;
    }
    &__text {
      width: 50%;
    }
  }

  .dive-activity-detail-card-activity {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .dive-activity-detail-services {
    display: flex;
    flex-wrap: wrap;

    &__item  {
      display: flex;
      width: 50%;
      align-items: center;
      padding-right: 12px;
      margin-bottom: 14px;
      &__icon {
        width: 30px;
        height: 30px;
        margin-right: 12px;
      }
    }
  }

  .dive-activity-detail-right {
    width: 40%;
    &__card {
      margin-bottom: 120px;
    }
  }

  .dive-activity-detail-right-section {
    margin-bottom: 52px;
    &__title {
      color: ${colors['NE-002/100']};
      padding-bottom: 8px;
      margin-bottom: 24px;
      border-bottom: 1px solid ${colors['NE-002/100']};
    }

    &__item {
      margin-bottom: 28px;
      &:last-child  {
        margin-bottom: 0px;
      }
    }
  }

  .dive-activity-user-card {
    height: 232px;
    padding: 40px 32px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }

  .dive-activity-buy {
    margin-bottom: 94px;
  }
  .dive-activity-user-card-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    &__avatar  {
      width: 72px;
      height: 72px;
      overflow: hidden;
      border-radius: 100%;
      img {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }
    }
  }
  .dive-activity-user-card-name {
    margin-bottom: 10px;
  }
  .dive-activity-user-card-level {
    margin-bottom: 10px;
    color: ${colors['NE-002/100']};
  }

  .dive-activity-user-card-line {
    position: absolute;
    height: 9px;
    background: ${colors['SE-002/100']};
    width: 100%;
    left: 0;
    bottom: 0px;
  }
`;
