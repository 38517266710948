import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CardStatsStyle = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;

  .card-stats-number {
    margin-right: 20px;
    min-width: 60px;
    text-align: right;
  }
  .card-stats-text {
    text-transform: uppercase;
    &__title {
    }
    &__subtitle {
    }
  }

  &.blue {
     color: ${colors['SE-002/100']};
    background: ${colors['SE-002/5']};
  }
  &.light-blue {
    color: ${colors['SE-005/100']};
    background: ${colors['SE-005/5']};
  }
  &.orange {
     color: ${colors['CO-004/100']};
    background: rgba(252, 125, 97, 0.1);
  }
`;
