import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ListItemAddedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 17px; */
  /* padding: 0px 0 17px; */
  padding: 15px 16px;
  color: ${colors['SE-002/100']};
  background: ${colors['NE-002/10']};
  border-radius: 5px;

  .item-certifier-border {
    /* border-bottom: 1px solid ${colors['SE-002/100']}; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding-top: 5px; */
  }

  .item-certifier-img {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    /* margin-bottom: 12px; */
    border-radius: 5px;
    overflow: hidden;
    margin-right: 12px;
    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
      
  .item-certifier-delete{
    min-width: 22px;
    min-height: 22px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    /* margin: 0 8px; */
    cursor: pointer;
  }
  
  &.add-service {
    margin-bottom: 0px;
    .item-certifier-border { 
      padding-bottom: 17px;
    }
  }
`;
