import * as React from 'react';
import UnavailableContent from '../../../../components/unavailable-content/unavailable-content';
import { images } from '../../../../assets/images';
import { NavigationTabContainer } from '../../../../components/navigation-tabs/navigation-tabs-style';
import { NavLink, RouteComponentProps, match } from 'react-router-dom';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import HeaderMain from '../../../../components/header-main/header-main';
import { MainLayoutSection } from '../../../main-layout-style';

export interface IMyCenterEquipmentShopProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
}

export interface IMyCenterEquipmentShopState {}

export default class MyCenterEquipmentShop extends React.Component<
  IMyCenterEquipmentShopProps,
  IMyCenterEquipmentShopState
> {
  constructor(props: IMyCenterEquipmentShopProps) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    return (
      <>
        <HeaderMain
          title={'Material del centro'}
          mainButton={{
            img: images.createWhiteSvg,
            text: 'Agregar equipo',
            onClick: () => {},
          }}
        />
        <MainLayoutSection>
          <NavigationTabContainer>
            <NavLink to={`/centres/${id}/business/equipment/inventary`}>
              <div className="navigation-item">
                <BodyDestacadoText>Inventario</BodyDestacadoText>
              </div>
            </NavLink>
            <NavLink to={`/centres/${id}/business/equipment/rent`}>
              <div className="navigation-item">
                <BodyDestacadoText>Alquiler de equipo</BodyDestacadoText>
              </div>
            </NavLink>
            <NavLink to={`/centres/${id}/business/equipment/shop`}>
              <div className="navigation-item">
                <BodyDestacadoText>Tienda</BodyDestacadoText>
              </div>
            </NavLink>
          </NavigationTabContainer>
          <UnavailableContent />
        </MainLayoutSection>
      </>
    );
  }
}
