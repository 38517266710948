import services from './services';

export const getBookingPayment = (params: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'booking-payment',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getOneBookingPayment = (id: string, params?: any) => {
  const endpoint = `booking-payment/${id}`;
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadBookingPayment = (bookingId: string, data: any) => {
  const endpoint = `booking-payment/${bookingId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};