import React from 'react';
import { ModalAditionalPreparationStyle } from './modal-additional-preparation-style';
import ModalContainer from '../../../../../../components/modals/modal-container';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../../main-layout-style';
import { images } from '../../../../../../assets/images';
import InputBox from '../../../../../../components/input-box';
import MainButton from '../../../../../../components/main-button';
import SubheadDestacadoText from '../../../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../../../../../components/text/body-text/body-text';
import AddItemFormSimple from '../../../../../../components/add-item-form-simple/add-item-form-simple';
import { DiverProfile } from '../../types';
import services from '../../../../../../services/services';
import { setDiverData } from '../../../../../../services/diver';

interface ModalAditionalPreparationProps {
  showModal: boolean;
  onClose: () => void;
  onSave: () => void;
  person: DiverProfile;
}

interface ModalAditionalPreparationState {
  person?: DiverProfile;
  newAditionalTraining: string;
}

export default class ModalAditionalPreparation extends React.Component<
  ModalAditionalPreparationProps,
  ModalAditionalPreparationState
> {
  constructor(props: ModalAditionalPreparationProps) {
    super(props);
    this.state = {
      newAditionalTraining: '',
    };
  }

  componentDidMount() {
    const { person } = this.props;
    if (!person.aditionalTraining) person.aditionalTraining = [];
    this.setState({ person });
  }

  componentDidUpdate(prevProps: ModalAditionalPreparationProps) {
    const { person } = this.props;
    if (!person.aditionalTraining) person.aditionalTraining = [];
    if (prevProps.person !== person) {
      this.setState({ person });
    }
  }

  async handleSaveChanges() {
    const { person } = this.state;
    const { onSave } = this.props;
    if (person && person.aditionalTraining) {
      await setDiverData(person.id.toString(), { aditionalTraining: person.aditionalTraining });
      services.pushNotification({
        title: 'Genial!',
        text: 'El usuario se ha editado correctamente',
        type: 'green',
      });
      onSave();
    }
  }

  addTraining() {
    const { person, newAditionalTraining } = this.state;
    if (person && person.aditionalTraining && newAditionalTraining.length > 0) {
      person.aditionalTraining.push(newAditionalTraining);
      this.setState({ person, newAditionalTraining: '' });
    }
  }

  render() {
    const { onClose, showModal } = this.props;
    const { person, newAditionalTraining } = this.state;
    return (
      <ModalContainer className="" modalClose={() => onClose()} active={showModal}>
        <ModalAditionalPreparationStyle>
          <div className="create-user-title">
            <SubheadDestacadoText>Formación adicional</SubheadDestacadoText>
          </div>
          <div className="create-user-text">
            <BodyText>
              Añade la formación adicional necesaria para que quede reflejada en el perfil del buceador y pueda
              demostrar su experiencia.
            </BodyText>
          </div>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-1">
                    <InputBox
                      // errorCode="Error"
                      placeholder="Formación adicional"
                      className="rounded"
                      type="text"
                      value={newAditionalTraining}
                      labelText="Usuario"
                      onChange={newAditionalTraining => this.setState({ newAditionalTraining })}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-3">
                    <MainButton
                      type="secondary full-height"
                      iconImg={images.plusSvg}
                      disabled={newAditionalTraining.length <= 0}
                      text="Añadir"
                      onClick={() => {
                        if (
                          !person ||
                          !person.aditionalTraining ||
                          person.aditionalTraining.includes(newAditionalTraining)
                        ) {
                          return;
                        }
                        this.addTraining();
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
              {person &&
                person.aditionalTraining &&
                person.aditionalTraining.map(training => {
                  return (
                    <AddItemFormSimple
                      key={training}
                      text={training}
                      onDelete={() => {
                        if (!person || !person.aditionalTraining) return;
                        person.aditionalTraining = person.aditionalTraining.filter(
                          adTraining => training !== adTraining
                        );
                        this.setState({ person });
                      }}
                    />
                  );
                })}
            </div>
          </div>

          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton type="primary" onClick={() => this.handleSaveChanges()} text="Guardar Cambios" />
            </div>
          </div>
        </ModalAditionalPreparationStyle>
      </ModalContainer>
    );
  }
}
