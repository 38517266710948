import * as React from 'react';
import { FormGridRow, FormGridItem, FormGrid, MainLayoutSection } from '../../../main-layout-style';
import { MyCenterProfileDescriptionStyle } from './my-center-profile-description-style';
import SelectBox from '../../../../components/select-box';
import { images } from '../../../../assets/images';
import MainButton from '../../../../components/main-button';
import ListItemAdded from '../../../../components/list-item-added/list-item-added';
import LabelText from '../../../../components/text/label-text/label';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import ItemFeatureCenter from '../../../../components/item-feature-center/item-feature-center';
import ItemTag from '../../../../components/item-tag/item-tag';
import ModalSlider from '../../../../components/modals/modal-slider/modal-slider';
import ModalUploadFiles from '../../../../components/modals/modal-upload-files/modal-upload-files';
import { RouteComponentProps, match, withRouter } from 'react-router-dom';
import HeaderMain from '../../../../components/header-main/header-main';
import TooltipBox from '../../../../components/tooltip-box/tooltip-box';
import InputArea from '../../../../components/input-area/input-area';
import MyCenterProfileNav from '../my-center-profile-nav/my-center-profile-nav';
import { IOrganization } from '../../../../models/organization';
import { getOrganizations } from '../../../../services/collaborator';
import { ICentreForm } from '../../../../models/centre-forms';
import {
  getCentre,
  editCentre,
  getCentrePictures,
  uploadCentrePictures,
  deleteCentrePicture,
} from '../../../../services/centre';
import InputBox from '../../../../components/input-box';
import services from '../../../../services/services';
import {
  FULL_LANGUAGES,
  FULL_LANGUAGES_KEY_VALUES,
} from '../../../../constants/languages';
import { CENTRE_FEATURES_DROPDOWN, CENTRE_FEATURES_KEY_VALUE, FULL_CENTRE_FEATURES_KEY_VALUES, FULL_CENTRE_FEATURES_KEY_ICONS } from '../../../../constants/centres';
import { CURRENCIES } from '../../../../constants/currencies';
import { IPicture } from '../../../../models/generic';
import { AttachedFile } from '../../../../types/file';
import ModalDelete from '../../../../components/modals/modal-delete/modal-delete';
import { setCentreCurrency } from '../../../../redux/actions';
import { capitalize } from '../../../../utils/texts';
import IStoreState from '../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { havePermissionsDeals } from '../../../../services/staff-centre';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_KEY } from '../../../../env';

export interface IMyCenterProfileDescriptionProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  staffPermissions?: any;
  agent: any;
}

export interface IMyCenterProfileDescriptionState {
  selectedCertifierId: string;
  selectedCertifierOwnId: string;
  selectedCertifierName: string;
  modal: string;
  activeLang: string;
  languages: Object;
  centre?: ICentreForm;
  pictures?: IPicture[];
  openedImage: number;
  selectedDeleteImage: number;
  allCertifiers: { value: string; label: string }[];
}

class MyCenterProfileDescription extends React.Component<
  IMyCenterProfileDescriptionProps,
  IMyCenterProfileDescriptionState
> {
  constructor(props: IMyCenterProfileDescriptionProps) {
    super(props);
    this.state = {
      modal: '',
      openedImage: 0,
      selectedDeleteImage: 0,
      allCertifiers: [],
      selectedCertifierId: '',
      selectedCertifierOwnId: '',
      selectedCertifierName: '',
      languages: {
        sp: 'Descripcion',
        de: '',
        cat: '',
        es:  '',
        eus: '',
        fr: '',
        glg: '',
        en: '',
        it: '',
        pt: '',
      },
      activeLang: 'es',
    };
  }

  componentDidMount() {
    this.getMyCenterProfile();
    localStorage.removeItem('arrayImages')
  }

  async getCertifiers() {
    const { centre } = this.state;
    if (!centre) return;
    try {
      const organizations: IOrganization = await getOrganizations();
      const allCertifiers = organizations.data.data
        .filter((item: any) => {
          return (
            centre.certifiers.filter((cert: any) => cert.certifier && cert.certifier.id === item.id).length === 0 &&
            item.type === 'certifier'
          );
        })
        .map((item: any) => ({
          value: item.id.toString(),
          label: item.name,
        }));
      allCertifiers.push({
        value: '-1',
        label: 'Otra',
      });
      this.setState({ allCertifiers });
    } catch (err) {
      console.log(err);
    }
  }

  async getMyCenterProfile() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const params = {
      filter: {
        relations: ['certifiers', 'certifiers.certifier'],
      },
    };
    const paramsPic = {
      filter: {
        relations: ['likes'],
      },
    };
    const centre: ICentreForm = (await getCentre(id, params)).data;
    const pictures: IPicture[] = (await getCentrePictures(id, paramsPic)).data.data;
    this.setState({ centre, pictures }, () => this.getCertifiers());
  }

  async onSave() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { centre } = this.state;
    if (!centre) return;
    centre.certifiers = centre.certifiers.map((certs: any) => {
      return {
        certifier: typeof certs.certifier === 'object' && certs.certifier ? certs.certifier.id : certs.certifier,
        ownId: certs.ownId,
        certifierName: certs.certifierName,
      };
    });
    await editCentre(id, centre);
    services.setValue(setCentreCurrency(centre.currency));
    services.pushNotification({
      title: '¡Guardado!',
      text: 'Los datos de perfil se han guardado correctamente',
      type: 'green',
    });
  }

  async onUploadImages(files: AttachedFile[]) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const formattedFiles = files.map(file => file.data);
    for (const file of formattedFiles) {
      const formData = new FormData();
      formData.set('file', file);
      await uploadCentrePictures(id, formData);
    }
    services.pushNotification({
      title: '¡Guardado!',
      text: 'Las fotos se han subido correctamente',
      type: 'green',
    });
    this.onSave();
    this.setState({ modal: '' }, () => this.getMyCenterProfile());
    localStorage.removeItem('arrayImages')
    window.location.reload();
  }

  async onRemoveImage() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const { selectedDeleteImage } = this.state;

    await deleteCentrePicture(id, selectedDeleteImage.toString());

    services.pushNotification({
      title: '¡Eliminado!',
      text: 'La foto se ha eliminado correctamente',
      type: 'red',
    });
    this.onSave();
    this.setState({ modal: '' }, () => this.getMyCenterProfile());
  }

  openModalSlider(openedImage: number) {
    this.setState({ openedImage }, () => {
      this.setState({ modal: 'modal-slider' });
    });
  }

  addCertifier() {
    const { selectedCertifierId, selectedCertifierName, selectedCertifierOwnId, centre, allCertifiers } = this.state;
    if (!centre || selectedCertifierId === '' || selectedCertifierName === '') return;
    centre.certifiers.push({
      certifierName: selectedCertifierName,
      certifier: parseInt(selectedCertifierId, undefined),
      ownId: selectedCertifierOwnId,
    });
    if (selectedCertifierId !== '-1') {
      const allCert = allCertifiers.filter(cert => selectedCertifierId !== cert.value);
      this.setState({
        centre,
        allCertifiers: allCert,
        selectedCertifierId: '',
        selectedCertifierName: '',
        selectedCertifierOwnId: '',
      });
    } else {
      this.setState({ centre, selectedCertifierId: '', selectedCertifierName: '', selectedCertifierOwnId: '' });
    }
  }

  removeCertifier(certName: string, cert: number | any) {
    const { centre, allCertifiers } = this.state;
    if (!centre) return;
    let certId = '-1';
    if (cert) {
      certId = typeof cert === 'number' ? cert.toString() : cert.id.toString();
      centre.certifiers = centre.certifiers.filter(cert => cert.certifierName !== certName && cert.id !== certId);
    } else {
      centre.certifiers = centre.certifiers.filter(cert => cert.certifierName !== certName);
    }
    if (certId !== '-1') {
      allCertifiers.push({
        value: certId,
        label: certName,
      });
      this.setState({ centre, allCertifiers, selectedCertifierId: certId, selectedCertifierName: certName });
    } else {
      this.setState({ centre, selectedCertifierId: certId });
    }
  }


  handleChangeDescriptions(e:any, centre: any, lang: string) {
    //e.preventDefault();
    console.log(e);
    const description =  e;
    /*if (centre) {
      if (!centre.description) centre.description = {};
      centre.description[lang] = description;
      this.setState({ centre });
    }*/

/*
    const { descriptions, selectedLanguageDescriptions, errors } = Object.assign(this.state);
    descriptions[selectedLanguageDescriptions] = description;
    if (!description || description === '') errors[selectedLanguageDescriptions] = 'required';
    else errors[selectedLanguageDescriptions] = '';*/
    //this.setState({ descriptions, errors });
  }




  render() {
    const {
      modal,
      activeLang,
      allCertifiers,
      centre,
      openedImage,
      pictures,
      selectedCertifierId,
      selectedCertifierName,
      selectedCertifierOwnId
    } = this.state;
    const {
      match: {
        params: { id },
      },
      staffPermissions,
    } = this.props;

    return (
      <>
        <HeaderMain
          title={'Perfil del centro'}
          mainButton={
            !staffPermissions
              ? {
                img: images.checkCircleWhiteSvg,
                text: 'Guardar cambios',
                onClick: () => this.onSave(),
                disabled: !havePermissionsDeals()
              }
              : undefined
          }
        />
        <MainLayoutSection>
          <MyCenterProfileNav centreId={id} />
          <MyCenterProfileDescriptionStyle>
            <div className="my-center-profile-description-left">
              <div className="my-center-profile-desxcription-form">
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-1">
                      <SelectBox
                        labelText="certificadoras"
                        withTooltip={false}
                        placeholder="Certificadora"
                        required={true}
                        className="rounded-title"
                        clearValue={selectedCertifierId === ''}
                        optionsText={allCertifiers}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={selectedCertifierId}
                        initialValue={selectedCertifierId}
                        icon={images.arrowDown2BlueSvg}
                        onChange={(value: string, label) => {
                          if (value === '-1') {
                            this.setState({ selectedCertifierId: value, selectedCertifierName: '' });
                          } else if (label) {
                            this.setState({ selectedCertifierId: value, selectedCertifierName: label });
                          }
                        }}
                      />
                    </FormGridItem>
                    <FormGridItem type="col-3">
                      <InputBox
                        required={true}
                        placeholder="Incluya ID"
                        className="rounded-title"
                        type="text"
                        max={20}
                        value={selectedCertifierOwnId}
                        labelText="ID"
                        filledValue={''}
                        onChange={(value: string) => this.setState({ selectedCertifierOwnId: value })}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  {selectedCertifierId === '-1' && (
                    <FormGridRow>
                      <span>Ponte en contacto con nosotros para añadir tu certificadora info@diveasapp.com</span>
                      {
                        /*<FormGridItem type="col-3">
                          <InputBox
                            required={true}
                            placeholder="Incluya nombre"
                            className="rounded-title"
                            type="text"
                            value={selectedCertifierName}
                            labelText="Nombre"
                            filledValue={''}
                            onChange={(value: string) => this.setState({ selectedCertifierName: value })}
                          />
                        </FormGridItem>*/
                      }
                    </FormGridRow>
                  )}
                  <FormGridRow>
                    <FormGridItem type="button">
                      <MainButton
                        type={'primary'}
                        onClick={() => this.addCertifier()}
                        text={'Añadir'}
                        iconImg={images.createWhiteSvg}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  {centre &&
                    centre.certifiers.map((item: any, key: number) => (
                      <FormGridRow key={`${key}-${item.name}`}>
                        <div className="certifier-languages-container">
                          <div className="certifier-languages-container__item">
                            <ListItemAdded
                              id={item.ownId !== '' ? `ID ${item.ownId}` : ''}
                              img={images.turtle}
                              text={item.certifierName}
                              onRemove={() => this.removeCertifier(item.certifierName, item.certifier)}
                            />
                          </div>
                        </div>
                      </FormGridRow>
                    ))}
                </FormGrid>
              </div>
              <div className="my-center-profile-desxcription-form">
                {/*  */}
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <SelectBox
                        labelText="Destacado en tu perfil"
                        withTooltip={false}
                        placeholder="Seleccione"
                        required={true}
                        className="rounded-title"
                        initialValue={''}
                        clearValue={false}
                        optionsText={CENTRE_FEATURES_DROPDOWN.filter(
                          feat => centre && (!centre.features || !centre.features.includes(feat.value))
                        )}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={''}
                        icon={images.arrowDown2BlueSvg}
                        onChange={value => {
                          if (!centre) return;
                          if (!centre.features) centre.features = [];
                          centre.features.push(value);
                          this.setState({ centre });
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <div className="feautres-center-container">
                    {centre &&
                      centre.features &&
                      centre.features.map((feat, i) => (
                        <ItemFeatureCenter
                          key={feat}
                          text={FULL_CENTRE_FEATURES_KEY_VALUES[feat]}
                          image={FULL_CENTRE_FEATURES_KEY_ICONS[feat]}
                          onClick={() => {
                            if (!centre || !centre.features) return;
                            centre.features = centre.features.filter(feature => feature !== feat);
                            this.setState({ centre });
                          }}
                        />
                      ))
                    }
                  </div>
                </FormGrid>
              </div>
              <div className="my-center-profile-desxcription-form">
                {/*  */}
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <SelectBox
                        labelText="idiomas"
                        withTooltip={false}
                        placeholder="Selecciona los idiomas"
                        required={true}
                        //searchable={true}
                        className="rounded-title"
                        initialValue={''}
                        clearValue={false}
                        optionsText={FULL_LANGUAGES.filter(
                          (lang: any) => centre && centre.languages && !centre.languages.includes(lang.value)
                        )}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={''}
                        icon={images.arrowDown2BlueSvg}
                        onChange={value => {
                          if (!centre || !centre.languages) return;
                          centre.languages.push(value);
                          this.setState({ centre });
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <div className="center-languages-container">
                      {centre &&
                        centre.languages &&
                        centre.languages.map((lang) => (
                          <div key={lang} className="center-languages-container__item">
                            <ItemTag
                              onClick={() => {
                                if (!centre || !centre.languages) return;
                                centre.languages = centre.languages.filter(language => language !== lang);
                                this.setState({ centre });
                              }}
                              text={capitalize(Object.assign(FULL_LANGUAGES_KEY_VALUES)[lang])}
                            />
                          </div>
                        ))}
                    </div>
                  </FormGridRow>
                </FormGrid>
              </div>
              <div className="my-center-profile-desxcription-form">
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <SelectBox
                        labelText="Moneda"
                        withTooltip={false}
                        placeholder="Euro (€)"
                        required={true}
                        searchable={true}
                        className="rounded-title"
                        initialValue={centre ? centre.currency : ''}
                        clearValue={false}
                        optionsText={CURRENCIES}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={centre ? centre.currency : ''}
                        icon={images.arrowDown2BlueSvg}
                        onChange={value => {
                          if (!centre) return;
                          centre.currency = value;
                          this.setState({ centre });
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
              </div>
            </div>

            <div className="my-center-profile-description-right">
              <div className="my-center-profile-description-right-languages">
                <div className="my-center-profile-description-right-languages-title">
                  <div className="my-center-profile-description-right-languages-title__text">
                    <LabelText>Descripción</LabelText>
                  </div>
                  <div className="my-center-profile-description-right-languages-title__tooltip">
                    <TooltipBox tooltipText={centre && centre.description ? centre.description[activeLang] : ''} />
                  </div>
                </div>

                <div className="my-center-profile-description-right-languages-choose">
                  <div className="my-center-profile-description-right-languages-choose__item">
                    <MainButton
                      type={activeLang === 'es' ? 'dark-blue' : 'secondary'}
                      onClick={() => this.setState({ activeLang: 'es' })}
                      text="Español"
                    />
                  </div>
                  <div className="my-center-profile-description-right-languages-choose__item">
                    <MainButton
                      type={activeLang === 'en' ? 'dark-blue' : 'secondary'}
                      onClick={() => this.setState({ activeLang: 'en' })}
                      text="Inglés"
                    />
                  </div>
                  {/*<div className="my-center-profile-description-right-languages-choose__item">
                    <MainButton
                      type={activeLang === 'de' ? 'dark-blue' : 'secondary'}
                      onClick={() => this.setState({ activeLang: 'de' })}
                      text="Alemán"
                    />
                  </div>
                  <div className="my-center-profile-description-right-languages-choose__item">
                    <MainButton
                      type={activeLang === 'it' ? 'dark-blue' : 'secondary'}
                      onClick={() => this.setState({ activeLang: 'it' })}
                      text="Italiano"
                    />
                  </div>
                  <div className="my-center-profile-description-right-languages-choose__item">
                    <MainButton
                      type={activeLang === 'pt' ? 'dark-blue' : 'secondary'}
                      onClick={() => this.setState({ activeLang: 'pt' })}
                      text="Portugués"
                    />
                  </div>
                  <div className="my-center-profile-description-right-languages-choose__item">
                    <MainButton
                      type={activeLang === 'fr' ? 'dark-blue' : 'secondary'}
                      onClick={() => this.setState({ activeLang: 'fr' })}
                      text="Francés"
                    />
                  </div>*/}
                </div>

                <div className="my-center-profile-description-right-languages__input">

                <Editor 
                  id={'description'}
                  apiKey={TINYMCE_KEY} 
                  initialValue={centre && centre.description[activeLang] ? centre.description[activeLang] : ''}
                  init={{
                    directionality : "ltr",
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat '
                  }}
                  onEditorChange={(event: any) => {
                    //event.preventDefault();
                    if (centre) {
                      if (!centre.description) centre.description = {};
                      centre.description[activeLang] = event;
                      //this.setState({ centre });
                    }
                  }}
                  //onEditorChange={(event: any) => this.handleChangeDescriptions(event, centre, activeLang)}
                  //onKeyUp={(event: any) => { event.preventDefault() }}
                  //onKeyDown={(event: any) => { event.preventDefault() }}
                  /*onKeyUp={(event: any) => this.handleChangeUpdate(event)}
                  onEditorChange={(event: any) => {
                    if (centre) {
                      //if (!centre.description) centre.description = {};
                      centre.description[activeLang] = event;
                      this.setState({ centre });
                    }
                  }}*/
                  ></Editor>

                {/*
                  <Editor 
                  
                    apiKey={TINYMCE_KEY} 
                    initialValue={descriptions[selectedLanguageDescriptions]}
                    init={{
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                      'bold italic | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat '
                    }}
                    onKeyDown={(event: any) => this.handleChangeUpdate(event)}
                    onKeyUp={(event: any) => this.handleChangeUpdate(event)}
                    onEditorChange={(event: any) => this.handleChangeDescriptions(event)}
                    ></Editor>
                <Editor 
                  apiKey={TINYMCE_KEY} 
                  initialValue={centre && centre.description ? centre.description[activeLang] : ''}
                  init={{
                    menubar: false,
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | numlist bullist'
                  }}
                  onKeyUp={(event: any) => { event.preventDefault() }}
                  onChange={(event: any) => {
                    event.preventDefault();
                    if (centre) {
                      if (!centre.description) centre.description = {};
                      centre.description[activeLang] = event.target.getContent();
                      this.setState({ centre });
                    }
                  }}
                  ></Editor>*/}
                  {' '}
                </div>
                {/* 
                <div className="my-center-profile-description-right-languages__button">
                  <MainButton type={'primary'} onClick={() => {}} text={'Guardar'} iconImg={images.saveWhiteSvg} />
                </div> */}
              </div>

              <div className="my-center-profile-description-right__title">
                <LabelText>Fotos del centro</LabelText>
              </div>
              <div className="my-center-profile-description-right__grid">
                {pictures &&
                  pictures.map((pic: IPicture, index: number) => {
                    //if (index < 12) {
                    return (
                      <div
                        key={pic.src}
                        className="my-center-profile-description-right__grid__item"
                      >
                        {/*pictures.length > 12 && index === 11 && (
                            <div className="my-center-profile-description-right__grid__item__veil">
                              <BodyDestacadoText>{`+ ${pictures.length - 12}`}</BodyDestacadoText>
                            </div>
                          )*/}
                        <img src={pic.src} alt="" onClick={() => this.openModalSlider(index)} />
                        <div className="selected-pictures__delete-icon">
                          <div className="selected-pictures__icon">
                            <img src={images.closeWhiteSvg} onClick={() => this.setState({ modal: 'modal-delete', selectedDeleteImage: pic.id })} />
                          </div>
                        </div>
                      </div>
                    );
                    //}
                  })}
                {!pictures || (pictures.length === 0 && <div>Sin imágenes</div>)}
              </div>
              <div className="my-center-profile-description-right__add">
                <div className="my-center-profile-description-right__add__button">
                  <MainButton
                    type="secondary"
                    iconImg={images.uploadSvg}
                    text="Subir imágenes"
                    onClick={() => this.setState({ modal: 'modal-upload' })}
                  />
                </div>
              </div>
            </div>
          </MyCenterProfileDescriptionStyle>
          {modal === 'modal-upload' && (
            <ModalUploadFiles
              showModal={modal === 'modal-upload'}
              onClose={() => this.setState({ modal: '' })}
              onConfirm={(files: AttachedFile[]) => this.onUploadImages(files)}
            />
          )}
          {modal === 'modal-slider' && (
            <ModalSlider
              showModal={modal === 'modal-slider'}
              onClose={() => this.setState({ modal: '' })}
              pictures={pictures ? pictures : []}
              pictureSelected={openedImage}
              canAddFav={true}
            />
          )}
          {modal === 'modal-delete' && (
            <ModalDelete
              showModal={modal === 'modal-delete'}
              onCancel={() => this.setState({ modal: '' })}
              title={'Vas a eliminar una imagen'}
              subtitle={'¿estás seguro?'}
              onRemove={() => this.onRemoveImage()}
            />
          )}
        </MainLayoutSection>
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(withRouter(MyCenterProfileDescription));
