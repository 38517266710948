import * as React from 'react';
import { ReassignActivityStyle } from './modal-reassign-activity-style';
import SelectBox from '../../select-box';
import { images } from '../../../assets/images';
import BodyText from '../../text/body-text/body-text';
import { getCentreActivities } from '../../../services/centre';
import { IDropdown } from '../../../models/generic';
import MainButton from '../../main-button';
import moment from 'moment';
import { getTimeFormatted } from '../../../utils/time';
import { ITimeBlocks } from '../../../models/generic';
import { getOneBookings } from '../../../services/bookings';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

interface ReassignActivityProps extends RouteComponentProps, WithTranslation {
  onNext: (activitySelected: any) => void;
  selectedBooking: any;
  t: any
}

interface ReassignActivityState {
  selectedActivity: any;
  activitiesDropdown: IDropdown[];
  error: string;
}

class ReassignActivity extends React.Component<ReassignActivityProps, ReassignActivityState> {
  constructor(props: ReassignActivityProps) {
    super(props);
    this.state = {
      selectedActivity: null,
      activitiesDropdown: [],
      error: '',
    };
  }

  componentDidMount() {
    this.getCentreActivities();
  }


  custom_sort(a: any, b: any) {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  }





  async getCentreActivities() {
    const { selectedBooking } = this.props;
    const centreId = selectedBooking.centreId;

    const params = {
        filter: {
          relations: ['activity', 'activity.template'],
        },
      };
    const completeBooking = (await getOneBookings(selectedBooking.id.toString(), params)).data;

    const params2 = {
      filter: {
        where: {
          cancelled: false,
          template: completeBooking.activity.template.id,
          bookBefore: { method: '>=', value: new Date() },
        }
      }
    };
    let activities = (await getCentreActivities(centreId, params2)).data.data.filter((activity: any) => { return activity.id !== completeBooking.activity.id});

    activities.sort(this.custom_sort);

    const activitiesDropdown = activities.map((activity: any) => {
      return {
        value: activity.id.toString(),
        label: `${activity.name} ${moment(activity.date).format('DD/MM/YYYY')} ${this.getTimeFormatBlock(activity.timeBlocks)}`,
      };
    });

    this.setState({ activitiesDropdown });

  }

  getTimeFormatBlock(timeBlocks: any) {
    let startHour = 24;
    let startMinutes = 0;

    timeBlocks.forEach((timeBlock: ITimeBlocks) => {
      if (timeBlock.start.hour < startHour) {
        startHour = timeBlock.start.hour;
        startMinutes = timeBlock.start.minutes;
      }
    });
    return getTimeFormatted(startHour, startMinutes);
  }

  onSave() {
    const { selectedActivity } = this.state;
    const { onNext } = this.props;
    if (!selectedActivity || selectedActivity.length === 0) this.setState({ error: 'required' });
    else {
      onNext(selectedActivity);
    }
  }

  render() {
    const { activitiesDropdown, selectedActivity, error } = this.state;
    const { t } = this.props

    return (
      <ReassignActivityStyle>
        <div className="reassign-activity-disclaimer">
          <BodyText>
          {t('modalMessages:modal-reassign-activity.reassign-title')}
          </BodyText>
        </div>
        <div className="reassign-activity-select">
          <SelectBox
            labelText=""
            errorCode={error}
            placeholder={t('components:actions.choose-activity')}
            required={true}
            multiple={true}
            className="rounded"
            optionsText={activitiesDropdown}
            optionKey={'value'}
            optionValue={'label'}
            defaultValue={''}
            initialValue={''}
            initialMultipleValues={selectedActivity}
            defaultMultipleValues={selectedActivity}
            icon={images.arrowDown2BlueSvg}
            onChangeMultiple={value => {
              this.setState({ selectedActivity: value });
            }}
            onChange={() => { }}
          />
        </div>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={t('components:actions.save')} type="primary" onClick={() => this.onSave()} />
            </div>
          </div>
        </div>
      </ReassignActivityStyle>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ReassignActivity));
