import * as React from 'react';
import { PlanningReportStyle } from './planning-report-style';
import { MainLayoutContent } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import UnavailableContent from '../../../components/unavailable-content/unavailable-content';

export interface IPlanningReportProps {}

export interface IPlanningReportState {}

export default class PlanningReport extends React.Component<IPlanningReportProps, IPlanningReportState> {
  constructor(props: IPlanningReportProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <HeaderMain title={'Embarcaciones'} />
        <UnavailableContent body={'Si dispones de múltiples embarcaciones, podrás gestionar las plazas disponibles en tu oferta diaria también en Diveasapp'} />
        {/* <MainLayoutContent> */}
        {/* <PlanningReportStyle>
          <p>Content</p>
        </PlanningReportStyle> */}
        {/* </MainLayoutContent> */}
      </>
    );
  }
}
