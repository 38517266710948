import React from 'react';
import ModalContainer from '../../../../components/modals/modal-container';
import { ModalInviteCenterStyle } from './modal-invite-center-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import InputBox from '../../../../components/input-box';
import InputArea from '../../../../components/input-area/input-area';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import MainButton from '../../../../components/main-button';
import { isValidEmail } from '../../../../utils/validation';
import { copyOf } from '../../../../utils/other';
import { postInviteCentre } from '../../../../services/collaborator';
import services from '../../../../services/services';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ModalInviteCenterProps extends WithTranslation{
  showModal: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  title: string;
  text?: string;
  t:any;
}

interface ModalInviteCenterState {
  invitation: {
    email: string;
    subject: string;
    body: string;
    name: string;
    ownId?: string;
  };
  errors: { [key: string]: string };
}

class ModalInviteCenter extends React.Component<ModalInviteCenterProps, ModalInviteCenterState> {
  constructor(props: ModalInviteCenterProps) {
    super(props);
    this.state = {
      invitation: {
        email: '',
        subject: '',
        body: '',
        name: '',
      },
      errors: {},
    };
  }

  validate(field: string, value: string, required: boolean) {
    if (required && (!value || value === '')) return 'required';
    if (field === 'email' && !isValidEmail(value)) return 'invalid-email';
    return '';
  }

  handleChange(field: string, value: string, required: boolean = true) {
    const { invitation, errors } = Object.assign(this.state);
    invitation[field] = value;
    errors[field] = this.validate(field, value, required);
    this.setState({ invitation, errors });
  }

  async handleSubmit() {
    const { onSuccess, t } = this.props;
    const { invitation, errors } = this.state;
    const formatInvitation = copyOf(invitation);
    const formatErrors = copyOf(errors);
    const organizationId = localStorage.getItem('organizationId');
    formatErrors.email = this.validate('email', invitation.email, true);
    formatErrors.subject = this.validate('subject', invitation.subject, true);
    formatErrors.body = this.validate('body', invitation.body, true);
    formatErrors.name = this.validate('name', invitation.name, true);
    this.setState({ errors: formatErrors });
    if (!invitation.ownId || invitation.ownId === '') delete formatInvitation.ownId;
    const hasErrors = !Object.values(formatErrors).every((value: any) => value === '');
    if (organizationId && !hasErrors) {
      await postInviteCentre(organizationId, formatInvitation);
      services.showModalSuccess(t('modalMessages:success-title'), t('modalMessages:centinv'));
      onSuccess();
    }
  }

  render() {
    const { showModal, onSuccess, onCancel, title, text, t } = this.props;
    const { invitation, errors } = this.state;
    return (
      <ModalContainer title={''} active={showModal} modalClose={() => onCancel()}>
        <ModalInviteCenterStyle>
          <div className="modal-generic-file-title">
            <SubheadDestacadoText>{title}</SubheadDestacadoText>
          </div>
          <div className="modal-invite-center-subhead">
            {t('modalMessages:invcent')} <br /> <br />
            {t('modalMessages:invcentyes')}
          </div>

          <FormGrid>
            <FormGridRow>
              <FormGridItem type="col-0">
                <InputBox
                  placeholder={t('modalMessages:write')}
                  className="rounded"
                  type="text"
                  value={invitation.name}
                  labelText={t('modalMessages:centrename')}
                  errorCode={errors.name}
                  onChange={value => this.handleChange('name', value)}
                  required={true}
                />
              </FormGridItem>
            </FormGridRow>

            <FormGridRow>
              <FormGridItem type="col-0">
                <InputBox
                  placeholder={t('modalMessages:write')}
                  className="rounded"
                  type="text"
                  value={invitation.email}
                  labelText={t('modalMessages:centremail')}
                  errorCode={errors.email}
                  onChange={value => this.handleChange('email', value)}
                  required={true}
                />
              </FormGridItem>
            </FormGridRow>

            <FormGridRow>
              <FormGridItem type="col-0">
                <InputBox
                  placeholder={t('modalMessages:write')}
                  labelText={t('modalMessages:mailsub')}
                  className="rounded"
                  type="text"
                  value={invitation.subject}
                  errorCode={errors.subject}
                  onChange={value => this.handleChange('subject', value)}
                  required={true}
                />
              </FormGridItem>
            </FormGridRow>

            <FormGridRow>
              <FormGridItem type="col-0">
                <InputBox
                  placeholder={t('modalMessages:write')}
                  labelText={t('modalMessages:idcentre')}
                  className="rounded"
                  type="text"
                  value={invitation.ownId || ''}
                  errorCode={errors.ownId}
                  onChange={value => this.handleChange('ownId', value)}
                />
              </FormGridItem>
            </FormGridRow>

            <FormGridRow>
              <FormGridItem type="col-0">
                <InputArea
                  value={invitation.body}
                  name={''}
                  required={true}
                  labelText={t('modalMessages:mailbody')}
                  placeholder={t('modalMessages:write')}
                  errorCode={errors.body}
                  error={!!errors.body && errors.body !== ''}
                  onChange={(ev: any) => this.handleChange('body', ev.target.value)}
                />
              </FormGridItem>
            </FormGridRow>
          </FormGrid>

          <div className="modal-generic-file-button">
            <MainButton onClick={() => this.handleSubmit()} text={t('modalMessages:modal-invite-staff.send-invitation-email')} type="primary" />
          </div>
        </ModalInviteCenterStyle>
      </ModalContainer>
    );
  }
}


export default withTranslation(['modalMessages']) (ModalInviteCenter)