import * as React from 'react';
import { ActivityDetailGeneralStyle } from './activity-detail-general-style';
import CardActivity from '../../../../components/card-activity/card-activity';
import ItemSectionDetail from '../../../../components/item-sections-detail/item-sections-detail';
import { images } from '../../../../assets/images';
import TextDestacadoText from '../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../components/text/text-text/text-text';
import { IActivity } from '../../../../models/activities';
import { DiveDisciplinesKeyValues, DiveKindsKeyValues } from '../../../../constants/dive-dropdowns';
import { CANCELLATION_POLICIES_KEY_VALUES } from '../../../../constants/cancellation-policies';
import { capitalize, getLanguagesText } from '../../../../utils/texts';
import { TRANSPORT_TYPES_KEY_VALUES } from '../../../../constants/transport';
import { ITimeBlocks, IMultilang, IId } from '../../../../models/generic';
import { getUserLanguageComment, copyOf, calculateActivityDuration } from '../../../../utils/other';
import ModalRegistreDive from '../../../../components/modals/modal-registre-dive/modal-registre-dive';
import { IDiveForm } from '../../../../models/dive-forms';
import { AttachedFile } from '../../../../types/file';
import ModalContainer from '../../../../components/modals/modal-container';
import CreateActivitiesGeneralData from '../../../dive-offers/activities/modal-create-activities/create-activities-general-data/create-activities-general-data';
import CreateActivitiesBoats from '../../../dive-offers/activities/modal-create-activities/create-activities-boats/create-activities-boats';
import {
  IDiveGeneralData,
  IMinimumRequirements,
  IOtherServices,
  IBoatsData,
  ICourseGeneralData,
} from '../../../../models/activity-template';
import CreateActivitiesMinimunRequirements from '../../../dive-offers/activities/modal-create-activities/create-activities-minimum-requirements/create-activities-minimum-requirements';
import CreateActivitiesOtherServices from '../../../dive-offers/activities/modal-create-activities/create-activities-other-services/create-activities-other-services';
import CreateActivitiesDetails from '../../../dive-offers/activities/modal-create-activities/create-activities-details/create-activities-details';
import CreateActivitiesIncludedServices from '../../../dive-offers/activities/modal-create-activities/create-activities-included-services/create-activities-included-services';
import CreateActivitiesIncludedEquipment from '../../../dive-offers/activities/modal-create-activities/create-activities-included-equipment/create-activities-included-equipment';
import CreateActivitiesPrices from '../../../dive-offers/activities/modal-create-activities/create-activities-prices/create-activities-prices';
import { ModalCreateActivitiesStyle } from '../../../dive-offers/activities/modal-create-activities/modal-create-activities-style';
import { getCentres } from '../../../../services/centre';
import { transformToUser, getUserUnit } from '../../../../utils/measurement-units';
import PlanningDiveSite from '../../modal-planning-activity/planning-dive-site/planning-dive-site';
import PlanningDiver from '../../modal-planning-activity/planning-diver/planning-diver';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ICourseActivityInfo } from '../../../../models/activities';
import PlanningCourseDate, { PlanningCourseDateState } from '../../../../containers/planning/modal-planning-activity/planning-course-date/planning-course-date';
import { getTimeFormatted } from '../../../../utils/time';
import moment from 'moment';
import { CENTRE_SERVICES_KEY_ICON, CENTRE_SERVICES_KEY_VALUE } from '../../../../constants/centre-services';
import { COURSE_KINDS_KEY_VALUES } from '../../../../constants/course';
import CreateActivitiesGeneralDataTraining from '../../../dive-offers/activities/modal-create-activities/create-activities-general-data-training/create-activities-general-data-training';
import CreateActivitiesGallery from '../../../dive-offers/activities/modal-create-activities/create-activities-gallery/create-activities-gallery';
import parser from 'html-react-parser';
import { ActivityDetailGeneralController } from './activity-detail-general-controller';
import CalendarBox from '../../../../components/calendar-box';
import LabelText from '../../../../components/text/label-text/label';
import InputBox from '../../../../components/input-box';
import MainButton from '../../../../components/main-button';



class ActivityDetailGeneral extends ActivityDetailGeneralController {

  renderSites(item: any, index: number) {
    return (
      <div className="activity-detail-general-right-section__sites" key={index}>
        <div style={{ marginBottom: '2em' }}>
          <p style={{ color: '#61656D', fontSize: '20px' }}>{index + 1} - {item.name}</p>
        </div>
        <div style={{ paddingLeft: 20 }}>
          <div className="activity-detail-general-double">
            <div className="activity-detail-general-double__title">
              <TextDestacadoText>Ubicación:</TextDestacadoText>
            </div>
            <div className="activity-detail-general-double__text">
              <TextText>{item.latitude} {item.longitude}</TextText>
            </div>
          </div>
          <div className="activity-detail-general-double">
            <div className="activity-detail-general-double__title">
              <TextDestacadoText>Punto de inmersión:</TextDestacadoText>
            </div>
            <div className="activity-detail-general-double__text">
              <TextText>{item.name}</TextText>
            </div>
          </div>
          <div className="activity-detail-general-double">
            <div className="activity-detail-general-double__title">
              <TextDestacadoText>Lugar:</TextDestacadoText>
            </div>
            <div className="activity-detail-general-double__text">
              <TextText>{item.state}</TextText>
            </div>
          </div>
          <div className="activity-detail-general-double">
            <div className="activity-detail-general-double__title">
              <TextDestacadoText>Profundidad mín - máx:</TextDestacadoText>
            </div>
            <div className="activity-detail-general-double__text">
              <TextText>{item.minDepth} - {item.maxDepth} m</TextText>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {

    const { activity, status, centreId } = this.props;
    
    const { levels, levelsLoaded, renderModal, selectedDiveIndex } = this.state;
    const dates = activity && activity.timeBlocks && activity.timeBlocks.length !== 0 && activity.timeBlocks[0].start ? activity.timeBlocks.filter((t: any) => !!t.start.date) : [];
    const courseInfo = activity && activity.courseInfo ? this.courseTemplateInfo(activity) : null;
    if (!activity) return '';

    let levelName = this.state.levelName;

    let confirmedDivers = [];
    if (activity.bookings) {
      for (const booking of activity.bookings) {
        if (booking.divers && booking.divers.length) confirmedDivers.push(...booking.divers);
      }
    }
    let totalDivers = 0;
    if (activity.bookings && activity.bookings.length > 0) {
      //totalDivers = 1;
      for (let i = 0; i < activity.bookings.length; i++) {
        if (activity.bookings[i].rejected == false && activity.bookings[i].confirmed == true && activity.bookings[i].cancelled == 'noCancelled') {
          totalDivers = totalDivers + (activity.bookings[i].divers.length + 1)
        }
      }
    }
    
    
    const { startHour, endHour } = this.getHours();
    const duration = calculateActivityDuration(activity.timeBlocks);
    const type = activity.courseInfo ? "Formación" : "Inmersión";
    const discipline = activity.discipline;
   
     
    return (
      <ActivityDetailGeneralStyle>
        <div className="activity-detail-general-hero">
          <CardActivity
            withCard={{
              price: activity.price,
              type: activity.template
                ? CANCELLATION_POLICIES_KEY_VALUES[activity.cancellationPolicy.type]
                : '',
            }}
            activity={activity}
            startHour={startHour}
            type={type}
            discipline={discipline}
            endHour={endHour}
            name={activity.name}
            date={activity.date}
            sortDates={true}
            bookings={true}
            dates={dates.length > 0 ? dates : undefined}
            backgroundImg={activity.pictures && activity.pictures.length > 0 ? activity.pictures[0] : undefined}
            oneditPrices={this.oneditPrices}
            oneditDates={this.oneditDates}
          />
        </div>
        <div className="activity-detail-general-container">
          <div className="activity-detail-general-left">

            {activity.courseInfo && activity.courseInfo.theoreticSessions && activity.courseInfo.theoreticSessions.length > 0 && (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="PLANIFICACIÓN FASE TEÓRICA"
                  onEdit={activity.bookings && activity.bookings.length === 0 ? () => this.setState({ renderModal: 'edit-times-curse' }) : undefined}>
                  {activity.courseInfo.theoreticSessions.map((session: any, key) => (
                    session && session.onlineTheory && (
                      <div key={key} className="activity-detail-general-left-section_card-planning">
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Tipo de sesión:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>Teórica - online</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Fecha:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{moment(new Date(session.onlineTheory.timeBlock.start.date), 'YYYY-MM-DD').format('DD/MM/YYYY')}</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Hora:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{`${this.formattedHours(session.onlineTheory.timeBlock)}`}</TextText>
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                  {activity.courseInfo.theoreticSessions.map((session: any, key) => (
                    session && session.presentialTheory && (
                      <div key={key} className="activity-detail-general-left-section_card-planning">
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Tipo de sesión:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>Teórica - presencial</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Fecha:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{moment(new Date(session.presentialTheory.timeBlock.start.date), 'YYYY-MM-DD').format('DD/MM/YYYY')}</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Hora:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{`${this.formattedHours(session.presentialTheory.timeBlock)}`}</TextText>
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                </ItemSectionDetail>
              </div>
            )}

            {activity.template && activity.template.courseInfo && activity.template.courseInfo.theoreticPhases && activity.template.courseInfo.theoreticPhases.length ? (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="Fases teóricas"
                  icon={images.theorySvg}>

                  {activity.template.courseInfo.theoreticPhases.map((theoreticPhase: any, key: number) => (
                    theoreticPhase && theoreticPhase.onlineTheory && (
                      <div key={key} className="activity-detail-general-left-section_card">
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Nº sesiones online:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{`${theoreticPhase.onlineTheory.sessions} sesiones`}</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Idioma:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{getLanguagesText(theoreticPhase.onlineTheory.languages)}</TextText>
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                  {activity.template.courseInfo.theoreticPhases.map((theoreticPhase: any, key: number) => (
                    theoreticPhase && theoreticPhase.presentialTheory && (
                      <div key={key} className="activity-detail-general-left-section_card">
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Nº sesiones presenciales:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{`${theoreticPhase.presentialTheory.sessions} sesiones`}</TextText>
                          </div>
                        </div>
                        <div className="activity-detail-general-double">
                          <div className="activity-detail-general-double__title">
                            <TextDestacadoText>Idioma:</TextDestacadoText>
                          </div>
                          <div className="activity-detail-general-double__text">
                            <TextText>{getLanguagesText(theoreticPhase.presentialTheory.languages)}</TextText>
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                </ItemSectionDetail>
              </div>
            ) : ''}


            {activity.courseInfo ? (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="Datos generales"
                  icon={images.dataGeneral}
                  onEdit={activity.bookings && activity.bookings.length == 0 ? () => this.setState({ renderModal: 'edit-general-data-formation' }) : undefined}
                >
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Certificadora:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.course && activity.course.certifier ? activity.course.certifier.name : ''}</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Curso/Especialidad:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.course ? activity.course.name : ''}</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Tipo:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.course ? COURSE_KINDS_KEY_VALUES[activity.course.kind] : ''}</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
              </div>
            ) : (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="Datos generales"
                  icon={images.dataGeneral}
                  onEdit={activity.bookings && activity.bookings.length == 0 ? () => this.setState({ renderModal: 'edit-general-data' }) : undefined}
                >
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Duración:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{`${Math.floor(duration.hours)} horas y ${Math.floor(
                        duration.minutes
                      )} minutos`}</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Disciplina:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{DiveDisciplinesKeyValues[activity.discipline]}</TextText>
                    </div>
                  </div>
                  {activity.kind && activity.kind.length > 0 && (
                    <div className="activity-detail-general-double">
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>Tipo de inmersión:</TextDestacadoText>
                      </div>
                      <div className="activity-detail-general-double__text">
                        <TextText>
                          {capitalize(
                            activity.kind
                              .map((kind: string) => {
                                return DiveKindsKeyValues[kind];
                              })
                              .toString()
                              .replace(/,/g, ', ')
                              .toLowerCase()
                          )}
                        </TextText>
                      </div>
                    </div>
                  )}
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Nº de inmersiones:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.diveCount}</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Plazas ocupadas:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{totalDivers}</TextText>
                    </div>
                  </div>
                   
                    <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>{activity.maxDivers? "Aforo máximo:" : "Sin aforo máximo"}</TextDestacadoText>
                    </div>
                    {activity.maxDivers &&(<div className="activity-detail-general-double__text">
                      <TextText>{activity.maxDivers}</TextText>
                    </div>)}
                  </div>
                </ItemSectionDetail>
              </div>

            )}
            {!activity.courseInfo && (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="Horarios"
                  icon={images.dataGeneral}
                  onEdit={() => this.oneditDates()}
                >
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Horarios:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <div>
                        {this.datesActivity().length > 0 &&
                          this.datesActivity().sort((a: any, b: any) => {
                            if (new Date(a.start.date).getTime() === new Date(b.start.date).getTime()) {
                              if (a.start.hour < b.start.hour) {
                                return -1
                              } else {
                                return 1;
                              }
                            }
                            return new Date(a.start.date).getTime() - new Date(b.start.date).getTime()
                          }).map((d: any, i: any) =>
                            <div key={i} className="pop-calendar-date">
                              <div className="pop-calendar-date__day">
                                <p>{capitalize(moment(activity.date).format('DD/MM/YYYY'))} {`${getTimeFormatted(d.start.hour, (d.start.minutes + d.start.seconds))} - ${getTimeFormatted(
                                  d.end.hour, (d.end.minutes + d.end.seconds)
                                )}`}</p>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </ItemSectionDetail>
              </div>
            )}

            {!activity.courseInfo && (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="Embarcaciones"
                  icon={images.boatsSvg}
                  onEdit={activity.bookings && activity.bookings.length == 0 ? () => this.setState({ renderModal: 'edit-boats' }) : undefined}
                >
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Se necesita embarcación:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.needBoat ? 'Si' : 'No'}</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
              </div>
            )}

            <div className="activity-detail-general-left__section">
              <ItemSectionDetail
                title="Servicios incluidos"
                icon={images.servicesSvg}
                onEdit={activity.bookings && activity.bookings.length == 0 ? () => this.setState({ renderModal: 'edit-services' }) : undefined}
              >
                <div className="activity-detail-general-services">
                  {activity.services &&
                    activity.services.length > 0 &&
                    activity.services.map((serviceItem: any, index: number) => (
                      <div className="activity-detail-general-services__item" key={index}>
                        <div className="activity-detail-general-services__item__icon">
                          <img src={CENTRE_SERVICES_KEY_ICON[serviceItem.name]} alt="" />
                        </div>
                        <div className="activity-detail-general-services__item__text">
                          <TextText>{CENTRE_SERVICES_KEY_VALUE[serviceItem.name]}</TextText>
                        </div>
                      </div>
                    ))}
                </div>
              </ItemSectionDetail>
            </div>

            <div className="activity-detail-general-left__section">
              <ItemSectionDetail
                title="Texto descriptivo de actividad"
                onEdit={() => this.setState({ renderModal: 'edit-observations' })}
              >
                <div className="activity-detail-general-left__text">
                {activity.descriptions ? parser(getUserLanguageComment(activity.descriptions)) : ''}
                </div>

              </ItemSectionDetail>
            </div>
            {activity.transport && (
              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="Desplazamiento"
                  icon={images.transportSvg}
                >
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Salida desde:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{capitalize(activity.transport.departurePoint)}</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Medio de transporte:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{TRANSPORT_TYPES_KEY_VALUES[activity.transport.transport]}</TextText>
                    </div>
                  </div>
                </ItemSectionDetail>
              </div>
            )}
          </div>
          <div className="activity-detail-general-right">
            <div className="activity-detail-general-right-section">
              <div className="activity-detail-general-right-section__observaciones">
                <div className="title">
                  <TextDestacadoText>Observaciones</TextDestacadoText>
                  {(<img src={images.editSvg} alt="" onClick={() => this.setState({ renderModal: 'edit-observations' })} />)}
                </div>
                <div style={{ marginBottom: 10 }} />
                <div className="activity-detail-general-right__text">
                {activity.comments ? parser(getUserLanguageComment(activity.comments)) : ''}
                </div>

              </div>
              {activity.courseInfo && activity.courseInfo.practicalSessions && activity.courseInfo.practicalSessions.length > 0 && (
                <div className="activity-detail-general-right__section">
                  <ItemSectionDetail
                    title="PLANIFICACIÓN FASE PRÁCTICA"
                    onEdit={activity.bookings && activity.bookings.length == 0 ? () => this.setState({ renderModal: 'edit-times-curse' }) : undefined}>
                    {activity.courseInfo.practicalSessions.map((session: any, key) => (
                      session && session.closedWaterDives && (
                        <div key={key} className="activity-detail-general-right-section_card-planning">
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Tipo de sesión:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>Práctica - Aguas cerradas</TextText>
                            </div>
                          </div>
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Fecha:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>{moment(new Date(session.closedWaterDives.timeBlock.start.date), 'YYYY-MM-DD').format('DD/MM/YYYY')}</TextText>
                            </div>
                          </div>
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Hora:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>{`${this.formattedHours(session.closedWaterDives.timeBlock)}`}</TextText>
                            </div>
                          </div>
                        </div>
                      )
                    ))}
                    {activity.courseInfo.practicalSessions.map((session: any, key) => (
                      session && session.openWaterDives && (
                        <div key={key} className="activity-detail-general-right-section_card-planning">
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Tipo de sesión:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>Práctica - Aguas Abiertas</TextText>
                            </div>
                          </div>
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Fecha:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>{moment(new Date(session.openWaterDives.timeBlock.start.date), 'YYYY-MM-DD').format('DD/MM/YYYY')}</TextText>
                            </div>
                          </div>
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Hora:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>{`${this.formattedHours(session.openWaterDives.timeBlock)}`}</TextText>
                            </div>
                          </div>
                        </div>
                      )
                    ))}
                  </ItemSectionDetail>
                </div>
              )}

              {activity.template && activity.template.courseInfo && activity.template.courseInfo.practicalPhases && activity.template.courseInfo.practicalPhases.length ? (
                <div className="activity-detail-general-right__section">
                  <ItemSectionDetail
                    title="Fases prácticas"
                    icon={images.theorySvg}
                  >
                    {activity.template.courseInfo.practicalPhases.map((practicalPhase: any, key: number) => (
                      practicalPhase && practicalPhase.closedWaterDives && (
                        <div key={key} className="activity-detail-general-right-section_card">
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Nº inmersiones piscina/aguas confinadas:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>{practicalPhase.closedWaterDives.sessions && practicalPhase.closedWaterDives.sessions}</TextText>
                            </div>
                          </div>
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Idiomas:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>{practicalPhase.closedWaterDives.languages && getLanguagesText(practicalPhase.closedWaterDives.languages)}</TextText>
                            </div>
                          </div>
                        </div>
                      )
                    ))}
                    {activity.template.courseInfo.practicalPhases.map((practicalPhase: any, key: number) => (
                      practicalPhase && practicalPhase.openWaterDives && (
                        <div key={key} className="activity-detail-general-right-section_card">
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Nº inmersiones aguas abiertas:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>{practicalPhase.openWaterDives.sessions && practicalPhase.openWaterDives.sessions}</TextText>
                            </div>
                          </div>
                          <div className="activity-detail-general-double">
                            <div className="activity-detail-general-double__title">
                              <TextDestacadoText>Idiomas:</TextDestacadoText>
                            </div>
                            <div className="activity-detail-general-double__text">
                              <TextText>{practicalPhase.openWaterDives.languages && getLanguagesText(practicalPhase.openWaterDives.languages)}</TextText>
                            </div>
                          </div>
                        </div>
                      )
                    ))}
                  </ItemSectionDetail>
                </div>
              ) : ''}

              <div className="activity-detail-general-left__section">
                <ItemSectionDetail
                  title="Requisitos mínimos"
                  icon={images.minimunRequirementsSvg}
                  onEdit={activity.bookings && activity.bookings.length === 0 ? () => this.setState({ renderModal: 'edit-minimum-requirements' }) : undefined}
                >
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Edad mínima:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{activity.minimumRequisites.age} años</TextText>
                    </div>
                  </div>
                  <div className="activity-detail-general-double">
                    <div className="activity-detail-general-double__title">
                      <TextDestacadoText>Nivel mínimo del buceador:</TextDestacadoText>
                    </div>
                    <div className="activity-detail-general-double__text">
                      <TextText>{levelName ? levelName : 'Ninguna'}</TextText>
                    </div>
                  </div>
                  {activity.minimumRequisites.depth && (
                    <div className="activity-detail-general-double">
                      <div className="activity-detail-general-double__title">
                        <TextDestacadoText>Profundidad mínima:</TextDestacadoText>
                      </div>
                      <div className="activity-detail-general-double__text">
                        <TextText>
                          Hasta {`${transformToUser('depth', activity.minimumRequisites.depth)} ${getUserUnit('depth')}`}
                        </TextText>
                      </div>
                    </div>
                  )}
                  {activity.minimumRequisites.requisites &&
                    activity.minimumRequisites.requisites.findIndex((r: any) => r.requisite === 'medicalCertificate') >
                    -1 && (
                      <div className="activity-detail-general-double">
                        <div className="activity-detail-general-double__title">
                          <TextDestacadoText>Certificado médico:</TextDestacadoText>
                        </div>
                        <div className="activity-detail-general-double__text">
                          <TextText>
                            {
                              activity.minimumRequisites.requisites.find((r: any) => r.requisite === 'medicalCertificate')
                                .data
                            }
                          </TextText>
                        </div>
                      </div>
                    )}
                  {activity.minimumRequisites.requisites &&
                    activity.minimumRequisites.requisites.findIndex((r: any) => r.requisite === 'diveCount') > -1 && (
                      <div className="activity-detail-general-double">
                        <div className="activity-detail-general-double__title">
                          <TextDestacadoText>Nº mínimo inmersiones:</TextDestacadoText>
                        </div>
                        <div className="activity-detail-general-double__text">
                          <TextText>
                            {activity.minimumRequisites.requisites.find((r: any) => r.requisite === 'diveCount').data}
                          </TextText>
                        </div>
                      </div>
                    )}
                  {activity.minimumRequisites.requisites &&
                    activity.minimumRequisites.requisites.findIndex((r: any) => r.requisite === 'speciality') > -1 && (
                      <div className="activity-detail-general-double">
                        <div className="activity-detail-general-double__title">
                          <TextDestacadoText>Especialidad:</TextDestacadoText>
                        </div>
                        <div className="activity-detail-general-double__text">
                          <TextText>
                            {activity.minimumRequisites.requisites.find((r: any) => r.requisite === 'speciality').data}
                          </TextText>
                        </div>
                      </div>
                    )}
                  {activity.minimumRequisites.requisites &&
                    activity.minimumRequisites.requisites.findIndex((r: any) => r.requisite === 'other') > -1 && (
                      <div className="activity-detail-general-double">
                        <div className="activity-detail-general-double__title">
                          <TextDestacadoText>Requisito:</TextDestacadoText>
                        </div>
                        <div className="activity-detail-general-double__text">
                          <TextText>
                            {activity.minimumRequisites.requisites.find((r: any) => r.requisite === 'other').data}
                          </TextText>
                        </div>
                      </div>
                    )}
                </ItemSectionDetail>
              </div>


              <div className="activity-detail-general-right__section">
                <ItemSectionDetail title="Puntos de inmersión" icon={images.diveSvg} onEdit={() => this.setState({ renderModal: 'edit-dive-sites' })}>
                  {activity.sites && (
                    activity.sites.map((item: any, index: number) => {
                      return this.renderSites(item, index)
                    }))
                  }
                </ItemSectionDetail>
              </div>
            </div>

            <div className="activity-detail-general-right__section">
              <ItemSectionDetail
                title="Imágenes"
                icon={images.boatsSvg}
                onEdit={() => this.setState({ renderModal: 'edit-gallery' })}
              >
                <div className="activity-detail-general-services">
                  {activity.pictures &&
                    activity.pictures.length > 0 &&
                    activity.pictures.map((picture: any, index: number) => (
                      <div className="activity-detail-general-services__item" key={index}>
                        <div className="activity-detail-general-services__item__text">
                          <img src={picture.toString()} alt='' />
                        </div>
                      </div>
                    ))}
                </div>
              </ItemSectionDetail>
            </div>

          </div>
        </div>
        {
          activity && renderModal === 'create-inmersion' && (
            <ModalRegistreDive
              showModal={renderModal === 'create-inmersion'}
              activity={activity}
              selectedDiveIndex={selectedDiveIndex}
              onClose={() => this.setState({ renderModal: '' })}
              onSubmit={(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) =>
                this.onCreateDive(dive, mainPicture, pictures)
              }
            />
          )
        }
        {
          activity && renderModal === 'edit-times-curse' && (
            <ModalContainer
              title={'Editar datos generales'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-times-curse'}
            >
              <ModalCreateActivitiesStyle>
                <PlanningCourseDate
                  courseActivityInfo={courseInfo.courseActivityInfo}
                  startDate={courseInfo.startDate}
                  centreId={centreId}
                  courseTemplateInfo={courseInfo.courseTemplateInfo}
                  onNext={(state: PlanningCourseDateState, dates: string[], courseActivityInfo: ICourseActivityInfo, sites: IId[]) => {
                    const timeBlocks = [];
                    if (courseActivityInfo.practicalSessions && courseActivityInfo.practicalSessions.length !== 0) {
                      for (const ses of courseActivityInfo.practicalSessions) {
                        if (ses.closedWaterDives) timeBlocks.push(ses.closedWaterDives.timeBlock);
                        if (ses.openWaterDives) timeBlocks.push(ses.openWaterDives.timeBlock);
                      }
                    }
                    if (courseActivityInfo.theoreticSessions && courseActivityInfo.theoreticSessions.length !== 0) {
                      for (const ses of courseActivityInfo.theoreticSessions) {
                        if (ses.onlineTheory) timeBlocks.push(ses.onlineTheory.timeBlock);
                        if (ses.presentialTheory) timeBlocks.push(ses.presentialTheory.timeBlock);
                      }
                    }
                    const setActivity = {
                      courseInfo: courseActivityInfo,
                      date: state.startDate,
                      sites: sites,
                      dates: dates,
                      timeBlocks: timeBlocks
                    }
                    this.onEditActivity(setActivity);
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }

        {activity && renderModal === 'edit-times-inmersion' && (
          <ModalContainer
            title={'Editar horario de actividad'}
            modalClose={() => this.setState({ renderModal: '' })}
            active={renderModal === 'edit-times-inmersion'}>
            <ModalCreateActivitiesStyle>
              <div className="planing-date-title">
                <LabelText>{'Fecha de inicio'}</LabelText>
              </div>
              <CalendarBox
                multiSelect={false}
                initialValue={moment(this.state.curEditTime.date).format('DD/MM/YYYY')}
                className="rounded multiple"
                labelText=""
                forbidPastDates={true}
                placeholder="Fecha de inicio"
                onChange={value => {
                  this.state.curEditTime.date = moment(value, 'DD/MM/YYYY')
                    .hours(12)
                    .toISOString()
                }} />
              <div className="planing-date-horary">
                <div className="planing-date-title">
                  <LabelText>Horario de la actividad</LabelText>
                </div>
                <div className="planing-date-horary-form">
                  <div className="planing-date-horary-form__item">
                    <InputBox
                      className="rounded"
                      required={true}
                      type="text"
                      value={this.state.curEditTime.curInmputs.start}
                      placeholder="Hora de inicio"
                      labelText=""
                      onChange={value => {
                        this.state.curEditTime.curInmputs.start = value;
                      }}
                      withTooltip={false}
                      cleaveOptions={{ time: true, timePattern: ['h', 'm'] }} />
                  </div>
                  <div className="planing-date-horary-form__item">
                    <InputBox
                      className="rounded"
                      required={true}
                      type="text"
                      value={this.state.curEditTime.curInmputs.end}
                      placeholder="Hora de fin"
                      labelText=""
                      onChange={value => {
                        this.state.curEditTime.curInmputs.end = value;
                      }}
                      withTooltip={false}
                      cleaveOptions={{ time: true, timePattern: ['h', 'm'] }} />
                  </div>
                  <div className="planing-date-horary-form__add mt-6">
                    <MainButton
                      text={'Añadir'}
                      iconImg={images.plusSvg}
                      type="secondary full-height"
                      onClick={() => {
                        this.addTimeToInmersion()
                      }} />
                  </div>
                </div>
                <div className="planing-date-horary-form__items">
                  {this.state.curEditTime.timeBlocks.map((timeBlock: ITimeBlocks, index: number) => {
                    return (
                      <div key={index} className="planing-date-horary-form__times">
                        <div className="delate-img">
                          <img src={images.deleteSvg} alt="" onClick={() => {
                            this.delTimeToInmersion(index);
                          }} />
                        </div>
                        {`${getTimeFormatted(timeBlock.start.hour, timeBlock.start.minutes)}
                            - ${getTimeFormatted(timeBlock.end.hour, timeBlock.end.minutes)}`}
                      </div>
                    );
                  })}
                </div>
                <div className="modal-create-activities-buttons-left__next">
                  <MainButton text={'Guardar'} type="primary" onClick={(e: any) => this.saveTimeInmersion(e)} />
                </div>
              </div>
            </ModalCreateActivitiesStyle>
          </ModalContainer>
        )}

        {
          activity && renderModal === 'edit-general-data-formation' && (
            <ModalContainer
              title={'Editar datos generales'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-general-data-formation'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesGeneralDataTraining
                  centreId={centreId}
                  generalData={{
                    name: activity.name,
                    discipline: activity.discipline,
                    minDivers: activity.minDivers,
                    maxDivers: activity.maxDivers || NaN,
                    category: activity.category || NaN,
                    certifier: activity.course.certifier.id || '',
                    course: activity.course.id || '',
                    courseType: activity.course.kind || '',
                  }}
                  onNext={(generalDataCourse: ICourseGeneralData) => {
                    this.onEditActivity(generalDataCourse);
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-general-data' && (
            <ModalContainer
              title={'Editar datos generales'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-general-data'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesGeneralData
                  centreId={centreId}
                  generalData={{
                    name: activity.name,
                    discipline: activity.discipline,
                    kind: activity.kind ? activity.kind : [],
                    category: activity.category || NaN,
                    minDivers: activity.minDivers,
                    maxDivers: activity.maxDivers || NaN,
                    diveCount: activity.diveCount,
                    needBoat: activity.needBoat,
                    collaboration: activity.collaboration,
                  }}
                  onNext={(generalData: IDiveGeneralData) => {
                    this.onEditActivity(generalData);
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-boats' && (
            <ModalContainer
              title={'Editar embarcaciones'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-boats'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesBoats
                  boats={{
                    needBoat: activity.needBoat
                  }}
                  onNext={(boats: IBoatsData) => {
                    this.onEditActivity(boats);
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-minimum-requirements' && (
            <ModalContainer
              title={'Editar requisitos mínimos'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-minimum-requirements'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesMinimunRequirements
                  centreId={parseInt(centreId)}
                  minimumRequisites={{
                    age: activity.minimumRequisites.age.toString(),
                    level: activity.minimumRequisites.level.toString(),
                    depth: activity.minimumRequisites.depth,
                    requisites: activity.minimumRequisites.requisites || [],
                  }}
                  isCourse={!!activity.courseInfo}
                  discipline={activity.discipline}
                  kind={!!activity.kind ? activity.kind[0] : ''}
                  course={activity.course ? activity.course.id : undefined}
                  onNext={(minimumRequisites: IMinimumRequirements) => {
                    const activityEdit = copyOf(minimumRequisites);
                    activityEdit.age = activityEdit.age.toString();
                    activityEdit.level = parseInt(activityEdit.level, undefined);
                    this.onEditActivity({ minimumRequisites: activityEdit }, this.getCourse);
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-transport-meal' && (
            <ModalContainer
              title={'Editar transporte y alojamiento'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-transport-meal'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesOtherServices
                  centreId={centreId}
                  otherServices={{
                    foodAccommodation: activity.meal,
                    departurePoint: activity.transport ? activity.transport.departurePoint : undefined,
                    transport: activity.transport ? activity.transport.transport : undefined,
                  }}
                  onNext={(otherServices: IOtherServices) => {
                    this.onEditActivity({
                      meal: otherServices.foodAccommodation ? otherServices.foodAccommodation : null,
                      transport:
                        otherServices.departurePoint && otherServices.transport
                          ? {
                            departurePoint: otherServices.departurePoint,
                            transport: otherServices.transport,
                          }
                          : null,
                    });
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-observations' && (
            <ModalContainer
              title={'Editar observaciones'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-observations'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesDetails
                  languagesComments={activity.comments}
                  languagesDescriptions={activity.descriptions}
                  onNext={(languagesDescriptions: IMultilang, languagesComments: IMultilang) => {
                    this.onEditActivity({
                      descriptions: languagesDescriptions,
                      comments: languagesComments
                    });
                  }}
                  centreId={centreId}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-services' && (
            <ModalContainer
              title={'Editar servicios'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-services'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesIncludedServices
                  services={activity.services.map((service: any) => ({ id: service.id }))}
                  onNext={(services: IId[]) => {
                    this.onEditActivity({
                      services,
                    });
                  }}
                  centreId={centreId}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-stock' && (
            <ModalContainer
              title={'Editar stock'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-stock'}
            >
              <ModalCreateActivitiesStyle>
                <CreateActivitiesIncludedEquipment
                  stock={activity.stock.map((stockItem: any) => ({ id: stockItem.id }))}
                  onNext={(stock: IId[]) => {
                    this.onEditActivity({
                      stock,
                    });
                  }}
                  centreId={centreId}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-course-blocks' && (
            <ModalContainer
              title={'Editar curso'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-course-blocks'}
            >
              <ModalCreateActivitiesStyle>
                {

                  <PlanningCourseDate
                    courseTemplateInfo={activity.template.courseInfo}
                    courseActivityInfo={activity.courseInfo}
                    startDate={activity.date}
                    centreId={centreId}
                    edit={true}
                    onNext={(
                      state: PlanningCourseDateState,
                      dates: string[],
                      courseActivityInfo: ICourseActivityInfo,
                      sites: IId[],
                    ) => {
                      this.onEditActivity({
                        courseInfo: courseActivityInfo,
                        sites,
                      });
                    }}
                  />
                }
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }

        {
          activity && renderModal === 'edit-dive-sites' && (
            <ModalContainer
              title={'Editar Puntos de inmersión'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-dive-sites'}
            >
              <ModalCreateActivitiesStyle>
                <PlanningDiveSite
                  diveSites={activity.sites && activity.sites.map((site: any) => site.id)}
                  centreId={centreId}
                  edit={true}
                  onNext={(state: any, sites: IId[]) => {
                    this.onEditActivity({
                      sites,
                    });
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-diver' && (
            <ModalContainer
              title={'Editar Buceadores'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-diver'}
            >
              <ModalCreateActivitiesStyle>
                <PlanningDiver
                  selectedDivers={
                    activity.bookings
                      ? activity.bookings.map((bookingActivity: any) => bookingActivity.diverId)
                      : undefined

                  }
                  centreId={centreId}
                  edit={true}
                  activityId={activity.id}
                  services={activity.services}
                  onNext={(state: any, divers: IId[]) => {
                    const diver = divers.map(diver => ({ diver: +diver.id }));
                    this.onEditActivity({
                      diver
                    });
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-gallery' && (
            <ModalContainer
              title={'Editar galería'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-gallery'}>
              <ModalCreateActivitiesStyle>
                <CreateActivitiesGallery
                  pictures={activity.pictures}
                  onNext={(pictures: AttachedFile[], picturesDeleted: string[]) => {
                    this.onEditGallery(pictures, picturesDeleted);
                  }}
                  onPrev={() => { }}
                  onlyDialog={true}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-general-prices' && (
            <ModalContainer
              title={'Precios / Política de cancelación'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-general-prices'}>
              <ModalCreateActivitiesStyle>
                <CreateActivitiesPrices
                  cancellationPolicy={activity.cancellationPolicy.id}
                  price={activity.price}
                  onNext={(price: number, cancellationPolicy: number) => {
                    this.onEditActivity({
                      cancellationPolicy: cancellationPolicy,
                      price: price
                    });
                  }}
                />
              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
        {
          activity && renderModal === 'edit-general-dates' && (
            <ModalContainer
              title={'Editar fechas / horas'}
              modalClose={() => this.setState({ renderModal: '' })}
              active={renderModal === 'edit-general-dates'}>
              <ModalCreateActivitiesStyle>


              </ModalCreateActivitiesStyle>
            </ModalContainer>
          )
        }
      </ActivityDetailGeneralStyle >
    );
  }
}

export default withTranslation(['buttons'])(ActivityDetailGeneral);
