import React, { Component } from 'react';
import { InputCounterContainer } from './input-counter-style';
import TextText from '../text/text-text/text-text';
import { images } from '../../assets/images';

interface InputBoxProps {
  className?: string;
  disabled?: boolean;
  errorCode?: string;
  required?: boolean;
  value: string;
  labelText?: string;
  onChange: (value: string, error?: boolean) => void;
  placeholder?: string;
  message?: string;
}

interface InputBoxState {
  prevInputValue: string;
  focus: boolean;
  showTooltip: boolean;
  error: string;
  inputValue: string;
}

interface InputBoxState {
  prevInputValue: string;
  focus: boolean;
  showTooltip: boolean;
  error: string;
  inputValue: string;
}
class InputCounter extends Component<InputBoxProps, InputBoxState> {
  state: InputBoxState = {
    prevInputValue: '',
    focus: false,
    showTooltip: false,
    error: '',
    inputValue: '',
  };

  componentDidMount = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    const { value } = this.props;
    if (value !== '') {
      this.setState({ inputValue: value });
    }
  };

  componentDidUpdate(prevProps: InputBoxProps) {
    const { value } = this.props;
    const { inputValue, prevInputValue } = this.state;

    if (value !== '' && inputValue === '' && prevInputValue === '') {
      this.setState({ inputValue: value });
    } else if (prevProps.value !== value && value === '') {
      this.setState({ inputValue: '' });
    } else if (value && value !== prevProps.value) {
      this.setState({ inputValue: value });
    }
  }

  handleBlur(value: string) {
    const { onChange } = this.props;
    onChange(value);
  }

  handleChange(value: string) {
    const { onChange } = this.props;
    const { inputValue } = this.state;
    onChange(value);
    this.setState({ inputValue: value, prevInputValue: inputValue });
  }

  render() {
    const { disabled, className, required, labelText, value, errorCode, message, placeholder } = this.props;

    const { inputValue } = this.state;
    const errorText = errorCode ? '' : '';

    return (
      <InputCounterContainer className={`${className} ${disabled ? 'disabled' : ''} ${errorCode ? 'error' : ''}`}>
        {labelText && (
          <div className="counter-field-topbar">
            <p>{labelText}</p>
          </div>
        )}
        <div className="counter-field-main">
          {parseInt(inputValue, undefined) > 0 && (
            <div
              className="counter-button"
              onClick={() => this.handleChange((inputValue ? parseFloat(inputValue) - 1 : 0).toString())}
            >
              <img src={images.minusWhiteSvg} alt="" />
            </div>
          )}
          <div className="counter-input-field">
            <input
              type="number"
              placeholder={placeholder}
              value={inputValue}
              onBlur={e => this.handleBlur(e.target.value)}
              onChange={e => this.handleChange(e.target.value)}
            />
          </div>
          <div
            className="counter-button"
            onClick={() => this.handleChange((inputValue ? parseFloat(inputValue) + 1 : 1).toString())}
          >
            <img src={images.plusWhiteSvg} alt="" />
          </div>
          {errorCode && (
            <div className="input-counter-error">
              <TextText>
                <p>{`* ${errorText}`}</p>
              </TextText>
            </div>
          )}
        </div>
      </InputCounterContainer>
    );
  }
}

export default InputCounter;
