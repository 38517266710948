import React from 'react';
import { ModalQualificationStyle } from './modal-qualification-style';
import ModalContainer from '../../../../../../components/modals/modal-container';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../../main-layout-style';
import { images } from '../../../../../../assets/images';
import InputBox from '../../../../../../components/input-box';
import MainButton from '../../../../../../components/main-button';
import SubheadDestacadoText from '../../../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../../../../../components/text/body-text/body-text';
import AddItemFormSimple from '../../../../../../components/add-item-form-simple/add-item-form-simple';
import AddItemFormCertifier from '../../../../../../components/add-item-form-certifier/add-item-form-certifier';
import SelectBox from '../../../../../../components/select-box';

interface ModalQualificationProps {
  showModal: boolean;
  onClose: () => void;
}

interface ModalQualificationState {}

export default class ModalQualification extends React.Component<ModalQualificationProps, ModalQualificationState> {
  render() {
    const { onClose, showModal } = this.props;
    return (
      <ModalContainer className="" modalClose={() => onClose()} active={showModal}>
        <ModalQualificationStyle>
          <div className="create-user-title">
            <SubheadDestacadoText>Titulación</SubheadDestacadoText>
          </div>
          <div className="create-user-text">
            <BodyText>
              Añade las titulaciones que tenga el usuario para que quede reflejado en el perfil del buceador y pueda
              demostrar su experiencia.
            </BodyText>
          </div>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      className="rounded"
                      labelText="Seleccione una certificadora"
                      placeholder="Nivel"
                      required={true}
                      initialValue={''}
                      optionsText={[]}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={() => {}}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      className="rounded"
                      labelText="Seleccione una especialización"
                      placeholder="Certificadora"
                      required={true}
                      initialValue={''}
                      optionsText={[]}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={() => {}}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-1">
                    <InputBox
                      // errorCode="Error"
                      placeholder="ID"
                      className="rounded"
                      type="email"
                      value=""
                      labelText="Usuario"
                      onChange={() => {}}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-3">
                    <MainButton
                      type="secondary full-height"
                      iconImg={images.plusSvg}
                      text="Añadir"
                      onClick={() => {}}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>

              <AddItemFormCertifier
                title="Nombre de la especialización"
                certifier="Certificadora Lorem ipsum"
                id="ID 29894910"
                onDelete={() => {}}
              />
            </div>
          </div>

          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton type="primary" onClick={() => {}} text="Guardar Cambios" />
            </div>
          </div>
        </ModalQualificationStyle>
      </ModalContainer>
    );
  }
}
