import React, { Component, Children } from 'react';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import { images } from '../../assets/images';
import { MainButtonDropContainerProfile } from './main-button-drop-style';

interface MainButtonProps {
  text: string;
  type?: string;
  iconImg?: any;
  disabled?: boolean;
  active: boolean;
  iconRight?: boolean;
  iconImgRight?: any;
  children?: any;
  propOpen: boolean;
  onClick: Function;
}
interface MainButtonState {
  open: boolean;
}

class MainButtonDropProfile extends Component<MainButtonProps, MainButtonState> {
  state = {
    open: this.props.propOpen,
  };

  componentDidUpdate(prevProps: MainButtonProps, prevState: MainButtonState) {
    if (prevProps.propOpen !== this.props.propOpen) {
      // Perform some operation here
      this.setState({ open: this.props.propOpen });
      // this.classMethod();
    }
  }

  render() {
    const { text, type, iconImg, disabled, children, onClick, active, propOpen } = this.props;
    const { open } = this.state;
    return (
      <MainButtonDropContainerProfile
        disabled={disabled}
        className={`${disabled ? 'disabled' : type} ${propOpen && 'open'} ${active && 'active'}`}
      >
        <div className="main-button-drop-top-profile" onClick={() => onClick()}>
          <div className="main-button-icon left">
            <img src={iconImg} />
          </div>
          <div className="main-button-text">
            <BodyDestacadoText>{text}</BodyDestacadoText>
          </div>

          <div className="main-button-icon right">
            <img src={images.arrowDown2WhiteSvg} />
          </div>
        </div>
        <div className={`main-button-drop-content ${propOpen && 'open'}`}>{children}</div>
      </MainButtonDropContainerProfile>
    );
  }
}

export default MainButtonDropProfile;
