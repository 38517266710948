import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const DiveSiteRateStyle = styled.div`
  .dive-site-rate-hero {
    display: flex;
    margin-bottom: 80px;
  }
  .dive-site-rate-hero-total {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${colors['NE-002/10']};
    width: 450px;
    margin-right: 60px;
    &__number {
      margin-bottom: 20px;
    }
    &__stars  {
      margin-bottom: 10px;
    }
  }
  .dive-site-rate-hero-detail {
    border: 3px solid ${colors['NE-002/10']};
    padding: 47px 60px 32px;
    border-radius: 5px;
    flex: auto;
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
    }
  }

  .dive-site-rate-reviews {
    margin-bottom: 80px;
    &__item {
      margin-bottom: 48px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .dive-site-rate-pagination {
    margin-bottom: 80px;
  }
`;
