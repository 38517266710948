import * as React from 'react';
import { MainLayoutSection } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import { TaxesStyle } from './taxes-style';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import BodyText from '../../../components/text/body-text/body-text';
import ModalTaxes from './modal-taxes/modal-taxes';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { getIvaTypes } from '../../../services/iva-types';
import { COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import services from '../../../services/services';
import ModalInfo from '../../../components/modals/modal-info/modal-info';
import { images } from '../../../assets/images';

export interface ITaxesProps extends RouteComponentProps, WithTranslation {}
export interface ITaxesState {
  renderModal: string;
  taxes: any;
  accept: Function;
}

class Taxes extends React.Component<ITaxesProps, ITaxesState> {
  constructor(props: ITaxesProps) {
    super(props);

    this.state = {
      renderModal: '',
      taxes: [],
      accept: () => {},
    };
  }

  componentDidMount() {
    this.getIvaTypes();
  }

  getIvaTypes = async () => {
    try {
      const params = {};
      const res: any = await getIvaTypes(params);
      this.setState({ taxes: res.data.data });
    } catch (err) {}
  };

  render() {
    const { renderModal, taxes, accept } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderMain
          title={t('layouts:config.taxes.header')}
          mainButton={{ text: t('buttons:manage'), onClick: () => this.setState({ renderModal: 'modal-taxes' }) }}
        />
        <MainLayoutSection>
          <TaxesStyle>
            <div className="taxes-text">
              <Trans i18nKey={'layouts:config.taxes.text'}>
                <SubtitleText>{'-'}</SubtitleText>
                <SubtitleText>{'-'}</SubtitleText>
              </Trans>
            </div>
            <div className="taxes-table">
              <table>
                <thead>
                  <tr>
                    <th>
                      <BodyDestacadoText>{t('layouts:config.taxes.country')}</BodyDestacadoText>
                    </th>
                    <td>
                      <BodyDestacadoText>{t('layouts:config.taxes.tax')}</BodyDestacadoText>
                    </td>
                    <td />
                    <td />
                  </tr>
                </thead>
                <tbody>
                  {taxes.map((item: any) => (
                    <tr key={`taxes-${item.id}`}>
                      <td>
                        <BodyDestacadoText>{COUNTRIES_KEY_LABEL[item.country]}</BodyDestacadoText>
                      </td>
                      {item.types.map((type: any, index: number) => (
                        <td key={`taxes-${item.id}-${index}`}>
                          <BodyText>{type}%</BodyText>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </TaxesStyle>
        </MainLayoutSection>
        {(renderModal === 'modal-taxes' || renderModal === 'modal-info') && (
          <ModalTaxes
            showModal={renderModal === 'modal-taxes'}
            onCancel={() => this.setState({ renderModal: '' })}
            onAccept={(accept: Function) => this.setState({ accept, renderModal: 'modal-info' })}
            taxes={taxes}
          />
        )}
        {renderModal === 'modal-info' && (
          <ModalInfo
            type="blue"
            icon={images.checkAllGreenSvg}
            title={['Confirmar edición']}
            onClose={() => this.setState({ renderModal: 'modal-manage-dive-levels' })}
            text={[
              `Al guardar los cambios realizados sobre Tipo de IVA,
              se aplicarán en toda la plataforma. Si elimina algún IVA
              añadido posteriormente, los vinculados se relacionarán con
              uno predefinido equivalente.`,
            ]}
            showModal={renderModal === 'modal-info'}
            buttons={[
              {
                text: 'Aceptar',
                type: 'primary',
                onClick: async () => {
                  try {
                    await accept();
                    this.setState({ renderModal: '' }, () => {
                      this.getIvaTypes();
                      services.showModalSuccess('Genial!', 'Se ha guardado correctamente');
                    });
                  } catch (err) {
                    this.setState({ renderModal: 'modal-manage-dive-levels' });
                  }
                },
              },
              {
                text: 'Cancelar',
                type: 'secondary-red',
                onClick: () => this.setState({ renderModal: 'modal-manage-dive-levels' }),
              },
            ]}
          />
        )}
      </>
    );
  }
}
export default withTranslation(['intro', 'buttons'])(withRouter(Taxes));
