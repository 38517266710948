import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const InputStyle = styled.div`
  /* TOP */
  .input-top {
    display: flex;
    align-items: center;
    //margin-bottom: 8px;
  }
  .input-top-label {
    flex: auto;
    /*  */
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 6px;
    text-transform: uppercase;
    color: ${colors['NE-002/100']};

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:before {
      content: ' ';
      width: 4px;
      height: 4px;
      display: flex;
      background: #00a1a5;
      border-radius: 100%;
      margin-right: 6px;
    }
  }
  .input-top-tooltip {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: 8px;
  }

  /* BODY */
  .input-body {
    position: relative;
    display: flex;
    min-height: 60px;
    height: auto;
    /*margin-bottom: 8px;*/
    font-family: Plain;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid ${colors['NE-002/100']};
    padding: 16px;
    border-radius: 5px;
  }
  .input-body-wrapper {
    width: 100%;
    position: relative;
    display: flex;
  }
  .input-body-container {
    display: flex;
  }

  input {
    flex: auto;
    /*  */
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;

    ::placeholder {
      color: #bcc5d7;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
    }
  }
  textarea {
    background: none;
    border: none;
    resize: none;
    width: 100%;
    min-height: 140px;
    /*  */
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    &:focus  {
      outline: none;
    }
  }

  .input-body-icon {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  /* BOTTOM */
  .input-bottom {
    /* visibility: hidden;
    opacity: 0; */
    /*  */
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
  }
  .input-bottom-error {
    color: #ff0000;
  }

  /* FILTER */
  &.filter {
    .input-body {
      background: #ffffff;
      padding: 8px 16px;
      max-height: 40px;
      border: none;
    }
  }

  /* FILTER */
  &.minimal-grey {
    .input-body {
      background: #f8fafd;
      padding: 12px 16px;
      max-height: 40px;
      border: none;
    }
    input {
      ::placeholder {
        color: #000019;
        padding: 0 12px;
      }
    }
    .input-body-icon {
      width: 16px;
      height: 16px;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const SelectStyle = styled.div`
  width: 100%;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  padding: 8px;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  /*  */
  max-height: 200px;
  overflow-y: scroll;
  /*  */
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 25, 0.12);
  border-radius: 5px;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .select-item {
    padding: 4px 0;
    transition: 0.3s ease-in-out;
    padding-left: 18px;
    color: #405364;
    /*  */
    margin-bottom: 4px;
    background: white;
    &:hover {
      background: #eee;
      cursor: pointer;
    }
  }
`;

export const SelectContainerStyle = styled.div`
  .selected-container {
    padding: 10px 0;

    .selected-item {
      margin-right: 16px;
    }
  }
`;

export const InputCalendarStyle = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  opacity: 0;
  visibility: hidden;

  /*  */

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(202, 209, 216, 0.4);
  border-radius: 8px;

  &.open {
    opacity: 1;
    visibility: visible;
  }
`;
