import * as React from 'react';
import ModalContainer from '../../../components/modals/modal-container';
import { ModalCreateAdsMembershipStyle } from './modal-create-ads-membership-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../containers/main-layout-style';
import InputBox from '../../input-box';
import { images } from '../../../assets/images';
import SelectBox from '../../select-box';
import MainButton from '../../main-button';
import InputArea from '../../input-area/input-area';
import InputUploadFiles from '../../input-upload-file/input-upload-file';
import { IAdsForm } from '../../../models/ads-forms';
import { getOneAds, createAds, uploadAdsFormData, uploadAds } from '../../../services/ads';
import { isValidURL } from '../../../utils/validation';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

export interface IModalCreateAdProps extends RouteComponentProps, WithTranslation {
  onCancel: Function;
  showModal: boolean;
  onAccept: Function;
  editMode: boolean;
  adsId?: string;
  t: any
}
export interface IModalCreateAdState {
  ads: IAdsForm;
  adsObject: any;
  languages: any[];
  selectedLanguage: string;
}

class ModalCreateAd extends React.Component<IModalCreateAdProps, IModalCreateAdState> {
  readonly state: IModalCreateAdState;
  constructor(props: IModalCreateAdProps) {
    super(props);
    this.state = {
      ads: {
        name: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        company: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        picture: {
          value: '',
          error: false,
          errorCode: '',
          required: false,
        },
        description: {
          es: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          pt: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          en: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          fr: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          it: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          de: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
        },
        website: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        type: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        fee: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        visibleBy: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
      },
      adsObject: {},
      languages: [
        { value: 'es', label: 'ES' },
        { value: 'pt', label: 'PT' },
        { value: 'en', label: 'EN' },
        { value: 'fr', label: 'FR' },
        { value: 'it', label: 'IT' },
        { value: 'de', label: 'DE' },
      ],
      selectedLanguage: 'es',
    };
  }

  componentDidMount() {
    const { editMode } = this.props;

    if (editMode) {
      this.getOneAds();
    }
  }

  getOneAds = async () => {
    const { adsId } = this.props;
    const { ads } = this.state;
    if (adsId) {
      try {
        const response: any = await getOneAds(adsId);
        Object.keys(ads).forEach((k: string) => {
          if (k === 'description') {
            Object.keys(ads[k]).forEach((kd: string) => {
              ads[k][kd].value = response.data[k][kd];
            });
          } else {
            ads[k].value = response.data[k];
          }
        });
        this.setState({ ads, adsObject: response.data });
      } catch (err) {
        console.log(err);
      }
    }
  };

  createAds = async (ads: any, picture: any) => {
    const { onAccept } = this.props;
    try {
      const formData = new FormData();
      const response: any = await createAds({ ...ads, active: false });
      if (picture.value) {
        formData.set('picture', picture.data);
        await uploadAdsFormData(response.data.id, formData);
      }
      onAccept();
    } catch (err) {
      console.log(err);
    }
  };

  editAds = async (data: any, picture: any) => {
    const { onAccept, adsId } = this.props;
    try {
      const formData = new FormData();
      const response: any = await uploadAds(adsId || '', data);
      if (picture.value) {
        formData.set('picture', picture.data);
        await uploadAdsFormData(response.data.id, formData);
      }
      onAccept();
    } catch (err) {
      console.log(err);
    }
  };

  changeValue = (value: string, key: string, subKey?: string) => {
    const { ads } = this.state;
    if (!subKey) {
      ads[key].value = value;
    } else {
      ads[key][subKey].value = value;
    }

    this.setState({ ads });
  };

  handleChangePicture = (value: any, key: string) => {
    const { ads } = this.state;
    const newAds = { ...ads };
    if (value.length > 0) {
      newAds[key] = value[0];
      newAds[key].value = value[0].name;
    } else {
      newAds[key] = {
        value: '',
        error: false,
        errorCode: '',
        required: false,
      };
    }
    this.setState({ ads: newAds });
  };

  handleAccept = () => {
    const { ads } = this.state;
    const { editMode } = this.props;
    let isOk = true;
    Object.keys(ads).forEach((k: string) => {
      if (ads[k].required && ads[k].value === '') {
        ads[k].error = true;
        ads[k].errorCode = 'required';
        isOk = false;
      } else if (k === 'website' && !isValidURL(ads[k].value)) {
        ads[k].error = true;
        ads[k].errorCode = 'invalid-format';
        isOk = false;
      } else if ((k === 'fee' && parseFloat(ads[k].value) < 0) || parseFloat(ads[k].value) > 100) {
        ads[k].error = true;
        ads[k].errorCode = 'invalid-percent';
        isOk = false;
      } else if (k === 'description') {
        Object.keys(ads[k]).forEach((kd: string) => {
          if (ads[k][kd].required && ads[k][kd].value === '') {
            ads[k][kd].error = true;
            ads[k][kd].errorCode = 'required';
            isOk = false;
          } else {
            ads[k][kd].error = false;
            ads[k][kd].errorCode = '';
          }
        });
      } else {
        ads[k].error = false;
        ads[k].errorCode = '';
      }
    });
    this.setState({ ads });
    if (isOk) {
      const description: any = {};
      Object.keys(ads.description).forEach((k: string) => {
        description[k] = ads.description[k].value;
      });
      const data: any = {};
      Object.keys(ads).forEach((k: string) => {
        if (k === 'description') {
          data[k] = description;
        } else {
          data[k] = ads[k].value;
        }
      });
      if (editMode) {
        this.editAds(data, ads.picture);
      } else {
        this.createAds(data, ads.picture);
      }
    }
  };

  render() {
    const { onCancel, showModal, editMode, t } = this.props;
    const { ads, languages, selectedLanguage } = this.state;
    return (
      <ModalContainer
        title={`${editMode ? t('components:actions.edit') : t('components:actions.create')} ${t('modalMessages:modal-create-user.affiliation_ad')}`}
        className=""
        modalClose={() => onCancel()}
        active={showModal}
      >
        <ModalCreateAdsMembershipStyle>
          <div className="modal-create-ad-section">
            <div className="modal-create-ad-section__form">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText={t('modalMessages:modal-create-user.ad-type')}
                      icon={images.arrowDown2BlueSvg}
                      placeholder={t('modalMessages:modal-create-user.ad-type')}
                      className="rounded-title"
                      optionsText={[{ label: 'type1' }, { label: 'type2' }, { label: 'type3' }]}
                      optionKey={'label'}
                      optionValue={'label'}
                      required={true}
                      errorCode={ads.type.errorCode}
                      initialValue={ads.type.value}
                      onChange={(value: string) => this.changeValue(value, 'type')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      labelText={t('modalMessages:modal-create-user.bonus-name')}
                      placeholder={t('modalMessages:modal-create-user.bonus-name')}
                      className="rounded-title"
                      type="text"
                      required={true}
                      errorCode={ads.name.errorCode}
                      value={ads.name.value}
                      onChange={(value: string) => this.changeValue(value, 'name')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <div className={'modal-create-add-languages'}>
                  {languages.map((k: any, index: number) => (
                    <div className={'modal-create-add-languages__item'} key={index}>
                      <MainButton
                        onClick={() => this.setState({ selectedLanguage: k.value })}
                        text={k.label}
                        type={
                          selectedLanguage === k.value
                            ? 'primary'
                            : !ads.description[k.value].error
                            ? 'secondary'
                            : 'secondary-red'
                        }
                      />
                    </div>
                  ))}
                </div>

                <FormGridRow key={`description-${selectedLanguage}`}>
                  <FormGridItem type="col-0">
                    <InputArea
                      name={selectedLanguage}
                      placeholder={`Descripción ${selectedLanguage}`}
                      required={ads.description[selectedLanguage].required}
                      errorCode={ads.description[selectedLanguage].errorCode}
                      error={ads.description[selectedLanguage].error}
                      value={ads.description[selectedLanguage].value}
                      onChange={(e: any) => this.changeValue(e.target.value, 'description', selectedLanguage)}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputUploadFiles
                      multiple={false}
                      required={true}
                      label="Logo"
                      onChange={ev => this.handleChangePicture(ev, 'picture')}
                      value={ads.picture.value}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('modalMessages:modal-create-user.company-name')}
                      className="rounded-title"
                      type="text"
                      required={true}
                      labelText={t('modalMessages:modal-create-user.company-name')}
                      errorCode={ads.company.errorCode}
                      value={ads.company.value}
                      onChange={(value: string) => this.changeValue(value, 'company')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('modalMessages:modal-create-user.web-direction')}
                      className="rounded-title"
                      type="text"
                      labelText={t('modalMessages:modal-create-user.web-direction')}
                      required={true}
                      errorCode={ads.website.errorCode}
                      value={ads.website.value}
                      onChange={(value: string) => this.changeValue(value, 'website')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder={t('modalMessages:modal-create-user.sell-commision')}
                      className="rounded-title"
                      labelText={t('modalMessages:modal-create-user.sell-commision')}
                      type="number"
                      max={100}
                      min={0}
                      required={true}
                      errorCode={ads.fee.errorCode}
                      value={ads.fee.value}
                      onChange={(value: string) => this.changeValue(value, 'fee')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText={t('modalMessages:modal-create-user.visible-by')}
                      placeholder={t('modalMessages:modal-create-user.visible-by')}
                      className="rounded-title"
                      optionsText={[{ label: 'type1' }, { label: 'type2' }, { label: 'type3' }]}
                      optionKey={'label'}
                      optionValue={'label'}
                      required={true}
                      errorCode={ads.visibleBy.errorCode}
                      initialValue={ads.visibleBy.value}
                      onChange={(value: string) => this.changeValue(value, 'visibleBy')}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>

          <div className="modal-create-ad__button">
            <div className="modal-create-ad__button__item">
              <MainButton type="primary" text={t('components:actions.add')} onClick={() => this.handleAccept()} />
            </div>
            <div className="modal-create-ad__button__item">
              <MainButton type="secondary" text={t('components:actions.cancel')} onClick={() => onCancel()} />
            </div>
          </div>
        </ModalCreateAdsMembershipStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalCreateAd));
