import services from './services';

export const getCourses = async (params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'courses',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCoursesByOrg = async (params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'organizations/:id/courses',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};





export const getCourse = (courseId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: `courses/${courseId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getCourseCentre = (centreId: number, kind?: string, discipline?: string, level?: string) => {
  const params: any = {};
  if(kind && kind!== ''){
    params.kind = kind;
  }
  if(discipline && discipline!== ''){
    params.discipline = discipline;
  }
  
  if(level && level!== ''){
    params.level = level;
  }

  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint:`centres/${centreId}/courses`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args)
  });
};


export const getCourseDivers = (organizationId: string, courseId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: `organizations/${organizationId}/courses/${courseId}/divers`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};
