import styled from 'styled-components';

export const DestinationStyle = styled.div`
  .destination-text {
    margin-bottom: 78px;
    p {
      margin-bottom: 20px;
      &:last-child  {
        margin-bottom: 0px;
      }
    }
  }

  .destination-continent {
    margin-bottom: 80px;
    &__title {
      margin-bottom: 28px;
    }
    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 20px;
      &__item {
      }
    }
  }
`;
