import styled from 'styled-components';

export const DiveSiteGalleryStyle = styled.div`
  .dive-site-gallery-section {
    margin-bottom: 80px;
    &__title  {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      &__buttons  {
        display: flex;
        &__item {
          margin-left: 12px;
        }
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 20px;
    }
  }
`;
