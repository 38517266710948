import styled from 'styled-components';
import { colors } from '../../assets/colors';
export const ItemCertificationContainer = styled.div`
  display: flex;

  .item-certification-img {
    width: 84px;
    height: 61px;
    background: #c4c4c4;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 20px;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .item-certification-info {
    &__title {
      color: ${colors['NE-001/100']};
      margin-bottom: 4px;
    }
    &__certification {
      color: ${colors['NE-001/50']};
      margin-bottom: 2px;
    }
    &__id {
      color: ${colors['NE-001/50']};
    }
    .item-certification-info-validation {
      margin-top: 4px;
      display: inline-block;
      background: ${colors['CO-004/15']};
      padding: 5px 8px;
      color: ${colors['CO-004/100']};
    }
  }
`;
