import * as React from 'react';
import { ConfigurationFeeStyle } from './configuration-fee-style';
import { MainLayoutSection } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import InputBox from '../../../components/input-box';
import MainButton from '../../../components/main-button';
import { getConfiguration, postConfiguration } from '../../../services/configuration';
import ToastNotification from '../../../components/toast-notification/toast-notification';
import services from '../../../services/services';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { isNumeric } from '../../../utils/validation';

export interface IConfigurationFeeProps extends WithTranslation {}

export interface IConfigurationFeeState {
  fee?: number;
  discount?: number;
  discountText?: string;
  error: boolean;
}

class ConfigurationFee extends React.Component<IConfigurationFeeProps, IConfigurationFeeState> {
  constructor(props: IConfigurationFeeProps) {
    super(props);
    this.state = {
      error: false,
    };
  }

  componentDidMount() {
    this.getConfiguration();
  }

  async getConfiguration() {
    try {
      const resp: any = await getConfiguration();
      console.log("-----",resp);
      const {fee, discount, discountText } = resp.data;
      if (fee) this.setState({ fee });
      if (discount) this.setState({ discount });
      if (discountText) this.setState({ discountText });
    } catch (err) {
      console.log(err);
    }
  }

  async postConfiguration() {
    const { fee, discount, discountText } = this.state;
    const { t } = this.props;

    let valueDiscount = !discount ? 0 : discount;
    let valueDiscountText = !discountText ? '' : discountText;
    if (!fee) return;
    try {
      await postConfiguration({
        fee: +fee,
        discount: valueDiscount,
        discountText: valueDiscountText

      });
      services.pushNotification({
        title: t('push:save-fee.title'),
        text: t('push:save-fee.text'),
        type: 'green',
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { fee, discount, discountText, error } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderMain title={t('layouts:config.fee.header')} />
        <MainLayoutSection>
          <ConfigurationFeeStyle>
            <div className="configuration-fee-text">
              <Trans i18nKey={'layouts:config.fee.text'}>
                <SubtitleText>{'-'}</SubtitleText>
                <SubtitleText>{'-'}</SubtitleText>
              </Trans>
            </div>

            <div className="configuration-fee-input">
              <InputBox
                placeholder={t('inputs:fee-percent.placeholder')}
                className="rounded-title"
                required={true}
                errorCode={error ? 'invalid-format' : undefined}
                type="number"
                value={fee ? fee.toString() : ''}
                labelText={t('inputs:fee-percent.label')}
                max={100}
                min={0}
                onChange={(val: string) => {
                  this.setState({ fee: parseInt(val, undefined), error: !isNumeric(val) });
                }}
              />
            </div>
            <div className="configuration-fee-input">

            <InputBox
                      placeholder={t('inputs:fee-discount-text.label')}
                      className="rounded-title"
                      type="text"
                      value={ discountText ? discountText : ''} 
                      labelText={t('inputs:fee-discount-text.label')}
                      onChange={(val: string) => {
                        this.setState({ discountText: val });
                      }}
                    />

              <InputBox
                placeholder={t('inputs:fee-discount.placeholder')}
                className="rounded-title"
                required={true}
                errorCode={error ? 'invalid-format' : undefined}
                type="number"
                value={discount ? discount.toString() : ''}
                labelText={t('inputs:fee-discount.label')}
                max={100}
                min={0}
                onChange={(val: string) => {
                  this.setState({ discount: parseInt(val, undefined), error: !isNumeric(val) });
                }}
              />
            </div>

            <div className="config-contact-buttons">
              <div className="config-contact-buttons__item">
                <MainButton
                  disabled={error}
                  type="primary"
                  text={t('buttons:save')}
                  onClick={() => this.postConfiguration()}
                />
              </div>
              {/* <div className="config-contact-buttons__item">
                <MainButton type="secondary" text={t('buttons:cancel')} onClick={() => this.getConfiguration()} />
              </div> */}
            </div>
          </ConfigurationFeeStyle>
        </MainLayoutSection>
      </>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(ConfigurationFee);
