import React from 'react';
import { ModalCreateMarineReserveContainer } from './modal-create-marine-reserve-style';
import BodyDestacadoText from '../../text/body-destacado-text/body-destacado-text';
import { FormGrid, FormGridRow, FormGridItem, FormSpacer } from '../../../containers/main-layout-style';
import InputBox from '../../input-box';
import MainButton from '../../main-button';
import PhoneBox from '../../phone-box/phone-box';
import SelectBox from '../../select-box';
import { images } from '../../../assets/images';
import BodyText from '../../text/body-text/body-text';
import ListItemAdded from '../../list-item-added/list-item-added';
import ModalContainer from '../modal-container';
import InputArea from '../../input-area/input-area';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import { createMarineReserve, uploadMarineReserve, getOneMarineReserve } from '../../../services/marine-reserve';
import { COUNTRIES_KEY_LABEL, COUNTRIES } from '../../../constants/countries';
import { getUserUnit, transformToSI, transformToUser } from '../../../utils/measurement-units';
import { getDataFromAddress } from '../../../services/google-maps';
import { getAdministrativeLevel1, getCountry } from '../../../utils/maps';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

interface ModalCreateMarineReserveProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  onClose: Function;
  reserveMarineId: string;
  t: any
}

interface ModalCreateMarineReserveState {
  marineReserve: any;
  languages: any[];
  selectedLanguage: string;
  states: any[];
}

class ModalCreateMarineReserve extends React.Component<
  ModalCreateMarineReserveProps,
  ModalCreateMarineReserveState
  > {
  constructor(props: ModalCreateMarineReserveProps) {
    super(props);
    this.state = {
      states: [],
      marineReserve: {
        name: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        country: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        state: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
        },
        latitude: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
          type: 'number',
        },
        longitude: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
          type: 'number',
        },
        description: {
          es: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          pt: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          en: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          fr: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          it: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
          de: {
            value: '',
            error: false,
            errorCode: '',
            required: true,
          },
        },
        shape: {
          value: 'circle',
          error: false,
          errorCode: '',
          required: false,
        },
        radius: {
          value: '',
          error: false,
          errorCode: '',
          required: true,
          type: 'number',
        },
      },
      languages: [
        { value: 'es', label: 'ES' },
        { value: 'pt', label: 'PT' },
        { value: 'en', label: 'EN' },
        { value: 'fr', label: 'FR' },
        { value: 'it', label: 'IT' },
        { value: 'de', label: 'DE' },
      ],
      selectedLanguage: 'es',
    };
  }

  componentDidMount() {
    const { reserveMarineId } = this.props;
    if (reserveMarineId) {
      this.getReserveMarine(reserveMarineId);
    }
  }

  getReserveMarine = async (id: string) => {
    const { marineReserve } = this.state;
    try {
      const response: any = await getOneMarineReserve(id);
      Object.keys(marineReserve).forEach(k => {
        if (k === 'description') {
          Object.keys(marineReserve[k]).forEach(kd => {
            marineReserve[k][kd].value = response.data[k][kd];
          });
        } else {
          marineReserve[k].value = response.data[k];
        }
      });
      marineReserve.radius.value = transformToUser('distance', marineReserve.radius.value);
      this.setState({ marineReserve });
    } catch (err) { }
  };

  changeValue = (value: string, key: string, subKey?: string) => {
    const { marineReserve } = this.state;
    if (!subKey) {
      marineReserve[key].value = value;
    } else {
      marineReserve[key][subKey].value = value;
    }

    this.setState({ marineReserve });
  };

  validateForm = () => {
    const { marineReserve } = this.state;

    let isOk = true;
    Object.keys(marineReserve).forEach((k: string) => {
      if (marineReserve[k].required && marineReserve[k].value === '') {
        marineReserve[k].error = true;
        marineReserve[k].errorCode = 'required';
        isOk = false;
      } else if (k === 'description') {
        // Object.keys(marineReserve[k]).forEach((kd: string) => {
        //   if (marineReserve[k][kd].required && marineReserve[k][kd].value === '') {
        //     marineReserve[k][kd].error = true;
        //     marineReserve[k][kd].errorCode = 'required';
        //     isOk = false;
        //   } else {
        //     marineReserve[k][kd].error = false;
        //     marineReserve[k][kd].errorCode = '';
        //   }
        // });
      } else {
        marineReserve[k].error = false;
        marineReserve[k].errorCode = '';
      }
    });

    this.setState({ marineReserve });
    if (isOk) {
      this.handleSave();
    }
  };

  handleSave = () => {
    const { marineReserve } = this.state;
    const { reserveMarineId } = this.props;
    const description: any = {};
    Object.keys(marineReserve.description).forEach((k: string) => {
      description[k] = marineReserve.description[k].value;
    });
    const data: any = {};
    Object.keys(marineReserve).forEach((k: string) => {
      if (marineReserve[k].type === 'number') {
        data[k] = parseFloat(marineReserve[k].value);
      } else if (k === 'description') {
        data[k] = description;
      } else {
        data[k] = marineReserve[k].value;
      }
    });
    data.radius = Math.round(transformToSI('distance', data.radius));
    if (!!reserveMarineId) {
      this.editReserveMarine(data);
    } else {
      this.createReserveMarine(data);
    }
  };

  editReserveMarine = async (data: any) => {
    const { reserveMarineId, onClose } = this.props;
    try {
      const response: any = await uploadMarineReserve(reserveMarineId, data);
      onClose(true);
    } catch (err) { }
  };

  createReserveMarine = async (data: any) => {
    const { onClose } = this.props;
    try {
      const response: any = await createMarineReserve(data);
      onClose(true);
    } catch (err) { }
  };

  searchTimeout: any;
  handleSearchState(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (value && value !== '') this.searchAddress(value);
    }, 300);
  }

  async searchAddress(value: string) {
    const { marineReserve } = this.state;
    const resp: any = await getDataFromAddress(value, marineReserve.country.value);
    const results = resp.data.results;
    let states: any[] = results.map((result: any) => {
      const level1 = getAdministrativeLevel1(result);
      const country = getCountry(result);
      return {
        level1: level1.longName,
        country: country.shortName,
      };
    });
    states = Array.from(new Set(states)).map(state => ({
      country: state.country,
      value: state.level1,
      label: state.level1,
    }));
    this.setState({ states });
  }

  render() {
    const { showModal, onCancel, reserveMarineId, t } = this.props;
    const { languages, selectedLanguage, marineReserve, states } = this.state;

    return (
      <ModalContainer title={``} active={showModal} modalClose={() => onCancel()}>
        <ModalCreateMarineReserveContainer>
          <div className="modal-create-marine-reserve-title">
            <SubheadDestacadoText>{reserveMarineId ? t('components:general.edit') : t('components:general.create')} {t('components:general.marine-reserve')}</SubheadDestacadoText>
          </div>
          <div className="modal-create-marine-reserve-form">
            <div className="modal-create-marine-reserve-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      labelText={t('components:general.name')}
                      placeholder={t('components:general.name')}
                      type="text"
                      className="rounded"
                      required={marineReserve.name.required}
                      errorCode={marineReserve.name.errorCode}
                      value={marineReserve.name.value}
                      onChange={(value: string) => this.changeValue(value, 'name')}
                    />
                  </FormGridItem>
                </FormGridRow>

                <div className={'modal-create-marine-reserve-languages'}>
                  {languages.map((k: any, index: number) => (
                    <div className={'modal-create-add-languages__item'} key={index}>
                      <MainButton
                        onClick={() => this.setState({ selectedLanguage: k.value })}
                        text={k.label}
                        type={
                          selectedLanguage === k.value
                            ? 'primary'
                            : !marineReserve.description[k.value].error
                              ? 'secondary'
                              : 'secondary-red'
                        }
                      />
                    </div>
                  ))}
                </div>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputArea
                      name={selectedLanguage}
                      placeholder={`${t('components:general.description')} ${selectedLanguage}`}
                      required={marineReserve.description[selectedLanguage].required}
                      errorCode={marineReserve.description[selectedLanguage].errorCode}
                      error={marineReserve.description[selectedLanguage].error}
                      value={marineReserve.description[selectedLanguage].value}
                      onChange={(e: any) => this.changeValue(e.target.value, 'description', selectedLanguage)}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormSpacer />

                {/* //TODO: ADD MORE SHAPE OPTIONS
                <FormGridRow> 
                  <FormGridItem type="col-0">
                    <SelectBox
                      className="rounded"
                      labelText="Forma"
                      placeholder="Seleccione la forma"
                      required={true}
                      optionsText={[{ label: 'Circular', value: 'circle' }]}
                      optionKey={'value'}
                      optionValue={'label'}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => this.changeValue(value, 'shape')}
                      initialValue={marineReserve.shape.value}
                      errorCode={marineReserve.shape.errorCode}
                    />
                  </FormGridItem>
                </FormGridRow> */}

                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      className="rounded"
                      placeholder={t('components:general.latitude')}
                      labelText={t('components:general.latitude')}
                      type={marineReserve.latitude.type}
                      required={marineReserve.latitude.required}
                      errorCode={marineReserve.latitude.errorCode}
                      value={marineReserve.latitude.value}
                      onChange={(value: string) => this.changeValue(value, 'latitude')}
                    />
                  </FormGridItem>
                  <FormGridItem type="col-2">
                    <InputBox
                      className="rounded"
                      placeholder={t('components:general.longitude')}
                      labelText={t('components:general.longitude')}
                      type={marineReserve.longitude.type}
                      required={marineReserve.longitude.required}
                      errorCode={marineReserve.longitude.errorCode}
                      value={marineReserve.longitude.value}
                      onChange={(value: string) => this.changeValue(value, 'longitude')}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      className="rounded"
                      placeholder={`Radio (${getUserUnit('distance')})`}
                      labelText="Radio"
                      type={'number'}
                      required={marineReserve.radius.required}
                      errorCode={marineReserve.radius.errorCode}
                      value={marineReserve.radius.value}
                      onChange={(value: string) => this.changeValue(value, 'radius')}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormSpacer />

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      searchable={true}
                      className="rounded"
                      labelText={t('components:general.country')}
                      placeholder={t('components:general.country')}
                      required={true}
                      optionsText={COUNTRIES}
                      optionKey={'value'}
                      optionValue={'label'}
                      initialValueFilterText={COUNTRIES_KEY_LABEL[marineReserve.country.value]}
                      defaultValueFilterText={COUNTRIES_KEY_LABEL[marineReserve.country.value]}
                      defaultValue={marineReserve.country.value}
                      initialValue={marineReserve.country.value}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => this.changeValue(value, 'country')}
                      errorCode={marineReserve.country.errorCode}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText={t('components:general.state')}
                      placeholder={t('components:general.state')}
                      className="rounded"
                      optionsText={states}
                      optionKey={'value'}
                      optionValue={'label'}
                      onSearch={value => this.handleSearchState(value)}
                      required={marineReserve.state.required}
                      errorCode={marineReserve.state.errorCode}
                      initialValue={marineReserve.state.value}
                      defaultValue={marineReserve.state.value}
                      initialValueFilterText={marineReserve.state.value}
                      defaultValueFilterText={marineReserve.state.value}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.changeValue(value, 'state')}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>

          <div className="modal-create-marine-reserve-buttons">
            <div className="modal-create-marine-reserve-buttons__item">
              <MainButton
                type="primary"
                onClick={() => this.validateForm()}
                text={reserveMarineId ? t('components:actions.edit') : t('components:actions.create')}
              />
            </div>
          </div>
        </ModalCreateMarineReserveContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalCreateMarineReserve));