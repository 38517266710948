import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import { SubheadDestacadoTextContainer } from '../../../../components/text/subhead-destacado-text/subhead-destacado-text-styles';

export const ConfigurationTemplateDetailStyle = styled.div`
  display: flex;
  .config-template-detail-languages {
    width: 306px;
    margin-right: 10%;
    padding-top: 29px;
    &__title {
      border-bottom: 1px solid ${colors['NE-002/100']};
      color: ${colors['NE-002/100']};
      padding-bottom: 8px;
      margin-bottom: 34px;
      text-transform: uppercase;
    }
  }
  .config-template-detail-form {
    width: 100%;
  }

  .config-template-detail-form-section {
    margin-bottom: 46px;
    &:last-child  {
      margin-bottom: 60px;
    }
    &__title {
      margin-bottom: 24px;
      color: ${colors['SE-002/100']};
    }
    &__form {
    }
  }

  .config-template-detail-form-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 19px;
    border-bottom: 1px solid ${colors['NE-002/100']};
    margin-bottom: 34px;
    &__text {
      ${SubheadDestacadoTextContainer} {
        line-height: 14px;
      }
    }
  }

  .config-contact-buttons {
    display: flex;
    &__item {
      margin-right: 12px;
      width: 144px;
    }
  }
`;

export const ConfigItemLanguage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &.disable {
    .config-item-language-item-icon {
      opacity: 0;
      visibility: hidden;
    }
  }
  .config-item-language-item-icon {
    width: 32px;
    margin-right: 16px;
  }
  .config-item-language-item-text {
    display: flex;
    align-items: center;
    height: 33px;
  }
`;
