import services from './services';
import { IDiveForm } from '../models/dive-forms';

export const getDives = async (params: any) => {
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'dives',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getOneDives = (divesId: string, params?: any) => {
  const endpoint = `dives/${divesId}`;
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadDives = (divesId: string, data: any) => {
  const endpoint = `dives/${divesId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const validateDives = (divesId: string) => {
  const endpoint = `dives/${divesId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const deleteDives = (divesId: string) => {
  const endpoint = `dives/${divesId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createDives = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'dives',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const editDive = (diveId: string | number, data: IDiveForm) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `dives/${diveId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const uploadPictures = (diveId: string, data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `dives/${diveId}/upload-pictures`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
}

export const uploadMainPicture = (diveId: string, data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `dives/${diveId}/upload-main-picture`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
}



