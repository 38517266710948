import services from './services';
import { Size } from '../containers/divers/detail/profile/types';

export const patchSize = (diverId: string | number, data: Size) => {
  const endpoint = `size/${diverId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const postSize = (diverId: number, data: Size) => {
  const endpoint = `size/`;
  data.diver = diverId;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
