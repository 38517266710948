import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const DetailMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(1, 66, 81, 0.9);
  width: 274px;
  /* width: 204px; */
  height: 100vh;
  max-height: 100vh;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  .menu-top {
    display: flex;
    flex-direction: column;
    background-color: rgba(1, 66, 81, 1);
  }
  .menu-superadmin {
  }
  .menu-back {
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    /* border-bottom: 1px solid white; */
    margin: 0 24px;
    padding-bottom: 0px;
    margin-bottom: 10px;
    .menu-back-button {
      display: flex;
      opacity: 0.6;
      transition: all 0.3s ease-in-out;
      color: #b3e3e4;

      &:hover {
        opacity: 1;
      }
      &__arrow {
        margin-right: 8px;
        min-width: 16px;
        min-height: 16px;
        margin-top: 3px;
      }
    }
    .menu-centers-button {
      .input-box-main-field {
        color: white;
      }
    }
  }


  
  .menu-back{
    .input-box-main-field,
    .input-box-main-list {
      li  {
        padding-left: 0;
      }
      padding-left: 0;
    }
    .input-box-error {
      display: none;
    }
    .input-box-topbar {
      display: none;
    }
    
       .rounded-title {
        .input-box-error {
          min-height: 12px;
        }
        .input-box-main {
        .input-box-main-field {
            border: 0px solid ${colors['NE-002/100']};
            border-radius: 5px;
          }
        }
        &.show-list {
          .input-box-main {
            .input-box-main-field {
              color: ${colors['NE-001/100']};
              border-bottom: 0px solid ${colors['NE-001/100']};
              border: 0px solid ${colors['PR-001-100']};
              p {
                /* color: ${colors['NE-001/100']};*/
              }
            }
          }
        }
      }
    
    
    .input-box-main-field {
        position: relative;
        width: 100%;
        padding: 0;
        padding-right: 30px;
        transition: all 0.2s ease-in-out;
        margin-bottom: 4px;
        color: ${colors['NE-001/100']};
        border-bottom: 0px solid ${colors['NE-002/100']};
        z-index: 0;
        display: flex; flex-direction: column;
    
        input {
          font-size: 13px;
          line-height: 22px;
          font-weight: 300;
          color: ${colors['NE-002/100']};
          &::placeholder  {
            color: ${colors['NE-002/100']};
          }
        }
        cursor: pointer;
        height: ${props => (props.multiple ? '48px' : '')};
        min-height: 48px;
        p {
          cursor: inherit;
          color: ${colors['PR-001/100']}
        }
    
        &:hover {
          /* background: ${colors['NE-002/100']}; */
        }
    
        &:focus {
          background: ${colors['SE-002/0']};
          border-bottom: none;
          color: ${colors['NE-001/100']};
          border-bottom: 0px solid ${colors['NE-002/100']};
        }
      }
      
    .input-box-main-list {
      top: calc(75%);
      padding: 8px 12px;
      overflow: initial;
      max-height: unset;
      font-size:12px;
      color: ${colors['SE-002/20']};
      background: ${colors['SE-002/75']};
      box-shadow: none;
      li {
        opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
    }

  }


  .menu-logo {
    width: 120px;
    margin: 37px 60px 20px 27px;
  }
  .menu-items {
    width: 100%;
    height: 100%;
    padding: 20px 10px 0 13px;
    &__button {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
    p{
    font-size: 13px;
    }
  }

  .menu-bottom {
    margin: 0 24px 36px;
    border-top: 1px solid white;
    padding-top: 20px;
    &__profile {
      margin-bottom: 10px;
    }
    &__logout {
    }
  }

  .menu-back-superadmin {
    color: white;
    padding: 12px;
    background: rgb(0,161,165,.3);
    border-radius: 5px;
    text-transform: initial;
    margin-top: 18px;
    cursor: pointer;
    &__text {
    }
    &__back {
      color: ${colors['SE-001/100']};
    }
  }

  .track-vertical {
    min-width: 2px;
    width: 2px;
    padding-left: 3px;
    height: 100%;
    right: 4px;
  }

  .thumb-vertical {
    background: #bcc5d7 !important;
    border-radius: 0px;
  }
`;
