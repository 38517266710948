import * as React from 'react';
import { CreateActivitiesGalleryStyle } from './create-activities-gallery-style';
import InputUploadFiles from '../../../../../components/input-upload-file/input-upload-file';
import { AttachedFile } from '../../../../../types/file';
import { images } from '../../../../../assets/images';
import SingleButton from '../../../../../components/single-button/single-button';
import MainButton from '../../../../../components/main-button';
import 'moment/locale/es.js';
export interface ICreateActivitiesGalleryProps {
  onPrev: () => void;
  onNext: (newPictures: AttachedFile[], deletedPictures: string[]) => void;
  pictures?: any[];
  onlyDialog?: boolean;
}

export interface ICreateActivitiesGalleryState {
  newPictures: AttachedFile[];
  deletedPictures: string[];
  pictures: string[];

}

export default class CreateActivitiesGallery extends React.Component<
  ICreateActivitiesGalleryProps,
  ICreateActivitiesGalleryState
> {
  constructor(props: ICreateActivitiesGalleryProps) {
    super(props);

    this.state = {
      newPictures: [],
      deletedPictures: [],
      pictures: [],
    };
  }

  componentDidMount() {
    const { pictures } = this.props;

    if (pictures) {
      this.setState({ pictures })
    }
  }

  handleNext() {

    let picturesTemp: any[] = [];
    const { onNext } = this.props;
    const { newPictures, deletedPictures, pictures } = this.state;


    if (this.props.pictures) {
      for (const file of this.props.pictures) {
        fetch(file.name)
          .then(res => res.blob()) // Gets the response and returns it as a blob
          .then(blob => {
            let error = false;
            let errorType = false;
            let objectURL = URL.createObjectURL(blob);
            let myImage = new Image();
            picturesTemp.push({
              errorType,
              error,
              data: file,
              name: file.name,
              size: file.size,
              creation_date: new Date(),
            })
          });
      }
    }

    if (newPictures) {
      for (const file of newPictures) {
        fetch(file.name)
          .then(res => res.blob()) // Gets the response and returns it as a blob
          .then(blob => {
            let error = false;
            let errorType = false;
            let objectURL = URL.createObjectURL(blob);
            let myImage = new Image();
            picturesTemp.push({
              errorType,
              error,
              data: file,
              name: file.name,
              size: file.size,
              creation_date: new Date(),
            })
          });
      }
    }
    onNext(picturesTemp, deletedPictures);
  }

  removePicture(item: any, index: number) {
    localStorage.removeItem(item.name);
    const { pictures, deletedPictures } = this.state;

    pictures.splice(index, 1);
    
    deletedPictures.push(item);

    this.setState({ pictures, deletedPictures })
  }


  imgFromLocalStorage(obj: any, index: number) {
    let url: any = '';
    url = localStorage.getItem(obj.name);
    if (url) {
      return (
        <img key={index} src={url} alt="" />
      )
    }
  }

  renderpictures() {
    const { pictures } = this.state;
    return (
      <div className="selected-pictures">
        {
          pictures.map((pic, index) => {
            return (
              <div key={index} className="selected-pictures__picture">
                {this.imgFromLocalStorage(pic, index)}
                <img key={index} src={pic} alt="" />
                <div className="selected-pictures__delete-icon">
                  <div className="selected-pictures__icon">
                    <img src={images.closeWhiteSvg} onClick={() => this.removePicture(pic, index)} />
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  render() {
    const { onPrev } = this.props;
    return (
      <CreateActivitiesGalleryStyle>
        <InputUploadFiles
          multiple={true}
          required={true}
          onChange={(images: AttachedFile[]) => this.setState({ newPictures: images })}
          value={''}
        />

        {
          this.renderpictures()
        }

        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {!(!!this.props.onlyDialog) &&
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            }
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={!(!!this.props.onlyDialog) ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesGalleryStyle>
    );
  }
}