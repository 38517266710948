import * as React from 'react';
import { MyCenterProfileDataStyle } from './my-center-profile-data-style';
import HeaderMain from '../../../../components/header-main/header-main';
import { MainLayoutSection, FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import { images } from '../../../../assets/images';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import InputBox from '../../../../components/input-box';
import InputUploadFiles from '../../../../components/input-upload-file/input-upload-file';
import { RouteComponentProps, match, withRouter } from 'react-router';
import MyCenterProfileNav from '../my-center-profile-nav/my-center-profile-nav';
import { getCentre, editCentre, uploadLogo } from '../../../../services/centre';
import { ICentreForm } from '../../../../models/centre-forms';
import PhoneBox from '../../../../components/phone-box/phone-box';
import _ from 'lodash';
import { isValidEmail, isValidPhone, isValidURL, isValidCif } from '../../../../utils/validation';
import services from '../../../../services/services';
import { getDataFromAddress } from '../../../../services/google-maps';
import { getAdministrativeLevel1, getTown, getCountry } from '../../../../utils/maps';
import SelectBox from '../../../../components/select-box';
import { IDropdown } from '../../../../models/generic';
import IStoreState from '../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { havePermissionsDeals } from '../../../../services/staff-centre';
import { APP_WEB_URL } from '../../../../env';


export const TYPES_CENTRE: any = [
  { value: 'centre', label: 'Centro' },
  { value: 'club', label: 'Club' },
];

export interface IMyCenterProfileDataProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  staffPermissions?: any;
}

export interface IMyCenterProfileDataState {
  renderModal: string;
  centre?: ICentreForm;
  places: IDropdown[];
  logo: any;
  errors: {
    [key: string]: string;
  };
}

class MyCenterProfileData extends React.Component<IMyCenterProfileDataProps, IMyCenterProfileDataState> {
  constructor(props: IMyCenterProfileDataProps) {
    super(props);

    this.state = {
      renderModal: '',
      places: [],
      logo: null,
      errors: {},
    };
  }

  componentDidMount() {
    this.getMyCenterProfile();
  }

  async getMyCenterProfile() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const centre: ICentreForm = (await getCentre(id)).data;
    this.setState({ centre });
  }

  async onSave() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { centre, errors, logo } = this.state;
    if (centre && centre.personResponsible && !centre.personResponsible.name) {
      errors['personResponsible.name'] = 'required';
    }
    if (
      centre &&
      centre.personResponsible &&
      centre.personResponsible.phone &&
      !centre.personResponsible.phone.prefix
    ) {
      centre.personResponsible.phone.prefix = '+34';
    }
    this.setState({ errors });
    if (!centre || Object.values(errors).filter(error => error !== '').length > 0) return;
    const { centreId, ...centreWithoutId } = centre;
    const formattedCertifiers = centreWithoutId.certifiers.map((cer: any) => ({
      ...cer,
      certifier: cer.certifier ? cer.certifier.id : -1,
    }));
    await editCentre(id, { ...centreWithoutId, certifiers: formattedCertifiers });
    if (logo) {
      const logoForm = new FormData();
      logoForm.set('file', logo[0].data);
      await uploadLogo(id, logoForm);
    }
    services.pushNotification({
      title: '¡Guardado!',
      text: 'Los datos de perfil se han guardado correctamente',
      type: 'green',
    });
  }

  validateField(field: string, value: string, required: boolean) {
    const { errors } = this.state;
    errors[field] = '';
    if (required && (!value || value === '')) {
      errors[field] = 'required';
      this.setState({ errors });
      return false;
    }
    if (!required && (!value || value === '')) {
      this.setState({ errors });
      return true;
    }
    const fieldType = field.split('.').pop();
    switch (fieldType) {
      case 'email':
        errors[field] = !isValidEmail(value) ? 'invalid-email' : '';
        break;
      case 'number':
        errors[field] = !isValidPhone(value) ? 'invalid-phone' : '';
        break;
      case 'instagram':
      case 'facebook':
      case 'website':
        errors[field] = !isValidURL(value) ? 'invalid-format' : '';
        break;
      case 'cif':
        errors[field] = !isValidCif(value) ? 'invalid-cif' : '';
        break;
      default:
        break;
    }
    this.setState({ errors });
    return errors[field] === '';
  }

  handleChange(field: string, value: string, required: boolean = false) {
    const { centre } = Object.assign(this.state);
    if (field.indexOf('.') > -1) {
      _.update(centre, field, () => value);
    } else {
      centre[field] = value;
    }
    this.validateField(field, value, required);
    return new Promise((res, rej) => {
      this.setState({ centre }, () => res(''));
    });
  }

  handleChangeLogo(file: any) {
    this.setState({ logo: file });
  }

  searchTimeout: any;
  handleSearchAddress(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (value && value !== '') this.searchAddress(value);
    }, 300);
  }

  async searchAddress(value: string) {
    const resp: any = await getDataFromAddress(value);
    const results = resp.data.results;
    const places: any[] = results.map((result: any) => {
      const level1 = getAdministrativeLevel1(result);
      const town = getTown(result);
      const country = getCountry(result);
      return {
        level1,
        town,
        country,
        value: result.formatted_address,
        label: result.formatted_address,
      };
    });
    this.setState({ places });
  }

  render() {
    const {
      match: {
        params: { id },
      },
      staffPermissions,
    } = this.props;
    const { centre, errors, places } = this.state;

    return (
      <>
        <HeaderMain
          title={'Perfil del centro'}
          mainButton={
            !staffPermissions
              ? {
                img: images.checkCircleWhiteSvg,
                text: 'Guardar cambios',
                onClick: () => this.onSave(),
                disabled: !havePermissionsDeals()
              }
              : undefined
          }
        />
        <MainLayoutSection>
          <MyCenterProfileNav centreId={id} />
          <MyCenterProfileDataStyle>
            <div className="my-center-profile-data-left">
              <div className="my-center-profile-data-section">
                <div className="my-center-profile-data-title">
                  <div className="my-center-profile-data-title__img">
                    <img src={images.homeSvg} alt="" />
                  </div>
                  <div className="my-center-profile-data-title__text">
                    <SubheadDestacadoText>Datos generales</SubheadDestacadoText>
                  </div>
                </div>
                <div className="my-center-profile-data-form">
                  <FormGrid>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="Nombre"
                          className="rounded-title"
                          type="text"
                          value={centre ? centre.name : ''}
                          labelText="Nombre del centro"
                          errorCode={errors.name}
                          filledValue={''}
                          onChange={value => this.handleChange('name', value, true)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          className="rounded-title"
                          type="text"
                          disabled={true}
                          value={centre && centre.type == 'centre' ? 'Centro' : 'Club'}
                          labelText="Tipo de empresa"
                          filledValue={''}
                          onChange={value => this.handleChange('centreId', value)}
                        />
                        {/*<SelectBox
                          labelText="Tipo de empresa"
                          withTooltip={false}
                          placeholder="Tipo de empresa"
                          required={true}
                          className="rounded-title"
                          optionsText={TYPES_CENTRE}
                          optionKey={'value'}
                          optionValue={'label'}
                          defaultValue={centre ? centre.type : ''}
                          initialValue={centre ? centre.type : ''}
                          icon={images.arrowDown2BlueSvg}
                          onChange={value => this.handleChange('type', value, true)}
                        />*/}
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="ID del centro"
                          className="rounded-title"
                          type="text"
                          disabled={true}
                          errorCode={errors.centreId}
                          value={centre ? centre.centreId || '' : ''}
                          labelText="ID del centro"
                          filledValue={''}
                          onChange={value => this.handleChange('centreId', value)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    {/*<FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="Razón social"
                          className="rounded-title"
                          type="text"
                          errorCode={errors.bussinessName}
                          value={centre ? centre.bussinessName || '' : ''}
                          labelText="Razón social"
                          filledValue={''}
                          onChange={value => this.handleChange('bussinessName', value)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                     <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="CIF"
                          className="rounded-title"
                          type="text"
                          errorCode={errors.cif}
                          value={centre ? centre.cif || '' : ''}
                          labelText="CIF"
                          filledValue={''}
                          onChange={value => this.handleChange('cif', value)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="Z9999999Z"
                          className="rounded-title"
                          type="text"
                          errorCode={errors.cif}
                          value={centre ? centre.cif || '' : ''}
                          labelText="CIF"
                          filledValue={''}
                          onChange={value => this.handleChange('cif', value)}
                        />
                      </FormGridItem>
                    </FormGridRow> */}
                    {/* <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="Nombre"
                          className="rounded-title"
                          type="text"
                          value=""
                          labelText="¿Tienes un IVA especial?"
                          filledValue={''}
                          onChange={() => {}}
                        />
                      </FormGridItem>
                    </FormGridRow> */}
                  </FormGrid>
                </div>
              </div>

              <div className="my-center-profile-data-section">
                <div className="my-center-profile-data-title">
                  <div className="my-center-profile-data-title__img">
                    <img src={images.userSvg} alt="" />
                  </div>
                  <div className="my-center-profile-data-title__text">
                    <SubheadDestacadoText>Datos responsable</SubheadDestacadoText>
                  </div>
                </div>
                <div className="my-center-profile-data-form">
                  <FormGrid>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="Nombre y apellidos"
                          className="rounded-title"
                          type="text"
                          errorCode={errors['personResponsible.name']}
                          value={centre && centre.personResponsible ? centre.personResponsible.name : ''}
                          labelText="Nombre y apellidos"
                          filledValue={''}
                          onChange={value => this.handleChange('personResponsible.name', value, true)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="example@diveassap.com"
                          className="rounded-title"
                          type="text"
                          errorCode={errors['personResponsible.email']}
                          value={centre && centre.personResponsible ? centre.personResponsible.email || '' : ''}
                          labelText="Email de contacto"
                          filledValue={''}
                          onChange={value => this.handleChange('personResponsible.email', value)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <PhoneBox
                          className="rounded-title"
                          labelText="Teléfono de contacto"
                          placeholder={'666 666 666'}
                          errorNumber={errors['personResponsible.phone.number']}
                          filledPrefix={
                            centre && centre.personResponsible && centre.personResponsible.phone
                              ? centre.personResponsible.phone.prefix
                              : ''
                          }
                          filledNumber={
                            centre && centre.personResponsible && centre.personResponsible.phone
                              ? centre.personResponsible.phone.number
                              : ''
                          }
                          onChange={(field, value) => {
                            if (field === 'phonePrefix') {
                              this.handleChange('personResponsible.phone.prefix', value, false);
                            } else {
                              this.handleChange('personResponsible.phone.number', value, false);
                            }
                          }}
                        />
                      </FormGridItem>
                    </FormGridRow>
                  </FormGrid>
                </div>
              </div>
            </div>

            <div className="my-center-profile-data-right">
              <div className="my-center-profile-data-section">
                <div className="my-center-profile-data-title">
                  <div className="my-center-profile-data-title__img">
                    <img src={images.eyeSvg} alt="" />
                  </div>
                  <div className="my-center-profile-data-title__text">
                    <SubheadDestacadoText>Datos públicos</SubheadDestacadoText>
                  </div>
                </div>
                <div className="my-center-profile-data-form">
                  <FormGrid>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <SelectBox
                          required={true}
                          placeholder="Dirección"
                          className="rounded-title"
                          optionsText={places}
                          errorCode={errors['address']}
                          optionKey={'value'}
                          optionValue={'label'}
                          defaultValue={centre ? centre.address : ''}
                          initialValue={centre ? centre.address : ''}
                          initialValueFilterText={centre ? centre.address : ''}
                          defaultValueFilterText={centre ? centre.address : ''}
                          labelText="Dirección"
                          onSearch={value => {
                            this.handleSearchAddress(value);
                          }}
                          onChange={(value, label) => {
                            const place: any = places.filter(place => place.value === value)[0];
                            this.handleChange('address', label || '', true).then(() => {
                              this.handleChange('state', place.level1.longName, true).then(() => {
                                this.handleChange('town', place.town.longName).then(() => {
                                  this.handleChange('country', place.country.shortName);
                                });
                              });
                            });
                          }}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    {/* 
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="Zona de actividades"
                          className="rounded-title"
                          type="text"
                          errorCode={errors['activityArea']}
                          value={centre ? centre.activityArea || '' : ''}
                          labelText="Zona de actividades"
                          filledValue={''}
                          onChange={value => this.handleChange('activityArea', value)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    */}
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="example@diveassap.com"
                          className="rounded-title"
                          type="text"
                          errorCode={errors['email']}
                          value={centre && centre.email ? centre.email : ''}
                          labelText="Email público"
                          filledValue={''}
                          onChange={value => this.handleChange('email', value)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <PhoneBox
                          className="rounded-title"
                          labelText="Teléfono de contacto"
                          placeholder={'666 666 666'}
                          errorNumber={errors['phone.number']}
                          filledPrefix={centre && centre.phone ? centre.phone.prefix : ''}
                          filledNumber={centre && centre.phone ? centre.phone.number : ''}
                          onChange={(field, value) => {
                            if (field === 'phonePrefix') {
                              this.handleChange('phone.prefix', value, true);
                            } else {
                              this.handleChange('phone.number', value, true);
                            }
                          }}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="example.com"
                          className="rounded-title"
                          type="text"
                          errorCode={errors['website']}
                          value={centre && centre.website ? centre.website : ''}
                          labelText="Página web"
                          filledValue={''}
                          onChange={value => this.handleChange('website', value, true)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={false}
                          disabled={true}
                          className="rounded-title"
                          type="text"
                          value={centre && centre.id ? APP_WEB_URL + '/dive-center/' + centre.id : ''}
                          labelText="Link del centro"
                          filledValue={''}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <InputUploadFiles
                      multiple={false}
                      accept={['png', 'jpg', 'jpeg']}
                      required={true}
                      label="Logo"
                      value={centre ? centre.logo || '' : ''}
                      onChange={file => {
                        this.handleChangeLogo(file);
                      }}
                    />
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="instagram.com/example"
                          className="rounded-title"
                          type="text"
                          errorCode={errors['instagram']}
                          value={centre && centre.instagram ? centre.instagram : ''}
                          labelText="Perfil de instagram"
                          filledValue={''}
                          onChange={value => this.handleChange('instagram', value)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                    <FormGridRow>
                      <FormGridItem type="col-0">
                        <InputBox
                          required={true}
                          placeholder="facebook.com/example"
                          className="rounded-title"
                          type="text"
                          errorCode={errors['facebook']}
                          value={centre && centre.facebook ? centre.facebook : ''}
                          labelText="Perfil de facebook"
                          filledValue={''}
                          onChange={value => this.handleChange('facebook', value)}
                        />
                      </FormGridItem>
                    </FormGridRow>
                  </FormGrid>
                </div>
              </div>
            </div>
          </MyCenterProfileDataStyle>
        </MainLayoutSection>
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(withRouter(MyCenterProfileData));
