import styled from 'styled-components';

export const CheckboxStyle = styled.div`
  .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease-in-out all;

    &:hover {
      .checkbox-item {
        border: 1px solid #00a1a5;
      }
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;

    /* Tambien se define en active */
    &:checked ~ .checkbox-item {
      background: black;
    }
  }

  .checkbox-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-right: 14px;
    transition: 0.3s ease-in-out all;

    border: 1px solid #00a1a5;
    box-sizing: border-box;
    border-radius: 3px;

    &--icon {
      position: absolute;
      width: 16px;
      height: 16px;
      visibility: hidden;
    }
  }

  .checkbox-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.active {
    .checkbox-container {
      &:hover {
        .checkbox-item {
        }
      }
      .checkbox-item {
        background: #00a1a5;
        position: relative;

        &--icon {
          visibility: visible;
        }
      }
    }
  }

  &.error {
    .checkbox-label {
      color: #ff0000;
    }
    .checkbox-item {
      border: 1px solid #ff0000;
    }
  }
`;
