import React, { Component } from 'react';
import services from '../../../../services/services';
import { ERROR_MESSAGES_ES } from '../../../../constants/error-messages';
import {
  isValidEmail,
  isValidURL,
  isValidDocumentID,
  isValidPhone,
  isValidPostalCode,
  isUnderAge,
} from '../../../../utils/validation';
import ModalDiverForm from './modal-diver-form';
import { Certifier } from '../../../../types/company';
import moment from 'moment';
import 'moment/locale/es.js';
import { GhostDiverForm, DiverForm } from '../../../../types/user';

export interface DiverFormFunctionalProps {
  title: string;
  showModal: boolean;
  onAccept: (id: string) => void;
  onCancel: () => void;
  className?: string;
  centreID?: string;
  ghostDiver: GhostDiverForm;
}

interface DiverFormFunctionalState {
  error: string;
  errorFields: { [key: string]: string };
  person: DiverForm;
  [key: string]: any;
}

class DiverFormFunctional extends Component<DiverFormFunctionalProps, DiverFormFunctionalState> {
  state: DiverFormFunctionalState = {
    error: '',
    errorFields: {},
    userID: '',
    person: {
      name: this.props.ghostDiver.name,
      surname: this.props.ghostDiver.surname,
      email: this.props.ghostDiver.email,

      birthday: '',
      nif: '',
      address: '',
      town: '',
      state: '',
      postalCode: '',
      country: '',
      phonePrefix: '+34',
      phoneNumber: '',

      diverId:
        this.props.ghostDiver.diver && this.props.ghostDiver.diver.id ? this.props.ghostDiver.diver.id.toString() : '',
    },
  };

  cleanPreviousError(keys: string[], callback: Function) {
    const { errorFields } = this.state;
    const keysWithErrors = keys.filter(key => errorFields[key] || key === 'centres');

    if (keysWithErrors && keysWithErrors.length) {
      const aux = { ...errorFields };
      for (const field of Object.keys(aux)) {
        if (keysWithErrors.includes(field) || (keysWithErrors.includes('centres') && field.startsWith('centres.'))) {
          delete aux[field];
        }
      }
      this.setState({ errorFields: aux }, () => callback());
    } else {
      callback();
    }
  }

  handleDataChange(field: string, value: string | Certifier, isAdding?: boolean, key?: string) {
    return new Promise<void>((res, rej) => {
      this.cleanPreviousError([field], () => {
        const person = { ...this.state.person };
        person[field] = value;
        this.setState({ person }, () => res());
      });
    });
  }

  validateForm() {
    const { person, errorFields } = this.state;

    let formError = false;
    const tempFieldErrors = { ...errorFields };

    Object.keys(person).map(key => {
      const value = person[key];
      if ((value === '' || value === null) && !this.props.ghostDiver) {
        tempFieldErrors[key] = 'required';
        formError = true;
      } else if (key === 'email' && !isValidEmail(value)) {
        tempFieldErrors[key] = 'invalid-email';
        formError = true;
        // } else if (key === 'nif' && !isValidDocumentID(value, 'DNI')) {
        //   tempFieldErrors[key] = 'invalid-dni';
        //   formError = true;
      } else if (key === 'phoneNumber' && !isValidPhone(value)) {
        tempFieldErrors[key] = 'invalid-phone';
        formError = true;
      } else if (key === 'postalCode' && !isValidPostalCode(value)) {
        tempFieldErrors[key] = 'invalid-postal-code';
        formError = true;
      } else if (key === 'birthday' && isUnderAge(18, value)) {
        tempFieldErrors[key] = 'under-18';
        formError = true;
      } else {
        delete tempFieldErrors[key];
      }
    });

    if (formError) {
      const errorMessage = ERROR_MESSAGES_ES['form-error'];
      this.setState({ error: errorMessage, errorFields: tempFieldErrors });
      return false;
    }
    this.setState({ error: '', errorFields: {} });
    return true;
  }

  async handleSubmitForm() {
    await this.validateForm();
    const { error } = this.state;
    if (error !== '') {
      return;
    }

    this.updateDiver();
  }

  updateDiver() {
    const { centreID, onAccept, ghostDiver } = this.props;
    const { person } = this.state;

    const endpoint = centreID ? `centres/${centreID}/divers/${ghostDiver.diver.id}` : `divers/${ghostDiver.diver.id}`;
    const data = { ...person };
    data.phone = { number: person.phoneNumber, prefix: person.phonePrefix };
    data.birthday = moment(person.birthday, 'DD/MM/YYYY').toISOString();

    Object.keys(data).forEach((key: string) => {
      if (!data[key]) {
        delete data[key];
      }
      if (key === 'phone') {
        if (!data[key].number) {
          delete data[key];
        }
      }
    });

    services.patch({
      endpoint,
      data,
      loader: true,
      then: ({ data: res }: any) => {
        const { id } = res;
        onAccept(id);
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  render() {
    const { person, error, errorFields } = this.state;
    return (
      <ModalDiverForm
        {...this.props}
        person={person}
        error={error}
        errorFields={errorFields}
        validateForm={() => this.validateForm()}
        handleDataChange={(field, value) => this.handleDataChange(field, value)}
        submitForm={() => this.handleSubmitForm()}
      />
    );
  }
}

export default DiverFormFunctional;
