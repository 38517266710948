import styled from 'styled-components';

export const PlanningDiveSiteStyle = styled.div`
  .planning-dive-site-disclaimer {
    font-size: 14px;
    line-height: 22px;
    color: #bcc5d7;
    margin-bottom: 28px;
  }
  .planning-dive-site-form {
    display: flex;
    margin-bottom: 28px;
    &__select {
      flex: auto;
    }
    &__add {
      width: 120px;
      margin-left: 20px;
    }
  }

  .planning-dive-site-list {
    margin-bottom: 16px;
  }
`;
