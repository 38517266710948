import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const ModalBookingDetailBodyStyle = styled.div`
  padding: 42px 32px 32px 32px;
  width: 416px;
  .booking-status {
    text-transform: uppercase;
    padding: 5px;
    border-radius: 3px;
    font-size: 10px;
    display: inline-block;
    margin-bottom: 8px;
    line-height: 14px;

    &--did-not-show {
      color: ${colors['NE-003/100']};
      background-color: ${colors['NE-003/10']};
    }

    &--canceled-center {
      color: ${colors['CO-003/100']};
      background-color: ${colors['CO-003/10']};
    }

    &--canceled-diver {
      color: ${colors['CO-004/100']};
      background-color: ${colors['CO-004/15']};
    }

    &--finished {
      color: ${colors['CO-001/100']};
      background-color: ${colors['CO-001/15']};
    }
  }

  .booking-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    max-width: 240px;
    color: ${colors['NE-001/100']};
    margin-bottom: 10px;
  }

  .booking-number {
    text-transform: uppercase;
    color: ${colors['SE-004/75']};
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 25px;
  }

  .booking-date-box {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    font-size: 12px;
    line-height: 18px;
    color: ${colors['NE-001/100']};
    margin-bottom: 37px;
    & > div {
      display: flex;
      align-items: center;
    }
  }

  .booking-diver {
    margin-bottom: 24px;
    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 16px;
    }

    &__data {
      display: grid;
      grid-template-columns: 48px 1fr;
      column-gap: 14px;
    }

    &__picture {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${colors['SE-004/100']};
      margin-bottom: 2px;
    }
    &__id {
      color: ${colors['SE-004/75']};
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .bookings-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 2px;
  }

  .booking-other-details {
    border-radius: 5px;
    padding: 20px 16px;
    background-color: ${colors['NE-002/10']};
    font-size: 14px;
    margin-bottom: 4px;

    &__row {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    &__detail-title {
      font-weight: 500;
      text-transform: uppercase;
      line-height: 22px;
      color: ${colors['NE-002/100']};
    }
    &__detail-value {
      font-weight: 300;
      color: ${colors['SE-004/100']};
    }
  }

  .booking-other-details-divers {
    margin-top: 10px;
    padding: 5px 16px;
    font-size: 14px;
    &__row {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    &__detail-title {
      font-weight: 500;
      text-transform: uppercase;
      line-height: 22px;
      color: ${colors['NE-002/100']};
    }
    &__detail-value {
      font-weight: 300;
      color: ${colors['SE-004/100']};
    }
  }

  .booking-buttons {
    display: flex;
    flex-direction: column;

    & button {
      flex: 1;
      font-size: 14px;
      border-radius: 5px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
`;
