import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ConfigurationContactStyle = styled.div`
  .config-contact-title {
    display: flex;
    border-bottom: 1px solid ${colors['NE-002/100']};
    padding-bottom: 12px;
    margin-bottom: 40px;
    &__icon {
      width: 30px;
      margin-right: 12px;
    }
    &__text {
      color: ${colors['NE-001/100']};
    }
  }

  .config-contact-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    &__left,
    &__right {
      width: 45%;
    }
  }
  .config-contact-buttons {
    display: flex;
    &__item {
      margin-right: 12px;
      width: 144px;
    }
  }
`;
