import React, { Component } from 'react';
import HeaderMain from '../../components/header-main/header-main';
import MainTable from '../../components/main-table/main-table';
import { MainLayoutSection, MainLayoutTableContainer } from '../main-layout-style';
import { images } from '../../assets/images';
import Pagination from '../../components/pagination';
import { DivingCenterLayoutContainer } from './diving-center-style';
import ModalDelete from '../../components/modals/modal-delete/modal-delete';
import ModalSuccess from '../../components/modals/modal-success/modal-success';
import { tableColumns, rowKeys, Centre } from './types';
import { TableRow, TableCell } from '../../types/table';
import Can from '../../roles/can-component';
import IStoreState from '../../redux/store/IStoreState';
import { connect } from 'react-redux';
import ModalCreateCentre from './create-edit/modal-centre-form';
import services from '../../services/services';
import { WithTranslation, withTranslation } from 'react-i18next';

interface CentresLayoutProps extends WithTranslation {
  agent?: any;
  centres: Centre[];
  totalPages: number;
  page: number;
  totalCentres: number;
  setPage: (page: number) => void;
  refreshCentres: () => void;
  deleteCentre: (id: string) => void;
  onFilterChange: (key: string, value: any, method?: string, collection?: string) => void;
  onFilterDelete: (key: string) => void;
  t: any;
}
interface CentresLayoutState {
  renderModal: string;
  editID?: string;
  searchValue?: string;
}

class CentresLayout extends Component<CentresLayoutProps, CentresLayoutState> {
  state: CentresLayoutState = {
    renderModal: '',
  };
  searchTimeout: any;

  formatDataToTableRows(data: { [key: string]: any }[]) {
    return data.map(item => {
      const row: TableRow = {};
      const { t } = this.props;

      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };

        switch (key) {
          case 'icon':
            cell.value = images.homeSvg;
            break;
          case 'name':
            cell.value = item[key];
            cell.key = 'highlighted-body';
            break;
          case 'rating':
            cell.value = item[key] / 2;
            cell.key = 'rate';
            break;
          case 'id':
            cell.value = item[key];
            cell.key = 'detail';
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });

      row.icon = {
        value: images.divingCompaniestMenuBlack,
        type: '',
        key: 'icon',
      };

      row.detail = {
        value: t('buttons:access'),
        key: 'text-button',
        type: 'light-blue', // si esta pendiente "pending"
        link: `centres/${item.id}/planning/calendar`,
      };

      row.options = {
        value: item.id,
        type: '',
        key: 'options',
        actions: [
          {
            label: t('buttons:edit'),
            icon: images.edit3Svg,
            onClick: () => {
              this.setState({ renderModal: 'modal-center-form', editID: item.id });
            },
          },
          {
            label: t('buttons:delete'),
            icon: images.delete2Svg,
            onClick: () => {
              this.setState({ renderModal: 'modal-delete', editID: item.id });
            },
          },
        ],
      };

      row['manager'] = {
        value: item.managers || '-',
        type: '',
        key: '',
      };

      return row;
    });
  }

  render() {
    const { renderModal, editID, searchValue } = this.state;
    const {
      agent,
      centres,
      totalPages,
      setPage,
      refreshCentres,
      deleteCentre,
      onFilterChange,
      onFilterDelete,
      totalCentres,
      page,t
    } = this.props;
    return (
      <>
        {/* Header */}
        <HeaderMain
          title={t('modalMessages:divecia')}
          withSearch={true}
          resultCount={totalCentres}
          mainButton={{
            img: images.createWhiteSvg,
            text: t('buttons:new'),
            onClick: () => this.setState({ renderModal: 'modal-center-form' }),
          }}
          searchValue={searchValue}
          handleSearch={(value: string) => {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
              this.setState({ searchValue: value })
              if (value && value.length > 3) onFilterChange('name', value);
              else if ((value && value.length === 3) || !value) onFilterDelete('name');
            }, 150);
            // if (value && value.length > 3) onFilterChange('name', value);
            // else if ((value && value.length === 3) || !value) onFilterDelete('name');
          }}
        />
        {/* Content */}
        <MainLayoutSection>
          <DivingCenterLayoutContainer>
            <MainLayoutTableContainer>
              <Can roles={agent.roles} perform="centres:management">
                <div className="diving-center-table">
                  <MainTable
                    columns={tableColumns}
                    rows={this.formatDataToTableRows(centres)}
                    rowKeys={rowKeys}
                    onOrder={() => { }}
                  />
                </div>
                {totalPages > 1 && (
                  <div className="diving-center-pagination">
                    <Pagination page={page} limit={totalPages} callback={page => setPage(page)} />
                  </div>
                )}
              </Can>
            </MainLayoutTableContainer>
          </DivingCenterLayoutContainer>
        </MainLayoutSection>

        {/* Company Form */}

        {renderModal === 'modal-center-form' && (
          <ModalCreateCentre
            showModal={renderModal === 'modal-center-form'}
            onAccept={() => {
              this.setState({ renderModal: 'modal-success', editID: undefined }, () => {
                refreshCentres();
              });
            }}
            onCancel={() => this.setState({ renderModal: '', editID: undefined })}
            centreID={editID}
            editMode={!!editID}
          />
        )}

        {/* Modal Delete centre  */}

        {renderModal === 'modal-delete' && (
          <ModalDelete
            title={t("modalMessages:roles-delete.delete")}
            subtitle={t("modalMessages:roles-delete.confirm")}
            showModal={renderModal === 'modal-delete'}
            onRemove={async () => {
              if (!!editID) {
                await deleteCentre(editID);

                this.setState({ renderModal: '', editID: undefined }, () => {
                  refreshCentres();
                });
              }
            }}
            onCancel={() => this.setState({ renderModal: '', editID: undefined })}
          />
        )}

        {/* Modal Success */}

        {/* <ModalSuccess
          title="¡Genial!"
          text={
            !!editID
              ? 'Se ha editado el centro correctamente'
              : 'Se ha creado un nuevo centro de buceo pendiente de validación por parte de DiveAsapp.'
          }
          showModal={renderModal === 'modal-success'}
          onClose={() => this.setState({ renderModal: '', editID: undefined })}
        /> */}
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default withTranslation(['modalMessages', 'buttons']) (connect<{}, {}, {}, IStoreState>(mapStateToProps)(CentresLayout));
