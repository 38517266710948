import React from 'react';
import { images } from '../../assets/images';
import { MapMarkerStyle } from './map-marker-style';
interface IMapMarkerProps {
  lng: number;
  lat: number;
  text: string;
}

export const MapMarker = (props: IMapMarkerProps) => {
  return (
    <MapMarkerStyle lat={props.lat} lng={props.lng} key={`card-map-selected`}>
      <div className="map-marker-container">
        <img src={images.puntoInmersionSelectedSvg} />
      </div>
    </MapMarkerStyle>
  );
};
