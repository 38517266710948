import React from 'react';
import { TFunction } from 'i18next';
import SingleButton from '../../../components/single-button/single-button';
import { images } from '../../../assets/images';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';

export const STATUS_OPTIONS = (t: TFunction) => {
  return [
    {
      label: t('layouts:bills.status.billing-pending'),
      value: 'billing-pending',
    },
    {
      label: t('layouts:bills.status.billed'),
      value: 'billed',
    },
  ];
};

export const getBillLinesTableColumns = (onEdit: Function, onDelete: Function, t: TFunction) => {
  return [
    {
      Header: t('modalMessages:modal-superadmin-create-bill.step-2.concept.label'),
      accessor: 'concept',
      width: 196,
    },
    {
      Header: t('modalMessages:modal-superadmin-create-bill.step-2.quantity.label'),
      accessor: 'quantity',
      width: 100,
    },
    {
      Header: t('modalMessages:modal-superadmin-create-bill.step-2.unit.label'),
      accessor: 'unit',
      width: 100,
    },
    {
      Header: t('modalMessages:modal-superadmin-create-bill.step-2.price.label'),
      accessor: 'price',
      width: 100,
    },
    {
      Header: t('modalMessages:modal-superadmin-create-bill.step-2.amount.label'),
      accessor: 'amount',
      width: 100,
    },
    {
      id: 'edit',
      Header: '',
      width: 60,
      Cell: (cell: any) => (
        <SingleButton
          className="small secondary"
          icon={images.editSvg}
          onClick={() => {
            onEdit(cell.row.index);
          }}
        />
      ),
    },
    {
      id: 'delete',
      Header: '',
      width: 60,
      Cell: (cell: any) => (
        <SingleButton
          className="small secondary"
          icon={images.deleteSvg}
          onClick={() => {
            onDelete(cell.row.index);
          }}
        />
      ),
    },
  ];
};

export const getBillTableColumns = (t: TFunction, onDetail: (billId: number) => void, onDownload: Function) => [
  {
    Header: t('layouts:bills-superadmin.columns.status'),
    accessor: 'status',
    width: 80,
  },
  {
    Header: t('layouts:bills-superadmin.columns.bill-number'),
    accessor: 'billNumber',
    className: 'bold-text',
    sizeCol: 2,
  },
  {
    Header: t('layouts:bills-superadmin.columns.concept'),
    accessor: 'concept',
    sizeCol: 2,
  },
  {
    Header: t('layouts:bills-superadmin.columns.centre-id'),
    accessor: 'centreId',
    width: 120,
  },
  {
    Header: t('layouts:bills-superadmin.columns.centre'),
    accessor: 'centre',
    sizeCol: 3,
  },
  {
    Header: t('layouts:bills-superadmin.columns.created-at'),
    accessor: 'createdAt',
    width: 120,
  },
  {
    Header: t('layouts:bills-superadmin.columns.amount'),
    accessor: 'amount',
    width: 120,
  },
  {
    Header: t('layouts:bills-superadmin.columns.payment-status'),
    accessor: 'paymentStatus',
    width: 120,
  },
  {
    sticky: 'right',
    id: 'action',
    Header: '',
    width: 70,
    className: 'actions',
    Cell: (cell: any) => (
      <>
        <SingleButton className="small secondary" drop={true} icon={images.otherSvg}>
          <div
            className="single-button-drop__item"
            onClick={() => {
              onDetail(cell.row.original.id);
            }}
          >
            <div className="single-button-drop__item__icon">
              <SingleButton className="secondary" icon={images.eyeSvg} />
            </div>
            <div className="single-button-drop__item__text">
              <BodyDestacadoText>{t('layouts:bills-superadmin.options.see-detail')}</BodyDestacadoText>
            </div>
          </div>
          <div
            className="single-button-drop__item"
            onClick={() => {
              onDownload(cell.row.original.pdfUrl);
            }}
          >
            <div className="single-button-drop__item__icon">
              <SingleButton className="secondary" icon={images.downloadSvg} />
            </div>
            <div className="single-button-drop__item__text">
              <BodyDestacadoText>{t('layouts:bills-superadmin.options.download-bill')}</BodyDestacadoText>
            </div>
          </div>
        </SingleButton>
      </>
    ),
  },
];

export const getBillDetailChargesColumn = (t: TFunction) => [
  {
    Header: t('tables:concept'),
    accessor: 'concept',
    width: 600,
  },
  {
    Header: t('tables:quantity'),
    accessor: 'quantity',
    width: 150,
  },
  {
    Header: t('tables:unit'),
    accessor: 'unit',
    width: 150,
  },
  {
    Header: t('tables:price'),
    accessor: 'price',
    width: 150,
  },
  {
    Header: t('tables:paid-amount'),
    accessor: 'amount',
    width: 157,
  },
];
