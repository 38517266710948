import styled from 'styled-components';

export const CreateActivitiesExclusivenessStyle = styled.div`
  .mt-20 {
    margin-top: 20px;
  }
  .create-activities-other-title {
    display: flex;
    margin-bottom: 24px;
    &__text {
      margin-right: 28px;
    }
    &__check  {
      margin-right: 20px;
    }
  }
  .create-activities-other-form {
    display: flex;
    &__input  {
      width: 100%;
      margin-right: 20px;
    }
  }
  .create-activities-explusiveness  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    .create-activities-dicotomic-container {
       display: flex;
      &__text {
        width: 100%;
      }
      &__icon {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
    }
  }
`;
