import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalIncidenceManagementStyle = styled.div`
  padding: 32px;
  padding-top: 0;
  width: 416px;
  color: ${colors['NE-001/100']};

  .modal-incident-management-text {
    color: ${colors['NE-002/100']};
    margin-bottom: 28px;
  }
  .modal-incident-management-reason  {
    margin-bottom: 34px;
    &__item {
      margin-bottom: 16px;
    }
  }
  .modal-incidence-detail-input  {
    &__title {
      margin-bottom: 14px;
      color: ${colors['SE-002/100']};
    }
  }

  .modal-incidence-detail-buttons {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;
