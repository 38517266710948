import services from './services';

export const getActivities = (params: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'activities',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getOneActivities = (activityId: string, params?: any) => {
  const endpoint = `activities/${activityId}`;
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadActivities = (activityId: string, data: any) => {
  const endpoint = `activities/${activityId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const bookActivities = (activityId: string, data: any) => {
  const endpoint = `activities/${activityId}/book`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const deleteActivities = (activityId: string) => {
  const endpoint = `activities/${activityId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createActivities = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'activities',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchActivity = (activityId: string, data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `activities/${activityId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const patchStaffActivity = (activityId: string, data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `activities/${activityId}/staff`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};
export const patchAddStaffActivity = (activityId: string, data: any) => {
  
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `activities/${activityId}/staff/add`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteStaffActivity = (activityId: string, staffId: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `activities/${activityId}/staff/${staffId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const patchConfirmStaff = (centreId: string, activityId: string, data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `centres/${centreId}/activities/${activityId}/confirm-staff`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const patchRejectStaff = (centreId: string, activityId: string, data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: `centres/${centreId}/activities/${activityId}/reject-staff`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const getActivityHistoryCsv = (centreId: string) => {
  return new Promise((resolve, reject) => {
    const args = {
      endpoint: `centres/${centreId}/activities/csv`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const postActivityPictures = (activityId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      data,
      endpoint: `activities/${activityId}/upload-pictures`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};

export const deleteActivityPictures = (activityId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      data,
      endpoint: `activities/${activityId}/pictures`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};
