import styled from 'styled-components';

export const IncidenceStyle = styled.div`
  .dive-site-head {
    display: flex;
    justify-content: space-between;
    &__nav {
    }
    &__buttons {
      display: flex;
      height: 40px;
      &__filter {
        padding-right: 21px;
        position: relative;
        margin-right: 20px;

        &:after {
          content: '';
          position: absolute;
          top: 12px;
          right: 0;
          height: 16px;
          width: 1px;
          background: #f1f3f8;
        }
      }
    }
  }
  .dive-site-head-filters  {
    display: none;
    &.active {
      display: flex;
    }
    margin-bottom: 60px;
    &__item {
      width: 213px;
    }
  }
`;
