import React from 'react';
import { ItemFileContainer } from './item-file-style';
import Label02Text from '../text/label-02-text/label-02';
import { images } from '../../assets/images';

export interface IItemFileProps {
  type: string | any;
}

export default class ItemFile extends React.Component<IItemFileProps, {}> {
  renderType(type: string) {

    type = type.toLocaleLowerCase();
    switch (type) {
      case 'pdf':
        return <Label02Text>{type}</Label02Text>;
      case 'doc':
        return <Label02Text>{type}</Label02Text>;
      case 'png':
        return <Label02Text>{type}</Label02Text>;
      case 'jpg':
      case 'jpeg':
        return <Label02Text>{type}</Label02Text>;
      case 'xls':
        return <Label02Text>{type}</Label02Text>;
      case 'image':
        return <img src={images.turtle} alt="" />;
      default:
        return <Label02Text>{type}</Label02Text>;
        break;
    }
  }
  render() {
    const { type } = this.props;
    return (
      <ItemFileContainer className={type}>
        <div className="item-file-icon">{this.renderType(type)}</div>
      </ItemFileContainer>
    );
  }
}
