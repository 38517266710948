import * as React from 'react';
import { connect } from 'react-redux';
import IStoreState from '../../../../../redux/store/IStoreState';
import { ContentRightStyle } from './content-right-style';
import moment from 'moment';
import { getDiver, } from '../../../../../services/diver';
import { images } from '../../../../../assets/images';
import { setUserData } from '../../../../../redux/actions';
import services from '../../../../../services/services';
import ModalContainer from '../../../../../components/modals/modal-container';
import { ModalCreateActivitiesStyle } from '../../../../dive-offers/activities/modal-create-activities/modal-create-activities-style';
import Button from '../../../../../components/buttons/main-button/button';
import { getOneBookings, uploadBookings } from '../../../../../services/bookings';
import { useEffect, useState } from 'react';
import { CENTRE_SERVICES_KEY_VALUE } from '../../../../../constants/centre-services';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_KEY } from '../../../../../env';
import parser from 'html-react-parser';

const ContentRight = (props: any): JSX.Element => {
  let observation = '';
  const [bookingState, setBookingState] = useState(
    {
      activity: {
        activityDate: '',
        price: 0
      },
      bookingDate: '',
      confirmationDate: '',
      cancelDate: '',
      diverId: '',
      diver: {},
      lines: [],
      commentsCentre: '',
      divers: []
    },
  )
  const [totalPrice, setTotalPrice] = useState(0)
  const [observationDescription, setObservationDescription] = useState('')
  const [editObservationDescription, setEditObservationDescription] = useState(false)
  const { booking } = props
  const { t } = useTranslation(['layouts'])

  useEffect(() => {
    if (booking) {
      getBooking()
    }
  }, [booking]);

  useEffect(() => {
    if (props.startActivityDate) {
      setBookingState({
        ...bookingState,
        activity: {
          ...bookingState.activity,
          activityDate: formatDate(props.startActivityDate)
        },
      });
    }
  }, [props.startActivityDate]);

  useEffect(() => {
    setBookingState({
      ...bookingState,
      activity: { ...bookingState.activity, price: props.activityPrice },
      bookingDate: formatDate(new Date()),
    })
    //const booking = {...bookingState, activity: { ...bookingState.activity, price: props.activityPrice}}
    //setTotalPrice(callTotalPrice(booking, props.bookingLines))
  }, [props.activityPrice]);

  useEffect(() => {
    if (props.bookingLines.length > 0) {
      updateBookingLines(props.bookingLines)
    }
  }, [props.bookingLines]);

  useEffect(() => {
    if (props.accountDivers || props.accountDiversGhost) {
      const accountDiversList = props.accountDivers || [];
      const accountDiversGhostList = props.accountDiversGhost || [];
      const diversList = accountDiversList.concat(accountDiversGhostList);
      setBookingState({
        ...bookingState,
        divers: diversList,
        diver: props.accountDivers[0] || {}
      });
      //const booking = {...bookingState, divers: diversList};
      //setTotalPrice(callTotalPrice(booking, props.bookingLines))
    }
  }, [props.accountDivers, props.accountDiversGhost])

  const getMinutes = (field: Date) => {
    let minutesResult: any;
    const minutes = new Date(field).getMinutes();
    if (minutes > 9) {
      minutesResult = new Date(field).getMinutes();
    } else {
      minutesResult = '0' + new Date(field).getMinutes();
    }
    return minutesResult;
  }

  const getBooking = async () => {
    const { booking } = props;

    if (booking.diverId !== null) {
      const params = {
        filter: {
          relations: ['user', 'documents'],
        },
      };
      const res = await getDiver(booking.diverId, params);
      const bookingLines: any = findAccountParticipantsOfLines(booking.lines);
      bookingLines.pop();

      let dates = [];
      if (booking.activity && booking.activity.timeBlocks) {

        if (booking.activity.timeBlocks && booking.activity.timeBlocks.length > 1) {
          dates = booking.activity.timeBlocks.sort((a: any, b: any) => {
            const dateA = new Date(a.start.date);
            dateA.setHours(a.start.hour);
            dateA.setMinutes(a.start.minutes);
            const dateB = new Date(b.start.date);
            dateB.setHours(b.start.hour);
            dateB.setMinutes(b.start.minutes);
            return b - a;
          });
        } else {
          dates = booking.activity.timeBlocks;
        }
      }

      let hours = '';
      let startHour = '';
      let endHour = '';
      for (let i = 0; i < dates.length; i++) {
        startHour = booking.activity.timeBlocks[i].start.hour;
        if (booking.activity.timeBlocks[i].start.minutes > 9) {
          startHour = startHour + ':' + booking.activity.timeBlocks[0].start.minutes;
        }
        else {
          startHour = startHour + ':' + booking.activity.timeBlocks[0].start.minutes + booking.activity.timeBlocks[0].start.seconds;
        }
        endHour = booking.activity.timeBlocks[i].end.hour;
        if (booking.activity.timeBlocks[i].end.minutes > 9) {
          endHour = endHour + ':' + booking.activity.timeBlocks[0].end.minutes;
        }
        else {
          endHour = endHour + ':' + booking.activity.timeBlocks[0].end.minutes + booking.activity.timeBlocks[0].end.seconds;
        }
        hours = hours + startHour.toString() + '-' + endHour.toString() + ' '
      }

      const minutesBooking = getMinutes(booking.createdAt);
      const minutesConf = getMinutes(booking.confirmationDate);
      const minutesCancel = getMinutes(booking.updatedAt);

      const cancelDate = (booking.cancelled !== 'noCancelled' && !booking.rejected)
        || (booking.cancelled === 'noCancelled' && booking.activity && booking.activity.cancelled)
        ? booking.updatedAt !== null ? `${formatDate(booking.updatedAt)}, ${new Date(booking.updatedAt).getHours()}:${minutesCancel}` : '-'
        : ''

      setBookingState({
        activity: {
          activityDate: formatDate(booking.activity.date) + ' - ' + hours,
          price: booking.activity.price
        },
        bookingDate: `${formatDate(booking.createdAt)}, ${new Date(booking.createdAt).getHours()}:${minutesBooking}`,
        cancelDate,
        confirmationDate: booking.confirmationDate !== null ? `${formatDate(booking.confirmationDate)}, ${new Date(booking.confirmationDate).getHours()}:${minutesConf}` : '-',
        diverId: booking.diverId,
        diver: res.data,
        lines: bookingLines,
        commentsCentre: booking.commentsCentre,
        divers: booking.divers
      })
      //setTotalPrice(callTotalPrice(booking, bookingLines))
      //setObservationDescription(booking.commentsCentre)
      observation = booking.commentsCentre;
      services.setValue(setUserData(res.data))
    }
  }

  const updateBookingLines = (bookingLinesUpdated: any) => {
    // const { booking } = props;
    //const bookingLines: any = findAccountParticipantsOfLines(bookingLinesUpdated);

    setBookingState({
      ...bookingState,
      lines: bookingLinesUpdated
    });
    //setTotalPrice(callTotalPrice(bookingState, bookingLines))
  }

  const callTotalPrice = () => {
    let activityPrice = 0;
    let activityPriceLines = booking.lines;
    if (bookingState.divers.length) {
      activityPrice = (bookingState.activity.price + (bookingState.activity.price * bookingState.divers.length)) / 100;
    }
    else {
      activityPrice = bookingState.activity.price / 100;
    }

    activityPriceLines = activityPriceLines.slice(0, activityPriceLines.length - 1)
    let totalPriceOfLines = 0;
    totalPriceOfLines = getTotalPriceOfLines(activityPriceLines);
    const totalPrice = activityPrice + totalPriceOfLines;
    return totalPrice
  }

  const getTotalPriceOfLines = (bookingLines: any) => {
    let totalPriceOfLines = 0
    for (let i = 0; i < bookingLines.length; i++) {
      totalPriceOfLines += (bookingLines[i].price / 100);
    }
    return totalPriceOfLines
  }

  const findAccountParticipantsOfLines = (lines: any) => {
    for (let i = 0; i < lines.length; i++) {
      const element = lines[i];
      lines[i].accountParticipants = 0;
      for (let j = 0; j < lines.length; j++) {
        const element2 = lines[j];
        if (element.concept === element2.concept) {
          lines[i].accountParticipants += 1
        }
      }
    }
    return deleteDuplicated(lines, 'concept')
  }

  const deleteDuplicated = (arr: any, prop: any) => {
    const newArray = [];
    const lookup = [];

    for (const i in arr) {
      lookup[arr[i][prop]] = arr[i];
    }
    for (const i in lookup) {
      newArray.push(lookup[i]);
    }
    return newArray;
  }

  const formatDate = (date: any) => {
    // tslint:disable-next-line:prefer-template
    return moment(date).locale('es').format("dddd") + ', '
      + moment(date).format("D") + ' '
      + moment(date).locale('es').format("MMMM") + ' '
      + moment(date).format("YYYY");
  }

  const renderServices = (line: any, pos: number) => {
    return (
      <tr key={pos}>
        <td>{CENTRE_SERVICES_KEY_VALUE[line.concept]} x{line.accountParticipants}</td>
        <td>{(line.price / 100) * line.accountParticipants} €</td>
      </tr>
    )
  }

  const renderDiverInfo = (diver: any) => {
    return (
      <>
        <div className="image" style={{ width: '25px' }}>
          <br /> {diver.user ? diver.user.picture ? <img src={diver.user.picture} alt="" /> :
            <img style={{ borderRadius: '50%' }} src={images.userSvg} alt="" /> : ''}
        </div>
        <div className="contact-info"><br />
          <span>{diver.user ? diver.user.name : ''} {diver.user ? diver.user.surname : ''}</span>
          <span>{diver.phone && diver.phone.prefix ? `(+${diver.phone.prefix})` : ''} {diver.phone && diver.phone.number ? diver.phone.number : ''}</span> <br />
          <span className="contact-info__email"><a href={diver.user ? `mailto:${diver.user.email}` : '#'}>{diver.user ? diver.user.email : ''}</a></span>
        </div>
      </>
    )
  }


  const saveComments = async () => {
    const { booking } = props;
    const params = {
      filter: {
        relations: ['divers'],
      },
    };

    const bookingResult = (await getOneBookings(booking.id, params)).data;
    bookingResult.commentsCentre = observation;

    await uploadBookings(booking.id, bookingResult);
    //    await uploadBookings(booking.id,  {commentsCentre: bookingResult.commentsCentre});
    setBookingState({ ...bookingState, commentsCentre: observation });
    //props.updateCommentsState(observationDescription)
    setEditObservationDescription(false)
    sendNotification(t('layouts:bookings.confirmed'), t('layouts:bookings.booking.comupdated'), 'green');
  }

  const sendNotification = (title: string, msg: string, type: string) => {
    services.pushNotification({
      title,
      text: msg,
      type,
    });
  }

  return (
    <ContentRightStyle>
      <div className="content-right__observation">
        <div className="title">
          <span>{t('layouts:bookings.intobs')}</span>
          <img src={images.edit3WhiteSvg} alt="" onClick={() => setEditObservationDescription(true)} />
        </div>
        {
          bookingState.commentsCentre == null ?
            <p>{t('layouts:bookings.addnote')}</p>
            : <p>{parser(bookingState.commentsCentre)}</p>
        }
      </div>
      <div className="content-right__date">
        <div style={{ marginBottom: '1.6rem' }}>
          <span className="title">{t('layouts:soldate')}</span>
          <span className="data">{bookingState.bookingDate}</span>
        </div>

        <div style={{ marginBottom: '1.6rem' }}>
          <span className="title">{t('layouts:actdate')}</span>
          <span className="data">{bookingState.activity.activityDate} </span>
        </div>

        <div style={{ marginBottom: bookingState.cancelDate ? '1.6rem' : '0' }}>
          <span className="title">{t('layouts:confirmdate')}</span>
          <span className="data">{bookingState.confirmationDate}</span>
        </div>

        {bookingState.cancelDate &&
          <div>
            <span className="title">{t('layouts:canceldate')}</span>
            <span className="data">{bookingState.cancelDate}</span>
          </div>
        }

      </div>
      <div className="content-right__price">
        <div>
          <span className="title">{t('layouts:bookings.booking.price')}</span>
          <div className="data">
            <table>
              <tbody>
                {
                  props.create ?
                    <tr>
                      <td>{t('layouts:activity-menu.activity')} x{bookingState.divers.length}</td>
                      <td>{(bookingState.activity.price / 100) * bookingState.divers.length} €</td>
                    </tr>
                    : <tr>
                      <td>{t('layouts:activity-menu.activity')} x{bookingState.divers.length + 1}</td>
                      <td>{(bookingState.activity.price / 100) * (bookingState.divers.length + 1)} €</td>
                    </tr>
                }
                {
                  bookingState.lines.map((item: any, index: number) => {
                    return renderServices(item, index)
                  })
                }

                <tr className="total">
                  <td>{t('layouts:totprice')}</td>
                  <td>
                    <p>{callTotalPrice()} €</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="content-right__contact">
        <span className="title">{t('layouts:bookings.contactdive')}</span>
        <div className="data" style={{ display: 'flex' }}>
          {renderDiverInfo(bookingState.diver)}
        </div>
      </div>

      {/* Modals */}
      {editObservationDescription && (
        <ModalContainer
          title={t('layouts:bookings.booking.editdobs')}
          modalClose={() => setEditObservationDescription(false)}
          active={editObservationDescription}
        >
          <ModalCreateActivitiesStyle>
            <Editor
              apiKey={TINYMCE_KEY}
              initialValue={bookingState.commentsCentre}
              init={{
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat '
              }}
              onEditorChange={(event: any) => observation = event}
            ></Editor>
            <div className="modal-footer">
              <Button
                onClick={() => saveComments()}
                text={t('layouts:billing-information.save')}
                type={'primary'}
              />
            </div>
          </ModalCreateActivitiesStyle>
        </ModalContainer>
      )}
    </ContentRightStyle>
  )
}

function mapStateToProps(state: IStoreState) {
  return {
    bookingLines: state.bookingLines.value || [],
    startActivityDate: state.startActivityDate.value,
    accountDivers: state.accountDivers.value,
    accountDiversGhost: state.accountDiversGhost.value,
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(ContentRight);
