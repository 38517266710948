import * as React from 'react';
import { CardStatsStyle } from './card-stats-style';
import HeadDestacadoText from '../text/head-destacado-text/head-destacado-text';
import LabelText from '../text/label-text/label';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';

export interface ICardStatsProps {
  number: number;
  subtitle: string;
  title: string;
  className: string;
}

export interface ICardStatsState {}

export default class CardStats extends React.Component<ICardStatsProps, ICardStatsState> {
  constructor(props: ICardStatsProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { number, subtitle, title, className } = this.props;
    return (
      <CardStatsStyle className={className}>
        <div className="card-stats-number">
          <HeadDestacadoText>{number}</HeadDestacadoText>
        </div>
        <div className="card-stats-text">
          <div className="card-stats-text__subtitle">
            <LabelText>{subtitle}</LabelText>
          </div>
          <div className="card-stats-text__title">
            <TextDestacadoText>{title}</TextDestacadoText>
          </div>
        </div>
      </CardStatsStyle>
    );
  }
}
