import * as React from 'react';
import { CardDiverActivityStyle } from './card-diver-activity-style';
import InfoText from '../text/info-text/info-text';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import BodyText from '../text/body-text/body-text';
import TitleText from '../text/title-text/title-text';
import LabelText from '../text/label-text/label';
import MainButton from '../main-button';
import moment from 'moment';
import { capitalize } from 'lodash';

export interface ICardDiverActivityProps {
  state: string;
  title: string;
  location: string;
  type: string;
  onClickRegister?: () => void;
  date?: string;
}

export interface ICardDiverActivityState {}

export default class CardDiverActivity extends React.Component<ICardDiverActivityProps, ICardDiverActivityState> {
  constructor(props: ICardDiverActivityProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { state, title, location, type, onClickRegister, date } = this.props;
    let dateMoment = null;
    if (date) {
      dateMoment = moment(date);
    }
    return (
      <CardDiverActivityStyle className={type}>
        <div className="card-diver-activity-left">
          <div className="card-diver-activity-left__state">
            <InfoText>{state}</InfoText>
          </div>
          <div className="card-diver-activity-left__title">
            <TextDestacadoText>{title}</TextDestacadoText>
          </div>
          <div className="card-diver-activity-left__location">
            <BodyText>{location}</BodyText>
          </div>
          {onClickRegister && <MainButton type="primary" text="Registrar" onClick={() => onClickRegister()} />}
        </div>
        {dateMoment && (type === 'done' || type === 'registered') && (
          <div className="card-diver-activity-right">
            <div className="card-diver-activity-right__item">
              <div className="card-diver-activity-right__item__title">
                <TitleText>{dateMoment.format('DD')}</TitleText>
              </div>
              <div className="card-diver-activity-right__item__subtitle">
                <LabelText>{capitalize(dateMoment.format('MMMM'))}</LabelText>
              </div>
            </div>
            <div className="card-diver-activity-right__item">
              <div className="card-diver-activity-right__item__title">
                <TitleText>{dateMoment.format('LT').split(' ')[0]}</TitleText>
              </div>
              <div className="card-diver-activity-right__item__subtitle">
                <LabelText>{capitalize(dateMoment.format('LT').split(' ')[1])}</LabelText>
              </div>
            </div>
          </div>
        )}
      </CardDiverActivityStyle>
    );
  }
}
