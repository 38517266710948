import styled from 'styled-components';
import { images } from '../../assets/images';
import { colors } from '../../assets/colors';

export const ButtonStarStyle = styled.div`
  display: flex;
  padding: 9px 9px 9px 6px;
  background: ${colors['NE-002/10']};
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  cursor: pointer;
  .button-star-icon {
    width: 22px;
    height: 22px;
    margin-right: 6px;
    background-image: url(${images.starRedSvg});
    background-size: cover;
    background-position: contain;
    transition: all 0.3s ease-in-out;

    &--active {
      background-image: url(${images.starFullRedSvg});
    }
  }
  .button-thumbs-up-icon {
    width: 22px;
    height: 22px;
    margin-right: 6px;
    background-image: url(${images.thumbsUp});
    background-size: cover;
    background-position: contain;
    transition: all 0.3s ease-in-out;
  }
  .button-star-count {
    color: ${colors['PR-001/100']};
  }
`;
