import services from './services';

export const getDiveSiteRatings = async (params: any, loader: boolean = true) => {
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      loader,
      endpoint: 'dive-site-ratings',
      then: resolve,
      catch: reject,
    };
    services.get(args);
  });
};

export const getOneDiveSiteRatings = (diveSiteRatingsId: string, params?: any) => {
  const endpoint = `dive-site-ratings/${diveSiteRatingsId}`;
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadDiveSiteRatings = (diveSiteRatingsId: string, data: any) => {
  const endpoint = `dive-site-ratings/${diveSiteRatingsId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const validateDiveSiteRatings = (diveSiteRatingsId: string) => {
  const endpoint = `dive-site-ratings/${diveSiteRatingsId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const deleteDiveSiteRatings = (diveSiteRatingsId: string) => {
  const endpoint = `dive-site-ratings/${diveSiteRatingsId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createDiveSiteRatings = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'dive-site-ratings',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
