import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ModalCreateActivitiesStyle = styled.div`
  width: 780px;
  padding: 32px;
  padding-top: 48px;

  .modal-create-activities-title {
    margin-bottom: 40px;
    &__step {
      text-transform: uppercase;
      color: ${colors['SE-002/100']};
      margin-bottom: 2px;
    }
  }

  .modal-create-activities-buttons {
    display: flex;
    justify-content: space-between;
  }
  .modal-create-activities-buttons-left {
    display: flex;
    align-items: center;
    &__back {
      margin-right: 12px;
    }
    &__next  {
      min-width: 144px;
    }
  }
  .modal-create-activities-container {
    //margin-bottom: 48px;
  }
  .modal-create-activities-type {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    &__item  {
      width: 100%;
      margin-right: 12px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;
