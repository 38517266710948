import React, { Component } from 'react';
import { CalendarBoxContainer } from './calendar-box-style';
import { images } from '../../assets/images';

import CalendarItem from '../calendar-item';
import { ERROR_MESSAGES_ES } from '../../constants/error-messages';
import Cleave from 'cleave.js/react';
import TooltipBox from '../tooltip-box/tooltip-box';
import LabelText from '../text/label-text/label';
import InfoText from '../text/info-text/info-text';
import * as dateFns from 'date-fns';

export interface CalendarBoxProps {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  icon?: string;
  iconDisabled?: string;
  errorCode?: string;
  rangeSelect?: boolean;
  multiSelect?: boolean;
  forbidPastDates?: boolean;
  forbidFutureDates?: boolean;
  allowedDates?: string[];
  minAge?: number;
  maxAge?: number;
  maxYear?: number;
  minYear?: number;

  required?: boolean;

  initialValue: string;
  labelText: string;
  initialMultiValues?: Date[];
  onFocus?: any;
  onChange: (value: string, error?: boolean, secondValue?: string) => void;
  onChangeMulti?: (values: Date[]) => void;
  clearValue?: boolean;

  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;

  isOutsideRange?: (date: string) => boolean;
  adviceText?: string;
  disableWrite?: boolean;
  clear?: boolean;
  cleaded?: ()=>void;
}

interface CalendarBoxState {
  focus: boolean;
  focus2: boolean;
  showCalendar: boolean;
  showTooltip: boolean;
  value: string;
  secondValue?: string;
  multiValues?: Date[];
  changeInput: boolean;
  valueInput: string;
  valueInput2: string;
  clearValue?: boolean;
  valores: any
}

class CalendarBox extends Component<CalendarBoxProps, CalendarBoxState> {
  private toggleContainer: any;

  constructor(props: CalendarBoxProps) {
    super(props);
    this.state = {
      valores: {},
      focus: false,
      focus2: false,
      showCalendar: false,
      showTooltip: false,
      changeInput: false,
      value: '',
      secondValue: '',
      multiValues: [],
      valueInput: '',
      valueInput2: '',
      clearValue: false,
    };
    this.toggleContainer = React.createRef();

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  public static defaultProps = {
    icon: images.calendarSvg,
  };

  componentDidMount() {
    const { value } = this.state;
    const { initialValue, initialMultiValues, rangeSelect } = this.props;
    if (initialValue && value === '') {
      if (rangeSelect) {
        this.setState({ value: initialValue, secondValue: initialValue, valores: {value: initialValue} });
      } else {
        this.setState({ value: initialValue });
      }
    }
    if (initialMultiValues) {
      this.setState({ multiValues: initialMultiValues });
    }
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentDidUpdate = (prevProps: CalendarBoxProps) => {
    const { initialValue, initialMultiValues, clearValue, rangeSelect, clear, cleaded } = this.props;

    if (initialValue && initialValue !== prevProps.initialValue) {
      if (rangeSelect) {
        this.setState({value:initialValue, secondValue: initialValue, valores: {value: initialValue} });
      } else {
        this.setState({value:initialValue, valores: {value: initialValue} });
      }
    } else if (clearValue && prevProps.clearValue !== clearValue) {
      this.setState({value:'', valueInput: '', valueInput2: '',  valores: {value: ''} });

    }
    if (initialMultiValues && initialMultiValues !== prevProps.initialMultiValues) {
      this.setState({multiValues: initialMultiValues});
    }
    if(clear){
      this.setState({value:'', valueInput: '', valueInput2: '',  valores: {value: ''} });
    }
    //this.setState({...state});
     if(clear && cleaded){
      cleaded();
    } 

  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    if (this.state.showCalendar && !this.toggleContainer.current.contains(event.target)) {
      if (this.state.changeInput) {
        this.onToggleCalendar(undefined, this.state.valueInput, this.state.valueInput2);
      }
      this.onToggle(false);
    }
  }

  onToggle(show: boolean) {
    const { onFocus, clearValue } = this.props;
    if (onFocus && show && clearValue) {
      onFocus();
    }
    this.setState({ showCalendar: show });
  }

  onToggleCalendar(show?: boolean, firstValue?: string, secondVal?: string) {
    const { secondValue, value } = this.state;
    const {
      onChange,
      isOutsideRange,
      allowedDates,
      initialValue,
      forbidFutureDates,
      forbidPastDates,
      multiSelect,
      onChangeMulti,
    } = this.props;
    let secondValueAux = secondVal !== null && secondVal !== undefined ? secondVal : secondValue;
    let firstValueAux = firstValue !== null && firstValue !== undefined ? firstValue : value;

    if (isOutsideRange) {
      if (firstValue && firstValue.length > 9) {
        if (isOutsideRange(firstValue)) {
          firstValueAux = dateFns.format(new Date(), 'dd/MM/yyyy');
        }
      }
      if (secondVal && secondVal.length > 9) {
        if (isOutsideRange(secondVal)) {
          secondValueAux = dateFns.format(new Date(), 'dd/MM/yyyy');
        }
      }
    }

    let change = true;
    // TODO: comprobar todas las restricciones son correctas
    if (allowedDates && firstValueAux) {
      change = allowedDates.indexOf(firstValueAux) > -1;
    }
    if (
      forbidFutureDates &&
      firstValueAux.length > 9 &&
      dateFns.isFuture(dateFns.parse(firstValueAux, 'dd/MM/yyyy', new Date()))
    ) {
      change = false;
    }
    if (
      forbidPastDates &&
      firstValueAux.length > 9 &&
      dateFns.isPast(dateFns.parse(firstValueAux, 'dd/MM/yyyy', new Date())) &&
      !dateFns.isToday(dateFns.parse(firstValueAux, 'dd/MM/yyyy', new Date()))
    ) {
      change = false;
    }

    const state: any = {
      value: !change ? initialValue : firstValueAux,
      secondValue: secondValueAux,
      showCalendar: show,
      changeInput: false,
      valueInput: !change ? initialValue : firstValueAux,
      valueInput2: secondValueAux,
    };

    let execute = true;
    if (firstValue && firstValue.length < 10) {
      execute = false;
      delete state.value;
      delete state.valueInput;
    }
    if (secondVal && secondVal.length < 10) {
      execute = false;
      delete state.secondValue;
      delete state.valueInput2;
    }

    if (!change) if (show === undefined) delete state.showCalendar;

    if (multiSelect && onChangeMulti && show === false) {
      this.setState({ clearValue: true });
      onChangeMulti([]);
    } else {
      this.setState({ ...state }, () => {
        if (!show && execute) {
          if (change) {
            onChange(firstValueAux, false, secondValueAux);
          }
        }
      });
    }
  }

  render() {
    const {
      disabled,
      placeholder,
      className,
      icon,
      iconDisabled,
      forbidFutureDates,
      forbidPastDates,
      minAge,
      maxAge,
      labelText,
      errorCode,
      withTooltip,
      tooltipTitle,
      tooltipText,
      required,
      rangeSelect,
      multiSelect,
      onChangeMulti,
      allowedDates,
      isOutsideRange,
      onFocus,
      minYear,
      maxYear,
      adviceText,
      disableWrite,
      initialValue,
      clear,
    } = this.props;
    const {
      showCalendar,
      showTooltip,
      value,
      secondValue,
      multiValues,
      valueInput,
      valueInput2,
      focus,
      focus2,
      clearValue,
    } = this.state;
    const errorText = errorCode ? ERROR_MESSAGES_ES[errorCode] : '';

    return (
      <CalendarBoxContainer
        // multiple={!!secondValue}
        ref={this.toggleContainer}
        className={`${className} ${showCalendar ? 'show-calendar' : ''}
        ${disabled ? 'disabled' : ''} ${errorCode ? 'error' : ''} ${disableWrite ? 'disable-write' : ''}
        ${value !== '' ? 'complete' : ''} ${required ? 'required' : ''} ${rangeSelect ? 'range' : ''}`}
      >
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText>{labelText}</LabelText>
          </div>

          {withTooltip && (
            <div
              className="input-box-topbar-icon"
              onClick={() => this.setState((prevState: CalendarBoxState) => ({ showTooltip: !prevState.showTooltip }))}
            >
              <img src={disabled ? images.infoSvg : images.infoSvg} />
              <div className={'input-box-tooltip '}>
                <TooltipBox active={showTooltip} tooltipTitle={tooltipTitle} tooltipText={tooltipText} />
              </div>
            </div>
          )}
        </div>
        <div className="input-box-main">
          {!multiSelect && !disableWrite ? (
            <div className="input-box-main-field" onClick={() => this.onToggle(!showCalendar)}>
              <Cleave
                className="calendar-box-fix-position"
                placeholder={placeholder || ''}
                value={focus || valueInput ? valueInput : value}
                options={{ date: true }}
                disabled={!!allowedDates}
                onChange={(ev: any) => {
                  this.setState({ changeInput: true, valueInput: ev.target.value });
                }}
                onFocus={(ev: any) => {
                  this.setState({ focus: true });
                  onFocus && onFocus(ev);
                }}
                onBlur={() => {
                  this.setState({ focus: false });
                }}
                onKeyDown={(ev: any) => {
                  // TAB
                  if (ev.keyCode === 9) {
                    this.onToggle(!showCalendar);
                  }
                }}
              />
              {secondValue && (
                <>
                  {'-'}
                  <Cleave
                    placeholder={placeholder || ''}
                    value={focus2 || valueInput2 ? valueInput2 : secondValue}
                    options={{ date: true }}
                    onChange={(ev: any) => this.setState({ changeInput: true, valueInput2: ev.target.value })}
                    onFocus={(ev: any) => {
                      this.setState({ focus2: true });
                    }}
                    onBlur={() => this.setState({ focus2: false })}
                    onKeyDown={(ev: any) => {
                      // TAB
                      if (ev.keyCode === 9) {
                        this.onToggle(!showCalendar);
                      }
                    }}
                  />
                </>
              )}
            </div>
          ) : disableWrite ? (
            <div className="input-box-main-field" onClick={() => this.onToggle(!showCalendar)}>
              {initialValue && <React.Fragment>{initialValue}</React.Fragment>}
            </div>
          ) : (
            <div className="input-box-main-field" onClick={() => this.onToggle(!showCalendar)}>
              {multiValues &&
                multiValues.map((multiValue, index) => (
                  <React.Fragment key={`${multiValue} ${index}`}>
                    {dateFns.format(new Date(multiValue), 'dd/MM/yyyy')}
                    {index !== multiValues.length - 1 && ', '}
                  </React.Fragment>
                ))}
            </div>
          )}
          {!disabled && (valueInput || value) && value !== 'Invalid date' && (
            <div className="input-box-icon-clear" onClick={() => this.onToggleCalendar(false, '', '')}>
              <img src={images.closeSvg} />
            </div>
          )}
          <div className="input-box-icon" onClick={() => this.onToggle(!showCalendar)}>
            <img src={disabled ? images.calendarDisableSvg : icon} />
          </div>
        </div>
        <div className="calendar-popup">
          <CalendarItem
            forbidPastDates={forbidPastDates}
            forbidFutureDates={forbidFutureDates}
            allowedDates={allowedDates}
            minAge={minAge}
            maxAge={maxAge}
            maxYear={maxYear}
            minYear={minYear}
            initialValue={value}
            rangeSelect={rangeSelect}
            multiSelect={multiSelect}
            clearValue={clearValue}
            onSelect={(value, closeCalendar, secondValue) => {
              this.onToggleCalendar(!closeCalendar, value, secondValue);
            }}
            onChangeMulti={(values: Date[]) => {
              this.setState({ multiValues: values, clearValue: false }, () => {
                if (onChangeMulti) onChangeMulti(values);
              });
            }}
            isOutsideRange={isOutsideRange}
          />
        </div>
        {adviceText && (
          <div className="input-box-advice">
            <InfoText>{adviceText}</InfoText>
          </div>
        )}
        <div className="input-box-error">{errorCode && <InfoText>{errorText}</InfoText>}</div>
      </CalendarBoxContainer>
    );
  }
}

export default CalendarBox;
