import React from 'react';
import { ModalEditProfileStyle } from './modal-edit-profile-style';
import ModalContainer from '../../../components/modals/modal-container';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import MainButton from '../../../components/main-button';
import { images } from '../../../assets/images';
import { FormGrid, FormGridRow, FormGridItem } from '../../main-layout-style';
import InputBox from '../../../components/input-box';
import { uploadUsers, uploadUsersFormData, deletePicture } from '../../../services/users';
import InputButtonFiles from '../../../components/input-button-file/input-button-file';
import { AttachedFile } from '../../../types/file';
import FileUtils from '../../../utils/files';

interface ModalEditProfileProps {
  showModal: boolean;
  onCancel: () => void;
  onAccept: () => void;
  user: any;
}
interface ModalEditProfileState {
  user: any;
  file: AttachedFile[];
  fileUrl: string;
}

export default class ModalEditProfile extends React.Component<ModalEditProfileProps, ModalEditProfileState> {
  readonly state: ModalEditProfileState;

  constructor(props: ModalEditProfileProps) {
    super(props);
    this.state = {
      user: {
        name: {
          value: props.user.name || '',
          error: false,
          errorCode: '',
          required: true,
        },
        surname: {
          value: props.user.surname || '',
          error: false,
          errorCode: '',
          required: true,
        },
      },
      file: [],
      fileUrl: '',
    };
  }

  updateUser = async (data: any) => {
    const { onAccept, user } = this.props;
    const { file } = this.state;
    try {
      const response: any = await uploadUsers(user.id, data);
      if (file[0]) {
        await this.handlePicture(file[0].data);
      }
      onAccept();
    } catch (err) {
      console.log(err);
    }
  };

  handlePicture = async (picture: any) => {
    const { user } = this.props;
    try {
      const data = new FormData();
      data.set('picture', picture);
      const response: any = await uploadUsersFormData(user.id, data);
    } catch (err) {}
  };

  handleDeletePicture = async () => {
    const { onAccept, user } = this.props;
    await deletePicture(user.id);
    onAccept();
  };

  changeValue = (value: string, key: string, subKey?: string) => {
    const { user } = this.state;
    if (!subKey) {
      user[key].value = value;
    } else {
      user[key][subKey].value = value;
    }

    this.setState({ user });
  };

  handleChangePicture = async (file: AttachedFile[]) => {
    if (file[0]) {
      const fileUrl = await FileUtils.readFileUrlAsync(file[0].data);
      this.setState({ file, fileUrl });
    } else {
      this.setState({ file: [], fileUrl: '' });
    }
  };

  handleAccept = () => {
    const { user } = this.state;

    let isOk = true;
    Object.keys(user).forEach((k: string) => {
      if (user[k].required && user[k].value === '') {
        user[k].error = true;
        user[k].errorCode = 'required';
        isOk = false;
      } else {
        user[k].error = false;
        user[k].errorCode = '';
      }
    });
    this.setState({ user });
    if (isOk) {
      const data: any = {};
      Object.keys(user).forEach((k: string) => {
        data[k] = user[k].value;
      });

      this.updateUser(data);
    }
  };

  render() {
    const { showModal, onCancel, user: userProps } = this.props;
    const { user, fileUrl } = this.state;
    return (
      <ModalContainer title={``} active={showModal} modalClose={() => onCancel()}>
        <ModalEditProfileStyle>
          <div className="modal-edit-profile-title">
            <SubheadDestacadoText>Editar perfil</SubheadDestacadoText>
          </div>
          <div className="modal-edit-profile-avatar">
            <div className="modal-edit-profile-avatar__img">
              <img src={fileUrl || userProps.picture || images.avatar} alt="" />
            </div>
            <div className="modal-edit-profile-avatar__buttons">
              <div className="modal-edit-profile-avatar__buttons__item">
                <MainButton text="Subir imagen" onClick={() => {}} type="secondary" />
                <div className="modal-edit-profile-avatar__buttons__item__input">
                  <InputButtonFiles
                    multiple={false}
                    onChange={(file: AttachedFile[]) => this.handleChangePicture(file)}
                  />
                </div>
              </div>
              {!!userProps.picture && (
                <div className="modal-edit-profile-avatar__buttons__item">
                  <MainButton text="Eliminar" onClick={() => this.handleDeletePicture()} type="secondary-red" />
                </div>
              )}
            </div>
          </div>
          <div className="modal-edit-profile-form">
            <FormGrid>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder="Nombre"
                    className="rounded"
                    labelText=""
                    type="text"
                    required={user.name.required}
                    errorCode={user.name.errorCode}
                    value={user.name.value}
                    onChange={(value: string) => this.changeValue(value, 'name')}
                  />
                </FormGridItem>
              </FormGridRow>

              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder="Apellidos"
                    className="rounded"
                    labelText=""
                    type="text"
                    required={user.surname.required}
                    errorCode={user.surname.errorCode}
                    value={user.surname.value}
                    onChange={(value: string) => this.changeValue(value, 'surname')}
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>
          </div>
          <div className="modal-edit-profile-button">
            <MainButton onClick={() => this.handleAccept()} text="Guardar cambios" type="primary" />
          </div>
        </ModalEditProfileStyle>
      </ModalContainer>
    );
  }
}
