import React from 'react';
import { HeaderMainContainer } from './header-main-style';
import { images } from '../../assets/images';
import MainButton from '../main-button';
import TitleText from '../text/title-text/title-text';
import SingleButton from '../single-button/single-button';
import { withRouter, RouteComponentProps } from 'react-router';
import LabelText from '../text/label-text/label';
import InputBox from '../input-box';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IconButton {
  img: string | any;
  onClick?: Function;
}
interface PrimaryButton {
  img?: string | any;
  text: string;
  onClick?: Function;
  type?: string;
  className?: string;
  disabled?: boolean;
}

interface ISubtitle {
  type: string;
  text: string;
}

interface HeaderMainProps extends RouteComponentProps, WithTranslation {
  goBack?: boolean;
  title: string;
  icons?: IconButton[];
  className?: string;
  mainButton?: PrimaryButton;
  secondaryButton?: PrimaryButton;
  secondaryButtonRed?: PrimaryButton;
  secondaryButtonBorderRed?: PrimaryButton;
  subtitle?: ISubtitle;
  withSearch?: boolean;
  handleSearch?: Function;
  onClickSearch?: Function;
  placeholderSearch?: string;
  dropdown?: boolean;
  dropdownChildren?: any;
  disabled?: boolean;
  onCloseSearch?: () => void;
  searchValue?: string;
  searched?: string;
  onPressEnter?: () => void;
  resultCount?: number;
  notifications?: string;
  t: any
}
interface HeaderMainState {
  search: boolean;
  showDropdown: boolean;
  searched: boolean;
  localSearchValue?: string;
}

class HeaderMain extends React.Component<HeaderMainProps, HeaderMainState> {
  toggleDropdownRef: any;
  constructor(props: HeaderMainProps) {
    super(props);
    this.state = {
      search: false,
      showDropdown: true,
      searched: false,
    };

    this.toggleDropdownRef = React.createRef();
  }

  onKeyDownHandler = (e: any) => {
    if (this.props.onPressEnter && !this.props.disabled && e.key === 'Enter') {
      this.setState({ showDropdown: false }, () => {
        this.props.onPressEnter && this.props.onPressEnter();
      });
    }
  };

  onClickOutsideHandler = (event: Event) => {
    if (this.state.showDropdown && !this.toggleDropdownRef.current.contains(event.target)) {
      this.setState({ showDropdown: false });
    }
  };

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideHandler);
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  componentDidUpdate(prevProps: HeaderMainProps) {
    const { dropdownChildren, searched, searchValue } = this.props;
    if (
      prevProps.dropdownChildren !== dropdownChildren &&
      !this.state.showDropdown
      //  &&
      // (!searched || (searched === prevProps.searched && searchValue !== searched))
    ) {
      this.setState({ showDropdown: true });
    }
  }

  returnNotifications(){
    window.location.href = '/centres/94/notifications';
  }

  render() {
    const {
      goBack,
      title,
      icons,
      mainButton,
      secondaryButton,
      className,
      subtitle,
      secondaryButtonRed,
      secondaryButtonBorderRed,
      withSearch,
      handleSearch,
      onClickSearch,
      placeholderSearch,
      dropdown,
      dropdownChildren,
      disabled,
      onCloseSearch,
      searchValue,
      resultCount,
      notifications,
      t
    } = this.props;

    const { search, showDropdown, localSearchValue } = this.state;


    return (
      <HeaderMainContainer className={className} ref={this.toggleDropdownRef}>
        <div className={'header-main-container'}>
          {!search ? (
            <>
              <div className="header-main-left">
                {notifications != '' ?
                  <div className="header-main-left__arrow" onClick={() => this.props.history.goBack()}>
                    <img src={images.arrowLeftSvg} alt="" />
                  </div>
                :
                <div className="header-main-left__arrow" onClick={() => this.returnNotifications()}>
                    <img src={images.arrowLeftSvg} alt="" />
                  </div>
              }
                <div className="header-main-left__title">
                  <TitleText>{title}</TitleText>
                  {subtitle && subtitle.text && (
                    <div className={`header-main-left-subtitle ${subtitle.type}`}>
                      <LabelText>{subtitle.text}</LabelText>
                    </div>
                  )}
                </div>
              </div>

              <div className="header-main-right">
                <div className="header-main-right-icons">
                  {withSearch && (
                    <div className="header-main-right-icons__item">
                      <SingleButton
                        className="secondary"
                        icon={images.searchSvg}
                        onClick={() => {
                          if (onClickSearch) onClickSearch();
                          if (!disabled) this.setState({ search: true });
                        }}
                      />
                    </div>
                  )}
                  {icons && icons.length > 0 && icons.map((icon: IconButton, key) => (
                      <div key={key} className="header-main-right-icons__item">
                        <SingleButton
                          className="secondary"
                          icon={icon.img}
                          onClick={() => {
                            if (icon.onClick) {
                              icon.onClick();
                            }
                          }}
                        />
                      </div>
                    ))}
                </div>
                {mainButton && (
                  <div className="header-main-right-button">
                    <MainButton
                      iconImg={mainButton.img}
                      disabled={mainButton.disabled}
                      text={mainButton.text}
                      type={mainButton.type || 'primary'}
                      className={mainButton.className ? mainButton.className : ''}
                      onClick={() => {
                        if (mainButton.onClick) {
                          mainButton.onClick();
                        }
                      }}
                    />
                  </div>
                )}
                {secondaryButton && (
                  <div className="header-main-right-button secondary">
                    <MainButton
                      iconImg={secondaryButton.img}
                      text={secondaryButton.text}
                      type={secondaryButton.type ? secondaryButton.type : 'secondary'}
                      className={secondaryButton.className ? secondaryButton.className : ''}
                      onClick={() => {
                        if (secondaryButton.onClick) {
                          secondaryButton.onClick();
                        }
                      }}
                    />
                  </div>
                )}
                {secondaryButtonRed && (
                  <div className="header-main-right-button secondary">
                    <MainButton
                      iconImg={secondaryButtonRed.img}
                      text={secondaryButtonRed.text}
                      type="secondary-red"
                      className={secondaryButtonRed.className ? secondaryButtonRed.className : ''}
                      onClick={() => {
                        if (secondaryButtonRed.onClick) {
                          secondaryButtonRed.onClick();
                        }
                      }}
                    />
                  </div>
                )}
                {secondaryButtonBorderRed && (
                  <div className="header-main-right-button secondary">
                    <MainButton
                      iconImg={secondaryButtonBorderRed.img}
                      text={secondaryButtonBorderRed.text}
                      type="secondary-border-red"
                      className={secondaryButtonBorderRed.className ? secondaryButtonBorderRed.className : ''}
                      onClick={() => {
                        if (secondaryButtonBorderRed.onClick) {
                          secondaryButtonBorderRed.onClick();
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
              <div className="header-main-search">
                <div
                  className="header-main-close"
                  onClick={() => {
                    if (onCloseSearch) onCloseSearch();
                    this.setState({ search: false });
                  }}
                >
                  <img src={images.closeSvg} alt="" />
                </div>

                <InputBox
                  placeholder={placeholderSearch ? placeholderSearch : t('components:general.name')}
                  className="search"
                  type="text"
                  value={searchValue ? searchValue : ''}
                  // errorCode={errorFields['name']}
                  labelText=""
                  onChange={value => {
                    if (handleSearch) {
                      handleSearch(value);
                      this.setState({ searched: true, localSearchValue: value });
                    }
                  }}
                  onKeyDown={this.onKeyDownHandler}
                />

                {dropdown && (
                  <div
                    className={classNames('header-main-search__dropdown', {
                      'header-main-search__dropdown--visible': dropdownChildren && showDropdown,
                    })}
                  >
                    {dropdownChildren}
                  </div>
                )}
              </div>
            )}

        </div>
        {resultCount && resultCount > 0 && this.state.searched && localSearchValue && search ? (
          <div className="header-main-advice">
            {resultCount === 1 ? `${resultCount} resultado` : `${resultCount} resultados`}
          </div>
        ) : ('')}

      </HeaderMainContainer>
    );
  }
}

export default withTranslation(['components'])(withRouter(HeaderMain));
