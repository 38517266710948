import * as React from 'react';

import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { images } from '../../../assets/images/index';
import { getOrganization, getOrganizationCentres } from '../../../services/collaborator';
import { copyOf, createFilterRequest } from '../../../utils/other';
import { CERTIFIER_CENTRES_PER_PAGE } from '../../../constants/data';

import ModalInviteCenter from './modal-invite-center/modal-invite-center';
import ModalConfirmation from '../../../components/modals/modal-confimation/modal-confirmation';

import Services from '../../../services/services';
import moment, { Moment, max } from 'moment';

interface RouteParams {
  id: string;
}
interface ICertifierCenterProps extends RouteComponentProps<RouteParams>, WithTranslation { }

interface ICertifierCenterState {
  adActive: boolean;
  certifierId?: string;
  modal: string;
  ads: any;
  activeFilters: boolean;
  centres: any[];
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
  filterSearch: string;
}

class CertifierCenter extends React.Component<ICertifierCenterProps, ICertifierCenterState> {
  constructor(props: ICertifierCenterProps) {
    super(props);

    this.state = {
      adActive: false,
      modal: '',
      ads: {},
      activeFilters: false,
      centres: [],
      total: 0,
      page: 1,
      totalPages: 0,
      skipPage: 0,
      filterSearch: '',
    };
  }

  componentDidMount() {
    this.getCentres();
  }

  async getCentres() {
    const { filterSearch, skipPage } = this.state;
    const filters = {
      name: { value: filterSearch },
    };
    const params = {
      filter: {
        where: createFilterRequest(filters),
        relations: ['activities', 'certifiers', 'certifiers.certifier'],
        skip: skipPage,
        limit: CERTIFIER_CENTRES_PER_PAGE,
      },
    };
    const organizationId = localStorage.getItem('organizationId');
    if (organizationId) {
      const resp = await getOrganizationCentres(organizationId, params);
      const centres = resp.data.data;
      const totalPages: number = Math.ceil(resp.data.count / CERTIFIER_CENTRES_PER_PAGE);
      this.setState({ centres, totalPages, total: resp.data.count });
    }
  }

  getTableRows() {
    const { centres } = this.state;
    const tableRows: any[] = [];
    centres.forEach((centre: any) => {
      const tableRow = copyOf(this.tableRowTemplate);
      const organizationId = localStorage.getItem('organizationId');
      const centreCourses = centre.activities.filter((activity: any) => !!activity.courseInfo);
      const certifierId = centre.certifiers.filter(
        (certifier: any) => certifier.certifier && certifier.certifier.id === parseInt(organizationId || '0', undefined)
      )[0].ownId;
      tableRow.id.value = certifierId || '-';
      tableRow.name.value = centre.name;
      tableRow.location.value = `${centre.town}, ${centre.state}`;
      // tableRow.certificatedDivers.value = '0';
      tableRow.numberCourses.value = centreCourses.length;
      if (centreCourses.length > 0) {
        tableRow.last.value = centreCourses
          .reduce((maxDate: Moment, activity: any) => {
            const date = moment(activity.date);
            if (date.isAfter(maxDate)) return date;
            return maxDate;
          }, moment(0))
          .format('DD/MM/YYYY');
      }
      tableRow.detail.link = `centres/${centre.id}`;
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CERTIFIER_CENTRES_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ skipPage: skip, page }, () => {
        this.getCentres();
      });
    }
  }

  searchTimeout: any;
  handleSearch(value: string | null) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState({ filterSearch: value || '', page: 1, skipPage: 0 }, () => {
        this.getCentres();
      });
    }, 500);
  }

  render() {
    const { activeFilters, modal, totalPages, page, total } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderMain
          title={t('modalMessages:mydivecenters')}
          withSearch={true}
          resultCount={total}
          handleSearch={(value: string) => {
            this.handleSearch(value);
          }}
          mainButton={{
            text: t('buttons:invite'),
            onClick: () => this.setState({ modal: 'invite-center' }),
          }}
        />
        <MainLayoutSection>
          {activeFilters && (
            <div className={`ads-detail-table-filters${activeFilters ? ' active' : ''}`}>{/* To Do ADD Filters */}</div>
          )}

          <div className="ads-detail-table__content">
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout
                  columns={this.tableColumns}
                  rows={this.getTableRows()}
                  rowKeys={this.rowKeys}
                  onOrder={() => { }}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                </div>
              )}
            </MainLayoutTableContainer>
          </div>
        </MainLayoutSection>
        {modal === 'invite-center' && ( // ¿es necesario añadir esta variable?:  && certifierId
          <ModalInviteCenter
            // certifierId={certifierId}
            title={t('modalMessages:invcent2')}
            showModal={modal === 'invite-center'}
            onCancel={() => this.setState({ modal: '' })}
            onSuccess={() => {
              this.setState({ modal: '' });
            }}
          />
        )}
      </>
    );
  }

  tableColumns = [
    {
      text: 'id',
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalmessages:name'),
      filter: false,
      ascendent: true,
    },
    {
      text:  this.props.t('modalmessages:place').toLowerCase(),
      filter: false,
      ascendent: true,
    },
    // {
    //   text: 'BUCEADORES CERT.',
    //   filter: false,
    //   ascendent: true,
    // },
    {
      text:  this.props.t('modalmessages:ncursos'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('modalmessages:lastcourse'),
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    id: {
      key: 'highlighted-body',
      value: '29385329',
    },
    name: {
      key: '',
      value: 'Manuel Javier Hernández Valverde - Gil',
    },
    location: {
      key: '',
      value: 'Murcia',
    },
    // certificatedDivers: {
    //   key: '',
    //   value: '148',
    // },
    numberCourses: {
      key: '',
      value: '123',
    },
    last: {
      key: '',
      value: '09/08/2019',
    },
    detail: {
      key: 'detail',
      link: '',
    },
  };

  rowKeys = ['id', 'name', 'location', /*'certificatedDivers', */ 'numberCourses', 'last', 'detail'];
}
export default withTranslation(['intro', 'buttons','modalMessages'])(withRouter(CertifierCenter));
