import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const BillingInformationStyle = styled.div`
  .billing-information {
    display: grid;
    grid-template-columns: 1fr 183px;
    justify-content: space-between;
    align-items: end;
    grid-template-areas:
      'header saveBtn'
      'payment-gateway payment-gateway'
      'bill-data bill-data';

    .events-disabled {
      pointer-events: none;
    }

    &-btn {
      border-radius: 5px;

      &--save-btn {
        grid-area: saveBtn;
        align-self: center;
      }

      &-title-btn {
        grid-area: title-btn;
        align-self: center;
      }
    }

    &-section {
      &:not(:last-child) {
        border-bottom: 1px solid #bcc5d7;
        padding-bottom: 60px;
      }

      &--payment-gateway {
        grid-area: payment-gateway;
        display: grid;
        grid-template-columns: 1fr 192px;
        grid-template-areas:
          'title title-btn'
          'text text';
        margin-bottom: 66px;
      }
      &--bill-data {
        grid-area: bill-data;
        display: grid;
        grid-template-columns: 1fr 105px;
        grid-template-areas:
          'title title-btn'
          'text text'
          'vat .';
      }
    }

    &-header {
      width: auto;
      grid-area: header;
      margin-bottom: 66px;
      & > div {
        padding: 0;
        min-height: unset;
      }
    }

    &-title {
      padding-bottom: 25px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      grid-area: title;
      align-self: center;
      display: flex;
      align-items: end;
    }

    &-connected {
      text-transform: uppercase;
      font-size: 10px;
      line-height: 14px;
      padding: 5px 8px 7px 8px;
      border-radius: 3px;
      margin-left: 20px;
      color: ${colors['CO-001/100']};
      background-color: ${colors['CO-001/15']};
    }

    &-text {
      margin-top: 32px;
      font-weight: 300;
      font-size: 18px;
      line-height: 26px;

      grid-area: text;

      &__paragraph:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &-vat {
      margin-top: 38px;

      display: flex;
      flex-direction: column;
      aling-items: flex-start;

      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: ${colors['NE-002/100']};
        text-transform: uppercase;
      }

      &__details {
        margin-top: 33px;

        display: grid;
        grid-template-columns: repeat(2, minmax(245px, max-content));
        gap: 20px;
      }
      &__detail {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      &__detail-value {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
      }

      &__reject-certificate {
        align-self: flex-start;
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: ${colors['CO-003/100']};
        cursor: pointer;
        & p:hover {
          color: ${colors['CO-003/75']};
        }
      }
      &__download-certificate {
        align-self: flex-start;

        margin-top: 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: ${colors['PR-001/100']};
        cursor: pointer;
        &:hover {
          color: ${colors['PR-001/75']};
        }
      }
    }
  }
  .save-button {
    display: flex;
    width: 15%;
  }
`;
