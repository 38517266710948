import styled from 'styled-components';

export const ModalGenericFileStyle = styled.div`
  width: 440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 32px;
  padding-top: 48px;

  .modal-generic-file-title  {
    margin-bottom: 12px;
  }
  .modal-generic-file-subtitle {
    margin-bottom: 32px;
    color: #bcc5d7;
  }
  .modal-generic-file-input  {
    margin-bottom: 32px;
  }

  .modal-generic-file-container {
    margin-bottom: 32px;

    .card-file {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: #f8fafd;
      border: 1px solid #f8fafd;
      border-radius: 5px;
      padding: 20px;
      oveflow: hidden;

      .card-file__type {
        max-width: 10%;
      }

      .card-file__text {
        max-width: 70%;
        overflow: hidden;
        &__file {
          color: #007d91;
          font-weight: 500;
        }
        &__date {
          color: #007d91;
          font-weight: 300;
        }
      }

      .card-file__delete {
        max-width: 10%;
        img {
          width: 24px;
        }
      }
    }
  }
`;
