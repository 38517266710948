import services from './services';

export const getIncidences = async (params: any) => {
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'incidences',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getOneIncidences = (incidenceId: string, params: any) => {
  const endpoint = `incidences/${incidenceId}`;
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadIncidencesFormData = (incidenceId: string, data: FormData) => {
  const endpoint = `incidences/${incidenceId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const uploadIncidences = (incidenceId: string, data: any) => {
  const endpoint = `incidences/${incidenceId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteIncidences = (incidenceId: string) => {
  const endpoint = `incidences/${incidenceId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createIncidences = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'incidences',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const solveIncidence = (incidenceId: string, data: any) => {
  const endpoint = `incidences/${incidenceId}/solve`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const closeIncidence = (incidenceId: string, data: any) => {
  const endpoint = `incidences/${incidenceId}/close`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const reopenIncidence = (incidenceId: string, data: any) => {
  const endpoint = `incidences/${incidenceId}/re-open`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

