import React from 'react';
import { ModalCancelationPolicyStyle } from './modal-cancelation-policy-style';
import ModalContainer from '../../../../components/modals/modal-container';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../../../../components/text/body-text/body-text';
import { FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import InputBox from '../../../../components/input-box';
import MainButton from '../../../../components/main-button';
import SelectBox from '../../../../components/select-box';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import DicotomicButton from '../../../../components/dicotomic-button';
import InputArea from '../../../../components/input-area/input-area';
import InfoText from '../../../../components/text/info-text/info-text';
import { uploadCancellationPolicies } from '../../../../services/cancellation-policies';
import { POLICIES_DROPDOWN, POLICIES_KEY_VALUE } from '../../../../constants/policies';
import { editCentre } from '../../../../services/centre';
import { ICentreCancellation } from '../../../../models/centre-forms';

interface ModalCancelationPolicyProps {
  centreId?: string;
  centreCancellation?: ICentreCancellation;
  policies: any[];
  showModal: boolean;
  onCancel: () => void;
  onAccept: Function;
}
interface ModalCancelationPolicyState {
  policy: any;
  languages: any[];
  selectedLanguage: string;
}

export default class ModalCancelationPolicy extends React.Component<
  ModalCancelationPolicyProps,
  ModalCancelationPolicyState
> {
  readonly state: ModalCancelationPolicyState;
  constructor(props: ModalCancelationPolicyProps) {
    super(props);
    this.state = {
      languages: [
        { value: 'es', label: 'ES' },
        { value: 'pt', label: 'PT' },
        { value: 'en', label: 'EN' },
        { value: 'fr', label: 'FR' },
        { value: 'it', label: 'IT' },
        { value: 'de', label: 'DE' },
      ],
      selectedLanguage: 'es',
      policy: {
        name: {
          value: props.policies[0].name,
          error: false,
          errorCode: '',
          required: true,
        },
        type: {
          value: props.policies[0].id.toString(),
          error: false,
          errorCode: '',
          required: true,
        },
        '>72': {
          value: props.policies[0]['>72'],
          error: false,
          errorCode: '',
          required: true,
          type: 'number',
        },
        '48-72': {
          value: props.policies[0]['48-72'],
          error: false,
          errorCode: '',
          required: true,
          type: 'number',
        },
        '<48': {
          value: props.policies[0]['<48'],
          error: false,
          errorCode: '',
          required: true,
          type: 'number',
        },
        active: {
          value: !!props.policies[0].active,
          error: false,
          errorCode: '',
          required: true,
        },
        description: {
          es: {
            value: props.policies[0].description ? props.policies[0].description.es : '',
            error: false,
            errorCode: '',
            required: true,
          },
          pt: {
            value: props.policies[0].description ? props.policies[0].description.pt : '',
            error: false,
            errorCode: '',
            required: true,
          },
          en: {
            value: props.policies[0].description ? props.policies[0].description.en : '',
            error: false,
            errorCode: '',
            required: true,
          },
          fr: {
            value: props.policies[0].description ? props.policies[0].description.fr : '',
            error: false,
            errorCode: '',
            required: true,
          },
          it: {
            value: props.policies[0].description ? props.policies[0].description.it : '',
            error: false,
            errorCode: '',
            required: true,
          },
          de: {
            value: props.policies[0].description ? props.policies[0].description.de : '',
            error: false,
            errorCode: '',
            required: true,
          },
        },
      },
    };
  }

  changeValue = (value: any, key: string, subKey?: string) => {
    const { policy } = this.state;
    if (!subKey) {
      policy[key].value = value;
    } else {
      policy[key][subKey].value = value;
    }

    this.setState({ policy });
  };

  changePolicy = (id: number) => {
    const props = this.props;

    const index = props.policies.findIndex(policy => policy.id === id);

    const policy = {
      name: {
        value: props.policies[index].name,
        error: false,
        errorCode: '',
        required: true,
      },
      type: {
        value: props.policies[index].id.toString(),
        error: false,
        errorCode: '',
        required: true,
      },
      '>72': {
        value: props.policies[index]['>72'],
        error: false,
        errorCode: '',
        required: true,
        type: 'number',
      },
      '48-72': {
        value: props.policies[index]['48-72'],
        error: false,
        errorCode: '',
        required: true,
        type: 'number',
      },
      '<48': {
        value: props.policies[index]['<48'],
        error: false,
        errorCode: '',
        required: true,
        type: 'number',
      },
      active: {
        value: !!props.policies[index].active,
        error: false,
        errorCode: '',
        required: true,
      },
      description: {
        es: {
          value: props.policies[index].description ? props.policies[index].description.es : '',
          error: false,
          errorCode: '',
          required: true,
        },
        pt: {
          value: props.policies[index].description ? props.policies[index].description.pt : '',
          error: false,
          errorCode: '',
          required: true,
        },
        en: {
          value: props.policies[index].description ? props.policies[index].description.en : '',
          error: false,
          errorCode: '',
          required: true,
        },
        fr: {
          value: props.policies[index].description ? props.policies[index].description.fr : '',
          error: false,
          errorCode: '',
          required: true,
        },
        it: {
          value: props.policies[index].description ? props.policies[index].description.it : '',
          error: false,
          errorCode: '',
          required: true,
        },
        de: {
          value: props.policies[index].description ? props.policies[index].description.de : '',
          error: false,
          errorCode: '',
          required: true,
        },
      },
    };
    this.setState({ policy });
  };

  handleValidateForm = () => {
    const { policy } = this.state;
    const { onAccept, centreId } = this.props;
    let isOk = true;
    Object.keys(policy).forEach((k: string) => {
      if (policy[k].required && policy[k].value === '') {
        policy[k].error = true;
        policy[k].errorCode = 'required';
        isOk = false;
      } else {
        policy[k].error = false;
        policy[k].errorCode = '';
      }
    });
    if (centreId) isOk = true;
    this.setState({ policy });
    if (isOk) {
      this.updatePolicy();
    }
  };

  updatePolicy = async () => {
    const { centreId, centreCancellation, onAccept } = this.props;
    const { policy } = this.state;
    const description: any = {};
    Object.keys(policy.description).forEach((k: string) => {
      description[k] = policy.description[k].value;
    });
    const data: any = {};
    Object.keys(policy).forEach((k: string) => {
      if (policy[k].type === 'number') {
        data[k] = parseFloat(policy[k].value);
      } else if (k === 'description') {
        data[k] = description;
      } else {
        data[k] = policy[k].value;
      }
    });

    try {
      if (centreId) {
        const centreData: any = centreCancellation;
        centreData[data.type] = data.active;
        await editCentre(centreId, { cancellation: centreData });
      } else {
        await uploadCancellationPolicies(policy.type.value.toString(), data);
      }
      onAccept();
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { showModal, onCancel, centreId, policies } = this.props;
    const { policy, languages, selectedLanguage } = this.state;
    return (
      <ModalContainer title={``} active={showModal} modalClose={() => onCancel()}>
        <ModalCancelationPolicyStyle>
          <div className="modal-cancelation-policy-title">
            <SubheadDestacadoText>Administrar políticas de cancelación</SubheadDestacadoText>
          </div>
          <div className="modal-cancelation-policy-text">
            <BodyText>
              Selecciona la política que quieras modificar, activar o desactivar para que todos los usuarios de la
              plataforma puedan disponer de ella.
            </BodyText>
          </div>
          <div className="modal-cancelation-policy-type-select">
            <SelectBox
              labelText="política de cancelación"
              placeholder="Tipo de políticas"
              className="rounded-title"
              optionsText={policies.map((policy: any) => ({
                value: policy.id.toString(),
                label: POLICIES_KEY_VALUE[policy.type],
              }))}
              suggestedOptionsText={[]}
              optionKey={'value'}
              optionValue={'label'}
              required={policy.type.required}
              errorCode={policy.type.errorCode}
              initialValue={policy.type.value}
              onChange={(value: string) => this.changePolicy(parseFloat(value))}
            />
          </div>

          <div className="modal-cancelation-policy-top">
            <div className="modal-cancelation-policy-top-row">
              <div className="modal-cancelation-policy-top-select">
                <InputBox
                  placeholder="Nombre"
                  className="rounded-title"
                  type="text"
                  labelText="Nombre"
                  required={true}
                  errorCode={policy.name.errorCode}
                  value={policy.name.value}
                  onChange={(value: string) => this.changeValue(value, 'name')}
                />
              </div>
              <div className="modal-cancelation-policy-top-row__dicotomic">
                <DicotomicButton
                  className={'modal'}
                  initialValue={policy.active.value ? 'on' : 'off'}
                  onChange={(value: string) => this.changeValue(value === 'on', 'active')}
                  options={[
                    { code: 'on', label: 'Activar' },
                    { code: 'off', label: 'Desactivar' },
                  ]}
                />
              </div>
            </div>
          </div>
          {!centreId && (
            <>
              <div className="manage-dive-levels-line" />

              <div className="modal-cancelation-policy-form">
                <div className={'modal-cancelation-policy-languages'}>
                  {languages.map((k: any, index: number) => (
                    <div className={'modal-cancelation-policy-languages__item'} key={index}>
                      <MainButton
                        onClick={() => this.setState({ selectedLanguage: k.value })}
                        text={k.label}
                        type={
                          selectedLanguage === k.value
                            ? 'primary'
                            : !policy.description[k.value].error
                            ? 'secondary'
                            : 'secondary-red'
                        }
                      />
                    </div>
                  ))}
                </div>
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputArea
                        className="rounded"
                        name={selectedLanguage}
                        placeholder={`Descripción ${selectedLanguage}`}
                        required={policy.description[selectedLanguage].required}
                        errorCode={policy.description[selectedLanguage].errorCode}
                        error={policy.description[selectedLanguage].error}
                        value={policy.description[selectedLanguage].value}
                        onChange={(e: any) => this.changeValue(e.target.value, 'description', selectedLanguage)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
              </div>

              <div className="modal-cancelation-policy-tableform">
                <div className="modal-cancelation-policy-tableform-title">
                  <div className="modal-cancelation-policy-tableform-title__term">
                    <BodyDestacadoText>PLAZO DE CANCELACIÓN</BodyDestacadoText>
                  </div>
                  <div className="modal-cancelation-policy-tableform-title__penalty">
                    <BodyDestacadoText>Penalización</BodyDestacadoText>
                  </div>
                </div>
                <div className="modal-cancelation-policy-tableform-row">
                  <div className="modal-cancelation-policy-tableform-row__text">
                    <InfoText>Más de 72 horas antes del servicio</InfoText>
                  </div>
                  <div className="modal-cancelation-policy-tableform-row__input">
                    <InputBox
                      placeholder="%"
                      labelText=""
                      className="rounded"
                      type="number"
                      required={policy['>72'].required}
                      errorCode={policy['>72'].errorCode}
                      value={policy['>72'].value}
                      onChange={(value: string) => this.changeValue(value, '>72')}
                    />
                  </div>
                </div>
                <div className="modal-cancelation-policy-tableform-row">
                  <div className="modal-cancelation-policy-tableform-row__text">
                    <InfoText>Entre 72 y 48 horas antes del servicio</InfoText>
                  </div>
                  <div className="modal-cancelation-policy-tableform-row__input">
                    <InputBox
                      placeholder="%"
                      labelText=""
                      className="rounded"
                      type="number"
                      required={policy['48-72'].required}
                      errorCode={policy['48-72'].errorCode}
                      value={policy['48-72'].value}
                      onChange={(value: string) => this.changeValue(value, '48-72')}
                    />
                  </div>
                </div>
                <div className="modal-cancelation-policy-tableform-row">
                  <div className="modal-cancelation-policy-tableform-row__text">
                    <InfoText>Entre 48 y 24 horas antes del servicio</InfoText>
                  </div>
                  <div className="modal-cancelation-policy-tableform-row__input">
                    <InputBox
                      placeholder="%"
                      labelText=""
                      className="rounded"
                      type="number"
                      required={policy['<48'].required}
                      errorCode={policy['<48'].errorCode}
                      value={policy['<48'].value}
                      onChange={(value: string) => this.changeValue(value, '<48')}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="modal-cancelation-policy-button">
            <MainButton onClick={() => this.handleValidateForm()} text="Guardar" type="primary" />
          </div>
        </ModalCancelationPolicyStyle>
      </ModalContainer>
    );
  }
}
