import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const PlanningDiverStyle = styled.div`
  .planning-diver-disclaimer {
    color: ${colors['NE-002/100']};
    margin-bottom: 28px;
  }
  .planning-diver-select {
    margin-bottom: 40px;
  }

  .planning-diver-subtitle {
    margin-bottom: 14px;
  }
  .planning-diver-check {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 50px;
      }
    }
  }
`;
