import classnames from "classnames";
import Cleave from "cleave.js/react";
import React, { useState } from "react";
import { InputStyle } from "./input-style";
export interface TextAreaProps {
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: any;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  message?: string;
  errorMessage?: string;
  tooltip?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  maxLength?: number;
  value?: string;
  className?: string;
}
const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  const [value, setValue] = useState<string>("");
  const handleChange = (ev: any) => {
    if (
      (props.maxLength && ev.target.value.length <= props.maxLength) ||
      !props.maxLength
    ) {
      if (props.onChange) {
        props.onChange(ev);
      } else if (props.value === undefined) {
        setValue(ev.target.value);
      }
    }
  };
  return (
    <InputStyle
      className={classnames(props.className || "", {
        error: !!props.error,
        disabled: !!props.disabled,
        required: !!props.required
      })}
    >
      <div className="input-top">
        {props.label && (
          <div className="input-top-label">
            <p>{props.label}</p>
          </div>
        )}
        {props.tooltip && (
          <div className="input-top-tooltip">{props.tooltip}</div>
        )}
      </div>
      <div className="input-body">
        <textarea
          name={props.name}
          placeholder={props.placeholder}
          value={props.value || value}
          disabled={props.disabled}
          required={props.required}
          onChange={(ev: any) => handleChange(ev)}
          onFocus={(ev: any) => props.onFocus && props.onFocus(ev)}
          onBlur={(ev: any) => props.onBlur && props.onBlur(ev)}
          onKeyDown={(ev: any) => props.onKeyDown && props.onKeyDown(ev)}
        />
      </div>

      <div className="input-bottom">
        {props.errorMessage && (
          <div className="input-bottom-error">
            <p>{props.errorMessage}</p>
          </div>
        )}
        {props.message && (
          <div className="input-bottom-message">
            <p>{props.message}</p>
          </div>
        )}
      </div>
    </InputStyle>
  );
};

export default TextArea;
