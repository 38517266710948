import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ItemTeamContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .item-team-count  {
    background: ${colors['NE-002/10']};
    border-radius: 3px;
    padding: 9px 4px;
    cursor: pointer;
  }
  .item-team-pop   {
    position: fixed;
    background: ${colors['SE-002/100']};
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 16px 14px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
    background: ${colors['SE-002/100']};
    min-width: 260px;
    &::before  {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 3px;
      position: absolute;
      background: ${colors['SE-002/100']};
      top: calc(50% - 5px);
      transform: rotate(45deg);
      right: -3px;
    }
    &.active {
      visibility: visible;
      opacity: 1;
    }
    &__title  {
      color: white;
      margin-bottom: 4px;
    }
    &__list {
      color: ${colors['NE-002/100']};
    }
  }
  .item-team-veil {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }
`;
