// tslint:disable: max-line-length
interface InterfaceErrorMessage {
  required: string;
  'invalid-format': string;
  'invalid-percent': string;
  'invalid-dni': string;
  'invalid-prefix': string;
  'invalid-phone': string;
  'invalid-email': string;
  'invalid-date': string;
  'invalid-postal-code': string;
  'invalid-password': string;
  'passwords-not-equal': string;
  'should-be-under-14': string;
  'form-error': string;
  'server-error': string;
  'previous-docs': string;
  'insured-duplicate-birthdate': string;
  'invalid-insured-birthdate': string;
  'invalid-taker-birthdate': string;
  'taker-under-18': string;
  'under-18': string;
  'invalid-address': string;
  'budget-not-found': string;
  'budget-without-acts': string;
  [key: string]: string;
}

export const ERROR_MESSAGES_ES: InterfaceErrorMessage = {
  required: 'Campo obligatorio',
  'invalid-format': 'Formato no válido',
  'invalid-iban': 'IBAN no válido',
  'invalid-percent': 'Tiene que estar entre 0 y 100',
  'invalid-dni': 'DNI inválido',
  'invalid-cif': 'CIF inválido',
  'add-certifier': 'Tienes que añadir una certificadora al menos',
  'add-state': 'Tienes que añadir una zona al menos',
  'invalid-prefix': 'Prefijo no válido',
  'invalid-phone': 'Teléfono no válido',
  'invalid-email': 'Email no válido',
  'invalid-country': 'País no válido',
  'invalid-date': 'Fecha no válida',
  'invalid-date-start-end': 'La fecha de inicio tiene que ser menor a la de fin',
  'invalid-date-future': 'La fecha tiene que ser futura',
  'invalid-date-past': 'La fecha tiene que ser pasada',
  'invalid-postal-code': 'C.P. no válido',
  'invalid-max-years': 'No es posible generar más de 365 actividades',
  'invalid-range': 'Rango inválido',
  'invalid-negative': 'No puede ser un número negativo',
  'introduced-range': 'Rango incompatible',
  'invalid-max-min': 'El valor máximo tiene que ser mayor al mínimo',
  'invalid-included-date': 'La fecha ya ha sido introducida',
  'invalid-password': 'Contraseña no válida',
  'invalid-password-length': 'La contraseña debe de contener al menos 6 caracteres',
  'passwords-not-equal': 'Las contraseñas no coinciden',
  'should-be-under-14': 'Es ilegal no tener NIF con más de 14 años',
  'form-error': 'Revisa que has rellenado todo correctamente',
  'server-error': 'Ha ocurrido un fallo en el servidor',
  'previous-docs':
    'Le informamos que la contratación del actual asegurado no puede llevarse a cabo ya que posee documentos pendientes de firma.',
  'insured-duplicate-birthdate':
    'La contratación del presupuesto seleccionado no se puede llevar a cabo ya que se ha detectado un error en la fecha de nacimiento del asegurado en las simulaciones previas.',
  'invalid-insured-birthdate': 'El asegurado debe tener entre 12 y 80 años de edad.',
  'invalid-taker-birthdate': 'El tomador del seguro debe tener entre 18 y 80 años de edad.',
  'taker-under-18': 'El tomador del seguro debe ser mayor de edad.',
  'under-18': 'Tienes que ser mayor de edad',
  'under-12': 'Tienes que tener mas de 11 años',
  'invalid-address': 'La dirección introducida no es válida, comprueba que los datos son correctos.',
  'budget-not-found': 'No se han encontrado datos para los IDs introducidos.',
  'budget-without-acts': 'El presupuesto seleccionado no dispone de actos asegurables.',
};

export const ERROR_MESSAGES_EN: InterfaceErrorMessage = {
  required: 'Campo obligatorio',
  'invalid-format': 'Formato no válido',
  'invalid-iban': 'IBAN no válido',
  'invalid-percent': 'Tiene que estar entre 0 y 100',
  'invalid-dni': 'DNI inválido',
  'invalid-cif': 'CIF inválido',
  'add-certifier': 'Tienes que añadir una certificadora al menos',
  'add-state': 'Tienes que añadir una zona al menos',
  'invalid-prefix': 'Prefijo no válido',
  'invalid-phone': 'Teléfono no válido',
  'invalid-email': 'Email no válido',
  'invalid-country': 'País no válido',
  'invalid-date': 'Fecha no válida',
  'invalid-date-start-end': 'La fecha de inicio tiene que ser menor a la de fin',
  'invalid-date-future': 'La fecha tiene que ser futura',
  'invalid-date-past': 'La fecha tiene que ser pasada',
  'invalid-postal-code': 'C.P. no válido',
  'invalid-max-years': 'No es posible generar más de 365 actividades',
  'invalid-range': 'Rango inválido',
  'invalid-negative': 'No puede ser un número negativo',
  'introduced-range': 'Rango incompatible',
  'invalid-max-min': 'El valor máximo tiene que ser mayor al mínimo',
  'invalid-included-date': 'La fecha ya ha sido introducida',
  'invalid-password': 'Contraseña no válida',
  'passwords-not-equal': 'Las contraseñas no coinciden',
  'should-be-under-14': 'Es ilegal no tener NIF con más de 14 años',
  'form-error': 'Revisa que has rellenado todo correctamente',
  'server-error': 'Ha ocurrido un fallo en el servidor',
  'previous-docs':
    'Le informamos que la contratación del actual asegurado no puede llevarse a cabo ya que posee documentos pendientes de firma.',
  'insured-duplicate-birthdate':
    'La contratación del presupuesto seleccionado no se puede llevar a cabo ya que se ha detectado un error en la fecha de nacimiento del asegurado en las simulaciones previas.',
  'invalid-insured-birthdate': 'El asegurado debe tener entre 12 y 80 años de edad.',
  'invalid-taker-birthdate': 'El tomador del seguro debe tener entre 18 y 80 años de edad.',
  'taker-under-18': 'El tomador del seguro debe ser mayor de edad.',
  'under-18': 'Tienes que ser mayor de edad',
  'under-12': 'Tienes que tener mas de 11 años',
  'invalid-address': 'La dirección introducida no es válida, comprueba que los datos son correctos.',
  'budget-not-found': 'No se han encontrado datos para los IDs introducidos.',
  'budget-without-acts': 'El presupuesto seleccionado no dispone de actos asegurables.',
};

export const ERROR_MESSAGES_PT: InterfaceErrorMessage = {
  required: 'Campo obligatorio',
  'invalid-format': 'Formato no válido',
  'invalid-iban': 'IBAN no válido',
  'invalid-percent': 'Tiene que estar entre 0 y 100',
  'invalid-dni': 'DNI inválido',
  'invalid-cif': 'CIF inválido',
  'add-certifier': 'Tienes que añadir una certificadora al menos',
  'add-state': 'Tienes que añadir una zona al menos',
  'invalid-prefix': 'Prefijo no válido',
  'invalid-phone': 'Teléfono no válido',
  'invalid-email': 'Email no válido',
  'invalid-country': 'País no válido',
  'invalid-date': 'Fecha no válida',
  'invalid-date-start-end': 'La fecha de inicio tiene que ser menor a la de fin',
  'invalid-date-future': 'La fecha tiene que ser futura',
  'invalid-date-past': 'La fecha tiene que ser pasada',
  'invalid-postal-code': 'C.P. no válido',
  'invalid-max-years': 'No es posible generar más de 365 actividades',
  'invalid-range': 'Rango inválido',
  'invalid-negative': 'No puede ser un número negativo',
  'introduced-range': 'Rango incompatible',
  'invalid-max-min': 'El valor máximo tiene que ser mayor al mínimo',
  'invalid-included-date': 'La fecha ya ha sido introducida',
  'invalid-password': 'Contraseña no válida',
  'passwords-not-equal': 'Las contraseñas no coinciden',
  'should-be-under-14': 'Es ilegal no tener NIF con más de 14 años',
  'form-error': 'Revisa que has rellenado todo correctamente',
  'server-error': 'Ha ocurrido un fallo en el servidor',
  'previous-docs':
    'Le informamos que la contratación del actual asegurado no puede llevarse a cabo ya que posee documentos pendientes de firma.',
  'insured-duplicate-birthdate':
    'La contratación del presupuesto seleccionado no se puede llevar a cabo ya que se ha detectado un error en la fecha de nacimiento del asegurado en las simulaciones previas.',
  'invalid-insured-birthdate': 'El asegurado debe tener entre 12 y 80 años de edad.',
  'invalid-taker-birthdate': 'El tomador del seguro debe tener entre 18 y 80 años de edad.',
  'taker-under-18': 'El tomador del seguro debe ser mayor de edad.',
  'under-18': 'Tienes que ser mayor de edad',
  'under-12': 'Tienes que tener mas de 11 años',
  'invalid-address': 'La dirección introducida no es válida, comprueba que los datos son correctos.',
  'budget-not-found': 'No se han encontrado datos para los IDs introducidos.',
  'budget-without-acts': 'El presupuesto seleccionado no dispone de actos asegurables.',
};

export const ERROR_MESSAGES_FR: InterfaceErrorMessage = {
  required: 'Campo obligatorio',
  'invalid-format': 'Formato no válido',
  'invalid-iban': 'IBAN no válido',
  'invalid-percent': 'Tiene que estar entre 0 y 100',
  'invalid-dni': 'DNI inválido',
  'invalid-cif': 'CIF inválido',
  'add-certifier': 'Tienes que añadir una certificadora al menos',
  'add-state': 'Tienes que añadir una zona al menos',
  'invalid-prefix': 'Prefijo no válido',
  'invalid-phone': 'Teléfono no válido',
  'invalid-email': 'Email no válido',
  'invalid-country': 'País no válido',
  'invalid-date': 'Fecha no válida',
  'invalid-date-start-end': 'La fecha de inicio tiene que ser menor a la de fin',
  'invalid-date-future': 'La fecha tiene que ser futura',
  'invalid-date-past': 'La fecha tiene que ser pasada',
  'invalid-postal-code': 'C.P. no válido',
  'invalid-max-years': 'No es posible generar más de 365 actividades',
  'invalid-range': 'Rango inválido',
  'invalid-negative': 'No puede ser un número negativo',
  'introduced-range': 'Rango incompatible',
  'invalid-max-min': 'El valor máximo tiene que ser mayor al mínimo',
  'invalid-included-date': 'La fecha ya ha sido introducida',
  'invalid-password': 'Contraseña no válida',
  'passwords-not-equal': 'Las contraseñas no coinciden',
  'should-be-under-14': 'Es ilegal no tener NIF con más de 14 años',
  'form-error': 'Revisa que has rellenado todo correctamente',
  'server-error': 'Ha ocurrido un fallo en el servidor',
  'previous-docs':
    'Le informamos que la contratación del actual asegurado no puede llevarse a cabo ya que posee documentos pendientes de firma.',
  'insured-duplicate-birthdate':
    'La contratación del presupuesto seleccionado no se puede llevar a cabo ya que se ha detectado un error en la fecha de nacimiento del asegurado en las simulaciones previas.',
  'invalid-insured-birthdate': 'El asegurado debe tener entre 12 y 80 años de edad.',
  'invalid-taker-birthdate': 'El tomador del seguro debe tener entre 18 y 80 años de edad.',
  'taker-under-18': 'El tomador del seguro debe ser mayor de edad.',
  'under-18': 'Tienes que ser mayor de edad',
  'under-12': 'Tienes que tener mas de 11 años',
  'invalid-address': 'La dirección introducida no es válida, comprueba que los datos son correctos.',
  'budget-not-found': 'No se han encontrado datos para los IDs introducidos.',
  'budget-without-acts': 'El presupuesto seleccionado no dispone de actos asegurables.',
};

export const ERROR_MESSAGES_DE: InterfaceErrorMessage = {
  required: 'Campo obligatorio',
  'invalid-format': 'Formato no válido',
  'invalid-iban': 'IBAN no válido',
  'invalid-percent': 'Tiene que estar entre 0 y 100',
  'invalid-dni': 'DNI inválido',
  'invalid-cif': 'CIF inválido',
  'add-certifier': 'Tienes que añadir una certificadora al menos',
  'add-state': 'Tienes que añadir una zona al menos',
  'invalid-prefix': 'Prefijo no válido',
  'invalid-phone': 'Teléfono no válido',
  'invalid-email': 'Email no válido',
  'invalid-country': 'País no válido',
  'invalid-date': 'Fecha no válida',
  'invalid-date-start-end': 'La fecha de inicio tiene que ser menor a la de fin',
  'invalid-date-future': 'La fecha tiene que ser futura',
  'invalid-date-past': 'La fecha tiene que ser pasada',
  'invalid-postal-code': 'C.P. no válido',
  'invalid-max-years': 'No es posible generar más de 365 actividades',
  'invalid-range': 'Rango inválido',
  'invalid-negative': 'No puede ser un número negativo',
  'introduced-range': 'Rango incompatible',
  'invalid-max-min': 'El valor máximo tiene que ser mayor al mínimo',
  'invalid-included-date': 'La fecha ya ha sido introducida',
  'invalid-password': 'Contraseña no válida',
  'passwords-not-equal': 'Las contraseñas no coinciden',
  'should-be-under-14': 'Es ilegal no tener NIF con más de 14 años',
  'form-error': 'Revisa que has rellenado todo correctamente',
  'server-error': 'Ha ocurrido un fallo en el servidor',
  'previous-docs':
    'Le informamos que la contratación del actual asegurado no puede llevarse a cabo ya que posee documentos pendientes de firma.',
  'insured-duplicate-birthdate':
    'La contratación del presupuesto seleccionado no se puede llevar a cabo ya que se ha detectado un error en la fecha de nacimiento del asegurado en las simulaciones previas.',
  'invalid-insured-birthdate': 'El asegurado debe tener entre 12 y 80 años de edad.',
  'invalid-taker-birthdate': 'El tomador del seguro debe tener entre 18 y 80 años de edad.',
  'taker-under-18': 'El tomador del seguro debe ser mayor de edad.',
  'under-18': 'Tienes que ser mayor de edad',
  'under-12': 'Tienes que tener mas de 11 años',
  'invalid-address': 'La dirección introducida no es válida, comprueba que los datos son correctos.',
  'budget-not-found': 'No se han encontrado datos para los IDs introducidos.',
  'budget-without-acts': 'El presupuesto seleccionado no dispone de actos asegurables.',
};

export const ERROR_MESSAGES_IT: InterfaceErrorMessage = {
  required: 'Campo obligatorio',
  'invalid-format': 'Formato no válido',
  'invalid-iban': 'IBAN no válido',
  'invalid-percent': 'Tiene que estar entre 0 y 100',
  'invalid-dni': 'DNI inválido',
  'invalid-cif': 'CIF inválido',
  'add-certifier': 'Tienes que añadir una certificadora al menos',
  'add-state': 'Tienes que añadir una zona al menos',
  'invalid-prefix': 'Prefijo no válido',
  'invalid-phone': 'Teléfono no válido',
  'invalid-email': 'Email no válido',
  'invalid-country': 'País no válido',
  'invalid-date': 'Fecha no válida',
  'invalid-date-start-end': 'La fecha de inicio tiene que ser menor a la de fin',
  'invalid-date-future': 'La fecha tiene que ser futura',
  'invalid-date-past': 'La fecha tiene que ser pasada',
  'invalid-postal-code': 'C.P. no válido',
  'invalid-max-years': 'No es posible generar más de 365 actividades',
  'invalid-range': 'Rango inválido',
  'invalid-negative': 'No puede ser un número negativo',
  'introduced-range': 'Rango incompatible',
  'invalid-max-min': 'El valor máximo tiene que ser mayor al mínimo',
  'invalid-included-date': 'La fecha ya ha sido introducida',
  'invalid-password': 'Contraseña no válida',
  'passwords-not-equal': 'Las contraseñas no coinciden',
  'should-be-under-14': 'Es ilegal no tener NIF con más de 14 años',
  'form-error': 'Revisa que has rellenado todo correctamente',
  'server-error': 'Ha ocurrido un fallo en el servidor',
  'previous-docs':
    'Le informamos que la contratación del actual asegurado no puede llevarse a cabo ya que posee documentos pendientes de firma.',
  'insured-duplicate-birthdate':
    'La contratación del presupuesto seleccionado no se puede llevar a cabo ya que se ha detectado un error en la fecha de nacimiento del asegurado en las simulaciones previas.',
  'invalid-insured-birthdate': 'El asegurado debe tener entre 12 y 80 años de edad.',
  'invalid-taker-birthdate': 'El tomador del seguro debe tener entre 18 y 80 años de edad.',
  'taker-under-18': 'El tomador del seguro debe ser mayor de edad.',
  'under-18': 'Tienes que ser mayor de edad',
  'under-12': 'Tienes que tener mas de 11 años',
  'invalid-address': 'La dirección introducida no es válida, comprueba que los datos son correctos.',
  'budget-not-found': 'No se han encontrado datos para los IDs introducidos.',
  'budget-without-acts': 'El presupuesto seleccionado no dispone de actos asegurables.',
};

export const getServiceErrorMsg = (errorCode: string, centreId?: boolean) => {
  switch (errorCode) {
    case 'DUPLICATE_VALUE':
      if (centreId) {
        return 'Ya existe un centro con ese ID.';
      }
      return 'Ya existe un usuario con este email.';
    case 'EMAIL_NOT_CONFIRMED':
      return 'No has confirmado el email';
    case 'UNIQUE_BOOKING':
      return 'Solo puedes reservar una actividad una vez';
    case 'NOT_A_DIVER':
      return 'El usuario no tiene perfil de buceador';
    case 'BLOCKED_ACTIVITY':
      return 'Las actividades confirmadas no pueden editarse';
    case 'ACTIVITY_BOOKED':
      return 'Esta actividad ya ha sido reservada por un buceador. Cancela en lugar de eliminar.';
    case 'UNRELATED_CENTRE_ACTIVITY':
      return 'El centro no posee esta actividad.';
    case 'TICKET_NOT_FOUND':
      return 'No se ha encontrado el ticket';
    case 'UNSHAREABLE_TICKET':
      return 'Este ticket no se puede compartir con otras personas';
    case 'USED_TICKET':
      return 'Este ticket ya no tiene más usos';
    case 'UNRELATED_TICKET_ACTIVITY':
      return 'Este ticket no es valido para la actividad';
    case 'UNRELATED_CENTRE_SERVICE':
      return 'El servicio no esta disponible en este centro';
    case 'UNRELATED_CENTRE_EQUIPMENT':
      return 'El equipo no esta disponible para este centro';
    case 'UNRELATED_ACTIVITY_DATA':
      return 'Una comida, servicio o equipo no pertenece a este centro';
    case 'INACTIVE_POLICY_CENTRE':
      return 'La política de cancelación esta inactiva para este centro';
    case 'FORBIDDEN_RELATION':
      return 'La relación esta bloqueada para tu usuario';
    case 'GOOGLE_AUTH_CONFLICT':
      return 'Error de autenticación con google';
    case 'LAST_CANCELLATION_POLICY':
      return 'No puedes desactivar la última política de cancelación activa';
    case 'BLOCKED_DOCUMENT':
      return 'Los documentos validados no pueden editarse';
    case 'REQUIRED_FILE':
      return 'Se requiere un archivo';
    case 'RATING_NOT_OWNED':
      return 'La calificación no es propiedad del buceador';
    case 'BOOKING_NOT_OWNED':
      return 'La reserva no es tuya';
    case 'CANCELLATION_POLICY_NOT_FOUND':
      return 'Política de cancelación no encontrada';
    case 'COMPLETED_ACTIVITY':
      return 'La actividad ya ha sido encontrada y no es posible realizar el reembolso';
    case 'NOT_CONSERVATION_ORGANIZATIONS':
      return 'La organización no es de conservación marina';
    case 'NOT_CERTIFIER_ORGANIZATIONS':
      return 'La organización no es una certificadora';
    case 'BLOCKED_SITE':
      return 'Los puntos de buceo validados no se pueden editar';
    case 'BLOCKED_USER':
      return 'No puedes editar el perfil de este usuario';
    case 'NOT_OWNED_CENTRE':
      return 'El usuario no pertenece a este centro';
    case 'BLOCKED_DIVE':
      return 'Las inmersiones validadas no se pueden editar';
    case 'NOT_IN_DIVE':
      return 'No eres parte de esta inmersión';
    case 'UNRELATED_CENTRE_STOCK':
      return 'El stock no pertenece al centro';
    case 'CLOSED_INCIDENCE':
      return 'La incidencia esta cerrada';
    case 'REFERENCE_NOT_FOUND':
      return 'Referencia no encontrada';
    case 'EDITING_DEFAULT_LEVELS':
      return 'Los niveles de buceo por defecto no pueden ser eliminados';
    case 'MAILER_ERROR':
      return 'Error del servicio de email';
    case 'ACCOMODATION_NOT_FOUND':
      return 'Alojamiento no encontrado';
    case 'ROOM_NOT_FOUND':
      return 'Habitación no encontrada';
    case 'PICTURE_NOT_FOUND':
      return 'Imágen no encontrada';
    case 'S3_ERROR':
      return 'Error de servidor S3, contacta con el admin';
    case 'UNRELATED_CENTRE_TEMPLATE':
      return 'La plantilla no pertenece a este centro';
    case 'NOT_STRIPE_USER':
      return 'El usuario no esta registrado en stripe';
    case 'INVALID_CREDENTIALS':
      return 'Usuario o contraseña incorrectos';
    case 'INVALID_TOKEN':
      return 'Token invalido';
    case 'NOTHING_TO_DELETE':
      return 'No hay imágenes para eliminar';
    case 'PICTURE_NOW_OWNED':
      return 'No tienes permisos para eliminar esta imágen';
    case 'NOTHING_TO_DELETE':
      return 'La plantilla no tiene imagenes para eliminar';
    case 'WRONG_GEOGRAPHIC_ZONE':
      return 'El buceador no esta asignado a tu zona';
    case '403':
      return 'No tiene permisos para realizar esta acción';
    default:
      return 'Ha ocurrido un error con el servidor.';
  }
};
