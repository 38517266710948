import * as React from 'react';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter, match } from 'react-router';
import { BookingsStyle } from '../bookings-style';
import HeaderMain from '../../../../components/header-main/header-main';
import { MainLayoutSection } from '../../../main-layout-style';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import { listBooking } from '../../../../services/bookings';
import { connect } from 'react-redux';
import IStoreState from '../../../../redux/store/IStoreState';
import { NavigationTabContainer } from '../../../../components/navigation-tabs/navigation-tabs-style';
import BookingsType from './bookings-type/bookings-type'
import Pagination from '../../../../components/pagination-v2/pagination';
const PAGINATION_LIMIT = 10;

export interface IBookingsProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
  }>;
  centreCurrency?: string;
  activity?: any;
}

export interface IBookingsState {
  noResult: any;
  modal: string;
  preResult: any[];
  superAdmin: boolean;
  page: number;
  pages: number;
  searchValue: string | null;
  skipPage: number;
  section: string;
  subsection?: string | null;
  bookings: any[];
  bookingsConfirmed: any[];
}

class ListBookings extends React.Component<IBookingsProps, IBookingsState> {
  sections: { [s: string]: string } = {
    solicitudes: "pending",
    rechazadas: "rejected",
    confirmadas: "confirmed",
    cancelaciones: "cancelled",
    historial: "history"
  }
  constructor(props: IBookingsProps) {
    super(props);

    this.state = {
      noResult: null,
      modal: '',
      preResult: [],
      page: 1,
      pages: 1,
      superAdmin: false,
      searchValue: '',
      skipPage: 0,
      section: 'solicitudes',
      subsection: 'rechazadas',
      bookings: [],
      bookingsConfirmed: [],
    };
  }
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;
    if (!id && history.location.pathname.indexOf('centres') === -1) {
      this.setState({ superAdmin: true }, () => {
        //      this.getBookingsData();
        //      this.getBookingsDataConfirmed();
      });
    } else {
      //  this.getBookingsData();
      //  this.getBookingsDataConfirmed();
    }
    this.load(this.state.section);
  }



  load = async (section: string, searchValue: string = '') => {
    const changeSection = this.state.section !== section;
    let page = changeSection ? 1 : this.state.page;
    const {
      match: {
        params: { id },
      }, activity
    } = this.props;
    let type: string = "centre";
    let updateSearchValue = false;
    if (searchValue === 'noValue') {
      page = 1;
      searchValue = '';
      updateSearchValue = true;
    } else if (searchValue !== '') {
      page = 1;
      updateSearchValue = true;
    } else {
      searchValue = this.state.searchValue ? this.state.searchValue : '';
    }

    let args: any = {
      "key": searchValue === 'noValue' ? this.state.searchValue : searchValue,
      "type": type,
      "onlyStatus": this.sections[section],
      "page": page,
      "rows": PAGINATION_LIMIT,
    }
    if (this.state.superAdmin) {
      args.type = "all";
    } else if (activity) {
      args.type = "activity";
      args.id = activity.id;
    } else {
      args.id = parseInt(id);
    }
    const result: any = await listBooking(args);
    if (result.status === 201) {
      const rowsTotal = result.data.globalRows;
      const page = result.data.page;
      let pages: number = Math.ceil(rowsTotal / PAGINATION_LIMIT);
      this.setState({
        ...this.state,
        searchValue: !updateSearchValue ? this.state.searchValue : searchValue,
        bookings: result.data.data,
        pages: pages,
        page: page,
        section: section
      });
    } else {
      this.setState({
        ...this.state,
        searchValue: !updateSearchValue ? this.state.searchValue : searchValue,
        pages: 1,
        page: 1,
        bookings: [],
        section: section
      });
    }
  }

  render() {
    const { t } = this.props;
    const { noResult, section } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;


    /*
              mainButton={{
            text: 'Crear reserva',
            onClick: () => { this.props.history.push(`/centres/${id}/billing/bookings/create`) }
            //onClick: () => this.setState({ renderModal: 'modal-create-bookin' }),
          }}

    */
    return (
      <BookingsStyle>
        <HeaderMain
          className={classNames({ 'search-empty': noResult })}
          title={t('layouts:detail-menu.bookings').toUpperCase()}
          withSearch={true}
          handleSearch={(value: string | null) => this.load(this.state.section, value ? value : 'noValue')}

        />

        <MainLayoutSection>
          <NavigationTabContainer>
            <div
              className={`navigation-item ${section === 'solicitudes' && 'active'}`}
              onClick={() => this.load('solicitudes')}
            >
              <BodyDestacadoText>{t('modalMessages:sol').toUpperCase()}</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${section === 'rechazadas' && 'active'}`}
              onClick={() => this.load('rechazadas')}
            >
              <BodyDestacadoText>{t('modalMessages:rejected').toUpperCase()}</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${section === 'confirmadas' && 'active'}`}
              onClick={() => this.load('confirmadas')}
            >
              <BodyDestacadoText>{t('modalMessages:confirmed').toUpperCase()}</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${section === 'historial' && 'active'}`}
              onClick={() => this.load('historial')}
            >
              <BodyDestacadoText>{t('layouts:detail-menu.history').toUpperCase()}</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${section === 'cancelaciones' && 'active'}`}
              onClick={() => this.load('cancelaciones')}
            >
              <BodyDestacadoText>{t('layouts:detail-menu.cancelled').toUpperCase()}</BodyDestacadoText>
            </div>

          </NavigationTabContainer>
          <Pagination
            page={this.state.page}
            limit={this.state.pages}
            callback={(page) => {
              this.setState({ page: page }, () => {
                this.load(this.state.section);
              });
            }
            } />
          <BookingsType
            section={this.state.section}
            bookings={this.state.bookings}
            onReload={() => this.load(this.state.section)}
          />

        </MainLayoutSection>
      </BookingsStyle>
    );
  }
}
function mapStateToProps(state: IStoreState) {
  return {
    centreCurrency: state.centreCurrency.value,
    loading: state.loading.value,
  };
}

export default connect(mapStateToProps)(
  withTranslation(['intro', 'buttons', 'tables', 'layouts', 'modalMessages'])(withRouter(ListBookings))
);
/* export default withRouter(ListBookings); */
