import React, { Component } from 'react';
import { IncidenceStyle } from './incidence-style';
import HeaderMain from '../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../main-layout-style';
import MainTableLayout from '../../components/main-table/main-table';
import Pagination from '../../components/pagination';
import { images } from '../../assets/images';
import { NavigationTabContainer } from '../../components/navigation-tabs/navigation-tabs-style';
import BodyDestacadoText from '../../components/text/body-destacado-text/body-destacado-text';
import SingleButton from '../../components/single-button/single-button';
import MainButton from '../../components/main-button';
import ModalIncidenceDetail from '../../components/modals/modal-incidence-detail/modal-incidence-detail';
import ModalIncidenceManagement from '../../components/modals/modal-incidence-management/modal-incidence-management';
import ModalIncidenceType from '../../components/modals/modal-incidence-type/modal-incidence-type';
import {
  getIncidencesTableColumns,
  getIncidencesRowKeys,
  Incidence,
  filtersBySection,
  filtersByStatus,
} from './incidences-types';
import moment from 'moment';
import 'moment/locale/es.js';
import { TableRow } from '../../types/table';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ImageReference, INCIDENCE_STATES_DROPDOWN } from '../../constants/incidences';
import { DIVERS_PER_PAGE } from '../../constants/data';
import { getIncidences } from '../../services/incidences';
import ModalDelete from '../../components/modals/modal-delete/modal-delete';
import { deleteTypesIncidence, getTypesIncidence } from '../../services/type-incidences';
import SelectBox from '../../components/select-box';
import CalendarBox from '../../components/calendar-box';
import { IFilter } from '../../models/filter';
import { createFilterRequest } from '../../utils/other';
import { RouteComponentProps, match, withRouter } from 'react-router';
import { getCentreIncidences } from '../../services/centre';
import { ContentBlock } from '../../components/content-block/content-block';
import { connect } from 'react-redux';
import IStoreState from '../../redux/store/IStoreState';

interface IIncidencesLayoutProps extends WithTranslation, RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  staffPermissions?: any;
}

interface IIncidencesLayoutState {
  centreId?: string;
  modal: string;
  section: string;
  statusOpen: boolean;
  incidenceId: string;
  items: Incidence[];
  totalItems: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  typeAction: string;
  typeIncidence: string;
  idType: string;
  reloadType: boolean;
  [key: string]: any;
  activeFilter: boolean;
  incidenceTypes: any[];
  incidenceTypesDropdown: any[];
  filterValues: { [key: string]: IFilter };
  page: number;
}

class Incidences extends Component<IIncidencesLayoutProps, IIncidencesLayoutState> {
  static sections = {
    business: 'business',
    sites: 'sites',
    pictures: 'pictures',
  };

  readonly state: IIncidencesLayoutState;
  readonly auxCategoryConversor: any = {
    "diveSite": "sites",
    "pictures": "pictures",
    "bussiness": "business"
  }

  constructor(props: IIncidencesLayoutProps) {
    super(props);
    this.state = {
      modal: '',
      section: Incidences.sections.business,
      statusOpen: true,
      incidenceId: '',
      items: [],
      totalItems: 0,
      totalPages: 0,
      searchFilter: '',
      skipPage: 0,
      orderAscendent: '',
      typeAction: '',
      typeIncidence: '',
      reloadType: false,
      idType: '',
      activeFilter: false,
      incidenceTypes: [],
      incidenceTypesDropdown: [],
      filterValues: {},
      page: 1,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ centreId: id }, () => {
        this.getIncidences();
        this.getIncidenceTypes();
      });
    } else {
      this.getIncidences();
      this.getIncidenceTypes();
    }
  }

  getIncidenceTypes = async () => {
    const { section } = this.state;
    const response: any = await getTypesIncidence({ filter: { orderBy: [{ key: 'createdAt', direction: 'DESC' }] } });
    const incidenceTypes = response.data.data;
    const lang = localStorage.i18nextLng ? localStorage.i18nextLng : 'es';
    const incidenceTypesDropdown = incidenceTypes.filter((incidence: any) =>
      this.auxCategoryConversor[incidence.category] === section).map((incidence: any) => {
        return {
          value: incidence.id.toString(),
          label: incidence.name[lang],
        };
      });
    this.setState({ incidenceTypes, incidenceTypesDropdown });
  };

  filterIncidenceTypes = () => {
    const { incidenceTypes, section } = this.state;
    const lang = localStorage.i18nextLng ? localStorage.i18nextLng : 'es';
    const incidenceTypesDropdown = incidenceTypes.filter((incidence: any) =>
      this.auxCategoryConversor[incidence.category] === section).map((incidence: any) => {
        return {
          value: incidence.id.toString(),
          label: incidence.name[lang],
        };
      });
    this.setState({ incidenceTypesDropdown });
  }


  getIncidences = async () => {
    const { searchFilter, skipPage, orderAscendent, section, statusOpen, filterValues, centreId } = this.state;
    const whereFilter = Object.assign(
      {},
      {
        name: { method: 'ilike', value: `%${searchFilter}%` },
        ref: {
          method: 'in',
          values: filtersBySection[section],
        },
        status: {
          method: 'in',
          values: filtersByStatus[statusOpen ? 'open' : 'closed'],
        },
      },
      createFilterRequest(filterValues)
    );

    const params = {
      filter: {
        where: whereFilter,
        skip: skipPage,
        limit: DIVERS_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: ['type'],
      },
    };
    try {
      let response: any;
      if (centreId) response = await getCentreIncidences(centreId, params);
      else response = await getIncidences(params);
      const lang = localStorage.i18nextLng ? localStorage.i18nextLng : 'es';
      const items: any[] = response.data.data.map((item: any) => {
        const {
          id,
          ref,
          status,
          type,
          date,
          reference: { name: refName },
        } = item;
        return {
          id,
          ref,
          refName,
          date,
          status,
          type: type ? type.name[lang] : '-',
        };
      });

      const totalItems: number = response.data.data.count;
      const totalPages: number = Math.ceil(totalItems / DIVERS_PER_PAGE);
      this.setState({ items, totalItems, totalPages }, () => this.filterIncidenceTypes());
    } catch (err) {
      this.setState({ items: [], totalItems: 0, totalPages: 0 });
    }
  };

  setPage(page: number) {
    const { totalItems } = this.state;
    const skip: number = DIVERS_PER_PAGE * (page - 1);
    if (skip < totalItems) {
      this.setState({ page, skipPage: skip }, () => {
        this.getIncidences();
      });
    }
  }

  handleChangeView(key: string, value: string | boolean) {
    if ('statusOpen' && value === false) {
      this.handleFilterDelete('status');
    }
    this.setState({ [key]: value }, () => {
      this.getIncidences();
    });
  }

  getIconForStatus(status: string) {
    switch (status) {
      case 'open':
        return images.alertCircleRedSvg;
      case 'solved':
        return images.checkSuccessSvg;
      case 'closed':
        return images.checkApprovedBlueSvg;
    }
  }

  formatDataToTableRows(data: { [key: string]: any }[] = []) {
    const { t, staffPermissions } = this.props;
    const { statusOpen } = this.state;
    return data.map(item => {
      const row: TableRow = {};
      row.status = {
        value: this.getIconForStatus(item.status),
        type: '',
        key: 'icon',
      };

      row.ref = {
        value: ['centre', 'site'].includes(item.ref) ? item.refName : ImageReference[item.ref],
        type: '',
        key: 'highlighted-body',
      };

      row.type = {
        value: item.type ? t(`incidences:${item.type}`).toString() || item.type : '',
        type: '',
        key: 'body',
      };

      row.date = {
        value: moment(item.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        type: '',
        key: '',
      };

      row.detail = {
        value: images.eyeSvg,
        type: '',
        key: 'icon-button',
        onClick: () => this.setState({ modal: 'incidence-detail', incidenceId: item.id }),
      };
      row.options = {
        value: item.id,
        type: '',
        key: 'options',
        actions: [],
      };
      const reopen = {
        label: 'Reabrir',
        icon: images.folderSvg,
        onClick: () => {
          this.setState({ modal: 'incidence-managment', typeAction: 'reopen', incidenceId: item.id });
        },
      };
      const close = {
        label: 'Cerrar',
        icon: images.lockSvg,
        onClick: () => {
          this.setState({ modal: 'incidence-managment', typeAction: 'close', incidenceId: item.id });
        },
      };
      const solved = {
        label: 'Resolver',
        icon: images.checkCircleSvg,
        onClick: () => {
          this.setState({ modal: 'incidence-managment', typeAction: 'solved', incidenceId: item.id });
        },
      };
      if (row.options && row.options.actions) {
        if (statusOpen && (!staffPermissions || staffPermissions.incidences)) {
          if (item.status === 'open') {
            row.options.actions.push(solved);
            row.options.actions.push(close);
          } else {
            row.options.actions.push(reopen);
            row.options.actions.push(close);
          }
        }
      }

      return row;
    });
  }

  deleteType = async (typeId: string) => {
    try {
      await deleteTypesIncidence(typeId);
      this.setState({ modal: 'incidence-type', reloadType: true });
    } catch (err) {
      this.setState({ modal: 'incidence-type' });
    }
  };

  handleFilterChange(key: string, value: any, method?: string, collection?: string) {
    const { filterValues } = this.state;
    if (!filterValues[key]) filterValues[key] = { value: '' };
    filterValues[key].value = value;
    if (method) filterValues[key].method = method;
    if (collection) filterValues[key].collection = collection;
    this.setState({ filterValues }, () => {
      this.getIncidences();
    });
  }

  handleFilterDelete(key: string) {
    const { filterValues } = this.state;
    delete filterValues[key];
    this.setState({ filterValues }, () => {
      this.getIncidences();
    });
  }

  render() {
    const { sections } = Incidences;
    const { t } = this.props;
    const {
      centreId,
      modal,
      section,
      statusOpen,
      items,
      totalPages,
      setPage,
      typeAction,
      incidenceId,
      typeIncidence,
      idType,
      reloadType,
      activeFilter,
      incidenceTypesDropdown,
      page,
    } = this.state;
    return (
      <>
        <ContentBlock>
          {centreId ? (
            <HeaderMain title={'Incidencias'} />
          ) : (
              <HeaderMain
                title={'Incidencias'}
                mainButton={{
                  text: 'Administrar',
                  onClick: () => this.setState({ modal: 'incidence-type' }),
                }}
              />
            )}

          <MainLayoutSection>
            <IncidenceStyle>
              <div className="dive-site-head">
                {/* Navigation Tabs */}
                <div className="dive-site-head__nav">
                  <NavigationTabContainer>
                    <div
                      className={`navigation-item ${section === sections.business && 'active'}`}
                      onClick={() => {
                        this.setState({ section: sections.business }, () =>
                          this.handleChangeView('section', sections.business)
                        );
                      }}
                    >
                      <BodyDestacadoText>Negocios</BodyDestacadoText>
                    </div>
                    <div
                      className={`navigation-item ${section === sections.sites && 'active'}`}
                      onClick={() => {
                        this.setState({ section: sections.sites }, () =>
                          this.handleChangeView('section', sections.sites)
                        );
                      }}
                    >
                      <BodyDestacadoText>Puntos de inmersión</BodyDestacadoText>
                    </div>
                    <div
                      className={`navigation-item ${section === sections.pictures && 'active'}`}
                      onClick={() => {
                        this.setState({ section: sections.pictures }, () =>
                          this.handleChangeView('section', sections.pictures)
                        );
                      }}
                    >
                      <BodyDestacadoText>Imágenes</BodyDestacadoText>
                    </div>
                  </NavigationTabContainer>
                </div>

                {/* Filter */}
                <div className="dive-site-head__buttons">
                  <div className="dive-site-head__buttons__filter">
                    <MainButton
                      iconImg={activeFilter ? images.filtersLightBlueSvg : images.filtersSvg}
                      text="Filtrar"
                      type={activeFilter ? 'secondary-active' : 'secondary'}
                      onClick={() => this.setState({ activeFilter: !activeFilter })}
                    />
                  </div>
                  <div className="dive-site-head__buttons__item">
                    <SingleButton
                      className="secondary modal"
                      icon={statusOpen ? images.archiveSvg : images.archiveBlueSvg}
                      onClick={() =>
                        this.setState(
                          (prevState: IIncidencesLayoutState) => ({ statusOpen: !prevState.statusOpen }),
                          () => this.handleChangeView('statusOpen', this.state.statusOpen)
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={`dive-site-head-filters${activeFilter ? ' active' : ''}`}>
                <div className="dive-site-head-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Tipo de incidencia"
                    className="rounded-filter"
                    optionsText={[{ value: '*', label: 'Todas' }].concat(incidenceTypesDropdown)}
                    optionKey={'value'}
                    optionValue={'label'}
                    icon={images.arrowDown2BlueSvg}
                    onChange={value => {
                      if (value === '*') this.handleFilterDelete('id');
                      else this.handleFilterChange('id', parseInt(value, undefined), undefined, 'type');
                    }}
                  />
                </div>

                <div className="dive-site-head-filters__item">
                  <CalendarBox
                    forbidFutureDates={true}
                    maxYear={new Date().getFullYear()}
                    placeholder="Fecha"
                    className="filter"
                    labelText=""
                    initialValue={''}
                    onChange={value => {
                      if (value) this.handleFilterChange('date', moment(value, 'DD/MM/YYYY'));
                      else this.handleFilterDelete('date');
                    }}
                  />
                </div>
                {statusOpen && (
                  <div className="dive-site-head-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Estado"
                      className="rounded-filter"
                      optionsText={[{ value: '*', label: 'Todos' }].concat(INCIDENCE_STATES_DROPDOWN)}
                      optionKey={'value'}
                      optionValue={'label'}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        if (value === '*') this.handleFilterDelete('status');
                        else this.handleFilterChange('status', value);
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Table */}
              <MainLayoutTableContainer>
                <div className="diving-center-table">
                  <MainTableLayout
                    columns={getIncidencesTableColumns(section, !statusOpen)}
                    rows={this.formatDataToTableRows(items)}
                    rowKeys={getIncidencesRowKeys(!statusOpen)}
                    onOrder={() => { }}
                  />
                </div>
                {totalPages > 1 && <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />}
              </MainLayoutTableContainer>
            </IncidenceStyle>
          </MainLayoutSection>
          {modal === 'incidence-detail' && (
            <ModalIncidenceDetail
              incidenceId={incidenceId}
              showModal={modal === 'incidence-detail'}
              onCancel={() => this.setState({ modal: '' })}
              handleButton={(typeAction: string) => this.setState({ typeAction, modal: 'incidence-managment' })}
            />
          )}
          {modal === 'incidence-managment' && (
            <ModalIncidenceManagement
              showModal={modal === 'incidence-managment'}
              typeAction={typeAction}
              onCancel={() => this.setState({ modal: '' })}
              onAccept={() => this.setState({ modal: '' }, () => this.getIncidences())}
              incidenceId={incidenceId}
            />
          )}

          <ModalIncidenceType
            showModal={modal === 'incidence-type'}
            handleRemove={(id: string, type: string) =>
              this.setState({ modal: 'modal-delete', typeIncidence: type, idType: id })
            }
            onCancel={() => this.setState({ modal: '' })}
            typeIncidence={typeIncidence}
            reloadType={reloadType}
            setReloadType={() => this.setState({ reloadType: false })}
          />

          {/* Modal Delete incidence-type  */}
          {modal === 'modal-delete' && (
            <ModalDelete
              title="Vas a eliminar un tipo de incidencia"
              subtitle="¿estás seguro?"
              showModal={modal === 'modal-delete'}
              onRemove={() => this.deleteType(idType)}
              onCancel={() => this.setState({ modal: 'incidence-type' })}
            />
          )}
        </ContentBlock>
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}
export default withTranslation('layouts')(connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(Incidences)));
