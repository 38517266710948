import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const CreateActivitiesDiveSitesStyle = styled.div`
  .create-activities-other-title {
    display: flex;
    margin-bottom: 24px;
    &__text {
      margin-right: 28px;
    }
    &__check  {
      margin-right: 20px;
    }
  }
  .create-activities-other-form {
    display: flex;
    &__input  {
      width: 100%;
      margin-right: 20px;
    }

    &__add {
      margin-top: 30px;
    }
  }

  .create-activities-other-cards  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 6px 14px 16px;
    background: ${colors['NE-002/10']};
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0px;
    }
    &__text {
      color: ${colors['SE-002/100']};
    }
    &__delete {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
`;
