import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ChatUserContainer = styled.div`
  display: flex;
  padding: 16px 44px 16px 20px;
  width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.1s background-color ease-in-out;

  &:hover {
    background-color: ${colors['NE-003/10']};
  }

  .chat-user-avatar {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    position: relative;
    &__img {
      overflow: hidden;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      img  {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }
    }
    &__new  {
      position: absolute;
      width: 8px;
      height: 8px;
      border: 1px solid #ffffff;
      background: ${colors['PR-001/100']};
      border-radius: 100%;
      right: 4px;
      bottom: 2px;
    }
  }

  .chat-user-info {
    &__name  {
      margin-bottom: 2px;
      color: ${colors['NE-001/100']};
    }
    &__bio  {
      margin-bottom: 2px;
      color: ${colors['NE-001/50']};
    }
    &__time  {
      color: ${colors['NE-002/100']};
    }
  }

  .chat-user-button {
    position: absolute;
    top: 16px;
    right: 10px;
  }
`;
