import React, { useState, useRef } from 'react';
import { SingleButtonContainer } from './single-button-style';
import { useOutsideClick } from '../../hooks/useOutsideClick';

interface SingleButtonProps {
  className?: String;
  drop?: boolean;
  icon?: string;
  children?: any;
  text?: string;
  onClick?: () => void;
}

const SingleButton: React.FC<SingleButtonProps> = props => {
  const [openDrop, setOpenDrop] = useState(false);
  const toggleMenu = useRef(null);

  const onClickOutsideHandler = () => {
    if (openDrop) {
      setOpenDrop(false);
    }
  };

  useOutsideClick(toggleMenu, onClickOutsideHandler);

  const { drop, onClick, className, icon, children, text } = props;

  return (
    <SingleButtonContainer ref={toggleMenu} className={`${openDrop ? 'openDrop' : ''} ${className}`}>
      <div className="single-button-icon" onClick={() => (drop ? setOpenDrop(true) : onClick && onClick())}>
        {text}
        <img src={icon} alt="" />
      </div>
      {/* each children <div className="single-button-drop__item"> */}
      {drop && (
        <div
          className="single-button-drop"
          onClick={() => {
            setOpenDrop(false);
          }}
        >
          <div className="single-button-drop-container">{children}</div>
        </div>
      )}
    </SingleButtonContainer>
  );
};

export default SingleButton;
