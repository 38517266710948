import styled from 'styled-components';

export const MarineReserveStyle = styled.div`
  .dive-site-head {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
    &__nav {
    }
    &__buttons {
      display: flex;
      height: 40px;
      &__item {
        &:last-child {
          margin-left: 12px;
        }
      }
      &__filter {
        padding-right: 21px;
        position: relative;
        margin-right: 40px;

        &:after {
          content: '';
          position: absolute;
          top: 12px;
          right: 0;
          height: 16px;
          width: 1px;
          background: #f1f3f8;
        }
      }
    }
  }

  .dive-site-filters {
    display: flex;
    margin-bottom: 60px;
    &__item  {
      width: 213px;
      margin-right: 20px;
    }
  }

  .dive-center-legend {
    display: flex;
    margin-bottom: 60px;
    &__item {
      display: flex;
      align-items: center;
      margin-right: 28px;
      &__icon {
        margin-right: 8px;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
      }
      &__text {
      }
    }
  }

  .dive-site-map  {
    width: 100%;
    height: 80vh;
  }
`;
