import React from 'react';
import { ButtonLoaderStyle } from './button-loader-style';

interface ButtonLoaderProps {}
const ButtonLoader: React.FC<ButtonLoaderProps> = (props: ButtonLoaderProps) => {
  return (
    <ButtonLoaderStyle>
      <div className="spinner">
        <div className="spinner-body">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </ButtonLoaderStyle>
  );
};
export default ButtonLoader;
