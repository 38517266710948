import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CardMapStyle = styled.div`
  .card-map-veil {
    position: absolute;
    width: 3000px;
    height: 3000px;
    top: -1500px;
    left: -1500px;
    background: black;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    &.active {
      visibility: visible;
    }
  }
  .card-map-group-marker {
    background: ${colors['CO-001/100']};
    color: white;
    width: 30px;
    height: 30px;
    text-align: center;
    border: solid ${colors['CO-001/30']} 5px;
    font-size: 12px;
    line-height: 20px;
    min-width: 30px;
    border-radius: 50%;
  }
  .card-map-marker {
    position: relative;

    &__icon {
      cursor: pointer;
      z-index: 2;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -12px;
      left: -12px;
    }
  }

  .card-map-pop  {
    z-index: 2;
    position: absolute;
    bottom: 25px;
    left: -98px;

    background: white;
    width: 207px;
    border-radius: 5px;
    opacity: 1;
    visibility: hidden;
    transition: 0.3s all ease-in-out;
    opacity: 0;
    &::after {
      content: '';
      width: 12px;
      height: 12px;
      background: white;
      position: absolute;
      bottom: -4px;
      transform: rotate(45deg);
      border-radius: 4px;
      left: 92px;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  .card-map-pop-slider  {
    height: 120px;
    background: paleturquoise;
    position: relative;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    .card-map-slide {
      width: 100%;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }
    }
  }

  .slick-dots  {
    width: 100%;
    justify-content: center;
    margin-top: 0px;
    position: absolute;
    bottom: 8px;
    li  {
      &:last-of-type button {
        margin-right: 0px;
      }
      &.slick-active button {
        width: 12px;
        height: 6px;
        background: ${colors['PR-001/100']};
        border-radius: 14px;
        transition: all 0.3s ease-in-out;
      }
    }
    li button {
      border: none;
      width: 6px;
      height: 6px;
      display: inline-block;
      background: #e2e5ea;
      border-radius: 8px;
      transition: all 0.6s ease-in-out;
      margin-right: 4px;
      padding: 0;
      cursor: pointer;
    }
  }
  .card-map-pop-container {
    padding: 18px 12px;
    color: ${colors['NE-001/100']};

    &__type {
      display: flex;
      align-items: center;
      color: ${colors['NE-002/100']};
      margin-bottom: 4px;
      text-transform: uppercase;
      &__icon {
        margin-right: 4px;
      }
      &__text {
        padding-bottom: 2px;
      }
      &__edit {
        text-transform: none;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        color: ${colors['PR-001/100']};
        cursor: pointer;
      }
    }
    &__title {
    }
    &__location {
      font-size: 11px;
      line-height: 16px;
      font-weight: 300;
    }
    &__depth {
      font-size: 11px;
      line-height: 16px;
      font-weight: 300;
    }
    &__rate {
      margin-bottom: 6px;
    }
    &__next {
      text-transform: uppercase;
    }
  }
`;
