import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalCreateAdsMembershipStyle = styled.div`
  padding: 0 32px 32px 32px;
  width: 416px;
  .modal-create-ad-section {
    margin-bottom: 40px;
    &__title  {
      color: ${colors['SE-002/100']};
      margin-bottom: 14px;
    }
    &__form  {
      width: 100%;
    }
  }
  .modal-create-ad__button {
    &__item {
      margin-bottom: 16px;
      &:last-child  {
        ºmargin-bottom: 0px;
      }
    }
  }
  .modal-create-add-languages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;
  }
`;
