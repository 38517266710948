import styled from 'styled-components';

export const StarRateContainer = styled.div`
  display: flex;
  &.big  {
    img {
      width: 40px;
      height: 40px;
    }
  }
  &.middle  {
    img {
      width: 32px;
      height: 32px;
    }
  }
`;
