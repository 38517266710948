import React, { Component } from 'react';
import { SelectBoxContainer } from './select-box-style';
import { images } from '../../assets/images';
import TooltipBox from '../tooltip-box/tooltip-box';
import JSON from '../../utils/json';
import { ERROR_MESSAGES_ES } from '../../constants/error-messages';
import BodyText from '../text/body-text/body-text';
import InfoText from '../text/info-text/info-text';
import LabelText from '../text/label-text/label';
import Scrollbars from 'react-custom-scrollbars';
import { capitalize } from '../../utils/texts';
import { colors } from '../../assets/colors/colors';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface SelectBoxProps extends WithTranslation {
  className?: string;
  name?: string;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  disabled?: boolean;
  required?: boolean;
  errorCode?: string;
  searchable?: boolean;
  suggestedOptionsText?: {
    [key: string]: string;
  }[];
  optionsText: {
    [key: string]: string;
  }[];
  optionKey: string;
  optionValue: string;

  placeholder?: string;
  labelText?: string;
  initialValue?: string;
  defaultValue?: string;
  initialValueFilterText?: string;
  defaultValueFilterText?: string;
  initialMultipleValues?: string[];
  defaultMultipleValues?: string[];
  onChange: (value: string, label?: string) => void;
  onSearch?: (value: string) => void;
  onChangeMultiple?: (value: string[]) => void;
  clearValue?: boolean;

  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
  multiple?: boolean;
  t: any;
}

interface SelectBoxState {
  showList: boolean;
  showTooltip: boolean;
  value: string | string[];
  error: string;
  filterText: string;
  filteredOptions?: { [key: string]: string }[];
}

class SelectBox extends Component<SelectBoxProps, SelectBoxState> {
  toggleContainer: any;

  constructor(props: SelectBoxProps) {
    super(props);
    // console.log("-----------",props)
    const auxFilterText = props.optionsText ? props.optionsText.filter((op: any) => op.value === props.initialValue) : [];
    const filterText = auxFilterText.length > 0 ? auxFilterText[0].label : '';
    this.state = {
      showList: false,
      showTooltip: false,
      value: props.multiple ? props.defaultMultipleValues || [] : props.defaultValue || props.initialValue || '',
      error: '',
      filterText: props.defaultValueFilterText || filterText,
    };
    this.toggleContainer = React.createRef();

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  static defaultProps = {
    icon: images.arrowDown2BlueSvg,
    iconDisabled: images.arrowDown2BlueSvg,
  };

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentDidUpdate(prevProps: SelectBoxProps) {
    const { value, showList, filterText, filteredOptions } = this.state;
    const {
      initialValue,
      initialMultipleValues,
      clearValue,
      defaultValueFilterText,
      optionsText,
      searchable,
    } = this.props;

    if (initialValue && initialValue !== value && !showList && initialValue !== '') {
      const filterText = optionsText.filter((op: any) => op.value === initialValue);
      this.setState({
        value: initialValue,
        filterText: filterText.length > 0 ? filterText[0].label : '',
      });
    } else if (initialMultipleValues && initialMultipleValues !== value && !showList) {
      this.setState({ value: initialMultipleValues });
    } else if (
      clearValue &&
      prevProps.clearValue !== clearValue &&
      (value !== '' || filterText !== '' || prevProps.optionsText !== optionsText)
    ) {
      this.setState({ value: '', filterText: '', filteredOptions: optionsText });
    }
    if (prevProps.defaultValueFilterText === '' && defaultValueFilterText) {
      this.setState({ filterText: defaultValueFilterText });
    }

    if (optionsText && searchable && prevProps.optionsText !== optionsText && optionsText !== filteredOptions) {
      let filteredOptionsNew = optionsText;
      if (!clearValue) {
        filteredOptionsNew = optionsText.filter(option => {
          if (!option) return false;
          return option.label.toUpperCase().includes(filterText.toUpperCase());
        });
      }
      this.setState((prevState: SelectBoxState) => ({
        filteredOptions: filteredOptionsNew,
        showList: filterText ? true : prevState.showList,
      }));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    if (this.state.showList && !this.toggleContainer.current.contains(event.target)) {
      this.onToggle(false);
    }
  }

  onToggle(show: boolean) {
    this.setState({ showList: show });
  }

  handleSelect = (value: string) => {
    const { onChange, optionsText, optionKey, optionValue, multiple, onChangeMultiple } = this.props;
    const state = this.state;
    if (value !== '') {
      const label = value !== '' ? JSON.getLabel(value, optionsText, optionKey, optionValue) : '';
      this.setState({ filterText: label });
    }
    let valueSet: string | string[] = state.value;
    if (multiple && typeof valueSet !== 'string' && value !== '') {
      if (!valueSet.includes(value)) valueSet.push(value);
    } else if (value !== '') {
      valueSet = value;
    }
    this.setState({ value: valueSet, showList: false }, () => {
      const label = value !== '' ? JSON.getLabel(value, optionsText, optionKey, optionValue) : '';
      onChange(value, label);
      if (multiple && onChangeMultiple && typeof state.value !== 'string') {
        onChangeMultiple(state.value);
      }
    });
  };

  handleInputChange(event: any) {
    const { optionsText, onSearch, searchable } = this.props;
    const value = event.target.value;
    onSearch && onSearch(value);
    if (optionsText && searchable) {
      const filteredOptions = optionsText.filter(option => {
        if (!option) return false;
        return option.label.toUpperCase().includes(value.toUpperCase());
      });
      this.setState({ filteredOptions, filterText: value, showList: true });
    } else {
      this.setState({ filterText: value, showList: true });
    }
  }

  handleDeleteOption(value: string) {
    const { onChangeMultiple } = this.props;
    if (typeof this.state.value !== 'string') {
      const valueOptionDeleted = this.state.value.filter(val => val !== value);
      onChangeMultiple && onChangeMultiple(valueOptionDeleted);
      this.setState({ value: valueOptionDeleted });
    }
  }

  render() {
    const {
      disabled,
      className,
      icon,
      iconDisabled,
      labelText,
      placeholder,
      errorCode,
      suggestedOptionsText,
      optionsText,
      optionKey,
      optionValue,
      withTooltip,
      tooltipTitle,
      tooltipText,
      required,
      searchable,
      multiple,
      onSearch,
      t
    } = this.props;
    const { showList, showTooltip, value, filteredOptions, filterText } = this.state;
    const errorText = errorCode ? ERROR_MESSAGES_ES[errorCode] : '';

    // let selectLabel = placeholder !== undefined ? placeholder : 'Seleccionar';
    let selectLabel = '';
    if (value !== '') {
      if (typeof value === 'string' || !value) {
        selectLabel = JSON.getLabel(value, optionsText, optionKey, optionValue);
      } else if (value.forEach) {
        value.forEach(val => {
          selectLabel = `${selectLabel}, ${JSON.getLabel(val, optionsText, optionKey, optionValue)}`;
        });
      }
    }
    const optionsTextFiltered = filteredOptions ? filteredOptions : optionsText;

    return (
      <SelectBoxContainer
        ref={this.toggleContainer}
        className={`${className} ${showList ? 'show-list' : ''} ${disabled ? 'disabled' : ''} ${required ? 'required' : ''
          } ${errorCode ? 'error' : ''} ${value !== '' ? 'complete' : ''}`}
        muliple={multiple}
      >
        <div className="input-box-topbar">
          {labelText ? <div className="input-box-topbar-label">
            <LabelText>{labelText}</LabelText>
          </div> : ''
          }

          {withTooltip && (
            <div className="input-box-topbar-icon">
              <div
                className="input-box-topbar-img"
                onClick={() => this.setState((prevState: SelectBoxState) => ({ showTooltip: !prevState.showTooltip }))}
              >
                <img src={disabled ? images.infoGreySvg : images.infoBlueSvg} />
              </div>
              <div className="input-box-tooltip">
                <TooltipBox active={showTooltip} tooltipTitle={tooltipTitle} tooltipText={tooltipText} />
              </div>
            </div>
          )}
        </div>

        <div
          className="input-box-main"
          onClick={() => {
            this.onToggle(!showList);
          }}
        >
          <div className="input-box-main-field">
            {searchable || onSearch ? (
              <input
                value={filterText}
                placeholder={placeholder}
                onChange={evt => this.handleInputChange(evt)}
                autoComplete="nope"
              />
            ) : typeof value !== 'string' && value && value.length !== 0 ? (
              value.map((val, index) => {
                return (
                  <div className="input-box-multi-value" key={index} onClick={() => this.handleDeleteOption(val)}>
                    <BodyText>{capitalize(JSON.getLabel(val, optionsText, optionKey, optionValue))}</BodyText>
                  </div>
                );
              })
            ) : selectLabel === '' ? (
              <BodyText color={colors['NE-002/100']}>{placeholder || 'Seleccionar'}</BodyText>
            ) : (
                    <BodyText>{selectLabel}</BodyText>
                  )}
          </div>
          <div className="input-box-icon">
            <img src={disabled ? iconDisabled : icon} />
          </div>
        </div>
        <ul className="input-box-main-list">
          <Scrollbars
            renderTrackVertical={props => <div {...props} className="track-vertical" />}
            renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
            style={{ width: '100%' }}
            autoHide={true}
            autoHideTimeout={200}
            autoHideDuration={200}
            autoHeight={true}
            autoHeightMin={60}
            autoHeightMax={160}
          >
            {suggestedOptionsText &&
              suggestedOptionsText.map((option, i) => (
                <li
                  key={i}
                  onClick={() => {
                    this.handleSelect(option[optionKey]);
                  }}
                >
                  <BodyText>{capitalize(option[optionValue])}</BodyText>
                </li>
              ))}
            {suggestedOptionsText && <div className="selector-separator" />}
            {!!optionsTextFiltered.length ? (
              optionsTextFiltered.map((option, i) => (
                <li
                  key={i}
                  onClick={() => {
                    this.handleSelect(option[optionKey]);
                  }}
                >
                  {option && option.icon && (
                    <div className="input-box-option-icon">
                      <img src={option.icon} />
                    </div>
                  )}
                  {option &&
                    <BodyText>{capitalize(option[optionValue])}</BodyText>
                  }

                </li>
              ))
            ) : (
                <li>
                  <div>
                    <BodyText>{t('components:general.noresults')}</BodyText>
                    {(searchable || onSearch) && (
                      <BodyText>{t('components:general.moredata')}</BodyText>
                    )}
                  </div>
                </li>
              )}
          </Scrollbars>
        </ul>

        <div className="input-box-error">{errorCode && <InfoText>{errorText}</InfoText>}</div>
      </SelectBoxContainer>
    );
  }
}

export default withTranslation(['components']) (SelectBox);
