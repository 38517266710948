import services from './services';

export const getBookings = (params: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: `bookings/table`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getOneBookings = (bookingId: string, params?: any) => {
  const endpoint = `bookings/${bookingId}`;
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getBookingsCentre = (centreId: string, params: any) => {
  const endpoint = `bookings/centre/${centreId}/table`;
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getBookingsActivity = (activityId: string, params?: any) => {
  const endpoint = `bookings/activity/${activityId}/table`;
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};


export const uploadBookings = (bookingId: string, data: any) => {
  const endpoint = `bookings/${bookingId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const cancelBooking = (bookingId: string, data: any) => {
  const endpoint = `bookings/${bookingId}/cancel`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const confirmBooking = (centreId: string, activityId: string, bookingId: string, params?: any) => {
  const endpoint = `centres/${centreId}/activities/${activityId}/bookings/${bookingId}`;
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const rejectBooking = (bookingId: string, params?: any) => {
  const endpoint = `bookings/${bookingId}/reject`;
  return new Promise((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const listBooking = ( data: any) => {
  const endpoint = `bookings/filter`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: false,
    };
    services.post(args);
  });
};


export const getOneBookingsFilter = (boonkingId: number,) => {
  const endpoint = `bookings/filter/${boonkingId}`;
  return new Promise<any>((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: false,
    };
    services.get(args);
  });
};