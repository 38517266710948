import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalIncidentTypeStyle = styled.div`
  padding: 32px;
  padding-top: 0;
  width: 416px;
  color: ${colors['NE-001/100']};

  .sign-up-diver-form-container-col2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 100%;
    &__item {
      width: 100%;
      position: relative;
      margin-right: 20px;
    }
    &__button {
      width: 120px;
      display: flex;
      padding-bottom: 3px;
    }
  }

  .modal-incident-type-list {
    margin-bottom: 48px;
    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
      &__icon {
        cursor: pointer;
        margin-left: 12px;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .modal-incidence-detail-buttons {
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .modal-incident-languages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;
  }
`;
