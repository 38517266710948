import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ModalManageDiveLevelsStyle = styled.div`
  padding: 58px 32px 32px 32px;
  width: 416px;

  .modal-change-pass-title {
    margin-bottom: 12px;
  }
  .modal-change-pass-text  {
    margin-bottom: 28px;
    color: ${colors['NE-002/100']};
  }
  .manage-dive-levels-line {
    width: 100%;
    height: 1px;
    background: ${colors['NE-002/100']};
    margin-bottom: 40px;
  }
  .modal-change-pass-form-level-row  {
    display: flex;
    width: 100%;
    &__input {
      margin-right: 20px;
    }

    &__select {
      margin-right: 12px;
    }

    &__delete {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__full {
      width: 100%;
    }
    &__scale {
      margin-right: 20px;
      width: 100%;
    }

    &__add {
      min-width: 130px;
      & > div {
        margin-top: 26px;
      }
    }
  }

  .modal-change-pass-form-level  {
  }
`;
