import React from 'react';
import { NavigationTabContainer } from '../../../../components/navigation-tabs/navigation-tabs-style';
import { NavLink } from 'react-router-dom';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';

interface MyCenterProfileNavProps {
  centreId: string;
}

export default class MyCenterProfileNav extends React.Component<MyCenterProfileNavProps, any> {
  render() {
    const { centreId } = this.props;
    return (
      <NavigationTabContainer>
        <NavLink to={`/centres/${centreId}/business/profile/data`}>
          <div className="navigation-item">
            <BodyDestacadoText>Datos</BodyDestacadoText>
          </div>
        </NavLink>

        <NavLink to={`/centres/${centreId}/business/profile/description`}>
          <div className="navigation-item">
            <BodyDestacadoText>Descripción</BodyDestacadoText>
          </div>
        </NavLink>

        <NavLink to={`/centres/${centreId}/business/profile/services`}>
          <div className="navigation-item">
            <BodyDestacadoText>Servicios</BodyDestacadoText>
          </div>
        </NavLink>

        <NavLink to={`/centres/${centreId}/business/profile/facilites`}>
          <div className="navigation-item">
            <BodyDestacadoText>Instalaciones</BodyDestacadoText>
          </div>
        </NavLink>
      </NavigationTabContainer>
    );
  }
}
