import React from 'react';
import ModalContainer from "../../../../../components/modals/modal-container";
import {images} from "../../../../../assets/images";
import { ModalArchiveChatStyle } from "./modal-archive-chat-style"
import BodyDestacadoText from "../../../../../components/text/body-destacado-text/body-destacado-text";
import MainButton from "../../../../../components/main-button";

interface ModalArchiveChatProps {
  centreId?: string;
  showModal: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const ModalArchiveChat = ({ onCancel, onSuccess, showModal }: ModalArchiveChatProps) => {

  return (
    <ModalContainer modalClose={onCancel} active={showModal} title=''>
      <ModalArchiveChatStyle>
        <div className="modal-icon-container">
          <img src={images.alertBlueSvg} alt="alerta" className="modal-icon"/>
        </div>
        <BodyDestacadoText className="modal-text">
          Vas a archivar una conversación ¿estas seguro?
        </BodyDestacadoText>

        <MainButton
          type="primary"
          text="Archivar"
          onClick={onSuccess}
        />
      </ModalArchiveChatStyle>
    </ModalContainer>
  );
};

export default ModalArchiveChat;
