import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalCancelationActivityContainer = styled.div`
  width: 780px;
  padding: 48px 32px 32px;

  .modal-cancelation-activity-name {
    font-family: Plain;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 2px;
    color: ${colors['SE-002/100']};
  }

  .modal-cancelation-activity-title {
    font-family: Plain;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
    color: ${colors['NE-001/100']};
  }

  .modal-cancelation-activity-alert {
    font-family: Plain;
    font-size: 14px;
    font-weight: 100;
    line-height: 22px;
    margin-bottom: 40px;
    color: ${colors['NE-001/100']};
  }

  .modal-cancelation-activity-message {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .message-checkbox {
      margin-right: 16px;
    }
    .message-text {
      font-family: Plain;
      font-size: 12px;
      line-height: 18px;
    }
    &.last-child {
      margin-bottom: 48px;
    }
  }

  .modal-cancelation-activity-inputarea-title {
    font-family: Plain;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 14px;
    color: ${colors['NE-001/100']};
  }

  .modal-cancelation-activity-inputarea-content {
    height: 120px;
    overflow-y: hidden;
  }

  .modal-cancelation-activity-button {
    width: max-content;
  }
`;
