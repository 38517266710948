interface KeyLabel {
  [key: string]: string;
}

const JSON = {
  getLabel: (key: string, data: KeyLabel[], keyName: string, valueName: string) => {
    let label = '';
    data.forEach((item) => {
      if (item[keyName] === key) {
        label = item[valueName];
      }
    });
    return label;
  },
};

export default JSON;
