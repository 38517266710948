import React from 'react';
import { H2TextContainer } from './h2-text-styles';

interface H2TextProps {
  children: any;
  className?: string;
  color?: string;
}

const H2Text = ({ children = '', className = '', color = '' }: H2TextProps) => {
  return (
    <H2TextContainer color={color} className={className}>
      {children}
    </H2TextContainer>
  );
};

export default H2Text;
