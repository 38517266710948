import React from 'react';
import MainButton from '../../../../components/main-button';
import ModalContainer from '../../../../components/modals/modal-container';
import { FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import InputBox from '../../../../components/input-box';
import InputArea from '../../../../components/input-area/input-area';
import InputUploadFiles from '../../../../components/input-upload-file/input-upload-file';
import SelectBox from '../../../../components/select-box';
import { images } from '../../../../assets/images';
import { ModalCreateCatalogStyle } from './modal-create-catalog-style';
import SubheadDestacadoText from '../../../../components/text/subhead-destacado-text/subhead-destacado-text';
import { LANGUAGES, LANGUAGES_KEY_VALUES } from '../../../../constants/languages';
import { DiveDisciplines } from '../../../../constants/dive-dropdowns';
import { AttachedFile } from '../../../../types/file';
import {
  postCertifierCourse,
  postCertifierCourseLicense,
  patchCertifierCourse,
} from '../../../../services/collaborator';
import { getCourses } from '../../../../services/courses';
import { getLevels } from '../../../../services/levels';
import { ILevels } from '../../../../models/levels';
import { IDropdown } from '../../../../models/generic';
import { COURSE_KINDS_DROPDOWN } from '../../../../constants/course';
import { copyOf } from '../../../../utils/other';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_KEY } from '../../../../env';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ModalCreateCatalogProps extends WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  certifierId: string;
  course?: any;
  editMode?: boolean;
  t:any;
}

interface ModalCreateCatalogState {
  step: number;
  selectedLanguage: string;
  selectedLanguages: string[];
  levels: IDropdown[];
  courses: IDropdown[];
  catalog: {
    name: string;
    discipline: string;
    kind: string;
    detail: string;
    description: any;
    minimumRequisites: {
      training: number;
      // profile: string;
      age: string;
    };
    previousCourses?: string[];
    eLicense: AttachedFile | string | null;
    certifier: number;
  };
  errors: { [key: string]: string };
}

class ModalCreateCatalog extends React.Component<ModalCreateCatalogProps, ModalCreateCatalogState> {
  constructor(props: ModalCreateCatalogProps) {
    super(props);

    this.state = {
      step: 1,
      selectedLanguage: '',
      selectedLanguages: [],
      catalog: {
        name: '',
        discipline: '',
        kind: '',
        detail: '',
        description: {},
        minimumRequisites: {
          training: NaN,
          age: '0',
        },
        previousCourses: [],
        eLicense: null,
        certifier: 0,
      },
      levels: [],
      courses: [],
      errors: {},
    };
  }

  componentDidMount() {
    const { course } = this.props;
    if (course) {
      const formattedCourse = copyOf(course);
      let newSelectedLanguages: any[] = [];
      if (formattedCourse.previousCourses) {
        let formatted = [];
        for (const prevCourse of formattedCourse.previousCourses) {
          if (prevCourse.id) formatted.push(prevCourse.id.toString());
        }
        formattedCourse.previousCourses = formatted;
      }
      if (course.description) {
        formattedCourse.description = course.description;
        newSelectedLanguages = Object.keys(course.description);
      }
      this.setState({ catalog: formattedCourse, selectedLanguages: newSelectedLanguages });
    }
    this.getLevels();
  }

  componentDidUpdate(prevProps: ModalCreateCatalogProps) {
    const { course } = this.props;

    if (course && course !== prevProps.course) {
      const newCourse = copyOf(course);
      let newSelectedLanguages: any[] = [];

      if (course.description && prevProps.course && prevProps.course.description !== course.description) {
        newCourse.description = course.description;
        newSelectedLanguages = Object.keys(course.description)
      }
      this.setState({ catalog: newCourse, selectedLanguages: newSelectedLanguages })
    }
  }

  async handleSubmit() {
    const { onSuccess, certifierId, course } = this.props;
    const { catalog } = Object.assign(this.state);
    catalog.level = catalog.minimumRequisites.training;
    if (catalog.previousCourses && catalog.previousCourses.length === 0) {
      delete catalog.previousCourses;
    } else {
      catalog.previousCourses = catalog.previousCourses.map((value: string) => parseInt(value));
    }
    let resp: any;
    if (course) {
      let catalogToSave = catalog;
      delete catalogToSave.certifier;
      resp = await patchCertifierCourse(certifierId, course.id.toString(), catalogToSave);
    } else {
      resp = await postCertifierCourse(certifierId, catalog);
    }
    if (!!catalog.eLicense) {
      const formData = new FormData();
      formData.append('file', catalog.eLicense.data);
      await postCertifierCourseLicense(certifierId, resp.data.id.toString(), formData);
    }
    onSuccess();
  }

  async getLevels() {
    const params = {
      filter: {
        orderBy: [{ key: 'level', direction: 'ASC' }],
      },
    };
    const resp = await getLevels(params);
    const levels = resp.data.data.map((level: ILevels) => {
      return { value: level.id.toString(), label: level.name };
    });
    this.setState({ levels }, () => this.getCourses());
  }

  async getCourses() {
    const { course, certifierId, t } = this.props;
    const params = {
      filter: {
        relations: ['certifier'],
      },
    };

    // ver como ordenar??
    const resp = await getCourses(params);
    let coursesArray = [{ value: '-1', label: t('modalMessages:none') }]
    let courses = [{ value: '-1', label: t('modalMessages:none') }].concat(
      resp.data.data
        .filter((c: any) => !course || c.id !== course.id)
        .map((course: any) => {
          if (course.certifier && course.certifier.id && course.certifier.id == Number(certifierId)) {
            coursesArray.push({ value: course.id.toString(), label: course.name })
            return { value: course.id.toString(), label: course.name };
          }
        })
    );
    courses = coursesArray;
    this.setState({ courses });
  }

  hasErrors(errors: { [key: string]: string }, fields: string[]) {
    return !fields.every(field => errors[field] === '');
  }

  validate(value: string | AttachedFile | number | string[], field: string, required: boolean) {
    if (field === 'age') {
      if (value != '' && value <= 0) {
        return 'invalid-negative'
      }
      return '';
    } else {
      if (required && (!value || value === '')) return 'required';
    }
    if (field === 'age' && value <= 0) return 'invalid-negative';
    return '';
  }

  handleChange(value: string | AttachedFile | number | string[], field: string, required: boolean = true, subfield?: string) {
    const { errors, catalog } = Object.assign(this.state);

    const error = this.validate(value, field, required);
    if (subfield) {
      catalog[subfield][field] = value;
      errors[`${subfield}.${field}`] = error;
    } else {
      catalog[field] = value;
      errors[field] = error;
    }
    this.setState({ catalog, errors });
  }

  validateFirstStep() {
    const { errors, catalog } = Object.assign(this.state);
    const requiredField = ['name', 'discipline', 'kind'];
    requiredField.forEach(field => {
      errors[field] = this.validate(catalog[field], field, true);
    });
    this.setState({ errors });
    return !this.hasErrors(errors, requiredField);
  }

  validateSecondStep() {
    const { errors, catalog } = Object.assign(this.state);
    // errors.detail = this.validate(catalog.detail, 'detail', true);
    const requiredField: string[] = [];
    // Object.keys(LANGUAGES_KEY_VALUES).forEach(lang => {
    //   errors[`description.${lang}`] = this.validate(catalog.description[lang], 'description', true);
    //   requiredField.push(`description.${lang}`);
    // });
    this.setState({ errors });
    return !this.hasErrors(errors, requiredField);
  }

  validateThirdStep() {
    const { errors, catalog } = Object.assign(this.state);
    const requiredField: any = {
      //training: true,
      //age: false,
    };

    Object.keys(requiredField).forEach(field => {
      errors[`minimumRequisites.${field}`] = this.validate(
        catalog.minimumRequisites[field],
        field,
        requiredField[field]
      );
    });
    this.setState({ errors });
    return !this.hasErrors(errors, Object.keys(errors));
  }

  nextStep() {
    const { step } = this.state;
    switch (step) {
      case 1:
        if (this.validateFirstStep()) {
          this.setState({ step: step + 1 });
        }
        break;
      case 2:
        if (this.validateSecondStep()) {
          this.setState({ step: step + 1 });
        }
        break;
      case 3:
        if (this.validateThirdStep()) {
          this.handleSubmit();
        }
        break;
    }
  }

  previousStep() {
    if (this.state.step > 1) {
      this.setState({ step: this.state.step - 1 });
    }
  }

  handleChangeDescriptionsTest(e: any) {
    e.preventDefault();
  }

  renderContent() {
    switch (this.state.step) {
      case 1:
        return this.general();
      case 2:
        return this.details();
      case 3:
        return this.requirements();
      default:
        break;
    }
  }

  general() {
    const { catalog, errors } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="modal-certifier-form__title">
          <BodyDestacadoText>{t('modalMessages:modal-bill.general-data')}</BodyDestacadoText>
        </div>
        <FormGrid>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                withTooltip={true}
                isTooltipInside={true}
                tooltipTitle=""
                tooltipText={t("modalMessages:nameproj")}
                placeholder={t("modalMessages:name")}
                className="rounded-title"
                required={true}
                type="text"
                icon={images.infoBlueSvg}
                value={catalog.name}
                labelText={t("modalMessages:name")}
                errorCode={errors.name}
                onChange={value => this.handleChange(value, 'name')}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <SelectBox
                className="rounded-title"
                labelText={t("modalMessages:discipline")}
                placeholder={t("modalMessages:discipline")}
                required={true}
                optionsText={DiveDisciplines}
                optionKey={'value'}
                optionValue={'label'}
                initialValue={catalog.discipline}
                defaultValue={catalog.discipline}
                icon={images.arrrowDown2Svg}
                errorCode={errors.discipline}
                onChange={value => this.handleChange(value, 'discipline')}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <SelectBox
                className="rounded-title"
                labelText={t("modalMessages:type")}
                placeholder={t("modalMessages:type")}
                required={true}
                optionsText={COURSE_KINDS_DROPDOWN}
                optionKey={'value'}
                optionValue={'label'}
                icon={images.arrrowDown2Svg}
                initialValue={catalog.kind}
                defaultValue={catalog.kind}
                errorCode={errors.kind}
                onChange={value => this.handleChange(value, 'kind')}
              />
            </FormGridItem>
          </FormGridRow>
        </FormGrid>

        <div className="item-modal-message-container">
          <InputUploadFiles
            className="dark-blue-label"
            label={t("modalMessages:dig")}
            multiple={false}
            errorCode={errors.eLicense}
            required={true}
            value={typeof catalog.eLicense === 'string' ? catalog.eLicense : undefined}
            selectedFiles={catalog.eLicense && typeof catalog.eLicense !== 'string' ? [catalog.eLicense] : undefined}
            onChange={(file: AttachedFile[]) => {
              this.handleChange(file[0], 'eLicense');
            }}
          />

          <div className="item-modal-message-bottom">
            <div className="item-modal-message-button">
              <MainButton
                type={'primary'}
                text={t("modalMessages:next")}
                onClick={() => {
                  this.nextStep();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  details() {
    const { catalog, selectedLanguage, selectedLanguages, errors } = this.state;
    const {t} = this.props;
    if (
      (selectedLanguage === '' && selectedLanguages.length > 0) ||
      (selectedLanguages.length > 0 && !selectedLanguages.includes(selectedLanguage))
    ) {
      this.setState({ selectedLanguage: selectedLanguages[0] });
    }

    return (
      <div>
        {/* <div className="modal-certifier-form__title">
          <BodyDestacadoText>Detalles</BodyDestacadoText>
        </div> */}

        <FormGrid>
          {/* <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                placeholder="Formación que me habilita"
                className="rounded"
                type="text"
                value={catalog.detail}
                labelText=""
                errorCode={errors.detail}
                onChange={value => this.handleChange(value, 'detail', true)}
              />
            </FormGridItem>
          </FormGridRow> */}

          <div className="modal-certifier-form__title">
            <BodyDestacadoText>{t('modalMessages:desc')}</BodyDestacadoText>
          </div>

          <FormGridRow>
            <FormGridItem type="col-0">
              <SelectBox
                className="rounded-title"
                labelText={t('modalMessages:desc')}
                placeholder={t('modalMessages:langshow')}
                multiple={true}
                required={true}
                initialMultipleValues={selectedLanguages}
                errorCode={errors.description}
                optionsText={LANGUAGES}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={''}
                icon={images.arrowDown2BlueSvg}
                onChange={() => { }}
                onChangeMultiple={selectedLanguages => this.setState({ selectedLanguages: selectedLanguages })}
              />
            </FormGridItem>
          </FormGridRow>
          <div className={'modal-create-add-languages'}>
            {selectedLanguages.map((lang: string, index: number) => (
              <div className={'modal-create-add-languages__item'} key={index}>
                <MainButton
                  onClick={() => { this.setState({ selectedLanguage: lang }) }}
                  text={LANGUAGES_KEY_VALUES[lang]}
                  type={selectedLanguage === lang ? 'primary' : errors[`description.${lang}`] === '' || !errors[`description.${lang}`] ? 'secondary' : 'secondary-red'}
                />
              </div>
            ))}
          </div>
          {selectedLanguage !== '' && selectedLanguages.includes(selectedLanguage) && (
            <FormGridRow key={`description-${selectedLanguage}`}>
              <FormGridItem type="col-0">
                {/*<InputArea
                  name={selectedLanguage}
                  placeholder={`Descripción ${selectedLanguage}`}
                  required={true}
                  errorCode={''}
                  value={catalog.description[selectedLanguage] ? catalog.description[selectedLanguage] : ''}
                  onChange={(event: any) => this.handleChange(event.target.value, selectedLanguage, true, 'description')}
                />*/}

                <Editor 
                  id={selectedLanguage}
                  textareaName={selectedLanguage}
                  apiKey={TINYMCE_KEY}
                    init={{
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                      'bold italic | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat '
                    }}
                    /*onKeyUp={(event: any) => this.handleChangeUpdate(event)}*/
                    onEditorChange={(event: any) => this.handleChange(event, selectedLanguage, true, 'description')}
                    ></Editor>

              </FormGridItem>
            </FormGridRow>
          )}

        </FormGrid>

        <div className="item-modal-message-bottom">
          <div className="item-create-message-button">
            <MainButton type={'secondary'} iconImg={images.arrowLeftSvg} text="" onClick={() => this.previousStep()} />
          </div>
          <div className="item-modal-message-button">
            <MainButton
              type={'primary'}
              text={t('modalMessages:next')}
              onClick={() => {
                this.nextStep();
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  requirements() {
    const { catalog, errors, levels, courses } = this.state;
    const { editMode, t } = this.props;


    return (
      <div>
        <div className="modal-certifier-form__title">
          <BodyDestacadoText>{t('modalMessages:diverreq').toUpperCase()}</BodyDestacadoText>
        </div>
        <FormGrid>
          <FormGridRow>
            <FormGridItem type="col-0">
              <SelectBox
                className="rounded-title"
                labelText={t('modalMessages:levelad')}
                placeholder={t('modalMessages:levelad')}
                errorCode={errors['minimumRequisites.training']}
                required={true}
                optionsText={levels}
                optionKey={'value'}
                optionValue={'label'}
                icon={images.arrrowDown2Svg}
                initialValue={catalog.minimumRequisites.training.toString()}
                defaultValue={catalog.minimumRequisites.training.toString()}
                onChange={value => {
                  this.handleChange(parseInt(value, undefined), 'training', true, 'minimumRequisites');
                }}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              { }
              <SelectBox
                className="rounded-title"
                labelText={t('modalMessages:titreq')}
                placeholder={t('modalMessages:titreq')}
                errorCode={errors['previousCourses']}
                required={true}
                multiple={true}
                optionsText={courses}
                optionKey={'value'}
                optionValue={'label'}
                icon={images.arrrowDown2Svg}
                defaultMultipleValues={catalog.previousCourses}
                initialMultipleValues={catalog.previousCourses}
                onChange={() => { }}
                onChangeMultiple={(values: string[]) => {
                  this.handleChange(values, 'previousCourses', false);
                }}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                errorCode={errors.age}
                placeholder={t('modalMessages:minage')}
                labelText={t('modalMessages:minage')}
                className="rounded-title"
                type="number"
                value={catalog.minimumRequisites.age.toString() == '0' ? '' : catalog.minimumRequisites.age.toString()}
                required={false}
                onChange={value => this.handleChange(value, 'age', true, 'minimumRequisites')}
              />
              {/*
              <SelectBox
                errorCode={errors.age}
                className="rounded-title"
                labelText="Edad mínima"
                placeholder="Edad mínima"
                required={true}
                initialValue={catalog.minimumRequisites.age.toString()}
                optionsText={MIN_AGE}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={catalog.minimumRequisites.age.toString()}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.handleChange(value, 'age', true, 'minimumRequisites')}
              />
              */}
            </FormGridItem>
          </FormGridRow>
        </FormGrid>

        <div className="item-modal-message-bottom">
          <div className="item-create-message-button">
            <MainButton type={'secondary'} iconImg={images.arrowLeftSvg} text="" onClick={() => this.previousStep()} />
          </div>
          <div className="item-modal-message-button">
            <MainButton
              type={'primary'}
              text={t('modalMessages:save')}
              onClick={() => {
                this.nextStep();
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { showModal, onCancel, course } = this.props;
    const {t} = this.props;
    const edit = !!course;
    return (
      <ModalContainer title="" active={showModal} modalClose={() => onCancel()}>
        <ModalCreateCatalogStyle>
          <div className="modal-create-catalog-step">Paso {this.state.step}</div>
          <div className="modal-create-catalog-title">
            <SubheadDestacadoText>
              {edit ? t('modalMessages:editcertcat') : t('modalMessages:createcertcat')}
            </SubheadDestacadoText>
          </div>
          {this.renderContent()}
        </ModalCreateCatalogStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages']) (ModalCreateCatalog)
