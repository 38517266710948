import styled from 'styled-components';

export const DropDownStyle = styled.div`
  .drop-top {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    cursor: pointer;
    color: #01030c;
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid #ffffff;
    &__title {
      font-weight: 500;
    }
    &__arrow {
      transition: all 0.5s ease-in-out;
      filter: grayscale(100%);
    }
  }

  .drop-content {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    padding: 0 20px 0px;
    max-height: 0px;
    overflow: hidden;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    &__item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.active {
    .drop-top {
      &__arrow {
        transform: rotate(180deg);
        filter: grayscale(0%);
      }
    }
    .drop-content {
      visibility: visible;
      opacity: 1;
      padding: 20px 20px 20px;
      max-height: 500px;
      background: #f8fafd;
      border-top: 1px solid #bcc5d7;
      border-bottom: 1px solid #bcc5d7;
    }
  }
`;
