import { images } from '../assets/images';

export const CENTRE_TYPES = [
  {
    label: 'Club',
    value: 'club',
  },
  {
    label: 'Centro',
    value: 'centre',
  },
  // {
  //   label: 'Crucero',
  //   value: 'cruise',
  // },
];

export const CENTRE_TYPES_KEY_VALUE: any = {
  club: 'Club',
  centre: 'Centro',
  // cruise: 'Crucero',
};
export const CENTRE_FEE_TYPES=[
  {
    label: 'STANDARD',
    value: 'STANDARD',
  }, {
    label: 'NO_FEE',
    value: 'NO_FEE',
  }, {
    label: 'SPECIAL_FEE',
    value: 'SPECIAL_FEE',
  }, ]

export const CENTRE_FEATURES_DROPDOWN = [
  { label: 'Nitrox', value: 'nitrox', icon: images.nitroxSvg },
  { label: 'Buceo técnico', value: 'technical', icon: images.tecnicalDivingSvg },
  { label: 'Buceo adaptado', value: 'adapted', icon: images.adaptedDivingSvg },
  { label: 'Apnea', value: 'apnea', icon: images.apnea },
  { label: 'Viajes de buceo', value: 'divingtravel', icon: images.divingtravel },
  { label: 'Proyectos Ambientales', value: 'ambient', icon: images.diveSiteMarineReserve },
];
/*
export const CENTRE_FEATURES_KEY_VALUE: any = {
  nitrox: 'Nitrox',
  technical: 'Buceo técnico',
  adapted: 'Buceo adaptado',
};*/

export const CENTRE_FEATURES_KEY_VALUE: any = [
  { label: 'Nitrox', value: 'nitrox', icon: images.nitroxSvg },
  { label: 'Buceo técnico', value: 'technical', icon: images.tecnicalDivingSvg },
  { label: 'Buceo adaptado', value: 'adapted', icon: images.adaptedDivingSvg },
  { label: 'Apnea', value: 'apnea', icon: images.apnea },
  { label: 'Viajes de buceo', value: 'divingtravel', icon: images.divingtravel },
  { label: 'Proyectos Ambientales', value: 'ambient', icon: images.diveSiteMarineReserve },
];

export const FULL_CENTRE_FEATURES_KEY_VALUES: any = {
  nitrox: 'Nitrox',
  technical: 'Buceo técnico',
  adapted: 'Buceo adaptado',
  apnea: 'Apnea',
  divingtravel:'Viajes de buceo',
  ambient: 'Proyectos Ambientales',
};

export const FULL_CENTRE_FEATURES_KEY_ICONS: any = {
  nitrox: images.nitroxSvg,
  technical: images.tecnicalDivingSvg,
  adapted: images.adaptedDivingSvg,
  apnea: images.apnea,
  divingtravel: images.divingtravel ,
  ambient: images.diveSiteMarineReserve ,
};

export const CENTRE_STOCK_DROPDOWN = [];

export const CENTRE_STOCK_KEY_VALUE: any = {
  regulators: 'Reguladores',
  goggles: 'Gafas',
  jacket: 'Jacket',
  rebreather: '',
  sideMount: '',
  harness: '',
  plummet: '',
  buoy: '',
  wetSuit: 'Traje húmedo',
  drySuit: 'Traje seco',
  halfDrySuit: 'Traje semi-seco',
  booties: 'Escarpines',
  paddles: '',
  knife: '',
  divingComputer: 'Ordenador de',
  clapboard: '',
  spotlight: '',
  flashlight: '',
  'photographic camera': '',
  scooter: '',
};
