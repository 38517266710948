import styled from 'styled-components';

export const CreateActivitiesDetailsStyle = styled.div`
  .modal-create-add-languages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;
  }

  .subtitle {
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
  }
`;
