import React, { Component } from 'react';
import { InputUploadFilesContainer } from './input-upload-files-style';
import { images } from '../../assets/images';
import FileItemModal from '../file-item-modal/file-item-modal';
import moment from 'moment';
import 'moment/locale/es.js';
import BodyText from '../text/body-text/body-text';
import InfoText from '../text/info-text/info-text';
import LabelText from '../text/label-text/label';
import { ERROR_MESSAGES_ES } from '../../constants/error-messages';
import { AttachedFile } from '../../types/file';
import FileItemModalUrl from '../file-item-modal-url/file-item-modal-url';
import FileUtils from '../../utils/files';
import { uploadPictures } from '../../services/dives';

interface InputUploadFilesProps {
  className?: string;
  label?: string;
  required?: boolean;
  errorCode?: string;
  value?: string;
  values?: any[];
  deletedPictures?: any[];
  multiple: boolean;
  accept?: string[];
  onChange?: (files: AttachedFile[]) => void;
  onConfirm?: (files: AttachedFile[]) => void;
  onDeleteChange?: (deletedPictures: any[]) => void;
  selectedFiles?: AttachedFile[];
}

interface InputUploadFilesState {
  selectedFiles: AttachedFile[];
  deletedPictures: any[];
}

export default class InputUploadFiles extends Component<InputUploadFilesProps, InputUploadFilesState> {
  state: InputUploadFilesState = {
    selectedFiles: [],
    deletedPictures: []
  };
  fileInput: any;

  componentDidMount() {
    const { selectedFiles } = this.props;
    if (selectedFiles && selectedFiles.length > 0) {
      this.setState({ selectedFiles });
    }
    document.body.classList.add('no-scroll');
  }

  componentDidUpdate(prevProps: InputUploadFilesProps) {
    const { selectedFiles } = this.props;
    if (selectedFiles && selectedFiles.length > 0 && prevProps.selectedFiles !== selectedFiles) {
      this.setState({ selectedFiles });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
  }

  countCorrectFiles() {
    const { selectedFiles } = this.state;
    return selectedFiles.reduce((sum, item) => {
      if (!item.error) {
        return sum + 1;
      }
      return sum;
    }, 0);
  }

  getImage = async (file: File, name: string) => {
    const url = await FileUtils.readFileUrlAsync(file);
    if (localStorage.getItem('arrayImages')) {
      let imagesTemp = localStorage.getItem('arrayImages');
      imagesTemp = imagesTemp + name + ','
      localStorage.setItem('arrayImages', imagesTemp);
    }
    else {
      localStorage.setItem('arrayImages', name + ',');
    }

    // localStorage.setItem('arrayImages', name);
    localStorage.setItem(name, url);
  };

  uploadFile(files: any) {
    const { selectedFiles } = this.state;
    const { accept } = this.props;
    const inputFiles: AttachedFile[] = selectedFiles.slice();
    //this.uploadPartial(files);
    for (const file of files) {
      let error = false;
      let errorType = false;
      if (file.size > 1024 * 1024 * 2) {
        // max size 2MB
        error = true;
      }
      if (accept && !accept.includes('*')) {
        const extension = FileUtils.getExtension(file.name).toLowerCase();
        errorType = !accept.some((ext: string) => ext.toLowerCase() === extension);
      }
      inputFiles.push({
        errorType,
        error,
        data: file,
        name: file.name,
        size: file.size,
        creation_date: moment(file.lastModified).format('DD MMM YYYY'),
      });
      this.getImage(file, file.name)
    }
    //"selectedFiles::",selectedFiles);
    this.setState({ selectedFiles: inputFiles }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(inputFiles);
    });
  }

  removeFile(item: any, index: number) {
    localStorage.removeItem(item.name);
    const { selectedFiles } = this.state;
    const { multiple } = this.props;

    selectedFiles.splice(index, 1);
    if (multiple) {
      Array.from(this.fileInput.value).splice(index, 1);
    }

    this.setState({ selectedFiles }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(selectedFiles);
    });
  }

  removePicture(item: any, index: number) {
    const { deletedPictures } = this.state;
    const { values } = this.props;

    if (values && deletedPictures) {
      values.splice(index, 1);
      deletedPictures.push(item);

      this.setState({ deletedPictures }, () => {
        const { onDeleteChange } = this.props;
        if (onDeleteChange) onDeleteChange(deletedPictures);
      });
    }
  }

  // DEPRECATED: not being used
  confirmFiles() {
    const { selectedFiles } = this.state;
    const { onConfirm } = this.props;

    const tempFiles = selectedFiles.slice();

    this.setState({ selectedFiles: [] }, () => {
      if (onConfirm) onConfirm(tempFiles);
    });
  }

  render() {
    const { selectedFiles, deletedPictures } = this.state;
    const { className, label, required, multiple, errorCode, value, values, accept } = this.props;
    const filesCount = this.countCorrectFiles();
    const errorText = errorCode ? ERROR_MESSAGES_ES[errorCode] : '';
    return (
      <InputUploadFilesContainer
        className={`${className} ${required && 'required'} ${errorCode ? ' error' : ''} ${multiple && 'multiple'} `}
      >
        {label && (
          <div className="item-modal-message-label">
            <LabelText>{label}</LabelText>
          </div>
        )}
        <div className="item-modal-message-container">
          {!multiple && selectedFiles.length < 1 && (
            <>
              <div className="item-modal-message-drag">
                <form className="uploader" encType="multipart/form-data">
                  <input
                    multiple={multiple}
                    type="file"
                    name="file"
                    id="file"
                    onChange={e => this.uploadFile(e.target.files)}
                    accept={accept ? accept.toString() : 'image/*'}
                  />
                </form>
                <div className="item-modal-message-drag__dashed">
                  <div className="item-modal-message-drag__container">
                    {/* <div className="item-modal-message-drag__icon">
                      <img src={value ? value :images.addImageSvg} alt="" />
                    </div> */}
                    <div className="item-modal-message-drag__text">
                      <BodyText>
                        Arrastra y suelta tu{multiple ? 's' : ''} archivo{multiple ? 's ' : ' '}o haz{' '}
                        <span>click aquí</span>
                      </BodyText>
                    </div>
                  </div>
                </div>
              </div>
              {value && (
                <div className={`file-item-container-img`}>
                  <img src={value} alt="" />
                </div>
              )}
              {errorCode ? (
                <div className="input-box-error">{errorCode && <InfoText>{`${errorText}`}</InfoText>}</div>
              ) : (
                <div className="item-modal-message-disclaimer">
                  <InfoText>Los archivos no pueden superar los 2MB*</InfoText>
                  {accept && !accept.includes('*') && (
                    <InfoText>{`Las extenciones permitidas son: ${accept
                      .join(', ')
                      .replace(/(\b, \b)(?!.*\b\1\b)/, ' y ')}`}</InfoText>
                  )}
                </div>
              )}
            </>
          )}

          <div className={`item-modal-message-docs ${className === 'full' ? 'full' : ''}`}>
            {selectedFiles.length > 0 &&
              selectedFiles.map((item, i) => {
                if (item.data !== null) {
                return (
                  <FileItemModal
                    className={className === 'full' ? 'full' : ''}
                    selectable={true}
                    key={i}
                    file={item}
                    multiple={multiple}
                    onClear={() => this.removeFile(item, i)}
                  />
                );
              }
              })}

            {multiple && (
              <>
                {values &&
                  values.map((item, i) => {
                    if (item !== null) {
                    return (
                      <div key={i} className={`item-modal-message-img`}>
                        <img src={item.src} alt="" />
                        <div className="item-modal-message-delete-icon">
                          <img alt="img" src={images.closeWhiteSvg} onClick={() => this.removePicture(item, i)} />
                        </div>
                      </div>
                    );
                  }
                  })}
                <div className="item-modal-message-drag">
                  <form className="uploader" encType="multipart/form-data">
                    <input
                      ref={ref => (this.fileInput = ref)}
                      multiple={multiple}
                      type="file"
                      name="file"
                      id="file"
                      onChange={e => this.uploadFile(e.target.files)}
                      accept={accept ? accept.toString() : 'image/*'}
                    />
                  </form>
                  <div className="item-modal-message-drag__dashed">
                    <div className="item-modal-message-drag__container">
                      <div className="item-modal-message-drag__text">
                        <BodyText>
                          Arrastra y suelta o haz
                        <span>click aquí</span>
                        </BodyText>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </InputUploadFilesContainer>
    );
  }
}
