import classnames from 'classnames';

import React, { useState } from 'react';
import { CheckboxStyle } from './checkbox-style';
import { images } from '../../../assets/images';

interface CheckboxProps {
  children?: any;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: any;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  message?: string;
  errorMessage?: string;
  tooltip?: string;
  name?: string;
  checked?: boolean;
  className?: string;
  icon?: string;
}

// States: disable | active | error

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = (ev: any) => {
    if (props.onChange) {
      props.onChange(ev);
    } else if (props.checked === undefined) {
      setChecked(ev.target.checked);
    }
  };

  return (
    <CheckboxStyle
      className={classnames(props.className || '', {
        error: !!props.error,
        disabled: !!props.disabled,
        required: !!props.required,
        active: props.checked || checked,
      })}
    >
      <label className="checkbox-container">
        <input
          type="checkbox"
          name={props.name}
          disabled={props.disabled}
          required={props.required}
          checked={props.checked || checked}
          onChange={(ev: any) => handleChange(ev)}
          onFocus={(ev: any) => props.onFocus && props.onFocus(ev)}
          onBlur={(ev: any) => props.onBlur && props.onBlur(ev)}
          onKeyDown={(ev: any) => props.onKeyDown && props.onKeyDown(ev)}
        />
        <div className="checkbox-item">
          <div className="checkbox-item--icon">
            <img src={props.icon ? props.icon : images.checkBlueSvg} />
          </div>
        </div>
        <div className="checkbox-label">{props.children}</div>
        <div className="checkbox-bottom">
          {props.errorMessage && (
            <div className="checkbox-bottom-error">
              <p>{props.errorMessage}</p>
            </div>
          )}
          {props.message && (
            <div className="checkbox-bottom-message">
              <p>{props.message}</p>
            </div>
          )}
        </div>
      </label>
    </CheckboxStyle>
  );
};

export default Checkbox;
