import * as React from 'react';
import { MarineReserveStyle } from './marine-reserve-style';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { images } from '../../../assets/images';
import SingleButton from '../../../components/single-button/single-button';
import GoogleMap from 'google-map-react';
import { mapStyle } from '../../../constants/map-style';
import IStoreState from '../../../redux/store/IStoreState';
import CardMap from '../../../components/card-map/card-map';
import { connect } from 'react-redux';
import services from '../../../services/services';
import { DIVE_SITE_PER_PAGE } from '../../../constants/data';
import { withRouter, RouteComponentProps } from 'react-router';
import { getMarineReserve, deleteMarineReserve } from '../../../services/marine-reserve';
import { GMAPS_KEY } from '../../../env';
import { getDayMonthNumberYear } from '../../../utils/time';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import ModalCreateMarineReserve from '../../../components/modals/modal-create-marine-reserve/modal-create-marine-reserve';
import { COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import CardMapReserve from '../../../components/card-map-marine/card-map-marine';

interface RouteParams { }

export interface IMarineReserveProps extends RouteComponentProps<RouteParams> {
  agent?: any;
}

export interface IMarineReserveState {
  modal: string;
  display: string;
  reserveMarines: any[];
  cards: any[];
  rows: any[];
  total: number;
  totalPages: number;
  page: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  reserveMarineId: string;
  textDelete: string;
  filters: boolean;
  center: {
    lat: number;
    lng: number;
  };
  agent?: any;
}

class MarineReserve extends React.Component<IMarineReserveProps, IMarineReserveState> {
  constructor(props: IMarineReserveProps) {
    super(props);

    this.state = {
      modal: '',
      display: 'map',
      reserveMarines: [],
      rows: [],
      cards: [],
      total: 0,
      totalPages: 0,
      searchFilter: '',
      skipPage: 0,
      orderAscendent: '',
      page: 1,
      reserveMarineId: '',
      textDelete: '',
      filters: false,
      center: { lat: 40.24594, lng: 3.42922 },
      agent: {}
    };
  }

  componentDidMount() {
    this.getMarineReserve();
  }

  getCategory = (type?: string) => {
    switch (type) {
      case 'dive-site':
        return 'site';
      case 'marine-reserve':
        return 'marine reserve';
      default:
        return 'invalid site';
    }
  };

  getSpot = (type?: string) => {
    switch (type) {
      case 'site':
        return 'dive-spot';
      case 'marine-reserve':
        return 'marine-reserve';
      default:
        return 'invalid-spot';
    }
  };

  getMarineReserve = async () => {
    const { searchFilter, skipPage, orderAscendent } = this.state;

    const params = {
      filter: {
        skip: skipPage,
        limit: DIVE_SITE_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: ['sites'],
      },
    };

    try {
      const response: any = await getMarineReserve(params);
      const totalPages: number = Math.ceil(response.data.count / DIVE_SITE_PER_PAGE);
      const total = response.data.count;

      this.setState({ totalPages, total, reserveMarines: response.data.data }, () => {
        this.getRows();
        this.getCardsMaps();
      });
    } catch (err) {
      console.log(err);
    }
  };

  getColumns = () => {
    return [
      {
        text: 'Nombre',
        filter: false,
        ascendent: true,
      },
      {
        text: 'País',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Provincia',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Valoración',
        filter: false,
        ascendent: false,
      },
      {
        text: 'Creación',
        filter: false,
        ascendent: true,
      },
      {
        text: 'Creado por',
        filter: false,
        ascendent: true,
      },
      {
        text: '',
        filter: false,
        ascendent: true,
      },
    ];
  };

  getKeysColumns = () => {
    return ['name', 'country', 'province', 'rating', 'createdAt', 'createdBy', 'actions'];
  };

  getRows = () => {
    const { reserveMarines } = this.state;
    const rows = reserveMarines.map((site: any) => {
      const row: any = {
        id: {
          value: site.id,
        },
        name: {
          key: 'highlighted-body',
          value: site.name,
        },
        country: {
          key: '',
          value: COUNTRIES_KEY_LABEL[site.country],
        },
        province: {
          key: '',
          value: site.state,
        },
        rating: {
          key: 'rate',
          value: site.rating,
        },
        createdAt: {
          key: '',
          value: getDayMonthNumberYear(site.createdAt),
        },
        createdBy: {
          key: '',
          value: site.createdBy ? `${site.createdBy.name} ${site.createdBy.surname}` : '',
        },
        actions: {
          key: 'options',
          value: '',
          actions: [
            {
              label: 'Ver',
              icon: images.eyeSvg,
              onClick: () => this.props.history.push(`${this.props.history.location.pathname}/detail/${site.id}`),
            },
            {
              label: 'Editar',
              icon: images.edit3Svg,
              onClick: () => this.setState({ reserveMarineId: site.id, modal: 'create-reserve' }),
            },
            {
              label: 'Eliminar',
              icon: images.delete2Svg,
              onClick: () =>
                this.setState({
                  reserveMarineId: site.id,
                  modal: 'modal-delete',
                  textDelete: 'Vas a eliminar una reserva marina',
                }),
            },
          ],
        },
      };
      return row;
    });
    this.setState({ rows });
  };

  getCardsMaps = async () => {
    const { reserveMarines } = this.state;
    const pending: any[] = reserveMarines.map(async (site: any, index: number) => {
      return {
        location: `${COUNTRIES_KEY_LABEL[site.country]}, ${site.state}`,
        title: site.name,
        lat: site.latitude,
        lng: site.longitude,
        type: site.shape,
        total: site.sites.length,
        id: site.id,
      };
    });
    const cards: any[] = await Promise.all(pending);
    this.setState({ cards });
  };

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = DIVE_SITE_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getMarineReserve();
      });
    }
  }

  deleteReserveMarine = async (id: string) => {
    try {
      await deleteMarineReserve(id);
      services.pushNotification({
        title: 'Reserva marina eliminada',
        text: 'Se ha eliminado la reserva marina con éxito.',
        type: 'red',
      });
      this.setState({ modal: '', reserveMarineId: '' }, () => {
        this.getMarineReserve();
      });
    } catch (err) { }
  };

  handleCheck = (newRows: any[], all: boolean) => {
    this.setState({ rows: newRows });
  };

  render() {
    const { agent } = this.props
    const { modal, display, cards, totalPages, page, rows, reserveMarineId, textDelete, center } = this.state;

    return (
      <>
        <HeaderMain
          title={'Reserva Marina'}
          mainButton={{
            img: images.createWhiteSvg,
            text: 'Crear',
            onClick: () => this.setState({ modal: 'create-reserve' }),
          }}
        />
        <MainLayoutSection>
          <MarineReserveStyle>
            <div className="dive-site-head">
              <div className="dive-site-head__buttons">
                <div className="dive-site-head__buttons__item">
                  <SingleButton
                    className="secondary modal"
                    icon={display === 'map' ? images.mapBlueSvg : images.mapSvg}
                    onClick={() => this.setState({ display: 'map' })}
                  />
                </div>
                <div className="dive-site-head__buttons__item">
                  <SingleButton
                    className="secondary modal"
                    icon={display === 'table' ? images.listBlueSvg : images.listSvg}
                    onClick={() => this.setState({ display: 'table' })}
                  />
                </div>
              </div>
            </div>

            {display === 'table' ? (
              <MainLayoutTableContainer>
                <div className="diving-center-table">
                  <MainTableLayout
                    columns={this.getColumns()}
                    rows={rows}
                    rowKeys={this.getKeysColumns()}
                    onOrder={() => { }}
                    onSelect={(rows: any[], all: boolean, allValue?: boolean) => this.handleCheck(rows, all)}
                  />
                </div>
                <div className="diving-center-pagination">
                  <Pagination page={page} limit={totalPages} callback={page => this.setPage(page)} />
                </div>
              </MainLayoutTableContainer>
            ) : (
              <div className="dive-site-map">
                <GoogleMap
                  options={{
                    styles: mapStyle,
                    mapTypeId: 'hybrid',
                  }}
                  bootstrapURLKeys={{ key: GMAPS_KEY }}
                  defaultCenter={{ lat: 40.24594, lng: 3.42922 }}
                  defaultZoom={0}
                  center={center}
                >
                  {/* <CardMap lat={28.457044} lng={-16.83126} title="My Marker" /> */}
                  {cards.map((c: any, index: number) => (
                    <CardMapReserve
                      key={`card-map-${index}`}
                      lng={c.lng}
                      lat={c.lat}
                      title={c.title}
                      location={c.location}
                      total={c.total}
                      link={`/dive-sites/marine-reserve/detail/${c.id}`}
                      onClickCard={() => {
                        this.setState(
                          // !Mandatory 2 setState in order to allow map to center always
                          {
                            center: {
                              lat: parseFloat(c.lat) + 0.001,
                              lng: parseFloat(c.lng) + 0.001,
                            },
                          },
                          () =>
                            this.setState({
                              center: {
                                lat: parseFloat(c.lat),
                                lng: parseFloat(c.lng),
                              },
                            })
                        );
                      }}
                    />
                  ))}
                </GoogleMap>
              </div>
            )}
          </MarineReserveStyle>
        </MainLayoutSection>
        {modal === 'modal-delete' && (
          <ModalDelete
            title={textDelete}
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete'}
            onRemove={() => {
              if (!!reserveMarineId) {
                this.deleteReserveMarine(reserveMarineId);
              }
            }}
            onCancel={() => this.setState({ modal: '' })}
          />
        )}
        {modal === 'create-reserve' && (
          <ModalCreateMarineReserve
            reserveMarineId={reserveMarineId}
            showModal={modal === 'create-reserve'}
            onCancel={() => this.setState({ modal: '' })}
            onClose={(load?: boolean) =>
              this.setState({ modal: '', reserveMarineId: '' }, () => {
                if (load) this.getMarineReserve();
              })
            }
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default (connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(MarineReserve)));
