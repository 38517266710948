export const STOCK_TYPES_DROPDOWN: any = [
  { label: 'Escarpines', value: 'booties' },
  { label: 'Gafas', value: 'glasses' },
  { label: 'Guantes', value: 'gloves' },
  { label: 'Aletas', value: 'fins' },
  { label: 'Jacket', value: 'jacket' },
  { label: 'Reguladores', value: 'regulators' },
  { label: 'Lastre', value: 'ballast' },
  { label: 'Botella', value: 'bottle' },
  { label: 'Traje húmedo', value: 'wetSuit' },
  { label: 'Traje Semi-seco', value: 'halfDrySuit' },
  { label: 'Traje Seco', value: 'drySuit' },
  { label: 'Ordenador de buceo', value: 'diveComputer' },
  { label: 'Tabilla submarina', value: 'underwaterTable' },
  { label: 'Foco', value: 'focus' },
  { label: 'Linterna', value: 'lantern' },
  { label: 'Cámara de foto', value: 'camera' },
  { label: 'Scooter submarino', value: 'underwaterScooter' },
  { label: 'Compresor', value: 'compressor' },
  { label: 'Embarcación', value: 'boat' },
  { label: 'Otro', value: 'other' },
];

export const STOCK_TYPES_KEY_VALUES: any = {
  booties: 'Escarpines',
  glasses: 'Gafas',
  gloves: 'Guantes',
  fins: 'Aletas',
  jacket: 'Jacket',
  regulators: 'Reguladores',
  ballast: 'Lastre',
  bottle: 'Botella',
  wetSuit: 'Traje húmedo',
  halfDrySuit: 'Traje Semi-seco',
  drySuit: 'Traje Seco',
  diveComputer: 'Ordenador de buceo',
  underwaterTable: 'Tabilla submarina',
  focus: 'Foco',
  lantern: 'Linterna',
  camera: 'Cámara de foto',
  compressor: 'Compresor',
  boat: 'Embarcación',
  underwaterScooter: 'Scooter submarino',
};

export const STOCK_STATUS_DROPDOWN: any = [
  { label: 'Operativo', value: 'functional' },
  { label: 'Con limitaciones', value: 'limited' },
  { label: 'Inoperativo', value: 'nonFunctional' },
];

export const STOCK_STATUS_KEY_VALUES: any = {
  functional: 'Operativo',
  limited: 'Con limitaciones',
  nonFunctional: 'Inoperativo',
};
