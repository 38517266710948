import React from 'react';
import { ModalCreateStaffContainer } from './modal-create-staff-style';
import ModalContainer from '../modal-container';
import LabelText from '../../text/label-text/label';
import { FormGrid, FormGridRow, FormGridItem } from '../../../containers/main-layout-style';
import InputBox from '../../input-box';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import MainButton from '../../main-button';
import BodyDestacadoText from '../../text/body-destacado-text/body-destacado-text';
import InfoText from '../../text/info-text/info-text';
import SingleButton from '../../single-button/single-button';
import { images } from '../../../assets/images';
import DicotomicCheckbox from '../../dicotomic-checkbox';
import { IGhostStaff } from '../../../models/ghost-staff';
import PhoneBox from '../../phone-box/phone-box';
import { isValidEmail, isValidPhone } from '../../../utils/validation';
import { postCentreStaff, patchCentreStaff } from '../../../services/centre';
import services from '../../../services/services';
import SelectBox from '../../select-box';
import { copyOf } from '../../../utils/other';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

const ROLES = [
  { value: 'manager', label: 'Gestor total' },
  { value: 'staff', label: 'Staff' },
];

interface ModalCreateStaffProps extends RouteComponentProps, WithTranslation {
  centreId: string;
  showModal: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  staff?: IGhostStaff;
  staffCentre?: any;
  sectionRoles?: string;
  t: any
}
interface ModalCreateStaffState {
  step: number;
  staff: IGhostStaff;
  errors: { [key: string]: string };
}

class ModalCreateStaff extends React.Component<ModalCreateStaffProps, ModalCreateStaffState> {
  state = {
    step: 1,
    staff: {
      email: '',
      name: '',
      surname: '',
      role: 'manager',
      phone: {
        prefix: '+34',
        number: '',
      },
      divers: true,
      sites: true,
      incidences: true,
      messaging: true,
      manageStaff: true,
      activities: true,
      instructor: true,
      deals: false,
    },
    errors: {
      email: '',
      name: '',
      surname: '',
      number: '',
    },
  };

  componentWillMount(){
    const { staff } = this.props;
    if (staff) {
      this.setState({ staff, step: 2 });
    }
  }
  /*componentDidMount() {
    const { staff } = this.props;
    if (staff) {
      this.setState({ staff, step: 2 });
    }
  }*/

  componentDidUpdate(prevProps: ModalCreateStaffProps) {
    const { staff } = this.props;
    if (staff && staff !== prevProps.staff) {
      this.setState({ staff, step: 2 });
    }
  }

  async handleSubmit() {
    const { centreId, onSuccess, sectionRoles, t } = this.props;
    const { staff } = this.state;
    const formattedStaff = copyOf(staff);
    if (!this.props.staff && formattedStaff.role === 'manager') {
      formattedStaff.deals = true;
    }
    formattedStaff.role = 'manager';
    Object.keys(formattedStaff).forEach((key: string) => {
      if (typeof formattedStaff[key] === 'boolean' && !formattedStaff[key]) {
        formattedStaff.role = 'staff';
      }
    });
    if (this.props.staff) {
      const staffId = this.props.staff.id ? this.props.staff.id : '0';
      await patchCentreStaff(centreId, staffId, formattedStaff);
      services.showModalSuccess(
        `¡${t('components:general.edited')}!`,
        sectionRoles
          ? t('modalMessages:modal-create-staff.permissions-edited')
          : t('components:modal-create-staff.permissions-staff-edited')
      );
    } else {
      await postCentreStaff(centreId, formattedStaff);
      services.showModalSuccess(
        '¡Creado!',
        sectionRoles ? t('modalMessages:modal-create-staff.role-created-succesfully')
        : t('components:modal-create-staff.staff-created-succesfully')
      );
    }
    onSuccess();
  }

  validate(field: string, value: boolean | string) {
    let error = '';
    if (value === '') {
      error = 'required';
    } else if (typeof value === 'string' && field === 'email' && !isValidEmail(value)) {
      error = 'invalid-email';
    } else if (typeof value === 'string' && field === 'number' && !isValidPhone(value)) {
      error = 'invalid-phone';
    }
    return error;
  }

  handleChange(field: string, value: boolean | string, subfield?: string) {
    const { staff, errors } = Object.assign(this.state);
    if (subfield) staff[subfield][field] = value;
    else staff[field] = value;
    errors[field] = this.validate(field, value);
    this.setState({ staff, errors });
  }

  validateFirstStep() {
    const { staff, errors } = Object.assign(this.state);
    errors.email = this.validate('email', staff.email);
    errors.name = this.validate('name', staff.name);
    errors.surname = this.validate('surname', staff.surname);
    errors.number = this.validate('number', staff.phone.number);
    this.setState({ errors });
    return Object.values(errors).filter((err: any) => err !== '').length === 0;
  }

  renderCreate() {
    const { sectionRoles, t } = this.props;
    const { staff, errors } = this.state;
    return (
      <div className="create-staff-container">
        <FormGrid>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                placeholder="Email"
                className="rounded-title"
                type="email"
                required={true}
                errorCode={errors.email}
                value={staff.email}
                labelText="Email"
                onChange={value => this.handleChange('email', value)}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                placeholder={t('components:general.name')}
                className="rounded-title"
                type="text"
                required={true}
                errorCode={errors.name}
                value={staff.name}
                labelText={t('components:general.name')}
                onChange={value => this.handleChange('name', value)}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <InputBox
                placeholder={t('components:general.surnames')}
                className="rounded-title"
                type="text"
                required={true}
                errorCode={errors.surname}
                value={staff.surname}
                labelText={t('components:general.surnames')}
                onChange={value => this.handleChange('surname', value)}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <PhoneBox
                placeholder={t('components:general.phone')}
                className="rounded-title"
                labelText={t('components:general.phone')}
                required={true}
                errorNumber={errors.number}
                filledNumber={staff.phone.number}
                filledPrefix={staff.phone.prefix}
                defaultPrefix={staff.phone.prefix}
                defaultNumber={staff.phone.number}
                onChange={(field, value) => {
                  if (field === 'phoneNumber') this.handleChange('number', value, 'phone');
                  else this.handleChange('prefix', value, 'phone');
                }}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-0">
              <SelectBox
                className="rounded-title"
                optionsText={ROLES}
                required={true}
                labelText={t('components:general.role')}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={staff.role}
                initialValue={staff.role}
                onChange={value => this.handleChange('role', value)}
              />
            </FormGridItem>
          </FormGridRow>
        </FormGrid>
        <div className="create-staff-button">
          <MainButton
            text={staff.role === 'manager' ? (sectionRoles ? t('components:actions.save') : `${t('components:actions.invite')} staff`) : t('components:actions.next')}
            onClick={() => {
              if (this.validateFirstStep()) {
                if (staff.role === 'manager') this.handleSubmit();
                else this.setState({ step: 2 });
              }
            }}
            type="primary"
          />
        </div>
      </div>
    );
  }

  renderLicence() {
    const { staff } = this.state;
    const { t } = this.props;
    return (
      <div className="create-staff-container">
        <div className="create-staff-section">
          <div className="create-staff-section-title">
            <div className="create-staff-section-title__text">
              <BodyDestacadoText>{t('modalMessages:modal-create-staff.divers-permimssions')}</BodyDestacadoText>
            </div>
            <div className="create-staff-section-title__check">
              <DicotomicCheckbox
                className={'switch'}
                checked={staff.divers}
                onCheck={value => this.handleChange('divers', value)}
              />
            </div>
          </div>
          <div className="create-staff-section-list">
            <div className="create-staff-section-list__item">
              <InfoText>{t('components:actions.invite')} {t('components:general.divers')}</InfoText>
            </div>
          </div>
        </div>
        <div className="create-staff-section">
          <div className="create-staff-section-title">
            <div className="create-staff-section-title__text">
              <BodyDestacadoText>{t('modalMessages:modal-create-staff.diving-point-permissions')}</BodyDestacadoText>
            </div>
            <div className="create-staff-section-title__check">
              <DicotomicCheckbox
                className={'switch'}
                checked={staff.sites}
                onCheck={value => this.handleChange('sites', value)}
              />
            </div>
          </div>
          <div className="create-staff-section-list">
            <div className="create-staff-section-list__item">
              <InfoText>{t('components:actions.create')} / {t('components:actions.edit')} / {t('components:actions.delete')} puntos de inmersión</InfoText>
            </div>
          </div>
        </div>
        <div className="create-staff-section">
          <div className="create-staff-section-title">
            <div className="create-staff-section-title__text">
              <BodyDestacadoText>{t('modalMessages:modal-create-staff.staff-permissions')}</BodyDestacadoText>
            </div>
            <div className="create-staff-section-title__check">
              <DicotomicCheckbox
                className={'switch'}
                checked={staff.manageStaff}
                onCheck={value => this.handleChange('manageStaff', value)}
              />
            </div>
          </div>
          <div className="create-staff-section-list">
            <div className="create-staff-section-list__item">
              <InfoText>{t('components:actions.add')} / {t('components:actions.edit')} / {t('components:actions.delete')} staff</InfoText>
            </div>
          </div>
        </div>
        <div className="create-staff-section">
          <div className="create-staff-section-title">
            <div className="create-staff-section-title__text">
              <BodyDestacadoText>{t('modalMessages:modal-create-staff.activities-reserves-permissions')}</BodyDestacadoText>
            </div>
            <div className="create-staff-section-title__check">
              <DicotomicCheckbox
                className={'switch'}
                checked={staff.activities}
                onCheck={value => this.handleChange('activities', value)}
              />
            </div>
          </div>
          <div className="create-staff-section-list">
            <div className="create-staff-section-list__item">
              <InfoText>{t('modalMessages:modal-create-staff.reservations-gestion')}</InfoText>
            </div>
            <div className="create-staff-section-list__item">
              <InfoText>{t('modalMessages:modal-create-staff.create-manage-activities')}</InfoText>
            </div>
            <div className="create-staff-section-list__item">
              <InfoText>{t('modalMessages:modal-create-staff.plan-activities')}</InfoText>
            </div>
          </div>
        </div>
        <div className="create-staff-section">
          <div className="create-staff-section-title">
            <div className="create-staff-section-title__text">
              <BodyDestacadoText>{t('modalMessages:modal-create-staff.diving-centre-permissiones')}</BodyDestacadoText>
            </div>
            <div className="create-staff-section-title__check">
              <DicotomicCheckbox
                className={'switch'}
                checked={staff.deals}
                onCheck={value => this.handleChange('deals', value)}
              />
            </div>
          </div>
          <div className="create-staff-section-list">
            <div className="create-staff-section-list__item">
              <InfoText>{t('components:actions.create')} / {t('components:actions.edit')} / {t('components:actions.delete')} {t('components:general.services')}</InfoText>
            </div>
            <div className="create-staff-section-list__item">
              <InfoText>{t('components:actions.create')} / {t('components:actions.edit')} / {t('components:actions.delete')} {t('components:general.installations')}</InfoText>
            </div>
            <div className="create-staff-section-list__item">
              <InfoText>{t('components:actions.create')} / {t('components:actions.edit')} / {t('components:actions.delete')} {t('components:general.documents')}</InfoText>
            </div>
          </div>
        </div>
        <div className="create-staff-button-second">
          {!this.props.staff && (
            <div className="create-staff-button-second__arrow">
              <SingleButton icon={images.arrowLeftSvg} onClick={() => this.setState({ step: 1 })} />
            </div>
          )}
          <div className="create-staff-button-second__save">
            <MainButton
              text={this.props.staff ? t('components:actions.save-changes') : t('components:actions.save')}
              type="primary"
              onClick={() => this.handleSubmit()}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { showModal, onCancel, staff, sectionRoles, t } = this.props;
    const { step } = this.state;
    const languajes = [
      { label: 'ES', value: 'ES' },
      { label: 'EN', value: 'EN' },
    ];

    return (
      <ModalContainer title={``} active={showModal} modalClose={() => onCancel()}>
        <ModalCreateStaffContainer>
          <div className="modal-create-staff-step">
            <LabelText>
              {sectionRoles
                ? sectionRoles
                : staff
                  ? `${t('components:actions.edit')} ${t('components:general.permissions')}`
                  : this.state.staff.role === 'manager'
                    ? ''
                    : `${t('components:general.step')} ${step}`}
            </LabelText>
          </div>
          <div className="modal-create-staff-title">
            <SubheadDestacadoText>
              {step < 2
                ? sectionRoles
                  ? t('components:actions.create-role')
                  : t('components:actions.invite-staff')
                : sectionRoles
                  ? t('components:actions.role-permissions')
                  : t('components:actions.staff-permissions')}
            </SubheadDestacadoText>
          </div>
          {step < 2 ? this.renderCreate() : this.renderLicence()}
        </ModalCreateStaffContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalCreateStaff));