export const updateObject = (oldObject: { [key: string]: any }, updatedProperties: any) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value: any, rules: any) => {
  const validation: any = {
    valid: true,
    error: false,
  };
  if (!rules) {
    return true;
  }
  if (rules.required) {
    const result = value.trim() !== '';
    validation.valid = result && validation.valid;
    validation.error = !result && 'required';
  }

  if (rules.minLength) {
    const result = value.length >= rules.minLength;
    validation.valid = result && validation.valid;
    validation.error = !result && 'invalid-format';
  }

  if (rules.maxLength) {
    const result = value.length <= rules.maxLength;
    validation.valid = result && validation.valid;
    validation.error = !result && 'invalid-format';
  }

  if (rules.isNumeric) {
    const pattern = /^-?[0-9]\d*(\.\d+)?$/;
    const result = pattern.test(value);
    validation.valid = result && validation.valid;
    validation.error = !result && 'invalid-format';
  }

  return validation;
};

export const transformEuNum = (number: string | number): string => {
  const newNumber = number.toString().replace(' €', '');
  return newNumber.toString().replace(/[,.]/g, x => {
    return x === ',' ? '.' : '';
  });
};

export const transformToEuNum = (number: string | number): string => {
  return Number(+number).toLocaleString('de-DE', { minimumFractionDigits: 2 });
};
