import * as React from 'react';
import { PlanningDiveSiteStyle } from './planning-dive-site-style';
import SelectBox from '../../../../components/select-box';
import { images } from '../../../../assets/images';
import MainButton from '../../../../components/main-button';
import AddItemFormSimple from '../../../../components/add-item-form-simple/add-item-form-simple';
import { IActivityTemplate } from '../../../../models/activity-template';
import { getCentreDiveSites } from '../../../../services/centre';
import { IDropdown, IId } from '../../../../models/generic';

export interface PlanningDiveSiteState {
  selectedDiveSite: number;
  diveSites: number[];
  diveSitesDropdown: IDropdown[];
  diveSitesKeyValues: { [key: string]: string };
  error: string;
}
interface PlanningDiveSiteProps {
  centreId: string;
  selectedTemplate?: IActivityTemplate;
  state?: PlanningDiveSiteState;
  onNext: (state: PlanningDiveSiteState, sites: IId[]) => void;
  edit?: boolean;
  diveSites?: number[];
}

class PlanningDiveSite extends React.Component<PlanningDiveSiteProps, PlanningDiveSiteState> {
  constructor(props: PlanningDiveSiteProps) {
    super(props);
    this.state = {
      selectedDiveSite: NaN,
      diveSites: [],
      diveSitesDropdown: [],
      diveSitesKeyValues: {},
      error: '',
    };
  }

  componentDidMount() {
    const { state } = this.props;
    // console.log("Puedes editar los puntos de inmersión si lo deseas o mantener los puntos seleccionados en la plantilla original:::",this.props)
    if (state) {
      this.setState(state);
    } else {
      this.getDiveSites();
    }
  }

  async getDiveSites() {
    const { centreId, selectedTemplate } = this.props;
    const diveSites = selectedTemplate && selectedTemplate.sites
      ? selectedTemplate.sites.map((site: any) => site.id)
      : this.props.diveSites
        ? [...this.props.diveSites]
        : [];
    const resp = await getCentreDiveSites(centreId);

    const sites = resp.data.data;
    const diveSitesDropdown = sites.map((diveSite: any) => {
      return {
        label: diveSite.name,
        value: diveSite.id.toString(),
      };
    });
    const diveSitesKeyValues: any = {};
    sites.forEach((diveSite: any) => {
      diveSitesKeyValues[diveSite.id] = diveSite.name;
    });
    this.setState({ diveSites, diveSitesDropdown, diveSitesKeyValues });
  }

  handleAdd() {
    const { diveSites, selectedDiveSite } = this.state;
    if (selectedDiveSite && !diveSites.includes(selectedDiveSite)) {
      const newDiveSites = diveSites.concat([selectedDiveSite]);
      this.setState({ diveSites: newDiveSites, error: '', selectedDiveSite: NaN });
    }
  }

  handleDelete(delDiveSite: number) {
    const { diveSites } = this.state;
    const newDiveSites = diveSites.filter(diveSite => diveSite !== delDiveSite);
    this.setState({ diveSites: newDiveSites });
  }

  render() {
    const { diveSites, diveSitesDropdown, diveSitesKeyValues, error, selectedDiveSite } = this.state;
    const { onNext, edit } = this.props;
    return (
      <PlanningDiveSiteStyle>
        <div className="planning-dive-site-disclaimer">
          <p>
            Puedes editar los puntos de inmersión si lo deseas o mantener los puntos seleccionados en la plantilla
            original
          </p>
        </div>
        <div className="planning-dive-site-form">
          <div className="planning-dive-site-form__select">
            <SelectBox
              labelText=""
              placeholder="Punto de inmersión"
              required={true}
              errorCode={error}
              className="rounded"
              optionsText={diveSitesDropdown.filter(
                (site: any) => !diveSites.includes(parseInt(site.value, undefined))
              )}
              searchable={true}
              optionKey={'value'}
              optionValue={'label'}
              defaultValue={''}
              initialValue={''}
              clearValue={!selectedDiveSite}
              icon={images.arrowDown2BlueSvg}
              onChange={value => {
                this.setState({ selectedDiveSite: parseInt(value, undefined) });
              }}
            />
          </div>
          <div className="planning-dive-site-form__add">
            <MainButton
              onClick={() => this.handleAdd()}
              text={'Añadir'}
              iconImg={images.plusSvg}
              type="secondary full-height"
            />
          </div>
        </div>
        {diveSites.map((diveSite: number) => {
          return (
            <div key={diveSite} className="planning-dive-site-list">
              <div className="planning-dive-site-list__item">
                <AddItemFormSimple text={diveSitesKeyValues[diveSite]} onDelete={() => this.handleDelete(diveSite)} />
              </div>
            </div>
          );
        })}

        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            <div className="modal-create-activities-buttons-left__next">
              <MainButton
                text={edit ? 'Guardar' : 'Siguiente'}
                type="primary"
                onClick={() => {
                  //if (diveSites.length > 0) {
                  onNext(
                    this.state,
                    diveSites.map(diveSite => {
                      return { id: diveSite };
                    })
                  );
                  //} else {
                  //  this.setState({ error: 'required' });
                  //}
                }}
              />
            </div>
          </div>
        </div>
      </PlanningDiveSiteStyle>
    );
  }
}

export default PlanningDiveSite;
