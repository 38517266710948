import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalRejectStaffContainer = styled.div`
  width: 780px;
  padding: 48px 32px 32px;
  .modal-reject-staff-activity {
    font-family: Plain;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 2px;
    color: ${colors['SE-002/100']};
  }

  .modal-reject-staff-title {
    font-family: Plain;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: ${colors['NE-001/100']};
    margin-bottom: 12px;
  }

  .modal-reject-staff-subtitle {
    font-family: Plain;
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
    color: ${colors['NE-002/100']};
    margin-bottom: 32px;
  }

  .modal-reject-staff-checkbox-container{
    margin-bottom: 26px;
    .modal-reject-staff-checkbox{
      margin-bottom: 22px;
    }
  }

  .modal-reject-staff-input {
    width: 100%;
    margin-bottom: 22px;
  }

  .modal-reject-staff-message-title{
    font-family: Plain;
    font-size: 14px;
    line-height: 22px;
    ${colors['NE-001/100']};
    margin-bottom: 14px;
  }

  .modal-reject-staff-message-inpu{
    width: 100%;
    height: 116px
    margin-bottom: 40px;
  }

  .modal-reject-staff-send-button {
    width: 144px;
  }
`;
