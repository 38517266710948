import * as React from 'react';
import { CreateActivitiesBoatsStyle } from './create-activities-boats-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../main-layout-style';
import { images } from '../../../../../assets/images';
import { DiveMinimumRequisitesTypes, MIN_AGE } from '../../../../../constants/dive-dropdowns';
import SingleButton from '../../../../../components/single-button/single-button';
import MainButton from '../../../../../components/main-button';
import { IBoatsData } from '../../../../../models/activity-template';
import { copyOf } from '../../../../../utils/other';
import { transformToUser, transformToSI } from '../../../../../utils/measurement-units';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import InfoText from '../../../../../components/text/info-text/info-text';
import BodyDestacadoText from '../../../../../components/text/body-destacado-text/body-destacado-text';

export interface ICreateActivitiesBoatsProps {
  boats?: IBoatsData;
  onPrev?: () => void;
  onNext: (boats: any) => void;
}

export interface ICreateActivitiesBoatsState {
  boats: IBoatsData;
  errors: { [key: string]: string };
}

export default class CreateActivitiesBoats extends React.Component<ICreateActivitiesBoatsProps, ICreateActivitiesBoatsState> {
  constructor(props: ICreateActivitiesBoatsProps) {
    super(props);

    this.state = {
      boats: {
        needBoat: false,
      },
      errors: {
      },
    };
  }

  componentDidMount() {
    const { boats } = this.props;

    if (boats) {
      this.setState({ boats });
    }
  }


  validate(field: string, value: string | number) {
    if (!value || value === '') return 'required';
    return '';
  }

  handleChange(field: string, value: any) {
    const { boats, errors } = Object.assign(this.state);
    boats[field] = value;
    errors[field] = this.validate(field, value);
    this.setState({ boats, errors });
  }

  handleCheck(field: string, value: boolean) {
    const { boats } = Object.assign(this.state);
    boats[field] = value;
    this.setState({ boats });
  }

  handleNext() {
    const { onNext } = this.props;
    const { boats, errors } = Object.assign(this.state);
    Object.keys(errors).forEach(field => {
      errors[field] = this.validate(field, boats[field]);
    });

    this.setState({ errors });
    if (Object.values(errors).every(err => err === '')) {
      onNext(boats);
    }

  }

  render() {
    const { onPrev } = this.props;
    const { boats } = this.state;

    return (
      <CreateActivitiesBoatsStyle>
        <FormGrid>
          <FormGridRow>
            <FormGridItem type="col-0">
              <div className="create-activities-other">
                <div className="create-activities-other__text">
                  <BodyDestacadoText>¿Se necesita embarcación?</BodyDestacadoText>
                </div>
                <div className="create-activities-other__check">
                  <DicotomicCheckbox
                    className="rounded"
                    checked={boats.needBoat}
                    onCheck={() => this.handleCheck('needBoat', true)}
                  >
                    <InfoText>Sí</InfoText>
                  </DicotomicCheckbox>
                </div>
                <div className="create-activities-other__check">
                  <DicotomicCheckbox
                    className="rounded"
                    checked={!boats.needBoat}
                    onCheck={() => this.handleCheck('needBoat', false)}
                  >
                    <InfoText>No</InfoText>
                  </DicotomicCheckbox>
                </div>
              </div>
            </FormGridItem>
          </FormGridRow>

        </FormGrid>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={onPrev ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => this.handleNext()} />
            </div>
          </div>
        </div>
      </CreateActivitiesBoatsStyle>
    );
  }
}
