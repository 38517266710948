import firebase from 'firebase/app';
import 'firebase/messaging';
import Services from './services';

export const initializeFirebase = (userId: number) => {
  //const config = require("../../public/firebaseConfig.json");
  const config = {
    "apiKey": "AIzaSyAk6leatrIqx9vs0F_UsGblRyThWIR4xPY",
    "authDomain": "syntonize-c356c.firebaseapp.com",
    "databaseURL": "https://syntonize-c356c.firebaseio.com",
    "projectId": "syntonize-c356c",
    "storageBucket": "syntonize-c356c.appspot.com",
    "messagingSenderId": "431319057367",
    "appId": "1:431319057367:web:b54f7750646bab7e208192"
  };


  if (firebase.messaging.isSupported()){
    try {
    firebase.initializeApp(config);
    } catch (error) {
    console.log(error);
  }

  const messaging = firebase.messaging();
  messaging.getToken()
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("tokenPush", currentToken);
        Services.post({
          endpoint: 'users/setFirebaseToken',
          data: { userId: userId, token: currentToken },
          loader: true,
          then: (response: any) => {
            console.log(response);
          },
          catch: (error: any) => { }
        });
      } else {
        console.log("No token");
      }
    });
  }

  /*   firebase.initializeApp(config);
    const messaging = firebase.messaging();
    messaging.getToken()
    .then((currentToken)=>{
      if(currentToken){
        console.log(currentToken);
        localStorage.setItem("tokenPush", currentToken);
      }
    });
    messaging.onBackgroundMessage((event)=> console.log("evento", event));
    messaging.onMessage((event)=> console.log("mensaje", event));
    */
}
