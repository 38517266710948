import axios from 'axios';
import { SERVICES_URL, APP_WEB_URL } from '../env';
// import { LOCAL_URL as SERVICES_URL } from '../env';
import { store } from '../redux/store';
import { setLoading, setExpiredToken, addNotification, showModalSuccess } from '../redux/actions';
import { NOTIFICATION_DURATION } from '../constants/data';
import { INotification } from '../models/notifications';
import { getServiceErrorMsg } from '../constants/error-messages';
class Services {
  get = async (args: any) => {
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.token}`,
      };
      const response = await axios({
        headers,
        method: 'GET',
        url: `${SERVICES_URL}/${args.endpoint}`,
        params: args.params,
      });
     // this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.handleError(args, err);
    }
  };

  getApiExternal = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      const response = await axios({
        headers,
        method: 'GET',
        url: args.endpoint,
        params: args.params,
      });
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.handleError(args, err);
    }
  };

  post = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.token}`,
      };
      const response = await axios({
        headers,
        method: 'POST',
        url: `${SERVICES_URL}/${args.endpoint}`,
        data: args.data,
      });
      this.setValue(setLoading(false));
      if (args.then && typeof args.then === 'function') args.then(response);
    } catch (err) {
      this.handleError(args, err);
    }
  };

  put = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.token}`,
      };
      const response = await axios({
        headers,
        method: 'PUT',
        url: `${SERVICES_URL}/${args.endpoint}`,
        data: args.data,
      });
      this.setValue(setLoading(false));
      if (args.then && typeof args.then === 'function') args.then(response);
    } catch (err) {
      this.handleError(args, err);
    }
  };

  patch = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.token}`,
      };
      const response = await axios({
        headers,
        method: 'PATCH',
        url: `${SERVICES_URL}/${args.endpoint}`,
        data: args.data,
      });
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.handleError(args, err);
    }
  };

  deleteReq = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.token}`,
      };
      const response = await axios({
        headers,
        method: 'DELETE',
        url: `${SERVICES_URL}/${args.endpoint}`,
        data: args.data,
      });
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.handleError(args, err);
    }
  };

  postFormData = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const config = {
        headers: {
          Accept: 'multipart/form-data',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.token}`,
        },
      };
      const response = await axios.post(`${SERVICES_URL}/${args.endpoint}`, args.data, config);
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.handleError(args, err);
    }
  };

  patchFormData = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const config = {
        headers: {
          Accept: 'multipart/form-data',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.token}`,
        },
      };
      const response = await axios.patch(`${SERVICES_URL}/${args.endpoint}`, args.data, config);
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.handleError(args, err);
    }
  };

  setValue = (args: any) => {
    store.dispatch(args);
  };

  pushNotification = (args: INotification) => {
    const notification = addNotification([args]);
    const actualNotifications = store.getState().notifications.notifications;
    notification.notifications = actualNotifications.concat(notification.notifications);
    this.setValue(notification);
    setTimeout(() => {
      const actualNotifications = store.getState().notifications.notifications;
      actualNotifications.shift();
      notification.notifications = actualNotifications;
      this.setValue(notification);
    }, NOTIFICATION_DURATION * 1000);
  };

  showModalSuccess = (title: string, text: string, onClose?: Function) => {
    this.setValue(showModalSuccess({ title, text, onClose }));
  };

  handleError = (args: any, err: any) => {
    this.setValue(setLoading(false));
    if (err.response && err.response.status && err.response.status === 401) {
      if (!args.endpoint.includes('/password')) this.setValue(setExpiredToken(true));
      args.catch(err);
    } else {
      const error = {
        text: getServiceErrorMsg(
          err.response ? (err.response.data.code ? err.response.data.code : `${err.response.status}`) : '',
          err.response && err.response.data.message && err.response.data.message.indexOf('centreId') > -1
        ),
        title: '¡Error!',
        type: 'red',
      };
      this.pushNotification(error);
      args.catch(err);
    }
  };
  encript = (obj: any) => {   
    return new Promise<string>((resolve, reject) => {
      const args = {
        data: obj,
        endpoint: 'crypt/object',
        then: resolve,
        catch: reject,
        loader: true,
      };
      this.post(args);
    });
  }
  decript = (jwt: string) => {   
  return new Promise<any>((resolve, reject) => {

      const args = {
      data: {jwt},
      endpoint: 'crypt/jwt',
      then: resolve,
      catch: reject,
      loader: true,
      };
      this.post(args);
  });
  }

/**
 * 
 * @param link Enlace
 * @param callBack functión a la que se llama enviándole un string con la url para el enlace
 */      
  setLink = (link: string, callBack: (newLink: string)=>void) => { 
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      const diverId = localStorage.getItem("diverId");
      const urlLink = APP_WEB_URL + "/enterjwt/";
      this.encript({
          userId: userId,
          token:  token,
          url: link,
          diverId: diverId
        }).then((data:any) =>{
            callBack(urlLink + data.data)
        });
  }
}

export default new Services();
