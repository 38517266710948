import * as React from 'react';
import { DiverDiveDetailStyle } from './diver-dive-detail-style';
import HeaderMain from '../../../../../components/header-main/header-main';
import { MainLayoutSection } from '../../../../main-layout-style';
import HeadDestacadoText from '../../../../../components/text/head-destacado-text/head-destacado-text';
import { images } from '../../../../../assets/images';
import ItemSectionDetail from '../../../../../components/item-sections-detail/item-sections-detail';
import ItemUser from '../../../../../components/item-user/item-user';
import TextText from '../../../../../components/text/text-text/text-text';
import DiveSiteDetailMap from '../../../../../components/dive-site-detail-map/dive-site-detail-map';
import { RouteComponentProps, match } from 'react-router';
import { getDiveDetail } from '../../../../../services/diver';
import { COUNTRIES_KEY_LABEL } from '../../../../../constants/countries';
import { IDiveForm } from '../../../../../models/dive-forms';
import { AttachedFile } from '../../../../../types/file';
import ModalRegistreDive from '../../../../../components/modals/modal-registre-dive/modal-registre-dive';
import { editDive, deleteDives } from '../../../../../services/dives';
import services from '../../../../../services/services';
import moment from 'moment';
import 'moment/locale/es.js';
import ModalInfo from '../../../../../components/modals/modal-info/modal-info';

export interface IDiverDiveDetailProps extends RouteComponentProps {
  match: match<{
    diver: string;
    diveId: string;
  }>;
}

export interface IDiverDiveDetailState {
  dive?: any;
  renderModal: string;
}

export default class DiverDiveDetail extends React.Component<IDiverDiveDetailProps, IDiverDiveDetailState> {
  constructor(props: IDiverDiveDetailProps) {
    super(props);
    this.state = {
      renderModal: '',
    };
  }

  componentDidMount() {
    this.getDive();
  }

  async getDive() {
    const {
      match: {
        params: { diveId },
      },
    } = this.props;
    const diveResp0: any = await getDiveDetail(diveId, ['divers', 'divers.user']);
    const diveResp1: any = await getDiveDetail(diveId, ['site', 'pictures']);
    diveResp1.data.divers = diveResp0.data.divers;
    this.setState({ dive: diveResp1.data });
  }

  async onCreateDive(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) {
    if (!dive.id) return;
    await editDive(dive.id, dive);
    services.showModalSuccess('Genial!', 'La inmersión se ha editado correctamente');
    this.closeModal();
  }

  async onDeleteDive() {
    const {
      match: {
        params: { diveId },
      },
    } = this.props;
    await deleteDives(diveId);
    services.pushNotification({
      text: 'La inmersión se ha eliminado con éxito',
      title: 'Inmersión eliminada',
      type: 'red',
    });
    this.props.history.goBack();
  }

  closeModal() {
    this.setState({ renderModal: '' });
  }

  async validateDive() {
    const {
      match: {
        params: { diveId },
      },
    } = this.props;
    const endpoint = `dives/${diveId}/validate`;
    await services.post({
      endpoint,
      then: (response: any) => {
        this.setState({ renderModal: '' }, () => {
          this.getDive();
        });
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  render() {
    const {
      match: {
        params: { diver },
      },
    } = this.props;
    const { dive, renderModal } = this.state;
    const date = dive ? moment(dive.date) : moment();
    if (dive) {
      return (
        <>
          {!diver ? (
            <HeaderMain
              goBack={true}
              title={'Detalle de la inmersión'}
              subtitle={{ type: 'confirmed', text: dive.validated ? 'Validada' : 'No validada' }}
              mainButton={
                dive.validated
                  ? {
                      text: 'Editar',
                      onClick: () => this.setState({ renderModal: 'modal-edit-dive' }),
                    }
                  : {
                      text: 'Validar',
                      onClick: () => this.setState({ renderModal: 'modal-validate-dive' }),
                    }
              }
              // Aparece el secundario cuando estan marcados los CheckBox de la tabla
              secondaryButtonRed={{
                text: 'Eliminar',
                onClick: () => this.setState({ renderModal: 'modal-delete-dive' }),
              }}
            />
          ) : (
            <HeaderMain
              goBack={true}
              title={'Detalle de la inmersión'}
              subtitle={{ type: 'confirmed', text: dive.validated ? 'Validada' : 'No validada' }}
            />
          )}
          <MainLayoutSection>
            {renderModal === 'modal-edit-dive' && (
              <ModalRegistreDive
                onSubmit={(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) =>
                  this.onCreateDive(dive, mainPicture, pictures)
                }
                showModal={renderModal === 'modal-edit-dive'}
                onClose={() => this.closeModal()}
                dive={dive}
              />
            )}
            {renderModal === 'modal-delete-dive' && (
              <ModalInfo
                type="red"
                icon={images.closeCircleRedSvg}
                title={[<span key="modal-delete-dive">{'Eliminar inmersión'}</span>]}
                text={['Una vez eliminada la inmersión, no se podrá recupear.']}
                showModal={renderModal === 'modal-delete-dive'}
                onClose={() => {
                  this.setState({ renderModal: '' });
                }}
                buttons={[
                  {
                    text: 'Eliminar',
                    type: 'secondary-red',
                    onClick: () => this.onDeleteDive(),
                  },
                  { text: 'Cancelar', type: 'secondary-red', onClick: () => this.setState({ renderModal: '' }) },
                ]}
              />
            )}
            <DiverDiveDetailStyle>
              <div className="diver-dive-detail-title">
                <HeadDestacadoText>
                  {`${dive.site ? dive.site.name : ''}, ${dive.site ? dive.site.state : ''}, ${
                    dive.site ? COUNTRIES_KEY_LABEL[dive.site.country] : ''
                  }`}
                </HeadDestacadoText>
              </div>
              <div className="diver-dive-detail-date">
                <div className="diver-dive-detail-date__item">
                  <div className="diver-dive-detail-date__item__icon">
                    <img src={images.clockSvg} alt="" />
                  </div>
                  <div className="diver-dive-detail-date__item__text">
                    <TextText>{`${date.format('HH')}:00 horas`}</TextText>
                  </div>
                </div>
                <div className="diver-dive-detail-date__item">
                  <div className="diver-dive-detail-date__item__icon">
                    <img src={images.clockSvg} alt="" />
                  </div>
                  <div className="diver-dive-detail-date__item__text">
                    <TextText>{date.format('DD MMMM, YYYY')}</TextText>
                  </div>
                </div>
              </div>
              <div className="diver-dive-detail-map">
                <DiveSiteDetailMap item={dive.site} />
              </div>
              <div className="diver-dive-detail-container">
                <div className="diver-dive-detail-container-left">
                  <ItemSectionDetail title="Foto" icon={images.imagesSvg}>
                    <div className="diver-dive-detail-container-grid">
                      {dive.pictures &&
                        dive.pictures.map((picture: any, index: number) => {
                          return (
                            <div key={`${index}-${picture.id}`} className="diver-dive-detail-container-grid__item">
                              <img src={picture.src} alt="" />
                            </div>
                          );
                        })}
                    </div>
                  </ItemSectionDetail>
                </div>
                <div className="diver-dive-detail-container-right">
                  <ItemSectionDetail title="Compañeros de buceo" icon={images.userDobleSvg}>
                    <div className="diver-dive-detail-container-right-users">
                      {dive.divers &&
                        dive.divers.map((diver: any, index: number) => {
                          return (
                            <div key={`${diver.id}-${index}`} className="diver-dive-detail-container-right-users__item">
                              <ItemUser
                                name={`${diver.user.name} ${diver.user.surname}`}
                                subtitle={`Buceador ${diver.diverId}`}
                                info={
                                  diver.degree ? `Nivel de buceo: ${diver.degree}` : 'Nivel de buceo no especificado'
                                }
                                avatar={diver.user.picture}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </ItemSectionDetail>
                </div>
              </div>
            </DiverDiveDetailStyle>
          </MainLayoutSection>
          {renderModal === 'modal-validate-dive' && (
            <ModalInfo
              type="blue"
              icon={images.checkCircleBlueSvg}
              title={[<span key="modal-validate-dive">{'Validar inmersion'}</span>]}
              text={[
                'Una vez validada la inmersión del buceador, esta aparecerá reflejada en el perfil del buceador y le permitirá tener acceso a determinados servicios dentro de la aplicación.',
              ]}
              showModal={renderModal === 'modal-validate-dive'}
              onClose={() => {
                this.closeModal();
              }}
              buttons={[
                {
                  text: 'Validar',
                  type: 'primary',
                  onClick: () => {
                    this.validateDive();
                  },
                },
              ]}
            />
          )}
        </>
      );
    }
    return '';
  }
}
