import * as React from 'react';
import { ActivityTemplatesDetailStyle } from './activity-templates-detail-style';
import HeaderMain from '../../../../components/header-main/header-main';
import { NavigationTabContainer } from '../../../../components/navigation-tabs/navigation-tabs-style';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import { RouteComponentProps, match, withRouter } from 'react-router';
import { MainLayoutSection } from '../../../main-layout-style';
import { connect } from 'react-redux';
import IStoreState from '../../../../redux/store/IStoreState';
import ActivityTemplatesDetailGeneral from './general/activity-templates-detail-general';
import { getActivityTemplate } from '../../../../services/centre';
import { IActivityTemplate } from '../../../../models/activity-template';
import { capitalize } from '../../../../utils/texts';
import { APP_WEB_URL } from '../../../../env'
export interface IActivityTemplatesDetailProps extends RouteComponentProps {
  match: match<{
    id: string;
    activityTemplatesId: string;
    diver: string;
  }>;
  staffPermissions?: any;
}

export interface IActivityTemplatesDetailState {
  activity?: IActivityTemplate;
  status: string;
  section: string;
  renderModal: string;
}

class ActivityTemplatesDetail extends React.Component<IActivityTemplatesDetailProps, IActivityTemplatesDetailState> {
  constructor(props: IActivityTemplatesDetailProps) {
    super(props);

    this.state = {
      section: 'general',
      renderModal: '',
      status: '',
    };
  }

  componentDidMount() {
    this.getActivity();
  }

  async getActivity() {
    const {
      match: {
        params: { activityTemplatesId },
      },
    } = this.props;
    const params = {
      filter: {
        relations: ['course', 'course.certifier', 'services', 'stock', 'sites', 'kind'],
      },
    };
    const params2 = {
      filter: {
        relations: ['category', 'exclusiveStock', 'exclusiveServices', 'cancellationPolicy'],
      },
    };
    const params3 = {
      filter: {
        relations: ['sites'],
      },
    };
    const resp = await getActivityTemplate(activityTemplatesId, params);
    const resp2 = await getActivityTemplate(activityTemplatesId, params2);
    const resp3 = await getActivityTemplate(activityTemplatesId, params3);
    resp.data = { ...resp.data, category: resp2.data.category, exclusiveStock: resp2.data.exclusiveStock, exclusiveServices: resp2.data.exclusiveServices, cancellationPolicy: resp2.data.cancellationPolicy, sites: resp3.data.sites };

    const activityTemplate: IActivityTemplate = resp.data;

    this.setState({ activity: activityTemplate });
  }

  reloadActivity() {
    this.getActivity();
  }

  renderContent() {
    const { section, activity, status } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;

    switch (section) {
      case 'general':
        return (
          <ActivityTemplatesDetailGeneral
            activity={activity}
            centreId={id}
            status={status}
            onReload={() => this.reloadActivity()}
          />
        );
      case 'divers':
        //return <ActivityDetailDivers activity={activity} />;
        break;
      case 'staff':
        //return <ActivityDetailStaff activity={activity} centreId={id} onReload={() => this.reloadActivity()} />;
        break;
      case 'rate':
        // return <ActivityDetailRate />;
        break;
      default:
        break;
    }
  }

  render() {
    const {
      match: {
        params: { activityTemplatesId },
      },
      staffPermissions,
    } = this.props;
    const { section, activity, status } = this.state;
    //const subtitleText = ACTIVITY_STATES_KEY_VALUES[status] ? ACTIVITY_STATES_KEY_VALUES[status].toUpperCase() : '';
    const subtitleText = 'No planificada'
    return (
      <>
        <HeaderMain
          goBack={true}
          title={activity ? capitalize(activity.name) : ''}
          subtitle={{ text: subtitleText, type: 'red' }}
          secondaryButtonBorderRed={{
            // img: images.eyeSvg,
            text: 'Vista buscador',
            onClick: () => { window.open(`${APP_WEB_URL}/activities/detail/${activityTemplatesId}`, "_blank") },

          }}
          secondaryButtonRed={
            (status === 'pending' || status === 'undone' || status === 'confirmed') && !staffPermissions
              ? {
                text: 'Cancelar',
                onClick: () => this.setState({ renderModal: 'modal-cancel-activity' }),
              }
              : undefined
          }
          mainButton={
            status === 'pending' && !staffPermissions
              ? {
                text: 'Confirmar',
                onClick: () => this.setState({ renderModal: 'modal-confirm' }),
              }
              : status === 'done' && !staffPermissions
                ? {
                  text: 'Finalizar',
                  onClick: () => this.setState({ renderModal: 'modal-finish' }),
                }
                : undefined
          }
        />
        <MainLayoutSection>
          <ActivityTemplatesDetailStyle>
            <NavigationTabContainer>
              <div
                className={`navigation-item ${section === 'general' && 'active'}`}
                onClick={() => this.setState({ section: 'general' })}
              >
                <BodyDestacadoText>Datos generales</BodyDestacadoText>
              </div>
              <div
                className={`navigation-item disable ${section === 'rate' && 'active'}`}
                onClick={() => this.setState({ section: 'rate' })}
              >
                <BodyDestacadoText>Valoraciones</BodyDestacadoText>
              </div>
            </NavigationTabContainer>
            {this.renderContent()}
          </ActivityTemplatesDetailStyle>
        </MainLayoutSection>
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(withRouter(ActivityTemplatesDetail));
