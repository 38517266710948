import * as React from 'react';
import { MainLayoutSection } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import { DestinationStyle } from './destination-style';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import TitleText from '../../../components/text/title-text/title-text';

import ModalDestination from './modal-destination/modal-destination';
import RenderModals from '../../../utils/render-modals/render-modals';
import { images } from '../../../assets/images';
import CardDestination from '../../../components/card-destination/card-destination';
import { getDestinations, deleteDestinations } from '../../../services/destinations';
import { COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import services from '../../../services/services';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

export interface IDestinationProps extends WithTranslation {}

export interface IDestinationState {
  renderModal: string;
  destinations: any;
  selected: any;
}

class Destination extends React.Component<IDestinationProps, IDestinationState> {
  constructor(props: IDestinationProps) {
    super(props);
    this.state = {
      renderModal: '',
      destinations: {},
      selected: '',
    };
  }

  componentDidMount() {
    this.getDestinations();
  }

  getDestinations = async () => {
    try {
      const res: any = await getDestinations({});
      const destinations: any = {};
      res.data.data.forEach((item: any) => {
        if (destinations[item.cotinent]) {
          destinations[item.cotinent].push(item);
        } else {
          destinations[item.cotinent] = [item];
        }
      });
      this.setState({ destinations });
    } catch (err) {}
  };

  deleteDestination = async (id: string) => {
    const { t } = this.props;
    try {
      await deleteDestinations(id);
      services.pushNotification({
        title: t('push:delete-destination.title'),
        text: t('push:delete-destination.text'),
        type: 'red',
      });
      this.setState({ renderModal: '', selected: '' }, () => this.getDestinations());
    } catch (err) {}
  };

  render() {
    const { renderModal, destinations, selected } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderMain
          title={t('layouts:config.destination.header')}
          mainButton={{
            text: t('buttons:create-new'),
            onClick: () => this.setState({ renderModal: 'modal-create-destination' }),
          }}
        />
        <MainLayoutSection>
          <DestinationStyle>
            <div className="destination-text">
              <Trans i18nKey={'layouts:config.destination.text'}>
                <SubtitleText>{'-'}</SubtitleText>
                <SubtitleText>{'-'}</SubtitleText>
              </Trans>
            </div>

            {Object.keys(destinations).map((key: string) => (
              <div key={key} className="destination-continent">
                <div className="destination-continent__title">
                  <TitleText>{t(`layouts:config.destination.${key}`)}</TitleText>
                </div>
                <div className="destination-continent__grid">
                  {destinations[key].map((item: any) => (
                    <div key={`destination-${item.id}`} className="destination-continent__item">
                      <CardDestination
                        isActive={item.active}
                        title={COUNTRIES_KEY_LABEL[item.country]}
                        onEdit={() => this.setState({ renderModal: 'modal-create-destination', selected: item })}
                        onDelete={() => this.setState({ renderModal: 'modal-delete', selected: item })}
                        img={item.picture ? item.picture : images.avatar}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </DestinationStyle>
        </MainLayoutSection>
        {renderModal === 'modal-create-destination' && (
          <ModalDestination
            showModal={renderModal === 'modal-create-destination'}
            onCancel={() => this.setState({ renderModal: '', selected: '' })}
            onAccept={() =>
              this.setState({ renderModal: '', selected: '' }, () => {
                this.getDestinations();
                services.showModalSuccess('Genial!', 'Se ha guardado correctamente');
              })
            }
            editItem={selected}
          />
        )}
        {renderModal === 'modal-delete' && (
          <ModalDelete
            title={'Eliminar destino'}
            subtitle={'Vas a eliminar un destino, ¿estás seguro?'}
            showModal={renderModal === 'modal-delete'}
            onRemove={() => {
              if (!!selected) {
                this.deleteDestination(selected.id);
              }
            }}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}
      </>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(Destination);
