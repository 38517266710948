export const transformToSI = (type: string, value: number): number => {
  const units = JSON.parse(localStorage.getItem('units') || '[]');
  if (units.length === 0) return 0;
  switch (type) {
    case 'depth':
      if (units.depth === 'feet') return Math.round(parseFloat((value / 3.28084).toFixed(2)));
      break;
    case 'distance':
      if (units.distance === 'mile') return parseFloat((value / 0.621371).toFixed(2));
      break;
    case 'weight':
      if (units.weight === 'pound') return parseFloat((value / 2.20462).toFixed(2));
      break;
    case 'pressure':
      if (units.pressure === 'psi') return parseFloat((value / 14.5038).toFixed(2));
      break;
    case 'drift':
    case 'wind':
      if (units[type] === 'knot') return parseFloat((value / 0.539957).toFixed(2));
      if (units[type] === 'm/s') return parseFloat((value / 0.277778).toFixed(2));
      break;
    default:
      break;
  }
  return value;
};

export const transformToUser = (type: string, value: number): number => {
  const units = JSON.parse(localStorage.getItem('units') || '[]');
  if (units.length === 0) return 0;
  switch (type) {
    case 'depth':
      if (units.depth === 'feet') return Math.round(parseFloat((value * 3.28084).toFixed(2)));
      break;
    case 'distance':
      if (units.distance === 'mile') return parseFloat((value * 0.621371).toFixed(2));
      break;
    case 'weight':
      if (units.weight === 'pound') return parseFloat((value * 2.20462).toFixed(2));
      break;
    case 'pressure':
      if (units.pressure === 'psi') return parseFloat((value * 14.5038).toFixed(2));
      break;
    case 'drift':
    case 'wind':
      if (units[type] === 'knot') return parseFloat((value * 0.539957).toFixed(2));
      if (units[type] === 'm/s') return parseFloat((value * 0.277778).toFixed(2));
      break;
    default:
      break;
  }
  return value;
};

export const getUserUnit = (type: string) => {
  const units = JSON.parse(localStorage.getItem('units') || '[]');
  if (units.length === 0) return '';
  const value = units[type];
  switch (value) {
    case 'meters':
      return 'm';
    case 'feet':
      return 'ft';
    case 'mile':
      return 'mi';
    case 'celsius':
      return '°C';
    case 'farenheit':
      return '°F';
    case 'pound':
      return 'lb';
    default:
      return value;
  }
};

export const ConvertDMSToDD = (degrees: number, minutes: number, seconds: number, direction: string) => {
  let dd = degrees + minutes / 60 + seconds / (60 * 60);
  if (direction === 'S' || direction === 'W') {
    dd = dd * -1;
  }
  return dd;
};

export const ConvertDDMToDD = (degrees: number, minutes: number, direction: string) => {
  let dd = degrees + minutes / 60;
  if (direction === 'S' || direction === 'W') {
    dd = dd * -1;
  }
  return dd;
};

// export const ConvertDDToDMS = (value: number, isLat: boolean) => {
//   const M = 0 | ((value % 1) * 60e7);
//   return {
//     dir: value < 0 ? (!isLat ? 'W' : 'S') : !isLat ? 'E' : 'N',
//     // tslint:disable-next-line: no-parameter-reassignment
//     deg: 0 | (value < 0 ? (value = -value) : value),
//     min: 0 | (M / 1e7),
//     sec: (0 | (((M / 1e6) % 1) * 6e4)) / 100,
//   };
// };

export const ConvertDDToDMS = (deg: number, lat: boolean) => {
  const absolute = Math.abs(deg);

  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);
  let direction;
  if (lat) {
    direction = deg >= 0 ? 'N' : 'S';
  } else {
    direction = deg >= 0 ? 'E' : 'W';
  }
  return {
    dir: direction,
    deg: degrees,
    min: minutes,
    sec: seconds,
  };
};

export const ConvertDDToDDM = (deg: number, lat: boolean) => {
  const absolute = Math.abs(deg);

  const degrees = Math.floor(absolute);
  const minutesNotTruncated = ((absolute - degrees) * 60).toFixed(2);

  let direction;
  if (lat) {
    direction = deg >= 0 ? 'N' : 'S';
  } else {
    direction = deg >= 0 ? 'E' : 'W';
  }
  return {
    dir: direction,
    deg: degrees,
    min: minutesNotTruncated,
  };
};
