import React, { Component } from 'react';
import { ModalGhostDiverFormContainer } from './modal-ghost-diver-form-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import InputBox from '../../../../components/input-box';
import MainButton from '../../../../components/main-button';
import ModalContainer from '../../../../components/modals/modal-container';
import { GhostDiverFormFunctionalProps } from './ghost-diver-form-functional';

interface ModalGhostDiverFormProps extends GhostDiverFormFunctionalProps {
  error: string;
  errorFields: { [key: string]: string };
  handleDataChange: (field: string, value: string) => void;
  submitForm: () => void;
  clearState: () => void;
}

export default class ModalGhostDiverForm extends Component<ModalGhostDiverFormProps, {}> {
  render() {
    const {
      onCancel,
      showModal,
      className,
      title,
      error,
      errorFields,
      handleDataChange,
      submitForm,
      clearState,
    } = this.props;
    return (
      <ModalContainer
        title={title}
        className=""
        modalClose={() => {
          clearState();
          onCancel();
        }}
        active={showModal}
      >
        <ModalGhostDiverFormContainer className={className}>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Nombre"
                      className="rounded-title"
                      type="text"
                      value=""
                      errorCode={errorFields['name']}
                      required={true}
                      labelText="Nombre"
                      onChange={value => {
                        handleDataChange('name', value);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Apellidos"
                      className="rounded-title"
                      type="text"
                      value=""
                      required={true}
                      errorCode={errorFields['surname']}
                      labelText="Apellidos"
                      onChange={value => {
                        handleDataChange('surname', value);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Email"
                      className="rounded-title"
                      type="email"
                      value=""
                      required={true}
                      errorCode={errorFields['email']}
                      labelText="Email"
                      onChange={value => {
                        handleDataChange('email', value);
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>
          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton type="primary" text="Añadir" onClick={() => submitForm()} />
            </div>
            <div className="create-user-buttons__item">
              <MainButton
                type="secondary"
                text="Cancelar"
                onClick={() => {
                  onCancel();
                  clearState();
                }}
              />
            </div>
          </div>
        </ModalGhostDiverFormContainer>
      </ModalContainer>
    );
  }
}
