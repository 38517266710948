// tslint:disable: file-name-casing
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Intro from './containers/intro/intro';
import NotFoundContainer from './components/not-found/not-found';
import CentrePanel from './navigation/centre-panel';
import EnterJwt from './containers/enterjwt/enterjwt-layout'
import Home from './navigation/home';
import services from './services/services';
import Loader from './components/loader';
import { setAgent, setExpiredToken } from './redux/actions';
import { images } from './assets/images';
import ModalInfo from './components/modals/modal-info/modal-info';
import ToastNotification from './components/toast-notification/toast-notification';
import ModalSuccess from './components/modals/modal-success/modal-success';
import { uploadUsers, confirmUser, getUser } from './services/users';
import queryString from 'query-string';
import { isAllowed } from './roles/can-component';
import OrganizationPanel from './navigation/organization-panel';
import i18n from 'i18next';
import ModalCookies from './components/modal-cookies/modal-cookies';
import Legal from './containers/legal/legal';
import { APP_WEB_URL } from './env';
import { withTranslation } from 'react-i18next';

interface AppProps {
  expiredToken?: boolean;
  loading?: boolean;
  notifications?: any;
  modalSuccess?: any;
  agent: any;
  t: any;
}

interface AppState {
  expiredToken: boolean;
  loading: boolean;
  logged: boolean;
  loggingOut: boolean;
  profilePicture?: string;
  modalSuccess: {
    title: string;
    text: string;
    show: boolean;
    onClose?: Function;
  };
}

class App extends Component<AppProps, AppState> {
  state: AppState = {
    logged: true,
    loading: true,
    loggingOut: false,
    expiredToken: false,
    modalSuccess: {
      title: '',
      text: '',
      show: false,
      onClose: () => { },
    },
  };

  componentDidMount() {
    const { reassignCentre, reassignOrg, token, jwt } = queryString.parse(window.location.search);

    if (jwt != null) {
      // confirmar usuario
      this.confirmUserAction(jwt);
    }

    if (reassignCentre && typeof reassignCentre === 'string') {
      localStorage.setItem('token', reassignCentre);
      localStorage.removeItem('userId');
    }
    if (reassignOrg && typeof reassignOrg === 'string') {
      localStorage.setItem('token', reassignOrg);
      localStorage.removeItem('userId');
    }

    if (token) {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    }

    if (localStorage.token && localStorage.userId) {
      this.getUser();
    } else {
      this.setState({ loading: false, logged: false });
      //window.location.href = APP_WEB_URL;
    }

  }

  componentDidUpdate(prevProps: AppProps) {
    const { modalSuccess } = this.props;

    if (prevProps.modalSuccess !== modalSuccess) {
      this.showModalSuccess();
    }
  }

  confirmUserAction = async (data: any) => {
    try {

      await confirmUser(data);
    } catch (err) {

      // console.log(err);
    }
  };


  shouldComponentUpdate(nextProps: AppProps, nextState: AppState) {

    if (nextProps.expiredToken && !this.props.expiredToken) {
      nextState.expiredToken = true;
    }
    if (!nextProps.loading && this.state.loading) {
      nextState.loading = false;
    }
    return true;
  }
  getUser = async () => {

    const res = await getUser(localStorage.userId);
    if (res.status === 403) {
      await services.setValue(setExpiredToken(true));
      this.setState({ loading: false });
      return;
    }
    this.setState({ logged: true, loading: false, profilePicture: res.data.picture });
    localStorage.setItem('units', JSON.stringify(res.data.units));
    await i18n.changeLanguage(res.data.lang);
    await services.setValue(setAgent(res.data));
    await services.setValue(setExpiredToken(false));
    return;
  };



  getUserOld = () => {
    services.get({
      endpoint: `users/${localStorage.userId}`,
      catch: (err: any) => {
        if (err.response && err.response.status && err.response.status === 403) {
          services.setValue(setExpiredToken(true));
        }
        this.setState({ loading: false });
      },
      then: (res: any) => {

        localStorage.setItem('units', JSON.stringify(res.data.units));
        i18n.changeLanguage(res.data.lang);
        services.setValue(setAgent(res.data));
        services.setValue(setExpiredToken(false));
        this.setState({ logged: true, loading: false, profilePicture: res.data.picture });
      },
    });
  };

  logout = () => {

    localStorage.clear();
    this.setState({ logged: false, expiredToken: false, loggingOut: true });
    window.location.href = APP_WEB_URL + '/?logout=true';
  };

  showModalSuccess() {
    const {
      modalSuccess: { value },
    } = this.props;
    value.show = true;
    this.setState({ modalSuccess: value });
  }

  closeModalSuccess() {
    const { modalSuccess } = this.state;
    if (modalSuccess.onClose) {
      modalSuccess.onClose();
    }
    modalSuccess.show = false;
    this.setState({ modalSuccess });
  }

  // Delete to Pro
  updateUser = async (data: any) => {
    const { agent } = this.props;

    try {
      const response: any = await uploadUsers(agent.id, data);
      services.setValue(setAgent(response.data));
    } catch (err) {
      // console.log(err);
    }
  };
  onLoginEnterJwt = () => {

    this.setState({ ...this.state, logged: true });
  }
  mensaje(msg: any) {
    return <></>

  }
  render() {
    const { t } = this.props;
    const { logged, modalSuccess, profilePicture } = this.state;
    const { loading, expiredToken, notifications, agent } = this.props;
    const notificationsArray = notifications && notifications.notifications ? notifications.notifications : [];
    if (!this.state.logged && this.props.loading) {
      //window.location.href = APP_WEB_URL;
    }


    return (
      <>
        {!logged ? (
          <Router>
            {loading && <Loader />}
            <Route path="/enterjwt/:id" render={props => <EnterJwt getUser={this.getUser} />} />
            <Switch>
              {/* Login // SignUp */}
              {!loading && !this.state.loggingOut &&
                <Route path="/" render={props => <Intro {...props} getUser={this.getUser} />} />
              }
            </Switch>
            <ToastNotification notifications={notificationsArray} />
            <ModalSuccess
              title={modalSuccess.title}
              text={modalSuccess.text}
              showModal={modalSuccess.show}
              onClose={() => this.closeModalSuccess()}
            />
            <Route path={`/legal`} component={Legal} />
            <ModalCookies />

            <Route path="/centres/:centreId/billing/bookings/:bookingId" render={({ match }) => {
              window.location.href = APP_WEB_URL + '/login-gestor?centreId=' + match.params.centreId + '&bookingId=' + match.params.bookingId;
              return '';
            }} />

          </Router>


        ) : (
          <Router>

            {loading && <Loader />}


            <ToastNotification notifications={notificationsArray} />
            <ModalSuccess
              title={modalSuccess.title}
              text={modalSuccess.text}
              showModal={modalSuccess.show}
              onClose={() => this.closeModalSuccess()}
            />
            {expiredToken && (
              <ModalInfo
                type="blue"
                className="max-index"
                title={['Su sesión ha ', <span key="modal-expired-token">caducado</span>]}
                onClose={() => this.logout()}
                text={['Es necesario que inicie la sesión de nuevo.']}
                icon={images.alertBlueSvg}
                showModal={true}
                buttons={[{ text: 'Aceptar', type: 'primary', onClick: () => this.logout() }]}
              />
            )}


            <Switch>
              {/* DetailMenu */}
              <Route path="/enterjwt/:id" render={props => <EnterJwt getUser={this.getUser} />} />
              {isAllowed(agent.roles, 'centres:management') && (
                <Route
                  path="/centres/:id"
                  render={props => <CentrePanel {...props} onLogout={this.logout} profilePicture={profilePicture} t={t} />}
                />
              )}
              {isAllowed(agent.roles, 'configuration:management') && (
                <Route
                  path="/organization/:organizationId"
                  render={props => (
                    <OrganizationPanel {...props} onLogout={this.logout} profilePicture={profilePicture} t={t} />
                  )}
                />
              )}
              <Route path={`/legal`} component={Legal} />
              {/* SimpleMenu */}
              <Route
                path={'/'}
                render={props => <Home {...props} onLogout={this.logout} profilePicture={profilePicture} />}
              />
              {/* <Route exact={true} path={'/'} render={props => <Home {...props} onLogout={this.logout} />} /> */}

              {/* 404 */}
              {/* <Route path={`/render-modals`} component={RenderModals} /> */}
              {loading &&
                <Route path="/" component={NotFoundContainer} />
              }
            </Switch>

            <ModalCookies />
          </Router>
        )}
        {/* <div className="fixed-languages">
        <SelectBox
            labelText="SELECCIONE UNA OPCIÓN"+
            placeholder="Seleccione"
            required={true}
            className="rounded-title"
            optionsText={LANGUAGES}
            optionKey={'value'}
            optionValue={'label'}
            defaultValue={agent.lang}
            icon={images.arrowDown2BlueSvg}
            onChange={value => this.updateUser({ lang: value })}
          />
        </div> */}
      </>
    );
  }
}

function mapStateToProps(
  state = {
    loading: { value: false },
    expiredToken: { value: false },
    notifications: [],
    modalSuccess: {},
    agent: { value: {} },
  }
) {
  return {
    loading: state.loading.value,
    expiredToken: state.expiredToken.value,
    notifications: state.notifications,
    modalSuccess: state.modalSuccess,
    agent: state.agent.value,
  };
}
export default withTranslation()(connect(mapStateToProps)(App));