import * as React from 'react';
import { PlanningDiverStyle } from './planning-diver-style';
import DicotomicCheckbox from '../../../../components/dicotomic-checkbox';
import InfoText from '../../../../components/text/info-text/info-text';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import SelectBox from '../../../../components/select-box';
import { images } from '../../../../assets/images';
import BodyText from '../../../../components/text/body-text/body-text';
import { getCentreDivers } from '../../../../services/centre';
import { IDropdown, IId } from '../../../../models/generic';
import SingleButton from '../../../../components/single-button/single-button';
import MainButton from '../../../../components/main-button';

interface PlanningDiverProps {
  onPrev?: () => void;
  onNext: (state: PlanningDiverState, Divers: IId[]) => void;
  state?: PlanningDiverState;
  centreId: string;
  edit?: boolean;
  selectedDivers?: string[];
  activityId: number;
  services: any[];
}

export interface PlanningDiverState {
  selectedDivers: string[];
  diversDropdown: IDropdown[];
  error: string;
}

class PlanningDiver extends React.Component<PlanningDiverProps, PlanningDiverState> {
  constructor(props: PlanningDiverProps) {
    super(props);
    this.state = {
      selectedDivers: [],
      diversDropdown: [],
      error: '',
    };
  }

  componentDidMount() {
    const { state } = this.props;
    if (state) {
      this.setState(state);
    } else {
      this.getCentreDiver();
    }
  }

  async getCentreDiver() {
    const { centreId, selectedDivers } = this.props;
    const params = {
      filter: {
        relations: ['user'],
      },
    };
    const resp = await getCentreDivers(centreId, params);
    if (!selectedDivers) {
      const newSelectedDivers: string[] = [];
      const diversDropdown = resp.data.data.map((diver: any) => {
        newSelectedDivers.push(diver.id.toString());
        return {
          value: diver.id.toString(),
          label: `${diver.user.name} ${diver.user.surname}`,
        };
      });
      this.setState({ diversDropdown, selectedDivers: newSelectedDivers });
    } else {
      const diversDropdown = resp.data.data.map((diver: any) => {
        return {
          value: diver.id.toString(),
          label: `${diver.user.name} ${diver.user.surname}`,
        };
      });
      this.setState({ diversDropdown, selectedDivers });
    }
  }

  onSave() {
    const { selectedDivers } = this.state;
    const { centreId, activityId, services, onNext } = this.props;
    if (!selectedDivers || selectedDivers.length === 0) this.setState({ error: 'required' });
    else {
      onNext(
        this.state,
        selectedDivers.map(diver => ({ id: parseInt(diver, undefined) })));
    }
  }

  render() {
    const { onPrev, edit } = this.props;
    const { diversDropdown, error, selectedDivers } = this.state;
    return (
      <PlanningDiverStyle>
        <div className="planning-diver-disclaimer">
          <BodyText>
            Especifica los buceadores que quieres que participen en la actividad.
          </BodyText>
        </div>
        <div className="planning-diver-select">
          <SelectBox
            labelText=""
            errorCode={error}
            placeholder="Selecciona un buceador"
            required={true}
            multiple={true}
            className="rounded"
            optionsText={diversDropdown}
            optionKey={'value'}
            optionValue={'label'}
            defaultValue={''}
            initialValue={''}
            initialMultipleValues={selectedDivers}
            defaultMultipleValues={selectedDivers}
            icon={images.arrowDown2BlueSvg}
            onChangeMultiple={value => {
              this.setState({ selectedDivers: value });
            }}
            onChange={() => { }}
          />
        </div>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={edit ? 'Guardar' : 'Siguiente'} type="primary" onClick={() => this.onSave()} />
            </div>
          </div>
        </div>
      </PlanningDiverStyle>
    );
  }
}

export default PlanningDiver;
