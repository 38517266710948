import * as React from 'react';
import { connect } from 'react-redux';
import { NotificationsStyle } from './notifications-style';
import HeaderMain from '../../components/header-main/header-main';
import { MainLayoutSection } from '../main-layout-style';
import { images } from '../../assets/images';
import SelectBox from '../../components/select-box';
import SubheadDestacadoText from '../../components/text/subhead-destacado-text/subhead-destacado-text';
import RowNotifications from '../../components/row-notifications/row-notifications';
import services from '../../services/services';
import { getNotifications, deleteNotifications } from '../../services/notifications';
import ModalDelete from '../../components/modals/modal-delete/modal-delete';
import Pagination from '../../components/pagination';
import { RouteComponentProps, match } from 'react-router-dom';
import { ContentBlock } from '../../components/content-block/content-block';
import IStoreState from '../../redux/store/IStoreState';
import { getOrganization } from '../../services/collaborator';
import { getCentre } from '../../services/centre';

export interface INotificationsProps extends RouteComponentProps {
  match: match<{
    id: string;
  }>;
  t?: any;
  type?: string;
  agent?: any;
}

export interface INotificationsState {
  orderBy: string;
  typeNotification: string;
  notifications: any[];
  notificationsFirst: any[];
  notificationsSecond: any[];
  notificationsSelected: any;
  modal: string;
  totalItemsFirst: number;
  totalItemsSecond: number;
  totalPagesFirst: number;
  totalPagesSecond: number;
  skipPageFirst: number;
  skipPageSecond: number;
  pageFirst: number;
  pageSecond: number;
  searchTitleFilter: string;
  [key: string]: any;
}

const NOTIFICATIONS_PER_PAGE = 5;
class Notifications extends React.Component<INotificationsProps, INotificationsState> {
  constructor(props: INotificationsProps) {
    super(props);

    this.state = {
      orderBy: 'read',
      typeNotification: '',
      notifications: [],
      notificationsFirst: [],
      notificationsSecond: [],
      notificationsSelected: {},
      modal: '',
      searchTitleFilter: '',
      totalItemsFirst: 0,
      totalItemsSecond: 0,
      totalPagesFirst: 0,
      totalPagesSecond: 0,
      skipPageFirst: 0,
      skipPageSecond: 0,
      pageFirst: 1,
      pageSecond: 1,
    };
  }

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications = async () => {
    const { type, agent, history } = this.props;

    const params = {
      filter: {
        relations: ['user'],
      },
    };
    let userId;
    let where: any;
    let centreId;
    if (!type) {
      userId = agent.id;
    } else {
      if (type === 'centre') {

        centreId = localStorage.getItem('centreId');
        if (!centreId || isNaN(+centreId)) this.props.history.push('/');
        else {
          const { data } = await getCentre(centreId, params);
          userId = data.user.id;
        }
      } else {
        const organizationId = localStorage.getItem('organizationId');
        if (!organizationId || isNaN(+organizationId)) this.props.history.push('/');
        else {
          const { data } = await getOrganization(organizationId, params);
          userId = data.user.id;
        }
      }
    }
    if (centreId) {
      where = { centreId: centreId, deleted: false, typeTo: true }
    } else {
      where = { deleted: false, typeTo: true }
    }
    const { skipPageFirst, skipPageSecond, orderBy } = this.state;
    let { searchTitleFilter } = this.state;
    if (searchTitleFilter === null) searchTitleFilter = '';
    const paramsFirst: any = {
      filter: {
        where: where,
        skip: skipPageFirst,
        limit: NOTIFICATIONS_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        relations: ['user'],
      },
    };
    const paramsSecond: any = {
      filter: {
        where: where,
        skip: skipPageSecond,
        limit: NOTIFICATIONS_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        relations: ['user'],
      },
    };

    if (orderBy === 'read') {
      paramsFirst.filter.where = {
        ...paramsFirst.filter.where,
        title: { value: `%${searchTitleFilter}%`, method: 'iLike' },
        read: false,
      };
      paramsSecond.filter.where = {
        ...paramsSecond.filter.where,
        title: { value: `%${searchTitleFilter}%`, method: 'iLike' },
        read: true,
      };
    } else {
      paramsFirst.filter.where = {
        ...paramsFirst.filter.where,
        title: { value: `%${searchTitleFilter}%`, method: 'iLike' },
        fav: true,
      };
      paramsSecond.filter.where = {
        ...paramsSecond.filter.where,
        title: { value: `%${searchTitleFilter}%`, method: 'iLike' },
        fav: false,
      };
    }
    userId = localStorage.getItem("userId");

    try {
      const userId = localStorage.getItem('userId') || '';
      const responseFirst: any = await getNotifications(userId, paramsFirst);
      const responseSecond: any = await getNotifications(userId, paramsSecond);
      const notificationsFirst = responseFirst.data.data;
      const notificationsSecond = responseSecond.data.data;
      const totalItemsFirst = responseFirst.data.count;
      const totalItemsSecond = responseSecond.data.count;
      const totalPagesFirst = Math.ceil(totalItemsFirst / NOTIFICATIONS_PER_PAGE);
      const totalPagesSecond = Math.ceil(totalItemsSecond / NOTIFICATIONS_PER_PAGE);
      this.setState({
        totalPagesFirst,
        totalPagesSecond,
        totalItemsFirst,
        totalItemsSecond,
        notificationsFirst,
        notificationsSecond,
      });
    } catch (err) {
      console.log(err);
    }
  };

  setNotifications = (notifications: any[]) => {
    const { typeNotification, orderBy } = this.state;
    const notificationsFirst = notifications.filter(
      n =>
        (!typeNotification || n.type === typeNotification) &&
        ((orderBy === 'read' && !n.read) || (orderBy === 'fav' && n.fav))
    );

    const notificationsSecond = notifications.filter(
      n =>
        (!typeNotification || n.type === typeNotification) &&
        ((orderBy === 'read' && n.read) || (orderBy === 'fav' && !n.fav))
    );
    this.setState({ notificationsFirst, notificationsSecond });
  };

  changeFilter = (value: string, key: string) => {
    this.setState({ [key]: value, pageFirst: 1, skipPageSecond: 0, pageSecond: 1, skipPageFirst: 0 }, () => {
      this.getNotifications();
    });
  };

  handleSelected = (select: boolean, id: string) => {
    const { notificationsSelected } = this.state;
    notificationsSelected[id] = select;
    this.setState({ notificationsSelected });
  };

  timeoutSearch: any;
  handleSearch = (value: string) => {
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      this.setState(
        { searchTitleFilter: value, pageFirst: 1, skipPageSecond: 0, pageSecond: 1, skipPageFirst: 0 },
        () => this.getNotifications()
      );
    }, 500);
  };

  handleDelete = async () => {
    const { notificationsSelected } = this.state;
    const keys = Object.keys(notificationsSelected);
    for (const k of keys) {
      try {
        await deleteNotifications(k);
      } catch (err) {
        console.log(err);
      }
    }
    services.pushNotification({
      text:
        keys.length > 1
          ? 'Se han eliminado las notificaciones del panel con éxito'
          : 'Se ha eliminado una notificación del panel con éxito',
      title: keys.length > 1 ? 'Notificaciones eliminadas' : 'Notificación eliminada',
      type: 'red',
    });
    this.setState({ modal: '', notificationsSelected: {} }, () => this.getNotifications());
  };

  setPage(page: number, first: boolean) {
    const { totalItemsFirst, totalItemsSecond } = this.state;
    const skip: number = NOTIFICATIONS_PER_PAGE * (page - 1);
    if ((first && skip < totalItemsFirst) || (!first && skip < totalItemsSecond)) {
      if (first) {
        this.setState({ skipPageFirst: skip, pageFirst: page }, () => {
          this.getNotifications();
        });
      } else {
        this.setState({ skipPageSecond: skip, pageSecond: page }, () => {
          this.getNotifications();
        });
      }
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t
    } = this.props;
    const {
      totalItemsFirst,
      totalItemsSecond,
      notificationsFirst,
      notificationsSecond,
      notificationsSelected,
      orderBy,
      modal,
      totalPagesFirst,
      totalPagesSecond,
      pageFirst,
      pageSecond,
    } = this.state;
    let deleted = false;

    Object.keys(notificationsSelected).forEach(k => {
      if (notificationsSelected[k]) {
        deleted = true;
        return;
      }
    });

    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Notificaciones'}
            withSearch={true}
            resultCount={totalItemsFirst + totalItemsSecond}
            handleSearch={(value: string) => this.handleSearch(value)}
            placeholderSearch={'Títutlo'}
            secondaryButtonRed={
              deleted ? { text: 'Eliminar', onClick: () => this.setState({ modal: 'modal-delete' }) } : undefined
            }
          />
          <MainLayoutSection>
            <NotificationsStyle>
              <div className="notification-filters">
                {/*<div className="notification-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Tipo de notificación"
                    className="rounded-filter"
                    optionsText={[
                      { label: 'Tipo de notificación', value: '' },
                      { label: 'TODO', value: 'TODO' },
                    ]}
                    optionKey={'value'}
                    optionValue={'label'}
                    initialValue={''}
                    icon={images.arrowDown2BlueSvg}
                    onChange={value => this.changeFilter(value, 'typeNotification')}
                  />
                </div>*/}
                <div className="notification-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Ordenar por"
                    className="rounded-filter"
                    optionsText={[
                      { label: 'Favoritas', value: 'fav' },
                      { label: 'Leídas', value: 'read' },
                    ]}
                    optionKey={'value'}
                    optionValue={'label'}
                    initialValue={orderBy}
                    icon={images.arrowDown2BlueSvg}
                    onChange={value => this.changeFilter(value, 'orderBy')}
                  />
                </div>
              </div>
              <div className="notifications-section">
                <div className="notifications-section__title">
                  <SubheadDestacadoText>
                    {orderBy === 'read' ? 'No Leídas' : 'Favoritas'} ({totalItemsFirst})
                  </SubheadDestacadoText>
                </div>
                {notificationsFirst.map((n: any, index: number) => (
                  <div key={`notification-${n.id}`} className="notifications-section__row">
                    <RowNotifications
                      reloadNotifications={() => this.getNotifications()}
                      notification={n}
                      notificationSelected={!!notificationsSelected[n.id]}
                      onSelected={(select: boolean, id: string) => this.handleSelected(select, id)}
                      centreId={id}
                    />
                  </div>
                ))}
                {totalPagesFirst > 1 && (
                  <div className="diving-center-pagination">
                    <Pagination page={pageFirst} limit={totalPagesFirst} callback={page => this.setPage(page, true)} />
                  </div>
                )}
              </div>

              <div className="notifications-section">
                <div className="notifications-section__title">
                  <SubheadDestacadoText>
                    {orderBy === 'read' ? 'Leídas' : 'No Favoritas'} ({totalItemsSecond})
                  </SubheadDestacadoText>
                </div>
                {notificationsSecond.map((n: any, index: number) => (
                  <div key={`notification-${n.id}`} className="notifications-section__row">
                    <RowNotifications
                      reloadNotifications={() => this.getNotifications()}
                      notification={n}
                      notificationSelected={!!notificationsSelected[n.id]}
                      onSelected={(select: boolean, id: string) => this.handleSelected(select, id)}
                      centreId={id}
                    />
                  </div>
                ))}
                {totalPagesSecond > 1 && (
                  <div className="diving-center-pagination">
                    <Pagination
                      page={pageSecond}
                      limit={totalPagesSecond}
                      callback={page => this.setPage(page, false)}
                    />
                  </div>
                )}
              </div>
            </NotificationsStyle>
          </MainLayoutSection>
          {/* Modal Delete centre  */}
          <ModalDelete
            title="Vas a eliminar las notificaciones seleccionadas"
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete'}
            onRemove={() => this.handleDelete()}
            onCancel={() => this.setState({ modal: '' })}
          />
        </ContentBlock>
      </>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    agent: state.agent.value,
  };
};

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(Notifications);
