import * as React from 'react';
import { BonusesStyle } from './bonuses-style';
import { images } from '../../../assets/images';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import SelectBox from '../../../components/select-box';
import MainTable from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { withRouter, RouteComponentProps } from 'react-router';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';

export interface IBonusesProps extends RouteComponentProps, WithTranslation {}

export interface IBonusesState {}

class Bonuses extends React.Component<IBonusesProps, IBonusesState> {
  constructor(props: IBonusesProps) {
    super(props);

    this.state = {};
  }

  tableColumns = [
    {
      text: this.props.t('tables:ref-bill'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:dive-site'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:cif'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('tables:month'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:year'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:tax-base'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:tax'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:discount'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:total'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:status'),
      filter: false,
      ascendent: true,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRows = [
    {
      ref: {
        key: 'highlighted-body',
        value: 'sdfghjkl',
      },
      center: {
        key: '',
        value: 'Centro La Marina',
      },
      cif: {
        key: '',
        value: '1234567043',
      },
      month: {
        key: '',
        value: 'Diciembre',
      },
      year: {
        key: '',
        value: '2019',
      },
      base: {
        key: '',
        value: '15.00 €',
      },
      tax: {
        key: '',
        value: '15.00 €',
      },
      discount: {
        key: '',
        value: '15.00 €',
      },
      total: {
        key: '',
        value: '15.00 €',
      },
      status: {
        key: 'icon',
        value: '',
      },
      actions: {
        key: 'options',
        actions: [
          {
            label: this.props.t('tables:watch'),
            icon: images.eyeSvg,
            onClick: () => {},
          },
          {
            label: this.props.t('tables:download'),
            icon: images.downloadSvg,
            onClick: () => {},
          },
        ],
      },
    },
  ];

  rowKeys = ['ref', 'center', 'cif', 'month', 'year', 'base', 'tax', 'discount', 'total', 'status', 'actions'];

  render() {
    const { t } = this.props;
    return (
      <>
        <HeaderMain title={t('layouts:bonuses.header')} withSearch={true} />
        <MainLayoutSection>
          <BonusesStyle>
            <div className="bonus-title">
              <div className="bonus-title__filters">
                <SelectBox
                  className="rounded-filter"
                  labelText="Certificadora"
                  placeholder="Seleccione"
                  required={true}
                  initialValue={''}
                  optionsText={[]}
                  optionKey={'value'}
                  optionValue={'label'}
                  defaultValue={''}
                  icon={images.arrowDown2BlueSvg}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="bonus-table">
              <MainLayoutTableContainer>
                <div className="diving-center-table">
                  <MainTable
                    columns={this.tableColumns}
                    rows={this.tableRows}
                    rowKeys={this.rowKeys}
                    onOrder={() => {}}
                  />
                </div>
                <div className="diving-center-pagination">
                  <Pagination page={1} callback={() => {}} limit={22} />
                </div>
              </MainLayoutTableContainer>
            </div>
          </BonusesStyle>
        </MainLayoutSection>
      </>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(withRouter(Bonuses));
