import React, { useEffect, useState } from 'react';
import IStoreState from '../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import HeaderMain from '../../../../components/header-main/header-main';
import { images } from '../../../../assets/images';
import { ContentBlock } from '../../../../components/content-block/content-block';
import { BookingsDetailStyle } from '../bookings-detail/bookings-detail-style';
import { FormGrid, FormGridItem, FormGridRow, MainLayoutSection } from '../../../main-layout-style';
import Collapsible from 'react-collapsible';
import ActivityDetailBooking from '../../../planning/activity-detail/booking/activity-detail-booking';
import ContentRight from '../bookings-detail/content-right/content-right';
import styles from './booking-create.module.scss'
import Button from '../../../../components/buttons/main-button/button';
import calendarSvg from '../../../../assets/images/calendar.svg';
import SelectBox from '../../../../components/select-box';
import CalendarBox from '../../../../components/calendar-box';
import { IActivity, ICourseActivityInfo } from '../../../../models/activities';
import { IMultilang, ITimeBlocks } from '../../../../models/generic';
import BookingData from '../bookings-detail/booking-data/booking-data';
import PayData from '../bookings-detail/pay-data/pay-data';
import BookingFormDivers from '../booking-form/booking-form-divers/booking-form-divers';
import ItemSectionDetail from '../../../../components/item-sections-detail/item-sections-detail';
import TextText from '../../../../components/text/text-text/text-text';
import BookingCreateData from './booking-create-data/booking-create-data';
import moment from 'moment';
import { PLANNING_RESERVES_PER_PAGE } from '../../../../constants/data';
import { copyOf, mergeObject } from '../../../../utils/other';
import { getCentreActivities, getCentreActivity } from '../../../../services/centre';
import services from '../../../../services/services';
import { setStartActivityDate } from '../../../../redux/actions';
import { transformDateCalendar } from '../../../../utils/time';
import { bookActivities } from '../../../../services/activities';
import { useTranslation } from 'react-i18next';

const BookingCreate = (props: any): JSX.Element => {

  const {match: {params: { id } }} = props;
  const [booking, setBooking] = useState({
    services: [],
    divers: [],
    comments: '',
    activity: {},
    diverId: null,
    lines: [],
    bookingId: '000000',
    id: 0,
    confirmed: false,
    rejected: false,
    cancelled: false
  });
  const [activity, setActivity] = useState<any>({
    createdAt: '',
    updatedAt: '',
    id: 0,
    cancelled: false,
    collaboration: {
      centre: 0,
      type: '',
    },
    comments: '',
    descriptions: '',
    additionalInformation: '',
    confirmed: false,
    courseInfo: {},
    date: '',
    discipline: '',
    category: 0,
    diveCount: 0,
    needBoat: false,
    meal: '',
    exclusiveness: {
      included: false,
      price: 0,
    },
    price: 0,
    inmediateConfirmation: false,
    kind: [],
    maxDivers: 0,
    minDivers: 0,
    minimumRequisites: {
      age: '',
      depth: 0,
      level: 0,
      requisites: [],
    },
    name: '',
    pictures: '',
    timeBlocks: [],
    transport: {
      departurePoint: '',
      transport: '',
    },
    periodicityId: '',
    dives: [],
    stock: [],
    services: [],
    template: '',
    bookings: [],
    staff_activities: [],
    sites: [],
    divesInfo: {
      start: '',
      finish: '',
      diveSite: 0,
      diveId: 0,
    },
    centre: '',
    divers: [],
  });
  const [activitiesSelect, setActivitiesSelect] = useState<any>([]);
  const [startDate, setStartDate] = useState('');
  const [isActivitySelected, setIsActivitySelected] = useState(false);
  const [isDiverSelected, setIsDiverSelected] = useState(false);
 

  useEffect(() => {
    getFutureActivities();
  },[]);

  useEffect(() => {
    setIsDiverSelected(props.isDiverSelected)
  },[props.isDiverSelected]);

  const renderTrigger = (title: string, index: number) => {
    return (
      <>
        <span className={`title_index title_index_${index}`}>{index}</span>
        <span>{title}</span>
      </>
    )
  }

  const getFutureActivities = async () => {
    const now = moment();
    const params: any = {
      filter: {
        where: {
          date: {
            method: '>',
            value: now.toISOString(),
            type: 'timestamp',
          },
        },
      },
    };
    const resp = await getCentreActivities(id, params);
    const activities = resp.data.data;

    const selectList = activities.map((activity: any) => {
        return { value: activity.id.toString(), label: activity.name };
    });
    setActivitiesSelect(selectList)
  }

  const startDateSelected = (value: string) => {
    setStartDate(value)
    services.setValue(setStartActivityDate(transformDateCalendar(value)))
  };

  const activitySelected = async (value: string) => {
    const activityId = value;
    const params1 = {
      filter: {
        relations: ['dives', 'dives.site', 'services', 'stock'],
      },
    };
    const params2 = {
      filter: {
        relations: ['template', 'template.cancellationPolicy'],
      },
    };
    const params3 = {
      filter: {
        relations: ['bookings', 'bookings.divers', 'divers.user', 'sites'],
      },
    };
    const params4 = {
      filter: {
        relations: ['staff_activities', 'staff_activities.staff', 'staff.user', 'staff.documents'],
      },
    };
    const resp = await getCentreActivity(id, activityId, params1);
    const resp2 = await getCentreActivity(id, activityId, params2);
    const resp3 = await getCentreActivity(id, activityId, params3);
    const resp4 = await getCentreActivity(id, activityId, params4);
    const activity = mergeObject(mergeObject(mergeObject(resp.data, resp2.data), resp3.data), resp4.data);
    setActivity(activity)
    setIsActivitySelected(true)
  }

  const reloadActivity = () => {
    activitySelected(activity.id);
  }

  const checkIsActivitySelected = () => {
    if (!isActivitySelected) {
      sendNotification(t('layouts:error'), t('layouts:selact'), 'red');
    }
  };

  const sendNotification = (title: string, msg: string, type: string) => {
    services.pushNotification({
      title,
      text: msg,
      type,
    });
  }

  const renderSelectActivity = () => {
    return (
      <div className={styles.find_activity}>
        {/*<div className={styles.select_calendar}>*/}
        {/*  <span>Puedes añadir tu actividad desde el Calendario</span>*/}
        {/*  <Button*/}
        {/*    onClick={() => {}}*/}
        {/*    text="Ver Calendario"*/}
        {/*    leftIcon={images.calendarSvg}*/}
        {/*    className={'border-red'}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className={styles.select_activity}>
          <FormGrid>
            <FormGridRow>
              <FormGridItem type="col-2">
                <CalendarBox
                  className="rounded-title"
                  initialValue=""
                  required={true}
                  labelText={t('layouts:bookings.initdate')}
                  onChange={ev => startDateSelected(ev)}
                />
              </FormGridItem>
              <FormGridItem type="col-2">
                <SelectBox
                  className="rounded-title"
                  labelText={t('layouts:bookings.options.addact')}
                  optionsText={activitiesSelect}
                  optionKey={'value'}
                  optionValue={'label'}
                  icon={images.arrowDown2BlueSvg}
                  onChange={(value) => activitySelected(value)}
                />
              </FormGridItem>
            </FormGridRow>
          </FormGrid>
        </div>
      </div>
    )
  }

  const makeBooking = async () => {
    let servicesList: any = [];
    for (let i = 0; i < props.bookingLines; i++) {
      servicesList = [...servicesList, props.bookingLines.id]
    }
    const data = {
      user: props.accountDivers[0].user.id,
      card: 'cardExample',
      isForOtherPerson: false,
      services: servicesList,
      divers: props.accountDiversGhost || [],
      paymentType: props.bookingPaymentData.paymentType
    };

    if (props.bookingPaymentData.paymentType === 1) {
      data.card = props.bookingPaymentData.paymentResponse;
    }

    if (data.services.length === 0) {
      delete data.services
    }
    const res = await bookActivities(activity.id, data);
    props.history.goBack()
  };
  const { t } = useTranslation(['layouts']);
  return (
    <ContentBlock>
      <HeaderMain
        title={t('layouts:bookings.options.createres')}
        goBack={true}
        secondaryButtonBorderRed={{
          img: images.closeRedSvg,
          text: t('layouts:bookings.options.cancelproc'),
          onClick: () => {props.history.goBack()},
        }}
      />
      <MainLayoutSection>
        <BookingsDetailStyle>
          <div className="contents">
            <div className="content-center">
              <Collapsible trigger={renderTrigger(t('layouts:bookings.options.addToact'), 1)} transitionTime={200} triggerTagName='div'>
                { renderSelectActivity() }
                {
                  activity ?
                    <ActivityDetailBooking
                      create={true}
                      activity={activity}
                      centreId={id}
                      onReload={() => reloadActivity()}
                    />: ''
                }
              </Collapsible>
              <Collapsible
                trigger={renderTrigger(t('layouts:bookings.data'), 2)}
                transitionTime={200}
                triggerTagName='div'
                triggerDisabled={!isActivitySelected}
              >
                <BookingData
                  booking={[]}
                  activity={activity}
                  create={true}
                />
              </Collapsible>
              <Collapsible
                trigger={renderTrigger(t('layouts:bookings.paydata'), 3)}
                transitionTime={200}
                triggerTagName='div'
                triggerDisabled={!isActivitySelected || !isDiverSelected}
              >
                {
                  <PayData
                    bookingId={'null'}
                    create={true}
                  />
                }
              </Collapsible>

              <div className="footer_btn">
                {
                  <Button
                    onClick={() => makeBooking()}
                    text={t('layouts:bookings.dobook')}
                    type={'error'}
                    disabled={!isActivitySelected || !isDiverSelected}
                  />
                }
              </div>
            </div>
            <div className="content-right">
              <ContentRight
                booking={booking}
                create={true}
                activityPrice={activity.price}
                updateCommentsState={(e: any) => {}}
              />
            </div>
          </div>
        </BookingsDetailStyle>
      </MainLayoutSection>
    </ContentBlock>
  )
}

function mapStateToProps(state: IStoreState) {
  return {
    bookingLines: state.bookingLines.value,
    isDiverSelected: state.isDiverSelected.value,
    accountDivers: state.accountDivers.value,
    accountDiversGhost: state.accountDiversGhost.value,
    bookingPaymentData: state.bookingPaymentData.value
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(BookingCreate));
