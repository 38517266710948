import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import { MainLayoutSection } from '../../main-layout-style';
import { NavigationTabContainer } from '../../../components/navigation-tabs/navigation-tabs-style';
import MarineReserveDetail from '../marine-reserve-detail/marine-reserve-detail';
import MarineReserveGallery from '../marine-reserve-gallery/marine-reserve-gallery';
import MarineReserveRate from '../marine-reserve-rate/marine-reserve-rate';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import MarineReserveDive from '../marine-reserve-dive-site/marine-reserve-dives';
import { COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import services from '../../../services/services';
import { deleteMarineReservePictures, createMarineReservePictures } from '../../../services/marine-reserve-pictures';
import ModalUploadFiles from '../../../components/modals/modal-upload-files/modal-upload-files';
import { getOneMarineReserve, deleteMarineReserve } from '../../../services/marine-reserve';
import ModalCreateMarineReserve from '../../../components/modals/modal-create-marine-reserve/modal-create-marine-reserve';
import ModalDiveSite from '../../../components/modals/modal-dive-site/modal-dive-site';

interface RouteParams {
  id: string;
}
export interface IMarineReserveDetailSwitchProps extends RouteComponentProps<RouteParams> {}

export interface IMarineReserveDetailSwitchState {
  activeTab: string;
  marineReserve: any;
  selectedPictures: any[];
  modal: string;
  diveSites: any[];
  cards: any[];
}

class MarineReserveDetailSwitch extends React.Component<
  IMarineReserveDetailSwitchProps,
  IMarineReserveDetailSwitchState
> {
  static tabs = {
    detail: 'detail',
    gallery: 'gallery',
    rate: 'rate',
    dives: 'dives',
  };

  constructor(props: IMarineReserveDetailSwitchProps) {
    super(props);

    this.state = {
      activeTab: MarineReserveDetailSwitch.tabs.detail,
      marineReserve: {
        name: '-',
        state: '-',
        country: 'ES',
        description: {},
      },
      selectedPictures: [],
      modal: '',
      diveSites: [],
      cards: [],
    };

    // this.contentComponent = React.createRef();
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.getMarineReserve(match.params.id);
    }
  }

  getMarineReserve = async (id: string) => {
    try {
      const params = {
        filter: {
          relations: ['sites', 'sites.reserve'],
        },
      };

      const response: any = await getOneMarineReserve(id, params);
      const diveSites = response.data.sites ? response.data.sites : [];
      this.setState({ diveSites, marineReserve: response.data }, () => this.getCardsMaps());
    } catch (err) {}
  };

  getSpot = (type?: string) => {
    switch (type) {
      case 'site':
        return 'dive-spot';
      case 'marine-reserve':
        return 'marine-reserve';
      default:
        return 'invalid-spot';
    }
  };

  getCardsMaps = async () => {
    const { diveSites } = this.state;
    const pending: any[] = diveSites.map(async (site: any, index: number) => {
      return {
        location: site.location,
        spot: this.getSpot(site.category),
        images:
          site.pictures && site.pictures.length > 0
            ? site.pictures.map((p: any) => p.src).slice(0, 3)
            : [images.jellyfishIntro, images.modalSuccessJelly, images.comingSoon],
        type: {
          icon: images.beachGreySvg,
          text: site.kind,
        },
        title: site.name,
        depth: `${site.minDepth} m - ${site.maxDepth} m`,
        rate: site.raiting, // RAITING O RATINGS?
        next: 'inmersiones próximos 7 días',
        lat: site.latitude,
        lng: site.longitude,
      };
    });
    const cards: any[] = await Promise.all(pending);
    this.setState({ cards });
  };

  deleteMarineReserve = async (id: string) => {
    const { history, location } = this.props;
    try {
      await deleteMarineReserve(id);
      services.pushNotification({
        title: 'Reserva marina eliminada',
        text: 'Se ha eliminado la reserva marina con éxito.',
        type: 'red',
      });
      this.setState({ modal: '' }, () => history.push(location.pathname.split('/detail')[0]));
    } catch (err) {}
  };

  deletePicturesMarineReserve = async () => {
    const { selectedPictures } = this.state;

    for (const pic of selectedPictures) {
      try {
        await deleteMarineReservePictures(pic.id);
        services.pushNotification({
          title: 'Fotografía eliminada',
          text: 'Se ha eliminado la fotografía con éxito.',
          type: 'red',
        });
      } catch (err) {}
    }

    this.setState({ modal: '', selectedPictures: [] }, () => {
      const event = new Event('reload-marine-reserve');
      window.dispatchEvent(event);
    });
  };

  addPicturesMarineReserve = async (files: any) => {
    const { match } = this.props;
    const picturesData: any[] = files.map((p: any) => ({
      file: p.data,
      reserve: match.params.id.toString(),
    }));

    for (const element of picturesData) {
      try {
        const formData = new FormData();
        formData.set('file', element.file);
        formData.set('reserve', element.reserve);
        await createMarineReservePictures(formData);
      } catch (err) {}
    }

    this.setState({ modal: '' }, () => {
      const event = new Event('reload-marine-reserve');
      window.dispatchEvent(event);
    });
  };

  setSelectedPictures = (selectedPictures: string[]) => {
    this.setState({ selectedPictures });
  };

  renderHeader() {
    const { activeTab, marineReserve, selectedPictures } = this.state;
    const {
      tabs: { detail, gallery, rate, dives },
    } = MarineReserveDetailSwitch;

    let mainButton = undefined;
    let secondaryButtonRed = undefined;

    switch (activeTab) {
      case detail:
        mainButton = {
          text: 'Editar',
          onClick: () => this.setState({ modal: 'create-marine' }),
        };
        secondaryButtonRed = {
          text: 'Eliminar',
          onClick: () => this.setState({ modal: 'modal-delete' }),
        };

        break;
      case gallery:
        if (selectedPictures.length === 0) {
          mainButton = {
            img: images.uploadWhiteSvg,
            text: 'Subir fotografías',
            onClick: () => this.setState({ modal: 'modal-add-pictures' }),
          };
        } else {
          secondaryButtonRed = {
            // img: images.uploadSvg,
            text: 'Eliminar',
            onClick: () => this.setState({ modal: 'modal-delete-pictures' }),
          };
        }
        break;
      case rate:
        break;
      case dives:
        mainButton = {
          text: 'Crear',
          onClick: () => this.setState({ modal: 'create-site' }),
        };
        break;
    }

    return (
      <HeaderMain
        goBack={true}
        title={`${marineReserve.name}, ${marineReserve.state}, ${COUNTRIES_KEY_LABEL[marineReserve.country]}`}
        mainButton={mainButton}
        secondaryButtonRed={secondaryButtonRed}
      />
    );
  }

  renderContent() {
    const { activeTab } = this.state;
    const {
      tabs: { detail, gallery, rate, dives },
    } = MarineReserveDetailSwitch;

    switch (activeTab) {
      case detail:
        return (
          <MarineReserveDetail
            // ref={this.contentComponent}
            marineReserve={this.state.marineReserve}
          />
        );
      case gallery:
        return (
          <MarineReserveGallery
            setSelectedPictures={(selectedPicture: string[]) => this.setSelectedPictures(selectedPicture)}
            clearValue={this.state.selectedPictures.length === 0}
            {...this.props}
          />
        );
      case rate:
        return (
          <MarineReserveRate
            // ref={this.contentComponent}
            {...this.props}
          />
        );
      case dives:
        return <MarineReserveDive />;
    }
  }

  render() {
    const { activeTab, modal, cards, diveSites, marineReserve } = this.state;
    const { match } = this.props;

    return (
      <>
        {/* Header */}
        {this.renderHeader()}
        {/* Content */}
        <MainLayoutSection>
          <NavigationTabContainer>
            <div
              className={`navigation-item ${activeTab === MarineReserveDetailSwitch.tabs.detail && 'active'}`}
              onClick={() => this.setState({ activeTab: MarineReserveDetailSwitch.tabs.detail })}
            >
              <BodyDestacadoText>Detalle</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${activeTab === MarineReserveDetailSwitch.tabs.gallery && 'active'}`}
              onClick={() => this.setState({ activeTab: MarineReserveDetailSwitch.tabs.gallery, selectedPictures: [] })}
            >
              <BodyDestacadoText>Galería de imágenes</BodyDestacadoText>
            </div>
            {/*<div
              className={`navigation-item ${activeTab === MarineReserveDetailSwitch.tabs.dives && 'active'}`}
              onClick={() => this.setState({ activeTab: MarineReserveDetailSwitch.tabs.dives })}
            >
              <BodyDestacadoText>Puntos de inmersión</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${activeTab === MarineReserveDetailSwitch.tabs.rate && 'active'}`}
              onClick={() => this.setState({ activeTab: MarineReserveDetailSwitch.tabs.rate })}
            >
              <BodyDestacadoText>Valoraciones</BodyDestacadoText>
            </div>*/}
          </NavigationTabContainer>

          {/* Page Content */}
          {this.renderContent()}
        </MainLayoutSection>

        {modal === 'create-marine' && (
          <ModalCreateMarineReserve
            reserveMarineId={match.params.id}
            showModal={modal === 'create-marine'}
            onCancel={() => this.setState({ modal: '' })}
            onClose={(load?: boolean) =>
              this.setState({ modal: '' }, () => {
                if (load) this.getMarineReserve(match.params.id);
              })
            }
          />
        )}
        {modal === 'modal-delete' && (
          <ModalDelete
            title={'Vas a eliminar una reserva marina'}
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete'}
            onRemove={() => {
              if (!!match.params.id) {
                this.deleteMarineReserve(match.params.id);
              }
            }}
            onCancel={() => this.setState({ modal: '' })}
          />
        )}

        {modal === 'modal-delete-pictures' && (
          <ModalDelete
            title={'Vas a eliminar imágenes de una reserva marina'}
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete-pictures'}
            onRemove={() => {
              this.deletePicturesMarineReserve();
            }}
            onCancel={() => this.setState({ modal: '' })}
          />
        )}

        {modal === 'modal-add-pictures' && (
          <ModalUploadFiles
            showModal={modal === 'modal-add-pictures'}
            onConfirm={(files: any) => {
              this.addPicturesMarineReserve(files);
            }}
            onClose={() => this.setState({ modal: '' })}
          />
        )}

        {modal === 'create-site' && (
          <ModalDiveSite
            showModal={modal === 'create-site'}
            diveSiteId={''}
            divesSites={diveSites}
            cards={cards}
            onClose={(load?: boolean) =>
              this.setState({ modal: '' }, () => {
                if (load) {
                  const event = new Event('reload-marine-reserve');
                  window.dispatchEvent(event);
                }
              })
            }
            reserve={marineReserve}
          />
        )}
      </>
    );
  }
}

export default withRouter(MarineReserveDetailSwitch);
