import * as React from 'react';
import moment from 'moment';

import { BillsStyle } from './bills-style';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import SelectBox from '../../../components/select-box';
import { images } from '../../../assets/images';
import Pagination from '../../../components/pagination';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { RouteComponentProps, withRouter, match } from 'react-router';
import { getCentreBills } from '../../../services/bills';
import { capitalize } from '../../../utils/texts';
import FormatUtils from '../../../utils/formatter';
import CalendarBox from '../../../components/calendar-box';
import Select from '../../../components/form-components/input/select';
import ButtonDropdown from '../../../components/buttons/button-dropdown/button-dropdown';
import MiniModalCheckbox from '../../../components/modals/mini/checkbox/mini-modal-checkbox';
import MainButton from '../../../components/main-button';
import SingleButton from '../../../components/single-button/single-button';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import OopsContent from '../../../components/oops-content/oops-content';
import { MainTableV2 } from '../../../components/main-table-v2/main-table-layout-v2';
import ModalInfo from '../../../components/modals/modal-info/modal-info';
import services from '../../../services/services';
import { IFilter } from '../../../models/filter';
import YearsCheckList from '../../../components/years-checklist/years-checklist';
import { getMonthFilterOptions, getBillColumns } from '../data/bills-data';

const PAGINATION_LIMIT = 10;

export interface IBillsProps extends RouteComponentProps, WithTranslation {
  match: match<{ id: string }>;
}

export interface IBillsState {
  filters: { [key: string]: IFilter };
  searchedText: any;
  noResult: any;
  preResult: any[];
  bills: any;
  pages: number;
  page: number;
  count: number;
  downloadUrl: string | null;
  firstLoad: boolean;

  yearFilter: any[];
  monthFilter: string[];
  modal: string;
}

class Bills extends React.Component<IBillsProps, IBillsState> {
  constructor(props: IBillsProps) {
    super(props);

    this.state = {
      filters: {},
      searchedText: null,
      noResult: null,
      preResult: [],
      bills: null,
      pages: 1,
      page: 1,
      count: 0,
      downloadUrl: null,
      firstLoad: false,

      yearFilter: [],
      monthFilter: [],
      modal: '',
    };
  }

  componentDidMount() {
    this.fetchBills();
  }

  fetchBills = async (newFilters: any = {}) => {
    const {
      match: {
        params: { id: centreId },
      },
    } = this.props;
    const params = {
      filter: {
        skip: (this.state.page - 1) * PAGINATION_LIMIT,
        limit: PAGINATION_LIMIT,
        orderBy: [{ key: 'createdAt', direction: 'DESC' }],
        ...newFilters,
      },
    };
    const { data } = await getCentreBills(centreId, params);
    const bills = data.data.map((bill: any) => ({
      billId: bill.billId,
      concept: bill.concept,
      month: this.props.t(`layouts:config.months.${new Date(bill.createdAt).getMonth() + 1}`),
      year: new Date(bill.createdAt).getFullYear(),
      amount: `${bill.total} €`,
      pdfUrl: bill.pdfUrl,
    }));
    this.setState((prevState: IBillsState) => ({
      bills,
      pages: Math.ceil(data.count / PAGINATION_LIMIT),
      count: data.count,
      firstLoad: !prevState.bills,
    }));
  };

  onMonthChangeHandler = (value: any) => {
    const newMonthFilter = value.map((item: any) => item.value);
    this.setState({ monthFilter: newMonthFilter }, () => {
      this.onFilter();
    });
  };
  onYearChangeHandler = (value: any) => {
    const newYearFilter = value.map((item: any) => item.value);
    this.setState({ yearFilter: newYearFilter }, () => {
      this.onFilter();
    });
  };

  onFilter = () => {
    const { monthFilter, yearFilter } = this.state;
    const filterArr: any[] = [];
    if (monthFilter.length <= 0) {
      const yearsFiltersMap = yearFilter.map((year: number) => {
        const date = new Date();
        date.setFullYear(year);
        return {
          createdAt: {
            type: 'timestamp',
            method: 'between',
            values: [
              moment(date)
                .startOf('year')
                .format('YYYY-MM-DD HH:mm:ss'),
              moment(date)
                .endOf('year')
                .format('YYYY-MM-DD HH:mm:ss'),
            ],
          },
        };
      });

      filterArr.push(...yearsFiltersMap);
    } else if (yearFilter.length <= 0) {
      const monthFiltersMap = monthFilter.map((month: string) => {
        const date = new Date();
        date.setMonth(+month);
        return {
          createdAt: {
            type: 'timestamp',
            method: 'between',
            values: [
              moment(date)
                .startOf('month')
                .format('YYYY-MM-DD HH:mm:ss'),
              moment(date)
                .endOf('month')
                .format('YYYY-MM-DD HH:mm:ss'),
            ],
          },
        };
      });

      filterArr.push(...monthFiltersMap);
    } else if (yearFilter.length > 0 && monthFilter.length > 0) {
      for (const year of yearFilter) {
        const monthFiltersMap = monthFilter.map((month: string) => {
          const date = new Date();
          date.setFullYear(year);
          date.setMonth(+month);
          return {
            createdAt: {
              type: 'timestamp',
              method: 'between',
              values: [
                moment(date)
                  .startOf('month')
                  .format('YYYY-MM-DD HH:mm:ss'),
                moment(date)
                  .endOf('month')
                  .format('YYYY-MM-DD HH:mm:ss'),
              ],
            },
          };
        });
        filterArr.push(...monthFiltersMap);
      }
    }
    const filter = filterArr.length > 0 ? { where: filterArr } : {};
    this.fetchBills(filter);
  };

  modalHandler = (value: string) => {
    this.setState({ modal: value });
  };

  onDownloadHandler = (downloadUrl: string) => {
    this.setState({ downloadUrl, modal: 'modal-download' });
  };

  downloadBill = () => {
    if (this.state.downloadUrl && this.state.downloadUrl !== '') {
      this.setState({ modal: '' }, () => {
        window.open(this.state.downloadUrl || '', '_blank');
      });
    } else {
      this.setState({ modal: '' }, () => {
        services.pushNotification({
          title: this.props.t('push:error-download-bill.title'),
          text: this.props.t('push:error-download-bill.text'),
          type: 'red-warning',
        });
      });
    }
  };

  render() {
    const { t } = this.props;
    const { pages, yearFilter, monthFilter, modal, page, bills, firstLoad } = this.state;

    const billsHeader = (
      <div className="bills-title">
        <div className="bills-title__filters">
          <div className="bills-title__filter-item">
            <ButtonDropdown
              orientationModal={'right'}
              text={t('layouts:config.month')}
              rightIcon={images.arrowDown2Svg}
              component={
                <MiniModalCheckbox
                  showSelectionCount={true}
                  showDeleteBtn={true}
                  options={getMonthFilterOptions(t)}
                  value={monthFilter}
                  onDelete={() => {
                    this.onMonthChangeHandler([]);
                  }}
                  onChange={(value: any) => this.onMonthChangeHandler(value)}
                  multiple={true}
                />
              }
            />
          </div>
          <div className="bills-title__filter-item">
            <YearsCheckList
              onDelete={() => {
                this.onYearChangeHandler([]);
              }}
              onChange={(value: any) => {
                this.onYearChangeHandler(value);
              }}
              value={yearFilter}
            />
          </div>
        </div>
      </div>
    );

    let content;
    if (bills && bills.length <= 0 && firstLoad) content = <OopsContent body={t('layouts:bills.no-content')} />;

    if (bills && (!firstLoad || bills.length > 0)) {
      content = (
        <MainLayoutSection>
          {billsHeader}
          <div className="bookings-table">
            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableV2
                  columns={getBillColumns(t, this.onDownloadHandler)}
                  data={bills || []}
                  disableSort={true}
                />
              </div>

              {this.state.pages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination
                    page={page}
                    callback={(page: any) => {
                      this.setState({ page }, () => {
                        this.fetchBills();
                      });
                    }}
                    limit={this.state.pages}
                  />
                </div>
              )}
            </MainLayoutTableContainer>
          </div>
        </MainLayoutSection>
      );
    }

    return (
      <BillsStyle>
        <HeaderMain title={t('layouts:bills.header')} />
        {content}
        <ModalInfo
          type="blue"
          title={[t('modalMessages:modal-bills-download.title')]}
          onClose={() => {
            this.modalHandler('');
          }}
          icon={images.downloadGreenSvg}
          showModal={modal === 'modal-download'}
          buttons={[
            {
              text: 'Aceptar',
              type: 'primary',
              onClick: this.downloadBill,
            },
          ]}
        />
      </BillsStyle>
    );
  }
}

export default withTranslation(['intro', 'buttons', 'tables', 'layouts', 'modalMessages', 'push'])(withRouter(Bills));
