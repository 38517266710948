import * as React from 'react';
import { StatsLayoutStyle } from './stats-style';
import { MainLayoutSection } from '../main-layout-style';
import HeaderMain from '../../components/header-main/header-main';
import { Line, Bar, defaults } from 'react-chartjs-2';
import CardStats from '../../components/card-stats/card-stats';
import { colors } from '../../assets/colors';
import LabelText from '../../components/text/label-text/label';
import TextDestacadoText from '../../components/text/text-destacado-text/text-destacado-text';
import H3Text from '../../components/text/h3-text/h3-text';
import SelectBox from '../../components/select-box';
import { images } from '../../assets/images';
import HeadDestacadoText from '../../components/text/head-destacado-text/head-destacado-text';
import TextText from '../../components/text/text-text/text-text';
import InfoText from '../../components/text/info-text/info-text';
import DicotomicCheckbox from '../../components/dicotomic-checkbox';
import { getDivers } from '../../services/diver';
import { getCentres } from '../../services/centre';
import { COUNTRIES_KEY_LABEL } from '../../constants/countries';
import moment, { Moment } from 'moment';
import { copyOf } from '../../utils/other';
import {
  lineDatasetTemplate,
  diversBarDatasetTemplate,
  optionsLine,
  optionsPie,
  data,
  centresBarDatasetTemplate,
} from './stats-templates';
import CalendarBox from '../../components/calendar-box';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { getAllConservationActions } from '../../services/conservation-actions';
import { getCertifierDivers } from '../../services/collaborator';

export interface IStatsLayoutProps extends RouteComponentProps, WithTranslation {}

export interface IStatsLayoutState {
  divers?: any;
  projects?: any;
  centres?: any;
  numMaleDivers: number;
  numFemaleDivers: number;
  numUnknownDivers: number;
  diversCountries: { value: string; label: string }[];
  centresCountries: { value: string; label: string }[];
  signUpChartPeriod: string;
  activityChartPeriod: string;
  billingChartPeriod: string;
  customSingUpDateStart: Moment;
  customSignUpDateEnd: Moment;
  signUpDiversFilter: boolean;
  signUpCountryFilter: string;
  projectCountryFilter: string;
  projectChartPeriod: string;
  customProjectDateStart: Moment;
  customProjectDateEnd: Moment;
  projectProjectFilter: boolean;
  projectsCountries: { value: string; label: string }[];
}

class StatsOrganizationLayout extends React.Component<IStatsLayoutProps, IStatsLayoutState> {
  /*
    Periods:
      -today
      -yesterday
      -month
      -3month
      -custom
  */

  constructor(props: IStatsLayoutProps) {
    super(props);

    this.state = {
      numMaleDivers: 0,
      numFemaleDivers: 0,
      numUnknownDivers: 0,
      diversCountries: [],
      centresCountries: [],
      signUpChartPeriod: 'today',
      activityChartPeriod: 'today',
      billingChartPeriod: 'today',
      customSingUpDateStart: moment(),
      customSignUpDateEnd: moment(),
      signUpDiversFilter: false,
      signUpCountryFilter: 'all',
      projectCountryFilter: 'all',
      projectChartPeriod: 'today',
      customProjectDateStart: moment(),
      customProjectDateEnd: moment(),
      projectProjectFilter: false,
      projectsCountries: [],
    };
  }

  componentDidMount() {
    this.getDiversStats();
    this.getProjectsStats();
  }

  async getDiversStats() {
    const params = {
      filter: {
        relations: ['user'],
        orderBy: [{ key: 'createdAt', direction: 'ASC' }],
      },
    };
    const certifierId: string | null = localStorage.getItem('organizationId');
    const divers: any = (await getCertifierDivers(certifierId || '0', params)).data.data;
    let numMaleDivers = 0;
    let numFemaleDivers = 0;
    let numUnknownDivers = 0;
    const diversCountries: any = [
      {
        value: 'all',
        label: 'Todos los países',
      },
    ];
    divers.forEach((diver: any) => {
      if (diver.user && diver.user.gender && diver.user.gender === 'male') {
        numMaleDivers += 1;
      } else if (diver.user && diver.user.gender && diver.user.gender === 'female') {
        numFemaleDivers += 1;
      } else {
        numUnknownDivers += 1;
      }
      if (
        COUNTRIES_KEY_LABEL[diver.country] &&
        diver.country &&
        diversCountries.filter((c: any) => c.value === diver.country).length === 0
      ) {
        diversCountries.push({
          value: diver.country,
          label: COUNTRIES_KEY_LABEL[diver.country],
        });
      }
    });
    this.setState({ numMaleDivers, numFemaleDivers, numUnknownDivers, diversCountries, divers });
  }

  async getProjectsStats() {
    const params = {
      filter: {
        relations: ['organization'],
        orderBy: [{ key: 'createdAt', direction: 'ASC' }],
      },
    };
    const certifierId: string | null = localStorage.getItem('organizationId');

    if (!certifierId) return;
    const projects: any = (await getAllConservationActions(certifierId, params)).data.data;

    const projectsCountries: any = [
      {
        value: 'all',
        label: 'Todos los países',
      },
    ];

    projects.forEach((project: any) => {
      if (
        project.country &&
        COUNTRIES_KEY_LABEL[project.country] &&
        projectsCountries.filter((p: any) => p.value === project.country).length === 0
      ) {
        projectsCountries.push({
          value: project.country,
          label: COUNTRIES_KEY_LABEL[project.country],
        });
      }
    });

    this.setState({ projectsCountries, projects });
  }

  getLineData(elements: { createdAt: string }[], range: string, dateStart: Moment, dateEnd: Moment) {
    if (!elements) return [];
    const elementsData: { t: any; y: number }[] = [{ t: dateStart.toISOString(), y: 0 }];
    elements.forEach((element: any) => {
      const date = moment(element.createdAt);
      switch (range) {
        case 'month':
          date.startOf('month');
          break;
        case 'day':
          date.startOf('day');
          break;
        case 'hour':
          date.startOf('hour');
      }
      if (
        (!dateStart || date.isAfter(dateStart) || date.isSame(dateStart)) &&
        (!dateEnd || date.isBefore(dateEnd) || date.isSame(dateEnd))
      ) {
        let newItem = true;
        elementsData.forEach(elementData => {
          if (elementData.t === date.toISOString()) {
            elementData.y += 1;
            newItem = false;
          }
        });
        if (newItem) {
          elementsData.push({
            t: date.toISOString(),
            y: 1,
          });
        }
      }
    });
    if (elementsData[elementsData.length - 1].t !== dateEnd.toISOString()) {
      elementsData.push({
        t: dateEnd.toISOString(),
        y: 0,
      });
    }
    return elementsData;
  }

  getPeriod(period: string) {
    const { customSingUpDateStart, customSignUpDateEnd } = this.state;
    switch (period) {
      case 'today':
        return { range: 'hour', dateStart: moment().startOf('day'), dateEnd: moment().endOf('day') };
      case 'yesterday':
        return {
          range: 'hour',
          dateStart: moment()
            .add(-1, 'days')
            .startOf('day'),
          dateEnd: moment()
            .add(-1, 'days')
            .endOf('day'),
        };
      case 'month':
        return { range: 'day', dateStart: moment().startOf('month'), dateEnd: moment().endOf('day') };
      case '3month':
        return {
          range: 'day',
          dateStart: moment()
            .add(-3, 'month')
            .startOf('month'),
          dateEnd: moment().endOf('day'),
        };
      case 'custom':
        const diff = customSignUpDateEnd.diff(customSingUpDateStart, 'days');
        return {
          range: diff < 2 ? 'hour' : diff < 150 ? 'day' : 'month',
          dateStart: customSingUpDateStart.startOf('day'),
          dateEnd: customSignUpDateEnd.endOf('day'),
        };
    }
    return {
      range: 'day',
      dateStart: moment(),
      dateEnd: moment().add(1, 'days'),
    };
  }

  setLineColors(object: any, color: string) {
    object.backgroundColor = color;
    object.borderColor = color;
    object.pointBorderColor = color;
    object.pointHoverBackgroundColor = color;
  }

  getSignInStats() {
    const { divers, signUpChartPeriod, signUpDiversFilter, signUpCountryFilter } = this.state;

    const diversFiltered = divers
      ? divers.filter((d: any) => signUpCountryFilter === 'all' || signUpCountryFilter === d.country)
      : null;

    const { range, dateStart, dateEnd } = this.getPeriod(signUpChartPeriod);
    const diversData: { t: any; y: number }[] = this.getLineData(diversFiltered, range, dateStart, dateEnd);
    const diversDataset = copyOf(lineDatasetTemplate);
    const options = copyOf(optionsLine);
    options.scales.xAxes[0].time.unit = range;

    diversDataset.label = 'Buceadores';
    diversDataset.data = diversData;
    this.setLineColors(diversDataset, colors['CO-004/100']);

    let datasets = [];
    if (signUpDiversFilter) {
      datasets.push(diversDataset);
    }
    if (!signUpDiversFilter) {
      datasets = [diversDataset];
    }

    const signInNumDivers = diversDataset.data.reduce((prev: number, actual: any) => {
      return prev + actual.y;
    }, 0);

    return {
      dateStart,
      dateEnd,
      signInNumDivers,
      options,
      data: {
        datasets,
        type: 'line',
      },
    };
  }

  getProjectStats() {
    const { projects, projectChartPeriod, projectProjectFilter, projectCountryFilter } = this.state;

    const projectsFiltered = projects
      ? projects.filter((p: any) => projectCountryFilter === 'all' || projectCountryFilter === p.country)
      : null;

    const { range, dateStart, dateEnd } = this.getPeriod(projectChartPeriod);
    const projectsData: { t: any; y: number }[] = this.getLineData(projectsFiltered, range, dateStart, dateEnd);
    const projectsDataset = copyOf(lineDatasetTemplate);
    const options = copyOf(optionsLine);
    options.scales.xAxes[0].time.unit = range;

    projectsDataset.label = 'Proyectos';
    projectsDataset.data = projectsData;
    this.setLineColors(projectsDataset, colors['CO-004/100']);

    let datasets = [];

    if (projectProjectFilter) datasets.push(projectsDataset);
    else datasets = [projectsDataset];

    const projectNumProjects = projectsDataset.data.reduce((prev: number, actual: any) => prev + actual.y, 0);

    return {
      dateStart,
      dateEnd,
      projectNumProjects,
      options,
      data: {
        datasets,
        type: 'line',
      },
    };
  }

  render() {
    const { t } = this.props;
    const { diversCountries, signUpChartPeriod, projectChartPeriod, projectsCountries } = this.state;
    const signInStats = this.getSignInStats();
    const projectStats = this.getProjectStats();
    return (
      <>
        <HeaderMain title={t('layouts:stats.header')} />
        <MainLayoutSection>
          <StatsLayoutStyle>
            <div className="stats-lines">
              <div className="stats-lines-cards">
                <div className="stats-lines-cards__title">
                  <HeadDestacadoText>{t('layouts:stats.divers')}</HeadDestacadoText>
                </div>
                <div className="stats-lines-cards__gap">
                  <TextText>{`${signInStats.dateStart.format('DD [de] MMM YYYY')} - ${signInStats.dateEnd.format(
                    'DD [de] MMM YYYY'
                  )}`}</TextText>
                </div>
                <div className="stats-lines-cards__item">
                  <CardStats
                    className={'orange'}
                    number={signInStats.signInNumDivers}
                    subtitle={t('layouts:stats.new')}
                    title={t('layouts:stats.divers')}
                  />
                </div>
              </div>

              <div className="stats-lines-graph">
                <div className="stats-lines-graph-filters">
                  <div className="stats-lines-graph-filters__left">
                    <SelectBox
                      // translate
                      className="rounded-filter"
                      labelText={t('inputs:country.label')}
                      placeholder={t('inputs:country.label')}
                      required={true}
                      initialValue={''}
                      optionsText={[...diversCountries.slice(1, -1)]}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={signUpCountryFilter => {
                        this.setState({ signUpCountryFilter });
                      }}
                    />
                  </div>
                  <div className="stats-lines-graph-filters__right">
                    <div
                      className={`stats-lines-graph-filters__right__item ${
                        signUpChartPeriod === 'today' ? 'active' : ''
                      }`}
                      onClick={() => this.setState({ signUpChartPeriod: 'today' })}
                    >
                      <InfoText>{t('layouts:stats.filters.today')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${
                        signUpChartPeriod === 'yesterday' ? 'active' : ''
                      }`}
                      onClick={() => this.setState({ signUpChartPeriod: 'yesterday' })}
                    >
                      <InfoText>{t('layouts:stats.filters.yesterday')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${
                        signUpChartPeriod === 'month' ? 'active' : ''
                      }`}
                      onClick={() => this.setState({ signUpChartPeriod: 'month' })}
                    >
                      <InfoText>{t('layouts:stats.filters.this-month')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${
                        signUpChartPeriod === '3month' ? 'active' : ''
                      }`}
                      onClick={() => this.setState({ signUpChartPeriod: '3month' })}
                    >
                      <InfoText>{t('layouts:stats.filters.three-months')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${signUpChartPeriod === 'custom' ? '' : ''}`}
                      onClick={() => this.setState({ signUpChartPeriod: 'custom' })}
                    >
                      {signUpChartPeriod !== 'custom' ? (
                        <InfoText>{t('layouts:stats.filters.custom')}</InfoText>
                      ) : (
                        <CalendarBox
                          initialValue={moment().format('DD/MM/YYYY')}
                          labelText=""
                          icon={images.calendarSvg}
                          onChange={(value, err, secondValue) => {
                            this.setState({
                              customSingUpDateStart: moment(value, 'DD/MM/YYYY'),
                              customSignUpDateEnd: moment(secondValue, 'DD/MM/YYYY'),
                              signUpChartPeriod: 'custom',
                            });
                          }}
                          isOutsideRange={date =>
                            moment(date, 'DD/MM/YYYY').isBefore(moment('01/12/2019', 'DD/MM/YYYY')) ||
                            moment(date, 'DD/MM/YYYY').isAfter(moment(new Date()))
                          }
                          rangeSelect={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="stats-lines-graph-draw">
                  {/* <div className="stats-lines-graph-draw-filters">
                    <div className="stats-lines-graph-draw-filters__item">
                      <DicotomicCheckbox onCheck={checked => this.setState({ signUpDiversFilter: checked })}>
                        <div className="stats-lines-graph-draw-filters__item__text red">
                          <InfoText>{t('layouts:stats.divers')}</InfoText>
                        </div>
                      </DicotomicCheckbox>
                    </div>
                  </div> */}

                  <StatsLayoutStyle>
                    <Line options={signInStats.options} data={signInStats.data} />
                  </StatsLayoutStyle>
                </div>
              </div>
            </div>
            <div className="stats-lines">
              <div className="stats-lines-cards">
                <div className="stats-lines-cards__title">
                  <HeadDestacadoText>{t('layouts:stats.projects')}</HeadDestacadoText>
                </div>
                <div className="stats-lines-cards__gap">
                  <TextText>{`${projectStats.dateStart.format('DD [de] MMM YYYY')} - ${projectStats.dateEnd.format(
                    'DD [de] MMM YYYY'
                  )}`}</TextText>
                </div>
                <div className="stats-lines-cards__item">
                  <CardStats
                    className="blue"
                    number={projectStats.projectNumProjects}
                    subtitle={t('layouts:stats.new')}
                    title={t('layouts:stats.projects')}
                  />
                </div>
              </div>

              <div className="stats-lines-graph">
                <div className="stats-lines-graph-filters">
                  <div className="stats-lines-graph-filters__left">
                    <SelectBox
                      // translate
                      className="rounded-filter"
                      labelText={t('inputs:country.label')}
                      placeholder={t('inputs:country.label')}
                      required={true}
                      initialValue={''}
                      optionsText={[...projectsCountries.slice(1)]}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={projectCountryFilter => {
                        this.setState({ projectCountryFilter });
                      }}
                    />
                  </div>
                  <div className="stats-lines-graph-filters__right">
                    <div
                      className={`stats-lines-graph-filters__right__item ${
                        projectChartPeriod === 'today' ? 'active' : ''
                      }`}
                      onClick={() => this.setState({ projectChartPeriod: 'today' })}
                    >
                      <InfoText>{t('layouts:stats.filters.today')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${
                        projectChartPeriod === 'yesterday' ? 'active' : ''
                      }`}
                      onClick={() => this.setState({ projectChartPeriod: 'yesterday' })}
                    >
                      <InfoText>{t('layouts:stats.filters.yesterday')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${
                        projectChartPeriod === 'month' ? 'active' : ''
                      }`}
                      onClick={() => this.setState({ projectChartPeriod: 'month' })}
                    >
                      <InfoText>{t('layouts:stats.filters.this-month')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${
                        projectChartPeriod === '3month' ? 'active' : ''
                      }`}
                      onClick={() => this.setState({ projectChartPeriod: '3month' })}
                    >
                      <InfoText>{t('layouts:stats.filters.three-months')}</InfoText>
                    </div>
                    <div
                      className={`stats-lines-graph-filters__right__item ${signUpChartPeriod === 'custom' ? '' : ''}`}
                      onClick={() => this.setState({ projectChartPeriod: 'custom' })}
                    >
                      {projectChartPeriod !== 'custom' ? (
                        <InfoText>{t('layouts:stats.filters.custom')}</InfoText>
                      ) : (
                        <CalendarBox
                          initialValue={moment().format('DD/MM/YYYY')}
                          labelText=""
                          icon={images.calendarSvg}
                          onChange={(value, err, secondValue) => {
                            this.setState({
                              customProjectDateStart: moment(value, 'DD/MM/YYYY'),
                              customProjectDateEnd: moment(secondValue, 'DD/MM/YYYY'),
                              projectChartPeriod: 'custom',
                            });
                          }}
                          isOutsideRange={date =>
                            moment(date, 'DD/MM/YYYY').isBefore(moment('01/12/2019', 'DD/MM/YYYY')) ||
                            moment(date, 'DD/MM/YYYY').isAfter(moment(new Date()))
                          }
                          rangeSelect={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="stats-lines-graph-draw">
                  {/* <div className="stats-lines-graph-draw-filters">
                    <div className="stats-lines-graph-draw-filters__item">
                      <DicotomicCheckbox onCheck={checked => this.setState({ projectProjectFilter: checked })}>
                        <div className="stats-lines-graph-draw-filters__item__text red">
                          <InfoText>{t('layouts:stats.projects')}</InfoText>
                        </div>
                      </DicotomicCheckbox>
                    </div>
                  </div> */}

                  <StatsLayoutStyle>
                    <Line options={projectStats.options} data={projectStats.data} />
                  </StatsLayoutStyle>
                </div>
              </div>
            </div>
          </StatsLayoutStyle>
        </MainLayoutSection>
      </>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(withRouter(StatsOrganizationLayout));
