import styled from 'styled-components';
import { MainLayoutWithHeaderSearch } from '../../main-layout-style';

export const BookingsStyle = styled(MainLayoutWithHeaderSearch)`
  display: flex;
  flex-direction: column;
  height: 100%;

  .title-reject {
    font-weight: 500;
    padding-bottom: 10px;
  }

  .actual {
    color: #00a1a5;
  }
`;
