export const ROLES: any[] = [
  { value: 'superAdmin', label: 'Super admin' },
  { value: 'organization_admin', label: ' Gestor de colaboradores' },
  { value: 'organization_owner', label: 'Colaborador' },
];

export const ROLES_LABEL: any = {
  superAdmin: 'Super admin',
  organization_admin: ' Gestor de colaboradores',
  organization_owner: 'Colaborador',
  organization_staff: 'Staff colaborador',
};

export const CERTIFIER_ROLES: any[] = [
  { value: 'manager', label: 'Gestor principal' },
  { value: 'local', label: 'Gestor local' },
];

export const CERTIFIER_ROLES_KEY_VALUES: any = {
  manager: 'Gestor principal',
  local: 'Gestor local',
};
