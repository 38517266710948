import React, { Component } from 'react';
import services from '../../../../services/services';
import DiverDocumentsLayout from './diver-documents-layout';
import { DiverDocument } from './types';
import { DIVERS_DIVES_PER_PAGE } from '../../../../constants/data';
import { RouteComponentProps, match } from 'react-router';
import { HeaderButtons } from '../diver-detail';
import { IUploadDocumentForm } from '../../../../models/document-forms';
import { uploadDocument, validateDocument, deleteDocument } from '../../../../services/diver';
import { validateDiverDocument } from '../../../../services/collaborator';

export interface DiverDocumentsFunctionalProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
    staff: string;
  }>;
  onMultipleSelect: (mutiple: any) => void;
  isCentre: boolean;
  isOrganization: boolean;
}

interface DiverDocumentsFunctionalState {
  items: DiverDocument[];
  itemsSelected: any[];
  totalItems: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  renderModal: string;
  viewDocument: object;
  page: number;
  statusRow: any;
}

class DiverDocumentsFunctional extends Component<DiverDocumentsFunctionalProps, DiverDocumentsFunctionalState>
  implements HeaderButtons {
  state: DiverDocumentsFunctionalState = {
    items: [],
    itemsSelected: [],
    totalItems: 0,
    totalPages: 0,
    searchFilter: '',
    skipPage: 0,
    orderAscendent: '',
    renderModal: '',
    viewDocument: {},
    page: 1,
    statusRow: false,
  };

  componentDidMount() {
    this.getDiverDocuments();
  }

  getDiverDocuments() {
    const {
      match: {
        params: { id, diver, staff },
      },
    } = this.props;
    const { searchFilter, skipPage, orderAscendent } = this.state;

    const endpoint = staff
      ? `centres/${id}/staff/${staff}/documents`
      : diver
        ? `centres/${id}/divers/${diver}/documents`
        : `divers/${id}/documents`;

    const params = {
      filter: {
        where: {
          name: {
            method: 'ilike',
            value: `%${searchFilter}%`,
          },
        },
        skip: skipPage,
        limit: DIVERS_DIVES_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: ['certifier', 'course'],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        if (res.count > 0) {
          const items: any[] = res.data.map((item: any) => {
            const { id, docId, name, kind, validated, expirationDate, certifier, file } = item;
            const document: DiverDocument = {
              kind,
              name,
              expirationDate,
              validated,
              file,
              certifier: certifier ? certifier.name : '-',
              id: id,
              docId: docId ? docId : '',
            };
            return document;
          });

          const totalItems: number = res.count;
          const totalPages: number = Math.ceil(totalItems / DIVERS_DIVES_PER_PAGE);
          this.setState({ items, totalItems, totalPages });
        } else {
          this.setState({ items: [], totalItems: 0, totalPages: 0 });
        }
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  setPage(page: number) {
    const { totalItems } = this.state;
    const skip: number = DIVERS_DIVES_PER_PAGE * (page - 1);
    if (skip < totalItems) {
      this.setState({ page, skipPage: skip }, () => {
        this.getDiverDocuments();
      });
    }
  }

  handleSelectRows(rows: any[], allRowsAffected: boolean, allValue?: boolean) {
    const { items } = this.state;
    const { onMultipleSelect } = this.props;
    // const itemsSelected: DiverDocument[] = items.filter(item => {
    //   const rowsFiltered = rows.filter(row => row.id.value === item.id.toString());
    //   return rowsFiltered.length > 0 && rowsFiltered[0].check.value;
    // });
    const itemsSelected = rows
      .filter((row: any) => row.check.value)
      .map(row => ({ id: +row.id.value, status: row.status.key === 'success' }));
    const statusRow =
      itemsSelected.length > 0
        ? itemsSelected.every((row: any) => row.status)
          ? 'success'
          : itemsSelected.every((row: any) => !row.status)
            ? 'reject'
            : 'none'
        : false;
    onMultipleSelect(statusRow);
    this.setState({ itemsSelected, statusRow });
  }

  async validateDocuments(selectedDocument: number, rowClicked: boolean) {
    const {
      match: {
        params: { id },
      },
      onMultipleSelect,
      isOrganization,
    } = this.props;
    const { itemsSelected } = this.state;
    const orgId = localStorage.getItem('organizationId');
    if (rowClicked) {
      if (isOrganization && orgId) {
        await validateDiverDocument(orgId, id, selectedDocument.toString());
      } else {
        await validateDocument(selectedDocument.toString());
      }
    } else {
      const promises: Promise<any>[] = [];
      itemsSelected.forEach(item => {
        if (isOrganization && orgId) {
          promises.push(validateDiverDocument(orgId, id, item.id));
        } else {
          promises.push(validateDocument(item.id));
        }
      });
      await Promise.all(promises);
    }
    services.pushNotification({
      text: 'Se ha completado el proceso de validación correctamente',
      title: 'Genial',
      type: 'green',
    });
    this.setState({ renderModal: '', itemsSelected: [] });
    onMultipleSelect(false);
    this.getDiverDocuments();
  }

  handleMainButtonClick() {
    const { statusRow } = this.state;
    if (statusRow === 'reject') this.setState({ renderModal: 'modal-validate-document' });
    else this.setState({ renderModal: 'modal-upload-document' });
  }

  handleSecondaryButtonClick() {
    this.setState({ renderModal: 'modal-delete-document' });
  }

  async onViewDocument(documentId: number) {
    const {
      match: {
        params: { id, diver, staff },
      },
    } = this.props;
    /*const endpoint = diver
      ? `centres/${id}/divers/${diver}/documents/${documentId}`
      : `divers/${id}/documents/${documentId}`;*/
      const endpoint = staff
      ? `centres/${id}/divers/${staff}/documents/${documentId}`
      : diver
        ? `centres/${id}/divers/${diver}/documents/${documentId}`
        : `divers/${id}/documents/${documentId}`;


    const params = {
      filter: {
        relations: ['certifier', 'course'],
      },
    };
    await services.get({
      params,
      endpoint,
      loader: true,
      then: (response: any) => {
        this.setState({ viewDocument: response.data });
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  async onUploadDocument(form: FormData) {
    const {
      match: {
        params: { id, diver },
      },
    } = this.props;
    try {
      const urlId = diver ? diver : id;
      await uploadDocument(urlId, form);
      services.pushNotification({
        title: 'Genial',
        text: 'El documento se ha creado correctamente',
        type: 'green',
      });

      this.setState({ renderModal: '' });
      this.getDiverDocuments();
    } catch (err) {
      console.log(err);
    }
  }

  async onDeleteDocument(id: number, rowClicked: boolean) {
    const { onMultipleSelect } = this.props;
    const { itemsSelected } = this.state;
    let newItemsSelected;
    let newStatusRow;
    try {
      if (rowClicked) {
        await deleteDocument(id.toString());
        newItemsSelected = itemsSelected.filter((row: any) => +row.id !== id);
        newStatusRow =
          newItemsSelected.length > 0
            ? newItemsSelected.every((row: any) => row.status)
              ? 'success'
              : newItemsSelected.every((row: any) => !row.status)
                ? 'reject'
                : 'none'
            : false;
        services.pushNotification({
          text: 'Se ha eliminado el documento correctamente',
          title: 'Eliminado',
          type: 'red',
        });
      } else {
        const promises: Promise<any>[] = [];
        itemsSelected.forEach(item => {
          promises.push(deleteDocument(item.id));
        });
        await Promise.all(promises);
        newItemsSelected = [];
        newStatusRow = false;
        services.pushNotification({
          text: 'Se han eliminado los documentos correctamente',
          title: 'Eliminado',
          type: 'red',
        });
      }
      this.setState({ renderModal: '', itemsSelected: newItemsSelected, statusRow: newStatusRow });
      this.getDiverDocuments();
      onMultipleSelect(false);
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { isCentre, isOrganization } = this.props;
    const { items, totalPages, itemsSelected, renderModal, viewDocument, page } = this.state;

    return (
      <DiverDocumentsLayout
        {...this.props}
        renderModal={renderModal}
        closeModal={() => this.setState({ renderModal: '' })}
        items={items}
        totalPages={totalPages}
        page={page}
        setPage={page => this.setPage(page)}
        onValidateDocument={(selectedDocument: number, rowClicked: boolean) =>
          this.validateDocuments(selectedDocument, rowClicked)
        }
        rowsSelected={itemsSelected}
        onSelectRows={(rows, all, allValue) => this.handleSelectRows(rows, all, allValue)}
        onViewDocument={id => this.onViewDocument(id)}
        onUploadDocument={(form: FormData) => this.onUploadDocument(form)}
        onRenderModal={(modal: string) => this.setState({ renderModal: modal })}
        onDeleteDocument={(id: number, rowClicked: boolean) => this.onDeleteDocument(id, rowClicked)}
        viewDocument={viewDocument}
        isCentre={isCentre}
        isOrganization={isOrganization}
      />
    );
  }
}

export default DiverDocumentsFunctional;
