import styled from 'styled-components';

export const DiverDivesContainer = styled.div`
  .top-card-container {
    display: flex;
    margin-bottom: 40px;
    &__item {
      width: 269px;
      margin-right: 22px;
    }
  }
  .my-divers-dive-table {
    margin-bottom: 80px;
  }
`;
