export const DURATION_UNITS_KEY_VALUE: any = {
  hour: 'horas',
  day: 'dias',
  week: 'semanas',
  activity: 'Actividad completa',
};

export const SINGLE_DURATION_UNITS_KEY_VALUE: any = {
  hour: 'hora',
  day: 'día',
  week: 'semana',
  activity: 'Actividad completa',
  noduration: 'Sin duración'
};

export const PLURAL_DURATION_UNITS_KEY_VALUE: any = {
  hour: 'horas',
  day: 'días',
  week: 'semanas',
  activity: 'Actividad completa',
  noduration: 'Sin duración'
};

export const DURATION_UNITS_DROPDOWN: any = [
  { label: 'Horas', value: 'hour' },
  { label: 'Dias', value: 'day' },
  { label: 'Semanas', value: 'week' },
  { label: 'Actividad completa', value: 'activity' },
];

export const SINGLE_DURATION_OPTIONS = [
  { value: 'hour', label: 'horas' },
  { value: 'day', label: 'días' },
  { value: 'activity', label: 'Actividad completa' },
  { value: 'noduration', label: 'Sin duración' },
];

export const TICKET_DURATION_UNITS_KEY_VALUE: any = {
  hour: 'horas',
  day: 'dias',
  week: 'semanas',
  activity: 'Actividad completa',
  undefined: 'indefinido',
};

export const TICKET_DURATION_UNITS_DROPDOWN: any = [
  { label: 'Horas', value: 'hour' },
  { label: 'Dias', value: 'day' },
  { label: 'Semanas', value: 'week' },
  { label: 'Indefinido', value: 'undefined' },
];

export const TICKET_DURATION_UNITS_DROPDOWN_2: any = [
  { label: '1 dia', value: 'day' },
  { label: '1 mes', value: 'month' },
  { label: '1 año', value: 'year' },
];

export const TICKET_DURATION_UNITS_KEY_VALUE_2: any = {
  day: '1 dia',
  month: '1 mes',
  year: '1 año',
};
