import React, { Component } from 'react';
import services from '../../../../services/services';
import { getOneUsers } from '../../../../services/users';
import { getDiver } from '../../../../services/diver';
import { ERROR_MESSAGES_ES } from '../../../../constants/error-messages';
import { isValidEmail } from '../../../../utils/validation';
import ModalGhostDiverForm from './modal-ghost-diver-form';
import { GhostDiverForm } from '../../../../types/user';

export interface GhostDiverFormFunctionalProps {
  title: string;
  showModal: boolean;
  onAccept: (newGhostDiver: GhostDiverForm) => void;
  onCancel: () => void;
  className?: string;
  centreID?: string;
}

interface GhostDiverFormFunctionalState {
  error: string;
  errorFields: { [key: string]: string };
  person: GhostDiverForm;
  [key: string]: any;
}

class GhostDiverFormFunctional extends Component<GhostDiverFormFunctionalProps, GhostDiverFormFunctionalState> {
  state: GhostDiverFormFunctionalState = {
    error: '',
    errorFields: {},
    userID: '',
    person: {
      name: '',
      surname: '',
      email: '',
    },
  };

  cleanPreviousError(keys: string[], callback: Function) {
    const { errorFields } = this.state;
    const keysWithErrors = keys.filter(key => errorFields[key] || key === 'centres');

    if (keysWithErrors && keysWithErrors.length) {
      const aux = { ...errorFields };
      for (const field of Object.keys(aux)) {
        if (keysWithErrors.includes(field) || (keysWithErrors.includes('centres') && field.startsWith('centres.'))) {
          delete aux[field];
        }
      }
      this.setState({ errorFields: aux }, () => callback());
    } else {
      callback();
    }
  }

  clearState() {
    this.setState({
      error: '',
      errorFields: {},
      userID: '',
      person: {
        name: '',
        surname: '',
        email: '',
      },
    });
  }

  handleDataChange(field: string, value: string) {
    this.cleanPreviousError([field], () => {
      // Value
      const person = { ...this.state.person };
      person[field] = value;
      this.setState({ person });
    });
  }

  validateForm() {
    const { person, errorFields } = this.state;

    let formError = false;
    const tempFieldErrors = { ...errorFields };

    Object.keys(person).map(key => {
      const value = person[key];
      if (value === '' || value === null) {
        tempFieldErrors[key] = 'required';
        formError = true;
      } else if (key === 'email' && !isValidEmail(value)) {
        tempFieldErrors[key] = 'invalid-email';
        formError = true;
      } else {
        delete tempFieldErrors[key];
      }
    });

    if (formError) {
      const errorMessage = ERROR_MESSAGES_ES['form-error'];
      return this.setState({ error: errorMessage, errorFields: tempFieldErrors });
    }
    return this.setState({ error: '', errorFields: {} });
  }

  async handleSubmitForm() {
    await this.validateForm();
    const { error } = this.state;
    if (error !== '') {
      return;
    }

    this.createGhostDiver();
  }

  createGhostDiver() {
    const { centreID, onAccept } = this.props;
    const { person } = this.state;

    const endpoint = centreID ? `centres/${centreID}/divers` : `users/ghost/`;
    const data = { ...person };


    services.post({
      endpoint,
      data,
      loader: true,
      then: async ({ data: res }: any) => {
        this.clearState();
        const data: any = await this.getUser(res.id);
        onAccept(data);
      },
      catch: () => { },
    });
  }

  getUser = async (id: string) => {
    const params = {
      filter: {
        relations: ['user'],
      },
    };
    const diver = (await getDiver(id, params)).data;
    const params2 = {
      filter: {
        relations: ['diver'],
      },
    };
    const res = await getOneUsers(diver.user.id, params2);
    return res.data;
  };

  render() {
    const { error, errorFields } = this.state;
    return (
      <ModalGhostDiverForm
        {...this.props}
        error={error}
        errorFields={errorFields}
        handleDataChange={(field, value) => this.handleDataChange(field, value)}
        submitForm={() => this.handleSubmitForm()}
        clearState={() => this.clearState()}
      />
    );
  }
}

export default GhostDiverFormFunctional;
