import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ModalTaxesStyle = styled.div`
  padding: 58px 32px 32px 32px;
  width: 520px;

  .modal-taxes-title {
    margin-bottom: 12px;
  }
  .modal-taxes-text  {
    margin-bottom: 28px;
    color: ${colors['NE-002/100']};
  }
  .modal-taxes-line {
    width: 100%;
    height: 1px;
    background: ${colors['NE-002/100']};
    margin-bottom: 40px;
  }

  .modal-taxes-button  {
    width: 144px;
  }

  .modal-taxes-form-row {
    display: flex;
    width: 100%;

    &__item {
      width: 100%;
      margin-right: 12px;
    }
    &__item:last-child {
      margin-right: 0;
    }
    &__button {
      margin-left: 12px;
      min-width: 116px;
    }
  }

  .modal-taxes-table {
    table  {
      text-align: left;
      thead {
        color: ${colors['SE-002/100']};

        th  {
          padding-bottom: 14px;
        }
      }
      tbody {
        .modal-taxes-table-percent {
          display: flex;
          p {
            padding-right: 6px;
            width: 38px;
          }
        }
        tr  {
          td {
            width: 100%;
            padding-top: 0;
            padding-bottom: 20px;
            padding-right: 12px;
            vertical-align: middle;
            &:first-child {
              width: 120px;
              min-width: 120px;
            }
            &:last-child {
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
`;
