import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CancellationLineStyle = styled.div`
  padding: 80px 60px 120px 0;
  display: flex;
  .cancellation-line-track {
    height: 14px;
    background: ${colors['CO-001/50']};
    position: relative;
    flex: auto;
    border-radius: 50px;

    &.green {
      background: ${colors['CO-001/50']};
      .cancellation-line-track-cicle {
        &__dot {
          background: ${colors['CO-001/50']};
        }
      }
    }
    &.yellow {
      background: ${colors['CO-002/75']};
      .cancellation-line-track-cicle {
        &__dot {
          background: ${colors['CO-002/75']};
        }
      }
    }
    &.pink {
      background: ${colors['CO-004/50']};
      .cancellation-line-track-cicle {
        &__dot {
          background: ${colors['CO-004/50']};
        }
      }
    }
    &.red {
      background: ${colors['CO-004/75']};
      .cancellation-line-track-cicle {
        &__dot {
          background: ${colors['SE-002/100']};
        }
      }
    }

    .cancellation-line-track-cicle {
      z-index: 2;
      color: ${colors['SE-002/100']};
      right: -48px;
      top: -42px;
      position: absolute;
      width: 100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform-origin: 50% 50%;

      &__percent {
        margin-bottom: 9px;
        min-height: 20px;
      }
      &__dot {
        width: 22px;
        height: 22px;
        border-radius: 100%;
        border: 4px solid white;
        background: ${colors['CO-001/50']};
        margin-bottom: 12px;
      }

      &__hours {
        text-transform: uppercase;
      }
    }
  }
`;
