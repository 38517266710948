import React, { Component } from 'react';
import { PhoneBoxContainer } from './phone-box-style';
import { images } from '../../assets/images';
import TooltipBox from '../tooltip-box/tooltip-box';
import { ERROR_MESSAGES_ES } from '../../constants/error-messages';
import Axios from 'axios';
import { hasNumber, isNumeric } from '../../utils/validation';
import TextText from '../text/text-text/text-text';
import InfoText from '../text/info-text/info-text';
import LabelText from '../text/label-text/label';
import { WithTranslation, withTranslation } from 'react-i18next';

interface PhoneBoxProps extends WithTranslation{
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  placeholder?: string;
  required?: boolean;
  errorPrefix?: string;
  errorNumber?: string;

  defaultPrefix?: string;
  defaultNumber?: string;
  filledPrefix?: string;
  filledNumber?: string;
  onChange: (field: string, value: string, error?: boolean) => void;
  labelText: string;

  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
  t: any;
}

interface PhoneBoxState {
  showTooltip: boolean;
  showPrefixList: boolean;
  prefix: string;
  number: string;
  search: string;
  foundPrefixes: { name: string; code: string }[];
  filterPrefixes: { name: string; code: string }[];
  errorPrefix: string;
  errorNumber: string;
  [key: string]: any;
}

class PhoneBox extends Component<PhoneBoxProps, PhoneBoxState> {
  toggleContainer: any;
  PREFIX_URL: string;

  constructor(props: PhoneBoxProps) {
    super(props);
    this.state = {
      showTooltip: false,
      showPrefixList: false,
      prefix: '',
      number: '',
      search: '',
      foundPrefixes: [],
      filterPrefixes: [],
      errorPrefix: '',
      errorNumber: '',
    };
    this.PREFIX_URL = 'https://restcountries.com/v2/all';

    this.toggleContainer = React.createRef();

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  componentDidMount() {
    const { defaultPrefix, defaultNumber } = this.props;
    const newState = {
      ...this.state,
      ...(defaultPrefix &&
        defaultPrefix !== '' && {
        prefix: defaultPrefix,
      }),
      ...(defaultNumber &&
        defaultNumber !== '' && {
        number: defaultNumber,
      }),
    };
    this.setState(newState);
    this.searchPrefixByNumber('');
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentDidUpdate(prevProps: PhoneBoxProps) {
    const { filledPrefix, filledNumber } = this.props;
    const { prefix, number } = this.state;

    const newState: any = {
      ...(filledPrefix !== undefined &&
        filledPrefix !== prefix && {
        prefix: filledPrefix,
      }),
      ...(filledNumber !== undefined &&
        filledNumber !== number && {
        number: filledNumber,
      }),
    };

    if (
      Object.keys(newState).length > 0 &&
      (prevProps.filledPrefix !== filledPrefix || prevProps.filledNumber !== filledNumber)
    ) {
      this.setState(newState);
    }
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    if (this.state.showPrefixList && !this.toggleContainer.current.contains(event.target)) {
      this.setState({ showPrefixList: false });
    }
  }

  searchPrefix(search: string) {
    const { foundPrefixes } = this.state;
    console.log("search", search)
    const val = search
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const optionsNew = foundPrefixes.filter(
      (item: any) =>
        item.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(val) > -1 ||
        item.code
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(val) > -1
    );

    console.log("search", search)
    this.setState({ filterPrefixes: optionsNew, search });
  }

  searchPrefixByNumber(search: string) {
    const searchPrefix = search.charAt(0) === '+' ? search.substr(1) : search;
    const url = `${this.PREFIX_URL}`;

    Axios.get(url)
      .then(response => {
        const { data } = response;
        const foundPrefixes = data.map((item: any) => ({
          name: item.name,
          code: item.callingCodes[0],
        }));
        if (foundPrefixes.length === 1) {
          this.setState({ foundPrefixes, filterPrefixes: foundPrefixes, prefix: foundPrefixes[0].code });
        } else {
          this.setState({ foundPrefixes, filterPrefixes: foundPrefixes });
        }
      })
      .catch(() => {
        this.setState({ foundPrefixes: [] });
      });
  }

  searchPrefixByCountryName(search: string) {
    const url = `${this.PREFIX_URL}name/${search}`;

    Axios.get(url)
      .then(response => {
        const { data } = response;
        const foundPrefixes = data.map((item: any) => ({
          name: item.name,
          code: item.callingCodes[0],
        }));

        this.setState({ foundPrefixes });
      })
      .catch(() => {
        this.setState({ foundPrefixes: [] });
      });
  }

  handleBlur(field: string, value: string) {
    const { onChange } = this.props;
    let shouldShowPrefixList = false;
    let auxValue = value;
    if (field === 'prefix') {
      auxValue = value.charAt(0) === '+' ? value : `+${value}`;
      if (!Number(value.substr(1))) {
        auxValue = '';
        shouldShowPrefixList = true;
      }
    }
    this.setState({ [field]: auxValue, showPrefixList: shouldShowPrefixList }, () => {
      if (!shouldShowPrefixList) {
        onChange(`phone${field}`, auxValue);
      }
    });
  }

  handleSelectPrefix(prefix: string) {
    const { onChange } = this.props;
    this.setState(
      {
        prefix,
        search: '',
        showPrefixList: false,
      },
      () => {
        const newPrefix = prefix.charAt(0) === '+' ? prefix : `+${prefix}`;
        onChange('phonePrefix', newPrefix);
        this.searchPrefix('');
      }
    );
  }

  handleNumberChange(number: string) {
    this.setState({ number });
  }

  render() {
    const {
      disabled,
      className,
      placeholder,
      labelText,
      errorPrefix,
      errorNumber,
      withTooltip,
      tooltipTitle,
      tooltipText,
      required,
      t
    } = this.props;
    const { showTooltip, showPrefixList, prefix, number, search, filterPrefixes } = this.state;

    const errorTextPrefix = errorPrefix ? ERROR_MESSAGES_ES[errorPrefix] : '';
    const errorTextNumber = errorNumber ? ERROR_MESSAGES_ES[errorNumber] : '';

    return (
      <PhoneBoxContainer className={`${className} ${required && 'required'} ${disabled ? 'disabled' : ''}`}>
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText>{labelText}</LabelText>
          </div>

          {withTooltip && (
            <div
              className="input-box-topbar-icon"
              onClick={() => {
                this.setState((prevState: PhoneBoxState) => ({ showTooltip: !prevState.showTooltip }));
              }}
            >
              <img src={disabled ? images.infoSvg : images.infoSvg} />
              <div className="input-box-tooltip">
                <TooltipBox active={showTooltip} tooltipTitle={tooltipTitle} tooltipText={tooltipText} />
              </div>
            </div>
          )}
        </div>

        <div className="input-box-main">
          <div
            ref={this.toggleContainer}
            className={`input-box-main-field-select
            ${errorPrefix ? ' error' : ''}  ${prefix !== '' ? 'complete' : ''}`}
            onClick={() => this.setState((prevState: PhoneBoxState) => ({ showPrefixList: !prevState.showPrefixList }))}
          >
            <div className="input-box-main-field-select-inputs">
              <input
                type="text"
                autoComplete="nope"
                placeholder="XX"
                value={showPrefixList ? search : prefix.charAt(0) === '+' ? prefix : prefix ? `+${prefix}` : ''}
                onChange={e => {
                  this.searchPrefix(e.target.value);
                }}
                disabled={false}
                onBlur={e => setTimeout(() => this.setState({ showPrefixList: false }), 100)}
              />
              <div className="input-box-icon">
                <img src={disabled ? images.arrowDown2BlueSvg : images.arrowDown2BlueSvg} />
              </div>
            </div>
            <div className="input-box-error">
              <InfoText>{errorPrefix ? errorTextPrefix : ' '}</InfoText>
            </div>
          </div>

          {showPrefixList && (
            <ul className="input-box-main-list">
              {filterPrefixes.length <= 0 ? (
                <li className="no-data">
                  <p>{t('components:general.noresults')}</p>
                </li>
              ) : (
                  filterPrefixes.map((prefix, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        this.handleSelectPrefix(prefix.code);
                      }}
                    >
                      <TextText>
                        {`${prefix.name}`}
                        <span>{`+${prefix.code}`}</span>
                      </TextText>
                    </li>
                  ))
                )}
            </ul>
          )}

          {/* tslint:disable-next-line: max-line-length */}
          <div className={`input-box-main-field-input ${errorNumber && 'error'}  ${number !== '' && 'complete'}`}>
            <input
              type="text"
              autoComplete="nope"
              placeholder={placeholder}
              value={number}
              onChange={e => this.handleNumberChange(e.target.value)}
              onBlur={e => {
                this.handleBlur('Number', e.target.value);
              }}
            />
            <div className="input-box-error">
              <InfoText>{errorNumber ? errorTextNumber : ' '}</InfoText>
            </div>
          </div>
        </div>
      </PhoneBoxContainer>
    );
  }
}

export default withTranslation(['components']) (PhoneBox);
