import React, { Component } from 'react';
import HeaderMain from '../../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../../main-layout-style';
import HeadDestacadoText from '../../../../components/text/head-destacado-text/head-destacado-text';
import TextDestacadoText from '../../../../components/text/text-destacado-text/text-destacado-text';
import TextText from '../../../../components/text/text-text/text-text';
import { CertifierCatalogDetailStyle } from './certifier-catalog-detail-style';
import SubtitleText from '../../../../components/text/subtitle-text/subtitle-text';
import TitleText from '../../../../components/text/title-text/title-text';
import { connect } from 'react-redux';
import Pagination from '../../../../components/pagination';
import MainTableLayout from '../../../../components/main-table/main-table';
import { RouteComponentProps, match } from 'react-router';
import { getCourse, getCourseDivers } from '../../../../services/courses';
import { DiveDisciplinesKeyValues, MIN_AGE_KEY } from '../../../../constants/dive-dropdowns';
import { COURSE_KINDS_KEY_VALUES } from '../../../../constants/course';
import { ILevels } from '../../../../models/levels';
import { getLevels } from '../../../../services/levels';
import { IDropdown } from '../../../../models/generic';
import SelectBox from '../../../../components/select-box';
import { LANGUAGES } from '../../../../constants/languages';
import ModalCreateCatalog from '../modal-create-catalog/modal-create-catalog';
import services from '../../../../services/services';
import { copyOf, mergeObject } from '../../../../utils/other';
import moment from 'moment';
import { CERTIFIER_CATALOG_DETAIL_DIVERS_PER_PAGE } from '../../../../constants/data';
import IStoreState from '../../../../redux/store/IStoreState';
import { images } from '../../../../assets/images';
import ModalDelete from '../../../../components/modals/modal-delete/modal-delete';
import ModalConfirmation from '../../../../components/modals/modal-confimation/modal-confirmation';
import { patchCertifierCourse } from '../../../../services/collaborator';
import { deleteCollaboratorCourse } from '../../../../services/collaborator';
import parser from 'html-react-parser';
import { WithTranslation, withTranslation } from 'react-i18next';

interface CertifierProfileProps extends RouteComponentProps, WithTranslation {
  match: match<{
    id: string;
    organizationId: string;
  }>;
  agent: any;
  t: any;
}

interface CertifierProfileState {
  course?: any;
  courseLevel: string;
  renderModal: string;
  selectedLanguage: string;
  levels: IDropdown[];
  divers: any[];
  certifierId: string;
  total: number;
  totalPages: number;
  skipPage: number;
  page: number;
}

class CertifierCatalogDetail extends Component<CertifierProfileProps, CertifierProfileState> {
  state: CertifierProfileState = {
    renderModal: '',
    courseLevel: '',
    selectedLanguage: this.props.agent ? this.props.agent.lang : 'es',
    levels: [],
    divers: [],
    certifierId: '0',
    total: 0,
    totalPages: 0,
    skipPage: 0,
    page: 1,
  };

  componentDidMount() {
    const certifierId: string | null = localStorage.getItem('organizationId');
    if (certifierId) {
      this.setState({ certifierId }, () => {
        this.getLevels();
      });
    } else {
      this.getLevels();
    }
  }

  componentDidUpdate(prevProps: CertifierProfileProps) {
    if (!prevProps.agent.id && !!this.props.agent.id) {
      this.setState({ selectedLanguage: this.props.agent.lang });
    }
  }

  async getCourse() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { levels, skipPage } = this.state;
    const params = {
      filter: {
        relations: ['certifier'],
      },
    };
    const params2 = {
      filter: {
        skip: skipPage,
        limit: CERTIFIER_CATALOG_DETAIL_DIVERS_PER_PAGE,
        relations: ['user', 'documents', 'documents.course'],
      },
    };
    const params3 = {
      filter: {
        skip: skipPage,
        limit: CERTIFIER_CATALOG_DETAIL_DIVERS_PER_PAGE,
        relations: ['centreList', 'centreList.centre'],
      },
    };
    const organizationId = localStorage.getItem('organizationId');
    const resp = await getCourse(id, params);
    const resp2 = await getCourseDivers(organizationId || '', id, params2);
    const resp3 = await getCourseDivers(organizationId || '', id, params3);
    const course = resp.data;
    const divers = mergeObject(resp2.data, resp3.data).data;
    const filteredLevels = levels.filter(
      (level: IDropdown) => parseInt(level.value, undefined) === course.minimumRequisites.training
    );
    const courseLevel = filteredLevels.length > 0 ? filteredLevels[0].label : '';
    const totalPages: number = Math.ceil(resp2.data.count / CERTIFIER_CATALOG_DETAIL_DIVERS_PER_PAGE);
    this.setState({ course, courseLevel, divers, totalPages, total: resp2.data.count });
  }

  async getLevels() {
    const resp = await getLevels();
    const levels = resp.data.data.map((level: ILevels) => {
      return { value: level.id.toString(), label: level.name };
    });
    this.setState({ levels }, () => {
      this.getCourse();
    });
  }

  async handleRemove() {
    const { certifierId, course } = this.state;
    const { t } = this.props
    if (course && certifierId) {
      await deleteCollaboratorCourse(certifierId, course.id.toString());
      services.pushNotification({
        title: t('buttons:deleted'),
        text: t('modalMessages:coursedel'),
        type: 'red',
      });
      this.props.history.goBack();
    }
  }

  async handleConfirm() {
    const { certifierId, course } = this.state;
    const { t } = this.props
    if (course && certifierId) {
      course.published = true;
      await patchCertifierCourse(certifierId, course.id.toString(), course);
      services.pushNotification({
        title: t('modalMessages:published'),
        text: t('modalMessages:coursepub'),
        type: 'green',
      });
    }
    this.setState({ renderModal: '' });
  }

  getTableRows() {
    const {
      match: {
        params: { id, organizationId },
      },
    } = this.props;

    const { divers, course } = this.state;
    const tableRows: any[] = [];
    divers.forEach(diver => {
      const tableRow = copyOf(this.tableRowTemplate);
      const diverDocument = diver.documents.filter((document: any) => {
        if (!document.course) return false;
        return document.course.id === course.id;
      })[0];
      tableRow.id.value = diverDocument ? diverDocument.docId || '-' : '-';
      tableRow.name.value = `${diver.user.name} ${diver.user.surname}`;
      tableRow.validated.value =
        diverDocument && !!diverDocument.validated && !!diverDocument.validated.user
          ? `${diverDocument.validated.user.name} ${diverDocument.validated.user.surname}`
          : '-';
      tableRow.cert_date.value = diverDocument ? moment(diverDocument.createdAt).format('DD/MM/YYYY') : '-';
      tableRow.expiration_date.value =
        diverDocument && diverDocument.expiration_date
          ? moment(diverDocument.expiration_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '';
      tableRow.detail.link = organizationId
        ? `/organization/${organizationId}/divers/${diver.id}`
        : `/divers/${diver.id}`;
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  setPage(page: number) {
    const { total } = this.state;
    const skip: number = CERTIFIER_CATALOG_DETAIL_DIVERS_PER_PAGE * (page - 1);
    if (skip < total) {
      this.setState({ page, skipPage: skip }, () => {
        this.getCourse();
      });
    }
  }

  render() {
    const { course, courseLevel, selectedLanguage, certifierId, renderModal, divers, totalPages, page } = this.state;
    const { t } = this.props;

    /*switch(){
      course:
certifier: {id: 28, createdAt: "2021-01-27T09:45:24.531Z", updatedAt: "2021-01-27T09:45:24.531Z", name: "Test", logo: null, …}
createdAt: "2021-02-01T11:27:50.862Z"
description: {es
    }*/

    if (!course) return '';
    return (
      <>
        { course.published ? (
          <HeaderMain
            title={t('buttons:prof')}
            goBack={true}
            mainButton={{
              text: t('buttons:edit'),
              onClick: () => this.setState({ renderModal: 'modal-edit' }),
            }}
            icons={[{
              img: images.deleteSvg,
              onClick: () => this.setState({ renderModal: 'modal-delete' }),
            }]}
          />
        ) : (
          <HeaderMain
            title={t('buttons:prof')}
            goBack={true}
            mainButton={{
              text: t('buttons:edit'),
              onClick: () => this.setState({ renderModal: 'modal-edit' }),
            }}
            secondaryButton={{
              text: t('modalMessages:publish'),
              onClick: () => this.setState({ renderModal: 'modal-publish' }),
            }}
            icons={[{
              img: images.deleteSvg,
              onClick: () => this.setState({ renderModal: 'modal-delete' }),
            }]}
          />
        )
        }
        <MainLayoutSection>
          <CertifierCatalogDetailStyle>
            <div className="certifier-catalog-detail-top">
              <div className="certifier-catalog-detail-top-left">
                <img src={course.eLicense} alt="" />
              </div>
              <div className="certifier-catalog-detail-top-right">
                <div className="certifier-catalog-detail-top-right__title">
                  <HeadDestacadoText> {course.name}</HeadDestacadoText>
                </div>

                <div className="certifier-catalog-detail-top-right-info">
                  <div className="certifier-catalog-detail-top-right-info__item">
                    <div className="certifier-catalog-detail-top-right-info__item__title">
                      <TextDestacadoText>Disciplina:</TextDestacadoText>
                    </div>
                    <div className="certifier-catalog-detail-top-right-info__item__text">
                      <TextText>{DiveDisciplinesKeyValues[course.discipline]}</TextText>
                    </div>
                  </div>
                  <div className="certifier-catalog-detail-top-right-info__item">
                    <div className="certifier-catalog-detail-top-right-info__item__title">
                      <TextDestacadoText>{t('buttos:type')}:</TextDestacadoText>
                    </div>
                    <div className="certifier-catalog-detail-top-right-info__item__text">
                      <TextText>{COURSE_KINDS_KEY_VALUES[course.kind]}</TextText>
                    </div>
                  </div>
                  {/* <div className="certifier-catalog-detail-top-right-info__item">
                    <div className="certifier-catalog-detail-top-right-info__item__title">
                      <TextDestacadoText>Formación habilitada:</TextDestacadoText>
                    </div>
                    <div className="certifier-catalog-detail-top-right-info__item__text">
                      <TextText>{course.detail}</TextText>
                    </div>
                  </div> */}
                  <div className="certifier-catalog-detail-top-right-info__item">
                    <div className="certifier-catalog-detail-top-right-info__item__title">
                      <TextDestacadoText>{t('modalMessages:levelad')}:</TextDestacadoText>
                    </div>
                    <div className="certifier-catalog-detail-top-right-info__item__text">
                      <TextText>{courseLevel}</TextText>
                    </div>
                  </div>
                  <div className="certifier-catalog-detail-top-right-info__item">
                    <div className="certifier-catalog-detail-top-right-info__item__title">
                      <TextDestacadoText>{t('modalMessages:titneed')}:</TextDestacadoText>
                    </div>
                    <div className="certifier-catalog-detail-top-right-info__item__text">
                      <TextText>
                        {course.previousCourses && course.previousCourses.map((prevCourse: any, index: any) => {
                          if (index === course.previousCourses.length - 1) {
                            return prevCourse.name;
                          } else {
                            return prevCourse.name + ", ";
                          }
                        })
                        }</TextText>
                    </div>
                  </div>
                  <div className="certifier-catalog-detail-top-right-info__item">
                    <div className="certifier-catalog-detail-top-right-info__item__title">
                      <TextDestacadoText>{t('modalMessages:minage')}:</TextDestacadoText>
                    </div>
                    <div className="certifier-catalog-detail-top-right-info__item__text">
                      <TextText>{course.minimumRequisites.age}</TextText>
                    </div>
                  </div>
                  <div className="certifier-catalog-detail-top-right-info__item">
                    <div className="certifier-catalog-detail-top-right-info__item__title">
                      <TextDestacadoText>{t('modalMessages:certdivers')}:</TextDestacadoText>
                    </div>
                    <div className="certifier-catalog-detail-top-right-info__item__text">
                      <TextText>{divers.length}</TextText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="certifier-catalog-detail-description">
              <div className="certifier-catalog-detail-description-title">
                <div className="certifier-catalog-detail-description-title__text">
                  <TextDestacadoText>{t('modalMessages:desc')}</TextDestacadoText>
                </div>
                <div className="certifier-catalog-detail-description-title__language">
                  <SelectBox
                    optionsText={LANGUAGES}
                    className="rounded-filter"
                    optionKey={'value'}
                    optionValue={'label'}
                    labelText=""
                    initialValue={selectedLanguage}
                    defaultValue={selectedLanguage}
                    onChange={value => {
                      this.setState({ selectedLanguage: value });
                    }}
                  />
                </div>
              </div>
              <div className="certifier-catalog-detail-description-text">
                <SubtitleText>{course.description[selectedLanguage] && parser(course.description[selectedLanguage])}</SubtitleText>
              </div>
            </div>

            <div className="certifier-catalog-detail-table">
              <div className="certifier-catalog-detail-table-title">
                <div className="certifier-catalog-detail-table-title__text">
                  <TitleText>{t('modalMessages:certdivers')}</TitleText>
                </div>
                <div className="certifier-catalog-detail-table-title__filters">{/* Filtros */}</div>
              </div>
              <MainLayoutTableContainer>
                <div className="diving-center-table">
                  <MainTableLayout
                    columns={this.tableColumns}
                    rows={this.getTableRows()}
                    rowKeys={this.rowKeys}
                    onOrder={() => { }}
                  />
                </div>
                {totalPages > 1 && (
                  <div className="diving-center-pagination">
                    <Pagination page={page} callback={page => this.setPage(page)} limit={totalPages} />
                  </div>
                )}
              </MainLayoutTableContainer>
            </div>
          </CertifierCatalogDetailStyle>
        </MainLayoutSection>
        {renderModal === 'modal-publish' && (
          <ModalConfirmation
            showModal={renderModal === 'modal-publish'}
            subtitle={
              t('modalMessages:presscourse')
            }
            onAccept={() => this.handleConfirm()}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}
        {renderModal === 'modal-edit' && certifierId && (
          <ModalCreateCatalog
            certifierId={certifierId}
            showModal={renderModal === 'modal-edit'}
            onCancel={() => this.setState({ renderModal: '' })}
            onSuccess={() => {
              services.showModalSuccess(t('modalMessages:edited'), t('modalMessages:coursedit'));
              this.setState({ renderModal: '' });
              this.getLevels();
            }}
            course={course}
          />
        )}
        {renderModal === 'modal-delete' && (
          <ModalDelete
            title={''}
            subtitle={t('modalMessages:confdel')}
            showModal={renderModal === 'modal-delete'}
            onRemove={() => this.handleRemove()}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}
      </>
    );
  }
  
  tableColumns = [
    {
      text: 'ID',
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:name'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:valby'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:datecert'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('modalMessages:datend'),
      filter: false,
      ascendent: false,
    },
    {
      text: '',
      filter: false,
      ascendent: true,
    },
  ];

  tableRowTemplate = {
    id: {
      key: 'highlighted-body',
      value: '09/05/2019',
    },
    name: {
      key: '',
      value: '16:40',
    },
    validated: {
      key: '',
      value: 'España',
    },
    cert_date: {
      key: '',
      value: 'Murcia',
    },
    expiration_date: {
      key: '',
      value: '',
    },
    detail: {
      key: 'detail',
      link: '/certifier/catalog/detail',
    },
  };

  rowKeys = ['id', 'name', 'validated', 'cert_date', 'expiration_date', 'detail'];
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}
export default withTranslation(['buttons', 'modalMessages']) (connect<{}, {}, {}, IStoreState>(mapStateToProps)(CertifierCatalogDetail));
