import * as React from 'react';
import { AdsDetailStyle } from './ads-detail-style';
import HeaderMain from '../../../components/header-main/header-main';
import { MainLayoutSection, MainLayoutTableContainer } from '../../main-layout-style';
import { images } from '../../../assets/images';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import HeadDestacadoText from '../../../components/text/head-destacado-text/head-destacado-text';
import SubheadText from '../../../components/text/subhead-text/subhead-text';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import TitleText from '../../../components/text/title-text/title-text';
import MainButton from '../../../components/main-button';
import MainTableLayout from '../../../components/main-table/main-table';
import Pagination from '../../../components/pagination';
import { RouteComponentProps, withRouter } from 'react-router';
import { getOneAds, uploadAds } from '../../../services/ads';
import { getDayMonthNumberYear } from '../../../utils/time';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { copyOf } from '../../../utils/other';

interface RouteParams {
  id: string;
}
interface IAdsDetailLayoutProps extends RouteComponentProps<RouteParams>, WithTranslation {}

interface IAdsDetailLayoutState {
  adActive: boolean;
  modal: string;
  ads: any;
  activeFilters: boolean;
}

class AdsDetailLayout extends React.Component<IAdsDetailLayoutProps, IAdsDetailLayoutState> {
  constructor(props: IAdsDetailLayoutProps) {
    super(props);

    this.state = {
      adActive: false,
      modal: '',
      ads: {},
      activeFilters: false,
    };
  }

  tableColumns = [
    {
      text: this.props.t('tables:date'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:hour'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:country'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:region'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:name'),
      filter: false,
      ascendent: false,
    },
    {
      text: this.props.t('tables:age'),
      filter: false,
      ascendent: true,
    },
    {
      text: this.props.t('tables:gendre'),
      filter: false,
      ascendent: true,
    },
  ];

  tableRows = [
    {
      date: {
        key: 'highlighted-body',
        value: '09/05/2019',
      },
      hour: {
        key: '',
        value: '16:40',
      },
      country: {
        key: '',
        value: 'España',
      },
      region: {
        key: '',
        value: 'Murcia',
      },
      name: {
        key: '',
        value: 'Margarita Lozano Crespo',
      },
      age: {
        key: '',
        value: '34',
      },
      gendre: {
        key: '',
        value: 'Mujer',
      },
    },
  ];

  rowKeys = ['date', 'hour', 'country', 'region', 'name', 'age', 'gendre'];

  componentDidMount() {
    this.getOneAds();
  }

  getOneAds = async () => {
    const { match } = this.props;
    const { ads } = this.state;
    if (match.params.id) {
      try {
        const response: any = await getOneAds(match.params.id);

        this.setState({ ads: response.data });
      } catch (err) {
        console.log(err);
      }
    }
  };

  async activateAd() {
    const { ads } = this.state;
    const newAd = copyOf(ads);
    newAd.active = !newAd.active;
    await uploadAds(newAd.id.toString(), newAd);
    this.getOneAds();
  }

  render() {
    const { adActive, modal, ads, activeFilters } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderMain
          title={t('layouts:ads-detail.header')}
          goBack={true}
          mainButton={{
            text: ads.active ? t('layouts:ads-detail.minactive') : t('layouts:ads-detail.mactive'),
            onClick: () => this.activateAd(),
          }}
        />
        <MainLayoutSection>
          <AdsDetailStyle>
            <div className="dive-site-hero">
              <div className="dive-site-hero-slider">
                <img src={ads.picture ? ads.picture : images.jellyfishIntro} alt="" />
              </div>
              <div className="dive-site-hero-info">
                <div className="dive-site-hero-info__type">
                  <div className={`dive-site-hero-info__type__item ${ads.active ? 'active' : ''}`}>
                    <BodyDestacadoText>
                      {ads.active ? t('layouts:ads-detail.actived') : t('layouts:ads-detail.deactivated')}
                    </BodyDestacadoText>
                  </div>
                </div>
                <div className="dive-site-hero-info__title">
                  <HeadDestacadoText>{ads.name} </HeadDestacadoText>
                </div>
                <div className="dive-site-hero-info__item">
                  <div className="dive-site-hero-info__item__icon">
                    <img src={images.calendarSvg} alt="" />
                  </div>
                  <div className="dive-site-hero-info__item__text">
                    <SubheadText>{getDayMonthNumberYear(ads.createdAt)}</SubheadText>
                  </div>
                </div>
                <div className="dive-site-hero-info__item">
                  <div className="dive-site-hero-info__item__icon">
                    <img src={images.homeSvg} alt="" />
                  </div>
                  <div className="dive-site-hero-info__item__text">
                    <SubheadText>{ads.company}</SubheadText>
                  </div>
                </div>
                <div className="dive-site-hero-info__item">
                  <div className="dive-site-hero-info__item__icon">
                    <img src={images.linkSvg} alt="" />
                  </div>
                  <div className="dive-site-hero-info__item__text">
                    <SubheadText>
                      <a
                        href={`${ads.website && ads.website.indexOf('http') === -1 ? 'https://' : ''}${ads.website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {ads.website}
                      </a>
                    </SubheadText>
                  </div>
                </div>
              </div>
            </div>

            <div className="ads-detail-text">
              <SubtitleText>{ads.description ? ads.description.es : ''}</SubtitleText>
            </div>

            <div className="ads-detail-table">
              <div className="ads-detail-table__title">
                <div className="ads-detail-table__title__text">
                  <TitleText>{t('layouts:ads-detail.stats')}</TitleText>
                </div>
                <div className="ads-detail-table__title__filter">
                  <MainButton
                    iconImg={activeFilters ? images.filtersLightBlueSvg : images.filtersSvg}
                    text={t('buttons:filter')}
                    type={activeFilters ? 'secondary-active' : 'secondary'}
                    onClick={() => this.setState({ activeFilters: !activeFilters })}
                  />
                </div>
              </div>

              <div className={`ads-detail-table-filters${activeFilters ? ' active' : ''}`}>
                {/* To Do ADD Filters */}
              </div>

              <div className="ads-detail-table__content">
                <MainLayoutTableContainer>
                  <div className="diving-center-table">
                    <MainTableLayout
                      columns={this.tableColumns}
                      rows={this.tableRows}
                      rowKeys={this.rowKeys}
                      onOrder={() => {}}
                    />
                  </div>
                  <div className="diving-center-pagination">
                    <Pagination page={1} callback={() => {}} limit={22} />
                  </div>
                </MainLayoutTableContainer>
              </div>
            </div>
          </AdsDetailStyle>
        </MainLayoutSection>
      </>
    );
  }
}
export default withTranslation(['intro', 'buttons'])(withRouter(AdsDetailLayout));
