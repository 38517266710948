import React, { Component } from 'react';
import { FAQStyle } from './configuration-faq-style';
import { MainLayoutSection } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import SubtitleText from '../../../components/text/subtitle-text/subtitle-text';
import { APP_WEB_URL } from '../../../env';

class FAQ extends Component {
  render() {
    return (
      <>
        <HeaderMain title={'Preguntas frecuentes'} />
        <MainLayoutSection>
          <FAQStyle>
            <div className="faq-section">
              <div className="faq-section__title">
                <SubheadDestacadoText>1. Relación entre mi perfil de centro y mi cuenta de Diveasapp</SubheadDestacadoText>
              </div>
              <div className="faq-section__text">
                <SubtitleText>Cuando registras un centro de buceo o un club te pedimos en el primer paso tus datos personales para crear <strong>tu cuenta de Diveasapp</strong>. Tu cuenta siempre estará asociada a un perfil de buceador en el buscador aunque solo hayas registrado un centro de buceo.</SubtitleText>
                <SubtitleText>La razón de esta funcionalidad es para que tú mismo puedas participar como parte de tu staff y, así, asignarte actividades de buceo. </SubtitleText>
                <SubtitleText>Puedes acceder a tu perfil de buceador en el buscador desde tu panel de gestión pinchando en “mi perfil buceador” que encuentras al final del menú del panel de tu centro.</SubtitleText>
              </div>
            </div>
            <div className="faq-section">
              <div className="faq-section__title">
                <SubheadDestacadoText>2. Validación del centro</SubheadDestacadoText>
              </div>
              <div className="faq-section__text">
                <SubtitleText>Cuando accedes por primera vez al panel del centro verás que no puedes utilizar todas las funcionalidades disponibles. Esto es debido a que queremos, dentro de lo posible, evitar que centros sin licencia ofrezcan actividades dentro de nuestra plataforma.</SubtitleText>
                <SubtitleText>Para desbloquear el panel solo tienes que subir en el apartado "documentación" dentro de "mi centro" el <strong>seguro de responsabilidad civil</strong> y, registrarte en la plataforma de pago <strong>Stripe</strong> en el apartado del menú "facturación \ datos de facturación" para poder recibir pagos online.</SubtitleText>
              </div>
            </div>
            <div className="faq-section">
              <div className="faq-section__title">
                <SubheadDestacadoText>3. Ya tengo una cuenta de Stripe, ¿Qué hago para conectarla al buscador?</SubheadDestacadoText>
              </div>
              <div className="faq-section__text">
                <SubtitleText>Accede al apartado facturación \ datos de facturación y pincha en conectar con Stripe. Diveasapp te dirige externamente al acceso de Stripe donde puedes hacer login introduciendo tus datos de acceso. Cuando termines el proceso verás que el color del rótulo conectar con Stripe habrá cambiado a verde. </SubtitleText>
              </div>
            </div>
            <div className="faq-section">
              <div className="faq-section__title">
                <SubheadDestacadoText>4. ¿Qué información se publica en la plataforma?</SubheadDestacadoText>
              </div>
              <div className="faq-section__text">
                <SubtitleText>Los buceadores que naveguen por Diveasapp podrán conocer tu centro en tu <strong>perfil del buscador</strong>. La información que introduces en tu panel sobre tu centro, a excepción de los datos del responsable y datos de facturación, se publica en el buscador: <strong>datos públicos, descripción, servicios, instalaciones, staff, catálogo de actividades y planificación de actividades</strong>. </SubtitleText>
                <SubtitleText>Cuando guardas tus datos en el panel del centro se publican automáticamente en el buscador. Puedes acceder rápidamente al perfil de tu centro en el buscador pinchando arriba a la izquierda en el menú sobre la palabra Diveasapp.</SubtitleText>
              </div>
            </div>
            <div className="faq-section">
              <div className="faq-section__title">
                <SubheadDestacadoText>5. Roles del staff de un centro y acceso al panel</SubheadDestacadoText>
              </div>
              <div className="faq-section__text">
                <SubtitleText>Puedes encontrar <strong>3 roles</strong> diferentes dentro del panel de gestión de Diveasapp: 
                  <ul>
                  <li>Owner (creador del centro)</li>
                  <li>Gestor total (accede a todas las funcionalidades del panel del centro)</li>
                  <li>Staff (las acciones dentro del panel pueden ser restringidas por un usuario con permisos)</li>
                  </ul>
Cuando una persona recibe la invitación como gestor total o como staff, podrá acceder al panel del centro creado por el owner con su cuenta de Diveasapp. La vista del panel será igual para todos con la diferencia de que un staff puede tener accesos restringidos, pudiendo acceder a cada apartado pero no efectuar acciones sobre los apartados en los que no tiene permisos.</SubtitleText>
              </div>
            </div>
            <div className="faq-section">
              <div className="faq-section__title">
                <SubheadDestacadoText>6. ¿Diveasapp tendrá más funcionalidades?</SubheadDestacadoText>
              </div>
              <div className="faq-section__text">
                <SubtitleText>¡Desde luego que sí! Esto es solo el inicio. Te mantendremos informad@ de las actualizaciones de la plataforma.</SubtitleText>
              </div>
            </div>
            <div className="faq-section">
              <div className="faq-section__title">
                <SubheadDestacadoText>7. Puedes acceder a los documentos legales de Diveasapp a través de estos enlaces:</SubheadDestacadoText>
              </div>
              <div className="faq-section__text">
                <SubtitleText>
                  <ul>
                  <li><a href={`${APP_WEB_URL}/legal-advice`} target="_blank"><u>Términos y condiciones</u></a></li>
                  <li><a href={`${APP_WEB_URL}/privacity`} target="_blank"><u>Políticas de privacidad</u></a></li>
                  </ul>
                </SubtitleText>
              </div>
            </div>
          </FAQStyle>
        </MainLayoutSection>
      </>
    );
  }
}

export default FAQ;
