import * as React from 'react';
import ModalContainer from '../modal-container';
import { ModalIncidenceDetailStyle } from './modal-incident-detail-style';
import Label02Text from '../../text/label-02-text/label-02';
import SubheadDestacadoText from '../../text/subhead-destacado-text/subhead-destacado-text';
import { images } from '../../../assets/images';
import BodyText from '../../text/body-text/body-text';
import InfoText from '../../text/info-text/info-text';
import BodyDestacadoText from '../../text/body-destacado-text/body-destacado-text';
import LabelText from '../../text/label-text/label';
import MainButton from '../../main-button';
import { getOneIncidences } from '../../../services/incidences';
import { getDateLongString, getDateMonthString } from '../../../utils/time';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

export interface IModalIncidenceDetailProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onCancel: () => void;
  isEditing?: boolean;
  incidenceId?: string;
  handleButton: Function;
  t: any
}

export interface IModalIncidenceDetailState {
  incidence: any;
}

class ModalIncidenceDetail extends React.Component<IModalIncidenceDetailProps, IModalIncidenceDetailState> {
  readonly state: IModalIncidenceDetailState;
  private STATUS: any = {
    closed: 'Cerrada',
    reOpen: 'Reabierta',
    solved: 'Resuelta',
  };

  private ROLE: any = {
    superAdmin: 'superAdmin',

    diver: 'diver',

    divingCenter_admin: 'divingCenter_admin',
    divingCenter_staff: 'divingCenter_staff',
    divingCenter_owner: 'divingCenter_owner',

    certifier_admin: 'certifier_admin',
    certifier_staff: 'certifier_staff',
    certifier_owner: 'certifier_owner',

    organization_admin: 'organization_admin',
    organization_staff: 'organization_staff',
    organization_owner: 'organization_owner',

  };

  constructor(props: IModalIncidenceDetailProps) {
    super(props);

    this.state = {
      incidence: {
        createdAt: '',
        date: '',
        description: '',
        id: '',
        name: '',
        ref: '',
        refId: '',
        reference: {},
        status: '',
        type: {},
        updatedAt: '',
        user: {},
        statusChanges: {},
      },
    };
  }

  componentDidMount() {
    const { incidenceId } = this.props;
    if (incidenceId) {
      this.getIncidence(incidenceId);
    }
  }

  getIncidence = async (id: string) => {
    try {
      const params = {
        filter: {
          relations: ['type', 'user', 'statusChanges'],
        },
      };
      const res: any = await getOneIncidences(id, params);
      const incidence = res.data;
      this.setState({ incidence });
    } catch (err) {
      console.log(err);
    }
  };

  handleLink = () => {
    const { incidence } = this.state;
    const { history } = this.props;
    if (incidence.ref === 'site') {
      history.push(`/dive-sites/dive-site/detail/${incidence.reference.id}`);
    } else if (incidence.ref.indexOf('picture') > -1) {
      history.push(`/centres/${incidence.reference.centre.id}/business/profile/description`);
    } else {
      history.push(`/centres/${incidence.reference.id}/business/profile/data`);
    }
  };

  render() {
    const { showModal, onCancel, handleButton, t } = this.props;
    const { incidence } = this.state;

    return (
      <ModalContainer title={``} active={showModal} modalClose={() => onCancel()}>
        <ModalIncidenceDetailStyle>
          <div className={`modal-incidence-detail-status ${incidence.status}`}>
            <Label02Text>{this.STATUS[incidence.status]}</Label02Text>
          </div>
          <div className="modal-incidence-detail-title">
            <SubheadDestacadoText>{incidence.name}</SubheadDestacadoText>
            <div className="modal-incidence-detail-title__icon" onClick={() => this.handleLink()}>
              <img src={images.externalLinkGrey} alt="" />
            </div>
          </div>
          <div className="modal-incidence-detail-date">
            <p>{getDateLongString(incidence.createdAt)}</p>
          </div>
          <div className="modal-incidence-detail-user">
            <div className="modal-incidence-detail-user__avatar" onClick={() => { }}>
              <img src={incidence.user.picture ? incidence.user.picture : images.avatar} alt="" />
            </div>
            <div className="modal-incidence-detail-user__info">
              <div className="modal-incidence-detail-user__info__name">
                <BodyText>
                  {incidence.user.name} {incidence.user.surname}
                </BodyText>
              </div>
              <div className="modal-incidence-detail-user__info__type">
                <InfoText>{this.ROLE[incidence.user.roles]}</InfoText>
              </div>
            </div>
          </div>
          {incidence.ref.indexOf('picture') > -1 && (
            <div className="modal-incidence-detail-image">
              <div className="modal-incidence-detail-image-img">
                <img src={incidence.reference.src || images.turtle} alt="" />
                <div className="modal-incidence-detail-image-img__veil">
                  <div className="modal-incidence-detail-image-img__veil__img">
                    <img src={images.fullScreenWhite} alt="" />
                  </div>
                </div>
              </div>
              <div className="modal-incidence-detail-image-info">
                <div className="modal-incidence-detail-image-info__top">
                  <div className="modal-incidence-detail-image-info__top__name">
                    <BodyDestacadoText>{t('components:general.image-name')}</BodyDestacadoText>
                  </div>
                  <div className="modal-incidence-detail-image-info__top__file">
                    <InfoText>{incidence.reference.description || '-'}</InfoText>
                  </div>
                </div>

                <div className="modal-incidence-detail-image-info__bottom">
                  <div className="modal-incidence-detail-image-info__bottom__subtitle">
                    <LabelText>{t('components:general.upload-by')}</LabelText>
                  </div>
                  <div className="modal-incidence-detail-image-info__bottom__name">
                    <p>
                      {incidence.reference.uploadedBy.name} {incidence.reference.uploadedBy.surname},
                      {getDateMonthString(incidence.reference.updatedAt)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  */}
          {incidence.ref === 'site' && (
            <div className="modal-incidence-detail-section">
              <div className="modal-incidence-detail-section__title">
                <LabelText>{t('components:general.related-with')}</LabelText>
              </div>
              <div className="modal-incidence-detail-section__text">
                <InfoText>{incidence.reference.name}</InfoText>
              </div>
            </div>
          )}
          {/*  */}
          <div className="modal-incidence-detail-section blue">
            <div className="modal-incidence-detail-section__title">
              <LabelText>{t('components:general.diving-centre')}</LabelText>
            </div>
            <div className="modal-incidence-detail-section__text">
              <InfoText>
                {incidence.reference.centre ? incidence.reference.centre.name : incidence.reference.name}
              </InfoText>
            </div>
          </div>
          {/*  */}
          <div className="modal-incidence-detail-section">
            <div className="modal-incidence-detail-section__title">
              <LabelText>{t('components:general.incidence-type')}</LabelText>
            </div>
            <div className="modal-incidence-detail-section__text">
              <InfoText>
                {incidence.type && incidence.type.name && localStorage.i18nextLng
                  ? incidence.type.name[localStorage.i18nextLng]
                  : '-'}
              </InfoText>
            </div>
          </div>
          {/*  */}
          <div className="modal-incidence-detail-section">
            <div className="modal-incidence-detail-section__title">
              <LabelText>{t('components:general.reason')}</LabelText>
            </div>
            <div className="modal-incidence-detail-section__text">{incidence.description}</div>
          </div>

          {incidence.statusChanges.length > 0 && (
            <div className="modal-incident-detail-historic">
              <div className="modal-incident-detail-historic-title">
                <LabelText>{t('components:general.incidence-historical')}</LabelText>
              </div>
              {incidence.statusChanges.map((sc: any) => (
                <div key={`historic-${sc.id}`} className="modal-incident-detail-historic-pop">
                  <div className="modal-incident-detail-historic-pop-top">
                    <div className="modal-incident-detail-historic-pop-top__title">
                      <div className="modal-incident-detail-historic-pop-top__title__icon">
                        <img src={images.messageCircleGreenSvg} alt="" />
                      </div>
                      <div className={`modal-incident-detail-historic-pop-top__title__text ${sc.type}`}>
                        <LabelText>{this.STATUS[sc.type]}</LabelText>
                      </div>
                    </div>
                    <div className="modal-incident-detail-historic-pop-top__date">
                      <p>{getDateMonthString(sc.updatedAt)}</p>
                    </div>
                  </div>

                  <div className="modal-incident-detail-historic-pop-message">
                    <div className="modal-incident-detail-historic-pop-message__center">
                      <InfoText>Centro de buceo “Murcia”</InfoText>
                    </div>

                    <div className="modal-incident-detail-historic-pop-message__subtitle">
                      <InfoText>{t('components:general.incidence-resolution-reason')}</InfoText>
                    </div>
                    <div className="modal-incident-detail-historic-pop-message__text">
                      <p>{sc.message || '-'}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="modal-incidence-detail-buttons">
            {incidence.status === 'open' && (
              <div className="modal-incidence-detail-buttons__item">
                <MainButton className="primary" onClick={() => handleButton('solved')} text={t('components.actions.resolve-incidence')} />
              </div>
            )}
            {incidence.status !== 'closed' && (
              <div className="modal-incidence-detail-buttons__item">
                <MainButton className="primary" onClick={() => handleButton('close')} text={t('components.actions.close-incidence')} />
              </div>
            )}
            {incidence.status !== 'closed' && (
              <div className="modal-incidence-detail-buttons__item">
                <MainButton className="secondary-red" onClick={() => handleButton('reopen')} text={t('components.actions.reopen')} />
              </div>
            )}
          </div>
        </ModalIncidenceDetailStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalIncidenceDetail));
