import * as React from 'react';
import ModalContainer from '../modal-container';
import { ModalAddFacilitiesContainer } from './modal-add-facilities-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../containers/main-layout-style';
import MainButton from '../../main-button';
import ListItemAdded from '../../list-item-added/list-item-added';
import InputBoxButton from '../../input-box-button/input-box-button';
import { copyOf } from '../../../utils/other';
import InputBox from '../../input-box';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IModalAddFacilitiesProps extends RouteComponentProps, WithTranslation {
  showModal: boolean;
  onClose: () => void;
  items: string[];
  addItems: Function;
  t:any;
}

export interface IModalAddFacilitiesState {
  items: string[];
  item: string;
}

class ModalAddFacilities extends React.Component<IModalAddFacilitiesProps, IModalAddFacilitiesState> {
  constructor(props: IModalAddFacilitiesProps) {
    super(props);

    this.state = {
      items: copyOf(props.items),
      item: '',
    };
  }

  addOthers(other: string) {
    const { items } = this.state;
    if (!items.includes(other) && other !== '') {
      items.push(other);
      this.setState({ items, item: '' });
    }
  }
  removeOthers(index: number) {
    const { items } = this.state;
    items.splice(index, 1);
    this.setState({ items });
  }

  confirm() {
    const { onClose, addItems } = this.props;
    const { items } = this.state;
    onClose();
    addItems(items);
  }

  render() {
    const { onClose, showModal, addItems, t } = this.props;
    const { items, item } = this.state;
    return (
      <ModalContainer title={'Añadir instalación'} className="" modalClose={() => onClose()} active={showModal}>
        <ModalAddFacilitiesContainer>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-12">
                    <InputBox
                      // errorCode="Error"
                      placeholder={t('components:general.installation')}
                      className="rounded"
                      type="text"
                      value={item}
                      labelText={t('components:general.nominst')}
                      onChange={value => this.setState({ item: value })}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-12">
                    <MainButton 
                      type="primary" 
                      onClick={() => this.addOthers(item)} 
                      text={t('components:actions.add')}
                       />
                  </FormGridItem>
                </FormGridRow>
                {items.map((item: string, index: number) => (
                  <div key={`other-${index}`} className="modal-add-service-item">
                    <ListItemAdded
                      id={''}
                      className="add-service"
                      text={item}
                      onRemove={() => this.removeOthers(index)}
                    />
                  </div>
                ))}
              </FormGrid>
            </div>
          </div>
          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton type="primary" onClick={() => this.confirm()} 
              text={t('components:actions.accept')}
               />
            </div>
            <div className="create-user-buttons__item">
              <MainButton type="secondary" onClick={() => onClose()} 
              text={t('components:actions.cancel')}
               />
            </div>
          </div>
        </ModalAddFacilitiesContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages'])(withRouter(ModalAddFacilities));
