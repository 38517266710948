import React, { Component } from 'react';
import { ModalDiverFormContainer } from './modal-diver-form-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../main-layout-style';
import InputBox from '../../../../components/input-box';
import MainButton from '../../../../components/main-button';
import ModalContainer from '../../../../components/modals/modal-container';
import { DiverFormFunctionalProps } from './diver-form-functional';
import BodyDestacadoText from '../../../../components/text/body-destacado-text/body-destacado-text';
import CalendarBox from '../../../../components/calendar-box';
import PhoneBox from '../../../../components/phone-box/phone-box';
import SelectBox from '../../../../components/select-box';
import { images } from '../../../../assets/images';
import { Certifier } from '../../../../types/company';
import { DiverForm } from '../../../../types/user';
import { COUNTRIES } from '../../../../constants/countries';
import { IDropdown } from '../../../../models/generic';
import { getDataFromAddress } from '../../../../services/google-maps';
import { getAdministrativeLevel1, getTown, getCountry, getPostalCode } from '../../../../utils/maps';

interface ModalDiverFormProps extends DiverFormFunctionalProps {
  person: DiverForm;
  error: string;
  errorFields: { [key: string]: string };
  handleDataChange: (field: string, value: string) => Promise<any>;
  submitForm: () => void;
  validateForm: () => boolean;
}

interface ModalDiverFormState {
  firstStep: boolean;
  clearCertifier: boolean;
  tempCertifier?: Certifier;
  places: IDropdown[];
}

export default class ModalDiverForm extends Component<ModalDiverFormProps, ModalDiverFormState> {
  state: ModalDiverFormState = {
    firstStep: true,
    clearCertifier: false,
    places: [],
  };

  searchTimeout: any;
  handleSearchAddress(value: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (value && value !== '') this.searchAddress(value);
    }, 300);
  }

  async searchAddress(value: string) {
    const resp: any = await getDataFromAddress(value);
    const results = resp.data.results;
    const places: any[] = results.map((result: any) => {
      const level1 = getAdministrativeLevel1(result);
      const town = getTown(result);
      const country = getCountry(result);
      const postalCode = getPostalCode(result);
      return {
        level1,
        town,
        country,
        postalCode,
        value: result.formatted_address,
        label: result.formatted_address,
      };
    });
    this.setState({ places });
  }

  render() {
    const { firstStep, tempCertifier, clearCertifier, places } = this.state;
    const {
      onCancel,
      showModal,
      className,
      title,
      error,
      errorFields,
      person,
      handleDataChange,
      submitForm,
      validateForm,
    } = this.props;
    return (
      <ModalContainer title={title} className="" modalClose={() => onCancel()} active={showModal}>
        <ModalDiverFormContainer className={className}>
          {firstStep ? (
            <div className="modal-create-form">
              <div className="modal-create-form__title">
                <BodyDestacadoText>DATOS GENERALES</BodyDestacadoText>
              </div>
              <div className="modal-create-form__content">
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-2">
                      <InputBox
                        placeholder="Nombre"
                        className="rounded"
                        type="text"
                        value={person.name}
                        errorCode={errorFields['name']}
                        labelText=""
                        onChange={value => {
                          handleDataChange('name', value);
                        }}
                      />
                    </FormGridItem>
                    <FormGridItem type="col-2">
                      <InputBox
                        placeholder="Apellidos"
                        className="rounded"
                        type="text"
                        value={person.surname}
                        errorCode={errorFields['surname']}
                        labelText=""
                        onChange={value => {
                          handleDataChange('surname', value);
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-2">
                      <CalendarBox
                        placeholder="Fecha de nacimiento"
                        className="rounded"
                        labelText=""
                        initialValue={person.birthday}
                        errorCode={errorFields['birthday']}
                        onChange={value => {
                          handleDataChange('birthday', value);
                        }}
                      />
                    </FormGridItem>
                    <FormGridItem type="col-2">
                      <InputBox
                        placeholder="DNI"
                        className="rounded"
                        type="text"
                        value={person.nif}
                        errorCode={errorFields['nif']}
                        labelText=""
                        onChange={value => {
                          handleDataChange('nif', value);
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
              </div>
              <div className="modal-create-form__title">
                <BodyDestacadoText>DATOS DE CONTACTO</BodyDestacadoText>
              </div>
              <div className="modal-create-form__content">
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <SelectBox
                        required={true}
                        placeholder="Dirección"
                        className="rounded"
                        optionsText={places}
                        errorCode={errorFields['address']}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={person ? person.address : ''}
                        initialValue={person ? person.address : ''}
                        initialValueFilterText={person ? person.address : ''}
                        defaultValueFilterText={person ? person.address : ''}
                        labelText="Dirección fiscal"
                        onSearch={value => {
                          this.handleSearchAddress(value);
                        }}
                        onChange={async (value, label) => {
                          const place: any = places.filter(place => place.value === value)[0];
                          await handleDataChange('address', label || '');
                          await handleDataChange('state', place.level1.longName);
                          await handleDataChange('town', place.town.longName);
                          await handleDataChange('country', place.country.shortName);
                          await handleDataChange('postalCode', place.postalCode.longName);
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-2">
                      <InputBox
                        placeholder="Localidad"
                        className="rounded"
                        type="text"
                        value={person.town}
                        errorCode={errorFields['town']}
                        labelText=""
                        onChange={value => {
                          handleDataChange('town', value);
                        }}
                      />
                    </FormGridItem>
                    <FormGridItem type="col-2">
                      <InputBox
                        placeholder="Provincia"
                        className="rounded"
                        type="text"
                        value={person.state}
                        errorCode={errorFields['state']}
                        labelText=""
                        onChange={value => {
                          handleDataChange('state', value);
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-2">
                      <InputBox
                        placeholder="Código postal"
                        className="rounded"
                        type="text"
                        value={person.postalCode}
                        errorCode={errorFields['postalCode']}
                        labelText=""
                        onChange={value => {
                          handleDataChange('postalCode', value);
                        }}
                      />
                    </FormGridItem>
                    <FormGridItem type="col-2">
                      <SelectBox
                        labelText=""
                        placeholder="País"
                        required={true}
                        className="rounded"
                        optionsText={COUNTRIES}
                        disabled={true}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={person.country}
                        initialValue={person.country}
                        errorCode={errorFields['country']}
                        icon={images.arrowDown2BlueSvg}
                        onChange={value => {
                          handleDataChange('country', value);
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-2">
                      <PhoneBox
                        placeholder="Teléfono"
                        className="rounded"
                        errorPrefix={errorFields['phonePrefix']}
                        errorNumber={errorFields['phoneNumber']}
                        labelText=""
                        filledPrefix={person.phonePrefix}
                        filledNumber={person.phoneNumber}
                        onChange={(field, value) => {
                          handleDataChange(field, value);
                        }}
                      />
                    </FormGridItem>
                    <FormGridItem type="col-2">
                      <InputBox
                        placeholder="Email"
                        className="rounded"
                        disabled={true}
                        type="text"
                        value={person.email}
                        labelText=""
                        onChange={() => {}}
                      />
                    </FormGridItem>
                  </FormGridRow>
                </FormGrid>
              </div>
            </div>
          ) : (
            <div className="modal-create-form">
              <div className="modal-create-form__title">
                <BodyDestacadoText>DATOS DE BUCEO</BodyDestacadoText>
              </div>
              <div className="modal-create-form__content">
                <div />
                <FormGrid>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <SelectBox
                        className="rounded"
                        labelText="Nivel de buceo"
                        placeholder="Seleccione"
                        required={true}
                        initialValue={''}
                        optionsText={[]}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={''}
                        icon={images.arrowDown2BlueSvg}
                        onChange={() => {}}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <SelectBox
                        className="rounded"
                        labelText="Seleccione certificadora"
                        placeholder="Seleccione"
                        required={true}
                        initialValue={''}
                        optionsText={[]}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={''}
                        icon={images.arrowDown2BlueSvg}
                        onChange={() => {}}
                      />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    <FormGridItem type="col-1">
                      <InputBox
                        placeholder="Número ID"
                        className="rounded"
                        type="text"
                        value={person.diverId}
                        errorCode={errorFields['diverId']}
                        labelText=""
                        onChange={value => {
                          handleDataChange('diverId', value);
                        }}
                      />
                    </FormGridItem>
                    <FormGridItem type="col-3">
                      <MainButton iconImg={images.plusSvg} onClick={() => {}} text="Añadir" />
                    </FormGridItem>
                  </FormGridRow>
                  <FormGridRow>
                    {/* <AddItemFormCertifier 
                      title="certificadora" 
                      id="345678" 
                      certifier="Cert" 
                      onDelete={() => {}}
                    /> */}
                  </FormGridRow>
                </FormGrid>
              </div>
            </div>
          )}
          <div className="modal-create-buttons">
            <div className="modal-create-buttons__item">
              <MainButton
                type="primary"
                text={'Guardar'}
                onClick={() => {
                  if (validateForm()) {
                    submitForm();
                  }
                }}
              />
            </div>
            <div className="modal-create-buttons__item">
              <MainButton
                type="secondary"
                text={firstStep ? 'Omitir' : 'Volver'}
                onClick={() => {
                  if (firstStep) {
                    onCancel();
                  } else {
                    this.setState({ firstStep: true });
                  }
                }}
              />
            </div>
          </div>
        </ModalDiverFormContainer>
      </ModalContainer>
    );
  }
}
