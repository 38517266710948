import React from 'react';
import { CardCheckContainer } from './card-check-style';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';
import BodyText from '../text/body-text/body-text';
import { images } from '../../assets/images';

interface CardCheckProps {
  isActive?: boolean;
  title: string;
  text: string;
  type?: string;
}

export default class CardCheck extends React.Component<CardCheckProps> {
  render() {
    const { isActive, title, text, type } = this.props;
    return (
      <CardCheckContainer className={`${isActive ? 'active' : ''} ${type}`}>
        <div className="card-check-box">
          <div className="card-check-box__img">
            <img src={type === 'light-blue' ? images.checkBlueSvg : images.checkSvg} alt="" />
          </div>
        </div>
        <div className="card-check-title">
          <SubheadDestacadoText>{title}</SubheadDestacadoText>
        </div>
        <div className="card-check-text">
          <BodyText>{text}</BodyText>
        </div>
      </CardCheckContainer>
    );
  }
}
