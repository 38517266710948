import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const PlanningEndActivitySecondStyle = styled.div`
  padding: 48px 32px 32px;
  width: 416px;
  .planning-end-activity-second-title-top {
    font-family: Plain;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${colors['SE-002/100']};
    margin-bottom: 2px;
  }

  .planning-end-activity-second-title-buttom {
    font-family: Plain;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: ${colors['NE-001/100']};
    margin-bottom: 12px;
    max-width: 320px;
  }

  .planning-end-activity-second-subtitle {
    font-family: Plain;
    font-size: 14px;
    font-weight: 100;
    line-height: 22px;
    color: ${colors['NE-002/100']};
    margin-bottom: 28px;
    max-width: 350px;
  }

  .planning-end-activity-second-container {
    padding: 16px 14px;
    width: 352px;
    border-radius: 5px;
    background: ${colors['NE-002/10']};
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 48px;
    }
    .not-registered-title {
      font-family: Plain;
      margin-bottom: 4px;
      font-size: 10px;
      line-height: 16px;
      font-weight: 100;
      color: ${colors['CO-004/100']};
    }
    .registered-title {
      font-family: Plain;
      font-size: 10px;
      line-height: 16px;
      font-weight: 100;
      margin-bottom: 4px;
      color: ${colors['CO-001/100']};
    }
    .registered-text {
      font-family: Plain;
      font-size: 12px;
      line-height: 18px;
      color: ${colors['SE-004/100']};
      margin-bottom: 2px;
    }
    .registered-date {
      font-family: Plain;
      font-size: 10px;
      line-height: 16px;
      color: ${colors['SE-002/100']};
    }
  }

  .buttons-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buttons-bottom-left {
      width: 60px;
    }
    .buttons-bottom-right {
      width: 280px;
    }
  }
`;
