import services from './services';

export const getTicket = (ticketId: string, params?: any) => {
  const endpoint = `tickets/${ticketId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      params,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};
