import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const CertifierProfileContainer = styled.div`
  .certifier-profile {
    display: flex;
    justify-content: space-between;
  }
  .certifier-profile-left  {
    width: 450px;
    margin-right: 60px;
  }
  .certifier-profile-right {
    flex: auto;
  }
  /* Right */
  .certifier-profile-left-logo {
    width: 100%;
    background: #002840;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    margin-bottom: 25px;
    overflow: hidden;
    &__item {
      width: 100%;
      min-height: 150px;
      display: flex;
      max-height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
  .certifier-profile-left-delete {
    margin-bottom: 76px;
    display: flex;
    justify-content: flex-end;
    &__item {
      width: 120px;
    }
  }
  .certifier-profile-left-section {
    margin-bottom: 72px;
  }
  .certifier-profile-left-section-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    border-bottom: 1px solid ${colors['NE-002/100']};
    padding-bottom: 8px;
    &__text {
      color: ${colors['NE-002/100']};
      text-transform: uppercase;
    }
    &__button {
      color: ${colors['PR-001/100']};
      cursor: pointer;
    }
  }

  /* Left */

  .modal-cancelation-policy-languages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;
  }
  .my-center-profile-description-right-languages-title {
    margin-bottom: 12px;
    display: flex;

    &__text {
      color: #bcc5d7;
      padding-left: 10px;
      position: relative;

      text-transform: uppercase;
      /* &::before {
        content: '';
        width: 4px;
        height: 4px;
        background: ${colors['PR-001/100']};
        border-radius: 100%;
        position: absolute;
        left: 0px;
        top: 8px;
      } */
    }
  }
  .certifier-profile-right-data-save {
    margin-bottom: 40px;
    max-width: 120px;
  }
  .certifier-profile-right-data-dicotomic {
    display: flex;
    margin-bottom: 24px;
    &__text {
      margin-right: 28px;
    }
    &__check  {
      margin-right: 20px;
    }
  }
  .certifier-profile-left-section-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    transition: all 0.2s;
    &__file {
      margin-right: 20px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &__title {
        margin-bottom: 4px;
        font-size: 16px;
        color: ${colors['NE-001/100']};
      }

      &__name {
        margin-bottom: 2px;
        font-size: 12px;
        color: ${colors['NE-001/50']};
        font-weight: 100;
      }

      &__date {
        font-size: 10px;
        color: ${colors['NE-001/50']};
        font-weight: 100;
      }
    }
    &__delete {
      margin-left: 260px;
    }
  }
  .certifier-profile-left-section-content:hover {
    cursor: pointer;
    background: ${colors['SE-004/10']};
  }
`;
