import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ModalContainer from '../../../../../components/modals/modal-container';
import { Booking } from '../../../../../models/bookings';
import { ModalBookingDetailBodyStyle } from './modal-booking-detail-style';
import { images } from '../../../../../assets/images';
import Button from '../../../../../components/buttons/main-button/button';
import { getDayOfMonth } from '../../../../../utils/time';

interface IModalBookingDetailProps {
  showModal: boolean;
  modalClose: Function;
  booking: Booking;
}

const ModalBookingDetail: React.FC<IModalBookingDetailProps> = ({ booking, showModal, modalClose }) => {
  const { t } = useTranslation(['modalMessages', 'layouts']);
  const {
    id,
    bookingId,
    createdAt,
    activity,
    discipline,
    activityDate,
    numDivers,
    price,
    status,
  } = booking;
  const formattedDate = getDayOfMonth(t, createdAt);
  const paymentType = '';
  const bookingType = '';
  const prePaidAmount = '';
  const pendingAmount = '';
  const otherDetails = Object.entries({ paymentType, bookingType, prePaidAmount, pendingAmount, price });

  const otherDetailsMap = otherDetails.map(([key, value]) => (
    <div className="booking-other-details__row" key={key}>
      <div className="booking-other-details__detail-title">{t(`layouts:bookings.booking.${key}`)}</div>
      <div className="booking-other-details__detail-value">{value}</div>
    </div>
  ));

  const statusClasses = classNames('booking-status', {
    'booking-status--did-not-show': status === t('layouts:bookings.status.didNotShow'),
    'booking-status--canceled-diver': status === t('layouts:bookings.status.canceledDiver'),
    'booking-status--canceled-center': status === t('layouts:bookings.status.canceledCenter'),
    'booking-status--finished': status === t('layouts:bookings.status.finished'),
  });

  let btns: any[];
  switch (status) {
    case t('layouts:bookings.status.didNotShow'):
      btns = [
        { text: t('modalMessages:modal-booking-detail.buttons.seeReceipt'), type: 'primary' },
        { text: t('modalMessages:modal-booking-detail.buttons.refundPayment') },
      ];
      break;
    case t('layouts:bookings.status.canceledCenter'):
      btns = [{ text: t('modalMessages:modal-booking-detail.buttons.seeReturnProof'), type: 'primary' }];
      break;
    case t('layouts:bookings.status.canceledDiver'):
      btns = [{ text: t('modalMessages:modal-booking-detail.buttons.seeProof'), type: 'primary' }];
      break;
    case t('layouts:bookings.status.finished'):
      btns = [
        { text: t('modalMessages:modal-booking-detail.buttons.seeReceipt'), type: 'primary' },
        { text: t('modalMessages:modal-booking-detail.buttons.refundPayment') },
      ];
      break;
    default:
      btns = [];
      break;
  }

  const buttons = btns.map(btn => (
    <Button
      key={btn.text}
      text={btn.text}
      type={btn.type ? btn.type : ''}
      onClick={btn.onClick ? btn.onClick : () => { }}
    />
  ));

  const modalBody = (
    <ModalBookingDetailBodyStyle>
      <div className={statusClasses}>{status}</div>
      <div className="booking-title">{activity}</div>
      <div className="booking-number">{`${t('layouts:bookings.booking.booking')} Nº ${id}`}</div>
      <div className="booking-date-box">
        <div>
          <img src={images.calendarSvg} alt="calendar icon" className="bookings-icon" />
          <span>{formattedDate.charAt(0).toUpperCase() + formattedDate.substr(1)}</span>
        </div>
        <div>
          <img src={images.clockSvg} alt="clock icon" className="bookings-icon" />
          <span>hour</span>
        </div>
      </div>
      <div className="booking-diver">
        <div className="booking-diver__title">{t('layouts:bookings.booking.diverData')}</div>
        <div className="booking-diver__data">
          <img src={images.ellipse} alt="diver img" />
          <div className="booking-diver__info">
            <div className="booking-diver__name">diverName</div>
            <div className="booking-diver__id">diverID</div>
          </div>
        </div>
      </div>
      <div className="booking-other-details">{otherDetailsMap}</div>
      <div className="booking-buttons">{buttons}</div>
    </ModalBookingDetailBodyStyle>
  );

  return <ModalContainer active={showModal} modalClose={modalClose} children={modalBody} />;
};

export default ModalBookingDetail;
