import styled from 'styled-components';

export const BonusesStyle = styled.div`
  .bonus-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 40px;
    &__filters {
      display: flex;

      &__item {
        margin-right: 12px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .bonus-table {
    margin-bottom: 80px;
  }
`;
