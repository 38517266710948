import React, { Component } from 'react';
import { InputBoxContainer } from './input-box-style';
import { images } from '../../assets/images';
import TooltipBox from '../tooltip-box/tooltip-box';
import { ERROR_MESSAGES_ES } from '../../constants/error-messages';
import { isNumeric, isValidEmail, isValidDocumentID } from '../../utils/validation';
import TextText from '../text/text-text/text-text';
import LabelText from '../text/label-text/label';
import InfoText from '../text/info-text/info-text';
import Cleave from 'cleave.js/react';

export interface InputBoxProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  iconClick?: any;
  errorCode?: any;
  type?: 'text' | 'number' | 'email' | 'password' | 'NIF' | 'NIE' | 'PAS' | 'CHILD';
  required?: boolean;
  placeholder?: string;
  filledValue?: string;
  value: string;
  labelText: string;
  onChange?: (value: string, error?: boolean) => void;
  onBlur?: (value: string, error?: boolean) => void;
  onKeyUp?: (event: any) => void;
  onKeyDown?: (event: any) => void;
  clearValue?: boolean;
  autocompleteOff?: boolean;
  withTooltip?: boolean;
  isTooltipInside?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
  max?: number;
  min?: number;
  cleaveOptions?: any;
  adviceText?: string;
}

interface InputBoxState {
  prevInputValue: string;
  focus: boolean;
  showTooltip: boolean;
  error: string;
  inputValue: string;
  isFocus: boolean;
}

class InputBox extends Component<InputBoxProps, {}> {
  state: InputBoxState = {
    prevInputValue: '',
    focus: false,
    showTooltip: false,
    error: '',
    inputValue: '',
    isFocus: false,
  };

  componentDidMount() {
    const { value } = this.props;
    if (value !== '') {
      this.setState({ inputValue: value });
    }
  }

  componentDidUpdate(prevProps: InputBoxProps) {
    const { filledValue, value, type, clearValue } = this.props;
    const { inputValue, prevInputValue } = this.state;
    if (filledValue && filledValue !== '' && filledValue !== inputValue) {
      this.setState({ inputValue: filledValue });
    } else if (value !== '' && inputValue === '' && prevInputValue === '') {
      this.setState({ inputValue: value });
    } else if (type === 'CHILD' && value === '-' && inputValue !== value) {
      this.setState({ inputValue: '-' });
    } else if (clearValue) {
      this.setState({ inputValue: '' });
    } else if (prevProps.value !== value && value === '') {
      this.setState({ inputValue: '' });
    } else if (value && value !== prevProps.value) {
      this.setState({ inputValue: value });
    }
  }

  handleBlur(value: string) {
    const { onBlur } = this.props;
    onBlur ? onBlur(value) : '';
    this.setState({ isFocus: false });
  }

  handleChange(value: string) {
    const { onChange, type, max, min } = this.props;
    if (type && type === 'number') {
      const valueFloat = parseFloat(value);
      if (max && valueFloat > max) return;
      if (min !== undefined && valueFloat < min) return;
    }
    onChange ? onChange(value) : '';
    const { inputValue } = this.state;
    this.setState({ inputValue: value, prevInputValue: inputValue });
  }
  handleFocus(e: any) {
    this.setState({ isFocus: true });
  }

  render() {
    const {
      disabled,
      className,
      icon,
      iconDisabled,
      required,
      autocompleteOff,
      labelText,
      type,
      value,
      max,
      filledValue,
      placeholder,
      errorCode,
      withTooltip,
      isTooltipInside,
      tooltipTitle,
      tooltipText,
      cleaveOptions,
      onKeyUp,
      onKeyDown,
      adviceText,
    } = this.props;

    const { showTooltip, inputValue, isFocus } = this.state;
    const errorText = errorCode ? ERROR_MESSAGES_ES[errorCode] : '';
    const actualValue = inputValue === '-' && type === 'CHILD' ? '' : inputValue;

    return (
      <InputBoxContainer
        className={`${className} ${disabled ? 'disabled' : ''}
        ${errorCode ? ' error' : ''} ${isFocus && 'focus'}
        ${required ? ' required' : ''} ${showTooltip ? 'tooltip' : ''}`}
      >
        <div className="input-box-topbar">
          {labelText !== '' && (
            <div className="input-box-topbar-label">
              <LabelText>{labelText}</LabelText>
            </div>
          )}
          {withTooltip && !isTooltipInside && (
            <div
              className={`input-box-topbar-icon ${showTooltip ? 'active' : ''}`}
              onClick={(ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                this.setState((prevState: InputBoxState) => ({ showTooltip: !prevState.showTooltip }));
              }}
            >
              <img src={disabled ? images.infoGreySvg : images.infoBlueSvg} />
              <div className="input-box-tooltip">
                <TooltipBox
                  setActive={(active: boolean) => this.setState({ showTooltip: active })}
                  active={showTooltip}
                  tooltipTitle={tooltipTitle}
                  tooltipText={tooltipText}
                />
              </div>
            </div>
          )}
        </div>

        <div className="input-box-main">
          <div className={'input-box-main-field'}>
            {!cleaveOptions ? (
              <input
                type={type !== 'text' ? type : 'text'}
                autoComplete={autocompleteOff ? 'nope' : ''}
                value={actualValue}
                maxLength={max ? max : 200}
                onFocus={e => this.handleFocus(e)}
                onBlur={e => {
                  if (!disabled) {
                    this.handleBlur(e.target.value);
                  }
                }}
                onChange={e => {
                  if (!disabled) {
                    this.handleChange(e.target.value);
                  }
                }}
                onKeyUp={e => {
                  if (!disabled && onKeyUp) onKeyUp(e);
                  // if (e.which === 13) this.setState({ inputValue: '' });
                }}
                onKeyDown={e => {
                  if (!disabled && onKeyDown) onKeyDown(e);
                }}
                readOnly={disabled}
                placeholder={placeholder}
              />
            ) : (
              <Cleave
                type={type !== 'text' ? type : 'text'}
                autoComplete={autocompleteOff ? 'nope' : ''}
                value={actualValue}
                onFocus={e => this.handleFocus(e)}
                onBlur={e => {
                  if (!disabled) {
                    this.handleBlur(e.target.value);
                  }
                }}
                onChange={e => {
                  if (!disabled) {
                    this.handleChange(e.target.value);
                  }
                }}
                readOnly={disabled}
                placeholder={placeholder}
                options={cleaveOptions}
              />
            )}
          </div>

          <div
            className="input-box-icon"
            onClick={(ev: any) => {
              ev.preventDefault();
              ev.stopPropagation();
              if (withTooltip && isTooltipInside) {
                this.setState((prevState: InputBoxState) => ({
                  showTooltip: !prevState.showTooltip,
                }));
              }
            }}
          >
            <img onClick={() => this.props.iconClick && this.props.iconClick()} src={disabled ? iconDisabled : icon} />

            {withTooltip && isTooltipInside && (
              <div className="input-box-tooltip">
                <TooltipBox
                  setActive={(active: boolean) => this.setState({ showTooltip: active })}
                  dark={true}
                  active={showTooltip}
                  tooltipTitle={tooltipTitle}
                  tooltipText={tooltipText}
                />
              </div>
            )}
          </div>
        </div>
        {adviceText && !errorCode && (
          <div className="input-box-advice">
            <InfoText>{adviceText}</InfoText>
          </div>
        )}
        <div className="input-box-error">{!disabled && errorCode && <InfoText>{`${errorText}`}</InfoText>}</div>
      </InputBoxContainer>
    );
  }
}

export default InputBox;
