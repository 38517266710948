import styled, { css } from 'styled-components';
import { mediaqueries } from '../constants/mediaqueries';
import { CheckboxStyle } from '../components/form-components/checkbox/checkbox-style';
import { colors } from '../assets/colors';

export const MainLayout = styled.div`
  display: flex;
  min-height: 100vh;
`;
export const MainLayoutContent = styled.div`
  width: 100%;
  padding-left: 274px;
  /* padding-left: 204px; */
  position: relative;
`;

export const MainLayoutSection = styled.div`
  padding: 24px 40px 58px 48px;
  min-height: calc(100vh - 120px);
  .canceledActivity {
    color: red;
    size: 16px;
    padding: 10px;
    width: 100%;
  }
`;

export const MainLayoutTableContainer = styled.div`
  .file-extension-icon {
    & > div {
      width: max-content;
    }
  }
  .diving-center-table {
    margin-bottom: 60px;
  }
  .diving-center-pagination {
    margin-bottom: 20px;
  }
`;

// to see usage go to the bills-superadmin-layout file
export const MainLayoutWithHeaderSearch = styled.div`
  .main-layout-table {
    margin-bottom: 80px;
  }

  .main-layout-title {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'text filters';
    align-items: center;
    margin-bottom: 40px;

    &__result-message {
      font-size: 12px;
      color: ${colors['CO-004/100']};
    }

    &__filters {
      grid-area: filters;
      display: flex;
      justify-self: flex-end;

      & .checkbox-list {
        min-width: max-content;
      }
    }

    &__filter-item {
      margin-right: 12px;
      &:last-child {
        margin-right: 0px;
      }

      ${CheckboxStyle} {
        .checkbox-item--icon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  .checkbox-list__items-container {
    max-height: 155px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .search-empty {
    & input {
      color: ${colors['CO-004/100']} !important;
    }
  }

  .main-layout-status-icon {
    width: 30px;
  }
`;

export const FormGridItem = styled.div`
  height: 100%;

${props =>
  props.type === 'w-1/3' &&
  css`
    width: 32%;
    ${mediaqueries['max-width768']} {
      width: 100%;
      &:last-of-type {
        margin-bottom: 24px;
      }
    }
  `}

${props =>
  props.type === 'col-0' &&
  css`
    width: 100%;
  `}
${props =>
  props.type === 'col-1' &&
  css`
    width: calc(((100% - 50px) / 4) * 3);
  `}
${props =>
  props.type === 'col-2' &&
  css`
    width: calc((100% - 25px) / 2);
    ${mediaqueries['max-width768']} {
      width: 100%;
    }
  `}
${props =>
  props.type === 'col-3' &&
  css`
    width: calc((100% - 50px) / 3);
  `}
${props =>
  props.type === 'col-4' &&
  css`
    width: calc((100% - 75px) / 4);
  `}
${props =>
  props.type === 'col-5' &&
  css`
    width: calc((100% - 100px) / 5);
  `}
${props =>
  props.type === 'col-6' &&
  css`
    width: calc((100% - 125px) / 6);
  `}
  ${mediaqueries['max-width768']} {
    flex-wrap:wrap;
  }
${props =>
  props.type === 'button' &&
  css`
    width: calc((100% - 125px) / 6);
    min-width: 110px;
  `}
  ${mediaqueries['max-width768']} {
    flex-wrap:wrap;
  }
`;

export const FormGridRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 12px;

  ${props =>
    props.type === 'row-wrap' &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
    `}

  ${mediaqueries['max-width768']} {
    flex-wrap: wrap;
  }
  ${mediaqueries['max-width768']} {
    margin-bottom: 0px;
  }
`;
export const FormGrid = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormSpacer = styled.div`
  margin-bottom: 40px;
`;

export const CenterAbsoluteMixin = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
