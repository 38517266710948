export const COURSE_KINDS_DROPDOWN: any = [
  { label: 'Kids', value: 'kids' },
  { label: 'Mermaid Kids', value: 'mermaidKids' },
  { label: 'Experiencia de descubrimiento', value: 'discover' },
  { label: 'Curso de Nivel', value: 'levelCourse' },
  { label: 'Especialidad', value: 'specialty' },
  { label: 'Repaso', value: 'review' },
  { label: 'Seguridad y apoyo', value: 'security' },
  { label: 'Buceo adaptado', value: 'adapteddiving' },
  { label: 'Mermaid', value: 'mermaid' },
];

export const COURSE_KINDS_KEY_VALUES: any = {
  kids: 'Kids',
  mermaidKids: 'Mermaid Kids',
  discover: 'Experiencia de descubrimiento',
  levelCourse: 'Curso de Nivel',
  specialty: 'Especialidad',
  review: 'Repaso',
  security: 'Seguridad y apoyo',
  adapteddiving: 'Buceo adaptado',
  mermaid: 'Mermaid',
};
