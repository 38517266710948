import services from './services';

export const getCategories = async (params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'categories',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getOneCategory = (categoryId: string, params?: any) => {
  const endpoint = `categories/${categoryId}`;
  return new Promise<any>((resolve, reject) => {
    const args = {
      params,
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};


export const deletCategory = (categoryId: string) => {
  const endpoint = `categories/${categoryId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createCategory = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'categories',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
