import * as React from 'react';
import { ItemMailTemplateStyle } from './item-mail-template-style';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import SingleButton from '../single-button/single-button';
import { images } from '../../assets/images';
import { withRouter, RouteComponentProps } from 'react-router';
import { ITemplateEmail } from '../../models/template-emails';

export interface IItemMailTemplateProps extends RouteComponentProps {
  number: string;
  title: string;
  emailLanguages: ITemplateEmail;
  templateName: string;
}

export interface IItemMailTemplateState { }

class ItemMailTemplate extends React.Component<IItemMailTemplateProps, IItemMailTemplateState> {
  constructor(props: IItemMailTemplateProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { number, title, emailLanguages, templateName } = this.props;
    return (
      <ItemMailTemplateStyle>
        <div className="item-mail-temp-number">
          <BodyDestacadoText>{number}.</BodyDestacadoText>
        </div>
        <div className="item-mail-temp-content">
          <div className="item-mail-temp-content__title">
            <BodyDestacadoText>{title}</BodyDestacadoText>
          </div>
          <div className="item-mail-temp-content__languages">
            <div
              className={`item-mail-temp-content__languages__item black ${emailLanguages.de.active ? '' : 'disabled'}`}
            >
              <p>DE</p>
            </div>
            <div
              className={`item-mail-temp-content__languages__item yellow ${emailLanguages.es.active ? '' : 'disabled'}`}
            >
              <p>ES</p>
            </div>
            <div
              className={`item-mail-temp-content__languages__item green ${emailLanguages.pt.active ? '' : 'disabled'}`}
            >
              <p>PT</p>
            </div>
            <div
              className={`item-mail-temp-content__languages__item red ${emailLanguages.it.active ? '' : 'disabled'}`}
            >
              <p>IT</p>
            </div>
            <div
              className={`item-mail-temp-content__languages__item  light-blue ${
                emailLanguages.fr.active ? '' : 'disabled'
                }`}
            >
              <p>FR</p>
            </div>
            <div
              className={`item-mail-temp-content__languages__item blue ${emailLanguages.en.active ? '' : 'disabled'}`}
            >
              <p>EN</p>
            </div>
          </div>
          <div className="item-mail-temp-content__detail">
            <SingleButton
              icon={images.edit4Svg}
              onClick={() => this.props.history.push(`/configuration/mail-template/detail/${templateName}`)}
            />
          </div>
        </div>
      </ItemMailTemplateStyle>
    );
  }
}

export default withRouter(ItemMailTemplate);
