import styled from 'styled-components';

export const CertifierProjectDetailStyle = styled.div`
  .certifier-project-detail-top{
    display: flex;
    margin-bottom: 76px;
    justify-content: space-between;
  }
  .certifier-project-detail-top-left{
    width: 450px;
    border-radius: 6px;
    margin-right: 60px;
    overflow: hidden;
    max-height: 600px;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .certifier-project-detail-top-right{
    flex: auto;
    &__title{
      margin-bottom: 40px;
    }
  }
  .certifier-project-detail-top-right-info {
    &__item {
      display: flex;
      margin-bottom: 12px;
      &__title {
        width: 240px;
        margin-right: 20px;
      }
      &__text {
      }
    }
  }

  .certifier-project-detail-description {
    margin-bottom: 80px;
  }
  .certifier-project-detail-description-title  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    &__text {
      margin-top: 12px;
    }
  }
  .certifier-project-detail-description-text  {
  }
  .certifier-project-detail-table  { 
  }
  .certifier-project-detail-table-title  { 
    display: flex;
    margin-bottom: 50px;
  }
`;
