export const mediaqueries = {
  "max-width1440": "@media (max-width: 1440px)",
  "max-width1140": "@media (max-width: 1140px)",
  "max-width1024": "@media (max-width: 1024px)",
  "max-width1000": "@media (max-width: 1000px)",
  "max-width900": "@media (max-width: 900px)",
  "max-width768": "@media (max-width: 768px)",
  "max-width600": "@media (max-width: 600px)",
  "max-width425": "@media (max-width: 425px)",
  "max-width375": "@media (max-width: 375px)",
  "max-width370": "@media (max-width: 370px)"
};
