import services from './services';

export const getDiveSitesPictures = async (params: any) => {
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'dive-site-pictures',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
}

export const getOneDiveSitesPictures = (pictureId: string) => {
  const endpoint = `dive-site-pictures/${pictureId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadDiveSitesPictures = (pictureId: string, data: any) => {
  const endpoint = `dive-site-pictures/${pictureId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const deleteDiveSitesPictures = (pictureId: string) => {
  const endpoint = `dive-site-pictures/${pictureId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createDiveSitesPictures = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'dive-site-pictures',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};