import * as React from 'react';
import ModalContainer from '../../../components/modals/modal-container';
import { ModalPlanningActivityStyle } from './modal-planning-activity-style';
import LabelText from '../../../components/text/label-text/label';
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import CardCheck from '../../../components/card-check/card-check';
import MainButton from '../../../components/main-button';
import DicotomicCheckbox from '../../../components/dicotomic-checkbox';
import InfoText from '../../../components/text/info-text/info-text';
import PlanningChooseTemplate from './planning-choose-template/planning-choose-template';
import PlanningDiveSite, { PlanningDiveSiteState } from './planning-dive-site/planning-dive-site';
import PlanningStaff, { PlanningStaffState } from './planning-staff/planning-staff';
import PlanningDate, { PlanningDateState } from './planning-date/planning-date';
import PlanningCourseDate, { PlanningCourseDateState } from './planning-course-date/planning-course-date';
import PlanningPrice, { PlanningPriceState } from './planning-price/planning-price';
import PlanningPreferences, { PlanningPreferencesState } from './planning-preferences/planning-preferences';
import PlanningInvite from './planning-invite/planning-invite';
import { IActivityTemplate } from '../../../models/activity-template';
import { IId, ITimeBlocks, ITimeBlock } from '../../../models/generic';
import { createActivities } from '../../../services/activities';
import { getActivityTemplate } from '../../../services/activity-templates';
import { ICourseActivityInfo } from '../../../models/activities';
import { Router } from 'react-router';

export interface IModalPlanningActivityProps {
  centreId: string;
  activityId?: string;
  showModal: boolean;
  onCancel: Function;
  onSuccess: (id?: number) => void;
  onCreateActivity: (activitiesType: string) => void;
  onRefresh?: () => void;
}

export interface IModalPlanningActivityState {
  step: number;
  activitiesType: string;
  isNewDive: boolean;
  selectedTemplate?: IActivityTemplate;
  sites: IId[];
  staffs: IId[];
  price: number;
  bookBefore: string;
  inmediateBooking: boolean;
  inmediateConfirmation: boolean;
  timeBlocks: ITimeBlocks[];
  states: {
    site?: PlanningDiveSiteState;
    staff?: PlanningStaffState;
    date?: PlanningDateState;
    dateCourse?: PlanningCourseDateState;
    price?: PlanningPriceState;
    preferences?: PlanningPreferencesState;
  };
  dates?: string[];
  periodicity?: { periodicity: string; date: string; end: string };
  activityId: string;
  courseActivityInfo: ICourseActivityInfo;
  startDate: string;
}

export default class ModalPlanningActivity extends React.Component<
  IModalPlanningActivityProps,
  IModalPlanningActivityState
> {
  constructor(props: IModalPlanningActivityProps) {
    super(props);

    this.state = {
      step: 0,
      sites: [],
      isNewDive: false,
      price: NaN,
      staffs: [],
      activitiesType: 'dive',
      bookBefore: '',
      inmediateBooking: false,
      inmediateConfirmation: false,
      timeBlocks: [],
      states: {},
      startDate: '',
      activityId: '',
      courseActivityInfo: {
        theoreticSessions: [],
        practicalSessions: [],
      },
    };
  }

  componentDidMount() {
    const { activityId } = this.props;
    if (activityId) {
      this.getActivityTemplate();
    }
  }

  async createActivity() {
    const { centreId } = this.props;
    const {
      selectedTemplate,
      sites,
      staffs,
      inmediateConfirmation,
      dates,
      timeBlocks,
      price,
      inmediateBooking,
      bookBefore,
      periodicity,
    } = this.state;
    if (!selectedTemplate) return;
    const activity: any = {
      bookBefore,
      inmediateBooking,
      price,
      timeBlocks,
      inmediateConfirmation,
      sites,
      category: selectedTemplate.category ? selectedTemplate.category.id : null,
      centre: parseInt(centreId, undefined),
      template: selectedTemplate.id,
    };
    if (staffs.length > 0) {
      activity.staff = staffs.map(staff => ({ staff: staff.id, confirmed: inmediateConfirmation }));
    }
    if (dates) activity.dates = dates;
    let resp: any;
    if (periodicity) activity.periodicity = periodicity;
    if ((periodicity && periodicity.periodicity === 'custom') || !periodicity) {
      const activityFormatted = {
        ...activity,
        timeBlocks: activity.timeBlocks.map((t: any) => ({
          ...t,
          end: { ...t.end, date: t.end.date.toDateString() },
          start: { ...t.start, date: t.start.date.toDateString() },
        })),
      };
      resp = await createActivities(activityFormatted);

    } else {
      resp = await createActivities(activity);

    }

    let activityId = null;
    if (resp.data.id) {
      activityId = resp.data.id.toString();
    } else if (resp.data[0].id) {
      activityId = resp.data[0].id.toString();
    }

    if (activityId) {
      const url = "../../../../centres/" + resp.data.centre + "/planning/calendar/" + activityId;
      window.location.href = url;
      //Router.
      //this.setState({ activityId, step: 6 });
    }
  }

  async createActivityTraining() {
    const { centreId } = this.props;
    const {
      selectedTemplate,
      sites,
      staffs,
      inmediateConfirmation,
      dates,
      timeBlocks,
      price,
      inmediateBooking,
      bookBefore,
      courseActivityInfo,
    } = this.state;
    if (!selectedTemplate) return;
    const activity: any = {
      bookBefore,
      inmediateBooking,
      price,
      timeBlocks,
      inmediateConfirmation,
      sites,
      category: selectedTemplate.category ? selectedTemplate.category.id : null,
      centre: parseInt(centreId, undefined),
      template: selectedTemplate.id,
    };
    if (staffs.length > 0) {
      activity.staff = staffs.map(staff => ({ staff: staff.id, confirmed: inmediateConfirmation }));
    }
    if (dates) activity.dates = dates;
    if (courseActivityInfo) activity.courseInfo = courseActivityInfo;
    const sitesArr = [];
    for (const index in activity.sites) {
      if(activity.sites[index].id > 0){
        sitesArr.push({ id: activity.sites[index].id})
      }
    }
    activity.sites = sitesArr;



    let resp: any = await createActivities(activity);
    let activityId = null;
    if (resp.data.id) {
      activityId = resp.data.id.toString();
    } else if (resp.data[0].id) {
      activityId = resp.data[0].id.toString();
    }
    if (activityId) {
      const url = "../../../../centres/" + resp.data.centre + "/planning/calendar/" + activityId;
      window.location.href = url;
      //this.setState({ activityId, step: 5 });
    }
  }

  async getActivityTemplate() {
    const { activityId } = this.props;
    if (!activityId) return;
    const params = {
      filter: {
        relations: ['sites', 'category'],
      },
    };
    const resp = await getActivityTemplate(activityId, params);
    const selectedTemplate: IActivityTemplate = resp.data;
    this.setState({ selectedTemplate, step: 2 });
  }

  handlePrev() {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  handleNext(fields: string[], values: any[]) {
    const state = Object.assign(this.state);
    values.map((value, index) => {
      state[fields[index]] = value;
    });
    state.step += 1;
    return new Promise((res, rej) => {
      this.setState(state, () => {
        res('');
      });
    });
  }

  handleChangeState(field: string, value: any) {
    const { states } = Object.assign(this.state);
    states[field] = value;
    return new Promise((res, rej) => {
      this.setState({ states }, () => {
        res('');
      });
    });
  }

  renderStep() {
    const { centreId, activityId, onSuccess } = this.props;
    const { step, activitiesType, selectedTemplate, courseActivityInfo, dates, states } = this.state;

    if (selectedTemplate && selectedTemplate.courseInfo) {
      switch (step) {
        case 0:
          return <p>Hola</p>;

        case 1:
          return (
            <PlanningChooseTemplate
              centreId={centreId}
              isDive={activitiesType === 'dive'}
              onNext={(selectedTemplate: IActivityTemplate) => {
                this.handleNext(['selectedTemplate'], [selectedTemplate]);
              }}
            />
          );

        case 2:
          return (
            <PlanningStaff
              centreId={centreId}
              state={states.staff}
              onNext={(state: PlanningStaffState, staffs: IId[], inmediateConfirmation: boolean) =>
                this.handleChangeState('staff', state).then(() => {
                  this.handleNext(['staffs', 'inmediateConfirmation'], [staffs, inmediateConfirmation]);
                })
              }
            //onPrev={() => this.handlePrev()}
            />
          );

        case 3:
          return (
            <PlanningCourseDate
              state={states.dateCourse}
              courseTemplateInfo={selectedTemplate.courseInfo}
              courseActivityInfo={courseActivityInfo}
              startDate={dates && dates.length ? dates[0] : ''}
              centreId={centreId}
              onNext={(
                state: PlanningCourseDateState,
                dates: string[],
                courseActivityInfo: ICourseActivityInfo,
                sites: IId[]
              ) => {
                this.handleChangeState('date', state).then(() => {
                  this.handleNext(['dates', 'courseActivityInfo', 'sites'], [dates, courseActivityInfo, sites]);
                });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 4:
          return (
            <PlanningPrice
              state={states.price}
              price={selectedTemplate ? selectedTemplate.price : NaN}
              onNext={(state: PlanningPriceState, price: number) => {
                this.handleChangeState('price', state).then(() => {
                  this.handleNext(['price'], [price]);
                });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 5:
          return (
            <PlanningPreferences
              isDive={activitiesType === 'dive'}
              date={states.date}
              state={states.preferences}
              onNext={(state: PlanningPreferencesState, bookBefore: string, inmediateBooking: boolean) => {
                this.handleChangeState('preferences', state).then(() => {
                  this.handleNext(['bookBefore', 'inmediateBooking'], [bookBefore, inmediateBooking]).then(() => {
                    this.createActivityTraining().then(() => {
                      onSuccess(parseInt(this.state.activityId));

                    });
                  });
                });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        /*         case 6:
                  return (
                    <PlanningInvite
                      centreId={centreId}
                      activityId={activityId || this.state.activityId}
                      onNext={() => onSuccess()}
                    />
                  ); */
      }
    } else if (selectedTemplate && !selectedTemplate.courseInfo) {
      switch (step) {
        case 0:
          return <p>Hola</p>;

        case 1:
          return (
            <PlanningChooseTemplate
              centreId={centreId}
              isDive={activitiesType === 'dive'}
              onNext={(selectedTemplate: IActivityTemplate) => {
                this.handleNext(['selectedTemplate'], [selectedTemplate]);
              }}
            />
          );

        case 2:
          return (
            <PlanningDiveSite
              centreId={centreId}
              selectedTemplate={selectedTemplate}
              state={states.site}
              onNext={(state: PlanningDiveSiteState, sites: IId[]) => {
                this.handleChangeState('site', state).then(() => {
                  this.handleNext(['sites'], [sites]);
                });
              }}
            />
          );

        case 3:
          return (
            <PlanningStaff
              centreId={centreId}
              state={states.staff}
              onNext={(state: PlanningStaffState, staffs: IId[], inmediateConfirmation: boolean) =>
                this.handleChangeState('staff', state).then(() => {
                  this.handleNext(['staffs', 'inmediateConfirmation'], [staffs, inmediateConfirmation]);
                })
              }
              onPrev={() => this.handlePrev()}
            />
          );

        case 4:
          return (
            <PlanningDate
              isDive={activitiesType === 'dive'}
              state={states.date}
              onNext={(
                state: PlanningDateState,
                timeBlocks: ITimeBlocks[],
                dates?: string[],
                periodicity?: { periodicity: string; date?: string; end?: string }
              ) => {
                this.handleChangeState('date', state).then(() => {
                  this.handleNext(['timeBlocks', 'dates', 'periodicity'], [timeBlocks, dates, periodicity]);
                });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 5:
          return (
            <PlanningPrice
              state={states.price}
              price={selectedTemplate ? selectedTemplate.price : NaN}
              onNext={(state: PlanningPriceState, price: number) => {
                this.handleChangeState('price', state).then(() => {
                  this.handleNext(['price'], [price]);
                });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        case 6:
          return (
            <PlanningPreferences
              isDive={activitiesType === 'dive'}
              date={states.date}
              state={states.preferences}
              onNext={(state: PlanningPreferencesState, bookBefore: string, inmediateBooking: boolean) => {
                this.handleChangeState('preferences', state).then(() => {
                  this.handleNext(['bookBefore', 'inmediateBooking'], [bookBefore, inmediateBooking]).then(() => {
                    this.createActivity();
                    onSuccess();
                  });
                });
              }}
              onPrev={() => this.handlePrev()}
            />
          );

        /*case 7:
          return (
            <PlanningInvite
              centreId={centreId}
              activityId={this.state.activityId}
              onNext={() => onSuccess()}
            />
          );*/
      }
    }
    else {
      switch (step) {
        case 0:
          return <p>Hola</p>;

        case 1:
          return (
            <PlanningChooseTemplate
              centreId={centreId}
              isDive={activitiesType === 'dive'}
              onNext={(selectedTemplate: IActivityTemplate) => {
                this.handleNext(['selectedTemplate'], [selectedTemplate]);
              }}
            />
          );

        case 2:
          return (
            <PlanningDiveSite
              centreId={centreId}
              selectedTemplate={selectedTemplate}
              state={states.site}
              onNext={(state: PlanningDiveSiteState, sites: IId[]) => {
                this.handleChangeState('site', state).then(() => {
                  this.handleNext(['sites'], [sites]);
                });
              }}
            />
          );
      }
    }
  }

  getTitle() {
    const { step, selectedTemplate } = this.state;
    if (selectedTemplate && selectedTemplate.courseInfo) {
      switch (step) {
        case 0:
          return '¿Qué tipo de actividad quieres planificar?';
        case 1:
          return 'Elige la actividad a planificar';
        case 2:
          return '1. Staff';
        case 3:
          return '2. Planificación de las fases';
        case 4:
          return '3. Precio';
        case 5:
          return '4. Preferencias en las reservas';
        /*case 5:
          return '¡Genial, acabas de planificar una actividad!';*/
        default:
      }
    } else {
      switch (step) {
        case 0:
          return '¿Qué tipo de actividad quieres planificar?';
        case 1:
          return 'Elige la actividad a planificar';
        case 2:
          return '1. Puntos de inmersión';
        case 3:
          return '2. Staff';
        case 4:
          return '3. Planificación';
        case 5:
          return '4. Precio';
        case 6:
          return '5. Preferencias en las reservas';
        /*case 6:
          return '¡Genial, acabas de planificar una actividad!';*/
        default:
      }
    }
  }

  render() {
    const { onCancel, showModal, onCreateActivity } = this.props;
    const { step, activitiesType, isNewDive } = this.state;
    return (
      <ModalContainer className="" modalClose={() => onCancel()} active={showModal}>
        <ModalPlanningActivityStyle>
          <div className="modal-create-activities-title">
            <div className="modal-create-activities-title__step">
              <LabelText>Planificar actividad</LabelText>
            </div>
            <div className="modal-create-activities-title">
              <SubheadDestacadoText>{this.getTitle()}</SubheadDestacadoText>
            </div>
          </div>

          <div className={'modal-create-activities-container'}>
            {step === 0 ? (
              <>
                <div className={'modal-create-activities-type'}>
                  <div
                    className={'modal-create-activities-type__item'}
                    onClick={() => this.setState({ activitiesType: 'dive' })}
                  >
                    <CardCheck type="dark-blue" text="Inmersión" title="" isActive={activitiesType === 'dive'} />
                  </div>
                  <div
                    className={'modal-create-activities-type__item'}
                    onClick={() => this.setState({ activitiesType: 'course' })}
                  >
                    <CardCheck type="dark-blue" text="Formación" title="" isActive={activitiesType === 'course'} />
                  </div>
                </div>
                <div className={'modal-create-activities-check'}>
                  <div className="modal-create-activities-check__item">
                    <DicotomicCheckbox
                      className="rounded"
                      checked={isNewDive}
                      onCheck={() => this.setState({ isNewDive: true })}
                    >
                      <InfoText>
                        {activitiesType === 'dive'
                          ? 'Crear nueva inmersión y planificarla'
                          : 'Crear nueva actividad de formación y planificarla'}
                      </InfoText>
                    </DicotomicCheckbox>
                  </div>
                  <div className="modal-create-activities-check__item">
                    <DicotomicCheckbox
                      className="rounded"
                      checked={!isNewDive}
                      onCheck={() => this.setState({ isNewDive: false })}
                    >
                      <InfoText>
                        Planificar {activitiesType === 'dive' ? 'inmersión' : 'formación'} desde un modelo existente
                      </InfoText>
                    </DicotomicCheckbox>
                  </div>
                </div>
              </>
            ) : (
              this.renderStep()
            )}
          </div>
          <div className="modal-create-activities-buttons">
            <div className="modal-create-activities-buttons-left">
              {/* {step > 0 && (
                <div className="modal-create-activities-buttons-left__back">
                  <SingleButton
                    className={step < 2 ? 'secondary' : "button-planning-activities"}
                    icon={images.arrowLeftSvg}
                    onClick={() => this.setState({ step: step - 1 })}
                  />
                </div>
              )} */}
              {step === 0 && (
                <div className="modal-create-activities-buttons-left__next">
                  <MainButton
                    text={step === 0 ? 'Planificar' : 'Siguiente'}
                    type="primary"
                    className={step < 2 ? '' : 'button-planning-activities'}
                    onClick={() => {
                      if (step === 0 && isNewDive) {
                        onCreateActivity(activitiesType);
                      } else {
                        this.setState({ step: step + 1 });
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </ModalPlanningActivityStyle>
      </ModalContainer>
    );
  }
}
