import styled from 'styled-components';

export const ContentBlockContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  filter: grayscale(1);
  .content-block-message-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    padding: 100px;
    z-index: 1000;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    p {
      max-width: 600px;
      margin-bottom: 12px;
      text-align: center;
    }
  }

  /* .content-block-message-container {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  } */
`;
