import React, { Component } from 'react';
import { DicotomicButtonContainer } from './dicotomic-button-style';
import MainButton from '../main-button';
import { ERROR_MESSAGES_ES } from '../../constants/error-messages';
import TextText from '../text/text-text/text-text';

interface DicotomicButtonProps {
  labelText?: string;
  errorCode?: string;
  options: {
    code: string;
    label: string;
  }[];
  onChange: Function;
  initialValue?: string;
  disabled?: boolean;
  clearValue?: boolean;
  className?: string;
}

interface DicotomicButtonState {
  value: string;
}

class DicotomicButton extends Component<DicotomicButtonProps, DicotomicButtonState> {
  state = {
    value: '',
  };

  componentDidMount() {
    const { onChange, initialValue } = this.props;

    if (initialValue && initialValue !== '') {
      this.setState({ value: initialValue });
    }
  }

  componentDidUpdate() {
    const { value } = this.state;
    const { initialValue, clearValue } = this.props;
    if (initialValue && initialValue !== value) {
      this.setState({ value: initialValue });
    } else if (clearValue) {
      this.setState({ value: '' });
    }
  }

  handleOnChange(optionText: string) {
    const { onChange } = this.props;
    const { value } = this.state;
    if (optionText !== value) {
      this.setState({ value: optionText }, () => {
        onChange(optionText);
      });
    }
  }

  render() {
    const { value } = this.state;
    const { labelText, options, errorCode, disabled, className } = this.props;

    const errorText = errorCode ? ERROR_MESSAGES_ES[errorCode] : '';

    return (
      <DicotomicButtonContainer className={`${className} ${disabled ? 'disabled' : ''}`}>
        {labelText && (
          <div className="dicotomic-button-label">
            <TextText>{labelText}</TextText>
          </div>
        )}
        <div className="dicotomic-container">
          <label className={`dicotomic-item ${value === options[0].code ? 'active' : ''}`}>
            <MainButton
              text={options[0].label}
              onClick={() => {
                if (!disabled && value !== options[0].code) {
                  this.handleOnChange(options[0].code);
                }
              }}
            />
          </label>
          <label className={`dicotomic-item ${value === options[1].code ? 'active' : ''}`}>
            <MainButton
              text={options[1].label}
              onClick={() => {
                if (!disabled && value !== options[1].code) {
                  this.handleOnChange(options[1].code);
                }
              }}
            />
          </label>
        </div>
        {errorCode && (
          <div className="input-box-error">
            <TextText>{`* ${errorText}`}</TextText>
          </div>
        )}
      </DicotomicButtonContainer>
    );
  }
}

export default DicotomicButton;
