import styled from "styled-components";

export const ModalSuccessContainer = styled.div`
  width: 416px;
  text-align: center;
  .modal-success-pop  {
    width: 100%;
    padding: 60px 50px 60px;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
  }

  .modal-success-img {
    border-radius: 100%;
    overflow: hidden;
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .modal-success-title {
    color: white;
    margin-bottom: 16px;
  }
  .modal-success-text {
    color: white;
    width: 286px;
  }
`;
