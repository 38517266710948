import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CardFacilitiesStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 14px;
  border-radius: 5px;
  border: 1px solid ${colors['SE-004/100']};
  width: 100%;
  height: 100%;
  transition: 0.3s all ease-in-out;
  cursor: pointer;

  .card-facilities-img  {
    margin-bottom: 18px;
    width: 40px;
    height: 40px;
    transition: 0.3s all ease-in-out;
    position: relative;
    &__number  {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colors['SE-004/100']};
    }
  }
  .card-facilities-text  {
    color: ${colors['SE-004/100']};
    text-align: center;
    text-transform: uppercase;
    transition: 0.3s all ease-in-out;
  }

  &.active {
    background: ${colors['SE-004/100']};
    .card-facilities-img  {
      filter: invert(100%) brightness(1000%);
    }
    .card-facilities-text  {
      color: white;
    }
  }
`;
