import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import { MainLayoutSection } from '../../main-layout-style';
import { NavigationTabContainer } from '../../../components/navigation-tabs/navigation-tabs-style';
import DiveSiteDetail from '../dive-site-detail/dive-site-detail';
import DiveSiteGallery from '../dive-site-gallery/dive-site-gallery';
import DiveSiteRate from '../dive-site-rate/dive-site-rate';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import DiveSiteDive from '../dive-site-dives/dive-site-dives';
import { getOneDiveSites, deleteDiveSites } from '../../../services/dive-sites';
import { COUNTRIES_KEY_LABEL } from '../../../constants/countries';
import ModalDiveSite from '../../../components/modals/modal-dive-site/modal-dive-site';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import services from '../../../services/services';
import { deleteDiveSitesPictures, createDiveSitesPictures } from '../../../services/dive-sites-pictures';
import ModalUploadFiles from '../../../components/modals/modal-upload-files/modal-upload-files';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';

interface RouteParams {
  id: string;
  siteId?: string;
}
export interface IDiveSiteDetailSwitchProps extends RouteComponentProps<RouteParams> {
  staffPermissions: any;
  agent: any;
  t: any;
}

export interface IDiveSiteDetailSwitchState {
  activeTab: string;
  diveSite: any;
  selectedPictures: any[];
  modal: string;
  siteId: string;
}

class DiveSiteDetailSwitch extends React.Component<IDiveSiteDetailSwitchProps, IDiveSiteDetailSwitchState> {
  static tabs = {
    detail: 'detail',
    gallery: 'gallery',
    rate: 'rate',
    dives: 'dives',
  };

  constructor(props: IDiveSiteDetailSwitchProps) {
    super(props);

    this.state = {
      activeTab: DiveSiteDetailSwitch.tabs.detail,
      diveSite: {
        name: '-',
        state: '-',
        country: 'ES',
        description: {},
      },
      selectedPictures: [],
      modal: '',
      siteId: '',
    };

    // this.contentComponent = React.createRef();
  }

  componentDidMount() {
    const { match } = this.props;
    const siteId = match.params.siteId ? match.params.siteId : match.params.id;
    if (siteId) {
      this.setState({ siteId }, () => {
        this.getDiveSite(siteId);
      });
    }
  }

  getDiveSite = async (id: string) => {
    try {
      const params = {
        filter: {
          relations: ['reserve', 'centres', 'centres.user'],
        },
      };
      const response: any = await getOneDiveSites(id, params);

      this.setState({ diveSite: response.data });
    } catch (err) { }
  };

  deleteDiveSite = async (id: string) => {
    const { history, location } = this.props;
    try {
      await deleteDiveSites(id);
      services.pushNotification({
        title: 'Punto de inmersión eliminado',
        text: 'Se ha eliminado el punto de inmersión con éxito.',
        type: 'red',
      });
      this.setState({ modal: '' }, () => history.push(location.pathname.split('/detail')[0]));
    } catch (err) { }
  };

  deletePicturesDiveSite = async () => {
    const { selectedPictures } = this.state;
    try {
      for (const pic of selectedPictures) {
        await deleteDiveSitesPictures(pic.id);
        services.pushNotification({
          title: 'Fotografía eliminada',
          text: 'Se ha eliminado la fotografía con éxito.',
          type: 'red',
        });
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({ modal: '', selectedPictures: [] }, () => {
      const event = new Event('reload-dive-site');
      window.dispatchEvent(event);
    });
  };

  addPicturesDiveSite = async (files: any) => {
    const { siteId } = this.state;
    const picturesData: any[] = files.map((p: any) => ({
      file: p.data,
      site: siteId,
    }));

    for (const element of picturesData) {
      try {
        const formData = new FormData();
        formData.set('file', element.file);
        formData.set('site', element.site);
        await createDiveSitesPictures(formData);
      } catch (err) { }
    }

    this.setState({ modal: '' }, () => {
      const event = new Event('reload-dive-site');
      window.dispatchEvent(event);
    });
  };

  setSelectedPictures = (selectedPictures: string[]) => {
    this.setState({ selectedPictures });
  };

  renderHeader() {
    const { staffPermissions, agent } = this.props;
    const { activeTab, diveSite, selectedPictures } = this.state;
    const {
      tabs: { detail, gallery, rate, dives },
    } = DiveSiteDetailSwitch;

    const icons = undefined;
    let mainButton = undefined;
    let secondaryButtonRed = undefined;
    const centreCreator =
      diveSite && diveSite.centres && diveSite.centres[0] && diveSite.centres[0].user.id === agent.id;
    if (!staffPermissions || staffPermissions.sites) {
      switch (activeTab) {
        case detail:
          if (centreCreator || agent.roles.includes('superAdmin')) {
            mainButton = {
              text: 'Editar',
              onClick: () => this.setState({ modal: 'create-site' }),
            };
            secondaryButtonRed = {
              text: 'Eliminar',
              onClick: () => this.setState({ modal: 'modal-delete' }),
            };
          }
          break;
        case gallery:
          if (selectedPictures.length === 0) {
            mainButton = {
              img: images.uploadWhiteSvg,
              text: 'Subir fotografías',
              onClick: () => this.setState({ modal: 'modal-add-pictures' }),
            };
          } else {
            let isOwner;
            if (selectedPictures.length > 0) {
              isOwner = selectedPictures.every((picture: any) => picture.uploadedBy.id === agent.id);
            } else isOwner = false;

            if (centreCreator || agent.roles.includes('superAdmin') || isOwner) {
              secondaryButtonRed = {
                text: 'Eliminar',
                onClick: () => this.setState({ modal: 'modal-delete-pictures' }),
              };
            }
          }

          break;
        case rate:
          break;
        case dives:
          break;
      }
    }

    return (
      <HeaderMain
        goBack={true}
        title={`${diveSite.name}, ${diveSite.state}, ${COUNTRIES_KEY_LABEL[diveSite.country]}`}
        icons={icons}
        mainButton={mainButton}
        secondaryButtonRed={secondaryButtonRed}
      />
    );
  }

  renderContent() {
    const { activeTab, diveSite } = this.state;
    const { agent, t } = this.props;
    const {
      tabs: { detail, gallery, rate, dives },
    } = DiveSiteDetailSwitch;

    const centreCreator =
      diveSite && diveSite.centres && diveSite.centres[0] && diveSite.centres[0].user.id === agent.id;

    switch (activeTab) {
      case detail:
        return (
          <>
            {diveSite && (
              <DiveSiteDetail
                t = { t } 
                diveSite={diveSite}
                agent={agent}
              // ref={this.contentComponent}
              />
            )}
          </>
        );
      case gallery:
        return (
          <DiveSiteGallery
            canAddFav={centreCreator || agent.roles.includes('superAdmin')}
            setSelectedPictures={(selectedPicture: any[]) => this.setSelectedPictures(selectedPicture)}
            clearValue={this.state.selectedPictures.length === 0}
            {...this.props}
          />
        );
      case rate:
        return (
          <DiveSiteRate
            // ref={this.contentComponent}
            {...this.props}
          />
        );
      case dives:
        return <DiveSiteDive />;
    }
  }

  render() {
    const { activeTab, modal, diveSite } = this.state;
    const { siteId } = this.state;

    return (
      <>
        {/* Header */}
        {this.renderHeader()}
        {/* Content */}
        <MainLayoutSection>
          <NavigationTabContainer>
            <div
              className={`navigation-item ${activeTab === DiveSiteDetailSwitch.tabs.detail && 'active'}`}
              onClick={() => this.setState({ activeTab: DiveSiteDetailSwitch.tabs.detail })}
            >
              <BodyDestacadoText>Detalle</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${activeTab === DiveSiteDetailSwitch.tabs.gallery && 'active'}`}
              onClick={() => this.setState({ activeTab: DiveSiteDetailSwitch.tabs.gallery, selectedPictures: [] })}
            >
              <BodyDestacadoText>Galería de imágenes</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${activeTab === DiveSiteDetailSwitch.tabs.dives && 'active'}`}
              onClick={() => this.setState({ activeTab: DiveSiteDetailSwitch.tabs.dives })}
            >
              <BodyDestacadoText>Inmersiones</BodyDestacadoText>
            </div>
            <div
              className={`navigation-item ${activeTab === DiveSiteDetailSwitch.tabs.rate && 'active'}`}
              onClick={() => this.setState({ activeTab: DiveSiteDetailSwitch.tabs.rate })}
            >
              <BodyDestacadoText>Valoraciones</BodyDestacadoText>
            </div>
          </NavigationTabContainer>

          {/* Page Content */}
          {this.renderContent()}
        </MainLayoutSection>

        {modal === 'create-site' && (
          <ModalDiveSite
            showModal={modal === 'create-site'}
            diveSiteId={siteId}
            divesSites={[diveSite]}
            cards={[]}
            onClose={(load?: boolean) =>
              this.setState({ modal: '' }, () => {
                if (load) this.getDiveSite(siteId);
              })
            }
          />
        )}

        {modal === 'modal-delete' && (
          <ModalDelete
            title={'Vas a eliminar un punto de inmersión'}
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete'}
            onRemove={() => {
              if (!!siteId) {
                this.deleteDiveSite(siteId);
              }
            }}
            onCancel={() => this.setState({ modal: '' })}
          />
        )}

        {modal === 'modal-delete-pictures' && (
          <ModalDelete
            title={'Vas a eliminar imágenes de un punto de inmersión'}
            subtitle="¿estás seguro?"
            showModal={modal === 'modal-delete-pictures'}
            onRemove={() => {
              this.deletePicturesDiveSite();
            }}
            onCancel={() => this.setState({ modal: '' })}
          />
        )}

        {modal === 'modal-add-pictures' && (
          <ModalUploadFiles
            showModal={modal === 'modal-add-pictures'}
            onConfirm={(files: any) => {
              this.addPicturesDiveSite(files);
            }}
            onClose={() => this.setState({ modal: '' })}
          />
        )}

        {/* <ModalDocument showModal={true} onClose={() => {}} /> */}

        {/* Modal Confirmacion */}

        {/* <ModalConfirmation
          title="Vas a validar 5 documentos"
          subtitle={'¿estás seguro?'}
          showModal={renderModal === 'modal-confirmation'}
          onAccept={() => this.setState({ renderModal: 'modal-success' })}
          onCancel={() => {}}
        /> */}

        {/* Notificacion */}

        {/* <ToastNotification
          onClose={() => this.setState({ popNot: false })}
          text="Se ha eliminado un centro de buceo del panel con éxito"
          title="Centro eliminado"
          type="green"
          showToast={popNot}
        /> */}
      </>
    );
  }
}
function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
    agent: state.agent.value,
  };
}
export default connect(mapStateToProps)(withRouter(DiveSiteDetailSwitch));
