import services from './services';

export const getMarineReservePictures = async (params: any) => {
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'marine-reserve-pictures',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
}

export const getOneMarineReservePictures = (pictureId: string) => {
  const endpoint = `marine-reserve-pictures/${pictureId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadMarineReservePictures = (pictureId: string, data: any) => {
  const endpoint = `marine-reserve-pictures/${pictureId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const deleteMarineReservePictures = (pictureId: string) => {
  const endpoint = `marine-reserve-pictures/${pictureId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createMarineReservePictures = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'marine-reserve-pictures',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};