import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const CertifierCenterDetailContainer = styled.div`
  .certifier-centre-detail-logo-container {
    height: 360px;
    overflow: hidden;
    border-radius: 5px;

    img {
      height: auto;
      min-height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .certifier-centre-detail-title-container {
    margin-top: 80px;
    margin-bottom: 60px;
  }

  .certifier-centre-detail-data-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
  }

  .certifier-centre-detail-data-item {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    &__title {
      min-width: 150px;
    }
    &__text {
    }
  }

  .certifier-centre-detail-data-subtitle {
    color: ${colors['NE-002/100']};
    margin-top: 52px;
    margin-bottom: 24px;
    text-transform: uppercase;
  }
`;
