import styled, { css } from "styled-components";
import { mediaqueries } from "../../../constants/mediaqueries";

export const H3TextContainer = styled.p`
  font-family: "plain";
  font-weight: 500;
  font-size: 42px;
  line-height: 48px;

  ${props => css`
    color: ${props.color};
  `}
  ${mediaqueries["max-width768"]} {
    font-size: 36px;
    line-height: 44px;
  }
`;
