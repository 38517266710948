import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const CalendarBoxContainer = styled.div`
  position: relative;
  z-index: 9;

  &.show-calendar {
    z-index: 12;
    .input-box-main-field {
      color: ${colors['PR-001-100']};
      /* border-bottom: 1px solid ${colors['PR-001-100']}; */
      input {
        color: ${colors['PR-001-100']};
      }
    }
    .calendar-popup {
      display: block;
    }
    .input-box-icon {
    opacity: 1;
    }
  }

  &.error {
    
    .input-box-main-field {
      border-bottom:1px solid ${colors['CO-003/100']};
    }
    .input-box-error {
      opacity: 1;
      color: ${colors['CO-003/100']};
    }

  }

  &.complete {
    .input-box-main-field {

      input {
      font-size: 14px;
      line-height: 22px;
      color: ${colors['NE-001/100']};
      font-weight: 100;
      padding: 14px 20px 12px 20px;
        color: #091e42;
        &:focus {
          color: ${colors['PR-001-100']};
        }
      }
    }
  }

  &.multiple {
    .input-box-main-field {
      height: auto;
      padding: 12px;
      min-height: 46px;
      padding-right: 60px;
    }
  }

  

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: ${colors['TX-001-30']};

      &:before{
        background: ${colors['NE-002/100']} !important;
      }
    }

    .input-box-main-field {
      background: ${colors['NE-003/20']};
      input {
        color: ${colors['NE-003/100']};
      }
      color: ${colors['NE-003/100']};
      &:before {
        background: ${colors['NE-003/20']};
      }
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 6px;
  }

  .input-box-topbar-label {
    color: ${colors['NE-002/100']};
    text-transform: uppercase;
    padding-left: 12px;
    transition: all 0.3s ease-in-out;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;
    width: 100%;
    /* padding: 13px 18px 9px; */
    transition: all 0.2s ease-in-out;
    margin-bottom: 4px;
    color: ${colors['SE-002/100']};
    border-bottom: 1px solid ${colors['NE-002/100']};
    cursor: pointer;
    height: 48px;

    input {
    font-family: "plain";
    font-size: 14px;
    line-height: 22px;
    width: ${props => (props.multiple ? '36%' : '100%')};
    font-weight: 300;
    padding: ${props => (props.multiple ? '14px 5px 12px' : '14px 20px 12px')};
    transition: all 0.2s ease-in-out;
    color: ${colors['SE-002/100']};
    height: 48px;

    &::placeholder  {
      color: ${colors['NE-002/100']};
    }
  }

    &:before {
      content: "";
      z-index: 9;
      position: absolute;
      top: 23px;
      left: 4px;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      background: ${colors['PR-001-100']};
    }

    &:hover {
      background: ${colors['SC-001-10']};
    }

    &:focus-within {
      border-bottom: 1px solid ${colors['PR-001-100']};
      input {
        color: ${colors['PR-001-100']};
      }
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-icon-clear {
    position: absolute;
    top: 16px;
    right: 32px;
    width: 20px;
    z-index: 10;
    opacity: 0.3;
    &:focus {
      opacity: 1;
    }
    transition: all 0.3s ease-in-out;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    right: 0;
  }

  .calendar-popup {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    display: none;
    color: ${colors['PR-001-100']};

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      &:hover {
        background: ${colors['SC-001-10']};
      }
    }
  }

  .input-box-error {
    color: ${colors['CO-003/100']};
    transition: 0.3s ease-in-out all;
    opacity: 0;
  }

  &.rounded {
    .input-box-topbar {
      display: none;
    }
    input {
      /* border-radius: 5px; */
    }
    .input-box-error {
      min-height: 12px;
    }
    .input-box-main-field {
      border: 1px solid ${colors['NE-002/100']};
      border-radius: 5px;
    }
    .calendar-box-fix-position {
      position: absolute;
      top: 0;
      left: 0;
    }
    &.show-calendar {
      .input-box-main-field {
        border: 1px solid ${colors['PR-001-100']};
        color: ${colors['PR-001-100']};
      }
    }
  }
  &.filter {
    .input-box-topbar {
      display: none;
    }
    input {
      border: 0px solid ${colors['NE-002/100']};
      border-radius: 5px;
      color: ${colors['NE-001/100']};
    }
    .input-box-error {
      min-height: 12px;
    }
    .input-box-main-field {
      border: 0;
    }
  }
  &.required {
    .input-box-topbar-label {
      position: relative;
      &::before  {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 8px;
        background: ${colors['PR-001/100']}
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &.rounded-title{
    input {
      border-radius: 5px;
    }
    .input-box-error {
      min-height: 12px;
    }
    .input-box-main-field {
      border: 1px solid ${colors['NE-002/100']};
      border-radius: 5px;
    }
    .calendar-box-fix-position {
      position: absolute;
      top: 0;
      left: 0;
    }
    &.show-calendar {
      .input-box-main-field {
        border: 1px solid ${colors['PR-001-100']};
        color: ${colors['PR-001-100']};
      }
    }
  }

  .input-box-advice {
    color: ${colors['PR-001/100']};
  }

  &.range {
    /* height:48px; */
    &.filter {
      padding: 13px 18px 14px;
      &.stat {
        padding: 0px 18px;
      }
      min-width: 220px;
      max-width: unset;
      margin-top: 1.8px;
      .input-box-error {
        display: none;
      }
      .input-box-main {
        display: flex;
        .input-box-icon-clear {
          top: unset;
          right: unset;
          position: relative;
          order: 2;
        }
        .input-box-icon {
          position: relative;
          top: unset;
          left: unset;
          order: 0;
        }
      .input-box-main-field {
        width: auto;
        display: flex;
          order: 1;
          input {
            width: auto;
            max-width: 80px;
          }

      
      }
      }
    }

    max-width: 180px;
    .input-box-topbar {
      display : none;
    }
    .input-box-icon {
      top: 0px;
      left: 0;
    }
    .input-box-main-field {
      margin-bottom: 0px;
      border-bottom: 0px;
      height: auto;
      display: flex;
      justify-content: flex-end;
      input {
        height: auto;
        padding:0 5px;
        font-size: 12px;
        line-height: 18px;
        width: 43%;
        color: ${colors['PR-001/100']};
      }
    }
  }

  &.disable-write {
    .input-box-main-field {
      height: auto;
      padding: 12px;
      min-height: 46px;
      padding-right: 60px;
    }
  }
`;
