import React from 'react';
import { ModalConfirmationActivityContainer } from './modal-confirmation-activity-style';
import { images } from '../../../assets/images';
import MainButton from '../../main-button';
import ModalContainer from '../modal-container';
import CalendarBox from '../../calendar-box';
import InputBox from '../../input-box';
import DicotomicCheckbox from '../../dicotomic-checkbox';
import InfoText from '../../text/info-text/info-text';
import SingleButton from '../../single-button/single-button';
import { IActivity } from '../../../models/activities';
import { patchCentreActivity, postCentreActivityConfirm } from '../../../services/centre';
import * as dateFns from 'date-fns';
import { formatTime } from '../../../utils/time';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

interface ModalProps extends RouteComponentProps, WithTranslation {
  centreId: string;
  activity: IActivity;
  className?: String;
  showModal: boolean;
  onAccept: () => void;
  onCancel: () => void;
  t: any;
}

interface ModalState {
  step: number;
  sendEmail: boolean;
  date?: string;
  start?: string;
  end?: string;
  allowedDates: string[];
  allowedHours: any[];
  divesInfo: {
    start: string;
    finish: string;
  }[];
  errors: {
    date: string;
    start: string;
    end: string;
  };
  dateClear: boolean;
}

class ModalActivityConfirmation extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      step: 0,
      sendEmail: false,
      dateClear: false,
      start: '',
      end: '',
      allowedDates: [],
      allowedHours: [],
      divesInfo: [],
      errors: {
        date: '',
        start: '',
        end: '',
      },
    };
  }

  componentDidMount() {
    this.getAllowedDates();
  }

  getAllowedDates() {
    const { activity } = this.props;
    if (activity) {
      const dates = activity.timeBlocks.filter(t => !!t.start.date);
      const allowedDates =
        dates.length > 0
          ? dates
            .sort((a: any, b: any) => new Date(a.start.date).getTime() - new Date(b.start.date).getTime())
            .map((d: any) => dateFns.format(new Date(d.start.date), 'dd/MM/yyyy'))
          : [dateFns.format(new Date(activity.date), 'dd/MM/yyyy')];
      const allowedHours = activity.timeBlocks.map(t => t);
      const d: any = allowedHours.find(
        allowedHour =>
          dateFns.format(new Date(allowedHour.start.date || activity.date), 'dd/MM/yyyy') === allowedDates[0]
      );
      this.setState({
        allowedDates,
        allowedHours,
        date: allowedDates[0],
        start: d ? `${formatTime(d.start.hour)}:${formatTime(d.start.minutes)}` : '',
        end: d ? `${formatTime(d.end.hour)}:${formatTime(d.end.minutes)}` : '',
        dateClear: false,
      });
    }
  }

  handleChangeHour(isStart: boolean, value: string) {
    if (isStart) this.setState({ start: value });
    else this.setState({ end: value });
  }

  handleNextStep() {
    const { step, start, end, date, divesInfo, allowedDates, allowedHours } = this.state;
    const errors = {
      date: '',
      start: '',
      end: '',
    };
    if (!start || start === '') errors.start = 'required';
    if (!end || end === '') errors.end = 'required';
    if (!date || date === '') errors.date = 'required';
    const now = new Date();
    const dateMoment = dateFns.parse(date || dateFns.format(new Date(), 'dd/MM/yyyy'), 'dd/MM/yyyy', new Date());
    if (start && end && date) {
      const startArray = start.split(':');
      const endArray = end.split(':');
      if (startArray.length < 2) {
        errors.start = 'invalid-format';
      }
      if (endArray.length < 2) {
        errors.end = 'invalid-format';
      }
      if (dateFns.isBefore(dateMoment, now)) {
        errors.date = 'invalid-date-future';
      }

      if (!allowedDates.find((d: string) => d === date)) {
        errors.date = 'invalid-date';
      }
      if (startArray.length === 2 && endArray.length === 2) {
        if (startArray[0] > endArray[0] || (startArray[0] === endArray[0] && startArray[1] >= endArray[1])) {
          errors.start = 'invalid-range';
          errors.end = 'invalid-range';
        }
        let dateHour = null;
        if (allowedHours.length > 1) {
          dateHour = allowedHours.find(d => d.start.date ? dateFns.format(new Date(d.start.date), 'dd/MM/yyyy') === date : true);
        } else {
          dateHour = allowedHours[0];
        }

        if (dateHour) {
          if (dateHour.start.hour > parseFloat(startArray[0])) {
            errors.start = 'invalid-range';
          } else if (
            dateHour.start.hour === parseFloat(startArray[0]) &&
            dateHour.start.minutes > parseFloat(startArray[1])
          ) {
            errors.start = 'invalid-range';
          }
          if (dateHour.end.hour < parseFloat(endArray[0])) {
            errors.end = 'invalid-range';
          } else if (dateHour.end.hour === parseFloat(endArray[0]) && dateHour.end.minutes < parseFloat(endArray[1])) {
            errors.end = 'invalid-range';
          }
        }
      }
      if (Object.values(errors).every(err => err === '')) {
        const startDateAux = dateFns.setHours(dateMoment, parseInt(startArray[0], undefined));
        const startDate = dateFns.setSeconds(startDateAux, parseInt(endArray[0], undefined));
        const endDateAux = dateFns.setHours(dateMoment, parseInt(startArray[0], undefined));
        const endDate = dateFns.setSeconds(endDateAux, parseInt(endArray[0], undefined));

        const newDivesInfo = divesInfo.concat([
          {
            start: startDate.toISOString(),
            finish: endDate.toISOString(),
          },
        ]);
        this.setState({ step: Infinity }, () => {
          this.setState(
            {
              errors,
              step: step + 1,
              divesInfo: newDivesInfo,
              start: '',
              end: '',
              date: '',
              dateClear: true,
            },
            () => this.getAllowedDates()
          );
        });
        return;
      }
    }
    this.setState({ errors });
  }

  handlePrevStep() {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  async handleSubmit() {
    const { onAccept, activity, centreId } = this.props;
    const { divesInfo, sendEmail } = this.state;
    if (activity) {
      const dataConfirm: any = {
        sendEmail,
      };
      let data: any = {
        divesInfo,
      };
      if (activity.courseInfo) {
        //TODO
        /* const practicalPhase = activity.courseInfo.practicalPhase;
         practicalPhase.blocks = divesInfo;
         data = {
           courseInfo: {
             practicalPhase,
             theoreticPhase: activity.courseInfo.theoreticPhase,
           },
         };*/
      }
      await patchCentreActivity(centreId, activity.id.toString(), data);
      await postCentreActivityConfirm(centreId, activity.id.toString(), dataConfirm);
      onAccept();
    }
  }

  renderFirstStep() {
    const { errors, step, date, start, end, allowedDates, dateClear, allowedHours } = this.state;
    const { activity, t } = this.props;
    return (
      <>
        <div className="modal-confirmation-activity-name">
          <p>{activity ? activity.name : ''}</p>
        </div>
        <div className="modal-confirmation-activity-title">
          <p>{t('modalMessages:modal-confirmation-activity.confirm-immersions')}</p>
        </div>
        {activity && activity.courseInfo && (
          <div className="modal-confirmation-activity-subtitle">
            <p>{t('modalMessages:modal-confirmation-activity.choose-date-hours-block')}</p>
          </div>
        )}
        <div className="modal-confirmation-activity-inmersion-number">
          <p>{t('components:general.immersion')}{`${step + 1}`}</p>
        </div>
        <div className="modal-confirmation-activity-calendar">
          <CalendarBox
            placeholder={t('components:general.date')}
            className="rounded"
            labelText=""
            allowedDates={allowedDates}
            errorCode={errors.date}
            initialValue={date || ''}
            clearValue={dateClear}
            onChange={value =>
              this.setState({ date: value, dateClear: false }, () => {
                const d = allowedHours.find(
                  allowedHour =>
                    dateFns.format(new Date(allowedHour.start.date || activity.date), 'dd/MM/yyyy') === value
                );
                if (d) {
                  this.setState({
                    start: `${formatTime(d.start.hour)}:${formatTime(d.start.minutes)}`,
                    end: `${formatTime(d.end.hour)}:${formatTime(d.end.minutes)}`,
                  });
                }
              })
            }
            onFocus={() => this.setState({ dateClear: false })}
          />
        </div>
        <div className="modal-confirmation-activity">
          <div className="modal-confirmation-activity-time-left">
            <InputBox
              placeholder={t('components:general.start-hour')}
              className="rounded"
              type="text"
              value={start || ''}
              labelText={t('components:general.start')}
              errorCode={errors.start}
              onChange={value => this.handleChangeHour(true, value)}
              cleaveOptions={{
                time: true,
                timePattern: ['h', 'm'],
              }}
            />
          </div>
          <div className="modal-confirmation-activity-time-right">
            <InputBox
              placeholder={t('components:general.end-hour')}
              className="rounded"
              type="text"
              value={end || ''}
              labelText={t('components:general.end')}
              errorCode={errors.end}
              onChange={value => this.handleChangeHour(false, value)}
              cleaveOptions={{
                time: true,
                timePattern: ['h', 'm'],
              }}
            />
          </div>
        </div>
        <div className="modal-confirmation-activity-button-next">
          <MainButton text={t('components:actions.next')} type="primary" onClick={() => this.handleNextStep()} />
        </div>
      </>
    );
  }

  renderSecondStep() {
    const { activity, t } = this.props;
    const { sendEmail } = this.state;
    return (
      <>
        <div className="modal-confirmation-activity-name">
          <p>{activity ? activity.name : ''}</p>
        </div>
        <div className="modal-confirmation-activity-title">
          <p>{t('modalMessages:modal-confirmation-activity.confirm-activity')}</p>
        </div>
        <div className="modal-confirmation-activity-text">
          <p>
          {t('modalMessages:modal-confirmation-activity.confirm-advice-staff')}
          </p>
          {/* <p>Los instructores que no hayan respondido a la actividad, quedarán fuera de la misma.</p> */}
        </div>
        <DicotomicCheckbox
          checked={sendEmail}
          onCheck={checked => {
            this.setState({ sendEmail: !sendEmail });
          }}
        >
          <div className="create-activities-dicotomic-container">
            <div className="create-activities-dicotomic-container__text">
              <InfoText>
                {t('components:content-block.notify')}
              </InfoText>
            </div>
          </div>
        </DicotomicCheckbox>
        <div className="modal-confirmation-activity-buttons">
          <div className="modal-confirmation-activity-button-back">
            <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => this.handlePrevStep()} />
          </div>
          <div className="modal-confirmation-activity-button-next">
            <MainButton text={t("modalMessages:confirm")} type="primary" onClick={() => this.handleSubmit()} />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { className, showModal, onCancel, activity } = this.props;
    const { step } = this.state;
    const isPeriodic = activity && activity.periodicityId;
    return (
      <ModalContainer className="center" modalClose={() => onCancel()} active={showModal}>
        <ModalConfirmationActivityContainer className={className}>
          {activity && step < activity.diveCount ? this.renderFirstStep() : this.renderSecondStep()}
        </ModalConfirmationActivityContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalActivityConfirmation));