import React, { Component } from 'react';
import HeaderMain from '../../../components/header-main/header-main';
import MainTable from '../../../components/main-table/main-table';
import { MainLayoutSection } from '../../main-layout-style';
import { images } from '../../../assets/images';
import Pagination from '../../../components/pagination';
import { RolesContainer } from './roles-style';
// tslint:disable-next-line: max-line-length
import SubheadDestacadoText from '../../../components/text/subhead-destacado-text/subhead-destacado-text';
import MainButton from '../../../components/main-button';
import ModalDelete from '../../../components/modals/modal-delete/modal-delete';
import { NavLink } from 'react-router-dom';
import { rowKeys, tableColumns, CentreStaff } from './types';
import { TableRow, TableCell } from '../../../types/table';
import StaffFormFunctional from './create-edit/staff-form-functional';
import { getDayMonthNumberYear } from '../../../utils/time';
import services from '../../../services/services';
import { withTranslation, WithTranslation, Trans, useTranslation } from 'react-i18next';
import ModalCreateStaff from '../../../components/modals/modal-create-staff/modal-create-staff';
import { deleteCentreStaff } from '../../../services/centre';

interface RolesLayoutProps extends WithTranslation {
  items: CentreStaff[];
  totalPages: number;
  setPage: (page: number) => void;
  refreshData: () => void;
  page: number;
}
interface RolesLayoutState {
  renderModal: string;
  centreID?: string;
  editID?: any;
  centreName: string;
}

// Secciones de la tabla

// -- nombre
// -- Organiz./empresa
// -- Rol
// -- creación
// -- email
// -- ver perfil -- (deshabilitado en esta primera versión, solo podrán ver el modal para editarlo, no su perfil web)
// -- opciones
//      Edit. permisos
//      Eliminar

class RolesLayout extends Component<RolesLayoutProps, RolesLayoutState> {
  state: RolesLayoutState = {
    renderModal: '',
    centreName: ''
  };

  formatDataToTableRows(data: { [key: string]: any }[]) {
    return data.map(item => {
      const row: TableRow = {};

      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };
        switch (key) {
          case 'icon':
            cell.value = item[key] || images.avatarDefault;
            cell.key = 'icon';
            break;
          case 'name':
            cell.value = `${item.name} ${item.surname}`;
            cell.key = 'highlighted-body';
            break;
          case 'rating':
            cell.value = item[key] / 2;
            cell.key = 'rate';
            break;
          case 'id':
            cell.value = item[key];
            cell.key = 'detail';
            break;
          case 'createdAt':
            cell.value = getDayMonthNumberYear(item[key]);
            cell.key = 'createdAt';
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });

      row.detail = {
        value: 'Ver perfil',
        type: 'light-grey',
        key: 'text-button',
        link: `/centres/${item.centreID}/staff/${item.id}`,
      };

      row.options = {
        value: item.id,
        type: '',
        key: 'options',
        actions: [
          {
            label: 'Edit. permisos',
            icon: images.edit3Svg,
            onClick: () => {
              this.setState({
                renderModal: 'modal-form',
                editID: item.rawItem,
                centreID: item.centreID,
                centreName: item.centreName,
              });
            },
          },
          {
            label: 'Eliminar',
            icon: images.delete2Svg,
            onClick: () => {
              this.setState({ renderModal: 'modal-delete', editID: item.id, centreID: item.centreID });
            },
          },
        ],
      };

      return row;
    });
  }

  async handleRemoveStaff(centreID: string | number, editID: any) {
    const { refreshData } = this.props;
    await deleteCentreStaff(centreID.toString(), editID.toString());
    services.pushNotification({
      title: '¡Eliminado!',
      text: 'Se ha eliminado el rol con éxito',
      type: 'red',
    });
    this.setState({ renderModal: '', editID: undefined, centreID: undefined })
    refreshData();
  }

  render() {
    const { renderModal, centreID, editID, centreName } = this.state;
    const { items, totalPages, setPage, refreshData, t, page } = this.props;
    return (
      <>
        <HeaderMain
          title={t('layouts:roles.header')}
        />
        {/* Content */}
        <MainLayoutSection>
          <RolesContainer>
            {items.map((item: any, i) => (
              <div key={i} className="role-managment-table">
                <div className="roles-managment-flex">
                  <div className="role-managment-table__title">
                    <SubheadDestacadoText>
                      {`${item.name}${item.location ? `, ${item.location}` : ''}`}
                    </SubheadDestacadoText>
                  </div>
                  {!!item.validated ? (
                    <div className="roles-managment-flex__button">
                      <MainButton
                        text={'Crear'}
                        type={'primary'}
                        onClick={() => this.setState({
                          centreID: item.centreID, centreName: item.name,
                          editID: undefined, renderModal: 'modal-form'
                        })}
                        iconImg={images.createWhiteSvg}
                      />
                    </div>
                  ) : (
                      <div className={'roles-managment-advice'}>
                        Para poder crear roles valida el centro primero
                      </div>
                    )}


                </div>


                <div className="role-managment-table__content">
                  <MainTable
                    columns={tableColumns(t)}
                    rows={this.formatDataToTableRows(item.staff.slice(0, 3))}
                    rowKeys={rowKeys}
                    onOrder={() => { }}
                  />
                  {item.staff.length > 3 && (
                    <div className="role-managment-table__more">
                      <NavLink to="/role-management/center">
                        <MainButton type="secondary" text="Ver más" />
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            ))}

            {totalPages > 1 && (
              <div className="role-managment-pagination">
                <Pagination page={page} limit={totalPages} callback={page => setPage(page)} />
              </div>
            )}
          </RolesContainer>
        </MainLayoutSection>

        {/* Formulario Staff */}

        {renderModal === 'modal-form' && centreID && (
          // <StaffFormFunctional
          //   showModal={renderModal === 'modal-form'}
          //   onCancel={() => this.setState({ renderModal: '' })}
          //   onAccept={isEdit => {
          //     if (!isEdit) {
          //       this.setState({ renderModal: 'modal-success', centreID: undefined, editID: undefined }, () => {
          //         refreshData();
          //       });
          //     } else {
          //       services.pushNotification({
          //         title: 'Genial',
          //         text: 'Se ha editado el rol de un miembro correctamente',
          //         type: 'green',
          //       });
          //       this.setState({ centreID: undefined, editID: undefined }, () => {
          //         refreshData();
          //       });
          //     }
          //   }}
          //   centreID={centreID}
          //   staffID={editID}
          //   editMode={!!editID}
          // />
          <ModalCreateStaff
            centreId={centreID}
            staff={editID}
            sectionRoles={centreName || ''}
            showModal={true}
            onCancel={() => this.setState({ renderModal: '' })}
            onSuccess={() => {
              refreshData();
              this.setState({ renderModal: '' });
            }}
          />
        )}

        {/* Modal Eliminar */}

        {renderModal === 'modal-delete' && (
          <ModalDelete
            title="Vas a eliminar un rol del centro de buceo"
            subtitle="¿estás seguro?"
            showModal={renderModal === 'modal-delete'}
            onRemove={async () => {
              if (!!editID && !!centreID) {
                this.handleRemoveStaff(centreID, editID);
              }
            }}
            onCancel={() => this.setState({ renderModal: '', editID: undefined, centreID: undefined })}
          />
        )}
      </>
    );
  }
}

export default withTranslation(['intro', 'buttons'])(RolesLayout);
