import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const DiveCardContainer = styled.div`
  border: 0.5px solid #dde4f1;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  .dive-card-container {
    display: flex;
    padding: 12px 18px 15px;
    flex-direction: column;
    color: ${colors['SE-002/100']};
    text-transform: uppercase;
    &__title {
      margin-bottom: 12px;
    }
    &__text {
    }
    &__subtext {
    }
  }

  &.red  {
    .dive-card-container {
      border-left: 8px solid ${colors['CO-004/100']};
      &__title  {
        color: ${colors['CO-004/100']};
      }
    }
  }

  &.blue {
    .dive-card-container {
      border-left: 8px solid ${colors['SE-002/100']};
      &__title  {
        color: ${colors['SE-002/100']};
      }
    }
  }
  &.turquoise {
    .dive-card-container {
      border-left: 8px solid ${colors['SE-001/100']};
      &__title  {
        color: ${colors['SE-001/100']};
      }
    }
  }

  &.yellow {
    .dive-card-container {
      border-left: 8px solid ${colors['CO-002/100']};
      &__title  {
        color: ${colors['CO-002/100']};
      }
    }
  }
`;
