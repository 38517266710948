import services from './services';

export const getCancellationPolicies = async (params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'cancellation-policies',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const getOneCancellationPolicies = (cancellationPolicyId: string, params?: any) => {
  const endpoint = `cancellation-policies/${cancellationPolicyId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};

export const uploadCancellationPoliciesFormData = (cancellationPolicyId: string, data: FormData) => {
  const endpoint = `cancellation-policies/${cancellationPolicyId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const uploadCancellationPolicies = (cancellationPolicyId: string, data: any) => {
  const endpoint = `cancellation-policies/${cancellationPolicyId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const deleteCancellationPolicies = (cancellationPolicyId: string) => {
  const endpoint = `cancellation-policies/${cancellationPolicyId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createCancellationPolicies = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'cancellation-policies',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};
export const createCancellationPoliciesFormData = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'cancellation-policies',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};
