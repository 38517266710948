import styled, { css } from "styled-components";
import { mediaqueries } from "../../../constants/mediaqueries";

export const H1TextContainer = styled.p`
  font-family: "plain";
  font-weight: 500;
  font-size: 54px;
  line-height: 60px;

  ${props => css`
    color: ${props.color};
  `}

  ${mediaqueries["max-width768"]} {
    font-size: 48px;
    line-height: 54px;
  }
`;
