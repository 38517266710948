export const CENTRE_DOCUMENTS_TYPES_DROPDOWN = [
  { label: 'Seguro', value: 'insurance' },
  { label: 'Seguro de responsabilidad civil', value: 'civilinsurance' },
  { label: 'Documentación CIF', value: 'cif' },
  { label: 'Documentación club', value: 'nolucrative' },
  { label: 'Certificado', value: 'certificate' },
  { label: 'Garantía', value: 'warranty' },
  { label: 'Contrato', value: 'contract' },
  { label: 'Licencia de apertura', value: 'licap'},
  { label: 'Revision de material', value: 'materialReview' },
  { label: 'Aurorización de Comunidad Autonoma', value:'autpesc'},
  { label: 'Otro', value: 'other' },
];

export const CENTRE_DOCUMENTS_TYPES_KEY_VALUES: any = {
  insurance: 'Seguro',
  civilinsurance: 'Seguro de responsabilidad civil',
  nolucrative: 'Documentación club',
  cif: 'Documentación CIF',
  certificate: 'Certificado',
  licap: 'Licencia de apertura',
  warranty: 'Garantía',
  contract: 'Contrato',
  materialReview: 'Revision de material',
  autpesca: 'Aurorización de Comunidad Autonoma',
  other: 'Otro',
};
