import * as React from 'react';
import { PlanningCalendarStyle } from './planning-calendar-style';
import { momentLocalizer, Calendar } from 'react-big-calendar';
import moment from 'moment';
import './react-calendar-style.scss';
import { MainLayoutSection } from '../../main-layout-style';
import HeaderMain from '../../../components/header-main/header-main';
import { images } from '../../../assets/images';
import PopCalendar from '../../../components/pop-calendar/pop-calendar';
import ModalPlanningActivity from '../modal-planning-activity/modal-planning-activity';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import MainButton from '../../../components/main-button';
import { colors } from '../../../assets/colors';
import { RouteComponentProps, match } from 'react-router';
import CalendarItemPlanning from '../../../components/calendar-item-planning';
import ModalCreateActivities from '../../dive-offers/activities/modal-create-activities/modal-create-activities';
import { getCentreActivitiesTable, getCentreActivities, getCentreOneStaff } from '../../../services/centre';
import { ITimeBlocks } from '../../../models/generic';
import { getTimeFormatted } from '../../../utils/time';
import { capitalize, normalize } from '../../../utils/texts';
import SelectBox from '../../../components/select-box';
import _ from 'lodash';
import { DiveKinds, DiveDisciplines } from '../../../constants/dive-dropdowns';
import { PLANNING_CALENDAR_ACTIVITY_STATES as ACTIVITY_STATES_NEW } from '../../../constants/states';
import services from '../../../services/services';
import ModalActivityConfirmation from '../../../components/modals/modal-confimation-activity/modal-confirmation-activity';
import { ContentBlock } from '../../../components/content-block/content-block';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import PlanningEndActivity from '../activity-detail/modals/planning-end-activity/planning-end-activity';
import { getOneUsers } from '../../../services/users';
import { getBookingsCentre } from '../../../services/bookings';
import { CENTRE_SERVICES_KEY_VALUE } from '../../../constants/centre-services';
import { getDiver } from '../../../services/diver';


moment.locale('ES');

export interface IPlanningCalendarProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver?: string;
  }>;
  t: any;
  isValidCentre: boolean;
  staffPermissions?: any;
}

export interface IPlanningCalendarState {
  selectedDate: Date;
  events: any;
  renderModal: string;
  filters: boolean;
  newActivityType: string;
  activities: any[];
  loaded: boolean;
  availableKinds: any[];
  availableTypes: any[];
  selectedTypes: any[];
  availableIds: any[];
  availableSites: any[];
  availableDisciplines: any[];
  availableStaff: any[];
  selectedDiscipline: any[];
  selectedStatus: string;
  selectedStatusActivity: string;
  selectedKinds: string[];
  selectedSites: string[];
  selectedIds: any[];
  selectedStaff: string[];
  searchValue: string;
  activityId?: string;
  selectedActivity?: any;
  selectedPeriod?: 'month' | 'week' | 'work_week' | 'day' | 'agenda';
  resPayments: any[];
}

const localizer = momentLocalizer(moment);


class PlanningCalendar extends React.Component<IPlanningCalendarProps, IPlanningCalendarState> {
  publicId: number = 0;

  constructor(props: IPlanningCalendarProps) {
    super(props);

    this.state = {
      selectedDate: new Date(),
      selectedPeriod: 'month',
      loaded: false,
      filters: false,
      searchValue: '',
      renderModal: '',
      newActivityType: '',
      selectedKinds: [],
      selectedTypes: [],
      selectedSites: [],
      selectedIds: [],
      selectedStaff: [],
      selectedDiscipline: [],
      selectedStatus: '',
      selectedStatusActivity: '',
      availableKinds: [],
      availableTypes: [],
      availableIds: [],
      availableSites: [],
      availableStaff: [],
      availableDisciplines: [],
      activities: [],
      events: [],
      resPayments: [],
    };
  }

  componentDidMount() {
    let centreId = localStorage.getItem('centreId') + '';
    let diverId = localStorage.getItem('diverId') + '';
    let userId = localStorage.getItem('userId') + '';

    if (diverId === 'null') {
      const diver: any = this.getUserId(userId.toString()).then(() => {
        if (diver) {
          getCentreOneStaff(centreId, diver)
            .then((resp) => {
              for (let i = 0; i < resp.data.staff_centres.length; i++) {
                if (resp.data.staff_centres[0].centre && resp.data.staff_centres[0].centre.id && (centreId === resp.data.staff_centres[0].centre.id)) {
                  localStorage.setItem('staff_centre', JSON.stringify(resp.data.staff_centres[0]));
                }
                break;
              }
            });
        }
      })
    } else {
      getCentreOneStaff(centreId, diverId)
        .then((resp) => {
          for (let i = 0; i < resp.data.staff_centres.length; i++) {
            //if (centreId === resp.data.staff_centres[0].centre.id) {
            if (resp.data.staff_centres[0].centre && resp.data.staff_centres[0].centre.id && (centreId === resp.data.staff_centres[0].centre.id)) {
              localStorage.setItem('staff_centre', JSON.stringify(resp.data.staff_centres[0]));
            }
            break;
          }
        });
    }

    this.getActivities();
  }

  async getUserId(userId: string) {
    const params = {
      filter: {
        relations: ['diver'],
      },
    };
    const resp = await getOneUsers(userId, params);
    if (resp.data) {
      //localStorage.setItem('diverId', resp.data.diver.id)
      return resp.data.diver.id;
    }
  }

  async getActivities() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { selectedDate, selectedPeriod } = this.state;

    const start = !selectedPeriod
      ? moment(selectedDate).startOf('year')
      : selectedPeriod === 'week'
        ? moment(selectedDate).startOf('week')
        : moment(selectedDate).startOf('month');
    const end = !selectedPeriod
      ? moment(selectedDate).endOf('year')
      : selectedPeriod === 'week'
        ? moment(selectedDate).endOf('week')
        : moment(selectedDate).endOf('month');


    let resPayments = await getBookingsCentre(id, {});

    if (resPayments) {
      resPayments = resPayments.data.data;
    }
    const params = {
      filter: {
        where: {
          date: { method: 'between', values: [start.toISOString(), end.toISOString()], type: 'timestamp' },
        }
      },
    };
    let activities = (await getCentreActivitiesTable(id, params)).data.data;

    let kinds: string[] = [];
    let disciplines: string[] = [];
    const availableKinds: any[] = [];
    const availableSites: any[] = [];
    const availableStaff: any[] = [];
    const availableDisciplines: any[] = [];

    let availableTypes: any[] = [];
    const sites: string[] = [];
    const staffs: string[] = [];

    activities.forEach((activity: any) => {

      if (activity.template) {
        kinds = _.union(kinds, activity.template.kind);
      }

      activity.sites.forEach((site: any) => {
        if (!sites.includes(site.id)) {
          sites.push(site.id);
          availableSites.push({
            value: site.id.toString(),
            label: site.name,
          });
        }
      });


      if (!disciplines.includes(activity.discipline)) {
        disciplines.push(activity.discipline);
      }

      if (activity.courseInfo) {
        availableTypes.push({ value: '0', label: 'Formación', });
      } else {
        availableTypes.push({ value: '1', label: 'Inmersión', });
      }

      /*if (activity.category && availableTypes && !availableTypes.includes(activity.category.id)) {
        availableTypes.push({
          value: activity.category.id.toString(),
          label: `${activity.category.name}`,
        });
      }else{
        availableTypes.push({value: '0',label: 'Todos',});
      }*/

      activity.staff_activities.forEach((staff: any) => {
        if (staff.staff && !staffs.includes(staff.staff.id)) {
          staffs.push(staff.staff.id);
          availableStaff.push({
            value: staff.staff.id.toString(),
            label: staff.staff.user ? `${staff.staff.user.name} ${staff.staff.user.surname}` : '',
          });
        }
      });

    });


    let hash: any = [];
    availableTypes = availableTypes.filter(function (current) {
      let exists = !hash[current.value];
      hash[current.value] = true;
      return exists;
    });

    DiveKinds.forEach((kind: any) => {
      if (kinds.includes(kind.value)) {
        availableKinds.push(kind);
      }
    });



    DiveDisciplines.forEach((discipline: any) => {
      if (disciplines.includes(discipline.value)) {
        availableDisciplines.push(discipline);
      }
    });


    this.setState({ activities, availableKinds, availableTypes, availableSites, availableStaff, availableDisciplines, resPayments }, () => {
      this.displayActivities();
    });
  }

  isActivityDone(activityDate: any) {
    const actualDate = new Date();
    const bookingDate = new Date(activityDate);
    return actualDate > bookingDate;
  }

  getActivityStatus(activity: any) {
    let color = '';

    this.state.resPayments.forEach((bookingList: any) => {

      /*
            URGENTE: Actividades con reservas pendientes de confirmar, R error en el cobro, R pendiente de cobro, actividades pendiente de información 24 h antes de la actividad.
            PENDIENTE: actividades con Reservas Pendiente de asignar actividad, R pendiente de cambio, R pendiente devolución.
            RESUELTA: actividades con Reservas confirmadas, R canceladas, R finalizadas.
            SIN RESERVA: color gris
      */

      if (bookingList && bookingList.activity && bookingList.activity.id === activity.id) {

        if (((bookingList.cancelled === 'noCancelled' && bookingList.confirmed === false) && (!bookingList.rejected))
          || (bookingList.payment && bookingList.payment.paymentResponse && !bookingList.payment.paymentCorrect)) {
          color = 'urgent';
        } else if ((bookingList.cancelled === 'noCancelled' && bookingList.activity.cancelled === true)) {
          color = 'pending';
        } else if ((bookingList.cancelled === 'noCancelled' && bookingList.confirmed === true && !bookingList.rejected)) {
          color = 'resolved';
        } else if (activity.cancelled && !this.isActivityDone(activity.date)) {
          // actividad cancelada y pendiente de reasignar
          color = 'pending';
        } else if (!activity.cancelled && this.isActivityDone(activity.date)) {
          if (bookingList.payment && bookingList.payment.paymentType) {
            if (bookingList.payment.paymentType.name === 'Stripe') {
              if (bookingList.payment.paymentResponse) {
                if (bookingList.payment.paymentCorrect) {
                  color = 'resolved';
                } else {
                  color = 'pending';
                }
              } else {
                color = 'resolved';
              }
            } else {
              color = 'resolved';
            }
          }
          else {
            color = 'resolved';
          }
        }
      }
    })
    return color;
  }

  displayActivities() {
    const { activities } = this.state;
    const events: any[] = [];

    activities.forEach((activity: any) => {

      this.state.availableIds.push({ value: activity.id.toString(), label: activity.id.toString() })
      if ((!activity.cancelled && !activity.deleted) || activity.bookings.length > 0) {
        //const activityExpDate = getExpirationDate(activity);
        const title = activity.name;
        let status = this.getActivityStatus(activity)

        let color = colors['SE-001/50'];
        if (status === 'urgent') {
          color = colors['CO-003/50'];
        } else if (status === 'pending') {
          color = colors['CO-002/50'];
        } else if (status === 'resolved') {
          color = colors['SE-001/50'];
        } else {
          color = colors['SE-004/10'];
        }

        let categType = '0';
        if (activity.courseInfo) {
          categType = '0';
        } else {
          categType = '1';
        }


        const id = activity.id;
        const kinds = activity.kinds ? activity.kinds : activity.template ? activity.template.kind : [];
        const sites = activity.sites;

        const types = categType;
        const bookings = activity.bookings;
        const dives = activity.dives;
        const staff = activity.staff_activities;
        const services = activity.services;
        const discipline = activity.discipline;
        let start: any, end: any, hour: any, startTime: any, endTime: any;
        const event = {
          id,
          bookings,
          title,
          end,
          hour,
          color,
          sites,
          kinds,
          types,
          status,
          staff,
          dives,
          start,
          startTime,
          discipline,
          endTime,
          allDay: false,
          services,
          /*divers*/
        };
        if (!activity.courseInfo) {
          activity.timeBlocks.forEach((timeBlock: ITimeBlocks) => {
            const ev = { ...event };

            ev.start = (timeBlock.start.date || activity.date).substring(0, 10);
            ev.end = moment(timeBlock.end.date || activity.date)
              .startOf('day')
              .toDate();
            ev.hour = getTimeFormatted(timeBlock.start.hour, timeBlock.start.minutes);
            ev.startTime = { hour: timeBlock.start.hour, minutes: timeBlock.start.minutes };
            ev.endTime = { hour: timeBlock.end.hour, minutes: timeBlock.end.minutes };
            events.push(ev);
          });
        } else if (activity.courseInfo && (activity.courseInfo.theoreticSessions || activity.courseInfo.practicalSessions)) {
          if (activity.courseInfo.theoreticSessions) {
            for (const a of activity.courseInfo.theoreticSessions) {
              const obj = a.onlineTheory ? a.onlineTheory : a.presentialTheory;
              const ev = { ...event };
              ev.hour = getTimeFormatted(obj.timeBlock.start.hour, obj.timeBlock.start.minutes);
              ev.startTime = { hour: obj.timeBlock.start.hour, minutes: obj.timeBlock.start.minutes };
              ev.endTime = { hour: obj.timeBlock.end.hour, minutes: obj.timeBlock.end.minutes };
              ev.start = (obj.timeBlock.start.date || activity.date).substring(0, 10);
              ev.end = moment(obj.timeBlock.end.date || activity.date)
                .startOf('day')
                .toDate();
              events.push(ev);
            }
          }
          if (activity.courseInfo.practicalSessions) {
            for (const a of activity.courseInfo.practicalSessions) {
              const ev = { ...event };
              const obj = a.closedWaterDives ? a.closedWaterDives : a.openWaterDives;
              ev.hour = getTimeFormatted(obj.timeBlock.start.hour, obj.timeBlock.start.minutes);
              ev.startTime = { hour: obj.timeBlock.start.hour, minutes: obj.timeBlock.start.minutes };
              ev.endTime = { hour: obj.timeBlock.end.hour, minutes: obj.timeBlock.end.minutes };
              ev.start = (obj.timeBlock.start.date || activity.date).substring(0, 10);
              ev.end = moment(obj.timeBlock.end.date || activity.date)
                .startOf('day')
                .toDate();
              events.push(ev);
            }
          }
        }
      }
    });
    this.setState({ events, loaded: true });
  }

  handleNextPrev(isNext: boolean) {
    const { selectedPeriod, selectedDate } = this.state;
    const count = isNext ? 1 : -1;
    const type =
      selectedPeriod === 'month' ? 'M' : selectedPeriod === 'week' ? 'w' : selectedPeriod === 'day' ? 'd' : 'y';
    const newDate = moment(selectedDate)
      .add(count, type)
      .toDate();
    this.setState(
      {
        selectedDate: newDate,
      },
      () => {
        if (selectedPeriod !== 'day') {
          this.getActivities();
        } else if (selectedDate.getMonth() !== newDate.getMonth()) {
          this.getActivities();
        }
      }
    );
  }

  handleChangePeriod(newPeriod?: 'month' | 'week' | 'work_week' | 'day' | 'agenda') {
    const { selectedPeriod } = this.state;
    this.setState({ selectedPeriod: newPeriod }, () => {
      if (
        !newPeriod ||
        (selectedPeriod === 'week' && newPeriod === 'month') ||
        (selectedPeriod === 'month' && newPeriod === 'week')
      ) {
        this.getActivities();
      }
    });
  }

  getDisplaySelectedDate() {
    const { selectedPeriod, selectedDate } = this.state;
    const selectedDateMoment = moment(selectedDate);
    switch (selectedPeriod) {
      case 'month':
        return `${capitalize(selectedDateMoment.format('MMMM'))} ${selectedDateMoment.format('YYYY')}`;
      case 'week':
        return `${selectedDateMoment.startOf('week').format('DD')}-${selectedDateMoment
          .endOf('week')
          .format('DD')} ${selectedDateMoment.startOf('week').format('MMM')} ${selectedDateMoment
            .startOf('week')
            .format('YY')}`;
      case 'day':
        return `${selectedDateMoment.format('DD/MM/YYYY')}`;
      default:
        return `${selectedDateMoment.format('YYYY')}`;
    }
  }

  handleClickAnualDay(day: string) {
    const { selectedDate } = this.state;
    this.setState({
      selectedDate: moment(day)
        .year(moment(selectedDate).get('year'))
        .toDate(),
      selectedPeriod: 'day',
    });
  }

  handleSelectEvent(event: any) {
    const { activities } = this.state;
    const selectedActivity = activities.filter(act => act.id === event.id);
    if (selectedActivity.length > 0) {
      this.setState({ renderModal: 'pop', selectedActivity: selectedActivity[0] });
    }
  }

  closeModal() {
    this.setState({ renderModal: '' });
  }

  getMonthDayActivities(monthDate: string) {
    const month = moment(monthDate);
    const events = this.getEvents();
    const planningMonth: any = {};
    for (let i = 0; i < month.daysInMonth(); i += 1) {
      const day = moment()
        .year(month.year())
        .month(month.month())
        .day(i);
      events.forEach((event: any) => {
        const activityDate = moment(event.start);
        if (activityDate.isSame(day, 'month') && activityDate.isSame(day, 'year')) {
          if (!planningMonth[activityDate.format('DD-MM-YYYY')]) {
            planningMonth[activityDate.format('DD-MM-YYYY')] = {};
          }
          if (event.status) {
            planningMonth[activityDate.format('DD-MM-YYYY')][event.status] = true;
          }
        }
      });
    }
    return planningMonth;
  }

  eventMap = (e: any) => {
    const start = new Date(e.start);
    start.setHours(e.startTime.hour);
    start.setMinutes(e.startTime.minutes);
    const end = new Date(e.end);
    end.setHours(e.endTime.hour);
    end.setMinutes(e.endTime.minutes);

    return { ...e, start, end };
  };

  getEvents() {
    const {
      events,
      selectedKinds,
      selectedTypes,
      selectedSites,
      selectedIds,
      selectedStatus,
      selectedStatusActivity,
      selectedStaff,
      selectedDiscipline,
      searchValue,
      selectedPeriod,
      filters,
    } = this.state;
    let filteredEvents = events;

    if (filters && selectedKinds.length > 0) {
      filteredEvents = filteredEvents.filter((event: any) => {
        let valid = false;
        selectedKinds.forEach((kind: string) => {
          if (event.kinds && event.kinds.includes(kind)) {
            valid = true;
          }
        });
        return valid;
      });
    }


    if (filters && selectedDiscipline.length > 0) {

      filteredEvents = filteredEvents.filter((event: any) => {
        let valid = false;
        selectedDiscipline.forEach((discipline: string) => {
          if (event.discipline && event.discipline.includes(discipline)) {
            valid = true;
          }
        });
        return valid;
      });
    }


    if (filters && selectedIds.length > 0) {
      filteredEvents = filteredEvents.filter((event: any) => {
        let valid = false;
        selectedIds.forEach((id: string) => {
          if (event.id.toString() === id) {
            valid = true;
          }
        });
        return valid;
      });
    }

    if (filters && selectedTypes.length > 0) {
      filteredEvents = filteredEvents.filter((event: any) => {
        let valid = false;
        if (event.types === '0') {
          valid = true;
        }
        else {
          selectedTypes.forEach((type: string) => {
            if (event.types && event.types.length > 0) {
              for (let i = 0; i < event.types.length; i++) {
                if (event.types[i] === type) {
                  valid = true;
                }
              }

            }
          });
        }
        return valid;
      });
    }

    if (filters && selectedSites.length > 0) {
      filteredEvents = filteredEvents.filter((event: any) => {
        let valid = false;
        selectedSites.forEach(site => {
          if (event.sites && event.sites.map((site: any) => site.id.toString()).includes(site)) {
            valid = true;
          }
        });
        return valid;
      });
    }

    if (filters && selectedStaff.length > 0) {
      filteredEvents = filteredEvents.filter((event: any) => {
        let valid = false;
        selectedStaff.forEach(staff => {
          if (event.staff && event.staff.map((s: any) => s.staff.id.toString()).includes(staff)) {
            valid = true;
          }
        });
        return valid;
      });
    }

    if (filters && selectedStatus !== '' && selectedStatus !== '*') {
      filteredEvents = filteredEvents.filter((event: any) => {
        let valid = true;
        if (event.status !== selectedStatus) {
          valid = false;
        }
        return valid;
      });
    }

    if (filters && selectedStatusActivity !== '' && selectedStatusActivity !== '*') {
      filteredEvents = filteredEvents.filter((event: any) => {
        let valid = true;
        if (event.status !== selectedStatusActivity) {
          valid = false;
        }
        return valid;
      });
    }

    if (searchValue !== '') {
      filteredEvents = filteredEvents.filter((event: any) => {

        let res = false;

        if (normalize(event.title).includes(normalize(searchValue))) {
          res = normalize(event.title).includes(normalize(searchValue))
        }
        if (event.sites.length > 0) {
          for (let i = 0; i < event.sites.length; i++) {
            if (normalize(event.sites[i].name).includes(normalize(searchValue))) {
              res = normalize(event.sites[i].name).includes(normalize(searchValue))
            }

          }
        }
        if (event.staff.length > 0) {
          for (let i = 0; i < event.staff.length; i++) {
            if (event.staff[i].staff.user.name && normalize(event.staff[i].staff.user.name).includes(normalize(searchValue))) {
              res = normalize(event.staff[i].staff.user.name).includes(normalize(searchValue))
            }
            if (event.staff[i].staff.user.surname && normalize(event.staff[i].staff.user.surname).includes(normalize(searchValue))) {
              res = normalize(event.staff[i].staff.user.surname).includes(normalize(searchValue))
            }
          }
        }
        if (event.bookings.length > 0) {
          for (let i = 0; i < event.bookings.length; i++) {
            if (event.bookings[i].bookingId && normalize(event.bookings[i].bookingId).includes(normalize(searchValue))) {
              res = normalize(event.bookings[i].bookingId).includes(normalize(searchValue))
            }
            if (event.bookings[i].diverData && event.bookings[i].diverData.user) {
              if (event.bookings[i].diverData.user.name && normalize(event.bookings[i].diverData.user.name).includes(normalize(searchValue))) {
                res = normalize(event.bookings[i].diverData.user.name).includes(normalize(searchValue))
              }
              if (event.bookings[i].diverData.user.email && normalize(event.bookings[i].diverData.user.email).includes(normalize(searchValue))) {
                res = normalize(event.bookings[i].diverData.user.email).includes(normalize(searchValue))
              }
            }

          }
        }
        if (event.services.length > 0) {
          for (let i = 0; i < event.services.length; i++) {
            if (event.services[i].name && normalize(CENTRE_SERVICES_KEY_VALUE[event.services[i].name]).includes(normalize(searchValue))) {
              res = normalize(CENTRE_SERVICES_KEY_VALUE[event.services[i].name]).includes(normalize(searchValue))
            }
          }
        }

        return res;

      });
    }

    if (selectedPeriod === 'month') {
      filteredEvents = filteredEvents.map((event: any) => ({
        ...event,
        title: `${event.title} - (${event.hour})`,
      }));
    }
    return filteredEvents;
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t,
      staffPermissions,
    } = this.props;
    const {
      renderModal,
      filters,
      newActivityType,
      activityId,
      selectedPeriod,
      selectedDate,
      selectedActivity,
      loaded,
      availableKinds,
      availableTypes,
      availableSites,
      availableDisciplines,
      availableStaff,
      selectedKinds,
      selectedTypes,
      selectedSites,
      selectedStatus,
      selectedDiscipline,
      selectedStaff,
    } = this.state;
    const displaySelectedDate = this.getDisplaySelectedDate();
    const months = Array.from(Array(12).keys());
    const events = this.getEvents();
    return (
      <>
        <ContentBlock>
          <HeaderMain
            title={'Planificación'}
            withSearch={true}
            resultCount={events.length}
            handleSearch={(value: string) => {
              if (!value) this.setState({ searchValue: '' });
              else this.setState({ searchValue: value });
            }}
            mainButton={
              !staffPermissions || staffPermissions.activities
                ? {
                  img: images.arrowUpRightWhiteSvg,
                  text: 'Planificar',
                  onClick: () => this.setState({ renderModal: 'modal-plan-activity' }),
                }
                : undefined
            }
          />
          <MainLayoutSection>
            <PlanningCalendarStyle>
              <div className="planning-calendar-header">
                <div className="planning-calendar-header-left">
                  <div className="planning-calendar-header-current">
                    <div className="planning-calendar-header-current__arrow" onClick={() => this.handleNextPrev(false)}>
                      <img src={images.arrowLeft2Svg} alt="" />
                    </div>
                    <div className="planning-calendar-header-current__date">
                      <BodyDestacadoText>{displaySelectedDate}</BodyDestacadoText>
                    </div>
                    <div className="planning-calendar-header-current__arrow" onClick={() => this.handleNextPrev(true)}>
                      <img src={images.arrowRight2Svg} alt="" />
                    </div>
                  </div>

                  <div className="planning-calendar-header-item">
                    <MainButton
                      type={`secondary ${selectedPeriod === 'day' ? 'secondary-active' : ''}`}
                      onClick={() => this.handleChangePeriod('day')}
                      text="Diario"
                    />
                  </div>

                  <div className="planning-calendar-header-item">
                    <MainButton
                      type={`secondary ${selectedPeriod === 'week' ? 'secondary-active' : ''}`}
                      onClick={() => this.handleChangePeriod('week')}
                      text="Semanal"
                    />
                  </div>

                  <div className="planning-calendar-header-item">
                    <MainButton
                      type={`secondary ${selectedPeriod === 'month' ? 'secondary-active' : ''}`}
                      onClick={() => this.handleChangePeriod('month')}
                      text="Mensual"
                    />
                  </div>

                  <div className="planning-calendar-header-item">
                    <MainButton
                      type={`secondary ${selectedPeriod === undefined ? 'secondary-active' : ''}`}
                      onClick={() => this.handleChangePeriod()}
                      text="Anual"
                    />
                  </div>
                </div>
                <div className="planning-calendar-header-right">
                  <MainButton
                    iconImg={filters ? images.filtersLightBlueSvg : images.filtersSvg}
                    text="Filtrar"
                    type={`${filters ? 'secondary-active' : 'secondary'}`}
                    onClick={() => this.setState({ filters: !filters })}
                  />
                </div>
              </div>
              {filters && (
                <div className="planning-calendar-filters">
                  <div className="planning-calendar-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Tipo de inmersión"
                      required={true}
                      className="rounded-filter"
                      multiple={true}
                      optionsText={availableKinds}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedKinds}
                      initialMultipleValues={selectedKinds}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: any[]) => {
                        this.setState({ selectedKinds: values });
                      }}
                    />
                  </div>
                  <div className="planning-calendar-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Disciplina"
                      required={true}
                      className="rounded-filter"
                      multiple={true}
                      optionsText={availableDisciplines}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedDiscipline}
                      initialMultipleValues={selectedDiscipline}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: any[]) => {
                        this.setState({ selectedDiscipline: values });
                      }}
                    />
                  </div>


                  <div className="planning-calendar-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Tipo de actividad"
                      required={true}
                      className="rounded-filter"
                      multiple={true}
                      optionsText={availableTypes}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedTypes}
                      initialMultipleValues={selectedTypes}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: any[]) => {
                        this.setState({ selectedTypes: values });
                      }}
                    />
                  </div>
                  <div className="planning-calendar-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Punto de inmersión"
                      required={true}
                      multiple={true}
                      className="rounded-filter"
                      optionsText={availableSites}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedSites}
                      initialMultipleValues={selectedSites}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: string[]) => {
                        this.setState({ selectedSites: values });
                      }}
                    />
                  </div>
                  <div className="planning-calendar-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Estado Reservas"
                      required={true}
                      className="rounded-filter"
                      optionsText={[{ value: '*', label: 'Todos' }].concat(ACTIVITY_STATES_NEW)}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={selectedStatus}
                      initialValue={selectedStatus}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => {
                        this.setState({ selectedStatus: value });
                      }}
                    />
                  </div>
                  {/*
                    <div className="planning-calendar-filters__item">
                      <SelectBox
                        labelText=""
                        placeholder="Estado actividad"
                        required={true}
                        className="rounded-filter"
                        optionsText={[{ value: '*', label: 'Todos' }].concat(ACTIVITY_STATES)}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={selectedStatusActivity}
                        initialValue={selectedStatusActivity}
                        icon={images.arrowDown2BlueSvg}
                        onChange={(value: any) => {
                          this.setState({ selectedStatusActivity: value });
                        }}
                      />
                    </div>
                  */}
                  <div className="planning-calendar-filters__item">
                    <SelectBox
                      labelText=""
                      placeholder="Staff"
                      required={true}
                      multiple={true}
                      className="rounded-filter"
                      optionsText={availableStaff}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultMultipleValues={selectedStaff}
                      initialMultipleValues={selectedStaff}
                      icon={images.arrowDown2BlueSvg}
                      onChange={(value: any) => { }}
                      onChangeMultiple={(values: string[]) => {
                        this.setState({ selectedStaff: values });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="caption-container">
                <div className="caption-container__item">
                  <div className="caption-container__circle red" />
                  {'Urgente'}
                </div>
                <div className="caption-container__item">
                  <div className="caption-container__circle yellow" />
                  {'Pendiente'}
                </div>
                <div className="caption-container__item">
                  <div className="caption-container__circle blue" />
                  {'Resuelta'}
                </div>
                <div className="caption-container__item">
                  <div className="caption-container__circle grey" />
                  {'Sin reservas'}
                </div>
              </div>
              {selectedPeriod ? (
                <Calendar
                  onView={() => { }}
                  onNavigate={() => { }}
                  localizer={localizer}
                  date={selectedDate}
                  defaultView="month"
                  view={selectedPeriod}
                  // events={selectedPeriod === 'month' ? events : events.map(this.eventMap)}
                  events={events.map(this.eventMap)}
                  popup={true}
                  formats={{
                    weekdayFormat: 'dddd',
                  }}
                  showMultiDayTimes={false}
                  selectable={true}
                  className={'planning-calendar'}
                  onSelectEvent={event => this.handleSelectEvent(event)}
                  eventPropGetter={(event: any) => {
                    const newStyle = {
                      backgroundColor: 'lightgrey',
                      color: 'black',
                      borderRadius: '0 0 5px 5px',
                      border: 'none',
                    };
                    if (event.color) {
                      newStyle.backgroundColor = event.color;
                    }
                    return {
                      className: '',
                      style: newStyle,
                    };
                  }}
                />
              ) : (
                loaded && (
                  <div className="anual-calendar">
                    {months.map((month: number) => (
                      <div key={month} className="anual-calendar__item">
                        <CalendarItemPlanning
                          className="anual"
                          initialValue={moment(selectedDate)
                            .month(month)
                            .toISOString()}
                          onClickDay={day => {
                            this.handleClickAnualDay(day);
                          }}
                          eventPoints={this.getMonthDayActivities(
                            moment(selectedDate)
                              .month(month)
                              .toISOString()
                          )}
                        />
                      </div>
                    ))}
                  </div>
                )
              )}
            </PlanningCalendarStyle>
          </MainLayoutSection>
          {/* Sale al dar en una actividad del calendario */}
          {renderModal === 'pop' && (
            <PopCalendar
              centreId={id}
              activityId={selectedActivity.id.toString()}
              showModal={renderModal === 'pop'}
              onClose={() => this.closeModal()}
              onConfirm={() => this.setState({ renderModal: 'modal-confirm-activity' })}
              onFinish={() => this.setState({ renderModal: 'modal-finish-activity' })}
            />
          )}
          {renderModal === 'modal-confirm-activity' && (
            <ModalActivityConfirmation
              activity={selectedActivity}
              centreId={id}
              showModal={renderModal === 'modal-confirm-activity'}
              onAccept={() => {
                services.pushNotification({
                  title: '¡Confirmada!',
                  text: 'La actividad ha sido confirmada con éxito',
                  type: 'green',
                });
                this.getActivities();
                this.setState({ renderModal: '' });
              }}
              onCancel={() => this.closeModal()}
            />
          )}
          {renderModal === 'modal-plan-activity' && (
            <ModalPlanningActivity
              onCreateActivity={(activitiesType: string) =>
                this.setState({ newActivityType: activitiesType, renderModal: 'modal-create-activity' })
              }
              onSuccess={() => {
                services.showModalSuccess('¡Actividad planificada!', 'La actividad se ha planificado correctamente.');
                this.setState({ activityId: undefined }, () => {
                  this.closeModal();
                  this.getActivities();
                });
              }}
              centreId={id}
              onCancel={() => this.closeModal()}
              showModal={renderModal === 'modal-plan-activity'}
              activityId={activityId}
            />
          )}
          {renderModal === 'modal-create-activity' && (
            <ModalCreateActivities
              newActivityType={newActivityType}
              centreId={id}
              onSuccess={(id?: string) => {
                this.setState({ activityId: id, renderModal: 'modal-plan-activity' });
              }}
              onCancel={() => this.closeModal()}
              showModal={renderModal === 'modal-create-activity'}
            />
          )}
          {selectedActivity && renderModal === 'modal-finish-activity' && (
            <PlanningEndActivity
              centreId={id}
              activity={selectedActivity}
              showModal={renderModal === 'modal-finish-activity'}
              onSuccess={() => {
                services.pushNotification({
                  title: '¡Finalizada!',
                  text: 'La actividad se ha finalizado correctamente',
                  type: 'green',
                });
                this.setState({ renderModal: '' }, () => this.getActivities());
              }}
              onCancel={() => this.setState({ renderModal: '' })}
            />
          )}
        </ContentBlock>
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(PlanningCalendar);
