import * as React from 'react';
import { CreateActivitiesPricesStyle } from './create-activities-prices-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../main-layout-style';
import InputBox from '../../../../../components/input-box';
import SelectBox from '../../../../../components/select-box';
import { images } from '../../../../../assets/images';
import SingleButton from '../../../../../components/single-button/single-button';
import MainButton from '../../../../../components/main-button';
import { IDropdown } from '../../../../../models/generic';
import { getCancellationPolicies } from '../../../../../services/cancellation-policies';
import IStoreState from '../../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { POLICIES_KEY_VALUE } from '../../../../../constants/policies';
import Checkbox from '../../../../../components/form-components/checkbox/checkbox';
import BodyText from '../../../../../components/text/body-text/body-text';



export interface ICreateActivitiesPricesProps {
  price?: number;
  cancellationPolicy?: number;
  onPrev?: () => void;
  onNext: (price: number, cancellationPolicy: number, updateAllPrices?:boolean ) => void;
  centreId?: string;
  centreCurrency?: string;
  updateAllPrices?:boolean 
}

export interface ICreateActivitiesPricesState {
  price: number;
  cancellationPolicy: number;
  cancellationPolicies: IDropdown[];
  updateAllPrices?:boolean;
  errors: {
    price: string;
    cancellationPolicy: string;
  };
}

class CreateActivitiesPrices extends React.Component<ICreateActivitiesPricesProps, ICreateActivitiesPricesState> {
  constructor(props: ICreateActivitiesPricesProps) {
    super(props);

    this.state = {
      price: NaN,
      cancellationPolicy: NaN,
      cancellationPolicies: [],
      updateAllPrices: false,
      errors: {
        price: '',
        cancellationPolicy: '',
      },
    };
  }

  componentDidMount() {
    const { price, cancellationPolicy } = this.props;
    if (price !== undefined && cancellationPolicy !== undefined) {
      this.setState({ cancellationPolicy, price: price / 100 }, () => this.getCancellationPolicies());
    } else {
      this.getCancellationPolicies();
    }
  }

  componentDidUpdate(prevProps: ICreateActivitiesPricesProps) {
    const { price, cancellationPolicy } = this.props;
    if (
      price !== undefined &&
      cancellationPolicy !== undefined &&
      price !== prevProps.price &&
      cancellationPolicy !== prevProps.cancellationPolicy
    ) {
      this.setState({ cancellationPolicy, price: price / 100 }, () => this.getCancellationPolicies());
    }
  }

  async getCancellationPolicies() {
    const resp = await getCancellationPolicies();
    // const resp2 = await getCentre(centreId);
    // const availablePolicies: any = resp2.data.cancellation;
    const cancellationPolicies = resp.data.data
      .filter((policy: any) => policy.active)
      .map((cancellationPolicy: any) => {
        // if (availablePolicies[cancellationPolicy.type]) {
        return {
          label: POLICIES_KEY_VALUE[cancellationPolicy.type],
          value: cancellationPolicy.id.toString(),
        };
        // }
      });
    this.setState({ cancellationPolicies });
  }

  handleNext() {
    const { onNext } = this.props;
    const { price, cancellationPolicy, updateAllPrices} = this.state;
    const errors = {
      price: '',
      cancellationPolicy: '',
    };
    let hasError = false;
    if (!price) {
      errors.price = 'required';
      hasError = true;
    }
    if (!cancellationPolicy) {
      errors.cancellationPolicy = 'required';
      hasError = true;
    }
    this.setState({ errors });
    if (!hasError) {
      onNext(Math.round(price * 100), cancellationPolicy, updateAllPrices)
    }
  }

  render() {
    const { onPrev, centreCurrency } = this.props;
    const { cancellationPolicy, price, errors, cancellationPolicies } = this.state;
 
    return (
      <CreateActivitiesPricesStyle>
        <FormGrid>
          <FormGridRow>
            {/*
            <FormGridItem type="col-2">
              <SelectBox
                className="rounded"
                labelText=""
                placeholder="Moneda"
                required={true}
                initialValue={''}
                optionsText={CURRENCIES}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={''}
                icon={images.arrowDown2BlueSvg}
                onChange={() => {}}
              />
            </FormGridItem>
            */}
          </FormGridRow>
          <FormGridRow>
            <FormGridItem type="col-2">
              <InputBox
                errorCode={errors.price}
                placeholder={`Precio de la actividad (${centreCurrency})`}
                className="rounded-title"
                labelText="precio de la actividad"
                type="number"
                value={price.toString()}
                required={true}
                onChange={value => this.setState({ price: parseFloat(value) })}
              />
            </FormGridItem>
            <FormGridItem type="col-2">
              <SelectBox
                errorCode={errors.cancellationPolicy}
                className="rounded-title"
                labelText="política de cancelación"
                placeholder="Política de cancelación"
                required={true}
                initialValue={cancellationPolicy ? cancellationPolicy.toString() : ''}
                optionsText={cancellationPolicies}
                optionKey={'value'}
                optionValue={'label'}
                defaultValue={cancellationPolicy ? cancellationPolicy.toString() : ''}
                icon={images.arrowDown2BlueSvg}
                onChange={value => this.setState({ cancellationPolicy: parseInt(value, undefined) })}
              />
            </FormGridItem>
          </FormGridRow>
          <FormGridRow>
          <div style={{display:"flex", marginBottom:"40px", alignItems:"center"}}>
            <Checkbox onChange={() => {this.setState({updateAllPrices: !this.state.updateAllPrices})}} checked={this.state.updateAllPrices}/><BodyText color='#BCC5D7'>Cambiar el precio en todas las actividades relacionadas</BodyText>
            </div>
          </FormGridRow>
        </FormGrid>
        <div className="modal-create-activities-buttons">
          <div className="modal-create-activities-buttons-left">
            {onPrev && (
              <div className="modal-create-activities-buttons-left__back">
                <SingleButton className={'secondary'} icon={images.arrowLeftSvg} onClick={() => onPrev()} />
              </div>
            )}
            <div className="modal-create-activities-buttons-left__next">
              <MainButton text={onPrev ? 'Siguiente' : 'Guardar'} type="primary" onClick={() => {
                this.handleNext()
              }} />
            </div>
          </div>
        </div>
      </CreateActivitiesPricesStyle>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    centreCurrency: state.centreCurrency.value,
  };
}

export default connect(mapStateToProps)(CreateActivitiesPrices);
