import * as React from 'react';
import ModalContainer from '../../../components/modals/modal-container';
import { ModalTitlePhotosStyle } from './modal-title-photo-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../containers/main-layout-style';
import MainButton from '../../main-button';
import InputArea from '../../input-area/input-area';
import { isValidURL } from '../../../utils/validation';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

export interface IModalTitlePhotoProps extends RouteComponentProps, WithTranslation {
  onCancel: Function;
  showModal: boolean;
  onAccept: Function;
  idPicture?: string;
  picture: any;
  t: any
}
export interface IModalTitlePhotoState {
  picture: any; // TO DO
  pictureObject: any;
  languages: any[];
  selectedLanguage: string;
}

class ModalTitlePhoto extends React.Component<IModalTitlePhotoProps, IModalTitlePhotoState> {
  readonly state: IModalTitlePhotoState;
  constructor(props: IModalTitlePhotoProps) {
    super(props);
    this.state = {
      picture: {
        // main: {
        //   value: props.picture.main || '',
        //     error: false,
        //     errorCode: '',
        //     required: true,
        // },
        description: {
          es: {
            value: props.picture.description ? props.picture.description.es : '',
            error: false,
            errorCode: '',
            required: true,
          },
          pt: {
            value: props.picture.description ? props.picture.description.pt : '',
            error: false,
            errorCode: '',
            required: true,
          },
          en: {
            value: props.picture.description ? props.picture.description.en : '',
            error: false,
            errorCode: '',
            required: true,
          },
          fr: {
            value: props.picture.description ? props.picture.description.fr : '',
            error: false,
            errorCode: '',
            required: true,
          },
          it: {
            value: props.picture.description ? props.picture.description.it : '',
            error: false,
            errorCode: '',
            required: true,
          },
          de: {
            value: props.picture.description ? props.picture.description.de : '',
            error: false,
            errorCode: '',
            required: true,
          },
        },
      },
      pictureObject: {},
      languages: [
        { value: 'es', label: 'ES' },
        { value: 'pt', label: 'PT' },
        { value: 'en', label: 'EN' },
        { value: 'fr', label: 'FR' },
        { value: 'it', label: 'IT' },
        { value: 'de', label: 'DE' },
      ],
      selectedLanguage: 'es',
    };
  }

  changeValue = (value: string, key: string, subKey?: string) => {
    const { picture } = this.state;
    if (!subKey) {
      picture[key].value = value;
    } else {
      picture[key][subKey].value = value;
    }

    this.setState({ picture });
  };

  handleAccept = () => {
    const { picture } = this.state;
    const { onAccept } = this.props;
    let isOk = true;
    Object.keys(picture).forEach((k: string) => {
      if (picture[k].required && picture[k].value === '') {
        picture[k].error = true;
        picture[k].errorCode = 'required';
        isOk = false;
      } else if (k === 'description') {
        Object.keys(picture[k]).forEach((kd: string) => {
          if (picture[k][kd].required && picture[k][kd].value === '') {
            picture[k][kd].error = true;
            picture[k][kd].errorCode = 'required';
            isOk = false;
          } else {
            picture[k][kd].error = false;
            picture[k][kd].errorCode = '';
          }
        });
      } else {
        picture[k].error = false;
        picture[k].errorCode = '';
      }
    });
    this.setState({ picture });
    if (isOk) {
      const description: any = {};
      Object.keys(picture.description).forEach((k: string) => {
        description[k] = picture.description[k].value;
      });
      const data: any = {};
      Object.keys(picture).forEach((k: string) => {
        if (k === 'description') {
          data[k] = description;
        } else {
          data[k] = picture[k].value;
        }
      });

      onAccept(data);
    }
  };

  render() {
    const { onCancel, showModal, t } = this.props;
    const { picture, languages, selectedLanguage } = this.state;
    return (
      <ModalContainer title={'Editar titulo'} className="" modalClose={() => onCancel()} active={showModal}>
        <ModalTitlePhotosStyle>
          <div className="modal-create-ad-section">
            <div className="modal-create-ad-section__form">
              <FormGrid>
                <div className={'modal-create-add-languages'}>
                  {languages.map((k: any, index: number) => (
                    <div className={'modal-create-add-languages__item'} key={index}>
                      <MainButton
                        onClick={() => this.setState({ selectedLanguage: k.value })}
                        text={k.label}
                        type={
                          selectedLanguage === k.value
                            ? 'primary'
                            : !picture.description[k.value].error
                              ? 'secondary'
                              : 'secondary-red'
                        }
                      />
                    </div>
                  ))}
                </div>

                <FormGridRow key={`description-${selectedLanguage}`}>
                  <FormGridItem type="col-0">
                    <InputArea
                      name={selectedLanguage}
                      placeholder={t('components:general.description') `${selectedLanguage}`}
                      required={picture.description[selectedLanguage].required}
                      errorCode={picture.description[selectedLanguage].errorCode}
                      error={picture.description[selectedLanguage].error}
                      value={picture.description[selectedLanguage].value}
                      onChange={(e: any) => this.changeValue(e.target.value, 'description', selectedLanguage)}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>

          <div className="modal-create-ad__button">
            <div className="modal-create-ad__button__item">
              <MainButton type="primary" text={t('components:actions.save')} onClick={() => this.handleAccept()} />
            </div>
            <div className="modal-create-ad__button__item">
              <MainButton type="secondary" text={t('components:actions.cancel')} onClick={() => onCancel()} />
            </div>
          </div>
        </ModalTitlePhotosStyle>
      </ModalContainer>
    );
  }
}

export default withTranslation(['modalMessages','components'])(withRouter(ModalTitlePhoto));