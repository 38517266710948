import React from 'react';
import { ModalAddServiceContainer } from './modal-add-service-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../containers/main-layout-style';
import InputBox from '../../input-box';
import MainButton from '../../main-button';
import ModalContainer from '../modal-container';
import { images } from '../../../assets/images';
import SelectBox from '../../select-box';
import { SINGLE_DURATION_OPTIONS } from '../../../constants/duration';
import { addService, editService } from '../../../services/centre';
import { ICentreService } from '../../../models/centre-forms';
import { copyOf } from '../../../utils/other';
import InputArea from '../../../components/input-area/input-area';
import { CENTRE_SERVICES_KEY_VALUE, CENTRE_SERVICES } from '../../../constants/centre-services';
import { LANGUAGES, LANGUAGES_KEY_VALUES } from '../../../constants/languages';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

const languagesTemplate = {
  en: '',
  es: '',
  de: '',
  fr: '',
  it: '',
  pt: '',
};

interface ModalAddServiceProps extends RouteComponentProps, WithTranslation {
  centreId: string;
  showModal: boolean;
  onClose: () => void;
  onSuccess: () => void;
  service?: ICentreService;
  t: any
}

interface ModalAddServiceState {
  service: ICentreService;
  selectedLanguage: string;
  selectedLanguages: string[];
  errors: {
    name: string;
    duration: string;
    price: string;
    description: any;
  };
}

class ModalAddService extends React.Component<ModalAddServiceProps, ModalAddServiceState> {
  constructor(props: ModalAddServiceProps) {
    super(props);
    this.state = {
      service: {
        name: '',
        duration: {
          units: 'hour',
          amount: 1,
        },
        price: NaN,
        description: {},
      },
      selectedLanguage: '',
      selectedLanguages: ['es'],
      errors: {
        name: '',
        duration: '',
        price: '',
        description: copyOf(languagesTemplate),
      },
    };
  }

  componentDidMount() {
    const { service } = this.props;
    if (service) {
      const newService = copyOf(service);
      let newSelectedLanguages: any[] = [];
      newService.price = newService.price / 100;

      if (service.description) {
        newService.description = service.description;
        newSelectedLanguages = Object.keys(service.description);
      }
      this.setState({ service: newService, selectedLanguages: newSelectedLanguages });
    }
  }

  componentDidUpdate(prevProps: ModalAddServiceProps) {
    const { service } = this.props;
    if (service && service !== prevProps.service) {
      const newService = copyOf(service);
      let newSelectedLanguages: any[] = [];
      newService.price = newService.price / 100;

      if (service.description && prevProps.service && prevProps.service.description !== service.description) {
        newService.description = service.description;
        newSelectedLanguages = Object.keys(service.description)
      }
      this.setState({ service: newService, selectedLanguages: newSelectedLanguages })
    }
  }

  handleChange(field: string, value: string | number, subfield?: string) {
    const { service } = Object.assign(this.state);
    if (subfield) service[field][subfield] = value;
    else service[field] = value;
    this.setState({ service });
  }

  handleChangeDescription(description: string) {
    const { service, selectedLanguage, errors } = Object.assign(this.state);
    service.description[selectedLanguage] = description;
    if (!description || description === '') errors[selectedLanguage] = 'required';
    else errors[selectedLanguage] = '';

    const newService = copyOf(service);
    newService.description = service.description;

    this.setState({ service: newService, errors });
  }

  validateForm() {
    const { service, errors } = this.state;
    let error = false;
    if (service.name === '') {
      errors.name = 'required';
      error = true;
    } else {
      errors.name = '';
    }
    if (service.duration.units !== 'activity' && (!service.duration.amount || service.duration.amount <= 0)) {
      errors.duration = 'required';
      error = true;
    } else {
      errors.duration = '';
    }
    if (!service.price || service.price <= 0) {
      errors.price = 'required';
      error = true;
    } else {
      errors.price = '';
    }
    this.setState({ errors });
    return error;
  }

  async handleSubmit() {
    const { service } = this.state;
    const { centreId, onSuccess } = this.props;
    const error = this.validateForm();
    if (!error) {
      service.price = Math.round(service.price * 100);
      if (service.duration.units === 'activity') service.duration.amount = 1;
      if (service.id !== undefined) {
        await editService(centreId, service.id.toString(), service);
      } else {
        await addService(centreId, service);
      }
      onSuccess();
    }
  }

  render() {
    const { onClose, showModal, t } = this.props;
    const { service, selectedLanguage, selectedLanguages, errors } = this.state;

    if (
      (selectedLanguage === '' && selectedLanguages.length > 0) ||
      (selectedLanguages.length > 0 && !selectedLanguages.includes(selectedLanguage))
    ) {
      //this.setState({ selectedLanguage: selectedLanguages[0] });
      
      this.setState({ selectedLanguage: "es" })
    }
    console.log(CENTRE_SERVICES_KEY_VALUE)
    return (
      <ModalContainer title={'Añadir servicio'} className="" modalClose={() => onClose()} active={showModal}>
        <ModalAddServiceContainer>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      className="rounded"
                      labelText=""
                      placeholder={'Seleccione servicio'}
                      optionsText={CENTRE_SERVICES}
                      optionKey={'value'}
                      optionValue={'label'}
                      errorCode={errors.name}
                      defaultValue={service.name}
                      initialValue={service.name}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => this.handleChange('name', value)}
                    />
                  </FormGridItem>
                </FormGridRow>

                <FormGridRow>
                  {/*<FormGridItem type="col-0">
                    <SelectBox
                      className="rounded-title"
                      labelText="Descripción"
                      placeholder="Idiomas mostrados"
                      multiple={true}
                      required={true}
                      initialMultipleValues={selectedLanguages}
                      errorCode={errors.description}
                      optionsText={LANGUAGES}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={''}
                      icon={images.arrowDown2BlueSvg}
                      onChange={() => { }}
                      onChangeMultiple={selectedLanguages => this.setState({ selectedLanguages: selectedLanguages })}
                    />
                  </FormGridItem>
                </FormGridRow>
                <div className={'modal-create-add-languages'}>
                  {selectedLanguages.map((lang: string, index: number) => (
                    <div className={'modal-create-add-languages__item'} key={index}>
                      <MainButton
                        onClick={() => this.setState({ selectedLanguage: lang })}
                        text={LANGUAGES_KEY_VALUES[lang]}
                        type={selectedLanguage === lang ? 'primary' : errors.description[lang] === '' ? 'secondary' : 'secondary-red'}
                      />
                    </div>
                  ))}
                </div>
                selectedLanguage !== '' && selectedLanguages.includes(selectedLanguage) && (
                  <FormGridRow key={`description-${selectedLanguage}`}>
                    <FormGridItem type="col-0">
                      <InputArea
                        name={selectedLanguage}
                        placeholder={`Descripción ${selectedLanguage}`}
                        required={true}
                        showMessage={errors.description[selectedLanguage] !== ''}
                        errorCode={errors.description[selectedLanguage]}
                        value={service.description[selectedLanguage]}
                        onChange={(event: any) => this.handleChangeDescription(event.target.value)}
                      />
                    </FormGridItem>)*/}
                  </FormGridRow>
                
                  <FormGridRow key={`description-es`}>
                    <FormGridItem type="col-0">
                      <InputArea
                        disabled={false}
                        name="es"
                        placeholder={`Descripción es`}
                        required={true}
                        showMessage={errors.description["es"] !== ''}
                        errorCode={errors.description["es"]}
                        value={service.description["es"]}
                        onChange={(event: any) => this.handleChangeDescription(event.target.value)}
                      />
                    </FormGridItem>
                  </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-2">
                    <InputBox
                      // errorCode="Error"
                      placeholder={t('components:general.duration')}
                      className="rounded"
                      type="number"
                      errorCode={errors.duration}
                      value={service.duration.amount.toString()}
                      labelText={t('components:general.duration')}
                      onChange={value => this.handleChange('duration', parseFloat(value), 'amount')}
                    />
                  </FormGridItem>
                  <div className="duration-units">
                    <FormGridItem type={'col-0'}>
                      <SelectBox
                        className="rounded"
                        labelText={t('components:actions.choose')}
                        placeholder={t('components:general.duration')}
                        required={true}
                        optionsText={SINGLE_DURATION_OPTIONS}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={service.duration.units}
                        initialValue={service.duration.units}
                        icon={images.arrowDown2BlueSvg}
                        onChange={value => this.handleChange('duration', value, 'units')}
                      />
                    </FormGridItem>
                  </div>
                </FormGridRow>

                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      // errorCode="Error"
                      placeholder={t('components:general.priceCaptl') + '(€)'}
                      className="rounded"
                      errorCode={errors.price}
                      type="number"
                      value={service.price ? service.price.toString() : ''}
                      labelText={t('components:general.priceCaptl') + '(€)'}
                      onChange={value => this.handleChange('price', parseFloat(value))}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>

          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton
                type="primary"
                onClick={() => this.handleSubmit()}
                text={service.id !== undefined ? t('components:actions.save') : t('components:actions.add')}
              />
            </div>
            <div className="create-user-buttons__item">
              <MainButton type="secondary" onClick={() => onClose()} text={t('components:actions.cancel')} />
            </div>
          </div>
        </ModalAddServiceContainer>
      </ModalContainer>
    );
  }
}

export default withTranslation(['components'])(withRouter(ModalAddService));