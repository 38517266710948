import React, { Component } from 'react';
import { ModalInviteCollaboratorContainer } from './modal-invite-collaborator-style';
import MainButton from '../../../components/main-button';
import ModalContainer from '../../../components/modals/modal-container';
import { FormGrid, FormGridRow, FormGridItem } from '../../main-layout-style';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';
import InputBox from '../../../components/input-box';
import { InviteCollaboratorFormFunctionalProps } from './collaborator-form-functional';
import SelectBox from '../../../components/select-box';
import { images } from '../../../assets/images';
import { getActiveTemplateLanguages } from '../../../utils/other';
import { InviteOrganizationForm } from '../collaborators-types';

interface IModalInviteCollaboratorProps extends InviteCollaboratorFormFunctionalProps {
  error: string;
  errorFields: { [key: string]: string };
  handleDataChange: (field: string, value: string) => void;
  submitForm: () => void;
  form: InviteOrganizationForm;
}

interface IModalInviteCollaboratorState {
  languagesDropdown: any[];
}

export default class ModalInviteCollaboratorForm extends Component<
  IModalInviteCollaboratorProps,
  IModalInviteCollaboratorState
> {
  state = {
    languagesDropdown: [],
  };

  componentDidMount() {
    this.getActiveTemplateLanguages();
  }

  async getActiveTemplateLanguages() {
    const template = this.props.organizationType === 'marineConservation' ? 'newOrganization' : 'newCertifier';
    const languagesDropdown: any[] = await getActiveTemplateLanguages(template);
    this.setState({ languagesDropdown });
  }

  render() {
    const {
      onCancel,
      showModal,
      className,
      title,
      error,
      errorFields,
      handleDataChange,
      submitForm,
      form,
    } = this.props;
    const { languagesDropdown } = this.state;
    return (
      <ModalContainer className={className} title={title} active={showModal} modalClose={() => onCancel()}>
        <ModalInviteCollaboratorContainer>
          <div className="modal-certifier-form">
            <div className="modal-certifier-form__title">
              <BodyDestacadoText>Datos generales</BodyDestacadoText>
            </div>
            <FormGrid>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder="Nombre"
                    className="rounded-title"
                    labelText="Nombre"
                    type="text"
                    value={form.name}
                    errorCode={errorFields['name']}
                    required={true}
                    onChange={value => {
                      handleDataChange('name', value);
                    }}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <InputBox
                    placeholder="Email"
                    className="rounded-title"
                    type="text"
                    value={form.email}
                    errorCode={errorFields['email']}
                    labelText="Email"
                    required={true}
                    onChange={value => {
                      handleDataChange('email', value);
                    }}
                  />
                </FormGridItem>
              </FormGridRow>
              <FormGridRow>
                <FormGridItem type="col-0">
                  <SelectBox
                    className="rounded-title"
                    labelText="Idioma"
                    placeholder="Selecciona idioma..."
                    required={true}
                    optionsText={languagesDropdown}
                    optionKey={'value'}
                    optionValue={'label'}
                    icon={images.arrrowDown2Svg}
                    onChange={value => handleDataChange('language', value)}
                  />
                </FormGridItem>
              </FormGridRow>
            </FormGrid>
          </div>

          <div className="item-modal-message-bottom">
            <div className="item-modal-message-button">
              <MainButton
                type={'primary'}
                text="Enviar invitación"
                onClick={() => {
                  submitForm();
                }}
              />
            </div>
            <div className="item-modal-message-button">
              <MainButton type={'secondary'} text="Cancelar" onClick={() => onCancel()} />
            </div>
          </div>
        </ModalInviteCollaboratorContainer>
      </ModalContainer>
    );
  }
}
