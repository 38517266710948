import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const ItemTagStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 6px;
  border-radius: 5px;
  color: ${colors['SE-002/100']};
  border: 1px solid ${colors['PR-001/10']};

  .item-tag-text {
    margin-right: 6px;
  }
  .item-tag-delete {
    cursor: pointer;
    padding: 2px;
  }
`;
