import React, { Component } from 'react';
import { ItemTeamContainer } from './item-team-style';
import BodyText from '../text/body-text/body-text';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import InfoText from '../text/info-text/info-text';

interface ItemTeamProps {
  title: string;
  items: any[];
  text?: string;
}
interface ItemTeamState {
  active: boolean;
  position: {
    top: number;
    bottom: number;
  };
}
export default class ItemTeam extends Component<ItemTeamProps, ItemTeamState> {
  popRef: React.RefObject<any>;
  countRef: React.RefObject<any>;
  constructor(props: ItemTeamProps) {
    super(props);
    this.state = {
      active: false,
      position: {
        top: 0,
        bottom: 0,
      },
    };
    this.popRef = React.createRef();
    this.countRef = React.createRef();
  }

  handleClick() {
    const { active } = this.state;
    if (this.countRef.current) {
      let position = this.countRef.current.getBoundingClientRect();
      const positionPop = this.popRef.current.getBoundingClientRect();
      this.setPopPosition(position.left, position.top, positionPop.height);
      document.addEventListener('scroll', () => {
        if (this.countRef.current) {
          position = this.countRef.current.getBoundingClientRect();
          this.setPopPosition(position.left, position.top, positionPop.height);
        }
      });
      this.setState({ active: !active });
    }
  }

  setPopPosition(counLeft: number, countTop: number, popHeight: number) {
    this.popRef.current.style.left = `${counLeft - 275}px`;
    this.popRef.current.style.top = `${countTop - popHeight / 2 + 20}px`;
  }

  render() {
    const { active } = this.state;
    const { title, items, text } = this.props;

    return (
      <ItemTeamContainer>
        <div ref={this.countRef} className="item-team-count" onClick={() => this.handleClick()}>
          <BodyText>{text ? `+${items.length} ${text}` : `+${items.length - 1}`}</BodyText>
        </div>
        <div ref={this.popRef} className={`item-team-pop ${active ? 'active' : ''}`}>
          <div className="item-team-veil" onClick={() => this.setState({ active: false })} />
          <div className="item-team-pop__title">
            <TextDestacadoText>{title}</TextDestacadoText>
          </div>
          {items.length > 0 && (
            <div className={'item-team-pop__list'}>
              {items.map((item: any, i: number) => (
                <div key={i} className="item-team-pop__list__name">
                  {(i < 10 && 
                  <InfoText>{item.activity ? item.activity.name : item}</InfoText>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </ItemTeamContainer>
    );
  }
}
