import React from 'react';
import { TFunction } from 'i18next';
import SingleButton from '../../../components/single-button/single-button';
import { images } from '../../../assets/images';
import BodyDestacadoText from '../../../components/text/body-destacado-text/body-destacado-text';

// export const DATA_BILLS_LAYOUT_DROPDOWN_YEAR = [
//   {
//     label: 'Sin experiencia',
//     value: [0, 1],
//   },
//   {
//     label: 'Iniciado',
//     value: [2, 4],
//   },
//   {
//     label: 'Avanzado',
//     value: [4, 6],
//   },
// ];

export const getBillColumns = (t: TFunction, onDownload: (downloadUrl: string) => void) => [
  {
    Header: t('tables:bill-number'),
    accessor: 'billId',
    className: 'bold-text',
    width: 290,
  },
  {
    Header: t('tables:concept'),
    accessor: 'concept',
    width: 407,
  },
  {
    Header: t('tables:month'),
    accessor: 'month',
    width: 200,
  },
  {
    Header: t('tables:year'),
    accessor: 'year',
    width: 120,
  },
  {
    Header: t('tables:paid-amount'),
    accessor: 'amount',
    width: 120,
  },
  {
    id: 'action',
    Header: '',
    width: 70,
    Cell: (cell: any) => (
      <SingleButton
        className="small secondary"
        icon={images.downloadSvg}
        onClick={() => {
          onDownload(cell.row.original.pdfUrl);
        }}
      />
    ),
  },
];

export const getMonthFilterOptions = (t: TFunction) => [
  {
    label: t('layouts:config.months.1'),
    value: '0',
  },
  {
    label: t('layouts:config.months.2'),
    value: '1',
  },
  {
    label: t('layouts:config.months.3'),
    value: '2',
  },
  {
    label: t('layouts:config.months.4'),
    value: '3',
  },
  {
    label: t('layouts:config.months.5'),
    value: '4',
  },
  {
    label: t('layouts:config.months.6'),
    value: '5',
  },
  {
    label: t('layouts:config.months.7'),
    value: '6',
  },
  {
    label: t('layouts:config.months.8'),
    value: '7',
  },
  {
    label: t('layouts:config.months.9'),
    value: '8',
  },
  {
    label: t('layouts:config.months.10'),
    value: '9',
  },
  {
    label: t('layouts:config.months.11'),
    value: '10',
  },
  {
    label: t('layouts:config.months.12'),
    value: '11',
  },
];
