import * as React from 'react';
import { CardConservationDetailStyle } from './card-conservation-detail-style';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';
import TextText from '../text/text-text/text-text';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import LabelText from '../text/label-text/label';
import { images } from '../../assets/images';
import { getDateMonthString } from '../../utils/time';
import { COUNTRIES_KEY_LABEL } from '../../constants/countries';
import Label02Text from '../text/label-02-text/label-02';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface ICardConservationDetailProps extends RouteComponentProps, WithTranslation{
  conservation: any;
  t: any;
}

export interface ICardConservationDetailState {}

class CardConservationDetail extends React.Component<
  ICardConservationDetailProps,
  ICardConservationDetailState
> {
  constructor(props: ICardConservationDetailProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { conservation, t } = this.props;
    const img = conservation.file || images.avatar;

    return (
      <CardConservationDetailStyle>
        <div className="card-dive-detail-img">
          <img src={img} alt="" />
        </div>

        <div className="card-dive-detail-content">
          <div className="card-dive-detail-content-subtitle">
            <div className="card-dive-detail-content-subtitle__item">
              <Label02Text>{t('components:general.zact')}</Label02Text>
            </div>
            <div className="card-dive-detail-content-subtitle__item">
              <Label02Text>{COUNTRIES_KEY_LABEL[conservation.country]}</Label02Text>
            </div>
          </div>
          <div className="card-dive-detail-content__title">
            <SubheadDestacadoText>{conservation.name}</SubheadDestacadoText>
          </div>
          <div className="card-dive-detail-content__center">
            <TextText>{conservation.organization.name}</TextText>
          </div>
          <div className="card-dive-detail-content__info">
            <div className="card-dive-detail-content__info__icon">
              <img src={images.diversGreenSvg} alt="" />
            </div>
            <TextDestacadoText>{conservation.donations.length} {t('components:buy-resume.divers')}</TextDestacadoText>
          </div>
        </div>
      </CardConservationDetailStyle>
    );
  }
}

export default withTranslation(['components'])(withRouter(CardConservationDetail));
