import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { BodyDestacadoTextContainer } from '../text/body-destacado-text/body-destacado-text-styles';

export const MainButtonDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  width: 100%;
  background: ${colors['PR-001-100']};

  .main-button-drop-top {
    height: 40px;
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    color: ${colors['NE-004-0']};
    padding: 0 0 0 12px;
    display: flex;
    align-items: center;

    justify-content: center;
  }
  .main-button-text {
    flex: auto;
    text-align: left;
  }
  .main-button-icon {
    margin-right: 20px;
    display: flex;
    align-items: center;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;

    &.left {
      margin-right: 8px;
    }
    &.right {
      margin-left: 8px;
      margin-right: 0px;
      transition: all 0.3s ease-in-out;
    }
    img {
      max-width: 18px;
    }
  }

  &.dark-blue {
    background: ${colors['SE-004/100']};
    color: white;
    opacity: 0.8;
    font-weight: 300;
    ${BodyDestacadoTextContainer} {
      font-weight: 300;
      font-size: 13px;
      line-height: 22px;
    }

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }
  }
  &.primary {
    background: ${colors['PR-001/100']};
    color: white;
    &:hover {
      background: ${colors['PR-001/75']};
    }
  }
  &.secondary {
    background: ${colors['NE-002/10']};
    color: ${colors['NE-001/100']};
  }
  &.error {
    background: ${colors['CO-003/100']};
    color: white;
    &:hover {
      background: ${colors['CO-003/75']};
    }
  }
  &.success {
    background: ${colors['CO-001/100']};
    color: white;
    &:hover {
      background: ${colors['CO-001/75']};
    }
  }
  &.disabled {
    background: ${colors['CO-001/100']};
    color: ${colors['NE-003/100']};
  }
  &.simple-menu  {
    color: white;
    opacity: 0.8;
    padding-right: 6px;
    font-weight: 300;
    ${BodyDestacadoTextContainer} {
      font-weight: 300;
      font-size: 13px;
      line-height: 22px;
    }
    &.logout {
      padding: 0;
    }
    &.avatar {
      padding: 0;
      .main-button-icon {
        &.left {
          border-radius: 100%;
          overflow: hidden;
          width: 18px;
          height: 18px;
          img {
            width: 100%;
            height: auto;
            min-height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &:hover {
      opacity: 1;
    }
    &.open {
      opacity: 1;
      background: rgb(0,161,165,.3);
      .main-button-icon {
        &.right {
          transform: rotate(180deg);
        }
      }
    }
    &.active {
      opacity: 1;
      background: rgb(0,161,165,.3);
    }
  }

  .main-button-drop-top  {
    .main-button-icon {∫
      display :flex;
      justify-content: center; align-items: center;
      img {
        width: 18px;
      height: auto;
      }

    }
   }
  .main-button-drop-content  {
    /* display: none; */
    text-align: left;
    padding: 0;
    max-height: 0px;
    transition: all 0.4s ease-in-out;
    visibility: hidden;
    opacity: 0;
    padding: 0 9px 0px 52px;

    &.open {
      padding: 0 9px 11px 52px;
      visibility: visible;
      opacity: 1;
      max-height: 300px;

      /* display: block; */
    }
    &__item {
      position: relative;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0px;
      }
      a {
        font-family: 'plain';
        font-size: 11px;
        line-height: 18px;
        p {
          font-size: 12px;
          opacity: 0.6;
          transition: 0.4s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
        &::before {
          content: '';
          width: 4px;
          height: 4px;
          background: #ffb9b3;
          opacity: 0;
          transition: 0.3s ease-in-out;
          position: absolute;
          top: 8px;
          left: -12px;
          border-radius: 100%;
        }
        &.active {
          p {
            opacity: 1;
          }
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &.active {
  }
`;
