import * as React from 'react';
import { MainLayoutSection, MainLayoutTableContainer } from '../main-layout-style';
import HeaderMain from '../../components/header-main/header-main';
import { images } from '../../assets/images';
import MainTableLayout from '../../components/main-table/main-table';
import Pagination from '../../components/pagination';
import {
  rowKeys,
  certifierTableColumns,
  marineTableColumns,
  Organization,
  InviteOrganizationForm,
} from './collaborators-types';
import { TableRow, TableCell } from '../../types/table';
import ModalSuccess from '../../components/modals/modal-success/modal-success';
import ModalDelete from '../../components/modals/modal-delete/modal-delete';
import InviteCollaboratorFormFunctional from './invite/collaborator-form-functional';
import { withTranslation, WithTranslation } from 'react-i18next';
import { capitalize } from '../../utils/texts';
import ModalCollaboratorForm from './create/modal-collaborator-form';
import services from '../../services/services';
import MainButton from '../../components/main-button';
import { CollaboratorsStyle } from './collaborators-style';
import SelectBox from '../../components/select-box';
import { COLLABORATOR_TYPE_DROPDOWN } from '../../constants/collaborators';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ICollaboratorsLayoutProps extends WithTranslation, RouteComponentProps {
  organizationType: string;
  items: Organization[];
  totalPages: number;
  totalItems?: number;
  page: number;
  setPage: (page: number) => void;
  refreshData: () => void;
  deleteItem: (id: string) => void;
  onFilterChange: (key: string, value: any, method?: string, collection?: string) => void;
  onFilterDelete: (key: string) => void;
}

interface ICollaboratorsLayoutState {
  renderModal: string;
  editID?: string;
  action: string;
  activeFilter: boolean;
  fillForm?: InviteOrganizationForm;
}

class CollaboratorsLayout extends React.Component<ICollaboratorsLayoutProps, ICollaboratorsLayoutState> {
  state: ICollaboratorsLayoutState = {
    renderModal: '',
    editID: '',
    action: '',
    activeFilter: false,
    fillForm: {
      name: '',
      email: '',
      language: 'es',
    },
  };

  componentDidUpdate(prevProps: ICollaboratorsLayoutProps) {
    if (prevProps.organizationType !== this.props.organizationType) {
      if (this.state.activeFilter) {
        this.setState({ activeFilter: false });
      }
    }
  }

  formatDataToTableRows(data: { [key: string]: any }[]) {

    return data.map(item => {
      const row: TableRow = {};

      Object.keys(item).forEach((key, i) => {
        const cell: TableCell = { value: '', type: '', key: '' };
        switch (key) {
          case 'name':
            cell.value = item[key];
            cell.key = 'highlighted-body';
            break;
          case 'integrated':
            cell.value = !!item[key] ? 'Integrada' : 'No integrada';
            cell.key = 'body';
            break;
          default:
            cell.value = (item[key] || '').toString();
            cell.key = 'body';
            break;
        }
        row[key] = cell;
      });

      row.detail = {
        value: 'Acceder',
        key: 'text-button',
        type: 'light-blue',
        onClick: () => {
          this.props.history.push(`/organization/${item.id}/profile`);
        },
      };

      row.options = {
        value: item.id,
        type: '',
        key: 'options',
        actions: [],
      };
      if (row.options && row.options.actions) {
        if (!item.integrated) {
          row.options.actions.push({
            label: 'Invitar',
            icon: images.createSvg,
            onClick: () => {
              this.setState({
                fillForm: {
                  name: item.name,
                  email: item.email,
                  language: 'es',
                },
                renderModal: 'invite-collaborator',
              });
            },
          });
        }
        row.options.actions.push({
          label: 'Eliminar',
          icon: images.delete2Svg,
          onClick: () => {
            this.setState({ renderModal: 'modal-delete', editID: item.id });
          },
        });
      }
      return row;
    });
  }

  render() {
    const {
      t,
      organizationType,
      items,
      totalPages,
      setPage,
      refreshData,
      deleteItem,
      onFilterChange,
      onFilterDelete,
      page,
      totalItems,
    } = this.props;

    const { renderModal, editID, action, activeFilter, fillForm } = this.state;

    const tableColumns = organizationType === 'certifier' ? certifierTableColumns : marineTableColumns;
    const organizationTitle =
      organizationType === 'certifier' ? 'Certificadora' : 'Organización de conservación marina';
    const layoutTitle = organizationType === 'certifier' ? 'Certificadoras' : 'Organizaciones de conservación marina';

    const organizationTypeText = organizationType ? t(`collaborators.${organizationType}`) : '';
    const actionText = action ? t(`collaborators.success.${action}`, { collaborator: organizationTypeText }) : '';

    return (
      <>
        <HeaderMain
          title={layoutTitle}
          withSearch={true}
          resultCount={totalItems}
          mainButton={
            organizationType === 'certifier'
              ? {
                text: t('buttons:create'),
                onClick: () =>
                  this.setState({
                    fillForm: {
                      name: '',
                      email: '',
                      language: 'es',
                    },
                    renderModal: 'create-collaborator',
                  }),
              }
              : undefined
          }
          secondaryButton={{
            text: t('buttons:invite'),
            onClick: () => this.setState({ renderModal: 'invite-collaborator' }),
          }}
          handleSearch={(value: string) => {
            if (value === null) onFilterDelete('name');
            else onFilterChange('name', value);
          }}
        />
        <MainLayoutSection>
          <CollaboratorsStyle>
            {organizationType === 'certifier' && (
              <div className="diving-center-buttons">
                <div className="diving-center-buttons__item">
                  <MainButton
                    onClick={() => this.setState({ activeFilter: !activeFilter })}
                    type={activeFilter ? 'secondary-active' : 'secondary'}
                    text={t('buttons:filter')}
                    iconImg={activeFilter ? images.filtersLightBlueSvg : images.filtersSvg}
                  />
                </div>
              </div>
            )}

            {organizationType === 'certifier' && (
              <div className={`diving-center-filters${activeFilter ? ' active' : ''}`}>
                <div className="diving-center-filters__item">
                  <SelectBox
                    labelText=""
                    placeholder="Tipo"
                    className="rounded-filter"
                    optionsText={[{ value: '*', label: 'Todas' }].concat(COLLABORATOR_TYPE_DROPDOWN)}
                    optionKey={'value'}
                    optionValue={'label'}
                    icon={images.arrowDown2BlueSvg}
                    onChange={value => {
                      if (value === '*') onFilterDelete('id');
                      else if (value === 'integrated') onFilterChange('integrated', true);
                      else onFilterChange('integrated', false);
                    }}
                  />
                </div>
              </div>
            )}

            <MainLayoutTableContainer>
              <div className="diving-center-table">
                <MainTableLayout
                  columns={tableColumns}
                  rows={this.formatDataToTableRows(items)}
                  rowKeys={
                    organizationType === 'certifier' ? rowKeys : rowKeys.filter((c: string) => c !== 'integrated')
                  }
                  onOrder={() => { }}
                />
              </div>
              {totalPages > 1 && (
                <div className="diving-center-pagination">
                  <Pagination page={page} limit={totalPages} callback={page => setPage(page)} />
                </div>
              )}
            </MainLayoutTableContainer>
          </CollaboratorsStyle>
        </MainLayoutSection>

        {renderModal === 'create-collaborator' && (
          <ModalCollaboratorForm
            title={`${t('buttons:create')} ${organizationTitle}`}
            showModal={renderModal === 'create-collaborator'}
            organizationType={organizationType}
            onCancel={() => this.setState({ renderModal: '' })}
            onAccept={() =>
              this.setState({ renderModal: 'success', action: 'create' }, () => {
                refreshData();
              })
            }
          />
        )}

        {/* Modal Delete centre  */}

        {renderModal === 'modal-delete' && (
          <ModalDelete
            title={`${t('modalMessages:modal-delete-center.title')} ${organizationTitle}`}
            subtitle={t('modalMessages:modal-delete-center.subtitle')}
            showModal={renderModal === 'modal-delete'}
            onRemove={async () => {
              if (!!editID) {
                await deleteItem(editID);
                services.pushNotification({
                  title: t('delete-title', { item: capitalize(t('collaborators.collaborator')) }),
                  text: actionText,
                  type: 'red',
                });
                this.setState(
                  {
                    action: 'delete',
                    renderModal: '',
                    editID: undefined,
                  },
                  () => {
                    refreshData();
                    setTimeout(() => this.setState({ action: '' }), 8000);
                  }
                );
              }
            }}
            onCancel={() => this.setState({ renderModal: '' })}
          />
        )}
        {renderModal === 'invite-collaborator' && (
          <InviteCollaboratorFormFunctional
            title={`Invitar a organización`}
            showModal={renderModal === 'invite-collaborator'}
            organizationType={organizationType}
            fillForm={fillForm}
            onCancel={() => this.setState({ renderModal: '' })}
            onAccept={() =>
              this.setState({ renderModal: 'modal-success' }, () => {
                this.setState({
                  renderModal: 'success',
                  action: 'invite',
                  editID: undefined,
                });
              })
            }
          />
        )}
        {/* Modal Success */}

        {renderModal === 'success' && (
          <ModalSuccess
            title={t('success-title')}
            text={actionText}
            showModal={renderModal === 'success'}
            onClose={() => this.setState({ renderModal: '', action: '' })}
          />
        )}
      </>
    );
  }
}

export default withTranslation(['modalMessages'])(withRouter(CollaboratorsLayout));
