import services from './services';

export const getTypesIncidence = async (params: any) => {
  return new Promise((resolve, reject) => {
    const args: any = {
      params,
      endpoint: 'incidence-types',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
}

export const deleteTypesIncidence = (typeId: string) => {
  const endpoint = `incidence-types/${typeId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const createTypesIncidence = (data: any) => {
  return new Promise((resolve, reject) => {
    const args = {
      data,
      endpoint: 'incidence-types',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const uploadTypesIncidence = (typeId: string, data: any) => {
  const endpoint = `incidence-types/${typeId}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const updateTypesIncidences = (data: any) => {
  const endpoint = `incidence-types`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      data,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};
