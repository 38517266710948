import styled from 'styled-components';

export const ModalInviteDiversStyle = styled.div`
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 32px;
  padding-top: 48px;

  .modal-invite-center-subhead {
    font-family: Plain;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #bcc5d7;
    margin: 24px 0;
  }

  .modal-invite-center-flex-button {
    display: flex;

    &__max {
      flex-grow: 1;
      margin-right: 20px;
    }

    &__min {
    }
  }

  .modal-generic-cards-container {
    margin-bottom: 32px;

    .card-file {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: #f8fafd;
      border: 1px solid #f8fafd;
      border-radius: 5px;
      padding: 20px;
      oveflow: hidden;
      margin-top: 16px;

      .card-file__text {
        max-width: 90%;
        overflow: hidden;
        padding-right: 10px;
        font-family: Plain;
        text-align: left;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        align-items: center;
        color: #007d91;
      }

      .card-file__delete {
        max-width: 10%;
        cursor: pointer;
        img {
          width: 24px;
        }
      }
    }
  }
`;
