import React, { Component } from 'react';
import services from '../../../../services/services';
import DiverDivesLayout from './diver-dives-layout';
import { DiverDive, DiverDiveStats } from './types';
import { DIVERS_DIVES_PER_PAGE } from '../../../../constants/data';
import { RouteComponentProps, match } from 'react-router';
import { HeaderButtons } from '../diver-detail';
import { IDiveForm } from '../../../../models/dive-forms';
import { createDive, uploadDivePictures, uploadDiveMainPictures, deleteDive } from '../../../../services/diver';
import { AttachedFile } from '../../../../types/file';

export interface DiverDivesFunctionalProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
    staff: string;
  }>;
  onMultipleSelect: (mutiple: boolean) => void;
  isCentre: boolean;
  isOrganization: boolean;
}

interface DiverDivesFunctionalState {
  stats: DiverDiveStats;
  items: DiverDive[];
  itemsSelected: any[];
  totalItems: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  renderModal: string;
  validateMultiple: boolean;
  page: number;
}

class DiverDivesFunctional extends Component<DiverDivesFunctionalProps, DiverDivesFunctionalState>
  implements HeaderButtons {
  state: DiverDivesFunctionalState = {
    stats: {
      diveCount: 0,
      diveNotValidatedCount: 0,
      lastDiveDate: '',
    },
    items: [],
    itemsSelected: [],
    totalItems: 0,
    totalPages: 0,
    searchFilter: '',
    skipPage: 0,
    orderAscendent: '',
    renderModal: '',
    validateMultiple: false,
    page: 1,
  };

  componentDidMount() {
    this.getDiverDives();
    this.getDiverDivesStats();
  }

  getDiverDives() {
    const {
      match: {
        params: { id, diver, staff },
      },
    } = this.props;
    const { searchFilter, skipPage, orderAscendent } = this.state;

    const endpoint = staff
      ? `centres/${id}/staff/${staff}/dives`
      : diver
        ? `centres/${id}/divers/${diver}/dives`
        : `divers/${id}/dives`;

    const params = {
      filter: {
        where: {
          kind: {
            arraySearch: true,
            method: 'ilike',
            value: `%${searchFilter}%`,
          },
        },
        skip: skipPage,
        limit: DIVERS_DIVES_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: ['site', 'site.centres'],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        if (res.count > 0) {
          const items: any[] = res.data.map((item: any) => {
            const { id, date, kind, validated, type, discipline, site } = item;

            const dive: DiverDive = {
              id,
              date,
              kind,
              validated,
              type,
              discipline,
              site: site && site.name ? site.name : '-',
              centre: site && site.centres && site.centres.length > 0 ? site.centres[0].name : '',
            };
            return dive;
          });

          const totalItems: number = res.count;
          const totalPages: number = Math.ceil(totalItems / DIVERS_DIVES_PER_PAGE);
          this.setState({ items, totalItems, totalPages });
        } else {
          this.setState({ items: [], totalItems: 0, totalPages: 0 });
        }
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  getDiverDivesStats() {
    const {
      match: {
        params: { id, diver, staff },
      },
    } = this.props;

    const endpoint = staff
      ? `centres/${id}/staff/${staff}/dives`
      : diver
        ? `centres/${id}/divers/${diver}/dives`
        : `divers/${id}/dives`;

    const params = {
      filter: {
        orderBy: [{ key: 'date', direction: 'DESC' }],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        if (res.count > 0) {
          const stats: DiverDiveStats = {
            diveCount: res.count,
            lastDiveDate: res.data[0].date,
            diveNotValidatedCount: res.data.reduce((sum: number, e: any) => {
              if (!e.validated) return sum + 1;
              return sum;
            }, 0),
          };
          this.setState({ stats });
        }
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  setPage(page: number) {
    const { totalItems } = this.state;
    const skip: number = DIVERS_DIVES_PER_PAGE * (page - 1);
    if (skip < totalItems) {
      this.setState({ page, skipPage: skip }, () => {
        this.getDiverDives();
      });
    }
  }

  handleSelectRows(rows: any[], allRowsAffected: boolean, allValue?: boolean) {
    const { items } = this.state;
    const { onMultipleSelect } = this.props;
    const itemsSelected: DiverDive[] = items.filter(item => {
      const rowsFiltered = rows.filter(row => row.id.value === item.id.toString());
      return rowsFiltered.length > 0 && rowsFiltered[0].check.value;
    });
    onMultipleSelect(itemsSelected.length > 0);
    this.setState({ itemsSelected });
  }

  async validateDives(diveId?: string | number) {
    const {
      match: {
        params: { id, diver },
      },
      onMultipleSelect,
    } = this.props;
    const { itemsSelected, validateMultiple } = this.state;
    if (validateMultiple) {
      await Promise.all(
        itemsSelected.map(async dive => {
          const diveId = dive.id;
          const endpoint = diver
            ? `centres/${id}/divers/${diver}/dives/${diveId}/validate`
            : `dives/${diveId}/validate`;

          await services.post({
            endpoint,
            then: (response: any) => {
              this.setState({ renderModal: '', itemsSelected: [], validateMultiple: false }, () => {
                onMultipleSelect(false);
                this.getDiverDives();
              });
            },
            catch: (err: any) => {
              console.log(err);
            },
          });
        })
      );
    } else {
      const endpoint = diver ? `centres/${id}/divers/${diver}/dives/${diveId}/validate` : `dives/${diveId}/validate`;

      await services.post({
        endpoint,
        then: (response: any) => {
          this.setState({ renderModal: '', itemsSelected: [] }, () => {
            onMultipleSelect(false);

            this.getDiverDives();
          });
        },
        catch: (err: any) => {
          console.log(err);
        },
      });
    }
  }

  async deleteDives(diveId?: string | number) {
    const {
      match: {
        params: { id, diver },
      },
      onMultipleSelect,
    } = this.props;
    const { itemsSelected, validateMultiple } = this.state;
    if (validateMultiple) {
      await Promise.all(
        itemsSelected.map(async dive => {
          const diveId = dive.id;
          const endpoint = diver ? `centres/${id}/divers/${diver}/dives/${diveId}` : `dives/${diveId}`;

          await services.deleteReq({
            endpoint,
            then: (response: any) => {
              this.setState({ renderModal: '', itemsSelected: [], validateMultiple: false }, () => {
                onMultipleSelect(false);
                this.getDiverDives();
              });
            },
            catch: (err: any) => {
              console.log(err);
            },
          });
        })
      );
    } else {
      const endpoint = diver ? `centres/${id}/divers/${diver}/dives/${diveId}` : `dives/${diveId}`;

      await services.deleteReq({
        endpoint,
        then: (response: any) => {
          this.setState({ renderModal: '', itemsSelected: [] }, () => {
            onMultipleSelect(false);

            this.getDiverDives();
          });
        },
        catch: (err: any) => {
          console.log(err);
        },
      });
    }
  }

  handleMainButtonClick() {
    const { itemsSelected } = this.state;
    const multiSelect = itemsSelected.length > 0;
    this.setState({
      validateMultiple: multiSelect,
      renderModal: multiSelect ? 'modal-validate-dive' : 'modal-registre-dive',
    });
  }

  handleSecondaryButtonClick() {
    const { itemsSelected } = this.state;
    const multiSelect = itemsSelected.length > 0;
    this.setState({
      validateMultiple: multiSelect,
      renderModal: 'modal-delete-dive',
    });
  }

  async createDive(dive: IDiveForm, mainPicture?: AttachedFile[], pictures?: AttachedFile[]) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    try {
      const resp: any = await createDive(id, dive);
      if (mainPicture) {
        const mainPictureForm = new FormData();
        mainPictureForm.set('file', mainPicture[0].data);
        await uploadDiveMainPictures(resp.data.id, mainPictureForm);
      }
      if (pictures) {
        const pictureForm = new FormData();
        for (const picture of pictures) {
          pictureForm.append('files', picture.data);
        }
        await uploadDivePictures(resp.data.id, pictureForm);
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ renderModal: '' });
    this.getDiverDives();
    this.getDiverDivesStats();
    services.showModalSuccess('Inmersión creada', 'La inmersión se ha creado con éxito');
  }

  async deleteDive(diveId: string) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { validateMultiple, itemsSelected } = this.state;
    if (validateMultiple) {
      await Promise.all(
        itemsSelected.map(async dive => {
          const diveId = dive.id;
          await deleteDive(id, diveId);
        })
      );
      services.pushNotification({
        text: 'Las inmersiónes se han eliminado con éxito',
        title: 'Inmersiónes eliminadas',
        type: 'red',
      });
    } else {
      await deleteDive(id, diveId);
      services.pushNotification({
        text: 'La inmersión se ha eliminado con éxito',
        title: 'Inmersión eliminada',
        type: 'red',
      });
    }
    this.getDiverDives();
    this.getDiverDivesStats();
  }
  render() {
    const { stats, items, totalPages, itemsSelected, renderModal, validateMultiple, page } = this.state;
    return (
      <DiverDivesLayout
        {...this.props}
        renderModal={renderModal}
        closeModal={() => this.setState({ renderModal: '' })}
        onCreateDive={(dive, mainPicture, pictures) => this.createDive(dive, mainPicture, pictures)}
        stats={stats}
        items={items}
        totalPages={totalPages}
        page={page}
        setPage={page => this.setPage(page)}
        validateDives={(diveId?: string | number) => this.validateDives(diveId)}
        deleteDives={(diveId?: string | number) => this.deleteDives(diveId)}
        rowsSelected={itemsSelected}
        onSelectRows={(rows, all, allValue) => this.handleSelectRows(rows, all, allValue)}
        onRenderModal={(renderModal: string) => this.setState({ renderModal })}
        validateMultiple={validateMultiple}
        onValidateSingle={() => this.setState({ validateMultiple: false })}
      />
    );
  }
}

export default DiverDivesFunctional;
