import * as React from 'react';
import { MyCenterProfileFacilitiesStyle } from './my-center-proofile-facilities-style';
import CardFacilities from '../../../../components/card-facilities/card-facilities';
import HeaderMain from '../../../../components/header-main/header-main';
import { images } from '../../../../assets/images';
import { MainLayoutSection } from '../../../main-layout-style';
import { RouteComponentProps, match } from 'react-router-dom';
import ModalAddFacilities from '../../../../components/modals/moda-add-facilities/modal-add-facilites';
import services from '../../../../services/services';
import MyCenterProfileNav from '../my-center-profile-nav/my-center-profile-nav';
import { IFacilities } from '../../../../models/centre-forms';
import { editCentre, getCentre } from '../../../../services/centre';
import { IMultilang } from '../../../../models/generic';
import IStoreState from '../../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import { havePermissionsDeals } from '../../../../services/staff-centre';
import { withTranslation } from 'react-i18next';

interface IMyCenterProfileFacilitiesProps extends RouteComponentProps {
  match: match<{
    id: string;
    diver: string;
  }>;
  t: any;
  staffPermissions?: any;
}

interface IMyCenterProfileFacilitiesState {
  facilities: IFacilities;
  modal: string;
}

class MyCenterProfileFacilities extends React.Component<
  IMyCenterProfileFacilitiesProps,
  IMyCenterProfileFacilitiesState
> {
  constructor(props: IMyCenterProfileFacilitiesProps) {
    super(props);

    this.state = {
      modal: '',
      facilities: {
        accesibility: false,
        parking: false,
        wifi: false,
        freeWifi: false,
        beachAccess: false,
        pool: false,
        shop: false,
        restaurant: false,
        nightClub: false,
        bar: false,
        childrenCare: false,
        jacuzzi: false,
        ducha: false,
        audiov: false,
        duchaInd: false,
        usb: false,
        lockerskey: false,
        others: [],
      },
    };
  }

  componentDidMount() {
    this.getFacilities();
  }

  setFacilitiesValue(key: string) {
    const { facilities } = Object.assign(this.state);
    facilities[key] = !facilities[key];
    this.setState({ facilities });
  }

  changeOthers(others: string[]) {
    const { facilities } = this.state;
    const otherFacilities: IMultilang[] = [];
    others.forEach(facility => {
      otherFacilities.push({
        en: facility,
        es: facility,
        de: facility,
        fr: facility,
        it: facility,
        pt: facility,
      });
    });
    this.setState({ facilities: { ...facilities, others: otherFacilities } });
  }

  async getFacilities() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const resp = await getCentre(id);
    const facilities = resp.data.facilities;
    if (facilities) {
      this.setState({ facilities });
    }
  }

  async setFacilities() {
    const { facilities } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    await editCentre(id, { facilities });
    services.pushNotification({
      title: '¡Guardado!',
      text: 'Se han guardado las instalaciones correctamente',
      type: 'green',
    });
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t,
      staffPermissions,
    } = this.props;
    const { facilities, modal } = this.state;

    const arrayFacilities = [
      { key: 'accesibility', img: images.accesibilityBlueSvg, text: 'Accesibilidad' },
      { key: 'parking', img: images.parkingBlueSvg, text: 'Parking' },
      { key: 'wifi', img: images.wifiBlueSvg, text: 'Wi-fi' },
      { key: 'freeWifi', img: images.wifiFreeBlueSvg, text: 'Wi-fi Free' },
      { key: 'beachAccess', img: images.beachBlueSvg, text: 'Acc. Playa' },
      { key: 'pool', img: images.swimmerBlueSvg, text: 'Piscina' },
      { key: 'shop', img: images.shopBlueSvg, text: 'Tienda' },
      { key: 'restaurant', img: images.restaurantBlueSvg, text: 'Restaurante' },
      { key: 'nightClub', img: images.discoBlueSvg, text: 'Discoteca' },
      { key: 'bar', img: images.pubBlueSvg, text: 'Bar' },
      { key: 'childrenCare', img: images.kindergartenBlueSvg, text: 'Guardería' },
      { key: 'ducha', img: images.ducha_caliente, text: 'Ducha caliente' },
      { key: 'jacuzzi', img: images.jacuzziBlueSvg, text: 'Jacuzzi' },
      { key: 'audiov', img:images.trainingSvg, text:'Aulas audiovisuales'},
      { key: 'duchaInd', img: images.ducha_caliente, text:'Duchas individuales'},
      { key: 'usb', img:images.compresor, text:'Punto de carga'},
      { key: 'lockerskey', img:images.lockSvg, text:'Taquillas con llave'},
     // { key: 'others', img: images.circleBlueSvg, text: 'Otros' },
    ];

    // console.log(facilities.others)

    const numActiveFacilities =
      Object.values(facilities).reduce((value, facility) => {
        return value + (facility === true ? 1 : 0);
      }, 0) + facilities.others.length;

    return (
      <>
        <HeaderMain
          title={'Perfil del centro'}
          mainButton={
            !staffPermissions || staffPermissions.deals
              ? {
                  img: images.checkCircleWhiteSvg,
                  text: 'Guardar cambios',
                  onClick: () => this.setFacilities(),
                  disabled: !havePermissionsDeals()
                }
              : undefined
          }
        />
        <MainLayoutSection>
          <MyCenterProfileNav centreId={id} />

          <MyCenterProfileFacilitiesStyle>
            <div className="my-center-profile-facilities-selected">
              <p>
                Has seleccionado{' '}
                <span> {`${numActiveFacilities} instalación${numActiveFacilities !== 1 ? 'es' : ''}`} </span>
              </p>
            </div>
            <div className="my-center-profile-facilities-grid">
              {arrayFacilities.map(item => (
                <div
                  key={item.key}
                  className="my-center-profile-facilities-grid__item"
                  onClick={() => {
                    if (!staffPermissions || staffPermissions.deals) {
                      this.setFacilitiesValue(item.key);
                    }
                  }}
                >
                  <CardFacilities
                    isActive={Object.assign(facilities)[item.key]}
                    text={item.text}
                    img={item.img.toString()}
                  />
                </div>
              ))}
              <div
                key={'others'}
                className="my-center-profile-facilities-grid__item"
                onClick={() => {
                  if (!staffPermissions || staffPermissions.deals) {
                    this.setState({ modal: 'add-facilities' });
                  }
                }}
              >
                <CardFacilities
                  number={facilities.others.length}
                  isActive={facilities.others.length > 0}
                  text={'Otros'}
                  img={images.circleBlueSvg}
                />
              </div>
            </div>
          </MyCenterProfileFacilitiesStyle>
        </MainLayoutSection>
        {modal === 'add-facilities' && (
          <ModalAddFacilities
            items={facilities.others.map(fac => (fac.en ? fac.en.toString() : ''))}
            showModal={modal === 'add-facilities'}
            addItems={(items: string[]) => this.changeOthers(items)}
            onClose={() => this.setState({ modal: '' })}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    staffPermissions: state.staffPermissions.value,
  };
}

export default connect(mapStateToProps)(MyCenterProfileFacilities);
