import React from 'react';
import { BodyDestacadoTextContainer } from './body-destacado-text-styles';

interface Props {
  children: any;
  className?: string;
  color?: string;
  title?: string;
}

const BodyDestacadoText = ({ children = '', className = '', color = '', title }: Props) => {
  return (
    <BodyDestacadoTextContainer color={color} className={className} title={title}>
      {children}
    </BodyDestacadoTextContainer>
  );
};
export default BodyDestacadoText;
