import * as React from 'react';
import { CardMapStyle } from './card-map-style';
import { images } from '../../assets/images/index';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import LabelText from '../text/label-text/label';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';
import StarRate from '../star-rate/star-rate';
import Label02Text from '../text/label-02-text/label-02';
import MainButton from '../main-button';
import { NavLink } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Type {
  icon: string;
  text: string;
}
export interface ICardMapProps extends WithTranslation {
  lng: number;
  lat: number;
  title: string;
  type: Type;
  locationDive: string;
  depth: string;
  rate?: number;
  next: string;
  imagesSite: string[];
  spot: string;
  key: any;
  onClickCard?: () => void;
  addFav?: () => void;
  removeFav?: boolean;
  isStaff?: boolean;
  handleEdit?: () => void;
  link?: string;
  className?: string;
  t:any
}
export interface ICardMapState {
  active: boolean;
  settings: Object;
}

class CardMap  extends React.Component<ICardMapProps, ICardMapState> {
  slider: any = null;
  constructor(props: ICardMapProps) {
    super(props);

    this.state = {
      active: false,
      settings: {
        dots: true,
        // appendDots: (dots: any) => <ul>{dots}</ul>,
        customPaging: () => <button />,
        infinite: false,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: false,
      },
    };
  }
  componentDidMount() {
    const ul = document.getElementsByClassName('slick-dots') as HTMLCollectionOf<HTMLElement>;
    if (ul && ul.length) {
      // tslint:disable-next-line:no-increment-decrement
      for (let index = 0; index < ul.length; index++) {
        ul[index].style.display = 'flex';
      }
    }
  }

  renderSpot() {
    const { spot, type } = this.props;

    switch (spot) {
      case 'marine-reserve':
      case 'marine reserve':
        return <img src={images.diveSiteMarineReserve} alt="" />;
      case 'dive-spot':
        return <img src={images.diveSiteValidated} alt="" />;
      case 'invalid-spot':
        return <img src={images.diveSiteNoValidated} alt="" />;
      case 'group-spot':
        return <div className="card-map-group-marker">{type.text}</div>;
      default:
        break;
    }
  }

  closeWindow(card: number) {
    // console.log(this.props.key)
  }

  render() {
    const {
      lng,
      lat,
      title,
      type,
      locationDive,
      depth,
      rate,
      next,
      imagesSite,
      key,
      onClickCard,
      addFav,
      removeFav,
      isStaff,
      handleEdit,
      link,
      className,
      t
    } = this.props;
    const { active, settings } = this.state;



    return (
      <CardMapStyle lat={lat} lng={lng} key={key}>
        <div className="card-map-marker">
          <div className={`card-map-veil ${active && 'active'}`} onClick={() => this.setState({ active: false })} />
          <div
            className="card-map-marker__icon"
            onClick={() => {
              if (onClickCard) onClickCard();
              this.closeWindow(key)
              this.setState({ active: true });
            }}
          >
            {this.renderSpot()}
          </div>
          <div className={`card-map-pop ${active && 'active'}`} id={key}>
            {imagesSite && imagesSite.length > 0 && (
              <div className="card-map-pop-slider">
                <Slider ref={(c: any) => (this.slider = c)} {...settings}>
                  {imagesSite.map((slide: any, index) => (
                    <div key={index} className="card-map-slide">
                      <img src={slide.toString()} alt="" />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            <div className="card-map-pop-container">
              <div className="card-map-pop-container__type">
                <div className="card-map-pop-container__type__icon">
                  <img src={type.icon} alt="" />
                </div>
                <div className="card-map-pop-container__type__text">
                  <LabelText>{type.text}</LabelText>
                </div>
                {!isStaff && handleEdit && (
                  <div className="card-map-pop-container__type__edit">
                    <div onClick={() => handleEdit()}>{t('actions.edit')}</div>
                  </div>
                )}
              </div>

              <div className="card-map-pop-container__title">
                <NavLink target={'blank'} to={link ? link : ''}>
                  <BodyDestacadoText>{title}</BodyDestacadoText>
                </NavLink>
              </div>
              <div className="card-map-pop-container__location">
                <p>{locationDive}</p>
              </div>
              <div className="card-map-pop-container__depth">
                <p>{depth}</p>
              </div>
              {/*<div className="card-map-pop-container__rate">
                <StarRate number={rate} />
              </div>*/}
              <div className="card-map-pop-container__next">
                <Label02Text>{next}</Label02Text>
              </div>
              {addFav && (
                <MainButton
                  className={!removeFav ? 'map-fav' : ''}
                  text={removeFav ? t('actions.addtofav') : t('actions.delfromfav')}
                  type={removeFav ? 'primary' : undefined}
                  iconImg={!removeFav ? images.heartRedSvg : images.heartWhiteSvg}
                  onClick={() => (addFav ? addFav() : '')}
                />
              )}
            </div>
          </div>
        </div>
      </CardMapStyle>
    );
  }
}
export default withTranslation(['components'])(CardMap);
