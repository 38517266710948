import services from './services';
import { GMAPS_KEY } from '../env';

const googleMapsKey = GMAPS_KEY;
const googleMapsUrl = 'https://maps.google.com/maps/api/geocode/json';

/**
 * @param {string} lat
 * @param {string} lng
 * @param {string} [language]
 * @returns {Promise}
 */
export const fromLatLng = async (lat: string, lng: string, language = 'es') => {
  if (!lat || !lng) {
    return Promise.reject(new Error('Provided coordinates are invalid'));
  }

  const latLng = `${lat},${lng}`;
  let endpoint = `${googleMapsUrl}?latlng=${encodeURI(latLng)}`;
  endpoint += `&key=${googleMapsKey}&language=${language}`;

  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.getApiExternal(args);
  });
};

export const getDataFromAddress = (address: string, country?: string) => {
  const language = localStorage.getItem('i18nextLng');
  // const endpoint = `${googleMapsUrl}?address=${encodeURI(address)}&key=${googleMapsKey}&language=${language}&region=${country ||
  //   language}`;
  const endpoint = `${googleMapsUrl}?address=${encodeURI(address)}&key=${googleMapsKey}&language=${language}`;
  return new Promise((resolve, reject) => {
    const args = {
      endpoint,
      then: resolve,
      catch: reject,
      loader: false,
    };
    services.getApiExternal(args);
  });
};
