import styled, { css } from "styled-components";
import { mediaqueries } from "../../../constants/mediaqueries";

export const BodyTextContainer = styled.p`
  font-family: "plain";
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;

  ${props => css`
    color: ${props.color};
  `}

  ${mediaqueries["max-width768"]} {
    font-size: 14px;
    line-height: 22px;
  }
`;
