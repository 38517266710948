import React from 'react';
import ModalContainer from '../../../../../components/modals/modal-container';
import { ModalCreateEquipmentStyle } from './modal-create-equipment-style';
import { FormGrid, FormGridRow, FormGridItem } from '../../../../main-layout-style';
import SelectBox from '../../../../../components/select-box';
import InputBox from '../../../../../components/input-box';
import { images } from '../../../../../assets/images';
import MainButton from '../../../../../components/main-button';
import { DURATION_UNITS_DROPDOWN, SINGLE_DURATION_OPTIONS } from '../../../../../constants/duration';
import { IEquipment } from '../../../../../models/centre-forms';
import { getStock, postEquipment, patchEquipment } from '../../../../../services/centre';
import { IDropdown } from '../../../../../models/generic';
import services from '../../../../../services/services';
import { copyOf } from '../../../../../utils/other';

interface ModalCreateEquipmentProps {
  centreId: string;
  onSuccess: () => void;
  showModal: boolean;
  equipment?: IEquipment;
  onClose: () => void;
}

interface ModalCreateEquipmentState {
  equipment: IEquipment;
  fullEquipment: boolean;
  stock: IDropdown[];
  errors: { [key: string]: string };
}

export default class ModalCreateEquipment extends React.Component<
  ModalCreateEquipmentProps,
  ModalCreateEquipmentState
> {
  constructor(props: ModalCreateEquipmentProps) {
    super(props);
    this.state = {
      fullEquipment: false,
      equipment: {
        name: '',
        duration: {
          units: '',
          amount: 1,
        },
        stock: [],
        price: NaN,
      },
      errors: {
        name: '',
        units: '',
        amount: '',
        stock: '',
        price: '',
      },
      stock: [],
    };
  }

  componentDidMount() {
    const { equipment } = this.props;
    if (equipment) {
      const equip = this.formatEquipmentProp(equipment);
      const fullEquipment = equipment.stock.length > 1;
      this.setState({ fullEquipment, equipment: equip });
    }
    this.getStock();
  }

  componentDidUpdate(prevProps: ModalCreateEquipmentProps) {
    const { equipment } = this.props;
    if (equipment && equipment !== prevProps.equipment) {
      const equip = this.formatEquipmentProp(equipment);
      const fullEquipment = equipment.stock.length > 1;
      this.setState({ fullEquipment, equipment: equip });
    }
  }

  formatEquipmentProp(equipment: any) {
    const newEquipment = copyOf(equipment);
    newEquipment.stock = newEquipment.stock.map((stock: any) => stock.id.toString());
    newEquipment.price = newEquipment.price / 100;
    return newEquipment;
  }

  async getStock() {
    const { centreId } = this.props;
    const resp = await getStock(centreId);
    const stock = resp.data.data.map((stock: any) => {
      return {
        label: stock.name.en,
        value: stock.id.toString(),
      };
    });
    this.setState({ stock });
  }

  async handleSubmit() {
    const { errors, equipment } = Object.assign(this.state);
    const { centreId, onSuccess } = this.props;
    errors.units = this.validate('units', equipment.duration.units);
    if (equipment.duration.units !== 'activity') errors.amount = this.validate('amount', equipment.duration.amount);
    else errors.amount = '';
    errors.stock = this.validate('stock', equipment.stock);
    errors.price = this.validate('price', equipment.price);
    errors.name = this.validate('name', equipment.name);
    this.setState({ errors });
    if (Object.values(errors).every(err => err === '')) {
      const formattedEquipment = copyOf(equipment);
      formattedEquipment.stock = formattedEquipment.stock.map((elem: any) => {
        return { id: parseInt(elem, undefined) };
      });
      formattedEquipment.price = Math.round(formattedEquipment.price * 100);
      if (this.props.equipment && this.props.equipment.id) {
        await patchEquipment(centreId, this.props.equipment.id, formattedEquipment);
        services.showModalSuccess('¡Editado!', 'El equipo se ha editado con éxito');
      } else {
        await postEquipment(centreId, formattedEquipment);
        services.showModalSuccess('¡Creado!', 'El equipo se ha creado con éxito');
      }
      onSuccess();
    }
  }

  validate(field: string, value: string | number | any[]) {
    if (!value || value === '' || (typeof value !== 'number' && value.length === 0)) {
      return 'required';
    }
    return '';
  }

  validateField(field: string, value: string | number | any[]) {
    const { errors } = Object.assign(this.state);
    const error = this.validate(field, value);
    errors[field] = error;
    this.setState({ errors });
  }

  render() {
    const { onClose, showModal } = this.props;
    const { equipment, stock, fullEquipment, errors } = this.state;
    return (
      <ModalContainer
        title={`${this.props.equipment ? 'Editar equipo en alquiler' : 'Equipo en alquiler'} `}
        className=""
        modalClose={() => onClose()}
        active={showModal}
      >
        <ModalCreateEquipmentStyle>
          <div className="create-user-form">
            <div className="create-user-form__content">
              <FormGrid>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Nombre"
                      className="rounded-title"
                      type="text"
                      required={true}
                      value={equipment.name.toString()}
                      errorCode={errors.name}
                      labelText="Nombre"
                      onChange={value => {
                        this.setState({ equipment: { ...equipment, name: value } }, () =>
                          this.validateField('name', value)
                        );
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText="Equipo"
                      placeholder="Equipo"
                      required={true}
                      className="rounded-title"
                      optionsText={stock.concat([{ label: 'Equipo Completo', value: 'fullEquipment' }])}
                      optionKey={'value'}
                      optionValue={'label'}
                      errorCode={!fullEquipment ? errors['stock'] : ''}
                      defaultValue={fullEquipment ? 'fullEquipment' : equipment.stock[0]}
                      initialValue={fullEquipment ? 'fullEquipment' : equipment.stock[0]}
                      icon={images.arrowDown2BlueSvg}
                      onChange={value => {
                        if (value === 'fullEquipment') {
                          this.setState({ fullEquipment: true });
                        } else {
                          this.setState({ fullEquipment: false, equipment: { ...equipment, stock: [value] } }, () =>
                            this.validateField('stock', [value])
                          );
                        }
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>

                {/* Cuando se añade el equipo completo */}
                {fullEquipment && (
                  <FormGridRow>
                    <FormGridItem type="col-1">
                      <SelectBox
                        labelText=""
                        placeholder="Compuesto por"
                        required={true}
                        className="rounded"
                        optionsText={stock}
                        optionKey={'value'}
                        optionValue={'label'}
                        defaultValue={''}
                        errorCode={errors['stock']}
                        initialValue={''}
                        multiple={true}
                        icon={images.arrowDown2BlueSvg}
                        defaultMultipleValues={equipment.stock}
                        initialMultipleValues={equipment.stock}
                        onChange={() => {}}
                        onChangeMultiple={value => {
                          this.setState({ equipment: { ...equipment, stock: value } }, () =>
                            this.validateField('stock', value)
                          );
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                )}
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <SelectBox
                      labelText="Duración"
                      placeholder="Duración"
                      required={true}
                      className="rounded-title"
                      optionsText={SINGLE_DURATION_OPTIONS}
                      optionKey={'value'}
                      optionValue={'label'}
                      defaultValue={equipment.duration.units}
                      initialValue={equipment.duration.units}
                      icon={images.arrowDown2BlueSvg}
                      errorCode={errors.units}
                      onChange={value => {
                        this.setState(
                          {
                            equipment: { ...equipment, duration: { ...equipment.duration, units: value } },
                          },
                          () => this.validateField('units', value)
                        );
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
                {equipment.duration.units && equipment.duration.units !== 'activity' && (
                  <FormGridRow>
                    <FormGridItem type="col-0">
                      <InputBox
                        placeholder="indique cuantas"
                        className="rounded-title"
                        type="number"
                        value={equipment.duration.amount.toString()}
                        errorCode={errors.amount}
                        labelText="indique cuantas"
                        required={true}
                        min={1}
                        onChange={value => {
                          this.setState(
                            {
                              equipment: {
                                ...equipment,
                                duration: { ...equipment.duration, amount: parseFloat(value) },
                              },
                            },
                            () => this.validateField('amount', value)
                          );
                        }}
                      />
                    </FormGridItem>
                  </FormGridRow>
                )}
                <FormGridRow>
                  <FormGridItem type="col-0">
                    <InputBox
                      placeholder="Precio"
                      className="rounded-title"
                      type="number"
                      value={equipment.price.toString()}
                      errorCode={errors.price}
                      labelText="Precio"
                      required={true}
                      onChange={value => {
                        this.setState({ equipment: { ...equipment, price: parseFloat(value) } }, () =>
                          this.validateField('price', value)
                        );
                      }}
                    />
                  </FormGridItem>
                </FormGridRow>
              </FormGrid>
            </div>
          </div>
          <div className="create-user-buttons">
            <div className="create-user-buttons__item">
              <MainButton type="primary" onClick={() => this.handleSubmit()} text="Guardar" />
            </div>
          </div>
        </ModalCreateEquipmentStyle>
      </ModalContainer>
    );
  }
}
