import React, { Component } from 'react';
import { NotFoundContainer } from './not-found-style';
import MainButton from '../main-button';
import { images } from '../../assets/images';
import { withRouter, RouteComponentProps } from 'react-router';
import TitleText from '../text/title-text/title-text';
import BodyText from '../text/body-text/body-text';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

interface NotFoundProps extends RouteComponentProps, WithTranslation { }

class NotFound extends Component<NotFoundProps, {}> {
  render() {
    const { t } = this.props;
    return (
      <NotFoundContainer>
        <div className="not-found-content">
          <div className="not-found-item">
            <div className="not-found-item__title">
              <p>404</p>
            </div>
            <div className="not-found-item__subtitle">
              <TitleText>{t('components:not-found.title')}</TitleText>
            </div>
            <div className="not-found-item__content">
              <BodyText>{t('components:not-found.text')}</BodyText>
            </div>
            <div className="not-found-item__return">
              <MainButton type="secondary" text={t('buttons:back')} onClick={() => this.props.history.push('/')} />
            </div>
          </div>
          <div className="not-found-img">
            <img src={images.notFound2} alt="" />
          </div>
        </div>
      </NotFoundContainer>
    );
  }
}

export default withTranslation(['intro', 'buttons', 'components'])(withRouter(NotFound));
