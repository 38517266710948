import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const ModalDiverFormContainer = styled.div`
  padding: 0 32px 32px 32px;
  width: 780px;

  .modal-create-form {
    margin-bottom: 40px;
    &__title  {
      color: ${colors['SE-002/100']};
      margin-bottom: 14px;
    }
    &__left {
      width: calc(50% - 10px);
    }
    &__right {
      width: calc(50% - 10px);
    }
    &__content {
      margin-bottom: 40px;
    }
  }

  .modal-create-buttons {
    display: flex;

    &__item {
      width: 144px;
      margin-right: 12px;
    }
  }
`;
