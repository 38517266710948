import React, { useRef } from 'react';
import { usePdf } from 'react-pdf-js';
import { PdfViewerContainerStyle } from './pdf-viewer-style';

const PdfViewer = (file: any) => {
  const canvasPdf: any = useRef(null);
  const [loading, numPages] = usePdf({
    file: 'https://cors-anywhere.herokuapp.com/' + file.file,
    page: 1,
    canvasEl: canvasPdf,
  });

  return (
    <PdfViewerContainerStyle>
      <canvas ref={canvasPdf} />
    </PdfViewerContainerStyle>
  );
};

export default PdfViewer;
