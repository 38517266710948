import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ModalDiveSiteStyle = styled.div`
  padding: 32px;
  display: flex;
  width: 90vw;
  .modal-dive-site-map {
    background: ${colors['PR-001/10']};
    flex: 1 1;
    margin-right: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-dive-site-info {
    display: flex;
    flex: 0 0 content;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal-dive-site-langages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;
  }
.modal-dive-site-info-select {
  margin-bottom: 46px;
}
  .modal-dive-site-info-top {
    width: 100%;

    &__step {
      color: ${colors['SE-002/100']};
    }
    &__title {
      color: ${colors['NE-001/100']};
      margin-bottom: 20px;
      width: 280px;
      margin-bottom: 28px;
    }
    &__text {
      color: ${colors['NE-002/100']};
      margin-bottom: 28px;
    }
    &__form {
      margin-bottom: 48px;
    }
    &__file {
      width: 100%;


    }
    &__files {
      width: 100%;
      margin-bottom: 80px;

    }
    .modal-dive-site-form-check {
      height: 100%;
      margin-bottom: 20px;
    }
  }

  .modal-dive-site-info-bottom {
    display: flex;

    &__item {
      margin-right: 16px;
      min-width: 144px;
      &:last-child  {
        margin-right: 0px;
      }
    }
  }

  .modal-dive-site-pic-bottom {
    display: flex;
    justify-content: space-between;

    &__left {
      display: flex;
      &__save {
        min-width: 144px;
        margin-left: 12px;
      }
    }
    &__right  {
    }
  }

  .modal-dive-site-languages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 12px;
    margin-bottom: 20px;
  }
`;
