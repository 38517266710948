const getPermissionValue = (key: any) => {
    let staff_centre = JSON.parse(localStorage.getItem('staff_centre') + '');
    let user = JSON.parse(localStorage.getItem('user') + '');

    if (staff_centre && typeof staff_centre[key] !== 'undefined'){
        return staff_centre[key];
    }
    else{
        if (user && typeof user[key] !== 'undefined'){
            return user[key];
        }
        else{
            return true;
        }
    }
}

export const havePermissionsDivers = () => {
    return getPermissionValue('divers');
};

export const havePermissionsSites = () => {
    return getPermissionValue('sites');
};

export const havePermissionsManageStaff = () => {
    return getPermissionValue('manageStaff');
};

export const havePermissionsActivities = () => {
    return getPermissionValue('activities');
};

export const havePermissionsDeals = () => {
    return getPermissionValue('deals');
};