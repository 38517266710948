import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './booking-form-pay-data.module.scss'
import TextText from '../../../../../components/text/text-text/text-text';
import { connect, useSelector } from 'react-redux';
import IStoreState from '../../../../../redux/store/IStoreState';
import DicotomicCheckbox from '../../../../../components/dicotomic-checkbox';
import MainButton from '../../../../../components/main-button';
import { getOneBookings, uploadBookings } from '../../../../../services/bookings';
import services from '../../../../../services/services';
import { withRouter } from 'react-router';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../../../../components/stripe/checkoutForm";
import { userData } from '../../../../../redux/reducers';
import { setBookingPaymentData, setStripeTokenId } from '../../../../../redux/actions';

interface IBookingFormAnotherServicesProps {
  booking: {};
}

const BookingFormPayData = (props: any): JSX.Element => {
  const { t } = useTranslation(['modalMessages', 'layouts']);
  const [bookingServices, setBookingServices] = useState<any[]>([]);
  const [servicesSelected, setServicesSelected] = useState<any[]>([]);
  const [accountUser, setAccountUser] = useState([]);
  const [showStripeForm, setShowStripeForm] = useState(false)
  const [isStripe, setIsStripe] = useState<boolean>(false);
  const stripePromise = loadStripe('pk_test_gDUDsjtVjkGmFuYRrXorlEdu00AmqjwYR7');

  useEffect(() => {
    if (props.stripeTokenId.value) {
      save()
    }
  },[props.stripeTokenId])

  const handleCheck = (checked: boolean, serviceId: string) => {
    if (checked) { // add
    } else {
      const res = servicesSelected.filter(el => el !== serviceId);
    }
  }

  const save = async () => {
    if (!props.create) {
      const {
        match: {
          params: { bookingId },
        },
      } = props;
      const params = {
        filter: {
          relations: ['divers', 'payment', 'payment.paymentType'],
        },
      };
      const booking = (await getOneBookings(bookingId, params)).data;
      if (isStripe) {
        booking.payment.paymentType.id = 1;
        booking.payment.paymentResponse = props.stripeTokenId.value;
        services.setValue(setStripeTokenId(''))
      } else {
        booking.payment.paymentType.id = 2;
      }
      await uploadBookings(bookingId, booking);
      props.onNext()
    } else { // create booking
      const bookingPaymentData = {
        paymentType: 0,
        paymentResponse: ''
      };
      if (isStripe) {
        bookingPaymentData.paymentType = 1;
        bookingPaymentData.paymentResponse = props.stripeTokenId.value;
      } else {
        bookingPaymentData.paymentType = 2;
        bookingPaymentData.paymentResponse = '';
      }
      services.setValue(setBookingPaymentData(bookingPaymentData))
      props.onNext()
    }
  }

  const renderStripeForm = () => {
    return (
      <Elements stripe={stripePromise}>
        <div className={styles.stripe_form}>
          <CheckoutForm />
        </div>
      </Elements>
    )
  }

  return (
    <>
      <div className={styles.header}>
        <TextText color={'#00A1A5'}>{t('layouts:bookings.paymethods')}</TextText>
      </div>
      <div className={styles.body}>
        <DicotomicCheckbox
          checked={isStripe}
          className="rounded"
          onCheck={() => {
            {setIsStripe(true); setShowStripeForm(true)}
          }}
        >
          <span className="add-billing-data__radio-label">{t('layouts:bookings.paycard')}</span>
        </DicotomicCheckbox>
        <div className={styles.card}>
          {
            showStripeForm ? renderStripeForm() : ''
          }
        </div>
        <DicotomicCheckbox
          checked={!isStripe}
          className="rounded"
          onCheck={() => {
            {setIsStripe(false); setShowStripeForm(false)}
          }}
        >
          <span className="add-billing-data__radio-label">{t('layouts:centrepay')}</span>
        </DicotomicCheckbox>
      </div>
      {
        !isStripe && (
          <div className={styles.footer_btn}>
            <MainButton text={t('layouts:billing-information.save')} type="primary" onClick={() => save()} />
          </div>
        )
      }

    </>
  );
};

function mapStateToProps(state: IStoreState) {
  return {
    // bookingLines: state.bookingLines.value,
    stripeTokenId: state.stripeTokenId
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(withRouter(BookingFormPayData));
