import * as React from 'react';
import { ItemSectionDetailStyle } from './item-sections-detail-style';
import SubheadDestacadoText from '../text/subhead-destacado-text/subhead-destacado-text';
import { withTranslation, WithTranslation } from 'react-i18next';
import { images } from '../../assets/images';
export interface IItemSectionDetailProps extends WithTranslation {
  icon?: string;
  title: string;
  onEdit?: Function;
  children: any;
  disabled?: boolean;
}

export interface IItemSectionDetailState { }

class ItemSectionDetail extends React.Component<IItemSectionDetailProps, IItemSectionDetailState> {
  constructor(props: IItemSectionDetailProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { icon, title, onEdit, children, t, disabled} = this.props;
    return (
      <ItemSectionDetailStyle>
        <div className="item-section-detail-top">
          <div className="item-section-detail-text">
            {icon && (
              <div className="item-section-detail-icon">
                <img src={icon} alt="" />
              </div>
            )}
            <div className="item-section-detail-title">
              <SubheadDestacadoText>{title}</SubheadDestacadoText>
            </div>
          </div>
          {onEdit && !disabled && (
            <div className="item-section-detail-edit" onClick={() => onEdit()}>
              <img src={images.editSvg} alt="" />
            </div>
          )}
        </div>
        {children}
      </ItemSectionDetailStyle>
    );
  }
}
export default withTranslation(['intro', 'buttons'])(ItemSectionDetail);
