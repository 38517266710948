import React from 'react';
import moment from 'moment';
import { ChatUserContainer } from './chat-user-style';
import { images } from '../../assets/images';
import TextDestacadoText from '../text/text-destacado-text/text-destacado-text';
import BodyText from '../text/body-text/body-text';
import InfoText from '../text/info-text/info-text';
import SingleButton from '../single-button/single-button';
import BodyDestacadoText from '../text/body-destacado-text/body-destacado-text';


interface ChatUserProps {
  name: string;
  lastMessage: any;
  avatar: string;
  className: string;
  onClick: any;
  archive?: any;
  remove: any;
  recover?: any;
  channel?: any;
  t:any;
}
interface ChatUserState {}

export default class ChatUser extends React.Component<ChatUserProps, ChatUserState> {
  getTimestamp() {
    const { lastMessage, t} = this.props;
    let timestamp: string | moment.Moment = '';
    if (lastMessage && lastMessage.timestamp) {
      timestamp = moment(lastMessage.timestamp);
      const diff = moment().diff(timestamp, 'minutes');
      if (diff < 2) timestamp = t('components:general.justnow');
      if (diff < 60 && diff >= 2) timestamp = `${diff} min`;
      if (diff < 1440 && diff >= 60) timestamp = `${moment().diff(timestamp, 'hours')} `+t('components:cancelation.hours');
      if (diff >= 1440) timestamp = `${moment().diff(timestamp, 'days')} `+t('components:cancelation.days');
    }
    return timestamp;
  }

  render() {
    const { name, lastMessage, avatar, className, onClick, channel, archive, remove, recover, t } = this.props;
    const timestamp = this.getTimestamp();
    let unreadMessages = false;
    let lastReadIndex = 0;
    if (channel && channel.state && channel.state.lastConsumedMessageIndex) {
      lastReadIndex = channel.state.lastConsumedMessageIndex;
    }
    if (channel.messages && channel.messages.length > lastReadIndex) unreadMessages = true;
    const body = lastMessage ? lastMessage.body : '';
    return (
      <ChatUserContainer className={className} onClick={onClick}>
        <div className="chat-user-avatar">
          <div className="chat-user-avatar__img">
            {avatar ? <img src={avatar} alt="" /> : <div style={{ backgroundColor: '#001930', height: '100%' }} />}
          </div>
          {unreadMessages && <div className="chat-user-avatar__new" />}
        </div>
        <div className="chat-user-info">
          <div className="chat-user-info__name">
            <TextDestacadoText>{name}</TextDestacadoText>
          </div>
          <div className="chat-user-info__bio">
            <BodyText>{body}</BodyText>
          </div>
          <div className="chat-user-info__time">
            <InfoText>{timestamp}</InfoText>
          </div>
        </div>
        <div className="chat-user-button">
          <SingleButton className="small secondary" drop={true} onClick={() => {}} icon={images.otherSvg}>
            {archive && (
              <div className="single-button-drop__item" onClick={archive}>
                <div className="single-button-drop__item__icon">
                  <SingleButton className="secondary" onClick={archive} icon={images.archiveSvg} />
                </div>
                <div className="single-button-drop__item__text">
                  <BodyDestacadoText>{t('components:actions.arch')}</BodyDestacadoText>
                </div>
              </div>
            )}

            {recover && (
              <div className="single-button-drop__item" onClick={recover}>
                <div className="single-button-drop__item__icon">
                  <SingleButton className="secondary" onClick={recover} icon={images.arrowLeftSvg} />
                </div>
                <div className="single-button-drop__item__text">
                  <BodyDestacadoText>{t('components:actions.rec')}</BodyDestacadoText>
                </div>
              </div>
            )}

            {remove && (
              <div className="single-button-drop__item" onClick={remove}>
                <div className="single-button-drop__item__icon">
                  <SingleButton className="secondary" onClick={remove} icon={images.deleteSvg} />
                </div>
                <div className="single-button-drop__item__text">
                  <BodyDestacadoText>{t('components:actions.del')}</BodyDestacadoText>
                </div>
              </div>
            )}
          </SingleButton>
        </div>
      </ChatUserContainer>
    );
  }
}
