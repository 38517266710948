import services from './services';

export const postActivityTemplate = (data: any, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      data,
      endpoint: 'activity-templates',
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.post(args);
  });
};

export const patchActivityTemplate = (activityId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      data,
      endpoint: `activity-templates/${activityId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patch(args);
  });
};

export const postActivityTemplatePictures = (activityId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      data,
      endpoint: `activity-templates/${activityId}/upload-pictures`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.postFormData(args);
  });
};

export const patchActivityTemplatePictures = (activityId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      data,
      endpoint: `activity-templates/${activityId}/upload-pictures`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const deleteActivityTemplatePictures = (activityId: string, data: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      data,
      endpoint: `activity-templates/${activityId}/pictures`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};

export const deleteActivityTemplate = (activityId: string) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      endpoint: `activity-templates/${activityId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.deleteReq(args);
  });
};
export const unplaningActivityTemplate = (activityId: string) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      endpoint: `activities/${activityId}/unplaning`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.patchFormData(args);
  });
};

export const getActivityTemplate = (activityId: string, params?: any) => {
  return new Promise<any>((resolve, reject) => {
    const args: any = {
      params,
      endpoint: `activity-templates/${activityId}`,
      then: resolve,
      catch: reject,
      loader: true,
    };
    services.get(args);
  });
};
