import React, { Component } from 'react';
import services from '../../../services/services';
import { CENTRES_PER_PAGE } from '../../../constants/data';
import { Company } from './types';
import IStoreState from '../../../redux/store/IStoreState';
import { connect } from 'react-redux';
import CentresSuperAdminLayout from './centres-superadmin-layout';
import { CENTRE_TYPES_KEY_VALUE } from '../../../constants/centres';
import { IFilter } from '../../../models/filter';
import { createFilterRequest } from '../../../utils/other';
import { validateCentre, declineCentre } from '../../../services/centre';
import { t } from 'i18next';

interface CentresFuncionalState {
  centres: Company[];
  totalCentres: number;
  page: number;
  totalPages: number;
  searchFilter: string;
  skipPage: number;
  orderAscendent: string;
  selected: boolean;
  filters: { [key: string]: IFilter };
}

class CentresSuperAdminFuncional extends Component<any, CentresFuncionalState> {
  state: CentresFuncionalState = {
    centres: [],
    totalCentres: 0,
    totalPages: 0,
    page: 1,
    searchFilter: '',
    skipPage: 0,
    orderAscendent: '',
    selected: false,
    filters: {
      confirmed: { value: true },
    },
  };

  componentDidMount() {
    this.getCentres();
  }

  getCentres() {
    const { skipPage, orderAscendent, filters } = this.state;
    const endpoint = 'centres';
    const params = {
      filter: {
        where: createFilterRequest(filters),
        skip: skipPage,
        limit: CENTRES_PER_PAGE,
        orderBy: [{ key: 'createdAt', direction: orderAscendent ? 'ASC' : 'DESC' }],
        relations: ['certifiers'],
      },
    };

    services.get({
      endpoint,
      params,
      loader: true,
      then: async ({ data: res }: any) => {
        if (res.count > 0) {
          const centres: any[] = res.data.map((item: any) => {
            const {
              id,
              name,
              email,
              address,
              town,
              centreId,
              createdAt,
              certifiers,
              validated,
              rejectedBy,
              type,
              confirmed,
            } = item;
            return {
              id,
              name,
              email,
              centreId,
              createdAt,
              certifiers,
              validated,
              rejectedBy,
              confirmed,
              location: `${address}, ${town}`,
              check: false,
              kind: CENTRE_TYPES_KEY_VALUE[type],
            };
          });

          const totalCentres: number = res.count;
          const totalPages: number = Math.ceil(totalCentres / CENTRES_PER_PAGE);
          this.setState({ centres, totalCentres, totalPages, selected: false });
        } else {
          this.setState({ centres: [], totalCentres: 0, totalPages: 0, skipPage: 0, selected: false });
        }
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  setPage(page: number) {
    const { totalCentres } = this.state;
    const skip: number = CENTRES_PER_PAGE * (page - 1);
    if (skip < totalCentres) {
      this.setState({ skipPage: skip, page }, () => {
        this.getCentres();
      });
    }
  }

  async deleteCentre(id: string) {
    const endpoint = `centres/${id}`;

    await services.deleteReq({
      endpoint,
      then: (response: any) => {
        console.log(response);
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  handleChangeFilter(filters: { [key: string]: IFilter }) {
    this.setState({ filters, page: 1, skipPage: 0 }, () => {
      this.getCentres();
    });
  }

  handleCheck(rows: any[], all: boolean, allValue?: boolean | undefined) {
    const { centres } = this.state;
    let selected = false;
    centres.forEach((centre: any, index: number) => {
      if (rows[index].check.value) {
        selected = true;
      }
      centre.check = rows[index].check.value;
    });
    this.setState({ centres, selected });
  }

  async validateCentres(validateRequestBody: string) {
    const { centres } = this.state;
    const { t } = this.props;
    const promises: any[] = [];
    centres.forEach((centre: any) => {
      centre.check && promises.push(validateCentre(centre.id, validateRequestBody));
    });
    await Promise.all(promises);
    services.pushNotification({
      type: 'green',
      title: t('modalMessages:success-title'),
      text: t('modalMessages:bulkvalid'),
    });
    this.getCentres();
  }

  async rejectCentres(validateRequestBody: string) {
    const { centres } = this.state;
    const { t } = this.props;
    const promises: any[] = [];
    centres.forEach((centre: any) => {
      centre.check && promises.push(declineCentre(centre.id, validateRequestBody));
    });
    await Promise.all(promises);
    services.pushNotification({
      type: 'red',
      title: t('modalMessages:reject'),
      text: t('modalMessages:bulkinvalid'),
    });
    this.getCentres();
  }

  render() {
    const { centres, totalPages, page, selected, totalCentres } = this.state;
    return (
      <CentresSuperAdminLayout
        centres={centres}
        totalPages={totalPages}
        totalCentres={totalCentres}
        page={page}
        checked={selected}
        setPage={page => this.setPage(page)}
        validateCentres={(validateRequestBody: string) => this.validateCentres(validateRequestBody)}
        rejectCentres={(validateRequestBody: string) => this.rejectCentres(validateRequestBody)}
        refreshCentres={() => this.getCentres()}
        deleteCentre={id => this.deleteCentre(id)}
        onCheck={(rows: any[], all: boolean, allValue?: boolean | undefined) => this.handleCheck(rows, all, allValue)}
        onChangeFilter={(filters: { [key: string]: IFilter }) => this.handleChangeFilter(filters)}
      />
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    agent: state.agent.value,
  };
}

export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(CentresSuperAdminFuncional);
