import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const DiverBonusesDetailStyle = styled.div`
  .diver-bonuses-detail-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    max-width: 1000px;

    .diver-bonuses-detail-content-left {
      width: 100%;
      padding: 54px 32px 32px;
      background: ${colors['SE-002/100']};
      color: white;
      margin-right: 40px;
      border-radius: 5px;
      &__title  {
        margin-bottom: 40px;
        display: flex;
        &__icon {
          background: white;
          border-radius: 5px;
          margin-right: 12px;
          width: 36px;
          height: 36px;
          padding: 6px;
        }
        &__text {
        }
      }
      &__info {
        display: flex;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 40px;
        }
        &__title {
          width: 120px;
        }
      }
    }
    &__right {
      min-width: 300px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      grid-template-rows: minmax(120px, 120px);
      gap: 20px;
    }
  }
  .diver-bonuses-detail-content-bonus {
    &__by {
      margin-bottom: 7px;
      color: ${colors['NE-002/100']};
    }
    &__name {
      margin-bottom: 7px;
    }
    &__date {
    }
  }

  .diver-bonuses-detail-content-included {
    margin-bottom: 100px;
    &__title {
      margin-bottom: 24px;
    }
  }
  .diver-bonuses-detail-table-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    &__buttons {
      display: flex;
      &__item  {
        margin-left: 12px;
      }
    }
  }
`;
